function numeric(format, num) {
	return format.replace("1", num);
}

function getLetter(num) {
	const alphabet = [
		"A",
		"B",
		"C",
		"D",
		"E",
		"F",
		"G",
		"H",
		"I",
		"J",
		"K",
		"L",
		"M",
		"N",
		"O",
		"P",
		"Q",
		"R",
		"S",
		"T",
		"U",
		"V",
		"W",
		"X",
		"Y",
		"Z",
	];
	let alpha = alphabet[num % alphabet.length];

	if (num > alphabet.length - 1) {
		alpha = getLetter(Math.floor(num / alphabet.length) - 1) + alpha;
	}
	return alpha;
}

function alphabetical(format, num) {
	const letter = getLetter(num - 1);
	return format.replace("A", letter.toUpperCase()).replace("a", letter.toLowerCase());
}

function toRoman(num) {
	if (!+num) {
		return false;
	}

	const digits = String(+num).split("");
	const key = [
		"",
		"C",
		"CC",
		"CCC",
		"CD",
		"D",
		"DC",
		"DCC",
		"DCCC",
		"CM",
		"",
		"X",
		"XX",
		"XXX",
		"XL",
		"L",
		"LX",
		"LXX",
		"LXXX",
		"XC",
		"",
		"I",
		"II",
		"III",
		"IV",
		"V",
		"VI",
		"VII",
		"VIII",
		"IX",
	];
	let roman = "";
	let i = 3;
	while (i--) {
		roman = (key[+digits.pop() + i * 10] || "") + roman;
	}
	return Array(+digits.join("") + 1).join("M") + roman;
}

function romanic(format, num) {
	const letter = toRoman(num);
	return format.replace("I", letter.toUpperCase()).replace("i", letter.toLowerCase());
}

function getNumber(numberType, order) {
	const numberingSelection = ["none", "1", "1", "a", "A", "i", "I", "discBullet", "circleBullet", "squareBullet"];

	switch (numberType) {
		case 0: // Clear all numbers
			return "";
		case 1: // Decimal
			return numeric(numberingSelection[numberType], order);

		case 2: // Decimal - leading zero
			return numeric(numberingSelection[numberType], order);

		case 3: // Alpha - Lower Case
		case 4: // Alpha - Upper Case
			return alphabetical(numberingSelection[numberType], order);

		case 5: // Roman Numeral - Lower
		case 6: // Roman Numeral - Upper
			return romanic(numberingSelection[numberType], order);

		case 7: // Circle
			return "&#x2022;";

		case 8: // Disk
			return "&#x25E6;";

		case 9: // Square
			return "&#x25AA;";

		default:
			return "";
	}
}

function addLeftRightText(mask, num) {
	return mask != null && mask.includes("{style}")
		? mask.substring(0, mask.indexOf("{style}")) + num + mask.substring(mask.indexOf("{style}") + 7, mask.length)
		: num;
}

export default function getNumbering(config, itemType, indent, order) {
	let num = "";
	switch (itemType) {
		case 10: // agenda heading
		case 11: // minutes heading
			num = getNumber(config.headingStyle[indent], order);
			num = addLeftRightText(config.headingMask[indent], num);
			break;
		case 3: // agenda item
		case 4: // minutes item
			num = getNumber(config.itemStyle[indent], order);
			num = addLeftRightText(config.itemMask[indent], num);
			break;
		case 7: // recommendation
		case 8: // resolution
			num = getNumber(config.recommendationStyle[indent], order);
			num = addLeftRightText(config.recommendationMask[indent], num);
			break;
		default:
			break;
	}

	return num;
}
