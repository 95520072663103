import { grayColor } from '../shared';

const pageHeaderButtonStyle = () => ({
	root: {
		padding: '8px',
		borderRadius: '2px',
		'&:hover': {
			backgroundColor: '#455772',
		},
		'&:active': {
			backgroundColor: '#202935',
		},
		'&.Mui-focusVisible': {
			border: `solid 1px ${grayColor[3]}`,
			borderRadius: '2px',
			margin: '-1px',
		},
	},
});

export default pageHeaderButtonStyle;
