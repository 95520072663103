import React, { useState, useCallback, useRef } from "react";
import { useTranslation } from "react-i18next";
import { createPortal } from "react-dom";
import { DndContext, DragOverlay, KeyboardSensor, PointerSensor, useSensor, useSensors, rectIntersection } from "@dnd-kit/core";
import clsx from "clsx";

import makeStyles from "@mui/styles/makeStyles";
import { MenuItem } from "@mui/material";

import InputLabel from "atlas/components/FormControls/InputLabel";
import OutlinedInput from "atlas/components/FormControls/OutlinedInput";
import SelectInput from "atlas/components/FormControls/SelectInput";
import NonModalMenu from "atlas/components/Menu/NonModalMenu";
import MenuItemWithTooltip from "atlas/components/Menu/MenuItemWithTooltip";
import ButtonWithTooltip from "atlas/components/Buttons/ButtonWithTooltip";
import Draggable from "atlas/components/DragAndDrop/Draggable";
import Droppable from "atlas/components/DragAndDrop/Droppable";
import DragPresentation from "atlas/components/DragAndDrop/DragPresentation";
import Tooltip from "atlas/components/Tooltip/Tooltip";
import { MEDIUM } from "atlas/utils/buttonSize";
import { useWidthDown } from "atlas/utils/useWidth";
import { blackColor, grayColor } from "atlas/assets/jss/shared";
import inputStyle from "atlas/assets/jss/components/inputStyle";
import ApprovalProgress from "components/Approval/ApprovalProgress";
import { getRequiredLabel, getErrorProps } from "utils/updateObject";
import BlockUser from "./components/BlockUser";
import WorkflowBlock from "./components/WorkflowBlock";
import { getCollisionDetection } from "utils/dragAndDrop";
import telemetryAddEvent from "utils/telemetryAddEvent";

const useInputStyles = makeStyles(inputStyle);
const useStyles = makeStyles((theme) => ({
	blockColumns: {
		display: "flex",
		flexWrap: "wrap",
		margin: "0 -12px",
		[theme.breakpoints.down("sm")]: {
			margin: "0",
		},
		"& > div": {
			boxSizing: "border-box",
			margin: "0 12px",
			[theme.breakpoints.down("sm")]: {
				margin: "0",
			},
		},
		"& > div:first-child": {
			width: "calc(30% - 24px)",
			[theme.breakpoints.down("sm")]: {
				width: "100%",
			},
		},
		"& > div:not(:first-child)": {
			width: "calc(70% - 24px)",
			[theme.breakpoints.down("sm")]: {
				width: "100%",
			},
		},
	},
	inputLabelSpacing: {
		marginTop: "8px",
	},
	userList: {
		position: "sticky",
		top: 0,
		border: `solid 1px ${grayColor[1]}`,
		borderRadius: "5px",
		height: "50vh",
		overflowY: "auto",
		overflowX: "hidden",
		color: blackColor[1],
		"& ol": {
			margin: "0",
			padding: "0",
			listStyleType: "none",
		},
	},
	blockTier: {
		marginBottom: "16px",
	},
	detailsContainer: {
		display: "flex",
		justifyContent: "flex-start",
		flexWrap: "wrap",
	},
	name: {
		marginRight: "8px",
		minWidth: "206px",
	},
	rejectRule: {
		minWidth: "112px",
	},
	progress: {
		alignSelf: "center",
		[theme.breakpoints.up("xs")]: {
			marginLeft: "8px",
		},
	},
}));

export const FAIL_RULE_NONE = 0;
export const FAIL_RULE_FULL = 1;
export const FAIL_RULE_CURRENT = 2;
export const FAIL_RULE_TIERED = 3;

const Workflow = (props) => {
	const { width, workflow = {}, users, errors = {}, handleUpdate, handleRemoveBlock, handleAddBlock, telemetryPage } = props;
	const widthDownSm = useWidthDown("sm");
	const widthDownMd = useWidthDown("md");
	const { t } = useTranslation("workflows");
	const sensors = useSensors(useSensor(PointerSensor), useSensor(KeyboardSensor));
	const [anchor, setAnchor] = useState(null);
	const [addUserMenuOptions, setAddUserMenuOptions] = useState([]);
	const [draggedId, setDraggedId] = useState(null);
	const [dropTargets, setDropTargets] = useState([]);
	const dragRef = useRef(null);
	const classes = useStyles();
	const inputClasses = useInputStyles({ fullWidth: true });

	const addUser = useCallback(
		(userId, block) => {
			const user = users.find((user) => user.id === userId);
			if (user) {
				handleUpdate(
					{
						target: {
							value: {
								id: user.id,
								name: user.name,
								firstName: user.firstName || "",
								lastName: user.lastName || "",
								title: "",
								profileImageUrl: user.profileImageUrl,
								status: 0,
								comment: "",
								order: block.users.length + 1,
								readOnly: true,
								dateUpdated: "",
								dateUpdatedTimeStamp: 0,
								number: user.number, // Used for avatar color
							},
						},
					},
					"users",
					false,
					false,
					block,
					(newValue, prevValue) => (!prevValue.find((prevUser) => prevUser.id === newValue.id) ? prevValue.concat([newValue]) : prevValue), // Prevent duplicates
				);
			}

			setAnchor(null);
		},
		[users, handleUpdate],
	);

	const handleAddUser = useCallback(
		(e, userId) => {
			if (workflow.blocks.length === 1) {
				addUser(userId, workflow.blocks[0]);
			} else if (workflow.blocks.length > 1) {
				setAddUserMenuOptions(
					workflow.blocks.map((block) => ({
						label: block.name,
						tooltip: t("tooltips.addUserToSpecificBlock", { name: block.name }),
						actionFunction: () => addUser(userId, block),
						"data-cy": `add-user-block-${block.id}`,
					})),
				);

				setAnchor(e.currentTarget);
			}
		},
		[workflow, addUser],
	);

	const handleRemoveUser = useCallback(
		(_e, userId, block) => {
			const user = users.find((user) => user.id === userId);
			if (user) {
				handleUpdate(
					{
						target: {
							value: user.id,
						},
					},
					"users",
					false,
					false,
					block,
					(newValue, prevValue) => prevValue.filter((prevUser) => prevUser.id !== newValue),
				);
			}
		},
		[users, handleUpdate],
	);

	const handleMenuClose = (e) => {
		setAnchor(null);
	};

	const getDragId = (block, blockUser) => `${block.id}-${blockUser.id}`;

	const getUserFromDragId = (id, block) => {
		if (block) {
			return block.users.find((blockUser) => getDragId(block, blockUser) === id);
		} else {
			let user = null;
			for (let index = 0; index < workflow.blocks.length; index++) {
				user = getUserFromDragId(id, workflow.blocks[index]);
				if (user) {
					break;
				}
			}
			return user;
		}
	};

	const handleDragStart = (e) => {
		const { active } = e;
		const { data: { current: { block: activeBlock } = {} } = {} } = active;

		setDraggedId(active.id);
		if (!activeBlock) {
			// Adding
			setDropTargets(workflow.blocks.map((block) => block.id));
		} else {
			// Removing
			setDropTargets(["workflow"]);
		}

		document.body.style.userSelect = "none";
	};

	const endDrag = () => {
		setDraggedId(null);
		setDropTargets([]);

		document.body.style.userSelect = null;
	};

	const handleDragEnd = (e) => {
		const { active, over } = e;

		if (active && over) {
			const { data: { current: { block: activeBlock } = {} } = {} } = active;
			const { data: { current: { block } = {} } = {} } = over;

			if (activeBlock && block && block.ordered && block.users.length > 1 && activeBlock.id === block.id && active.id !== over.id) {
				// Reorder users in a block
				const reorderedUser = getUserFromDragId(active.id, block);
				const oldIndex = block.users.findIndex((blockUser) => getDragId(block, blockUser) === active.id);
				const newIndex = block.users.findIndex((blockUser) => getDragId(block, blockUser) === over.id);
				const newOrder = newIndex + 0.5 + (newIndex > oldIndex ? 1 : 0);

				handleUpdate(
					{
						target: {
							value: {
								id: reorderedUser.id,
								order: newOrder,
							},
						},
					},
					"users",
					false,
					false,
					block,
					(newValue, prevValue) =>
						prevValue
							.map((blockUser, index) => {
								blockUser.order = blockUser.id === newValue.id ? newValue.order : index + 1;

								return blockUser;
							})
							.sort((a, b) => (a.order < b.order ? -1 : a.order > b.order ? 1 : 0))
							.map((blockUser, index) => {
								// Correct the order to be integers
								blockUser.order = index + 1;

								return blockUser;
							}),
				);
			} else if (activeBlock && !block) {
				// Remove a user from a block
				const removedUser = getUserFromDragId(active.id, activeBlock);

				handleRemoveUser(null, removedUser.id, activeBlock);
			} else if (!activeBlock && block) {
				// Add a new user to a block
				addUser(active.id, block);
			}
		}

		endDrag();
	};

	const handleDragCancel = () => {
		endDrag();
	};

	const getFailRuleName = (failRule) => {
		let name = "";
		switch (failRule) {
			case FAIL_RULE_NONE:
				name = t("options.none");
				break;

			case FAIL_RULE_FULL:
				name = t("options.full");
				break;

			case FAIL_RULE_CURRENT:
				name = t("options.current");
				break;

			case FAIL_RULE_TIERED:
				name = t("options.tiered");
				break;
		}

		return name;
	};

	const getFailRuleTooltip = (failRule) => {
		let tooltip = "";
		switch (failRule) {
			case FAIL_RULE_NONE:
				tooltip = t("tooltips.failRuleNone");
				break;

			case FAIL_RULE_FULL:
				tooltip = t("tooltips.failRuleFull");
				break;

			case FAIL_RULE_CURRENT:
				tooltip = t("tooltips.failRuleCurrent");
				break;

			case FAIL_RULE_TIERED:
				tooltip = t("tooltips.failRuleTiered");
				break;
		}

		return tooltip;
	};

	const getTiersCount = (blocks) => {
		const uniqueTiers = [];
		if (blocks && blocks.length > 0) {
			blocks.forEach((block) => {
				if (!uniqueTiers.includes(block.tier)) {
					uniqueTiers.push(block.tier);
				}
			});
		}

		return uniqueTiers.length;
	};

	const getFailRules = () => {
		const tiersCount = getTiersCount(workflow.blocks);
		const failRules = [{ id: FAIL_RULE_NONE, label: t("options.none") }];
		if (tiersCount >= 2) {
			failRules.push({ id: FAIL_RULE_FULL, label: t("options.full") });
		}
		if (tiersCount >= 1) {
			failRules.push({ id: FAIL_RULE_CURRENT, label: t("options.current") });
		}
		if (tiersCount >= 3) {
			failRules.push({ id: FAIL_RULE_TIERED, label: t("options.tiered") });
		}

		const failRuleMenuItems = [];
		failRules.forEach((failRule) => {
			failRuleMenuItems.push(
				<MenuItem key={`fail-rule-${failRule.id}`} value={failRule.id} data-cy={`fail-rule-${failRule.id}`}>
					<Tooltip placement="right" title={getFailRuleTooltip(failRule.id)}>
						<div>{failRule.label}</div>
					</Tooltip>
				</MenuItem>,
			);
		});

		return failRuleMenuItems;
	};

	const getWorkflowBlockTier = (tier, workflowBlocks) => (
		<div key={tier} className={classes.blockTier}>
			{workflowBlocks}
		</div>
	);

	const getWorkflowBlocks = () => {
		let workflowBlocks = [];
		const workflowBlockTiers = [];
		let lastTier = null;
		workflow.blocks.map((block) => {
			if (block.tier !== lastTier) {
				if (workflowBlocks.length > 0) {
					workflowBlockTiers.push(getWorkflowBlockTier(lastTier, workflowBlocks));
				}

				lastTier = block.tier;
				workflowBlocks = [];
			}

			workflowBlocks.push(
				<WorkflowBlock
					key={block.id}
					block={block}
					blocks={workflow.blocks}
					errors={errors.blocks.find((blockError) => blockError.id === block.id) || {}}
					failRuleTiered={workflow.failRule === FAIL_RULE_TIERED}
					allowReadOnly={workflow.readOnlyUsersEnabled}
					getDragId={getDragId}
					dragPlaceholders={[draggedId]}
					dropTarget={dropTargets.includes(block.id)}
					handleUpdate={handleUpdate}
					handleRemoveBlock={handleRemoveBlock}
					handleRemoveUser={handleRemoveUser}
					isDragging={draggedId && typeof draggedId === "number"}
					telemetryPage={telemetryPage}
				/>,
			);
		});
		if (workflowBlocks.length > 0) {
			workflowBlockTiers.push(getWorkflowBlockTier(lastTier, workflowBlocks));
		}

		return workflowBlockTiers;
	};

	return (
		<>
			{anchor && (
				<NonModalMenu
					id="add-user-actions"
					className="overflow-menu"
					anchorEl={anchor}
					keepMounted
					open={Boolean(anchor)}
					onClose={handleMenuClose}
					position="right"
				>
					{addUserMenuOptions.map((option) => (
						<MenuItemWithTooltip
							key={`user-menu-options-${option.label}`}
							tooltip={option.tooltip}
							placement="left"
							onClick={option.actionFunction}
						>
							{option.label}
						</MenuItemWithTooltip>
					))}
				</NonModalMenu>
			)}
			<div>
				<div id="details">
					<div className={classes.detailsContainer}>
						<div className={classes.name}>
							<OutlinedInput
								id="name"
								label={getRequiredLabel(t, t("detail.name"))}
								value={workflow.name}
								onChange={(e) => handleUpdate(e, "name")}
								{...getErrorProps(errors, "name")}
								noDefaultClassName
								fullWidth
								size="small"
								data-cy="name"
							/>
						</div>
						<div className={classes.rejectRule}>
							<SelectInput
								id="fail-rule"
								className={inputClasses.smallInput}
								noDefaultClassName
								label={t("detail.failRule")}
								fullWidth
								size="small"
								value={workflow.failRule}
								onChange={(e) => {
									telemetryAddEvent(`${telemetryPage} - Change Reject Rule - ${getFailRuleName(parseInt(e.target.value, 10))}`, {
										area: "workflows",
									});

									handleUpdate(e, "failRule", undefined, true);
								}}
								data-cy="fail-rule"
							>
								{getFailRules()}
							</SelectInput>
						</div>
						{workflow.blocks.length > 0 && workflow.blocks.find((block) => block.users.length > 0) && (
							<div className={classes.progress} data-cy="progress">
								<ApprovalProgress workflow={workflow} maxIcons={widthDownMd ? 4 : undefined} disableExpandOverlap={widthDownMd} />
							</div>
						)}
					</div>
				</div>
				<div className={classes.blockColumns}>
					<DndContext
						sensors={sensors}
						collisionDetection={getCollisionDetection(dragRef, rectIntersection)}
						onDragStart={handleDragStart}
						onDragEnd={handleDragEnd}
						onDragCancel={handleDragCancel}
					>
						<div>
							<div className={classes.inputLabelSpacing}>
								<InputLabel role="heading" ariaLevel={2} size="large" label={t("detail.availablePeople")} />
							</div>
							{users && (
								<Droppable
									className={classes.userList}
									dropId="workflow"
									useHighlightDroppable
									highlightDroppable={dropTargets.includes("workflow")}
									dropComponent="div"
								>
									<ol data-cy="user-list">
										{users
											.filter((user) => !user.inactive && !user.deleted)
											.map((user) => (
												<Draggable
													key={user.id}
													dragId={user.id}
													dragComponent={BlockUser}
													dragAttributePassthrough
													canDrag
													dragPlaceholder={draggedId === user.id}
													user={user}
													actionIcon="add-circle"
													actionTooltip={t("tooltips.addUserToBlock")}
													handleAction={handleAddUser}
												/>
											))}
									</ol>
								</Droppable>
							)}
						</div>
						<div>
							<div className={classes.inputLabelSpacing}>
								<InputLabel role="heading" ariaLevel={2} size="large" label={t("detail.approvalBlocks")} />
							</div>
							{workflow.blocks && <div>{getWorkflowBlocks()}</div>}
							<div style={{ marginTop: "4px" }}>
								<ButtonWithTooltip
									className={clsx({ [classes.button]: !widthDownSm })}
									title={t("tooltips.addNewBlock")}
									size={MEDIUM}
									variant="outlined"
									color="primary"
									onClick={handleAddBlock}
									data-cy="add-new-block"
								>
									{t("buttons.addNewBlock")}
								</ButtonWithTooltip>
							</div>
						</div>
						{createPortal(
							<DragOverlay>
								<DragPresentation ref={dragRef}>
									{draggedId ? (
										<BlockUser dragPresentational user={users.find((user) => user.id === draggedId) || getUserFromDragId(draggedId)} />
									) : null}
								</DragPresentation>
							</DragOverlay>,
							document.body,
						)}
					</DndContext>
				</div>
			</div>
		</>
	);
};

export default Workflow;
