import { produce } from "immer";
import { v4 as uuid } from "uuid";

import findIndex from "lodash/fp/findIndex";
import forEach from "lodash/fp/forEach";

import { updateAgenda, findItemByID, findAttachment, findAttachmentAll } from "./utils";
import { allowedEditorExtensions } from "utils/allowedExtensions";
//
// for functions, this. will reference MeetingEditor instance
//
export function invalidFileExtension(itemGuid, fileNames) {
	console.log("invalidFileExtension, files: ", fileNames);
	if (fileNames.length > 0) {
		updateAgenda(this, { failedFileUploads: fileNames });
	}
}

export function addFileUpload(fileUploads) {
	const { id, active } = this.state;
	const activeGuid = active.selected.substr(0, 36);
	const fileData = new FormData();
	let closed = false;
	forEach((fileUpload) => {
		fileData.append(fileUpload.guid, fileUpload.file);
		if (findIndex((feature) => feature.id === "MOA", fileUpload.features) !== -1) {
			closed = true;
		}
	}, fileUploads);

	fileData.append(
		"data",
		JSON.stringify({
			meetingId: id,
			itemGuid: activeGuid,
			attach: true,
			closed,
		}),
	);
	//
	// This addFile comes from the MeetingEditorContainer that was passed into MeetingEditor as a property
	// queueFileUploads
	this.props.container.queueFileUploads(activeGuid, fileUploads);

	// put a placeholder in the UI
	const currentState = this.state;
	const nextState = produce(currentState, (draft) => {
		const item = findItemByID(activeGuid, draft.items);
		if (!item.attachments) {
			item.attachments = [];
		}
		//
		// Update attachments array with files to be uploaded
		//  Check for an existing entry and update, otherwise add a new entry
		//
		for (let i = 0; i < fileUploads.length; i++) {
			const idx = findIndex((att) => att.guid === fileUploads[i].guid, item.attachments);
			if (idx === -1) {
				item.attachments.push({
					name: fileUploads[i].name,
					guid: fileUploads[i].guid,
					url: fileUploads[i].url,
					itemGuid: activeGuid,
					include: true,
					deleted: false,
					removed: false,
				});
			} else {
				item.attachments[idx].name = fileUploads[i].name;
				item.attachments[idx].guid = fileUploads[i].guid;
				item.attachments[idx].url = fileUploads[i].url;
				item.attachments[idx].itemGuid = activeGuid;
				item.attachments[idx].include = true;
				item.attachments[idx].deleted = false;
				item.attachments[idx].removed = false;
			}
		}
	});
	//
	// Update the agenda state and then invoke a saveAgenda before uploading files
	// Prevents upload from updating item with old information
	//
	updateAgenda(this, { items: nextState.items }, null, { agenda: true });
	this.saveAgenda({
		success(data) {
			const { agenda, fileData } = data;
			agenda.props.container.addFile(fileData, () => agenda.state.items); // Get the latest version of the items
		},
		data: {
			agenda: this,
			fileData,
		},
	});
}
//
// NOTE:  This function is obsolete.  Can be removed when AttachFile.js is removed
//
export function addFile(file) {
	const { id, active } = this.state;
	const activeGuid = active.text ? active.selected.substr(0, 36) : active.selected;

	const fileData = new FormData();
	const acceptedFileExtensions = allowedEditorExtensions;
	const failedUploads = [];
	let addedFiles = false;
	for (let i = 0; i < file.length; i++) {
		const fileExtension = file[i].name.split(".").pop();
		if (acceptedFileExtensions.includes(fileExtension.toLowerCase())) {
			fileData.append(file[i].name, file[i]);
			addedFiles = true;
		} else {
			failedUploads.push(file[i].name);
		}
	}

	if (failedUploads.length > 0) {
		updateAgenda(this, { failedFileUploads: failedUploads });
	}

	if (!addedFiles) {
		return;
	}

	fileData.append(
		"data",
		JSON.stringify({
			meetingId: id,
			itemGuid: activeGuid,
			attach: true,
			closed: false,
		}),
	);
	//
	// This addFile comes from the MeetingEditorContainer that was passed into MeetingEditor as a property
	//
	this.props.container.addFile(fileData);

	// put a placeholder in the UI
	const currentState = this.state;
	const nextState = produce(currentState, (draft) => {
		const item = findItemByID(activeGuid, draft.items);
		if (!item.attachments) {
			item.attachments = [];
		}

		for (let i = 0; i < file.length; i++) {
			const fileExtension = file[i].name.split(".").pop();
			if (acceptedFileExtensions.includes(fileExtension.toLowerCase())) {
				item.attachments.push({
					name: file[i].name,
					guid: uuid(),
					url: "",
					itemGuid: activeGuid,
				});
			}
		}
	});

	updateAgenda(this, { items: nextState.items });
}

export function deleteFile(itemGuid, guid) {
	const currentState = this.state;
	this.isDirty = true;
	const nextState = produce(currentState, (draft) => {
		const parent = findItemByID(itemGuid, draft.items);
		const deleteIndex = findIndex((file) => file.guid === guid, parent.attachments);
		parent.attachments[deleteIndex].removed = true;
		this.itemIdsToUpdate.push(parent.guid);
	});

	updateAgenda(this, { items: nextState.items }, null, { agenda: true });
}

export function getFileAttachment(guid) {
	const { items } = this.state;
	return findAttachment(guid, items);
}

export function getFileAttachmentAll(guid) {
	const { items } = this.state;
	return findAttachmentAll(guid, items);
}
