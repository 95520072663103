import React from "react";
import { Routes, Route } from "react-router-dom";

import PolicyContainer from "../views/Policy/PolicyContainer";
import PolicyEditContainer from "../views/Policy/PolicyEditContainer";

const PolicyDetailRoutes = () => {
	return (
		<Routes>
			<Route path=":id" element={<PolicyContainer />} />
			<Route path="/edit/:id" element={<PolicyEditContainer />} />
		</Routes>
	);
};

export default PolicyDetailRoutes;
