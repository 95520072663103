import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { primaryColor } from "atlas/assets/jss/shared";
import { updateSettings } from "redux/generalSettings/actions";

import Accordion from "atlas/components/Accordion/Accordion";
import OutlinedInput from "atlas/components/FormControls/OutlinedInput";
import Icon from "atlas/components/Icon/Icon";

import accordionStyle from "assets/jss/components/accordionStyle";
import generalSettingsStyle from "../jss/generalSettingsStyle";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles(generalSettingsStyle);
const useAccordionStyles = makeStyles(accordionStyle);

const PolicyUrl = (props) => {
	const { t } = useTranslation("generalSettings");
	const accordionClasses = useAccordionStyles();
	const classes = useStyles();
	const dispatch = useDispatch();
	const { policyURL } = useSelector((state) => state.generalSettingsReducer);

	const handleUpdate = (e, name) => {
		const {
			target: { value },
		} = e;
		dispatch(updateSettings({ [name]: value }));
	};

	return (
		<>
			<div className={clsx(classes.section, classes.sectionAccordion)}>
				<Accordion
					classes={accordionClasses}
					className={classes.bold}
					label={t("subtitle.policyURL")}
					scrollContentIntoView
					overflow={false}
					dataCy="policy-url"
				>
					<div className={classes.mainCard}>
						<div className={classes.subCard}>
							<OutlinedInput
								className={clsx(classes.inputfield, classes.outlinedInput)}
								label={t("headings.policyURL")}
								value={policyURL || ""}
								onChange={(e) => handleUpdate(e, "policyURL")}
								noDefaultClassName
								size="small"
								inputProps={{ "aria-label": t("headings.policyURL") }}
								data-cy="organizationName"
							/>
							<Box className={classes.redirectLink} arial-label="external-link">
								<a href={`${policyURL}`} target="_blank">
									<Icon name="external-link" size="24px" color={primaryColor[3]} data-cy="externalLink" />
								</a>
							</Box>
						</div>
					</div>
				</Accordion>
			</div>
		</>
	);
};
export default PolicyUrl;
