import React, { useState, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Close } from "components/Icons";
import makeStyles from "@mui/styles/makeStyles";
import { useNavigate } from "react-router-dom";
import GenericDialog from "atlas/components/Dialogs/GenericDialog";
import GoalHeadingData from "../../views/Goals/GoalHeadingData";
import GoalContentData from "../../views/Goals/GoalContentData";
import GoalMeetingData from "../../views/Goals/GoalMeetingData";

import CircularProgressIndicator from "atlas/components/Progress/CircularProgressIndicator";
import Typography from "@mui/material/Typography";

import ComponentContainer from "atlas/components/ComponentContainer/ComponentContainer";
import { getGoal } from "redux/goals/actions";

const useStyles = makeStyles(() => ({
	dialog: {
		"& .MuiDialog-paper": {
			width: "600px",
			maxWidth: "100%",
		},
	},
}));

const GoalsViewDialog = (props) => {
	const { show = true, closeGoalPopup, goalLink } = props;
	const [loading, seLoading] = useState(true);
	const [error, setError] = useState(false);
	const [goal, setGoal] = useState({});
	const { t } = useTranslation("goals");
	const classes = useStyles();
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const getFileHrefInfo = (info) => {
		if (typeof info === "string") {
			const url = info;
			const parts = url.split("/");
			const extractedId = parts[parts.length - 2];
			return parseInt(extractedId);
		} else return info;
	};
	useEffect(() => {
		if (goalLink) {
			const id = getFileHrefInfo(goalLink);
			dispatch(getGoal(id))
				.then((res) => {
					if (res) {
						seLoading(false);
						setGoal(res);
					}
				})
				.catch((err) => {
					setError(true);
					seLoading(false);
					console.log(err);
				});
		}
	}, [goalLink]);

	const handleEditGoals = () => {
		let goalId = goal.draftGoalId;
		navigate(`/goals/edit/${goalId}`);
	};
	const dialog = {
		title: t("goalViewDialog.title"),
		primaryTitle: t("goalViewDialog.labels.primaryTitle"),
		primaryAction: handleEditGoals,
		secondaryAction: closeGoalPopup,
	};

	return (
		<GenericDialog
			className={classes.dialog}
			show={show}
			title={dialog.title}
			primaryAction={dialog.primaryAction}
			primaryTitle={Object.keys(goal).length > 0 && goal.canUpdate ? dialog.primaryTitle : null}
			primaryDisabled={loading || error}
			data-cy="goal-view-dialog"
			secondaryAction={dialog.secondaryAction}
			closeIcon={<Close />}
		>
			<div>
				{!loading ? (
					<>
						{Object.keys(goal).length > 0 ? (
							<ComponentContainer padding="24px 8px" maxWidth="974px">
								<GoalHeadingData goal={goal} />
								<GoalContentData goal={goal} />
								{goal && goal.linkedMeetingList && goal.linkedMeetingList.length > 0 && <GoalMeetingData goal={goal} />}
							</ComponentContainer>
						) : (
							<Typography variant="h4">{t("goalViewDialog.removedGoal")}</Typography>
						)}
					</>
				) : (
					<CircularProgressIndicator />
				)}
			</div>
		</GenericDialog>
	);
};

export default GoalsViewDialog;
