import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const useWidth = () => {
	const theme = useTheme();
	const keys = [...theme.breakpoints.keys].reverse();

	return (
		keys.reduce((output, key) => {
			const matches = useMediaQuery(theme.breakpoints.up(key));
			return !output && matches ? key : output;
		}, null) || 'xs'
	);
};

export const useWidthDown = (breakpoint) => {
	if (!breakpoint) {
		return undefined;
	}

	const theme = useTheme();

    return useMediaQuery(theme.breakpoints.down(breakpoint));
};

export const useWidthUp = (breakpoint) => {
	if (!breakpoint) {
		return undefined;
	}

	const theme = useTheme();

	return useMediaQuery(theme.breakpoints.up(breakpoint));
};

export default useWidth;
