import BoardMinutesStatusesEnum from "./enums/BoardMinutesStatuses";
import PublicMinutesStatusesEnum from "./enums/PublicMinutesStatuses";

export function isPublished(publicStatus) {
	return (
		publicStatus === PublicMinutesStatusesEnum().PUBLISHED.value ||
		publicStatus === PublicMinutesStatusesEnum().APPROVED.value ||
		publicStatus === PublicMinutesStatusesEnum().ADOPTED.value ||
		publicStatus === PublicMinutesStatusesEnum().OUTOFSYNC.value
	);
}

export function isShared(boardStatus) {
	return (
		boardStatus === BoardMinutesStatusesEnum().APPROVED.value ||
		boardStatus === BoardMinutesStatusesEnum().ADOPTED.value ||
		boardStatus === BoardMinutesStatusesEnum().SHARED.value ||
		boardStatus === BoardMinutesStatusesEnum().OUTOFSYNC.value
	);
}

export function isPublishedInSync(publicStatus) {
	return (
		publicStatus === PublicMinutesStatusesEnum().PUBLISHED.value ||
		publicStatus === PublicMinutesStatusesEnum().APPROVED.value ||
		publicStatus === PublicMinutesStatusesEnum().ADOPTED.value
	);
}

export function isSharedInSync(boardStatus) {
	return (
		boardStatus === BoardMinutesStatusesEnum().APPROVED.value ||
		boardStatus === BoardMinutesStatusesEnum().ADOPTED.value ||
		boardStatus === BoardMinutesStatusesEnum().SHARED.value
	);
}

export function isAdopted(publicStatus) {
	return publicStatus === BoardMinutesStatusesEnum().APPROVED.value || publicStatus === BoardMinutesStatusesEnum().ADOPTED.value;
}
