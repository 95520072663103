import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { withTranslation } from "react-i18next";
import request from "superagent";
import processHtml, { stripHtml } from "utils/processHtml";

import makeStyles from "@mui/styles/makeStyles";
import { MenuItem, Typography } from "@mui/material";

import telemetryAddEvent from "utils/telemetryAddEvent";
import MinutesItemTypesEnum from "utils/enums/MinutesItemTypes";
import Icon from "atlas/components/Icon/Icon";
import SelectInput from "atlas/components/FormControls/SelectInput";
import { API_HOST } from "config/env";
import GenericDialog from "atlas/components/Dialogs/GenericDialog";
import GenericEditor from "components/Editor/GenericEditor";

import inputStyle from "atlas/assets/jss/components/inputStyle";

import getNumbering from "utils/numbering";

const useInputStyles = makeStyles(inputStyle);

const useStyles = makeStyles(() => ({
	dialog: {
		"& .MuiDialog-paper": {
			width: "480px",
			maxWidth: "100%",
		},
	},
	menuItem: {
		maxWidth: (props) => (props.selectWidth > 0 ? `${props.selectWidth}px` : undefined),
	},
	menuItemText: {
		overflow: "hidden",
		textOverflow: "ellipsis",
	},
	progressBar: {
		width: "240px",
		margin: "0 auto",
		marginTop: "24px",
	},
	hidden: {
		display: "none",
	},
	createMeeting: {
		marginTop: "8px",
	},
}));

const MoveConsentItemDialog = (props) => {
	const { t, meetingId, item, items, numbering, show, onClose, defaultMoveConsentItemSection, setDefaultMoveConsentItemSection } = props;
	const [selectedSection, setSelectedSection] = useState(defaultMoveConsentItemSection);
	const dispatch = useDispatch();
	const [newSectionData, setNewSectionData] = useState({ name: null, description: null });
	const [moving, setMoving] = useState(false);
	const [selectWidth, setSelectWidth] = useState(0);

	const classes = useStyles({ selectWidth });
	const inputClasses = useInputStyles({ fullWidth: true });

	const editors = [];

	const features = [
		{
			id: "MOA",
			label: t("inlineFile.features.MOA.featureLabel"),
			className: "closed",
			defaultValue: closed,
			disabledValue: closed,
			isEnabled: !closed,
			anchorTitle: t("inlineFile.features.MOA.anchorTitleMember"),
			tooltipDisabledOn: t("inlineFile.features.MOA.tooltipDisabledOn"),
		},
	];

	const findItemParent = (guid) => {
		let foundSection = null;
		if (items) {
			foundSection = items.filter((filterItem) => filterItem.guid === guid);
		}

		return foundSection && foundSection.length ? foundSection[0] : null;
	};

	const handleMoveItem = () => {
		if (selectedSection || newSectionData.name) {
			const parentSection = findItemParent(item.attributes.relationshipGuid);
			const newSectionBullet = getNumbering(numbering, MinutesItemTypesEnum().HEADING.value, 0, parentSection.fields.Order.Value + 1);
			const updatesBullets = [];
			let headingOrder = 1;
			let itemOrder = 1;
			let consentHeadingReached = false;
			const consentHeading = findItemParent(item.attributes.relationshipGuid);
			if (newSectionData.name) {
				items.forEach((existingItem) => {
					if (MinutesItemTypesEnum().HEADING.value === existingItem.itemType) {
						if (consentHeadingReached) {
							let newNumbering = getNumbering(numbering, existingItem.itemType, existingItem.fields.Indent.Value, headingOrder);
							existingItem.fields.Number.Value = newNumbering;
							existingItem.fields.Order.Value = headingOrder;
							updatesBullets.push({ guid: existingItem.guid, bullet: newNumbering });
						} else if (consentHeading.guid === existingItem.guid) {
							consentHeadingReached = true;
						}

						headingOrder++;
						itemOrder = 1;
					} else if (MinutesItemTypesEnum().ITEM.value === existingItem.itemType && consentHeadingReached) {
						let newNumbering = getNumbering(numbering, existingItem.itemType, existingItem.fields.Indent.Value, itemOrder);
						const parentItem = findItemParent(existingItem.attributes.relationshipGuid);
						newNumbering = parentItem.fields.Number.Value + newNumbering;
						existingItem.fields.Number.Value = newNumbering;
						updatesBullets.push({ guid: existingItem.guid, bullet: newNumbering });

						itemOrder++;
					}
				});
			}
			setMoving(true);
			request
				.post(`${API_HOST}/api/meeting/${meetingId}/moveitem`)
				.send({
					itemGuid: item.guid,
					sectionGuid: selectedSection,
					newSectionData: {
						name: newSectionData.name,
						description: newSectionData.description,
						bullet: newSectionBullet,
						updatedBulletData: updatesBullets,
					},
				})
				.then((res) => {
					telemetryAddEvent(`Live meeting - Move consent item`);
					let option = notifierMessage(t("moveConsentItemDialog.snackbar.success"), "success");
					dispatch(setSnackbarOptions(option));
					setMoving(false);
					if (res && res.body && res.body.newSection) {
						telemetryAddEvent(`Live meeting - Move consent item new heading created`);
						setDefaultMoveConsentItemSection(res.body.newSection.guid);
					}
					onClose();
				})
				.catch((err) => {
					console.log("Error", err);
					onClose();
				});
		}
	};

	const handleSectionChange = (e) => {
		const {
			target: { value },
		} = e;

		setSelectedSection(value);
	};

	const handleCancel = () => {
		onClose();
	};

	const handleEditorChange = (_event, editor, field) => {
		const newContent = editor.getData();
		setNewSectionData((prev) => ({
			...prev,
			[field]: newContent,
		}));
	};

	const setEditorsData = (name, value) => {
		editors[name] = value;
	};

	const getSections = () => {
		const sectionsItems = [];
		if (items && items.length > 0) {
			items.forEach((item) => {
				if (item.itemType === MinutesItemTypesEnum().HEADING.value && !item.fields.Consent.Value) {
					sectionsItems.push(
						<MenuItem key={`section-${item.guid}`} className={classes.menuItem} value={item.guid} data-cy={`section-${item.guid}`}>
							<div className={classes.menuItemText}>{processHtml(stripHtml(item.fields.Name.Value))}</div>
						</MenuItem>,
					);
				}
			});
		}

		return sectionsItems;
	};

	useEffect(() => {
		// Set the drop-down options width to match the width of the control
		const itemSelect = document.getElementById("item");
		if (itemSelect) {
			setSelectWidth(itemSelect.offsetWidth);
		}
	}, []);

	return (
		<GenericDialog
			className={classes.dialog}
			show={show}
			title={t("moveConsentItemDialog.title")}
			primaryAction={handleMoveItem}
			primaryTitle={t("buttons.move")}
			primaryDisabled={moving || (selectedSection == null && newSectionData.name == null)}
			secondaryAction={handleCancel}
			secondaryTitle={t("app:buttons.cancel")}
			secondaryDisabled={moving}
			clickAwayDisabled={moving}
			closeIcon={<Icon name="close" />}
			data-cy="move-consent-item-dialog"
			disableAutoFocus
			disableEnforceFocus
		>
			<Typography type="h2">{t("moveConsentItemDialog.description")}</Typography>
			<SelectInput
				id="move-consent-item-section"
				name="move-consent-item-section"
				className={inputClasses.smallInput}
				noDefaultClassName
				fullWidth
				label={t("agendaMenu:section")}
				value={selectedSection ? selectedSection : ""}
				onChange={handleSectionChange}
				data-cy="move-consent-item-section"
			>
				{getSections()}
			</SelectInput>
			<Typography type="h2">{t("moveConsentItemDialog.createNewAgendaSection")}</Typography>
			<GenericEditor
				meetingId={meetingId}
				guid={newSectionData ? newSectionData.guid : ""}
				toolbar="simple"
				name="name"
				content={newSectionData ? newSectionData.name || "" : ""}
				title={t("agendaItems:editItem.nameLabel")}
				setEditorsData={setEditorsData}
				features={features}
				onChange={handleEditorChange}
				mt={0}
			/>
			<br />
			<GenericEditor
				meetingId={meetingId}
				guid={newSectionData ? newSectionData.guid : ""}
				toolbar="scratchpad"
				name="description"
				title={t("agendaItems:editItem.descriptionLabel")}
				content={newSectionData ? newSectionData.description || "" : ""}
				setEditorsData={setEditorsData}
				features={features}
				onChange={handleEditorChange}
				mt={0}
			/>
		</GenericDialog>
	);
};

export default withTranslation("meetings")(MoveConsentItemDialog);
