import React, { Component } from "react";

import Grid from "@mui/material/Grid";

import Logo from "components/Icons/logo";

class LoginAvatar extends Component {
	render() {
		const { leftAlign = false } = this.props;

		return !leftAlign ? (
			<Grid className="community-logo" container justifyContent="center" alignItems="center">
				<Logo className="sign-in-logo" />
			</Grid>
		) : (
			<Logo className="left-nav-logo" />
		);
	}
}

export default LoginAvatar;
