import React from "react";
import SvgIcon from "@mui/material/SvgIcon";

import themeConfig from "../../atlas/assets/jss/theme";
import getOr from "lodash/fp/getOr";

const icon = (props) => {
	const { color } = props;
	const fillColor = color ? getOr(color, ["palette", color, "main"], themeConfig) : "currentColor";

	return (
		<SvgIcon {...props}>
			<defs>
				<path
					d="M9.07594585,12.6925106 L0.175102578,3.04993043 C-0.212427033,2.63010668 0.0853449136,1.95 0.656686471,1.95 L23.3433135,1.95 C23.9146551,1.95 24.212427,2.63010668 23.8248974,3.04993043 L14.9240542,12.6925106 L14.9240542,19.9984722 C14.9240542,20.2467162 14.7837988,20.473654 14.5617627,20.5846721 L10.0244373,22.8533348 C9.58866677,23.0712201 9.07594585,22.7543411 9.07594585,22.2671349 L9.07594585,12.6925106 Z M2.15359076,3.2607829 L10.2129212,11.9917242 C10.3246739,12.1127896 10.3867287,12.2715043 10.3867287,12.4362632 L10.3867287,21.2066893 L13.6132713,19.593418 L13.6132713,12.4362632 C13.6132713,12.2715043 13.6753261,12.1127896 13.7870788,11.9917242 L21.8464092,3.2607829 L2.15359076,3.2607829 Z"
					id="filter-path-1"
				/>
			</defs>
			<g id="filter" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g id="Icons/24px/Unselected/filter">
					<mask id="filter-mask-2" fill="white">
						<use xlinkHref="#filter-path-1" />
					</mask>
					<g fillRule="nonzero" />
					<g mask="url(#filter-mask-2)" fill={fillColor} fillRule="evenodd">
						<rect x="0" y="0" width="24" height="24" />
					</g>
				</g>
			</g>
		</SvgIcon>
	);
};
export default icon;
