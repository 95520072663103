import React, { useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { UserContext } from "contexts/User/UserContext";
import { SettingsContext } from "contexts/Settings/SettingsContext";
import makeStyles from "@mui/styles/makeStyles";

import CircularProgressIndicator from "atlas/components/Progress/CircularProgressIndicator";
import { useWidthUp } from "atlas/utils/useWidth";
import { FOLDER_TYPE_RECYCLE_BIN } from "utils/enums/DocumentFolderTypes";
import DocumentListHeader from "views/Documents/components/DocumentListHeader";
import DocumentTree from "views/Documents/components/DocumentTree";
import DocumentTreeDateItemColumn from "views/Documents/components/DocumentTreeDateItemColumn";
import DocumentTreeMenuItemColumn from "views/Documents/components/DocumentTreeMenuItemColumn";
import DocumentTreeSelectItemColumn from "views/Documents/components/DocumentTreeSelectItemColumn";
import DocumentTreeTextItemColumn from "views/Documents/components/DocumentTreeTextItemColumn";
import DocumentTreeTitleItemColumn from "views/Documents/components/DocumentTreeTitleItemColumn";
import {
	getFolderOptions,
	getRecycleBinOptions,
	getLiteOptions,
	getOtherOptions,
	getPolicyOnlyOptions,
} from "../Policy/utils/getMenuOptions";

const useStyles = makeStyles(() => ({
	tree: {
		"& *": {
			fontFamily: ({ inPortal }) => (inPortal ? "Segoe UI, Helvetica, Tahoma, Arial, Calibri, Verdana, sans-serif !important" : undefined),
			fontSize: ({ inPortal }) => (inPortal ? "13px" : undefined),
		},
	},
	noResults: {
		padding: "16px",
		fontWeight: "600",
		fontSize: "16px",
		lineHeight: "20px",
		textAlign: "center",
		letterSpacing: "1px",
		textTransform: "uppercase",
		paddingTop: "54px",
		borderTop: ({ inPortal }) => (!inPortal ? "1px solid rgba(224, 224, 224, 1)" : undefined),
	},
}));

const PolicyList = (props) => {
	const {
		label,
		type,
		inPortal,
		order,
		permissions,
		policies,
		handleEvent,
		handleSort,
		filterOn,
		handleCheck,
		telemetryPage,
		landingPage,
	} = props;
	const widthUpSm = useWidthUp("sm");
	const widthUpLg = useWidthUp("lg");
	const [anchor, setAnchor] = useState({});
	const { t } = useTranslation("documents");
	const classes = useStyles({ inPortal });
	const recycleBin = type === FOLDER_TYPE_RECYCLE_BIN;
	const editorUser = permissions?.canUpdate;
	const { boardAdmin } = useContext(UserContext).user;
	const { lite, communityMeetings } = useContext(SettingsContext);
	const handleSetMenuAnchor = (document, anchor) =>
		setAnchor((prev) => (!anchor || (prev.document === document && prev.anchor) ? {} : { document, anchor }));

	const newMenuOptions = (policy) => {
		if (!recycleBin) {
			if (policy.folder) {
				return getFolderOptions(t, handleEvent, policy);
			} else if (!communityMeetings) {
				return getPolicyOnlyOptions(t, handleEvent);
			} else if (!lite.enabled || (lite.enabled && boardAdmin)) {
				return getLiteOptions(t, handleEvent);
			} else {
				return getOtherOptions(t, handleEvent);
			}
		} else return getRecycleBinOptions(t, handleEvent, permissions);
	};

	const columns = [];
	if (recycleBin) {
		columns.push({
			id: "select",
			action: handleCheck,
			isSelect: true,
			width: "42px",
			component: DocumentTreeSelectItemColumn,
		});
	}
	columns.push({
		id: "title",
		label: t("list.header.name"),
		action: handleSort,
		isSort: true,
		component: DocumentTreeTitleItemColumn,
	});
	if (widthUpSm && !inPortal && editorUser) {
		if (!recycleBin) {
			columns.push({
				id: "dateModified",
				label: t("policyList.tableHeader.lastModified"),
				action: handleSort,
				isSort: true,
				width: widthUpLg ? "200px" : widthUpSm ? "100px" : undefined,
				component: DocumentTreeDateItemColumn,
			});
			columns.push({
				id: "modifiedBy",
				label: t("policyList.tableHeader.modifiedBy"),
				action: handleSort,
				isSort: true,
				width: widthUpLg ? "180px" : widthUpSm ? "100px" : undefined,
				component: DocumentTreeTextItemColumn,
			});
		} else {
			columns.push({
				id: "dateDeleted",
				label: t("list.header.dateDeleted"),
				action: handleSort,
				isSort: true,
				width: widthUpLg ? "200px" : widthUpSm ? "100px" : undefined,
				component: DocumentTreeDateItemColumn,
			});
			columns.push({
				id: "deletedBy",
				label: t("list.header.deletedBy"),
				action: handleSort,
				isSort: true,
				width: widthUpLg ? "180px" : widthUpSm ? "100px" : undefined,
				component: DocumentTreeTextItemColumn,
			});
		}
	}
	if (!inPortal && editorUser) {
		columns.push({
			id: "menu",
			width: "76px",
			options: newMenuOptions,
			menuTooltipText: t("tooltips.listMenu"),
			component: DocumentTreeMenuItemColumn,
		});
	}

	return policies?.root ? (
		<>
			<DocumentListHeader inPortal={inPortal} isPolicy order={order} documents={policies} columns={columns} />
			<div className={classes.tree}>
				{policies.root.length > 0 ? (
					<DocumentTree
						documents={policies}
						columns={columns}
						label={label}
						type={type}
						inPortal={inPortal}
						handleEvent={handleEvent}
						anchor={anchor}
						setAnchor={handleSetMenuAnchor}
						telemetryPage={telemetryPage}
					/>
				) : (
					<div className={classes.noResults}>
						{inPortal
							? t("policyList.emptyFolder")
							: recycleBin
							? t("list.noResults.description")
							: filterOn
							? t("policyList.emptyFilterResult")
							: landingPage
							? t("policyList.noSectionFound")
							: t("policyList.noPolicyFound")}
					</div>
				)}
			</div>
		</>
	) : (
		<CircularProgressIndicator />
	);
};

export default PolicyList;
