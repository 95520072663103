import { whiteColor, successColor, infoColor, errorColor, warningColor, blackColor, defaultFont } from '../shared';

const alertStyle = () => ({
	root: {
		margin: '8px 0',
		pointerEvents: 'all',
	},
	standardSuccess: {
		backgroundColor: whiteColor,
		borderLeft: `6px solid ${successColor}`,
		color: blackColor[1],
	},
	standardInfo: {
		backgroundColor: whiteColor,
		borderLeft: `6px solid ${infoColor}`,
		color: blackColor[1],
	},
	standardError: {
		backgroundColor: whiteColor,
		borderLeft: `6px solid ${errorColor}`,
		color: blackColor[1],
	},
	standardWarning: {
		backgroundColor: whiteColor,
		borderLeft: `6px solid ${warningColor}`,
		color: blackColor[1],
	},
	filledSuccess: {},
	filledInfo: {
		backgroundColor: '#E8F5FC',
		color: '#244C66',
	},
	filledWarning: {},
	filledError: {
		backgroundColor: '#FAEBEA',
		color: '#FA0909',
	},
	message: {
		...defaultFont,
		fontWeight: '400',
		fontSize: '16px',
		lineHeight: '16px',
		letterSpacing: '1px',
		display: 'flex',
		alignItems: 'center',
		'&> svg': {
			marginRight: '16px',
			position: 'relative',
			top: '2px',
		},
	},
	action: {
		...defaultFont,
		paddingBottom: '2px',
		fontSize: '16px',
		lineHeight: '16px',
		letterSpacing: '1px',
		color: blackColor[1],
		'&:hover': {
			color: blackColor[1],
			textDecoration: 'none',
		},
	},
});

export default alertStyle;
