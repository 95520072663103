import { scrollToElement2 as scrollToElement } from "utils/scrollToElement";

export const isSingleLine = (element) => {
	if (!element) return false;
	const style = window.getComputedStyle(element); // Get the computed styles
	const lineHeight = parseFloat(style.lineHeight); // Get the line-height
	const elementHeight = element.clientHeight; // Get the height of the element
	const lineCount = Math.round(elementHeight / lineHeight); // Calculate the number of lines

	return lineCount === 1;
};

export const getElement = (id) => {
	return document.getElementById(id)?.getElementsByTagName("p")[0];
};

export const stripTags = (html, exceptionTags = []) => {
	if (!html) return html;

	const regexPattern = exceptionTags.length ? new RegExp(`<\/?(?!${exceptionTags.join("|")})([^>]+)>`, "g") : /<\/?[^>]+(>|$)/g;

	return html.replace(regexPattern, " ");
};

export const handleKeyDownDND = (event) => {
	if (event.key === "Enter" || event.key === "Space") {
		// Prevent drag from being initiated via Enter key
		event.preventDefault();
		event.stopPropagation();
		event.target.parentElement?.click();
	}
};

const handleUpDownArrowKeys = (listItem, moveUp) => {
	const list = listItem.closest("ul");
	const focusableChildren = list?.querySelectorAll("li[tabindex]");
	if (focusableChildren && focusableChildren.length > 0) {
		const currentFocusIndex = Array.from(focusableChildren).findIndex((element) => element === listItem);
		let nextFocusIndex = moveUp ? currentFocusIndex - 1 : currentFocusIndex + 1;
		if (nextFocusIndex >= 0 && nextFocusIndex < focusableChildren.length) {
			// Focus the next focusable list item
			var nextFocusItem = focusableChildren[nextFocusIndex];
			nextFocusItem.focus({ focusVisible: true });
			scrollToElement(nextFocusItem, undefined, -50);
		}
	}
};

export const handleListItemKeyDown = (e, setArrowNavigation) => {
	if (e.key === "ArrowDown" || e.key === "ArrowUp") {
		handleUpDownArrowKeys(e.target, e.key === "ArrowUp");

		e.preventDefault();
		e.stopPropagation();

		setArrowNavigation(true);
	} else if (e.key === "ArrowRight" || e.key === "ArrowLeft") {
		const focusableChildren = e.target.querySelectorAll("[tabindex]");
		if (focusableChildren.length > 0) {
			const focusIndex = e.key === "ArrowRight" ? 0 : focusableChildren.length - 1;
			focusableChildren[focusIndex].focus({ focusVisible: true });
		}
	} else if (e.key === " " || e.key === "Enter") {
		e.target.click();

		e.preventDefault();
		e.stopPropagation();
	} else {
		setArrowNavigation(false);
	}
};

export const handleMoveButtonKeyDown = (e) => {
	if (e.key === "ArrowDown" || e.key === "ArrowUp") {
		handleUpDownArrowKeys(e.target.closest("li"), e.key === "ArrowUp");

		e.preventDefault();
		e.stopPropagation();
	} else if (e.key === "ArrowRight" || e.key === "ArrowLeft") {
		const listItem = e.target.parentNode.closest("[tabindex]");
		const focusableChildren = listItem?.querySelectorAll("[tabindex]");
		if (focusableChildren && focusableChildren.length > 0) {
			const currentFocusIndex = Array.from(focusableChildren).findIndex((element) => element === e.target);
			let nextFocusIndex = e.key === "ArrowRight" ? currentFocusIndex + 1 : currentFocusIndex - 1;
			if (nextFocusIndex >= focusableChildren.length || nextFocusIndex < 0) {
				// Focus the parent element if the next focus index is out of bounds
				listItem.focus({ focusVisible: true });
			} else {
				// Focus the next focusable child element
				focusableChildren[nextFocusIndex].focus({ focusVisible: true });
			}
		}

		e.preventDefault();
		e.stopPropagation();
	}
};
