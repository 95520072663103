import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import Typography from "@mui/material/Typography";

import ComponentContainer from "atlas/components/ComponentContainer/ComponentContainer";
import { useWidthUp } from "atlas/utils/useWidth";
import SaveCancelButtons from "./SaveCancelButtons";
import StyledPhoneInput from "../../../components/PhoneInput/StyledPhoneInput";

const PhoneUpdate = (props) => {
	const { data, handleCancel, handleSave, handleChange, handleBlur } = props;
	const widthUpSm = useWidthUp("sm");
	const { editing = {}, errors = {} } = data;

	const { t } = useTranslation("userProfile");

	const [phoneCountry, setPhoneCountry] = useState(null);
	const [phoneValid, setPhoneValid] = useState(true);

	const phoneRef = useRef(null);

	const handleKeyPress = (event) => {
		if (event.key === "Enter") {
			handleBlur();
			handleSave();
		}
	};

	const handleValidatePhoneNumber = (isValid) => {
		setPhoneValid(isValid);
	};

	useEffect(() => {
		if (phoneRef !== null) {
			setPhoneCountry(phoneRef.current.state.selectedCountry);
		}
	}, [phoneRef, editing.phoneNumber]);

	return (
		<ComponentContainer maxWidth={widthUpSm ? "340px" : null}>
			<Typography variant="h5" color="textSecondary">
				{t("updatePhone")}
			</Typography>
			<Typography variant="h5" color="textSecondary">
				{t("updatePhoneSubMsg")}
			</Typography>

			<StyledPhoneInput
				id="phoneNumber"
				ref={phoneRef}
				label={t("phoneNumberLabel")}
				phoneValue={editing.phoneNumber || ""}
				onChange={(e) => {
					handleChange(e.target.value, e.target.formattedValue);
				}}
				onKeyPress={handleKeyPress}
				phoneCountry={phoneCountry}
				dataCy="phone-number"
				validateCountry
				onValidate={handleValidatePhoneNumber}
				errorMsg={t("userProfile:errors.invalidNumber")}
			/>

			<SaveCancelButtons
				save={handleSave}
				saveEnabled={phoneValid}
				disabledTooltip={errors.phoneNumber ? t("errors.validPhoneNumber") : ""}
				cancel={handleCancel}
				saveLabel={t("app:buttons.save")}
				cancelLabel={t("app:buttons.cancel")}
			/>
		</ComponentContainer>
	);
};

export default PhoneUpdate;
