import typographyStyle from './typographyStyle';
import { primaryColor } from '../shared';

const leftNavBarStyle = () => ({
	container: {
		height: '72px',
		display: 'flex',
		flexShrink: '0',
	},
	hamburger: {
		justifyContent: 'center',
		alignItems: 'center',
		cursor: 'pointer',
		'&:hover': {
			backgroundColor: 'rgba(0, 0, 0, 0.04)',
			'& .MuiIconButton-root:hover': {
				backgroundColor: 'transparent',
			},
		},
	},
	organization: {
		maxWidth: '260px',
		textOverflow: 'ellipsis',
		overflow: 'hidden',
	},
	organizationLogo: {
		maxWidth: '260px',
		maxHeight: '48px',
	},
	logo: {
		justifyContent: 'flex-start',
		alignItems: 'center',
		marginLeft: '20px',
	},
	customerLogo: {
		maxHeight: '48px',
	},
	nav: {
		padding: '0',
		minHeight: 'calc(100% - 132px)',
		flexShrink: '0',
		'& a': {
			textDecoration: 'none !important',
		},
	},
	item: {
		paddingTop: '12px',
		paddingBottom: '12px',
		borderLeft: '6px solid transparent',
		paddingLeft: '14px',
		paddingRight: '14px',
		height: '56px',
	},
	itemIcon: {
		minWidth: '24px',
		marginRight: '12px',
	},
	submenuIcon: {
		marginLeft: '-8px',
	},
	submenuIconOpen: {
		marginLeft: '16px',
	},
	menu: {
		minWidth: '160px',
		...typographyStyle.menu,
		'& ul': {
			padding: '0',
			overflowY: "auto",
			maxHeight: "600px",
			marginTop: "10px"
		},
		'& li': {
			height: '50px',
			padding: '10px 12px',
		},
	},
	text: {
		flexGrow: '0',
	},
	externalLink: {
		height: '18px',
		marginLeft: '16px',
	},
	selected: {
		borderLeft: `6px solid ${primaryColor[0]}`,
	},
	bottomLogoContainer: {
		display: 'flex',
		height: '56px',
		justifyContent: 'flex-start',
		alignItems: 'center',
		marginLeft: '20px',
		flexShrink: '0',
	},
	divider: {
		height: '2px',
	},
});

export default leftNavBarStyle;
