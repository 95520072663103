import React, { useContext, useState, useEffect, useRef } from "react";
import { useStore } from "react-redux";
import { useTranslation } from "react-i18next";
import { renderToStaticMarkup } from "react-dom/server";

import MultirootEditor from "components/Editor/plugins/MultirootEditor/MultirootEditor";
import EssentialsPlugin from "@ckeditor/ckeditor5-essentials/src/essentials";
import AlignmentPlugin from "@ckeditor/ckeditor5-alignment/src/alignment";
import AutoformatPlugin from "@ckeditor/ckeditor5-autoformat/src/autoformat";
import BoldPlugin from "@ckeditor/ckeditor5-basic-styles/src/bold";
import EasyImagePlugin from "@ckeditor/ckeditor5-easy-image/src/easyimage";
import FontPlugin from "@ckeditor/ckeditor5-font/src/font";
import ItalicPlugin from "@ckeditor/ckeditor5-basic-styles/src/italic";
import HeadingPlugin from "@ckeditor/ckeditor5-heading/src/heading";
import ImagePlugin from "@ckeditor/ckeditor5-image/src/image";
import ImageCaptionPlugin from "@ckeditor/ckeditor5-image/src/imagecaption";
import ImageStylePlugin from "@ckeditor/ckeditor5-image/src/imagestyle";
import ImageToolbarPlugin from "@ckeditor/ckeditor5-image/src/imagetoolbar";
import ImageUploadPlugin from "@ckeditor/ckeditor5-image/src/imageupload";
import IndentPlugin from "@ckeditor/ckeditor5-indent/src/indent";
import IndentBlockPlugin from "@ckeditor/ckeditor5-indent/src/indentblock";
import LinkPlugin from "@ckeditor/ckeditor5-link/src/link";
import ListPlugin from "@ckeditor/ckeditor5-list/src/list";
import MediaEmbedPlugin from "@ckeditor/ckeditor5-media-embed/src/mediaembed";
import ParagraphPlugin from "@ckeditor/ckeditor5-paragraph/src/paragraph";
import PageBreak from "@ckeditor/ckeditor5-page-break/src/pagebreak";
import PasteFromOfficePlugin from "@ckeditor/ckeditor5-paste-from-office/src/pastefromoffice";
import TablePlugin from "@ckeditor/ckeditor5-table/src/table";
import TableToolbarPlugin from "@ckeditor/ckeditor5-table/src/tabletoolbar";
import TableProperties from "@ckeditor/ckeditor5-table/src/tableproperties";
import TableCellProperties from "@ckeditor/ckeditor5-table/src/tablecellproperties";
import { TableColumnResize } from "@ckeditor/ckeditor5-table";
import TwoStepCaretMovement from "@ckeditor/ckeditor5-typing/src/twostepcaretmovement";
import CloudServicesPlugin from "@ckeditor/ckeditor5-cloud-services/src/cloudservices";
import Clipboard from "@ckeditor/ckeditor5-clipboard/src/clipboard";
import AutoSave from "@ckeditor/ckeditor5-autosave/src/autosave";

import AddPolicyPlugin from "components/Editor/plugins/AddPolicy/AddPolicy";
import AddGoalsPlugin from "components/Editor/plugins/AddGoals/AddGoalsInline";
import AttachFilePlugin from "components/Editor/plugins/AttachFile/AttachFile";
import InlineFilePlugin from "components/Editor/plugins/InlineFile/inlineFile";
import InlineFileMOPlugin from "components/Editor/plugins/InlineFile/memberOnly/inlineFileMO";
import MapleUploadAdapter from "components/Editor/MapleUploadAdapter";
import InsertPlaceholder from "components/Editor/plugins/Placeholders/placeholders";
import InsertRollCall from "components/Editor/plugins/RollCall/rollCall";

import Parser, { domToReact } from "html-react-parser";

import { debounce } from "lodash";
import compact from "lodash/fp/compact";
import findIndex from "lodash/fp/findIndex";
import forEach from "lodash/fp/forEach";

import { v4 as uuid } from "uuid";
import { SettingsContext } from "contexts/Settings/SettingsContext";
import { allowedEditorExtensions } from "utils/allowedExtensions";

const toolbars = {
	full: {
		items: [
			"undo",
			"redo",
			"heading",
			"bold",
			"italic",
			"outdent",
			"indent",
			"bulletedList",
			"numberedList",
			"link",
			"inlineFile",
			"inlineFileMO",
			"addPolicy",
			"addGoals",
			"insertTable",
			"alignment",
			"imageUpload",
			"insertRollCall",
			"pageBreak",
			"insertMeetingPlaceHolder",
		],
		shouldNotGroupWhenFull: false,
	},
	simple: { items: ["link", "inlineFile", "addPolicy", "addGoals", "undo", "redo", "insertMeetingPlaceHolder"] },
	simpleMO: { items: ["link", "inlineFileMO", "addPolicy", "addGoals", "undo", "redo", "insertMeetingPlaceHolder"] },
	simpleNoAttachments: { items: ["link", "undo", "redo"] },
	scratchpad: { items: ["bold", "italic", "outdent", "indent", "bulletedList", "numberedList", "link", "insertTable", "undo", "redo"] },
	limited: {
		items: [
			"bold",
			"italic",
			"outdent",
			"indent",
			"bulletedList",
			"numberedList",
			"link",
			"inlineFile",
			"addPolicy",
			"addGoals",
			"insertTable",
			"undo",
			"redo",
		],
	},
	goals: {
		items: [
			"bold",
			"italic",
			"outdent",
			"indent",
			"bulletedList",
			"numberedList",
			"link",
			"inlineFile",
			"addPolicy",
			"insertTable",
			"undo",
			"redo",
		],
	},
	itemText: {
		items: [
			"bold",
			"italic",
			"outdent",
			"indent",
			"bulletedList",
			"numberedList",
			"link",
			"inlineFile",
			"addPolicy",
			"addGoals",
			"insertTable",
			"pageBreak",
			"undo",
			"redo",
		],
	},
	itemTextMO: {
		items: [
			"bold",
			"italic",
			"outdent",
			"indent",
			"bulletedList",
			"numberedList",
			"link",
			"inlineFileMO",
			"addPolicy",
			"addGoals",
			"insertTable",
			"pageBreak",
			"undo",
			"redo",
		],
	},
	itemTextNoAttachments: {
		items: ["bold", "italic", "outdent", "indent", "bulletedList", "numberedList", "link", "insertTable", "pageBreak", "undo", "redo"],
	},
	minutesItemText: {
		items: [
			"bold",
			"italic",
			"outdent",
			"indent",
			"bulletedList",
			"numberedList",
			"link",
			"inlineFile",
			"addPolicy",
			"addGoals",
			"insertTable",
			"insertRollCall",
			"undo",
			"redo",
		],
	},
	minutesItemTextMO: {
		items: [
			"bold",
			"italic",
			"outdent",
			"indent",
			"bulletedList",
			"numberedList",
			"link",
			"inlineFileMO",
			"addPolicy",
			"addGoals",
			"insertTable",
			"insertRollCall",
			"undo",
			"redo",
		],
	},
	agendaItemText: {
		items: [
			"bold",
			"italic",
			"outdent",
			"indent",
			"bulletedList",
			"numberedList",
			"link",
			"inlineFile",
			"addPolicy",
			"addGoals",
			"insertTable",
			"pageBreak",
			"undo",
			"redo",
			"insertMeetingPlaceHolder",
		],
	},
	agendaItemTextMO: {
		items: [
			"bold",
			"italic",
			"outdent",
			"indent",
			"bulletedList",
			"numberedList",
			"link",
			"inlineFileMO",
			"addPolicy",
			"addGoals",
			"insertTable",
			"pageBreak",
			"undo",
			"redo",
			"insertMeetingPlaceHolder",
		],
	},
	notes: {
		items: ["bold", "italic", "outdent", "indent", "bulletedList", "numberedList", "link", "inlineFile", "addPolicy", "undo", "redo"],
	},
	minutesNotes: {
		items: [
			"bold",
			"italic",
			"outdent",
			"indent",
			"bulletedList",
			"numberedList",
			"link",
			"inlineFile",
			"addPolicy",
			"insertTable",
			"insertRollCall",
			"undo",
			"redo",
		],
	},
	header: {
		items: [
			"heading",
			"bold",
			"italic",
			"outdent",
			"indent",
			"bulletedList",
			"numberedList",
			"link",
			"insertTable",
			"alignment",
			"imageUpload",
			"insertMeetingPlaceHolder",
			"pageBreak",
			"undo",
			"redo",
		],
	},
	minutesHeader: {
		items: [
			"heading",
			"bold",
			"italic",
			"outdent",
			"indent",
			"bulletedList",
			"numberedList",
			"link",
			"insertTable",
			"alignment",
			"imageUpload",
			"insertRollCall",
			"insertMeetingPlaceHolder",
			"pageBreak",
			"undo",
			"redo",
		],
	},
	footer: {
		items: [
			"heading",
			"bold",
			"italic",
			"outdent",
			"indent",
			"bulletedList",
			"numberedList",
			"link",
			"insertTable",
			"alignment",
			"imageUpload",
			"insertMeetingPlaceHolder",
			"undo",
			"redo",
		],
	},
	notificationsSettings: {
		items: [
			"bold",
			"italic",
			"outdent",
			"indent",
			"bulletedList",
			"numberedList",
			"link",
			"insertTable",
			"|",
			"insertPlaceholder",
			"undo",
			"redo",
		],
	},
	generalSettings: {
		items: ["undo", "redo", "bold", "italic", "outdent", "indent", "bulletedList", "numberedList"],
	},
};

const SingletonEditor = (props) => {
	const {
		itemGuid,
		fields,
		fieldsRefs,
		fieldRefsSet,
		editorToolbarRef,
		// guid,
		meetingId,
		// name,
		deleteFile,
		invalidFileExtension,
		features,
		queueFileUploads,
		addPolicy,
		addGoals,
		fileExtensions = allowedEditorExtensions,
		willBeAnAttachment,
		// focus,
		// disabled = false,
		autoSave = 500,
		onFieldChange,
		onEditorInitialized,
		onFocusChange,
		onUndoRedoRoot,
		onDeletedFieldIndex,
		deletedFieldIdx,
		tableInlineFileUpload = false,
	} = props;
	const { t } = useTranslation();

	const { ckEditorArguments, lite } = useContext(SettingsContext);
	const [edFields, _setEdFields] = useState(
		fields
			? fields.map((fld) => ({
					guid: fld.guid,
					name: fld.name,
					label: fld.label,
					toolbar: fld.toolbar,
					placeholder: fld.placeholder,
					content: fld.content,
					isMemberOnly: fld.isMemberOnly || false,
				}))
			: [],
	);
	// const [edFieldContents, setEdFieldContents] = useState([]);
	const [firstInit, setFirstInit] = useState(true);
	const [currentFld, setCurrentFld] = useState(null);
	const edFieldsRef = useRef(edFields);
	const editorContainer = useRef(null);
	const editorReference = useRef(null);
	const parsedFieldRef = useRef([]);
	const store = useStore();

	const setEdFields = (newEdFields) => {
		edFieldsRef.current = newEdFields;
		_setEdFields(newEdFields);
	};

	const handleFieldChange = debounce((editor) => {
		onFieldChange(editor);
	}, 100);

	const validateLinkHref = (href) => {
		if (href && !href.toLowerCase().startsWith("http") && !href.toLowerCase().startsWith("mailto:")) {
			if (href.substr(0, 2) === "//") {
				href = `http:${href}`;
			} else if (href.substr(0, 1) === "/") {
				href = `http:/${href}`;
			} else {
				href = `http://${href}`;
			}
		}
		return href;
	};

	const addFileUpload = (field, fileUploads) => {
		const { guid } = field;
		const fileData = new FormData();
		let closed = false;
		forEach((fileUpload) => {
			fileData.append(fileUpload.guid, fileUpload.file);
			if (findIndex((feature) => feature.id === "MOA", fileUpload.features) !== -1) {
				closed = true;
			}
		}, fileUploads);

		fileData.append(
			"data",
			JSON.stringify({
				meetingId,
				itemGuid: guid,
				attach: true,
				closed,
				willBeAnAttachment,
			}),
		);

		queueFileUploads(guid, fileUploads, fileData);
	};

	function forceDisable(evt) {
		evt.return = false;
		evt.stop();
	}

	function enableCommand(cmd) {
		cmd.off("set:isEnabled", forceDisable);
		cmd.isEnabled = true;
		cmd.refresh();
	}

	function disableCommand(cmd) {
		cmd.on("set:isEnabled", forceDisable, { priority: "highest" });
		cmd.isEnabled = false;
		cmd.refresh();
	}

	const setLinkCommands = (isMemberOnly, editor, fieldName) => {
		const linkCommand = editor.commands.get("inlineFileLink");
		const unlinkCommand = editor.commands.get("inlineFileUnlink");
		const linkCommandMO = editor.commands.get("inlineFileLinkMO");
		const unlinkCommandMO = editor.commands.get("inlineFileUnlinkMO");
		if (fieldName === "toc-header" || fieldName === "toc-footer") {
			disableCommand(linkCommand);
			disableCommand(unlinkCommand);
			disableCommand(linkCommandMO);
			disableCommand(unlinkCommandMO);
		} else if (isMemberOnly) {
			disableCommand(linkCommand);
			disableCommand(unlinkCommand);
			enableCommand(linkCommandMO);
			enableCommand(unlinkCommandMO);
		} else {
			disableCommand(linkCommandMO);
			disableCommand(unlinkCommandMO);
			enableCommand(linkCommand);
			enableCommand(unlinkCommand);
		}
	};

	const setToolbar = (fieldName, editor) => {
		const useAddPolicy = typeof addPolicy === "function";

		let visibility = "visible";
		if (fieldName === null) {
			//visibility = "hidden";
		} else {
			const idx = edFieldsRef.current.findIndex((field) => field.name === fieldName);
			if (idx < 0) {
				//visibility = "hidden";
			} else {
				const fld = edFieldsRef.current[idx];
				const tb = toolbars[fld.toolbar];
				const ftb = { items: toolbars["full"].items.filter((item) => useAddPolicy || item !== "addPolicy") }; // Ensure that addPolicy is excluded if it is not in use
				//ck-inlinefile-form
				setCurrentFld(fld);
				if (features) {
					setLinkCommands(fld.isMemberOnly, editor, fieldName);
				}

				for (let i = 0; i < ftb.items.length; i++) {
					const itemName = ftb.items[i];
					const visible = tb.items.includes(itemName) && (useAddPolicy || itemName !== "addPolicy");
					const editorItem = editor.ui.view.toolbar.items._items[i];

					if (editorItem) {
						if (editorItem.buttonView) {
							if (itemName === "inlineFileMO" || itemName === "inlineFile") {
								editorItem.buttonView.isEnabled = visible;
								editorItem.buttonView.isToggleable = visible;
							}
							editorItem.buttonView.isVisible = visible;
						} else {
							if (itemName === "inlineFileMO" || itemName === "inlineFile") {
								editorItem.isEnabled = visible;
								editorItem.isToggleable = visible;
							}
							editorItem.isVisible = visible;
						}
					}
				}
			}
		}
		editorToolbarRef.current.style.visibility = visibility;
	};

	const setFieldContent = (editor, editorField) => {
		const parserOptions = {
			replace: (node) => {
				if (!node.attribs) return;
				if (node.name === "a" && node.attribs.href) {
					if (node.attribs.class && node.attribs.class.indexOf("inlineFile") !== -1) {
						// Link to the new document details
						if (node.attribs.href.indexOf("/document/") === 0) {
							node.attribs.href = `/home${node.attribs.href}`;
						}
					} else if (node.attribs.class && node.attribs.class.indexOf("inlineLink") !== -1) {
						if (node.attribs.href.indexOf("/goal/") === 0) {
							node.attribs.href = `/home${node.attribs.href}`;
						}
					} else {
						node.attribs.href = validateLinkHref(node.attribs.href);
					}
				} else if (["img", "br"].includes(node.name) && node.attribs.style) {
					node.attribs.style = "";
				}
				if (editorField.guid !== "toc-header" && ["h1", "h2"].indexOf(node.name) >= 0) {
					return <p>{domToReact(node.children, parserOptions)}</p>;
				}
				if (
					node.attribs.style === "margin-top: 0; margin-bottom: 0;" ||
					(node.attribs.style && node.attribs.style.indexOf("font-size") >= 0)
				) {
					// eslint-disable-next-line consistent-return
					return <node.name>{domToReact(node.children, parserOptions)}</node.name>;
				}
			},
		};
		const edContent = Parser(editorField.content, parserOptions);
		editorField.content = renderToStaticMarkup(edContent);
		editor.setFieldData(editorField.name, editorField.content);
	};

	// const updateEdFieldContents = (newFields) => {
	// 	const editor = window.editor;
	// 	const newFieldContents = [];
	// 	const fieldData = editor.getFieldData();
	// 	for (let idx = 0; idx < newFields.length; idx++) {
	// 		const fdIdx = fieldData.findIndex((fd) => fd.fieldName === newFields[idx].name);
	// 		const fdContent = fdIdx < 0 ? "" : fieldData[fdIdx].fieldData;
	// 		newFieldContents.push({ name: newFields[idx].name, content: fdContent });
	// 	}
	// 	setEdFieldContents(newFieldContents);
	// };

	const handleRootUndoRedo = (evt, isUndo) => {
		// if stack to undo is creation of a root - delete root and vice versa
		const stack = isUndo ? editor.commands.get("redo")._stack : editor.commands.get("undo")._stack;
		const item = stack[stack.length - 1];
		const idx = item.batch.operations.findIndex((op) => op.key === "data-added" || op.key === "data-deleted");
		if (idx > -1) {
			const op = item.batch.operations[idx];
			const { key, newValue, oldValue } = op;
			const name = isUndo ? oldValue : newValue;
			onUndoRedoRoot(name, isUndo, key === "data-deleted");
		}
	};

	const initEditor = () => {
		setFirstInit(false);
		let updatedEditorContentHtml; // Check for break-after style attribute and change it to page-break-after so ckeditor can read it properly
		if (editorContainer.current && editorContainer.current.innerHTML.indexOf("break-after: page") !== -1) {
			updatedEditorContentHtml = editorContainer.current.innerHTML.replace("break-after: page", "page-break-after: always");
		}
		//
		// Setup field tables
		//
		const contentFields = {};
		const placeholder = {};
		for (let i = 0; i < edFieldsRef.current.length; i++) {
			contentFields[edFieldsRef.current[i].name] = fieldsRefs.current[i];
			if (edFieldsRef.current[i].placeholder) {
				placeholder[edFieldsRef.current[i].name] = edFieldsRef.current[i].placeholder;
			}
		}
		//
		// Setup base inline File Configuration
		//

		const inlineFileConfig = {
			parentId: null,
			translations: t("editor.inlineFile"),
			anchorTitle: t("meetings:inlineFile.features.MOA.anchorTitlePublic"),
			features: features
				? [
						{
							id: features.id,
							label: features.label,
							className: features.className,
							defaultValue: false,
							disabledValue: false,
							isEnabled: true,
							anchorTitle: features.anchorTitle,
							tooltipDisabledOn: features.tooltipDisabledOn,
						},
					]
				: [],
			urlBase: "/home/document",
			allowedFileExtensions: fileExtensions,
			functions: {
				onAddFiles: (editor, parentId, files, rootName) => {
					const field = edFieldsRef.current.find((element) => element.name === rootName);
					if (field) {
						editor.onItemChange(editor);
						addFileUpload(field, files);
					} else if (tableInlineFileUpload) {
						const activeId = store.getState().agendaBuilderReducer.active.id;
						const activeField = edFieldsRef.current && edFieldsRef.current.find((element) => element.name === activeId);
						if (activeField) {
							editor.onItemChange(editor);
							addFileUpload(activeField, files);
						}
					}
				},
				onDeleteFile: (editor, parentId, fileGuid, rootName) => {
					const field = edFieldsRef.current.find((element) => element.name === rootName);
					editor.onItemChange(editor);
					if (deleteFile) {
						deleteFile(field, fileGuid);
					}
				},
				onInvalidExtension: (editor, parentId, fileNames, rootName) => {
					const field = edFieldsRef.current.find((element) => element.name === rootName);
					if (invalidFileExtension) {
						invalidFileExtension(field, fileNames);
					}
				},
			},
		};

		const useAddPolicy = typeof addPolicy === "function";
		const addPolicyConfig = {
			urlBase: "/home/document",
			itemGuid,
			features: features
				? [
						{
							id: features.id,
							label: features.label,
							className: features.className,
							defaultValue: false,
							disabledValue: false,
							isEnabled: true,
							anchorTitle: features.anchorTitle,
							tooltipDisabledOn: features.tooltipDisabledOn,
						},
					]
				: [],
			functions: {
				onOpenDialog: addPolicy,
			},
		};
		const addGoalsConfig = {
			urlBase: "/home/goals/view",
			itemGuid,
			features: features
				? [
						{
							id: features.id,
							label: features.label,
							className: features.className,
							defaultValue: false,
							disabledValue: false,
							isEnabled: true,
							anchorTitle: features.anchorTitle,
							tooltipDisabledOn: features.tooltipDisabledOn,
						},
					]
				: [],
			functions: {
				onOpenDialog: addGoals,
			},
		};
		//
		// Setup Base Editor Configuration
		//
		const editorConfig = {
			updateSourceElementOnDestroy: true,
			custom: ckEditorArguments,
			extraPlugins: [MapleUploadAdapter],
			mediaEmbed: {
				removeProviders: ["instagram", "twitter", "googleMaps", "flickr", "facebook"],
			},
			image: {
				toolbar: ["imageTextAlternative"],
			},
			language: "en",
			licenseKey: ckEditorArguments.key,
			plugins: compact([
				Clipboard,
				EssentialsPlugin,
				AlignmentPlugin,
				AutoformatPlugin,
				useAddPolicy && AddPolicyPlugin,
				!lite.enabled && AddGoalsPlugin,
				AttachFilePlugin,
				InlineFilePlugin,
				InlineFileMOPlugin,
				InsertPlaceholder,
				InsertRollCall,
				BoldPlugin,
				EasyImagePlugin,
				FontPlugin,
				HeadingPlugin,
				ItalicPlugin,
				ImagePlugin,
				ImageCaptionPlugin,
				ImageStylePlugin,
				ImageToolbarPlugin,
				ImageUploadPlugin,
				IndentPlugin,
				IndentBlockPlugin,
				LinkPlugin,
				ListPlugin,
				MediaEmbedPlugin,
				ParagraphPlugin,
				PageBreak,
				PasteFromOfficePlugin,
				TablePlugin,
				TableToolbarPlugin,
				TableProperties,
				TableCellProperties,
				TableColumnResize,
				TwoStepCaretMovement,
				CloudServicesPlugin,
			]),
			link: {
				defaultProtocol: "https://",
			},
			indentBlock: {
				offset: 1,
				unit: "em",
			},
			table: {
				tableCellProperties: {
					defaultProperties: {
						borderStyle: "none",
					},
				},
				contentToolbar: ["tableColumn", "tableRow", "mergeTableCells", "tableProperties", "tableCellProperties"],
			},
			heading: {
				options: [
					{ model: "paragraph", title: "Paragraph", class: "ck-heading_paragraph" },
					{ model: "heading1", view: "h1", title: "Heading 1", class: "ck-heading_heading1" },
					{ model: "heading2", view: "h2", title: "Heading 2", class: "ck-heading_heading2" },
				],
			},
			fontSize: {
				options: [9, 11, 13, "default", 17, 19, 21],
				supportAllValues: true,
			},
			toolbar: toolbars["full"],
			inlineFile: inlineFileConfig,
			addPolicy: addPolicyConfig,
			addGoals: addGoalsConfig,
			placeholder: placeholder,
		};
		//
		// Adjust editor configuration based on props
		//
		if (autoSave > 0 && typeof onFieldChange === "function") {
			editorConfig.plugins.push(AutoSave);
			editorConfig.autosave = {
				waitingTime: autoSave,
				save(editor) {
					onFieldChange(editor);
				},
			};
		}

		//
		// Create the editor
		//
		MultirootEditor.create(contentFields, editorConfig)
			.then((editor) => {
				// setup singleton editor
				window.editor = editor;
				editorToolbarRef.current.appendChild(editor.ui.view.toolbar.element);

				for (let i = 0; i < edFieldsRef.current.length; i++) {
					setFieldContent(editor, edFieldsRef.current[i]);
				}
				setToolbar(edFieldsRef.current[0].name, editor);

				// This sets tab shortcut to get into ANY sub bar that ck-editor might throw at us. Then using tabs and/or arrow keys and enter will change and press buttons
				editor.keystrokes.set("tab", (data, stop) => {
					const elem = document.getElementsByClassName(`ck-balloon-panel_visible`);
					if (elem.length > 0) {
						elem[0].getElementsByClassName("ck-balloon-rotator__content")[0].getElementsByTagName("button")[0].focus();
						stop(); // Works like data.preventDefault() + evt.stop()
					}
				});

				if (autoSave === 0 && typeof onFieldChange === "function") {
					editor.model.document.on("change:data", (event, batch) => {
						handleFieldChange(editor);
					});
				}

				editor.ui.focusTracker.on("change:focusedElement", (evt, name, focusedElement) => {
					let fieldName = null;
					if (focusedElement) {
						fieldName = focusedElement.getAttribute("data-fieldname");
					}
					setToolbar(fieldName, editor);
					if (typeof onFocusChange === "function") {
						onFocusChange(fieldName, focusedElement, editor);
					}
				});

				editor.guid = uuid();

				editor.onItemChange = onFieldChange;

				editor.commands.get("tableCellBorderStyle").on("change:value", (evt, propertyName, newValue, oldValue) => {
					editor.execute("tableCellBorderWidth", {
						value: newValue ? "1px" : "",
					});

					editor.execute("tableCellBorderColor", {
						value: newValue ? "#000000" : "",
					});
				});

				editor.commands.get("undo").on("execute", (evt) => {
					handleRootUndoRedo(evt, true);
				});

				editor.commands.get("redo").on("execute", (evt) => {
					handleRootUndoRedo(evt, false);
				});

				const clipboardPlugin = editor.plugins.get("ClipboardPipeline");
				if (clipboardPlugin != null) {
					clipboardPlugin.on("inputTransformation", (evt, data) => {
						let content = editor.data.processor.toData(data.content);
						content = content.replace(/font-size:[^;']*(;)?/gi, "").replace(/font-family:[^;']*(;)?/gi, "");
						data.content = editor.data.htmlProcessor.toView(content);
					});
				}

				editorReference.current = editor;

				// Prevent hidden file input accessibility errors
				forEach((element) => {
					if (element && element.tagName === "INPUT" && element.getAttribute("type") === "file") {
						element.setAttribute("title", t("app:editor.tooltips.selectFile"));
						if (element.previousSibling && element.previousSibling.tagName === "BUTTON") {
							const labeledBy = element.previousSibling.getAttribute("aria-labelledby");
							if (labeledBy) {
								element.setAttribute("aria-labelledby", labeledBy);
							}
						}
					}
				}, document.getElementsByClassName("ck-hidden"));

				// set up data and pointer to this editor, for undo functions
				if (updatedEditorContentHtml) {
					editor.setData(updatedEditorContentHtml);
				}

				// if (focus) {
				// 	editor.editing.view.focus();
				// }

				if (typeof onEditorInitialized === "function") {
					onEditorInitialized(editor);
				}
			})
			.catch((err) => {
				console.log(err);
				console.error(err.stack);
			});
	};

	useEffect(() => {
		if (!edFields || edFields.length === 0) {
			const newFields = [];
			// const newFieldContents = [];
			fields.forEach((fld, idx) => {
				newFields.push({
					guid: fld.guid,
					name: fld.name,
					label: fld.label,
					toolbar: fld.toolbar,
					placeholder: fld.placeholder,
					content: fld.content,
					isMemberOnly: fld.isMemberOnly || false,
				});
			});
			setEdFields(newFields);
		}
	}, []);

	useEffect(() => {
		// fieldRefsSet is used to re-trigger this if fieldRefs get updated or have not yet been fully set
		if (edFields && edFields.length > 0 && firstInit) {
			initEditor();
		}
		if (edFields && edFields.length > 0 && !firstInit) {
			const editor = window.editor;
			for (let idx = 0; idx < edFields.length; idx++) {
				const fld = edFields[idx];
				const element = fieldsRefs.current[idx];
				if (!editor.hasRoot(fld.name) && element) {
					editor.addRoot(fld.name, element);
					setFieldContent(editor, fld);
				} else if (fld.refresh) {
					// Refresh the editor content based on the content of the item
					setFieldContent(editor, fld);
					fld.refresh = false;
				}
			}
		}
	}, [edFields, fieldsRefs, fieldRefsSet]);

	useEffect(() => {
		if (deletedFieldIdx > -1) {
			const fldName = edFields[deletedFieldIdx].name;
			const root = window.editor.model.document.getRoot(fldName);
			window.editor.model.change((writer) => {
				writer.setAttribute("data-deleted", fldName, root);
			});
			onDeletedFieldIndex();
		}
	}, [deletedFieldIdx]);

	useEffect(() => {
		if (!firstInit) {
			// updateEdFieldContents(fields);
			setEdFields(fields);
		}
	}, [fields]);

	useEffect(() => {
		return () => {
			if (window.editor) {
				// Destroy the editor once we are finished with it
				window.editor.destroy();
				delete window.editor;
			}
		};
	}, []);

	return <></>;
};

export default SingletonEditor;
