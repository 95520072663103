import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Link } from '@mui/material';
import { primaryColor } from '../../assets/jss/shared';

const useStyles = makeStyles({
	link: {
		color: `${primaryColor[1]}`,
		'&:hover': {
			color: '#294772',
		},
	},
});

const StyledLink = (props) => {
	const { children, href, underline = 'hover', ...otherProps } = props;
	const classes = useStyles();
	return (
		<Link href={href} underline={underline} className={classes.link} {...otherProps}>
			{children}
		</Link>
	);
};

export default StyledLink;
