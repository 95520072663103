import React, { useState, useEffect, useRef } from "react";

import OutlinedInput from "atlas/components/FormControls/OutlinedInput";

import {
	timeStampToString,
	timeStampStringToSeconds,
	setTimeStampInputSelection,
	timeStampInputKeyUp,
	timeStampInputKeyDown,
} from "utils/videoTimeStamping";

const TimeStampInput = (props) => {
	const { itemGuid, timeStamp, timeStampPrefix, onBlur, disabled, className } = props;
	const timestampInputRef = useRef(null);

	const [currentTimeStamp, setCurrentTimeStamp] = useState(timeStamp);
	const [currentTimeStampString, setCurrentTimeStampString] = useState("--h--m--s");
	const [timestampCursorPosition, setTimestampCursorPosition] = useState(0);

	const handleFieldChange = (event, ref) => {
		const { target } = event;
		const timeStampStringValue = target.value;

		const timestampinput = ref.current;
		const selectionStart = timestampinput.value.length - timestampinput.selectionStart;
		setTimestampCursorPosition(selectionStart);

		setCurrentTimeStampString(timeStampStringValue);
		setCurrentTimeStamp(timeStampStringToSeconds(timeStampStringValue));
	};

	const handleTimeStampInputKeyUp = (event, ref) => {
		const timeStampStringValue = timeStampInputKeyUp(event, ref);
		if (timeStampStringValue) {
			setCurrentTimeStampString(timeStampStringValue);
			setCurrentTimeStamp(timeStampStringToSeconds(timeStampStringValue));
		}
	};

	const handleTimeStampInputKeyDown = (event, ref) => {
		timeStampInputKeyDown(event, ref);
	};

	useEffect(() => {
		if (timestampInputRef.current) {
			const timestampinput = timestampInputRef.current;
			const selectionStart = timestampinput.value.length - timestampCursorPosition;
			timestampInputRef.current.setSelectionRange(selectionStart, selectionStart);
		}
	}, [currentTimeStamp]);

	useEffect(() => {
		if (timeStamp !== undefined && timeStamp !== null && parseInt(timeStamp, 10) >= 0) {
			setCurrentTimeStampString(timeStampToString(timeStamp));
		} else {
			setCurrentTimeStampString("--h--m--s");
		}

		setCurrentTimeStamp(timeStamp);
	}, [timeStamp]);

	return (
		<OutlinedInput
			className={className}
			value={currentTimeStampString}
			title={timeStampPrefix + currentTimeStampString}
			onClick={() => setTimeStampInputSelection(timestampInputRef.current)}
			onChange={(event) => handleFieldChange(event, timestampInputRef)}
			onKeyUp={(event) => handleTimeStampInputKeyUp(event, timestampInputRef)}
			onKeyDown={(event) => handleTimeStampInputKeyDown(event, timestampInputRef)}
			onBlur={(event) => onBlur(event)}
			externalLabel
			size="small"
			inputProps={{ "aria-label": "timestamp", maxLength: "9", inputMode: "numeric" }}
			data-cy={`timestampInput-${itemGuid}`}
			disabled={disabled}
			inputRef={timestampInputRef}
		/>
	);
};

export default TimeStampInput;
