import { addHours, differenceInDays, formatRelative, format, parseISO, toDate } from "date-fns";

export const formatDate = (
	date,
	startTime = null,
	endTime = null,
	labelAt = "",
	labelFrom = "",
	labelTo = "",
	basic = true,
	startUpperCase = true,
	convertToLocalTime = false,
	stripTime = false,
) => {
	let parsedDate = date ? (typeof date === "number" ? toDate(date * 1000) : parseISO(date)) : null;
	let parsedStartTime = startTime && startTime.length > 0 ? parseISO(startTime) : null;
	let parsedEndTime = endTime && endTime.length > 0 ? parseISO(endTime) : null;
	const dateNow = Date.now();

	if (convertToLocalTime) {
		const offset = 0 - new Date().getTimezoneOffset() / 60;
		if (parsedDate && typeof date !== "number" && !date.toString().includes("Z")) {
			// Parsing time stamps automatically converts to local time
			// If the date string contains a Z, the offset is already applied
			parsedDate = addHours(parsedDate, offset);
		}
		if (parsedStartTime) {
			parsedStartTime = addHours(parsedStartTime, offset);
		}
		if (parsedEndTime) {
			parsedEndTime = addHours(parsedEndTime, offset);
		}
	}

	let dateTime = "";
	if (!basic) {
		if (parsedDate) {
			if (Math.abs(differenceInDays(parsedDate, dateNow)) < (parsedDate < dateNow ? 7 : 6)) {
				dateTime = `${formatRelative(parsedDate, dateNow)}`;
				if (stripTime) {
					dateTime = dateTime.replace(/ at .*/g, "");
				}
			} else {
				dateTime = `${format(parsedDate, "PP")}`;
			}

			if (startUpperCase) {
				dateTime = dateTime.charAt(0).toUpperCase() + dateTime.slice(1);
			}

			const atPosition = dateTime.indexOf(" at");
			if (startTime && atPosition >= 0) {
				dateTime = dateTime.substr(0, atPosition);
			}
		}

		if (startTime && startTime.length > 0) {
			if (endTime && endTime.length > 0) {
				// only parse the endTime if it is a timestamp - otherwise, use it directly.
				const formattedEndTime = endTime.toString().indexOf("T") < 0 ? endTime : format(parsedEndTime, "h:mm a");
				dateTime += ` ${labelFrom} ${format(parsedStartTime, "h:mm a")} ${labelTo} ${formattedEndTime}`;
			} else {
				dateTime += ` ${labelAt} ${format(parsedStartTime, "h:mm a")}`;
			}
		}
	} else {
		if (parsedDate) {
			dateTime = `${format(parsedDate, "PP")}`;
		}
		if (startTime && startTime.length > 0) {
			dateTime += `${parsedDate ? " " : ""}${format(parsedStartTime, "h:mm a")}`;
		}
	}

	return dateTime;
};

export const pad = (number, size) => {
	let s = String(number);
	while (s.length < (size || 2)) {
		s = `0${s}`;
	}
	return s;
};
