import React, { useState, useEffect, useRef, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { useInView } from "react-intersection-observer";
import clsx from "clsx";
import { debounce } from "lodash";
import { API_HOST } from "config/env";
import { useHotkeys } from "react-hotkeys-hook";
import { formatDate } from "utils/date";

import { Typography, MenuItem, Link, CircularProgress, FormGroup, FormControlLabel, Checkbox, Box, Tooltip } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import telemetryAddEvent from "utils/telemetryAddEvent";
import OutlinedInput from "atlas/components/FormControls/OutlinedInput";
import SelectInput from "atlas/components/FormControls/SelectInput";
import ButtonWithTooltip from "atlas/components/Buttons/ButtonWithTooltip";
import typographyStyle from "atlas/assets/jss/components/typographyStyle";
import { grayColor } from "atlas/assets/jss/shared";
import GenericDialog from "atlas/components/Dialogs/GenericDialog";
import Icon from "atlas/components/Icon/Icon";
import { VERY_SMALL, MEDIUM } from "atlas/utils/buttonSize";
import { useWidthDown } from "atlas/utils/useWidth";
import Voting from "views/LiveMeeting/components/Voting";
import checkVotingFinished, { checkMeetingQuorumMet, getVotingResults } from "views/LiveMeeting/utils/votingUtils";
import { TYPE_FOR, TYPE_ABSENT, TYPE_NONE } from "views/LiveMeeting/utils/voteType";
import { STATUS_ABSENT } from "views/LiveMeeting/utils/rollCallUserStatus";
import { setActive, finishVote, persistVote, stopVote, updateVote, startTieBreakerVote } from "redux/meeting/actions";
import { sendForVote } from "redux/meeting/actions";
import { setSnackbarOptions } from "redux/snackBar/actions";
import notifierMessage from "utils/notifierMessage";
import { getNoOptionsMenuItem } from "utils/dropDown";
import MotionLoading from "./MotionLoading";

const useStyles = makeStyles((theme) => ({
	hidden: {
		display: "none !important",
	},
	container: (props) => ({
		marginRight: `${props.isLiveMeeting ? 88 : 48}px`,
		marginTop: "16px",
		minHeight: "270px",
		[theme.breakpoints.down("sm")]: {
			marginLeft: "0",
			marginRight: "0",
		},
	}),
	fieldInput: {
		width: "100%",
		marginTop: "0",
		marginBottom: "16px",
		"& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)": {
			transform: "translate(14px, 28px)",
		},
		"& .MuiInputBase-root": {
			height: "40px",
			"& .MuiInputBase-input": {
				boxSizing: "border-box",
				height: "40px",
				paddingTop: "10.5px",
				paddingBottom: "10.5px",
			},
		},
	},
	voteTypeInput: {
		minWidth: "150px",
		marginTop: "0",
		marginBottom: "16px",
		"& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)": {
			transform: "translate(14px, 28px)",
		},
		"& .MuiInputBase-root": {
			height: "40px",
			"& .MuiInputBase-input": {
				boxSizing: "border-box",
				height: "40px",
				paddingTop: "10.5px",
				paddingBottom: "10.5px",
			},
		},
	},
	editorSpacing: {
		marginBottom: "16px",
	},
	divider: {
		margin: "0 -16px",
		marginTop: "8px",
		height: "2px",
		backgroundColor: grayColor[5],
	},
	dividerBetween: {
		marginTop: "0",
		marginBottom: "16px",
	},
	buttons: {
		display: "flex",
		alignItems: "center",
		flexWrap: "wrap",
		paddingTop: "16px",
		paddingBottom: "16px",
		marginBottom: "-16px",
		marginTop: "-16px",
	},
	buttonsLeft: {
		flexGrow: "1",
		display: "flex",
		alignItems: "center",
	},
	labelButton: {
		...typographyStyle.drawerTitle,
	},
	button: {
		minWidth: "100px",
		marginRight: "4px",
		padding: "0 16px",
	},
	thinButton: {
		height: "24px",
	},
	buttonsBetween: {
		marginTop: "-16px",
		marginBottom: "0",
	},
	rollCallVote: {
		marginRight: "2px",
	},
	rollCallTypesButton: {
		marginRight: "4px",
		padding: "8px",
	},
	recordedVote: {
		display: "inline-flex",
		verticalAlign: "middle",
		marginTop: "8px",
		"& > label": {
			margin: 0,
		},
		"& .MuiFormControlLabel-label": {
			margin: 0,
			fontSize: "16px",
		},
		"& .MuiTooltip-tooltip": {
			fontSize: "14px",
		},
	},
	motionButtons: {
		display: "flex",
		alignItems: "start",
		"& >:not(:last-child)": {
			display: "flex",
			flexGrow: 0,
		},
		"& >:last-child": {
			display: "flex",
			flexGrow: 1,
		},
	},
	signAdoptLink: {
		fontSize: "16px",
	},
	resultField: {
		width: "100%",
	},
	motionOverflowMenu: {
		alignSelf: "center",
		marginTop: "10px",
		width: "45px",
	},
	sendForVote: {
		marginLeft: "16px",
		marginTop: "8px",
		[theme.breakpoints.down("sm")]: {
			marginLeft: "0px",
		},
	},
}));

const Motion = (props) => {
	const {
		motion,
		meeting,
		otherRollCallTypes,
		additionalUsers,
		minutesItems,
		selected,
		alwaysUseRecordedVote,
		votingData,
		onlineVoters,
		votingSettings,
		adoptUpdating,
		forceUpdate,
		handleUpdateMotion,
		showSignIn,
		adoptPublishPreviousMinutes,
		isSelected,
		signalRClient,
		showResetVote,
		setShowVotingStatus,
		closeDialogs,
		isLiveMeeting,
	} = props;
	const { t } = useTranslation("meetings");
	const widthDownSm = useWidthDown("sm");
	const [fields, setFields] = useState(motion ? { ...motion.fields } : {});
	const [dialogs, setDialogs] = useState({});
	const [showVoting, setShowVoting] = useState(motion?.fields?.Voting?.Value?.length > 0);
	const [digitalVotingStarted, setDigitalVotingStarted] = useState(false);
	const [savingVote, setSavingVote] = useState([]);
	const [selectedRollCall, setSelectedRollCall] = useState(null);
	const [refreshVoting, setRefreshVoting] = useState(null);
	const [votersListExpanded, setVotersListExpanded] = useState(false);
	const confirmChangeVotePrimaryAction = useRef(null);
	const classes = useStyles({ isLiveMeeting });
	const dispatch = useDispatch();
	const [inViewRef, inView] = useInView({ triggerOnce: true });

	useEffect(() => {
		setShowVotingStatus(motion?.fields?.Voting?.Value?.length > 0);
	}, []);

	//Fetching the rollCall from the store since movedBy and secondedBy should not show absentees
	const meetingsReducer = useSelector((state) => state.meetingsReducer);
	const {
		meeting: { rollCall },
	} = meetingsReducer;

	const hotKeyProps = { enableOnContentEditable: true, enableOnFormTags: ["input", "select", "textarea"] };

	const resultShortCut = {
		keys: t("shortCutKeys.resultShortCutKey"),
		props: hotKeyProps,
	};

	const containerId = `motion-${(motion || {}).guid || "0"}`;

	const [votesExpanded, setVotesExpanded] = useState(
		meeting.digitalVoting &&
			motion?.fields?.Voting?.Value?.length > 0 &&
			motion.fields.Voting.Value.find((v) => v.Vote > 0 && v.Vote < 5) != null &&
			!checkVotingFinished(motion, rollCall),
	);

	useHotkeys(
		resultShortCut.keys,
		() => {
			const motionIndex = minutesItems.findIndex((product) => product.guid == motion.guid);
			const index = minutesItems.findIndex((product) => product.guid == selected);
			if (motionIndex == index) {
				showVotingResults(minutesItems[motionIndex], minutesItems[motionIndex]?.fields?.Disposition?.Value || "");
			}
		},
		resultShortCut.props,
	);

	const isMotionEmpty = () => motion?.fields?.MovedBy?.Value === 0 && motion?.fields?.SecondedBy?.Value === 0;

	const handleFocus = (field) => {
		dispatch(setActive(motion.guid, undefined, field));
	};

	const handleUpdateVote = (motionGuid, userId, vote) => {
		dispatch(updateVote(motionGuid, userId, vote));

		saveVote(motionGuid, userId, vote);
	};

	const saveVote = debounce((motionGuid, userId, vote) => {
		setSavingVote((prev) => [...prev, userId]);
		dispatch(persistVote(motionGuid, userId, vote))
			.then(() => {
				setSavingVote((prev) => prev.filter((savingUserId) => savingUserId !== userId));
			})
			.catch((err) => {
				setSavingVote((prev) => prev.filter((savingUserId) => savingUserId !== userId));
				showSignIn(err, () => {
					saveVote(motionGuid, userId, vote);
				});
			});
	}, 800);

	const handleSendForVote = (motion, hideSnackbar) => {
		dispatch(sendForVote(motion))
			.then((data) => {
				if (data) {
					if (!hideSnackbar) {
						let option = notifierMessage(t("voting.digitalVotingStarted"), "success");
						dispatch(setSnackbarOptions(option));
					}
					signalRClient.votingHub.reSendForVote(data.id, data.guid);
					const votingResults = getVotingResults(motion, selectedRollCall ? selectedRollCall : rollCall);
					if (votingResults && votingResults.tie) {
						handleStartTieBreakerVote(motion.guid);
					}
				}
			})
			.catch((err) => {
				console.log(err);
				showSignIn(err, () => {
					handleSendForVote(motion, hideSnackbar);
				});
			});
	};

	const handleStopVote = (motionGuid, hideSnackbar, callback) => {
		dispatch(stopVote(motionGuid))
			.then((success) => {
				if (success) {
					setSavingVote([]);
					if (!hideSnackbar) {
						let option = notifierMessage(t("voting.digitalVotingStopped"), "success");
						dispatch(setSnackbarOptions(option));
					}

					if (callback && typeof callback === "function") {
						callback();
					}
				}
			})
			.catch((err) => {
				showSignIn(err, () => {
					handleStopVote(motionGuid, hideSnackbar, callback);
				});
			});
	};

	const handleFinishVote = (motionGuid, disposition) => {
		dispatch(finishVote(motionGuid))
			.then((success) => {
				if (success) {
					signalRClient.votingHub.showResults(meeting.id, motionGuid, disposition);
				}
			})
			.catch((err) => {
				showSignIn(err, () => {
					handleFinishVote(motionGuid, disposition);
				});
			});
	};

	const showVotingResults = (motion, disposition) => {
		if (motion) {
			if (votingData && votingData.itemInProgress) {
				handleStopVote({ guid: votingData.itemInProgress.guid }, true, () => {
					signalRClient.votingHub.showResults(meeting.id, motion.guid, disposition);
				});
			} else {
				signalRClient.votingHub.showResults(meeting.id, motion.guid, disposition);
			}
		}
	};

	const handleStartTieBreakerVote = (motionGuid) => {
		dispatch(startTieBreakerVote(motionGuid));
	};

	const updateMotionValues = (updatedValue, field, convertToHtml, isNumber, preventSave) => {
		let value = updatedValue;
		if (convertToHtml) {
			value = `<p>${value.replace(/(?:\r\n|\r|\n)/g, "<br />")}</p>`;
		}
		if (isNumber && typeof value === "string") {
			value = parseInt(value, 10);
		}

		const fieldUpdate = {
			[field]: {
				Value: value,
			},
		};
		if (field === "RecordedVote" && fields?.Voting?.Value?.length > 0) {
			let votingData = fields.Voting.Value.map((data) => {
				data.RecordedVote = value;
				return data;
			});
			fieldUpdate.Voting = { Value: votingData };
		}

		setFields((prev) => ({
			...prev,
			...fieldUpdate,
		}));

		if (!preventSave) {
			handleUpdateMotion(motion, {
				[field]: {
					Value: value,
				},
			});

			if (field === "Name") {
				telemetryAddEvent("Live meeting - Edit motion text");
			} else if (field === "MovedBy") {
				telemetryAddEvent("Live meeting - Selected mover");
			}
		} else if (motion) {
			motion.fields = {
				...motion.fields,
				[field]: {
					Value: value,
				},
			};
		}
	};

	const handleChange = (e, field, convertToHtml, isNumber) => {
		const {
			target: { value },
		} = e;

		updateMotionValues(value, field, convertToHtml, isNumber);
	};

	const handleVoteChange = useCallback(
		(motionGuid, userId, vote) => {
			if (fields && fields.Voting == null && motion) {
				motion.fields.Voting = { Value: [] };
				fields.Voting = { Value: [] };
			}

			let needConfirm = false;
			const currentUserVote = fields.Voting.Value.find((userVote) => userVote.UserId === userId);
			if (currentUserVote && currentUserVote.Vote !== 5 && meeting.digitalVoting && motion.attributes.digitalVoting) {
				needConfirm = true;
			}

			if (needConfirm) {
				confirmChangeVotePrimaryAction.current = () => {
					setFields((prev) => ({
						...prev,
						Voting: {
							Value: prev.Voting.Value.map((userVote) => {
								if (userVote.UserId === userId) {
									userVote.Vote = vote;
									handleUpdateVote(motionGuid, userId, vote);
								}
								return userVote;
							}),
						},
					}));
				};
				setDialogs((prev) => ({
					...prev,
					confirmVoteChange: true,
				}));
			} else if (currentUserVote == null) {
				const selectedRollCallUser = selectedRollCall?.users.find((sr) => sr.userId === userId);
				const rollCallUser = rollCall.users.find((sr) => sr.userId === userId);
				setFields((prev) => {
					prev.Voting.Value.push({
						UserId: userId,
						Name: selectedRollCallUser ? selectedRollCallUser?.name : rollCallUser?.name,
						Vote: vote,
						RecordedVote: true,
						Weighting: selectedRollCallUser ? selectedRollCallUser.weighting : rollCallUser.weighting,
						QuorumNeeded: selectedRollCall ? selectedRollCall.quorumNeeded : rollCall.quorumNeeded,
						QuorumNeededValue: selectedRollCall ? selectedRollCall.quorumNeededValue : rollCall.quorumNeededValue,
					});

					return { ...prev, Voting: { Value: prev.Voting.Value } };
				});
			} else {
				setFields((prev) => ({
					...prev,
					Voting: {
						Value: prev.Voting.Value.map((userVote) => {
							if (userVote.UserId === userId) {
								userVote.Vote = vote;
								handleUpdateVote(motionGuid, userId, vote);
							}
							return userVote;
						}),
					},
				}));
			}
		},
		[fields, selectedRollCall, rollCall, motion],
	);

	const handleVoteTypeChange = (rollCallId) => {
		telemetryAddEvent("Live meeting - Vote type change");
		updateMotionValues(rollCallId, "SelectedRollCallId", false, false, true);
		const foundRollCall = otherRollCallTypes.find((r) => r.id === rollCallId);
		setSelectedRollCall(foundRollCall);
		updateMotionValues(motion.fields.Voting.Value, "Voting", false, false);
		if (votingSettings && checkVotingFinished(motion, foundRollCall)) {
			const votingResults = getVotingResults(motion, foundRollCall, meeting);
			const disposition = votingResults.quorumMet
				? votingResults.votePassed
					? votingSettings.votingLabels.carried
					: votingSettings.votingLabels.failed
				: t("voting.quorumNotMet");
			if (motion.fields.Disposition.Value != disposition) {
				updateMotionValues(disposition, "Disposition", false, false);
			}
		}
	};

	const recordVote = () => {
		let votesUpdated = false;
		rollCall.users.forEach((rollCallUser) => {
			const vote = motion?.fields?.Voting?.Value?.find((vote) => vote.UserId === rollCallUser.userId);
			if (!vote && motion && rollCallUser.votingMember) {
				if (motion.fields.Voting?.Value == null) {
					motion.fields.Voting = { Value: [] };
				}
				motion.fields.Voting.Value.push({
					UserId: rollCallUser.userId,
					Name: rollCallUser.name,
					Vote: rollCallUser.status === STATUS_ABSENT ? TYPE_ABSENT : !meeting.digitalVoting ? TYPE_FOR : TYPE_NONE,
					RecordedVote: false,
					Weighting: rollCallUser.weighting,
					QuorumNeeded: rollCallUser.quorumNeeded,
					QuorumNeededValue: rollCallUser.quorumNeededValue,
				});
				votesUpdated = true;
			}
		});

		if (votesUpdated && motion) {
			updateMotionValues(motion.fields.Voting.Value, "Voting", false, false);
			if (votingSettings && checkVotingFinished(motion, rollCall)) {
				const votingResults = getVotingResults(motion, selectedRollCall ? selectedRollCall : rollCall, meeting);
				const disposition = votingResults.quorumMet
					? votingResults.votePassed
						? votingSettings.votingLabels.carried
						: votingSettings.votingLabels.failed
					: t("voting.quorumNotMet");
				if (motion.fields.Disposition.Value != disposition) {
					updateMotionValues(disposition, "Disposition", false, false);
				}
			}
		}

		setShowVoting(true);
		setShowVotingStatus(true);
		telemetryAddEvent("Live meeting - Roll call vote");
	};

	const startVote = () => {
		handleSendForVote(motion);
	};

	const resetVote = () => {
		if (motion) {
			updateMotionValues([], "Voting", false, false, true);
			updateMotionValues("", "Disposition", false, false);
			motion.fields.RecordedVote = { Value: false };
			motion.attributes.digitalVoting = false;
			setFields((prev) => ({
				...prev,
				RecordedVote: {
					Value: false,
				},
			}));
			setRefreshVoting(new Date().getTime());
			handleStopVote(motion.guid, true);
			setDigitalVotingStarted(false);
			setVotesExpanded(true);
		}
	};

	useEffect(() => {
		if (motion && votingSettings) {
			const votingFinished = checkVotingFinished(motion, rollCall) && savingVote.length === 0;
			const votingResults = getVotingResults(motion, selectedRollCall ? selectedRollCall : rollCall, meeting);
			if (votingFinished) {
				const defaultDispositionValues = [
					votingSettings.votingLabels.carried,
					votingSettings.votingLabels.failed,
					t("voting.quorumNotMet"),
				];
				let disposition = votingResults.quorumMet
					? votingResults.votePassed
						? votingSettings.votingLabels.carried
						: votingSettings.votingLabels.failed
					: t("voting.quorumNotMet");
				if (
					!motion.fields?.Disposition?.Value ||
					(motion.fields.Disposition.Value !== disposition && defaultDispositionValues.includes(motion.fields.Disposition.Value))
				) {
					// Only update the disposition if it's not already set or if it's set to a default value. This avoids overwriting custom values.
					updateMotionValues(disposition, "Disposition", false, false);
				} else {
					disposition = motion.fields?.Disposition?.Value || "";
				}
				if (!votersListExpanded) {
					handleFinishVote(motion.guid, disposition);
				}
				setVotersListExpanded(false);
				setDigitalVotingStarted(false);
			} else if (votingResults && votingResults.tie) {
				handleStartTieBreakerVote(motion.guid);
			}
		}
	}, [fields?.Voting?.Value, savingVote]);

	useEffect(() => {
		if (motion && votingSettings) {
			const quorumMet = checkMeetingQuorumMet(meeting, rollCall);
			const votingFinished = checkVotingFinished(motion, rollCall);
			if (
				motion &&
				!votingFinished &&
				["", votingSettings.votingLabels.carried, votingSettings.votingLabels.failed, t("voting.quorumNotMet")].indexOf(
					motion.fields.Disposition.Value,
				) >= 0
			) {
				if (!quorumMet) {
					updateMotionValues(t("voting.quorumNotMet"), "Disposition", false, false, true);
				} else if (motion.fields.Disposition.Value === t("voting.quorumNotMet")) {
					updateMotionValues("", "Disposition", false, false, true);
				}
			}

			if (motion && motion.fields.SelectedRollCallId && motion.fields.SelectedRollCallId.Value) {
				const foundRollCall = otherRollCallTypes.find((r) => r.id == motion.fields.SelectedRollCallId.Value);
				setSelectedRollCall(foundRollCall);
			}
		}
	}, [votingSettings]);

	useEffect(() => {
		if (forceUpdate) {
			setFields(motion ? { ...motion.fields } : {});
			if (motion) {
				delete motion.forceUpdate; // Set this back to undefined
			}
		}
	}, [forceUpdate]);

	const getMotionOptions = (excludedId) => {
		return rollCall && rollCall.users
			? rollCall.users
					.filter((member) => member.userId !== excludedId && member.status !== STATUS_ABSENT && member.votingMember)
					.map((member) => ({
						label: member.name,
						value: member.userId.toString(),
					}))
					.concat(
						(additionalUsers || []).map((user) => ({
							label: user.name,
							value: user.userId.toString(),
						})),
					)
			: [];
	};

	const closeDialog = (dialog) => {
		setDialogs((prev) => ({
			...prev,
			[dialog]: false,
		}));
		closeDialogs();
	};

	const formattedMeetingDate = formatDate(meeting.date);

	const getMoved = () => {
		const motionMenuItems = [];

		motionMenuItems.push(getNoOptionsMenuItem(t));
		getMotionOptions(fields.SecondedBy.Value).map((option) => {
			motionMenuItems.push(
				<MenuItem key={`moved-by-${option.value}`} value={option.value} data-cy={`moved-by-${option.value}`}>
					{option.label}
				</MenuItem>,
			);
		});

		return motionMenuItems;
	};

	const getSeconded = () => {
		const motionMenuItem = [];

		motionMenuItem.push(getNoOptionsMenuItem(t));
		getMotionOptions(fields.MovedBy.Value).map((option) => {
			motionMenuItem.push(
				<MenuItem key={`seconded-by-${option.value}`} value={option.value} data-cy={`seconded-by-${option.value}`}>
					{option.label}
				</MenuItem>,
			);
		});
		return motionMenuItem;
	};

	const parentItem = minutesItems?.find((mi) => motion && motion.attributes && mi.guid === motion.attributes.relationshipGuid);
	const adoptPreviousMinutes = {
		showAdoptPublishPreviousMinutes: parentItem?.itemToAdoptPreviousMinutes,
		minutesToAdoptMeetingId: parentItem?.minutesToAdoptMeetingId,
	};

	return (
		<>
			{motion && (
				<div id={containerId} className={classes.container} ref={inViewRef}>
					{inView ? (
						<>
							<div>
								<SelectInput
									id={`motion-moved-by-${motion.guid}`}
									name="moved-by"
									className={classes.fieldInput}
									noDefaultClassName
									fullWidth
									externalLabel
									label={t("movedBy")}
									labelSize="large"
									value={fields.MovedBy.Value.toString()}
									onChange={(e) => handleChange(e, "MovedBy", undefined, true)}
									data-cy={`motion-moved-by-${motion.guid}`}
								>
									{getMoved()}
								</SelectInput>
							</div>
							<div>
								<SelectInput
									id={`motion-seconded-by-${motion.guid}`}
									name="seconded-by"
									className={classes.fieldInput}
									noDefaultClassName
									fullWidth
									externalLabel
									label={t("secondedBy")}
									labelSize="large"
									value={fields.SecondedBy.Value.toString()}
									onChange={(e) => handleChange(e, "SecondedBy", undefined, true)}
									data-cy={`motion-seconded-by-${motion.guid}`}
								>
									{getSeconded()}
								</SelectInput>
							</div>

							<ButtonWithTooltip
								color="primary"
								variant="text"
								size={VERY_SMALL}
								title={t("voting.tooltips.rollCallVote")}
								tooltipPlacement="bottom-start"
								onClick={() => {
									setVotesExpanded((prev) => !prev);
									setVotersListExpanded(true);
									recordVote();
								}}
								style={{ marginBottom: "10px", textTransform: "capitalize", color: "#212121", padding: "0" }}
								data-cy={`expand-votes-${motion.guid}`}
							>
								<>
									<Typography style={{ fontWeight: "600" }} variant="body1">
										{t("voting.voters")}
									</Typography>
									<Icon name={votesExpanded ? "expand-down" : "expand-right"} />
								</>
							</ButtonWithTooltip>
							{votesExpanded && (
								<Box display="flex" alignItems={widthDownSm ? "flex-start" : "center"} flexDirection={widthDownSm ? "column" : "row"}>
									<SelectInput
										id={`vote-type-${motion.guid}`}
										name="vote-type"
										className={classes.voteTypeInput}
										noDefaultClassName
										externalLabel
										externalLabelStyle="sentence"
										label={t("voteType")}
										labelSize="small"
										value={
											selectedRollCall
												? selectedRollCall.id
												: otherRollCallTypes && otherRollCallTypes.length > 0
													? otherRollCallTypes[0].id
													: ""
										}
										onChange={(e) => handleVoteTypeChange(e.target.value)}
										data-cy={`vote-type-${motion.guid}`}
									>
										{otherRollCallTypes?.map((type) => (
											<MenuItem key={`vote-type-${type.id}`} value={type.id} data-cy={`vote-type-${type.id}`}>
												{type.name.length > 0 ? type.name : t("voting.majority")}
											</MenuItem>
										))}
									</SelectInput>

									{showVoting && (
										<>
											{meeting.digitalVoting && !checkVotingFinished(motion, rollCall) && (
												<ButtonWithTooltip
													className={clsx(classes.button, classes.sendForVote)}
													primary
													variant="contained"
													size={MEDIUM}
													title={
														meeting.votingInRange
															? t("voting.tooltips.sendForVote")
															: t("voting.tooltips.votingNotInRange", { date: formattedMeetingDate })
													}
													onClick={() => {
														startVote();
														setDigitalVotingStarted(true);
														motion.attributes.digitalVoting = true;

														telemetryAddEvent("Live meeting - Send for vote");
													}}
													disabled={!checkMeetingQuorumMet(meeting, rollCall) || !meeting.votingInRange}
													data-cy={`send-for-vote-${motion.guid}`}
												>
													{digitalVotingStarted ? t("voting.buttons.reSendForVote") : t("voting.buttons.sendForVote")}
												</ButtonWithTooltip>
											)}
										</>
									)}

									{!alwaysUseRecordedVote ? (
										<FormGroup className={classes.recordedVote}>
											<Tooltip PopperProps={{ disablePortal: true }} title={t("voting.tooltips.recordedVote")}>
												<FormControlLabel
													id="recordedVote"
													control={
														<Checkbox
															checked={Boolean(fields?.RecordedVote?.Value)}
															inputProps={{
																"aria-label": t("voting.tooltips.recordedVote"),
																"aria-labelledby": "recordedVote",
															}}
															onChange={() => {
																if (motion.fields.RecordedVote == null) {
																	motion.fields.RecordedVote = { Value: false };
																}
																updateMotionValues(!motion.fields.RecordedVote.Value, "RecordedVote", false, false);
															}}
														/>
													}
													label={t("voting.recordedVote")}
													data-cy={`record-vote-${motion.guid}`}
												/>
											</Tooltip>
										</FormGroup>
									) : null}
								</Box>
							)}

							{showVoting && (
								<div>
									{showResetVote && (
										<GenericDialog
											show
											title={t("voting.confirmResetVoteDialog.title")}
											primaryAction={() => {
												resetVote();
												closeDialog("resetVote");
											}}
											primaryTitle={t("app:buttons.reset")}
											secondaryAction={() => {
												closeDialog("resetVote");
											}}
											secondaryTitle={t("app:buttons.cancel")}
											closeIcon={<Icon name="close" />}
											data-cy="confirm-reset-vote"
										>
											<Typography>{t("voting.confirmResetVoteDialog.body")}</Typography>
										</GenericDialog>
									)}
									{dialogs.confirmVoteChange && confirmChangeVotePrimaryAction.current && (
										<GenericDialog
											show
											title={t("voting.confirmChangeVote.title")}
											primaryAction={() => {
												if (confirmChangeVotePrimaryAction.current) {
													confirmChangeVotePrimaryAction.current();
													confirmChangeVotePrimaryAction.current = null;
													closeDialog("confirmVoteChange");
												}
											}}
											primaryTitle={t("app:buttons.confirm")}
											secondaryAction={() => {
												closeDialog("confirmVoteChange");
											}}
											secondaryTitle={t("app:buttons.cancel")}
											closeIcon={<Icon name="close" />}
											data-cy="confirm-vote-change"
										>
											<Typography>{t("voting.confirmChangeVote.body")}</Typography>
										</GenericDialog>
									)}
									<Voting
										motion={motion}
										rollCall={rollCall}
										selectedRollCall={selectedRollCall}
										voting={fields.Voting}
										onlineVoters={onlineVoters}
										votingSettings={votingSettings}
										digitalVoting={meeting.digitalVoting}
										digitalVotingStarted={digitalVotingStarted}
										handleVoteChange={handleVoteChange}
										isMinutesV2
										showVotesExpanded={votesExpanded}
										meeting={meeting}
										refreshVoting={refreshVoting}
									/>
								</div>
							)}
							<Box display="flex">
								<OutlinedInput
									id={`disposition-${motion.guid}`}
									externalLabel
									noDefaultClassName
									label={t("results")}
									labelSize="large"
									value={fields.Disposition.Value || ""}
									onChange={(e) => handleChange(e, "Disposition")}
									onFocus={() => handleFocus("disposition")}
									fullWidth
									containerFullWidth
									size="small"
									data-cy="disposition"
								/>
							</Box>
							<div className={clsx(classes.buttons, classes.buttonsBetween, classes.motionButtons, { [classes.hidden]: !isSelected })}>
								{motion && (
									<>
										{adoptPreviousMinutes.showAdoptPublishPreviousMinutes && !motion?.fields?.Consent?.Value && (
											<div style={{ marginRight: "8px", display: "flex", flexDirection: "column" }}>
												{adoptUpdating ? (
													<CircularProgress />
												) : (
													<>
														<div>
															<ButtonWithTooltip
																className={clsx(classes.button, classes.adoptPublish, classes.thinButton)}
																primary
																variant="outlined"
																title={t("tooltips.adoptPublish")}
																onClick={() => {
																	adoptPublishPreviousMinutes(adoptPreviousMinutes.minutesToAdoptMeetingId);
																}}
																data-cy="adopt-publish"
															>
																{t("buttons.adoptPublish")}
															</ButtonWithTooltip>
														</div>
														<div>
															<Link
																className={clsx("cursor-pointer", classes.signAdoptLink)}
																underline="always"
																href={`${API_HOST}/home/meeting/adopt/${adoptPreviousMinutes.minutesToAdoptMeetingId}/minutes?liveMeeting=${meeting.id}`}
															>
																{t("buttons.goToSignAdopt")}
															</Link>
														</div>
													</>
												)}
											</div>
										)}
										{(checkVotingFinished(motion, rollCall) || !showVoting || !meeting.digitalVoting) && !isMotionEmpty() && (
											<ButtonWithTooltip
												className={clsx(classes.button, classes.thinButton, classes.showResults)}
												primary
												variant="outlined"
												title={t("voting.tooltips.showResults")}
												onClick={() => {
													showVotingResults(motion, fields.Disposition.Value);
												}}
												data-cy={`show-voting-results-${motion.guid}`}
											>
												{t("voting.buttons.showResults")}
											</ButtonWithTooltip>
										)}
									</>
								)}
							</div>
						</>
					) : (
						<MotionLoading />
					)}
				</div>
			)}
		</>
	);
};

export default React.memo(Motion);
