const cellStyles = {
	minWidth: "2em",
	padding: "0.4em",
	border: "1px dashed #949494",
	textAlign: "left",
	verticalAlign: "top",
};

const meetingContentStyle = {
	"& figure.image": {
		padding: "0",
		marginLeft: "8px",
	},
	"& figure.table": {
		/* Matching CSS from CKE for tables in read mode. If we re-theme CKE, update this to match. */
		display: "table",
		margin: "1em auto",
		clear: "both",
		maxWidth: "7.5in",
		"& p": {
			clear: "both",
		},
		"& table": {
			borderCollapse: "collapse",
			borderSpacing: "0",
			border: "1px dashed #949494",
			width: "100%",
			height: "100%",
			"& td": {
				...cellStyles,
			},
			"& th": {
				...cellStyles,
				fontWeight: "700",
				background: "#e6e6e6",
			},
		},
	},
};

export default meetingContentStyle;
