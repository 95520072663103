import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";

import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

import SupportRequestDialog from "components/Dialogs/SupportRequestDialog";
import Container from "@mui/material/Container";

import { mapStateToProps } from "../redux/app/mapStateToProps";
import { resetPageConfigs, updatePageConfigs } from "../redux/app/actions";

class ErrorBoundary extends Component {
	constructor(props) {
		super(props);
		this.state = { hasError: false, showSupportRequestDialog: false };
	}

	static getDerivedStateFromError(error) {
		return { hasError: true, error };
	}

	componentDidCatch(error, info) {
		const { dispatch, t } = this.props;

		dispatch(resetPageConfigs({}));
		dispatch(updatePageConfigs({ title: t("error") }));

		// Future Story - Log errors to a data store.
		console.log("Error:", error, info);
	}

	openSupportRequestDialog = () => this.setState({ showSupportRequestDialog: true });

	closeSupportRequestDialog = () => this.setState({ showSupportRequestDialog: false });

	render() {
		const { t } = this.props;
		const { hasError, error, showSupportRequestDialog } = this.state;
		if (hasError) {
			return (
				<Container>
					{showSupportRequestDialog && <SupportRequestDialog show={showSupportRequestDialog} onClose={this.closeSupportRequestDialog} />}

					<Paper style={{ margin: "24px", width: "80%" }}>
						<div className="flex grow">
							<Typography variant="h5" style={{ padding: "16px" }}>
								{t("errorMessage")}
							</Typography>
							<Button variant="contained" size="small" color="primary" style={{ margin: "16px" }} onClick={this.openSupportRequestDialog}>
								{t("menu.support")}
							</Button>
						</div>
						<Divider />
						<Typography variant="caption">
							<div
								style={{ padding: "16px" }}
								dangerouslySetInnerHTML={{ __html: error.stack.replace(/(?:\r\n|\r|\n)/g, "~").split("~")[0] }}
							/>
						</Typography>
					</Paper>
				</Container>
			);
		}

		return this.props.children;
	}
}

export default withTranslation("app")(connect(mapStateToProps)(ErrorBoundary));
