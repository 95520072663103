import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import queryString from "query-string";
import request from "superagent";
import cookie from "react-cookies";
import { withRouter } from "utils/router";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";

import clone from "lodash/fp/clone";
import omit from "lodash/fp/omit";

import { Visibility, VisibilityOff } from "components/Icons";
import LoginAvatar from "components/Avatar/LoginAvatar";
import OutlinedInput from "atlas/components/FormControls/OutlinedInput";
import { mapStateToProps } from "redux/app/mapStateToProps";
import Tooltip from "atlas/components/Tooltip/Tooltip";
import { useWidthUp } from "atlas/utils/useWidth";
import { API_HOST } from "config/env";
import notifierMessage from "utils/notifierMessage";
import { setSnackbarOptions } from "redux/snackBar/actions";

const withWidth = () => (WrappedComponent) => (props) => {
	const widthUpSm = useWidthUp("sm");

	return <WrappedComponent {...props} widthUpSm={widthUpSm} />;
};

const PASSWORD_ERROR_LENGTH = 1;
const PASSWORD_ERROR_COMPLEXITY = 2;
const PASSWORD_ERROR_CONTAINS_PII = [3, 4, 5];
const PASSWORD_ERROR_COMMON = 6;

class ResetPassword extends Component {
	constructor(props) {
		super(props);
		const avatar = cookie.load("avatar");
		const queryStringValues = queryString.parse(this.props.location.search);
		this.state = {
			showPassword: false,
			token: queryStringValues ? queryStringValues.token : "",
			errors: {},
			editing: { password: "", confirmPassword: "" },
			// eslint-disable-next-line react/no-unused-state
			avatar: avatar || null,
		};

		this.handleFieldChange = this.handleFieldChange.bind(this);
		this.handleBlur = this.handleBlur.bind(this);
		this.handleClickShowPassword = this.handleClickShowPassword.bind(this);
		this.handleCancel = this.handleCancel.bind(this);
		this.handleResetPassword = this.handleResetPassword.bind(this);
		this.validatePassword = this.validatePassword.bind(this);

		this.validateToken();
	}

	validateToken = () => {
		const { navigate } = this.props;
		const { token } = this.state;

		request
			.post(`${API_HOST}/api/user/passwordtoken/validate`)
			.withCredentials()
			.send({ token })
			.then((res) => {
				if (!res.body.valid) {
					navigate("/forgot-password?invalidToken=true");
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	handleKeyPress = (event) => {
		if (event.key === "Enter") {
			this.handleResetPassword();
		}
	};

	validatePassword(singleField = null) {
		const { t } = this.props;
		const { password, confirmPassword } = this.state.editing;

		let valid = true;
		// if we are only validating one field onBlur, keep any other errors, otherwise reset all errors
		const errors = singleField ? omit(singleField, this.state.errors) : {};

		if (!singleField || singleField === "password") {
			if (!password || password.length < 8) {
				errors.password = t("errors.password8Characters");
				valid = false;
			}
		}

		if (!singleField || singleField === "confirmPassword") {
			if (!confirmPassword || confirmPassword !== password) {
				errors.confirmPassword = t("errors.passwordMatch");
				valid = false;
			}
		}

		if (!valid) {
			this.setState({
				errors,
			});
		}
		return valid;
	}

	handleResetPassword() {
		const { t, dispatch, navigate } = this.props;

		const {
			token,
			editing: { password },
		} = this.state;

		if (this.validatePassword()) {
			const data = {
				token,
				newPassword: password,
			};
			request
				.post(`${API_HOST}/api/users/resetpassword`)
				.withCredentials()
				.send(data)
				.then((res) => {
					const { errors = [] } = res.body;
					if (res.body.success) {
						let option = notifierMessage(t("notifications.passwordUpdated"), "success");
						dispatch(setSnackbarOptions(option));
						navigate("/sign-in");
					} else {
						if (errors.includes(PASSWORD_ERROR_LENGTH)) {
							this.setState({ errors: { password: t("errors.password8Characters") } });
						} else if (errors.includes(PASSWORD_ERROR_COMPLEXITY)) {
							this.setState({ errors: { password: t("errors.passwordComplexity") } });
						} else if (errors.some((error) => PASSWORD_ERROR_CONTAINS_PII.includes(error))) {
							this.setState({ errors: { password: t("errors.passwordContainsPii") } });
						} else if (errors.includes(PASSWORD_ERROR_COMMON)) {
							this.setState({ errors: { password: t("errors.passwordCommon") } });
						}
					}
				})
				.catch((err) => {
					console.log(err);
				});
		}
	}

	handleFieldChange(e) {
		const { editing = {}, errors = {} } = this.state;

		// get all values in edit mode, update just the one that changed.
		const newEditValues = clone(editing);
		newEditValues[e.target.id] = e.target.value;

		// clear validation errors when we change a field. (only the changed field)
		const newErrors = clone(errors);
		newErrors[e.target.id] = null;
		this.setState({
			editing: newEditValues,
			errors: newErrors,
		});
	}

	handleBlur(e, field) {
		this.validatePassword(field);
	}

	handleClickShowPassword() {
		this.setState((state) => ({ showPassword: !state.showPassword }));
	}

	handleCancel() {
		this.props.navigate("/sign-in");
	}

	render() {
		const { t, widthUpSm } = this.props;
		const {
			errors = {},
			editing: { password, confirmPassword },
			showPassword,
		} = this.state;

		// TODO manage avatar properly
		const content = (
			<form action="/" method="POST">
				<LoginAvatar />

				<Box mt={2}>
					<Typography variant="h3" align="center">
						{t("resetPassword.enterPassword")}
					</Typography>
				</Box>
				<OutlinedInput
					id="password"
					fullWidth
					type={showPassword ? "text" : "password"}
					label={t("resetPassword.newPassword")}
					value={password}
					onChange={this.handleFieldChange}
					onBlur={(e) => this.handleBlur(e, "password")}
					InputProps={{
						endAdornment: (
							<InputAdornment tabIndex="-1" position="end">
								<IconButton tabIndex="-1" onClick={this.handleClickShowPassword} size="large">
									{showPassword ? <VisibilityOff /> : <Visibility />}
								</IconButton>
							</InputAdornment>
						),
					}}
					helperText={errors.password ? errors.password : t("resetPassword.helper8Characters")}
					error={!!errors.password}
					data-cy="password"
				/>
				<OutlinedInput
					id="confirmPassword"
					className="no-margin-top"
					fullWidth
					type={showPassword ? "text" : "password"}
					label={t("resetPassword.confirmNewPassword")}
					value={confirmPassword}
					onChange={this.handleFieldChange}
					onBlur={(e) => this.handleBlur(e, "confirmPassword")}
					onKeyPress={this.handleKeyPress}
					InputProps={{
						endAdornment: (
							<InputAdornment tabIndex="-1" position="end">
								<IconButton tabIndex="-1" onClick={this.handleClickShowPassword} size="large">
									{showPassword ? <VisibilityOff /> : <Visibility />}
								</IconButton>
							</InputAdornment>
						),
					}}
					helperText={errors.confirmPassword ? errors.confirmPassword : t("resetPassword.helperPasswordMatch")}
					error={!!errors.confirmPassword}
					data-cy="confirmPassword"
				/>

				<Tooltip
					PopperProps={{
						disablePortal: true,
					}}
					title={errors.confirmPassword || errors.password ? t("resetPassword.tooltip") : ""}
				>
					<span>
						<Button
							className="signin-button medium-top-margin"
							id="reset-password"
							onClick={this.handleResetPassword}
							variant="contained"
							size="large"
							color="primary"
							disabled={!!errors.confirmPassword || !!errors.password}
							data-cy="resetPassword"
						>
							{t("buttons.resetPassword")}
						</Button>
					</span>
				</Tooltip>

				<Box className="text-center" mt={3}>
					<Link component="button" variant="body1" onClick={this.handleCancel} data-cy="backToSignIn">
						{t("buttons.returnSignIn")}
					</Link>
				</Box>
			</form>
		);

		return widthUpSm ? <Card className="login-card">{content}</Card> : <div className="login-small">{content}</div>;
	}
}

export default withRouter(withTranslation("account")(withWidth()(connect(mapStateToProps)(ResetPassword))));
