/* eslint-disable no-param-reassign */
import React, { useState, useEffect, useRef, useContext, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation, useMatch } from "react-router-dom";
import request from "superagent";
import { useTranslation } from "react-i18next";
import { closeSnackbar } from "notistack";
import useBackButtonHandler from "utils/hooks/useBackButtonHandler.js";
import Typography from "@mui/material/Typography";

import forEach from "lodash/fp/forEach";
import { format } from "date-fns";
import { v4 as uuid } from "uuid";

import { UserContext } from "contexts/User/UserContext";
import { SettingsContext } from "contexts/Settings/SettingsContext";
import CircularProgressIndicator from "atlas/components/Progress/CircularProgressIndicator";
import ComponentContainer from "atlas/components/ComponentContainer/ComponentContainer";
import { useWidthUp, useWidthDown } from "atlas/utils/useWidth";
import AddPolicyToAgendaDialog from "components/Dialogs/AddPolicyToAgendaDialog";
import AddGoalsToAgendaDialog from "components/Dialogs/AddGoalsToAgendaDialog";
import RightPanelContainer from "components/Panels/RightPanelContainer";
import UploadErrorDialog from "components/Dialogs/UploadErrorDialog";
import FileUploadFailureDialog from "components/Dialogs/FileUploadFailureDialog";
import AgendaItemSelectMeetingDialog from "./components/AgendaItemSelectMeetingDialog";
import AgendaItemDeleteDialog from "./components/AgendaItemDeleteDialog";
import withErrorHandling from "components/ErrorHOC";
import { API_HOST } from "config/env";
import { STATUS_SAVED_TIMEOUT } from "utils/meetingElement";
import telemetryAddEvent from "utils/telemetryAddEvent";
import { updateSelectedMeeting } from "./functions/meetings";
import AgendaItem from "./AgendaItem";
import AgendaItemTopBar from "./components/AgendaItemTopBar";
import AgendaItemCommentsDetails from "./components/AgendaItemCommentsDetails";
import SubmitForApprovalDialog from "./components/SubmitForApprovalDialog";
import CancelApprovalDialog from "./components/CancelApprovalDialog";
import AgendaItemApproveDialog from "./components/AgendaItemApproveDialog";
import AgendaItemRejectDialog from "./components/AgendaItemRejectDialog";
import SendReminderDialog from "./components/SendReminderDialog";
import { restoreItem } from "./functions/agendaItems";
import { isEmpty } from "utils/html";
import { APPROVAL_STATUS_PENDING, APPROVAL_STATUS_APPROVED, APPROVAL_STATUS_DENIED } from "utils/approvalStatuses";
import { resetPageConfigs, updatePageConfigs } from "redux/app/actions";
import { updatePageHeader } from "redux/pageHeader/actions";
import { updateHandlers, PROGRESS_HUB } from "utils/communication/SignalrClient";
import notifierMessage from "utils/notifierMessage";
import { setSnackbarOptions } from "redux/snackBar/actions";
import UpdateDraftPolicyDialog from "components/Dialogs/UpdateDraftPolicyDialog";

const telemetryPage = "Agenda items";

const SAVE_DELAY = 2000;

const AgendaItemContainer = (props) => {
	const { showSignIn, boardAdmin } = props;
	const widthUpMd = useWidthUp("md");
	const widthDownMd = useWidthDown("md");
	const navigate = useNavigate();
	const location = useLocation();
	const { params: { id: editId } = {} } = useMatch({ path: "/agendaitems/edit/:id", end: true }) || {};
	const { params: { id: approveId } = {} } = useMatch({ path: "/agendaitems/approve/:id", end: true }) || {};
	const id = editId || approveId;
	const { t } = useTranslation("agendaItems");
	const { workflowAdmin, systemAdministrator, userAdmin } = useContext(UserContext).user;
	const { dateFormat, policyEnabled } = useContext(SettingsContext);
	const [isFetchingItem, setIsFetchingItem] = useState(true);
	const [item, setItem] = useState(null);
	const itemRef = useRef(null); // Use to avoid stale closures
	const sendItemChangedEmailsRef = useRef(false);
	const [panels, setPanels] = useState({
		commentsDetails: false,
	});
	const [requestError, setRequestError] = useState(null);
	const [dialogs, setDialogs] = useState({});
	const [savingState, setSavingState] = useState({
		persistObject: null,
		isSaving: false,
		saveStatus: null,
		saveTooltip: "",
		isUpdating: false,
		uploadQueue: [],
		uploadingStatus: null,
		uploadingTooltip: "",
		failedUploads: [],
		timeLastSaved: 0, // Used to delay the next save if the user is still typing
		afterPendingSaves: null,
	});
	const savingStateRef = useRef(savingState);
	const savingTimeout = useRef(null);
	const statusTimeout = useRef(null);
	const [progress, setProgress] = useState({
		label: " ",
		percent: 0,
	});
	const [coverTemplates, setCoverTemplates] = useState(null);
	const [failedFileUploads, setFailedFileUploads] = useState(null);
	const query = new URLSearchParams(location.search);
	const dispatch = useDispatch();
	const withoutErrorMsg = useState(query.get("withoutErrorMsg"));
	const appReducer = useSelector((state) => state.appReducer);
	const [copyingCover, setCopyingCover] = useState(false);
	const enableEditButton = () => {
		setCopyingCover(true);
	};
	const {
		signalR: { client, handler },
	} = appReducer;

	const backToItems = () => {
		const { isSaving, isUploading } = savingStateRef.current;

		if (isSaving) {
			if (confirm(t("unsavedChanges"))) {
				navigate(`/agendaitems${location.search}`);
			} else {
				return;
			}
		} else if (isUploading) {
			if (confirm(t("unsavedUploading"))) {
				navigate(`/agendaitems${location.search}`);
			} else {
				return;
			}
		} else {
			navigate(`/agendaitems${location.search}`);
		}
	};

	const unsavedChangesText = t("unsavedChanges");
	useBackButtonHandler(savingState, "/agendaitems", unsavedChangesText);

	const handleEditWorkflow = () => {
		if (itemRef.current && itemRef.current.workflow) {
			telemetryAddEvent("Agenda item - Edit workflow", { area: "agendaItems" });

			dispatch(
				updatePageConfigs({
					preferedBack: { url: location.pathname + location.search },
					contentMaxWidth: "xl",
				}),
			);

			navigate(`/workflows/edit/${itemRef.current.workflow.id}`);
		}
	};

	const handleDuplicate = () => {
		request
			.post(`${API_HOST}/api/agendaitem/${itemRef.current.guid}/copy`)
			.send({})
			.then((res) => {
				navigate(`/agendaitems/edit/${res.body.guid}`);

				let option = notifierMessage(t("duplicateDialog.snackbar.success"), "success");
				dispatch(setSnackbarOptions(option));

				telemetryAddEvent("Agenda item - Duplicate", { area: "agendaItems" });
			})
			.catch((err) => {
				showSignIn(err, () => {
					handleDuplicate();
				});
			});
	};

	const handleDelete = () => {
		setDialogs({
			delete: true,
		});
	};

	const undoDeleteItem = (obj) => {
		const { id, sbKey } = obj;

		restoreItem(
			id,
			() => {
				navigate(`/agendaitems/edit/${id}`);
			},
			() => {},
		);
		closeSnackbar(sbKey);
	};

	const afterDeleteItem = () => {
		navigate("/agendaitems");
	};

	const clearStatus = () => {
		setSavingState((prev) => ({ ...prev, saveStatus: null, saveTooltip: null }));
	};

	const saveItem = useCallback(
		({ isRetry = false } = {}) => {
			clearTimeout(savingTimeout.current);

			const { persistObject } = savingState;
			if (persistObject.changeSetId !== item?.changeSetId) {
				persistObject.changeSetId = item.changeSetId;
			}

			clearTimeout(statusTimeout.current);
			setSavingState((prev) => ({
				...prev,
				persistObject: null,
				isSaving: true,
				saveStatus: t("agendaMenu:saving"),
				saveTooltip: t("agendaMenu:saveTooltip"),
				timeLastSaved: Date.now(),
			}));

			request
				.put(`${API_HOST}/api/agendaitem/${persistObject.guid}`)
				.withCredentials()
				.timeout({
					response: 300000, // Wait 30 seconds for the server to start sending,
					deadline: 350000, // but allow 35 seconds for the data to finish sending.
				})
				.send(persistObject)
				.then((res) => {
					if (res.status === 200) {
						if (persistObject.id === 0) {
							// New item
							setIsFetchingItem(false);

							navigate(`/agendaitems/edit/${persistObject.guid}`, { replace: true });
						}
						if (!item || !item.id || !item.changeSetId) {
							setItem((prev) => ({
								...res.body.item,
								name: prev?.name || "",
								text: prev?.text || "",
							}));
						}
						setSavingState((prev) => ({
							...prev,
							isSaving: false,
							saveStatus: t("agendaMenu:saved"),
							saveTooltip: `${t("agendaMenu:lastSaved")} ${format(new Date(), `${dateFormat} h:mm a`)}`,
						}));
						statusTimeout.current = setTimeout(clearStatus, STATUS_SAVED_TIMEOUT);
						sendItemChangedEmailsRef.current = sendItemChangedEmailsRef.current || res.body.sendItemChangedEmails;
					} else if (!isRetry) {
						// Only retry once
						clearTimeout(statusTimeout.current);
						setSavingState((prev) => ({
							...prev,
							isSaving: false,
							saveStatus: t("agendaMenu:saveError"),
							saveTooltip: t("agendaMenu:saveErrorTooltip"),
						}));
						saveItem({ isRetry: true });
					}
				})
				.catch((err) => {
					showSignIn(
						err,
						() => {
							saveItem();
						},
						() => {
							// Only retry once
							if (!isRetry) {
								clearTimeout(statusTimeout.current);
								setSavingState((prev) => ({
									...prev,
									isSaving: false,
									saveStatus: t("agendaMenu:saveError"),
									saveTooltip: t("agendaMenu:saveErrorTooltip"),
								}));
								saveItem({ isRetry: true });
							}
						},
					);
				});
		},
		[savingState.persistObject, item?.id, item?.changeSetId],
	);

	const updateItem = (value, field) => {
		clearTimeout(statusTimeout.current);

		setItem((prev) => {
			if (prev[field] === value) {
				return prev; // don't re-save unchanged content;
			}

			const nextItem = { ...prev, [field]: value };

			setSavingState((prevSavingState) => ({ ...prevSavingState, persistObject: { ...nextItem } }));

			return nextItem;
		});
	};

	const loadItem = (id) => {
		if (id) {
			request
				.get(`${API_HOST}/api/agendaitem/${id}`)
				.withCredentials()
				.then((res) => {
					if (res.body) {
						// Existing item
						if (res.body.item && res.body.item.comments) {
							// Give each comment a number to use for the avatar background
							let number = 0;
							const numberCache = {};
							res.body.item.comments.forEach((comment) => {
								if (typeof numberCache[comment.userId] === "undefined") {
									numberCache[comment.userId] = number;
									number++;
								}
								comment.number = numberCache[comment.userId];
							});
						}

						setItem(res.body.item);
						setFailedFileUploads(res.body.failedUploads);
						const { updatedPolicyAttachments } = res?.body?.item;
						setDialogs((prev) => ({
							...prev,
							errorItem: !withoutErrorMsg && res.body.failedUploads && res.body.failedUploads.length > 0,
							policyUpdate: updatedPolicyAttachments && updatedPolicyAttachments.length > 0,
						}));
						setIsFetchingItem(false);

						// Update header based on the item data
						dispatch(
							updatePageConfigs({
								contentMaxWidth: "xl",
								title:
									res.body.item.approvalStatus !== APPROVAL_STATUS_PENDING || !res.body.item.canApprove
										? res.body.item.added
											? t("editItem.titleAdded")
											: t("editItem.title")
										: t("editItem.approvalTitle"),
							}),
						);
						setOverflowMenu({
							item: res.body.item,
							widthDownMd,
						});
					}
				})
				.catch((err) => {
					showSignIn(
						err,
						() => {
							loadItem(id);
						},
						() => {
							setRequestError(err);
						},
					);
				});
		} else {
			// New item
			setSavingState((prev) => ({
				...prev,
				persistObject: {
					id: 0,
					guid: uuid(),
					name: "",
					text: "",
				},
			}));
		}
	};

	const deleteEmptyNewItem = () => {
		// !id indicates that this is a new item
		if (itemRef.current && isEmpty(itemRef.current.name) && isEmpty(itemRef.current.text)) {
			// Deletes a new item that has not been edited
			request
				.delete(`${API_HOST}/api/agendaitem/${itemRef.current.guid}`)
				.send({})
				.then(() => {})
				.catch(() => {});
		}
	};

	const sendItemChangedEmails = () => {
		if (sendItemChangedEmailsRef.current && itemRef.current) {
			// Sends the item changed emails for this item
			request
				.post(`${API_HOST}/api/agendaitem/${itemRef.current.guid}/sendchangedemails`)
				.send({})
				.then(() => {})
				.catch(() => {});
		}
	};

	const closeDialogs = () => {
		setDialogs({});
	};

	const closeSelectMeetingDialog = () => {
		setDialogs((prev) => ({
			...prev,
			selectMeeting: false,
		}));
	};

	const openSelectMeetingDialog = () => {
		setDialogs({
			selectMeeting: true,
		});
	};

	const selectMeetingCallback = (updatedItem) => {
		setItem((prev) => {
			const changed = prev.meetingId > 0;

			telemetryAddEvent(`Agenda item - ${changed ? "Change" : "Select"} meeting`, { area: "agendaItems" });

			return { ...updatedItem };
		});

		closeSelectMeetingDialog();

		let option = notifierMessage(t("selectMeetingDialog.snackbar.success"), "success");
		dispatch(setSnackbarOptions(option));
	};

	const handleSelectMeetingUpdate = (selection) => {
		const data = {
			...selection,
			agendaItemId: item.guid,
		};

		updateSelectedMeeting(data, selectMeetingCallback, setRequestError);
	};

	const closeFileUploadFailureDialog = () => {
		setFailedFileUploads(null);
	};

	const handleSelectMeeting = () => {
		openSelectMeetingDialog();
	};

	const openSubmitForApprovalDialog = () => {
		setDialogs({
			submitForApproval: true,
		});
	};

	const closeSubmitForApprovalDialog = () => {
		setDialogs((prev) => ({
			...prev,
			submitForApproval: false,
		}));
	};

	const handleSubmitForApproval = (selection) => {
		const data = {
			...selection,
			agendaItemId: itemRef.current.guid,
		};

		request
			.post(`${API_HOST}/api/workflow/${selection.workflowId}/submit`)
			.withCredentials()
			.send(data)
			.then((res) => {
				if (res.status === 200) {
					setItem(res.body.item);

					let option = notifierMessage(t("submitForApprovalDialog.snackbar.success"), "success");
					dispatch(setSnackbarOptions(option));
				}
				if (!res.body.meetingInPast) {
					createAttachmentsPdf();
					closeSubmitForApprovalDialog();

					telemetryAddEvent("Agenda item - Submit for approval", { area: "agendaItems" });
				}
			})
			.catch((err) => {
				showSignIn(err, () => {
					handleSubmitForApproval(selection);
				});
			});
	};

	const openCancelApprovalDialog = () => {
		setDialogs({
			cancelApproval: true,
		});
	};

	const closeCancelApprovalDialog = () => {
		setDialogs((prev) => ({
			...prev,
			cancelApproval: false,
		}));
	};

	const handleCancelApproval = (selection) => {
		const data = {
			agendaItemId: itemRef.current.guid,
		};

		request
			.post(`${API_HOST}/api/workflow/${itemRef.current.workflow.id}/cancel`)
			.withCredentials()
			.send(data)
			.then((res) => {
				if (res.status === 200) {
					setItem(res.body.item);

					let option = notifierMessage(t("cancelApprovalDialog.snackbar.success"), "success");
					dispatch(setSnackbarOptions(option));
				}
				closeCancelApprovalDialog();
			})
			.catch((err) => {
				showSignIn(err, () => {
					handleCancelApproval(selection);
				});
			});
	};

	const handleCommentsDetails = () => {
		setPanels((prev) => {
			if (!prev.commentsDetails) {
				// Reload the comments
				loadItem(id);

				telemetryAddEvent("Agenda item - Open comments/details", { area: "agendaItems" });
			}

			return {
				commentsDetails: !prev.commentsDetails,
			};
		});
	};

	const closeItemDeleteDialog = () => {
		setDialogs((prev) => ({
			...prev,
			delete: false,
		}));
	};

	const openApproveDialog = () => {
		setDialogs({
			approve: true,
		});
	};

	const closeApproveDialog = () => {
		setDialogs((prev) => ({
			...prev,
			approve: false,
		}));
	};

	const handleApprove = (comment) => {
		const data = {
			comment,
			agendaItemId: itemRef.current.guid,
		};

		request
			.post(`${API_HOST}/api/workflow/${itemRef.current.workflow.id}/approve`)
			.withCredentials()
			.send(data)
			.then((res) => {
				if (res.status === 200) {
					setItem(res.body.item);

					telemetryAddEvent("Agenda item - Approve", { area: "agendaItems" });
				}

				closeApproveDialog();

				navigate("/agendaitems?tab=2");
			})
			.catch((err) => {
				showSignIn(err, () => {
					handleApprove(comment);
				});
			});
	};

	const openRejectDialog = () => {
		setDialogs({
			reject: true,
		});
	};

	const closeRejectDialog = () => {
		setDialogs((prev) => ({
			...prev,
			reject: false,
		}));
	};

	const handleReject = (comment) => {
		const data = {
			comment,
			agendaItemId: itemRef.current.guid,
		};

		request
			.post(`${API_HOST}/api/workflow/${itemRef.current.workflow.id}/reject`)
			.withCredentials()
			.send(data)
			.then((res) => {
				if (res.status === 200) {
					setItem(res.body.item);

					telemetryAddEvent("Agenda item - Reject", { area: "agendaItems" });
				}

				closeRejectDialog();

				navigate("/agendaitems?tab=2");
			})
			.catch((err) => {
				showSignIn(err, () => {
					handleReject(comment);
				});
			});
	};

	const openSendReminderDialog = () => {
		setDialogs({
			sendReminder: true,
		});
	};

	const closeSendReminderDialog = () => {
		setDialogs((prev) => ({
			...prev,
			sendReminder: false,
		}));
	};

	const openAddPolicy = (options) => {
		setDialogs((prev) => ({
			...prev,
			addPolicy: options,
		}));
	};

	const openAddGoals = (options) => {
		setDialogs((prev) => ({
			...prev,
			addGoals: options,
		}));
	};

	const closeAddPolicyDialog = () => {
		setDialogs((prev) => ({
			...prev,
			addPolicy: false,
		}));
	};

	const closeAddGoalsDialog = () => {
		setDialogs((prev) => ({
			...prev,
			addGoals: false,
		}));
	};

	const closePolicyUpdateDialog = () => {
		setDialogs((prev) => ({
			...prev,
			policyUpdate: false,
		}));
	};

	const checkUploadStatus = () => {
		const { uploadQueue } = savingState;

		let status = null;
		let tooltip = null;
		for (let i = 0; i < uploadQueue.length; i++) {
			if (!uploadQueue[i].complete) {
				if (tooltip) {
					tooltip += ` / ${uploadQueue[i].file.name}`;
				} else {
					tooltip = uploadQueue[i].file.name;
				}
			}
		}
		if (tooltip) {
			status = t("agendaMenu:uploading");
		}
		setSavingState((prev) => ({
			...prev,
			uploadingStatus: status,
			uploadingTooltip: tooltip,
		}));
	};

	const completeFileUpload = (itemGuid, fileGuid, error) => {
		const { uploadQueue } = savingState;
		let allUploadsComplete = true;

		for (let i = 0; i < uploadQueue.length; i++) {
			if (uploadQueue[i].guid === itemGuid && uploadQueue[i].fileGuid === fileGuid) {
				uploadQueue[i].complete = true;
				uploadQueue[i].error = error;
			}

			if (uploadQueue[i].complete == false) {
				allUploadsComplete = false;
			}
		}

		// Create attachments pdf first time attachments are uploaded
		if (uploadQueue && uploadQueue.length > 0 && allUploadsComplete) {
			createAttachmentsPdf();
		}

		setSavingState((prev) => ({
			...prev,
			uploadQueue,
		}));

		checkUploadStatus();
	};

	const sendFiletoAPI = (fileData, isRetry = false) => {
		setSavingState((prev) => ({
			...prev,
			isUploading: true,
		}));

		request
			.post(`${API_HOST}/api/documents/uploadattachments`)
			.withCredentials()
			.send(fileData)
			.then((res) => {
				if (res.status === 200) {
					setSavingState((prev) => ({
						...prev,
						isUploading: false,
						failedUploads: prev.failedUploads.concat(
							(res.body.invalidFiles || []).map((name) => ({
								name,
							})),
						),
					}));

					forEach((attachment) => {
						completeFileUpload(attachment.itemGuid, attachment.guid);
					}, res.body.Attachments);
					checkUploadStatus();
				}
			})
			.catch((err) => {
				const fileError = err.status === 400 || err.status === 500 || isRetry;
				setSavingState((prev) => ({
					...prev,
					isUploading: false,
					failedUploads: prev.failedUploads.concat(
						fileError
							? (err.response.body.invalidFiles || []).map((name) => ({
									name,
								}))
							: [],
					),
				}));
				checkUploadStatus();

				if (!fileError) {
					showSignIn(
						err,
						() => {
							sendFiletoAPI(fileData);
						},
						!isRetry
							? () => {
									// Something went wrong, so wait 5 seconds and try again
									setTimeout(() => {
										sendFiletoAPI(fileData, true);
									}, 5000);
								}
							: undefined,
					);
				}
			});
	};

	const queueFileUploads = (guid, fileUploads, fileData) => {
		for (let i = 0; i < fileUploads.length; i++) {
			setSavingState((prev) => {
				prev.uploadQueue.push({
					guid,
					fileGuid: fileUploads[i].guid,
					file: fileUploads[i],
					complete: false,
					error: null,
				});

				return {
					...prev,
					uploadQueue: prev.uploadQueue,
					failedUploads: [],
				};
			});
		}
		checkUploadStatus();

		sendFiletoAPI(fileData);
	};

	const invalidFileExtension = (guid, fileNames) => {
		if (fileNames.length > 0) {
			setFailedFileUploads(fileNames);
		}
	};

	const handleCloseErrorItemDialog = () => {
		setDialogs((prev) => ({
			...prev,
			errorItem: false,
		}));
		setFailedFileUploads(null);
	};

	const createAttachmentsPdf = (callback) => {
		const guid = uuid();

		updateHandlers(dispatch, handler, PROGRESS_HUB, { announceProgress: updateProgress });

		client.ensureStarted().then(() => client.progressHub.registerProgressGuid(guid));

		setItem((prev) => ({
			...prev,
			attachmentsPdf: {
				...prev.attachmentsPdf,
				id: 0,
				url: "",
			},
		}));

		request
			.post(`${API_HOST}/api/agendaitem/${itemRef.current.guid}/createattachmentspdf`)
			.withCredentials()
			.send({ progressGuid: guid })
			.then((res) => {
				if (res.status === 200) {
					const { body: { created = false } = {} } = res;
					setProgress({
						label: " ",
						percent: created ? 100 : 0,
					});
					setItem((prev) => ({
						...prev,
						attachmentsPdf: res.body,
					}));

					// Hide the progress bar once the PDF is generated
					if (created) {
						setTimeout(() => {
							setProgress({
								label: " ",
								percent: 0,
							});
						}, 2000);
					}

					if (callback && typeof callback === "function") {
						callback();
					}
				}
			})
			.catch((err) => {});
	};

	const updateProgress = (percentage, message) => {
		setProgress({
			label: message,
			percent: percentage,
		});

		if (percentage === 100) {
			setTimeout(() => {
				setProgress({
					label: " ",
					percent: 0,
				});
			}, 2000);
		}
	};

	const setOverflowMenu = (options) => {
		if (options && options.item) {
			const menuOptions = [];
			if (options.widthDownMd) {
				const isResubmit = options.item.approvalStatus === APPROVAL_STATUS_DENIED;

				if (
					options.item.canUpdate &&
					options.item.approvalStatus !== APPROVAL_STATUS_APPROVED &&
					(!isEmpty(options.item.name) || !isEmpty(options.item.text))
				) {
					menuOptions.push({
						label: t("toolbar.selectMeeting"),
						tooltip: t("tooltips.selectMeeting"),
						actionFunction: handleSelectMeeting,
						"data-cy": "select-meeting",
					});
				}
				if (
					options.item.canUpdate &&
					options.item.meeting &&
					(!options.item.workflow || ![APPROVAL_STATUS_PENDING, APPROVAL_STATUS_APPROVED].includes(options.item.approvalStatus))
				) {
					menuOptions.push({
						label: t(`toolbar.${isResubmit ? "resubmitItem" : "submitItem"}`),
						tooltip: t(`tooltips.${isResubmit ? "resubmitItem" : "submitItem"}`),
						actionFunction: openSubmitForApprovalDialog,
						"data-cy": "submit-item",
					});
				}
				if (options.item.canUpdate && options.item.workflow && options.item.approvalStatus === APPROVAL_STATUS_PENDING) {
					menuOptions.push({
						label: t("toolbar.cancelApproval"),
						tooltip: t("tooltips.cancelApproval"),
						actionFunction: openCancelApprovalDialog,
						"data-cy": "cancel-approval",
					});
				}
			}
			if (
				(boardAdmin || workflowAdmin || systemAdministrator) &&
				options.item.workflow &&
				[APPROVAL_STATUS_PENDING, APPROVAL_STATUS_DENIED].includes(options.item.approvalStatus)
			) {
				menuOptions.push({
					label: t("buttons.editWorkflow"),
					tooltip: t("tooltips.editWorkflow"),
					actionFunction: handleEditWorkflow,
					"data-cy": "edit-workflow",
				});
			}
			if (options.item.canUpdate) {
				menuOptions.push({
					label: t("buttons.duplicate"),
					tooltip: t("tooltips.duplicate"),
					actionFunction: handleDuplicate,
					"data-cy": "duplicate",
				});
			}
			if (options.item.canUpdate && options.item.workflow && options.item.approvalStatus === APPROVAL_STATUS_PENDING) {
				menuOptions.push({
					label: t("buttons.sendSmsReminder"),
					tooltip: t("tooltips.sendSmsReminder"),
					actionFunction: openSendReminderDialog,
					"data-cy": "sendSmsReminder",
				});
			}
			if (options.item.canDelete && options.item.approvalStatus !== APPROVAL_STATUS_APPROVED) {
				menuOptions.push({
					label: t("buttons.delete"),
					tooltip: t("tooltips.delete"),
					actionFunction: handleDelete,
					"data-cy": "delete",
				});
			}
			dispatch(
				updatePageHeader({
					menuOptions,
				}),
			);
		}
	};

	const loadCoverTemplates = () => {
		request
			.get(`${API_HOST}/api/agendaitems/covertemplates`)
			.withCredentials()
			.then((res) => {
				if (res.body) {
					setCoverTemplates(res.body);
				}
			})
			.catch((err) => {
				showSignIn(
					err,
					() => {
						loadCoverTemplates();
					},
					() => {
						setRequestError(err);
					},
				);
			});
	};

	const editCover = (id, coverTemplateId) => {
		if (id) {
			const coverTemplate = coverTemplateId > 0 ? coverTemplates.find((c) => c.id === coverTemplateId) : null;
			if (coverTemplate && (item.name == null || item.name.length == 0)) {
				updateItem(`<p>${coverTemplate.name}</p>`, "name");
			}
			setSavingState((prev) => ({
				...prev,
				afterPendingSaves: () => {
					request
						.post(`${API_HOST}/api/agendaitem/${id}/editcover`)
						.withCredentials()
						.send({ coverTemplateId })
						.then((res) => {
							if (res.body) {
								const { documentId } = res.body;
								if (res.body.item !== null) {
									setItem(res.body.item);
								}
								setCopyingCover(false);
								navigate(`/document/edit/${documentId}`);
							}
						})
						.catch((err) => {
							showSignIn(
								err,
								() => {
									editCover(id, coverTemplateId);
								},
								() => {
									setRequestError(err);
								},
							);
						});
				},
			}));
		}
	};

	useEffect(() => {
		savingStateRef.current = savingState;
	}, [savingState]);

	useEffect(() => {
		if (!savingState.isSaving) {
			if (savingState.persistObject) {
				clearTimeout(savingTimeout.current);

				const timeSinceLastSaved = Date.now() - savingState.timeLastSaved;
				if (timeSinceLastSaved > SAVE_DELAY || savingState.afterPendingSaves) {
					saveItem();
				} else {
					// Delay the save to prevent too many requests
					savingTimeout.current = setTimeout(saveItem, SAVE_DELAY - timeSinceLastSaved);
				}
			} else if (typeof savingState.afterPendingSaves === "function") {
				// This should only run when there are no currently active or pending saves
				savingState.afterPendingSaves();
				setSavingState((prev) => ({ ...prev, afterPendingSaves: null }));
			}
		}
	}, [savingState.persistObject, savingState.isSaving, savingState.afterPendingSaves, savingState.timeLastSaved]);

	useEffect(() => {
		if (savingState.failedUploads && savingState.failedUploads.length > 0) {
			setDialogs({
				failedUploads: savingState.failedUploads,
			});
		}
	}, [savingState.failedUploads]);

	useEffect(() => {
		setOverflowMenu({
			item,
			widthDownMd,
		});
	}, [item, widthDownMd]);

	useEffect(() => {
		itemRef.current = item;
	}, [item]);

	useEffect(() => {
		if (item?.attachmentsPdf?.outOfDate) {
			createAttachmentsPdf();
		}
	}, [item?.attachmentsPdf?.outOfDate]);

	useEffect(() => {
		dispatch(resetPageConfigs({}));
		dispatch(
			updatePageConfigs({
				title: t("editItem.title"),
				contentMaxWidth: "xl",
				back: { url: `/agendaitems${window.location.search || "?tab=2"}`, action: backToItems },
				telemetryPage,
			}),
		);
		loadItem(id);
		loadCoverTemplates();
		if (id) {
			telemetryAddEvent("Agenda item - Open", { area: "agendaItems" });
		}

		return () => {
			deleteEmptyNewItem();
			sendItemChangedEmails();
		};
	}, [id]);

	if (requestError) {
		const errormsg =
			requestError.response && requestError.response.body && requestError.response.body.Message
				? requestError.response.body.Message
				: `${requestError.status} ${requestError.message}`;
		return (
			<Typography variant="h3" style={{ padding: "24px" }}>
				{errormsg}
			</Typography>
		);
	}

	const { saveStatus, saveTooltip, uploadingStatus, uploadingTooltip } = savingState;
	const { comments = [] } = item || {};

	return (
		<>
			<FileUploadFailureDialog
				show={failedFileUploads && failedFileUploads.length > 0}
				failedFileUploads={failedFileUploads}
				onClose={closeFileUploadFailureDialog}
			/>
			{dialogs.failedUploads && <UploadErrorDialog failedUploads={dialogs.failedUploads} onClose={closeDialogs} />}
			{dialogs.selectMeeting && (
				<AgendaItemSelectMeetingDialog
					show={dialogs.selectMeeting}
					item={item}
					onClose={closeSelectMeetingDialog}
					onSelect={handleSelectMeetingUpdate}
				/>
			)}
			{dialogs.submitForApproval && (
				<SubmitForApprovalDialog
					show={dialogs.submitForApproval}
					item={item}
					onSubmit={handleSubmitForApproval}
					onClose={closeSubmitForApprovalDialog}
				/>
			)}
			{dialogs.cancelApproval && (
				<CancelApprovalDialog
					show={dialogs.cancelApproval}
					item={item}
					onCancel={handleCancelApproval}
					onClose={closeCancelApprovalDialog}
				/>
			)}
			{dialogs.sendReminder && (
				<SendReminderDialog show={dialogs.sendReminder} item={item} onClose={closeSendReminderDialog} userAdmin={userAdmin} />
			)}
			{dialogs.approve && (
				<AgendaItemApproveDialog show={dialogs.approve} item={item} onApprove={handleApprove} onClose={closeApproveDialog} />
			)}
			{dialogs.reject && <AgendaItemRejectDialog show={dialogs.reject} item={item} onApprove={handleReject} onClose={closeRejectDialog} />}
			{dialogs.delete && item && (
				<AgendaItemDeleteDialog
					show={dialogs.delete}
					afterDelete={afterDeleteItem}
					onClose={closeItemDeleteDialog}
					item={item}
					undoDeleteItem={undoDeleteItem}
				/>
			)}
			{dialogs.addPolicy && <AddPolicyToAgendaDialog options={dialogs.addPolicy} onClose={closeAddPolicyDialog} showSignIn={showSignIn} />}
			{dialogs.addGoals && (
				<AddGoalsToAgendaDialog options={dialogs.addGoals} onClose={closeAddGoalsDialog} showSignIn={showSignIn} isAgendaItem />
			)}
			{dialogs.policyUpdate && (
				<UpdateDraftPolicyDialog
					onClose={closePolicyUpdateDialog}
					show={true}
					draftPolicyList={item.updatedPolicyAttachments}
					uploadPayloadKey="agendaItemId"
					itemId={item.id}
				/>
			)}
			{item && (
				<AgendaItemTopBar
					isResubmit={item.approvalStatus === APPROVAL_STATUS_DENIED}
					openSubmitForApprovalDialog={
						widthUpMd &&
						item.canUpdate &&
						item.meeting &&
						(!item.workflow || ![APPROVAL_STATUS_PENDING, APPROVAL_STATUS_APPROVED].includes(item.approvalStatus))
							? openSubmitForApprovalDialog
							: null
					}
					openCancelApprovalDialog={
						widthUpMd && item.canUpdate && item.workflow && item.approvalStatus === APPROVAL_STATUS_PENDING
							? openCancelApprovalDialog
							: null
					}
					handleCommentsDetails={handleCommentsDetails}
					saveStatus={saveStatus}
					saveTooltip={saveTooltip}
					uploadingStatus={uploadingStatus}
					uploadingTooltip={uploadingTooltip}
					panels={panels}
				/>
			)}
			<ComponentContainer padding="16px">
				{item && !isFetchingItem ? (
					<>
						{widthDownMd && (
							<AgendaItemCommentsDetails item={item} comments={comments} inMainContent hideComments linkMeeting={boardAdmin} />
						)}
						<AgendaItem
							item={item}
							updateItem={updateItem}
							queueFileUploads={queueFileUploads}
							invalidFileExtension={invalidFileExtension}
							addPolicy={policyEnabled ? openAddPolicy : undefined}
							addGoals={openAddGoals}
							handleCloseErrorItemDialog={handleCloseErrorItemDialog}
							showErrorItemDialog={dialogs.errorItem}
							failedUploads={failedFileUploads}
							handleApprove={openApproveDialog}
							handleReject={openRejectDialog}
							handleUpdateAttachments={createAttachmentsPdf}
							createAttachmentsPdfProgress={progress}
							coverTemplates={coverTemplates}
							editCover={editCover}
							handleSelectMeeting={
								widthUpMd &&
								item.canUpdate &&
								item.approvalStatus !== APPROVAL_STATUS_APPROVED &&
								(!isEmpty(item.name) || !isEmpty(item.text))
									? handleSelectMeeting
									: null
							}
							telemetryPage={telemetryPage}
							enableEditButton={enableEditButton}
							copyingCover={copyingCover}
						/>
						{widthDownMd && <AgendaItemCommentsDetails item={item} comments={comments} inMainContent hideDetails />}
						{widthUpMd && (
							<RightPanelContainer id="right-panel-container" open={panels.commentsDetails} drawerWidth={335} fullHeight float>
								{panels.commentsDetails && <AgendaItemCommentsDetails item={item} comments={comments} linkMeeting={boardAdmin} />}
							</RightPanelContainer>
						)}
					</>
				) : (
					<CircularProgressIndicator />
				)}
			</ComponentContainer>
		</>
	);
};

export default withErrorHandling(AgendaItemContainer);
