import PropTypes from 'prop-types';

import icons from '../../assets/icons/icons';
import { blackColor } from '../../assets/jss/shared';

const Icon = (props) => {
	const { name, color, size } = props;
	const ComputedIcon = icons[name] && icons[name](size, color);
	return ComputedIcon;
};

Icon.propTypes = {
	name: PropTypes.string.isRequired,
	color: PropTypes.string,
	size: PropTypes.string,
};

Icon.defaultProps = {
	color: blackColor[0],
	size: '24px',
};

export default Icon;
