import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import parse from "html-react-parser";

import { Dialog, DialogContent, DialogTitle, Typography, Chip, Grid, IconButton } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { useWidthDown } from "atlas/utils/useWidth";
import Icon from "atlas/components/Icon/Icon";
import NoticeCard from "atlas/components/Cards/NoticeCard";
import Tooltip from "atlas/components/Tooltip/Tooltip";
import { primaryColor, successColor, errorColor, warningColor } from "atlas/assets/jss/shared";
import { STATUS_WARNING, STATUS_GOOD } from "atlas/assets/jss/utils/statusIndicators";
import { updateNotice } from "redux/app/actions";
import processHtml from "utils/processHtml";
import checkVotingFinished, { getVotingResults } from "views/LiveMeeting/utils/votingUtils";

const useStyles = makeStyles(() => ({
	dialog: {
		pointerEvents: "none",
	},
	dialogTitle: {
		display: "inline-flex",
		textTransform: "none",
	},
	leftDialogBorder: {
		borderLeft: `8px solid ${primaryColor[0]}`,
	},
	actions: {
		justifyContent: "center",
		paddingTop: "16px",
		paddingBottom: "16px",
		pointerEvents: "auto",
	},
	chip: {
		color: "#fff",
		borderRadius: "4px",
		float: "right",
	},
	votePassed: {
		backgroundColor: successColor,
	},
	voteFailed: {
		backgroundColor: errorColor,
	},
	colorInherit: {
		color: "inherit",
	},
	quorumNotMet: {
		backgroundColor: warningColor,
		color: "#000",
	},
	voteResults: {
		margin: "16px 0",
		"& > div": {
			marginTop: "8px",
		},
	},
	resultsLabel: {
		fontWeight: "bold",
	},
	noticeCard: {
		margin: "16px 0",
		pointerEvents: "auto",
	},
	itemText: {
		"& > p": {
			margin: "0",
		},
		"& > p:first-child": {
			display: "inline-block",
		},
	},
	closeIcon: {
		margin: "-12px",
		pointerEvents: "auto",
	},
}));

const VotingResultsPublicDialog = (props) => {
	const { show, votingData, votingSettings = { votingLabels: {} }, votingDialogClose } = props;
	const widthDownSm = useWidthDown("sm");
	const { t } = useTranslation("meetings");
	const [votingResults, setVotingResults] = useState(null);
	const [votingFinished, setVotingFinished] = useState(false);
	const classes = useStyles();

	useEffect(() => {
		if (votingData && votingData.itemInProgress) {
			setVotingResults(getVotingResults({ fields: { Voting: { Value: votingData.itemInProgress.itemVotingData } } }, votingData.rollCall));
			setVotingFinished(
				votingData.itemVotingData && votingData.itemVotingData.length > 0
					? checkVotingFinished({ fields: { Voting: { Value: votingData.itemInProgress.itemVotingData } } }, votingData.rollCall)
					: votingData.showResults
					? true
					: false,
			);
		}
	}, [votingData]);

	return (
		show &&
		votingData && (
			<>
				<Dialog
					className={classes.dialog}
					maxWidth="sm"
					fullWidth
					open={show}
					fullScreen={widthDownSm}
					data-cy="voting-dialog"
					BackdropProps={{ invisible: true }}
					PaperProps={{ className: clsx({ [classes.leftDialogBorder]: votingResults && votingFinished }) }}
				>
					<DialogTitle>
						<Grid container spacing={0} direction="row" justifyContent="flex-start">
							<Grid item xs={11}>
								<Typography variant="h4" className={classes.dialogTitle}>
									{votingResults && votingFinished ? t("votingDialog.resultsTitle") : t("votingDialog.title")}
								</Typography>
								{votingResults && votingFinished && (
									<Chip
										size="small"
										className={clsx(classes.chip, {
											[classes.votePassed]:
												votingResults.quorumMet &&
												votingResults.votePassed &&
												votingData.itemInProgress.itemVotingData &&
												votingData.itemInProgress.itemVotingData.length > 0,
											[classes.voteFailed]:
												votingResults.quorumMet &&
												!votingResults.votePassed &&
												votingData.itemInProgress.itemVotingData &&
												votingData.itemInProgress.itemVotingData.length > 0,
											[classes.quorumNotMet]: !votingResults.quorumMet,
											[classes.colorInherit]:
												votingData.showResults &&
												(votingData.itemInProgress.itemVotingData == null || votingData.itemInProgress.itemVotingData.length === 0),
										})}
										label={votingData.itemInProgress.disposition}
										data-cy={`chip-disposition`}
									/>
								)}
							</Grid>
							<Grid item style={{ textAlign: "right" }} xs={1}>
								<Tooltip PopperProps={{ disablePortal: true }} title={t("app:tooltips.closeButton")}>
									<IconButton
										className={classes.closeIcon}
										aria-label={t("app:tooltips.closeButton")}
										onClick={votingDialogClose}
										data-cy="closeIcon"
										size="large"
									>
										{<Icon name="close" />}
									</IconButton>
								</Tooltip>
							</Grid>
						</Grid>
					</DialogTitle>
					<DialogContent>
						<div>
							<div className={classes.itemText}>{parse(votingData.itemInProgress.agendaItemText || "")}</div>
							<div className={classes.itemText}>
								{`${t("motions.panel.title")}: `}
								{parse(votingData.itemInProgress.itemText || "")}
							</div>
						</div>
						{votingResults &&
							votingFinished &&
							votingData.itemInProgress.itemVotingData &&
							votingData.itemInProgress.itemVotingData.length > 0 && (
								<div className={classes.voteResults}>
									<div>
										<span className={classes.resultsLabel}>{`${votingResults.forVotesCount} votes ${
											votingSettings.votingLabels.for || t("voting.for")
										}: `}</span>
										{votingResults.forVotes.map((vote, index) => {
											return `${index > 0 ? ", " : ""}${vote.Name}`;
										})}
									</div>
									<div>
										<span className={classes.resultsLabel}>{`${votingResults.againstVotesCount} votes ${
											votingSettings.votingLabels.against || t("voting.against")
										}: `}</span>
										{votingResults.againstVotes.map((vote, index) => {
											return `${index > 0 ? ", " : ""}${vote.Name}`;
										})}
									</div>
									<div>
										<span className={classes.resultsLabel}>{`${votingResults.abstainVotesCount} votes ${
											votingSettings.votingLabels.abstain || t("voting.abstain")
										}: `}</span>
										{votingResults.abstainVotes.map((vote, index) => {
											return `${index > 0 ? ", " : ""}${vote.Name}`;
										})}
									</div>
								</div>
							)}
						{votingData.showResults &&
							(votingData.itemInProgress.itemVotingData == null || votingData.itemInProgress.itemVotingData.length === 0) && (
								<div className={classes.voteResults}>
									<div style={{ paddingLeft: "16px" }}>
										<Typography variant="h4" className={classes.resultsLabel}>{`${t("movedBy")}: ${
											votingData.itemInProgress.movedBy
										}`}</Typography>
									</div>
									<div style={{ paddingLeft: "16px" }}>
										<Typography variant="h4" className={classes.resultsLabel}>{`${t("secondedBy")}: ${
											votingData.itemInProgress.secondedBy
										}`}</Typography>
									</div>
								</div>
							)}
						{votingResults != null && (!votingFinished || !votingResults.quorumMet) && (
							<NoticeCard
								className={classes.noticeCard}
								updateNotice={updateNotice}
								icon={`${votingResults.quorumMet ? "status-success" : "status-alert"}`}
								label={
									votingResults.quorumMet
										? t("votingDialog.notice.success", { voteType: currentUserVote })
										: t("votingDialog.notice.quorumLost")
								}
								processHtml={processHtml}
								status={votingResults.quorumMet ? STATUS_GOOD : STATUS_WARNING}
								thickStatus
								iconColor={votingResults.quorumMet ? successColor : warningColor}
								dataCy="vote-recorded"
							/>
						)}
					</DialogContent>
				</Dialog>
			</>
		)
	);
};

export default VotingResultsPublicDialog;
