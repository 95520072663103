import View from "@ckeditor/ckeditor5-ui/src/view";
import ViewCollection from "@ckeditor/ckeditor5-ui/src/viewcollection";
import ButtonView from "@ckeditor/ckeditor5-ui/src/button/buttonview";
import SwitchButtonView from "@ckeditor/ckeditor5-ui/src/button/switchbuttonview";
import Collection from "@ckeditor/ckeditor5-utils/src/collection";
import InputFileView from "./inputFileView";

import FocusTracker from "@ckeditor/ckeditor5-utils/src/focustracker";
import FocusCycler from "@ckeditor/ckeditor5-ui/src/focuscycler";
import KeystrokeHandler from "@ckeditor/ckeditor5-utils/src/keystrokehandler";

import checkIcon from "@ckeditor/ckeditor5-core/theme/icons/check.svg";
import cancelIcon from "@ckeditor/ckeditor5-core/theme/icons/cancel.svg";
import submitHandler from "@ckeditor/ckeditor5-ui/src/bindings/submithandler";
import InlineFileFeature from "./inlineFileFeature";
import { isAttachmentValue } from "./inlineFileUtils";

import forEach from "lodash/fp/forEach";

export default class InlineFileViewForm extends View {
	constructor(locale, allowedFileExtensions, translations, featureConfigs = []) {
		super(locale);
		const t = locale.t;
		this.set("href");
		this.set("files");
		this.allowedFileExtensions = allowedFileExtensions;
		this.featureConfigs = featureConfigs;
		this.translations = translations;
		this.featureStatuses = new Collection();

		this.focusTracker = new FocusTracker();
		this.keystrokes = new KeystrokeHandler();

		this._featureSwitches = this._createFeatureSwitches(this.featureConfigs, this.featureStatuses);

		this.urlInputView = this._createUrlInput();
		this.featureView = this._createFeaturesView();
		this.saveButtonView = this._createButton(
			this.translations ? this.translations.tooltips.editConfirm : "Save",
			checkIcon,
			"ck-button-save",
		);
		this.saveButtonView.type = "submit";
		this.cancelButtonView = this._createButton(
			this.translations ? this.translations.tooltips.editCancel : "Cancel",
			cancelIcon,
			"ck-button-cancel",
			"cancel",
		);
		this.children = this._createFormChildren();

		this.bind("files").to(this.urlInputView, "files");

		this._focusables = new ViewCollection();
		this._focusCycler = new FocusCycler({
			focusables: this._focusables,
			focusTracker: this.focusTracker,
			keystrokeHandler: this.keystrokes,
			actions: {
				focusPrevious: "shift + tab",
				focusNext: "tab",
			},
		});

		const classList = ["ck", "ck-link-form", "ck-link-form_layout-vertical", "ck-inlinefile-form"];

		this.setTemplate({
			tag: "form",
			attributes: {
				class: classList,
				tabindex: "-1",
			},
			children: this.children,
		});
	}

	render() {
		super.render();

		submitHandler({
			view: this,
		});

		const childViews = [this.urlInputView, this.saveButtonView, this.cancelButtonView];

		childViews.forEach((v) => {
			this._focusables.add(v);
			this.focusTracker.add(v.element);
		});

		this.keystrokes.listenTo(this.element);
	}

	focus() {
		this._focusables.focusFirst();
	}

	resetForm() {
		this.resetFiles();
		if (typeof this.href === "string") {
			const hrefInfo = JSON.parse(this.href);
			this.setPreviewText(hrefInfo.name);
			this.enableMultipleSelect(false);
			this._updateFeatureStatus(hrefInfo.features);
		} else {
			this.setPreviewText("Select file to upload");
			this.enableMultipleSelect(true);
			this._updateFeatureStatus([]);
			this.urlInputView.click();
		}
	}

	resetFiles() {
		this.urlInputView.resetFiles();
	}

	getFilesToUpload() {
		const files = this.urlInputView.getFiles();
		if (files) {
			return files;
		} else {
			return [];
		}
		//return this.urlInputView.getFiles();
	}

	getFeatureStatuses() {
		const features = [];
		const switches = Array.from(this._featureSwitches);
		forEach((item) => {
			const config = this.featureConfigs.get(item.id);
			features.push({ id: item.id, value: item.isOn, anchorTitle: config.anchorTitle });
		}, switches);
		return features;
	}

	enableMultipleSelect(status) {
		this.urlInputView.enableMultipleSelect(status);
	}
	setAcceptableFileExtensions(extensions) {
		this.urlInputView.setAcceptableFileExtensions(extensions);
	}
	setPreviewText(name) {
		this.urlInputView.setPreviewText(name);
	}

	_createUrlInput() {
		const t = this.locale.t;

		const fileInput = new InputFileView(this.locale, this.allowedFileExtensions, this.translations);
		return fileInput;
	}

	_createButton(label, icon, className, eventName) {
		const button = new ButtonView(this.locale);

		button.set({
			label,
			icon,
			tooltip: true,
		});

		button.extendTemplate({
			attributes: {
				class: className,
			},
		});

		if (eventName) {
			button.delegate("execute").to(this, eventName);
		}

		return button;
	}

	_createFeaturesView() {
		const featureButtonsView = new View();
		if (this._featureSwitches.length) {
			featureButtonsView.setTemplate({
				tag: "div",
				attributes: {
					class: ["ck-file-features"],
				},
				children: [
					{
						tag: "ul",
						children: this._featureSwitches.map((switchButton) => ({
							tag: "li",
							children: [switchButton],
							attributes: {
								class: ["ck", "ck-list__item"],
							},
						})),
						attributes: {
							class: ["ck", "ck-reset", "ck-list"],
						},
					},
				],
			});
		}

		return featureButtonsView;
	}

	_createFeatureSwitches(featureConfigs, featureStatuses) {
		const switches = this.createCollection();

		for (const featureConfig of featureConfigs) {
			const switchButton = new SwitchButtonView(this.locale);
			const featureStatus = new InlineFileFeature({
				id: featureConfig.id,
				label: featureConfig.label,
				className: featureConfig.className,
				defaultValue: featureConfig.defaultValue,
				disabledValue: featureConfig.disabledValue,
				isEnabled: featureConfig.isEnabled,
				anchorTitle: featureConfig.anchorTitle,
				tooltipDisabledOn: featureConfig.tooltipDisabledOn,
			});

			switchButton.set({
				id: featureConfig.id,
				label: featureConfig.label,
				withText: true,
			});

			switchButton.bind("isOn").to(featureStatus, "value");
			switchButton.bind("isEnabled").to(featureStatus, "isEnabled");
			switchButton
				.bind("tooltip")
				.to(
					featureStatus,
					"isEnabled",
					featureStatus,
					"value",
					featureStatus,
					"tooltipDisabledOn",
					(isEnabled, isOn, tooltipDisabledOn) => {
						if (!isEnabled && isOn) {
							return tooltipDisabledOn;
						}
						return "";
					},
				);

			switchButton.on("execute", () => {
				featureStatus.set("value", !switchButton.isOn);
			});

			switches.add(switchButton);
			featureStatuses.add(featureStatus);
		}

		return switches;
	}

	_createFormChildren() {
		const children = this.createCollection();

		children.add(this.urlInputView);
		if (this._featureSwitches.length > 0) {
			children.add(this.featureView);
		}
		children.add(this.saveButtonView);
		children.add(this.cancelButtonView);

		return children;
	}

	_updateFeatureStatus(hrefStatuses) {
		for (let i = 0; i < this.featureStatuses.length; i++) {
			const status = this.featureStatuses.get(i);
			status.set("value", status.isEnabled ? status.defaultValue : status.disabledValue);
		}
		for (let i = 0; i < hrefStatuses.length; i++) {
			const status = this.featureStatuses.get(hrefStatuses[i].id);
			if (status && status.isEnabled) {
				status.set("value", hrefStatuses[i].value);
			}
		}
	}
}
