import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import request from "superagent";

import ComponentContainer from "atlas/components/ComponentContainer/ComponentContainer";
import { API_HOST } from "config/env";
import { resetPageConfigs, updatePageConfigs } from "redux/app/actions";
import { updatePageHeader } from "redux/pageHeader/actions";
import { loadPolicyBooks } from "redux/policyGovernance/actions";
import { setSnackbarOptions } from "redux/snackBar/actions";
import notifierMessage from "utils/notifierMessage";
import { sortTextFieldCaseInsensitive, sortBooleanField } from "utils/sort";
import telemetryAddEvent from "utils/telemetryAddEvent";
import PolicyDeleteDialog from "./components/PolicyDeleteDialog";
import PolicySettingsList from "./components/PolicySettingsList";

const telemetryPage = "Policy Settings";

export const sortDefault = (a, b) => {
	let sort = sortBooleanField(a, b, "folder", true);
	sort = sort !== 0 ? sort : sortTextFieldCaseInsensitive(a, b, "code");

	return sort !== 0 ? sort : sortTextFieldCaseInsensitive(a, b, "title");
};
export const sortTitle = (a, b, descending) => {
	let sort = sortTextFieldCaseInsensitive(a, b, "code", descending);

	return sort !== 0 ? sort : sortTextFieldCaseInsensitive(a, b, "title", descending);
};
const getSortMethod = (field, descending) => {
	let method = sortDefault;
	switch (field) {
		case "title":
			method = (a, b) => sortTitle(a, b, descending);
			break;
	}

	return method;
};

const PolicySettingsModule = (props) => {
	const { showSignIn } = props;
	const { t } = useTranslation("documents");
	const navigate = useNavigate();
	const [books, setBooks] = useState(null);
	const [order, setOrder] = useState({});
	const dispatch = useDispatch();
	const [dialogs, setDialogs] = useState({});

	const loadBooks = () => {
		dispatch(loadPolicyBooks())
			.then((res) => {
				// Only list books
				setBooks(res.body.filter((book) => book.folder).sort(getSortMethod(order.field, order.descending)));
			})
			.catch((err) => {
				showSignIn(err, () => {
					loadBooks();
				});
			});
	};
	const handleDelete = (book) => {
		setDialogs({ delete: book });
	};

	const closeDialogs = () => {
		setDialogs({});
	};

	const createBook = () => {
		telemetryAddEvent(`Policy - Add Policy Book`);

		navigate("/policy/settings/book");
	};

	const afterPolicyBookDelete = (book) => {
		setBooks((prev) => {
			prev.forEach((record, index) => {
				if (record.guid === book.guid) {
					prev.splice(index, 1);
				}
			});
			return [...prev];
		});
	};

	const handleSort = (field, orderType) => {
		setOrder({ field: field, descending: orderType !== "asc" });
	};

	const undoDelete = (book) => {
		request
			.post(`${API_HOST}/api/document/${book.guid}/restore`)
			.withCredentials()
			.send({})
			.then(() => {
				let option = notifierMessage(t("deleteDocumentDialog.undo.successful"), "success");
				dispatch(setSnackbarOptions(option));

				loadBooks();
			})
			.catch((err) => {
				showSignIn(err, () => {
					undoDelete(book);
				});
			});
	};

	useEffect(() => {
		const sortMethod = getSortMethod(order.field, order.descending);

		setBooks((prev) => prev?.sort(sortMethod));
	}, [order]);

	useEffect(() => {
		dispatch(resetPageConfigs({ resetBack: true }));
		dispatch(
			updatePageConfigs({
				title: t("policySettings.title"),
				telemetryPage,
			}),
		);
		dispatch(
			updatePageHeader({
				primaryAction: createBook,
				primaryActionText: t("buttons.addBook"),
				primaryActionTooltip: t("tooltips.addBook"),
			}),
		);

		loadBooks();
	}, []);

	return (
		<ComponentContainer padding="0">
			<PolicySettingsList books={books} handleSort={handleSort} handleDelete={handleDelete}></PolicySettingsList>
			{dialogs.delete && (
				<PolicyDeleteDialog document={dialogs.delete} onClose={closeDialogs} afterDelete={afterPolicyBookDelete} undoDelete={undoDelete} />
			)}
		</ComponentContainer>
	);
};

export default PolicySettingsModule;
