export default function ellipsedTruncateText(text, maxLength = 35, slicedLength = 25) {
	if (text.length <= maxLength) {
		return {
			text,
			isTruncated: false,
		};
	} else {
		return {
			text: text.slice(0, slicedLength) + "...",
			isTruncated: true,
		};
	}
}
