import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import makeStyles from '@mui/styles/makeStyles';

import Icon from '../Icon/Icon';

import typographyStyle from '../../assets/jss/components/typographyStyle';
import { grayColor } from '../../assets/jss/shared';

import { Checkbox } from '@mui/material';

const ICON_POSITION_LEFT = 'left';
const ICON_POSITION_RIGHT = 'right';

const iconStyle = {
	height: '24px',
};

const useStyles = makeStyles({
	container: {},
	header: {
		height: '56px',
		padding: '0 20px',
		boxSizing: 'border-box',
		borderBottom: `solid 1px ${grayColor[4]}`,
		display: 'flex',
		alignItems: 'center',
		cursor: 'pointer',
	},
	iconLeft: {
		...iconStyle,
		marginRight: '20px',
	},
	iconRight: {
		...iconStyle,
		marginLeft: '20px',
	},
	label: {
		...typographyStyle.accordian,
		flexGrow: '1',
	},
	accordianCheckbox: {
		display: 'flex',
	},
	accordianLabel: {
		paddingTop: '5px',
	},
	content: {
		// eslint-disable-next-line no-nested-ternary
		maxHeight: (props) => (props.contentHeight ? props.contentHeight : props.overflow ? '288px' : undefined), // 64 * 4 + 32
		overflow: (props) => (props.overflow ? 'auto' : undefined),
	},
	checkboxInAccordian: {
		'& span.MuiButtonBase-root': {
			padding: '0px',
			height: '30px',
		},
	},
});

const Accordion = (props) => {
	const {
		id,
		className,
		classes: overrideClasses = {},
		label,
		iconPosition,
		startOpen,
		triggerOpen,
		scrollContentIntoView,
		contentHeight,
		overflow,
		dataCy,
		children,
		showCheckbox = false,
		handleToggle,
		enableCheckbox,
		checkboxName,
	} = props;
	const [open, setOpen] = useState(false);
	const contentRef = useRef(null);
	const classes = useStyles({ contentHeight, overflow });

	const toggle = () => {
		setOpen(!open);
	};

	const handleKeyPress = (e) => {
		if (e.key === 'Enter') {
			toggle();
		}
	};

	const handleClick = () => {
		toggle();
	};

	useEffect(() => {
		if (scrollContentIntoView && open && contentRef.current) {
			contentRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
		}
	}, [open]);

	useEffect(() => {
		if (!open && (startOpen || triggerOpen)) {
			setOpen(true);
		}
	}, [startOpen, triggerOpen]);

	const HeaderIcon = (
		<div
			className={clsx(
				{
					[classes.iconLeft]: iconPosition === ICON_POSITION_LEFT,
					[classes.iconRight]: iconPosition === ICON_POSITION_RIGHT,
				},
				overrideClasses.icon,
			)}
		>
			<Icon name={open ? 'expand-down' : 'expand-right'} />
		</div>
	);
	const labelId = `${id}-label`;

	return (
		<div className={clsx(classes.container, className, overrideClasses.container)} ref={contentRef}>
			<div
				className={clsx(classes.header, overrideClasses.header)}
				role="button"
				tabIndex={0}
				onClick={handleClick}
				onKeyPress={handleKeyPress}
				aria-expanded={open ? 'true' : 'false'}
				data-cy={props['data-cy'] || dataCy}
			>
				{iconPosition === ICON_POSITION_LEFT && HeaderIcon}
				<div className={clsx({ [classes.accordianCheckbox]: showCheckbox }, classes.label, overrideClasses.label)}>
					<div id={labelId} className={clsx({ [classes.accordianLabel]: showCheckbox })}>
						{label}
					</div>
					{showCheckbox && (
						<div className={classes.checkboxInAccordian}>
							<Checkbox
								checked={enableCheckbox}
								onChange={(e) => handleToggle(e, checkboxName)}
								color="primary"
								inputProps={{ 'aria-labelledby': labelId }}
								dataCy="portal-public"
							/>
						</div>
					)}
				</div>
				{iconPosition === ICON_POSITION_RIGHT && HeaderIcon}
			</div>
			{open && <div className={clsx(classes.content, overrideClasses.content)}>{children}</div>}
		</div>
	);
};

Accordion.propTypes = {
	className: PropTypes.string,
	label: PropTypes.string.isRequired,
	iconPosition: PropTypes.oneOf([ICON_POSITION_LEFT, ICON_POSITION_RIGHT]),
	startOpen: PropTypes.bool,
	triggerOpen: PropTypes.object,
	scrollContentIntoView: PropTypes.bool,
	contentHeight: PropTypes.string,
	overflow: PropTypes.bool,
	dataCy: PropTypes.string,
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

Accordion.defaultProps = {
	className: undefined,
	iconPosition: ICON_POSITION_LEFT,
	startOpen: false,
	triggerOpen: undefined,
	scrollContentIntoView: false,
	contentHeight: undefined,
	overflow: true,
	dataCy: undefined,
};

export default Accordion;
