import Countly from "countly-sdk-web";

/*
Usage:

This records a custom telemetry event.

"eventName" is a string with the name used for the Countly key
"segmentation" is a optional object with the segmentation information

import telemetryAddEvent from "utils/telemetryAddEvent";
*/

export default function telemetryAddEvent(eventName, segmentation) {
	if (Countly.app_key) {
		Countly.q.push([
			"add_event",
			{
				key: eventName,
				segmentation: segmentation || {},
			},
		]);
	}
	pendo.track(eventName, segmentation);
}
