import React, { useEffect, useState, forwardRef, useRef } from "react";

import { useDispatch } from "react-redux";
import { Divider } from "@mui/material";
import { Close } from "components/Icons";
import makeStyles from "@mui/styles/makeStyles";
import ellipsedTruncateText from "utils/ellipsedTruncateText";
import Tooltip from "atlas/components/Tooltip/Tooltip";
import { useTranslation } from "react-i18next";

import Typography from "@mui/material/Typography";
import ButtonWithTooltip from "atlas/components/Buttons/ButtonWithTooltip";
import CircularProgressIndicator from "atlas/components/Progress/CircularProgressIndicator";
import { MEDIUM } from "atlas/utils/buttonSize";
import IconButton from "@mui/material/IconButton";
import processHtml, { stripHtml } from "utils/processHtml";
import { formatDate } from "utils/date";

import { displayErrorNotification } from "views/Policy/utils/getNotification";

import RadioButtonList from "atlas/components/List/RadioButtonList";

const useStyles = makeStyles((theme) => ({
	popup: {
		"& .MuiDialog-paper": {
			position: "absolute",
			top: "0px",
			right: "0px",
			margin: "0px",
			minHeight: "100vh",
			width: "367px",
		},
		"& .MuiTypography-h3": {
			fontSize: "18px",
			fontWeight: "600",
		},
		"& .MuiDialogContent-root": {
			padding: "0px",

			overflowX: "hidden",

			"& .MuiFormControl-root": {
				margin: "0px",
			},
			"& .css-1kbhhsr-MuiTypography-root": {
				fontWeight: "600",
				fontSize: "16px",
				color: "#676767",
			},
		},
		"& .MuiFormControlLabel-root": {
			margin: "0",
			"& .MuiButtonBase-root": {
				margin: "20px 20px 0px 0px",
			},
		},
		"& .MuiFormControlLabel-label": {
			fontSize: "16px",
			fontWeight: "400",
		},
		emptyList: {
			marginLeft: "18px",
		},
	},

	policySideBar: {
		height: "100vh",
		width: "367px",
		background: "#FFFFFF",
		position: "fixed",
		top: "0px",
		right: "0px",
		borderRadius: "2px",
		overflowY: "auto",
		zIndex: "1",
		[theme.breakpoints.down(769)]: {
			width: "100%",
		},
	},
	title: {
		display: "flex",
		justifyContent: "space-between",
		padding: "0px 22px 0px 20px",
		position: "sticky",
		top: "0px",
		zIndex: "1",
		background: "#FFFFFF",
	},
	titleText: {
		fontSize: "22px",
		fontWeight: "600",
		lineHeight: "26px",
	},
	modelWrapper: {
		position: "fixed",
		top: "0px",
		left: "0px",
		right: "0px",
		bottom: "0px",
		background: "rgba(0,0,0,0.5)",
	},
	list: {
		marginBottom: "111px",
	},
	actions: {
		display: "flex",
		position: "fixed",
		bottom: "0px",
		background: "#FFFFFF",
		zIndex: "1",
		width: "inherit",
		left: "inherit",
		right: "inherit",
		justifyContent: "flex-end",
		padding: "16px 0px 16px 0px",
	},
	compareButton: {
		marginRight: "24px",
	},
}));

const ComparePolicySideBar = (props) => {
	const { onClose, onCompareClick, getPolicyCompareList, currentPolicyDetails, meetingId, selectedComparePolicy } = props;
	const [selectedPolicy, setSelectedPolicy] = useState(null);
	const [defaultSelectedPolicy, setDefaultSelectedPolicy] = useState(null);
	const [policyList, setPolicyList] = useState([]);
	const [loading, setLoading] = useState(false);
	const { t } = useTranslation("documents");
	const componentRef = useRef(null);
	const dispatch = useDispatch();

	useEffect(() => {
		let obj = {
			policyGuid: currentPolicyDetails.policyGuid,
			sourcePolicyGuid: currentPolicyDetails.sourcePolicyGuid,
			meetingId: meetingId,
		};
		setLoading(true);
		getPolicyCompareList(obj)
			.then((res) => {
				setPolicyList(res);
				setLoading(false);
			})
			.catch((err) => {
				setLoading(false);
				displayErrorNotification("Something went wrong", dispatch);
			});
	}, []);

	useEffect(() => {
		if (selectedComparePolicy) {
			let defaultSelectedKey = `${selectedComparePolicy.itemId}-${selectedComparePolicy.policyGuid}`;
			setDefaultSelectedPolicy(defaultSelectedKey);
		} else {
			setDefaultSelectedPolicy(null);
		}
	}, [selectedComparePolicy]);

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (componentRef.current && !componentRef.current.contains(event.target)) {
				onClose();
			}
		};
		document.addEventListener("mousedown", handleClickOutside);

		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, []);

	const handleCancel = () => {
		onClose();
	};
	const dialog = {
		secondaryAction: handleCancel,
	};

	const classes = useStyles();

	const handleRadioChange = (event) => {
		let policySelected = policyList.find((policy) => {
			const id = `${policy.itemId}-${policy.policyGuid}`;
			return id === event.target.value;
		});
		setSelectedPolicy(policySelected);
	};

	const getListOfOptions = () => {
		let options = [];
		if (policyList && policyList.length > 0) {
			policyList.map((option) => {
				const {
					meetingId,
					itemBulletNumber,
					rescindedDate,
					revisedDate,
					adoptedDate,
					dateModified,
					meetingName,
					itemId,
					policyGuid,
					itemTitle,
				} = option;
				let processedHtml = processHtml(stripHtml(itemTitle));

				let truncatedText1 = ellipsedTruncateText(meetingName, 37, 33);
				let truncatedText2 = ellipsedTruncateText(processedHtml, 32, 28);
				let currentPublishedDate = rescindedDate || revisedDate || adoptedDate || dateModified;

				let label = "";
				let displayDate = "";
				if (rescindedDate) {
					label = "Rescinded Date";
					displayDate = formatDate(currentPublishedDate);
				} else if (adoptedDate) {
					label = "Adopted Date";
					displayDate = formatDate(currentPublishedDate);
				} else if (revisedDate) {
					label = "Last Revised Date";
					displayDate = formatDate(currentPublishedDate);
				} else {
					label = "Last Modified Date";
					displayDate = formatDate(currentPublishedDate);
				}

				let fullText1 = {
					text: meetingId !== 0 ? truncatedText1.text : t("policy.comparePolicyDialog.currentPublishVersion"),
					isTruncated: meetingId !== 0 ? truncatedText1.isTruncated : false,
				};

				let fullText2 = {
					text: meetingId !== 0 ? itemBulletNumber + " " + truncatedText2.text : label ? `${label}: ${displayDate}` : "",
					isTruncated: meetingId !== 0 ? truncatedText2.isTruncated : false,
				};

				options.push({
					originalText1: meetingName,
					originalText2: processedHtml,
					text1: fullText1,
					text2: fullText2,
					key: `${itemId}-${policyGuid}`,
				});
			});
		}
		return options;
	};

	return (
		<div className={classes.animation}>
			<div className={classes.modelWrapper}></div>
			<div className={classes.policySideBar} ref={componentRef} id="policySideBar">
				<div className={classes.title}>
					<div>
						<h3>{t("policy.comparePolicyDialog.title")}</h3>
					</div>
					<Tooltip title={t("app:tooltips.closeButton")}>
						<IconButton
							className="align-with-icon"
							aria-label={t("app:tooltips.closeButton")}
							onClick={onClose}
							data-cy="closeIcon"
							size="large"
						>
							<Close />
						</IconButton>
					</Tooltip>
				</div>
				<Divider />
				{!loading ? (
					<>
						{policyList && policyList.length > 0 ? (
							<div className={classes.list}>
								<RadioButtonList
									handleRadioChange={handleRadioChange}
									getListOfOptions={getListOfOptions()}
									defaultSelectedValue={defaultSelectedPolicy}
								/>
							</div>
						) : (
							<Typography style={{ marginLeft: "22px", paddingTop: "8px" }}>{t("policy.comparePolicyDialog.emptyList")}</Typography>
						)}

						<div className={classes.actions} data-cy="dialog-actions">
							<ButtonWithTooltip
								title={t("policy.comparePolicyDialog.cancel")}
								size={MEDIUM}
								variant="text"
								color="primary"
								onClick={handleCancel}
								data-cy="secondaryAction"
							>
								{t("policy.comparePolicyDialog.cancel")}
							</ButtonWithTooltip>

							<ButtonWithTooltip
								className={classes.compareButton}
								title={t("policy.comparePolicyDialog.compareButton")}
								size={MEDIUM}
								primary
								onClick={() => onCompareClick(selectedPolicy)}
								disabled={!selectedPolicy || Object.keys(selectedPolicy).length === 0}
								data-cy="primaryAction"
							>
								{t("policy.comparePolicyDialog.compareButton")}
							</ButtonWithTooltip>
						</div>
					</>
				) : (
					<CircularProgressIndicator data-cy="loading" />
				)}
			</div>
		</div>
	);
};

export default ComparePolicySideBar;
