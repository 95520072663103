import { defaultFont, primaryColor, blackColor, grayColor, whiteColor } from "atlas/assets/jss/shared";
import typographyStyle from "atlas/assets/jss/components/typographyStyle";

const listItem = {
	listStyleType: "none",
	display: "flex",
	position: "relative",
	boxSizing: "border-box",
};
const defaultBorder = `solid 1px ${grayColor[4]}`;
const header = {
	...defaultFont,
	fontSize: "12px",
	lineHeight: "1.33",
	fontWeight: "600",
	letterSpacing: "1px",
	textTransform: "capitalize",
	padding: "0 24px 0 18px",
	minHeight: "36px",
};
const borderPseudo = {
	content: "''",
	alignSelf: "stretch",
};
const defaultBorderPseudo = {
	...borderPseudo,
	borderTop: (props) => (props.addTopBorder ? defaultBorder : undefined),
	borderLeft: defaultBorder,
	paddingLeft: "7px", // 1px border + 7px padding = 8px total
};
const font = {
	...defaultFont,
	fontSize: "18px",
	lineHeight: "1.33",
	fontWeight: "normal",
};
const headingFont = {
	...font,
	fontWeight: "600",
	color: blackColor[1],
};
const itemFont = {
	...font,
	color: blackColor[2],
};
const pageFont = {
	...defaultFont,
	fontSize: "14px",
	lineHeight: "1.29",
	fontWeight: "normal",
	color: blackColor[1],
};
const itemPadding = "12px 16px 12px 0px";
const itemPaddingXs = "6px 8px 6px 0px";

const minutesStyle = (theme) => ({
	itemContainer: {
		maxWidth: "100%",
	},
	radioListItem: {
		marginLeft: "40px",
	},
	listItemClickable: {
		color: `${primaryColor[1]}`,
		"&:hover": {
			color: "#294772",
			textDecoration: "underline",
			cursor: "pointer",
		},
	},
	headerListItem: {
		...listItem,
		alignItems: "center",
	},
	memberOnlyHeader: {
		...header,
		color: whiteColor,
		backgroundColor: blackColor[0],
	},
	consentHeader: {
		...header,
		color: blackColor[0],
		backgroundColor: grayColor[4],
	},
	publicCommentHeader: {
		...header,
		color: blackColor[0],
		backgroundColor: "#6790cc",
	},
	headerTextWithIcon: {
		marginLeft: "8px",
	},
	agendaListItem: {
		...listItem,
		minHeight: "48px",
		display: "flex",
		alignItems: "stretch",
		padding: "0px",
		borderLeft: "none",
	},
	rightBorder: {
		borderRight: defaultBorder,
	},
	bottomBorder: {
		borderBottom: defaultBorder,
	},
	borderDefault: {
		"&::before": {
			...defaultBorderPseudo,
		},
	},
	borderDefaultBottomBorder: {
		"&::before": {
			...defaultBorderPseudo,
			borderBottom: defaultBorder,
		},
	},
	borderReadOnly: {
		"&::before": {
			border: "solid 1px transparent !important",
		},
	},
	borderMemberOnly: {
		"&::before": {
			...borderPseudo,
			borderLeft: `solid 8px ${blackColor[0]}`,
			paddingLeft: "0",
		},
	},
	borderConsent: {
		"&::before": {
			...borderPseudo,
			borderLeft: `solid 8px ${grayColor[4]}`,
			paddingLeft: "0",
		},
	},
	borderPublicComment: {
		"&::before": {
			...borderPseudo,
			borderLeft: `solid 8px #6790cc`,
			paddingLeft: "0",
		},
	},
	selected: {
		backgroundColor: grayColor[4],
		"&::before": {
			backgroundColor: "#385F99",
			paddingLeft: "7px",
		},
	},
	itemSelected: {
		marginLeft: "-7px",
	},

	presentationSelected: {
		backgroundColor: grayColor[1],
	},
	checkbox: {
		width: "40px",
		position: "absolute",
		left: "-44px",
		top: "4px",
	},
	hasNotes: {
		position: "absolute",
		width: "8px",
		height: "8px",
		top: "8px",
		right: "8px",
		backgroundColor: primaryColor[0],
		boxSizing: "border-box",
		border: `solid 1px ${whiteColor}`,
	},
	item: {
		display: "flex",
		alignItems: "first baseline",
		flexGrow: "1",
		borderTop: (props) => (props.addTopBorder ? defaultBorder : undefined),
		maxWidth: "calc(100% - 8px)", // 8px is the width of the parent::before psuedo element
		boxSizing: "border-box",
	},
	itemText: {
		flexGrow: "1",
		flexShrink: "1",
		maxWidth: "100%",
	},
	itemTextWithBullet: {
		maxWidth: "calc(100% - 40px)", // 40px is the width of the bullet column
	},
	itemIcon: {
		width: "24px",
		textAlign: "center",
	},
	motionIcon: {
		marginTop: "14px",
	},
	proposedRecommendationIcon: {
		position: "absolute",
		left: "48px",
	},
	agendaAction: {
		marginTop: "18px",
	},
	dottedListItem: {
		outline: "dotted 2px #000;",
		"&:hover": {
			cursor: "pointer",
		},
	},

	itemHeading: {
		...headingFont,
		padding: itemPadding,
		paddingLeft: (props) => `${props.isSubHeading ? 40 : 0}px`,
		[theme.breakpoints.down("sm")]: {
			paddingLeft: (props) => `${props.isSubHeading ? 24 : 0}px`,
			padding: itemPaddingXs,
		},
	},
	itemItem: {
		...itemFont,
		padding: itemPadding,
		paddingLeft: "40px",
		[theme.breakpoints.down("sm")]: {
			padding: itemPaddingXs,
		},
	},
	liveMeetingItem: {
		minHeight: "36px",
	},
	itemMotionIcon: {
		alignSelf: "center",
		marginLeft: "16px",
	},
	timestampButton: {
		padding: "0",
	},
	itemRecommendation: {
		...itemFont,
		padding: itemPadding,
		paddingLeft: (props) => `${(props.isHeadingAction ? 24 : 40) + 38}px`,
		position: "relative",
		"& [data-icon='recommendation']": {
			position: "absolute",
			height: "24px",
			width: "24px",
			top: "12px",
			left: (props) => `${props.isHeadingAction ? 24 : 40}px`,
		},
		[theme.breakpoints.down("sm")]: {
			padding: itemPaddingXs,
			paddingLeft: `40px !important`,
			"& [data-icon='recommendation']": {
				left: "12px !important",
			},
		},
	},
	itemAttachment: {
		...itemFont,
		padding: itemPadding,
		paddingLeft: `${24 + 44}px`,
		position: "relative",
		cursor: "pointer",
		"& [data-icon='expand']": {
			position: "absolute",
			height: "24px",
			width: "24px",
			top: "12px",
			left: "24px",
		},
		"& [data-icon='collapse']": {
			position: "absolute",
			height: "24px",
			width: "24px",
			top: "14px",
			left: "24px",
		},
		"& [data-text]": {
			flexGrow: "1",
		},
	},
	itemAttachmentPage: {
		...pageFont,
		padding: itemPadding,
		paddingLeft: "96px",
		alignSelf: "center",
		minHeight: "48px",
		boxSizing: "border-box",
	},
	itemReadOnly: {
		color: grayColor[0],
		border: "solid 1px transparent !important",
	},
	bullet: {
		minWidth: "40px",
		width: "40px",
		display: "flex",
		justifyContent: "flex-end",
		marginTop: "13px",
		"&::after": {
			content: '"\\00a0"', // Non-breaking space
		},
	},
	minutesBullet: {
		minWidth: "64px",
		width: "64px",
		display: "flex",
		justifyContent: "flex-end",
		marginTop: "13px",
		"&::after": {
			content: '"\\00a0"',
		},
	},
	bulletMargin: {
		marginLeft: "30px",
	},
	disabledLink: {
		cursor: "not-allowed",
	},
	moveButtonMargin: {
		paddingRight: "102px",
	},
	textContainer: {
		display: "flex",
		flexGrow: 1,
		width: "100%",
		boxSizing: "border-box",
	},
	textTruncate: {
		overflow: "hidden",
		textOverflow: "ellipsis",
		display: "-webkit-box",
		WebkitBoxOrient: "vertical",
		WebkitLineClamp: 2,
	},
	editorFieldWrapper: {
		display: "flex",
		width: "calc(100% - 40px)",
	},
	editorFieldContentWithMotion: {
		width: "100%",
		margin: "0 !important",
	},
	editorFieldContentHeader: {
		maxWidth: "100% !important",
	},
	editorFieldContent: {
		position: "relative",
		border: "solid 1px #c4c4c4 !important",
		flexGrow: "1",
		padding: "0 8px !important",
		lineBreak: "normal",
		maxWidth: "calc(100% - 40px)", // 40px right margin
		boxSizing: "border-box",
		"&:not([contenteditable='true'])": {
			overflowX: "hidden",
		},
		"& td": {
			wordBreak: "break-word",
		},
	},
	editorFieldDescriptionContent: {
		position: "relative",
		border: "solid 1px #c4c4c4 !important",
		flexGrow: "1",
		padding: "0 8px !important",
		lineBreak: "normal",
		"& td": {
			wordBreak: "break-word",
		},
	},
	editorFieldPlaceHolder: {
		margin: "12px",
		borderRadius: "4px",
		backgroundColor: grayColor[3],
		height: "24px",
	},
	editorFieldHide: {
		display: "none",
	},
	fieldReadOnly: {
		minHeight: "48px",
		border: "solid 1px transparent !important",
	},
	fieldContentMargin: {
		marginRight: "48px",
	},
	fieldContentMarginWithVideo: {
		marginRight: "48px",
	},
	descriptionContainer: {
		fontSize: "16px",
		fontWeight: "normal",
		paddingTop: "8px !important",
		paddingBottom: "0 !important",
		borderLeft: "none !important",
	},
	descriptionContainerMinutes: {
		fontSize: "16px",
		fontWeight: "normal",
		paddingTop: "8px !important",
		paddingBottom: "0 !important",
		borderLeft: "none !important",
		width: "calc(100% - 40px)",
	},
	buttons: {
		display: "flex",
		alignItems: "center",
		flexWrap: "wrap",
	},
	thinButton: {
		height: "24px",
	},
	buttonsLeft: {
		flexGrow: "1",
		display: "flex",
		alignItems: "center",
	},
	labelButton: {
		...typographyStyle.drawerTitle,
	},
	addIcon: {
		marginTop: "-12px",
		marginBottom: "-12px",
	},
	startAlign: {
		alignItems: "start",
	},
	boardNotes: {
		position: "absolute",
		top: "50%",
		right: "-48px",
		transform: "translateY(-50%)",
		marginTop: "-4px",
	},
	overflowMenu: {
		position: "absolute",
		right: "0",
		top: "12px",
	},
	overflowMenuButton: {
		padding: "8px",
		margin: "0 4px",
	},
	overflowMenuButtonWithVideo: {
		padding: "2px",
		margin: "2px",
		[theme.breakpoints.down("sm")]: {
			padding: "4px",
			margin: "4px",
		},
	},
	actionItems: {
		display: "inline-flex",
		alignItems: "center",
		textAlign: "center",
		position: "absolute",
		right: "8px",
		top: "12px",
	},
	addButton: {
		marginTop: "8px",
	},
	draggableOffset: {
		paddingLeft: "10px",
	},
	dragHandleOffset: (props) => ({
		left: props.isSelected ? "24px" : "12px",
	}),
});

export default minutesStyle;
