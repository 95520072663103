import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import makeStyles from '@mui/styles/makeStyles';

import AccessibleIconButton from '../Buttons/AccessibleIconButton';
import ButtonWithTooltip from '../Buttons/ButtonWithTooltip';
import Icon from '../Icon/Icon';
import { MEDIUM } from '../../utils/buttonSize';

import { grayColor, primaryColor, successColor, warningColor, errorColor, backgroundColor } from '../../assets/jss/shared';
import typographyStyle from '../../assets/jss/components/typographyStyle';
import {
	STATUS_NONE,
	STATUS_DEFAULT,
	STATUS_INFO,
	STATUS_GOOD,
	STATUS_WARNING,
	STATUS_ERROR,
} from '../../assets/jss/utils/statusIndicators';
import { BOTTOM_LEFT, BOTTOM_RIGHT, BOTTOM, TOP_LEFT, TOP_RIGHT, TOP } from '../../assets/jss/utils/placement';

export const ICON_POSITION_TOP = 'top';
export const ICON_POSITION_MIDDLE = 'middle';
export const ACTION_DISMISS = 'dismiss';
export const ACTION_CLOSE_MIDDLE = 'close-middle';
export const ACTION_CLOSE_MIDDLE_SMALL = 'close-middle-small';

const statusBorderRadius = {
	borderRadius: (props) => (props.thickStatus ? '0 2px 2px 0' : undefined),
};

const useStyles = makeStyles((theme) => ({
	card: {
		display: (props) => (props.limitSizeToText ? 'inline-block' : 'block'),
		margin: (props) => ([BOTTOM, TOP].includes(props.placement) ? '0 auto' : undefined),
		marginTop: (props) => props.marginTop,
		minHeight: '48px',
		maxWidth: (props) => props.maxWidth,
		boxSizing: 'border-box',
		borderRadius: '2px',
		boxShadow: '0 2px 10px 0 rgba(0, 0, 0, 0.1)',
		backgroundColor: backgroundColor[1],
		[`&.${STATUS_DEFAULT}`]: {
			...statusBorderRadius,
			borderLeft: (props) => `${props.thickStatus ? 8 : 6}px solid ${grayColor[0]}`,
		},
		[`&.${STATUS_INFO}`]: {
			...statusBorderRadius,
			borderLeft: (props) => `${props.thickStatus ? 8 : 6}px solid ${primaryColor[3]}`,
		},
		[`&.${STATUS_GOOD}`]: {
			...statusBorderRadius,
			borderLeft: (props) => `${props.thickStatus ? 8 : 6}px solid ${successColor}`,
		},
		[`&.${STATUS_WARNING}`]: {
			...statusBorderRadius,
			borderLeft: (props) => `${props.thickStatus ? 8 : 6}px solid ${warningColor}`,
		},
		[`&.${STATUS_ERROR}`]: {
			...statusBorderRadius,
			borderLeft: (props) => `${props.thickStatus ? 8 : 6}px solid ${errorColor}`,
		},
		'& svg:firstOfType': {
			marginRight: '16px',
		},
	},
	cardFloat: {
		position: 'absolute',
		zIndex: '1201',
		top: (props) => ([TOP_LEFT, TOP_RIGHT, TOP].includes(props.placement) ? '24px' : undefined),
		right: (props) => ([BOTTOM_RIGHT, BOTTOM, TOP_RIGHT, TOP].includes(props.placement) ? '24px' : undefined),
		bottom: (props) => ([BOTTOM_LEFT, BOTTOM_RIGHT, BOTTOM].includes(props.placement) ? '24px' : undefined),
		left: (props) => ([BOTTOM_LEFT, BOTTOM, TOP_LEFT, TOP].includes(props.placement) ? '24px' : undefined),
		transform: (props) => ([BOTTOM, TOP].includes(props.placement) ? 'translate(50%)' : undefined),
		maxWidth: (props) => ([BOTTOM, TOP].includes(props.placement) ? undefined : props.maxWidth || '50%'),
		[theme.breakpoints.down('sm')]: {
			maxWidth: (props) => ([BOTTOM, TOP].includes(props.placement) ? undefined : props.maxWidth || 'calc(100% - 48px)'),
		},
	},
	mainContent: {
		padding: '16px',
		display: 'flex',
		alignItems: 'flex-start',
	},
	iconContainer: {
		display: 'flex',
		alignItems: 'center',
		height: '20px', // font-size * line-height
		alignSelf: (props) => (props.iconPosition === ICON_POSITION_MIDDLE ? 'center' : undefined),
	},
	icon: {
		width: (props) => props.iconSize,
		height: (props) => props.iconSize,
		marginRight: '16px',
	},
	labelClass: {
		...typographyStyle.text,
		display: 'inline',
	},
	complexLabelClass: {
		...typographyStyle.text,
		'& p:not(:first-child)': {
			marginTop: '20px !important',
		},
	},
	flexLabelClass: {
		display: 'flex',
		flexGrow: '1',
	},
	closeButtonMiddle: {
		alignSelf: 'center',
		margin: (props) => (props.smallCloseIcon ? '-12px -12px -12px 0' : undefined),
	},
	actions: {
		padding: '0 16px',
		display: 'flex',
		justifyContent: 'flex-end',
		borderTop: `solid 1px ${grayColor[4]}`,
	},
}));

const NoticeCard = (props) => {
	const {
		updateNotice,
		className,
		status,
		thickStatus,
		icon,
		iconSize,
		iconColor,
		iconPosition,
		label,
		limitSizeToText,
		complexLabel,
		processHtml,
		actions,
		onDismiss,
		placement,
		marginTop,
		maxWidth,
		dataCy,
	} = props;
	const { t } = useTranslation();
	const smallCloseIcon = actions.includes(ACTION_CLOSE_MIDDLE_SMALL);
	const classes = useStyles({
		thickStatus,
		iconSize,
		iconPosition,
		limitSizeToText,
		marginTop,
		maxWidth,
		placement,
		smallCloseIcon,
	});
	const dispatch = useDispatch();

	const dismissCard = (e) => {
		dispatch(updateNotice({}));

		if (onDismiss) {
			onDismiss(e);
		}
	};

	const getIconColor = () => {
		switch (status) {
			case STATUS_INFO:
				return primaryColor[3];

			case STATUS_GOOD:
				return successColor;

			case STATUS_WARNING:
				return warningColor;

			case STATUS_ERROR:
				return errorColor;

			default:
				return grayColor[0];
		}
	};

	const getBottomActions = () =>
		actions
			.map((action) => {
				if (typeof action === 'string') {
					if (action === ACTION_DISMISS) {
						return {
							key: 'dismiss-notice-card',
							label: t('app:buttons.dismiss'),
							toolTip: t('app:tooltips.dismissNotice'),
							onClick: dismissCard,
							dataCy: 'dismiss-notice',
						};
					}
				}

				return action;
			})
			.filter((action) => typeof action !== 'string')
			.map((action, index) => (
				<ButtonWithTooltip
					key={action.key || action.id || `action-${index}`}
					title={action.toolTip}
					size={MEDIUM}
					onClick={action.onClick}
					dataCy={action.dataCy || action['data-cy']}
				>
					{action.label}
				</ButtonWithTooltip>
			));

	return (
		label &&
		(typeof label !== 'string' || label.length > 0) && (
			<div
				className={clsx({
					[classes.cardFloat]: placement.length > 0,
				})}
			>
				<div className={clsx(className, classes.card, status)} data-cy={dataCy}>
					<div className={classes.mainContent}>
						{icon && (
							<div className={classes.iconContainer}>
								<div className={classes.icon}>
									<Icon name={icon} size={iconSize} color={iconColor || getIconColor()} />
								</div>
							</div>
						)}
						<div
							className={clsx({
								[classes.labelClass]: !complexLabel,
								[classes.complexLabelClass]: complexLabel,
								[classes.flexLabelClass]: typeof label !== 'string',
							})}
						>
							{complexLabel && typeof label === 'string' ? processHtml(label) : label}
						</div>
						{(actions.includes(ACTION_CLOSE_MIDDLE) || smallCloseIcon) && (
							<div className={classes.closeButtonMiddle}>
								<AccessibleIconButton
									id="dismiss-notice-card"
									color="inherit"
									aria-label={t('app:tooltips.dismissNotice')}
									onClick={dismissCard}
									iconName="close"
									iconSize={smallCloseIcon ? '16px' : undefined}
									dataCy="dismiss-notice"
									tooltipText={t('app:tooltips.dismissNotice')}
								/>
							</div>
						)}
					</div>
					{actions.length > 0 && <div className={classes.actions}>{getBottomActions()}</div>}
				</div>
			</div>
		)
	);
};

NoticeCard.propTypes = {
	updateNotice: PropTypes.func.isRequired,
	status: PropTypes.oneOf([STATUS_NONE, STATUS_DEFAULT, STATUS_INFO, STATUS_GOOD, STATUS_WARNING, STATUS_ERROR]),
	thickStatus: PropTypes.bool,
	icon: PropTypes.string,
	iconSize: PropTypes.string,
	iconColor: PropTypes.string,
	iconPosition: PropTypes.oneOf([ICON_POSITION_TOP, ICON_POSITION_MIDDLE]),
	label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
	limitSizeToText: PropTypes.bool,
	complexLabel: PropTypes.bool,
	processHtml: PropTypes.func.isRequired,
	actions: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.object])),
	onDismiss: PropTypes.func,
	placement: PropTypes.oneOf(['', BOTTOM_LEFT, BOTTOM_RIGHT, BOTTOM, TOP_LEFT, TOP_RIGHT, TOP]),
	marginTop: PropTypes.string,
	maxWidth: PropTypes.string,
	dataCy: PropTypes.string,
};

NoticeCard.defaultProps = {
	status: STATUS_NONE,
	thickStatus: false,
	icon: undefined,
	iconSize: '16px',
	iconColor: undefined,
	iconPosition: ICON_POSITION_TOP,
	label: '',
	limitSizeToText: false,
	complexLabel: false,
	actions: [],
	onDismiss: undefined,
	placement: '',
	marginTop: undefined,
	maxWidth: undefined,
	dataCy: undefined,
};

export default NoticeCard;
