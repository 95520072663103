import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import makeStyles from '@mui/styles/makeStyles';
import { ListItem, ListItemText } from '@mui/material';

import LeftNavigationAccessibleIcon from './LeftNavigationAccessibleIcon';
import NonModalMenu from '../Menu/NonModalMenu';
import Icon from '../Icon/Icon';

import { grayColor } from '../../assets/jss/shared';
import typographyStyles from '../../assets/jss/components/typographyStyle';
import leftNavBarStyles from '../../assets/jss/components/leftNavBarStyle';
import { useWidthDown } from '../../utils/useWidth';

const useTypographyStyles = makeStyles(typographyStyles);
const useLeftNavBarStyles = makeStyles(leftNavBarStyles);

const LeftNavigationListItem = (props) => {
	const {
		id,
		selected,
		menuIndex,
		open,
		iconName,
		text,
		tooltipText,
		ariaLabelText,
		newTab,
		navigateUrl,
		onClick,
		menuOptions,
		menuOpen,
		closeMenu,
		badgeProps,
		showCount = false,
		...navItemProps
	} = props;
	const widthDownSm = useWidthDown('sm');
	const [menuAnchor, setMenuAnchor] = useState(null);
	const typographyClasses = useTypographyStyles();
	const classes = useLeftNavBarStyles();

	const getListItemContent = (stopPropagation) => {
		let finalOnClick = onClick;
		if (stopPropagation && typeof onClick === 'function') {
			finalOnClick = (e) => {
				e.stopPropagation();
				e.preventDefault();

				onClick(e);
			};
		}
		if (menuOptions.length > 0) {
			finalOnClick = (e) => {
				setMenuAnchor(e.currentTarget);
				onClick(e);
			};
		}

		return (
			<>
				<ListItem
					id={id}
					className={`${selected && classes.selected} ${classes.item}`}
					data-menuindex={menuIndex}
					{...navItemProps}
					onClick={finalOnClick}
					selected={selected}
					aria-label={ariaLabelText || tooltipText || text}
					ref={menuAnchor}
					data-cy={`${props['data-cy']}LeftNavigation`}
				>
					<LeftNavigationAccessibleIcon
						className={classes.itemIcon}
						name={iconName}
						tooltipText={!open ? tooltipText || ariaLabelText || text : ''}
						badgeProps={badgeProps}
					/>
					{open && (
						<>
							<ListItemText
								primary={text}
								className={clsx(typographyClasses.navigation, classes.text)}
								disableTypography
								data-cy={`${props['data-cy']}Text`}
							/>
							{newTab && (
								<div className={classes.externalLink}>
									<Icon name="external-link" size="18px" color={grayColor[1]} data-cy="externalLink" />
								</div>
							)}
						</>
					)}
					{menuOptions.length > 0 && (
						<div
							className={clsx({
								[classes.submenuIcon]: !open,
								[classes.submenuIconOpen]: open,
							})}
						>
							<Icon name="expand-right" size="10px" />
						</div>
					)}
				</ListItem>
				{menuAnchor && (
					<NonModalMenu
						id={`${id}-menu`}
						className={classes.menu}
						anchorEl={menuAnchor}
						keepMounted
						open={menuOpen}
						onClose={() => {
							setMenuAnchor(null);
							closeMenu();
						}}
						options={menuOptions}
						position={widthDownSm ? 'auto-end' : 'right'}
						showCount={showCount}
					/>
				)}
			</>
		);
	};

	const getListItemLink = () => {
		if (navigateUrl) {
			return (
				<a href={navigateUrl} target={newTab ? '_blank' : null} aria-label={ariaLabelText || tooltipText || text}>
					{getListItemContent(true)}
				</a>
			);
		}

		return getListItemContent();
	};

	return getListItemLink();
};

LeftNavigationListItem.propTypes = {
	id: PropTypes.string,
	selected: PropTypes.bool,
	menuIndex: PropTypes.number,
	open: PropTypes.bool,
	iconName: PropTypes.string.isRequired,
	text: PropTypes.string.isRequired,
	tooltipText: PropTypes.string,
	ariaLabelText: PropTypes.string,
	newTab: PropTypes.bool,
	navigateUrl: PropTypes.string,
	onClick: PropTypes.func.isRequired,
	menuOptions: PropTypes.arrayOf(PropTypes.object),
	menuOpen: PropTypes.bool,
	closeMenu: PropTypes.func,
	'data-cy': PropTypes.string,
};

LeftNavigationListItem.defaultProps = {
	id: undefined,
	selected: false,
	menuIndex: 0,
	open: false,
	tooltipText: undefined,
	ariaLabelText: undefined,
	newTab: false,
	navigateUrl: undefined,
	menuOptions: [],
	menuOpen: false,
	closeMenu: undefined,
	'data-cy': '',
};

export default LeftNavigationListItem;
