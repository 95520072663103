import { produce } from "immer";
import { v4 as uuid } from "uuid";

import cloneDeep from "lodash/fp/cloneDeep";
import findIndex from "lodash/fp/findIndex";
import { createMeetingElement, prepareAttachmentDuplication } from "utils/meetingElement";
import telemetryAddEvent from "utils/telemetryAddEvent";

import { updateAgenda, reOrderItemArray, findItemByID, getItemLength, checkAttachments } from "./utils";

export function addAgendaItem(guid) {
	const currentState = this.state;
	const itemId = uuid();

	telemetryAddEvent("Agenda builder - Add agenda item");

	const nextState = produce(
		currentState,
		(draft) => {
			const newItem = createMeetingElement({
				guid: itemId,
				itemType: 3,
				indent: "1",
				order: "1",
				number: 1,
				parentGuid: guid,
			});

			this.itemIdsToUpdate.push(newItem.guid);

			// if header selected add immediately after first heading in draft.items (which will be 1st item)
			// if another item is selected, add it immediately after that item
			let selectedIndex;
			if (guid === "toc-header") {
				if (draft.items.length) {
					selectedIndex = 0; // 1st item will always be a heading.
				} else {
					// this should never happen, but putting it in as a graceful failure just in case
					// eslint-disable-next-line no-param-reassign
					if (!draft.items) draft.items = [];
					draft.items.push(newItem);
					return;
				}
			} else {
				selectedIndex = findIndex((item) => item.guid === guid, draft.items);
			}
			draft.items.splice(selectedIndex + 1, 0, newItem);
			reOrderItemArray(draft.items, this, currentState.customNumbering);
		},
		(patches, inversePatches) => {
			if (patches.length) {
				this.redoStack.push([...patches]);
				this.undoStack.push([...inversePatches]);
			}
		},
	);

	updateAgenda(this, { items: nextState.items }, itemId);
}

export function duplicateItem(guid) {
	const currentState = this.state;
	const dupeId = uuid();
	const nextState = produce(
		currentState,
		(draft) => {
			const currentIndex = findIndex((item) => item.guid === guid, draft.items);
			const numberOfItemsToDuplicate = getItemLength(guid, draft.items);
			const newItems = [];
			for (let i = currentIndex; i < currentIndex + numberOfItemsToDuplicate; i += 1) {
				const dupeItem = prepareAttachmentDuplication(cloneDeep(draft.items[i]));
				if (i === currentIndex) {
					dupeItem.guid = dupeId;
				} else {
					dupeItem.guid = uuid();
				}

				if (dupeItem.attachments) {
					// eslint-disable-next-line no-plusplus
					for (let j = 0; j < dupeItem.attachments.length; j++) {
						dupeItem.attachments[j].itemGuid = dupeItem.guid;
					}
				}
				this.itemIdsToUpdate.push(dupeItem.guid);
				newItems.push(dupeItem);
			}

			draft.items.splice(currentIndex + numberOfItemsToDuplicate, 0, ...newItems);
			reOrderItemArray(draft.items, this, currentState.customNumbering);
		},
		(patches, inversePatches) => {
			if (patches.length) {
				this.redoStack.push([...patches]);
				this.undoStack.push([...inversePatches]);
			}
		},
	);

	updateAgenda(this, { items: nextState.items }, dupeId);
}

export function deleteItem(guid) {
	const currentState = this.state;
	const nextState = produce(
		currentState,
		(draft) => {
			const numberOfItemsToDelete = getItemLength(guid, draft.items);
			const currentIndex = findIndex((item) => item.guid === guid, draft.items);
			for (let i = currentIndex; i < currentIndex + numberOfItemsToDelete; i += 1) {
				const item = draft.items[i];
				item.deleted = true;
				this.itemIdsToDelete.push(item.guid);
			}

			const nonDeletedItems = draft.items.filter((item) => !item.deleted);
			draft.items.splice(0, draft.items.length, ...nonDeletedItems);

			reOrderItemArray(draft.items, this, currentState.customNumbering);
		},
		(patches, inversePatches) => {
			if (patches.length) {
				this.redoStack.push([...patches]);
				this.undoStack.push([...inversePatches]);
			}
		},
	);

	updateAgenda(this, { items: nextState.items });
}

export function updateAgendaItem(guid, data, cancelNextRender) {
	const currentState = this.state;
	let cancelChanges = false;
	const nextState = produce(
		currentState,
		(draft) => {
			const item = findItemByID(guid, draft.items);
			if (item && item.fields.Name.Value !== data) {
				item.fields.Name.Value = data;
				checkAttachments(item);
			} else {
				cancelChanges = true;
			}
		},
		(patches, inversePatches) => {
			if (patches.length) {
				if (patches.length === 1) {
					const firstPatch = patches[0].value.includes("<br />") ? patches[0].value.split("<br />").join("<br>") : patches[0].value;
					const firstInversePatches = inversePatches[0].value.includes("<br />")
						? inversePatches[0].value.split("<br />").join("<br>")
						: inversePatches[0].value;
					if (firstPatch !== firstInversePatches) {
						this.redoStack.push([...patches]);
						this.undoStack.push([...inversePatches]);
					}
				} else {
					this.redoStack.push([...patches]);
					this.undoStack.push([...inversePatches]);
				}
			}
		},
	);

	if (!cancelChanges) {
		this.itemIdsToUpdate.push(guid);
		updateAgenda(this, { items: nextState.items }, null, cancelNextRender);
	}
}
