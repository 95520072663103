export const parseTextBookmarks = (data) => {
	let parsedData = "";
	let temp = document.createElement("div");
	temp.innerHTML = data;
	temp.innerHTML = temp.innerHTML.replaceAll("&nbsp;", " ");
	temp.innerHTML = temp.innerHTML.replace(/>\s+</g, "><");
	if (temp.childNodes && temp.childNodes.length > 0) {
		temp.childNodes.forEach((pNode) => {
			if (pNode.nodeName === "P") {
				pNode.childNodes.forEach((e) => {
					if (e.nodeName === "#text") {
						e.textContent = e.textContent?.replace(/\s+/g, " ").trim();
						if (e.nextSibling && e.nextSibling.nodeName === "A" && e.previousSibling === null) {
							e.textContent = e.textContent + " ";
						} else if (e.nextSibling === null && e.previousSibling && e.previousSibling.nodeName === "A") {
							e.textContent = " " + e.textContent;
						} else if (e.nextSibling && e.nextSibling.nodeName === "A" && e.previousSibling && e.previousSibling.nodeName === "A") {
							e.textContent = " " + e.textContent + " ";
						}
					}
					if (e.nodeName === "A") {
						if (e.nextSibling && e.nextSibling.nodeName === "A" && (e.previousSibling === null || e.previousSibling.nodeName === "#text")) {
							e.innerHTML = e.innerHTML?.replace(/\s+/g, " ").trim();
							e.innerHTML = e.innerHTML + " ";
						} else if (
							(e.nextSibling === null || e.nextSibling.nodeName === "#text") &&
							e.previousSibling &&
							e.previousSibling.nodeName === "A"
						) {
							e.innerHTML = e.innerHTML?.replace(/\s+/g, " ").trim();
							e.innerHTML = " " + e.innerHTML;
						} else if (e.nextSibling && e.nextSibling.nodeName === "A" && e.previousSibling && e.previousSibling.nodeName === "A") {
							e.innerHTML = e.innerHTML?.replace(/\s+/g, " ").trim();
							e.innerHTML = " " + e.innerHTML + " ";
						}
					}
				});
			}
			parsedData += pNode.outerHTML;
		});
	}
	return parsedData;
};
