import React, { useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { isValid, format } from "date-fns";

import { MenuItem } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import OutlinedDatePicker from "atlas/components/FormControls/OutlinedDatePicker";
import SelectInput from "atlas/components/FormControls/SelectInput";
import StackedCheckbox from "atlas/components/FormControls/StackedCheckbox";
import filterStyle from "assets/jss/components/filterStyle";
import { SettingsContext } from "contexts/Settings/SettingsContext";
import {
	DATE_RANGE_NONE,
	DATE_RANGE_TODAY,
	DATE_RANGE_THIS_WEEK,
	DATE_RANGE_THIS_MONTH,
	DATE_RANGE_LAST_MONTH,
	DATE_RANGE_CUSTOM,
} from "utils/customDateRange";

const useFilterStyles = makeStyles(filterStyle);
const useStyles = makeStyles({
	fieldHeading: {
		marginBottom: "8px",
	},
	selectInput: {
		minWidth: "150px",
		width: "100%",
		height: "auto",
	},
	dateInput: {
		minWidth: "150px",
		width: "100%",
		height: "auto",
		marginTop: "-16px",
		"& .MuiInputLabel-outlined:not(.MuiFormLabel-filled)": {
			transform: "translate(14px, 28px)",
		},
		"& .MuiOutlinedInput-root": {
			height: "40px",
			"& .MuiOutlinedInput-input": {
				boxSizing: "border-box",
				height: "40px",
				paddingTop: "10.5px",
				paddingBottom: "10.5px",
			},
		},
	},
});

export const DEFAULT_SEARCH_FIELD = "search";

const AgendaItemFilter = (props) => {
	const { filter, updateFilter, meetingTypes = [] } = props;
	const { t } = useTranslation();
	const [dateSettings, setDateSettings] = useState({
		date: {
			from: null,
			to: null,
		},
		focused: {
			from: false,
			to: false,
		},
	});
	const { dateFormat } = useContext(SettingsContext);
	const filterClasses = useFilterStyles();
	const classes = useStyles();

	const handleDateChange = (field) => (date) => {
		updateFilter({ target: { value: date && isValid(date) ? format(date, "yyyy-MM-dd") : date } }, field);

		setDateSettings((prev) => ({
			...prev,
			date: {
				...prev.date,
				[field]: date,
			},
		}));
	};

	const getDateRangeOptions = () =>
		[
			{ text: t("options.none"), value: DATE_RANGE_NONE },
			{ text: t("options.today"), value: DATE_RANGE_TODAY },
			{ text: t("options.thisWeek"), value: DATE_RANGE_THIS_WEEK },
			{ text: t("options.thisMonth"), value: DATE_RANGE_THIS_MONTH },
			{ text: t("options.lastMonth"), value: DATE_RANGE_LAST_MONTH },
			{ text: t("options.custom"), value: DATE_RANGE_CUSTOM },
		].map((option) => (
			<MenuItem key={option.value} value={option.value} data-cy={`filter-date-range-${option.value}`}>
				{option.text}
			</MenuItem>
		));

	return (
		<>
			<div className={filterClasses.section}>
				<div className={clsx(filterClasses.fieldHeading, classes.fieldHeading)}>
					<label id="filter-date-range-label" htmlFor="filter-date-range">
						{t("search.meetingDateRange")}
					</label>
				</div>
				<SelectInput
					id="filter-date-range"
					className={classes.selectInput}
					fullWidth
					size="small"
					externalLabel
					labelSize="large"
					value={filter.customDateRange || 0}
					onChange={(e) => updateFilter(e, "customDateRange", false, true)}
					data-cy="filter-date-range"
				>
					{getDateRangeOptions()}
				</SelectInput>
				{filter.customDateRange === DATE_RANGE_CUSTOM && (
					<>
						<div>
							<OutlinedDatePicker
								label={t("search.from")}
								dateFormat={dateFormat}
								value={filter.from}
								onChange={handleDateChange("from")}
								dataCy="filter-date-range-from"
							></OutlinedDatePicker>
						</div>
						<div>
							<OutlinedDatePicker
								label={t("search.to")}
								dateFormat={dateFormat}
								value={filter.to}
								minDate={dateSettings.date.from}
								onChange={handleDateChange("to")}
								dataCy="filter-date-range-to"
							></OutlinedDatePicker>
						</div>
					</>
				)}
			</div>
			<div className={filterClasses.section}>
				<StackedCheckbox
					options={meetingTypes}
					getChecked={(meetingType) => filter.meetingTypes && filter.meetingTypes.includes(meetingType.id)}
					getId={(meetingType) => `filter-meeting-type-${meetingType.id}`}
					getKey={(meetingType) => `filter-meeting-type-${meetingType.id}`}
					getValue={(meetingType) => meetingType.id}
					getDataCy={(meetingType) => `filter-meeting-type-${meetingType.id}`}
					getLabel={(meetingType) => meetingType.name}
					handleChange={(meetingType) => {
						updateFilter({ target: { value: meetingType.id } }, "meetingTypes", false, false, undefined, (newValue, prevValue) =>
							prevValue.includes(newValue) ? prevValue.filter((value) => value != newValue) : prevValue.concat([newValue]),
						);
					}}
				/>
			</div>
		</>
	);
};

export default AgendaItemFilter;
