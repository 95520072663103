import { Command } from "@ckeditor/ckeditor5-core";
import { getRootNameFromSelection, findLinkRange, getFileHrefInfo, getElementText } from "../InlineFile/inlineFileUtils";
import toMap from "@ckeditor/ckeditor5-utils/src/tomap";

export default class AddGoalsCommand extends Command {
	refresh() {
		const model = this.editor.model;
		const doc = model.document;

		this.value = doc.selection.getAttribute("fileHref");
		this.isEnabled = model.schema.checkAttributeInSelection(doc.selection, "fileHref");
	}

	execute() {
		const { editor } = this;
		const config = editor.config.get("addGoals");

		// Open the add goal to agenda dialog
		if (typeof config?.functions?.onOpenDialog === "function") {
			const view = editor.editing.view;
			const selection = view.document.selection;
			const fieldName = config.itemGuid || getRootNameFromSelection(selection);
			const itemGuid = fieldName.length >= 36 ? fieldName.substr(0, 36) : null;

			config.functions.onOpenDialog({
				itemGuid,
				fieldName,
				callback: (goals) => {
					const getFileHrefJson = (goal, config) => {
						const { urlBase, features } = config;
						const fileHref = { url: "", guid: "", text: "", name: "", features: [], canUpdate: false };
						fileHref.url = `${urlBase}/${goal.id}`;
						fileHref.guid = goal.guid;
						fileHref.id = goal.id;
						fileHref.name = `${goal.title}`.trim();
						fileHref.canUpdate = goal.canUpdate;
						features.forEach((feature) => {
							fileHref.features.push({
								id: feature.id,
								className: feature.className,
								value: Boolean(goal.closed),
								anchorTitle: feature.anchorTitle,
							});
						});
						return fileHref;
					};

					const getFileHref = (goal, config) => {
						return JSON.stringify(getFileHrefJson(goal, config));
					};

					// Add the goal links to the editor
					const config = editor.config.get("addGoals");
					const model = editor.model;
					const selection = model.document.selection;
					model.change((writer) => {
						if (selection.isCollapsed) {
							const position = selection.getFirstPosition();
							if (selection.hasAttribute("fileHref")) {
								const oldHref = selection.getAttribute("fileHref");
								const hrefInfo = getFileHrefInfo(oldHref);
								let goal = null;
								if (goals.length > 0) {
									goal = goals[0];
									if (hrefInfo.id) {
										goal.id = hrefInfo.id;
										goal.url = `${config.urlBase}/${hrefInfo.id}`;
									}
								}
								const fileHref = getFileHref(goal, config);
								const linkRange = findLinkRange(position, selection.getAttribute("fileHref"), model);

								writer.setAttribute("fileHref", fileHref, linkRange);
							} else {
								const docFrag = writer.createDocumentFragment();
								for (let i = 0; i < goals.length; i++) {
									let goal = goals[i];
									const fileHref = getFileHref(goal, config);
									const attributes = toMap(selection.getAttributes());
									attributes.set("fileHref", fileHref);
									const node = writer.createText(`${goal.title}`.trim(), attributes);
									writer.append(node, docFrag);
									writer.appendText(" ", docFrag);
									if (goals.length > 1) {
										const range = writer.createRangeOn(node);
										range.end.offset += 1;
										writer.wrap(range, "paragraph");
									}
								}
								model.insertContent(docFrag, position);
							}
						} else {
							let goal = null;
							if (goals.length > 0) {
								goal = goals[0];
							}
							const fileHref = getFileHrefJson(goal, config);
							const ranges = model.schema.getValidRanges(selection.getRanges(), "fileHref");
							for (const range of ranges) {
								fileHref.text = getElementText(range);
								writer.setAttribute("fileHref", JSON.stringify(fileHref), range);
							}
						}
					});
				},
			});
		} else {
			console.error("addGoals: config.functions.onOpenDialog is not a function");
		}
	}
}
