import React, { useState, useRef, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { createPortal } from "react-dom";
import { DndContext, DragOverlay, KeyboardSensor, PointerSensor, useSensor, useSensors, rectIntersection } from "@dnd-kit/core";
import { SortableContext, verticalListSortingStrategy } from "@dnd-kit/sortable";
import clsx from "clsx";

import makeStyles from "@mui/styles/makeStyles";
import { MenuItem } from "@mui/material";

import DragPresentation from "atlas/components/DragAndDrop/DragPresentation";
import Sortable from "atlas/components/DragAndDrop/Sortable";
import OutlinedInput from "atlas/components/FormControls/OutlinedInput";
import SelectInput from "atlas/components/FormControls/SelectInput";
import AccessibleIconButton from "atlas/components/Buttons/AccessibleIconButton";
import ButtonWithTooltip from "atlas/components/Buttons/ButtonWithTooltip";
import { MEDIUM } from "atlas/utils/buttonSize";
import { grayColor, primaryColor } from "atlas/assets/jss/shared";
import inputStyle from "atlas/assets/jss/components/inputStyle";
import { useWidthUp, useWidthDown } from "atlas/utils/useWidth";
import { getRequiredLabel, getErrorProps, hasError } from "utils/updateObject";
import { getCollisionDetection } from "utils/dragAndDrop";
import { getNoOptionsMenuItem, getDropdownProgressIndicator } from "utils/dropDown";
import { useUpdateObject } from "utils/updateObject";
import BoardUser from "./components/BoardUser";
import BoardUserOptionLabels from "./components/BoardUserOptionLabels";

const useInputStyles = makeStyles(inputStyle);
const useStyles = makeStyles((theme) => ({
	columns: {
		display: "flex",
		flexWrap: "wrap",
		margin: "0 -12px",
		[theme.breakpoints.down("md")]: {
			margin: "0",
		},
		"& > div": {
			boxSizing: "border-box",
			margin: "0 12px",
			width: "calc(50% - 24px)",
			[theme.breakpoints.down("md")]: {
				margin: "0",
				width: "100%",
			},
		},
	},
	sectionLabel: {
		marginBottom: "8px",
	},
	section: {
		border: `solid 1px ${grayColor[1]}`,
		borderRadius: "5px",
		"& ol": {
			listStyleType: "none",
			margin: "0",
			padding: "0",
		},
	},
	sectionRow: {
		position: "relative",
		display: "flex",
		flexWrap: "wrap",
		alignItems: "center",
		width: "100%",
		paddingLeft: "16px",
		boxSizing: "border-box",
	},
	noSelection: {
		fontSize: "12px",
		display: "flex",
		alignItems: "center",
		height: "48px",
		textAlign: "center",
		"& > div": {
			flexGrow: "1",
		},
	},
	buttonNextToError: {
		marginBottom: "20px",
	},
	meetingTypeSelect: {
		minWidth: "150px",
		width: "calc(40% - 24px)",
		boxSizing: "border-box",
		paddingRight: "12px",
		[theme.breakpoints.down("md")]: {
			width: "calc(100% - 48px)",
		},
	},
	meetingTypeName: {
		flexGrow: "1",
		wordBreak: "break-word",
	},
	userSection: {
		marginTop: "16px",
	},
	userSelect: {
		minWidth: "150px",
		width: "calc(40% - 24px)",
		boxSizing: "border-box",
		paddingRight: "12px",
		[theme.breakpoints.down("md")]: {
			width: "calc(100% - 48px)",
		},
	},
}));

const defaultMeetingType = { meetingType: 0 };
const defaultUser = {
	user: 0,
	member: false,
	staff: false,
	administrator: false,
	votingMember: false,
	tieBreaker: false,
	showOnPortal: false,
};

const Board = (props) => {
	const { board = {}, meetingTypes, users, errors = {}, handleUpdate, handleMeetingTypeRemove } = props;
	const widthUpSm = useWidthUp("sm");
	const mobile = useWidthDown("md");
	const { t } = useTranslation("boards");
	const [selections, setSelections] = useState({
		...defaultMeetingType,
		...defaultUser,
	});
	const sensors = useSensors(useSensor(PointerSensor), useSensor(KeyboardSensor));
	const [anchor, setAnchor] = useState(null);
	const [draggedId, setDraggedId] = useState(null);
	const dragRef = useRef(null);
	const classes = useStyles();
	const inputClasses = useInputStyles({ fullWidth: true });

	const updateSelections = useUpdateObject(setSelections);

	const addMeetingType = () => {
		if (selections.meetingType > 0) {
			handleUpdate(
				{
					target: { value: selections.meetingType },
				},
				"meetingTypes",
				false,
				true,
				undefined,
				(newValue, prevValue) =>
					!prevValue.find((prevMeetingType) => prevMeetingType === newValue) ? prevValue.concat([newValue]) : prevValue,
			);

			setSelections((prev) => ({
				...prev,
				...defaultMeetingType,
			}));
		}
	};

	const addUser = () => {
		if (selections.user > 0) {
			handleUpdate(
				{
					target: {
						value: {
							userId: selections.user,
							member: selections.member,
							staff: selections.staff,
							administrator: selections.administrator,
							votingMember: selections.votingMember,
							tieBreaker: selections.tieBreaker,
							showOnPortal: selections.showOnPortal,
						},
					},
				},
				"users",
				false,
				false,
				undefined,
				(newValue, prevValue) =>
					!prevValue.find((prevUser) => prevUser.userId === newValue.userId) ? prevValue.concat([newValue]) : prevValue,
			);

			setSelections((prev) => ({
				...prev,
				...defaultUser,
			}));
		}
	};

	const handleMenu = useCallback((e, userId) => {
		setAnchor(e ? { userId, target: e.currentTarget } : null);
	}, []);

	const handleMove = useCallback(
		(userId, offset) => {
			handleUpdate(
				{
					target: {
						value: userId,
					},
				},
				"users",
				false,
				false,
				undefined,
				(newValue, prevValue) =>
					prevValue
						.map((boardUser, index) => {
							// Ensure that the order for this user is before the previous/after the next one
							boardUser.order = index + 1 + (boardUser.userId === newValue ? offset : 0);

							return boardUser;
						})
						.sort((a, b) => (a.order < b.order ? -1 : a.order > b.order ? 1 : 0))
						.map((boardUser, index) => {
							// Correct the order to be integers
							boardUser.order = index + 1;

							return boardUser;
						}),
			);

			setAnchor(null);
		},
		[handleUpdate],
	);

	const handleMoveUp = useCallback((userId) => handleMove(userId, -1.5), [handleMove]);

	const handleMoveDown = useCallback((userId) => handleMove(userId, 1.5), [handleMove]);

	const removeUser = useCallback(
		(userId) => {
			handleUpdate(
				{
					target: {
						value: userId,
					},
				},
				"users",
				false,
				false,
				undefined,
				(newValue, prevValue) => prevValue.filter((prevUser) => prevUser.userId !== newValue),
			);
		},
		[handleUpdate],
	);

	const getMeetingTypes = () => {
		const meetingTypeMenuItems = [];

		if (meetingTypes) {
			meetingTypeMenuItems.push(getNoOptionsMenuItem(t));

			meetingTypes
				.filter((meetingType) => !meetingType.deleted)
				.forEach((meetingType) => {
					meetingTypeMenuItems.push(
						<MenuItem key={meetingType.id} value={meetingType.id} data-cy={`meeting-type-${meetingType.id}`}>
							<div>{meetingType.name}</div>
						</MenuItem>,
					);
				});
		} else {
			meetingTypeMenuItems.push(getDropdownProgressIndicator());
		}

		return meetingTypeMenuItems;
	};

	const getUsers = () => {
		const userMenuItems = [];

		if (users) {
			userMenuItems.push(getNoOptionsMenuItem(t));

			users
				.filter(
					(user) => !user.deleted && !user.inactive && !user.external && !board.users.find((boardUser) => boardUser.userId === user.id),
				)
				.forEach((user) => {
					userMenuItems.push(
						<MenuItem key={user.id} value={user.id} data-cy={`user-${user.id}`}>
							<div>{user.name}</div>
						</MenuItem>,
					);
				});
		} else {
			userMenuItems.push(getDropdownProgressIndicator());
		}

		return userMenuItems;
	};

	const getDragId = (boardUser) => `${boardUser.userId}`;

	const getBoardUserByDragId = (dragId) => {
		const found = board.users.find((boardUser) => getDragId(boardUser) === dragId);

		return { boardUser: found, user: users.find((user) => user.id === found.userId) };
	};

	const handleDragStart = (e) => {
		const { active } = e;

		setDraggedId(active.id);

		document.body.style.userSelect = "none";
	};

	const endDrag = () => {
		setDraggedId(null);

		document.body.style.userSelect = null;
	};

	const handleDragEnd = (e) => {
		const { active, over } = e;

		if (active && over) {
			// Reorder users in a block
			const reorderedUser = getBoardUserByDragId(active.id).boardUser;
			const oldIndex = board.users.findIndex((boardUser) => getDragId(boardUser) === active.id);
			const newIndex = board.users.findIndex((boardUser) => getDragId(boardUser) === over.id);
			const newOrder = newIndex + 0.5 + (newIndex > oldIndex ? 1 : 0);

			handleUpdate(
				{
					target: {
						value: {
							userId: reorderedUser.userId,
							order: newOrder,
						},
					},
				},
				"users",
				false,
				false,
				undefined,
				(newValue, prevValue) =>
					prevValue
						.map((boardUser, index) => {
							boardUser.order = boardUser.userId === newValue.userId ? newValue.order : index + 1;

							return boardUser;
						})
						.sort((a, b) => (a.order < b.order ? -1 : a.order > b.order ? 1 : 0))
						.map((boardUser, index) => {
							// Correct the order to be integers
							boardUser.order = index + 1;

							return boardUser;
						}),
			);
		}

		endDrag();
	};

	const handleDragCancel = () => {
		endDrag();
	};

	return (
		<>
			<div>
				<div id="details" className={classes.columns}>
					<div>
						<div>
							<OutlinedInput
								id="name"
								label={getRequiredLabel(t, t("detail.name"))}
								placeholder={t("detail.placeholders.name")}
								value={board.name}
								onChange={(e) => handleUpdate(e, "name")}
								{...getErrorProps(errors, "name")}
								noDefaultClassName
								fullWidth
								size="small"
								data-cy="name"
							/>
						</div>
					</div>
				</div>
				<div>
					<div className={classes.sectionLabel}>{t("detail.selectedMeetingTypes")}</div>
					<div className={classes.section}>
						<div className={classes.sectionRow}>
							<div className={classes.meetingTypeSelect}>
								<SelectInput
									id="meeting-types"
									className={inputClasses.smallInput}
									noDefaultClassName
									label={board.id === 0 ? getRequiredLabel(t, t("detail.selectMeetingType")) : t("detail.selectMeetingType")}
									size="small"
									value={selections.meetingType}
									onChange={(e) => updateSelections(e, "meetingType", false, true)}
									{...getErrorProps(errors, "meetingTypes")}
									data-cy="meeting-types"
								>
									{getMeetingTypes()}
								</SelectInput>
							</div>
							<div
								className={clsx({
									[classes.buttonNextToError]: hasError(errors, "meetingTypes"),
								})}
							>
								<ButtonWithTooltip
									className={clsx({ [classes.button]: widthUpSm })}
									title={t("tooltips.addMeetingType")}
									size={MEDIUM}
									variant="outlined"
									color="primary"
									onClick={addMeetingType}
									disabled={selections.meetingType === 0}
									data-cy="add-meeting-type"
								>
									{t("buttons.addMeetingType")}
								</ButtonWithTooltip>
							</div>
						</div>
						{meetingTypes && board.meetingTypes.length > 0 ? (
							<ol data-cy="selected-meeting-types">
								{board.meetingTypes
									.map((meetingTypeId) => meetingTypes.find((meetingType) => meetingType.id === meetingTypeId))
									.sort((a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0))
									.map((meetingType) => (
										<li key={meetingType.id} className={classes.sectionRow}>
											<div className={classes.meetingTypeName}>{meetingType.name}</div>
											<div>
												<AccessibleIconButton
													iconName="remove"
													iconColor={primaryColor[1]}
													tooltipText={t("tooltips.removeMeetingType")}
													onClick={() => handleMeetingTypeRemove(meetingType.id)}
													dataCy="remove-meeting-type"
												/>
											</div>
										</li>
									))}
							</ol>
						) : (
							<div className={classes.noSelection}>
								<div>{t("detail.validation.noSelectedMeetingTypes")}</div>
							</div>
						)}
					</div>
				</div>
				<div>
					<div className={clsx(classes.section, classes.userSection)}>
						<div className={classes.sectionRow}>
							<div className={classes.userSelect}>
								<SelectInput
									id="users"
									className={inputClasses.smallInput}
									noDefaultClassName
									label={board.id === 0 ? getRequiredLabel(t, t("detail.selectUser")) : t("detail.selectUser")}
									size="small"
									value={selections.user}
									onChange={(e) => updateSelections(e, "user", false, true)}
									{...getErrorProps(errors, "users")}
									data-cy="users"
								>
									{getUsers()}
								</SelectInput>
							</div>
							<div
								className={clsx({
									[classes.buttonNextToError]: hasError(errors, "users"),
								})}
							>
								<ButtonWithTooltip
									className={clsx({ [classes.button]: widthUpSm })}
									title={t("tooltips.addUser")}
									size={MEDIUM}
									variant="outlined"
									color="primary"
									onClick={addUser}
									disabled={selections.user === 0}
									data-cy="add-user"
								>
									{t("buttons.addUser")}
								</ButtonWithTooltip>
							</div>
						</div>
						{!mobile && users && board.users.length > 0 && (
							<div className={clsx(classes.sectionRow, classes.userLabels)}>
								<div className={classes.userSelect}>{t("detail.person")}</div>
								<BoardUserOptionLabels />
							</div>
						)}
						{users && board.users.length > 0 ? (
							<DndContext
								sensors={sensors}
								collisionDetection={getCollisionDetection(dragRef, rectIntersection)}
								onDragStart={handleDragStart}
								onDragEnd={handleDragEnd}
								onDragCancel={handleDragCancel}
							>
								<ol data-cy="selected-users">
									<SortableContext
										items={board.users.sort((a, b) => (a.order < b.order ? -1 : a.order > b.order ? 1 : 0)).map(getDragId)}
										strategy={verticalListSortingStrategy}
									>
										{board.users
											.map((boardUser) => ({
												boardUser,
												user: users.find((user) => user.id === boardUser.userId),
											}))
											.filter((userData) => userData.user)
											.sort((a, b) => (a.boardUser.order < b.boardUser.order ? -1 : a.boardUser.order > b.boardUser.order ? 1 : 0))
											.map((userData, index) => {
												const { boardUser, user } = userData;
												const dragId = getDragId(boardUser);

												return (
													<Sortable
														key={user.id}
														dragId={dragId}
														dragComponent={BoardUser}
														dragAttributePassthrough
														canDrag
														dragPlaceholder={draggedId === dragId}
														className={classes.sectionRow}
														{...userData}
														errors={errors}
														editable
														handleUpdate={handleUpdate}
														handleMoveUp={handleMoveUp}
														handleMoveDown={handleMoveDown}
														removeUser={removeUser}
														isFirst={index === 0}
														isLast={index === board.users.length - 1}
														anchor={anchor}
														handleMenu={handleMenu}
													></Sortable>
												);
											})}
									</SortableContext>
								</ol>
								{createPortal(
									<DragOverlay>
										<DragPresentation ref={dragRef}>
											{draggedId ? (
												<BoardUser dragPresentational className={classes.sectionRow} {...getBoardUserByDragId(draggedId)} />
											) : null}
										</DragPresentation>
									</DragOverlay>,
									document.body,
								)}
							</DndContext>
						) : (
							<div className={classes.noSelection}>
								<div>{t("detail.validation.noSelectedUsers")}</div>
							</div>
						)}
					</div>
				</div>
			</div>
		</>
	);
};

export default Board;
