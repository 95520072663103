import React from "react";
import { Highlight } from "./Assets/Highlight.js";
import { Underline } from "./Assets/Underline.js";
import { Freehand } from "./Assets/Freehand.js";
import { StickyNotes } from "./Assets/StickyNotes.js";
import { toolModes } from "./Helper/CustomUIConfiguration.js";
import { ArrowDropdown } from "./Assets/ArrowDropdown.js";

const CustomAnnotationPopup = (props) => {
	const {
		instance: { UI },
		currentCustomAnnotation,
		setCurrentCustomAnnotation,
	} = props;

	const onCustomAnnotClick = (e) => {
		const pdfViewIframe = UI.iframeWindow.document;
		let annotationElement = pdfViewIframe.querySelector(
			"div[data-element='customAnnotationMenu'] > div > div[data-element='customAnnotationOptions']",
		);
		if (annotationElement.className.includes(" closed")) {
			annotationElement.className = annotationElement.className.replace(" closed", "");
		} else {
			annotationElement.className += " closed";
		}
	};

	const onAnnotationClick = (e) => {
		e.target.parentElement.childNodes.forEach((child) => (child.className = "row"));
		if (e.target.innerText === currentCustomAnnotation.toolName) {
			UI.setToolMode("Pan");
			setCurrentCustomAnnotation(toolModes.default);
			e.target.parentElement.className += " closed";
			return;
		}
		switch (e.target.innerText) {
			case "Highlight":
				UI.setToolMode("AnnotationCreateTextHighlight");
				setCurrentCustomAnnotation(toolModes.AnnotationCreateTextHighlight);
				break;
			case "Underline":
				UI.setToolMode("AnnotationCreateTextUnderline");
				setCurrentCustomAnnotation(toolModes.AnnotationCreateTextUnderline);
				break;
			case "Freehand":
				UI.setToolMode("AnnotationCreateFreeHand");
				setCurrentCustomAnnotation(toolModes.AnnotationCreateFreeHand);
				break;
			case "Note":
				UI.setToolMode("AnnotationCreateSticky");
				setCurrentCustomAnnotation(toolModes.AnnotationCreateSticky);
				break;
			default:
				setCurrentCustomAnnotation(toolModes.default);
				break;
		}
		e.target.parentElement.className += " closed";
	};

	const styles = {
		optionRowStyle: { margin: "6px 4px 6px 4px" },
		optionIconStyle: { color: "white", marginRight: "12px" },
		optionsContainerStyle: { maxHeight: "unset", overflow: "hidden" },
		annotationContainer: { display: "flex", justifyContent: "center" },
		parentDivContainer: { display: "none" },
		annotationEditIcon: { left: "2px", position: "relative", display: "flex", justifyContent: "space-between", width: "32px" },
		annotationArrowIcon: { display: "flex", height: "100%", alignItems: "center" },
	};

	const annotationTools = [
		{
			innerText: "Highlight",
			iconComponent: <Highlight />,
			dataElement: "customHighlight",
		},
		{
			innerText: "Underline",
			iconComponent: <Underline />,
			dataElement: "customUnderline",
		},
		{
			innerText: "Freehand",
			iconComponent: <Freehand />,
			dataElement: "customFreehand",
		},
		{
			innerText: "Note",
			iconComponent: <StickyNotes />,
			dataElement: "customNote",
		},
	];

	return (
		<div style={styles.parentDivContainer}>
			<button
				class="Button ActionButton"
				onClick={onCustomAnnotClick}
				data-element="customAnnotationButton"
				aria-label="customAnnotationButton"
				type="button"
				style={styles.annotationContainer}
			>
				<div class="Icon" style={styles.annotationEditIcon}>
					{<currentCustomAnnotation.toolIcon />}
					<div style={styles.annotationArrowIcon}>{<ArrowDropdown />}</div>
				</div>
			</button>
			<div
				data-element="customAnnotationOptions"
				class="Overlay FlyoutMenu closed"
				onClick={onAnnotationClick}
				style={styles.optionsContainerStyle}
			>
				{annotationTools.map((e, index) => (
					<div
						data-element={e.dataElement}
						class={e.innerText === currentCustomAnnotation.toolName ? "row active" : "row"}
						style={styles.optionRowStyle}
						key={index}
					>
						<div class="Icon" style={styles.optionIconStyle}>
							{e.iconComponent}
						</div>
						{e.innerText}
					</div>
				))}
			</div>
		</div>
	);
};

export default CustomAnnotationPopup;
