import React from 'react';

import makeStyles from '@mui/styles/makeStyles';

import dragAndDropStyle from '../../assets/jss/components/dragAndDropStyle';

const useDragAndDropStyles = makeStyles(dragAndDropStyle);

const DragPresentation = React.forwardRef((props, ref) => {
	const { children } = props;
	const dragAndDropClasses = useDragAndDropStyles();

	return (
		<div ref={ref} className={dragAndDropClasses.dragPresentation}>
			{children}
		</div>
	);
});

export default DragPresentation;
