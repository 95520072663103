import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import makeStyles from "@mui/styles/makeStyles";
import OutlinedInput from "atlas/components/FormControls/OutlinedInput";
import Icon from "atlas/components/Icon/Icon";
import GenericDialog from "atlas/components/Dialogs/GenericDialog";
import ellipsedTruncateText from "utils/ellipsedTruncateText";

const useStyles = makeStyles(() => ({
	dialog: {
		"& .MuiDialog-paper": {
			width: "480px",
			maxWidth: "100%",
		},
	},
}));

const AddNewDocumentDialog = ({ show, onClose, onSubmit, documentDetails, setDocumentDetails, isEllipsed = false }) => {
	const { t } = useTranslation("documents");
	const [adding, setAdding] = useState(false);
	const classes = useStyles();
	const onlySpacesRegex = /^\s*$/;

	const validateInput = (name) => {
		if (name.length < 1 || onlySpacesRegex.test(name)) return true;
		return false;
	};

	const onChangeFunc = (key, value) => {
		if (key === "name" && value === " ") {
			return;
		}

		setDocumentDetails((prev) => ({
			...prev,
			[key]: value,
		}));
	};

	const handleCancel = () => {
		onClose();
	};

	const dialogConfig = {
		title: t("addFolderDialog.titleForNewDocument"),
		primaryTitle: t("app:buttons.create"),
		primaryAction: onSubmit,
		secondaryTitle: t("app:buttons.cancel"),
		secondaryAction: handleCancel,
	};

	return (
		<GenericDialog
			ellipsedTruncatedText={ellipsedTruncateText(dialogConfig.title)}
			isEllipsed={isEllipsed}
			className={classes.dialog}
			show={show}
			title={dialogConfig.title}
			primaryAction={dialogConfig.primaryAction}
			primaryTitle={dialogConfig.primaryTitle}
			primaryDisabled={validateInput(documentDetails.name) || adding}
			secondaryAction={dialogConfig.secondaryAction}
			secondaryTitle={dialogConfig.secondaryTitle}
			secondaryDisabled={adding}
			clickAwayDisabled={adding}
			closeIcon={<Icon name="close" />}
			data-cy="add-document-dialog"
		>
			<OutlinedInput
				id="name"
				label={t("addFolderDialog.labels.name")}
				placeholder={t("addFolderDialog.placeholders.documentName")}
				value={documentDetails.name}
				onChange={(e) => onChangeFunc("name", e.target.value)}
				noDefaultClassName
				fullWidth
				autoFocus
				size="small"
				data-cy="name"
			/>
		</GenericDialog>
	);
};

export default AddNewDocumentDialog;
