import React, { Fragment } from "react";
import { Close } from "components/Icons";
import { shortcutKeyBoardSectionHeading, shortCutKeyMapping, SHORTCUTKEY_DIALOG_HEADING } from "utils/enums/shortCutKeysMapping";
import GenericDialog from "atlas/components/Dialogs/GenericDialog";
import makeStyles from "@mui/styles/makeStyles";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import clsx from "clsx";

const useStyles = makeStyles(() => ({
	listItem: {
		marginLeft: "0px",
		paddingLeft: "0px",
		marginBottom: "0px",
		paddingBottom: "0px",
	},
	listItemSpan: {
		"& span": {
			fontWeight: "700",
		},
	},
	itemListKeys: {
		textAlign: "right",
	},
	keyStyle: {
		height: "32px",
		background: "#F0F0F0",
		borderRadius: "4px",
		display: "flex",
		marginBottom: "10px",
		fontFamily: "Courier",
		paddingLeft: "11px",
		paddingRight: "11px",
	},
	keySpan: {
		height: "18px",
		alignItems: "center",
		textAlign: "center",
		"& span": {
			fontWeight: "400",
			fontFamily: "Courier",
			fontStyle: "normal",
			fontSize: "16px",
			lineHeight: "22px",
			color: "#3F3D3C",
		},
	},
	plusIcon: {
		display: "flex",
		alignItems: "center",
		textAlign: "center",
		marginBottom: "15px",
		marginLeft: "5px",
		marginRight: "5px",
		"& span": {
			fontWeight: "400",
			fontFamily: "Lato",
			fontStyle: "normal",
			fontSize: "16px",
			lineHeight: "19px",
			color: "#717171",
		},
	},
	popup: {
		"& .MuiDialog-paper": {
			position: "absolute",
			top: "0px",
			right: "0px",
			margin: "0px",
			minHeight: "100vh",
			width: "450px",
			top: "0px",
			right: "0px",
			margin: "0px",
			minHeight: "100vh",
		},
	},
}));

const ShortcutKeysDialog = (props) => {
	const { show, onClose } = props;
	const handleCancel = () => {
		onClose();
	};
	const classes = useStyles();

	const dialog = {
		title: SHORTCUTKEY_DIALOG_HEADING,
		secondaryAction: handleCancel,
	};

	return (
		<GenericDialog
			className={classes.popup}
			show={show}
			title={dialog.title}
			secondaryAction={dialog.secondaryAction}
			closeIcon={<Close />}
			fullWidth
		>
			<List>
				{shortcutKeyBoardSectionHeading.map((item, index) => {
					return (
						<div key={index}>
							<ListItem className={classes.listItem}>
								<ListItemText className={clsx(classes.listText, classes.listItemSpan)} primary={item.headingValue} />
							</ListItem>
							<Divider />
							{shortCutKeyMapping[item.headingKey].map((subItem, subIndex) => {
								return (
									<div key={subIndex}>
										<ListItem className={clsx(classes.listItem)}>
											<ListItemText primary={subItem.name} />
											{subItem.keyboardKeys.map((keyboardKey, keysIndex) => {
												return (
													<Fragment key={keysIndex}>
														<div className={classes.keyStyle}>
															<ListItemText className={clsx(classes.keySpan, classes.listItemSpan)} primary={keyboardKey} />
														</div>
														{subItem.keyboardKeys.length > 1 && keysIndex !== subItem.keyboardKeys.length - 1 && (
															<div>
																<ListItemText className={classes.plusIcon} primary={"+"} />
															</div>
														)}
													</Fragment>
												);
											})}
										</ListItem>
										<Divider />
									</div>
								);
							})}
						</div>
					);
				})}
			</List>
		</GenericDialog>
	);
};
export default ShortcutKeysDialog;
