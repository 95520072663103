import React from "react";
import SvgIcon from "@mui/material/SvgIcon";

import themeConfig from "../../atlas/assets/jss/theme";
import getOr from "lodash/fp/getOr";

const icon = (props) => {
	const { color } = props;
	const fillColor = color ? getOr(color, ["palette", color, "main"], themeConfig) : "currentColor";

	return (
		<SvgIcon {...props}>
			<defs>
				<path
					d="M9.33050009,12.3338882 L16.7693657,4.43009349 C17.0905502,4.08883496 17.074277,3.55181878 16.7330185,3.23063428 C16.3917599,2.90944978 15.8547438,2.92572299 15.5335593,3.26698153 L7,12.3338882 L15.5335593,21.4007949 C15.8547438,21.7420535 16.3917599,21.7583267 16.7330185,21.4371422 C17.074277,21.1159577 17.0905502,20.5789415 16.7693657,20.237683 L9.33050009,12.3338882 Z"
					id="caret-right-path-1"
				/>
			</defs>
			<g id="caret-right" stroke="none" strokeWidth="1" fill="none" transform="rotate(180, 12, 12)" fillRule="evenodd">
				<g id="Icons/24px/Unselected/Caret/Right">
					<mask id="caret-right-mask-2" fill="white">
						<use xlinkHref="#caret-right-path-1" />
					</mask>
					<use fill="#FFFFFF" fillRule="nonzero" xlinkHref="#caret-right-path-1" />
					<g mask="url(#caret-right-mask-2)" fill={fillColor} fillRule="evenodd">
						<rect x="0" y="0" width="24" height="24" />
					</g>
				</g>
			</g>
		</SvgIcon>
	);
};

export default icon;
