import { signalR } from "utils/communication/SignalRProxy";
import { updateSignalR } from "redux/app/actions";
import getMeetingControlPanelHandlers from "./meetingControlPanelHandlers";
import { infoColor, successColor, warningColor, errorColor } from "atlas/assets/jss/shared";

export const PRESENCE_HUB = "concurrentEditorsHub";
export const CHAT_HUB = "concurrentEditorsHub";
export const LIVE_MEETING_HUB = "meetingControlPanelHub";
export const PROGRESS_HUB = "progressHub";
export const GENERAL_HUB = "generalHub";
export const VOTING_HUB = "votingHub";

const connectionStatusUrlsAllowed = ["/meeting/liveV2", "/meeting/presentation", "/meeting/document", "/public/meeting/document"];
const connectionStates = { 0: "connecting", 1: "connected", 2: "reconnecting", 3: "slow", 4: "disconnected" };
const connectionStateColor = {
	connecting: infoColor,
	connected: successColor,
	reconnecting: warningColor,
	slow: warningColor,
	disconnected: errorColor,
};

const registerClientHandler = (hub, hubName, methodName, clientHandler) => {
	hub.on(methodName, (...args) => {
		// Find the client method in clientHandler and call it
		const { [hubName]: { [methodName]: handler = () => {} } = {} } = clientHandler;
		if (typeof handler === "function") {
			handler(...args);
		}
	});
};

const SignalRClient = (props) => {
	const { concurrentEditorsHub, meetingControlPanelHub, progressHub, generalHub, votingHub } = signalR;
	const { initialize = false, clientHandler, t } = props;

	if (!signalR.initialized || initialize) {
		// Presence events
		registerClientHandler(concurrentEditorsHub, PRESENCE_HUB, "announceEditor", clientHandler);
		registerClientHandler(concurrentEditorsHub, PRESENCE_HUB, "removeEditor", clientHandler);

		// Chat events
		registerClientHandler(concurrentEditorsHub, CHAT_HUB, "sendMessage", clientHandler);

		// Live meeting events
		registerClientHandler(meetingControlPanelHub, LIVE_MEETING_HUB, "updateMeetingPosition", clientHandler);
		registerClientHandler(meetingControlPanelHub, LIVE_MEETING_HUB, "clearMeetingPosition", clientHandler);
		registerClientHandler(meetingControlPanelHub, LIVE_MEETING_HUB, "setLiveMeetings", clientHandler);
		registerClientHandler(meetingControlPanelHub, LIVE_MEETING_HUB, "updateActiveItem", clientHandler);
		registerClientHandler(meetingControlPanelHub, LIVE_MEETING_HUB, "clearActiveItem", clientHandler);
		registerClientHandler(meetingControlPanelHub, LIVE_MEETING_HUB, "updateTimer", clientHandler);
		registerClientHandler(meetingControlPanelHub, LIVE_MEETING_HUB, "loadMeetingItems", clientHandler);

		// Progress events
		registerClientHandler(progressHub, PROGRESS_HUB, "announceProgress", clientHandler);

		// General Event
		registerClientHandler(generalHub, GENERAL_HUB, "requestUpdatedOutstandingAgendaItemApprovalsCount", clientHandler);
		registerClientHandler(generalHub, GENERAL_HUB, "updateOutstandingAgendaItemApprovalsCount", clientHandler);
		registerClientHandler(generalHub, GENERAL_HUB, "updateSubmittedRTSCount", clientHandler);

		// Voting Events
		registerClientHandler(votingHub, VOTING_HUB, "showResults", clientHandler);
		registerClientHandler(votingHub, VOTING_HUB, "hideResults", clientHandler);
		registerClientHandler(votingHub, VOTING_HUB, "reSendForVote", clientHandler);

		signalR.initialized = true;
	}

	const statusChanged = (state) => {
		let stateElem = document.querySelector("span.signalr-status-icon");
		if (!stateElem) {
			stateElem = document.createElement("span");
			stateElem.setAttribute("class", "signalr-status-icon");
			stateElem.style.position = "absolute";
			stateElem.style.bottom = "8px";
			stateElem.style.right = "8px";
			stateElem.style.zIndex = 9999;
			stateElem.style.border = "6px solid";
			stateElem.style.borderRadius = "10px";
			const parentElem = document.querySelector("main") || document.querySelector("body");
			if (parentElem) {
				parentElem.prepend(stateElem);
			}
		}

		stateElem.setAttribute("title", t(`app:tooltips.signalR.${connectionStates[state.newState]}`));

		switch (state.newState) {
			case 0:
				stateElem.style.borderColor = connectionStateColor.connecting;
				break;
			case 1:
				stateElem.style.borderColor = connectionStateColor.connected;
				break;
			case 2:
				stateElem.style.borderColor = connectionStateColor.reconnecting;
				break;
			case 3:
				stateElem.style.borderColor = connectionStateColor.slow;
				break;
			case 4:
				stateElem.style.borderColor = connectionStateColor.disconnected;
				break;
		}
	};

	const updateStatusIcon = () => {
		if (signalR && signalR.hub && connectionStatusUrlsAllowed.find((urlPart) => window.location.pathname.indexOf(urlPart) > 0) != null) {
			statusChanged({ newState: signalR.hub.state });
			signalR.hub.stateChanged(statusChanged);
		} else {
			const stateElem = document.querySelector("span.signalr-status-icon");
			if (stateElem) {
				stateElem.remove();
			}
		}
	};

	const tryStart = (resolve, reject, iteration) => {
		if (signalR.hub.state === signalR.connectionState.disconnected) {
			let reconnectCount = 0;
			const startDone = () => {
				reconnectCount = 0;

				resolve();
			};

			signalR.hub.logging = true;
			signalR.hub.start({ transport: ["webSockets", "serverSentEvents"] }).done(startDone);

			signalR.hub.disconnected(() => {
				if (reconnectCount < 10) {
					reconnectCount += 1;
					setTimeout(() => {
						signalR.hub.start().done(startDone);
					}, 3000); // Restart connection after 3 seconds.
				}
			});
		} else if (signalR.hub.state === signalR.connectionState.connected) {
			resolve();
		} else if (
			(signalR.hub.state === signalR.connectionState.connecting || signalR.hub.state === signalR.connectionState.reconnecting) &&
			iteration <= 10
		) {
			setTimeout(() => {
				tryStart(resolve, reject, iteration + 1);
			}, 500); // Try again in 0.5 seconds
		} else {
			// Timed out
			reject();
		}
	};

	const start = () =>
		new Promise((resolve, reject) => {
			if (signalR && signalR.hub) {
				tryStart(resolve, reject, 0);
			} else {
				reject(new Error("SignalR not loaded"));
			}
		});

	const stop = () => {
		if (signalR && signalR.hub) {
			if (signalR.hub.state === signalR.connectionState.connected) {
				signalR.hub.stop(true, true);
			}
		}
	};

	return {
		presenceHub: concurrentEditorsHub.server,
		liveMeetingHub: meetingControlPanelHub.server,
		progressHub: progressHub.server,
		generalHub: generalHub.server,
		votingHub: votingHub.server,
		updateStatusIcon,
		start,
		ensureStarted: start,
		stop,
	};
};

const setupSignalR = (props, handler) => {
	const signalRClientHandler = handler || {
		[LIVE_MEETING_HUB]: getMeetingControlPanelHandlers(props),
		[PROGRESS_HUB]: {},
		[GENERAL_HUB]: {},
		[VOTING_HUB]: {},
	};
	const client = SignalRClient({ t: props.t, clientHandler: signalRClientHandler });
	const done = () => {
		// Load initial data for this user
		client.liveMeetingHub.checkLiveMeetings();
	};
	props.dispatch(updateSignalR({ client, handler: signalRClientHandler, done }));
};

export const setupPublicSignalR = (props, handler, done) => {
	const signalRClientHandler = handler || {};
	const client = SignalRClient({ t: props.t, clientHandler: signalRClientHandler });
	const finalDone =
		typeof done === "function"
			? () => {
					done(client);
			  }
			: undefined;
	props.dispatch(updateSignalR({ client, handler: signalRClientHandler, done: finalDone }));
};

export const updateHandlers = (dispatch, handler, hub, handlers) => {
	dispatch(
		updateSignalR({
			handler: {
				[hub]: {
					...handler[hub], // Preserve unmodified handlers
					...handlers,
				},
			},
		}),
	);
};

export default setupSignalR;
