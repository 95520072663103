import { Plugin } from "@ckeditor/ckeditor5-core";
import TwoStepCaretMovement from "@ckeditor/ckeditor5-typing/src/twostepcaretmovement";

import InlineFileCommandLinkMO from "./inlineFileCommandLinkMO";
import InlineFileCommandUnlinkMO from "./inlineFileCommandUnlinkMO";
import { findLinkRange, createLinkElement, getElementText, createFileHref } from "../inlineFileUtils";

const HIGHLIGHT_CLASS = "ck-link_selected";

export default class InlineFileEditingMO extends Plugin {
	static get requires() {
		return [TwoStepCaretMovement];
	}

	init() {
		const editor = this.editor;

		editor.commands.add("inlineFileLinkMO", new InlineFileCommandLinkMO(editor));
		editor.commands.add("inlineFileUnlinkMO", new InlineFileCommandUnlinkMO(editor));

		this._defineSchema();
		this._defineConverters();

		editor.plugins.get("TwoStepCaretMovement").registerAttribute("fileHref");

		this._setupLinkHighlight();
	}

	_defineSchema() {
		const schema = this.editor.model.schema;

		schema.extend("$text", { allowAttributes: "fileHref" });
	}

	_defineConverters() {
		const editor = this.editor;
		const conversion = editor.conversion;
		const linkCommand = editor.commands.get("inlineFileLinkMO");
		const { featureConfigs, anchorTitle } = linkCommand;
		const info = { anchorTitle: anchorTitle };

		conversion.for("dataDowncast").attributeToElement({
			model: "fileHref",
			view: (href, writer) => {
				const linkCommand = editor.commands.get("inlineFileLinkMO");
				return createLinkElement(href, writer, { anchorTitle: linkCommand.anchorTitle, featureConfigs: linkCommand.featureConfigs });
			},
		});
		conversion.for("editingDowncast").attributeToElement({
			model: "fileHref",
			view: (href, writer) => {
				const linkCommand = editor.commands.get("inlineFileLinkMO");
				return createLinkElement(href, writer, { anchorTitle: linkCommand.anchorTitle, featureConfigs: linkCommand.featureConfigs });
			},
		});
		conversion.for("upcast").elementToAttribute({
			view: {
				name: "a",
				classes: ["inlineFile"],
				attributes: {
					href: true,
				},
			},
			model: {
				key: "fileHref",
				value: (viewElement) => {
					const href = viewElement.getAttribute("href");
					const text = getElementText(viewElement);
					let name = viewElement.getAttribute("name");
					if (!name || name === "") {
						name = viewElement.getAttribute("title");
					}
					const features = [];
					if (featureConfigs) {
						for (let i = 0; i < featureConfigs.length; i++) {
							const featureConfig = featureConfigs.get(i);
							const feature = {
								id: featureConfig.id,
								className: featureConfig.className,
								value: false,
								anchorTitle: featureConfig.anchorTitle,
							};
							if (viewElement.hasClass(featureConfig.className)) {
								feature.value = true;
							}
							features.push(feature);
						}
					}
					return JSON.stringify(createFileHref(href, text, name, features));
				},
			},
			converterPriority: "highest",
		});
	}

	_setupLinkHighlight() {
		const editor = this.editor;
		const view = editor.editing.view;
		const highlightedLinks = new Set();

		view.document.registerPostFixer((writer) => {
			const selection = editor.model.document.selection;
			let changed = false;

			if (selection.hasAttribute("fileHref")) {
				const modelRange = findLinkRange(selection.getFirstPosition(), selection.getAttribute("fileHref"), editor.model);
				const viewRange = editor.editing.mapper.toViewRange(modelRange);

				for (const item of viewRange.getItems()) {
					if (item.is("element", "a") && !item.hasClass(HIGHLIGHT_CLASS)) {
						writer.addClass(HIGHLIGHT_CLASS, item);
						highlightedLinks.add(item);
						changed = true;
					}
				}
			}
			return changed;
		});

		editor.conversion.for("editingDowncast").add((dispatcher) => {
			dispatcher.on("insert", removeHighlight, { priority: "highest" });
			dispatcher.on("remove", removeHighlight, { priority: "highest" });
			dispatcher.on("attribute", removeHighlight, { priority: "highest" });
			dispatcher.on("selection", removeHighlight, { priority: "highest" });

			function removeHighlight() {
				view.change((writer) => {
					for (const item of highlightedLinks.values()) {
						writer.removeClass(HIGHLIGHT_CLASS, item);
						highlightedLinks.delete(item);
					}
				});
			}
		});
	}
}
