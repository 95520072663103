const selectInputOptionStyle = () => ({
	menuItem: {
		maxWidth: (props) => (props && props.selectWidth > 0 ? `${props.selectWidth}px` : undefined),
	},
	menuItemText: {
		overflow: 'hidden',
		textOverflow: 'ellipsis',
	},
});

export default selectInputOptionStyle;
