import React from 'react';
import clsx from 'clsx';

import makeStyles from '@mui/styles/makeStyles';

import { grayColor } from '../../assets/jss/shared';
import dragAndDropStyle from '../../assets/jss/components/dragAndDropStyle';
import Icon from '../Icon/Icon';

const useDragAndDropStyles = makeStyles(dragAndDropStyle);

const DragHandle = (props) => {
	const { className, size = '16px', color = grayColor[0], ...otherProps } = props;
	const dragAndDropClasses = useDragAndDropStyles({ dragHandleSize: size });

	return (
		<div className={clsx(dragAndDropClasses.dragHandle, className)} data-role="drag-handle" {...otherProps}>
			<Icon name="drag-handle" size={size} color={color} />
		</div>
	);
};

export default DragHandle;
