import React, { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { cloneDeep, debounce } from "lodash";
import { createPortal } from "react-dom";
import { DndContext, DragOverlay, KeyboardSensor, PointerSensor, useSensor, useSensors } from "@dnd-kit/core";

import makeStyles from "@mui/styles/makeStyles";
import { Typography, Checkbox, FormControlLabel } from "@mui/material";
import { Check } from "components/Icons";

import { blackColor, grayColor } from "atlas/assets/jss/shared";
import OutlinedInput from "atlas/components/FormControls/OutlinedInput";
import StackedCheckbox from "atlas/components/FormControls/StackedCheckbox";
import InputLabel from "atlas/components/FormControls/InputLabel";
import Draggable from "atlas/components/DragAndDrop/Draggable";
import Droppable from "atlas/components/DragAndDrop/Droppable";
import DragPresentation from "atlas/components/DragAndDrop/DragPresentation";
import ButtonWithTooltip from "atlas/components/Buttons/ButtonWithTooltip";
import { useWidthDown } from "atlas/utils/useWidth";
import SubscribersUser from "./components/SubscribersUser";

const useStyles = makeStyles((theme) => ({
	meetingTypes: {
		"& > div:not(:first-child)": {
			border: `1px solid ${grayColor[4]}`,
			borderRadius: "4px",
			"& > label": {
				margin: 0,
				padding: 0,
				"&:not(:first-child)": {
					borderTop: `1px solid ${grayColor[4]}`,
				},
			},
		},
	},
	meetingTypesLabel: {
		fontWeight: 600,
		textTransform: "uppercase",
	},
	userColumns: {
		display: "flex",
		flexWrap: "wrap",
		margin: "0 -12px",
		[theme.breakpoints.down("sm")]: {
			margin: "0",
		},
		"& > div": {
			boxSizing: "border-box",
			margin: "0 12px",
			[theme.breakpoints.down("sm")]: {
				margin: "0",
			},
		},
		"& > div:first-child": {
			width: "calc(30% - 24px)",
			[theme.breakpoints.down("sm")]: {
				width: "100%",
			},
		},
		"& > div:not(:first-child)": {
			width: "calc(70% - 24px)",
			[theme.breakpoints.down("sm")]: {
				width: "100%",
			},
		},
	},
	inputLabelSpacing: {
		marginTop: "8px",
	},
	userList: {
		top: 0,
		border: `solid 1px ${grayColor[1]}`,
		borderRadius: "5px",
		height: "50vh",
		overflowY: "auto",
		overflowX: "hidden",
		color: blackColor[1],
		"& ol": {
			margin: "0",
			padding: "0",
			listStyleType: "none",
		},
	},
	body: {
		border: "1px solid transparent",
		"& ol": {
			margin: "0",
			padding: "0",
			listStyleType: "none",
		},
	},
	noUsers: {
		display: "flex",
		alignItems: "center",
		height: "48px",
		paddingLeft: "8px",
		border: `solid 1px ${grayColor[1]}`,
		borderRadius: "4px",
		"& > div": {
			flexGrow: "1",
			opacity: "0.7",
		},
	},
	createButtonContainer: {
		textAlign: "right",
		width: "100% !important",
	},
	externalEmails: {
		marginTop: "8px",
	},
	externalEmailsInput: {
		marginTop: "2px",
		height: "auto",
		"& > div": {
			marginTop: "2px",
		},
	},
}));

const CreateSubscribers = (props) => {
	const { createDisabled, subscription, meetingTypes, users, subscriptionChange, handleCreate, externalEmailError } = props;
	const mobile = useWidthDown("md");
	const { t } = useTranslation("subscribers");
	const sensors = useSensors(useSensor(PointerSensor), useSensor(KeyboardSensor));
	const dragRef = useRef(null);

	const classes = useStyles();

	const [externalEmailsValue, setExternalEmailsValue] = useState("");
	const [draggedId, setDraggedId] = useState(null);
	const [dropTarget, setDropTarget] = useState(null);

	const canDrag = !mobile;

	const fieldChange = (value, field, action) => {
		const newSubscription = cloneDeep(subscription);

		switch (field) {
			case "meetingTypes":
				const intValue = parseInt(value, 10);
				const selectedMeetingTypeIndex = newSubscription.meetingTypes.indexOf(intValue);
				if (selectedMeetingTypeIndex >= 0) {
					newSubscription.meetingTypes.splice(selectedMeetingTypeIndex, 1);
				} else {
					newSubscription.meetingTypes.push(intValue);
				}
				break;
			case "sendUserVerification":
				newSubscription.sendUserVerification = value;
				break;
			case "sendExternalVerification":
				newSubscription.sendExternalVerification = value;
				break;
			case "users":
				const intUserValue = parseInt(value, 10);
				const selectedUserIndex = newSubscription.users.indexOf(intUserValue);
				if (selectedUserIndex >= 0 && action === "remove") {
					newSubscription.users.splice(selectedUserIndex, 1);
				} else if (action === "add") {
					newSubscription.users.push(intUserValue);
				}
				break;
			case "externalEmails":
				newSubscription.externalEmailsValue = value;
				break;
		}

		subscriptionChange(newSubscription);
	};

	const externalUsersChange = debounce(() => {
		fieldChange(externalEmailsValue, "externalEmails");
	}, 500);

	const handleDragStart = (e) => {
		if (!canDrag) {
			return false;
		} else {
			const { active } = e;
			const { id } = active;
			const userSelected = subscription.users.indexOf(id) >= 0;

			setDraggedId(active.id);
			if (!userSelected) {
				// Adding
				setDropTarget("selected-people");
			} else {
				// Removing
				setDropTarget("available-people");
			}

			document.body.style.userSelect = "none";
		}
	};

	const endDrag = () => {
		setDraggedId(null);
		setDropTarget("");

		document.body.style.userSelect = null;
	};

	const handleDragEnd = (e) => {
		const { active } = e;
		const { id } = active;
		const userSelected = subscription.users.indexOf(id) >= 0;

		if (active) {
			fieldChange(id, "users", userSelected ? "remove" : "add");
		}

		endDrag();
	};

	const handleDragCancel = (e) => {
		endDrag();
	};

	useEffect(() => {
		externalUsersChange();
	}, [externalEmailsValue]);

	return (
		<>
			<div id="details">
				<div id="meeting-types" className={classes.meetingTypes}>
					<div>
						<Typography variant="body1" className={classes.meetingTypesLabel}>
							{t("meetingTypes")}
						</Typography>
					</div>
					<StackedCheckbox
						options={meetingTypes}
						getChecked={(meetingType) => subscription && subscription.meetingTypes && subscription.meetingTypes.includes(meetingType.id)}
						getId={(meetingType) => `subscription-meeting-type-${meetingType.id}`}
						getKey={(meetingType) => `subscription-meeting-type-${meetingType.id}`}
						getValue={(meetingType) => meetingType.id}
						getDataCy={(meetingType) => `subscription-meeting-type-${meetingType.id}`}
						getLabel={(meetingType) => meetingType.name}
						handleChange={(e) => fieldChange(e.id, "meetingTypes")}
					/>
				</div>
			</div>
			<div id="subscribers" className={classes.userColumns}>
				<DndContext
					autoScroll={{ enabled: false }}
					sensors={sensors}
					onDragStart={handleDragStart}
					onDragEnd={handleDragEnd}
					onDragCancel={handleDragCancel}
				>
					<div>
						<div className={classes.inputLabelSpacing}>
							<InputLabel htmlFor="available-people" size="large" label={t("availablePeople")} />
						</div>
						{users && (
							<Droppable
								className={classes.userList}
								dropId="selected-people"
								useHighlightDroppable
								highlightDroppable={dropTarget === "available-people"}
								dropComponent="div"
							>
								<ol data-cy="available-user-list">
									{users
										.filter((user) => !user.inactive && !user.deleted && subscription.users.indexOf(user.id) < 0)
										.map((user) => (
											<Draggable
												key={user.id}
												dragId={user.id}
												dragComponent={SubscribersUser}
												dragAttributePassthrough={canDrag}
												canDrag={canDrag}
												dragPlaceholder={draggedId === user.id}
												user={user}
												actionIcon="add-circle"
												actionTooltip={t("tooltips.addUser")}
												handleAction={() => {
													fieldChange(user.id, "users", "add");
												}}
											/>
										))}
								</ol>
							</Droppable>
						)}
					</div>
					<div>
						<div className={classes.inputLabelSpacing}>
							<InputLabel htmlFor="selected-people" size="large" label={t("selectedPeople")} />
						</div>
						<Droppable
							className={classes.body}
							dropId={"selected-people"}
							useHighlightDroppable
							highlightDroppable={dropTarget === "selected-people"}
							dropComponent="div"
						>
							{subscription.users && subscription.users.length > 0 ? (
								<ol data-cy="selected-user-list">
									{subscription.users.map((subscriptionUserId) => (
										<Draggable
											key={subscriptionUserId}
											dragId={subscriptionUserId}
											dragComponent={SubscribersUser}
											dragAttributePassthrough={canDrag}
											canDrag={canDrag}
											dragPlaceholder={draggedId === subscriptionUserId}
											user={users.find((user) => user.id === subscriptionUserId)}
											actionIcon="remove"
											actionTooltip={t("tooltips.removeUser")}
											handleAction={() => {
												fieldChange(subscriptionUserId, "users", "remove");
											}}
										/>
									))}
								</ol>
							) : (
								<div className={classes.noUsers}>
									<div>{t("noSelectedUsers")}</div>
								</div>
							)}
						</Droppable>
						<div>
							{subscription.users && subscription.users.length > 0 && (
								<FormControlLabel
									id="users-send-verification-checkbox"
									control={
										<Checkbox
											checkedIcon={<Check fontSize="small" color="primary" />}
											checked={!!(subscription && subscription.sendUserVerification)}
											onChange={(e) => fieldChange(e.target.checked, "sendUserVerification")}
										/>
									}
									label={t("sendVerification")}
									data-cy="sendUserVerification"
								/>
							)}
						</div>
						<div id="external-emails-container" className={classes.externalEmails}>
							<InputLabel
								className={classes.inputLabelSpacing}
								htmlFor="external-emails"
								size="large"
								label={t("addExternalEmailAddresses")}
							/>
							<OutlinedInput
								id="external-emails"
								className={classes.externalEmailsInput}
								value={externalEmailsValue || ""}
								onChange={(e) => {
									setExternalEmailsValue(e.target.value);
								}}
								error={externalEmailError}
								fullWidth
								multiline
								rows={5}
								placeholder={t("noEmailsAddedPlaceholder")}
								data-cy="external-emails"
							/>
							<div>
								{externalEmailsValue && externalEmailsValue.length > 0 && (
									<FormControlLabel
										id="external-send-verification-checkbox"
										control={
											<Checkbox
												checkedIcon={<Check fontSize="small" color="primary" />}
												checked={!!(subscription && subscription.sendExternalVerification)}
												onChange={(e) => fieldChange(e.target.checked, "sendExternalVerification")}
											/>
										}
										label={t("sendVerification")}
										data-cy="sendExternalVerification"
									/>
								)}
							</div>
						</div>
					</div>
					{canDrag &&
						createPortal(
							<DragOverlay>
								<DragPresentation ref={dragRef}>
									{draggedId ? (
										<SubscribersUser dragPresentational user={draggedId ? users.find((user) => user.id === draggedId) : null} />
									) : null}
								</DragPresentation>
							</DragOverlay>,
							document.body,
						)}
				</DndContext>
				<div className={classes.createButtonContainer}>
					<ButtonWithTooltip
						title={t("tooltips.create")}
						primary
						color="primary"
						onClick={handleCreate}
						disabled={createDisabled}
						data-cy="createSubscription"
					>
						{t("buttons.create")}
					</ButtonWithTooltip>
				</div>
			</div>
		</>
	);
};

export default CreateSubscribers;
