import { differenceInMinutes, getHours, getMinutes, parseISO } from "date-fns";

export const getDurations = (t) => [
	{ label: t ? `15 ${t("durations.minutes")}` : undefined, value: 0.25 },
	{ label: t ? `30 ${t("durations.minutes")}` : undefined, value: 0.5 },
	{ label: t ? `45 ${t("durations.minutes")}` : undefined, value: 0.75 },
	{ label: t ? `1 ${t("durations.hour")}` : undefined, value: 1 },
	{ label: t ? `1.5 ${t("durations.hours")}` : undefined, value: 1.5 },
	{ label: t ? `2 ${t("durations.hours")}` : undefined, value: 2 },
	{ label: t ? `2.5 ${t("durations.hours")}` : undefined, value: 2.5 },
	{ label: t ? `3 ${t("durations.hours")}` : undefined, value: 3 },
	{ label: t ? t("app:options.none") : undefined, value: null },
];

export const getNearestDurationOption = (duration, durationOptions) => {
	const durations = durationOptions || getDurations();
	let finalDuration = null;

	if (duration && duration > 0) {
		let bestDifference = null;
		durations.forEach((option) => {
			if (option.value) {
				const difference = Math.abs(duration - option.value);
				if (bestDifference == null || difference < bestDifference) {
					finalDuration = option.value;
					bestDifference = difference;
				}
			}
		});
	}

	return finalDuration;
};

export const processTimes = (objectWithTimes) => {
	if (!objectWithTimes?.startTime) {
		// Probably an imported meeting from BoardDocs
		return objectWithTimes;
	}

	const startTime = parseISO(objectWithTimes.startTime);
	const startTimeHours = getHours(startTime);
	return {
		...objectWithTimes,
		hour: startTimeHours > 12 ? startTimeHours - 12 : startTimeHours === 0 ? 12 : startTimeHours,
		minute: getMinutes(startTime) / 60,
		period: startTimeHours >= 12 ? 2 : 1,
		duration:
			objectWithTimes.startTime && objectWithTimes.endTime
				? getNearestDurationOption(differenceInMinutes(parseISO(objectWithTimes.endTime), startTime) / 60)
				: null,
	};
};
