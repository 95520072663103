/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import request from "superagent";

import makeStyles from "@mui/styles/makeStyles";
import Box from "@mui/material/Box";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";

import { getMeetingOutputI18nLabel } from "utils/meetingOutputText";
import CircularProgressIndicator from "atlas/components/Progress/CircularProgressIndicator";
import { API_HOST } from "config/env";
import telemetryAddEvent from "utils/telemetryAddEvent";
import Icon from "atlas/components/Icon/Icon";
import GroupMember from "./GroupMember";

const useStyles = makeStyles({
	summary: {
		padding: "0",
	},
});

const GroupMemberList = (props) => {
	const {
		agenda,
		meeting: { id },
		showSelect,
		handleCheckboxChange,
		selectedUsers,
		showSignIn,
		telemetryPage = "",
	} = props;
	const { t } = useTranslation("meetings");
	const [gettingMembers, setGettingMembers] = useState(false);
	const [expanded, setExpanded] = useState(false);
	const [members, setMembers] = useState(null);
	const classes = useStyles();

	const getMembers = async () => {
		try {
			const res = await request.get(`${API_HOST}/api/meeting/${id}/members`);
			setGettingMembers(false);
			setMembers(res.body);
		} catch (err) {
			showSignIn(err, () => {
				getMembers();
			});
		}
	};

	useEffect(() => {
		getMembers();
	}, []);

	const dialog = {
		confInfo: t(getMeetingOutputI18nLabel("publishMeetingDialog.info.confidential", agenda)),
	};

	return (
		<Box mt={1} mb={1}>
			<Accordion elevation={0} classes={{ root: "expansion-panel-root" }} square data-cy="member-list">
				<AccordionSummary
					className={classes.summary}
					classes={{ root: "expansion-summary-root", content: "expansion-summary-content" }}
					expandIcon={<Icon name={expanded ? "expand-down" : "expand-right"} />}
					data-cy="member-list-summary"
					onClick={() => {
						telemetryAddEvent(`${telemetryPage} - Expand members`);

						setExpanded(!expanded);
					}}
				>
					<Box mt="auto" mb="auto" mr={2}>
						<Icon name="locked" />
					</Box>
					<Box mt="auto" mb="auto" mr={2}>
						<Typography variant="body1">{dialog.confInfo}</Typography>
					</Box>
				</AccordionSummary>
				<AccordionDetails>
					<List>
						{gettingMembers ? (
							<CircularProgressIndicator size={20} />
						) : (
							members &&
							members.map((member, index) => {
								const { id, name, email, avatar, firstName, lastName } = member;
								return (
									<GroupMember
										id={id}
										name={name}
										firstName={firstName}
										lastName={lastName}
										email={email}
										avatar={avatar}
										avatarNumber={index}
										key={index}
										showSelect={showSelect}
										handleCheckboxChange={handleCheckboxChange}
										selectedUsers={selectedUsers}
									/>
								);
							})
						)}
					</List>
				</AccordionDetails>
			</Accordion>
		</Box>
	);
};

export default GroupMemberList;
