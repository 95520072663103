import React from "react";
import { Routes, Route } from "react-router-dom";

import PublicRequestToSpeakSubmission from "views/RequestToSpeak/PublicRequestToSpeakSubmission";
import PublicRequestToSpeakCancel from "views/RequestToSpeak/PublicRequestToSpeakCancel";

const PublicRequestToSpeakRoutes = () => {
	// Base path = /public/requesttospeak/
	return (
		<Routes>
			<Route path=":id" element={<PublicRequestToSpeakSubmission />} />
			<Route path=":id/cancel" element={<PublicRequestToSpeakCancel />} />
			<Route path=":id/update" element={<PublicRequestToSpeakSubmission update />} />
		</Routes>
	);
};

export default PublicRequestToSpeakRoutes;
