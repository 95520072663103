import React from "react";

import makeStyles from "@mui/styles/makeStyles";

import AccessibleIconButton from "atlas/components/Buttons/AccessibleIconButton";
import NonModalMenu from "atlas/components/Menu/NonModalMenu";
import menuStyle from "atlas/assets/jss/components/menuStyle";

const useStyles = makeStyles(() => ({
	menuColumn: {
		textAlign: "right",
		flexShrink: "0",
	},
	menuButton: {
		margin: "-12px 0",
	},
	menu: {
		...menuStyle.menu,
	},
}));

const DocumentTreeMenuItemColumn = (props) => {
	const { document, options, menuTooltipText, width, anchor, setAnchor } = props;
	const classes = useStyles();

	const toggleMenu = (e) => {
		e.stopPropagation();
		e.preventDefault();

		setAnchor(document, e.currentTarget);
	};

	const closeMenu = () => {
		setAnchor(document, null);
	};

	const processedMenuOptions = (typeof options === "function" ? options(document) : options).filter((option) => !option.hidden);

	return (
		<div className={classes.menuColumn} style={{ width: width }}>
			{processedMenuOptions && processedMenuOptions.length > 0 && (
				<>
					<AccessibleIconButton
						className={classes.menuButton}
						iconColor="inherit"
						aria-label={menuTooltipText}
						onClick={toggleMenu}
						iconName="more"
						iconSize="20px"
						dataCy="row-menu-button"
					/>
					{anchor && (
						<NonModalMenu
							id="table-menu"
							className={classes.menu}
							anchorEl={anchor}
							keepMounted
							open
							onClose={closeMenu}
							options={processedMenuOptions.map((option) => ({ ...option, actionFunction: () => option.actionFunction(document) }))}
							position="bottom-end"
						/>
					)}
				</>
			)}
		</div>
	);
};

export default DocumentTreeMenuItemColumn;
