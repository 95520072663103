import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import parse, { domToReact } from "html-react-parser";

import { useWidthDown } from "atlas/utils/useWidth";
import { successColor, errorColor, warningColor, whiteColor } from "atlas/assets/jss/shared";
import Icon from "atlas/components/Icon/Icon";

import { Dialog, DialogContent, DialogTitle, Grid } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import VoteResultType from "components/Meeting/VoteResultType";
import { stripHtml } from "utils/processHtml";
import checkVotingFinished, { getVotingResults } from "views/LiveMeeting/utils/votingUtils";

const baseSize = 30;
const baseLineHeight = 1.25;
const baseDispositionSize = 37.5;
const fontSize = {
	fontSize: `${baseSize}px`,
	lineHeight: `${baseLineHeight}`,
};
const useStyles = makeStyles((theme) => ({
	dialog: {
		"& .MuiDialog-paper": {
			maxWidth: "80%",
			[theme.breakpoints.down("sm")]: {
				maxWidth: "100%",
			},
		},
		"& .MuiDialogTitle-root": {
			padding: "0 30px",
		},
		"& .MuiDialogContent-root": {
			padding: "30px",
		},
	},
	title: {
		display: "flex",
		alignItems: "center",
		height: "70px",
		fontSize: "40px",
		lineHeight: `${baseLineHeight}`,
		fontWeight: "normal",
	},
	titleText: {
		overflow: "hidden",
		whiteSpace: "nowrap",
		textOverflow: "ellipsis",
	},
	recommendationContainer: {
		overflow: "visible",
	},
	recommendation: {
		display: "flex",
		alignItems: "flex-start",
		gap: "30px",
		width: "100%",
	},
	recommendationAlignContainer: {
		display: "flex",
		alignItems: "center",
		height: `${baseSize * baseLineHeight}px`,
	},
	recommendationText: {
		display: "-webkit-box",
		"-webkit-box-orient": "vertical",
		"-webkit-line-clamp": "2",
		overflow: "hidden",
		flexGrow: "1",
		fontWeight: "600",
		...fontSize,
		"& > p": {
			margin: "0",
		},
		"& > p:first-child": {
			display: "inline-block",
		},
	},
	disposition: {
		display: "inline-flex",
		alignItems: "center",
		justifyContent: "center",
		color: whiteColor,
		height: `${baseDispositionSize * 2}px`,
		padding: `0 ${baseDispositionSize}px`,
		borderRadius: `${baseDispositionSize}px`,
		fontSize: `${baseDispositionSize}px`,
		lineHeight: "1.16",
	},
	votePassed: {
		backgroundColor: successColor,
	},
	voteFailed: {
		backgroundColor: errorColor,
	},
	colorInherit: {
		color: "inherit",
	},
	quorumNoMet: {
		backgroundColor: warningColor,
	},
	voteResults: {
		display: "flex",
		flexDirection: "column",
		gap: "40px",
	},
	voteResult: {
		...fontSize,
	},
	voteMoved: {
		fontWeight: "600",
	},
}));

const VotingResultsPresentationDialog = (props) => {
	const { votingData, votingSettings = { votingLabels: {} }, meeting } = props;
	const widthDownSm = useWidthDown("sm");
	const { t } = useTranslation("meetings");
	const [votingResults, setVotingResults] = useState({ results: null, finished: null });

	const classes = useStyles();

	const parserOptions = {
		replace: (node) => {
			if (!node.attribs || !node.attribs.style) return;
			if (["img", "br"].includes(node.name) && node.attribs.style) {
				node.attribs.style = "";
			}
			if (node.attribs.style.indexOf("font-size") >= 0) {
				// eslint-disable-next-line consistent-return
				return <node.name>{domToReact(node.children, parserOptions)}</node.name>;
			}
		},
	};

	useEffect(() => {
		if (votingData && votingData.itemInProgress) {
			setVotingResults({
				results: getVotingResults(
					{ fields: { Voting: { Value: votingData.itemInProgress.itemVotingData } } },
					votingData.rollCall,
					meeting,
				),
				finished:
					votingData.itemInProgress.itemVotingData && votingData.itemInProgress.itemVotingData.length > 0
						? checkVotingFinished({ fields: { Voting: { Value: votingData.itemInProgress.itemVotingData } } }, votingData.rollCall)
						: votingData.itemInProgress.movedBy && votingData.itemInProgress.secondedBy && votingData.showResults
							? true
							: false,
			});
		}
	}, [votingData]);

	return (
		<>
			{votingResults.results && votingResults.finished && votingData && votingData.itemInProgress && (
				<Dialog
					className={classes.dialog}
					open={true}
					fullScreen={widthDownSm}
					fullWidth
					data-cy="voting-dialog"
					BackdropProps={{ invisible: true }}
				>
					<DialogTitle>
						<div className={classes.title}>
							<div className={classes.titleText}>{parse(stripHtml(votingData.itemInProgress.agendaItemText || ""), parserOptions)}</div>
						</div>
					</DialogTitle>
					<DialogContent dividers className={classes.recommendationContainer}>
						<Grid container spacing={0} direction="row" justifyContent="flex-start">
							<Grid item className={classes.recommendation}>
								<div className={classes.recommendationAlignContainer}>
									<Icon name="recommendation" size="60" />
								</div>
								<div className={classes.recommendationText}>
									{parse(stripHtml(votingData.itemInProgress.itemText || ""), parserOptions)}
								</div>
								{votingResults.finished && (
									<div className={classes.recommendationAlignContainer}>
										<div
											className={clsx(classes.disposition, {
												[classes.votePassed]:
													votingResults.results.quorumMet &&
													votingResults.results.votePassed &&
													votingData.itemInProgress.itemVotingData &&
													votingData.itemInProgress.itemVotingData.length > 0,
												[classes.voteFailed]:
													votingResults.results.quorumMet &&
													!votingResults.results.votePassed &&
													votingData.itemInProgress.itemVotingData &&
													votingData.itemInProgress.itemVotingData.length > 0,
												[classes.quorumNoMet]: !votingResults.results.quorumMet,
												[classes.colorInherit]:
													votingData.showResults &&
													(votingData.itemInProgress.itemVotingData == null || votingData.itemInProgress.itemVotingData.length === 0),
											})}
											data-cy="disposition"
										>
											{votingData.itemInProgress.disposition}
										</div>
									</div>
								)}
							</Grid>
						</Grid>
					</DialogContent>
					<DialogContent>
						<div className={classes.voteResults}>
							{votingResults.results && votingData.itemInProgress.itemVotingData.length > 0 && (
								<>
									<VoteResultType
										className={classes.voteResult}
										label={votingSettings.votingLabels.for || t("voting.for")}
										count={votingResults.results.forVotes.length}
										total={votingResults.results.totalVotes}
										names={votingResults.results.forVoteNames}
										totalWeight={votingResults.results.forVotesWeighted}
									></VoteResultType>
									<VoteResultType
										className={classes.voteResult}
										label={votingSettings.votingLabels.against || t("voting.against")}
										count={votingResults.results.againstVotes.length}
										total={votingResults.results.totalVotes}
										names={votingResults.results.againstVoteNames}
										totalWeight={votingResults.results.againstVotesWeighted}
									></VoteResultType>
									<VoteResultType
										className={classes.voteResult}
										label={votingSettings.votingLabels.abstain || t("voting.abstain")}
										count={votingResults.results.abstainVotes.length}
										total={votingResults.results.eligibleVotesCount}
										names={votingResults.results.abstainVoteNames}
										totalWeight={votingResults.results.abstainVotesWeighted}
									></VoteResultType>
								</>
							)}
							{votingData.itemInProgress.itemVotingData.length == 0 && votingResults.finished && (
								<>
									<div className={classes.voteResult}>
										<div>
											<span className={classes.voteMoved}>{`${t("movedBy")}: `}</span>
											{votingData.itemInProgress.movedBy}
										</div>
									</div>
									<div className={classes.voteResult}>
										<div>
											<span className={classes.voteMoved}>{`${t("secondedBy")}: `}</span>
											{votingData.itemInProgress.secondedBy}
										</div>
									</div>
								</>
							)}
						</div>
					</DialogContent>
				</Dialog>
			)}
		</>
	);
};

export default VotingResultsPresentationDialog;
