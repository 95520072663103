import React, { useState } from 'react';
import clsx from 'clsx';

import makeStyles from '@mui/styles/makeStyles';
import { TableCell } from '@mui/material';

import AccessibleIconButton from '../Buttons/AccessibleIconButton';
import NonModalMenu from '../Menu/NonModalMenu';
import menuStyle from '../../assets/jss/components/menuStyle';
import tableDataStyle from 'assets/jss/components/tableDataStyle';

const useStyles = makeStyles({
	...tableDataStyle,
	menuCell: {
		textAlign: 'right',
	},
	menuButton: {
		margin: '-12px 0',
	},
	menu: {
		...menuStyle.menu,
	},
});

const MenuCell = (props) => {
	const { row, menuOptions, menuTooltipText, anchor, setAnchor } = props;
	const classes = useStyles();

	const toggleMenu = (e) => {
		e.stopPropagation();
		e.preventDefault();

		setAnchor(row, e.currentTarget);
	};

	const closeMenu = () => {
		setAnchor(row, null);
	};

	const processedMenuOptions = (typeof menuOptions === 'function' ? menuOptions(row) : menuOptions).filter((option) => !option.hidden);

	return (
		<TableCell className={clsx(classes.cell, classes.menuCell)}>
			{processedMenuOptions && processedMenuOptions.length > 0 && (
				<>
					<AccessibleIconButton
						className={classes.menuButton}
						iconColor="inherit"
						aria-label={menuTooltipText}
						onClick={toggleMenu}
						iconName="more"
						iconSize="20px"
						dataCy="row-menu-button"
					/>
					{anchor && (
						<NonModalMenu
							id="table-menu"
							className={classes.menu}
							anchorEl={anchor}
							keepMounted
							open
							onClose={closeMenu}
							options={processedMenuOptions.map((option) => ({ ...option, actionFunction: () => option.actionFunction(row) }))}
							position="bottom-end"
						/>
					)}
				</>
			)}
		</TableCell>
	);
};

export default MenuCell;
