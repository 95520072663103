import React, { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { createPortal } from "react-dom";
import { DndContext, DragOverlay, KeyboardSensor, PointerSensor, useSensor, useSensors, rectIntersection } from "@dnd-kit/core";
import clsx from "clsx";

import makeStyles from "@mui/styles/makeStyles";
import { Checkbox, FormControlLabel } from "@mui/material";

import Accordion from "atlas/components/Accordion/Accordion";
import InputLabel from "atlas/components/FormControls/InputLabel";
import OutlinedInput from "atlas/components/FormControls/OutlinedInput";
import AccessibleIconButton from "atlas/components/Buttons/AccessibleIconButton";
import ButtonWithTooltip from "atlas/components/Buttons/ButtonWithTooltip";
import isEmail from "atlas/utils/isEmail";
import Draggable from "atlas/components/DragAndDrop/Draggable";
import Droppable from "atlas/components/DragAndDrop/Droppable";
import DragPresentation from "atlas/components/DragAndDrop/DragPresentation";
import { MEDIUM } from "atlas/utils/buttonSize";
import { useWidthDown } from "atlas/utils/useWidth";
import { blackColor, grayColor } from "atlas/assets/jss/shared";
import { Check } from "components/Icons";
import BlockUser from "./components/BlockUser";
import NotificationUsers from "./components/NotificationUsers";
import { getCollisionDetection } from "utils/dragAndDrop";
import { useUpdateObject } from "utils/updateObject";

const border = `solid 1px ${grayColor[1]}`;

const useStyles = makeStyles((theme) => ({
	blockColumns: {
		display: "flex",
		flexWrap: "wrap",
		margin: "0 -12px",
		[theme.breakpoints.down("sm")]: {
			margin: "0",
		},
		"& > div": {
			boxSizing: "border-box",
			margin: "0 12px",
			[theme.breakpoints.down("sm")]: {
				margin: "0",
			},
		},
		"& > div:first-child": {
			width: "calc(30% - 24px)",
			[theme.breakpoints.down("sm")]: {
				width: "100%",
			},
		},
		"& > div:not(:first-child)": {
			width: "calc(70% - 24px)",
			[theme.breakpoints.down("sm")]: {
				width: "100%",
			},
		},
	},
	inputLabelSpacing: {
		marginTop: "8px",
	},
	userList: {
		border: `solid 1px ${grayColor[1]}`,
		borderRadius: "5px",
		height: "350px",
		overflowY: "auto",
		overflowX: "hidden",
		color: blackColor[1],
		"& ol": {
			margin: "0",
			padding: "0",
			listStyleType: "none",
		},
	},
	userListItem: {
		margin: "0",
		display: "flex",
		alignItems: "center",
		height: "48px",
		"&:hover": {
			backgroundColor: grayColor[4],
		},
	},
	userListItemName: {
		flexGrow: "1",
		padding: "0 8px",
	},
	userListMenu: {
		cursor: "pointer",
	},
	selectedUsers: {
		border,
		borderRadius: "5px",
		"& ol": {
			margin: "0",
			padding: "0",
			listStyleType: "none",
		},
	},
	noUsers: {
		display: "flex",
		alignItems: "center",
		height: "48px",
		textAlign: "center",
		"& > div": {
			flexGrow: "1",
		},
	},
	addExternalEmailAddress: {
		display: "flex",
		alignItems: "center",
		"& > div:not(:last-child)": {
			marginRight: "8px",
		},
	},
	externalEmailAddress: {
		minWidth: "230px",
	},
	alignWithErrorInput: {
		marginBottom: "20px",
	},
	droppableOver: {
		margin: "0px",
		border: "1px dashed #1E1E1E !important",
		background: "#E6E6E6",
	},
	droppableIsDragging: {
		margin: "0px",
		border: "1px dashed #949494",
		background: "#E6E6E6",
	},
}));
const useAccordionStyles = makeStyles(() => ({
	header: {
		borderBottom: "none",
		height: "auto",
		padding: "8px 16px",
		margin: "0 -16px",
		"&:hover": {
			backgroundColor: "rgba(0, 0, 0, 0.08)",
		},
		"&:focus": {
			backgroundColor: "rgba(0, 0, 0, 0.08)",
		},
	},
	icon: {
		marginRight: "8px",
	},
	label: {
		fontSize: "16px",
		fontWeight: "600",
		lineHeight: "1.25",
	},
	content: {
		paddingBottom: "16px",
	},
}));

const clearErrors = {
	externalEmailAddress: "",
};

const WorkflowNotifications = (props) => {
	const { workflow = {}, users, handleUpdate } = props;
	const widthDownSm = useWidthDown("sm");
	const { t } = useTranslation("workflows");
	const sensors = useSensors(useSensor(PointerSensor), useSensor(KeyboardSensor));
	const [draggedId, setDraggedId] = useState(null);
	const [dropTargets, setDropTargets] = useState([]);
	const [selections, setSelections] = useState({
		externalEmailAddress: "",
	});
	const [selectionErrors, setSelectionErrors] = useState({
		...clearErrors,
	});
	const dragRef = useRef(null);
	const classes = useStyles();
	const accordionClasses = useAccordionStyles();

	const validateSelections = (updatedSelection) => {
		setSelectionErrors((prev) => {
			if (updatedSelection.externalEmailAddress && !isEmail(updatedSelection.externalEmailAddress)) {
				prev.externalEmailAddress = t("detail.validation.externalEmailAddress");
			} else {
				prev.externalEmailAddress = "";
			}

			return { ...prev };
		});
	};

	const updateSelections = useUpdateObject(setSelections, undefined, validateSelections);

	const handleAddUser = (_e, userId) => {
		handleUpdate(
			{
				target: {
					value: {
						userId,
						customEmail: "",
					},
				},
			},
			"notificationUsers",
			false,
			false,
			undefined,
			(newValue, prevValue) =>
				!prevValue.find((prevNotificationUser) => prevNotificationUser.userId === newValue.userId)
					? prevValue.concat([newValue])
					: prevValue, // Prevent duplicates
		);
	};

	const handleDragStart = (e) => {
		const { active } = e;

		setDraggedId(active.id);
		if (!workflow.notificationUsers.find((notificationUser) => notificationUser.userId === active.id)) {
			// Adding
			setDropTargets(["notification-users"]);
		} else {
			// Removing
			setDropTargets(["workflow"]);
		}

		document.body.style.userSelect = "none";
	};

	const endDrag = () => {
		setDraggedId(null);
		setDropTargets([]);

		document.body.style.userSelect = null;
	};

	const handleDragEnd = (e) => {
		const { active, over } = e;

		if (over) {
			if (over.id === "workflow") {
				handleRemoveUser(active.id);
			} else {
				handleAddUser(undefined, active.id);
			}
		}

		endDrag();
	};

	const handleDragCancel = () => {
		endDrag();
	};

	const handleRemoveUser = (userId) => {
		handleUpdate(
			{
				target: {
					value: userId,
				},
			},
			"notificationUsers",
			false,
			false,
			undefined,
			(newValue, prevValue) => prevValue.filter((prevNotificationUser) => prevNotificationUser.userId !== newValue),
		);
	};

	const handleAddExternalEmailAddress = () => {
		handleUpdate(
			{
				target: {
					value: {
						userId: 0,
						customEmail: selections.externalEmailAddress,
					},
				},
			},
			"notificationUsers",
			false,
			false,
			undefined,
			(newValue, prevValue) =>
				!prevValue.find((prevNotificationUser) => prevNotificationUser.customEmail === newValue.customEmail)
					? prevValue.concat([newValue])
					: prevValue, // Prevent duplicates
		);
		updateSelections(
			{
				target: {
					value: "",
				},
			},
			"externalEmailAddress",
		);
	};

	const handleRemoveExternalEmailAddress = (externalEmailAddress) => {
		handleUpdate(
			{
				target: {
					value: externalEmailAddress,
				},
			},
			"notificationUsers",
			false,
			false,
			undefined,
			(newValue, prevValue) => prevValue.filter((prevNotificationUser) => prevNotificationUser.customEmail !== newValue),
		);
	};

	const notificationUsers = users
		? workflow.notificationUsers
				.filter((notificationUser) => notificationUser.userId > 0)
				.map((notificationUser) => users.find((user) => user.id === notificationUser.userId))
				.filter((user) => !!user)
				.sort((a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0))
		: [];
	const notificationExternalEmailAddresses = workflow.notificationUsers
		.filter((notificationUser) => notificationUser.userId === 0 && notificationUser.customEmail)
		.sort((a, b) => (a.customEmail < b.customEmail ? -1 : a.customEmail > b.customEmail ? 1 : 0));

	return (
		users && (
			<div>
				<Accordion
					classes={accordionClasses}
					label={t("detail.sendNotificationWhen")}
					startOpen
					scrollContentIntoView
					overflow={false}
					dataCy="notification-options-when"
				>
					<div>
						<FormControlLabel
							control={
								<Checkbox
									checkedIcon={<Check fontSize="small" color="primary" />}
									checked={workflow.notifySubmitterOnItemChange}
									onChange={(e) => handleUpdate(e, "notifySubmitterOnItemChange", true)}
								/>
							}
							label={t("detail.notifySubmitterOnItemChange")}
							data-cy={`notify-submitter-on-item-change`}
						/>
					</div>
					<div>
						<FormControlLabel
							control={
								<Checkbox
									checkedIcon={<Check fontSize="small" color="primary" />}
									checked={workflow.notifyApproversOnDenial}
									onChange={(e) => handleUpdate(e, "notifyApproversOnDenial", true)}
								/>
							}
							label={t("detail.notifyApproversOnDenial")}
							data-cy={`notify-approvers-on-denial`}
						/>
					</div>
				</Accordion>
				<Accordion
					classes={accordionClasses}
					label={t("detail.sendNotificationToBelow")}
					scrollContentIntoView
					overflow={false}
					dataCy="notification-options-below"
				>
					<div>
						<div>
							<FormControlLabel
								control={
									<Checkbox
										checkedIcon={<Check fontSize="small" color="primary" />}
										checked={workflow.notifyOnSubmitted}
										onChange={(e) => handleUpdate(e, "notifyOnSubmitted", true)}
									/>
								}
								label={t("detail.notifyOnSubmitted")}
								data-cy={`notify-on-submitted`}
							/>
						</div>
						<div>
							<FormControlLabel
								control={
									<Checkbox
										checkedIcon={<Check fontSize="small" color="primary" />}
										checked={workflow.notifyOnComment}
										onChange={(e) => handleUpdate(e, "notifyOnComment", true)}
									/>
								}
								label={t("detail.notifyOnComment")}
								data-cy={`notify-on-comment`}
							/>
						</div>
						<div>
							<FormControlLabel
								control={
									<Checkbox
										checkedIcon={<Check fontSize="small" color="primary" />}
										checked={workflow.notifyPerApproval}
										onChange={(e) => handleUpdate(e, "notifyPerApproval", true)}
									/>
								}
								label={t("detail.notifyPerApproval")}
								data-cy={`notify-per-approval`}
							/>
						</div>
					</div>
					<div className={classes.blockColumns}>
						<DndContext
							sensors={sensors}
							collisionDetection={getCollisionDetection(dragRef, rectIntersection)}
							onDragStart={handleDragStart}
							onDragEnd={handleDragEnd}
							onDragCancel={handleDragCancel}
						>
							<div>
								<div className={classes.inputLabelSpacing}>
									<InputLabel htmlFor="available-people" size="large" label={t("detail.availablePeople")} />
								</div>
								{users && (
									<Droppable
										className={classes.userList}
										dropId="workflow"
										useHighlightDroppable
										highlightDroppable={dropTargets.includes("workflow")}
										dropComponent="div"
									>
										<ol data-cy="user-list">
											{users
												.filter(
													(user) =>
														!user.inactive &&
														!user.deleted &&
														!workflow.notificationUsers.find((notificationUser) => notificationUser.userId === user.id),
												)
												.map((user) => (
													<Draggable
														key={user.id}
														dragId={user.id}
														dragComponent={BlockUser}
														dragAttributePassthrough
														canDrag
														dragPlaceholder={draggedId === user.id}
														user={user}
														actionIcon="add-circle"
														actionTooltip={t("tooltips.addUserToNotifications")}
														handleAction={handleAddUser}
													></Draggable>
												))}
										</ol>
									</Droppable>
								)}
							</div>
							<div>
								<div className={classes.inputLabelSpacing}>
									<InputLabel htmlFor="selected-people" size="large" label={t("detail.selectedPeople")} />
								</div>
								<NotificationUsers
									notificationUsers={notificationUsers}
									handleRemoveUser={handleRemoveUser}
									classes={classes}
									highlightDroppable={dropTargets.includes("notification-users")}
									dragPlaceholders={[draggedId]}
								></NotificationUsers>
								<div className={classes.addExternalEmailAddress}>
									<div>
										<OutlinedInput
											id="external-email-address"
											className={classes.externalEmailAddress}
											label={t("detail.addExternalEmailAddress")}
											placeholder={t("detail.placeholders.addEmailAddress")}
											value={selections.externalEmailAddress}
											onChange={(e) => updateSelections(e, "externalEmailAddress")}
											helperText={selectionErrors.externalEmailAddress}
											error={!!selectionErrors.externalEmailAddress}
											noDefaultClassName
											fullWidth
											size="small"
											data-cy="external-email-address"
										/>
									</div>
									<div>
										<ButtonWithTooltip
											className={clsx({
												[classes.button]: !widthDownSm,
												[classes.alignWithErrorInput]: selectionErrors.externalEmailAddress,
											})}
											title={t("tooltips.addExternalEmailAddress")}
											size={MEDIUM}
											variant="outlined"
											color="primary"
											onClick={handleAddExternalEmailAddress}
											disabled={!selections.externalEmailAddress || selectionErrors.externalEmailAddress}
											data-cy="add-external-email-address"
										>
											{t("app:buttons.add")}
										</ButtonWithTooltip>
									</div>
								</div>
								<div>
									<InputLabel htmlFor="selected-external-email-addresses" size="large" label={t("detail.selectedExternalEmailAddresses")} />
								</div>
								<div className={classes.selectedUsers}>
									{notificationExternalEmailAddresses.length > 0 ? (
										<ol data-cy="notification-external-email-addresses">
											{notificationExternalEmailAddresses.map((notificationUser) => (
												<li className={classes.userListItem} key={notificationUser.customEmail}>
													<div className={classes.userListItemName}>
														<span>{notificationUser.customEmail}</span>
													</div>
													<div className={classes.userListMenu}>
														<AccessibleIconButton
															iconName="remove"
															iconColor={blackColor[1]}
															tooltipText={t("tooltips.removeExternalEmailAddress")}
															onClick={(e) => {
																e.stopPropagation();

																handleRemoveExternalEmailAddress(notificationUser.customEmail);
															}}
															dataCy={`remove-external-email-address-${notificationUser.customEmail}`}
														/>
													</div>
												</li>
											))}
										</ol>
									) : (
										<div className={classes.noUsers}>
											<div>{t("detail.noSelectedExternalEmailAddresses")}</div>
										</div>
									)}
								</div>
							</div>
							{createPortal(
								<DragOverlay>
									<DragPresentation ref={dragRef}>
										{draggedId ? <BlockUser dragPresentational user={users.find((user) => user.id === draggedId)}></BlockUser> : null}
									</DragPresentation>
								</DragOverlay>,
								document.body,
							)}
						</DndContext>
					</div>
				</Accordion>
			</div>
		)
	);
};

export default WorkflowNotifications;
