import React, { useState, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useMatch } from "react-router-dom";
import queryString from "query-string";
import { useTranslation } from "react-i18next";
import request from "superagent";

import makeStyles from "@mui/styles/makeStyles";

import { API_HOST } from "config/env";
import { FOLDER_TYPE_DRAFT_POLICY } from "utils/enums/DocumentFolderTypes";
import { getSortMethod } from "utils/sort";
import Breadcrumbs from "atlas/components/Breadcrumbs/Breadcrumbs";
import ComponentContainer from "atlas/components/ComponentContainer/ComponentContainer";
import { useWidthDown } from "atlas/utils/useWidth";
import { resetPageConfigs, updatePageConfigs } from "redux/app/actions";
import { updatePageHeader } from "redux/pageHeader/actions";
import PolicyList from "./PolicyList";
import { getPolicyListForPublic, getPath } from "redux/policyGovernance/actions";

const useStyles = makeStyles({
	breadcrumbs: {
		height: "40px",
		padding: "0 8px",
		"& *": {
			fontFamily: "Segoe UI, Helvetica, Tahoma, Arial, Calibri, Verdana, sans-serif !important",
			fontSize: "13px",
		},
	},
	breadcrumb: {
		color: "rgb(100, 101, 103)",
		"& div": {
			letterSpacing: "inherit !important",
		},
	},
	breadcrumbSelected: {
		color: "rgb(100, 101, 103)",
		"& div": {
			letterSpacing: "inherit !important",
		},
	},
	fileInput: {
		display: "none",
	},
});

const telemetryPage = "Document List";

const ROOT_ID = "root";

const PublicPolicyModule = (props) => {
	const { showSignIn } = props;
	const widthDownSm = useWidthDown("sm");
	const { params: { id } = {} } = useMatch({ path: "/policies/policybook/:id", end: true }) || {};
	const { t } = useTranslation("documents");
	const navigate = useNavigate();
	const [policies, setPolicies] = useState(null);
	const [expandedPolicies, setExpandedPolicies] = useState([]);
	const [path, setPath] = useState(null);
	const [order, setOrder] = useState({});
	const dispatch = useDispatch();
	const classes = useStyles();

	const backToParent = useCallback(() => {
		if (path && path.length > 1 && path[path.length - 2].url) {
			navigate(path[path.length - 2].url);
		}

		return true;
	}, [path]);

	const setTitle = useCallback(
		(title) => {
			dispatch(
				updatePageConfigs({
					title,
					telemetryPage,
					back: path && path.length > 1 ? { action: backToParent } : undefined,
				}),
			);
		},
		[path],
	);

	const getExpandedSection = (policybook) => {
		handlePolicyEvent({ eventName: "expand", document: policybook });
	};

	const loadDocuments = (parentId, root, parent) => {
		dispatch(getPolicyListForPublic(parentId))
			.then((res) => {
				if (!id && !parentId && res && res.length === 1) {
					getExpandedSection(res[0]);
				}
				setPolicies((prev) => {
					// Check if the page has changed. If it has make no changes to the books
					if (prev.folder.id !== id) {
						return prev;
					}

					const newDocuments = root ? { folder: prev.folder } : { ...prev };
					newDocuments[root || !parentId ? "root" : parentId] = res.sort(getSortMethod(order.field, order.descending));

					return newDocuments;
				});
			})
			.catch(console.error);
	};

	const loadPath = () => {
		const rootName = t("title.public");
		if (!id) {
			// Root folder
			setPath([
				{
					name: "resource-center",
					icon: true,
					size: "20px",
					title: rootName,
				},
			]);
		} else {
			dispatch(getPath(id))
				.then((res) => {
					setPath(
						res.map((path, index) => ({
							name: path.code + " " + path.title,
							url: index < res.length - 1 ? `/policies/policybook/${path.guid}` : undefined,
						})),
					);
				})
				.catch((err) => {
					showSignIn(err, () => {
						loadPath();
					});
				});
		}
	};

	const setExpandedQuery = useCallback(() => {
		if (policies) {
			// Get a list of the expanded policies
			const expanded = [];
			Object.keys(policies).forEach((key) => {
				if (Array.isArray(policies[key])) {
					expanded.push(...policies[key].filter((policy) => policy.expanded).map((policy) => policy.guid));
				}
			});

			// Set the expanded policies in the query string
			try {
				let currentWindow = window;
				while (currentWindow) {
					const queryString = new URLSearchParams(currentWindow.location.search);
					if (expanded.length > 0) {
						queryString.set("expanded", expanded.join(","));
					} else {
						queryString.delete("expanded");
					}
					const queryStringString = queryString.toString();
					currentWindow.history.pushState(
						{},
						"",
						`${currentWindow.location.pathname}${queryStringString.length > 0 ? `?${queryStringString}` : ""}`,
					);

					if (currentWindow !== currentWindow.parent) {
						currentWindow = currentWindow.parent;
					} else {
						break;
					}
				}
			} catch {
				// Ignored
			}
		}
	}, [policies]);

	const handlePolicyEvent = ({ eventName, document, keyboard }) => {
		switch (eventName) {
			case "expand":
				document.expanded = !document.expanded;
				document.focusChild = document.expanded;
				document.focusKeyboard = keyboard;

				// Trigger re-render
				setPolicies((prev) => {
					// Load children if needed
					if (document.expanded && !prev[document.guid]) {
						loadDocuments(document.guid, false, document);
					}

					return { ...prev };
				});

				setExpandedQuery();
				break;
		}
	};

	const handleSort = (field) => {
		// If sorting by the same field switch between ascending, descending, and default order. New sort fields start sorted ascending.
		setOrder((prev) => ({
			field: prev.field !== field || !prev.descending ? field : undefined,
			descending: Boolean(prev.field === field && !prev.descending),
		}));
	};

	const handleResize = () => {
		if (window.top && window.top !== window && typeof window.top.resizePublicDocuments === "function") {
			window.top.resizePublicDocuments({ height: document.body.scrollHeight });
		}
	};

	const getExpandedPolicyParentGuids = (guids) => {
		request
			.get(`${API_HOST}/api/policies/parentids?ids=${guids}`)
			.withCredentials()
			.then((res) => {
				if (res.status === 200) {
					setExpandedPolicies(res.body);
				}
			})
			.catch(console.error);
	};

	const expandPolicies = (policies) => {
		if (expandedPolicies?.length > 0 && policies?.length > 0) {
			// Expand the policies that are parents of the expanded policies
			policies
				.filter(
					(policy) =>
						policy.folder &&
						!policy.expanded &&
						!policy.expandChecked &&
						(expandedPolicies.includes(policy.guid) || expandedPolicies.includes(policy.publishedGuid)),
				)
				.forEach((policy) => {
					policy.expanded = true;

					// Trigger re-render
					setPolicies((prev) => {
						// Load children if needed
						if (!prev[policy.guid]) {
							loadDocuments(policy.guid, false, policy);
						}

						return { ...prev };
					});
				});

			// Mark policy as checked
			policies
				.filter((policy) => !policy.expandChecked)
				.forEach((policy) => {
					policy.expandChecked = true;
				});
		}
	};

	useEffect(() => {
		const sortMethod = getSortMethod(order.field, order.descending);

		setPolicies((prev) => {
			if (prev) {
				Object.keys(prev).forEach((key) => {
					if (Array.isArray(prev[key])) {
						prev[key].sort(sortMethod);
					}
				});

				return { ...prev };
			} else {
				return prev;
			}
		});
	}, [order]);

	useEffect(() => {
		if (path && path.length > 0) {
			setTitle(path[path.length - 1].name);
		}
		dispatch(
			updatePageHeader({
				leftMenuOptions:
					path && widthDownSm
						? path
								.filter((folder) => folder.url)
								.map((folder) => ({
									label: folder.name,
									actionFunction: () => navigate(folder.url),
								}))
						: [],
			}),
		);
	}, [path, widthDownSm]);

	useEffect(() => {
		dispatch(resetPageConfigs({ resetBack: true }));

		setPolicies({ folder: { id } });

		loadDocuments(id, true);
		loadPath();

		if (id && window.top && window.top !== window && typeof window.top.updateHistory === "function") {
			window.top.updateHistory({ id });
		}
	}, [id]);

	useEffect(() => {
		// Expand the policies that are parents of the expanded policies
		if (expandedPolicies && policies) {
			Object.keys(policies).forEach((key) => {
				if (Array.isArray(policies[key])) {
					expandPolicies(policies[key]);
				}
			});
		}
	}, [expandedPolicies, policies]);

	useEffect(() => {
		document.body.classList.remove("maple-background");
		document.body.classList.add("portal-background");

		// Get the expanded policy parent guids from the query string
		const queryStringValues = queryString.parse(location.search) || {};
		if (queryStringValues.expanded) {
			getExpandedPolicyParentGuids(queryStringValues.expanded);
		}

		return () => {
			document.body.classList.remove("portal-background");
			document.body.classList.add("maple-background");
		};
	}, []);

	useEffect(() => {
		setTimeout(window.requestAnimationFrame(handleResize));
	}); // There is no body resize event yet, so update the iframe size after every render

	const getChildDocuments = useCallback(
		(list, childDocuments, depth = 0) => {
			let updatedList = list;

			if (childDocuments) {
				for (let index = 0; index < childDocuments.length; index++) {
					const childDocument = childDocuments[index];

					childDocument.depth = depth;

					updatedList.push(childDocument);

					if (childDocument.folder && childDocument.expanded) {
						updatedList = getChildDocuments(updatedList, policies[childDocument.guid], depth + 1);
					}
				}
			} else {
				updatedList.push({ loading: true });
			}

			return updatedList;
		},
		[policies],
	);

	return (
		<ComponentContainer padding="0">
			{policies && (
				<Breadcrumbs
					className={classes.breadcrumbs}
					classes={classes}
					showMenu
					items={path}
					separator={<span className={classes.breadcrumbSeparator}>/</span>}
				></Breadcrumbs>
			)}
			<PolicyList
				label={path?.[0]?.title || ""}
				type={FOLDER_TYPE_DRAFT_POLICY}
				inPortal
				order={order}
				policies={policies}
				handleEvent={handlePolicyEvent}
				handleSort={handleSort}
				telemetryPage={telemetryPage}
			></PolicyList>
		</ComponentContainer>
	);
};

export default PublicPolicyModule;
