export default {
	buttons: {
		addMeetingType: "+ Add meeting template",
		addUser: "+ Add person",
		deleteAndMove: "Delete and move",
		newBoard: "+ New meeting group",
		removeUser: "Remove",
	},
	deleteBoardDialog: {
		line1: "You are about to delete the meeting group {{name}}",
		line2: "To proceed, move the following meeting templates to another meeting group, so access is not lost.",
		meetingTypes: "Meeting templates",
		selectBoard: "Select meeting group",
		snackbar: {
			success: "The meeting group {{name}} has been deleted.",
		},
		title: "Delete meeting group",
		undo: {
			error: "Meeting group undo delete failed",
			successful: "Meeting group undo delete successful",
		},
		warning: "Warning:",
	},
	detail: {
		administrator: "Admin",
		member: "Member",
		name: "Group name",
		person: "Person",
		placeholders: {
			name: "Add a meeting group name",
		},
		selectedMeetingTypes: "Selected meeting templates",
		selectMeetingType: "Select meeting template",
		selectUser: "Select user",
		showOnPortal: "Show on public site",
		snackbar: {
			saved: "Meeting group saved",
		},
		staff: "Staff",
		tieBreaker: "Tie breaker",
		unsavedChanges: "You have unsaved changes",
		validation: {
			meetingTypes: "You need to add a meeting template to save a new meeting group",
			name: "Meeting group name required",
			noSelectedMeetingTypes: "No meeting templates have been added to this group",
			noSelectedUsers: "No people have been added to this group",
			noSelectedUserRoles: "You need to select a role for this person to save this meeting group",
			users: "You need to add a person to save a new meeting group",
		},
		votingMember: "Voting member",
	},
	list: {
		header: {
			name: "Name",
		},
		noResults: {
			description: "We couldn’t find any meeting groups",
			title: "No meeting groups found",
		},
	},
	reassignMeetingTypeDialog: {
		buttons: {
			removeAndReassign: "Remove and reassign",
		},
		line1: "In order to remove the meeting template {{name}}, it needs to be reassigned to another meeting group.",
		title: "Remove and reassign meeting template",
		selectBoard: "Select meeting group",
	},
	saveProgressDialog: {
		title: "Saving meeting group",
	},
	title: {
		boards: "Meeting groups",
		board: "Edit meeting group",
	},
	tooltips: {
		addMeetingType: "Add this meeting template",
		administrator: "Set this person as an administrator",
		addUser: "Add this person",
		delete: "Delete this meeting group",
		member: "Set this person as a member",
		moveUserDown: "Move this person down",
		moveUserUp: "Move this person up",
		newBoard: "Create a new meeting group",
		removeMeetingType: "Remove and reassign this meeting template",
		removeUser: "Remove this person",
		save: "Save this meeting group",
		showOnPortal: "Set this person to show on the portal",
		staff: "Set this person as staff",
		tieBreaker: "Set this person as a tie breaker",
		votingMember: "Set this person as a voting member",
	},
};
