import React, { useContext, useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import request from "superagent";
import { API_HOST } from "config/env";

import telemetryAddEvent from "utils/telemetryAddEvent";

import makeStyles from "@mui/styles/makeStyles";
import { Typography, MenuItem } from "@mui/material";

import CircularProgressIndicator from "atlas/components/Progress/CircularProgressIndicator";
import AccessibleIconButton from "atlas/components/Buttons/AccessibleIconButton";
import ButtonWithTooltip from "atlas/components/Buttons/ButtonWithTooltip";
import SelectedMeetingDetails from "./components/SelectedMeetingDetails";
import ErrorAgendaItemDialog from "components/Dialogs/ErrorAgendaItemDialog";
import ApprovalProgress from "../../components/Approval/ApprovalProgress";
import typographyStyles from "atlas/assets/jss/components/typographyStyle";
import { APPROVAL_STATUS_PENDING, APPROVAL_STATUS_APPROVED } from "utils/approvalStatuses";
import ProgressBar from "atlas/components/Progress/ProgressBar";
import { useWidthDown } from "atlas/utils/useWidth";
import SelectInput from "atlas/components/FormControls/SelectInput";
import inputStyle from "atlas/assets/jss/components/inputStyle";
import Spinner from "atlas/components/Spinner/Spinner";
import InputLabel from "atlas/components/FormControls/InputLabel";
import { Box } from "@mui/material";
import SingletonEditor from "components/Editor/SingletonEditor";
import { SettingsContext } from "contexts/Settings/SettingsContext";
import clsx from "clsx";
import Parser from "html-react-parser";
import PdfViewerNew from "../../components/PdfViewerNew/PdfViewerNew";
import { deleteAnnotation, loadSignatureAnnotations, saveAnnotation } from "components/PdfViewerNew/PdfAPICalls";
import Icon from "atlas/components/Icon/Icon";
import RejectedFromMeetingStatus from "./components/RejectedFromMeetingStatus";
import { addTableWidth } from "utils/meetingElement";

const gap = 16;
const useInputStyles = makeStyles(inputStyle);
const useTypographyStyles = makeStyles(typographyStyles);
const useStyles = makeStyles((theme) => ({
	approval: {
		display: "flex",
		alignItems: "start",
		width: "calc(50% - 8px)",
		[theme.breakpoints.down("md")]: {
			flexWrap: "wrap",
			width: "auto",
		},
	},
	approvalButtons: {
		[theme.breakpoints.down("md")]: {
			margin: "16px 0",
		},
		margin: "auto 8px",
	},
	attachmentsDocumentFrame: {
		width: "100%",
		height: "70vh",
		border: "none",
	},
	progressBar: {
		width: "240px",
		margin: "0 auto",
		marginTop: "24px",
	},
	attachmentsDocumentHeader: {
		display: "flex",
		width: "100%",
	},
	attachmentsDocumentLabel: {
		display: "flex",
		flexGrow: 0,
		flexDirection: "column",
		justifyContent: "center",
		fontWeight: "normal",
	},
	attachmentsDocumentHeaderButton: {
		display: "inline-flex",
	},
	agendaItemEditorDisabled: {
		marginTop: "16px",
		"& > div:first-child": {
			marginBottom: 0,
		},
		"& p": {
			marginTop: 0,
		},
		"& label": {
			fontWeight: "normal",
		},
	},
	approvalsTitle: {
		fontWeight: "normal",
	},
	coverTemplateContainer: {
		display: "flex",
		alignItems: "center",
		margin: "0 8px",
		gap: `${gap / 2}px`,
		"& > div": {
			display: "flex",
			flexGrow: 1,
			flexDirection: "column",
		},
		[theme.breakpoints.down("md")]: {
			flexDirection: "column",
			alignItems: "flex-start",
			"& > *": {
				width: "100%",
			},
		},
	},
	coverTemplate: {
		minWidth: "250px",
		display: "flex",
		flexGrow: 1,
		[theme.breakpoints.down("md")]: {
			minWidth: "100%",
		},
	},
	editCover: {
		marginTop: "11px",
		[theme.breakpoints.down("md")]: {
			marginTop: "0",
		},
	},
	agendaItemContainer: {
		display: "flex",
		flexWrap: "wrap",
		alignItems: "flex-start",
		gap: `${gap}px`,
		"& > div": {
			boxSizing: "border-box",
			width: `calc(50% - ${gap / 2}px)`,
			[theme.breakpoints.down("md")]: {
				width: "100%",
			},
		},
		[theme.breakpoints.down("md")]: {
			flexDirection: "column",
		},
	},
	editorContainer: {
		border: "1px solid #c4c4c4",
		padding: `${gap / 2}px`,
	},
	editorFieldHide: {
		display: "none",
	},
	stickyToolbar: {
		position: "-webkit-sticky",
		position: "sticky",
		top: 0,
		zIndex: 150,
	},
	editorField: {
		marginTop: "16px",
	},
	editorFieldLabel: {},
	editorFieldContainer: {
		display: "flex",
		flexDirection: "row",
		flexWrap: "nowrap",
		alignItems: "center",
		margin: "8px 0",
		padding: "8px",
	},
	editorFieldContent: {
		position: "relative",
		border: "solid 1px #c4c4c4 !important",
		flexGrow: "1",
		"& td": {
			wordBreak: "break-word",
		},
	},
	progress: {},
	meetingHeading: {
		fontWeight: "600",
		marginBottom: "12px",
	},
	inactiveToolbar: {
		display: "none",
	},
	editButton: {
		display: "flex",
		alignItems: "center",
		gap: "8px",
	},
	wordIcon: {
		paddingTop: "4px",
	},
}));

const AgendaItem = (props) => {
	const {
		item = {},
		item: { meeting, workflow, canApprove } = {},
		updateItem,
		queueFileUploads,
		invalidFileExtension,
		addPolicy,
		addGoals,
		showErrorItemDialog,
		handleCloseErrorItemDialog,
		failedUploads,
		handleApprove,
		handleReject,
		handleUpdateAttachments,
		createAttachmentsPdfProgress,
		coverTemplates = [],
		editCover,
		handleSelectMeeting,
		telemetryPage,
		enableEditButton,
		copyingCover,
	} = props;
	const widthDownMd = useWidthDown("md");
	const { lite } = useContext(SettingsContext);
	const { t } = useTranslation("agendaItems");
	const classes = useStyles();
	const typographyClasses = useTypographyStyles();
	const inputClasses = useInputStyles();
	const editorToolbar = useRef(null);
	const elementsRef = useRef([]);
	const [editorInitializing, setEditorInitializing] = useState(true);
	const [editorFields, _setEditorFields] = useState([]);
	const [selectedCoverTemplate, setSelectedCoverTemplate] = useState(item.coverTemplateId);
	const [meetingGroups, setMeetingGroups] = useState(null);

	useEffect(() => {
		const editorFields = [];
		const fieldsDisabled =
			!item.canUpdate ||
			(item.workflow &&
				!item.workflow.readOnlyUsersEnabled &&
				[APPROVAL_STATUS_PENDING, APPROVAL_STATUS_APPROVED].includes(item.approvalStatus));

		if (!fieldsDisabled || item.name) {
			editorFields.push({
				guid: item.guid,
				name: "name",
				content: item.name ? item.name : "",
				label: t("editItem.nameLabel"),
				toolbar: "simple",
				placeholder: t("editItem.nameLabel"),
				deleted: false,
				disabled: fieldsDisabled,
			});
		}
		if (!fieldsDisabled || item.text) {
			editorFields.push({
				guid: item.guid,
				name: "text",
				content: item.text ? item.text : "",
				label: t("editItem.descriptionLabel"),
				toolbar: "limited",
				placeholder: t("editItem.descriptionLabel"),
				deleted: false,
				disabled: fieldsDisabled,
			});
		}
		if (!fieldsDisabled || item.proposedRecommendation) {
			editorFields.push({
				guid: item.guid,
				name: "proposedRecommendation",
				content: item.proposedRecommendation ? item.proposedRecommendation : "",
				label: t("editItem.recommendedActionLabel"),
				toolbar: "limited",
				placeholder: t("editItem.recommendedActionLabel"),
				deleted: false,
				disabled: fieldsDisabled,
			});
		}
		_setEditorFields(editorFields);
	}, [item]);

	useEffect(() => {
		request
			.get(`${API_HOST}/api/boards?basic=true`)
			.withCredentials()
			.then((res) => {
				const { boards } = res.body;
				setMeetingGroups(boards);
			})
			.catch((err) => {
				console.log(err);
			});
	}, []);

	const handleCoverTemplateChange = (id) => {
		setSelectedCoverTemplate(id);
	};

	const handleEditorInitialized = (editor) => {
		setEditorInitializing(false);
	};

	const customizationOptions = {
		printCustom: {
			printButton1: {
				customlabel: t("meetings:pdfViewer.printButton1"),
				onClickUrl: item.attachmentsPdf?.url,
			},
			printButton2: {
				customlabel: t("meetings:pdfViewer.printButton2"),
				onClickUrl: "printpopup",
			},
		},
	};

	const getCoverTemplates = () => {
		const coverTemplateOptions = [
			<MenuItem key="0" value="0">
				{t("editItem.selectTemplate")}
			</MenuItem>,
		];
		if (coverTemplates && coverTemplates.length > 0) {
			coverTemplates.map((coverTemplate) => {
				coverTemplateOptions.push(
					<MenuItem key={coverTemplate.id} value={coverTemplate.id}>
						{coverTemplate.name}
					</MenuItem>,
				);
			});
		}

		return coverTemplateOptions;
	};
	const handleFieldChange = (editor) => {
		const fieldData = editor.getFieldData();
		fieldData.forEach((field) => {
			if (item[field.fieldName] === field.fieldData) return;
			updateItem(addTableWidth(field, field.fieldData), field.fieldName);
		});
		editor.ui.update();
	};

	const isMemberOnly = meeting ? meeting.closed : false;
	const features = {
		id: "MOA",
		label: t("meetings:inlineFile.features.MOA.featureLabel"),
		className: "closed",
		defaultValue: meeting ? meeting.closed : true,
		disabledValue: isMemberOnly,
		isEnabled: !isMemberOnly,
		anchorTitle: t("meetings:inlineFile.features.MOA.anchorTitleMember"),
		tooltipDisabledOn: t("meetings:inlineFile.features.MOA.tooltipDisabledOn"),
	};
	const fieldsDisabled =
		!item.canUpdate ||
		(item.workflow &&
			!item.workflow.readOnlyUsersEnabled &&
			[APPROVAL_STATUS_PENDING, APPROVAL_STATUS_APPROVED].includes(item.approvalStatus));

	return (
		<>
			{showErrorItemDialog && (
				<ErrorAgendaItemDialog show={showErrorItemDialog} handleClose={handleCloseErrorItemDialog} failedUploads={failedUploads} />
			)}
			{workflow && item.approvalStatus > 0 && (
				<div className={classes.approval}>
					<div className={classes.progress}>
						<div>
							<Typography variant="h3" className={clsx(typographyClasses.fieldLabelLarge, classes.approvalsTitle)}>
								{t("app:approvals.title")}
							</Typography>
						</div>
						<ApprovalProgress workflow={workflow} expandDown fullWidth={widthDownMd} maxIcons={widthDownMd ? 5 : undefined} />
					</div>
					{canApprove && (
						<div className={classes.approvalButtons}>
							<ButtonWithTooltip
								variant="outlined"
								color="primary"
								hasRightMargin
								onClick={handleApprove}
								title={t("tooltips.approveItem")}
								data-cy="approve"
							>
								{t("buttons.approveItem")}
							</ButtonWithTooltip>
							<ButtonWithTooltip
								variant="outlined"
								color="primary"
								onClick={handleReject}
								title={t("tooltips.rejectItem")}
								data-cy="reject"
							>
								{t("buttons.rejectItem")}
							</ButtonWithTooltip>
						</div>
					)}
					<RejectedFromMeetingStatus item={item}></RejectedFromMeetingStatus>
				</div>
			)}
			<div className={classes.agendaItemContainer}>
				<Box className={clsx(classes.editorContainer, "genericEditor")}>
					{editorInitializing && <Spinner />}
					<Box className={editorInitializing ? classes.editorFieldHide : null}>
						<Box
							className={clsx(classes.stickyToolbar, {
								[classes.inactiveToolbar]: !item.canUpdate,
							})}
							id="toolbar"
							ref={editorToolbar}
						></Box>
						{editorFields.map((field, idx) => {
							return (
								<Box className={field.deleted ? clsx(classes.editorField, classes.editorFieldHide) : classes.editorField} key={field.name}>
									<Box className={classes.editorFieldLabel}>
										<InputLabel boldLabel={true} htmlFor={field.name} label={field.label} bottomSpacing />
										{field.disabled && Parser(field.content)}
									</Box>
									<Box className={classes.editorFieldContainer}>
										<Box
											className={field.disabled ? clsx(classes.editorFieldContent, classes.editorFieldHide) : classes.editorFieldContent}
											data-fieldname={field.name}
											ref={(el) => (elementsRef.current[idx] = el)}
										></Box>
									</Box>
									{!fieldsDisabled && coverTemplates && coverTemplates.length > 0 && field.name === "name" && !lite.enabled && (
										<div className={classes.coverTemplateContainer}>
											<SelectInput
												id="cover-template"
												className={clsx(inputClasses.smallInput, classes.coverTemplate)}
												noDefaultClassName
												fullWidth
												size="small"
												label={t(`editItem.coverReportTemplate`)}
												externalLabel
												labelSize="large"
												value={
													selectedCoverTemplate && coverTemplates && coverTemplates.find((c) => c.id === selectedCoverTemplate) != null
														? selectedCoverTemplate
														: "0"
												}
												onChange={(e) => handleCoverTemplateChange(e.target.value)}
												data-cy="cover-templates"
											>
												{getCoverTemplates()}
											</SelectInput>
											<ButtonWithTooltip
												primary
												color="primary"
												className={classes.editCover}
												onClick={() => {
													enableEditButton();
													editCover(item.guid, selectedCoverTemplate);

													telemetryAddEvent(`${telemetryPage} - Agenda Item Cover opened`);
												}}
												disabled={
													selectedCoverTemplate == 0 ||
													(selectedCoverTemplate <= 0 && item.coverDocumentId <= 0) ||
													(coverTemplates &&
														coverTemplates.find((c) => c.id === selectedCoverTemplate) == null &&
														item.coverDocumentId <= 0) ||
													copyingCover
												}
												data-cy="editCover"
											>
												{copyingCover ? (
													<CircularProgressIndicator size={20} />
												) : (
													<div className={classes.editButton}>
														{
															<span className={classes.wordIcon}>
																<Icon name={"microsoft-word-topBar"} />
															</span>
														}
														{t("app:buttons.editInWord")}
													</div>
												)}
											</ButtonWithTooltip>
										</div>
									)}
								</Box>
							);
						})}
					</Box>
					{editorFields.length > 0 && (
						<SingletonEditor
							itemGuid={item.guid}
							fields={editorFields}
							fieldsRefs={elementsRef}
							editorToolbarRef={editorToolbar}
							queueFileUploads={queueFileUploads}
							invalidFileExtension={invalidFileExtension}
							addPolicy={addPolicy}
							addGoals={addGoals}
							onFieldChange={handleFieldChange}
							onEditorInitialized={handleEditorInitialized}
							features={features}
							autoSave={0}
						/>
					)}
				</Box>
				<div>
					<Typography className={classes.meetingHeading} variant="h4">
						{t("editItem.meeting")}
					</Typography>
					<SelectedMeetingDetails meetingGroups={meetingGroups} item={item} handleSelectMeeting={handleSelectMeeting} />
				</div>
			</div>
			{createAttachmentsPdfProgress && createAttachmentsPdfProgress.percent > 0 && (
				<ProgressBar
					className={classes.progressBar}
					label={createAttachmentsPdfProgress.label}
					progress={createAttachmentsPdfProgress.percent}
					maxLabelWidthPercent={100}
				/>
			)}
			{item && item.attachmentsPdf && item.attachmentsPdf.id > 0 && (
				<div style={{ marginTop: "16px" }}>
					<div className={classes.attachmentsDocumentHeader}>
						<Typography variant="h3" className={clsx(typographyClasses.fieldLabelLarge, classes.attachmentsDocumentLabel)}>
							{t(`editItem.${item.coverDocumentId > 0 ? "coverAttachments" : "attachmentsDocumentLabel"}`)}
						</Typography>
						{item.canUpdate && !fieldsDisabled && (
							<AccessibleIconButton
								id="update-attachments"
								color="inherit"
								aria-label={t("buttons.update")}
								onClick={handleUpdateAttachments}
								iconName="reload"
								iconSize={"20px"}
								dataCy="update-attachments"
								tooltipText={t("tooltips.updateAttachmentsPdf")}
							/>
						)}
					</div>
					<div className={classes.attachmentsDocumentFrame}>
						<PdfViewerNew
							id={item.attachmentsPdf.id}
							settings={{
								url: item.attachmentsPdf.url,
								userId: item.attachmentsPdf.userId,
								viewFullScreenButton: true,
								disableDownload: true,
								loadAllAnnotationVersions: true,
							}}
							isMeetingDocument
							openLeftPanel={false}
							selectedDocumentId={item.attachmentsPdf.id}
							loadAnnotationsAction={loadSignatureAnnotations}
							saveAnnotationAction={saveAnnotation}
							deleteAnnotationAction={deleteAnnotation}
							isAgendaScreen
							customizationOptions={customizationOptions}
							headerEndDivider
						/>
					</div>
				</div>
			)}
		</>
	);
};

export default AgendaItem;
