import React from 'react';
import { useSelector } from 'react-redux';

import { Badge, Box, Container, Tabs, Tab } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import typographyStyle from '../../assets/jss/components/typographyStyle';
import { whiteColor } from '../../assets/jss/shared';
import Tooltip from '../Tooltip/Tooltip';
import PropSoak from './PropSoak';
import TabsHeaderScrollButton from './TabsHeaderScrollButton';

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: '#2f3b4d',
		width: '100%',
		height: '48px',
		minHeight: '48px',
		paddingLeft: '24px',
		paddingRight: '24px',
		[theme.breakpoints.down('sm')]: {
			paddingLeft: '16px',
			paddingRight: '16px',
		},
		[theme.breakpoints.up('xl')]: {
			paddingLeft: '96px',
			paddingRight: '96px',
		},
		paddingBottom: '8px',
		boxSizing: 'border-box',
	},
	rootWithBadge: {
		overflow: 'visible',
	},
	container: {
		padding: '0',
		position: 'relative',
	},
	tabs: {
		'& .MuiTab-root': {
			...typographyStyle.tab,
			minWidth: 'auto',
			minHeight: 'auto',
			height: '40px',
			padding: '8px 0',
			margin: '0 16px',
			'&.Mui-selected': {
				...typographyStyle.tab,
			},
		},
	},
	underline: {
		position: 'absolute',
		bottom: '0',
		height: '1px',
		width: '100%',
		backgroundColor: whiteColor,
	},
	status: {
		flexGrow: '1',
		textAlign: 'right',
		alignSelf: 'center',
		paddingRight: '16px',
		color: whiteColor,
	},
}));

const TabsHeader = () => {
	const { root, rootWithBadge, container, tabs, underline, status } = useStyles();
	const contentMaxWidth = useSelector((state) => state.appReducer.contentMaxWidth);
	const { display, selectedTab, scrollButtons, tabsOptions, onChange } = useSelector((state) => state.appReducer.tabs);

	return display ? (
		<div className={root}>
			<Container className={container} maxWidth={contentMaxWidth}>
				<Tabs
					className={tabs}
					variant="scrollable"
					aria-label="Tabs"
					value={selectedTab}
					onChange={onChange}
					TabIndicatorProps={{
						style: {
							backgroundColor: whiteColor,
						},
					}}
					scrollButtons={scrollButtons}
					ScrollButtonComponent={(scrollProps) => <TabsHeaderScrollButton {...scrollProps} />}
				>
					{tabsOptions && tabsOptions.length > 0
						? tabsOptions.map((tab, index) => {
								if (tab.status) {
									return (
										<PropSoak key={tab.key || tab.id}>
											<div id={tab.id} className={status} data-cy={tab.dataCy}>
												<Tooltip placement="bottom-start" title={tab.tooltip}>
													<span>{tab.label}</span>
												</Tooltip>
											</div>
										</PropSoak>
									);
								}

								return (
									<Tab
										key={tab.key}
										label={
											tab.badgeProps ? (
												<Badge
													badgeContent={tab.badgeProps.content}
													color={tab.badgeProps.color || 'default'}
													showZero={false}
													variant={tab.badgeProps.content ? 'standard' : 'dot'}
													max={9}
												>
													{tab.label}
												</Badge>
											) : (
												tab.label
											)
										}
										classes={tab.badgeProps ? { root: rootWithBadge } : undefined}
										data-cy={tab.dataCy}
										id={`tab-${index}`}
									/>
								);
							})
						: null}
				</Tabs>
				<div className={underline} />
			</Container>
		</div>
	) : null;
};

export default TabsHeader;
