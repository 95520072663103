import React from "react";

import ErrorBoundary from "./ErrorBoundary";

const withErrorHandling = (WrappedComponent) => {
	class withErrorHandling extends React.Component {
		render() {
			return (
				<ErrorBoundary>
					<WrappedComponent {...this.props} />
				</ErrorBoundary>
			);
		}
	}

	return withErrorHandling;
};

export default withErrorHandling;
