import React from "react";
import SvgIcon from "@mui/material/SvgIcon";

import themeConfig from "../../atlas/assets/jss/theme";
import getOr from "lodash/fp/getOr";

const icon = (props) => {
	const { color } = props;
	const fillColor = color ? getOr(color, ["palette", color, "main"], themeConfig) : "currentColor";

	return (
		<SvgIcon {...props}>
			<g id="profile" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
				<path
					d="M16.6666667,10 C16.6666667,12.0333333 15.5333333,13.8333333 14.3333333,14.9333333 L14.3333333,16 C14.3333333,16.6666667 14.6666667,17.7 18,18.3333333 C19.9194962,18.7006742 21.4674475,20.1196296 22,22 L2,22 C2.31504262,20.1208132 3.78747988,18.648376 5.66666667,18.3333333 C9,17.7333333 9.66666667,16.6666667 9.66666667,16 L9.66666667,14.9333333 C8.46666667,13.8333333 7.33333333,12.0666667 7.33333333,10 L7.33333333,6.8 C7.33333333,3.33333333 9.8,2 12,2 C14.2,2 16.6666667,3.33333333 16.6666667,6.8 L16.6666667,10 Z"
					id="profile-Path"
					stroke={fillColor}
					strokeWidth="1.5"
				/>
			</g>
		</SvgIcon>
	);
};

export default icon;
