/* eslint-disable no-nested-ternary */
import React from "react";
import { useTranslation } from "react-i18next";

import Grid from "@mui/material/Grid";
import makeStyles from "@mui/styles/makeStyles";

import { primaryColor } from "atlas/assets/jss/shared";
import AccessibleIconButton from "atlas/components/Buttons/AccessibleIconButton";
import StyledLink from "atlas/components/Links/StyledLink";

const useStyles = makeStyles(() => ({
	videoLinkButtons: {
		marginTop: 0,
		marginBottom: 0,
		marginRight: 0,
		marginLeft: 0,
	},
	videoRemoveButtonContainer: {
		textAlign: "right",
	},
	videoRemoveButton: {
		padding: 0,
	},
	marginTopHR: {
		marginTop: "8px",
		borderColor: "#d7dde8",
	},
}));

const BroadcastRow = (props) => {
	const { key, broadcast, removeBroadcast, multipleBroadcasts } = props;
	const { t } = useTranslation("meetings");
	const classes = useStyles({});

	return (
		<React.Fragment key={key}>
			<Grid container direction="row" data-cy={"broadcastRow-" + broadcast.broadcastId}>
				<Grid item lg={7} xs={12}>
					<div className={classes.marginTop}>{broadcast.title}</div>
				</Grid>
				<Grid item lg={2} xs={5}>
					{broadcast.broadcastId && broadcast.broadcastId.length > 0 && (
						<StyledLink
							href={`https://dashboard.boxcast.com/#/events/${broadcast.broadcastId}`}
							target="_blank"
							data-id="broadcastVideoLink"
						>
							{t("Boxcast")}
						</StyledLink>
					)}
				</Grid>
				<Grid item lg={2} xs={5}>
					{broadcast.youtubeId && broadcast.youtubeId.length > 0 && (
						<StyledLink href={`https://www.youtube.com/watch?v=${broadcast.youtubeId}`} target="_blank" data-id="youtubeVideoLink">
							{t("YouTube")}
						</StyledLink>
					)}
				</Grid>
				<Grid className={classes.videoRemoveButtonContainer} item lg={1} xs={2}>
					<AccessibleIconButton
						className={classes.videoRemoveButton}
						iconName="remove"
						iconSize="24px"
						iconColor={primaryColor[1]}
						tooltipText={t("meetings:addBroadcastDialog.broadcastRemove")}
						onClick={() => removeBroadcast(broadcast)}
						data-cy={"broadcastRemove-" + broadcast.broadcastId}
					/>
				</Grid>
			</Grid>
			{multipleBroadcasts && <hr className={classes.marginTopHR} />}
		</React.Fragment>
	);
};

export default BroadcastRow;
