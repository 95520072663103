/* eslint-disable no-plusplus */
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import request from "superagent";
import { v4 as uuid } from "uuid";

import makeStyles from "@mui/styles/makeStyles";

import Icon from "atlas/components/Icon/Icon";
import { API_HOST } from "config/env";
import GenericDialog from "atlas/components/Dialogs/GenericDialog";
import ProgressBar from "atlas/components/Progress/ProgressBar";
import ErrorDialogMeetingPublish from "components/Dialogs/ErrorDialogMeetingPublish";

import { defaultFont } from "atlas/assets/jss/shared";
import { updateHandlers, PROGRESS_HUB } from "utils/communication/SignalrClient";

const useStyles = makeStyles(() => ({
	dialog: {
		"& .MuiDialog-paper": {
			width: "440px",
		},
	},
	meetingName: {
		...defaultFont,
		fontSize: "22px",
		lineHeight: "1.18",
		fontWeight: "nromal",
		letterSpacing: "normal",
		textAlign: "center",
	},
	progressBar: {
		width: "240px",
		margin: "0 auto",
		marginTop: "24px",
	},
}));

const MeetingApproveDialog = (props) => {
	const {
		show,
		meeting,
		agenda = false,
		onClose,
		onApproved,
		showSignIn,
		openSupportRequestDialog,
		editorFunctions,
		isLite = false,
	} = props;
	const { t } = useTranslation("meetings");
	const [progress, setProgress] = useState({
		label: " ",
		percent: 0,
	});
	const [errors, setErrors] = useState(null);
	const appReducer = useSelector((state) => state.appReducer);
	const {
		signalR: { client, handler },
	} = appReducer;
	const dispatch = useDispatch();
	const classes = useStyles();

	const updateProgress = (percentage, message) => {
		setProgress({
			label: message,
			percent: percentage,
		});
	};

	const approveMinutes = (guid) => {
		if (meeting && meeting.id > 0) {
			request
				.post(`${API_HOST}/api/meeting/${meeting.id}/${isLite ? "adopt" : "createdrafttosign"}`)
				.withCredentials()
				.send({ progressGuid: guid, agenda: false })
				.then((res) => {
					if (res.status === 200) {
						setProgress({
							label: " ",
							percent: 100,
						});
						if (res && res.body && res.body.pdf && res.body.pdf.errors) {
							// backend returns both item and attachment errors under the "pdf.errors" section
							setErrors(res.body.pdf.errors);
						} else {
							setErrors(null);
							if (onApproved && typeof onApproved === "function") {
								onApproved();
							}
						}
					}
				})
				.catch((err) => {
					showSignIn(err, () => {
						approveMinutes(guid);
					});
				});
		}
	};

	const handleCancel = () => {
		onClose();
	};

	useEffect(() => {
		const guid = uuid();

		updateHandlers(dispatch, handler, PROGRESS_HUB, { announceProgress: updateProgress });

		client.ensureStarted().then(() => client.progressHub.registerProgressGuid(guid));

		approveMinutes(guid);

		return () => {
			client.ensureStarted().then(() => client.progressHub.clearProgressGuid(guid));

			updateHandlers(dispatch, handler, PROGRESS_HUB, { announceProgress: null });
		};
	}, []);

	return (
		<>
			{errors && (
				<ErrorDialogMeetingPublish
					agenda={agenda}
					errors={errors}
					handleClose={handleCancel}
					show
					openSupportRequestDialog={openSupportRequestDialog}
					meeting={meeting}
					triggeredBy="preview"
					editorFunctions={editorFunctions}
				/>
			)}
			{!errors && (
				<GenericDialog
					className={classes.dialog}
					show={show}
					clickAwayDisabled
					title={t(`approveMeetingDialog.title.${agenda ? "agenda" : "minutes"}`)}
					data-cy="meeting-preview"
				>
					<div className={classes.meetingName}>
						{t(`approveMeetingDialog.label.${agenda ? "agenda" : "minutes"}`, { meetingName: meeting.cleanName })}
					</div>
					<ProgressBar className={classes.progressBar} label={progress.label} progress={progress.percent} maxLabelWidthPercent={150} />
				</GenericDialog>
			)}
		</>
	);
};

export default MeetingApproveDialog;
