import React, { useState, useContext } from "react";
import { useTranslation } from "react-i18next";

import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Select from "@mui/material/Select";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import { isValid, format } from "date-fns";
import makeStyles from "@mui/styles/makeStyles";

import clonedeep from "lodash/cloneDeep";

import { SettingsContext } from "contexts/Settings/SettingsContext";
import OutlinedDatePicker from "atlas/components/FormControls/OutlinedDatePicker";
import Icon from "atlas/components/Icon/Icon";
import { Check } from "components/Icons";
import inputStyle from "atlas/assets/jss/components/inputStyle";
import StackedCheckbox from "atlas/components/FormControls/StackedCheckbox";
import filterStyle from "assets/jss/components/filterStyle";
import { STATUS_DEFAULT, STATUS_GOOD, STATUS_WARNING } from "atlas/assets/jss/utils/statusIndicators";

const useInputStyles = makeStyles(inputStyle);
const useFilterStyles = makeStyles(filterStyle);

export const DEFAULT_SEARCH_FIELD = "search";

const MeetingFilter = (props) => {
	const { filters, onFilterChange, settings: { meetingTypes = [] } = {}, boardAdmin } = props;
	const { t } = useTranslation("meetings");
	const [dateSettings, setDateSettings] = useState({
		customFromDate: null,
	});
	const { dateFormat } = useContext(SettingsContext);
	const classes = useFilterStyles();
	const inputClasses = useInputStyles({ fullWidth: true });

	const filterCustomDateId = "filter-custom-date";
	const boardAgendaStatuses = [
		{ value: 2, label: t("options.notShared"), color: STATUS_DEFAULT },
		{ value: 6, label: t("options.shared"), color: STATUS_GOOD },
		{ value: 7, label: t("options.outOfSync"), color: STATUS_WARNING },
	];
	const publicAgendaStatuses = [
		{ value: 2, label: t("options.notPublished"), color: STATUS_DEFAULT },
		{ value: 6, label: t("options.published"), color: STATUS_GOOD },
		{ value: 7, label: t("options.outOfSync"), color: STATUS_WARNING },
	];

	const boardAgendaStatusClick = (status) => {
		const newFilters = clonedeep(filters);

		// Select or deselect the status
		newFilters.boardAgendaStatuses = newFilters.boardAgendaStatuses || [];
		const index = newFilters.boardAgendaStatuses.indexOf(status);
		if (index >= 0) {
			newFilters.boardAgendaStatuses.splice(index, 1);
		} else {
			newFilters.boardAgendaStatuses.push(status);
		}

		onFilterChange(newFilters, true);
	};

	const publicAgendaStatusClick = (status) => {
		const newFilters = clonedeep(filters);

		// Select or deselect the status
		newFilters.publicAgendaStatuses = newFilters.publicAgendaStatuses || [];
		const index = newFilters.publicAgendaStatuses.indexOf(status);
		if (index >= 0) {
			newFilters.publicAgendaStatuses.splice(index, 1);
		} else {
			newFilters.publicAgendaStatuses.push(status);
		}

		onFilterChange(newFilters, true);
	};

	const meetingTypeClick = (meetingType) => {
		const newFilters = clonedeep(filters);

		// Select or deselect the status
		newFilters.meetingTypes = newFilters.meetingTypes || [];
		const index = newFilters.meetingTypes.indexOf(meetingType.id);
		if (index >= 0) {
			newFilters.meetingTypes.splice(index, 1);
		} else {
			newFilters.meetingTypes.push(meetingType.id);
		}

		onFilterChange(newFilters, true);
	};

	const dateRangeChange = (e) => {
		const newFilters = clonedeep(filters);

		newFilters.customDateRange = e.target.value;
		if ([0, 1].includes(newFilters.customDateRange)) {
			// None or Custom (Custom needs to reset these)
			newFilters.from = null;
			newFilters.to = null;
		}

		if (newFilters.customDateRange === 1) {
			onFilterChange(newFilters);
		} else {
			onFilterChange(newFilters, true);
		}
	};

	const handleDateChange = (field) => (date) => {
		const newFilters = clonedeep(filters);

		newFilters[`${field}Valid`] = date && isValid(date);
		if (newFilters[`${field}Valid`]) {
			newFilters[field] = format(date, "yyyy-MM-dd");
		} else {
			newFilters[field] = date;
		}

		if (field === "from") {
			setDateSettings({
				...dateSettings,
				customFromDate: date,
			});
		}

		if (newFilters[`${field}Valid`] || newFilters[field] === "" || !newFilters[field]) {
			onFilterChange(newFilters, true);
		} else {
			onFilterChange(newFilters);
		}
	};

	return (
		<>
			<div className={classes.section}>
				<div className={classes.fieldHeading}>
					<label id="date-range-label" htmlFor="date-range">
						{t("dateRange")}
					</label>
				</div>
				<FormControl variant="outlined" className={classes.fieldInput}>
					<Select
						id="date-range-container"
						labelId="date-range-label"
						value={filters.customDateRange || 0}
						onChange={dateRangeChange}
						input={<OutlinedInput labelWidth={0} name="date-range" id="date-range" fullWidth />}
						IconComponent={(iconProps) => (
							<div className={inputClasses.selectIcon} {...iconProps}>
								<Icon name="expand-down" />
							</div>
						)}
						data-cy="filterDateRange"
					>
						<MenuItem value={0} data-cy={`filterDateRange${0}`}>
							{t("app:options.none")}
						</MenuItem>
						<MenuItem value={2} data-cy={`filterDateRange${2}`}>
							{t("app:options.today")}
						</MenuItem>
						<MenuItem value={3} data-cy={`filterDateRange${3}`}>
							{t("app:options.thisWeek")}
						</MenuItem>
						<MenuItem value={4} data-cy={`filterDateRange${4}`}>
							{t("app:options.thisMonth")}
						</MenuItem>
						<MenuItem value={5} data-cy={`filterDateRange${5}`}>
							{t("app:options.lastMonth")}
						</MenuItem>
						<MenuItem value={6} data-cy={`filterDateRange${6}`}>
							{t("app:options.nextMonth")}
						</MenuItem>
						<MenuItem value={1} className="custom-date-menu-item" data-cy={`filterDateRange${1}`}>
							{t("app:options.custom")}
						</MenuItem>
					</Select>
				</FormControl>
				{filters.customDateRange === 1 ? (
					<div id={filterCustomDateId}>
						<div>
							<OutlinedDatePicker
								label={t("startTime")}
								dateFormat={dateFormat}
								value={filters.from}
								onChange={handleDateChange("from")}
								dataCy="filterDateFrom"
							></OutlinedDatePicker>
						</div>
						<div>
							<OutlinedDatePicker
								label={t("endTime")}
								dateFormat={dateFormat}
								value={filters.to}
								minDate={dateSettings.customFromDate}
								onChange={handleDateChange("to")}
								dataCy="filterDateTo"
							></OutlinedDatePicker>
						</div>
					</div>
				) : null}
			</div>
			<div className={classes.section}>
				<StackedCheckbox
					options={meetingTypes}
					getChecked={(meetingType) => filters.meetingTypes && filters.meetingTypes.includes(meetingType.id)}
					getId={(meetingType) => `filter-meeting-type-${meetingType.id}`}
					getKey={(meetingType) => `filter-meeting-type-${meetingType.id}`}
					getValue={(meetingType) => meetingType.id}
					getDataCy={(meetingType) => `filterMeetingType${meetingType.id}`}
					getLabel={(meetingType) => meetingType.name}
					handleChange={meetingTypeClick}
				/>
			</div>
			{boardAdmin && (
				<>
					<div className="section">
						<Typography variant="h4">
							<Box fontWeight="600" className="filter-heading">
								{t("agendaStatus")}
							</Box>
						</Typography>
					</div>
					<Box mt={1}>
						<FormLabel component="label" className="filter-subheading">
							{t("members")}
						</FormLabel>
						<FormGroup>
							{boardAgendaStatuses.map((status) => (
								<FormControlLabel
									key={status.value}
									control={
										<Checkbox
											checked={filters && filters.boardAgendaStatuses && filters.boardAgendaStatuses.some((s) => s === status.value)}
											checkedIcon={<Check fontSize="small" color="primary" />}
											onChange={() => boardAgendaStatusClick(status.value)}
											value={status.value}
											data-cy={`filterBoardAgendaStatus${status.value}`}
										/>
									}
									label={status.label}
									classes={{
										label: `agenda-status ${status.color}`,
									}}
								/>
							))}
						</FormGroup>
					</Box>
					<Box mt={1}>
						<FormLabel component="label" className="filter-subheading">
							{t("public")}
						</FormLabel>
						<FormGroup>
							{publicAgendaStatuses.map((status) => (
								<FormControlLabel
									key={status.value}
									control={
										<Checkbox
											checkedIcon={<Check fontSize="small" color="primary" />}
											checked={filters && filters.publicAgendaStatuses && filters.publicAgendaStatuses.some((s) => s === status.value)}
											onChange={() => publicAgendaStatusClick(status.value)}
											value={status.value}
											data-cy={`filterPublicAgendaStatus${status.value}`}
										/>
									}
									label={status.label}
									classes={{
										label: `agenda-status ${status.color}`,
									}}
								/>
							))}
						</FormGroup>
					</Box>
				</>
			)}
		</>
	);
};

export default MeetingFilter;
