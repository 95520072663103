import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import makeStyles from "@mui/styles/makeStyles";
import Button from "@mui/material/Button";

import ListCard from "atlas/components/Cards/ListCard";
import Icon from "atlas/components/Icon/Icon";
import Tooltip from "atlas/components/Tooltip/Tooltip";
import { useWidthUp } from "atlas/utils/useWidth";
import { isShared, isPublished } from "utils/agendaStatuses";
import { isShared as isMinutesShared, isPublished as isMinutesPublished, isAdopted } from "utils/minutesStatuses";
import { formatDate } from "utils/date";
import telemetryAddEvent from "utils/telemetryAddEvent";
import MeetingPublishStatus from "./MeetingPublishStatus";

const useStyles = makeStyles({
	mainContent: {
		maxWidth: (props) => (props.hasSecondaryContent ? (props.widthUpLg ? "50%" : props.widthUpMd ? "30%" : undefined) : undefined),
		minWidth: (props) => (props.hasSecondaryContent ? (props.widthUpLg ? "50%" : props.widthUpMd ? "30%" : undefined) : undefined),
	},
	textDetails: {
		marginBottom: (props) => (!props.widthUpMd ? "16px" : undefined),
	},
	secondaryContent: {
		display: (props) => (props.widthUpMd ? "flex" : undefined),
		alignItems: (props) => (props.widthUpMd ? "center" : undefined),
		"& > div:not(:first-child)": {
			marginLeft: (props) => (props.widthUpMd ? "16px" : undefined),
			marginTop: (props) => (!props.widthUpMd ? "16px" : undefined),
		},
	},
	notes: {
		display: "inline-flex",
	},
});

const MeetingListCard = (props) => {
	const { meeting, showPublishStatus, openMeetingDeleteDialog } = props;
	const widthUpMd = useWidthUp("md");
	const widthUpLg = useWidthUp("lg");
	const { t } = useTranslation("meetings");
	const navigate = useNavigate();
	const [anchor, setAnchor] = useState(null);
	const [menuOptions, setMenuOptions] = useState([]);
	const showStatus =
		showPublishStatus || (meeting.draftReview && !isShared(meeting.boardAgendaStatus) && !isPublished(meeting.publicAgendaStatus));
	const classes = useStyles({ hasSecondaryContent: showStatus || meeting.zoomLink, widthUpMd, widthUpLg });

	const openMeetingDetails = (id) => {
		navigate(
			meeting.administrator
				? `/meeting/${id}`
				: meeting.historic
				? `/document/${meeting.member && meeting.boardAgendaHtmlId ? meeting.boardAgendaHtmlId : meeting.publicAgendaHtmlId}`
				: `/meeting/document/${id}`,
		);
	};

	const handleToggleMenu = (menuAnchor) => setAnchor(menuAnchor);

	const getMeetingLinkClickHandler = (id) => (e) => {
		e.preventDefault();

		openMeetingDetails(id);
	};

	const handleDelete = () => {
		telemetryAddEvent("All meetings - Delete meeting");

		setAnchor(null);
		openMeetingDeleteDialog({
			id: meeting.id,
			name: meeting.name,
			date: meeting.date,
		});
	};

	useEffect(() => {
		const options = [];
		const administratorOrMember = meeting.administrator || meeting.member;
		const boardUser = administratorOrMember || meeting.staff;
		if (
			administratorOrMember &&
			isMinutesShared(meeting.boardMinutesStatus) &&
			(!isAdopted(meeting.boardMinutesStatus) || meeting.historic) &&
			meeting.minutes?.viewDraftMember
		) {
			options.push({
				label: t("options.draftMemberMinutes"),
				actionFunction: () => navigate(`/meeting/final/${meeting.id}/minutes/members`),
				"data-cy": "draft-member-minutes",
			});
		}
		if (
			boardUser &&
			isMinutesPublished(meeting.publicMinutesStatus) &&
			!isAdopted(meeting.publicMinutesStatus) &&
			meeting.minutes?.viewDraftPublic
		) {
			options.push({
				label: t("options.draftPublicMinutes"),
				actionFunction: () => navigate(`/meeting/final/${meeting.id}/minutes/public`),
				"data-cy": "draft-public-minutes",
			});
		}
		if (
			administratorOrMember &&
			isMinutesShared(meeting.boardMinutesStatus) &&
			isAdopted(meeting.boardMinutesStatus) &&
			meeting.minutes?.viewMember
		) {
			options.push({
				label: t("options.memberMinutes"),
				actionFunction: () => navigate(`/meeting/final/${meeting.id}/minutes/members`),
				"data-cy": "member-minutes",
			});
		}
		if (
			boardUser &&
			isMinutesPublished(meeting.publicMinutesStatus) &&
			isAdopted(meeting.publicMinutesStatus) &&
			meeting.minutes?.viewPublic
		) {
			options.push({
				label: t("options.publicMinutes"),
				actionFunction: () => navigate(`/meeting/final/${meeting.id}/minutes/public`),
				"data-cy": "public-minutes",
			});
		}
		if (meeting.administrator && !isPublished(meeting.publicAgendaStatus)) {
			options.push({ label: t("buttons.delete"), actionFunction: handleDelete, "data-cy": "delete" });
		}
		setMenuOptions(options);
	}, [meeting]);

	return (
		<ListCard
			item={meeting}
			urlField={(meeting) =>
				`/home${
					meeting.administrator
						? `/meeting/${meeting.id}`
						: meeting.historic
						? `/document/${meeting.member && meeting.boardAgendaHtmlId ? meeting.boardAgendaHtmlId : meeting.publicAgendaHtmlId}`
						: `/meeting/document/${meeting.id}`
				}`
			}
			dateField="date"
			nameField="cleanName"
			subTitleField={(meeting) => formatDate(null, meeting.startTime, meeting.endTime, t("app:at"), t("from"), t("to"), false)}
			reserveSubTitleSpace={widthUpMd}
			itemClick={getMeetingLinkClickHandler(meeting.id)}
			secondaryContent={
				<div className={classes.secondaryContent}>
					{showStatus && (
						<div>
							<MeetingPublishStatus
								meetingId={meeting.id}
								boardStatus={meeting.administrator || meeting.member ? meeting.boardAgendaStatus : undefined}
								publicStatus={meeting.publicAgendaStatus}
								publicReleaseDay={meeting.publicAgendaReleaseDay}
								boardHtmlId={meeting.boardAgendaHtmlId}
								draftReview={meeting.draftReview}
								previousUrl="/meetings"
								telemetryPage="All meetings"
								width="auto"
							/>
						</div>
					)}
					{meeting.zoomLink && (
						<div>
							<Button
								style={{ backgroundColor: "#fff", marginTop: "0" }}
								variant="outlined"
								color="primary"
								onClick={() => {
									window.open(meeting.zoomLink);
								}}
								data-cy="joinVideo"
							>
								{t("meetings:buttons.joinVideo")}
							</Button>
						</div>
					)}
				</div>
			}
			additionalContent={
				meeting.userAnnotations ? (
					<Tooltip title={t("meetingContainsAnnotations")}>
						<span className={classes.notes}>
							<Icon name="notes" />
						</span>
					</Tooltip>
				) : undefined
			}
			menuOptions={menuOptions}
			overflowTooltip={t("app:menu.options")}
			menuAnchor={anchor}
			handleToggleMenu={handleToggleMenu}
			classes={{ mainContent: classes.mainContent, textDetails: classes.textDetails }}
		></ListCard>
	);
};

export default MeetingListCard;
