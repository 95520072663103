import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import clsx from "clsx";

import makeStyles from "@mui/styles/makeStyles";

import AccessibleIconButton from "atlas/components/Buttons/AccessibleIconButton";
import userListStyle from "assets/jss/components/userListStyle";
import GenericAvatar, { GENERIC_AVATAR_BACKGROUNDS } from "components/Avatar/GenericAvatar";
import ApproversListApprovers from "./ApproversListApprovers";

const useUserListStyles = makeStyles(userListStyle);
const useStyles = makeStyles(() => ({
	userList: {
		marginLeft: "48px",
	},
}));

const ApproversListBlock = (props) => {
	const { block, usersOnly, indentUsers } = props;
	const { t } = useTranslation("app");
	const [expanded, setExpanded] = useState(false);
	const classes = useStyles();
	const userListClasses = useUserListStyles();

	const toggleExpand = () => {
		setExpanded((prev) => !prev);
	};

	return (
		block && (
			<>
				{usersOnly ? (
					<ApproversListApprovers block={block} indent={indentUsers} />
				) : (
					<li data-cy={`workflow-block-${block.id}`}>
						<div className={userListClasses.user}>
							<div>
								<AccessibleIconButton
									aria-label={t(`approvals.tooltips.${expanded ? "collapse" : "expand"}Block`)}
									onClick={toggleExpand}
									iconName={expanded ? "expand-down" : "expand-right"}
									dataCy="toggle-block"
									tooltipText={t(`approvals.tooltips.${expanded ? "collapse" : "expand"}Block`)}
									name="toggle"
									disableRipple
								/>
							</div>
							<div className={userListClasses.avatar}>
								<GenericAvatar
									id={`block-profile-picture-${block.id}`}
									name={block.name}
									initialsSource={block.name}
									backgroundNumber={((block.number || 0) % GENERIC_AVATAR_BACKGROUNDS) + 1}
								/>
							</div>
							<div className={userListClasses.identifier} tabIndex="0">
								<div className={userListClasses.name}>{block.name}</div>
								<div className={userListClasses.title}>{t("approvals.blockTitle")}</div>
							</div>
						</div>
						{expanded && (
							<ol className={clsx(userListClasses.list, classes.userList)}>
								<ApproversListApprovers block={block} indent />
							</ol>
						)}
					</li>
				)}
			</>
		)
	);
};

export default ApproversListBlock;
