import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import request from "superagent";

import makeStyles from "@mui/styles/makeStyles";
import { MenuItem } from "@mui/material";

import { API_HOST } from "config/env";
import Icon from "atlas/components/Icon/Icon";
import inputStyle from "atlas/assets/jss/components/inputStyle";
import CircularProgressIndicator from "atlas/components/Progress/CircularProgressIndicator";
import GenericDialog from "atlas/components/Dialogs/GenericDialog";
import SelectInput from "atlas/components/FormControls/SelectInput";
import ApproversList from "components/Approval/ApproversList";
import { useSelectInputOptionStyles, getNoOptionsMenuItem, getDropdownProgressIndicator, getMenuItem } from "utils/dropDown";

const useInputStyles = makeStyles(inputStyle);
const useStyles = makeStyles(() => ({
	dialog: {
		"& .MuiDialog-paper": {
			width: "440px",
			maxWidth: "100%",
		},
	},
	instructions: {
		marginBottom: "8px",
	},
	menuItem: {
		maxWidth: (props) => (props.selectWidth > 0 ? `${props.selectWidth}px` : undefined),
	},
	menuItemText: {
		overflow: "hidden",
		textOverflow: "ellipsis",
	},
	list: {
		maxHeight: "200px",
		overflowX: "auto",
		margin: "0 -24px -16px -24px",
		padding: "0 24px",
	},
}));

export const MEETING_ADMINISTRATORS_WORKFLOW_ID = -1;

const SubmitForApprovalDialog = (props) => {
	const {
		show,
		item: { meeting, workflow, meetingInPast },
		item,
		onSubmit,
		onClose,
	} = props;
	const { templateId = 0 } = workflow || {};
	const { t } = useTranslation("agendaItems");
	const [workflows, setWorkflows] = useState(null);
	const [selection, setSelection] = useState({
		workflowId: 0,
	});
	const [submitting, setSubmitting] = useState(false);
	const [selectWidth, setSelectWidth] = useState(0);
	const classes = useStyles({ selectWidth });
	const inputClasses = useInputStyles({ fullWidth: true });

	const loadWorkflows = () => {
		const { id: meetingId = 0 } = meeting || {};

		request
			.get(`${API_HOST}/api/workflows?meetingId=${meetingId}`)
			.withCredentials()
			.then((res) => {
				const { workflows } = res.body;

				// Give each block and user a number to use for the avatar background
				workflows.forEach((workflow) => {
					let number = 0;
					const numberCache = {};
					const singleBlock = workflow.blocks.length === 1;
					workflow.blocks.forEach((block) => {
						if (!singleBlock && block.users.length > 1) {
							if (typeof numberCache[`B${block.id}`] === "undefined") {
								numberCache[`B${block.id}`] = number;
								number++;
							}
							block.number = numberCache[`B${block.id}`];
						}

						block.users.forEach((user) => {
							if (typeof numberCache[user.id] === "undefined") {
								numberCache[user.id] = number;
								number++;
							}
							user.number = numberCache[user.id];
						});
					});
				});

				setWorkflows(workflows);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const getWorkflows = () => {
		const workflowItems = [];
		if (workflows && workflows.length) {
			workflowItems.push(getNoOptionsMenuItem(t));

			workflows.forEach((workflow) => {
				workflowItems.push(
					<MenuItem key={workflow.id} className={classes.menuItem} value={workflow.id} data-cy={`workflow-${workflow.id}`}>
						<div className={classes.menuItemText}>{workflow.name}</div>
					</MenuItem>,
				);
			});
		}

		return workflowItems;
	};

	const handleFieldChange = (e, field) => {
		const {
			target: { value },
		} = e;

		setSelection((prev) => ({
			...prev,
			[field]: value,
		}));
	};

	useEffect(() => {
		loadWorkflows();
	}, [item, meeting]);

	useEffect(() => {
		// Set the drop-down options width to match the width of the control
		const itemSelect = document.getElementById("workflows");
		if (itemSelect) {
			setSelectWidth(itemSelect.offsetWidth);
		}
	});

	return (
		<GenericDialog
			className={classes.dialog}
			show={show}
			title={t("submitForApprovalDialog.title")}
			primaryAction={() => {
				setSubmitting(true);
				onSubmit(selection, item);
			}}
			primaryTitle={
				submitting ? <CircularProgressIndicator size={20} minHeight="20px" /> : t("submitForApprovalDialog.buttons.submitForApproval")
			}
			primaryDisabled={meetingInPast || submitting || selection.workflowId === 0}
			secondaryAction={onClose}
			secondaryTitle={t("submitForApprovalDialog.buttons.cancel")}
			secondaryDisabled={submitting}
			clickAwayDisabled={submitting}
			closeIcon={<Icon name="close" />}
			data-cy="agenda-item-submit-for-approval-dialog"
		>
			{meetingInPast ? (
				<div className={classes.instructions}>{t("submitForApprovalDialog.warnings.meetingInPast")}</div>
			) : workflows ? (
				<>
					<div className={classes.instructions}>{t("submitForApprovalDialog.instructions")}</div>
					<SelectInput
						id="workflows"
						className={inputClasses.smallInput}
						noDefaultClassName
						fullWidth
						size="small"
						label={t("submitForApprovalDialog.labels.workflows")}
						externalLabel
						labelSize="large"
						value={selection.workflowId}
						onChange={(e) => handleFieldChange(e, "workflowId")}
						data-cy="workflows"
					>
						{getWorkflows()}
					</SelectInput>
					{selection.workflowId !== 0 ? (
						<ApproversList workflow={workflows.find((workflow) => workflow.id == selection.workflowId)}></ApproversList>
					) : null}
				</>
			) : (
				<CircularProgressIndicator />
			)}
		</GenericDialog>
	);
};

export default SubmitForApprovalDialog;
