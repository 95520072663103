import React, { useState, useEffect, useRef, useContext, useCallback } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import trimChars from "lodash/fp/trimChars";
import request from "superagent";
import { v4 as uuid } from "uuid";

import makeStyles from "@mui/styles/makeStyles";
import { Box, MenuItem, Typography } from "@mui/material";

import settingsStyle from "assets/jss/components/settingsStyle";
import { successColor, errorColor } from "atlas/assets/jss/shared";
import typographyStyles from "atlas/assets/jss/components/typographyStyle";
import ButtonWithTooltip from "atlas/components/Buttons/ButtonWithTooltip";
import RadioButton from "atlas/components/FormControls/RadioButton";
import SelectInput from "atlas/components/FormControls/SelectInput";
import CircularProgressIndicator from "atlas/components/Progress/CircularProgressIndicator";
import Icon from "atlas/components/Icon/Icon";
import { API_HOST } from "config/env";
import { SettingsContext } from "contexts/Settings/SettingsContext";
import { getPolicies } from "redux/policyGovernance/actions";
import { endsWithAllowedExtension, endsWithAllowedExtensionForPolicy } from "utils/allowedExtensions";
import { POLICY_DRAFT, POLICY_PUBLISHED } from "utils/enums/PolicyTypes";
import { sortTextFieldCaseInsensitive } from "utils/sort";
import { useUpdateObject } from "utils/updateObject";

const useSettingsStyles = makeStyles(settingsStyle);
const useStyles = makeStyles({
	bold: {
		...typographyStyles.bold,
	},
	fileInput: {
		display: "none",
	},
	files: {
		paddingTop: "8px",
	},
	file: {
		padding: "8px 0",
		display: "flex",
		alignItems: "center",
	},
	status: {
		width: "32px",
		height: "24px",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	},
});

const sortDefault = (a, b) => sortTextFieldCaseInsensitive(a, b, "title");

const FolderUpload = (props) => {
	const { showSignIn } = props;
	const { t } = useTranslation("settings");
	const [policyOptions, setPolicyOptions] = useState({
		book: "",
		status: POLICY_DRAFT,
	});
	const [books, setBooks] = useState(null);
	const [selectedFiles, setSelectedFiles] = useState([]);
	const [uploadIndex, setUploadIndex] = useState(null);
	const classes = useStyles();
	const settingsClasses = useSettingsStyles();
	const input = useRef(null);
	const policyInput = useRef(null);
	const active = useRef(true);
	const { policyEnabled } = useContext(SettingsContext);
	const dispatch = useDispatch();

	const loadBooks = () => {
		dispatch(getPolicies())
			.then((res) => {
				const filteredBooks = res.filter((book) => book.canUpdate).sort(sortDefault);
				setBooks(filteredBooks);
				if (filteredBooks.length > 0) {
					setPolicyOptions((prev) => ({
						...prev,
						book: filteredBooks[0].guid,
					}));
				}
			})
			.catch((err) => {
				showSignIn(err, () => {
					loadCustomFoldersForPolicy();
				});
			});
	};

	const folderUploadClick = () => {
		input.current.click();
	};

	const policyFolderUploadClick = () => {
		policyInput.current.click();
	};

	const inputChange = (e, endsWithAllowedExtension, policy = false) => {
		const { target: { files = [] } = {} } = e;

		const filteredFiles = [];
		// eslint-disable-next-line no-plusplus
		for (let index = 0; index < files.length; index++) {
			if (endsWithAllowedExtension(files[index].name)) {
				filteredFiles.push(files[index]);
			}
		}

		// Only get files with allowed extensions
		setSelectedFiles(
			filteredFiles
				.sort((first, second) =>
					// eslint-disable-next-line no-nested-ternary
					first.webkitRelativePath < second.webkitRelativePath ? -1 : first.webkitRelativePath > second.webkitRelativePath ? 1 : 0,
				)
				.map((file) => ({
					file,
					name: trimChars("\\/", file.webkitRelativePath),
					path: trimChars("\\/", file.webkitRelativePath.replace(file.name, "")),
					policy,
				}))
				.filter((fileData) => !policy || fileData.path.split("/").length === 2)
				.map((fileData) =>
					!policy
						? fileData
						: {
								...fileData,
								path: fileData.path.split("/")[1],
						  },
				),
		);

		setUploadIndex(0);
	};

	const folderInputChange = (e) => inputChange(e, endsWithAllowedExtension);

	const policyFolderInputChange = (e) => inputChange(e, endsWithAllowedExtensionForPolicy, true);

	const finishUpload = (file, files, index, status) => {
		if (active.current) {
			// eslint-disable-next-line no-param-reassign
			files[index] = {
				...file,
				uploading: false,
				uploaded: status === 200,
				error: status !== 200,
			};

			setSelectedFiles([...files]);
			setUploadIndex(index + 1);
		}
	};

	const uploadFile = useCallback(
		(index) => {
			if (index !== null && index >= 0 && index < selectedFiles.length && active.current) {
				const selectedFile = selectedFiles[index];

				selectedFiles[index] = {
					...selectedFile,
					uploading: true,
				};
				setSelectedFiles([...selectedFiles]);

				const fileData = new FormData();
				fileData.append(uuid(), selectedFile.file);
				fileData.append(
					"data",
					JSON.stringify({
						path: selectedFile.path,
						allowDuplicates: false,
						status: policyOptions.status,
					}),
				);

				request
					.post(`${API_HOST}/api/${!selectedFile.policy ? "document/0" : `policy/${policyOptions.book}`}/upload`)
					.withCredentials()
					.send(fileData)
					.then((res) => {
						finishUpload(selectedFile, selectedFiles, index, res.status);
					})
					.catch((err) => {
						if (err.status === 400) {
							finishUpload(selectedFile, selectedFiles, index, err.status);
						} else {
							showSignIn(err, () => {
								uploadFile(index);
							});
						}
					});
			}
		},
		[policyOptions, selectedFiles],
	);

	const updatePolicyOptions = useUpdateObject(setPolicyOptions);

	useEffect(() => {
		uploadFile(uploadIndex);
	}, [uploadIndex]);

	useEffect(() => {
		if (policyEnabled) {
			loadBooks();
		}

		return () => {
			active.current = false;
		};
	}, []);

	useEffect(() => {
		const element = document.getElementById(`file-${uploadIndex}`);
		if (element) {
			element.scrollIntoView();
		}
	});

	return (
		<>
			<Box sx={{ marginTop: 1, marginBottom: 2 }}>
				<label htmlFor="folder-upload">
					<Typography variant="h4" className={classes.bold}>
						{t("folderUpload")}
					</Typography>
				</label>
				<div>
					<ButtonWithTooltip
						id="folder-upload"
						variant="outlined"
						color="primary"
						hasRightMargin
						hasTopMargin
						onClick={folderUploadClick}
						title={t("tooltips.folderUpload")}
						data-cy="folder-upload-button"
						disabled={selectedFiles.length > 0}
					>
						{t("buttons.uploadFolder")}
					</ButtonWithTooltip>
					<input
						className={classes.fileInput}
						id="folder-upload-input"
						type="file"
						webkitdirectory=""
						ref={input}
						onChange={folderInputChange}
					/>
				</div>
			</Box>
			{policyEnabled && books && (
				<Box>
					<label htmlFor="policy-folder-upload">
						<Typography variant="h4" className={classes.bold}>
							{t("policyFolderUpload.labels.title")}
						</Typography>
					</label>
					<div>
						<SelectInput
							id="book"
							className={settingsClasses.selectInput}
							fullWidth
							size="small"
							label={t("policyFolderUpload.labels.book")}
							noDefaultClassName
							value={policyOptions.book}
							onChange={(e) => updatePolicyOptions(e, "book")}
							data-cy="book"
						>
							{books.map((book) => (
								<MenuItem key={book.guid} value={book.guid}>
									<div className={settingsClasses.menuItemText}>{book.title}</div>
								</MenuItem>
							))}
						</SelectInput>
					</div>
					<div>
						<RadioButton
							row
							options={[
								{ text: t("policyFolderUpload.options.draft"), value: POLICY_DRAFT, key: "draft" },
								{ text: t("policyFolderUpload.options.published"), value: POLICY_PUBLISHED, key: "published" },
							]}
							getId={(option) => `upload-${option.key || option.value}`}
							getKey={(option) => option.key || option.value}
							getValue={(option) => option.value}
							getDataCy={(option) => `upload-${option.key || option.value}`}
							getLabel={(option) => option.text}
							handleChange={(value) => {
								updatePolicyOptions({ target: { value } }, "status", false, true);
							}}
							objectToUpdate={policyOptions}
							field="status"
							sx={{ formControl: { margin: 0 } }}
						/>
					</div>
					<div>
						<ButtonWithTooltip
							id="policy-folder-upload"
							variant="outlined"
							color="primary"
							hasRightMargin
							hasTopMargin
							onClick={policyFolderUploadClick}
							title={t("policyFolderUpload.tooltips.upload")}
							data-cy="policy-folder-upload-button"
							disabled={selectedFiles.length > 0}
						>
							{t("policyFolderUpload.buttons.upload")}
						</ButtonWithTooltip>
						<input
							className={classes.fileInput}
							id="policy-folder-upload-input"
							type="file"
							webkitdirectory=""
							ref={policyInput}
							onChange={policyFolderInputChange}
						/>
					</div>
				</Box>
			)}
			{selectedFiles.length > 0 && (
				<Box className={classes.files}>
					{selectedFiles.map((selectedFile, index) => (
						<div id={`file-${index}`} key={selectedFile.name} className={classes.file}>
							<div className={classes.status}>
								{selectedFile.uploaded && <Icon name="status-success" color={successColor} />}
								{selectedFile.uploading && <CircularProgressIndicator size={24} />}
								{selectedFile.error && <Icon name="status-error" color={errorColor} />}
							</div>
							<div>{selectedFile.name}</div>
						</div>
					))}
				</Box>
			)}
		</>
	);
};

export default FolderUpload;
