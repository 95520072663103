import React from 'react';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';
import { Box, Grid, Switch } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { useTranslation } from 'react-i18next';
import { primaryColor, whiteColor } from '../../assets/jss/shared';
import LargeLabel from '../Typography/LargeLabel';
import SmallLabel from '../Typography/SmallLabel';
import Tooltip from '../Tooltip/Tooltip';

const LargeSwitch = withStyles(() => ({
	root: {
		width: 51,
		height: 28,
		padding: 0,
		display: 'flex',
	},
	switchBase: {
		padding: 0,
		color: whiteColor,
		border: 'solid 2px #455772!important',
		margin: '0!important',
		borderRadius: '13px',
		'&$checked': {
			transform: 'translateX(24px)',
			color: primaryColor[4],
			'& + $track': {
				opacity: 1,
				backgroundColor: primaryColor[4],
				borderColor: primaryColor[4],
			},
		},
	},
	thumb: {
		width: 25,
		height: 25,
		boxShadow: 'none',
		margin: '-1px',
	},
	track: {
		border: `1px solid ${primaryColor[4]}`,
		borderRadius: 0,
		opacity: 1,
		backgroundColor: primaryColor[4],
		height: '4px',
		alignSelf: 'center',
	},
	checked: {},
}))(Switch);

const SmallSwitch = withStyles((theme) => ({
	switchBase: {
		color: whiteColor,
		borderColor: 'transparent !important',
		'&$checked': {
			color: theme.palette.common.white,
			'& + $track': {
				opacity: 1,
				backgroundColor: primaryColor[4],
				borderColor: primaryColor[4],
			},
		},
		'&:hover': {
			backgroundColor: 'transparent !important',
		},
	},
	checked: {},
	track: {},
}))(Switch);

const useStyles = makeStyles({
	inline: {
		width: 'auto !important',
	},
});

const StyledSwitch = (props) => {
	const {
		id,
		label,
		useLabel,
		sideLabel,
		title,
		tooltipPlacement,
		classes: externalClasses = {},
		size,
		checked,
		fieldToUpdate,
		objectToUpdate,
		onChange,
		helperText,
		boldLabel = false,
		offLabel,
		onLabel,
		inline,
		persistOnChange = false,
		disabled = false,
		showLabel = true,
	} = props;
	const { t } = useTranslation('app');
	const classes = useStyles();

	const isChecked = typeof checked !== 'undefined' ? checked : objectToUpdate[fieldToUpdate];

	return (
		<>
			{!sideLabel ? (
				useLabel ? (
					<label className={externalClasses.label} htmlFor={id}>
						{label}
					</label>
				) : (
					<Box className={externalClasses.label} mb={2} mt={1}>
						<LargeLabel color="black" semiBold={boldLabel}>
							{label}
						</LargeLabel>
					</Box>
				)
			) : null}
			<Grid
				className={clsx({
					[classes.inline]: inline,
				})}
				component="label"
				container
				alignItems="center"
				spacing={1}
			>
				{!sideLabel && (
					<Grid item>
						<SmallLabel className={externalClasses.stateLabel} color="black">
							{showLabel && (offLabel || t('buttons.off'))}
						</SmallLabel>
					</Grid>
				)}
				<Grid item>
					<Tooltip placement={tooltipPlacement} title={title}>
						{size === 'large' ? (
							<LargeSwitch
								id={id}
								className={externalClasses.switch}
								checked={isChecked}
								onChange={(e) => onChange(e.target.checked, objectToUpdate, fieldToUpdate, persistOnChange)}
								aria-label={title}
								data-cy={props['data-cy']}
								disabled={disabled}
							/>
						) : (
							<SmallSwitch
								id={id}
								className={externalClasses.switch}
								checked={isChecked}
								onChange={(e) => onChange(e.target.checked, objectToUpdate, fieldToUpdate, persistOnChange)}
								aria-label={title}
								data-cy={props['data-cy']}
								disabled={disabled}
							/>
						)}
					</Tooltip>
				</Grid>
				<Grid item>
					{sideLabel ? (
						<label className={externalClasses.stateLabel} htmlFor={id}>
							{label}
						</label>
					) : (
						<SmallLabel className={externalClasses.stateLabel} color="black">
							{showLabel && (onLabel || t('buttons.on'))}
						</SmallLabel>
					)}
				</Grid>
			</Grid>
			{helperText && (
				<Box className={externalClasses.helperText} mt={1} ml="14px">
					<SmallLabel color="black">{helperText}</SmallLabel>
				</Box>
			)}
		</>
	);
};

export default StyledSwitch;
