import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import clsx from "clsx";

import makeStyles from "@mui/styles/makeStyles";

import processHtml from "utils/processHtml";
import Icon from "atlas/components/Icon/Icon";
import { whiteColor, blackColor } from "atlas/assets/jss/shared";
import LiveMeetingRadioButton from "./LiveMeetingRadioButton";
import LiveMeetingAttachments from "./LiveMeetingAttachments";
import TimeStampItem from "./TimeStampItem";
import agendaStyle from "../../../assets/jss/components/agendaStyle";

const useStyle = makeStyles(agendaStyle);

const LiveMeetingHeading = (props) => {
	const {
		heading,
		isClosedMeeting,
		isMemberOnlyHeading,
		isConsentHeading,
		isPublicCommentHeading,
		isSubHeading,
		hasMotions,
		addBottomBorder,
		selected,
		hasNotes,
		handleSelect,
		handleClick,
		presentation,
		presenting,
		syncEnabled,
		saveSyncEnabled,
		broadcasts,
		videoExists,
		youtubePlayerRef,
		updateSync,
		saveSync,
		handleTimeStampBlur,
		clearTimestamp,
		setTimestamp,
		videoControlsDisabled,
	} = props;
	const { t } = useTranslation("agendaMenu");
	const { fields, attachments } = heading;
	const classes = useStyle({ isSubHeading, addTopBorder: !isMemberOnlyHeading && !isConsentHeading });

	const isSelected = selected === heading.guid;
	const addBottomBorderToThis = addBottomBorder && heading.attachments.length === 0;

	return (
		!heading.deleted && (
			<>
				{isMemberOnlyHeading && !isSubHeading && (
					<li
						className={clsx(classes.headerListItem, classes.memberOnlyHeader, { [classes.radioListItem]: !presentation })}
						data-cy={`agenda-header-member-only-${heading.guid}`}
					>
						<Icon name="locked" color={whiteColor} size="16px" />
						<span className={classes.headerTextWithIcon}>{t("memberOnlySection")}</span>
					</li>
				)}
				{isConsentHeading && !isSubHeading && (
					<li
						className={clsx(classes.headerListItem, classes.consentHeader, { [classes.radioListItem]: !presentation })}
						data-cy={`agenda-header-consent-${heading.guid}`}
					>
						<span>{t("consentSection")}</span>
					</li>
				)}
				{isPublicCommentHeading && !isSubHeading && (
					<li
						className={clsx(classes.headerListItem, classes.publicCommentHeader, { [classes.radioListItem]: !presentation })}
						data-cy={`agenda-header-public-comment-${heading.guid}`}
					>
						<span>{t("publicCommentSection")}</span>
					</li>
				)}
				<li
					id={`agenda-${heading.guid}`}
					className={clsx(classes.agendaListItem, {
						[classes.radioListItem]: !presentation,
						[classes.borderDefault]: !isMemberOnlyHeading && !isConsentHeading && !isPublicCommentHeading && !addBottomBorderToThis,
						[classes.borderDefaultBottomBorder]:
							!isMemberOnlyHeading && !isConsentHeading && !isPublicCommentHeading && addBottomBorderToThis,
						[classes.borderMemberOnly]: isMemberOnlyHeading,
						[classes.borderConsent]: isConsentHeading,
						[classes.borderPublicComment]: isPublicCommentHeading,
						[classes.selected]: isSelected && !presentation,
						[classes.presentationSelected]: isSelected && presentation,
					})}
					data-cy={`agenda-${heading.guid}`}
				>
					{!presentation && (
						<LiveMeetingRadioButton
							value={heading.guid}
							label={fields.Name.Value}
							selected={isSelected}
							hasNotes={hasNotes}
							handleSelect={handleSelect}
							disabled={syncEnabled || videoControlsDisabled}
						/>
					)}
					<div
						className={clsx(classes.item, classes.itemHeading, classes.liveMeetingItem, classes.rightBorder, {
							[classes.bottomBorder]: addBottomBorderToThis,
						})}
					>
						<div
							onClick={(!syncEnabled || (syncEnabled && saveSyncEnabled)) && handleClick ? (event) => handleClick(event, heading) : null}
							className={clsx(classes.textContainer, {
								[classes.listItemClickable]: (!syncEnabled || (syncEnabled && saveSyncEnabled)) && handleClick,
							})}
						>
							{fields.Number.Value.length > 0 && <div className={classes.bullet}>{fields.Number.Value}</div>}
							<div className={clsx(classes.itemText, { [classes.textTruncate]: !presentation && videoExists })}>
								{processHtml(fields.Name.Value, {
									disableLinks: !presentation,
									classes: { link: classes.disabledLink },
									excludeMemberOnlyAttachments: presentation,
								})}
								{presentation && processHtml(fields.Text.Value, { excludeMemberOnlyAttachments: presentation })}
							</div>
						</div>
						{hasMotions && (
							<div
								className={clsx(classes.itemMotionIcon, classes.itemIcon)}
								title={t("meetings:tooltips.headingHasMotions")}
								data-icon="vote"
								data-cy="has-motions"
							>
								<Icon name={hasMotions.approved ? "vote" : "motion"} color={blackColor[1]} />
							</div>
						)}
						{!presentation && videoExists && (
							<TimeStampItem
								item={heading}
								isSelected={isSelected}
								syncEnabled={syncEnabled}
								saveSyncEnabled={saveSyncEnabled}
								youtubePlayerRef={youtubePlayerRef}
								updateSync={updateSync}
								saveSync={saveSync}
								handleTimeStampBlur={handleTimeStampBlur}
								clearTimestamp={clearTimestamp}
								setTimestamp={setTimestamp}
								disabled={videoControlsDisabled}
								presenting={presenting}
								broadcasts={broadcasts}
							/>
						)}
					</div>
				</li>
				{!presentation && (
					<LiveMeetingAttachments
						attachments={attachments}
						item={heading}
						isClosedMeeting={isClosedMeeting}
						isMemberOnlyHeading={isMemberOnlyHeading}
						isConsentHeading={isConsentHeading}
						isPublicCommentHeading={isPublicCommentHeading}
						addBottomBorder={addBottomBorder}
						selected={selected}
						handleSelect={handleSelect}
						presentation={presentation}
					/>
				)}
			</>
		)
	);
};

LiveMeetingHeading.propTypes = {
	heading: PropTypes.object.isRequired,
	isClosedMeeting: PropTypes.bool,
	isMemberOnlyHeading: PropTypes.bool,
	isConsentHeading: PropTypes.bool,
	isPublicCommentHeading: PropTypes.bool,
	isSubHeading: PropTypes.bool,
	addBottomBorder: PropTypes.bool,
	selected: PropTypes.string.isRequired,
	hasNotes: PropTypes.bool,
	handleSelect: PropTypes.func,
};

LiveMeetingHeading.defaultProps = {
	isClosedMeeting: false,
	isMemberOnlyHeading: false,
	isConsentHeading: false,
	isPublicCommentHeading: false,
	isSubHeading: false,
	addBottomBorder: false,
	hasNotes: false,
	handleSelect: undefined,
};

export default LiveMeetingHeading;
