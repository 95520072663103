import typographyStyle from "atlas/assets/jss/components/typographyStyle";
import { grayColor, blackColor, whiteColor } from "atlas/assets/jss/shared";

const generalSettingsStyle = () => ({
	inputtextarea: {
		width: "420px",
		["@media (max-width:690px)"]: {
			width: "calc(100% - 60px)",
		},
	},
	inputfield: {
		width: "416px",
		["@media (max-width:690px)"]: {
			width: "100%",
		},
	},
	subAccordians: {
		marginLeft: "33px",
		["@media (max-width:690px)"]: {
			width: "100%",
		},
	},
	youTubeUploadButton: {
		width: "75px",
	},
	flexMatrix: {
		gap: "10px",
		display: "flex",
		["@media (max-width:690px)"]: {
			gap: "2px",
		},
	},
	flexMatrixSend: {
		gap: "10px",
		display: "flex",
		["@media (max-width:690px)"]: {
			width: "calc(100% - 60px)",
			flexDirection: "column",
		},
	},
	mainCard: {
		marginLeft: "35px",
		["@media (max-width:690px)"]: {
			width: "100%",
		},
	},
	subCard: {
		display: "flex",
		["@media (max-width:690px)"]: {
			width: "calc(100% - 60px)",
		},
	},
	subCardEmail: {
		["@media (max-width:690px)"]: {
			width: "calc(100% - 20px)",
		},
	},
	validateButton: {
		marginTop: "10px",
	},
	fieldInput: {
		width: "150px",
		marginTop: "16px",
		marginBottom: "16px",
		"& .MuiInputBase-root": {
			height: "40px",
			"& .MuiInputBase-input": {
				boxSizing: "border-box",
				height: "40px",
				paddingTop: "10.5px",
				paddingBottom: "10.5px",
			},
		},
	},
	redirectLink: {
		marginLeft: "15px",
		marginTop: "22px",
		display: "flex",
		alignItems: "center",
		height: "100%",
	},
	buttons: {
		display: "flex",
		flexDirection: "column",
	},
	helpButton: {
		marginTop: "15px",
		["@media (max-width:690px)"]: {
			marginRight: "10px",
		},
	},
	addButton: {
		marginBottom: "20px",
	},
	addCredentialButton: {
		marginTop: "5px",
		marginBottom: "20px",
	},
	boxCastCheckbox: {
		display: "flex",
		marginTop: "5px",
		"& p": {
			marginTop: "7px",
		},
		"& span": {
			marginLeft: "8px",
			paddingBottom: "17px",
		},
	},
	youTubeCheckbox: {
		display: "flex",
		"& p": {
			marginTop: "7px",
		},
		"& span": {
			marginLeft: "3px",
			paddingBottom: "17px",
		},
	},
	boxCastTitle: {
		fontWeight: "600",
		lineHeight: "2.1",
		marginLeft: "31px",
	},
	youTubeTitle: {
		fontWeight: "600",
		lineHeight: "2.1",
		marginLeft: "31px",
	},
	boxcastSettings: {
		display: "flex",
	},
	headerWithCheckbox: {
		marginTop: "-10px",
	},
	boxCastText: {
		marginRight: "15px",
	},
	youTubeText: {
		marginLeft: "33px",
		marginBottom: "5px",
	},
	youtubeLink: {
		marginLeft: "5px",
	},
	youTubeLinkIcon: {
		marginLeft: "3px",
	},
	letterheadTemplate: {
		display: "flex",
		alignItems: "center",
		gap: "16px",
	},
	fileInput: {
		display: "none",
	},
});
export default generalSettingsStyle;
