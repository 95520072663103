import React from "react";
import { useTranslation } from "react-i18next";
import clsx from "clsx";

import makeStyles from "@mui/styles/makeStyles";

import settingsStyle from "assets/jss/components/settingsStyle";
import InputLabel from "atlas/components/FormControls/InputLabel";
import StyledSwitch from "atlas/components/FormControls/StyledSwitch";
import telemetryAddEvent from "utils/telemetryAddEvent";

const useStyles = makeStyles(settingsStyle);

const ToggleOldBackend = (props) => {
	const { settings, handleChange } = props;
	const { t } = useTranslation("settings");
	const classes = useStyles();

	return (
		<>
			<div className={classes.labelAndSwitch}>
				<div className={classes.sectionLabel}>
					<InputLabel htmlFor="view-old-back-end" label={t("viewOldBackEnd")} size="large" />
				</div>
				<div className={classes.labelWithOtherContent}>
					<StyledSwitch
						id="view-old-back-end"
						classes={{
							label: classes.switchLabel,
							stateLabel: classes.switchInstructions,
						}}
						inline
						title={t("tooltips.viewOldBackEnd")}
						size="small"
						objectToUpdate={settings}
						fieldToUpdate="viewOldBackEnd"
						onChange={(checked) => {
							telemetryAddEvent("Settings - View Old Back End");

							handleChange({ target: { checked } }, "viewOldBackEnd", true, false, true, true);
						}}
						data-cy="view-old-back-end"
					/>
				</div>
			</div>
			{settings.viewOldBackEnd && (
				<>
					<div>
						<a href="/filepro/documents">{t("labels.documentCenter")}</a>
					</div>
					<div>
						<a href="/eaengine">{t("labels.meetingManager")}</a>
					</div>
				</>
			)}
		</>
	);
};

export default ToggleOldBackend;
