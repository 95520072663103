import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import request from "superagent";
import { v4 as uuid } from "uuid";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Check } from "components/Icons";
import { API_HOST } from "config/env";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Checkbox from "@mui/material/Checkbox";
import makeStyles from "@mui/styles/makeStyles";

import processHtml from "utils/processHtml";
import telemetryAddEvent from "utils/telemetryAddEvent";
import CircularProgressIndicator from "atlas/components/Progress/CircularProgressIndicator";
import { STATUS_WARNING } from "atlas/assets/jss/utils/statusIndicators";
import NoticeCard from "atlas/components/Cards/NoticeCard";
import GenericDialog from "atlas/components/Dialogs/GenericDialog";
import ProgressBar from "atlas/components/Progress/ProgressBar";
import Icon from "atlas/components/Icon/Icon";

import { getMeetingOutputText } from "utils/meetingOutputText";
import { updateHandlers, PROGRESS_HUB } from "utils/communication/SignalrClient";
import ErrorDialogMeetingPublish from "./ErrorDialogMeetingPublish";
import GroupMemberList from "./GroupMemberList";
import { updateNotice } from "redux/app/actions";
import { useAsyncResult } from "utils/useAsyncResult";

const useStyles = makeStyles(() => ({
	progressBar: {
		width: "240px",
		margin: "0 auto",
		marginTop: "24px",
	},
}));

const MeetingShareDialog = (props) => {
	const {
		show,
		agenda = true,
		afterShare,
		onClose,
		meeting: { id, name, pendingItems },
		meeting,
		showSignIn,
		openSupportRequestDialog,
		editorFunctions,
		telemetryPage = "",
	} = props;
	const { t } = useTranslation("meetings");
	const [notifyMembers, setNotifyMembers] = useState(true);
	const [sharing, setSharing] = useState(false);
	const [showShareDialog, setShowShareDialog] = useState(show);
	const [errors, setErrors] = useState(null);
	const [progress, setProgress] = useState({
		label: " ",
		percent: 0,
	});
	const clampedProgress = Math.min(progress.percent, 100);
	const appReducer = useSelector((state) => state.appReducer);
	const {
		signalR: { client, handler },
	} = appReducer;
	const dispatch = useDispatch();
	const classes = useStyles();
	const isMounted = useRef();
	const guid = useRef();
	const progressId = "sharing-progress";

	const requestFinished = () => {
		if (isMounted.current) {
			setSharing(false);
		}

		onClose();
		afterShare({ id, agenda });
	};

	const previewSuccess = (res) => {
		setProgress({
			label: " ",
			percent: 100,
		});
		if (res && res.body && res.body.pdf && res.body.pdf.errors) {
			// backend returns both item and attachment errors under the "pdf.errors" section
			setErrors(res.body.pdf.errors);
			setShowShareDialog(false);
			setSharing(false);
		} else {
			requestFinished();
		}
	};

	const previewError = (err) => {
		if ([408, 504].indexOf(err.status) >= 0) {
			err.timeout = true;
		}
		handleError(err);
	};

	const [startResultCheck, resultCheckNow] = useAsyncResult(previewSuccess, previewError);

	const updateProgress = (percentage, message) => {
		setProgress({
			label: message,
			percent: percentage,
		});

		if (percentage >= 100) {
			resultCheckNow();
		}
	};

	const handleError = (err) => {
		if (isMounted.current) {
			setErrors(err);
			setShowShareDialog(false);
			setSharing(false);
		}
	};

	const handleShare = (notify) => {
		telemetryAddEvent(`${telemetryPage} - Share ${agenda ? "agenda" : "minutes"}`);

		if (!id) {
			handleError();
		}
		setSharing(true);

		request
			.post(`${API_HOST}/api/meeting/${id}/share`)
			.send({ progressGuid: guid.current, agenda, sendMembersNotifications: agenda && notify.members })
			.then(startResultCheck)
			.catch(previewError);
	};

	const handleCancel = () => {
		onClose();
		afterShare({ id, agenda, error: true });
	};

	const handleShareWithSave = (notify) => {
		// Check if editor function exists, if we are publishing from agenda editor we need to save, if from meeting details we don't need to save
		if (editorFunctions) {
			const { saveAgenda } = editorFunctions;

			saveAgenda({
				success: () => handleShare(notify),
				data: null,
			});
		} else {
			handleShare(notify);
		}
	};

	useEffect(() => {
		isMounted.current = true;
		guid.current = uuid();

		updateHandlers(dispatch, handler, PROGRESS_HUB, { announceProgress: updateProgress });

		client.ensureStarted().then(() => client.progressHub.registerProgressGuid(guid.current));

		return () => {
			client.ensureStarted().then(() => client.progressHub.clearProgressGuid(guid.current));

			updateHandlers(dispatch, handler, PROGRESS_HUB, { announceProgress: null });

			isMounted.current = false;
		};
	}, [meeting, agenda]);

	useEffect(() => {
		if (sharing) {
			window.document.getElementById(progressId)?.scrollIntoView();
		}
	}, [sharing]);

	const i18n = t("shareMeetingDialog", { name });
	const dialog = {
		title: getMeetingOutputText(i18n.title, agenda),
		line1: getMeetingOutputText(i18n.line1, agenda),
		line2: getMeetingOutputText(i18n.line2, agenda),
		notifyMembers: getMeetingOutputText(i18n.notifyMembers, agenda),
		primaryTitle: i18n.buttons.share,
		primaryAction: () => handleShareWithSave({ members: notifyMembers }),
		secondaryTitle: i18n.buttons.cancel,
		secondaryAction: handleCancel,
	};

	return (
		<>
			{errors && (
				<ErrorDialogMeetingPublish
					agenda={agenda}
					errors={errors}
					handleClose={dialog.secondaryAction} // error dialog close also triggers meeting publish cancel
					show
					openSupportRequestDialog={openSupportRequestDialog}
					meeting={meeting}
					triggeredBy="share"
					editorFunctions={editorFunctions}
				/>
			)}
			<GenericDialog
				show={showShareDialog}
				title={dialog.title}
				primaryAction={dialog.primaryAction}
				primaryTitle={sharing ? <CircularProgressIndicator color="secondary" size={20} minHeight="20px" /> : dialog.primaryTitle}
				primaryDisabled={sharing || pendingItems > 0}
				secondaryAction={dialog.secondaryAction}
				secondaryTitle={dialog.secondaryTitle}
				secondaryDisabled={sharing}
				clickAwayDisabled={sharing}
				closeIcon={<Icon name="close" />}
				data-cy="share-dialog"
			>
				{pendingItems === 0 ? (
					<>
						<Typography variant="h5">
							<Box mb={1}>{dialog.line1}</Box>
						</Typography>

						<Typography variant="h5">
							<Box mt={1} mb={1}>
								{dialog.line2}
							</Box>
						</Typography>

						<GroupMemberList agenda={agenda} meeting={meeting} showSignIn={showSignIn} telemetryPage={telemetryPage} />

						{agenda && (
							<>
								<Typography>{t("notifications.emailNotifications")}</Typography>
								<Box display="flex" justifyContent="flex-start" alignItems="flex-end">
									<FormGroup>
										<FormControlLabel
											id="notifyMembers"
											control={
												<Checkbox
													checkedIcon={<Check fontSize="small" color="primary" />}
													checked={notifyMembers}
													disabled={sharing}
													onChange={() => {
														telemetryAddEvent(`${telemetryPage} - ${!notifyMembers ? "Uncheck" : "Check"} notify members`);

														setNotifyMembers(!notifyMembers);
													}}
												/>
											}
											label={dialog.notifyMembers}
											data-cy="dialog-notify-members"
										/>
									</FormGroup>
								</Box>
							</>
						)}
						{sharing && <ProgressBar id={progressId} className={classes.progressBar} label={progress.label} progress={clampedProgress} />}
					</>
				) : (
					<NoticeCard
						updateNotice={updateNotice}
						status={STATUS_WARNING}
						thickStatus
						icon="status-alert"
						label={t("shareMeetingDialog.warningPendingItems", { count: pendingItems })}
						processHtml={processHtml}
					/>
				)}
			</GenericDialog>
		</>
	);
};

export default MeetingShareDialog;
