import React, { useEffect, useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { useWidthDown } from "atlas/utils/useWidth";
import SearchInput from "components/Search/SearchInput";
import { updateToolbar } from "redux/app/actions";
import { FOLDER_TYPE_RECYCLE_BIN } from "utils/enums/DocumentFolderTypes";

const PolicyTopBar = (props) => {
	const {
		id,
		type,
		permissions,
		uploadClick,
		addFolderClick,
		newPolicyClick,
		permanentlyDeleteClick,
		restoreClick,
		handleSearch,
		width,
		disableUpload,
		isRecycleBin,
		landingPage,
		filterClick,
		filtered,
		disableFilter,
		enableRecycleTopBar,
		onClearSearchClick,
	} = props;
	const isMobile = useWidthDown("sm");
	const { t } = useTranslation("documents");
	const [searchFocused, setSearchFocused] = useState(false);
	const [searchKeywords, setSearchKeywords] = useState("");
	const dispatch = useDispatch();
	const recycleBin = type === FOLDER_TYPE_RECYCLE_BIN;

	const searchFocus = () => setSearchFocused(true);

	const searchBlur = () => setSearchFocused(false);

	const handleSearchChange = (e) => {
		const { value } = e.target;
		setSearchKeywords(value);
	};

	const handleSearchKeyDown = (e) => {
		if (e.key === "Enter") {
			const { value } = e.target;
			handleSearch(value);
		}
	};

	// Display tools
	useEffect(() => {
		if (!isMobile && searchFocused) {
			setSearchFocused(false);
		}
		if (permissions) {
			dispatch(
				updateToolbar({
					display: true,
					left: {
						tools: [
							{
								id: "upload",
								icon: "upload",
								label: !isMobile ? t("app:buttons.upload") : undefined,
								tooltipText: "Upload a policy",
								onClick: uploadClick,
								dataCy: "upload",
								hidden: !permissions.canUpdate || recycleBin || searchFocused || landingPage,
							},
							{
								id: "add-folder",
								icon: "add-folder",
								label: "NEW SECTION",
								tooltipText: "Add new Section",
								onClick: addFolderClick,
								dataCy: "add-folder",
								hidden: !permissions.canUpdate || recycleBin || searchFocused || !landingPage,
							},
							{
								id: "add-policy",
								icon: "add-section",
								label: "NEW POLICY",
								tooltipText: "Add new policy",
								onClick: newPolicyClick,
								dataCy: "add-policy",
								hidden: !permissions.canUpdate || recycleBin || searchFocused || landingPage,
							},
							{
								id: "permanently-delete",
								icon: "trash",
								label: !isMobile ? t("app:buttons.permanentlyDelete") : undefined,
								tooltipText: t("tooltips.permanentlyDelete"),
								onClick: permanentlyDeleteClick,
								dataCy: "permanently-delete",
								hidden: !permissions.canPermanentlyDelete || !recycleBin || searchFocused,
								disabled: !enableRecycleTopBar,
							},
							{
								id: "restore",
								icon: "redo",
								label: !isMobile ? t("app:buttons.restore") : undefined,
								tooltipText: t("tooltips.restore"),
								onClick: restoreClick,
								dataCy: "restore",
								hidden: !permissions.canDelete || !recycleBin || searchFocused,
								disabled: !enableRecycleTopBar,
							},
						],
					},
					right: {
						tools: [
							!recycleBin && {
								component: (
									<SearchInput
										key="toolbar-search"
										size="extra-small"
										fullWidth={isMobile && searchFocused}
										fullWidh={isMobile && searchFocused}
										containerFullWidth={isMobile && searchFocused}
										value={searchKeywords || ""}
										showClearIcon={searchKeywords && searchKeywords.length > 0}
										onFocus={isMobile ? searchFocus : undefined}
										onBlur={isMobile ? searchBlur : undefined}
										onKeyDown={handleSearchKeyDown}
										onChange={handleSearchChange}
										onIconClick={() => {
											handleSearch(searchKeywords);
										}}
										onClearClick={onClearSearchClick}
									/>
								),
							},
							{
								id: "openFilter",
								icon: "filter",
								tooltipText: t("meetings:tooltips.openFilter"),
								ariaLabel: t("meetings:buttons.showHideFilter"),
								onClick: filterClick,
								dataCy: "openFilter",
								hidden: searchFocused || landingPage || isRecycleBin || !permissions.canUpdate,
								disabled: disableFilter,
							},
						],
					},
				}),
			);
		}
	}, [id, type, permissions, isMobile, searchFocused, searchKeywords, landingPage, isRecycleBin, enableRecycleTopBar, disableFilter]);

	return null;
};

export default PolicyTopBar;
