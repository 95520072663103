import { whiteColor, successColor, infoColor, errorColor, warningColor, blackColor, defaultFont, primaryColor } from '../shared';

const notificationStyle = () => ({
	root: {
		margin: '8px',
		pointerEvents: 'all',
	},
	standardSuccess: {
		backgroundColor: whiteColor,
		borderLeft: `6px solid ${successColor}`,
		color: blackColor[1],
	},
	standardInfo: {
		backgroundColor: whiteColor,
		borderLeft: `6px solid ${infoColor}`,
		color: blackColor[1],
	},
	standardError: {
		backgroundColor: whiteColor,
		borderLeft: `6px solid ${errorColor}`,
		color: blackColor[1],
	},
	standardWarning: {
		backgroundColor: whiteColor,
		borderLeft: `6px solid ${warningColor}`,
		color: blackColor[1],
	},
	message: {
		...defaultFont,
		fontWeight: '400',
		fontSize: '16px',
		lineHeight: '20px',
		letterSpacing: '0px',
        color: blackColor,
		'&> svg': {
			marginRight: '16px',
			position: 'relative',
			top: '2px',
		},
	},
	action: {
		...defaultFont,
		paddingBottom: '2px',
		fontSize: '16px',
		lineHeight: '20px',
		letterSpacing: '0px',
		color: blackColor[1],
		'&:hover': {
			color: blackColor[1],
			textDecoration: 'none',
		},
	},
    actionsLabels: {
        height: '14px',
        color: primaryColor[1],
        fontWeight: '700',
        lineheight: '14px',
        letterSpacing: '0px',
        textAlign: 'left',
        fontSize: '12px',
        lineHeight: '14px',
    },
});
export default notificationStyle;
