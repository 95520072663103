import React from "react";
import GenericAvatar, { GENERIC_AVATAR_BACKGROUNDS } from "components/Avatar/GenericAvatar";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import { Checkbox, FormControlLabel } from "@mui/material";
import { Check } from "components/Icons";

function GroupMember(props) {
	const { id, name, email, avatar, avatarNumber, showSelect, handleCheckboxChange, selectedUsers, firstName, lastName } = props;
	const initial = String(name).charAt(0);
	const pic = avatar ? (
		<GenericAvatar imageUrl={avatar} name={name} backgroundNumber={((avatarNumber || 0) % GENERIC_AVATAR_BACKGROUNDS) + 1} />
	) : (
		<GenericAvatar
			name={name}
			initials={`${(firstName || "").substr(0, 1)}${(lastName || "").substr(0, 1)}`}
			backgroundNumber={((avatarNumber || 0) % GENERIC_AVATAR_BACKGROUNDS) + 1}
		/>
	);
	return (
		<ListItem key={`${name}-${email}`} alignItems="flex-start" classes={{ root: "list-item-root" }} data-cy="member-list-member">
			<ListItemAvatar width={32} height={32}>
				{pic}
			</ListItemAvatar>
			<label htmlFor={`group-member-${id}`}>
				<ListItemText primary={name} secondary={email} />
			</label>
			{showSelect && (
				<FormControlLabel
					control={
						<Checkbox
							key={`select-member-${id}`}
							checkedIcon={<Check fontSize="small" color="primary" />}
							inputProps={{
								id: `group-member-${id}`,
								"aria-label": `Select ${email}`,
							}}
							checked={selectedUsers.indexOf(id) >= 0}
							onChange={() => {
								handleCheckboxChange(id);
							}}
							data-cy={`select-user-${id}`}
						/>
					}
				/>
			)}
		</ListItem>
	);
}

export default GroupMember;
