import React, { useState, useRef } from "react";

const DiffCustomAnnotation = () => {
	const [customAnnotationsDiff, setcustomAnnotationsDiff] = useState([]);

	const setUnderlineCustomAnnotation = (instance, pageNumber, quads, color) => {
		const { Annotations } = instance.current.Core;
		const underlineAnnotation = new Annotations.TextUnderlineAnnotation();
		underlineAnnotation.PageNumber = pageNumber;
		underlineAnnotation.Quads = quads;
		underlineAnnotation.StrokeColor = new Annotations.Color(...color);
		return underlineAnnotation;
	};

	const setStrikeoutCustomAnnotation = (instance, pageNumber, quads, color) => {
		const { Annotations } = instance.current.Core;
		const strikeoutAnnotation = new Annotations.TextStrikeoutAnnotation();
		strikeoutAnnotation.PageNumber = pageNumber;
		strikeoutAnnotation.Quads = quads;
		strikeoutAnnotation.StrokeColor = new Annotations.Color(...color);
		return strikeoutAnnotation;
	};

	const displayDiffCustomAnnotations = async (instance, doc1Annotations, doc2Annotations) => {
		const { UI, Core } = instance.current;
		const [documentViewer1, documentViewer2] = instance.current.Core.getDocumentViewers();
		const annotationManager1 = documentViewer1.getAnnotationManager();
		const annotationManager2 = documentViewer2.getAnnotationManager();

		for (const diffAnnot of doc1Annotations) {
			const strikeoutAnnotation = setUnderlineCustomAnnotation(instance, diffAnnot.PageNumber, diffAnnot.Quads, [0, 0, 0]);
			annotationManager1.addAnnotation(strikeoutAnnotation);
			annotationManager1.redrawAnnotation(strikeoutAnnotation);
			customAnnotationsDiff.push(strikeoutAnnotation);
		}

		for (const diffAnnot of doc2Annotations) {
			const underlineAnnotation = setStrikeoutCustomAnnotation(instance, diffAnnot.PageNumber, diffAnnot.Quads, [0, 0, 0]);
			annotationManager2.addAnnotation(underlineAnnotation);
			annotationManager2.redrawAnnotation(underlineAnnotation);
			customAnnotationsDiff.push(underlineAnnotation);
		}
	};

	const removeDiffCustomAnnotations = (documentViewer) => {
		const annotationManager1 = documentViewer.getAnnotationManager();
		if (customAnnotationsDiff.length > 0) {
			annotationManager1.deleteAnnotations(customAnnotationsDiff);
		}
	};

	return {
		displayDiffCustomAnnotations,
		removeDiffCustomAnnotations,
	};
};

export default DiffCustomAnnotation;
