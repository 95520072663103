export const GET_POLICIES_PENDING = "GET_POLICIES_PENDING";
export const GET_POLICIES_FULFILLED = "GET_POLICIES_FULFILLED";
export const GET_PERMISSIONS_PENDING = "GET_PERMISSIONS_PENDING";
export const GET_PERMISSIONS_FULFILLED = "GET_PERMISSIONS_FULFILLED";
export const ADD_FOLDER_PENDING = "ADD_FOLDER_PENDING";
export const ADD_FOLDER_FULFILLED = "ADD_FOLDER_FULFILLED";
export const GET_PATH_PENDING = "GET_PATH_PENDING";
export const GET_PATH_FULFILLED = "GET_PATH_FULFILLED";
export const GET_LANDING_PAGE = "GET_LANDING_PAGE";
export const DOWNLOAD_POLICY_PENDING = "DOWNLOAD_POLICY_PENDING";
export const DOWNLOAD_POLICY_FULFILLED = "DOWNLOAD_POLICY_FULFILLED";
export const POLICY_LOAD_HISTORY_PENDING = "POLICY_LOAD_HISTORY_PENDING";
export const POLICY_LOAD_HISTORY_FULFILLED = "POLICY_LOAD_HISTORY_FULFILLED";
export const POLICY_PUBLISH_PENDING = "POLICY_PUBLISH_PENDING";
export const POLICY_PUBLISH_FULFILLED = "POLICY_PUBLISH_FULFILLED";
export const POLICY_UNPUBLISH_PENDING = "POLICY_PUBLISH_PENDING";
export const POLICY_UNPUBLISH_FULFILLED = "POLICY_PUBLISH_FULFILLED";
export const GET_PUBLISHED_PERMISSIONS = "GET_PUBLISHED_PERMISSIONS";
export const GET_MULTIPLE_POLICY_ATTACHMENTS_FULFILLED = "GET_MULTIPLE_POLICY_ATTACHMENTS_FULFILLED";
export const GET_ATTACHMENTS_MINUTES_FULFILLED = "GET_ATTACHMENTS_MINUTES_FULFILLED";
export const SET_RELOAD_PAGE_AFTER_WOPI_EDIT = "SET_RELOAD_PAGE_AFTER_WOPI_EDIT";
