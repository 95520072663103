import React, { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import request from "superagent";
import { v4 as uuid } from "uuid";

import makeStyles from "@mui/styles/makeStyles";

import Icon from "atlas/components/Icon/Icon";
import ProgressBar from "atlas/components/Progress/ProgressBar";
import GenericDialog from "atlas/components/Dialogs/GenericDialog";
import { API_HOST } from "config/env";
import telemetryAddEvent from "utils/telemetryAddEvent";

const useStyles = makeStyles(() => ({
	progressBar: {
		width: "240px",
		margin: "24px auto",
	},
}));

const UploadDialog = (props) => {
	const {
		show = true,
		replace = false,
		id = 0,
		type,
		files,
		invalidFiles = [],
		onClose,
		afterUploadFile,
		showSignIn,
		telemetryPage,
	} = props;
	const { t } = useTranslation("documents");
	const [uploadProgress, setUploadProgress] = useState(0);
	const [uploadIndex, setUploadIndex] = useState(0);
	const [uploading, setUploading] = useState(true);
	const [failedUploads, setFailedUploads] = useState([...invalidFiles]);
	const classes = useStyles();

	const finishUpload = useCallback(() => {
		setUploadProgress(0);
		setUploading(false);
		setUploadIndex(null);

		if (failedUploads.length === files.length) {
			// Show the failed uploads immediately
			onClose(failedUploads);
		} else {
			setTimeout(() => onClose(failedUploads), 3000);
		}
	}, [failedUploads]);

	const nextUpload = (index) => {
		setUploadProgress(0);
		setUploadIndex(index + 1);
	};

	const uploadFile = (index) => {
		if (index !== null && index >= 0 && index < files.length) {
			const selectedFile = files[index];

			files[index] = {
				...selectedFile,
				uploading: true,
			};

			const fileData = new FormData();
			fileData.append(uuid(), selectedFile.file);
			fileData.append(
				"data",
				JSON.stringify({
					path: selectedFile.path,
					allowDuplicates: false,
				}),
			);

			const uploadRequest = replace
				? request.patch(`${API_HOST}/api/document/${id}/detail`)
				: request.post(`${API_HOST}/api/document/${id}/upload`).query({ type });
			uploadRequest
				.withCredentials()
				.send(fileData)
				.on("progress", (e) => {
					if (e.lengthComputable) {
						setUploadProgress(e.loaded / e.total);
					}
				})
				.then((res) => {
					(replace ? [res.body] : res.body.uploaded || []).forEach((document) => {
						afterUploadFile(document);

						telemetryAddEvent(`${telemetryPage} - Library - ${replace ? "Replaced" : "Uploaded"} document`, { area: "documents" });
					});
					nextUpload(index);
				})
				.catch((err) => {
					if (err.status === 400) {
						setFailedUploads((prev) => {
							prev.push(files[index]);

							return [...prev];
						});
						nextUpload(index);
					} else {
						showSignIn(err, () => {
							uploadFile(index);
						});
					}
				});
		} else {
			// Upload complete
			finishUpload();
			replace ? window.location.reload() : null;
		}
	};

	const handleCancel = () => {
		onClose();
	};

	useEffect(() => {
		uploadFile(uploadIndex);
	}, [uploadIndex]);

	const i18n = t("uploadDialog", { title: "" });

	const dialog = {
		title: i18n.title,
		secondaryTitle: t(`app:buttons.${uploading ? "cancel" : "close"}`),
		secondaryAction: handleCancel,
	};

	return (
		<GenericDialog
			show={show}
			title={dialog.title}
			secondaryAction={dialog.secondaryAction}
			secondaryTitle={dialog.secondaryTitle}
			clickAwayDisabled={uploading}
			closeIcon={<Icon name="close" />}
		>
			<ProgressBar
				className={classes.progressBar}
				label={
					uploading && uploadIndex !== null && uploadIndex >= 0 && uploadIndex < files.length
						? t("uploadDialog.uploading", { title: files[uploadIndex].name })
						: t("uploadDialog.uploadComplete")
				}
				progress={(uploading && uploadIndex !== null ? (uploadIndex + uploadProgress * 0.5) / files.length : 1) * 100}
				maxLabelWidthPercent={100}
			/>
		</GenericDialog>
	);
};

export default UploadDialog;
