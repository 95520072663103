import React, { useState, useCallback, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";

import makeStyles from "@mui/styles/makeStyles";
import { Button, Checkbox, FormControlLabel, InputAdornment, MenuItem } from "@mui/material";

import inputStyle from "atlas/assets/jss/components/inputStyle";
import typographyStyle from "atlas/assets/jss/components/typographyStyle";
import { blackColor, grayColor } from "atlas/assets/jss/shared";
import AccessibleIconButton from "atlas/components/Buttons/AccessibleIconButton";
import OutlinedInput from "atlas/components/FormControls/OutlinedInput";
import SelectInput from "atlas/components/FormControls/SelectInput";
import Icon from "atlas/components/Icon/Icon";
import {
	setMeetingTemplateVotingAndRollCall,
	setMinutesIncludesMinutesAttachment,
	setShowClosedItemTitle,
	setAlwaysUseRecordedVote,
	setPublishedDraftMinutes,
	setUseDigitalVoting,
} from "redux/meetingTemplate/actions";

import telemetryAddEvent from "utils/telemetryAddEvent";
import { useUpdateObject } from "utils/updateObject";
import RollCallDeleteDialog from "./RollCallDeleteDialog";
import { Close, Check } from "components/Icons";

import GenericDialog from "atlas/components/Dialogs/GenericDialog";
import PublishSettings from "./PublishSettings";
import Signatures from "./Signatures";

const useInputStyles = makeStyles(inputStyle);
const useStyles = makeStyles(() => ({
	popup: {
		"& .MuiDialog-paper": {
			position: "absolute",
			top: "0px",
			right: "0px",
			margin: "0px",
			minHeight: "100vh",
			width: "580px",
		},
		"& .MuiTypography-h3": {
			fontSize: "22px",
			fontWeight: "600",
		},
		"& .MuiDialogContent-root": {
			padding: "0px",
			overflowX: "hidden",
		},
		"& .MuiFormControlLabel-root": {
			margin: "20px 32px 40px 24px",
		},
		"& .MuiFormControlLabel-label": {
			fontSize: "16px",
			fontWeight: "400",
		},
	},
	options: {
		"& > div": {
			padding: "16px",
			"&:not(:first-child)": {
				borderTop: `solid 1px ${grayColor[4]}`,
			},
		},
	},
	titleContainer: {
		display: "flex",
		alignItems: "center",
	},
	title: {
		...typographyStyle.drawerTitle,
		color: blackColor[1],
		flexGrow: "1",
	},
	closeButton: {
		margin: "-12px",
	},
	fieldColumns: {
		display: "flex",
		alignItems: "center",
		"& > div": {
			width: "calc(50% - 16px)",
			"&:first-child": {
				marginRight: "16px",
			},
			"&:last-child:not(:first-child)": {
				marginLeft: "16px",
			},
		},
	},
	rollCallSection: {
		display: "flex",
		alignItems: "center",
		"& > div:first-child": {
			flexGrow: "1",
		},
	},
	externalLink: {
		"& a": {
			display: "flex",
			alignItems: "center",
			textDecoration: "none",
			"&:hover": {
				textDecoration: "underline",
			},
			"& > :not(:first-child)": {
				marginLeft: "8px",
			},
		},
	},
	rollCall: {
		border: `solid 1px ${grayColor[4]}`,
		borderRadius: "4px",
		marginTop: "16px",
	},
	rollCallHeader: {
		display: "flex",
		alignItems: "center",
		padding: "0 8px",
	},
	rollCallName: {
		flexGrow: "1",
	},
	rollCallDelete: {
		width: "48px",
	},
	rollCallBody: {
		borderTop: `solid 1px ${grayColor[4]}`,
		padding: "8px",
	},
	rollCallWeightedVotingUserList: {
		listStyleType: "none",
		margin: "4px 0",
		padding: "0",
	},
	rollCallWeightedVotingUser: {
		display: "flex",
		alignItems: "center",
		"& > div": {
			width: "calc(50% - 16px)",
			"&:first-child": {
				marginRight: "16px",
			},
			"&:last-child:not(:first-child)": {
				marginLeft: "16px",
			},
		},
	},
	rollCallWeightedVotingUserWeight: {
		width: "60px",
		margin: "4px 0",
	},
}));

const quorumOptions = {
	majority: 0,
	twoThirds: 1,
	percent: 2,
	memberCount: 3,
};

const MeetingTemplateMinutesOptionsPanel = (props) => {
	const { boards, handleClose, telemetryPage } = props;
	const { t } = useTranslation("meetings");
	const [dialogs, setDialogs] = useState({});
	const nextId = useRef(-1);
	const dispatch = useDispatch();
	const {
		meetingTemplate,
		minutes: {
			votingAndRollCall,
			includeMinutesAttachments,
			showClosedItemTitle,
			alwaysUseRecordedVote,
			useDigitalVoting,
			onPublishShowDraftMinutes,
			signatures,
		} = {},
	} = useSelector((state) => state.meetingTemplatesReducer);
	const inputClasses = useInputStyles({ fullWidth: true });
	const classes = useStyles();

	const updateMinutesAttachment = () => {
		dispatch(setMinutesIncludesMinutesAttachment(!includeMinutesAttachments));
		telemetryAddEvent(`Minutes Settings - Include minutes attachments in PDF?- changed`);
	};

	const updateShowClosedItemTitle = () => {
		dispatch(setShowClosedItemTitle(!showClosedItemTitle));
		telemetryAddEvent(`Minutes Settings - Show member only item titles on public minute documents?- changed`);
	};

	const updateAlwaysUseRecordedVote = () => {
		dispatch(setAlwaysUseRecordedVote(!alwaysUseRecordedVote));
		telemetryAddEvent(`Minutes Settings - Always record votes, this will set all voting results in minutes?- changed`);
	};

	const updateIsPublishDraftMinutes = () => {
		dispatch(setPublishedDraftMinutes(!onPublishShowDraftMinutes));
		telemetryAddEvent(`Minutes Settings - On publish, show Draft minutes publicly?- changed`);
	};

	const updateUseDigitalVoting = () => {
		dispatch(setUseDigitalVoting(!useDigitalVoting));
		telemetryAddEvent(`Minutes Settings - Use digital voting?- changed`);
	};

	const closeDialogs = () => setDialogs({});

	const handleDelete = (rollCall) => setDialogs({ delete: rollCall });

	const deleteRollCall = (rollCall) => () => {
		updateMeetingTemplateVotingAndRollCall({ target: { checked: true } }, "deleted", true, false, rollCall);
		closeDialogs();
	};

	const handleAddRollCall = useCallback(() => {
		const newRollCall = {
			id: nextId.current,
			name: "",
			default: false,
			quorumNeeded: 0,
			quorumValue: 0,
			useWeightedVoting: false,
			abstainCountAs: 0,
			users: votingAndRollCall.rollCalls.length > 0 ? votingAndRollCall.rollCalls[0].users.map((user) => ({ ...user, weighting: 1 })) : [],
		};

		updateMeetingTemplateVotingAndRollCall(
			{ target: { value: newRollCall } },
			"rollCalls",
			false,
			false,
			undefined,
			(newValue, oldValue) => {
				oldValue.push(newValue);

				return [...oldValue];
			},
		);

		nextId.current--;
	}, [votingAndRollCall]);

	const updateMeetingTemplateVotingAndRollCall = useUpdateObject(
		(votingAndRollCall) => dispatch(setMeetingTemplateVotingAndRollCall(votingAndRollCall)),
		undefined,
		undefined,
		() => {
			telemetryAddEvent(`${telemetryPage} - Minutes Edit Voting`);
		},
	);

	const getQuorumOptions = () => {
		const options = [
			{
				value: quorumOptions.majority,
				text: t("templateDetail.options.majority"),
				dataCy: "option-majority",
			},
			{
				value: quorumOptions.twoThirds,
				text: t("templateDetail.options.twoThirdsMajority"),
				dataCy: "option-two-thirds",
			},
			{
				value: quorumOptions.percent,
				text: t("templateDetail.options.percent"),
				dataCy: "option-percent",
			},
			{
				value: quorumOptions.memberCount,
				text: t("templateDetail.options.memberCount"),
				dataCy: "option-member-count",
			},
		];

		return options.map((option) => (
			<MenuItem key={option.value} value={option.value} data-cy={option.dataCy}>
				<div>{option.text}</div>
			</MenuItem>
		));
	};

	const getMemberCount = (votingAndRollCall) => {
		// Get current roll call users or default users
		const users = votingAndRollCall.users
			? votingAndRollCall.users
			: votingAndRollCall.rollCalls.length > 0
				? votingAndRollCall.rollCalls[0].users
				: [];
		const votingMembers = users.filter((user) => user.votingMember).length;

		let count = 0;
		let quorumValue = parseInt(votingAndRollCall.quorumValue, 10);
		if (isNaN(quorumValue)) {
			quorumValue = 0;
		}
		switch (votingAndRollCall.quorumNeeded) {
			case quorumOptions.majority:
				count = Math.floor(votingMembers / 2 + 1); // 50% + 1
				break;

			case quorumOptions.twoThirds:
				count = Math.ceil(votingMembers * (2 / 3)); // 2/3
				break;

			case quorumOptions.percent:
				count = Math.ceil(votingMembers * (quorumValue / 100)); // Percent
				break;

			case quorumOptions.memberCount:
				count = quorumValue; // Member count
				break;
		}

		return count;
	};

	return (
		<>
			<GenericDialog
				className={classes.popup}
				show={true}
				title={t("templateDetail.labels.minutesSettings")}
				secondaryAction={handleClose}
				closeIcon={<Close />}
				fullWidth
			>
				<div className={classes.options}>
					<>
						<PublishSettings
							includeMinutesAttachments={includeMinutesAttachments}
							showClosedItemTitle={showClosedItemTitle}
							alwaysUseRecordedVote={alwaysUseRecordedVote}
							useDigitalVoting={useDigitalVoting}
							updateMinutesAttachment={updateMinutesAttachment}
							updateShowClosedItemTitle={updateShowClosedItemTitle}
							updateAlwaysUseRecordedVote={updateAlwaysUseRecordedVote}
							updateIsPublishDraftMinutes={updateIsPublishDraftMinutes}
							updateUseDigitalVoting={updateUseDigitalVoting}
							onPublishShowDraftMinutes={onPublishShowDraftMinutes}
						/>
						<Signatures signatures={signatures} dispatch={dispatch} />
					</>

					<div>
						<div>
							<label id="quorum-needed-label" for="quorum-needed">
								{t("templateDetail.labels.quorumNeeded")}
							</label>
						</div>
						<div className={classes.fieldColumns}>
							<div>
								<SelectInput
									id="quorum-needed"
									className={inputClasses.smallInput}
									noDefaultClassName
									fullWidth
									size="small"
									value={votingAndRollCall.quorumNeeded}
									onChange={(e) => updateMeetingTemplateVotingAndRollCall(e, "quorumNeeded", false, true)}
									data-cy="quorum-needed"
								>
									{getQuorumOptions()}
								</SelectInput>
							</div>
							<div data-cy="member-count">{t("templateDetail.labels.memberCount", { count: getMemberCount(votingAndRollCall) })}</div>
						</div>
						{[quorumOptions.percent, quorumOptions.memberCount].includes(votingAndRollCall.quorumNeeded) ? (
							<div className={classes.fieldColumns}>
								<div>
									<OutlinedInput
										id="quorum-value"
										className={inputClasses.smallInput}
										noDefaultClassName
										fullWidth
										size="small"
										value={votingAndRollCall.quorumValue}
										onChange={(e) => updateMeetingTemplateVotingAndRollCall(e, "quorumValue")}
										InputProps={{
											startAdornment:
												votingAndRollCall.quorumNeeded === quorumOptions.memberCount ? (
													<InputAdornment position="start">#</InputAdornment>
												) : undefined,
											endAdornment:
												votingAndRollCall.quorumNeeded === quorumOptions.percent ? (
													<InputAdornment position="end">%</InputAdornment>
												) : undefined,
										}}
										inputProps={{
											"aria-label": t(
												`templateDetail.labels.quorumValue${
													votingAndRollCall.quorumNeeded === quorumOptions.percent ? "Percent" : "MemberCount"
												}`,
											),
										}}
										data-cy="quorum-value"
									/>
								</div>
							</div>
						) : null}
					</div>
					<div>
						<div className={classes.rollCallSection}>
							<div>{t("templateDetail.labels.voteType")}</div>
							<div className={classes.externalLink}>
								<a href={`/home/boards/edit/${meetingTemplate.boardId}`} target="_blank">
									<Icon name="external-link" size="18px" color={grayColor[1]} data-cy="externalLink" />
									<span>{boards.find((board) => board.id === meetingTemplate.boardId)?.name}</span>
								</a>
							</div>
						</div>
						{votingAndRollCall.rollCalls
							.filter((rollCall) => !rollCall.deleted)
							.map((rollCall) => (
								<div key={rollCall.id} className={classes.rollCall}>
									<div className={classes.rollCallHeader}>
										<div className={classes.rollCallName}>
											<OutlinedInput
												id={`roll-call-name-${rollCall.id}`}
												className={inputClasses.smallInput}
												noDefaultClassName
												fullWidth
												size="small"
												label={t("templateDetail.labels.rollCallName")}
												value={rollCall.name || (rollCall.default ? t("templateDetail.labels.default") : "")}
												onChange={(e) => updateMeetingTemplateVotingAndRollCall(e, "name", false, false, rollCall)}
												data-cy="roll-call-name"
											/>
										</div>
										<div className={classes.rollCallDelete}>
											{!rollCall.default ? (
												<AccessibleIconButton
													id={`delete-roll-call-${rollCall.id}`}
													iconName="remove"
													tooltipText={t("templateDetail.tooltips.deleteRollCall")}
													aria-label={t("templateDetail.tooltips.deleteRollCall")}
													onClick={() => handleDelete(rollCall)}
													dataCy="delete-roll-call"
												/>
											) : null}
										</div>
									</div>
									<div className={classes.rollCallBody}>
										<div>
											<SelectInput
												id={`roll-call-type-${rollCall.id}`}
												className={inputClasses.smallInput}
												noDefaultClassName
												fullWidth
												size="small"
												label={t("templateDetail.labels.rollCallType")}
												value={rollCall.quorumNeeded}
												onChange={(e) => updateMeetingTemplateVotingAndRollCall(e, "quorumNeeded", false, true, rollCall)}
												data-cy="roll-call-type"
											>
												{getQuorumOptions()}
											</SelectInput>
										</div>
										{[quorumOptions.percent, quorumOptions.memberCount].includes(rollCall.quorumNeeded) ? (
											<div className={classes.fieldColumns}>
												<div>
													<OutlinedInput
														id={`roll-call-quorum-value-${rollCall.id}`}
														className={inputClasses.smallInput}
														noDefaultClassName
														fullWidth
														size="small"
														value={rollCall.quorumValue}
														onChange={(e) => updateMeetingTemplateVotingAndRollCall(e, "quorumValue", false, false, rollCall)}
														InputProps={{
															startAdornment:
																rollCall.quorumNeeded === quorumOptions.memberCount ? (
																	<InputAdornment position="start">#</InputAdornment>
																) : undefined,
															endAdornment:
																rollCall.quorumNeeded === quorumOptions.percent ? (
																	<InputAdornment position="end">%</InputAdornment>
																) : undefined,
														}}
														inputProps={{
															"aria-label": t(
																`templateDetail.labels.quorumValue${
																	rollCall.quorumNeeded === quorumOptions.percent ? "Percent" : "MemberCount"
																}`,
															),
														}}
														data-cy="roll-call-quorum-value"
													/>
												</div>
											</div>
										) : null}
										<div data-cy="roll-call-member-count">
											{t("templateDetail.labels.memberCount", { count: getMemberCount(rollCall) })}
										</div>
										<div>
											<FormControlLabel
												control={
													<Checkbox
														checkedIcon={<Check fontSize="small" color="primary" />}
														checked={rollCall.useWeightedVoting}
														onChange={(e) => updateMeetingTemplateVotingAndRollCall(e, "useWeightedVoting", true, false, rollCall)}
													/>
												}
												label={t("templateDetail.labels.useWeightedVoting")}
												data-cy="roll-call-use-weighted-voting"
											/>
										</div>
										{rollCall.useWeightedVoting ? (
											<div>
												<ul className={classes.rollCallWeightedVotingUserList}>
													{rollCall.users
														.filter((user) => user.votingMember)
														.map((user) => (
															<li className={classes.rollCallWeightedVotingUser}>
																<div>{user.name}</div>
																<div>
																	<OutlinedInput
																		id={`roll-call-weighting-${rollCall.id}-${user.userId}`}
																		className={classes.rollCallWeightedVotingUserWeight}
																		noDefaultClassName
																		size="small"
																		value={user.weighting}
																		onChange={(e) => updateMeetingTemplateVotingAndRollCall(e, "weighting", false, false, user)}
																		inputProps={{
																			"aria-label": t("templateDetail.labels.userWeighting"),
																		}}
																		data-cy="roll-call-weighting"
																	/>
																</div>
															</li>
														))}
												</ul>
											</div>
										) : null}
									</div>
								</div>
							))}
						<div>
							<Button primary variant="outlined" onClick={handleAddRollCall} data-cy="add-roll-call">
								{t("templateDetail.buttons.addRollCall")}
							</Button>
						</div>
					</div>
				</div>
				{dialogs.delete ? (
					<RollCallDeleteDialog rollCall={dialogs.delete} onClose={closeDialogs} handleDelete={deleteRollCall(dialogs.delete)} />
				) : null}
			</GenericDialog>
		</>
	);
};

export default MeetingTemplateMinutesOptionsPanel;
