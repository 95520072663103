import React from "react";
import SvgIcon from "@mui/material/SvgIcon";

const icon = (props) => {
	const { color } = props.style;
	return (
		<SvgIcon width="16px" height="16px" viewBox="0 0 16 16" {...props}>
			<g id="Artboard" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<path
					d="M0.750967658,15.5 C0.193431143,15.5 -0.169190645,14.9499365 0.0801472652,14.4824279 L7.33014727,0.888677941 C7.60654047,0.370440686 8.39539485,0.370440686 8.67178805,0.888677941 L15.9217881,14.4824279 C16.171126,14.9499365 15.8085042,15.5 15.2509677,15.5 L0.750967658,15.5 Z M7.25096766,6.125 L7.25096766,10.8125 L8.75096766,10.8125 L8.75096766,6.125 L7.25096766,6.125 Z M8.00096766,13.15625 C8.41518122,13.15625 8.75096766,12.8414502 8.75096766,12.453125 C8.75096766,12.0647998 8.41518122,11.75 8.00096766,11.75 C7.5867541,11.75 7.25096766,12.0647998 7.25096766,12.453125 C7.25096766,12.8414502 7.5867541,13.15625 8.00096766,13.15625 Z"
					id="warning"
					fill={color || "#2B3545"}
				/>
			</g>
		</SvgIcon>
	);
};

export default icon;
