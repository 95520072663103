import Editor from "@ckeditor/ckeditor5-core/src/editor/editor";
import DataApiMixin from "@ckeditor/ckeditor5-core/src/editor/utils/dataapimixin";
import getDataFromElement from "@ckeditor/ckeditor5-utils/src/dom/getdatafromelement";
import setDataInElement from "@ckeditor/ckeditor5-utils/src/dom/setdatainelement";
import mix from "@ckeditor/ckeditor5-utils/src/mix";
import MultirootEditorUI from "./MultirootEditorUI";
import MultirootEditorUIView from "./MultirootEditorUIView";

/**
 * The multi-root editor implementation. It provides inline editables and a single toolbar.
 *
 * Unlike other editors, the toolbar is not rendered automatically and needs to be attached to the DOM manually.
 *
 * This type of an editor is dedicated to integrations which require a customized UI with an open
 * structure, allowing developers to specify the exact location of the interface.
 *
 * @mixes module:core/editor/utils/dataapimixin~DataApiMixin
 * @implements module:core/editor/editorwithui~EditorWithUI
 * @extends module:core/editor/editor~Editor
 */
class MultirootEditor extends Editor {
	/**
	 * Creates an instance of the multi-root editor.
	 *
	 * **Note:** Do not use the constructor to create editor instances. Use the static `MultirootEditor.create()` method instead.
	 *
	 * @protected
	 * @param {Object.<String,HTMLElement>} sourceElements The list of DOM elements that will be the source
	 * for the created editor (on which the editor will be initialized).
	 * @param {module:core/editor/editorconfig~EditorConfig} config The editor configuration.
	 */
	constructor(sourceElements, config) {
		super(config);

		// Create root and UIView element for each editable container.
		for (const rootName of Object.keys(sourceElements)) {
			this.model.document.createRoot("$root", rootName);
		}

		this.ui = new MultirootEditorUI(this, new MultirootEditorUIView(this.locale, this.editing.view, sourceElements));
	}

	getFieldData() {
		const data = [];
		const fieldNames = this.model.document.getRootNames();
		fieldNames.forEach((fieldName) => {
			const fieldData = this.getData({ rootName: fieldName });
			data.push({ fieldName: fieldName, fieldData: fieldData });
		});
		return data;
	}

	setFieldData(fieldName, data) {
		const opt = {};
		opt[fieldName] = data;
		this.data.set(opt);
	}

	getEditableElements() {
		const elems = [];
		const editablesNames = Array.from(this.ui.getEditableElementsNames());

		for (const rootName of editablesNames) {
			elems.push(this.ui.getEditableElement(rootName));
		}
		return elems;
	}
	//
	// Checks to see if the root is already registered
	//
	hasRoot(name) {
		return this.ui.view.editables.findIndex((item) => item.name === name) < 0 ? false : true;
	}
	//
	// Dynamically adds a new root element to the editor
	//
	addRoot(name, domElement) {
		this.model.document.createRoot("$root", name);
		this.ui.view.addNewRoot(this.locale, this.editing.view, name, domElement);
		if (this.ui.focusTracker._elements.has(domElement)) {
			this.ui.focusTracker.remove(domElement);
		}
		this.ui.setEditableElement(name, domElement);
		if (!this.ui.focusTracker._elements.has(domElement)) {
			// Avoid adding an editable element if it is already tracked
			this.ui.focusTracker.add(domElement);
		}
		this.editing.view.attachDomRoot(domElement, name);
		const root = this.model.document.getRoot(name);
		this.model.change((writer) => {
			writer.setAttribute("data-added", name, root);
			writer.setSelection(root, "end");
		});
		this.ui.focusTracker.focusedElement = domElement;
	}

	/**
	 * @inheritDoc
	 */
	destroy() {
		// Cache the data and editable DOM elements, then destroy.
		// It's safe to assume that the model->view conversion will not work after super.destroy(),
		// same as `ui.getEditableElement()` method will not return editables.
		const data = {};
		const editables = {};
		const editablesNames = Array.from(this.ui.getEditableElementsNames());

		for (const rootName of editablesNames) {
			data[rootName] = this.getData({ rootName });
			editables[rootName] = this.ui.getEditableElement(rootName);
		}

		this.ui.destroy();

		return super.destroy().then(() => {
			for (const rootName of editablesNames) {
				setDataInElement(editables[rootName], data[rootName]);
			}
		});
	}

	/**
	 * Creates a multi-root editor instance.
	 *
	 * @param {Object.<String,HTMLElement>} sourceElements The list of DOM elements that will be the source
	 * for the created editor (on which the editor will be initialized).
	 * @param {module:core/editor/editorconfig~EditorConfig} config The editor configuration.
	 * @returns {Promise} A promise resolved once the editor is ready. The promise returns the created multi-root editor instance.
	 */
	static create(sourceElements, config) {
		return new Promise((resolve) => {
			const editor = new this(sourceElements, config);

			resolve(
				editor
					.initPlugins()
					.then(() => editor.ui.init())
					.then(() => {
						const initialData = {};

						// Create initial data object containing data from all roots.
						for (const rootName of Object.keys(sourceElements)) {
							initialData[rootName] = getDataFromElement(sourceElements[rootName]);
						}

						return editor.data.init(initialData);
					})
					.then(() => editor.fire("ready"))
					.then(() => editor),
			);
		});
	}
}

mix(MultirootEditor, DataApiMixin);
export default MultirootEditor;
