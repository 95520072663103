import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import clsx from "clsx";

import { Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import documentTreeViewStyle from "assets/jss/components/documentTreeViewStyle";
import { infoColor } from "atlas/assets/jss/shared";
import Icon from "atlas/components/Icon/Icon";
import CircularProgressIndicator from "atlas/components/Progress/CircularProgressIndicator";
import Tooltip from "atlas/components/Tooltip/Tooltip";
import getIconName from "views/Documents/utils/documentIcon";
import ellipsedTruncateText from "utils/ellipsedTruncateText";
import PolicyTree from "./PolicyTree";

const useStyles = makeStyles(documentTreeViewStyle);

const PolicyTreeNode = (props) => {
	const { policy, booksAndSectionsOnly, canSelect, selection, handleLoadChildren, hideDraft, setSelectedData } = props;
	const { t } = useTranslation("meetings");
	const [loadingChildren, setLoadingChildren] = useState(false);
	const [selected, setSelected] = useState(false);
	const [expanded, setExpanded] = useState(false);
	const classes = useStyles();

	const handleSelect = () => {
		policy.selected = !policy.selected;
		setSelected(policy.selected);
		setSelectedData(policy);
	};

	const toggleExpanded = () => {
		policy.expanded = !policy.expanded;
		setExpanded(policy.expanded);
		setLoadingChildren(false);
	};

	const handleExpand = () => {
		if (policy.folder) {
			if (typeof policy.children === "undefined") {
				setLoadingChildren(true);
				handleLoadChildren(policy).then(() => toggleExpanded());
			} else {
				toggleExpanded();
			}
		}
	};

	useEffect(() => {
		if (policy) {
			setExpanded(policy.expanded);
		}
	}, [policy]);

	useEffect(() => {
		setSelected(selection && selection === policy?.guid);
	}, [selection]);

	const expandProps = {};
	if (policy.folder && handleExpand) {
		expandProps.role = "button";
		expandProps.tabIndex = 0;
		expandProps["aria-expanded"] = expanded ? "true" : "false";
		expandProps["aria-label"] = t("documents:tooltips.expandCollapse");
		expandProps.onClick = handleExpand;
	}

	const selectClick = (typeof canSelect === "function" && canSelect(policy)) || !policy.folder ? handleSelect : undefined;
	const selectProps = {};
	if (selectClick) {
		selectProps.role = "checkbox";
		selectProps.tabIndex = 0;
		selectProps["aria-checked"] = selected ? "true" : "false";
		selectProps.onClick = selectClick;
	}

	const name = `${policy.code} ${policy.title}`.trim();
	const ellipsedText = ellipsedTruncateText(name);

	const getPathForPolicyTab = () => {
		if (hideDraft) {
			if (policy.publishedGuid) {
				return `/home/policydoc/${policy.publishedGuid}`;
			}
		}
		return `/home/policydoc/${policy.guid}`;
	};

	return policy ? (
		<li data-cy={`policy-${policy.guid}`} className={classes.listItems}>
			<div className={classes.listItem}>
				<div className={classes.expandIcon} {...expandProps}>
					{policy.folder && policy.hasChildren ? <Icon name={expanded ? "expand-down" : "expand-right"} size="16px" /> : null}
				</div>
				<div
					className={clsx({
						[classes.canSelect]: !policy.folder,
					})}
					title={name}
					{...selectProps}
				>
					<Icon name={getIconName(policy)} />
				</div>
				<div
					className={clsx(classes.title, {
						[classes.canSelect]: !policy.folder,
					})}
					{...selectProps}
				>
					{!policy.folder ? (
						<Tooltip title={name} disableHoverListener={!ellipsedText.isTruncated}>
							<Typography>{ellipsedText.text}</Typography>
						</Tooltip>
					) : (
						name
					)}
				</div>
				{!policy.folder && (
					<div>
						<a href={getPathForPolicyTab()} target="_blank" title={name}>
							<Icon name="external-link" size="24px" color={infoColor} />
						</a>
					</div>
				)}
				{selected && <div className={classes.selected} {...selectProps}></div>}
			</div>
			{policy.folder && expanded ? (
				policy.children.filter((child) => !hideDraft || child.publishedGuid || child.status === 3).length > 0 ? (
					<div className={classes.children}>
						<PolicyTree
							policies={policy.children}
							booksAndSectionsOnly={booksAndSectionsOnly}
							canSelect={canSelect}
							selection={selection}
							handleLoadChildren={handleLoadChildren}
							hideDraft={hideDraft}
							setSelectedData={setSelectedData}
						/>
					</div>
				) : (
					<div className={classes.noChildren}>
						{booksAndSectionsOnly
							? t("documents:copyMoveDocumentDialog.labels.noSectionsFound")
							: t("addPolicyToAgendaDialog.labels.noPoliciesFound")}
					</div>
				)
			) : loadingChildren ? (
				<CircularProgressIndicator size={38} minHeight="38px" />
			) : null}
		</li>
	) : null;
};

export default PolicyTreeNode;
