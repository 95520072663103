/* eslint-disable no-plusplus */
import React, { useState, useEffect, useRef, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import request from "superagent";
import { formatDate } from "utils/date";
import { isBefore, getHours, getMinutes, set, parseISO } from "date-fns";
import processHtml, { stripHtml } from "utils/processHtml";
import clsx from "clsx";
import forEach from "lodash/fp/forEach";

import { v4 as uuid } from "uuid";

import makeStyles from "@mui/styles/makeStyles";
import { Box, MenuItem, Typography, Checkbox, FormControlLabel, FormGroup } from "@mui/material";

import Icon from "atlas/components/Icon/Icon";
import SelectInput from "atlas/components/FormControls/SelectInput";
import { API_HOST } from "config/env";
import GenericDialog from "atlas/components/Dialogs/GenericDialog";
import ProgressBar from "atlas/components/Progress/ProgressBar";
import CircularProgressIndicator from "atlas/components/Progress/CircularProgressIndicator";
import GenericEditor from "components/Editor/GenericEditor";
import inputStyle from "atlas/assets/jss/components/inputStyle";
import { primaryColor } from "atlas/assets/jss/shared";
import ButtonWithTooltip from "atlas/components/Buttons/ButtonWithTooltip";
import UploadErrorDialog from "components/Dialogs/UploadErrorDialog";
import { updateHandlers, PROGRESS_HUB } from "utils/communication/SignalrClient";
import notifierMessage from "utils/notifierMessage";
import { setSnackbarOptions } from "redux/snackBar/actions";
import { displaySuccessNotification } from "views/Policy/utils/getNotification";
import { addPolicyToAgenda } from "redux/policyGovernance/actions";
import telemetryAddEvent from "utils/telemetryAddEvent";

const useInputStyles = makeStyles(inputStyle);

const useStyles = makeStyles(() => ({
	dialog: {
		"& .MuiDialog-paper": {
			width: "480px",
			maxWidth: "100%",
		},
	},
	centerStyle: {
		"& .MuiInputLabel-shrink": {
			top: "0px",
		},
		"& label": {
			position: "absolute",
			top: "-8px",
		},
	},
	menuItem: {
		maxWidth: (props) => (props.selectWidth > 0 ? `${props.selectWidth}px` : undefined),
		whiteSpace: "break-spaces",
	},
	progressBar: {
		width: "240px",
		margin: "0 auto",
		marginTop: "24px",
	},
	hidden: {
		display: "none",
	},
	createMeeting: {
		marginTop: "8px",
	},
}));

const AddToAgendaDialog = (props) => {
	const {
		meetingId,
		meetingGroupId,
		meetingDate,
		meetingStartTime,
		publicMinutesHtml,
		membersMinutesHtml,
		show,
		onClose,
		showSignIn,
		meetingGroups,
		fromLive,
		isPolicy = false,
		policyGuid,
		finishPolicyUrl,
	} = props;
	const { t } = useTranslation("meetings");
	const navigate = useNavigate();
	const [meetings, setMeetings] = useState(null);
	const [noMeetings, setNoMeetings] = useState(false);
	const [sections, setSections] = useState(null);
	const [noSections, setNoSections] = useState(false);
	const [items, setItems] = useState(null);
	const [agendaData, setAgendaData] = useState({
		meetingGroup: meetingGroupId,
		meeting: 0,
		section: 0,
		item: "",
		includeMemberDocument: false,
		includePublicDocument: false,
	});
	const [itemData, setItemData] = useState({
		name: "",
		text: "",
	});
	const [uploading, setUploading] = useState(false);
	const [failedUploads, setFailedUploads] = useState([]);
	const [dialogs, setDialogs] = useState({});
	const [adding, setAdding] = useState(false);
	const [selectWidth, setSelectWidth] = useState(0);
	const [progress, setProgress] = useState({
		label: " ",
		percent: 0,
	});
	const newItemGuid = useRef(null);
	const uploadQueue = useRef([]);
	const editors = useRef([]);

	const classes = useStyles({ selectWidth });
	const inputClasses = useInputStyles({ fullWidth: true });

	const dispatch = useDispatch();
	const appReducer = useSelector((state) => state.appReducer);
	const {
		signalR: { client, handler },
	} = appReducer;
	const updateProgress = (percentage, message) => {
		setProgress({
			label: message,
			percent: percentage,
		});
	};

	const currentMeetingDate = parseISO(meetingDate);
	const currentMeetingTime = parseISO(meetingStartTime);
	const currentMeetingDateTime = set(currentMeetingDate, {
		hours: getHours(currentMeetingTime),
		minutes: getMinutes(currentMeetingTime),
	});

	const getEditor = (name, closed) => {
		if (editors.current[closed ? `${name}Mo` : name]) {
			return editors.current[closed ? `${name}Mo` : name];
		}

		return null;
	};

	const handleError = (err) => {
		console.log("Error", err);
		onClose();
	};

	const handleMeetingGroupChange = () => {
		if (agendaData.meetingGroup > 0) {
			setAgendaData({
				...agendaData,
				meeting: 0,
				section: 0,
				item: "",
			});
			setMeetings(null);
			setSections(null);
			setItems(null);
			setNoMeetings(false);
			setNoSections(false);

			request
				.get(`${API_HOST}/api/meetings/byboard?boardId=${agendaData.meetingGroup}`)
				.withCredentials()
				.then((res) => {
					if (res.body && res.body.meetings && res.body.meetings.length) {
						const futureMeetings = res.body.meetings.reduce((list, current) => {
							if (current.date) {
								const date = parseISO(current.date);
								const time = parseISO(current.startTime || current.date);
								const dateTime = current.startTime
									? set(date, {
											hours: getHours(time),
											minutes: getMinutes(time),
									  })
									: date;

								if (isBefore(currentMeetingDateTime, dateTime)) {
									list.push(current);
								}
							}

							return list;
						}, []);
						setMeetings(futureMeetings);
						if (futureMeetings.length === 0) {
							setNoMeetings(true);
						}
						if (agendaData.meetingGroup === meetingGroupId) {
							// Select the next meeting
							setAgendaData((prev) => ({
								...prev,
								meeting: futureMeetings.reduce((found, current) => (current.startTimeStamp < found.startTimeStamp ? current : found), {
									id: 0,
									startTimeStamp: Number.MAX_SAFE_INTEGER,
								}).id,
							}));
						}
					} else {
						setNoMeetings(true);
					}
				})
				.catch((err) => {
					console.log(err);
				});
		}
	};

	const handleMeetingChange = () => {
		if (agendaData.meeting > 0) {
			setAgendaData({
				...agendaData,
				section: 0,
				item: "",
			});
			setSections(null);
			setItems(null);
			request
				.get(`${API_HOST}/api/meeting/${agendaData.meeting}/agendaheadings`)
				.withCredentials()
				.then((res) => {
					if (res.body && res.body.headings && res.body.headings.length) {
						setSections(res.body.headings);
					} else {
						setNoSections(true);
					}
				})
				.catch((err) => {
					console.log(err);
				});
		}
	};

	const handleSectionChange = () => {
		if (agendaData.section > 0) {
			setAgendaData((prev) => ({
				...prev,
				item: "",
			}));
			newItemGuid.current = uuid();
			setItems(null);
			request
				.get(`${API_HOST}/api/meeting/${agendaData.meeting}/agendaheadingitems?headingId=${agendaData.section}`)
				.withCredentials()
				.then((res) => {
					if (res.body) {
						setItems(res.body.items);
					}
				})
				.catch((err) => {
					if (err.status === 403) {
						setItems([]);
					} else {
						console.log(err);
					}
				});
		}
	};

	const handleItemChange = useCallback(() => {
		let itemName = "";
		let itemText = "";
		let closed = false;

		if (agendaData.item && agendaData.item.length) {
			items.forEach((item) => {
				if (item.guid === agendaData.item) {
					itemName = item.fields.Name.Value || "";
					itemText = item.fields.Text.Value || "";
					closed = Boolean(item.fields.Closed.Value);
				}
			});
		}

		const meeting = meetings?.find((meeting) => meeting.id === parseInt(agendaData.meeting, 10));
		closed = Boolean(closed || meeting?.closed);
		setItemData({ name: itemName, text: itemText, closed });
		getEditor("name", closed)?.setData(itemName);
		getEditor("text", closed)?.setData(itemText);
	}, [meetings, agendaData, items]);

	const handleFieldChange = (e, field) => {
		const {
			target: { value },
		} = e;

		let { closed = false } = agendaData;
		if (field === "meeting") {
			closed = !!(meetings.find((meeting) => meeting.id === parseInt(value, 10)) || {}).closed;
		}

		setAgendaData({
			...agendaData,
			[field]: value,
			closed,
		});
	};

	const handleEditorChange = (_event, editor, field) => {
		const newContent = editor.getData();
		const correctedField = field.replace("Mo", "");
		if (newContent) {
			if (itemData[correctedField] === newContent) return; // don't re-save unchanged content;

			setItemData((prev) => ({
				...prev,
				[correctedField]: newContent,
			}));
		}
	};

	const handleToggleMinutes = useCallback(
		(minutesType, minutesDocument, members) => {
			setAgendaData((prev) => {
				const editor = getEditor("text", itemData.closed);
				if (editor && minutesDocument) {
					const text = editor.getData();
					if (!prev[minutesType]) {
						// Add minutes link
						editor.setData(
							text +
								`<p><a class=\"inlineFile${members || itemData.closed ? " closed" : ""}\" href=\"/document/${
									minutesDocument.guid
								}\" name=\"${minutesDocument.title}\"  title=\"${minutesDocument.title}\">${minutesDocument.title}</a></p>`,
						);
					} else {
						// Remove minutes link
						let position = text.indexOf(`/document/${minutesDocument.guid}`);
						if (position > 0) {
							const start = text.lastIndexOf("<p>", position);
							const end = text.indexOf("</p>", position);
							if (start >= 0 && end >= 0) {
								editor.setData(text.substring(0, start) + text.substring(end + 4));
							}
						}
					}
				}

				return {
					...prev,
					[minutesType]: !prev[minutesType],
				};
			});
		},
		[itemData, agendaData, meetings],
	);

	const handleAddToAgenda = () => {
		telemetryAddEvent(
			`Minutes - Add to Agenda -${
				agendaData.includeMemberDocument && agendaData.includePublicDocument
					? " Members and Public"
					: agendaData.includeMemberDocument
					? " Member"
					: " Public"
			}`,
		);
		if (
			meetingId > 0 &&
			agendaData.meetingGroup > 0 &&
			agendaData.meeting > 0 &&
			agendaData.section > 0 &&
			(agendaData.includeMemberDocument || agendaData.includePublicDocument)
		) {
			setAdding(true);
			const guid = uuid();
			setProgress({
				label: t("generatingMinutes"),
				percent: 5,
			});
			updateHandlers(dispatch, handler, PROGRESS_HUB, { announceProgress: updateProgress });
			client.ensureStarted().then(() => client.progressHub.registerProgressGuid(guid));
			request
				.post(`${API_HOST}/api/meeting/${meetingId}/addminutestoagenda`)
				.send({ agenda: agendaData, item: itemData, progressGuid: guid })
				.then(() => {
					setProgress({
						label: " ",
						percent: 100,
					});
					let option = notifierMessage(t("addToAgendaDialog.snackbar.success"), "success");
					dispatch(setSnackbarOptions(option));
					client.ensureStarted().then(() => client.progressHub.clearProgressGuid(guid));
					updateHandlers(dispatch, handler, PROGRESS_HUB, { announceProgress: null });
					setTimeout(onClose, 1000);
				})
				.catch((err) => handleError(err));
		}
	};

	const handleAddToAgendaForPolicy = () => {
		setAdding(true);
		let payload = {
			SelectedMeetingId: agendaData?.meeting,
			SectionId: agendaData?.section,
			ItemGuid: agendaData?.item,
			ItemName: itemData?.name,
			ItemText: itemData?.text,
		};
		dispatch(addPolicyToAgenda(policyGuid, payload)).then((res) => {
			displaySuccessNotification("Added Successfully", dispatch);
			setTimeout(onClose, 1000);
		});
	};

	const handleCancel = () => {
		onClose();
	};

	const closeDialogs = () => {
		setDialogs({});
	};

	const handleCreateItemOptionClick = useCallback(() => {
		getEditor("name", itemData.closed)?.editing.view.focus();
	}, [itemData]);

	const getDropdownProgressIndicator = (name) => (
		<MenuItem key={name} value={0} data-cy={name}>
			<CircularProgressIndicator minHeight="0px" size={24} padding="8px" />
		</MenuItem>
	);

	const getNoOptionsMenuItem = () => (
		<MenuItem key="no-item-option" value="">
			{t("addToAgendaDialog.options.none")}
		</MenuItem>
	);

	const getItems = () => {
		const itemsItems = [];
		if (items) {
			itemsItems.push(
				<MenuItem
					key={`item-${newItemGuid.current}`}
					className={classes.menuItem}
					value={newItemGuid.current}
					data-cy="item-0"
					onClick={handleCreateItemOptionClick}
				>
					<div className={classes.menuItemText}>{t("addToAgendaDialog.createNewAgendaItem")}</div>
				</MenuItem>,
			);
			items.forEach((item) => {
				itemsItems.push(
					<MenuItem key={`item-${item.guid}`} className={classes.menuItem} value={item.guid} data-cy={`item-${item.guid}`}>
						<div className={classes.menuItemText}>{processHtml(stripHtml(item.fields.Name.Value))}</div>
					</MenuItem>,
				);
			});
		} else if (agendaData.section > 0 && !items) {
			itemsItems.push(getDropdownProgressIndicator("item-loading"));
		}

		return itemsItems;
	};

	const getSections = () => {
		const sectionsItems = [];
		if (sections && sections.length) {
			sections.forEach((section) => {
				sectionsItems.push(
					<MenuItem key={`section-${section.id}`} className={classes.menuItem} value={section.id} data-cy={`section-${section.id}`}>
						<div className={classes.menuItemText}>{processHtml(stripHtml(section.name))}</div>
					</MenuItem>,
				);
			});
		} else if (noSections) {
			sectionsItems.push(getNoOptionsMenuItem());
		} else if (agendaData.meeting > 0 && !sections) {
			sectionsItems.push(getDropdownProgressIndicator("section-loading"));
		}

		return sectionsItems;
	};

	const getMeetings = () => {
		const meetingsItems = [];
		if (meetings && meetings.length) {
			meetings.forEach((meeting) => {
				const formattedDate = formatDate(null, meeting.startTime, null, t("app:at"), "", "", false);
				if (meeting.date) {
					const date = parseISO(meeting.date);
					const time = parseISO(meeting.startTime || meeting.date);
					const dateTime = meeting.startTime
						? set(date, {
								hours: getHours(time),
								minutes: getMinutes(time),
						  })
						: date;

					if (isBefore(currentMeetingDateTime, dateTime)) {
						meetingsItems.push(
							<MenuItem key={`meeting-${meeting.id}`} className={classes.menuItem} value={meeting.id} data-cy={`meeting-${meeting.id}`}>
								<div className={classes.menuItemText}>{`${meeting.name} ${formattedDate}`}</div>
							</MenuItem>,
						);
					}
				}
			});
		} else if (noMeetings) {
			meetingsItems.push(getNoOptionsMenuItem());
		} else if (agendaData.meetingGroup > 0 && !meetings) {
			meetingsItems.push(getDropdownProgressIndicator("meeting-loading"));
		}

		return meetingsItems;
	};

	const getMeetingGroups = () => {
		const meetingGroupsItems = [];
		if (meetingGroups && meetingGroups.length) {
			meetingGroups.forEach((meetingGroup) => {
				meetingGroupsItems.push(
					<MenuItem
						key={`meeting-group-${meetingGroup.id}`}
						className={classes.menuItem}
						value={meetingGroup.id}
						data-cy={`meeting-group-${meetingGroup.id}`}
					>
						<div className={classes.menuItemText}>{meetingGroup.name}</div>
					</MenuItem>,
				);
			});
		}

		return meetingGroupsItems;
	};

	const setEditorsData = (name, value) => {
		editors.current[name] = value;
	};

	const createMeeting = () => {
		isPolicy
			? navigate(`/meeting/create?boardId=${agendaData.meetingGroup}&finishUrl=${finishPolicyUrl}&finishAction=addToAgenda`)
			: navigate(
					`/meeting/create?boardId=${agendaData.meetingGroup}&finishUrl=/meeting/${
						fromLive ? "live/" : ""
					}${meetingId}&finishAction=addToAgenda`,
			  );
	};

	const completeFileUpload = (itemGuid, fileGuid, error) => {
		let incomplete = false;
		for (let i = 0; i < uploadQueue.current.length; i++) {
			if (uploadQueue.current[i].guid === itemGuid && uploadQueue.current[i].fileGuid === fileGuid) {
				uploadQueue.current[i].complete = true;
				uploadQueue.current[i].error = error;
			}

			incomplete = incomplete || !uploadQueue.current[i].complete; // Check if any uploads are still in progress
		}

		setUploading(incomplete);
	};

	const sendFiletoAPI = (guid, fileData) => {
		request
			.post(`${API_HOST}/api/documents/uploadattachments`)
			.withCredentials()
			.send(fileData)
			.then((res) => {
				if (res.status === 200) {
					setFailedUploads((prev) => [
						...prev.concat(
							(res.body.invalidFiles || []).map((name) => ({
								name,
							})),
						),
					]);
					forEach((attachment) => {
						completeFileUpload(guid, attachment.guid);
					}, res.body.Attachments || res.body.uploaded);
				}
			})
			.catch((err) => {
				setFailedUploads((prev) => [
					...prev.concat(
						err.status === 400
							? (err.response.body.invalidFiles || []).map((name) => ({
									name,
							  }))
							: [],
					),
				]);
				checkUploadStatus();

				if (err.status !== 400) {
					showSignIn(err, () => {
						sendFiletoAPI(guid, fileData);
					});
				}
			});
	};

	const queueFileUploads = (guid, fileUploads, fileData) => {
		for (let i = 0; i < fileUploads.length; i++) {
			uploadQueue.current.push({
				guid,
				fileGuid: fileUploads[i].guid,
				file: fileUploads[i],
				complete: false,
				error: null,
			});
		}

		setUploading(true);
		setFailedUploads([]);
		sendFiletoAPI(guid, fileData);
	};

	const setMinutesHtmlGuid = (minutesHtml) => {
		// Set the minutes guid if it does not exist
		if (minutesHtml && !minutesHtml.guid) {
			minutesHtml.guid = uuid();
		}
	};

	useEffect(() => {
		handleMeetingGroupChange();
	}, [agendaData.meetingGroup]);
	useEffect(() => {
		handleMeetingChange();
	}, [agendaData.meeting]);
	useEffect(() => {
		handleSectionChange();
	}, [agendaData.section]);
	useEffect(() => {
		handleItemChange();
	}, [agendaData.item]);

	useEffect(() => {
		if (failedUploads && failedUploads.length > 0) {
			setDialogs({
				failedUploads,
			});
		}
	}, [failedUploads]);

	useEffect(() => {
		setMinutesHtmlGuid(publicMinutesHtml);
		setMinutesHtmlGuid(membersMinutesHtml);
		setAgendaData((prev) => ({
			...prev,
			publicMinutesGuid: publicMinutesHtml?.guid,
			membersMinutesGuid: membersMinutesHtml?.guid,
		}));
	}, []);

	useEffect(() => {
		// Set the drop-down options width to match the width of the control
		const itemSelect = document.getElementById("item");
		if (itemSelect) {
			setSelectWidth(itemSelect.offsetWidth);
		}
	});

	const canAdd =
		(meetingId > 0 || isPolicy) &&
		agendaData.meetingGroup > 0 &&
		agendaData.meeting > 0 &&
		agendaData.section > 0 &&
		(agendaData.includeMemberDocument || agendaData.includePublicDocument || isPolicy);
	const features = [
		{
			id: "MOA",
			label: t("inlineFile.features.MOA.featureLabel"),
			className: "closed",
			defaultValue: itemData.closed,
			disabledValue: itemData.closed,
			isEnabled: !itemData.closed,
			anchorTitle: t("inlineFile.features.MOA.anchorTitleMember"),
			tooltipDisabledOn: t("inlineFile.features.MOA.tooltipDisabledOn"),
		},
	];

	return (
		<>
			<GenericDialog
				className={classes.dialog}
				show={show}
				title={isPolicy ? t("addToAgendaDialog.addPolicyTitle") : t("addToAgendaDialog.title")}
				primaryAction={
					isPolicy
						? () => {
								handleAddToAgendaForPolicy({ agenda: agendaData, item: itemData });
						  }
						: handleAddToAgenda
				}
				primaryTitle={isPolicy ? t("buttons.addCopyOfDraft") : t("buttons.addToAgenda")}
				primaryDisabled={!canAdd || uploading || adding}
				secondaryAction={handleCancel}
				secondaryTitle={t("app:buttons.cancel")}
				secondaryDisabled={adding}
				clickAwayDisabled={adding}
				closeIcon={<Icon name="close" />}
				data-cy="add-to-agenda-dialog"
				disableAutoFocus
				disableEnforceFocus
			>
				<SelectInput
					id="meeting-group"
					className={clsx(inputClasses.smallInput, classes.centerStyle)}
					noDefaultClassName
					fullWidth
					label={t("meetingGroup")}
					value={agendaData.meetingGroup > 0 ? agendaData.meetingGroup : ""}
					onChange={(e) => handleFieldChange(e, "meetingGroup")}
					data-cy="meeting-group"
				>
					{getMeetingGroups()}
				</SelectInput>
				{noMeetings && (
					<div>
						<div>{t("addToAgendaDialog.noMeetings")}</div>
						<div>
							<ButtonWithTooltip
								className={classes.createMeeting}
								variant="outlined"
								color="primary"
								onClick={createMeeting}
								title={t("tooltips.createMeeting")}
								data-cy="create-meeting"
							>
								{t("buttons.addMeeting")}
							</ButtonWithTooltip>
						</div>
					</div>
				)}
				{!noMeetings && (
					<SelectInput
						id="meeting"
						className={clsx(inputClasses.smallInput, classes.centerStyle)}
						noDefaultClassName
						fullWidth
						label={t("meeting")}
						value={agendaData.meeting > 0 ? agendaData.meeting : ""}
						onChange={(e) => handleFieldChange(e, "meeting")}
						data-cy="meeting"
					>
						{getMeetings()}
					</SelectInput>
				)}
				<SelectInput
					id="section"
					className={clsx(inputClasses.smallInput, classes.centerStyle)}
					noDefaultClassName
					fullWidth
					label={t("agendaMenu:section")}
					value={agendaData.section > 0 ? agendaData.section : ""}
					onChange={(e) => handleFieldChange(e, "section")}
					data-cy="section"
					disabled={noMeetings}
				>
					{getSections()}
				</SelectInput>
				<SelectInput
					id="item"
					className={clsx(inputClasses.smallInput, classes.centerStyle)}
					noDefaultClassName
					fullWidth
					label={t("agendaMenu:item")}
					value={agendaData.item && agendaData.item.length ? agendaData.item : ""}
					onChange={(e) => handleFieldChange(e, "item")}
					data-cy="item"
					disabled={noMeetings}
				>
					{getItems()}
				</SelectInput>
				<Typography type="h2">{t("addToAgendaDialog.createNewAgendaItem")}</Typography>
				<div className={clsx({ [classes.hidden]: !itemData.closed })}>
					<GenericEditor
						meetingId={meetingId}
						guid={agendaData.item}
						toolbar="simpleMo"
						name="nameMo"
						content={itemData.name || ""}
						title={t("agendaItems:editItem.nameLabel")}
						setEditorsData={setEditorsData}
						features={features}
						onChange={handleEditorChange}
						queueFileUploads={queueFileUploads}
						willBeAnAttachment
						mt={0}
						loadAsync
						preload={{ staticToolbar: true }}
					/>
				</div>
				<div className={clsx({ [classes.hidden]: itemData.closed })}>
					<GenericEditor
						meetingId={meetingId}
						guid={agendaData.item}
						toolbar="simple"
						name="name"
						content={itemData.name || ""}
						title={t("agendaItems:editItem.nameLabel")}
						setEditorsData={setEditorsData}
						features={features}
						onChange={handleEditorChange}
						queueFileUploads={queueFileUploads}
						willBeAnAttachment
						mt={0}
						loadAsync
						preload={{ staticToolbar: true }}
					/>
				</div>
				<br />
				<div className={clsx({ [classes.hidden]: !itemData.closed })}>
					<GenericEditor
						meetingId={meetingId}
						guid={agendaData.item}
						toolbar="limitedMo"
						name="textMo"
						title={t("agendaItems:editItem.descriptionLabel")}
						content={itemData.text || ""}
						setEditorsData={setEditorsData}
						features={features}
						onChange={handleEditorChange}
						queueFileUploads={queueFileUploads}
						willBeAnAttachment
						mt={0}
						loadAsync
						preload={{ staticToolbar: true }}
					/>
				</div>
				<div className={clsx({ [classes.hidden]: itemData.closed })}>
					<GenericEditor
						meetingId={meetingId}
						guid={agendaData.item}
						toolbar="limited"
						name="text"
						title={t("agendaItems:editItem.descriptionLabel")}
						content={itemData.text || ""}
						setEditorsData={setEditorsData}
						features={features}
						onChange={handleEditorChange}
						queueFileUploads={queueFileUploads}
						willBeAnAttachment
						mt={0}
						loadAsync
						preload={{ staticToolbar: true }}
					/>
				</div>
				{!isPolicy && (
					<Box display="flex" justifyContent="flex-start" alignItems="flex-start">
						<FormGroup>
							<FormControlLabel
								id="members-minutes"
								control={
									<Checkbox
										color="primary"
										icon={<Icon name="unchecked" color={primaryColor[0]} />}
										checkedIcon={<Icon name="checked" color={primaryColor[0]} />}
										checked={agendaData.includeMemberDocument}
										onChange={() => handleToggleMinutes("includeMemberDocument", membersMinutesHtml, true)}
										value={agendaData.includeMemberDocument}
										data-cy="include-members-minutes"
									/>
								}
								label={t("memberMinutes")}
								data-cy="include-members-minutes"
							/>
							<FormControlLabel
								id="public-minutes"
								control={
									<Checkbox
										color="primary"
										icon={<Icon name="unchecked" color={primaryColor[0]} />}
										checkedIcon={<Icon name="checked" color={primaryColor[0]} />}
										checked={agendaData.includePublicDocument}
										onChange={() => handleToggleMinutes("includePublicDocument", publicMinutesHtml, false)}
										value={agendaData.includePublicDocument}
										data-cy="include-public-minutes"
									/>
								}
								label={t("publicMinutes")}
								data-cy="include-public-minutes"
							/>
						</FormGroup>
						<ProgressBar
							className={clsx(classes.progressBar, {
								[classes.hidden]: progress.percent === 0,
							})}
							label={progress.label}
							progress={progress.percent}
							maxLabelWidthPercent={100}
						/>
					</Box>
				)}
			</GenericDialog>
			{dialogs.failedUploads && <UploadErrorDialog failedUploads={dialogs.failedUploads} onClose={closeDialogs} />}
		</>
	);
};

export default AddToAgendaDialog;
