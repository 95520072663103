import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { throttle } from "lodash";
import { API_HOST } from "config/env";

import makeStyles from "@mui/styles/makeStyles";
import { Typography, Link } from "@mui/material";

import processHtml, { stripHtml } from "utils/processHtml";
import AccessibleIconButton from "atlas/components/Buttons/AccessibleIconButton";
import ButtonWithTooltip from "atlas/components/Buttons/ButtonWithTooltip";
import typographyStyle from "atlas/assets/jss/components/typographyStyle";
import { blackColor } from "atlas/assets/jss/shared";
import GenericEditor from "components/Editor/GenericEditor";
import Motion from "./Motion";

const useStyles = makeStyles({
	header: {
		display: "flex",
		alignItems: "start",
		boxSizing: "border-box",
		height: "64px",
		padding: "16px",
		paddingBottom: "0",
	},
	flexLeftPlaceholder: {
		flexGrow: "1",
	},
	title: {
		...typographyStyle.drawerTitle,
		color: blackColor[3],
		flexGrow: "1",
		display: "flex",
		alignItems: "center",
	},
	addIcon: {
		marginTop: "-12px",
		marginBottom: "-12px",
	},
	close: {
		marginTop: "-4px",
		marginRight: "-12px",
	},
	previous: {
		position: "absolute",
		left: "4px",
		top: "4px",
	},
	next: {
		marginLeft: "-12px",
	},
	saveStatus: {
		...typographyStyle.toolbarLabel,
		color: blackColor[3],
		opacity: "1",
		marginRight: "16px",
	},
	saved: {
		opacity: "0",
		transition: "opacity 1s ease-in",
	},
	body: {
		overflow: "auto",
		padding: "16px",
		paddingTop: "0",
	},
	editorSpacing: {
		marginBottom: "16px",
	},
	thinButton: {
		height: "24px",
	},
});

const MotionPanel = (props) => {
	const {
		meetingId,
		meetingDate,
		meeting,
		closed,
		selectedItems: { item, previousItem, nextItem, motionParentItem, motions = [] } = {},
		rollCall,
		rollCallTypes,
		members = [],
		closePanel,
		handleSelect,
		addMotion,
		updateMotion,
		deleteMotion,
		queueFileUploads,
		invalidFileExtension,
		saveStatus,
		updateVote,
		sendForVote,
		finishVote,
		stopVote,
		showVotingResults,
		digitalVoting,
		votingSettings,
		votingInRange,
		onlineVoters,
		adoptPublishPreviousMinutes,
		openDraftAdoptMinutesToSign,
		startTieBreakerVote,
	} = props;
	const { t } = useTranslation("meetings");
	const [fields, setFields] = useState(motionParentItem ? { ...motionParentItem.fields } : {});
	const classes = useStyles();
	const focusItem = item && item.itemType === 7 ? motions.find((motion) => motion.agendaItemGuid === item.guid) : {}; // Find first motion related to a recommendation

	const isMotionEmpty = (motion) =>
		motion.fields.Name.Value === "" &&
		motion.fields.Text.Value === "" &&
		motion.fields.MovedBy.Value === 0 &&
		motion.fields.SecondedBy.Value === 0 &&
		motion.fields.Disposition.Value === "";

	let showAdoptPublishPreviousMinutes = null;
	let minutesToAdoptMeetingId = null;
	if (item && item.fields && item.fields.Consent) {
		showAdoptPublishPreviousMinutes = item.itemToAdoptPreviousMinutes;
		minutesToAdoptMeetingId = item.minutesToAdoptMeetingId;
	}

	let focusIndex = motions.findIndex((motion) => isMotionEmpty(motion));
	if (focusIndex < 0) {
		focusIndex = motions.findIndex((motion) => focusItem === motion);
	}
	if (focusIndex < 0) {
		focusIndex = 0;
	}

	const updateMinutesItemValues = (updatedValue, field, convertToHtml, isNumber) => {
		let value = updatedValue;
		if (convertToHtml) {
			value = `<p>${value.replace(/(?:\r\n|\r|\n)/g, "<br />")}</p>`;
		}
		if (isNumber && typeof value === "string") {
			value = parseInt(value, 10);
		}

		setFields((prev) => ({
			...prev,
			[field]: {
				Value: value,
			},
		}));

		updateMotion(motionParentItem, {
			[field]: {
				Value: value,
			},
		});
	};

	const handleEditorChange = (_event, editor, field) => {
		const newContent = editor.getData();

		if (fields[field].Value === newContent) {
			return; // don't re-save unchanged content;
		}

		updateMinutesItemValues(newContent, field);
	};

	useEffect(() => {
		setFields(motionParentItem ? { ...motionParentItem.fields } : {});
	}, [motionParentItem]);

	const itemSelected = motionParentItem && motionParentItem.guid && fields.Number;

	return (
		<>
			<div key={`motion-panel-${motionParentItem.guid}`} className={classes.header} data-cy="motion-panel-header">
				<div className={classes.flexLeftPlaceholder} />
				<div className={classes.close}>
					<span
						className={clsx(classes.saveStatus, { [classes.saved]: saveStatus === t("agendaMenu:saved") })}
						data-cy="motion-save-status"
					>
						{saveStatus}
					</span>
					<AccessibleIconButton
						aria-label={t("tooltips.closeMotion")}
						onClick={closePanel}
						iconName="close"
						dataCy="close-notes"
						tooltipText={t("tooltips.closeMotion")}
						className={classes.icon}
						name="close"
					/>
				</div>
				{itemSelected && previousItem && (
					<div className={classes.previous}>
						<AccessibleIconButton
							aria-label={t("tooltips.goToPreviousItem")}
							onClick={() => handleSelect(previousItem.guid, { scroll: true })}
							iconName="expand-up"
							dataCy="previous-item"
							tooltipText={t("tooltips.goToPreviousItem")}
							className={classes.icon}
							name="previous-item"
						/>
					</div>
				)}
			</div>
			<div className={classes.body}>
				{itemSelected ? (
					<>
						<div className={classes.editorSpacing} data-cy="minutes-notes">
							<GenericEditor
								key={`Text-${motionParentItem.guid}`}
								meetingId={meetingId}
								guid={motionParentItem.guid}
								toolbar={"minutesNotes"}
								name={`Text-${motionParentItem.guid}`}
								title={processHtml(t("minutesNotes", { name: stripHtml(`${fields.Number.Value} ${fields.Name.Value}`) }))}
								labelSize="large"
								labelEllipsis
								labelLines={2}
								content={fields.Text.Value || ""}
								features={[
									{
										id: "MOA",
										label: t("inlineFile.features.MOA.featureLabel"),
										className: "closed",
										defaultValue: closed,
										disabledValue: closed,
										isEnabled: !closed,
										anchorTitle: t("inlineFile.features.MOA.anchorTitleMember"),
										tooltipDisabledOn: t("inlineFile.features.MOA.tooltipDisabledOn"),
									},
								]}
								queueFileUploads={(guid, fileUploads, fileData) => queueFileUploads(guid, fileUploads, fileData, motionParentItem.guid)}
								onChange={(_event, editor) => handleEditorChange(_event, editor, "Text")}
								invalidFileExtension={invalidFileExtension}
								mt={0}
								loadAsync
								preload={{ staticToolbar: true }}
							/>
						</div>
						{showAdoptPublishPreviousMinutes && (
							<div style={{ margin: "0 8px" }}>
								<div>
									<ButtonWithTooltip
										className={clsx(classes.button, classes.adoptPublish, classes.thinButton)}
										primary
										variant="outlined"
										title={t("meetings:tooltips.adoptPublish")}
										onClick={() => {
											adoptPublishPreviousMinutes(minutesToAdoptMeetingId);
										}}
										data-cy="adopt-publish"
									>
										{t("meetings:buttons.adoptPublish")}
									</ButtonWithTooltip>
								</div>
								<div>
									<Link
										className={clsx("cursor-pointer", classes.signAdoptLink)}
										underline="always"
										href={`${API_HOST}/home/meeting/adopt/${minutesToAdoptMeetingId}/minutes?liveMeeting=${meetingId}`}
									>
										{t("meetings:buttons.goToSignAdopt")}
									</Link>
								</div>
							</div>
						)}
						{motions.length === 0 && (
							<Motion
								key="new-motion"
								meetingId={meetingId}
								meetingDate={meetingDate}
								meeting={meeting}
								closed={closed}
								rollCall={rollCall}
								rollCallTypes={rollCallTypes}
								members={members}
								parentItem={motionParentItem}
								addMotion={() => addMotion(motionParentItem, motions.length + 1)}
								updateMotion={updateMotion}
								deleteMotion={deleteMotion}
								queueFileUploads={queueFileUploads}
								invalidFileExtension={invalidFileExtension}
								isLast
								updateVote={updateVote}
								sendForVote={sendForVote}
								finishVote={finishVote}
								stopVote={stopVote}
								showVotingResults={showVotingResults}
								votingSettings={votingSettings}
								digitalVoting={digitalVoting}
								votingInRange={votingInRange}
								onlineVoters={onlineVoters}
								adoptPublishPreviousMinutes={adoptPublishPreviousMinutes}
								openDraftAdoptMinutesToSign={openDraftAdoptMinutesToSign}
								startTieBreakerVote={startTieBreakerVote}
							/>
						)}
						{motions.map((motion, index) => (
							<Motion
								key={`motion${motion.guid}`}
								meetingId={meetingId}
								meetingDate={meetingDate}
								meeting={meeting}
								closed={closed}
								motion={motion}
								rollCall={rollCall}
								rollCallTypes={rollCallTypes}
								members={members}
								parentItem={motionParentItem}
								addMotion={() => addMotion(motionParentItem, motions.length + 1)}
								updateMotion={updateMotion}
								deleteMotion={deleteMotion}
								queueFileUploads={queueFileUploads}
								invalidFileExtension={invalidFileExtension}
								isLast={index === motions.length - 1}
								focus={focusIndex === index}
								updateVote={updateVote}
								sendForVote={sendForVote}
								finishVote={finishVote}
								stopVote={stopVote}
								showVotingResults={showVotingResults}
								votingSettings={votingSettings}
								digitalVoting={digitalVoting}
								votingInRange={votingInRange}
								onlineVoters={onlineVoters}
								adoptPublishPreviousMinutes={adoptPublishPreviousMinutes}
								openDraftAdoptMinutesToSign={openDraftAdoptMinutesToSign}
								startTieBreakerVote={startTieBreakerVote}
							/>
						))}
						{nextItem && (
							<div className={classes.next}>
								<AccessibleIconButton
									aria-label={t("tooltips.goToNextItem")}
									onClick={() => handleSelect(nextItem.guid, { scroll: true })}
									iconName="expand-down"
									dataCy="next-item"
									tooltipText={t("tooltips.goToNextItem")}
									className={classes.icon}
									name="next-item"
								/>
							</div>
						)}
					</>
				) : (
					<div>
						<Typography variant="body1">{t("motions.panel.selectItem")}</Typography>
					</div>
				)}
			</div>
		</>
	);
};

export default MotionPanel;
