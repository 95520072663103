import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { API_HOST } from "config/env";
import { formatDate } from "utils/date";
import { Typography, MenuItem, ButtonGroup, Link } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import NonModalMenu from "atlas/components/Menu/NonModalMenu";
import telemetryAddEvent from "utils/telemetryAddEvent";
import AccessibleIconButton from "atlas/components/Buttons/AccessibleIconButton";
import OutlinedInput from "atlas/components/FormControls/OutlinedInput";
import SelectInput from "atlas/components/FormControls/SelectInput";
import ButtonWithTooltip from "atlas/components/Buttons/ButtonWithTooltip";
import MotionDeleteDialog from "components/Dialogs/MotionDeleteDialog";
import GenericEditor from "components/Editor/GenericEditor";
import { MEDIUM } from "atlas/utils/buttonSize";
import typographyStyle from "atlas/assets/jss/components/typographyStyle";
import { primaryColor, grayColor } from "atlas/assets/jss/shared";
import GenericDialog from "atlas/components/Dialogs/GenericDialog";
import Icon from "atlas/components/Icon/Icon";
import Voting from "./Voting";
import checkVotingFinished, { checkMeetingQuorumMet, getVotingResults } from "../utils/votingUtils";
import { TYPE_FOR, TYPE_AGAINST, TYPE_ABSTAIN, TYPE_ABSENT, TYPE_COI, TYPE_NONE } from "../utils/voteType";
import { STATUS_ABSENT } from "../utils/rollCallUserStatus";

const useStyles = makeStyles({
	fieldInput: {
		width: "100%",
		marginTop: "-16px",
		marginBottom: "16px",
		"& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)": {
			transform: "translate(14px, 28px)",
		},
		"& .MuiInputBase-root": {
			height: "40px",
			"& .MuiInputBase-input": {
				boxSizing: "border-box",
				height: "40px",
				paddingTop: "10.5px",
				paddingBottom: "10.5px",
			},
		},
	},
	editorSpacing: {
		marginBottom: "16px",
	},
	divider: {
		margin: "0 -16px",
		marginTop: "8px",
		height: "2px",
		backgroundColor: grayColor[5],
	},
	dividerBetween: {
		marginTop: "0",
		marginBottom: "16px",
	},
	buttons: {
		display: "flex",
		alignItems: "center",
		flexWrap: "wrap",
		paddingTop: "16px",
		paddingBottom: "16px",
		marginBottom: "-16px",
		marginTop: "-16px",
	},
	buttonsLeft: {
		flexGrow: "1",
		display: "flex",
		alignItems: "center",
	},
	labelButton: {
		...typographyStyle.drawerTitle,
	},
	button: {
		minWidth: "100px",
		marginRight: "4px",
		padding: "0 4px",
	},
	buttonsBetween: {
		marginTop: "-16px",
		marginBottom: "0",
	},
	rollCallVote: {
		marginRight: "0",
	},
	rollCallTypesButton: {
		marginRight: "4px",
	},
});

const Motion = (props) => {
	const {
		meetingId,
		meetingDate,
		meeting,
		closed,
		motion: { forceUpdate = false } = {},
		motion,
		parentItem,
		rollCall,
		rollCallTypes,
		addMotion,
		updateMotion,
		deleteMotion,
		queueFileUploads,
		invalidFileExtension,
		focus,
		isLast,
		updateVote,
		sendForVote,
		finishVote,
		stopVote,
		showVotingResults,
		digitalVoting,
		votingSettings,
		votingInRange,
		onlineVoters,
		adoptPublishPreviousMinutes,
		openDraftAdoptMinutesToSign,
		startTieBreakerVote,
	} = props;
	const [fields, setFields] = useState(motion ? { ...motion.fields } : {});
	const [dialogs, setDialogs] = useState({});
	const [openMenu, setOpenMenu] = useState({});
	const [anchor, setAnchor] = useState({});
	const [showVoting, setShowVoting] = useState(
		motion && motion.fields.Voting && motion.fields.Voting.Value && motion.fields.Voting.Value.length > 0,
	);
	const [digitalVotingStarted, setDigitalVotingStarted] = useState(false);
	const [selectedRollCall, setSelectedRollCall] = useState(null);
	const { t } = useTranslation("meetings");
	const classes = useStyles();

	const confirmChangeVotePrimaryAction = useRef(null);

	const containerId = `motion-${(motion || {}).guid || "0"}`;

	const showAdoptPublishPreviousMinutes =
		parentItem && parentItem.itemToAdoptPreviousMinutes && parentItem.fields.Consent && !parentItem.fields.Consent.Value;
	const minutesToAdoptMeetingId = parentItem && parentItem.minutesToAdoptMeetingId;

	const isMotionEmpty = () =>
		motion.fields.Name.Value === "" &&
		motion.fields.Text.Value === "" &&
		motion.fields.MovedBy.Value === 0 &&
		motion.fields.SecondedBy.Value === 0 &&
		motion.fields.Disposition.Value === "";

	const updateMotionValues = (updatedValue, field, convertToHtml, isNumber, preventSave) => {
		let value = updatedValue;
		if (convertToHtml) {
			value = `<p>${value.replace(/(?:\r\n|\r|\n)/g, "<br />")}</p>`;
		}
		if (isNumber && typeof value === "string") {
			value = parseInt(value, 10);
		}

		setFields((prev) => ({
			...prev,
			[field]: {
				Value: value,
			},
		}));

		if (!preventSave) {
			updateMotion(motion, {
				[field]: {
					Value: value,
				},
			});

			if (field === "Name") {
				telemetryAddEvent("Live meeting - Edit motion text");
			} else if (field === "MovedBy") {
				telemetryAddEvent("Live meeting - Selected mover");
			}
		} else {
			motion.fields = {
				...motion.fields,
				[field]: {
					Value: value,
				},
			};
		}
	};

	const handleToggleMenu = (e, type) => {
		e.stopPropagation();
		e.preventDefault();
		setAnchor({ [type]: e.currentTarget });
		setOpenMenu((prevOpen) => ({
			[type]: !prevOpen[type],
		}));
	};

	const handleCloseMenu = () => {
		setAnchor({});
		setOpenMenu({});
	};

	const handleChange = (e, field, convertToHtml, isNumber) => {
		const {
			target: { value },
		} = e;

		updateMotionValues(value, field, convertToHtml, isNumber);
	};

	const handleEditorChange = (_event, editor, field) => {
		const newContent = editor.getData();

		if (fields[field].Value === newContent) {
			return; // don't re-save unchanged content;
		}

		updateMotionValues(newContent, field);
	};

	const handleVoteChange = (motionGuid, userId, vote) => {
		if (fields && fields.Voting == null) {
			motion.fields.Voting = { Value: [] };
		}

		let needConfirm = false;
		const currentUserVote = fields.Voting.Value.find((userVote) => userVote.UserId === userId);
		if (currentUserVote && currentUserVote.Vote !== 5 && digitalVoting && motion.attributes.digitalVoting) {
			needConfirm = true;
		}

		if (needConfirm) {
			confirmChangeVotePrimaryAction.current = () => {
				setFields((prev) => ({
					...prev,
					Voting: {
						Value: prev.Voting.Value.map((userVote) => {
							if (userVote.UserId === userId) {
								userVote.Vote = vote;
								updateVote(motionGuid, userId, vote);
							}
							return userVote;
						}),
					},
				}));
			};
			setDialogs((prev) => ({
				...prev,
				confirmVoteChange: true,
			}));
		} else if (currentUserVote == null) {
			const selectedRollCallUser = selectedRollCall?.users.find((sr) => sr.userId === userId);
			const rollCallUser = rollCall.users.find((sr) => sr.userId === userId);
			setFields((prev) => {
				prev.Voting.Value.push({
					UserId: userId,
					Name: selectedRollCallUser ? selectedRollCallUser?.name : rollCallUser?.name,
					Vote: vote,
					RecordedVote: true,
					Weighting: selectedRollCallUser ? selectedRollCallUser.weighting : rollCallUser.weighting,
					QuorumNeeded: selectedRollCall ? selectedRollCall.quorumNeeded : rollCall.quorumNeeded,
					QuorumNeededValue: selectedRollCall ? selectedRollCall.quorumNeededValue : rollCall.quorumNeededValue,
				});

				return prev;
			});
		} else {
			setFields((prev) => ({
				...prev,
				Voting: {
					Value: prev.Voting.Value.map((userVote) => {
						if (userVote.UserId === userId) {
							userVote.Vote = vote;
							updateVote(motionGuid, userId, vote);
						}
						return userVote;
					}),
				},
			}));
		}

		if (votingSettings) {
			const votingFinished = checkVotingFinished({ fields: fields }, rollCall);
			const votingResults = getVotingResults({ fields: fields }, selectedRollCall ? selectedRollCall : rollCall, meeting);
			if (votingFinished) {
				const disposition = votingResults.quorumMet
					? votingResults.votePassed
						? votingSettings.votingLabels.carried
						: votingSettings.votingLabels.failed
					: t("voting.quorumNotMet");
				if (motion.fields.Disposition.Value != disposition) {
					updateMotionValues(disposition, "Disposition", false, false);
				}
				finishVote(motion, disposition);
				setDigitalVotingStarted(false);
			} else if (votingResults && votingResults.tie) {
				startTieBreakerVote({ fields: fields });
			}
		}
	};

	const handleVoteTypeChange = (rollCallId) => {
		telemetryAddEvent("Live meeting - Vote type change");
		updateMotionValues(rollCallId, "SelectedRollCallId", false, false);
		const foundRollCall = rollCallTypes.find((r) => r.id === rollCallId);
		setSelectedRollCall(foundRollCall);
		updateMotionValues(motion.fields.Voting.Value, "Voting", false, false);
		if (votingSettings && checkVotingFinished(motion, foundRollCall)) {
			const votingResults = getVotingResults(motion, foundRollCall, meeting);
			const disposition = votingResults.quorumMet
				? votingResults.votePassed
					? votingSettings.votingLabels.carried
					: votingSettings.votingLabels.failed
				: t("voting.quorumNotMet");
			if (motion.fields.Disposition.Value != disposition) {
				updateMotionValues(disposition, "Disposition", false, false);
			}
		}
	};

	const recordVote = () => {
		let votesUpdated = false;
		rollCall.users.forEach((rollCallUser) => {
			const vote =
				motion.fields && motion.fields.Voting ? motion.fields.Voting.Value.find((vote) => vote.UserId === rollCallUser.userId) : null;
			if (!vote && rollCallUser.votingMember) {
				if (motion.fields.Voting == null) {
					motion.fields.Voting = { Value: [] };
				}
				motion.fields.Voting.Value.push({
					UserId: rollCallUser.userId,
					Name: rollCallUser.name,
					Vote: rollCallUser.status === STATUS_ABSENT ? TYPE_ABSENT : !digitalVoting ? TYPE_FOR : TYPE_NONE,
					RecordedVote: true,
					Weighting: rollCallUser.weighting,
					QuorumNeeded: rollCallUser.quorumNeeded,
					QuorumNeededValue: rollCallUser.quorumNeededValue,
				});
				votesUpdated = true;
			}
		});

		if (votesUpdated) {
			updateMotionValues(motion.fields.Voting.Value, "Voting", false, false);
			if (votingSettings && checkVotingFinished(motion, rollCall)) {
				const votingResults = getVotingResults(motion, selectedRollCall ? selectedRollCall : rollCall, meeting);
				const disposition = votingResults.quorumMet
					? votingResults.votePassed
						? votingSettings.votingLabels.carried
						: votingSettings.votingLabels.failed
					: t("voting.quorumNotMet");
				if (motion.fields.Disposition.Value != disposition) {
					updateMotionValues(disposition, "Disposition", false, false);
				}
			}
		}

		setShowVoting(true);

		telemetryAddEvent("Live meeting - Roll call vote");
	};

	const startVote = () => {
		sendForVote(motion);
	};

	const resetVote = () => {
		setShowVoting(false);
		updateMotionValues([], "Voting", false, false);
		updateMotionValues("", "Disposition", false, false);
		stopVote(motion, true);
		setDigitalVotingStarted(false);
	};

	useEffect(() => {
		if (votingSettings) {
			const quorumMet = checkMeetingQuorumMet(meeting, rollCall);
			const votingFinished = checkVotingFinished(motion, rollCall);
			if (
				motion &&
				!votingFinished &&
				["", votingSettings.votingLabels.carried, votingSettings.votingLabels.failed, t("voting.quorumNotMet")].indexOf(
					motion.fields.Disposition.Value,
				) >= 0
			) {
				if (!quorumMet) {
					updateMotionValues(t("voting.quorumNotMet"), "Disposition", false, false, true);
				} else if (motion.fields.Disposition.Value === t("voting.quorumNotMet")) {
					updateMotionValues("", "Disposition", false, false, true);
				}
			}

			if (motion && motion.fields.SelectedRollCallId && motion.fields.SelectedRollCallId.Value) {
				const foundRollCall = rollCallTypes.find((r) => r.id == motion.fields.SelectedRollCallId.Value);
				setSelectedRollCall(foundRollCall);
			}
		}
	}, [votingSettings]);

	useEffect(() => {
		if (forceUpdate) {
			setFields(motion ? { ...motion.fields } : {});
			if (motion) {
				delete motion.forceUpdate; // Set this back to undefined
			}
		}
	}, [forceUpdate]);

	useEffect(() => {
		if (focus) {
			window.document.getElementById(containerId).scrollIntoView();
		}
	}, [focus]);

	const getMotionOptions = (excludedId) => {
		return rollCall && rollCall.users
			? rollCall.users
					.filter((member) => member.userId !== excludedId && member.status !== STATUS_ABSENT && member.votingMember)
					.map((member) => ({
						label: member.name,
						value: member.userId.toString(),
					}))
			: [];
	};

	const verifyDelete = () => {
		setDialogs((prev) => ({
			...prev,
			delete: true,
		}));
	};

	const closeDialog = (dialog) => {
		setDialogs((prev) => ({
			...prev,
			[dialog]: false,
		}));
	};

	const formattedMeetingDate = formatDate(meetingDate);

	return (
		<>
			{dialogs.delete && <MotionDeleteDialog show motion={motion} deleteMotion={deleteMotion} onClose={() => closeDialog("delete")} />}
			{motion && (
				<div id={containerId} className={classes.container}>
					<div className={classes.editorSpacing}>
						<GenericEditor
							meetingId={meetingId}
							guid={motion.guid}
							toolbar="itemText"
							name="Name"
							title={t("motionText")}
							labelSize="large"
							content={fields.Name.Value || ""}
							features={[
								{
									id: "MOA",
									label: t("inlineFile.features.MOA.featureLabel"),
									className: "closed",
									defaultValue: closed,
									disabledValue: closed,
									isEnabled: !closed,
									anchorTitle: t("inlineFile.features.MOA.anchorTitleMember"),
									tooltipDisabledOn: t("inlineFile.features.MOA.tooltipDisabledOn"),
								},
							]}
							queueFileUploads={(guid, fileUploads, fileData) => queueFileUploads(guid, fileUploads, fileData, motion.guid)}
							invalidFileExtension={invalidFileExtension}
							focus={focus}
							onChange={(_event, editor) => handleEditorChange(_event, editor, "Name")}
							mt={0}
							loadAsync
							preload={{ staticToolbar: true }}
						/>
					</div>
					<div>
						<SelectInput
							id={`motion-moved-by-${motion.guid}`}
							name="moved-by"
							className={classes.fieldInput}
							noDefaultClassName
							fullWidth
							externalLabel
							label={t("movedBy")}
							labelSize="large"
							value={fields.MovedBy.Value > 0 ? fields.MovedBy.Value.toString() : ""}
							onChange={(e) => handleChange(e, "MovedBy", undefined, true)}
							data-cy="moved-by"
						>
							{getMotionOptions(fields.SecondedBy.Value).map((option) => (
								<MenuItem key={`moved-by-${option.value}`} value={option.value} data-cy={`moved-by-${option.value}`}>
									{option.label}
								</MenuItem>
							))}
						</SelectInput>
					</div>
					<div>
						<SelectInput
							id={`motion-seconded-by-${motion.guid}`}
							name="seconded-by"
							className={classes.fieldInput}
							noDefaultClassName
							fullWidth
							externalLabel
							label={t("secondedBy")}
							labelSize="large"
							value={fields.SecondedBy.Value > 0 ? fields.SecondedBy.Value.toString() : ""}
							onChange={(e) => handleChange(e, "SecondedBy", undefined, true)}
							data-cy="seconded-by"
						>
							{getMotionOptions(fields.MovedBy.Value).map((option) => (
								<MenuItem key={`seconded-by-${option.value}`} value={option.value} data-cy={`seconded-by-${option.value}`}>
									{option.label}
								</MenuItem>
							))}
						</SelectInput>
					</div>
					<>
						<ButtonGroup style={{ marginBottom: "8px" }}>
							<ButtonWithTooltip
								className={clsx(classes.button, classes.rollCallVote)}
								primary
								variant="outlined"
								color="primary"
								title={t("voting.tooltips.rollCallVote")}
								onClick={recordVote}
								disabled={!checkMeetingQuorumMet(meeting, rollCall)}
								data-cy="record-vote"
							>
								{selectedRollCall
									? selectedRollCall.name.length > 0
										? selectedRollCall.name
										: t("voting.majority")
									: t("voting.buttons.rollCallVote")}
							</ButtonWithTooltip>
							<ButtonWithTooltip
								className={classes.rollCallTypesButton}
								variant="outlined"
								color="primary"
								title={t("voting.tooltips.votingTypes")}
								aria-controls={anchor.votingTypes ? "split-button-menu" : undefined}
								aria-expanded={anchor.votingTypes ? "true" : undefined}
								aria-label="select voting type action"
								aria-haspopup="menu"
								onClick={(e) => {
									handleToggleMenu(e, "votingTypes");
								}}
								tooltipPlacement="top"
								data-cy="vote-types"
							>
								<Icon name="expand-down" color={primaryColor[4]} />
							</ButtonWithTooltip>
						</ButtonGroup>
						{anchor.votingTypes && (
							<NonModalMenu
								id="votingType"
								className={classes.menu}
								anchorEl={anchor.votingTypes}
								keepMounted
								open={openMenu.votingTypes}
								onClose={handleCloseMenu}
								options={rollCallTypes.map((r) => {
									return {
										id: r.id,
										key: r.id,
										label: r.name || t("voting.majority"),
										actionFunction: () => {
											handleVoteTypeChange(r.id);
										},
									};
								})}
								position="bottom-end"
							/>
						)}
					</>
					{showVoting && (
						<div>
							{dialogs.resetVote && (
								<GenericDialog
									show
									title={t("voting.confirmResetVoteDialog.title")}
									primaryAction={() => {
										resetVote();
										closeDialog("resetVote");
									}}
									primaryTitle={t("app:buttons.reset")}
									secondaryAction={() => {
										closeDialog("resetVote");
									}}
									secondaryTitle={t("app:buttons.cancel")}
									closeIcon={<Icon name="close" />}
									data-cy="confirm-reset-vote"
								>
									<Typography>{t("voting.confirmResetVoteDialog.body")}</Typography>
								</GenericDialog>
							)}
							{dialogs.confirmVoteChange && confirmChangeVotePrimaryAction.current && (
								<GenericDialog
									show
									title={t("voting.confirmChangeVote.title")}
									primaryAction={() => {
										if (confirmChangeVotePrimaryAction.current) {
											confirmChangeVotePrimaryAction.current();
											confirmChangeVotePrimaryAction.current = null;
											closeDialog("confirmVoteChange");
										}
									}}
									primaryTitle={t("app:buttons.confirm")}
									secondaryAction={() => {
										closeDialog("confirmVoteChange");
									}}
									secondaryTitle={t("app:buttons.cancel")}
									closeIcon={<Icon name="close" />}
									data-cy="confirm-vote-change"
								>
									<Typography>{t("voting.confirmChangeVote.body")}</Typography>
								</GenericDialog>
							)}
							<Voting
								motion={motion}
								rollCall={rollCall}
								voting={motion?.fields?.Voting}
								selectedRollCall={selectedRollCall}
								onlineVoters={onlineVoters}
								votingSettings={votingSettings}
								digitalVoting={digitalVoting}
								digitalVotingStarted={digitalVotingStarted}
								handleVoteChange={handleVoteChange}
								showVotesExpanded={true}
								meeting={meeting}
							/>
						</div>
					)}
					<div>
						<OutlinedInput
							id={`disposition-${motion.guid}`}
							externalLabel
							noDefaultClassName
							label={t("disposition")}
							labelSize="large"
							value={fields.Disposition.Value || ""}
							onChange={(e) => handleChange(e, "Disposition")}
							fullWidth
							size="small"
							data-cy="disposition"
						/>
					</div>
					<div
						className={clsx(classes.buttons, {
							[classes.buttonsBetween]: !isLast,
						})}
					>
						{motion && (
							<>
								{showVoting && (
									<>
										{digitalVoting && !checkVotingFinished(motion, rollCall) && (
											<ButtonWithTooltip
												className={classes.button}
												primary
												variant="outlined"
												title={
													votingInRange
														? t("voting.tooltips.sendForVote")
														: t("voting.tooltips.votingNotInRange", { date: formattedMeetingDate })
												}
												onClick={() => {
													startVote();
													setDigitalVotingStarted(true);
													motion.attributes.digitalVoting = true;

													telemetryAddEvent("Live meeting - Send for vote");
												}}
												disabled={!checkMeetingQuorumMet(meeting, rollCall) || !votingInRange}
												data-cy="send-for-vote"
											>
												{digitalVotingStarted ? t("voting.buttons.reSendForVote") : t("voting.buttons.sendForVote")}
											</ButtonWithTooltip>
										)}
									</>
								)}
								{(checkVotingFinished(motion, rollCall) || !showVoting || !digitalVoting) && (
									<ButtonWithTooltip
										className={classes.button}
										primary
										variant="outlined"
										title={t("voting.tooltips.showResults")}
										onClick={() => {
											showVotingResults(motion, fields.Disposition.Value);
										}}
										data-cy="show-voting-results"
									>
										{t("voting.buttons.showResults")}
									</ButtonWithTooltip>
								)}
								{showVoting && (
									<ButtonWithTooltip
										className={classes.button}
										variant="text"
										title={t("voting.tooltips.resetVote")}
										onClick={() => {
											setDialogs((prev) => ({
												...prev,
												resetVote: true,
											}));
										}}
										data-cy="reset-vote"
									>
										{t("voting.buttons.resetVote")}
									</ButtonWithTooltip>
								)}
								<ButtonWithTooltip
									className={classes.button}
									variant="text"
									title={t("tooltips.deleteMotion")}
									size={MEDIUM}
									onClick={verifyDelete}
									data-cy="delete-motion"
								>
									{t("buttons.deleteMotion")}
								</ButtonWithTooltip>
								{showAdoptPublishPreviousMinutes && (
									<div style={{ margin: "8px 0" }}>
										<ButtonWithTooltip
											className={clsx(classes.button, classes.adoptPublish)}
											primary
											variant="outlined"
											title={t("tooltips.adoptPublish")}
											onClick={() => {
												adoptPublishPreviousMinutes(minutesToAdoptMeetingId);
											}}
											data-cy="adopt-publish"
										>
											{t("buttons.adoptPublish")}
										</ButtonWithTooltip>
										<Link
											className="cursor-pointer"
											underline="always"
											href={`${API_HOST}/home/meeting/adopt/${minutesToAdoptMeetingId}/minutes?liveMeeting=${meetingId}`}
										>
											{t("buttons.goToSignAdopt")}
										</Link>
									</div>
								)}
							</>
						)}
					</div>
				</div>
			)}
			<div className={classes.buttons}>
				<div className={classes.buttonsLeft}>
					{isLast && (
						<>
							<div className={classes.labelButton}>{t("motion")}</div>
							<AccessibleIconButton
								aria-label={t("tooltips.addNewMotion")}
								onClick={() => {
									if (!motion || (motion && !isMotionEmpty())) {
										addMotion();
									}
								}}
								iconName="add-circle"
								dataCy="add-new-motion"
								tooltipText={t("tooltips.addNewMotion")}
								className={classes.addIcon}
								name="add-new-motion"
								iconColor={primaryColor[1]}
							/>
						</>
					)}
				</div>
			</div>
		</>
	);
};

export default Motion;
