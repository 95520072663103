import React, { useEffect, useCallback, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import request from "superagent";
import { v4 as uuid } from "uuid";
import trimChars from "lodash/fp/trimChars";
import clsx from "clsx";

import { Checkbox, FormControlLabel, MenuItem, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import settingsStyle from "assets/jss/components/settingsStyle";
import ButtonWithTooltip from "atlas/components/Buttons/ButtonWithTooltip";
import SelectInput from "atlas/components/FormControls/SelectInput";
import { MEDIUM } from "atlas/utils/buttonSize";
import { Check } from "components/Icons";
import { API_HOST } from "config/env";
import { setSnackbarOptions } from "redux/snackBar/actions";
import { updateHandlers, PROGRESS_HUB } from "utils/communication/SignalrClient";
import { endsWithExtension } from "utils/allowedExtensions";
import { formatDate } from "utils/date";
import notifierMessage from "utils/notifierMessage";
import telemetryAddEvent from "utils/telemetryAddEvent";
import { useUpdateObject } from "utils/updateObject";
import BoardPaqImportProgress from "./BoardPaqImportProgress";

const useStyles = makeStyles(settingsStyle);

const clearImportingProgress = {
	label: " ",
	percent: 0,
};
const statusCheckTimeout = 60000;

const telemetryPage = "BoardPaq import";

const excelExtensions = [".xlsx"];

const defaultCredentials = {
	continueImport: true,
};

const BoardPaqImport = (props) => {
	const { uploadedFiles, progressGuid, handleChange } = props;
	const { t } = useTranslation("settings");
	const [uploading, setUploading] = useState(false);
	const [selectedFiles, setSelectedFiles] = useState([]);
	const [uploadIndex, setUploadIndex] = useState(null);
	const [credentials, setCredentials] = useState({ ...defaultCredentials });
	const [importing, setImporting] = useState({ active: false });
	const [dialogs, setDialogs] = useState({});
	const [progress, setProgress] = useState({
		...clearImportingProgress,
	});
	const [logs, setLogs] = useState([]);
	const [selectedLog, setSelectedlog] = useState(null);
	const guid = useRef();
	const statusCheckHandle = useRef();
	const active = useRef(true);
	const excelInput = useRef(null);
	const documentsInput = useRef(null);
	const { client, handler } = useSelector((state) => state.appReducer.signalR);
	const dispatch = useDispatch();
	const classes = useStyles();

	const updateCredentials = useUpdateObject(setCredentials);

	const excelUploadClick = () => {
		excelInput.current?.click();
	};

	const excelInputChange = (e) => {
		const { target: { files = [] } = {} } = e;

		// Only get files with allowed extensions
		const filteredFiles = [];
		for (let index = 0; index < files.length; index++) {
			const file = files[index];
			if (endsWithExtension(file.name, excelExtensions)) {
				filteredFiles.push(file);
			}
		}

		if (filteredFiles.length > 0) {
			setUploading(true);

			const fileData = new FormData();
			fileData.append(uuid(), filteredFiles[0]);

			request
				.put(`${API_HOST}/api/boardpaq/excel`)
				.withCredentials()
				.send(fileData)
				.then((response) => {
					if (response.status === 200) {
						handleChange(
							{
								target: {
									value: {
										...uploadedFiles,
										dateExcelUploaded: response.body.dateExcelUploaded,
									},
								},
							},
							"boardPaqFiles",
						);

						const option = notifierMessage(t("boardPaq.snackbar.excelUploadSuccess"), "success");
						dispatch(setSnackbarOptions(option));
					} else {
						const option = notifierMessage(t("boardPaq.snackbar.excelUploadFailure"), "error");
						dispatch(setSnackbarOptions(option));
					}

					setUploading(false);
				})
				.catch((exception) => {
					console.error(exception);

					const option = notifierMessage(t("boardPaq.snackbar.excelUploadFailure"), "error");
					dispatch(setSnackbarOptions(option));

					setUploading(false);
				});
		}

		e.target.value = null;
	};

	const handleClearDocuments = () => {
		request
			.delete(`${API_HOST}/api/boardpaq/documents`)
			.withCredentials()
			.then((res) => {
				if (res.status === 200) {
					handleChange(
						{
							target: {
								value: {
									...uploadedFiles,
									documentsUploaded: 0,
								},
							},
						},
						"boardPaqFiles",
					);
				}
			})
			.catch((exception) => {
				console.error(exception);

				const option = notifierMessage(t("boardPaq.snackbar.documentUploadFailure", { path: selectedFile.path }), "error");
				dispatch(setSnackbarOptions(option));
			});
	};

	const documentsUploadClick = () => {
		documentsInput.current?.click();
	};

	const documentsInputChange = (e) => {
		const { target: { files = [] } = {} } = e;

		const filteredFiles = [];
		for (let index = 0; index < files.length; index++) {
			const file = files[index];
			filteredFiles.push(file);
		}

		setSelectedFiles(
			filteredFiles
				.sort((first, second) =>
					// eslint-disable-next-line no-nested-ternary
					first.webkitRelativePath < second.webkitRelativePath ? -1 : first.webkitRelativePath > second.webkitRelativePath ? 1 : 0,
				)
				.map((file) => ({
					file,
					name: trimChars("\\/", file.webkitRelativePath),
					path: trimChars("\\/", file.webkitRelativePath.replace(file.name, "")),
				})),
		);

		setUploadIndex(0);
		setUploading(true);
	};

	const uploadFile = useCallback(
		(index) => {
			if (index !== null && index >= 0 && index < selectedFiles.length && active.current) {
				const selectedFile = selectedFiles[index];

				selectedFiles[index] = {
					...selectedFile,
					uploading: true,
				};
				setSelectedFiles([...selectedFiles]);

				const fileData = new FormData();
				fileData.append(uuid(), selectedFile.file);
				fileData.append(
					"data",
					JSON.stringify({
						path: selectedFile.path,
						allowDuplicates: false,
					}),
				);

				request
					.post(`${API_HOST}/api/boardpaq/documents`)
					.withCredentials()
					.send(fileData)
					.then((res) => {
						if (res.status === 200) {
							handleChange(
								{
									target: {
										value: {
											...uploadedFiles,
											documentsUploaded: res.body.documentsUploaded,
										},
									},
								},
								"boardPaqFiles",
							);
						}

						setUploadIndex(index + 1);
					})
					.catch((exception) => {
						console.error(exception);

						const option = notifierMessage(t("boardPaq.snackbar.documentUploadFailure", { path: selectedFile.path }), "error");
						dispatch(setSnackbarOptions(option));

						setUploadIndex(index + 1);
					});
			} else {
				setUploading(false);
			}
		},
		[selectedFiles],
	);

	const loadLogs = () => {
		request
			.get(`${API_HOST}/api/boardpaq/logs`)
			.withCredentials()
			.then((res) => {
				const { status, body: logs } = res;

				if (status === 200) {
					setLogs(logs);
					setSelectedlog(logs[0]?.name);
				}
			});
	};

	const downloadLog = useCallback(() => {
		window.location.href = `${API_HOST}/api/boardpaq/log?logname=${selectedLog}`;
	}, [selectedLog]);

	const checkImportStatus = () => {
		request
			.get(`${API_HOST}/api/boardpaq/status?progressguid=${guid.current}`)
			.withCredentials()
			.then((res) => {
				const {
					body: { active, complete },
				} = res;

				// Check if the import needs to be restarted
				if (!active && !complete) {
					handleImport(undefined, true);
				} else if (active) {
					scheduleStatusCheck();
				}
			})
			.catch(() => {});
	};

	const scheduleStatusCheck = () => {
		statusCheckHandle.current = setTimeout(checkImportStatus, statusCheckTimeout);
	};

	const clearStatusCheck = () => {
		if (statusCheckHandle.current) {
			clearTimeout(statusCheckHandle.current);
			statusCheckHandle.current = null;
		}
	};

	const handleImport = useCallback(
		(_e, restart = false) => {
			if (!restart) {
				setImporting((prev) => ({ ...prev, active: true }));
				clearProgress(true);

				telemetryAddEvent(`${telemetryPage} - BoardPaq Import Data`);
			}

			request
				.post(`${API_HOST}/api/boardpaq/import`)
				.withCredentials()
				.send({
					...credentials,
					progressGuid: guid.current,
				})
				.then((res) => {
					const {
						status,
						body: { errors },
					} = res;

					setImporting((prev) => ({ ...prev, active: false }));

					if (status === 200) {
						if (errors.active) {
							let option = notifierMessage(t("boardPaq.snackbar.importActive"), "success");
							dispatch(setSnackbarOptions(option));
						} else {
							// Start the periodic status check
							scheduleStatusCheck();
						}
					}
				})
				.catch(() => {
					setImporting((prev) => ({ ...prev, active: false }));
				});
		},
		[credentials],
	);

	const handleCloseProgress = () => {
		clearProgress();
		loadLogs();
	};

	const clearProgress = (show = false) => {
		setDialogs({ importProgress: show });
		setProgress({
			...clearImportingProgress,
		});
	};

	const updateProgress = (percentage, message) => {
		setProgress({
			label: message,
			percent: percentage,
		});
		if (percentage >= 100) {
			if (statusCheckHandle.current) {
				// This is a proxy to see if we have reached 100% once already to avoid duplicate snackbars
				let option = notifierMessage(t("boardPaq.snackbar.success"), "success");
				dispatch(setSnackbarOptions(option));
			}
			clearStatusCheck();
		}
	};

	useEffect(() => {
		uploadFile(uploadIndex);
	}, [uploadIndex]);

	useEffect(() => {
		guid.current = progressGuid || uuid();

		updateHandlers(dispatch, handler, PROGRESS_HUB, { announceProgress: updateProgress });

		client.ensureStarted().then(() => client.progressHub.registerProgressGuid(guid.current));

		if (progressGuid) {
			setImporting((prev) => ({ ...prev, active: true }));
			clearProgress(true);
			scheduleStatusCheck();
		}

		loadLogs();

		return () => {
			// Clear any pending status check timeouts
			clearStatusCheck();

			client.ensureStarted().then(() => client.progressHub.clearProgressGuid(guid.current));

			updateHandlers(dispatch, handler, PROGRESS_HUB, { announceProgress: null });

			active.current = false;
		};
	}, []);

	return (
		<>
			{dialogs.importProgress && progress.percent > 0 && (
				<BoardPaqImportProgress
					progress={progress}
					show={Boolean(dialogs.importProgress)}
					handleClose={handleCloseProgress}
				></BoardPaqImportProgress>
			)}
			<div className={classes.labelWithOtherContent}>
				<Typography variant="h4" className={classes.bold}>
					{t("boardPaq.labels.import")}
				</Typography>
			</div>
			<div className={classes.subSection}>{t("boardPaq.labels.instructions")}</div>
			<div className={classes.subSection}>
				<ButtonWithTooltip
					id="excel-upload"
					variant="outlined"
					size={MEDIUM}
					onClick={excelUploadClick}
					title={t("boardPaq.tooltips.uploadExcel")}
					data-cy="excel-upload-button"
					disabled={uploading}
				>
					{t("boardPaq.buttons.uploadExcel")}
				</ButtonWithTooltip>
				<input
					className={classes.fileInput}
					id="boardpaq-upload-excel"
					type="file"
					ref={excelInput}
					onChange={excelInputChange}
					accept={excelExtensions.join(",")}
					tabIndex={-1}
					aria-label={t("boardPaq.buttons.uploadExcel")}
					aria-hidden="true"
				/>
			</div>
			<div className={classes.subSection}>
				{uploadedFiles.dateExcelUploaded
					? t("boardPaq.labels.excelLastUploaded", {
							date: formatDate(uploadedFiles.dateExcelUploaded, null, null, t("app:at"), "", "", false, true, true),
						})
					: t("boardPaq.labels.excelNotUploaded")}
			</div>
			<div className={clsx(classes.subSection, classes.communityButtons)}>
				{uploadedFiles.documentsUploaded ? (
					<ButtonWithTooltip
						id="documents-clear"
						variant="outlined"
						size={MEDIUM}
						onClick={handleClearDocuments}
						title={t("boardPaq.tooltips.clearDocuments")}
						data-cy="documents-clear-button"
						disabled={uploading}
					>
						{t("boardPaq.buttons.clearDocuments")}
					</ButtonWithTooltip>
				) : null}
				<ButtonWithTooltip
					id="documents-upload"
					variant="outlined"
					size={MEDIUM}
					onClick={documentsUploadClick}
					title={t("boardPaq.tooltips.uploadDocuments")}
					data-cy="documents-upload-button"
					disabled={uploading}
				>
					{t("boardPaq.buttons.uploadDocuments")}
				</ButtonWithTooltip>
				<input
					className={classes.fileInput}
					id="boardpaq-upload-documents"
					type="file"
					webkitdirectory=""
					ref={documentsInput}
					onChange={documentsInputChange}
					tabIndex={-1}
					aria-label={t("boardPaq.buttons.uploadDocuments")}
					aria-hidden="true"
				/>
			</div>
			<div className={classes.subSection}>{t("boardPaq.labels.documentsUploaded", { count: uploadedFiles.documentsUploaded })}</div>
			<div>
				<FormControlLabel
					control={
						<Checkbox
							checkedIcon={<Check fontSize="small" color="primary" />}
							checked={credentials.continueImport}
							onChange={(e) => updateCredentials(e, "continueImport", true)}
						/>
					}
					label={t("boardPaq.labels.continueImport")}
					data-cy="continue-import"
				/>
			</div>
			<div className={classes.subSection}>
				<ButtonWithTooltip
					variant="outlined"
					title={t("boardPaq.tooltips.importData")}
					size={MEDIUM}
					onClick={handleImport}
					data-cy="board-paq-import-data"
					disabled={!uploadedFiles.dateExcelUploaded || !uploadedFiles.documentsUploaded || importing.active}
				>
					{t("boardPaq.buttons.importData")}
				</ButtonWithTooltip>
			</div>
			{logs.length > 0 ? (
				<>
					<div className={clsx(classes.subSection, classes.communityButtons)}>
						<SelectInput
							id="board-docs-log-files"
							className={classes.selectInput}
							noDefaultClassName
							fullWidth
							size="small"
							label={t("boardPaq.labels.logs")}
							labelSize="large"
							value={selectedLog}
							onChange={(e) => {
								setSelectedlog(e.target?.value);
							}}
							data-cy="board-docs-log-files"
						>
							{logs.map((log) => (
								<MenuItem key={log.name} value={log.name}>
									<div className={classes.menuItemText}>
										{formatDate(log.dateModified, null, null, t("app:at"), t("from"), t("to"), false)}
									</div>
								</MenuItem>
							))}
						</SelectInput>
					</div>
					<div>
						<ButtonWithTooltip
							variant="outlined"
							title={t("boardPaq.tooltips.downloadLog")}
							size={MEDIUM}
							onClick={downloadLog}
							data-cy="board-docs-download-log"
						>
							{t("boardPaq.buttons.downloadLog")}
						</ButtonWithTooltip>
					</div>
				</>
			) : null}
		</>
	);
};

export default BoardPaqImport;
