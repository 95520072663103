import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import clsx from "clsx";

import makeStyles from "@mui/styles/makeStyles";
import { Box, ListItem, ListItemText, Typography } from "@mui/material";

import tableOfContentsStyle from "assets/jss/components/tableOfContentsStyle";
import { Locked } from "components/Icons";
import telemetryAddEvent from "utils/telemetryAddEvent";
import TOCAttachments from "./TOCAttachments";
import TOCPrimaryText from "./TOCPrimaryText";
import useAttachmentsHighlight from "../PdfViewerNew/utils/useAttachmentsHighlight";
import { useDroppable } from "@dnd-kit/core";
import Draggable from "atlas/components/DragAndDrop/Draggable";
import DragHandle from "atlas/components/DragAndDrop/DragHandle";
import { Presentation } from "../Icons";
import MoveButtons from "./CommonComponents/MoveButtons";
import { handleKeyDownDND, handleListItemKeyDown, stripTags } from "./CommonComponents/tocUtils";
import TocContentWrapper from "./CommonComponents/TocContentWrapper";

const useTableOfContentsStyles = makeStyles(tableOfContentsStyle);

const TOCHeading = (props) => {
	const {
		section: { fields },
		section,
		isSubHeading,
		isMemberOnlySection,
		isConsentSection,
		isPublicCommentSection,
		notReallyAnItem,
		toggleTableOfContent,
		isSmallDevice,
		parserOptions,
		isClosedMeeting,
		idPrefix = "outline",
		button,
		selected,
		setActive,
		telemetryPage,
		setDataForAttachmentDocument,
		openGoalsPopup,
		setDataForOutlineItem,
		isPdfSidebar,
		selectedId,
		canDrag = false,
		canDrop = false,
		isLastChildItem = false,
		isActive = false,
		moveItem = () => {},
		getNextAvailablePosition = () => {},
		isAgenda = false,
		isMinutes = false,
		presenting = false,
		clone = false,
		renderControl = {},
		index,
		getOverallTabIndex = () => {},
	} = props;
	const { t } = useTranslation("agendaMenu");
	const dispatch = useDispatch();
	const tableOfContentsClasses = useTableOfContentsStyles({
		lineLimit: renderControl?.lineLimit ? renderControl?.lineLimit : section?.fields?.Name?.Value?.length > 50 ? 2 : 1,
	});
	const [clickedOnAttachment, setClickedOnAttachment, clickedOnAttachmentInSidePanel] = useAttachmentsHighlight(false);
	const [hoverStatus, setHoverStatus] = useState(false);
	const [arrowNavigation, setArrowNavigation] = useState(true);
	const dragProps = canDrag ? { dragId: section.guid, dragComponent: "div", tabIndex: null } : {};

	const droppable = useDroppable({ id: section.guid });
	const { setNodeRef } = canDrop ? droppable : {};

	const handleBlur = () => {
		if (arrowNavigation) {
			setHoverStatus(false);
		}
	};

	const handleMove = (item, isMoveUp) => {
		setHoverStatus(false);

		if (isAgenda) {
			telemetryAddEvent("Agenda builder reorder - outline button");
		} else {
			telemetryAddEvent("Minutes builder reorder - outline button");
		}

		moveItem(item, isMoveUp);
	};

	const ConditionForNonOutline = !canDrag && !(isAgenda || isMinutes);
	const ConditionForOutline = canDrag || isAgenda || isMinutes;
	const showAttachments = clone ? false : true;

	const getBulletAndText = () => (
		<>
			<Typography variant="h5" component="span" className={clsx("outline-number", tableOfContentsClasses.bullet)}>
				{fields.Number.Value}
			</Typography>
			<ListItemText
				className={clsx({
					[tableOfContentsClasses.limitLines]: Object.keys(renderControl).length <= 0,
					[tableOfContentsClasses.primaryTextEllipses]: Object.keys(renderControl).length > 0,
				})}
				primaryTypographyProps={{ variant: "h5" }}
				primary={
					<TOCPrimaryText
						text={`<p>${stripTags(fields.Name.Value, ["a"])}</p>`}
						parserOptions={parserOptions}
						showTruncationTooltip
						truncationThreshold={isPdfSidebar ? (!renderControl ? 23 : 121) : 50}
						isPdfSidebar={isPdfSidebar}
						renderControl={Object.keys(renderControl).length > 0}
					></TOCPrimaryText>
				}
			/>
		</>
	);

	return (
		!section.deleted && (
			<TocContentWrapper
				condition={isAgenda || isMinutes}
				wrapper="span"
				wrapperProps={{
					id: `${section.guid}-toc-heading`,
					ref: setNodeRef,
					onKeyDown: (e) => handleListItemKeyDown(e, setArrowNavigation),
				}}
			>
				{isMemberOnlySection && !isSubHeading && (
					<li
						data-id="toc-header"
						className={clsx("member-only-toc-header", { "transition-animation-memberOnly": section.copied })}
						tabIndex={-1}
					>
						<Typography variant="body2" className="margin-top-bottom-auto" component="div">
							<Box color="common.white">{t("memberOnlySection")}</Box>
						</Typography>
						<Box className="margin-top-bottom-auto" color="common.white">
							<Locked size={24} fontSize="small" className={`${ConditionForOutline ? "" : "toc-locked-icon"}`} />
						</Box>
					</li>
				)}
				{isConsentSection && !isSubHeading && (
					<li data-id="toc-header" className="consent-section-toc-header">
						<Typography variant="body2" className="margin-top-bottom-auto" component="div">
							<Box>{t("consentSection")}</Box>
						</Typography>
					</li>
				)}
				{isPublicCommentSection && !isSubHeading && (
					<li
						data-id="toc-header"
						className={`${isPdfSidebar ? "public-comment-section-toc-header-pdfsidebar" : "public-comment-section-toc-header"}`}
					>
						<Typography variant="body2" className="margin-top-bottom-auto" component="div">
							<Box>{t("publicCommentSection")}</Box>
						</Typography>
					</li>
				)}
				<ListItem
					id={`${idPrefix}-${section.guid}`}
					{...(renderControl && section.isFirstElementForSkipContent && { id: "cont" })}
					data-cy={`${idPrefix}-${section.guid}`}
					className={clsx(tableOfContentsClasses.listItem, {
						[tableOfContentsClasses.closedChanged]: section.original && section.original.closed && !section.fields.Closed.Value,
						[tableOfContentsClasses.backgroundColorForSelected]:
							renderControl && renderControl.highlightCheck && renderControl.highlightCheck(section),
					})}
					button={button}
					role=""
					component={"li"}
					disableGutters
					divider
					classes={{
						root: clsx(
							ConditionForOutline ? "candrag-outline-item" : "outline-item",
							ConditionForNonOutline && tableOfContentsClasses.tabFocusStyling,
							ConditionForNonOutline &&
								section.copied &&
								renderControl?.isAnimationAdded(document.getElementById(`${idPrefix}-${section.guid}`))
								? "transition-animation"
								: section.copied && renderControl
									? "transition-animation-added"
									: "",
							{
								"toc-padding": hoverStatus && canDrag,
								"toc-active-item":
									!notReallyAnItem && ((isActive && ConditionForOutline) || (selectedId && selectedId.includes(section.guid))),
								"outline-sub-heading": isSubHeading,
								"outline-heading": !isSubHeading,
								"member-only-section": !canDrag && isMemberOnlySection,
								"canDrag-member-only-section": canDrag && isMemberOnlySection,
								"consent-section-outline": isConsentSection,
								"public-comment-section-outline-pdfsidebar": isPublicCommentSection && isPdfSidebar,
								"public-comment-section-outline": isPublicCommentSection && !isPdfSidebar,
								"closed-meeting": isClosedMeeting,
								"pdfSideBar pdfSideBarHeading": isPdfSidebar && !Object.keys(renderControl).length > 0,
								"member-only-section-last-child-item":
									isLastChildItem && section.attachments.length === 0 && isMemberOnlySection && ConditionForOutline,
								"consent-section-last-child-item":
									isLastChildItem && section.attachments.length === 0 && isConsentSection && ConditionForOutline,
								"public-comment-section-last-child-item":
									isLastChildItem && section.attachments.length === 0 && isPublicCommentSection && ConditionForOutline,
								hyperlinkAttachment: isPdfSidebar && selectedId && selectedId.includes(section.guid) && !clickedOnAttachment,
								[tableOfContentsClasses.itemValueAndTextGapping]: ConditionForNonOutline && renderControl && !ConditionForOutline,
								[tableOfContentsClasses.tocHeadingLineHeight]: ConditionForNonOutline && renderControl && !ConditionForOutline,
								[tableOfContentsClasses.tocHeaderStyleNonItem]: notReallyAnItem,
							},
						),
					}}
					onMouseOver={() => setHoverStatus(true)}
					onMouseOut={() => setHoverStatus(false)}
					onFocus={() => setHoverStatus(true)}
					onBlur={handleBlur}
					onClick={
						button && isPdfSidebar
							? (e) => {
									setDataForOutlineItem(section);
									setClickedOnAttachment(false);

									dispatch(setActive(section.guid, true));
								}
							: button
								? () => {
										telemetryAddEvent(`${telemetryPage} - Outline navigation`);

										dispatch(setActive(section.guid, true));
										if (isSmallDevice) {
											toggleTableOfContent();
										}
									}
								: undefined
					}
					selected={selected && selected.includes(section.guid)}
					tabIndex={getOverallTabIndex(index)}
					onKeyDown={(e) => renderControl && renderControl?.keyDownAction?.(e)}
				>
					{notReallyAnItem ? (
						<ListItemText primaryTypographyProps={{ variant: "h5" }} primary={fields.Name.Value} />
					) : (
						<>
							{canDrag ? (
								<Draggable className={tableOfContentsClasses.draggableListItem} {...dragProps} handleKeyDown={handleKeyDownDND}>
									<DragHandle role="button" />
								</Draggable>
							) : null}
							{getBulletAndText()}
							{canDrag && (
								<MoveButtons
									moveItem={handleMove}
									item={section}
									showMoveUp={getNextAvailablePosition(section, true).nextPosition !== null}
									showMoveDown={getNextAvailablePosition(section, false).nextPosition !== null}
								/>
							)}
							{presenting && !hoverStatus && selected && selected.includes(section.guid) && <Presentation />}
						</>
					)}
					{renderControl?.isBoardNotesPresent && renderControl.isBoardNotesPresent(section)}
					{renderControl?.isCheckBoxVisible && renderControl.isCheckBoxVisible(section) && renderControl.rendercomponent(section, index)}
				</ListItem>
				{showAttachments && (
					<TOCAttachments
						attachments={section.attachments}
						item={section}
						itemGuid={section.guid}
						isMemberOnlySection={isMemberOnlySection}
						isConsentSection={isConsentSection}
						isPublicCommentSection={isPublicCommentSection}
						toggleTableOfContent={toggleTableOfContent}
						isSmallDevice={isSmallDevice}
						setActive={setActive}
						telemetryPage={telemetryPage}
						setDataForAttachmentDocument={setDataForAttachmentDocument}
						isPdfSidebar={isPdfSidebar}
						clickedOnAttachmentInSidePanel={clickedOnAttachmentInSidePanel}
						selectedId={selectedId}
						openGoalsPopup={openGoalsPopup}
						isLastChildItem={isLastChildItem}
						canDrag={ConditionForOutline}
						renderControl={renderControl}
						isAgendaHeading
					/>
				)}
			</TocContentWrapper>
		)
	);
};

export default React.memo(TOCHeading);
