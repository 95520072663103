import React from "react";

import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles(() => ({
	text: {
		width: (props) => props.width,
		flexShrink: (props) => (!props.width ? "1" : "0"),
		wordBreak: "break-word",
	},
}));

const DocumentTreeTextItemColumn = (props) => {
	const { value, width } = props;
	const classes = useStyles({ width });

	return <div className={classes.text}>{value}</div>;
};

export default DocumentTreeTextItemColumn;
