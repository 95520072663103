import React, { useState, useLayoutEffect, useRef } from "react";
import parse from "html-react-parser";
import Tooltip from "atlas/components/Tooltip/Tooltip";
import { stripHtml } from "utils/processHtml";

const TOCPrimaryText = (props) => {
	const { text, parserOptions, showTruncationTooltip, truncationThreshold, tooltipPlacement = "top", isPdfSidebar, renderControl } = props;

	const [isEllipsisActive, setIsEllipsisActive] = useState(false);
	const textRef = useRef(null);

	const textOnly = stripHtml(text);
	const parsedTextForTooltip = isPdfSidebar && parse(text, parserOptions({ tooltip: true }));
	let parsedText;
	if (isPdfSidebar) {
		parsedText = parse(text, parserOptions());
	} else {
		parsedText = parse(text, parserOptions);
	}

	useLayoutEffect(() => {
		if (renderControl) {
			const element = textRef.current;
			if (element) {
				const pTag = element.querySelector("p");
				if (pTag) {
					const isOverflowing = element.offsetWidth < element.scrollWidth;

					if (isOverflowing) {
						Object.assign(pTag.style, {
							whiteSpace: "nowrap",
							overflow: "hidden",
							textOverflow: "ellipsis",
						});
					}
					setIsEllipsisActive(isOverflowing);
				}
			}
		}
	}, [text, parsedText, renderControl]);

	return renderControl && showTruncationTooltip && isEllipsisActive ? (
		<Tooltip placement={tooltipPlacement} title={isPdfSidebar ? parsedTextForTooltip : textOnly}>
			<div ref={textRef}>{parsedText}</div>
		</Tooltip>
	) : showTruncationTooltip && textOnly.length > truncationThreshold && !renderControl ? (
		<Tooltip placement={tooltipPlacement} title={isPdfSidebar ? parsedTextForTooltip : textOnly}>
			<div ref={textRef}>{parsedText}</div>
		</Tooltip>
	) : (
		<div ref={textRef}>{parsedText}</div>
	);
};

export default TOCPrimaryText;
