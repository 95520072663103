import React from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import clsx from "clsx";

import makeStyles from "@mui/styles/makeStyles";

import LiveMeetingRadioButton from "./LiveMeetingRadioButton";

import agendaStyle from "../../../assets/jss/components/agendaStyle";

const useStyle = makeStyles(agendaStyle);

const LiveMeetingAttachmentPage = (props) => {
	const {
		attachment,
		page,
		isMemberOnlyHeading,
		isConsentHeading,
		isPublicCommentHeading,
		addBottomBorder,
		selected,
		handleSelect,
		t,
		noMargin,
	} = props;
	const classes = useStyle({});

	const id = `${attachment.guid}-page-${page}`;
	const label = t("attachmentPage", { page });

	return (
		<li
			id={`agenda-${id}`}
			className={clsx(classes.agendaListItem, {
				[classes.radioListItem]: !noMargin,
				[classes.borderDefault]: !isMemberOnlyHeading && !isConsentHeading && !isPublicCommentHeading && !addBottomBorder,
				[classes.borderDefaultBottomBorder]: !isMemberOnlyHeading && !isConsentHeading && !isPublicCommentHeading && addBottomBorder,
				[classes.borderMemberOnly]: isMemberOnlyHeading,
				[classes.borderConsent]: isConsentHeading,
				[classes.borderPublicComment]: isPublicCommentHeading,
				[classes.selected]: selected,
			})}
			data-cy={`agenda-${id}`}
		>
			<LiveMeetingRadioButton value={id} label={`${attachment.name} ${label}`} selected={selected} handleSelect={handleSelect} />
			<div
				className={clsx(classes.item, classes.itemAttachmentPage, classes.rightBorder, {
					[classes.bottomBorder]: addBottomBorder,
				})}
			>
				<div>{label}</div>
			</div>
		</li>
	);
};

LiveMeetingAttachmentPage.propTypes = {
	attachment: PropTypes.object.isRequired,
	page: PropTypes.number.isRequired,
	isMemberOnlyHeading: PropTypes.bool,
	isConsentHeading: PropTypes.bool,
	isPublicCommentHeading: PropTypes.bool,
	addBottomBorder: PropTypes.bool,
	handleSelect: PropTypes.func,
};

LiveMeetingAttachmentPage.defaultProps = {
	isMemberOnlyHeading: false,
	isConsentHeading: false,
	isPublicCommentHeading: false,
	addBottomBorder: false,
	handleSelect: undefined,
};

export default withTranslation("meetings")(LiveMeetingAttachmentPage);
