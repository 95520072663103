import {
	SET_MEETING_DETAILS,
	SET_SOURCE_MEETING_DETAILS,
	SET_TO_AGENDA_WARNING_MESSAGE,
	CLEAR_WARNING_MESSAGE,
	SET_OVERALL_SELECTED_MEETING_ITEMS,
	SET_SMART_SELECTED_ITEMS,
	SET_SMART_SELECT_TOGGLE,
	SET_LEFT_AGENDA_OBJECT,
	SET_RIGHT_AGENDA_MAP,
	RESET_STATE,
	SET_FINAL_RIGHT_MEETING_AGENDA,
	SET_NEW_MEETING_TAG,
	RESET_LEFT_AGENDA_OBJECT,
	CREATE_ITEMS_FOR_OVERFLOW,
	SET_FIRST_DELETE_BUTTON_INDEX,
	CLEAR_SELECTED_MEETING,
	CREATE_MEETING_IN_PROGRESS,
} from "./types";
import request from "superagent";
import { API_HOST } from "config/env";

export const fetchAgendaItems = (id) => {
	return () => {
		return new Promise((resolve, reject) => {
			request
				.get(`${API_HOST}/api/meeting/${id}/getagendaitems`)
				.withCredentials()
				.then((response) => response.body)
				.then((json) => {
					resolve(json);
				})
				.catch((exception) => {
					reject(exception);
				});
		});
	};
};

export const searchMeetings = (query) => {
	return () => {
		return new Promise((resolve, reject) => {
			request
				.get(`${API_HOST}/api/meetings/filter`)
				.withCredentials()
				.query(query)
				.then((res) => {
					resolve(res);
				})
				.catch((exception) => {
					reject(exception);
				});
		});
	};
};

export const confirmCopyOrMove = (data, id) => {
	return () => {
		return new Promise((resolve, reject) => {
			request
				.post(`${API_HOST}/api/meeting/${id}/copy`)
				.withCredentials()
				.send(data)
				.then((json) => {
					resolve(json);
				})
				.catch((exception) => {
					reject(exception);
				});
		});
	};
};

export const setMeetingDetails = (data) => (dispatch) => {
	dispatch({
		type: SET_MEETING_DETAILS,
		payload: data,
	});
};
export const setSourceMeetingDetails = (data) => (dispatch) => {
	dispatch({
		type: SET_SOURCE_MEETING_DETAILS,
		payload: data,
	});
};

export const setWarningMessage = (data) => (dispatch) => {
	dispatch({
		type: SET_TO_AGENDA_WARNING_MESSAGE,
		payload: data,
	});
};
export const clearWarningMessage = (data) => (dispatch) => {
	dispatch({
		type: CLEAR_WARNING_MESSAGE,
		payload: data,
	});
};
export const setOverallSelectedMeetingItems = (data) => (dispatch) => {
	dispatch({
		type: SET_OVERALL_SELECTED_MEETING_ITEMS,
		payload: data,
	});
};
export const setSmartSelectToggle = (data) => (dispatch) => {
	dispatch({
		type: SET_SMART_SELECT_TOGGLE,
		payload: data,
	});
};
export const setSmartSelectedItems = (data) => (dispatch) => {
	dispatch({
		type: SET_SMART_SELECTED_ITEMS,
		payload: data,
	});
};
export const setLeftAgendaObject = (data) => (dispatch) => {
	dispatch({
		type: SET_LEFT_AGENDA_OBJECT,
		payload: data,
	});
};
export const setRightAgendaMap = (data) => (dispatch) => {
	dispatch({
		type: SET_RIGHT_AGENDA_MAP,
		payload: data,
	});
};
export const resetState = () => (dispatch) => {
	dispatch({
		type: RESET_STATE,
	});
};
export const setFinalRightMeetingAgenda = (data) => (dispatch) => {
	dispatch({
		type: SET_FINAL_RIGHT_MEETING_AGENDA,
		payload: data,
	});
};
export const setNewMeetingFlag = (data) => (dispatch) => {
	dispatch({
		type: SET_NEW_MEETING_TAG,
		payload: data,
	});
};
export const resetLeftAgendaObject = () => (dispatch) => {
	dispatch({
		type: RESET_LEFT_AGENDA_OBJECT,
	});
};
export const createItemsForOverflow = (data) => (dispatch) => {
	dispatch({
		type: CREATE_ITEMS_FOR_OVERFLOW,
		payload: data,
	});
};
export const setFirstDeleteButtonIndex = (data) => (dispatch) => {
	dispatch({
		type: SET_FIRST_DELETE_BUTTON_INDEX,
		payload: data,
	});
};

export const clearSelectedMeeting = () => (dispatch) => {
	dispatch({
		type: CLEAR_SELECTED_MEETING,
	});
};

export const createMeetingInProgress = (data) => (dispatch) => {
	dispatch({
		type: CREATE_MEETING_IN_PROGRESS,
		payload: data,
	});
};
