import React, { useState, useEffect, useContext, useRef } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useMatch } from "react-router-dom";
import { resetPageConfigs, updatePageConfigs } from "redux/app/actions";
import { useTranslation } from "react-i18next";
import queryString from "query-string";
import request from "superagent";

import makeStyles from "@mui/styles/makeStyles";
import { ReCaptcha } from "react-recaptcha-v3";

import CircularProgress from "@mui/material/CircularProgress";
import ComponentContainer from "atlas/components/ComponentContainer/ComponentContainer";
import { useWidthDown } from "atlas/utils/useWidth";
import telemetryAddEvent from "utils/telemetryAddEvent";
import withErrorHandling from "components/ErrorHOC";
import isEmail from "atlas/utils/isEmail";
import { API_HOST } from "config/env";
import { UserContext } from "contexts/User/UserContext";
import { SettingsContext } from "contexts/Settings/SettingsContext";
import isSignedIn from "utils/isSignedIn";
import { MEDIUM } from "atlas/utils/buttonSize";
import ButtonWithTooltip from "atlas/components/Buttons/ButtonWithTooltip";
import AccessibleIconButton from "atlas/components/Buttons/AccessibleIconButton";
import requestToSpeakStyle from "./jss/requestToSpeakStyle";
import RequestToSpeakForm from "./components/RequestToSpeakForm";
import notifierMessage from "utils/notifierMessage";
import { setSnackbarOptions } from "redux/snackBar/actions";

const useStyles = makeStyles(requestToSpeakStyle);

const PublicRequestToSpeakSubmission = (props) => {
	const { setUser } = useContext(UserContext);
	let { user } = useContext(UserContext);
	const { recaptchaKey } = useContext(SettingsContext);
	const { update } = props;
	const widthDownSm = useWidthDown("sm");
	const { params: { id: viewId } = {} } = useMatch({ path: "/public/requesttospeak/:id", end: true }) || {};
	const { params: { id: updateId } = {} } = useMatch({ path: "/public/requesttospeak/:id/update", end: true }) || {};
	const id = viewId || updateId;
	const meetingId = update ? 0 : id;
	const { t } = useTranslation("requestToSpeak");
	const location = useLocation();
	const [requestToSpeak, setRequestToSpeak] = useState({
		guid: "",
		firstName: "",
		lastName: "",
		emailAddress: "",
		phoneNumber: "",
		topic: "",
		meetingId,
		accept: false,
		recaptchaToken: "",
	});
	const [saving, setSaving] = useState(false);
	const dispatch = useDispatch();
	const classes = useStyles();
	const recaptcha = useRef(null);
	const saveTriggered = useRef(false);

	const handleCancel = () => {
		// Contained in the portal iframe
		const queryStringValues = queryString.parse(location.search);
		if (queryStringValues && queryStringValues.kiosk) {
			setRequestToSpeak({
				guid: "",
				firstName: "",
				lastName: "",
				emailAddress: "",
				phoneNumber: "",
				topic: "",
				meetingId,
				accept: false,
				recaptchaToken: "",
			});
		} else if (queryStringValues && queryStringValues.backId && window.parent) {
			window.parent.document.querySelector("iframe.meeting-document").setAttribute("src", `/document/${queryStringValues.backId}`);
		} else {
			window.history.back();
		}
	};

	const loadRequest = () => {
		const { token } = queryString.parse(location.search) || {};

		request
			.get(`${API_HOST}/api/requesttospeak/${id}/public?token=${encodeURIComponent(token)}`)
			.withCredentials()
			.then((res) => {
				const { body: { requestToSpeak: data } = {} } = res;

				if (data) {
					setRequestToSpeak(data);
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const saveRequest = (data) => {
		const { token } = queryString.parse(location.search) || {};
		setSaving(true);
		saveTriggered.current = false;

		const editing = data.guid && data.guid.length > 0;
		const url = `${API_HOST}/api/requesttospeak${editing ? `/${data.guid}` : ""}`;
		const requestObject = editing ? request.put(url) : request.post(url);
		if (update) {
			// eslint-disable-next-line no-param-reassign
			data.token = token;
		}

		if (!editing) {
			telemetryAddEvent(`Request to speak - public item submitted`);
		}

		return requestObject
			.withCredentials()
			.send(data)
			.then((res) => {
				setSaving(false);

				const { status } = res;
				if (status === 200) {
					if (update) {
						let option = notifierMessage(t("snackbar.update.success"), "success");
						dispatch(setSnackbarOptions(option));
					} else {
						let option = notifierMessage(t("snackbar.submitted.success"), "success");
						dispatch(setSnackbarOptions(option));
						setTimeout(handleCancel, 2500);
					}
				}
			})
			.catch(() => {
				setSaving(false);
			});
	};

	const handleChange = (e, field, checkbox, numeric) => {
		const {
			target: { value, checked },
		} = e;

		setRequestToSpeak({
			...requestToSpeak,
			// eslint-disable-next-line no-nested-ternary
			[field]: !checkbox ? (numeric ? parseInt(value, 10) : value) : checked,
		});
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		saveTriggered.current = true;
		if (recaptcha.current) {
			recaptcha.current.execute();
		} else {
			// Fallback for localhost
			saveRequest(requestToSpeak);
		}
	};

	const verifyRecaptcha = (recaptchaToken) => {
		const updatedRequestToSpeak = {
			...requestToSpeak,
			recaptchaToken,
		};

		setRequestToSpeak(updatedRequestToSpeak);
		if (saveTriggered.current) {
			saveRequest(updatedRequestToSpeak);
		}
	};

	const isDisabled = () => {
		return (
			requestToSpeak.firstName.length === 0 ||
			requestToSpeak.lastName.length === 0 ||
			!isEmail(requestToSpeak.emailAddress) ||
			requestToSpeak.topic.length === 0 ||
			parseInt(requestToSpeak.meetingId, 10) === 0 ||
			!requestToSpeak.accept ||
			saving
		);
	};

	useEffect(() => {
		isSignedIn((res) => {
			if (!user || user.username !== res.body.username) {
				user = setUser({
					username: res.body.username,
					systemAdministrator: res.body.systemAdministrator,
					boardAdmin: res.body.boardAdmin,
					boardMember: res.body.boardMember,
					boardStaff: res.body.boardStaff,
					goalAdmin: res.body.goalAdmin,
					requestToSpeakAdmin: res.body.requestToSpeakAdmin,
					documentLibrary: res.body.documentLibrary,
					publicUser: false,
				});
			}
		});

		dispatch(resetPageConfigs({}));
		dispatch(
			updatePageConfigs({
				title: t("publicSubmission.title"),
				telemetryPage: "Request To Speak Submission",
			}),
		);

		if (update) {
			loadRequest();
		}
	}, []);

	return (
		<ComponentContainer fullHeight onlyScrollbar>
			<div className={classes.content}>
				{recaptchaKey && recaptchaKey.length > 0 && (
					<ReCaptcha ref={recaptcha} sitekey={recaptchaKey} action="submit" verifyCallback={verifyRecaptcha} />
				)}

				<header>
					{widthDownSm ? (
						<>
							<div className={classes.mobileHeader}>
								<h1 className={classes.mobileContentLabel}>{t("publicSubmission.requestToSpeak")}</h1>
								<AccessibleIconButton
									aria-label={t("app:buttons.cancel")}
									onClick={handleCancel}
									iconName="close"
									dataCy="close-request-to-speak"
									tooltipText={t("app:buttons.cancel")}
									className={classes.closeIcon}
									name="close"
								/>
							</div>
							<div className={classes.separator} />
						</>
					) : (
						<h1 className={classes.contentLabel}>{t("publicSubmission.requestContent")}</h1>
					)}
				</header>

				<main>
					<form onSubmit={handleSubmit}>
						<RequestToSpeakForm requestToSpeak={requestToSpeak} showMeetings showCodeOfConduct handleChange={handleChange} />
						<div className={classes.separator} />
						<div className={classes.buttons}>
							<ButtonWithTooltip
								title={t("app:buttons.cancel")}
								className={classes.buttonSpacer}
								size={MEDIUM}
								variant="text"
								color="primary"
								onClick={handleCancel}
								data-cy="cancel"
							>
								{t("app:buttons.cancel")}
							</ButtonWithTooltip>
							<ButtonWithTooltip
								title={t(update ? "publicSubmission.tooltips.updateRequest" : "publicSubmission.tooltips.submitRequestToSpeak")}
								size={MEDIUM}
								primary
								disabled={isDisabled()}
								data-cy="submit"
								type="submit"
							>
								{saving ? (
									<CircularProgress size={24} />
								) : (
									t(
										update
											? `publicSubmission.buttons.updateRequest`
											: `publicSubmission.buttons.${widthDownSm ? "submitRequest" : "submitRequestToSpeak"}`,
									)
								)}
							</ButtonWithTooltip>
						</div>
					</form>
				</main>
			</div>
		</ComponentContainer>
	);
};

export default withErrorHandling(PublicRequestToSpeakSubmission);
