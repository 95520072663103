import React, { useRef, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { warningColor } from "atlas/assets/jss/shared";
import makeStyles from "@mui/styles/makeStyles";
import { Box, Link } from "@mui/material";
import NoticeCard from "atlas/components/Cards/NoticeCard";
import { useWidthDown, useWidthUp } from "atlas/utils/useWidth";
import { setActive } from "redux/agendaBuilder/actions";
import TableOfContents from "components/Meeting/TableOfContents";
import AgendaSingletonEditor from "./AgendaSingletonEditor";
import TableOfContentsContainer from "views/MeetingTemplate/components/TableOfContentsContainer";
import { getSidebarWidth } from "../functions/utils";
import { useTranslation } from "react-i18next";
import { STATUS_WARNING } from "atlas/assets/jss/utils/statusIndicators";
import processHtml from "utils/processHtml";
import { sortMinuteItemsByAgendaItems } from "utils/meetingElement";
import { useDispatch } from "react-redux";
import { CircularProgress } from "@mui/material";
import { getMeetingMinutesItems } from "redux/meeting/actions";
import { updateOutOfSyncStatus, saveSyncedMinutes, undoSyncedMinutes } from "redux/agendaBuilder/actions";
import notifierMessage from "utils/notifierMessage";
import telemetryAddEvent from "utils/telemetryAddEvent";
import { setSnackbarOptions } from "redux/snackBar/actions";
import clonedeep from "lodash/cloneDeep";
import SplitPane from "react-split-pane";

const useStyles = makeStyles(() => ({
	tableOfContentContainer: {
		overflow: "auto",
		maxWidth: (props) => getSidebarWidth(props.wideSidebar),
		minWidth: (props) => getSidebarWidth(props.wideSidebar),
		height: "calc(100vh - 212px)",
		position: "fixed",
		borderRight: "1px solid #E6E6E6",
		paddingBottom: "8px",
	},
	splitContentTableOfContentContainer: (props) => {
		//Below console prevents stale code in this hook
		console.info("");
		return {
			height: `calc(100vh - ${215 + props.bannerHeight}px)`,
			borderRight: "1px solid #E6E6E6",
			paddingBottom: "8px",
			overflow: "auto",
		};
	},
	stickyToolbar: {
		position: "-webkit-sticky",
		position: "sticky",
		top: 0,
		zIndex: 150,
		width: "100%",
		minWidth: "200px",
		boxSizing: "border-box",
	},
	stickyToolbarPosition: {
		marginLeft: (props) => (props.showOutline ? getSidebarWidth(props.wideSidebar) : "0"),
		width: (props) => (props.showOutline ? `calc(100% - ${getSidebarWidth(props.wideSidebar)})` : "100%"),
	},
	syncMinutesagendaButton: {
		float: "right",
		fontSize: 16,
		fontWeight: 600,
		width: "max-content",
		textDecoration: "none",
		lineHeight: "20px",
		padding: "8px 0 8px 20px",
	},
	minutesOutOfSyncBanner: {
		top: 0,
		zIndex: 200,
		position: "sticky",
	},
	minutesOutOfSyncBannerMessage: {
		display: "flex",
	},
}));

const telemetryPage = "Meeting Template Agenda";

const AgendaEditorV2 = (props) => {
	const showSplitPane = true;
	const { meetingId, showNotes, updateMeeting, addPolicy, addGoals, queueFileUploads, requestedItemGuid } = props;
	const [isSavingMinutes, setSavingMinutesStatus] = useState(false);
	const [bannerHeight, setBannerHeight] = useState(0);
	const widthDownMd = useWidthDown("md");
	const widthUpXl = useWidthUp("xl");
	const editorToolbarRef = useRef(null);
	const outOfSyncBannerRef = useRef(null);
	const {
		agenda: { items, minutesOutOfSync, startTimeStamp },
		active,
		showTableOfContents = {},
	} = useSelector((state) => state.agendaBuilderReducer);

	const classes = useStyles({
		showOutline: !widthDownMd,
		wideSidebar: widthUpXl,
		bannerHeight,
	});

	const { t } = useTranslation("meetings");
	const dispatch = useDispatch();

	useEffect(() => {
		const contentScrollView = document.getElementById("content-scroll");
		if (contentScrollView) {
			contentScrollView.style.overflow = "hidden";
		}
		return () => {
			localStorage.removeItem("splitPos");
			if (contentScrollView) {
				contentScrollView.style.overflow = "";
			}
		};
	});

	const undoSaveSyncedMinutes = () => {
		setSavingMinutesStatus(true);
		dispatch(undoSyncedMinutes(meetingId))
			.then(() => {
				const option = notifierMessage(t("minutesAgendaSyncRevertSuccess"), "success");
				dispatch(setSnackbarOptions(option));
				dispatch(updateOutOfSyncStatus(true));
			})
			.catch(() => {
				const option = notifierMessage(t("minutesAgendaSyncRevertError"), "error");
				dispatch(setSnackbarOptions(option));
			})
			.finally(() => {
				setSavingMinutesStatus(false);
				calculateBannerHeight();
			});
	};

	const synchronizeAgendaAndMinutes = () => {
		setSavingMinutesStatus(true);
		dispatch(getMeetingMinutesItems(meetingId)).then((minutesData) => {
			const copyOfOldMinutes = clonedeep(minutesData.items);
			const minutesItems = minutesData.items;
			const sortedItems = sortMinuteItemsByAgendaItems(items, minutesItems);

			telemetryAddEvent("Agenda builder agenda minutes sync order");

			dispatch(saveSyncedMinutes(meetingId, sortedItems, copyOfOldMinutes))
				.then(() => {
					const option = notifierMessage(t("minutesAgendaSyncSuccess"), "success", () => undoSaveSyncedMinutes());
					dispatch(setSnackbarOptions(option));
					dispatch(updateOutOfSyncStatus(false));
				})
				.catch(() => {
					const option = notifierMessage(t("minutesAgendaSyncError"), "error");
					dispatch(setSnackbarOptions(option));
				})
				.finally(() => {
					setSavingMinutesStatus(false);
					calculateBannerHeight();
				});
		});
	};

	const calculateBannerHeight = () => setBannerHeight(outOfSyncBannerRef.current?.clientHeight || 0);

	useEffect(() => {
		calculateBannerHeight();
	});

	const outline = (isSplitPane = false) => (
		<TableOfContentsContainer
			containerClass={isSplitPane ? classes.splitContentTableOfContentContainer : classes.tableOfContentContainer}
			innerId="outline"
		>
			<TableOfContents
				isReorderable={!minutesOutOfSync}
				items={items}
				isClosed={true}
				buttons
				active={active}
				setActive={setActive}
				telemetryPage={telemetryPage}
				isAgenda
			/>
		</TableOfContentsContainer>
	);

	const builder = (isSplitPane = false) => (
		<Box>
			<Box
				id="toolbar"
				className={`${classes.stickyToolbar} ${!isSplitPane ? classes.stickyToolbarPosition : ""}`}
				ref={editorToolbarRef}
			/>
			<AgendaSingletonEditor
				isSplitPane={isSplitPane}
				queueFileUploads={queueFileUploads}
				showNotes={showNotes}
				editorToolbarRef={editorToolbarRef}
				id={meetingId}
				updateMeeting={updateMeeting}
				addPolicy={addPolicy}
				addGoals={addGoals}
				requestedItemGuid={requestedItemGuid}
				isReorderable={!minutesOutOfSync}
			/>
		</Box>
	);

	useEffect(() => {
		const pane1 = document.getElementsByClassName("Pane1")[0];
		const pane2 = document.getElementsByClassName("Pane2")[0];
		const agendaEditor = document.getElementById("new-editor-toc-header");
		if (!showTableOfContents || (showTableOfContents && widthDownMd)) {
			if (pane1 && pane2 && agendaEditor) {
				pane1.style.width = 0;
				pane2.style.width = "100%";
				agendaEditor.style.maxWidth = "100%";
			}
		} else {
			if (pane1 && pane2 && agendaEditor) {
				pane1.style.width = "30%";
				pane2.style.width = "70%";
				agendaEditor.style.maxWidth = "8.5in";
			}
		}
	}, [showTableOfContents, widthDownMd]);

	return (
		<div className="container">
			{minutesOutOfSync && (
				<div ref={outOfSyncBannerRef} className={classes.minutesOutOfSyncBanner}>
					<NoticeCard
						icon="status-alert"
						label={
							<div className={classes.minutesOutOfSyncBannerMessage}>
								<div role="status">{t("minutesOutofsyncWarning")}</div>
								<div>
									<Link
										component="button"
										variant="body2"
										classes={{
											root: classes.syncMinutesagendaButton,
										}}
										onClick={() => synchronizeAgendaAndMinutes()}
									>
										{isSavingMinutes ? <CircularProgress size={22} thickness={6} /> : t("syncAgendaAndminutes")}
									</Link>
								</div>
							</div>
						}
						status={STATUS_WARNING}
						processHtml={processHtml}
						thickStatus
						iconColor={warningColor}
					/>
				</div>
			)}
			{showSplitPane && (
				<div>
					<SplitPane
						allowResize={!widthDownMd}
						className="split-pane"
						split="vertical"
						minSize={220}
						maxSize={710}
						defaultSize={parseInt(localStorage.getItem("splitPos"), 10) || (widthDownMd ? 0 : 360)}
						onChange={(size) => {
							localStorage.setItem("splitPos", size);

							telemetryAddEvent("Agenda builder outline resize");
						}}
					>
						<div className="pane-left">{outline(true)}</div>
						<div className="pane-right">{builder(true)}</div>
					</SplitPane>
				</div>
			)}
		</div>
	);
};

export default AgendaEditorV2;
