import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Box, Link } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Alert from '@mui/material/Alert';
import Icon from '../Icon/Icon';
import AccessibleIconButton from '../Buttons/AccessibleIconButton';
import clsx from 'clsx';
import ClickAwayListener from '@mui/material/ClickAwayListener';

import {
	blackColor,
	warningColor,
	infoColor,
	successColor,
	errorColor,
	defaultFont,
	whiteColor,
	primaryColor,
} from '../../assets/jss/shared';

import notificationStyle from '../../assets/jss/components/notificationStyle';

const useNotificationStyle = makeStyles(notificationStyle);
const secondaryActionStyle = makeStyles({
	root: {
		margin: '8px',
		pointerEvents: 'all',
		'& .MuiAlert-message': {
			minHeight: '70px',
			width: '340px',
		},
		padding: '0px',
	},
	standardSuccess: {
		backgroundColor: whiteColor,
		borderLeft: `6px solid ${successColor}`,
		color: blackColor[1],
	},
	standardInfo: {
		backgroundColor: whiteColor,
		borderLeft: `6px solid ${infoColor}`,
		color: blackColor[1],
	},
	standardError: {
		backgroundColor: whiteColor,
		borderLeft: `6px solid ${errorColor}`,
		color: blackColor[1],
	},
	standardWarning: {
		backgroundColor: whiteColor,
		borderLeft: `6px solid ${warningColor}`,
		color: blackColor[1],
	},
	message: {
		...defaultFont,
		fontWeight: '400',
		fontSize: '16px',
		lineHeight: '20px',
		letterSpacing: '0px',
		color: blackColor,
		'&> svg': {
			margin: '0 10px',
			position: 'relative',
			top: '2px',
		},
	},
	action: {
		...defaultFont,
		paddingBottom: '2px',
		fontSize: '16px',
		lineHeight: '20px',
		letterSpacing: '0px',
		color: blackColor[1],
		'&:hover': {
			color: blackColor[1],
			textDecoration: 'none',
		},
	},
	actionsLabels: {
		height: '14px',
		width: '96px',
		color: primaryColor[1],
		fontWeight: '600',
		lineheight: '14px',
		letterSpacing: '0px',
		textAlign: 'left',
		fontSize: '16px',
		lineHeight: '14px',
	},
	divider: {
		height: '1px',
		width: '100%',
		backgroundColor: '#E6E6E6',
		marginTop: '10px',
	},
	secondaryActionContainer: {
		display: 'flex',
		justifyContent: 'flex-end',
		padding: '10px 10px',
		marginRight: '10px',
	},
	iconAndMessageContainer: {
		display: 'flex',
		alignItems: 'center',
	},
	iconContainer: {
		width: '16px',
		height: '16px',
		flexShrink: '0',
		margin: '0 10px',
	},
	messageContainer: {
		margin: '0 8px',
		wordBreak: 'break-word',
	},
});

const Notification = React.forwardRef((props, ref) => {
	const {
		key,
		severity = 'success',
		action,
		actionLabel,
		actionIconName,
		iconName,
		message,
		persist = false,
		dismissAction,
		secondaryAction,
		secondaryActionLabel,
		clickAwayEnabled,
	} = props;
	const { t } = useTranslation();
	const classes = useNotificationStyle();
	const secondaryActionClasses = secondaryActionStyle();

	let iconColor = successColor;

	useEffect(() => {
		const note = document?.querySelector("div[class*='notistack-SnackbarContainer']");
		if (note) {
			note.style.zIndex = '10001';
		}
	}, []);
	switch (severity) {
		case 'error':
			iconColor = errorColor;
			break;
		case 'info':
			iconColor = infoColor;
			break;
		case 'warning':
			iconColor = warningColor;
			break;
		default: {
			iconColor = successColor;
		}
	}
	let actionProp = null;
	if (action && (actionLabel || actionIconName)) {
		actionProp = (
			<Box ml={1} component="span">
				<Link
					ml={3}
					component="button"
					className={classes.action}
					onClick={(e) => {
						action(e);
						dismissAction(key);
					}}
					data-cy="snackbarAction"
				>
					{actionIconName && <Icon name={actionIconName} color={blackColor[1]} />}
					<div className={classes.actionsLabels}>{actionLabel && actionLabel}</div>
				</Link>
			</Box>
		);
	}
	let divider = null;
	if (secondaryAction && (secondaryActionLabel || actionIconName)) {
		divider = <div className={secondaryActionClasses.divider}></div>;
	}
	let secondaryProp = null;
	if (secondaryAction && (secondaryActionLabel || actionIconName)) {
		secondaryProp = (
			<Box ml={1} className={secondaryActionClasses.secondaryActionContainer}>
				<Box>
					<Link
						ml={3}
						component="button"
						className={classes.action}
						onClick={(e) => {
							dismissAction(key);
						}}
						data-cy="snackbarAction"
						aria-label="Dismiss"
					>
						{actionIconName && <Icon name={actionIconName} color={blackColor[1]} />}
						<div className={secondaryActionClasses.actionsLabels}>Dismiss</div>
					</Link>
				</Box>
				<Box>
					<Link
						ml={3}
						component="button"
						className={classes.action}
						onClick={(e) => {
							secondaryAction();
						}}
						data-cy="snackbarAction"
						aria-label={secondaryActionLabel}
					>
						{actionIconName && <Icon name={actionIconName} color={blackColor[1]} />}
						<div className={secondaryActionClasses.actionsLabels}>{secondaryActionLabel && secondaryActionLabel}</div>
					</Link>
				</Box>
			</Box>
		);
	}
	return (
		<>
			{clickAwayEnabled ? (
				<ClickAwayListener onClickAway={() => dismissAction(key)}>
					<Alert
						classes={secondaryAction ? secondaryActionClasses : classes}
						elevation={6}
						icon={false}
						severity={severity}
						ref={ref}
						data-cy="notification"
					>
						{secondaryProp ? (
							<div className={secondaryActionClasses.iconAndMessageContainer}>
								{iconName && (
									<div className={secondaryActionClasses.iconContainer}>
										<Icon name={iconName} color={iconColor} size="16px" />
									</div>
								)}
								<span className={secondaryActionClasses.messageContainer}>{message}</span>
							</div>
						) : (
							<>
								{iconName && <Icon name={iconName} color={iconColor} size="16px" />}
								{message}
							</>
						)}
						{secondaryProp && divider}
						{actionProp && actionProp}
						{secondaryProp && secondaryProp}
						{persist && (
							<AccessibleIconButton
								id="notification-close"
								onClick={() => dismissAction(key)}
								iconName="close"
								tooltipText={t('buttons.close')}
								dataCy="notification-close"
							/>
						)}
					</Alert>
				</ClickAwayListener>
			) : (
				<Alert
					classes={secondaryAction ? secondaryActionClasses : classes}
					elevation={6}
					icon={false}
					severity={severity}
					ref={ref}
					data-cy="notification"
				>
					{secondaryProp ? (
						<div className={secondaryActionClasses.iconAndMessageContainer}>
							{iconName && (
								<div className={secondaryActionClasses.iconContainer}>
									<Icon name={iconName} color={iconColor} size="16px" />
								</div>
							)}
							<span className={secondaryActionClasses.messageContainer}>{message}</span>
						</div>
					) : (
						<>
							{iconName && <Icon name={iconName} color={iconColor} size="16px" />}
							{message}
						</>
					)}
					{secondaryProp && divider}
					{actionProp && actionProp}
					{secondaryProp && secondaryProp}
					{persist && (
						<AccessibleIconButton
							id="notification-close"
							onClick={() => dismissAction(key)}
							iconName="close"
							tooltipText={t('buttons.close')}
							dataCy="notification-close"
						/>
					)}
				</Alert>
			)}
		</>
	);
});

Notification.propTypes = {
	severity: PropTypes.string,
	action: PropTypes.func,
	actionLabel: PropTypes.string,
	actionIconName: PropTypes.string,
	iconName: PropTypes.string,
	message: PropTypes.string,
};

export default Notification;
