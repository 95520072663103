/* eslint-disable react/no-array-index-key */
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import makeStyles from "@mui/styles/makeStyles";
import Box from "@mui/material/Box";
import List from "@mui/material/List";

import { Checkbox, FormControlLabel, ListItem } from "@mui/material";
import { Check } from "components/Icons";
import GenericDialog from "atlas/components/Dialogs/GenericDialog";
import Icon from "atlas/components/Icon/Icon";
import { grayColor } from "atlas/assets/jss/shared";

const useStyles = makeStyles({
	list: {
		overflow: "auto",
		height: "380px",
		border: `1px solid ${grayColor[0]}`,
		borderRadius: "3px",
	},
	userListItem: {
		padding: "0 0 12px 0",
	},
	dialog: {
		"& .MuiDialog-paper": {
			width: "500px",
		},
	},
});

const SendPasswordSetupRequestDialog = (props) => {
	const { show, users, handleSendPasswordSetup, handleCancel } = props;
	const { t } = useTranslation("users");
	const classes = useStyles();
	const [sending, setSending] = useState(false);
	const [selectedUsers, setSelectedUsers] = useState([]);

	const onChange = (id) => {
		const selectedUserIndex = selectedUsers.indexOf(id);
		if (selectedUserIndex >= 0) {
			setSelectedUsers((prev) => {
				prev.splice(selectedUserIndex, 1);
				return [...prev];
			});
		} else {
			setSelectedUsers((prev) => {
				prev.push(id);
				return [...prev];
			});
		}
	};

	const onChangeSelectAll = () => {
		const selected = [];
		if (selectedUsers.length !== users.length) {
			users.forEach((user) => {
				selected.push(user.id);
			});
		}
		setSelectedUsers(selected);
	};

	return (
		<GenericDialog
			className={classes.dialog}
			show={show}
			title={t("passwordSetupRequestDialog.title")}
			primaryAction={() => {
				setSending(true);
				handleSendPasswordSetup(selectedUsers);
			}}
			primaryTitle={t("app:buttons.send")}
			secondaryAction={handleCancel}
			secondaryTitle={t("app:buttons.cancel")}
			primaryDisabled={sending || selectedUsers.length === 0}
			clickAwayDisabled={sending}
			closeIcon={<Icon name="close" />}
			data-cy="send-password-setup-dialog"
			disableAutoFocus
			disableEnforceFocus
		>
			<Box mt={1} mb={1}>
				<FormControlLabel
					control={
						<Checkbox
							key={`select-all`}
							checkedIcon={<Check fontSize="small" color="primary" />}
							inputProps={{
								"aria-label": `Select all users`,
								"aria-labelledby": "select-all-label",
							}}
							checked={selectedUsers.length === users.length}
							onChange={() => {
								onChangeSelectAll();
							}}
							data-cy={`select-all`}
						/>
					}
					label={t("app:selectAll")}
				/>
				<List className={classes.list}>
					{users &&
						users.map((user) => {
							const { id, emailAddress } = user;
							return (
								<ListItem key={`user-${id}`} className={classes.userListItem}>
									<FormControlLabel
										control={
											<Checkbox
												key={`select-user-password-setup-${id}`}
												checkedIcon={<Check fontSize="small" color="primary" />}
												inputProps={{
													"aria-label": `Select ${emailAddress}`,
													"aria-labelledby": `user-label-${id}`,
												}}
												checked={selectedUsers.indexOf(id) >= 0}
												onChange={() => {
													onChange(id);
												}}
												data-cy={`select-user-${id}`}
											/>
										}
										label={emailAddress}
									/>
								</ListItem>
							);
						})}
				</List>
			</Box>
		</GenericDialog>
	);
};

export default SendPasswordSetupRequestDialog;
