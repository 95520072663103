/* eslint-disable no-plusplus */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import map from 'lodash/fp/map';

import { Grid, Box, Container } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';

import { useWidthDown } from '../../utils/useWidth';
import AccessibleIconButton from '../Buttons/AccessibleIconButton';
import ButtonWithTooltip from '../Buttons/ButtonWithTooltip';
import NonModalMenu from '../Menu/NonModalMenu';
import styles from '../../assets/jss/components/pageHeaderStyle';
import Icon from '../../../atlas/components/Icon/Icon';
import { refreshCurrentMeetings } from 'redux/pageHeader/actions';
import debounce from 'lodash/debounce';
import CircularProgressIndicator from '../../../atlas/components/Progress/CircularProgressIndicator';

const useStyles = makeStyles(styles);

const PageHeader = () => {
	const widthDownSm = useWidthDown('sm');
	const widthDownxs = useWidthDown('450');
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const [anchor, setAnchor] = useState({});
	const appReducer = useSelector((state) => state.appReducer);
	const pageHeaderReducer = useSelector((state) => state.pageHeaderReducer);
	const {
		title,
		pageHeaderTitle,
		secondaryAction,
		secondaryActionText,
		secondaryActionTooltip,
		secondaryActionDisabled,
		tertiaryAction,
		tertiaryActionText,
		tertiaryActionTooltip,
		tertiaryActionDisabled,
		contentMaxWidth,
		tabs: { isLoading },
	} = appReducer;
	const {
		additionalText,
		primaryAction,
		primaryActionText,
		primaryActionTooltip,
		primaryActionDisabled,
		leftMenuOptions,
		menuOptions,
		displayPageHeader,
		additionalRightAction,
		primaryActionDisabledBackground,
		exportButtonAction,
		exportButtonText,
		exportButtonTooltip,
		addUsersButtonAction,
		addUsersButtonText,
		addUsersButtonTooltip,
		isRefreshing,
		showRefreshButton,
	} = pageHeaderReducer;
	const classes = useStyles();

	const handleToggleLeftMenu = (e) => {
		e.stopPropagation();
		e.preventDefault();
		setAnchor((prev) => ({ left: !prev.left ? e.currentTarget : undefined }));
	};

	const handleToggleOverflowMenu = (e) => {
		e.stopPropagation();
		e.preventDefault();
		setAnchor((prev) => ({ right: !prev.right ? e.currentTarget : undefined }));
	};

	const handleCloseMenu = () => {
		setAnchor({});
	};

	const debouncedRefreshMeetings = debounce(() => dispatch(refreshCurrentMeetings()), 500);

	document.title = ((title || '').length > 0 ? `${title} - ` : '') + t('productName');
	let count = 0;
	const rightSectionPresent =
		additionalRightAction ||
		exportButtonAction ||
		addUsersButtonAction ||
		primaryAction ||
		secondaryAction ||
		tertiaryAction ||
		showRefreshButton ||
		(menuOptions && menuOptions.length > 0);

	return displayPageHeader ? (
		<div className={classes.root}>
			<Container className={classes.container} maxWidth={contentMaxWidth}>
				<Grid className={classes.grid} container>
					<Grid className={classes.pageTitleGrid} item xs={rightSectionPresent ? 9 : 12} sm={rightSectionPresent ? 9 : 12}>
						<Box display="flex" alignItems="center" marginRight={rightSectionPresent ? 5 : 0}>
							{leftMenuOptions && leftMenuOptions.length > 0 ? (
								<>
									<AccessibleIconButton
										id="left-menu-button"
										className={classes.menuButton}
										iconColor="#ffffff"
										aria-label={t('menu.options')}
										onClick={handleToggleLeftMenu}
										iconName="more-horizontal"
										iconSize="20px"
										dataCy="left-menu-button"
									/>
									{anchor.left && (
										<NonModalMenu
											id="left-menu"
											className={classes.menu}
											anchorEl={anchor.left}
											keepMounted
											open
											onClose={handleCloseMenu}
											options={leftMenuOptions}
											position="bottom-start"
										/>
									)}
								</>
							) : null}
							<div>
								<h1
									id="ui-name"
									className={widthDownxs ? classes.pageTitleXSmall : widthDownSm ? classes.pageTitleSmall : classes.pageTitle}
									data-cy="page-title"
								>
									{title || pageHeaderTitle}
								</h1>
								<div>
									{map(
										(data) => (
											<div key={`data-${++count}`}>
												<Typography variant="overline" style={{ color: '#fff' }} data-cy="headerAdditionalText">
													{data}
												</Typography>
											</div>
										),
										additionalText,
									)}
								</div>
							</div>
						</Box>
					</Grid>
					{rightSectionPresent && (
						<Grid className={classes.buttonGrid} item xs={3} sm={3}>
							<Box display="flex" alignItems="center" justifyContent="flex-end" height="100%" gap="8px">
								{additionalRightAction && <>{additionalRightAction}</>}
								{exportButtonAction && exportButtonText && (
									<ButtonWithTooltip
										className={classes.ExportButton}
										onClick={() => exportButtonAction()}
										dataCy="pageHeaderExportButtonAction"
										title={exportButtonTooltip || ''}
									>
										<div className={classes.ExportButtonWithIcon}>
											{
												<span>
													<Icon name={'export-list'} />
												</span>
											}
											<div>{exportButtonText}</div>
										</div>
									</ButtonWithTooltip>
								)}
								{addUsersButtonAction && addUsersButtonText && (
									<ButtonWithTooltip
										className={classes.disableButon}
										primary
										onClick={() => addUsersButtonAction()}
										dataCy="pageHeaderAddUsersButtonAction"
										title={addUsersButtonTooltip || ''}
										disabled={primaryActionDisabled}
									>
										<div className={classes.ExportButtonWithIcon}>
											<span>
												<Icon name="external-link" size="24px" color="#FFFFFF" data-cy="externalLink" />
											</span>
											<div>{addUsersButtonText}</div>
										</div>
									</ButtonWithTooltip>
								)}
								<span
									style={{
										visibility: isLoading ? 'visible' : 'hidden',
									}}
								>
									<CircularProgressIndicator minHeight={0} size={24} padding={0} />
								</span>
								{showRefreshButton && (
									<ButtonWithTooltip
										className={classes.refreshButton}
										onClick={() => debouncedRefreshMeetings()}
										dataCy="pageHeaderRefreshButtonAction"
										title={t('buttons.refreshCurrentMeetings')}
									>
										<span style={{ display: 'flex' }} className={`${isRefreshing ? classes.refreshButtonInProgress : ''}`}>
											<Icon name={'reload'} color={'#fff'} size={'24px'} />
										</span>
									</ButtonWithTooltip>
								)}
								{primaryAction && primaryActionText && (
									<ButtonWithTooltip
										className={primaryActionDisabledBackground ? classes.disableButon : classes.button}
										primary
										onClick={() => primaryAction()}
										dataCy="pageHeaderPrimaryAction"
										title={primaryActionTooltip || ''}
										disabled={primaryActionDisabled}
									>
										{primaryActionText}
									</ButtonWithTooltip>
								)}
								{/* This is temporary and will be gone when we redo the pageheader buttons styles in policy */}
								{tertiaryAction && tertiaryActionText && (
									<ButtonWithTooltip
										className={classes.button}
										onClick={() => tertiaryAction()}
										dataCy="pageHeaderTertiaryAction"
										primary
										title={tertiaryActionTooltip || ''}
										disabled={tertiaryActionDisabled}
										style={{ marginLeft: '8px' }}
									>
										{tertiaryActionText}
									</ButtonWithTooltip>
								)}
								{secondaryAction && secondaryActionText && (
									<ButtonWithTooltip
										onClick={() => secondaryAction()}
										dataCy="pageHeaderSecondaryAction"
										secondary
										pageHeader
										title={secondaryActionTooltip || ''}
										disabled={secondaryActionDisabled}
									>
										{secondaryActionText}
									</ButtonWithTooltip>
								)}
								{menuOptions && menuOptions.length > 0 && (
									<>
										<AccessibleIconButton
											id="pageHeaderOverflowMenuButton"
											iconColor="#ffffff"
											aria-label="pageHeaderOverflowMenuButton"
											onClick={(e) => handleToggleOverflowMenu(e)}
											iconName="more"
											dataCy="pageHeaderOverflowMenuButton"
											tooltipText={t('menu.options')}
											isHeader
											isPageHeader
										/>
										{anchor.right && (
											<NonModalMenu
												id="pageHeaderOverflowMenu"
												className={classes.menu}
												anchorEl={anchor.right}
												keepMounted
												open
												onClose={handleCloseMenu}
												options={menuOptions}
												position="bottom-end"
											/>
										)}
									</>
								)}
							</Box>
						</Grid>
					)}
				</Grid>
			</Container>
		</div>
	) : null;
};

export default PageHeader;
