import React from "react";
import { useTranslation } from "react-i18next";

import makeStyles from "@mui/styles/makeStyles";
import { MenuItem } from "@mui/material";

import permissionsStyle from "assets/jss/components/permissionsStyle";
import SelectInput from "atlas/components/FormControls/SelectInput";
import SearchInput from "components/Search/SearchInput";

const usePermissionsStyles = makeStyles(permissionsStyle);
const useStyles = makeStyles(() => ({
	select: {
		margin: "0",
		height: "auto",
		"& .MuiInputBase-root": {
			margin: "0",
		},
	},
}));

const FolderPermissionsFilter = (props) => {
	const { filter, updateFilter, updateLabelText, updateOnly = false } = props;
	const { t } = useTranslation("documents");
	const permissionsClasses = usePermissionsStyles();
	const classes = useStyles();

	const handleSearchChange = (e) => {
		if (e.target.id === "search-users") {
			updateFilter(e, "search");
		}
	};

	const menuSelect = (e) => {
		updateFilter(e, "editOnly", false, true, undefined, (newValue) => newValue === 2);
		updateLabelText(e.target.value);
	};
	return (
		<div className={permissionsClasses.columns}>
			<div>
				<SearchInput
					id="search-users"
					size="small"
					placeholder={t("customFolderDetail.placeholders.searchUsers")}
					fullWidth
					value={filter.search}
					showClearIcon={filter.search?.length > 0}
					onKeyDown={handleSearchChange}
					onChange={handleSearchChange}
					onClearClick={() => {
						updateFilter({ target: { value: "" } }, "search");
					}}
					data-cy="search-users"
				/>
			</div>
			<div>
				{!updateOnly && (
					<SelectInput
						className={classes.select}
						size="small"
						value={filter.editOnly ? 2 : 1}
						onChange={(e) => {
							menuSelect(e);
						}}
						data-cy="filter-permissions"
					>
						<MenuItem key={1} value={1} data-cy="filter-has-access">
							<div>{t("customFolderDetail.options.hasAccess")}</div>
						</MenuItem>
						<MenuItem key={2} value={2} data-cy="filter-edit-access">
							<div>{t("customFolderDetail.options.editAccess")}</div>
						</MenuItem>
					</SelectInput>
				)}
			</div>
		</div>
	);
};

export default FolderPermissionsFilter;
