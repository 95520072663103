import React, { useEffect, useContext } from "react";
import PublicSiteDisplayOptions from "./PublicSiteDisplayOptions";
import SocialMedia from "./SocialMedia";
import ICSCalender from "./ICSCalender";
import DisplayOptions from "./DisplayOptions";
import { useSelector, useDispatch } from "react-redux";
import { setSettingError } from "redux/publicSite/actions";
import { SettingsContext } from "contexts/Settings/SettingsContext";
const PublicSiteSettings = (props) => {
	const { settings, updateSettingsValue } = props;
	const { lite } = useSelector((state) => state.publicSiteReducer);
	const { communityMeetings } = useContext(SettingsContext);
	const dispatch = useDispatch();

	useEffect(
		() => () => {
			dispatch(setSettingError({}));
		},
		[],
	);

	return settings ? (
		<>
			<PublicSiteDisplayOptions />
			{communityMeetings && (
				<>
					<SocialMedia />
					{!lite && <ICSCalender />}
				</>
			)}
			<DisplayOptions settings={settings} updateSettingsValue={updateSettingsValue} />
		</>
	) : null;
};
export default PublicSiteSettings;
