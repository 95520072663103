import React from "react";

export const Freehand = () => (
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M18.9323 1.08213C19.4603 0.554418 20.1762 0.258035 20.9226 0.258179C21.669 0.258323 22.3849 0.554981 22.9126 1.08289C23.4403 1.61081 23.7367 2.32673 23.7365 3.07316C23.7364 3.8196 23.4397 4.53541 22.9118 5.06312L22.8976 5.07693L17.348 10.6262C17.3458 10.6283 17.3437 10.6305 17.3415 10.6326L6.24841 21.7261C6.17371 21.8008 6.08406 21.8588 5.98536 21.8965L1.28098 23.6909C1.00502 23.7961 0.692968 23.7296 0.483906 23.521C0.274843 23.3123 0.207698 23.0004 0.312394 22.7243L2.09889 18.0116C2.13653 17.9124 2.1948 17.8222 2.26987 17.7471L13.3699 6.64752C13.372 6.64543 13.3741 6.64335 13.3762 6.64129L17.685 2.33013L17.5271 2.17205L17.5269 2.1719L17.5268 2.17178C17.4085 2.05351 17.268 1.95968 17.1134 1.89565C16.9588 1.83157 16.7929 1.79858 16.6255 1.79858C16.4581 1.79858 16.2923 1.83157 16.1376 1.89565C15.9829 1.95973 15.8423 2.05365 15.7239 2.17205L15.7239 2.17207L10.4034 7.49257C10.1105 7.78546 9.63563 7.78546 9.34274 7.49257C9.04985 7.19968 9.04985 6.7248 9.34274 6.43191L14.6632 1.11143L14.6632 1.11141C14.9209 0.853715 15.2268 0.6493 15.5635 0.509835C15.9002 0.370368 16.2611 0.298584 16.6255 0.298584C16.9899 0.298584 17.3508 0.370368 17.6875 0.509835C18.0242 0.649303 18.3301 0.853724 18.5878 1.11143L18.5881 1.1117L18.7454 1.26919L18.9321 1.08237L18.9323 1.08213ZM14.9607 7.17779L16.8178 9.03509L21.846 4.00721L21.859 3.9945C22.1008 3.7488 22.2364 3.41784 22.2365 3.07287C22.2366 2.72426 22.0982 2.3899 21.8517 2.14335C21.6052 1.8968 21.2709 1.75825 20.9223 1.75818C20.5738 1.75811 20.2396 1.89644 19.993 2.14275L19.9928 2.14299L19.3099 2.82627C19.2992 2.83844 19.288 2.85036 19.2764 2.86198C19.265 2.87331 19.2534 2.8842 19.2415 2.89465L14.9607 7.17779ZM2.30709 21.6941L3.09021 19.6283L4.36927 20.9075L2.30709 21.6941ZM3.86083 18.2775L5.71809 20.135L15.7571 10.0957L13.9002 8.23854L3.86083 18.2775Z"
			fill="white"
		/>
	</svg>
);
