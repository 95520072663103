/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import clsx from 'clsx';

import IconButton from '@mui/material/IconButton';
import makeStyles from '@mui/styles/makeStyles';

import AccessibleIconButton from '../Buttons/AccessibleIconButton';
import Icon from '../Icon/Icon';
import Tooltip from '../Tooltip/Tooltip';

import styles from '../../assets/jss/components/bottomNoticeBarStyle';
import { blackColor } from '../../assets/jss/shared';

const useStyles = makeStyles(styles);

const BottomNoticeBar = (props) => {
	const { show, icon, label, preText, postText, action, canClose, onClose, closeTooltip, data, persistentData, updateBottomNotice } = props;
	const dispatch = useDispatch();
	const openLeftNav = useSelector((state) => state.appReducer.openLeftNav);
	const classes = useStyles({ openLeftNav });

	const onCloseDefault = (e) => {
		e.stopPropagation();
		e.preventDefault();

		if (typeof updateBottomNotice === 'function') {
			dispatch(updateBottomNotice({}));
		}
	};

	const hasAction = typeof action === 'function';
	const role = hasAction ? 'button' : undefined;
	const tabIndex = hasAction ? 0 : undefined;
	const onClick = hasAction
		? (e) => {
				e.preventDefault();
				e.stopPropagation();

				if (typeof action === 'function') {
					action();
				}
			}
		: undefined;

	return (
		(!show || show(data, persistentData)) &&
		label.length > 0 && (
			<footer className={classes.footer} data-cy="bottom-notice-container">
				<div
					className={clsx(classes.footerToolbar, {
						[classes.footerAction]: hasAction,
					})}
					role={role}
					tabIndex={tabIndex}
					onClick={onClick}
					aria-label={label}
				>
					<div className={classes.contentContainer}>
						{icon.length > 0 && (
							<Tooltip title={label}>
								<IconButton className={classes.icon} aria-label={label} size="large">
									<Icon name={icon} color={blackColor[1]} />
								</IconButton>
							</Tooltip>
						)}
						<div className={classes.content}>
							{preText.length > 0 && <div className={clsx(classes.secondaryText, classes.preText)}>{preText}</div>}
							<Tooltip title={label}>
								<div className={classes.primaryText}>{label}</div>
							</Tooltip>
							{postText.length > 0 && <div className={clsx(classes.secondaryText, classes.postText)}>{postText}</div>}
						</div>
					</div>
					{canClose && (
						<div className={classes.closeButton}>
							<AccessibleIconButton
								id="close-bottom-bar"
								color="inherit"
								aria-label={closeTooltip}
								onClick={onClose || onCloseDefault}
								iconName="close"
								dataCy="close-bottom-bar"
								tooltipText={closeTooltip}
								isHeader
							/>
						</div>
					)}
				</div>
			</footer>
		)
	);
};

BottomNoticeBar.propTypes = {
	show: PropTypes.func,
	icon: PropTypes.string,
	label: PropTypes.string,
	preText: PropTypes.string,
	postText: PropTypes.string,
	action: PropTypes.func,
	canClose: PropTypes.bool,
	closeTooltip: PropTypes.string,
	updateBottomNotice: PropTypes.func,
};

BottomNoticeBar.defaultProps = {
	show: undefined,
	icon: '',
	label: '',
	preText: '',
	postText: '',
	action: undefined,
	canClose: false,
	closeTooltip: undefined,
	updateBottomNotice: undefined,
};

export default BottomNoticeBar;
