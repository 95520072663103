import React, { Component } from "react";

import Button from "@mui/material/Button";

import Tooltip from "atlas/components/Tooltip/Tooltip";

class SaveCancelButtons extends Component {
	render() {
		const { save, cancel, saveLabel, cancelLabel, saveEnabled, disabledTooltip } = this.props;

		const tooltipPopperProps = {
			disablePortal: true,
		};

		const saveButton = saveEnabled ? (
			<Button id="save-button" onClick={save} variant="contained" size="small" color="primary" data-cy="Save">
				{saveLabel}
			</Button>
		) : (
			<Tooltip PopperProps={tooltipPopperProps} title={disabledTooltip}>
				<span>
					<Button id="save-button" onClick={save} variant="contained" size="small" color="primary" disabled data-cy="Save">
						{saveLabel}
					</Button>
				</span>
			</Tooltip>
		);

		return (
			<div>
				{saveButton}
				<Button id="cancel-button" size="small" onClick={cancel} variant="outlined" data-cy="Cancel">
					{cancelLabel}
				</Button>
			</div>
		);
	}
}

export default SaveCancelButtons;
