import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import request from "superagent";
import { API_HOST } from "config/env";

import { CellMeasurerCache } from "react-virtualized";

import { Button } from "@mui/material";

import ComponentContainer from "atlas/components/ComponentContainer/ComponentContainer";
import withErrorHandling from "components/ErrorHOC";
import RightPanelContainer, { MD_DOWN_WIDTH } from "components/Panels/RightPanelContainer";
import FilterPanel from "components/Panels/FilterPanel";
import SubscribersVirtualizedList from "./components/SubscribersVirtualizedList";
import SubscribersFilter from "./components/SubscribersFilter";

import telemetryAddEvent from "utils/telemetryAddEvent";
import { resetPageConfigs, updatePageConfigs, updateToolbar } from "redux/app/actions";
import { updatePageHeader } from "redux/pageHeader/actions";
import { setSnackbarOptions } from "../../redux/snackBar/actions";
import notifierMessage from "utils/notifierMessage";

const telemetryPage = "Subscribers";
const defaultFilter = {
	emailAddress: "",
	searchLowerCase: "",
	meetingTypes: [],
	publicYes: null,
	publicNo: null,
	activeYes: null,
	activeNo: null,
};
const PAGE_SIZE = 100;

const SubscribersModule = (props) => {
	const { showSignIn } = props;
	const { t } = useTranslation("subscribers");
	const navigate = useNavigate();
	const { closeSnackbar } = useSnackbar();
	const dispatch = useDispatch();
	const [subscribers, setSubscribers] = useState(null);
	const [forceReload, setForceReload] = useState(0);
	const [estimatedTotalRows, setEstimatedTotalRows] = useState(Number.MAX_VALUE);
	const [subscriptionToDelete, setSubscriptionToDelete] = useState(null);
	const [meetingTypes, setMeetingTypes] = useState(null);
	const [subscribersCount, setSubscribersCount] = useState(null);
	const [panels, setPanels] = useState({});
	const [filter, setFilter] = useState(defaultFilter);
	const [filtered, setFiltered] = useState(false);
	const [sort, setSort] = useState();

	const cache = new CellMeasurerCache({
		fixedWidth: true,
		defaultHeight: 48,
	});

	const mobileCache = new CellMeasurerCache({
		defaultHeight: 48,
	});

	const handleActiveClick = (subscription) => {
		const active = !subscription.active;
		const userEmail = subscription.userEmail || "";

		if (userEmail.length > 0) {
			request
				.put(`${API_HOST}/api/subscription/${userEmail}`)
				.send({ active: active })
				.then((res) => {
					telemetryAddEvent(`${telemetryPage} - ${!active ? "Deactivate" : subscription.public ? "Send Confirmation" : "Activate"}`);

					let option = notifierMessage(
						t(
							`list.notification.${res.body.ConfirmationSent ? "confirmationSent" : res.body.Active ? "activeSuccess" : "inactiveSuccess"}`,
						),
						"success",
					);
					dispatch(setSnackbarOptions(option));

					setSubscribers((prev) => {
						prev.forEach((prevSub) => {
							if (prevSub.userEmail === userEmail) {
								if (!subscription.public) {
									prevSub.active = active;
								} else if (!active) {
									prevSub.active = active;
								}
							}
						});
						return [...prev];
					});
				})
				.catch((err) => {
					console.log(err);
				});
		}
	};

	const handleDeleteClick = (subscription) => {
		const userEmail = subscription.userEmail || "";

		if (userEmail.length > 0 && userEmail != subscriptionToDelete) {
			setSubscriptionToDelete(userEmail);
			request
				.del(`${API_HOST}/api/subscription/${userEmail}`)
				.then(() => {
					telemetryAddEvent(`${telemetryPage} - Delete`);

					let option = notifierMessage(t("list.notification.deleteSuccess"), "success", () => undoDelete(subscription.userEmail));
					dispatch(setSnackbarOptions(option));

					setSubscribers((prev) => {
						var updatedSubscriptions = prev.filter((prevSub) => prevSub.userEmail !== userEmail);

						// If there are no more subscriptions to load, set the estimated total rows to the total number of subscriptions
						setEstimatedTotalRows((prev) => {
							return prev < Number.MAX_VALUE ? updatedSubscriptions.length : prev;
						});

						return updatedSubscriptions;
					});
					setSubscriptionToDelete(null);
				})
				.catch((err) => {
					console.log(err);
					setSubscriptionToDelete(null);
				});
		}
	};

	const undoDelete = (userEmail) => {
		closeSnackbar();
		request
			.post(`${API_HOST}/api/subscription/${userEmail}/undodelete`)
			.then(() => {
				let option = notifierMessage(t("list.notification.restoreSuccess"), "success");
				dispatch(setSnackbarOptions(option));
				loadSubscribers();
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const loadMeetingTypes = async () => {
		const meetingTypeRes = await request.get(`${API_HOST}/api/meetingtypes`);
		setMeetingTypes(meetingTypeRes.body);
	};

	const handleLoadSubscribers = async (page = 1, resolve) => {
		request
			.post(`${API_HOST}/api/subscriptions/filteredlist`)
			.send({ filter, page, pageSize: PAGE_SIZE, sort })
			.then((res) => {
				const { subscriptions } = res.body;

				setSubscribers((prev) => {
					var updatedSubscriptions = (page == 1 ? [] : prev || []).concat(subscriptions);

					// If there are no more subscriptions to load, set the estimated total rows to the total number of subscriptions
					if (subscriptions.length < PAGE_SIZE) {
						setEstimatedTotalRows(updatedSubscriptions.length);
					}

					return updatedSubscriptions;
				});
				resolve();
			})
			.catch((err) => {
				showSignIn(err, () => {
					handleLoadSubscribers(page, resolve);
				});
			});
	};

	const loadSubscribers = async () => {
		const countResponse = await request.get(`${API_HOST}/api/subscriptions/count`);
		setSubscribersCount(countResponse?.body);

		setSubscribers(null);
		setEstimatedTotalRows(Number.MAX_VALUE);
		setForceReload((prev) => prev + 1);
	};

	const loadMoreSubscribers = ({ startIndex }) =>
		new Promise((resolve) => {
			handleLoadSubscribers(Math.floor(startIndex / PAGE_SIZE) + 1, resolve);
		});

	const filterChange = (newActive) => {
		telemetryAddEvent(`${telemetryPage} - Filter`);
		setFilter(newActive || defaultFilter);
	};

	const clearFilter = () => {
		setFilter(defaultFilter);
	};

	const filterClick = () => {
		setPanels((prev) => ({
			filter: !prev.filter,
		}));
	};

	const closeFilter = () => {
		setPanels({
			filter: false,
		});
	};

	const handleSort = (property, order) => {
		setSort({ field: property, dir: order });
	};

	useEffect(() => {
		dispatch(resetPageConfigs({ resetBack: true }));
		dispatch(
			updatePageConfigs({
				title: t("title"),
				telemetryPage,
				preferedBack: { url: "/subscribers" },
			}),
		);
		dispatch(
			updatePageHeader({
				primaryAction: () => navigate("/subscribers/create"),
				primaryActionText: t("buttons.addNewSubscription"),
				primaryActionTooltip: t("tooltips.addNewSubscription"),
			}),
		);
	}, []);

	useEffect(() => {
		loadSubscribers();
		setFiltered(
			filter !== null &&
				(filter.emailAddress.length > 0 ||
					filter.meetingTypes.length > 0 ||
					filter.publicYes ||
					filter.publicNo ||
					filter.activeYes ||
					filter.activeNo),
		);
	}, [filter, sort]);

	useEffect(() => {
		loadMeetingTypes();

		dispatch(
			updateToolbar({
				display: true,
				right: {
					tools: [
						{
							id: "openFilter",
							icon: "filter",
							tooltipText: t("tooltips.filter"),
							ariaLabel: t("tooltips.filter"),
							onClick: filterClick,
							dataCy: "toggle-filter",
							badgeProps: {
								display: filtered,
								dataCy: "filtered",
							},
						},
					],
				},
			}),
		);
	}, [Boolean(subscribers), filtered]);

	return (
		<ComponentContainer padding="0">
			<SubscribersVirtualizedList
				rows={subscribers}
				estimatedTotalRows={estimatedTotalRows}
				forceReload={forceReload}
				sort={sort}
				headerHeight={48}
				meetingTypes={meetingTypes}
				handleActiveClick={handleActiveClick}
				handleDeleteClick={handleDeleteClick}
				handleSort={handleSort}
				cache={cache}
				mobileCache={mobileCache}
				loadMore={loadMoreSubscribers}
				pageSize={PAGE_SIZE}
			/>
			<RightPanelContainer id="right-panel-container" open={panels.filter} float drawerWidth={MD_DOWN_WIDTH} fullHeight>
				<FilterPanel
					filters={filter}
					onFilterChange={filterChange}
					clearClick={clearFilter}
					isFiltered={filtered}
					closeFilter={closeFilter}
				>
					<SubscribersFilter
						filters={filter}
						onFilterChange={filterChange}
						meetingTypes={meetingTypes}
						subscribersCount={subscribersCount}
					/>
				</FilterPanel>
			</RightPanelContainer>
		</ComponentContainer>
	);
};

export default withErrorHandling(SubscribersModule);
