import { SECTION_FORMAT_ITEM, NUMBER_ITEMS_FORMAT, ALPHABETS_ITEM_FORMAT, ROMAN_ITEM_FORMAT } from "utils/enums/CustomNumberingTypes";
export const convertNumberIntoRoman = (num) => {
	let lookup = { M: 1000, CM: 900, D: 500, CD: 400, C: 100, XC: 90, L: 50, xl: 40, x: 10, ix: 9, v: 5, iv: 4, i: 1 },
		roman = "",
		i;
	for (i in lookup) {
		while (num >= lookup[i]) {
			roman += i;
			num -= lookup[i];
		}
	}
	return roman;
};

export const convertNumberIntoCapitalRoman = (num) => {
	let lookup = { M: 1000, CM: 900, D: 500, CD: 400, C: 100, XC: 90, L: 50, Xl: 40, X: 10, IX: 9, V: 5, IV: 4, I: 1 },
		roman = "",
		i;
	for (i in lookup) {
		while (num >= lookup[i]) {
			roman += i;
			num -= lookup[i];
		}
	}
	return roman;
};

export const convertNumberIntoSmallAlphabets = (num) => {
	let result = "";
	while (num > 0) {
		let remainder = (num - 1) % 26;
		result = String.fromCharCode(97 + remainder) + result;
		num = Math.floor((num - 1) / 26);
	}
	return result;
};

export const convertNumberIntoCapsAlbhabets = (num) => {
	let result = "";
	while (num > 0) {
		let remainder = (num - 1) % 26;
		result = String.fromCharCode(65 + remainder) + result;
		num = Math.floor((num - 1) / 26);
	}
	return result;
};

export const convertNumberIntoZeroPaddedString = (num) => {
	if (num < 10) {
		return "0" + num.toString();
	} else {
		return num.toString();
	}
};

export const NumberToRoman = (parent, newnumber, num, itemFormat) => {
	const { RomanWithBracketCapitalAlphabets, RomanWithBracketSmallAlphabets, RomanWithBracketSmallRoman } = ROMAN_ITEM_FORMAT;
	if (parent == "") {
		return convertNumberIntoCapitalRoman(newnumber) + ".";
	} else {
		let str = num.split(".");
		let number = str[1];
		switch (itemFormat.agendaItemFormat) {
			case RomanWithBracketCapitalAlphabets:
				return convertNumberIntoCapsAlbhabets(number) + ")";
				break;
			case RomanWithBracketSmallAlphabets:
				return convertNumberIntoSmallAlphabets(number) + ")";
				break;
			case RomanWithBracketSmallRoman:
				return convertNumberIntoRoman(number) + ")";
				break;

			default:
				return num;
		}
	}
};

export const NumberSectionConversion = (itemFormat, newNumber) => {
	const {
		NumberWithNumber,
		NumberWithSmallAlphabets,
		NumberBracketWithSmallAlphabets,
		NumberWithCapitalAlphabets,
		NumberBracketWithCapitalAlphabets,
		NumberWithTwoDigits,
		NumberBracketWithTwoDigits,
		NumberWithRoman,
		NumberWithCapitalRoman,
		NumberBracketWithRoman,
		NumberBracketWithCapitalRoman,
	} = NUMBER_ITEMS_FORMAT;
	let optedFormat = newNumber.toString();

	switch (itemFormat.agendaItemFormat) {
		case NumberWithNumber:
			optedFormat = newNumber.toString();
			break;
		case NumberWithSmallAlphabets:
		case NumberBracketWithSmallAlphabets:
			optedFormat = convertNumberIntoSmallAlphabets(newNumber);
			break;
		case NumberWithCapitalAlphabets:
		case NumberBracketWithCapitalAlphabets:
			optedFormat = convertNumberIntoCapsAlbhabets(newNumber);
			break;
		case NumberWithTwoDigits:
		case NumberBracketWithTwoDigits:
			optedFormat = convertNumberIntoZeroPaddedString(newNumber);
			break;
		case NumberWithRoman:
		case NumberBracketWithRoman:
			optedFormat = convertNumberIntoRoman(newNumber);
			break;
		case NumberWithCapitalRoman:
		case NumberBracketWithCapitalRoman:
			optedFormat = convertNumberIntoCapitalRoman(newNumber);
			break;
		default:
			optedFormat = newNumber.toString();
	}

	return optedFormat;
};
export const NumberToAlphabet = (parent, newnumber, num, itemFormat) => {
	const {
		AlphabetWithBracketSmallAlphabets,
		AlphabetWithNumber,
		AlphabetWithRoman,
		AlphabetWithDashNumber,
		AlphabetWithBracketNumber,
		AlphabetWithBracketCapitalRoman,
		AlphabetWithBracketSmallRoman,

		AlphabetBracketWithBracetSmallAlphabets,
		AlphabetBracketWithNumber,
		AlphabetBracketWithBracketNumber,
		AlphabetBracketWithBracketCapitalRoman,
		AlphabetBracketWithBracketSmallRoman,
	} = ALPHABETS_ITEM_FORMAT;

	if (parent == "") {
		let integerPart = Math.floor(newnumber);
		let integerAlphabet = convertNumberIntoCapsAlbhabets(integerPart);
		switch (itemFormat.agendaItemFormat) {
			case AlphabetWithBracketSmallAlphabets:
			case AlphabetWithNumber:
			case AlphabetWithRoman:
			case AlphabetWithDashNumber:
			case AlphabetWithBracketNumber:
			case AlphabetWithBracketCapitalRoman:
			case AlphabetWithBracketSmallRoman:
				return integerAlphabet + ".";
				break;

			case AlphabetBracketWithBracetSmallAlphabets:
			case AlphabetBracketWithNumber:
			case AlphabetBracketWithBracketNumber:
			case AlphabetBracketWithBracketCapitalRoman:
			case AlphabetBracketWithBracketSmallRoman:
				return integerAlphabet + ")";
				break;

			default:
				return integerAlphabet;
		}
	} else {
		let splitNumber = num.split(/(\d+)/);
		let firstNumber = splitNumber[0];
		let secondNumber = splitNumber[1];

		switch (itemFormat.agendaItemFormat) {
			case AlphabetWithBracketSmallAlphabets:
			case AlphabetBracketWithBracetSmallAlphabets:
				return convertNumberIntoSmallAlphabets(secondNumber) + ")";
				break;
			case AlphabetWithNumber:
				return secondNumber + ".";
				break;

			case AlphabetWithRoman:
				secondNumber = convertNumberIntoRoman(secondNumber);
				return firstNumber + secondNumber;
				break;

			case AlphabetWithDashNumber:
				return num.replace(".", "-");
				break;

			case AlphabetWithBracketNumber:
				return secondNumber + ")";
				break;

			case AlphabetWithBracketCapitalRoman:
				return convertNumberIntoCapitalRoman(secondNumber) + ".";
				break;

			case AlphabetWithBracketSmallRoman:
				return convertNumberIntoRoman(secondNumber) + ".";
				break;
			case AlphabetBracketWithNumber:
				return secondNumber + ".";
				break;
			case AlphabetBracketWithBracketNumber:
				return secondNumber + ")";
				break;
			case AlphabetBracketWithBracketCapitalRoman:
				return convertNumberIntoCapitalRoman(secondNumber) + ")";
				break;
			case AlphabetBracketWithBracketSmallRoman:
				return convertNumberIntoRoman(secondNumber) + ")";
			default:
				return num;
		}
	}
};

export const customNumberingSelected = (persistCustomNumbering, newNumber, usesNumbering, parentNumber) => {
	const { NumberValue, NumberValueWithBracket, Alphabets, AlphabetBracket, Roman, None } = SECTION_FORMAT_ITEM;
	const { NumberWithNumber, NumberWithTwoDigits } = NUMBER_ITEMS_FORMAT;
	let customNumberingOpted = newNumber.toString();
	let customNewNumber = `${newNumber}.`;
	let newNumberText = usesNumbering ? (parentNumber ? parentNumber + customNumberingOpted : `${customNewNumber}`) : "";
	switch (persistCustomNumbering.sectionFormat) {
		case NumberValue:
			customNumberingOpted = NumberSectionConversion(persistCustomNumbering, newNumber);
			let parentPrefix = "";
			let postPrefix = ".";
			if (persistCustomNumbering.agendaItemFormat == NumberWithNumber || persistCustomNumbering.agendaItemFormat == NumberWithTwoDigits) {
				parentPrefix = parentNumber;
				postPrefix = "";
			}
			return usesNumbering ? (parentNumber ? parentPrefix + customNumberingOpted + postPrefix : `${customNewNumber}`) : "";
			break;

		case NumberValueWithBracket:
			customNewNumber = `${newNumber})`;
			customNumberingOpted = NumberSectionConversion(persistCustomNumbering, newNumber) + ")";
			return usesNumbering ? (parentNumber ? customNumberingOpted : `${customNewNumber}`) : "";
			break;

		case Alphabets:
		case AlphabetBracket:
			const numberToAlpha = NumberToAlphabet(parentNumber, newNumber, newNumberText, persistCustomNumbering);
			return numberToAlpha;
			break;
		case Roman:
			const numToRoman = NumberToRoman(parentNumber, newNumber, newNumberText, persistCustomNumbering);
			return numToRoman;
			break;

		default:
			return usesNumbering ? (parentNumber ? parentNumber + customNumberingOpted : `${customNewNumber}`) : "";
	}
};

export const moveSectionItemCustomNumbering = (persistCustomNumbering, number) => {
	const { NumberValue, NumberValueWithBracket, Alphabets, AlphabetBracket, Roman } = SECTION_FORMAT_ITEM;

	let newNumber = number.replace(/\.$/, "");

	switch (persistCustomNumbering.sectionFormat) {
		case NumberValue:
			return `${newNumber}.`;
			break;

		case NumberValueWithBracket:
			return `${newNumber})`;
			break;

		case Alphabets:
			return convertNumberIntoCapsAlbhabets(newNumber) + ".";
			break;
		case AlphabetBracket:
			return convertNumberIntoCapsAlbhabets(newNumber) + ")";
			break;

		case Roman:
			const numToRoman = convertNumberIntoCapitalRoman(newNumber);
			return numToRoman + ".";
			break;

		default:
			return newNumber + ".";
	}
};

export const moveAgendaItemCustomNumbering = (persistCustomNumbering, number) => {
	const { NumberValue, NumberValueWithBracket } = SECTION_FORMAT_ITEM;
	const {
		NumberWithNumber,
		NumberWithSmallAlphabets,
		NumberBracketWithSmallAlphabets,
		NumberWithCapitalAlphabets,
		NumberBracketWithCapitalAlphabets,
		NumberWithTwoDigits,
		NumberBracketWithTwoDigits,
		NumberWithRoman,
		NumberBracketWithRoman,
		NumberWithCapitalRoman,
		NumberBracketWithCapitalRoman,
	} = NUMBER_ITEMS_FORMAT;

	const {
		AlphabetWithBracketSmallAlphabets,
		AlphabetWithNumber,
		AlphabetWithRoman,
		AlphabetWithDashNumber,
		AlphabetWithBracketNumber,
		AlphabetWithBracketCapitalRoman,
		AlphabetWithBracketSmallRoman,

		AlphabetBracketWithBracetSmallAlphabets,
		AlphabetBracketWithNumber,
		AlphabetBracketWithBracketNumber,
		AlphabetBracketWithBracketCapitalRoman,
		AlphabetBracketWithBracketSmallRoman,
	} = ALPHABETS_ITEM_FORMAT;

	const { RomanWithBracketCapitalAlphabets, RomanWithBracketSmallAlphabets, RomanWithBracketSmallRoman } = ROMAN_ITEM_FORMAT;

	let middleValue = ".";
	let bracketValue = "";
	if (persistCustomNumbering.sectionFormat == NumberValueWithBracket) {
		middleValue = ")";
		bracketValue = ")";
	} else if (persistCustomNumbering.sectionFormat == NumberValue) {
		bracketValue = ".";
	}
	let splitedNumber = number.split(/(\d+)/);
	let firstNumber = splitedNumber[1];
	let secondNumber = splitedNumber[3];

	switch (persistCustomNumbering.agendaItemFormat) {
		// NUMBER CONVERSION//
		case NumberWithNumber:
			return firstNumber + middleValue + secondNumber.toString() + bracketValue;
			break;
		case NumberWithSmallAlphabets:
		case NumberBracketWithSmallAlphabets:
			return convertNumberIntoSmallAlphabets(secondNumber) + bracketValue;
			break;
		case NumberWithCapitalAlphabets:
		case NumberBracketWithCapitalAlphabets:
			return convertNumberIntoCapsAlbhabets(secondNumber) + bracketValue;
			break;
		case NumberWithTwoDigits:
			return firstNumber + middleValue + convertNumberIntoZeroPaddedString(secondNumber) + bracketValue;
			break;

		case NumberBracketWithTwoDigits:
			return convertNumberIntoZeroPaddedString(secondNumber) + bracketValue;
			break;
		case NumberWithRoman:
		case NumberBracketWithRoman:
			return convertNumberIntoRoman(secondNumber) + bracketValue;
			break;
		case NumberWithCapitalRoman:
		case NumberBracketWithCapitalRoman:
			return convertNumberIntoCapitalRoman(secondNumber) + bracketValue;
			break;

		//ALPHABETS CONVERSION//
		case AlphabetWithBracketSmallAlphabets:
		case AlphabetBracketWithBracetSmallAlphabets:
			return convertNumberIntoSmallAlphabets(secondNumber) + ")";
			break;
		case AlphabetWithNumber:
			return secondNumber + ".";
			break;

		case AlphabetWithRoman:
			secondNumber = convertNumberIntoRoman(secondNumber);
			return firstNumber + secondNumber;
			break;

		case AlphabetWithDashNumber:
			return convertNumberIntoCapsAlbhabets(firstNumber) + "-" + secondNumber;
			break;

		case AlphabetWithBracketNumber:
			return secondNumber + ")";
			break;

		case AlphabetWithBracketCapitalRoman:
			return convertNumberIntoCapitalRoman(secondNumber) + ".";
			break;

		case AlphabetWithBracketSmallRoman:
			return convertNumberIntoRoman(secondNumber) + ".";
			break;
		case AlphabetBracketWithNumber:
			return secondNumber + ".";
			break;
		case AlphabetBracketWithBracketNumber:
			return secondNumber + ")";
			break;
		case AlphabetBracketWithBracketCapitalRoman:
			return convertNumberIntoCapitalRoman(secondNumber) + ")";
			break;
		case AlphabetBracketWithBracketSmallRoman:
			return convertNumberIntoRoman(secondNumber) + ")";

		//ROMAN CONVERSION

		case RomanWithBracketCapitalAlphabets:
			return convertNumberIntoCapsAlbhabets(secondNumber) + ")";
			break;
		case RomanWithBracketSmallAlphabets:
			return convertNumberIntoSmallAlphabets(secondNumber) + ")";
			break;
		case RomanWithBracketSmallRoman:
			return convertNumberIntoRoman(secondNumber) + ")";
			break;

		default:
			return firstNumber + middleValue + secondNumber.toString();
	}
};

export const minutesCustomNumbering = (persistCustomNumbering, number, parent, child, numberObject) => {
	const { NumberValue, NumberValueWithBracket, Alphabets, AlphabetBracket, Roman } = SECTION_FORMAT_ITEM;
	const {
		NumberWithNumber,
		NumberWithSmallAlphabets,
		NumberBracketWithSmallAlphabets,
		NumberWithCapitalAlphabets,
		NumberBracketWithCapitalAlphabets,
		NumberWithTwoDigits,
		NumberBracketWithTwoDigits,
		NumberWithRoman,
		NumberBracketWithRoman,
		NumberWithCapitalRoman,
		NumberBracketWithCapitalRoman,
	} = NUMBER_ITEMS_FORMAT;

	const {
		AlphabetWithBracketSmallAlphabets,
		AlphabetWithNumber,
		AlphabetWithRoman,
		AlphabetWithDashNumber,
		AlphabetWithBracketNumber,
		AlphabetWithBracketCapitalRoman,
		AlphabetWithBracketSmallRoman,

		AlphabetBracketWithBracetSmallAlphabets,
		AlphabetBracketWithNumber,
		AlphabetBracketWithBracketNumber,
		AlphabetBracketWithBracketCapitalRoman,
		AlphabetBracketWithBracketSmallRoman,
	} = ALPHABETS_ITEM_FORMAT;

	const { RomanWithBracketCapitalAlphabets, RomanWithBracketSmallAlphabets, RomanWithBracketSmallRoman } = ROMAN_ITEM_FORMAT;

	let middleValue = ".";
	let bracketValue = ".";
	if (persistCustomNumbering.sectionFormat == NumberValueWithBracket) {
		middleValue = ")";
		bracketValue = ")";
	}

	let splitedNumber = "";
	if (number.includes(".")) {
		splitedNumber = number.split(".");
	} else if (number.includes("-")) {
		splitedNumber = number.split("-");
	} else if (number.includes(")")) {
		splitedNumber = number.split(")");
	}

	let firstNumber = splitedNumber[0];
	let secondNumber = splitedNumber[1];

	if (numberObject.isParent) {
		switch (persistCustomNumbering.sectionFormat) {
			case NumberValue:
				return `${firstNumber}.`;
				break;

			case NumberValueWithBracket:
				return `${firstNumber})`;
				break;

			case Alphabets:
				return convertNumberIntoCapsAlbhabets(firstNumber) + ".";
				break;
			case AlphabetBracket:
				return convertNumberIntoCapsAlbhabets(firstNumber) + ")";
				break;

			case Roman:
				const numToRoman = convertNumberIntoCapitalRoman(firstNumber);
				return numToRoman + ".";
				break;

			default:
				return firstNumber + ".";
		}
	} else {
		switch (persistCustomNumbering.agendaItemFormat) {
			// NUMBER CONVERSION//
			case NumberWithNumber:
				return firstNumber + middleValue + secondNumber.toString();
				break;
			case NumberWithSmallAlphabets:
			case NumberBracketWithSmallAlphabets:
				return convertNumberIntoSmallAlphabets(secondNumber) + bracketValue;
				break;
			case NumberWithCapitalAlphabets:
			case NumberBracketWithCapitalAlphabets:
				return convertNumberIntoCapsAlbhabets(secondNumber) + bracketValue;
				break;
			case NumberWithTwoDigits:
				return firstNumber + middleValue + convertNumberIntoZeroPaddedString(secondNumber);
				break;
			case NumberBracketWithTwoDigits:
				return convertNumberIntoZeroPaddedString(secondNumber) + bracketValue;
				break;
			case NumberWithRoman:
			case NumberBracketWithRoman:
				return convertNumberIntoRoman(secondNumber) + bracketValue;
				break;
			case NumberWithCapitalRoman:
			case NumberBracketWithCapitalRoman:
				return convertNumberIntoCapitalRoman(secondNumber) + bracketValue;
				break;

			//ALPHABETS CONVERSION//
			case AlphabetWithBracketSmallAlphabets:
			case AlphabetBracketWithBracetSmallAlphabets:
				return convertNumberIntoSmallAlphabets(secondNumber) + ")";
				break;
			case AlphabetWithNumber:
				return secondNumber + ".";
				break;

			case AlphabetWithRoman:
				secondNumber = convertNumberIntoRoman(secondNumber);
				return firstNumber + secondNumber;
				break;

			case AlphabetWithDashNumber:
				return convertNumberIntoCapsAlbhabets(firstNumber) ? convertNumberIntoCapsAlbhabets(firstNumber) : firstNumber + "-" + secondNumber;
				break;

			case AlphabetWithBracketNumber:
				return secondNumber + ")";
				break;

			case AlphabetWithBracketCapitalRoman:
				return convertNumberIntoCapitalRoman(secondNumber) + ".";
				break;

			case AlphabetWithBracketSmallRoman:
				return convertNumberIntoRoman(secondNumber) + ".";
				break;
			case AlphabetBracketWithNumber:
				return secondNumber + ".";
				break;
			case AlphabetBracketWithBracketNumber:
				return secondNumber + ")";
				break;
			case AlphabetBracketWithBracketCapitalRoman:
				return convertNumberIntoCapitalRoman(secondNumber) + ")";
				break;
			case AlphabetBracketWithBracketSmallRoman:
				return convertNumberIntoRoman(secondNumber) + ")";

			//ROMAN CONVERSION

			case RomanWithBracketCapitalAlphabets:
				return convertNumberIntoCapsAlbhabets(secondNumber) + ")";
				break;
			case RomanWithBracketSmallAlphabets:
				return convertNumberIntoSmallAlphabets(secondNumber) + ")";
				break;
			case RomanWithBracketSmallRoman:
				return convertNumberIntoRoman(secondNumber) + ")";
				break;

			default:
				return firstNumber + middleValue + secondNumber.toString();
		}
	}
};

export const convertToNumber = (data) => {
	let splitedNumber = data.split(".");
	let value = splitedNumber[0];
	if (/^[A-Za-z]+$/.test(value)) {
		if (/^(M{0,3})(CM|CD|D?C{0,3})(XC|XL|L?X{0,3})(IX|IV|V?I{0,3})$/.test(value)) {
			return convertRomanToNumber(value);
		}
		return convertAlphabetToNumber(value);
	} else if (/^(M{0,3})(CM|CD|D?C{0,3})(XC|XL|L?X{0,3})(IX|IV|V?I{0,3})$/.test(value)) {
		return convertRomanToNumber(value);
	} else {
		return value;
	}
};

export const convertAlphabetToNumber = (alphabet) => {
	if (/^[A-Za-z]+$/.test(alphabet)) {
		const uppercaseAlphabet = alphabet.toUpperCase();
		const charCodeOffset = "A".charCodeAt(0) - 1; // Offset to convert A to 1

		if (/^[A-Z]$/.test(uppercaseAlphabet)) {
			return uppercaseAlphabet.charCodeAt(0) - charCodeOffset;
		}
		return "";
	} else return alphabet;
};

function convertRomanToNumber(romanNumeral) {
	const uppercaseRomanNumeral = romanNumeral.toUpperCase();
	let pattern = /^(M{0,3})(CM|CD|D?C{0,3})(XC|XL|L?X{0,3})(IX|IV|V?I{0,3})$/;
	if (pattern.test(uppercaseRomanNumeral)) {
		const romanNumerals = {
			I: 1,
			V: 5,
			X: 10,
			L: 50,
			C: 100,
			D: 500,
			M: 1000,
		};

		let result = 0;
		let prevValue = 0;

		for (let i = uppercaseRomanNumeral.length - 1; i >= 0; i--) {
			const currentSymbol = uppercaseRomanNumeral[i];
			const currentValue = romanNumerals[currentSymbol];

			if (currentValue >= prevValue) {
				result += currentValue;
			} else {
				result -= currentValue;
			}

			prevValue = currentValue;
		}

		return result;
	} else return romanNumeral;
}

export const anyFormatToNumberConversion = (number, parentItem, childItem, numberObject) => {
	const {
		NumberWithNumber,
		NumberWithSmallAlphabets,
		NumberBracketWithSmallAlphabets,
		NumberWithCapitalAlphabets,
		NumberBracketWithCapitalAlphabets,
		NumberWithTwoDigits,
		NumberBracketWithTwoDigits,
		NumberWithRoman,
		NumberBracketWithRoman,
		NumberWithCapitalRoman,
		NumberBracketWithCapitalRoman,
	} = NUMBER_ITEMS_FORMAT;

	const {
		AlphabetWithBracketSmallAlphabets,
		AlphabetWithNumber,
		AlphabetWithRoman,
		AlphabetWithDashNumber,
		AlphabetWithBracketNumber,
		AlphabetWithBracketCapitalRoman,
		AlphabetWithBracketSmallRoman,

		AlphabetBracketWithBracetSmallAlphabets,
		AlphabetBracketWithNumber,
		AlphabetBracketWithBracketNumber,
		AlphabetBracketWithBracketCapitalRoman,
		AlphabetBracketWithBracketSmallRoman,
	} = ALPHABETS_ITEM_FORMAT;
	const { RomanWithBracketCapitalAlphabets, RomanWithBracketSmallAlphabets, RomanWithBracketSmallRoman } = ROMAN_ITEM_FORMAT;

	let oldSelectedFormat = formatToNumberConversion(parentItem, childItem);

	let num = "";
	if (number.includes(".")) {
		num = number.split(".");
	} else if (number.includes("-")) {
		num = number.split("-");
	} else if (number.includes(")")) {
		num = number.split(")");
	}

	let splitParentNumber = "";
	if (numberObject.parentNumber.includes(".")) {
		splitParentNumber = numberObject.parentNumber.split(".");
	} else if (numberObject.parentNumber.includes("-")) {
		splitParentNumber = numberObject.parentNumber.split("-");
	} else if (numberObject.parentNumber.includes(")")) {
		splitParentNumber = numberObject.parentNumber.split(")");
	}
	let firstNumber = num[0];
	let secondNumber = num[1];

	let parentSplitedNumber = splitParentNumber[0];

	if (numberObject.parentNumber == "") {
		if (parentItem.includes("A")) {
			return convertAlphabetToNumber(firstNumber) + ".";
		} else if (parentItem.includes("I")) {
			return convertRomanToNumber(firstNumber) + ".";
		} else {
			return num[0] + ".";
		}
	} else {
		if (oldSelectedFormat) {
			switch (oldSelectedFormat) {
				//NUMBER
				case NumberWithNumber:
					return firstNumber + "." + secondNumber;
					break;

				case NumberWithSmallAlphabets:
				case NumberBracketWithSmallAlphabets:
					return parentSplitedNumber + "." + convertAlphabetToNumber(firstNumber) + ".";
					break;

				case NumberWithCapitalAlphabets:
				case NumberBracketWithCapitalAlphabets:
					return parentSplitedNumber + "." + convertAlphabetToNumber(firstNumber) + ".";
					break;

				case NumberWithTwoDigits:
					if (secondNumber.startsWith("0") && secondNumber.length > 1) {
						return firstNumber + "." + secondNumber.slice(1);
					}
					return firstNumber + "." + secondNumber;
					break;
				case NumberBracketWithTwoDigits:
					if (firstNumber.startsWith("0") && firstNumber.length > 1) {
						return numberObject.parentNumber + firstNumber.slice(1);
					}
					return numberObject.parentNumber + firstNumber;
					break;

				case NumberWithCapitalRoman:
				case NumberBracketWithCapitalRoman:
					return parentSplitedNumber + "." + convertRomanToNumber(firstNumber) + ".";
					break;
				case NumberWithRoman:
				case NumberBracketWithRoman:
					return parentSplitedNumber + "." + convertRomanToNumber(firstNumber) + ".";
					break;

				//ALPHABETS
				case AlphabetWithBracketSmallAlphabets:
				case AlphabetBracketWithBracetSmallAlphabets:
					return parentSplitedNumber + "." + convertAlphabetToNumber(firstNumber) + ".";
					break;

				case AlphabetWithNumber:
				case AlphabetBracketWithNumber:
					return parentSplitedNumber + "." + firstNumber + ".";
					break;

				case AlphabetWithBracketSmallRoman:
				case AlphabetBracketWithBracketSmallRoman:
					return parentSplitedNumber + "." + convertRomanToNumber(firstNumber) + ".";
					break;
				case AlphabetWithBracketCapitalRoman:
				case AlphabetBracketWithBracketCapitalRoman:
					return parentSplitedNumber + "." + convertRomanToNumber(firstNumber) + ".";
					break;

				case AlphabetWithDashNumber:
					return parentSplitedNumber + "." + convertAlphabetToNumber(secondNumber);
					break;
				case AlphabetWithBracketNumber:
					return parentSplitedNumber + "." + firstNumber + ".";
					break;
				case AlphabetBracketWithBracketNumber:
					return parentSplitedNumber + "." + firstNumber + ".";
					break;

				//ROMAN

				case RomanWithBracketCapitalAlphabets:
					return parentSplitedNumber + "." + convertAlphabetToNumber(firstNumber) + ".";
					break;
				case RomanWithBracketSmallAlphabets:
					return parentSplitedNumber + "." + convertAlphabetToNumber(firstNumber) + ".";
					break;
				case RomanWithBracketSmallRoman:
					return parentSplitedNumber + "." + convertRomanToNumber(firstNumber) + ".";
					break;
				default:
					return null;
			}
		}
	}
};

export const formatToNumberConversion = (parentItem, childItem) => {
	const {
		NumberWithNumber,
		NumberBracketWithNumber,
		NumberWithSmallAlphabets,
		NumberBracketWithSmallAlphabets,
		NumberWithCapitalAlphabets,
		NumberBracketWithCapitalAlphabets,
		NumberWithTwoDigits,
		NumberBracketWithTwoDigits,
		NumberWithRoman,
		NumberBracketWithRoman,
		NumberWithCapitalRoman,
		NumberBracketWithCapitalRoman,
	} = NUMBER_ITEMS_FORMAT;

	const {
		AlphabetWithBracketSmallAlphabets,
		AlphabetWithNumber,
		AlphabetWithRoman,
		AlphabetWithDashNumber,
		AlphabetWithBracketNumber,
		AlphabetWithBracketCapitalRoman,
		AlphabetWithBracketSmallRoman,

		AlphabetBracketWithBracetSmallAlphabets,
		AlphabetBracketWithNumber,
		AlphabetBracketWithBracketNumber,
		AlphabetBracketWithBracketCapitalRoman,
		AlphabetBracketWithBracketSmallRoman,
	} = ALPHABETS_ITEM_FORMAT;
	const { RomanWithBracketCapitalAlphabets, RomanWithBracketSmallAlphabets, RomanWithBracketSmallRoman } = ROMAN_ITEM_FORMAT;

	switch (parentItem) {
		case "1.":
			if (childItem.includes(".1") && !childItem.includes(".01")) {
				return NumberWithNumber;
			} else if (childItem.includes("a")) {
				return NumberWithSmallAlphabets;
			} else if (childItem.includes("A")) {
				return NumberWithCapitalAlphabets;
			} else if (childItem.includes("01")) {
				return NumberWithTwoDigits;
			} else if (childItem.includes("i")) {
				return NumberWithRoman;
			} else if (childItem.includes("I")) {
				return NumberWithCapitalRoman;
			}
			break;

		case "1)":
			if (childItem.includes("1") && !childItem.includes("01")) {
				return NumberBracketWithNumber;
			} else if (childItem.includes("a")) {
				return NumberBracketWithSmallAlphabets;
			} else if (childItem.includes("A")) {
				return NumberBracketWithCapitalAlphabets;
			} else if (childItem.includes("01")) {
				return NumberBracketWithTwoDigits;
			} else if (childItem.includes("i")) {
				return NumberBracketWithRoman;
			} else if (childItem.includes("I")) {
				return NumberBracketWithCapitalRoman;
			}
			break;

		case "A.":
			if (childItem.includes("1.")) {
				return AlphabetWithNumber;
			} else if (childItem.includes("i")) {
				return AlphabetWithBracketSmallRoman;
			} else if (childItem.includes("a")) {
				return AlphabetWithBracketSmallAlphabets;
			} else if (childItem.includes("-1")) {
				return AlphabetWithDashNumber;
			} else if (childItem.includes("1)")) {
				return AlphabetWithBracketNumber;
			} else if (childItem.includes("I")) {
				return AlphabetWithBracketCapitalRoman;
			}
			break;
		case "A)":
			if (childItem.includes("1.")) {
				return AlphabetBracketWithNumber;
			} else if (childItem.includes("i")) {
				return AlphabetBracketWithBracketSmallRoman;
			} else if (childItem.includes("a")) {
				return AlphabetBracketWithBracetSmallAlphabets;
			} else if (childItem.includes("1)")) {
				return AlphabetBracketWithBracketNumber;
			} else if (childItem.includes("I")) {
				return AlphabetBracketWithBracketCapitalRoman;
			}
			break;
		case "I.":
			if (childItem.includes("A")) {
				return RomanWithBracketCapitalAlphabets;
			} else if (childItem.includes("a")) {
				return RomanWithBracketSmallAlphabets;
			} else if (childItem.includes("i")) {
				return RomanWithBracketSmallRoman;
			}

			break;
		default:
			return null;
	}
};

export const customNumberingConversion = (number, parentItem, childItem, persistCustomNumbering, numberObject) => {
	let parentNumberConversion = anyFormatToNumberConversion(number, parentItem, childItem, numberObject);
	return minutesCustomNumbering(persistCustomNumbering, parentNumberConversion, parentItem, childItem, numberObject);
};
