import React from "react";
import { useTranslation } from "react-i18next";

import makeStyles from "@mui/styles/makeStyles";
import { Checkbox, FormControlLabel } from "@mui/material";

import StackedCheckbox from "atlas/components/FormControls/StackedCheckbox";
import filterStyle from "assets/jss/components/filterStyle";
import { Check } from "components/Icons";

const useFilterStyles = makeStyles(filterStyle);

export const DEFAULT_SEARCH_FIELD = "search";

const MeetingTemplateFilter = (props) => {
	const { filter, updateFilter, boards = [] } = props;
	const { t } = useTranslation("meetings");
	const filterClasses = useFilterStyles();

	return (
		<>
			<div className={filterClasses.section}>
				<StackedCheckbox
					options={boards}
					getChecked={(board) => filter.boards && filter.boards.includes(board.id)}
					getId={(board) => `filter-board-${board.id}`}
					getKey={(board) => `filter-board-${board.id}`}
					getValue={(board) => board.id}
					getDataCy={(board) => `filter-board-${board.id}`}
					getLabel={(board) => board.name}
					handleChange={(board) => {
						updateFilter({ target: { value: board.id } }, "boards", false, false, undefined, (newValue, prevValue) =>
							prevValue.includes(newValue) ? prevValue.filter((value) => value != newValue) : prevValue.concat([newValue]),
						);
					}}
				/>
			</div>
			<div className={filterClasses.separator}></div>
			<div className={filterClasses.section}>
				<FormControlLabel
					control={
						<Checkbox
							checkedIcon={<Check fontSize="small" color="primary" />}
							checked={filter.includeArchived}
							onChange={(e) => updateFilter(e, "includeArchived", true)}
						/>
					}
					label={t("templateList.labels.includeArchived")}
					data-cy="include-archived"
				/>
			</div>
		</>
	);
};

export default MeetingTemplateFilter;
