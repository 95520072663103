import { PUBLICSITE_DATA, GET_INITIAL_SETTINGS_FULFILLED, SET_PUBLIC_SITE_ERROR } from "./types";

export const publicSiteReducer = (
	state = {
		ICSExternalCalendars: "[]",
		backgroundColor: "rgb(25,81,154)",
		backgroundImageUrl: "",
		logoImageUrl: "",
		makePortalPublic: false,
		displayVideoTile: false,
		displayAttendanceAndVotingRecords: false,
		ShowMeetingsWithoutPublishedDocuments: false,
		facebookUrl: "",
		linkedInUrl: "",
		twitterUrl: "",
		youtubeUrl: "",
		instagramUrl: "",
		email: "",
		lite: false,
		publicSiteErrors: {},
	},
	action,
) => {
	switch (action.type) {
		case GET_INITIAL_SETTINGS_FULFILLED: {
			state = {
				...state,
				...action.payload,
			};
		}

		case PUBLICSITE_DATA: {
			let key = Object.keys(action.payload)[0];
			let value = Object.values(action.payload)[0];

			state = {
				...state,
				[key]: value,
			};
			break;
		}

		case SET_PUBLIC_SITE_ERROR: {
			state = {
				...state,
				publicSiteErrors: action.payload,
			};
			break;
		}
		default:
			break;
	}

	return state;
};

export default publicSiteReducer;
