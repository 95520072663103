import React, { Component } from "react";
import { withTranslation } from "react-i18next";

import { withStyles } from "@mui/styles";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";

import ButtonWithTooltip from "atlas/components/Buttons/ButtonWithTooltip";
import { MEDIUM } from "atlas/utils/buttonSize";

const styles = () => ({
	dialog: {
		zIndex: "9002 !important",
		"& .MuiDialog-paper": {
			borderRadius: "2px",
		},
	},
});

class FileUploadFailureDialog extends Component {
	handleCancel = () => {
		this.props.onClose();
	};

	render() {
		const { show, failedFileUploads, t, classes, isPolicy } = this.props;
		const i18n = t("fileUploadFailureDialog");

		return (
			<Dialog
				className={classes.dialog}
				open={!!show}
				onClose={this.handleCancel}
				aria-label={i18n.dialogLabel}
				aria-describedby="alert-dialog-description"
			>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						{isPolicy ? i18n.failureMessageForPolicy : i18n.failureMessage}
						<br />
						{failedFileUploads && failedFileUploads.length > 1 && isPolicy
							? i18n.fileListPluralForPolicy
							: failedFileUploads && failedFileUploads.length > 1
							? ` ${i18n.fileListPlural}`
							: failedFileUploads && failedFileUploads.length === 1 && isPolicy
							? ` ${i18n.fileListSingularForPolicy}`
							: ` ${i18n.fileListSingular}`}
					</DialogContentText>
					<ul>{failedFileUploads && failedFileUploads.map((file) => <li key={file}>{isPolicy ? file.name : file}</li>)}</ul>
				</DialogContent>
				<DialogActions>
					<ButtonWithTooltip title="Got it" primary size={MEDIUM} onClick={this.handleCancel}>
						{i18n.buttonText}
					</ButtonWithTooltip>
				</DialogActions>
			</Dialog>
		);
	}
}

export default withTranslation()(withStyles(styles)(FileUploadFailureDialog));
