/* eslint-disable no-param-reassign */
import React, { useState, useEffect, useRef, useCallback } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useMatch } from "react-router-dom";
import { useTranslation } from "react-i18next";
import request from "superagent";

import CircularProgressIndicator from "atlas/components/Progress/CircularProgressIndicator";
import ComponentContainer from "atlas/components/ComponentContainer/ComponentContainer";
import withErrorHandling from "components/ErrorHOC";
import { API_HOST } from "config/env";
import { FOLDER_TYPE_CUSTOM } from "utils/enums/DocumentFolderTypes";
import telemetryAddEvent from "utils/telemetryAddEvent";
import CustomFolder from "./CustomFolder";
import { useUpdateObject, initializeValidate, setValidate } from "utils/updateObject";
import { resetPageConfigs, updatePageConfigs, updateLeftNav } from "redux/app/actions";
import { updatePageHeader } from "redux/pageHeader/actions";
import notifierMessage from "utils/notifierMessage";
import { setSnackbarOptions } from "redux/snackBar/actions";

const telemetryPage = "Custom Folder Detail";
const clearErrors = {
	title: "",
};
const clearSavingState = {
	isSaving: false,
	updated: false,
};

const CustomFolderContainer = (props) => {
	const { showSignIn } = props;
	const { params: { id } = {} } = useMatch({ path: "/documents/customFolder/:id", end: true }) || {};
	const { t } = useTranslation("documents");
	const navigate = useNavigate();
	const [folder, setFolder] = useState(null);
	const [users, setUsers] = useState(null);
	const [errors, setErrors] = useState({
		...clearErrors,
	});
	const [savingState, setSavingState] = useState({
		...clearSavingState,
	});
	const folderRef = useRef(folder); // For use in functions that have stale references
	const errorsRef = useRef(errors); // For use in functions that have stale references
	const savingStateRef = useRef(savingState); // For use in functions that have stale references
	const dispatch = useDispatch();
	const onlySpacesRegex = /^\s*$/;
	const [disableSave, setDisableSave] = useState(false);

	const loadFolder = (id = 0) => {
		request
			.get(`${API_HOST}/api/document/${id}/detail`)
			.query({ type: FOLDER_TYPE_CUSTOM })
			.withCredentials()
			.then((res) => {
				const { body: folder } = res || {};
				setFolder(folder);
				initializeValidate(setErrors, folder.guid, clearErrors);
				setSavingState({
					...clearSavingState,
				});
			})
			.catch((err) => {
				showSignIn(err, () => {
					loadFolder(id);
				});
			});
	};

	const loadUsers = () => {
		request
			.get(`${API_HOST}/api/users?includeDeleted=true&includeExternal=true&firstNameLastName=true&emailAddress=true`)
			.withCredentials()
			.then((res) => {
				const { body: users } = res || {};
				if (users) {
					// Give each user a number to use for the avatar background
					let number = 0;
					const numberCache = {};
					users.forEach((user) => {
						if (typeof numberCache[user.id] === "undefined") {
							numberCache[user.id] = number;
							number++;
						}
						user.number = numberCache[user.id];
					});

					setUsers(users);
				}
			})
			.catch((err) => {
				showSignIn(err, () => {
					loadUsers();
				});
			});
	};

	const backToCustomFolders = () => {
		const { isSaving, updated } = savingStateRef.current;
		const backPath = "/documents/customfolders";

		if (isSaving || updated) {
			if (confirm(t("customFolderDetail.unsavedChanges"))) {
				navigate(backPath);
			} else {
				return false;
			}
		} else {
			navigate(backPath);
		}

		return true;
	};

	const checkUnsavedChangesBeforeClosing = (event) => {
		const { isSaving, updated } = savingStateRef.current;

		if (isSaving || updated) {
			event.preventDefault();
			event.returnValue = "";
		}
	};

	const handleSave = () => {
		// Enable full validation
		initializeValidate(setErrors, true);

		if (!hasValidationErrors(errorsRef.current)) {
			saveFolder(folderRef.current);
		}
	};

	const saveFolder = (updatedFolder) => {
		setSavingState((prev) => ({
			...prev,
			isSaving: true,
			updated: false,
		}));

		request
			.put(`${API_HOST}/api/document/${updatedFolder.guid}/detail`)
			.withCredentials()
			.send(updatedFolder)
			.then((res) => {
				if (res.status === 200) {
					const { body: folder } = res || {};

					setFolder(folder);
					setSavingState((prev) => ({
						...prev,
						isSaving: false,
					}));

					let option = notifierMessage(t("customFolderDetail.snackbar.saved"), "success");
					dispatch(setSnackbarOptions(option));

					if (!id) {
						telemetryAddEvent(`${telemetryPage} - Library - Top-level folder added`, { area: "documents" });
					}

					dispatch(
						updateLeftNav({
							reloadLeftNav: { customFolders: Date.now() },
						}),
					);
				}
			})
			.catch((err) => {
				showSignIn(err, () => {
					saveFolder(updatedFolder);
				});
			});
	};
	const validateTitle = (title) => {
		if (title.length < 1 || onlySpacesRegex.test(title)) {
			setDisableSave(true);
		} else {
			setDisableSave(false);
		}
	};
	const validateCustomFolder = (updatedFolder, updatedField) => {
		validateTitle(updatedFolder.title);
		setErrors((prev) => {
			if (!updatedFolder.title) {
				prev.title = t("customFolderDetail.validation.title");
			} else {
				prev.title = "";
			}

			setValidate(prev, updatedField);

			return { ...prev };
		});
	};

	const hasValidationErrors = (errorsObject) => errorsObject.title;

	const updateFolder = useCallback(
		useUpdateObject(setFolder, undefined, validateCustomFolder, () => {
			setSavingState((prev) => ({
				...prev,
				updated: true,
			}));
		}),
		[],
	);

	useEffect(() => {
		folderRef.current = folder;
		errorsRef.current = errors;
		savingStateRef.current = savingState;
	}, [folder, errors, savingState]);

	useEffect(() => {
		dispatch(resetPageConfigs({}));

		loadFolder(id);
		loadUsers();

		if (id) {
			telemetryAddEvent(`${telemetryPage} - Open`, { area: "documents" });
		}

		window.addEventListener("beforeunload", checkUnsavedChangesBeforeClosing);

		return () => {
			window.removeEventListener("beforeunload", checkUnsavedChangesBeforeClosing);

			document.body.style.userSelect = null;
		};
	}, [id]);

	useEffect(() => {
		dispatch(
			updatePageHeader({
				primaryAction: handleSave,
				primaryActionText: !savingState.isSaving ? t("app:buttons.save") : t("app:buttons.saving"),
				primaryActionTooltip: !savingState.isSaving ? t("tooltips.saveFolder") : undefined,
				primaryActionDisabled: savingState.isSaving || !savingState.updated || disableSave,
			}),
		);
	}, [id, !!folder, savingState.isSaving, savingState.updated, savingState.canDelete, disableSave]);

	useEffect(() => {
		dispatch(
			updatePageConfigs({
				title: (folder || {}).title || t("customFolderDetail.title"),
				back: { action: backToCustomFolders },
				telemetryPage,
			}),
		);
	}, [(folder || {}).title]);

	return (
		<ComponentContainer padding="16px">
			{folder ? (
				<CustomFolder folder={folder} users={users} errors={errors} handleUpdate={updateFolder} telemetryPage={telemetryPage} />
			) : (
				<CircularProgressIndicator />
			)}
		</ComponentContainer>
	);
};

export default withErrorHandling(CustomFolderContainer);
