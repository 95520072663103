import {
	addDays,
	format,
	startOfWeek,
	endOfWeek,
	addWeeks,
	startOfMonth,
	endOfMonth,
	addMonths,
	subMonths,
	startOfYear,
	endOfYear,
	addYears,
} from "date-fns";

/*
usage:

The date range defaults to calulating from Now. If you want a range based off a different date, pass it in
as the 2nd parameter.

import getDateRange from './utils/customDateRange';

const dateRange = getDateRange('last7');
const dateRange = getDateRange('thisWeek');
const dateRange = getDateRange('nextMonth', '12-04-2014');

returns JS object with JS dates for 'to' and 'from', and strings for display values of 'to' and 'from'
*/

export const ISO_DATE_FORMAT = "yyyy-MM-dd";
export const DATE_RANGE_NONE = 0;
export const DATE_RANGE_TODAY = 2;
export const DATE_RANGE_THIS_WEEK = 3;
export const DATE_RANGE_THIS_MONTH = 4;
export const DATE_RANGE_LAST_MONTH = 5;
export const DATE_RANGE_CUSTOM = 1;

export default function getDateRange(keyword, keyDate) {
	const date = keyDate ? new Date(keyDate) : new Date();
	let dateRange;

	switch (keyword) {
		case "today":
			dateRange = rangeFromToday(date, 1);
			break;
		case "thisWeek":
			dateRange = thisWeek(date);
			break;
		case "lastWeek":
			dateRange = lastWeek(date);
			break;
		case "nextWeek":
			dateRange = nextWeek(date);
			break;
		case "thisMonth":
			dateRange = thisMonth(date);
			break;
		case "lastMonth":
			dateRange = lastMonth(date);
			break;
		case "nextMonth":
			dateRange = nextMonth(date);
			break;
		case "thisYear":
			dateRange = thisYear(date);
			break;
		case "lastYear":
			dateRange = lastYear(date);
			break;
		case "nextYear":
			dateRange = nextYear(date);
			break;
		case "next7":
			dateRange = rangeFromToday(date, 7);
			break;
		case "next30":
			dateRange = rangeFromToday(date, 30);
			break;
		case "next90":
			dateRange = rangeFromToday(date, 90);
			break;
		case "last7":
			dateRange = rangeFromToday(date, -7);
			break;
		case "last30":
			dateRange = rangeFromToday(date, -30);
			break;
		case "last90":
			dateRange = rangeFromToday(date, -90);
			break;
	}

	// If we want different date formatting by locale, we can enhance the next few lines.
	// I'm not coding that in yet, as we haven't had any discussion over what locales
	// we intend to support, or what the formatting differences will be.
	// But date-fns has locale support, so we can bring it in when appropriate.
	dateRange.displayTo = format(dateRange.to, "yyyy-MM-dd");
	dateRange.displayFrom = format(dateRange.from, "yyyy-MM-dd");

	return dateRange;
}

function thisWeek(date) {
	return {
		from: startOfWeek(date),
		to: endOfWeek(date),
	};
}

function lastWeek(date) {
	const newDateRange = thisWeek(date);
	newDateRange.from = addWeeks(newDateRange.from, -1);
	newDateRange.to = addWeeks(newDateRange.to, -1);
	return newDateRange;
}

function nextWeek(date) {
	const newDateRange = thisWeek(date);
	newDateRange.from = addWeeks(newDateRange.from, 1);
	newDateRange.to = addWeeks(newDateRange.to, 1);
	return newDateRange;
}

function thisMonth(date) {
	return {
		from: startOfMonth(date),
		to: endOfMonth(date),
	};
}

function lastMonth(date) {
	date = subMonths(date, 1);
	const newDateRange = thisMonth(date);
	return newDateRange;
}

function nextMonth(date) {
	const newDateRange = thisMonth(date);
	newDateRange.from = addMonths(newDateRange.from, 1);
	newDateRange.to = addMonths(newDateRange.to, 1);
	return newDateRange;
}

function thisYear(date) {
	return {
		from: startOfYear(date),
		to: endOfYear(date),
	};
}

function lastYear(date) {
	const newDateRange = thisYear(date);
	newDateRange.from = addYears(newDateRange.from, -1);
	newDateRange.to = addYears(newDateRange.to, -1);
	return newDateRange;
}

function nextYear(date) {
	const newDateRange = thisYear(date);
	newDateRange.from = addYears(newDateRange.from, 1);
	newDateRange.to = addYears(newDateRange.to, 1);
	return newDateRange;
}

function rangeFromToday(date, numDays) {
	const newDateRange = {
		from: new Date(date),
		to: new Date(date),
	};

	if (numDays < 0) {
		newDateRange.from = addDays(newDateRange.from, numDays);
	} else if (numDays > 0) {
		newDateRange.to = addDays(newDateRange.to, numDays);
	}

	return newDateRange;
}
