import request from "superagent";
import { API_HOST } from "config/env";

//
// id: agenda item id to undo
//
export function restoreItem(id, success, error) {
	const url = `${API_HOST}/api/agendaitem/${id}/restore`;
	const data = {};
	request
		.post(url)
		.withCredentials()
		.send(data)
		.then((res) => {
			let response = {};
			if (res.body) {
				response = res.body;
			}
			success(response);
		})
		.catch((err) => {
			error(err);
		});
}
