import typographyStyle from './typographyStyle';
import { whiteColor, grayColor, warningColor, focusColor } from '../shared';

const externalLabelHeight = 16;
const externalLabelLargeHeight = 20;
const headingLabel = {
	'& h1, & h2, & h3, & h4, & h5, & h6': {
		fontSize: 'inherit',
		fontWeight: 'inherit',
	},
};
const defaultExternalLabel = {
	height: (props) => `${externalLabelHeight * props.lines + (props.lines - 1) * (externalLabelHeight * 0.25)}px`,
	marginLeft: '12px',
	marginBottom: (props) => {
		if (typeof props.bottomSpacing === 'boolean' && props.bottomSpacing) {
			return '8px';
		}

		return props.bottomSpacing ? props.bottomSpacing : undefined;
	},
	...headingLabel,
};
const defaultExternalLabelLarge = {
	height: (props) => `${externalLabelLargeHeight * props.lines + (props.lines - 1) * (externalLabelLargeHeight * 0.25)}px`,
	marginBottom: (props) => {
		if (typeof props.bottomSpacing === 'boolean' && props.bottomSpacing) {
			return '8px';
		}

		return props.bottomSpacing ? props.bottomSpacing : undefined;
	},
	...headingLabel,
};

const externalLabelUppercase = {
	...typographyStyle.fieldLabel,
	...defaultExternalLabel,
	textTransform: 'uppercase',
};
const boldLabel = {
	...typographyStyle.boldFieldLabel,
	...defaultExternalLabel,
	textTransform: 'uppercase',
};
const externalLabelLargeUppercase = {
	...typographyStyle.fieldLabelLarge,
	...defaultExternalLabelLarge,
};

const inputStyle = () => ({
	container: {
		marginTop: (props) => props.marginTop,
		width: (props) => (props.fullWidth ? '100%' : 'auto'),
		
	},
	outlinedLabel: {
		'&.MuiInputLabel-shrink': {
			backgroundColor: whiteColor,
			padding: '0 4px',
		},
	},
	externalLabel: {
		// Legacy name - Use externalLabelUppercase instead
		...externalLabelUppercase,
	},
	externalLabelLarge: {
		// Legacy name - Use externalLabelLargeUppercase instead
		...externalLabelLargeUppercase,
	},
	externalLabelUppercase: {
		...externalLabelUppercase,
	},
	boldLabel: {
		...boldLabel,
	},
	externalLabelLargeUppercase: {
		...externalLabelLargeUppercase,
	},
	externalLabelSentence: {
		...typographyStyle.fieldLabel,
		...defaultExternalLabel,
	},
	externalLabelLargeSentence: {
		...typographyStyle.fieldLabelLargeSentence,
		...defaultExternalLabelLarge,
	},
	externalLabelEllipsis: {
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
	},
	defaultInput: {
		height: '88px',
	},
	smallInput: {
		width: (props) => (props && props.fullWidth ? '100%' : undefined),
		marginBottom: '16px',
		marginTop: '16px',
		'& .MuiInputBase-root': {
			height: '40px',
			'& .MuiInputBase-input': {
				boxSizing: 'border-box',
				height: '40px',
				paddingTop: '10.5px',
				paddingBottom: '10.5px',
				'& > div': {
					textOverflow: 'ellipsis',
					overflow: 'hidden',
				},
				'&.MuiInputBase-inputAdornedEnd': {
					width: 'calc(100% - 32px)',
				},
			},

			'& .MuiSelect-select': {
				'&:focus-visible': {
					outline: `solid 2px ${focusColor}`,
					outlineOffset: '3px',
					borderRadius: '4px',
				},
			},
		},
	},
	smallInputExternalLabel: {
		// Increase the specificity over "smallInput"
		'&.MuiFormControl-root': {
			marginTop: '0',
		},
		'& .MuiInputBase-root': {
			marginTop: '0',
		},
	},
	extraSmallInput: {
		width: (props) => (props && props.fullWidth ? '100%' : undefined),
		marginTop: '0',
		marginBottom: '16px',
		'& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)': {
			transform: 'translate(14px, 24px)',
		},
		'& .MuiInputBase-root': {
			height: '32px',
			'& .MuiInputBase-input': {
				boxSizing: 'border-box',
				height: '32px',
				paddingTop: '10.5px',
				paddingBottom: '10.5px',
				'& > div': {
					textOverflow: 'ellipsis',
					overflow: 'hidden',
				},
			},
		},
	},
	extraSmallInputExternalLabel: {
		'& .MuiInputBase-root': {
			marginTop: '0',
		},
	},
	required: {
		'& .MuiOutlinedInput-notchedOutline': {
			borderColor: warningColor,
		},
	},
	helperTextNoWrap: {
		'& .MuiFormHelperText-root': {
			whiteSpace: 'nowrap',
		},
	},
	selectIcon: {
		position: 'absolute',
		top: 'calc(50% - 12px)',
		right: '7px',
	},
	disabled: {
		'& .Mui-disabled': {
			color: grayColor[0],
		},
	},
	inputRowContainer: {
		display: 'flex',
		alignItems: 'center',
	},
	preText: {
		marginRight: '16px',
		marginBottom: '8px',
	},
	postText: {
		marginLeft: '16px',
		marginBottom: '8px',
	},
});

export default inputStyle;
