import React, { useState, useEffect, useContext, useRef } from "react";
import { Navigate, useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import CircularProgressIndicator from "atlas/components/Progress/CircularProgressIndicator";
import { UserContext } from "contexts/User/UserContext";
import { pollLiveData } from "redux/app/actions";
import setupSignalR from "utils/communication/SignalrClient";
import isSignedIn from "utils/isSignedIn";

const Authenticate = (ChildComponent) => {
	const Authentication = (props) => {
		const { t } = useTranslation();
		const navigate = useNavigate();
		const location = useLocation();
		const [signedIn, setSignedIn] = useState(false);
		const [signingIn, setSigningIn] = useState(true);
		const { setUser } = useContext(UserContext);
		let { user } = useContext(UserContext);
		const isMounted = useRef(false);
		const dispatch = useDispatch();

		const verifySignedIn = (recheck) => {
			if (!user || recheck) {
				isSignedIn(
					(res) => {
						if (!user || user.username !== res.body.username) {
							user = setUser({
								id: res.body.id,
								name: res.body.name,
								username: res.body.username,
								systemAdministrator: res.body.systemAdministrator,
								boardAdmin: res.body.boardAdmin,
								boardMember: res.body.boardMember,
								boardStaff: res.body.boardStaff,
								goalAdmin: res.body.goalAdmin,
								requestToSpeakAdmin: res.body.requestToSpeakAdmin,
								portalAdmin: res.body.portalAdmin,
								userAdmin: res.body.userAdmin,
								workflowAdmin: res.body.workflowAdmin,
								documentLibrary: res.body.documentLibrary,
								policySite: res.body.policySite,
								publicUser: false,
								callback: () => {
									setupSignalR({ dispatch, t, navigate });
								},
							});
						}

						if (isMounted.current) {
							setSignedIn(true);
							setSigningIn(false);
							dispatch(pollLiveData({ resetTimeout: true }));
						}
					},
					() => {
						if (isMounted.current) {
							setSignedIn(false);
							setSigningIn(false);
						}
					},
				);
			} else {
				setSignedIn(user !== undefined);
				setSigningIn(false);
			}
		};

		useEffect(() => {
			isMounted.current = true;

			verifySignedIn(true);

			return () => {
				isMounted.current = false;
			};
		}, []);

		useEffect(() => {
			verifySignedIn(true);
		}, [location.pathname, location.search]);

		if (signingIn) {
			return <CircularProgressIndicator />;
		}

		if (!signedIn) {
			return <Navigate to="/sign-in" state={{ from: location }} />;
		}

		return <ChildComponent {...props} />;
	};

	return Authentication;
};

export default Authenticate;
