import { addAgendaItem, updateAgendaItem, deleteItem, duplicateItem } from "./agendaItem";
import { onDragStart, onDragUpdate, onDragEnd } from "./drag";
import { invalidFileExtension, addFileUpload, addFile, deleteFile, getFileAttachment, getFileAttachmentAll } from "./file";
import { addHeader, updateHeader, deleteHeader, duplicateHeader } from "./header";
import {
	moveHeadingUp,
	moveHeadingDown,
	moveSubheadingUp,
	moveSubheadingDown,
	moveItemUp,
	moveItemDown,
	moveRecommendationUp,
	moveRecommendationDown,
} from "./move";
import { addRecommendation, deleteRecommendation, duplicateRecommendation, updateRecommendation } from "./recommendation";
import {
	addConsentSection,
	addMemberOnlySection,
	addPublicCommentSection,
	setSectionPublicStatus,
	setConsentSectionStatus,
	setPublicCommentSectionStatus,
} from "./section";
import { addSubheader } from "./subheader";
import { addText, updateText, removeText } from "./text";
import { undo, redo } from "./undo";
import { saveAgenda, setActiveItem, findItemByID, getAgenda, reOrderItemArray, setMeetingEditorData, focusNextElement } from "./utils";

// Only functions that are called from React event handlers should to be exported here, as everything exported gets bound to an instance of MeetingEditor.
// For any function that is solely used by other functions in /functions and does not need that binding, skip this.

const editorFunctions = {
	addAgendaItem,
	updateAgendaItem,
	deleteItem,
	duplicateItem,
	addHeader,
	updateHeader,
	deleteHeader,
	duplicateHeader,
	invalidFileExtension,
	addFileUpload,
	addFile,
	deleteFile,
	addRecommendation,
	deleteRecommendation,
	duplicateRecommendation,
	updateRecommendation,
	addSubheader,
	addText,
	updateText,
	removeText,
	addConsentSection,
	addMemberOnlySection,
	addPublicCommentSection,
	setSectionPublicStatus,
	setConsentSectionStatus,
	setPublicCommentSectionStatus,
	moveHeadingUp,
	moveHeadingDown,
	moveSubheadingUp,
	moveSubheadingDown,
	moveItemUp,
	moveItemDown,
	moveRecommendationUp,
	moveRecommendationDown,
	onDragStart,
	onDragUpdate,
	onDragEnd,
	setActiveItem,
	findItemByID,
	getAgenda,
	undo,
	redo,
	reOrderItemArray,
	setMeetingEditorData,
	getFileAttachment,
	getFileAttachmentAll,
	focusNextElement,
	saveAgenda,
};

export default editorFunctions;
