const getIconName = (document, isPolicy = false) => {
	if (isPolicy && !document.folder && !document.canUpdate) {
		return "document";
	} else if (document.folder) {
		return "folder";
	} else if (document.agenda) {
		if (document.preview) {
			return "document-agenda-preview";
		} else {
			return "document-agenda-published";
		}
	} else if (document.minutes) {
		if (document.preview) {
			return "document-minutes-preview";
		} else if (document.published) {
			return "document-minutes-published";
		} else {
			return "document-minutes-adopted";
		}
	} else {
		const lowerExtension = document.extension.toLowerCase().replace(/\./g, "");
		switch (lowerExtension) {
			case "xls":
			case "xlsx":
				return "document-excel";

			case "bmp":
			case "gif":
			case "jpg":
			case "jpeg":
			case "png":
			case "tif":
			case "tiff":
				return "document-image";

			case "pdf":
				return "document-pdf";

			case "ppt":
			case "pptx":
				return "document-powerpoint";

			case "doc":
			case "docx":
				return "document-word";

			default:
				return "document";
		}
	}
};

export default getIconName;
