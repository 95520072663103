import React from "react";
import SvgIcon from "@mui/material/SvgIcon";
import { withTranslation } from "react-i18next";
import themeConfig from "../../atlas/assets/jss/theme";
import Tooltip from "@mui/material/Tooltip";
import getOr from "lodash/fp/getOr";
import omit from "lodash/fp/omit";

const icon = (props) => {
	const { color, t } = props;
	const fillColor = color ? getOr(color, ["palette", color, "main"], themeConfig) : "currentColor";

	return (
		<SvgIcon {...omit(props, t)}>
			<defs>
				<path
					d="M22.1538462,11.2552308 L0.923076923,11.2552308 C0.413275616,11.2552308 0,11.6685064 0,12.1783077 C0,12.688109 0.413275616,13.1013846 0.923076923,13.1013846 L22.1538462,13.1013846 C22.6636475,13.1013846 23.0769231,12.688109 23.0769231,12.1783077 C23.0769231,11.6685064 22.6636475,11.2552308 22.1538462,11.2552308 Z M14.1677989,19.7831835 C13.8073149,20.1436674 13.8073149,20.7281274 14.1677989,21.0886114 C14.5282828,21.4490953 15.1127428,21.4490953 15.4732268,21.0886114 L23.729637,12.8322011 C24.090121,12.4717172 24.090121,11.8872572 23.729637,11.5267732 L15.4732268,3.27036297 C15.1127428,2.90987901 14.5282828,2.90987901 14.1677989,3.27036297 C13.8073149,3.63084693 13.8073149,4.21530691 14.1677989,4.57579087 L21.7714952,12.1794872 L14.1677989,19.7831835 Z"
					id="arrow-left-path-1"
				/>
			</defs>
			<g id="arrow-left" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<mask id="arrow-left-mask-2" fill="white">
					<use xlinkHref="#arrow-left-path-1" />
				</mask>
				<use
					fill={fillColor}
					fillRule="nonzero"
					transform="translate(12.000000, 12.179487) scale(-1, 1) translate(-12.000000, -12.179487) "
					xlinkHref="#arrow-left-path-1"
				/>
			</g>
		</SvgIcon>
	);
};

export default withTranslation()(icon);
