import { CLOSED_LEFT_NAV_WIDTH, OPEN_LEFT_NAV_WIDTH, BOTTOM_NOTICE_HEIGHT } from 'assets/jss/utils/componentSizes';
import { defaultFont, primaryColor, whiteColor, backgroundColor, grayColor } from '../shared';

const contentText = {
	whiteSpace: 'nowrap',
	textOverflow: 'ellipsis',
	overflow: 'hidden',
};

const bottomNoticeBarStyle = (theme) => ({
	footer: {
		position: 'fixed',
		left: 'auto',
		right: '0',
		bottom: '0',
		width: '100%',
		[theme.breakpoints.up('sm')]: {
			width: (props) => `calc(100% - ${props.openLeftNav ? OPEN_LEFT_NAV_WIDTH : CLOSED_LEFT_NAV_WIDTH}px)`,
		},
	},
	footerToolbar: {
		height: `${BOTTOM_NOTICE_HEIGHT}px`,
		maxHeight: `${BOTTOM_NOTICE_HEIGHT}px`,
		minHeight: `${BOTTOM_NOTICE_HEIGHT}px`,
		boxSizing: 'border-box',
		backgroundColor: primaryColor[0],
		padding: '16px',
		display: 'flex',
		alignItems: 'center',
	},
	footerAction: {
		cursor: 'pointer',
	},
	icon: {
		height: '48px',
		marginRight: '16px',
		backgroundColor: backgroundColor[1],
		borderRadius: '24px',
		display: 'flex',
		alignItems: 'center',
		'&:hover': {
			backgroundColor: backgroundColor[0],
		},
	},
	content: {
		maxWidth: 'calc(100% - 64px)',
	},
	contentContainer: {
		display: 'flex',
		padding: '8px',
		maxWidth: 'calc(100% - 64px)',
		'&:hover': {
			backgroundColor: primaryColor[4],
		},
		'&:active': {
			backgroundColor: grayColor[1],
		},
	},
	primaryText: {
		...contentText,
		...defaultFont,
		fontSize: '22px',
		lineHeight: '1.18',
		fontWeight: '600',
		color: whiteColor,
	},
	secondaryText: {
		...contentText,
		...defaultFont,
		fontSize: '12px',
		lineHeight: '1.33',
		fontWeight: '600',
		letterSpacing: '1px',
		color: whiteColor,
		textTransform: 'uppercase',
	},
	preText: {
		marginBottom: '4px',
	},
	postText: {
		marginTop: '4px',
	},
	closeButton: {
		position: 'absolute',
		top: '0',
		right: '0',
		'& button': {
			padding: '8px',
		},
	},
});

export default bottomNoticeBarStyle;
