import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useMatch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { loadPolicyBook } from "../../redux/policyGovernance/actions";
import { SettingsContext } from "contexts/Settings/SettingsContext";
import telemetryAddEvent from "utils/telemetryAddEvent";
import WopiFrame from "../Wopi/WopiFrame";
import makeStyles from "@mui/styles/makeStyles";
import { whiteColor } from "atlas/assets/jss/shared";
import { FOLDER_TYPE_AGENDA_ITEM_COVER_TEMPLATE } from "utils/enums/DocumentFolderTypes";
import { displayErrorNotification } from "views/Policy/utils/getNotification";

const useStyles = makeStyles(() => ({
	document: {
		height: "100%",
	},
	unavailable: {
		padding: "24px",
		textAlign: "center",
	},
	frame: {
		display: "block",
		border: "none",
		height: "100%",
		width: "100%",
		backgroundColor: whiteColor,
	},
	editorFrame: {
		"& .ck-content": {
			height: "calc(100vh - 150px)",
			overflow: "auto",
		},
	},
	hidden: {
		visibility: "hidden", // Workaround for PDFTron in firefox
		height: "0 !important",
		width: "0 !important",
	},
}));

const PolicyEditContainer = () => {
	const { params: { id: authenticatedId } = {} } = useMatch({ path: "/policydoc/edit/:id", end: true }) || {};
	const id = authenticatedId || publicPortalId;
	const classes = useStyles();

	const [policyDetails, setPolicyDetails] = useState(null);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { lite, pdfTronL, wopiSettings, testSite } = useContext(SettingsContext);
	const { dateDeleted, dateModified, canUpdate, guid, title, extension, parentFolderType, wopiDocumentId, html } = policyDetails || {};

	const loadDocument = (id = 0) => {
		const history = window.history;
		dispatch(loadPolicyBook(id, true))
			.then((res) => {
				if (res.status === 200 && res?.body?.guid !== null) {
					const { body: document } = res || {};
					if (document) {
						setPolicyDetails(document);
					}
				} else {
					throw res;
				}
			})
			.catch(async (err) => {
				if (err?.body?.guid === null) {
					displayErrorNotification("Unable to fetch Policy Details Please try again", dispatch);
				} else {
					displayErrorNotification(err?.response?.body || "Something went wrong. Please try again", dispatch);
				}
				if (history.length > 1) {
					navigate(-1);
				}
			});
	};
	useEffect(() => {
		loadDocument(id);
	}, [id]);

	const getFileContainer = () => {
		if (dateDeleted) {
			// This document has been deleted
			return <div className={classes.unavailable}>{t("detail.labels.documentDeleted")}</div>;
		}
		let fileContainer = null;

		switch (policyDetails?.extension) {
			case ".doc":
			case ".docx":
			case ".wopitest":
			case ".wopitestx":
			case ".ppt":
			case ".pptx":
				if (canUpdate) {
					telemetryAddEvent(
						parentFolderType === FOLDER_TYPE_AGENDA_ITEM_COVER_TEMPLATE
							? `Policy Detail-Agenda Item Cover Template Edit`
							: `Policy Detail-Document Library Edit Office`,
					);

					fileContainer = (
						<div className={classes.frame}>
							<WopiFrame id={wopiDocumentId} wopiSettings={wopiSettings} extension={extension} testSite={testSite} isPolicyDate={true} />
						</div>
					);
				}
				break;
		}

		return fileContainer;
	};

	return (
		<>
			<div id={id} className={classes.document}>
				{getFileContainer()}
			</div>
		</>
	);
};

export default PolicyEditContainer;
