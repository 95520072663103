import React from "react";
import { useTranslation } from "react-i18next";

import { formatDate } from "utils/date";

const DocumentTreeDateItemColumn = (props) => {
	const { value, width } = props;
	const { t } = useTranslation();

	return (
		<div style={{ width: width, flexShrink: !width ? "1" : "0" }}>
			{formatDate(value, value, null, t("app:at"), "", "", false, true, true)}
		</div>
	);
};

export default DocumentTreeDateItemColumn;
