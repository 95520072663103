import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { useSelector, useDispatch } from "react-redux";

import makeStyles from "@mui/styles/makeStyles";
import { Typography } from "@mui/material";

import Accordion from "atlas/components/Accordion/Accordion";
import AccessibleIconButton from "atlas/components/Buttons/AccessibleIconButton";
import GenericDialog from "atlas/components/Dialogs/GenericDialog";
import Icon from "atlas/components/Icon/Icon";
import RollCall from "./RollCall";
import { STATUS_PRESENT, STATUS_LATE } from "../../LiveMeeting/utils/rollCallUserStatus";
import typographyStyle from "atlas/assets/jss/components/typographyStyle";
import { blackColor } from "atlas/assets/jss/shared";

import { checkMeetingQuorumMet } from "../../LiveMeeting/utils/votingUtils";
import { updateMeeting } from "../../../redux/meeting/actions";

const useStyles = makeStyles({
	header: {
		display: "flex",
		alignItems: "center",
		boxSizing: "border-box",
		height: "64px",
		padding: "16px",
		paddingBottom: "0",
	},
	title: {
		...typographyStyle.drawerTitle,
		color: blackColor[3],
		flexGrow: "1",
		display: "flex",
		alignItems: "center",
	},
	close: {
		marginTop: "-4px",
		marginRight: "-12px",
	},
	saveStatus: {
		...typographyStyle.toolbarLabel,
		color: blackColor[3],
		opacity: "1",
		marginRight: "16px",
	},
	saved: {
		opacity: "0",
		transition: "opacity 1s ease-in",
	},
	body: {
		overflow: "auto",
	},
});

const RollCallPanel = (props) => {
	const { closePanel, saveRollCall, saveStatus, onlineVoters, votingSettings } = props;
	const { t } = useTranslation("meetings");
	const dispatch = useDispatch();
	const classes = useStyles();

	const meetingsReducer = useSelector((state) => state.meetingsReducer);
	const {
		meeting: { rollCall },
	} = meetingsReducer;

	const [showRollCallQuorumLostDialog, setShowRollCallQuorumLostDialog] = useState(false);

	const handleUpdate = (e, userId) => {
		const {
			target: { value },
		} = e;
		const {
			meeting: {
				rollCall: { users },
			},
			meeting,
		} = meetingsReducer;

		// Update status
		const user = users.find((user) => user.userId === userId);
		if (user) {
			user.status = parseInt(value, 10);
		}

		// Save roll call
		saveRollCall(meeting.rollCall);

		// Trigger rerender
		dispatch(updateMeeting(meeting));

		if (!checkMeetingQuorumMet(meeting, meeting.rollCall)) {
			setShowRollCallQuorumLostDialog(true);
		}

		if (document.getElementById("radio-btn")) {
			document.getElementById("radio-btn").parentElement.style.backgroundColor = "";
		}
	};

	const members = rollCall.users.filter((user) => user.votingMember);
	const nonVotingAttendees = rollCall.users.filter((user) => !user.votingMember);

	return (
		<>
			{showRollCallQuorumLostDialog && (
				<GenericDialog
					show
					title={t("rollCallQuorumLostDialog.title")}
					primaryAction={() => {
						setShowRollCallQuorumLostDialog(false);
					}}
					primaryTitle={t("rollCallQuorumLostDialog.buttonText")}
					secondaryAction={null}
					secondaryTitle={null}
					closeIcon={<Icon name="close" />}
					data-cy="roll-call-quorum-lost"
				>
					<Typography>{t("rollCallQuorumLostDialog.body")}</Typography>
				</GenericDialog>
			)}
			<div className={classes.header} data-cy="roll-call-panel-header">
				<div className={classes.title}>
					<div>{t("rollCall.attendeesRollCall")}</div>
				</div>
				<div className={classes.close}>
					<span
						className={clsx(classes.saveStatus, { [classes.saved]: saveStatus === t("agendaMenu:saved") })}
						data-cy="roll-call-save-status"
					>
						{saveStatus}
					</span>
					<AccessibleIconButton
						aria-label={t("tooltips.closeRollCall")}
						onClick={closePanel}
						iconName="close"
						dataCy="close-notes"
						tooltipText={t("tooltips.closeRollCall")}
						name="close"
					/>
				</div>
			</div>
			<div className={classes.body}>
				<Accordion
					label={t("rollCall.members", {
						present: members.filter((user) => user.status === STATUS_PRESENT || user.status === STATUS_LATE).length,
						total: members.length,
					})}
					startOpen
					overflow={false}
					dataCy={`roll-call-members`}
				>
					<RollCall
						users={members}
						onlineVoters={onlineVoters}
						votingSettings={votingSettings}
						handleUpdate={handleUpdate}
						dataCy="roll-call-members"
					></RollCall>
				</Accordion>
				{nonVotingAttendees.length > 0 && (
					<Accordion
						label={t("rollCall.nonVotingAttendees", {
							present: nonVotingAttendees.filter((user) => user.status === STATUS_PRESENT || user.status === STATUS_LATE).length,
							total: nonVotingAttendees.length,
						})}
						startOpen
						overflow={false}
						dataCy={`roll-call-non-voting-attendees`}
					>
						<RollCall
							users={nonVotingAttendees}
							onlineVoters={onlineVoters}
							handleUpdate={handleUpdate}
							dataCy="roll-call-non-voting-attendees"
						></RollCall>
					</Accordion>
				)}
			</div>
		</>
	);
};

export default RollCallPanel;
