import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';

import { Box, Link } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import { breadcrumbColor } from '../../assets/jss/shared';
import menuStyle from '../../assets/jss/components/menuStyle';
import AccessibleIconButton from '../Buttons/AccessibleIconButton';
import NonModalMenu from '../Menu/NonModalMenu';
import LargeLabel from '../Typography/LargeLabel';
import Icon from '../Icon/Icon';
import Tooltip from '../Tooltip/Tooltip';

const useStyles = makeStyles(() => ({
	container: {
		display: 'flex',
		flexWrap: 'nowrap',
		justifyContent: 'flex-start',
		minWidth: 0,
		alignItems: 'center',
	},
	menuButton: {
		margin: '0 -4px 0 -8px',
	},
	menu: {
		...menuStyle.menu,
	},
	expandRightIcon: {
		color: breadcrumbColor[0],
	},
	breadcrumb: {
		color: breadcrumbColor[0],
		'&:hover': {
			color: breadcrumbColor[1],
			textDecoration: 'none',
		},
	},
	breadcrumbSelected: {
		color: breadcrumbColor[2],
	},
}));
//
// props:
//      items: items to populate breadcrumb with
//          { 	name: (required),
//				url: (if null, then it is not clickable),
//				dataCy: (if null, uses 'breadcrumb{index}') }
//
const Breadcrumbs = (props) => {
	const { className, items, showMenu, notification, classes: overrideClasses = {}, separator: separatorProp } = props;
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [anchor, setAnchor] = useState();
	const classes = useStyles();

	const toggleMenu = (e) => {
		e.stopPropagation();
		e.preventDefault();

		setAnchor((prev) => (!prev ? e.currentTarget : null));
	};

	const closeMenu = () => {
		setAnchor(null);
	};

	const getMenuOptions = () =>
		items
			? items
					.filter((item) => item.url)
					.map((item) => ({
						label: item.icon ? item.title : item.name,
						actionFunction: () => navigate(item.url),
					}))
			: [];

	const separator = (
		<Box
			component="span"
			ml={0.5}
			mr={0.5}
			className={clsx(classes.expandRightIcon, overrideClasses.expandRightIcon, classes.breadcrumb, overrideClasses.breadcrumb)}
		>
			{separatorProp || <Icon name="expand-right" size="16px" />}
		</Box>
	);

	const applyTooltip = (children, tooltip) => (tooltip ? <Tooltip title={tooltip}>{children}</Tooltip> : children);

	return (
		<>
			<Box className={clsx(classes.container, overrideClasses.container, className)}>
				{items && showMenu && items.filter((item) => item.url).length > 0 && (
					<>
						<AccessibleIconButton
							id="breadcrumb-menu-button"
							className={clsx(classes.menuButton, overrideClasses.menuButton)}
							iconColor="rgba(0, 0, 0, 0.87)"
							aria-label={t('app:buttons.breadcrumbMenu')}
							onClick={toggleMenu}
							iconName="more-horizontal"
							iconSize="20px"
							dataCy="breadcrumb-menu-button"
						/>
						{anchor && (
							<NonModalMenu
								id="breadcrumb-menu"
								className={clsx(classes.menu, overrideClasses.menu)}
								anchorEl={anchor}
								keepMounted
								open
								onClose={closeMenu}
								options={getMenuOptions()}
								position="bottom-start"
							/>
						)}
					</>
				)}
				{items &&
					items.map((item, index) => {
						if (!item.name) {
							return null;
						}
						const disabled = !item.url || window.location.href.endsWith(item.url);
						const link = applyTooltip(
							item.icon ? (
								<Link
									component="button"
									className={clsx(classes.breadcrumb, overrideClasses.breadcrumb)}
									onClick={() => navigate(item.url)}
									disabled={disabled}
									aria-disabled={disabled ? `${disabled}` : undefined}
									data-cy={item.dataCy || `breadcrumb${index}`}
								>
									<Box display="flex" flex="auto" justifyContent="flex-middle">
										<Icon name={item.name} size={item.size || '16px'} />
									</Box>
								</Link>
							) : item.url ? (
								<Link
									component="button"
									className={clsx(classes.breadcrumb, overrideClasses.breadcrumb)}
									onClick={() => navigate(item.url)}
									disabled={disabled}
									aria-disabled={disabled ? `${disabled}` : undefined}
									data-cy={item.dataCy || `breadcrumb${index}`}
									style={{ overflow: 'hidden', minWidth: '56px' }}
								>
									<LargeLabel style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{item.name}</LargeLabel>
								</Link>
							) : (
								<Box
									className={clsx(classes.breadcrumbSelected, overrideClasses.breadcrumbSelected)}
									component="span"
									data-cy={item.dataCy || `breadcrumb${index}`}
									style={{ overflow: 'hidden', minWidth: '56px' }}
								>
									<LargeLabel style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{item.name}</LargeLabel>
								</Box>
							),
							item.icon ? item.title || item.name : item.title && item.title !== item.name ? item.title : null,
						);

						return (
							// eslint-disable-next-line react/no-array-index-key
							<React.Fragment key={`breadcrumb${index}`}>
								{index > 0 && separator}
								{link}
							</React.Fragment>
						);
					})}
			</Box>
			{notification && (
				<Box display="flex" flex="auto" justifyContent="flex-end" mr={2}>
					{notification}
				</Box>
			)}
		</>
	);
};

Breadcrumbs.propTypes = {
	className: PropTypes.string,
	items: PropTypes.array,
	showMenu: PropTypes.bool,
	separator: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node, PropTypes.string]),
};

Breadcrumbs.defaultProps = {
	className: undefined,
	items: undefined,
	showMenu: false,
	separator: undefined,
};

export default Breadcrumbs;
