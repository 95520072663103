import cloneDeep from "lodash/cloneDeep";
import { v4 as uuid } from "uuid";
import { prepareAttachmentDuplication } from "../../../utils/meetingElement";
import { stripHtml } from "utils/processHtml";
let globalObjectForAgendaItems = null;

const partialSearch = (searchTerm, myMap, searchFn, keyName) => {
	let lastPair = null;
	for (const [key, value] of myMap) {
		lastPair = [key, value];
		if (searchFn(key, searchTerm, keyName)) {
			return value;
		}
	}
	return lastPair ? lastPair[1] : null;
};

const compositeSearchFunction = (key, searchTerm, keyname) => {
	return key[keyname] && key[keyname] === searchTerm;
};

const getValueFromMap = (searchTerm, keyName, overallMapForRightAgendaItems) => {
	return partialSearch(searchTerm, overallMapForRightAgendaItems, compositeSearchFunction, keyName);
};

const fetchLastEntry = (obj) => {
	const lastEntry = Object.entries(obj).pop();
	return lastEntry[1];
};

const findDataObjectwithExistingIndex = (arr, index) => arr.find((obj) => obj.index === index);

const isObjectNotEmpty = (obj) => obj && Object.keys(obj).length > 0;

const getIndexByOrderOrLastEntry = (objectToFind, orderAsPerType, secondObjectToFind) => {
	let finalValue = null;
	if (isObjectNotEmpty(objectToFind)) {
		finalValue = objectToFind[orderAsPerType] || fetchLastEntry(objectToFind);
	} else if (secondObjectToFind && isObjectNotEmpty(secondObjectToFind)) {
		finalValue = fetchLastEntry(secondObjectToFind);
	}
	if (finalValue) return finalValue.index + (finalValue.totalChildren || 0) + 1;
	return false;
};

export const pushDataIntoArray = (itemsToInsert, tempObj, checkforType10, isMemberOnlySection) => {
	const existingObj = findDataObjectwithExistingIndex(itemsToInsert, tempObj.index);
	tempObj.data?.forEach((item) => {
		item.copied = true;
		if (
			(item.itemType === 3 || item.itemType === 10) &&
			isMemberOnlySection !== undefined &&
			item.fields?.Closed?.Value &&
			!isMemberOnlySection
		) {
			item.isMemberOnlyWarning = true;
		}
	});
	if (existingObj) {
		if (checkforType10) {
			const firstElement = tempObj.data[0];
			// First: Check for a match with relationshipGuid
			const matchingIndex = (() => {
				for (let i = existingObj.data.length - 1; i >= 0; i--) {
					if (existingObj.data[i].attributes.relationshipGuid === firstElement.attributes.relationshipGuid) {
						return i; // Return the last matching index and stop
					}
				}
				return -1; // Return -1 if no match is found
			})();

			if (matchingIndex !== -1) {
				// Find the length of children for the matching item
				const matchingItem = existingObj.data[matchingIndex];
				const childrenLength = globalObjectForAgendaItems[matchingItem.guid]?.children
					? globalObjectForAgendaItems[matchingItem.guid]?.children.length
					: 0;

				// Insert tempObj["data"] after the matching item and its children
				existingObj.data = [
					...existingObj.data.slice(0, matchingIndex + 1 + childrenLength),
					...tempObj.data,
					...existingObj.data.slice(matchingIndex + 1 + childrenLength),
				];
				return;
			}
			// Second: Check for type 10 mismatch if no matching relationshipGuid was found
			const hasType10Mismatch = existingObj.data.some(
				(item) => item.itemType === 10 && item.guid !== firstElement.attributes.relationshipGuid,
			);
			if (hasType10Mismatch) {
				// Insert tempObj["data"] before existingObj.data if type 10 mismatch is found
				existingObj.data = [...tempObj.data, ...existingObj.data];
				return;
			}
		}
		// If no match and no type 10 mismatch, append tempObj["data"] to the end
		existingObj.data.push(...tempObj.data);
	} else {
		// Insert new object if index doesn't exist
		itemsToInsert.push(tempObj);
	}
};

export const createSubheadingDataToInsert = (
	tempObj,
	objectForAgendaItems,
	isEntireSubheadingEnabled,
	subheadingChildren,
	mainsubHeadingObject,
	childrenEnabled = false,
	mainParentGuid,
	isCopy,
	isEntireSectionEnabledForINP,
) => {
	let newGeneratedGuid = null;
	if (isEntireSubheadingEnabled || Object.values(subheadingChildren).some((value) => value === true)) {
		if (isCopy) {
			// Subheading is children of type = 10 so if that type = 10 is present then this loop will run
			mainsubHeadingObject.itemDetails.attributes["newrelationshipGuid"] = mainParentGuid;
			mainsubHeadingObject.itemDetails.attributes.relationshipGuid = mainParentGuid;
			newGeneratedGuid = uuid();
			mainsubHeadingObject.itemDetails["newguid"] = newGeneratedGuid;
		} else if (mainParentGuid) {
			mainsubHeadingObject.itemDetails.attributes.relationshipGuid = mainParentGuid;
		}

		if (!isCopy && isEntireSectionEnabledForINP !== undefined && !isEntireSectionEnabledForINP) {
			mainsubHeadingObject.itemDetails.attributes.relationshipGuid = mainParentGuid;
			mainsubHeadingObject.itemDetails.attributes["newrelationshipGuid"] = mainParentGuid;
			if (!isEntireSubheadingEnabled && Object.values(subheadingChildren).some((value) => value === true)) {
				newGeneratedGuid = uuid();
				mainsubHeadingObject.itemDetails["newguid"] = newGeneratedGuid;
				mainsubHeadingObject.itemDetails["attachmentDuplicationRequired"] = true;
			}
		}
		tempObj["data"] = [
			...(tempObj["data"] || []),
			{ ...mainsubHeadingObject.itemDetails, ...(!isEntireSubheadingEnabled && { itemDeleteDisable: true }) },
		];
		childrenEnabled = true;
	}
	mainsubHeadingObject.children?.forEach((element) => {
		const { itemType, guid } = element;
		if (itemType === 7 && isEntireSubheadingEnabled) {
			if (isCopy && newGeneratedGuid) {
				element.attributes["newrelationshipGuid"] = newGeneratedGuid;
				element["newguid"] = uuid();
			} else if (!isCopy && newGeneratedGuid) {
				element.attributes["newrelationshipGuid"] = newGeneratedGuid;
			}
			tempObj["data"] = [...tempObj["data"], element];
		} else if (itemType === 3 && subheadingChildren[guid]) {
			if (!isEntireSubheadingEnabled) {
				element.checkForSibling = true;
				if (!isCopy && newGeneratedGuid) {
					element.attributes["newrelationshipGuid"] = newGeneratedGuid;
				}
			}
			let childrenOverall = objectForAgendaItems[guid]?.children || [];
			if (isCopy && newGeneratedGuid) {
				element.attributes["newrelationshipGuid"] = newGeneratedGuid;
				generateNewGuidForItems(element, childrenOverall);
			}
			tempObj["data"] = [...tempObj["data"], element, ...childrenOverall];
		}
	});
	return [childrenEnabled, tempObj];
};

// Sort the array only once after all insertions are complete
export const sortItemsByIndex = (itemsToInsert) => {
	itemsToInsert.sort((a, b) => b.index - a.index);
};

export const createObjectsToInsert = (overallSelectedMeetingItems, objectForItems, overallMapForRightAgendaItems, isCopy) => {
	//It will create a itemsToInsert array which will have all the items which needs to be inserted on right agenda along with it's index
	let itemsToInsert = [];
	let objectForAgendaItems = cloneDeep(objectForItems);
	globalObjectForAgendaItems = objectForAgendaItems;
	for (let key in overallSelectedMeetingItems) {
		let sectionObject = overallSelectedMeetingItems[key];
		if (typeof sectionObject === "object" && !Array.isArray(sectionObject)) {
			let obj = {};
			const {
				itemDetails,
				isItemPresent,
				criteria: { order },
				children,
			} = objectForAgendaItems[key];
			if (isItemPresent) {
				const {
					recommendations = null,
					childrenAgendaItems = null,
					subheading = null,
					index: mainParentIndex,
					totalChildren: mainParentTotalChildren,
					isMemberOnlySection,
				} = getValueFromMap(isItemPresent.guid, "guid", overallMapForRightAgendaItems);
				const isEntireSectionEnabled = sectionObject.enabled === true ? true : false;
				children?.forEach((element) => {
					let tempObj = {};
					const { itemType, mainOrderAsPerType, guid } = element;
					//To be checked later
					// if(!isMemberOnlySection && Value){
					// 	dispatch(setWarningMessage("Cannot copy/move closed items"));
					// }
					if (itemType === 7 && isEntireSectionEnabled) {
						//Recommendation
						tempObj["index"] = getIndexByOrderOrLastEntry(recommendations, mainOrderAsPerType) || mainParentIndex + 1;
						element.attributes.relationshipGuid = isItemPresent.guid;
						isCopy && (element["newguid"] = uuid());
						element.deleteEnabled = true;
						tempObj["data"] = [element];
						pushDataIntoArray(itemsToInsert, tempObj, true, isMemberOnlySection);
					} else if (itemType === 3 && sectionObject[guid]) {
						//Agenda Item
						tempObj["index"] = getIndexByOrderOrLastEntry(childrenAgendaItems, mainOrderAsPerType, recommendations) || mainParentIndex + 1;
						element.attributes.relationshipGuid = isItemPresent.guid;
						let childrenOverall = objectForAgendaItems[element.guid]?.children || [];
						if (isCopy) {
							generateNewGuidForItems(element, childrenOverall);
						}
						tempObj["data"] = [element, ...childrenOverall];
						pushDataIntoArray(itemsToInsert, tempObj, true, isMemberOnlySection);
					} else if (itemType === 10) {
						//Subheading
						const subheadingObject = sectionObject[guid];
						const isEntireSubheadingEnabled = sectionObject[guid].enabled === true ? true : false;
						const sameSubheadingParentPresent = objectForAgendaItems[guid].isItemPresent;
						let childrenAgendaItemsOfSubheading = null;
						let recommendationOfSubheading = null;
						if (sameSubheadingParentPresent) {
							for (let key in subheading) {
								let currentSubheading = subheading[key];
								if (currentSubheading.guid === sameSubheadingParentPresent.guid) {
									childrenAgendaItemsOfSubheading = currentSubheading.childrenAgendaItems;
									recommendationOfSubheading = currentSubheading.recommendation;
								}
							}
						}
						if (sameSubheadingParentPresent) {
							objectForAgendaItems[guid].children.forEach((element) => {
								const { guid, mainOrderAsPerType, itemType } = element;
								let tempSubheadingObj = {};
								if (itemType === 7 && isEntireSubheadingEnabled) {
									//Recommendation
									element.attributes.relationshipGuid = sameSubheadingParentPresent.guid;
									isCopy && (element["newguid"] = uuid());
									element.deleteEnabled = true;
									tempSubheadingObj["index"] =
										getIndexByOrderOrLastEntry(recommendationOfSubheading, mainOrderAsPerType) || sameSubheadingParentPresent.index + 1;
									tempSubheadingObj["data"] = [element];
								} else if (itemType === 3 && subheadingObject[guid]) {
									//For item type 3 (agenda item)
									element.children = objectForAgendaItems[element.guid].children;
									element.attributes.relationshipGuid = sameSubheadingParentPresent.guid;
									let childrenOverall = objectForAgendaItems[element.guid]?.children || [];
									if (isCopy) {
										generateNewGuidForItems(element, childrenOverall);
									}
									tempSubheadingObj["data"] = [element, ...childrenOverall];
									tempSubheadingObj["index"] =
										getIndexByOrderOrLastEntry(childrenAgendaItemsOfSubheading, mainOrderAsPerType, recommendationOfSubheading) ||
										sameSubheadingParentPresent.index + 1;
								}
								if (isObjectNotEmpty(tempSubheadingObj)) {
									pushDataIntoArray(itemsToInsert, tempSubheadingObj, true, isMemberOnlySection);
								}
							});
						} else {
							let childrenEnabled = false;
							const mainsubHeadingOrder = objectForAgendaItems[guid].mainOrderAsPerType;
							tempObj["index"] =
								getIndexByOrderOrLastEntry(subheading, mainsubHeadingOrder) || mainParentIndex + mainParentTotalChildren + 1;
							[childrenEnabled, tempObj] = createSubheadingDataToInsert(
								tempObj,
								objectForAgendaItems,
								isEntireSubheadingEnabled,
								subheadingObject,
								objectForAgendaItems[guid],
								childrenEnabled,
								isItemPresent.guid, // same subheading not present but main parent present
								isCopy,
								isEntireSubheadingEnabled,
							);
							childrenEnabled && pushDataIntoArray(itemsToInsert, tempObj, true, isMemberOnlySection);
						}
					}
				});
			} else if (!isItemPresent) {
				let childrenEnabled = false;
				const isEntireSectionEnabledForINP = sectionObject.enabled === true ? true : false;
				if (isEntireSectionEnabledForINP) {
					childrenEnabled = true;
				}
				let mainParentNewGeneratedGuid = null;
				if (isCopy || (!isCopy && !isEntireSectionEnabledForINP)) {
					mainParentNewGeneratedGuid = uuid();
					itemDetails["newguid"] = mainParentNewGeneratedGuid;
				}

				if (!isCopy && !isEntireSectionEnabledForINP) {
					itemDetails["attachmentDuplicationRequired"] = true;
				}
				obj["data"] = [{ ...itemDetails, ...(!isEntireSectionEnabledForINP && { itemDeleteDisable: true }) }];
				let insertPosition = getValueFromMap(order, "order", overallMapForRightAgendaItems);
				obj["index"] = insertPosition ? insertPosition?.index + insertPosition?.totalChildren + 1 : 0;
				children?.forEach((element) => {
					if (element.itemType === 7 && isEntireSectionEnabledForINP) {
						if (mainParentNewGeneratedGuid) {
							element.attributes["newrelationshipGuid"] = mainParentNewGeneratedGuid;
							element["newguid"] = uuid();
						}
						obj["data"] = [...obj["data"], element];
					}
					if (element.itemType === 3 && sectionObject[element.guid] === true) {
						childrenEnabled = true;
						let children = objectForAgendaItems[element.guid]?.children || [];
						if (isCopy) {
							element.attributes["newrelationshipGuid"] = mainParentNewGeneratedGuid;
							generateNewGuidForItems(element, children);
						} else if (!isCopy && !isEntireSectionEnabledForINP) {
							element.attributes["newrelationshipGuid"] = mainParentNewGeneratedGuid;
						}
						obj["data"] = [...obj["data"], element, ...children];
					} else if (element.itemType === 10 && typeof sectionObject[element.guid] === "object") {
						let isEntireSubheadingEnabled = sectionObject[element.guid].enabled === true ? true : false;
						[childrenEnabled, obj] = createSubheadingDataToInsert(
							obj,
							objectForAgendaItems,
							isEntireSubheadingEnabled,
							sectionObject[element.guid],
							objectForAgendaItems[element.guid],
							childrenEnabled,
							mainParentNewGeneratedGuid ? mainParentNewGeneratedGuid : undefined,
							isCopy,
							isEntireSectionEnabledForINP,
						);
					}
				});
				if (childrenEnabled) {
					//If any children enabled then only push it to itemsToInsert
					if (!isEntireSectionEnabledForINP) {
						obj["data"] = obj["data"].map((item) => ({
							...item,
							checkForSibling: true,
						}));
					}
					pushDataIntoArray(itemsToInsert, obj);
				}
			}
		}
	}
	sortItemsByIndex(itemsToInsert);
	return itemsToInsert;
};

export const createRightAgendaMap = (selectedMeetingAgendaItems, sameParentGuids) => {
	let itemType3Order = 1;
	let itemType10Order = 1;
	let agendaItemSubheadingOrder = 1;
	let itemType10SubheadingOrder = 1;
	let itemType7RecommendationOrder = 1;
	let recommendationOfSubheadingOrder = 1;
	let isItemsDetailsNeeded = false;
	let mainParentMap = null;
	let mainParentGuid = null;
	let mainKey = null;
	let subheadingParentGuid = null;
	let agendaItemMainGuid = null;
	let subheadingAgendaItemMainGuid = null;
	let totalChildren = 0;
	let agendaItemChildren = 0;
	let subheadingChildren = 0;
	let subheadingAgendaChildren = 0;
	let map = new Map();
	const setMapForParent = () => {
		if (mainParentGuid !== null) {
			mainParentMap.totalChildren = totalChildren;
			map.set(mainKey, mainParentMap);
		}
	};
	selectedMeetingAgendaItems?.items?.forEach((element, index) => {
		const {
			itemType,
			guid,
			attributes: { relationshipGuid },
			fields: { Closed: { Value = false } = {} } = {},
		} = element;
		if (!relationshipGuid) {
			setMapForParent(); // Save the current parent before resetting
			isItemsDetailsNeeded = sameParentGuids.includes(guid);
			mainParentGuid = guid;
			mainKey = { guid, order: itemType10Order };
			mainParentMap = { index, childrenAgendaItems: {}, subheading: {}, recommendations: {}, isMemberOnlySection: Value };
			itemType10Order++;
			itemType3Order =
				agendaItemSubheadingOrder =
				itemType10SubheadingOrder =
				itemType7RecommendationOrder =
				recommendationOfSubheadingOrder =
					1;
			totalChildren = agendaItemChildren = subheadingChildren = subheadingAgendaChildren = 0;
		} else {
			totalChildren++;
			if (isItemsDetailsNeeded) {
				// Direct Children of Main Parent
				if (mainParentGuid === relationshipGuid) {
					//Agenda Items(Direct Children of Parent)
					if (itemType === 3) {
						agendaItemMainGuid = guid;
						agendaItemChildren = 0;
						mainParentMap.childrenAgendaItems[itemType3Order] = { index };
						itemType3Order++;
					}
					//Subheadings(Direct Children of Parent)
					else if (itemType === 10) {
						subheadingParentGuid = guid;
						subheadingChildren = 0;
						agendaItemSubheadingOrder = 1;
						mainParentMap.subheading[itemType10SubheadingOrder] = { index, guid };
						itemType10SubheadingOrder++;
						recommendationOfSubheadingOrder = 1;
					}
					// Recommendations(Direct Children of Parent)
					else {
						mainParentMap.recommendations[itemType7RecommendationOrder] = { index };
						itemType7RecommendationOrder++;
					}
				}
				// Children of agendaItems of Main Parent
				else if (agendaItemMainGuid === relationshipGuid) {
					mainParentMap.childrenAgendaItems[itemType3Order - 1]["totalChildren"] = ++agendaItemChildren;
				}
				// Children of subheadings of Main Parent
				else if (relationshipGuid === subheadingParentGuid) {
					mainParentMap.subheading[itemType10SubheadingOrder - 1]["totalChildren"] = ++subheadingChildren;
					const subheadingItem = mainParentMap.subheading[itemType10SubheadingOrder - 1];
					if (itemType === 3) {
						// Agenda Items(Direct Children of Subheading)
						subheadingItem.childrenAgendaItems = subheadingItem.childrenAgendaItems || {};
						subheadingItem.childrenAgendaItems[agendaItemSubheadingOrder] = { index };
						subheadingAgendaItemMainGuid = guid;
						subheadingAgendaChildren = 0;
						agendaItemSubheadingOrder++;
					}
					if (itemType === 7) {
						subheadingItem["recommendation"] = { ...subheadingItem["recommendation"], [recommendationOfSubheadingOrder]: { index } };
						recommendationOfSubheadingOrder++;
					}
				}
				// Children of agendaItems of Subheadings of Main Parent
				else if (subheadingAgendaItemMainGuid === relationshipGuid) {
					let subheadingItem = mainParentMap.subheading[itemType10SubheadingOrder - 1];
					subheadingItem.childrenAgendaItems[agendaItemSubheadingOrder - 1]["totalChildren"] = ++subheadingAgendaChildren;
					subheadingItem["totalChildren"] = ++subheadingChildren;
				}
			}
		}
	});
	setMapForParent(); // Save the last parent item
	return map;
};

export const insertItemsIntoRightAgenda = (itemsToInsert, rightAgendaItems) => {
	// Insert objects at calculated indices
	itemsToInsert.forEach(({ data, index }) => {
		rightAgendaItems.splice(index, 0, ...data); // Insert all items at once using spread operator
	});
	return rightAgendaItems;
};

export const childModifier = (guid, newVal, overallSelectedMeetingItems, dispatch, setOverallSelectedMeetingItems) => {
	const parentguid = overallSelectedMeetingItems[guid];
	overallSelectedMeetingItems[parentguid][guid] = newVal;
	//check if all children are enabled
	const allChildrenSelected = Object.keys(overallSelectedMeetingItems[parentguid])
		.filter((element) => element !== "enabled")
		.every((element) => {
			if (typeof overallSelectedMeetingItems[parentguid][element] === "object") {
				return overallSelectedMeetingItems[parentguid][element].enabled;
			}
			return overallSelectedMeetingItems[parentguid][element];
		});
	//if all children are enabled then parent should be enabled
	overallSelectedMeetingItems[parentguid]["enabled"] = allChildrenSelected;
	dispatch(setOverallSelectedMeetingItems(overallSelectedMeetingItems));
};

export const parentModifier = (guid, newVal, overallSelectedMeetingItems, dispatch, setOverallSelectedMeetingItems) => {
	//if parent is enabled then enable all children
	//if parent is disabled disable all children
	overallSelectedMeetingItems[guid].enabled = newVal;
	Object.keys(overallSelectedMeetingItems[guid])
		.filter((element) => element !== "enabled")
		.forEach((element) => {
			if (typeof overallSelectedMeetingItems[guid][element] === "object") {
				overallSelectedMeetingItems[guid][element].enabled = newVal;
				Object.keys(overallSelectedMeetingItems[guid][element])
					.filter((element) => element !== "enabled")
					.forEach((subheadingChildren) => {
						overallSelectedMeetingItems[guid][element][subheadingChildren] = newVal;
					});
			} else {
				overallSelectedMeetingItems[guid][element] = newVal;
			}
		});
	dispatch(setOverallSelectedMeetingItems(overallSelectedMeetingItems));
};

export const subheadingModifier = (guid, newVal, itemType, overallSelectedMeetingItems, dispatch, setOverallSelectedMeetingItems) => {
	const parentguid = overallSelectedMeetingItems[guid];
	let mainSectionGuid = null;
	//itemType = 3, then enable that and enable subheading // subheading enabled then enable it's parent
	if (itemType === 3) {
		//This means it's a children of subheading under a section
		const nestedObj = parentguid.reduce((acc, key) => acc[key], overallSelectedMeetingItems);
		nestedObj[guid] = newVal;
		//Check if all children are enabled
		const allChildrenSelected = Object.keys(nestedObj)
			.filter((element) => element !== "enabled")
			.every((element) => nestedObj[element] === true);
		nestedObj["enabled"] = allChildrenSelected;
		//If subheading is enabled then need to see if parent can also be enabled.
		mainSectionGuid = parentguid[0];
	}
	//itemtype = 10, then enable all children of subheading and enable parent if all children selected
	if (itemType === 10) {
		mainSectionGuid = overallSelectedMeetingItems[guid];
		let subheadingParentObj = overallSelectedMeetingItems[mainSectionGuid][guid];
		subheadingParentObj.enabled = newVal;
		Object.keys(subheadingParentObj)
			.filter((element) => element !== "enabled")
			.forEach((element) => {
				subheadingParentObj[element] = newVal;
			});
	}
	//If all children are enabled then parent should be enabled or vice versa
	const allChildrenSelected = Object.keys(overallSelectedMeetingItems[mainSectionGuid])
		.filter((element) => element !== "enabled")
		.every((element) => {
			if (typeof overallSelectedMeetingItems[mainSectionGuid][element] === "object") {
				return overallSelectedMeetingItems[mainSectionGuid][element].enabled;
			}
			return overallSelectedMeetingItems[mainSectionGuid][element];
		});
	overallSelectedMeetingItems[mainSectionGuid]["enabled"] = allChildrenSelected;
	dispatch(setOverallSelectedMeetingItems(overallSelectedMeetingItems));
};

export const markItemsDeletedFromLeftAgenda = (items, overallSelectedMeetingItems, objectForAgendaItems) => {
	let i = 0;
	while (i < items.length) {
		const item = items[i];
		const { guid, attributes: { relationshipGuid = null } = {}, itemType } = item;
		if (itemType === 10 && !relationshipGuid && overallSelectedMeetingItems[guid]) {
			const parentStructure = overallSelectedMeetingItems[guid];
			if (parentStructure.enabled) {
				// Mark parent and all its children as deleted: true
				let childrenLength = objectForAgendaItems[item.guid].allChildren.length;
				i = markParentAndChildrenDeleted(items, i, childrenLength);
			} else {
				// Process each child recursively
				i = processChildren(items, i + 1, parentStructure, objectForAgendaItems);
			}
		} else {
			i++;
		}
	}
	return items;
};

const markParentAndChildrenDeleted = (items, startIndex, childrenLength) => {
	// Mark the parent as deleted
	items[startIndex].removed = true;
	// Mark all its children as deleted
	for (let i = startIndex; i <= startIndex + childrenLength; i++) {
		if (i < items.length) {
			items[i].removed = true;
		}
	}
	// Return the index of the next item to process
	return startIndex + childrenLength + 1;
};

const processChildren = (items, startIndex, parentStructure, objectForAgendaItems) => {
	let i = startIndex;
	// Update the condition to check for itemType or isParent
	while (i < items.length && !(items[i].itemType === 10 && !items[i].attributes.relationshipGuid)) {
		const childName = items[i].guid;

		if (typeof parentStructure[childName] === "object") {
			// Recursively process child with its own structure
			if (parentStructure[childName].enabled) {
				// Mark the current item as deleted
				// Check if this child has its own children and mark them as deleted
				const childrenLength = objectForAgendaItems[childName].allChildren.length;
				i = markParentAndChildrenDeleted(items, i, childrenLength);
			} else {
				i = processChildren(items, i + 1, parentStructure[childName], objectForAgendaItems);
				continue;
			}
		} else if (parentStructure[childName]) {
			// If the child is enabled and has direct children, mark them as deleted
			const childrenLength = objectForAgendaItems[childName].children.length;
			i = markParentAndChildrenDeleted(items, i, childrenLength);
		}
		i++;
	}
	return i;
};

export const recordOriginalItemOrder = (items) => {
	items
		.filter((item) => !item.original)
		.forEach((item) => {
			item.original = {
				guid: item.guid,
				order: item.fields.Order.Value,
				number: item.number || item.fields.Number.Value,
				closed: item.fields.Closed.Value,
			};
		});
};

const generateNewGuidForItems = (element, childrenOverall) => {
	let directParentGuid = uuid();
	element["newguid"] = directParentGuid;
	childrenOverall?.forEach((child) => {
		child["newguid"] = uuid(); // Directly modify the child object
		child.attributes["newrelationshipGuid"] = directParentGuid; // Modify the nested attributes object
	});
};

export const assignNewGuidToCopiedItems = (tempArray, isCopy) => {
	tempArray?.forEach((element) => {
		if (element.newguid) {
			element.guid = element.newguid;
			delete element.newguid;
		}
		if (element.attributes.relationshipGuid && element.attributes.newrelationshipGuid) {
			element.attributes.relationshipGuid = element.attributes.newrelationshipGuid;
			delete element.attributes.newrelationshipGuid; // Remove newrelationshipGuid after assigning
		}
		if (!isCopy && element.attachmentDuplicationRequired && element.attachments.length > 0) {
			element = prepareAttachmentDuplication(element);
		} else if (isCopy && element.attachments.length > 0) {
			element = prepareAttachmentDuplication(element);
		}
	});
};

export const setWarningForMemberOnlyItems = (tempArray, isCopy, dispatch, setWarningMessage) => {
	//Member Only Items will be will be moved to Public Section:
	let warningArray = [];
	tempArray.forEach((element) => {
		if (element.copied && element.isMemberOnlyWarning) {
			warningArray.push(`${element.number ? `${element.number},` : ""}${stripHtml(element.fields?.Name?.Value || "")}`);
		}
	});
	if (warningArray.length > 0) {
		let result = `The below Member Only Items will be ${isCopy ? "copied" : "moved"} to a Public section!<br><ul style="padding-inline-start: 17px; margin-block-end:0em;">`;

		warningArray.forEach((title) => {
			result += `<li>${title}</li>`;
		});

		result += `</ul><br>`;

		warningArray = [result];
	}
	dispatch(setWarningMessage({ id: "memberOnlyWarning", message: warningArray }));
};

export const createItemArrayForOverflowMenu = (mainArray, selectedItem, selectedIndex, isCalledFromOverflow = true) => {
	const isMainParent = (item) => item.itemType === 10 && !item.attributes?.relationshipGuid;
	// Start searching backward from selectedIndex if the selectedItem is not a main parent
	let startIndex = selectedIndex;
	if (!isMainParent(selectedItem)) {
		for (let i = selectedIndex; i >= 0; i--) {
			if (isMainParent(mainArray[i])) {
				startIndex = i;
				break;
			}
		}
	}
	// Find the next main parent after the startIndex
	let endIndex = mainArray.length; // Default to the end of the array if no next parent is found
	for (let i = startIndex + 1; i < mainArray.length; i++) {
		if (isMainParent(mainArray[i])) {
			endIndex = i;
			break;
		}
	}
	// Slice the mainArray between startIndex and endIndex
	const subArray = mainArray.slice(startIndex, endIndex);
	// Check if selectedItem.guid matches any item.guid in subArray and add the property
	if (isCalledFromOverflow) {
		const selectedSubItem = subArray.find((item) => item.guid === selectedItem.guid);
		if (selectedSubItem) {
			selectedSubItem.isItemSelectedFromOverflow = true;
		}
	}
	return isCalledFromOverflow ? subArray : [subArray, startIndex, endIndex];
};

export const extractBriefingNotesFromItem = (boardNotes, attachments, item) => {
	const parser = new DOMParser();
	const doc = parser.parseFromString(boardNotes, "text/html");
	// Select all <a> elements with the class 'inlineFile'
	const links = doc.querySelectorAll("a.inlineFile");
	// Initialize an array to store the extracted IDs
	const ids = [];
	// Loop through the selected <a> elements
	links.forEach((link) => {
		// Get the href attribute
		const href = link.getAttribute("href");
		// Extract ID from href using regex
		const match = href.match(/\/document\/([a-zA-Z0-9-]+)/);
		if (match) {
			ids.push(match[1]);
		}
	});
	if (ids.length > 0) {
		item.containsBriefingNotes = true;
		attachments.forEach((attachment) => {
			if (ids.includes(attachment.guid)) {
				attachment.removed = true;
			}
		});
	}
};

export const assignBriefingNotesIcon = (response) => {
	response.items.forEach((item) => {
		if (item.fields.BoardNotes.Value) {
			extractBriefingNotesFromItem(item.fields.BoardNotes.Value, item.attachments, item);
		}
	});
};
