function descendingComparator(a, b, orderBy) {
	// Make the sort case insensitive
	const sortA = typeof a[orderBy] === 'string' ? a[orderBy].toUpperCase() : a[orderBy];
	const sortB = typeof b[orderBy] === 'string' ? b[orderBy].toUpperCase() : b[orderBy];

	if (sortB < sortA) {
		return -1;
	}
	if (sortB > sortA) {
		return 1;
	}
	return 0;
}

export function getComparator(order, orderBy) {
	return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

export function stableSort(array, comparator) {
	const stabilizedThis = array.map((el, index) => [el, index]);
	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0], b[0]);
		if (order !== 0) return order;
		return a[1] - b[1];
	});
	return stabilizedThis.map((el) => el[0]);
}
