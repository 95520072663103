export const CONTENT_SCROLL_ID = "content-scroll";
export const CONTENT_PAPER_ID = "content-paper";

export const getContentSize = () => {
	const contentScrollRect = document.getElementById(CONTENT_SCROLL_ID)?.getBoundingClientRect();
	const paperRect = document.getElementById(CONTENT_PAPER_ID)?.getBoundingClientRect();
	const bodyRect = document.body.getBoundingClientRect();

	return {
		top: contentScrollRect?.top || 0,
		height: contentScrollRect?.height || bodyRect.height,
		left: paperRect?.left || contentScrollRect?.left || 0,
		right: (contentScrollRect?.right || bodyRect.right) - (paperRect?.right || contentScrollRect?.right || bodyRect.right),
		bodyWidth: bodyRect.width,
	};
};
