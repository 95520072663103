import React from "react";
import { useSelector } from "react-redux";
import CircularProgressIndicator from "../atlas/components/Progress/CircularProgressIndicator";
import { Navigate } from "react-router-dom";

const PolicyOnlyRoute = () => {
	const { policyData } = useSelector((state) => state.policyGovernanceReducer);

	return policyData && policyData.length > 0 ? (
		<Navigate to={`/policy/policybook/${policyData?.[0]?.guid}`} />
	) : (
		<CircularProgressIndicator />
	);
};

export default PolicyOnlyRoute;
