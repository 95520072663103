import React, { useState, useContext, useEffect } from "react";
import clsx from "clsx";
import makeStyles from "@mui/styles/makeStyles";
import { useTranslation } from "react-i18next";
import { UserContext } from "contexts/User/UserContext";
import { useDispatch, useSelector } from "react-redux";
import { whiteColor } from "atlas/assets/jss/shared";
import RightPanelContainer from "components/Panels/RightPanelContainer";
import { SettingsContext } from "contexts/Settings/SettingsContext";
import DocumentDetails from "./components/DocumentDetails";
import WopiFrame from "../Wopi/WopiFrame";
import GenericEditor from "components/Editor/GenericEditor";
import { FOLDER_TYPE_AGENDA_ITEM_COVER_TEMPLATE } from "utils/enums/DocumentFolderTypes";
import telemetryAddEvent from "utils/telemetryAddEvent";
import PdfViewerNew from "../../components/PdfViewerNew/PdfViewerNew";
import { reloadViewPageOfPolicyDocument } from "redux/policyGovernance/actions";

const DETAILS_COLLAPSED_WIDTH = 48;
const DETAILS_EXPANDED_WIDTH = 256;

const editors = [];

const useStyles = makeStyles(() => ({
	document: {
		height: "calc(100vh - 72px)",
	},
	documentDetailsCollapsed: {
		width: `calc(100% - ${DETAILS_COLLAPSED_WIDTH}px) !important`,
	},
	documentDetailsExpanded: {
		width: `calc(100% - ${DETAILS_EXPANDED_WIDTH}px) !important`,
	},
	unavailable: {
		padding: "24px",
		textAlign: "center",
	},
	frame: {
		display: "block",
		border: "none",
		height: "100%",
		width: "100%",
		backgroundColor: whiteColor,
	},
	pdfFrame: {
		height: "100%",
		width: "100%",
		"& iframe": {
			border: "none",
		},
	},
	editorFrame: {
		"& .ck-content": {
			height: "calc(100vh - 150px)",
			overflow: "auto",
		},
	},
	hidden: {
		visibility: "hidden", // Workaround for PDFTron in firefox
		height: "0 !important",
		width: "0 !important",
	},
	dialog: {
		"& .MuiDialog-paper": {
			width: "440px",
		},
	},
	progressBar: {
		width: "240px",
		margin: "0 auto",
		marginTop: "24px",
	},
	agendaItemDocumentFrame: {
		height: "calc(100vh - 214px)",
	},
}));

const Document = (props) => {
	const {
		id,
		document,
		viewByPublic,
		history,
		accessLogs,
		handleRename,
		handleHtmlEdit,
		handleDownload,
		handleReplace,
		handleCopyMove,
		loadMoreHistory,
		toggleFieldChanges,
		handleView,
		handleRestore,
		loadMoreAccessLogs,
		handleExport,
		editing,
		agendaItem,
		telemetryPage,
	} = props;
	const [detailsExpanded, setDetailsExpanded] = useState(false);
	const { lite, wopiSettings, testSite } = useContext(SettingsContext);
	const policyGovernanceReducer = useSelector((state) => state.policyGovernanceReducer);
	const { reloadPageAfterWOPIEdit } = policyGovernanceReducer;
	const dispatch = useDispatch();
	const classes = useStyles();
	const { t } = useTranslation("documents");
	const {
		documentLibrary: { viewRecycleBin },
	} = useContext(UserContext).user;
	const setEditorsData = (name, value) => {
		editors[name] = value;
	};
	useEffect(() => {
		if (reloadPageAfterWOPIEdit) {
			dispatch(reloadViewPageOfPolicyDocument(false));
			window.location.reload();
		}
	}, []);

	const getUrl = () => {
		return document.dateDeleted && viewRecycleBin
			? `/document/${document.guid}?deleted=true`
			: `/document/${document.guid}?modified=${document.dateModified}`;
	};
	const customizationOptions = {
		fullScreenCustom: {
			customWrapper: null,
		},
		downloadCustom: {
			onClickUrl: getUrl(),
		},
	};

	const handleExpandCollapse = () => setDetailsExpanded((prev) => !prev);

	const getFileContainer = () => {
		if (document.dateDeleted && !viewRecycleBin) {
			// This document has been deleted
			return <div className={classes.unavailable}>{t("detail.labels.documentDeleted")}</div>;
		}

		let fileContainer = (
			<iframe
				key={document.dateModified}
				className={clsx(classes.frame, {
					[classes.documentDetailsCollapsed]: !detailsExpanded && document.canUpdate,
					[classes.documentDetailsExpanded]: detailsExpanded && document.canUpdate,
				})}
				src={
					document.dateDeleted && viewRecycleBin
						? `/document/${document.guid}?deleted=true`
						: `/document/${document.guid}?modified=${document.dateModified}`
				}
				title={document.title}
			/>
		);

		switch (document.extension) {
			case ".doc":
			case ".docx":
			case ".wopitest":
			case ".wopitestx":
			case ".ppt":
			case ".pptx":
				if (document.canUpdate && editing && !lite.enabled) {
					telemetryAddEvent(
						document.parentFolderType === FOLDER_TYPE_AGENDA_ITEM_COVER_TEMPLATE
							? `${telemetryPage} - Agenda Item Cover Template Edit`
							: `${telemetryPage} - Document Library Edit Office`,
					);

					fileContainer = (
						<div
							className={clsx(classes.frame, {
								[classes.documentDetailsCollapsed]: !detailsExpanded && document.canUpdate,
								[classes.documentDetailsExpanded]: detailsExpanded && document.canUpdate,
							})}
						>
							<WopiFrame id={document.wopiDocumentId} wopiSettings={wopiSettings} extension={document.extension} testSite={testSite} />
						</div>
					);
				}
				break;

			case ".pdf":
				if (!viewByPublic) {
					fileContainer = (
						<div
							className={clsx(classes.pdfFrame, {
								[classes.documentDetailsCollapsed]: !detailsExpanded && document.canUpdate,
								[classes.documentDetailsExpanded]: detailsExpanded && document.canUpdate,
							})}
						>
							<PdfViewerNew
								id={document.id}
								settings={{
									lite,
									url: getUrl(),
									downloadOpensNewTab: true,
									showPrintButton: false,
									closeLeftPanel: true,
									disableAnnotations: true,
								}}
								selectedDocumentId={document.id}
								customizationOptions={customizationOptions}
								isMeetingDocument
							/>
						</div>
					);
				}
				break;

			case ".htm":
			case ".html":
				if (document.canUpdate && editing && testSite) {
					fileContainer = (
						<div
							className={clsx(classes.frame, classes.editorFrame, {
								[classes.documentDetailsCollapsed]: !detailsExpanded && document.canUpdate,
								[classes.documentDetailsExpanded]: detailsExpanded && document.canUpdate,
							})}
						>
							<GenericEditor
								guid={document.guid}
								toolbar="scratchpad"
								name="html"
								content={document.html || ""}
								title={document.title}
								setEditorsData={setEditorsData}
								onChange={handleHtmlEdit}
								mt={0}
								loadAsync
								preload={{ staticToolbar: true }}
							/>
						</div>
					);
				}
				break;
		}

		return fileContainer;
	};

	return (
		document && (
			<>
				<div id={id} className={clsx(classes.document, { [classes.agendaItemDocumentFrame]: agendaItem })}>
					{getFileContainer()}
				</div>
				{document.canUpdate && (
					<RightPanelContainer
						id="right-panel-container"
						open
						drawerWidth={detailsExpanded ? DETAILS_EXPANDED_WIDTH : DETAILS_COLLAPSED_WIDTH}
						drawerHeight={(contentSizes) => (contentSizes ? `calc(100% - ${contentSizes.top}px)` : undefined)}
						fullHeight
					>
						<DocumentDetails
							document={document}
							history={history}
							accessLogs={accessLogs}
							expanded={detailsExpanded}
							handleExpandCollapse={handleExpandCollapse}
							handleRename={handleRename}
							handleDownload={handleDownload}
							handleReplace={handleReplace}
							handleCopyMove={handleCopyMove}
							loadMoreHistory={loadMoreHistory}
							toggleFieldChanges={toggleFieldChanges}
							handleView={handleView}
							handleRestore={handleRestore}
							loadMoreAccessLogs={loadMoreAccessLogs}
							handleExport={handleExport}
						></DocumentDetails>
					</RightPanelContainer>
				)}
			</>
		)
	);
};

export default Document;
