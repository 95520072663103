import React, { Component } from "react";
import Parser, { domToReact } from "html-react-parser";
import { withTranslation } from "react-i18next";
import clsx from "clsx";
// import domToReact from "html-react-parser/lib/dom-to-react";

import { EditorFunctionsContext } from "contexts/EditorFunctions/EditorFunctionsContext";
import { setNodeTitle } from "components/Editor/plugins/InlineFile/inlineFileUtils";
import AgendaCkeEditorContent from "./AgendaCkeEditorContent";

import { validateLinkHref } from "../functions/utils";

class AgendaCKEditor extends Component {
	constructor(props) {
		super(props);

		this.handleKeyPress = this.handleKeyPress.bind(this);
	}

	handleKeyPress(event) {
		const { guid } = this.props;
		const { setActiveItem } = this.context;
		const { focused } = this.state;
		if (event.key === "Enter" && focused && guid) {
			setActiveItem(guid);
		}
	}

	render() {
		const {
			guid,
			isActive,
			disabled,
			content,
			item,
			isHeading,
			isSubHeading,
			isHeadingText,
			isAgendaItem,
			isItemText,
			isHeadingAction,
			isScratchpad,
			isRecommendation,
			isMemberOnlySection,
			isClosedMeeting,
			handleLoad,
			t,
		} = this.props;

		const { setActiveItem } = this.context;

		if (isActive) {
			return (
				<AgendaCkeEditorContent
					{...this.props}
					loadAsync={false}
					editorFunctionsContext={this.context}
					handleLoad={handleLoad}
					onClick={() => setActiveItem(guid)}
				/>
			);
		}

		// CKEditor is injecting 0 margins to paragraphs... sometimes. This removes it when rendering as HTML.
		const parserOptions = {
			replace: (node) => {
				if (!node.attribs) return;
				if (node.name === "a" && node.attribs.href) {
					if (node.attribs.class && node.attribs.class.indexOf("inlineFile") !== -1) {
						setNodeTitle(node, item, isMemberOnlySection, isClosedMeeting, t("inlineFile.features.MOA"));

						// Link to the new document details
						if (node.attribs.href.indexOf("/document/") === 0) {
							node.attribs.href = `/home${node.attribs.href}`;
						}
					} else {
						node.attribs.href = validateLinkHref(node.attribs.href);
					}
				} else if (["img", "br"].includes(node.name) && node.attribs.style) {
					node.attribs.style = "";
				}
				if (node.attribs.style === "margin-top: 0; margin-bottom: 0;") {
					// eslint-disable-next-line consistent-return
					return <node.name>{domToReact(node.children, parserOptions)}</node.name>;
				}
			},
		};

		let containerClassName;
		if (isSubHeading) {
			containerClassName = isHeadingText ? "subheading-text-container" : "subheading-container";
		} else if (isHeading) {
			containerClassName = isMemberOnlySection ? "member-only-heading-container" : "heading-container";
		} else if (isHeadingText) {
			containerClassName = "heading-text-container";
		} else if (isHeadingAction) {
			containerClassName = "heading-recommendation-container";
		} else if (isAgendaItem) {
			containerClassName = "agenda-item-container";
		} else if (isItemText) {
			containerClassName = "agenda-item-text-container";
		} else if (isScratchpad) {
			containerClassName = "scratchpad-container";
		} else if (isRecommendation) {
			containerClassName = "recommendation-container";
		}

		return !disabled ? (
			<div
				role="button"
				tabIndex={0}
				className={containerClassName}
				aria-label="edit"
				onFocus={() => this.setState({ focused: true })}
				onBlur={() => this.setState({ focused: false })}
				onKeyPress={(e) => this.handleKeyPress(e)}
				onClick={() => setActiveItem(guid)}
				data-cy={`agenda-item-${guid}`}
			>
				<div className="with-content noselect">{Parser(content || "<p></p>", parserOptions)}</div>
			</div>
		) : (
			<div className={clsx(containerClassName, "no-hover")} data-cy={`agenda-item-${guid}`}>
				<div className={clsx("with-content", "noselect", "no-hover", "disabled")}>{Parser(content || "<p></p>", parserOptions)}</div>
			</div>
		);
	}
}

AgendaCKEditor.contextType = EditorFunctionsContext;

export default withTranslation("meetings")(AgendaCKEditor);
