import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import request from "superagent";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import { getMeetingOutputText } from "utils/meetingOutputText";
import CircularProgressIndicator from "atlas/components/Progress/CircularProgressIndicator";
import { Close } from "components/Icons";
import { API_HOST } from "config/env";
import telemetryAddEvent from "utils/telemetryAddEvent";
import GenericDialog from "atlas/components/Dialogs/GenericDialog";

const MeetingUnpublishDialog = (props) => {
	const {
		show,
		agenda = true,
		meeting: { id, name, date },
		meeting,
		afterUnpublish,
		onClose,
		telemetryPage = "",
	} = props;
	const { t } = useTranslation("meetings");
	const [unpubishing, setUnpublishing] = useState(false);
	const isMounted = useRef();

	useEffect(() => {
		isMounted.current = true;

		return () => {
			isMounted.current = false;
		};
	}, [meeting, agenda]);

	const handleError = (err) => {
		console.log("Error", err);
		onClose();
	};

	const handleUnpublish = () => {
		telemetryAddEvent(`${telemetryPage} - Unpublish agenda`);

		const requestFinished = (meetings) => {
			const closeDialogWithCallback = () => {
				onClose();
				afterUnpublish({ id, agenda, meetings });
			};

			if (isMounted.current) {
				setUnpublishing(false);
			}
			closeDialogWithCallback();
		};

		if (!id) {
			handleError();
		}
		setUnpublishing(true);

		request
			.post(`${API_HOST}/api/meeting/${id}/unpublish`)
			.send({ agenda })
			.then((res) => requestFinished(res.body || []))
			.catch((err) => handleError(err));
	};

	const handleCancel = () => {
		onClose();
	};

	const i18n = t("unpublishMeetingDialog", { name });

	const dialog = {
		title: getMeetingOutputText(i18n.title, agenda),
		line1: getMeetingOutputText(i18n.line1, agenda),
		line2: getMeetingOutputText(i18n.line2, agenda),
		primaryTitle: getMeetingOutputText(i18n.buttons.unpublish, agenda),
		primaryAction: handleUnpublish,
		secondaryTitle: getMeetingOutputText(i18n.buttons.cancel, agenda),
		secondaryAction: handleCancel,
	};

	return (
		<GenericDialog
			show={show}
			title={dialog.title}
			primaryAction={dialog.primaryAction}
			primaryTitle={unpubishing ? <CircularProgressIndicator color="secondary" size={20} minHeight="20px" /> : dialog.primaryTitle}
			primaryDisabled={unpubishing}
			secondaryAction={dialog.secondaryAction}
			secondaryTitle={dialog.secondaryTitle}
			secondaryDisabled={unpubishing}
			clickAwayDisabled={unpubishing}
			closeIcon={<Close />}
			data-cy="unpublish-dialog"
		>
			<Typography variant="h4">
				<Box fontWeight="fontWeightBold">{dialog.line1}</Box>
			</Typography>
			<Box mt={2}>
				<Typography variant="h5">{dialog.line2}</Typography>
			</Box>
		</GenericDialog>
	);
};

export default MeetingUnpublishDialog;
