import React, { useState, useContext, useEffect, useRef, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import request from "superagent";

import { Box, Toolbar, Grid } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import styles from "atlas/assets/jss/components/topNavBarStyle";
import { whiteColor, defaultFont } from "atlas/assets/jss/shared";
import AccessibleIconButton from "atlas/components/Buttons/AccessibleIconButton";
import ButtonWithTooltip from "atlas/components/Buttons/ButtonWithTooltip";
import NonModalMenu from "atlas/components/Menu/NonModalMenu";
import Icon from "atlas/components/Icon/Icon";
import Tooltip from "atlas/components/Tooltip/Tooltip";
import { MEDIUM } from "atlas/utils/buttonSize";
import { useWidthDown, useWidthUp } from "atlas/utils/useWidth";
import { API_HOST } from "config/env";
import { SettingsContext } from "contexts/Settings/SettingsContext";
import { UserContext } from "contexts/User/UserContext";
import SendFeedbackDialog from "components/Dialogs/SendFeedbackDialog";
import SupportRequestDialog from "components/Dialogs/SupportRequestDialog";
import ShortcutKeysDialog from "components/Dialogs/ShortcutKeysDialog";
import SearchInput from "components/Search/SearchInput";
import { pollLiveData } from "redux/app/actions";
import telemetryAddEvent from "utils/telemetryAddEvent";
import AdminAppbarTimer from "views/LiveMeeting/components/AdminAppbarTimer";
import { useHotkeys } from "react-hotkeys-hook";

const useStyles = makeStyles(styles);
const useLocalStyles = makeStyles((theme) => ({
	whatsNewButton: {
		border: "none",
		backgroundColor: "transparent",
		color: whiteColor,
		cursor: "pointer",
		...defaultFont,
		fontSize: "16px",
		padding: "12px",
	},
}));
const maxOrganizationNameLengthBeforeTooltip = 33;

const defaultSearch = { show: false, text: "" };

const TopNavBar = (props) => {
	const { open, setOpen, resetPageConfigs } = props;
	const widthUpMd = useWidthUp("md");
	const widthUpLg = useWidthUp("lg");
	const widthUpXl = useWidthUp("xl");
	const widthDownSm = useWidthDown("sm");
	const widthDownXl = useWidthDown("xl");
	const { t } = useTranslation();
	const navigate = useNavigate();
	const classes = useStyles();
	const localClasses = useLocalStyles();
	const dispatch = useDispatch();
	const location = useLocation();
	const [search, setSearch] = useState({ ...defaultSearch });
	const [openHelpMenu, setOpenHelpMenu] = useState(false);
	const [helpAnchor, setHelpAnchor] = useState(null);
	const [showSupportRequestDialog, setShowSupportRequestDialog] = useState(false);
	const [showShortcutKeyDialog, setShortcutKeyDialog] = useState(false);
	const [showSendFeebackDialog, setShowSendFeebackDialog] = useState(false);
	const [openProfileMenu, setOpenProfileMenu] = useState(false);
	const [profileAnchor, setProfileAnchor] = useState(null);

	const appReducer = useSelector((state) => state.appReducer);
	const { appbarTools: { timer = {}, toggleVoteDialog, keyWords, updated } = {}, backStack, telemetryPage, meetingName } = appReducer;
	const { organizationName, lite } = useContext(SettingsContext);
	const { boardAdmin, publicUser } = useContext(UserContext).user;
	const showOrganization = widthUpMd || !timer.timer || toggleVoteDialog;
	const showLogo = widthUpXl || (!timer.timer && !toggleVoteDialog && widthUpLg);
	const hasCenterContent = widthUpLg || timer.timer || toggleVoteDialog;
	const hotKeyProps = { enableOnContentEditable: true, enableOnFormTags: ["input", "select", "textarea"] };
	const keyboardShortCut = {
		keys: t("shortCutKeys.keyboardShortCutkey"),
		props: hotKeyProps,
	};

	const openPage = (url) => {
		navigate(url);
	};

	const openNewTab = (url) => {
		window.open(url);

		if (open) {
			setOpen(!open);
		}
	};

	const hideBackForMemberAgendaScreen = () => {
		if (location && location.pathname && location.pathname.includes("/meeting/document")) {
			return false;
		}
		return true;
	};

	const handlePrevious = () => {
		if (backStack.length > 0) {
			const back = backStack[backStack.length - 1];

			let removeFromStack = true;
			let result = true;
			if (typeof back.action === "function") {
				result = back.action();
				//Check if the action returns a value other than undefined
				if (result !== undefined) {
					// Don't remove this history entry from the stack if the function returns false
					if (typeof result === "boolean" && !result) {
						removeFromStack = false;
					} else {
						result = true;
					}
				} else {
					removeFromStack = false;
				}
			}
			if (result && back.url) {
				// Only navigate to this URL if the action did not return false
				openPage(back.url);
			}
			//Navigate back only if result is not undefined and it is false
			if (result !== undefined) {
				if (!removeFromStack && !result) {
					navigate(-1);
				}
			}
			if (removeFromStack && typeof resetPageConfigs === "function") {
				dispatch(
					resetPageConfigs({
						updateBackStackOnly: !back.url, // If the url is not changing, then don't reset anything except the back stack
						backStack: backStack.slice(0, backStack.length - 1),
					}),
				); // Remove this page from the stack
			}
		}
	};

	const handleSearchChange = useCallback(
		(e) => {
			if (e.key === "Escape") {
				setSearch({ ...defaultSearch });
			} else if (e.key === "Enter") {
				openPage(`/search?keywords=${encodeURIComponent(search.text)}`);
			} else {
				const {
					target: { value = "" },
				} = e;

				setSearch((prev) => ({ ...prev, text: value }));
			}
		},
		[search],
	);

	const handleSearchClear = (e) => {
		setSearch((prev) => ({ ...prev, text: "" }));

		// Focus on the search element so that it dissappears on blur if no further typing is done
		const searchInput = document.querySelectorAll('[data-cy="header-search-input"] input');
		if (searchInput?.length > 0) {
			searchInput[0].focus();
		}
	};

	const handleSearchBlur = (e) => {
		if (!e.target.value) {
			setSearch({ ...defaultSearch });
		}
	};

	const closeMenus = () => {
		setSearch((prev) => ({ ...prev, show: false }));
		setHelpAnchor(null);
		setOpenHelpMenu(false);
		setProfileAnchor(null);
		setOpenProfileMenu(false);
	};

	const handleToggleSearch = () => {
		setSearch((prev) => ({ ...prev, show: true }));
	};

	const handleToggleHelpMenu = (e) => {
		closeMenus();
		setHelpAnchor(e.currentTarget);
		setOpenHelpMenu((prevOpen) => !prevOpen);
	};

	const handleCloseHelpMenu = () => {
		closeMenus();
		setOpenHelpMenu(false);
	};

	const openSupportRequestDialog = () => {
		telemetryAddEvent(`${telemetryPage} - Open support`);

		setShowSupportRequestDialog(true);
		closeMenus();
	};

	const closeSupportRequestDialog = () => setShowSupportRequestDialog(false);

	const openSendFeedbackDialog = () => {
		telemetryAddEvent(`${telemetryPage} - Open feedback`);

		setShowSendFeebackDialog(true);
		closeMenus();
	};

	const closeSendFeedbackDialog = () => setShowSendFeebackDialog(false);

	const openShortcutkeyDialog = () => {
		telemetryAddEvent(`${telemetryPage} - Open shortcut keys`);

		setShortcutKeyDialog(true);
		closeMenus();
	};
	const closeShortcutkeyDialog = () => setShortcutKeyDialog(false);

	useHotkeys(
		keyboardShortCut.keys,
		() => {
			if (!showShortcutKeyDialog) {
				openShortcutkeyDialog();
			} else if (showShortcutKeyDialog) {
				closeShortcutkeyDialog();
			}
		},
		keyboardShortCut.props,
	);
	const handleToggleProfileMenu = (e) => {
		closeMenus();
		setProfileAnchor(e.currentTarget);
		setOpenProfileMenu((prevOpen) => !prevOpen);
	};

	const handleCloseProfileMenu = () => {
		closeMenus();
		setOpenProfileMenu(false);
	};

	const signOut = (url) => {
		request
			.get(`${API_HOST}/api/user/signout`)
			.then((res) => {
				if (res.body.isSignedOut) {
					dispatch(pollLiveData({ cancel: true }));
					openPage(url);
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	useEffect(() => {
		setSearch({
			show: !!keyWords,
			text: keyWords || "",
		});
	}, [keyWords, updated]);

	const helpMenuOptions = [
		{
			label: t("menu.help"),
			actionFunction: () => {
				telemetryAddEvent(`${telemetryPage} - Help menu`);

				closeMenus();

				return openNewTab(`/help/${lite.enabled ? "Essentials" : ""}${boardAdmin ? "Admin" : "Member"}Help.pdf`);
			},
			external: true,
			dataCy: "helpMenuOption",
		},
		{
			label: t("menu.support"),
			actionFunction: openSupportRequestDialog,
			dataCy: "contactSupportMenuOption",
		},
		{
			label: t("menu.feedback"),
			actionFunction: openSendFeedbackDialog,
			dataCy: "sendFeedbackMenuOption",
		},
		{
			label: "Shortcut Keys",
			actionFunction: openShortcutkeyDialog,
			dataCy: "shortcutKeysMenuOption",
		},
	];

	const profileMenuOptions = () => [
		{
			label: t("menu.profile"),
			actionFunction: () => {
				closeMenus();
				openPage("/user/profile");
			},
			dataCy: "profileMenuOption",
		},
		{
			label: t("menu.signOut"),
			actionFunction: () => signOut("/sign-in"),
			dataCy: "signOutMenuOption",
		},
	];

	const publicProfileMenuOptions = [
		{
			label: t("menu.signIn"),
			actionFunction: () => {
				openPage("/sign-in");
			},
			dataCy: "signInMenuOption",
		},
	];

	const getOrganizationName = () => (
		<span id="customer-name" className={`${classes.organization} ${widthDownSm || backStack.length > 0 ? classes.organizationClosed : ""}`}>
			{publicUser ? meetingName : organizationName}
		</span>
	);
	const getOrganizationLabel = () =>
		organizationName && organizationName.length > maxOrganizationNameLengthBeforeTooltip ? (
			<Tooltip placement="bottom-start" title={publicUser ? meetingName : organizationName} role="organization-title">
				{getOrganizationName()}
			</Tooltip>
		) : (
			getOrganizationName()
		);

	return (
		<>
			<SendFeedbackDialog show={showSendFeebackDialog} onClose={closeSendFeedbackDialog} dispatch={dispatch} />
			<SupportRequestDialog show={showSupportRequestDialog} onClose={closeSupportRequestDialog} />
			<ShortcutKeysDialog show={showShortcutKeyDialog} onClose={closeShortcutkeyDialog} />

			<Toolbar className={classes.headerToolbar}>
				<Grid container>
					<Grid item xs={hasCenterContent ? 4 : 5} className={classes.leftSide}>
						{(open || widthDownSm) && (
							<AccessibleIconButton
								id="menu-button"
								onClick={() => setOpen(!open)}
								iconName="menu"
								tooltipText={t("menu.close")}
								dataCy="menu"
								isHeader
							/>
						)}
						{backStack.length > 0 && hideBackForMemberAgendaScreen() && (
							<Box mr={2}>
								<AccessibleIconButton
									id="back"
									color="inherit"
									aria-label="back"
									onClick={() => handlePrevious()}
									iconName="expand-left"
									dataCy="backButton"
									tooltipText={t("menu.previous")}
									isHeader
								/>
							</Box>
						)}
						{showOrganization && (
							<div className={classes.organizationContainer} data-cy="pageTitle">
								{!open && getOrganizationLabel()}
							</div>
						)}
					</Grid>
					{showLogo && !publicUser && !search.show && (
						<Grid id="community-logo" item xs={4} className={classes.diligentLogo}>
							<Icon name="diligent-logo-white" size="48px" />
						</Grid>
					)}
					{timer.timer && widthDownXl && (
						<Grid item xs={4} className={classes.diligentLogo}>
							<AdminAppbarTimer timer={timer.timer} meetingId={timer.meetingId} startTime={timer.startTime} />
						</Grid>
					)}
					<Grid item xs={hasCenterContent ? (!search.show ? 4 : 8) : 7} className={classes.rightSide}>
						{!publicUser && !search.show && (
							<>
								{widthDownSm ? (
									<AccessibleIconButton
										aria-label={t("tooltips.whatsNew")}
										iconName="announcements"
										tooltipText={t("tooltips.whatsNew")}
										dataCy="whats-new"
										isHeader
									/>
								) : (
									<button className={localClasses.whatsNewButton} data-cy="whats-new">
										{t("whatsNew")}
									</button>
								)}
							</>
						)}
						{timer.timer && widthDownXl && widthUpMd && <AdminAppbarTimer timer={timer.timer} meetingId={timer.meetingId} hideTimer />}
						{toggleVoteDialog && (
							<ButtonWithTooltip
								color="secondary"
								variant="text"
								secondary
								pageHeader
								size={MEDIUM}
								title={toggleVoteDialog.tooltipText || toggleVoteDialog.text}
								onClick={toggleVoteDialog.onClick}
								style={{ margin: "4px", color: "#fff", top: "85px", backgroundColor: "#5D7599", position: "absolute", borderradius: "2px" }}
							>
								{toggleVoteDialog.text}
							</ButtonWithTooltip>
						)}
						{!publicUser && (
							<>
								{search.show ? (
									<SearchInput
										className={classes.searchInput}
										size="small"
										autoFocus
										value={search.text}
										showClearIcon={search.text.length > 0}
										onKeyDown={handleSearchChange}
										onChange={handleSearchChange}
										onClearClick={handleSearchClear}
										onBlur={handleSearchBlur}
										data-cy="header-search-input"
									/>
								) : (
									<AccessibleIconButton
										id="search"
										aria-label={t("menu.search")}
										onClick={handleToggleSearch}
										iconName="search"
										tooltipText={t("menu.search")}
										dataCy="header-search"
										isHeader
									/>
								)}

								<AccessibleIconButton
									id="help"
									aria-label={t("menu.help")}
									onClick={handleToggleHelpMenu}
									iconName="help"
									tooltipText={t("menu.help")}
									ref={helpAnchor}
									dataCy="helpIcon"
									isHeader
								/>
								{helpAnchor && (
									<NonModalMenu
										id="helpMenu"
										className={classes.menu}
										anchorEl={helpAnchor}
										keepMounted
										open={openHelpMenu}
										onClose={handleCloseHelpMenu}
										options={helpMenuOptions}
										position="bottom-end"
									/>
								)}
								<AccessibleIconButton
									id="profile"
									aria-label={t("menu.profile")}
									onClick={handleToggleProfileMenu}
									iconName="avatar"
									tooltipText={t("menu.profile")}
									ref={profileAnchor}
									dataCy="userIcon"
									isHeader
								/>
								{profileAnchor && (
									<NonModalMenu
										id="profileMenu"
										className={classes.menu}
										anchorEl={profileAnchor}
										keepMounted
										open={openProfileMenu}
										onClose={handleCloseProfileMenu}
										options={publicUser ? publicProfileMenuOptions : profileMenuOptions()}
										position="bottom-end"
									/>
								)}
							</>
						)}
					</Grid>
				</Grid>
				{timer.timer && widthUpXl && <AdminAppbarTimer timer={timer.timer} meetingId={timer.meetingId} startTime={timer.startTime} />}
			</Toolbar>
		</>
	);
};

TopNavBar.defaultProps = {
	resetPageConfigs: undefined,
};

export default TopNavBar;
