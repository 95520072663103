import React from "react";

const icon = (props) => {
	return (
		<svg height="36" width="5" {...props}>
			<line x1="3" y1="0" x2="3" y2="36" stroke="rgba(0, 0, 0, 0.1)" />
		</svg>
	);
};

export default icon;
