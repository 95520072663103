import React, { useContext, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import clsx from "clsx";
import { v4 as uuid } from "uuid";

import makeStyles from "@mui/styles/makeStyles";

import generalSettingsStyle from "../jss/generalSettingsStyle";
import accordionStyle from "assets/jss/components/accordionStyle";
import menuStyle from "atlas/assets/jss/components/menuStyle";
import Accordion from "atlas/components/Accordion/Accordion";
import AccessibleIconButton from "atlas/components/Buttons/AccessibleIconButton";
import ButtonWithTooltip from "atlas/components/Buttons/ButtonWithTooltip";
import NonModalMenu from "atlas/components/Menu/NonModalMenu";
import CircularProgressIndicator from "atlas/components/Progress/CircularProgressIndicator";
import { UserContext } from "contexts/User/UserContext";
import { uploadDefaultLetterheadTemplate, deleteDefaultLetterheadTemplate } from "redux/generalSettings/actions";
import { setSnackbarOptions } from "redux/snackBar/actions";
import { getAllowedExtensionForTemplates, endsWithAllowedExtensionForTemplates } from "utils/allowedExtensions";
import notifierMessage from "utils/notifierMessage";

const useStyles = makeStyles(generalSettingsStyle);
const useAccordionStyles = makeStyles(accordionStyle);
const useMenuStyles = makeStyles(menuStyle);

const DefaultLetterheadTemplate = () => {
	const { t } = useTranslation("generalSettings");
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [anchor, setAnchor] = useState(null);
	const { defaultLetterheadTemplate, updatingLetterhead } = useSelector((state) => state.generalSettingsReducer);
	const letterheadGuid = defaultLetterheadTemplate?.guid || defaultLetterheadTemplate?.policyGuid;
	const classes = useStyles();
	const accordionClasses = useAccordionStyles();
	const menuClasses = useMenuStyles();
	const input = useRef(null);
	const { userAdmin } = useContext(UserContext).user;

	const linkClick = (e) => {
		navigate(`/document/${letterheadGuid}`);

		e.preventDefault();
		e.stopPropagation();
	};

	const toggleMenu = (e) => {
		setAnchor(e.currentTarget);
	};

	const closeMenu = () => {
		setAnchor(null);
	};

	const fileSelectClick = () => {
		input.current?.click();
	};

	const uploadInputChange = (e) => {
		const { target: { files = [] } = {} } = e;

		// Only get files with allowed extensions
		const filteredFiles = [];
		for (let index = 0; index < files.length; index++) {
			const file = files[index];
			if (endsWithAllowedExtensionForTemplates(file.name)) {
				filteredFiles.push(file);
			}
		}

		if (filteredFiles.length > 0) {
			const fileData = new FormData();
			fileData.append(uuid(), filteredFiles[0]);

			dispatch(uploadDefaultLetterheadTemplate(fileData))
				.then((res) => {
					if (!res.body) {
						const option = notifierMessage(t("snackbar.success.defaultLetterheadTemplateUpload"), "success");
						dispatch(setSnackbarOptions(option));
					} else {
						const option = notifierMessage(t("snackbar.error.defaultLetterheadTemplateUpload"), "error");
						dispatch(setSnackbarOptions(option));
					}
				})
				.catch((exception) => {
					console.error(exception);

					let option = notifierMessage(t("snackbar.error.defaultLetterheadTemplateUpload"), "error");
					dispatch(setSnackbarOptions(option));
				});
		}

		e.target.value = null;
	};

	const handleDelete = () => {
		dispatch(deleteDefaultLetterheadTemplate())
			.then((res) => {
				if (!res.body) {
					const option = notifierMessage(t("snackbar.success.defaultLetterheadTemplateDelete"), "success");
					dispatch(setSnackbarOptions(option));
				} else {
					const option = notifierMessage(t("snackbar.error.defaultLetterheadTemplateDelete"), "error");
					dispatch(setSnackbarOptions(option));
				}
			})
			.catch((exception) => {
				console.error(exception);

				let option = notifierMessage(t("snackbar.error.defaultLetterheadTemplateDelete"), "error");
				dispatch(setSnackbarOptions(option));
			});
	};

	const menuOptions = [
		{
			label: t("app:buttons.replace"),
			tooltip: t("tooltips.replaceLetterheadTemplate"),
			actionFunction: fileSelectClick,
			"data-cy": "replace-letterhead-template",
		},
		{
			label: t("app:buttons.delete"),
			tooltip: t("tooltips.deleteLetterheadTemplate"),
			actionFunction: handleDelete,
			"data-cy": "delete-letterhead-template",
		},
	];

	return userAdmin ? (
		<div className={clsx(classes.section, classes.sectionAccordion)}>
			<Accordion
				classes={accordionClasses}
				className={classes.bold}
				label={t("subtitle.defaultLetterheadTemplate")}
				scrollContentIntoView
				overflow={false}
				dataCy="default-letterhead-template"
			>
				<div className={clsx(classes.subAccordians)}>
					{updatingLetterhead ? (
						<CircularProgressIndicator size={24} />
					) : letterheadGuid ? (
						<div className={classes.letterheadTemplate}>
							<div>
								<a href={`/home/document/${letterheadGuid}`} onClick={linkClick}>
									{defaultLetterheadTemplate.title}
								</a>
							</div>
							<div>
								<AccessibleIconButton
									iconColor="inherit"
									aria-label={t("tooltips.defaultLetterheadTemplateMenu")}
									onClick={toggleMenu}
									iconName="more"
									iconSize="20px"
									dataCy="default-letterhead-template-menu-button"
								/>
								{anchor && (
									<NonModalMenu
										id="default-letterhead-template-menu"
										className={menuClasses.menu}
										anchorEl={anchor}
										keepMounted
										open
										onClose={closeMenu}
										options={menuOptions}
										position="bottom-end"
									/>
								)}
							</div>
						</div>
					) : (
						<div className={classes.buttons}>
							<ButtonWithTooltip
								id="default-letterhead-template-upload-button"
								onClick={fileSelectClick}
								variant="outlined"
								color="primary"
								title={t("tooltips.uploadTemplate")}
								data-cy="upload-letterhead-template-button"
							>
								{t("buttons.uploadTemplate")}
							</ButtonWithTooltip>
						</div>
					)}
					<input
						className={classes.fileInput}
						id="default-letterhead-template-upload-input"
						type="file"
						ref={input}
						onChange={uploadInputChange}
						accept={getAllowedExtensionForTemplates()}
						tabIndex={-1}
						aria-label={t("buttons.uploadTemplate")}
						aria-hidden="true"
					/>
				</div>
			</Accordion>
		</div>
	) : null;
};
export default DefaultLetterheadTemplate;
