import React, { useState, useEffect, useRef, useContext, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useMatch, useNavigate } from "react-router-dom";
import request from "superagent";
import { useTranslation } from "react-i18next";
import { debounce } from "lodash";
import isEqual from "lodash/fp/isEqual";
import CircularProgressIndicator from "atlas/components/Progress/CircularProgressIndicator";
import withErrorHandling from "components/ErrorHOC";
import { API_HOST } from "config/env";
import telemetryAddEvent from "utils/telemetryAddEvent";
import { updateHandlers, LIVE_MEETING_HUB, VOTING_HUB } from "utils/communication/SignalrClient";
import ViewerTimer from "../LiveMeeting/components/ViewerTimer";
import VotingDialog from "components/Dialogs/VotingDialog";
import { updateAppbarTools } from "redux/app/actions";
import { UserContext } from "contexts/User/UserContext";
import { getComparator, stableSort } from "atlas/components/TableData/sortFunctions";
import { resetPageConfigs, updatePageConfigs, updateBottomNotice } from "redux/app/actions";
import { updatePageHeader } from "redux/pageHeader/actions";
import { STATUS_ABSENT } from "../LiveMeeting/utils/rollCallUserStatus";
import PdfWithSideBar from "../../components/PdfViewerNew/PdfWithSideBar";
import { getAgendaItemsForOutline } from "../../components/PdfViewerNew/PdfAPICalls";

const MeetingDocument = (props) => {
	const { showSignIn } = props;
	const { params: { id: meetingId } = {} } = useMatch({ path: "/meeting/document/:id", end: true }) || {};
	const { t } = useTranslation("meetings");
	const location = useLocation();
	const navigate = useNavigate();
	const [settings, setSettings] = useState({
		attachments: [],
	});
	const [fileBlob, setFileBlob] = useState(null);
	const [agendaItems, setAgendaItems] = useState([]);
	const [showClosedItemDescription, setShowClosedItemDescription] = useState();
	const [agendaItemsForBookmarks, setAgendaItemsForBookmarks] = useState();
	const [id, setId] = useState(0);
	const [selectedDocumentId, setSelectedDocumentId] = useState(0);
	const [timer, setTimer] = useState(null);
	const [votingData, setVotingData] = useState(null);
	const [votingDataResponse, setVotingDataResponse] = useState(null);
	const [showVotingDialog, setShowVotingDialog] = useState(false);
	const [votingSettings, setVotingSettings] = useState({});
	const [votingItemId, setVotingItemId] = useState(0);
	const [rollCall, setRollCall] = useState(null);
	const [showTieBreakerVote, _setShowTieBreakerVote] = useState(false);

	const { boardAdmin } = useContext(UserContext).user;
	const keepCheckingVoting = useRef(false);

	const { meetingName = "", zoomLink = "", userId } = settings;
	const dispatch = useDispatch();
	const appReducer = useSelector((state) => state.appReducer);
	const {
		signalR: { client, handler },
	} = appReducer;
	const useBlob = window.location.href.indexOf("useBlob") > 0;

	const loadVotingSettings = () => {
		request
			.get(`${API_HOST}/api/settings`)
			.query({ type: "voting" })
			.then((res) => {
				if (res.body) {
					setVotingSettings(res.body);
				}
			})
			.catch((err) => {
				if (typeof showSignIn === "function") {
					showSignIn(err, () => {
						loadVotingSettings();
					});
				}
			});
	};

	const updateTimer = (timerMeetingId, timerTotalSeconds, timerRemainingSeconds, timerActive, timerCanceled) => {
		if (timerCanceled || parseInt(meetingId, 10) !== timerMeetingId) {
			setTimer(null);

			return;
		}

		setTimer({
			totalSeconds: timerTotalSeconds,
			remainingSeconds: timerRemainingSeconds,
			active: timerActive,
		});
	};

	const checkVotingData = debounce(async () => {
		const res = await request.get(`${API_HOST}/api/voting/votingdata/${meetingId}?page=member`);
		const { votingInRange } = res.body;

		setVotingDataResponse((prev) => {
			if (!isEqual(res.body, prev)) {
				return res.body;
			}
			return prev;
		});
		if (keepCheckingVoting.current && votingInRange) {
			checkVotingData();
		}
	}, 2000);

	const handleVoteClick = useCallback(
		(vote, callback) => {
			request
				.post(`${API_HOST}/api/voting/vote/${votingData.guid}`)
				.withCredentials()
				.send({ meetingId, userId, vote })
				.then((res) => {
					if (callback != null && typeof callback === "function") {
						callback(res);

						telemetryAddEvent("Agenda View - Member vote");
					}
				})
				.catch((err) => {
					isSaving.current = false;
					if (callback != null && typeof callback === "function") {
						callback(err);
					}
				});
		},
		[votingData?.guid, meetingId, userId],
	);

	const votingDialogClose = () => {
		setShowVotingDialog(false);
	};

	const showResults = (hubVotingData) => {
		hubVotingData.itemInProgress.showResults = true;
		setVotingItemId(0);
		setVotingData(hubVotingData.itemInProgress);
		setShowVotingDialog(true);
	};

	const reSendForVote = () => {
		setShowVotingDialog(true);
	};

	const hideResults = () => {
		setVotingItemId(0);
		setVotingData(null);
		setShowVotingDialog(false);
	};

	const downloadPdf = (url) => {
		if (url && url.startsWith("/document/") && useBlob) {
			const fileUrl = `${API_HOST}${url}`;
			request
				.get(fileUrl)
				.responseType("blob")
				.then((res) => {
					setFileBlob(res.body);
				});
		}
	};

	const getMeetingDocument = () => {
		request
			.get(`${API_HOST}/api/meeting/${meetingId}/meetingdocument`)
			.withCredentials()
			.then((res) => {
				const { id: newId, historic, guid } = res.body;

				if (historic) {
					navigate(`/document/${guid}`);
				} else {
					setSettings(res.body);
					setId(newId);
					setSelectedDocumentId(newId);
					downloadPdf(res.body.url);
				}
			})
			.catch((err) => {
				if (typeof showSignIn === "function") {
					showSignIn(err, () => {
						getMeetingDocument();
					});
				}
			});
	};

	useEffect(() => {
		dispatch(
			updateAppbarTools({
				appbarTools: {
					toggleVoteDialog:
						votingData != null
							? {
									onClick: () => {
										setShowVotingDialog(!showVotingDialog);
									},
									text: t(`voting.buttons.${showVotingDialog ? "hideVote" : "showVote"}`),
							  }
							: null,
				},
			}),
		);
	}, [showVotingDialog]);

	useEffect(() => {
		if (votingData != null) {
			if (votingItemId !== votingData.id) {
				setVotingItemId(votingData.id);
				setShowVotingDialog(true);
			}
		} else {
			setVotingItemId(0);
			setShowVotingDialog(false);
		}
	}, [votingData]);

	useEffect(() => {
		if (votingDataResponse) {
			const { itemInProgress, votingInRange } = votingDataResponse;
			if (votingInRange) {
				const rollCallUser = votingDataResponse.rollCall.users.find(
					(user) =>
						user.userId === userId &&
						(user.votingMember || (user.tieBreaker && itemInProgress && itemInProgress.status === 3)) &&
						user.status !== STATUS_ABSENT,
				);
				if (itemInProgress != null && rollCallUser != null) {
					if (votingItemId > 0 || itemInProgress.status != 2) {
						setVotingData(itemInProgress);
					}
				} else if (votingData != null && votingData.itemInProgress != null && !votingData.itemInProgress.showResults) {
					setVotingData(null);
				}
			}
			setRollCall(votingDataResponse.rollCall);
		}
	}, [votingDataResponse, showTieBreakerVote]);

	useEffect(() => {
		if (settings) {
			const { digitalVoting, votingInRange, rollCall, userId } = settings;

			setRollCall(rollCall);

			if (votingInRange) {
				const votingMember = rollCall
					? rollCall.users.find((user) => user.userId === userId && (user.votingMember || user.tieBreaker))
					: null;
				if (votingMember != null && digitalVoting) {
					keepCheckingVoting.current = true;
					checkVotingData();
					client.updateStatusIcon();
				}
			}
		}
	}, [settings]);

	useEffect(() => {
		dispatch(resetPageConfigs({}));
		dispatch(
			updatePageConfigs({
				title: "",
				telemetryPage: "Meeting document",
				contentMaxWidth: "xl",
				contentPaper: { transparent: true, fullHeight: true },
			}),
		);
		dispatch(
			updateBottomNotice({
				persistentData: {
					fields: [
						{
							name: "hiddenMeetings",
							action: "pop",
							value: parseInt(meetingId, 10),
						},
					],
				},
				update: true,
			}),
		);

		loadVotingSettings();

		getMeetingDocument();

		updateHandlers(dispatch, handler, LIVE_MEETING_HUB, { updateTimer });
		updateHandlers(dispatch, handler, VOTING_HUB, { showResults, hideResults, reSendForVote });

		client.ensureStarted().then(() => {
			client.liveMeetingHub.addUserToMcpViewers(meetingId, true);
			client.votingHub.addUserToMembersViewers(meetingId);
		});

		telemetryAddEvent("Agenda View - Load");

		getAgendaItemsForOutline(meetingId).then((response) => {
			sortAttachmentOrder(response);
		});

		return () => {
			updateHandlers(dispatch, handler, LIVE_MEETING_HUB, { updateTimer: null });
			updateHandlers(dispatch, handler, VOTING_HUB, {
				showResults: null,
				hideResults: null,
				reSendForVote: null,
			});
		};
	}, [meetingId]);

	useEffect(() => {
		dispatch(
			updatePageConfigs({
				back: {
					url:
						document.referrer && document.referrer.indexOf("/home/meetings") > 0
							? document.referrer.replace(`${window.location.origin}/home`, "")
							: boardAdmin
							? `/meeting/${meetingId}`
							: `/`,
				},
				title: meetingName,
				telemetryPage: "Meeting document",
				contentMaxWidth: "xl",
			}),
		);
		dispatch(
			updatePageHeader({
				primaryAction:
					zoomLink && zoomLink.length > 0
						? () => {
								window.open(zoomLink);
						  }
						: undefined,
				primaryActionText: zoomLink && zoomLink.length > 0 ? t("buttons.joinVideo") : undefined,
				primaryActionTooltip: zoomLink && zoomLink.length > 0 ? t("tooltips.openVideoLink") : undefined,
			}),
		);
	}, [meetingName, zoomLink]);

	useEffect(() => {
		return () => {
			keepCheckingVoting.current = false;
			checkVotingData.cancel();
			client.updateStatusIcon();
		};
	}, []);

	const arrangeItemsForBookmarks = (items) => {
		let itemForBookmarks = [];
		let index = 0;
		items.forEach((e) => {
			if (e.attributes && !e.attributes.relationshipGuid) {
				index = itemForBookmarks.length;
				itemForBookmarks[index] = e;
			} else {
				if (e.itemType === 7 || e.fields.Name.Value === "") return;
				if (!itemForBookmarks[index]["children"]) {
					itemForBookmarks[index]["children"] = [];
				}
				itemForBookmarks[index]["children"].push(e);
			}
		});
		setAgendaItemsForBookmarks(itemForBookmarks);
	};

	const sortAttachmentOrder = async (response) => {
		(await response.items) &&
			response.items.forEach((item) => {
				if (item.goals && item.attachments) {
					item.attachments = [...item.attachments, ...item.goals];
				} else if (item.goals && !item.attachments) {
					item.attachments = item.goals;
				}

				if (item.attachments) {
					item.attachments = stableSort(item.attachments, getComparator("asc", "order"));
				}
			});
		setAgendaItems(response.items);
		setShowClosedItemDescription(response.showClosedItemDescription);
		if (response.items) {
			arrangeItemsForBookmarks(response.items);
		}
	};

	return (
		<>
			{!settings.url || !settings.attachments ? <CircularProgressIndicator data-cy="loadingDocument" /> : null}
			<PdfWithSideBar
				showSignIn={showSignIn}
				agendaItems={agendaItems}
				agendaItemsForBookmarks={agendaItemsForBookmarks}
				showClosedItemDescription={showClosedItemDescription}
				id={id}
				meetingId={meetingId}
				selectedDocumentId={selectedDocumentId}
				settings={settings}
				fileBlob={fileBlob}
				useBlob={useBlob}
			/>
			{timer && <ViewerTimer timer={timer} />}
			{showVotingDialog && votingData && (
				<VotingDialog
					show={showVotingDialog}
					userId={userId}
					votingData={votingData}
					rollCall={rollCall}
					votingSettings={votingSettings}
					handleVoteClick={handleVoteClick}
					votingDialogClose={votingDialogClose}
				/>
			)}
		</>
	);
};

export default withErrorHandling(MeetingDocument);
