export const getFolderOptions = (t, handleEvent, policy) => {
	return [
		{
			label: t("app:buttons.rename"),
			tooltip: t("tooltips.rename"),
			actionFunction: (document) => handleEvent({ eventName: "rename", document }),
			hidden: false,
			"data-cy": "rename",
		},
		{
			label: t("app:buttons.publishSection"),
			tooltip: t("tooltips.publishSectionName"),
			actionFunction: (policy) => handleEvent({ eventName: "publish-section-name", document: policy }),
			hidden: !policy.publishedGuid,
			"data-cy": "publish-section-name",
		},
		{
			label: t("app:buttons.delete"),
			tooltip: t("tooltips.delete"),
			actionFunction: (document) => handleEvent({ eventName: "deleteSection", document }),
			hidden: false,
			"data-cy": "delete",
		},
	];
};

export const getRecycleBinOptions = (t, handleEvent, permissions) => {
	return [
		{
			label: t("app:buttons.permanentlyDelete"),
			tooltip: t("tooltips.permanentlyDeleteDocument"),
			actionFunction: (document) => handleEvent({ eventName: "permanently-delete", document }),
			"data-cy": "permanently-delete",
			hidden: !permissions?.canPermanentlyDelete,
		},
		{
			label: t("app:buttons.restore"),
			tooltip: t("tooltips.restoreDocument"),
			actionFunction: (document) => handleEvent({ eventName: "restore", document }),
			"data-cy": "restore",
			hidden: false,
		},
	];
};

export const getLiteOptions = (t, handleEvent) => {
	return [
		{
			label: t("app:buttons.editDraftInWord"),
			tooltip: t("tooltips.rename"),
			actionFunction: (document) => handleEvent({ eventName: "editdraft", document }),
			icon: "microsoft-word-menuOption",
			"data-cy": "rename",
			hidden: false,
		},
		{
			label: t("app:buttons.addToAgenda"),
			tooltip: t("tooltips.delete"),
			actionFunction: (document) => handleEvent({ eventName: "addtomeeting", document }),
			"data-cy": "delete",
			hidden: false,
		},
		{
			label: t("app:buttons.duplicate"),
			tooltip: t("tooltips.delete"),
			actionFunction: (document) => handleEvent({ eventName: "copyMove", document }),
			"data-cy": "delete",
			hidden: false,
		},
	];
};

export const getPolicyOnlyOptions = (t, handleEvent) => {
	return [
		{
			label: t("app:buttons.editDraftInWord"),
			tooltip: t("tooltips.rename"),
			actionFunction: (document) => handleEvent({ eventName: "editdraft", document }),
			icon: "microsoft-word-menuOption",
			"data-cy": "rename",
			hidden: false,
		},
		{
			label: t("app:buttons.duplicate"),
			tooltip: t("tooltips.delete"),
			actionFunction: (document) => handleEvent({ eventName: "copyMove", document }),
			"data-cy": "delete",
			hidden: false,
		},
	];
};

export const getOtherOptions = (t, handleEvent) => {
	return [
		{
			label: t("app:buttons.editDraftInWord"),
			tooltip: t("tooltips.rename"),
			actionFunction: (document) => handleEvent({ eventName: "editdraft", document }),
			icon: "microsoft-word-menuOption",
			"data-cy": "rename",
			hidden: false,
		},
		{
			label: t("app:buttons.duplicate"),
			tooltip: t("tooltips.delete"),
			actionFunction: (document) => handleEvent({ eventName: "copyMove", document }),
			"data-cy": "delete",
			hidden: false,
		},
	];
};
