import React from 'react';
import makeStyles from '@mui/styles/makeStyles';

import styles from '../../assets/jss/components/typographyStyle';

const useStyles = makeStyles(styles);

export default function LargeLabel(props) {
	const classes = useStyles();
	const { children, color, bold, semiBold, fullWidth, component, ...otherProps } = props;
	return (
		<>
			{component === 'span' ? (
				<span
					className={`${classes.largeLabel} ${color ? classes[color] : ''} ${bold ? classes.bold : ''} ${
						semiBold ? classes.semiBold : ''
					}  ${fullWidth ? classes.fullWidth : ''}`}
					{...otherProps}
				>
					{children}
				</span>
			) : (
				<div
					className={`${classes.largeLabel} ${color ? classes[color] : ''} ${bold ? classes.bold : ''} ${
						semiBold ? classes.semiBold : ''
					}  ${fullWidth ? classes.fullWidth : ''}`}
					{...otherProps}
				>
					{children}
				</div>
			)}
		</>
	);
}
