import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import request from "superagent";
import { API_HOST } from "config/env";

import ComponentContainer from "atlas/components/ComponentContainer/ComponentContainer";
import CircularProgressIndicator from "atlas/components/Progress/CircularProgressIndicator";
import isEmail from "atlas/utils/isEmail";

import CreateSubscribers from "./CreateSubscribers";

import telemetryAddEvent from "utils/telemetryAddEvent";
import { resetPageConfigs, updatePageConfigs } from "redux/app/actions";
import { updatePageHeader } from "redux/pageHeader/actions";
import { setSnackbarOptions } from "redux/snackBar/actions";
import notifierMessage from "utils/notifierMessage";

const telemetryPage = "Subscribers Add";
const defaultSubscription = {
	meetingTypes: [],
	users: [],
	externalEmailsValue: "",
};

const SubscribersContainer = (props) => {
	const { showSignIn } = props;
	const { t } = useTranslation("subscribers");
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [subscription, setSubscription] = useState(defaultSubscription);
	const [users, setUsers] = useState(null);
	const [meetingTypes, setMeetingTypes] = useState(null);
	const [externalEmailError, setExternalEmailError] = useState(false);
	const [createDisabled, setCreateDisabled] = useState(true);

	const externalEmails = useRef([]);

	const handleCreate = () => {
		if (validateSubscription()) {
			setCreateDisabled(true);
			request
				.post(`${API_HOST}/api/subscriptions/create`)
				.send({ ...subscription, externalEmails: externalEmails.current })
				.withCredentials()
				.then(() => {
					let option = notifierMessage(t("create.notification.createSuccess"), "success");
					dispatch(setSnackbarOptions(option));
					setCreateDisabled(false);
					telemetryAddEvent(`${telemetryPage} - Create`);
					navigate("/subscribers");
				})
				.catch((err) => {
					console.log(err);
					setCreateDisabled(false);
				});
		}
	};

	const validateSubscription = () => {
		let valid = true;
		setExternalEmailError(false);

		if (subscription && subscription.meetingTypes.length && (subscription.users.length || subscription.externalEmailsValue.length)) {
			externalEmails.current = (subscription.externalEmailsValue || "").trim().match(/[^\s,;]+/g) || [];
			if (externalEmails.current.length) {
				externalEmails.current.forEach((email) => {
					if (!isEmail(email)) {
						setExternalEmailError(true);
						valid = false;
					}
				});
			}
		} else {
			valid = false;
		}

		return valid;
	};

	const subscriptionChange = (newSubscription) => {
		setSubscription(newSubscription);
	};

	const loadMeetingTypes = () => {
		request
			.get(`${API_HOST}/api/meetingtypes`)
			.withCredentials()
			.then((res) => {
				const { body: meetingTypes } = res || {};

				setMeetingTypes(meetingTypes);
			})
			.catch((err) => {
				showSignIn(err, () => {
					loadMeetingTypes();
				});
			});
	};

	const loadUsers = () => {
		request
			.get(`${API_HOST}/api/users?firstNameLastName=true&emailAddress=true`)
			.withCredentials()
			.then((res) => {
				const { body } = res || {};

				setUsers(body);
			})
			.catch((err) => {
				showSignIn(err, () => {
					loadUsers();
				});
			});
	};

	useEffect(() => {
		if (externalEmailError) {
			let option = notifierMessage(t("create.notification.externalEmailError"), "success");
			dispatch(setSnackbarOptions(option));
		}
	}, [externalEmailError]);

	useEffect(() => {
		dispatch(
			updatePageHeader({
				primaryAction: handleCreate,
				primaryActionText: t("buttons.create"),
				primaryActionTooltip: t("tooltips.create"),
				primaryActionDisabled: createDisabled,
			}),
		);
	}, [createDisabled]);

	useEffect(() => {
		if (subscription && subscription.meetingTypes.length > 0 && (subscription.users.length || subscription.externalEmailsValue.length)) {
			setCreateDisabled(false);
		} else {
			setCreateDisabled(true);
		}
	}, [subscription]);

	useEffect(() => {
		loadUsers();
		loadMeetingTypes();

		dispatch(resetPageConfigs({ resetBack: true }));
		dispatch(
			updatePageConfigs({
				title: t("addSubscribers.title"),
				telemetryPage,
				back: { url: "/subscribers" },
			}),
		);
		dispatch(
			updatePageHeader({
				primaryAction: handleCreate,
				primaryActionText: t("buttons.create"),
				primaryActionTooltip: t("tooltips.create"),
				primaryActionDisabled: createDisabled,
			}),
		);
	}, []);

	return (
		<ComponentContainer>
			{meetingTypes && users ? (
				<CreateSubscribers
					subscription={subscription}
					createDisabled={createDisabled}
					users={users}
					meetingTypes={meetingTypes}
					handleCreate={handleCreate}
					subscriptionChange={subscriptionChange}
					externalEmailError={externalEmailError}
				/>
			) : (
				<CircularProgressIndicator />
			)}
		</ComponentContainer>
	);
};

export default SubscribersContainer;
