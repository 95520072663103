import React, { useRef, useEffect, useContext, useState } from "react";
import WebViewer from "@pdftron/webviewer";
import { SettingsContext } from "contexts/Settings/SettingsContext";
import clsx from "clsx";
import { displayErrorNotification } from "views/Policy/utils/getNotification";
import { useDispatch } from "react-redux";
import { Box } from "@mui/material";
import CircularProgressIndicator from "atlas/components/Progress/CircularProgressIndicator";
import makeStyles from "@mui/styles/makeStyles";
import AccessibleIconButton from "atlas/components/Buttons/AccessibleIconButton";
import HelpMenu from "views/HelpMenu/HelpMenu";
import { updateLeftSideIcons, updateZoomIconsForCompareMode } from "components/PdfViewerNew/Helper/CustomUIConfiguration";
import { useTranslation } from "react-i18next";
import DiffCustomAnnotation from "components/PdfViewerNew/utils/DiffCustomAnnotation";

const useStyles = makeStyles({
	headerContainer: {
		display: "flex",
		backgroundColor: "#2f3b4d",
		height: "56px",
		justifyContent: "space-between",
	},
	headerTitle: {
		color: "#FFFFFF",
		fontSize: "16px",
		fontWeight: "400",
		display: "flex",
		alignItems: "center",
	},
	headerItems: {
		display: "flex",
		alignItems: "center",
	},
	loadingIndicatorContainer: {
		position: "absolute",
		top: 0,
		width: "100%",
		height: "100%",
		backgroundColor: "white",
		zIndex: 1,
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
});

const DocumentCompare = (props) => {
	const viewerPromise = useRef(null);
	const viewer = useRef(null);
	const instance = useRef(null);
	const classes = useStyles();
	const { t } = useTranslation();
	const { pdfTronL } = useContext(SettingsContext);
	const { url1, styleClass, url2, disableCompareWindow, compareDocumentsDetails = null, policyTitle, headerTitle } = props;
	const { document1, document2 } = compareDocumentsDetails || {};
	const [checked, setChecked] = useState(true);
	const [loadingDocumentInProgress, setLoadingDocumentInProgress] = useState(true);
	const dispatch = useDispatch();

	const disabledElements = [
		"menuButton",
		"leftPanelButton",
		"zoomOverlayButton",
		"searchButton",
		"toggleNotesButton",
		"header",
		"viewControlsDivider3",
		"viewControlsDivider2",
		"coverLayoutButton",
		"doubleLayoutButton",
		"singleLayoutButton",
		"fullScreenButton",
		"layoutHeader",
		"annotationPopup",
		"contextMenuPopup",
		"textPopup",
	];

	const { displayDiffCustomAnnotations, removeDiffCustomAnnotations } = DiffCustomAnnotation();

	useEffect(() => {
		viewerPromise.current = WebViewer(
			{
				path: "/Global/3rdParty/version10.6.1/lib",
				css: "/css/pdftron.viewer.css",
				licenseKey: pdfTronL,
				useDownloader: false,
				disabledElements,
				streaming: false,
				backendType: "ems",
				extension: "pdf",
				preloadWorker: "pdf",
				fullAPI: true,
				accessibleMode: true,
				disableMultiViewerComparison: true,
			},
			viewer.current,
		);
	}, []);

	useEffect(() => {
		// If the viewer is initialized and the document URL exists, load the document
		if (viewerPromise.current && url1 && url2) {
			viewerPromise.current.then(async (inst) => {
				const { UI } = inst;
				instance.current = inst;
				UI.setToolbarGroup("toolbarGroup-View");
				UI.addEventListener(UI.Events.MULTI_VIEWER_READY, onMultiViewerReady);
				UI.enterMultiViewerMode();
			});
			viewerPromise.current = null;
		}
	}, [viewerPromise.current, url1, url2]);

	useEffect(() => {
		if (instance.current) {
			instance.current.UI.setHeaderItems((header) => {
				const items = header.getItems();
				const comparePanelButton = items.find((item) => item.dataElement === "showComparisonButton");
				if (comparePanelButton) {
					items[items.indexOf(comparePanelButton)] = {
						type: "customElement",
						render: () => <Slider />,
						dataElement: "showComparisonButton",
					};
					header.update([...items]);
				}
			});
		}
	}, [checked, instance.current]);

	const disableCompareMode = async () => {
		const { UI, Core } = instance.current;
		removeDiffCustomAnnotations(Core.documentViewer);
		await UI.exitMultiViewerMode();
		disableCompareWindow();
	};

	const toggleComparisonMode = (e) => {
		setChecked(!checked);
		const [documentViewer1, documentViewer2] = instance.current.Core.getDocumentViewers();
		const annotationManager1 = documentViewer1.getAnnotationManager();
		const annotationManager2 = documentViewer2.getAnnotationManager();
		if (e.target.checked) {
			const doc1Annotations = annotationManager1.getSemanticDiffAnnotations(1);
			const doc2Annotations = annotationManager2.getSemanticDiffAnnotations(2);
			annotationManager1.showAnnotations(doc1Annotations, 1);
			annotationManager2.showAnnotations(doc2Annotations, 2);
			displayDiffCustomAnnotations(instance, doc1Annotations, doc2Annotations);
		} else {
			annotationManager1.hideAnnotations(annotationManager1.getSemanticDiffAnnotations(1), 1);
			annotationManager2.hideAnnotations(annotationManager2.getSemanticDiffAnnotations(2), 2);
			removeDiffCustomAnnotations(documentViewer1);
			removeDiffCustomAnnotations(documentViewer2);
		}
	};

	const Slider = () => {
		return (
			<div class="checkboxcontainer">
				<input type="checkbox" checked={checked} onChange={(e) => toggleComparisonMode(e)}></input>
				<label style={{ fontSize: "unset" }}>Show Comparison</label>
			</div>
		);
	};

	const onMultiViewerReady = () => {
		setLoadingDocumentInProgress(true);
		const { UI, Core } = instance.current;
		const { Annotations } = Core;
		const { Color } = Annotations;
		const [documentViewer1, documentViewer2] = Core.getDocumentViewers();
		documentViewer1
			.loadDocument(url1, {
				extension: "pdf",
				filename: document1 ? `${document1?.name} - ${document1?.date}` : `Policy1`,
			})
			.catch((error) => {
				disableCompareMode();
				displayErrorNotification(t("meetings:comparePolicy.unableToLoadDocument"), dispatch);
				console.error(error, "documentViewer1");
				return;
			});
		const loadAnotherDocument = () => {
			documentViewer1.setOpenURIHandler((target) => {
				window.open(target, "_blank", "noopener");
			});
			documentViewer2
				.loadDocument(url2, {
					extension: "pdf",
					filename: document2 ? `${document2?.name} - ${document2?.date}` : `Policy2`,
				})
				.catch((error) => {
					disableCompareMode();
					displayErrorNotification(t("meetings:comparePolicy.unableToLoadDocument"), dispatch);
					console.error(error, "documentViewer2");
					return;
				});
			documentViewer2.setOpenURIHandler((target) => {
				window.open(target, "_blank", "noopener");
			});
		};

		const startCompare = async () => {
			removeDiffCustomAnnotations(Core.documentViewer);
			const shouldCompare = documentViewer1.getDocument() && documentViewer2.getDocument();
			if (shouldCompare) {
				let pageContent1 = await documentViewer1.getDocument().loadPageText(1);
				let pageContent2 = await documentViewer2.getDocument().loadPageText(1);
				if (pageContent1 === "" || pageContent2 === "") {
					disableCompareMode();
					displayErrorNotification(t("meetings:comparePolicy.emptyDocumentMsg"), dispatch);
					return;
				}
				// Check if both documents loaded before comparing
				const beforeColor = new Color(21, 205, 131, 0.4);
				const afterColor = new Color(255, 73, 73, 0.4);
				const options = { beforeColor, afterColor };
				const { doc1Annotations, doc2Annotations, diffCount } = await documentViewer1.startSemanticDiff(documentViewer2, options);
				displayDiffCustomAnnotations(instance, doc1Annotations, doc2Annotations);
				UI.enableMultiViewerSync(1);
				await UI.setHeaderItems((header) => {
					const item = header.getItems();
					const comparePanelPresent = item.find((item) => item.dataElement === "comparePanelToggleButton");
					header.update([
						{
							dataElement: "viewControlsButton",
							element: "viewControlsOverlay",
							img: "icon-header-page-manipulation-line",
							title: "component.viewControlsOverlay",
							type: "toggleElementButton",
						},
						{ type: "divider" },
						{
							toolName: "Pan",
							type: "toolButton",
						},
						{
							hidden: [],
							toolName: "AnnotationEdit",
							type: "toolButton",
						},
						{ type: "divider" },
						{
							type: "customElement",
							render: () => <Slider />,
							dataElement: "showComparisonButton",
						},
						{ type: "spacer" },
						...(!comparePanelPresent && [
							{
								dataElement: "comparePanelToggleButton",
								hiddenOnMobileDevices: true,
								img: "icon-header-compare",
								type: "toggleElementButton",
								element: "comparePanel",
								title: "component.comparePanel",
								hidden: ["small-mobile"],
							},
						]),
					]);
				});
				updateLeftSideIcons(UI);
				updateZoomIconsForCompareMode(UI);
				UI.enableElements(["header"]);
				UI.openElement("comparePanel");
				const pdfViewIframe = UI.iframeWindow.document;
				setLoadingDocumentInProgress(false);
			}
		};
		documentViewer1.addEventListener("documentLoaded", loadAnotherDocument);
		documentViewer2.addEventListener("documentLoaded", startCompare);
	};

	return (
		<div className={clsx("App", styleClass)}>
			<div id="header" className={classes.headerContainer}>
				<Box mr={2} className={classes.headerItems}>
					<AccessibleIconButton
						id="back"
						color="inherit"
						aria-label="back"
						onClick={() => {
							disableCompareMode();
						}}
						iconName="expand-left"
						dataCy="backButton"
						tooltipText={"Close Compare Mode"}
						isHeader
					/>
				</Box>
				<div className={classes.headerTitle}>
					{policyTitle} | {headerTitle}
				</div>
				<div className={classes.headerItems}>
					<HelpMenu />
				</div>
			</div>
			<div className={clsx("webviewer", styleClass)} ref={viewer}>
				{loadingDocumentInProgress && (
					<>
						<div className={classes.loadingIndicatorContainer}>
							<Box>
								<CircularProgressIndicator size={30} minHeight={"30"} style={{ color: "#2f3b4d" }} />
							</Box>
						</div>
					</>
				)}
			</div>
		</div>
	);
};

export default DocumentCompare;
