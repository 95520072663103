import { NONE, DRAFT, PUBLISHED, RESCINDED, REVIEW, HISTORICAL } from "utils/enums/PolicyTypes";

const getPolicyType = (type) => {
	switch (type) {
		case 1:
			return DRAFT;

		case 2:
			return ADOPTED;

		case 3:
			return PUBLISHED;

		case 4:
			return RESCINDED;

		case 6:
			return HISTORICAL;

		default:
			return undefined;
	}
};

export default getPolicyType;
