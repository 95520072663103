import React, { useState, useCallback, useRef, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { useDropzone } from "react-dropzone";

import makeStyles from "@mui/styles/makeStyles";
import { Checkbox, FormControlLabel, MenuItem, Typography } from "@mui/material";

import Accordion from "atlas/components/Accordion/Accordion";
import Icon from "atlas/components/Icon/Icon";
import InputLabel, { STYLE_SENTENCE_CASE, SIZE_LARGE, ROLE_HEADING } from "atlas/components/FormControls/InputLabel";
import OutlinedInput from "atlas/components/FormControls/OutlinedInput";
import SelectInput from "atlas/components/FormControls/SelectInput";
import AccessibleIconButton from "atlas/components/Buttons/AccessibleIconButton";
import NonModalMenu from "atlas/components/Menu/NonModalMenu";
import ButtonWithTooltip from "atlas/components/Buttons/ButtonWithTooltip";
import Tooltip from "atlas/components/Tooltip/Tooltip";
import { MEDIUM } from "atlas/utils/buttonSize";
import { blackColor, grayColor, errorColor, whiteColor, successColor } from "atlas/assets/jss/shared";
import { STATUS_GOOD } from "atlas/assets/jss/utils/statusIndicators";
import inputStyle from "atlas/assets/jss/components/inputStyle";
import typographyStyle from "atlas/assets/jss/components/typographyStyle";
import accordionStyle from "assets/jss/components/accordionStyle";
import { useWidthDown } from "atlas/utils/useWidth";
import { SettingsContext } from "contexts/Settings/SettingsContext";
import { useUpdateObject, getRequiredLabel, getErrorProps } from "utils/updateObject";
import { Check } from "components/Icons";
import GenericAvatar, { GENERIC_AVATAR_BACKGROUNDS } from "components/Avatar/GenericAvatar";
import GenericEditor from "components/Editor/GenericEditor";
import { getNoOptionsMenuItem, getDropdownProgressIndicator } from "utils/dropDown";
import { processProfileImage } from "utils/image";
import telemetryAddEvent from "utils/telemetryAddEvent";
import BoardUserOptions from "views/Boards/components/BoardUserOptions";
import BoardUserOptionLabels from "views/Boards/components/BoardUserOptionLabels";
import StyledPhoneInput from "../../components/PhoneInput/StyledPhoneInput";
import NotificationSettingsSection from "./components/NotificationSettingsSection";
import DocumentLibrarySection from "./components/DocumentLibrarySection";
import PolicySettingsSection from "./components/PolicySettingsSection";

const useInputStyles = makeStyles(inputStyle);
const useStyles = makeStyles((theme) => ({
	section: {
		backgroundColor: whiteColor,
		boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
		borderRadius: "2px",
		padding: "16px",
		marginBottom: "16px",
	},
	sectionAccordion: {
		padding: "0 16px",
	},
	header: {
		display: "flex",
		alignItems: "center",
	},
	headerTitle: {
		flexGrow: "1",
	},
	columns: {
		display: "flex",
		flexWrap: "wrap",
		margin: "0 -12px",
		[theme.breakpoints.down("sm")]: {
			margin: "0",
		},
		"& > div": {
			width: "calc(50% - 24px)",
			boxSizing: "border-box",
			margin: "0 12px",
			[theme.breakpoints.down("sm")]: {
				width: "100%",
				margin: "0",
			},
		},
	},
	inputLabelSpacing: {
		marginTop: "8px",
	},
	profilePictureSection: {
		display: "inline-flex",
		alignItems: "center",
		gap: "4px",
		margin: "16px 0",
		"& h3": {
			margin: "0",
		},
	},
	profilePictureAvatar: {
		marginLeft: "16px",
		"&:focus": {
			backgroundColor: grayColor[1],
		},
	},
	notes: {
		marginTop: "0",
	},
	publicProfileSection: {
		display: "flex",
		flexWrap: "nowrap",
		alignItems: "top",
		[theme.breakpoints.down("sm")]: {
			flexWrap: "wrap",
		},
	},
	publicProfilePicture: {
		width: "148px",
		minWidth: "148px",
		margin: "28px 16px 0 0",
		cursor: "pointer",
		[theme.breakpoints.down("sm")]: {
			margin: "0",
		},
	},
	profilePictureLarge: {
		maxWidth: "148px",
	},
	profilePictureLargeDropZone: {
		display: "flex",
		alignItems: "center",
		height: "150px",
		border: `solid 1px ${blackColor[1]}`,
		borderRadius: "4px",
		padding: "8px",
		boxSizing: "border-box",
	},
	checkboxFeature: {
		"&:focus": {
			backgroundColor: grayColor[1],
		},
	},
	publicProfileAbout: {
		flexGrow: "1",
	},
	boardsCommittees: {
		"& ul": {
			margin: "0",
			padding: "0",
			listStyleType: "none",
			"& li": {
				margin: "0",
			},
		},
	},
	userDetailsAvatar: {
		backgroundColor: grayColor[0],
	},
	outlinedInput: {
		"& legend": {
			color: grayColor[0],
		},
	},
	sectionRow: {
		display: "flex",
		alignItems: "center",
		flexWrap: "wrap",
	},
	spacer: {
		flexGrow: "1",
	},
	boardSelect: {
		minWidth: "150px",
		width: "calc(40% - 24px)",
		boxSizing: "border-box",
		paddingRight: "12px",
		[theme.breakpoints.down("md")]: {
			width: "calc(100% - 48px)",
		},
	},
	boardLabel: {
		minWidth: "150px",
		width: "calc(40% - 24px)",
		[theme.breakpoints.down("md")]: {
			width: "calc(100% - 48px)",
		},
	},
	userSmallLabel: {
		fontSize: "12px",
		textAlign: "center",
	},
	selectedBoards: {
		"& ol": {
			margin: "0",
			padding: "0",
			listStyleType: "none",
			"& li": {
				margin: "0",
			},
		},
	},
	boardRow: {
		minHeight: "32px",
	},
	boardIcon: {
		height: "24px",
		marginRight: "8px",
		cursor: "pointer",
	},
	boardName: {
		width: "calc(40% - 24px - 32px)",
		cursor: "pointer",
		wordBreak: "break-word",
		[theme.breakpoints.down("md")]: {
			width: "calc(100% - 48px - 32px)",
		},
	},
	boardUserRow: {
		minHeight: "48px",
	},
	userAvatar: {
		marginRight: "16px",
	},
	userName: {
		width: "calc(40% - 24px - 56px)",
		boxSizing: "border-box",
		paddingRight: "12px",
		wordBreak: "break-word",
		opacity: "0.6",
		[theme.breakpoints.down("md")]: {
			width: "calc(100% - 48px - 56px)",
		},
	},
	errorMessage: {
		color: errorColor,
		fontSize: "12px",
		fontWeight: "400",
		lineHeight: "1.33",
		width: "100%",
	},
	noSelection: {
		fontSize: "12px",
		display: "flex",
		alignItems: "center",
		height: "48px",
		textAlign: "center",
		"& > div": {
			flexGrow: "1",
		},
	},
	sendPasswordSetupRequest: {
		marginRight: "8px",
		[theme.breakpoints.down("md")]: {
			marginBottom: "8px",
		},
	},
	status: {
		...typographyStyle.status,
		whiteSpace: "nowrap",
		borderRadius: "4px",
		padding: "4px 8px 4px 8px",
		margin: "0 8px",
		height: "24px",
		verticalAlign: "middle",
		textAlign: "center",
		display: "inline-block",
		boxSizing: "border-box",
		[`&.${STATUS_GOOD}`]: {
			backgroundColor: successColor,
			color: whiteColor,
		},
	},
}));
const useAccordionStyles = makeStyles(accordionStyle);

const defaultBoard = {
	board: 0,
	expanded: [],
};

const User = (props) => {
	const {
		user = {},
		copyId,
		departments,
		boards,
		users,
		errors = {},
		saveAttempted = false,
		handleUpdate,
		handleCopy,
		handleDelete,
		handleValidatePhoneNumber,
		handleSendPasswordSetup,
		passwordSetupSent,
		telemetryPage,
	} = props;
	const widthDownSm = useWidthDown("sm");
	const mobile = useWidthDown("md");
	const { t } = useTranslation("users");
	const [anchor, setAnchor] = useState(null);
	const [selections, setSelections] = useState({
		...defaultBoard,
	});
	const { lite, policyEnabled, communityMeetings } = useContext(SettingsContext);
	const classes = useStyles();
	const inputClasses = useInputStyles({ fullWidth: true });
	const accordionClasses = useAccordionStyles();
	const phoneRef = useRef(null);
	const [phoneCountry, setPhoneCountry] = useState(null);

	const updateSelections = useUpdateObject(setSelections);

	const handleToggleMenu = (e) => {
		setAnchor((prev) => (prev ? null : e.currentTarget));
	};

	const handleMenuClose = () => {
		setAnchor(null);
	};

	const handleDrop = useCallback((acceptedFiles) => {
		const reader = new FileReader();

		reader.onabort = () => console.log("file reading was aborted");
		reader.onerror = () => console.log("file reading has failed");
		reader.onload = () => {
			processProfileImage(reader.result, (image) => handleUpdate({ target: { value: image } }, "profileImageUrl"));
		};

		acceptedFiles.forEach((file) => reader.readAsDataURL(file));
	}, []);

	const handleItemEditorChange = (_event, editor, field) => {
		handleUpdate({ target: { value: editor.getData() } }, field);
	};

	const flagUpdatedBoard = (boardId) => {
		handleUpdate(
			{
				target: {
					value: boardId,
				},
			},
			"updatedBoards",
			false,
			false,
			undefined,
			(newValue, prevValue) =>
				!prevValue || !prevValue.find((prevBoard) => prevBoard === newValue) ? (prevValue || []).concat([newValue]) : prevValue,
		);
	};

	const addBoard = () => {
		if (selections.board > 0) {
			handleUpdate(
				{
					target: {
						value: {
							userId: user.id,
							member: false,
							staff: false,
							administrator: false,
							votingMember: false,
							tieBreaker: false,
							showOnPortal: false,
						},
					},
				},
				"users",
				false,
				false,
				boards.find((board) => board.id === selections.board),
				(newValue, prevValue) =>
					!prevValue.find((prevUser) => prevUser.userId === newValue.userId) ? prevValue.concat([newValue]) : prevValue,
			);
			flagUpdatedBoard(selections.board);

			setSelections((prev) => ({
				...prev,
				board: 0,
			}));
		}
	};

	const getHandleUpdateBoardUser = (boardId) => (e, field, isCheckbox, isNumeric, objectToUpdate, valueTransform, objectTransform) => {
		handleUpdate(e, field, isCheckbox, isNumeric, objectToUpdate, valueTransform, objectTransform);
		flagUpdatedBoard(boardId);
	};

	const handleClearProfileImage = () => {
		handleUpdate({ target: { value: "" } }, "profileImageUrl");
	};

	const removeBoard = (boardId) => {
		handleUpdate(
			{
				target: {
					value: user.id,
				},
			},
			"users",
			false,
			false,
			boards.find((board) => board.id === boardId),
			(newValue, prevValue) => prevValue.filter((prevUser) => prevUser.userId !== newValue),
		);
		flagUpdatedBoard(boardId);
	};

	const toggleBoard = (boardId) => {
		updateSelections(
			{
				target: { value: boardId },
			},
			"expanded",
			false,
			true,
			undefined,
			(newValue, prevValue) =>
				!prevValue.find((prevBoard) => prevBoard === newValue)
					? prevValue.concat([newValue])
					: prevValue.filter((prevBoard) => prevBoard !== newValue),
		);
	};

	const getDepartments = () => {
		const departmentMenuItems = [];
		if (departments && departments.length) {
			departmentMenuItems.push(getNoOptionsMenuItem(t));

			if (departments.length) {
				departments.forEach((department) => {
					departmentMenuItems.push(
						<MenuItem key={`department-${department.id}`} value={department.id} data-cy={`department-${department.id}`}>
							<div>{department.name}</div>
						</MenuItem>,
					);
				});
			}
		} else if (!departments) {
			departmentMenuItems.push(getDropdownProgressIndicator("departments-loading"));
		}

		return departmentMenuItems;
	};

	const getBoards = () => {
		const boardMenuItems = [];

		if (boards) {
			boardMenuItems.push(getNoOptionsMenuItem(t));

			boards
				.filter((board) => !board.users.find((boardUser) => boardUser.userId === user.id))
				.forEach((board) => {
					boardMenuItems.push(
						<MenuItem key={board.id} value={board.id} data-cy={`board-${board.id}`}>
							<div>{board.name}</div>
						</MenuItem>,
					);
				});
		} else {
			boardMenuItems.push(getDropdownProgressIndicator("boards-loading"));
		}

		return boardMenuItems;
	};

	const getRemoveBoardButton = (boardId) => (
		<div>
			<AccessibleIconButton
				iconName="remove"
				iconColor={blackColor[1]}
				tooltipText={t("tooltips.removeBoard")}
				onClick={() => removeBoard(boardId)}
				dataCy="remove-board"
			/>
		</div>
	);

	const { getRootProps, getInputProps } = useDropzone({ onDrop: handleDrop });
	const menuOptions = [];
	if (user.id > 0 && !user.currentUser) {
		menuOptions.push({
			label: t("app:buttons.delete"),
			tooltip: t("tooltips.delete"),
			actionFunction: handleDelete,
			"data-cy": "delete",
		});
	}

	const selectedBoards = (boards || [])
		.filter((board) => board.users.find((boardUser) => boardUser.userId === user.id || (user.id === 0 && boardUser.userId === copyId)))
		.sort((a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0));

	const getUserError = () => <div className={classes.errorMessage}>{t("detail.validation.noSelectedUserRoles")}</div>;

	useEffect(() => {
		if (phoneRef !== null) {
			setPhoneCountry(phoneRef.current.state.selectedCountry);
		}
	}, [phoneRef, user.phoneNumber]);

	return (
		<>
			{anchor && (
				<NonModalMenu
					id="user-actions"
					className="overflow-menu"
					anchorEl={anchor}
					keepMounted
					open={Boolean(anchor)}
					onClose={handleMenuClose}
				>
					{menuOptions.map((option) => (
						<Tooltip
							PopperProps={{ disablePortal: true }}
							placement="left"
							title={option.tooltip}
							key={`user-menu-options-${option.label}`}
						>
							<span>
								<MenuItem disabled={false} onClick={option.actionFunction} data-cy={`user-menu-option-${option["data-cy"]}`}>
									{option.label}
								</MenuItem>
							</span>
						</Tooltip>
					))}
				</NonModalMenu>
			)}
			<div className={classes.section}>
				<div className={classes.header}>
					{!mobile ? (
						<>
							<div className={clsx(classes.headerTitle, classes.inputLabelSpacing)}>
								<InputLabel
									style={STYLE_SENTENCE_CASE}
									size={SIZE_LARGE}
									role={ROLE_HEADING}
									ariaLevel="2"
									label={t("detail.personInformation")}
								/>
							</div>
							<div>
								<FormControlLabel
									control={
										<Checkbox
											className={classes.checkboxFeature}
											checkedIcon={<Check fontSize="small" color="primary" />}
											checked={user.active}
											onChange={(e) => {
												telemetryAddEvent(`${telemetryPage} - Set user ${e.target.checked ? "Active" : "Inactive"}`, { area: "users" });

												handleUpdate(e, "active", true);
											}}
											disabled={user.currentUser}
										/>
									}
									label={t("detail.active")}
									data-cy="active"
								/>
							</div>
						</>
					) : (
						<div className={classes.headerTitle} />
					)}
					{user.id > 0 && (
						<div>
							{passwordSetupSent ? (
								<span className={clsx(classes.status, STATUS_GOOD)}>{t(`detail.passwordRequestSent`)}</span>
							) : (
								<div style={{ display: "inline-flex", flexFlow: "column" }}>
									<ButtonWithTooltip
										className={clsx(classes.sendPasswordSetupRequest, { [classes.button]: !widthDownSm })}
										title={t("tooltips.sendPasswordSetupRequest")}
										size={MEDIUM}
										variant="outlined"
										color="primary"
										onClick={handleSendPasswordSetup}
										data-cy="sendPasswordSetupRequest"
									>
										{t("buttons.sendPasswordSetupRequest")}
									</ButtonWithTooltip>
									{errors.emailAddress && (
										<Typography variant="caption" color="error">
											{t("detail.validation.emailAddress")}
										</Typography>
									)}
								</div>
							)}
							<ButtonWithTooltip
								className={clsx({ [classes.button]: !widthDownSm })}
								title={t("tooltips.copy")}
								size={MEDIUM}
								variant="outlined"
								color="primary"
								onClick={handleCopy}
								data-cy="copy"
							>
								{t("buttons.copy")}
							</ButtonWithTooltip>
						</div>
					)}
					{menuOptions.length > 0 && (
						<div>
							<AccessibleIconButton
								color="inherit"
								aria-label={t("app:menu.options")}
								onClick={handleToggleMenu}
								aria-expanded={anchor ? "true" : undefined}
								aria-haspopup="true"
								iconName="more"
								dataCy="user-overflow-menu"
								tooltipText={t("app:menu.options")}
							/>
						</div>
					)}
				</div>
				<div id="details" className={classes.columns}>
					<div>
						{mobile && (
							<div className={clsx(classes.headerTitle, classes.inputLabelSpacing)}>
								<InputLabel
									style={STYLE_SENTENCE_CASE}
									size={SIZE_LARGE}
									role={ROLE_HEADING}
									ariaLevel="2"
									label={t("detail.personInformation")}
								/>
							</div>
						)}
						<div>
							<OutlinedInput
								id="first-name"
								className={classes.outlinedInput}
								label={getRequiredLabel(t, t("detail.firstName"))}
								value={user.firstName}
								onChange={(e) => handleUpdate(e, "firstName")}
								{...getErrorProps(errors, "firstName")}
								noDefaultClassName
								fullWidth
								autoComplete="on"
								size="small"
								data-cy="first-name"
							/>
						</div>
						<div>
							<OutlinedInput
								id="last-name"
								className={classes.outlinedInput}
								label={getRequiredLabel(t, t("detail.lastName"))}
								value={user.lastName}
								onChange={(e) => handleUpdate(e, "lastName")}
								{...getErrorProps(errors, "lastName")}
								noDefaultClassName
								fullWidth
								autoComplete="on"
								size="small"
								data-cy="last-name"
							/>
						</div>
						<div>
							<OutlinedInput
								id="u-n"
								className={classes.outlinedInput}
								label={getRequiredLabel(t, t("detail.userName"))}
								value={user.userName}
								onChange={(e) => handleUpdate(e, "userName")}
								{...getErrorProps(errors, "userName")}
								noDefaultClassName
								fullWidth
								size="small"
								data-cy="user-name"
							/>
						</div>
						<div>
							<OutlinedInput
								id="title"
								className={classes.outlinedInput}
								label={t("detail.title")}
								value={user.title}
								onChange={(e) => handleUpdate(e, "title")}
								{...getErrorProps(errors, "title")}
								noDefaultClassName
								fullWidth
								size="small"
								data-cy="title"
							/>
						</div>
						<div>
							<OutlinedInput
								id="email-address"
								className={classes.outlinedInput}
								label={getRequiredLabel(t, t("detail.emailAddress"))}
								value={user.emailAddress}
								onChange={(e) => handleUpdate(e, "emailAddress")}
								{...getErrorProps(errors, "emailAddress")}
								noDefaultClassName
								fullWidth
								size="small"
								autoComplete="on"
								data-cy="email-address"
							/>
						</div>
					</div>
					<div>
						{mobile && (
							<div>
								<FormControlLabel
									control={
										<Checkbox
											checkedIcon={<Check fontSize="small" color="primary" />}
											checked={user.active}
											onChange={(e) => handleUpdate(e, "active", true)}
											disabled={user.currentUser}
										/>
									}
									label={t("detail.active")}
									data-cy="active"
								/>
							</div>
						)}
						<div className={classes.profilePictureSection}>
							<div className={classes.inputLabelSpacing}>
								<InputLabel role="heading" ariaLevel={3} style={STYLE_SENTENCE_CASE} size={SIZE_LARGE} label={t("detail.profilePicture")} />
							</div>
							<div className={clsx(classes.profilePictureAvatar, classes.avatarComponent)} {...getRootProps()}>
								<GenericAvatar
									className={user.profileImageUrl ? classes.userDetailsAvatar : undefined}
									imageUrl={user.profileImageUrl}
									name={user.name}
									initials={`${(user.firstName || "").substr(0, 1)}${(user.lastName || "").substr(0, 1)}`}
								/>
							</div>
							{user.profileImageUrl ? (
								<div>
									<AccessibleIconButton
										name="clear-profile-image"
										iconName="trash"
										aria-label={t("tooltips.clearProfileImage")}
										tooltipText={t("tooltips.clearProfileImage")}
										onClick={handleClearProfileImage}
										dataCy="clear-profile-image"
									/>
								</div>
							) : null}
						</div>
						{/* <div>
							<SelectInput
								id="department"
								className={clsx(inputClasses.smallInput, classes.outlinedInput)}
								noDefaultClassName
								label={t("detail.department")}
								fullWidth
								size="small"
								value={user.department}
								onChange={(e) => handleUpdate(e, "department", undefined, true)}
								data-cy="department"
							>
								{getDepartments()}
							</SelectInput>
						</div> */}
						<div>
							<StyledPhoneInput
								ref={phoneRef}
								label={t("detail.phoneNumber")}
								phoneValue={user.phoneNumber}
								onChange={(e) => {
									handleUpdate(e, "phoneNumber");
									// Updates formatted phone number
									handleUpdate({ target: { value: e.target.formattedValue } }, "formattedPhoneNumber");
								}}
								phoneCountry={phoneCountry}
								dataCy="phone-number"
								validateCountry
								onValidate={handleValidatePhoneNumber}
								errorMsg={t("userProfile:errors.invalidNumber")}
							/>
						</div>
						<div>
							<OutlinedInput
								id="notes"
								className={clsx(classes.outlinedInput, classes.notes)}
								label={t("detail.notes")}
								value={user.notes}
								onChange={(e) => handleUpdate(e, "notes")}
								{...getErrorProps(errors, "notes")}
								noDefaultClassName
								fullWidth
								multiline
								rows={7}
								data-cy="notes"
							/>
						</div>
					</div>
				</div>
				<div className={classes.columns}>
					<div>
						<div>
							<FormControlLabel
								control={
									<Checkbox
										checkedIcon={<Check fontSize="small" color="primary" />}
										checked={user.portalAdmin}
										onChange={(e) => {
											telemetryAddEvent(`${telemetryPage} - Portal admin ${e.target.checked ? "On" : "Off"}`, { area: "users" });

											handleUpdate(e, "portalAdmin", true);
										}}
									/>
								}
								label={t("detail.portalAdmin")}
								data-cy="portal-admin"
							/>
						</div>
						<div>
							<FormControlLabel
								control={
									<Checkbox
										checkedIcon={<Check fontSize="small" color="primary" />}
										checked={user.userAdmin}
										onChange={(e) => {
											telemetryAddEvent(`${telemetryPage} - User admin ${e.target.checked ? "On" : "Off"}`, { area: "users" });

											handleUpdate(e, "userAdmin", true);
										}}
									/>
								}
								label={t("detail.userAdmin")}
								data-cy="user-admin"
							/>
						</div>
						<div>
							<FormControlLabel
								control={
									<Checkbox
										checkedIcon={<Check fontSize="small" color="primary" />}
										checked={user.permanentlyDelete}
										onChange={(e) => {
											telemetryAddEvent(`${telemetryPage} - Library - Permanently delete ${e.target.checked ? "On" : "Off"}`, {
												area: "users",
											});

											handleUpdate(e, "permanentlyDelete", true);
										}}
									/>
								}
								label={t("detail.permanentlyDelete")}
								data-cy="permanently-delete"
							/>
						</div>
					</div>
				</div>
			</div>
			{communityMeetings && !lite.enabled && (
				<NotificationSettingsSection user={user} handleUpdate={handleUpdate} telemetryPage={telemetryPage} />
			)}
			{communityMeetings && (
				<div className={clsx(classes.section, classes.sectionAccordion)}>
					<Accordion
						classes={accordionClasses}
						label={t("detail.publicProfileInformation")}
						scrollContentIntoView
						overflow={false}
						startOpen={window.location?.search?.includes("info")}
						dataCy="public-profile"
					>
						<div id="public-profile" className={classes.publicProfileSection}>
							<div className={classes.publicProfilePicture} {...getRootProps()}>
								{user.profileImageUrl ? (
									<img className={classes.profilePictureLarge} src={user.profileImageUrl} alt={user.name} data-cy="profile-image-large" />
								) : (
									<div className={classes.profilePictureLargeDropZone} data-cy="profile-image-large-dropzone">
										<span>{t("app:dragImageFileOrClickToSelect")}</span>
									</div>
								)}
								<input id="profile-picture-large" aria-label={t("detail.updateProfileImage")} {...getInputProps()} />
							</div>
							<div className={classes.publicProfileAbout}>
								<GenericEditor
									key={`about-${user.id}`}
									toolbar="scratchpad"
									name="about"
									title={t("detail.about")}
									labelSize="large"
									onChange={handleItemEditorChange}
									content={user.about}
									features={[]}
									mt={0}
								/>
								{user.boardsCommittees.length > 0 &&
									user.boardsCommittees.filter((boardCommittee) => boardCommittee.include).length > 0 && (
										<div>
											<div className={classes.inputLabelSpacing}>
												<InputLabel htmlFor="public-profile" size="large" label={t("detail.boardsCommittees")} />
											</div>
											<div className={classes.boardsCommittees}>
												<ul data-cy="boards-committees">
													{user.boardsCommittees
														.filter((boardCommittee) => boardCommittee.include)
														.map((boardCommittee) => (
															<li key={boardCommittee.id}>{boardCommittee.name}</li>
														))}
												</ul>
											</div>
										</div>
									)}
							</div>
						</div>
					</Accordion>
				</div>
			)}
			{communityMeetings && (
				<div className={clsx(classes.section, classes.sectionAccordion)}>
					<Accordion
						classes={accordionClasses}
						label={t("detail.meetingGroups")}
						scrollContentIntoView
						overflow={false}
						triggerOpen={!!errors.invalidBoards && saveAttempted ? Date.now() : undefined}
						dataCy="meeting-group-membership"
					>
						<div id="meeting-group-membership">
							<div className={classes.sectionRow}>
								<div className={classes.boardSelect}>
									<SelectInput
										id="boards"
										className={inputClasses.smallInput}
										noDefaultClassName
										label={t("detail.selectBoard")}
										size="small"
										value={selections.board}
										onChange={(e) => updateSelections(e, "board", false, true)}
										data-cy="boards"
									>
										{getBoards()}
									</SelectInput>
								</div>
								<div>
									<ButtonWithTooltip
										className={clsx({ [classes.button]: !mobile })}
										title={t("tooltips.addBoard")}
										size={MEDIUM}
										variant="outlined"
										color="primary"
										onClick={addBoard}
										disabled={selections.board === 0}
										data-cy="add-board"
									>
										{t("buttons.addBoard")}
									</ButtonWithTooltip>
								</div>
							</div>
							{selectedBoards.length > 0 ? (
								<div className={classes.selectedBoards}>
									{!mobile && (
										<div className={clsx(classes.sectionRow, classes.userLabels)}>
											<div className={classes.boardLabel} />
											<BoardUserOptionLabels />
										</div>
									)}
									<ol data-cy="selected-boards">
										{selectedBoards.map((board) => {
											const open = selections.expanded.includes(board.id);
											const thisBoardUser = board.users.find(
												(boardUser) => boardUser.userId === user.id || (user.id === 0 && boardUser.userId === copyId),
											);
											const showErrror =
												!thisBoardUser.member && !thisBoardUser.staff && !thisBoardUser.administrator && errors.invalidBoards;
											const handleBoardClick = () => toggleBoard(board.id);
											const otherUsers = open
												? board.users
														.filter((boardUser) => boardUser.userId !== user.id)
														.map((boardUser) => ({
															boardUser,
															user: users.find((user) => user.id === boardUser.userId),
														}))
														.filter((userData) => userData.user)
														.sort((a, b) => (a.boardUser.order < b.boardUser.order ? -1 : a.boardUser.order > b.boardUser.order ? 1 : 0))
												: [];

											return (
												<li key={board.id}>
													<div className={clsx(classes.sectionRow, classes.boardRow)}>
														<div className={classes.boardIcon} onClick={handleBoardClick}>
															<Icon name={open ? "expand-down" : "expand-right"} />
														</div>
														<div className={classes.boardName} onClick={handleBoardClick}>
															{board.name} - {user.firstName} {user.lastName}
														</div>
														{mobile && getRemoveBoardButton(board.id)}
														{showErrror && mobile && getUserError()}
														<BoardUserOptions boardUser={thisBoardUser} handleUpdate={getHandleUpdateBoardUser(board.id)} />
														{!mobile && <div className={classes.spacer} />}
														{!mobile && getRemoveBoardButton(board.id)}
														{showErrror && !mobile && getUserError()}
													</div>
													{open && otherUsers.length > 0 && (
														<div>
															<ol data-cy={`board-users-${board.id}`}>
																{otherUsers.map((userData) => {
																	const { boardUser, user } = userData;

																	return (
																		<li key={user.id} className={clsx(classes.sectionRow, classes.boardUserRow)}>
																			<div className={classes.userAvatar}>
																				<GenericAvatar
																					imageUrl={user.profileImageUrl}
																					name={user.name}
																					initials={`${(user.firstName || "").substr(0, 1)}${(user.lastName || "").substr(0, 1)}`}
																					backgroundNumber={((user.number || 0) % GENERIC_AVATAR_BACKGROUNDS) + 1}
																					disabled
																				/>
																			</div>
																			<div className={classes.userName}>
																				{user.deleted || user.inactive || user.external
																					? t(`app:entities.${user.deleted ? "deleted" : user.external ? "external" : "inactive"}`, {
																							entity: user.name,
																						})
																					: user.name}
																			</div>
																			<BoardUserOptions boardUser={boardUser} />
																		</li>
																	);
																})}
															</ol>
														</div>
													)}
												</li>
											);
										})}
									</ol>
								</div>
							) : (
								<div className={classes.noSelection}>
									<div>{t("detail.validation.noSelectedBoards")}</div>
								</div>
							)}
						</div>
					</Accordion>
				</div>
			)}

			{communityMeetings && (
				<div className={clsx(classes.section, classes.sectionAccordion)}>
					<DocumentLibrarySection copyId={copyId} user={user} users={users} handleUpdate={handleUpdate} telemetryPage={telemetryPage} />
				</div>
			)}
			{policyEnabled && (
				<div className={clsx(classes.section, classes.sectionAccordion)}>
					<PolicySettingsSection copyId={copyId} user={user} users={users} handleUpdate={handleUpdate} telemetryPage={telemetryPage} />
				</div>
			)}
		</>
	);
};

export default User;
