// Default Typography
const defaultFont = {
	fontFamily: '"Source Sans Pro", "Verdana", "Helvetica", "Arial", "sans-serif"',
};

// Colors  - Add colors as you need them, just remember to put them in the proper array if one exists already
const blackColor = ['#000', '#1e1e1e', '#212121', '#292929', '#3c3c3c', '#2e2e2e', '#484545'];
const grayColor = ['#676767', '#949494', '#b3b3b3', '#cdd7e5', '#e6e6e6', '#d8d8d8', '#c4c4c4'];
const whiteColor = '#FFF';
const primaryColor = ['#2f3b4d', '#385f99', '#cdd7e5', '#5d7599', '#455772', '#ebeef2', '#37465b', '#202935', '#d7dde6', '#af292e'];
const errorColor = '#af292e';
const warningColor = '#eaa14b';
const infoColor = '#5d7599';
const successColor = '#05704b';
const focusColor = '#3e95fa';
const backgroundColor = ['#f4f6f8', '#ffffff', 'rgba(255, 255, 255, 0.2)', '#757575'];
const breadcrumbColor = ["#385F99", '#1e1e1e', '#242424'];
const disableColor = '#B3B3B3';
const disableBackgroundColor = ' #E6E6E6';
const linkColor = ['#294772','#2F476F'];
const pendingColor =  '#385F99';
const internalColor = "#04704B"
export {
	defaultFont,
	blackColor,
	grayColor,
	whiteColor,
	primaryColor,
	errorColor,
	warningColor,
	infoColor,
	successColor,
	focusColor,
	backgroundColor,
	breadcrumbColor,
	disableColor,
	disableBackgroundColor,
	linkColor,
	pendingColor,
	internalColor
};
