import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

import makeStyles from "@mui/styles/makeStyles";

import processHtml from "utils/processHtml";
import LiveMeetingRadioButton from "./LiveMeetingRadioButton";

import agendaStyle from "../../../assets/jss/components/agendaStyle";

const useStyle = makeStyles(agendaStyle);

const LiveMeetingRequestToSpeak = (props) => {
	const {
		requestToSpeak,
		isMemberOnlyHeading,
		isConsentHeading,
		isPublicCommentHeading,
		addBottomBorder,
		selected,
		handleSelect,
		presentation,
		isMinutesV2 = false,
	} = props;
	const classes = useStyle({ addTopBorder: true });

	const ORDER_TOKEN = "-order-";
	const fullId = requestToSpeak.guid + ORDER_TOKEN + requestToSpeak.order;
	const isSelected = selected === fullId;
	const addBottomBorderToThis = addBottomBorder;
	const name = `${requestToSpeak.firstName} ${requestToSpeak.lastName}`;

	return (
		!requestToSpeak.deleted && (
			<>
				<li
					id={`agenda-${requestToSpeak.guid}`}
					className={clsx(classes.agendaListItem, {
						[classes.radioListItem]: !presentation && !isMinutesV2,
						[classes.borderDefault]: !isMemberOnlyHeading && !isConsentHeading && !isPublicCommentHeading && !addBottomBorderToThis,
						[classes.borderDefaultBottomBorder]:
							!isMemberOnlyHeading && !isConsentHeading && !isPublicCommentHeading && addBottomBorderToThis,
						[classes.borderMemberOnly]: isMemberOnlyHeading,
						[classes.borderConsent]: isConsentHeading,
						[classes.borderPublicComment]: isPublicCommentHeading,
						[classes.selected]: isSelected && !presentation,
						[classes.presentationSelected]: isSelected && presentation,
					})}
					data-cy={`agenda-${requestToSpeak.guid}`}
				>
					{!presentation && <LiveMeetingRadioButton value={fullId} label={name} selected={isSelected} handleSelect={handleSelect} />}
					<div
						className={clsx(classes.item, classes.itemItem, classes.rightBorder, {
							[classes.bottomBorder]: addBottomBorderToThis,
						})}
					>
						<div className={classes.itemText}>
							<div>{name}</div>
							{!presentation && (
								<div>{processHtml(requestToSpeak.topic, { disableLinks: true, classes: { link: classes.disabledLink } })}</div>
							)}
						</div>
					</div>
				</li>
			</>
		)
	);
};

LiveMeetingRequestToSpeak.propTypes = {
	requestToSpeak: PropTypes.object.isRequired,
	isMemberOnlyHeading: PropTypes.bool,
	isConsentHeading: PropTypes.bool,
	isPublicCommentHeading: PropTypes.bool,
	addBottomBorder: PropTypes.bool,
	selected: PropTypes.string.isRequired,
	handleSelect: PropTypes.func,
	presentation: PropTypes.bool,
};

LiveMeetingRequestToSpeak.defaultProps = {
	isMemberOnlyHeading: false,
	isConsentHeading: false,
	isPublicCommentHeading: false,
	addBottomBorder: false,
	handleSelect: undefined,
	presentation: false,
};

export default LiveMeetingRequestToSpeak;
