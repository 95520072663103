import React from "react";
import SvgIcon from "@mui/material/SvgIcon";

import themeConfig from "../../atlas/assets/jss/theme";
import getOr from "lodash/fp/getOr";

const icon = (props) => {
	const { color } = props;
	const fillColor = color ? getOr(color, ["palette", color, "main"], themeConfig) : "currentColor";

	return (
		<SvgIcon height="26" width="26" viewBox="0 0 26 26" {...props}>
			<g
				fill="none"
				fillRule="evenodd"
				stroke={fillColor}
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="2"
				transform="translate(1 1)"
			>
				<circle cx="12" cy="12" r="12" />
				<path d="M12,5.6 L12,18.4 M18.4,12 L5.6,12" />
			</g>
		</SvgIcon>
	);
};

export default icon;
