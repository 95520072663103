import { grayColor } from "atlas/assets/jss/shared";

const userPermissionOptionsStyle = (theme) => ({
	sectionRow: {
		display: "flex",
		alignItems: "center",
		flexWrap: "wrap",
	},
	folderLabel: {
		minWidth: "150px",
		width: "calc(40% - 24px)",
		[theme.breakpoints.down("md")]: {
			width: "calc(100% - 48px)",
		},
	},
	folders: {
		margin: "8px 0",
		"& ol": {
			margin: "0",
			padding: "0",
			listStyleType: "none",
			"& li": {
				margin: "0",
			},
		},
	},
	folderRow: {
		minHeight: "32px",
	},
	folderIcon: {
		height: "24px",
		marginRight: "8px",
		cursor: "pointer",
	},
	folderName: {
		width: "calc(40% - 24px - 32px)",
		[theme.breakpoints.down("md")]: {
			width: "calc(100% - 48px - 32px)",
		},
		cursor: "pointer",
		wordBreak: "break-word",
	},
	folderPermissionRow: {
		minHeight: "48px",
	},
	userAvatar: {
		marginRight: "16px",
		opacity: "0.6",
	},
	userName: {
		width: "calc(40% - 24px - 56px)",
		[theme.breakpoints.down("md")]: {
			width: "calc(100% - 48px - 56px)",
		},
		boxSizing: "border-box",
		paddingRight: "12px",
		wordBreak: "break-word",
		opacity: "0.6",
	},
});

export default userPermissionOptionsStyle;
