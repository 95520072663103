import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AutoSizer, CellMeasurer, CellMeasurerCache, InfiniteLoader, List } from "react-virtualized";

import makeStyles from "@mui/styles/makeStyles";

import styles from "atlas/assets/jss/components/menuStyle";
import NonModalMenu from "atlas/components/Menu/NonModalMenu";
import CircularProgressIndicator from "atlas/components/Progress/CircularProgressIndicator";
import DocumentDetailsHistoryEntry from "./DocumentDetailsHistoryEntry";

const useStyles = makeStyles({
	history: {
		flexGrow: "1",
	},
	menu: {
		...styles.menu,
	},
});

const DocumentDetailsHistory = (props) => {
	const { document, history, loadMore, toggleFieldChanges, handleView, handleRestore } = props;
	const { t } = useTranslation("documents");
	const [anchor, setAnchor] = useState(null);
	const classes = useStyles();
	const cache = new CellMeasurerCache({
		defaultHeight: 60,
		fixedWidth: true,
	});

	const handleToggleMenu = (e, changeSet) =>
		setAnchor({
			element: e.currentTarget,
			changeSet,
		});

	const closeMenu = () => setAnchor(null);

	const isRowLoaded = ({ index }) => history && history.changeSets && !!history.changeSets[index];

	const rowRenderer = (rowProps) => {
		const { key, index, parent, style } = rowProps;
		const changeSet = history.changeSets[index];

		return (
			<CellMeasurer key={key} cache={cache} columnIndex={0} parent={parent} rowIndex={index}>
				<div style={style} role="row" data-cy={`history-change-set`}>
					<div role="cell">
						<DocumentDetailsHistoryEntry
							document={document}
							changeSet={changeSet}
							showRestore={index > 0}
							toggleFieldChanges={toggleFieldChanges}
							handleToggleMenu={(e) => handleToggleMenu(e, changeSet)}
						/>
					</div>
				</div>
			</CellMeasurer>
		);
	};

	const getMenuOptions = (changeSet) => [
		{
			label: t("app:menu.view"),
			actionFunction: () => handleView(changeSet),
		},
		{
			label: t("app:menu.restore"),
			actionFunction: () => handleRestore(changeSet),
		},
	];

	useEffect(() => {
		if (!history) {
			loadMore({ startIndex: 0 });
		}
	}, [history]);

	return history ? (
		<div className={classes.history}>
			<InfiniteLoader isRowLoaded={isRowLoaded} loadMoreRows={loadMore} rowCount={history.total} minimumBatchSize={25}>
				{({ onRowsRendered, registerChild }) => (
					<AutoSizer>
						{({ height, width }) => (
							<List
								ref={registerChild}
								onRowsRendered={onRowsRendered}
								rowRenderer={rowRenderer}
								rowCount={history.changeSets.length}
								rowHeight={cache.rowHeight}
								height={height}
								width={width}
								deferredMeasurementCache={cache}
								aria-label={t("detail.labels.historyList")}
							/>
						)}
					</AutoSizer>
				)}
			</InfiniteLoader>
			{anchor && (
				<NonModalMenu
					id="change-set-menu"
					className={classes.menu}
					anchorEl={anchor.element}
					keepMounted
					open
					onClose={() => closeMenu(anchor.element)}
					options={getMenuOptions(anchor.changeSet)}
					position="bottom-start"
				/>
			)}
		</div>
	) : (
		<CircularProgressIndicator />
	);
};

export default DocumentDetailsHistory;
