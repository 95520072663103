import React from "react";
import { useTranslation } from "react-i18next";

import makeStyles from "@mui/styles/makeStyles";

import Icon from "atlas/components/Icon/Icon";
import GenericDialog from "atlas/components/Dialogs/GenericDialog";

const useStyles = makeStyles(() => ({
	dialog: {
		"& .MuiDialog-paper": {
			width: "360px",
			maxWidth: "100%",
		},
	},
	failed: {
		overflow: "auto",
		maxHeight: "300px",
		"& ul": {
			margin: "16px 0 0 0",
			padding: "0",
			"& > li": {
				listStyleType: "none",
			},
		},
	},
}));

const UploadErrorDialog = (props) => {
	const { show = true, failedUploads, onClose } = props;
	const { t } = useTranslation();
	const classes = useStyles();

	const handleCancel = () => {
		onClose();
	};

	const i18n = t("uploadErrorDialog");

	const dialog = {
		title: i18n.title,
		line1: i18n.line1,
		secondaryTitle: t("app:buttons.close"),
		secondaryAction: handleCancel,
	};

	return (
		<GenericDialog
			className={classes.dialog}
			show={show}
			title={dialog.title}
			primaryAction={dialog.secondaryAction}
			primaryTitle={dialog.secondaryTitle}
			closeIcon={<Icon name="close" />}
			closeAction={dialog.secondaryAction}
		>
			<div className={classes.failed}>
				<div>{dialog.line1}</div>
				<ul className={classes.list}>
					{failedUploads.map((failedUpload) => (
						<li key={failedUpload.name}>{failedUpload.name}</li>
					))}
				</ul>
			</div>
		</GenericDialog>
	);
};

export default UploadErrorDialog;
