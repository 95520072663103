import React from "react";

export const StickyNotes = () => (
	<svg width="22" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M0.75 2.25C0.335786 2.25 0 2.58579 0 3V18C0 18.4142 0.335786 18.75 0.75 18.75H6.75V22.125C6.75 22.4224 6.92576 22.6917 7.198 22.8115C7.47025 22.9313 7.78755 22.8788 8.00679 22.6779L12.2917 18.75H23.25C23.6642 18.75 24 18.4142 24 18V3C24 2.58579 23.6642 2.25 23.25 2.25H0.75ZM1.5 17.25V3.75H22.5V17.25H12C11.8124 17.25 11.6315 17.3203 11.4932 17.4471L8.25 20.4201V18C8.25 17.5858 7.91421 17.25 7.5 17.25H1.5ZM5.25 6C4.83579 6 4.5 6.33579 4.5 6.75C4.5 7.16421 4.83579 7.5 5.25 7.5H18.75C19.1642 7.5 19.5 7.16421 19.5 6.75C19.5 6.33579 19.1642 6 18.75 6H5.25ZM5.25 9.75C4.83579 9.75 4.5 10.0858 4.5 10.5C4.5 10.9142 4.83579 11.25 5.25 11.25H18.75C19.1642 11.25 19.5 10.9142 19.5 10.5C19.5 10.0858 19.1642 9.75 18.75 9.75H5.25ZM5.25 13.5C4.83579 13.5 4.5 13.8358 4.5 14.25C4.5 14.6642 4.83579 15 5.25 15H14.25C14.6642 15 15 14.6642 15 14.25C15 13.8358 14.6642 13.5 14.25 13.5H5.25Z"
			fill="white"
		/>
	</svg>
);
