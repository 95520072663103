import { whiteColor, blackColor } from '../shared';

const buttonRippleStyle = () => ({
	root: {},
	child: {
		backgroundColor: blackColor[0],
	},
	rippleVisible: {
		opacity: 0.1,
		transform: 'scale(1.2)',
	},
	'@keyframes mui-ripple-enter': {
		'0%': {
			transform: 'scale(0)',
			opacity: 0.1,
		},
		'100%': {
			transform: 'scale(1)',
			opacity: 0.9,
		},
	},
	'@keyframes pulsate': {
		'0%': {
			transform: 'scale(1)',
		},
		'50%': {
			transform: 'scale(0.85)',
		},
		'100%': {
			transform: 'scale(1)',
		},
	},
});
export const primaryButtonRippleStyle = () => ({
	child: {
		backgroundColor: whiteColor,
	},
	rippleVisible: {
		opacity: 0.2,
		transform: 'scale(1.2)',
	},
	'@keyframes mui-ripple-enter': {
		'0%': {
			transform: 'scale(0)',
			opacity: 0.1,
		},
		'100%': {
			transform: 'scale(1)',
			opacity: 0.9,
		},
	},
	'@keyframes pulsate': {
		'0%': {
			transform: 'scale(1)',
		},
		'50%': {
			transform: 'scale(0.85)',
		},
		'100%': {
			transform: 'scale(1)',
		},
	},
});

export default buttonRippleStyle;
