import React from "react";
import clsx from "clsx";

import makeStyles from "@mui/styles/makeStyles";

import { blackColor, grayColor } from "atlas/assets/jss/shared";
import Tooltip from "atlas/components/Tooltip/Tooltip";
import ApprovalProgressApprovers from "./ApprovalProgressApprovers";
import ApprovalProgressPendingArrow from "./ApprovalProgressPendingArrow";

const useStyles = makeStyles(() => ({
	listItemExpanded: {
		position: "relative",
		padding: "4px",
		borderRadius: "9px",
		border: `1px solid ${grayColor[4]}`,
		boxSizing: "border-box",
	},
	userContainer: {
		display: "inline-block",
	},
	userList: {
		margin: "0",
		padding: "0",
		display: "flex",
		alignItems: "center",
		listStyleType: "none",
		"& > li": {
			display: "inline-block",
			marginRight: "4px",
			"&:last-child": {
				marginRight: "0",
			},
		},
	},
	label: {
		position: "absolute",
		top: "100%",
		left: "0",
		maxWidth: "80px",
		width: "calc(100% + 16px)",
		fontSize: "11px",
		lineHeight: "14px",
		color: blackColor[6],
		overflow: "hidden",
		whiteSpace: "nowrap",
		textOverflow: "ellipsis",
		"& > span": {
			whiteSpace: "nowrap",
		},
	},
}));

const ApprovalProgressBlock = (props) => {
	const { block, usersOnly, expanded } = props;
	const classes = useStyles();

	return (
		block && (
			<>
				{block.showPendingArrow && <ApprovalProgressPendingArrow dataCy={`workflow-block-${block.id}-pending`} />}
				{usersOnly ? (
					<ApprovalProgressApprovers block={block} tooltip expanded={expanded} />
				) : (
					<li
						className={clsx({
							[classes.listItemExpanded]: expanded,
						})}
						data-cy={`workflow-block-${block.id}`}
					>
						{!expanded ? (
							<Tooltip title={block.name}>
								<div className={classes.userContainer}>
									<ol className={classes.userList}>
										<ApprovalProgressApprovers block={block} highlightPending groupInactive />
									</ol>
								</div>
							</Tooltip>
						) : (
							<div className={classes.userContainer}>
								<ol className={classes.userList}>
									<ApprovalProgressApprovers block={block} highlightPending groupInactive tooltip />
								</ol>
							</div>
						)}
						{expanded && (
							<div className={classes.label}>
								<span>{block.name}</span>
							</div>
						)}
					</li>
				)}
			</>
		)
	);
};

export default ApprovalProgressBlock;
