import React, { forwardRef } from "react";
import { useTranslation } from "react-i18next";
import clsx from "clsx";

import makeStyles from "@mui/styles/makeStyles";

import { blackColor } from "atlas/assets/jss/shared";
import dragAndDropStyle from "atlas/assets/jss/components/dragAndDropStyle";
import AccessibleIconButton from "atlas/components/Buttons/AccessibleIconButton";
import DragHandle from "atlas/components/DragAndDrop/DragHandle";
import NonModalMenu from "atlas/components/Menu/NonModalMenu";
import MenuItemWithTooltip from "atlas/components/Menu/MenuItemWithTooltip";
import { useWidthDown } from "atlas/utils/useWidth";
import GenericAvatar, { GENERIC_AVATAR_BACKGROUNDS } from "components/Avatar/GenericAvatar";
import BoardUserOptions from "./BoardUserOptions";

const useDragAndDropStyles = makeStyles(dragAndDropStyle);
const useStyles = makeStyles((theme) => ({
	userDrag: {
		paddingLeft: "32px !important",
		minHeight: "48px",
	},
	dragHandle: {
		left: "12px",
	},
	spacer: {
		flexGrow: "1",
	},
	userAvatar: {
		marginRight: "16px",
	},
	userName: {
		width: "calc(40% - 24px - 56px)",
		boxSizing: "border-box",
		paddingRight: "12px",
		wordBreak: "break-word",
		[theme.breakpoints.down("md")]: {
			width: "calc(40% - 24px - 56px)",
		},
	},
	userListMenu: {
		cursor: "pointer",
	},
}));

const BoardUser = forwardRef((props, ref) => {
	const {
		className,
		boardUser,
		user,
		errors = {},
		editable,
		handleUpdate,
		handleMoveUp,
		handleMoveDown,
		removeUser,
		isFirst,
		isLast,
		anchor,
		handleMenu,
		dragAttributes = {},
		dragListeners = {},
		canDrag,
		dragPlaceholder,
		dragPresentational,
		...otherProps
	} = props;
	const mobile = useWidthDown("md");
	const { t } = useTranslation("boards");
	const dragAndDropClasses = useDragAndDropStyles();
	const classes = useStyles();

	const getUserError = () => <div className={classes.errorMessage}>{t("detail.validation.noSelectedUserRoles")}</div>;

	const menuOptions = [];
	if (!isFirst) {
		menuOptions.push({
			label: t("app:menuOptions.moveUp"),
			tooltip: t("tooltips.moveUserUp"),
			actionFunction: (boardUser) => handleMoveUp(boardUser.userId),
			"data-cy": "move-user-up",
		});
	}
	if (!isLast) {
		menuOptions.push({
			label: t("app:menuOptions.moveDown"),
			tooltip: t("tooltips.moveUserDown"),
			actionFunction: (boardUser) => handleMoveDown(boardUser.userId),
			"data-cy": "move-user-down",
		});
	}
	menuOptions.push({
		label: t("buttons.removeUser"),
		tooltip: t("tooltips.removeUser"),
		actionFunction: (boardUser) => removeUser(boardUser.userId),
		"data-cy": "remove-user",
	});

	const showError = !boardUser.member && !boardUser.staff && !boardUser.administrator && errors.invalidUsers;
	const Component = !dragPresentational ? "li" : "div";

	return (
		<Component
			className={clsx(className, {
				[dragAndDropClasses.dragPlaceholder]: dragPlaceholder,
				[classes.userDrag]: canDrag || dragPresentational,
			})}
			ref={ref}
			{...otherProps}
		>
			<div className={classes.userAvatar} {...dragAttributes} {...dragListeners}>
				{(canDrag || dragPresentational) && <DragHandle className={classes.dragHandle} />}
				<GenericAvatar
					imageUrl={user.profileImageUrl}
					name={user.name}
					initials={`${(user.firstName || "").substr(0, 1)}${(user.lastName || "").substr(0, 1)}`}
					backgroundNumber={((user.number || 0) % GENERIC_AVATAR_BACKGROUNDS) + 1}
				/>
			</div>
			<div className={classes.userName} {...dragAttributes} {...dragListeners}>
				{user.deleted || user.inactive || user.external
					? t(`app:entities.${user.deleted ? "deleted" : user.external ? "external" : "inactive"}`, {
							entity: user.name,
					  })
					: user.name}
			</div>
			{editable ? (
				<>
					{showError && mobile && getUserError()}
					<BoardUserOptions boardUser={boardUser} handleUpdate={handleUpdate}></BoardUserOptions>
					{!mobile && <div className={classes.spacer}></div>}
					{menuOptions && menuOptions.length > 0 && (
						<>
							<div className={classes.userListMenu}>
								<AccessibleIconButton
									iconName="more"
									iconColor={blackColor[1]}
									aria-label={t("app:menu.options")}
									onClick={(e) => {
										e.stopPropagation();

										handleMenu(e, user.id);
									}}
									dataCy={`overflow-menu`}
								/>
							</div>
							{anchor?.userId === user.id && (
								<NonModalMenu
									id="board-user-actions"
									className="overflow-menu"
									anchorEl={anchor.target}
									keepMounted
									open
									onClose={() => handleMenu(null)}
									position="left"
								>
									{menuOptions.map((option) => (
										<MenuItemWithTooltip
											key={option.label}
											tooltip={option.tooltip}
											placement="left"
											onClick={() => option.actionFunction(boardUser)}
											data-cy={`user-menu-option-${option["data-cy"]}`}
										>
											{option.label}
										</MenuItemWithTooltip>
									))}
								</NonModalMenu>
							)}
						</>
					)}
					{showError && !mobile && getUserError()}
				</>
			) : null}
		</Component>
	);
});

export default BoardUser;
