import React from "react";
import { useTranslation } from "react-i18next";

import makeStyles from "@mui/styles/makeStyles";
import { IconButton } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import PropTypes from "prop-types";
import Icon from "atlas/components/Icon/Icon";
import RightPanelContainer from "components/Panels/RightPanelContainer";
import Tooltip from "atlas/components/Tooltip/Tooltip";
import { Close } from "components/Icons";

const useStyles = makeStyles(() => ({
	stage1: {
		width: "500px",
		height: "80px",
		border: "1px solid #E6E6E6",
		boxSizing: "border-box",
		padding: "25px",
	},
	close: {
		position: "absolute",
		top: "18px",
		right: "20px",
	},
	stage2: {
		width: "500px",
		height: "80px",
		border: "1px solid #E6E6E6",
		boxSizing: "border-box",
		padding: "25px",
	},
	aPreview: {
		position: "absolute",
		top: "92px",
		left: "25%",
		textAlign: "center",
		width: "60px",
	},
	aPublish: {
		position: "absolute",
		top: "92px",
		left: "50%",
		textAlign: "center",
		width: "60px",
	},
	stage3: {
		width: "500px",
		height: "80px",
		border: "1px solid #E6E6E6",
		boxSizing: "border-box",
		padding: "25px",
	},
	mPreview: {
		position: "absolute",
		top: "173px",
		left: "25%",
		textAlign: "center",
		width: "60px",
	},
	mPublish: {
		position: "absolute",
		top: "173px",
		left: "50%",
		textAlign: "center",
		width: "60px",
	},
	mAdopted: {
		position: "absolute",
		top: "173px",
		left: "75%",
		textAlign: "center",
		width: "60px",
	},
	stage4: {
		width: "500px",
		height: "80px",
		border: "1px solid #E6E6E6",
		boxSizing: "border-box",
		padding: "25px",
	},
	goalIcon: {
		position: "absolute",
		bottom: "100px",
		left: "25%",
		textAlign: "center",
		width: "60px",
	},
	stage5: {
		width: "500px",
		height: "80px",
		border: "1px solid #E6E6E6",
		boxSizing: "border-box",
		padding: "25px",
	},
	requestIcon: {
		position: "absolute",
		bottom: "22px",
		left: "50%",
		textAlign: "center",
		width: "60px",
	},
}));

const LegendDialog = (props) => {
	const { closeAction, clickAwayDisabled = false, show } = props;
	const { t } = useTranslation("meetings");
	const classes = useStyles();

	const dialog = {
		title: t("app:search.legendDialog.title"),
		row1: t("app:search.legendDialog.row1"),
		row2: t("app:search.legendDialog.row2"),
		row3: t("app:search.legendDialog.row3"),
		row4: t("app:search.legendDialog.row4"),
		icon1: t("app:search.legendDialog.icon1"),
		icon2: t("app:search.legendDialog.icon2"),
		icon3: t("app:search.legendDialog.icon3"),
		icon4: t("app:search.legendDialog.icon4"),
		goal: t("app:search.tooltips.goal"),
		rts: t("app:menu.requestToSpeak"),
		agenda1: t("app:search.tooltips.agendaPreview"),
		agenda2: t("app:search.tooltips.agendaPublished"),
		minutes1: t("app:search.tooltips.minutesPreview"),
		minutes2: t("app:search.tooltips.minutesPublished"),
		minutes3: t("app:search.tooltips.minutesAdopted"),
	};
	const showTitlebarCloseIcon = !clickAwayDisabled;

	return (
		<RightPanelContainer id="right-panel-container" open={show} drawerWidth={488} drawerHeight={400} float>
			<Box className={classes.stage1}>
				<Typography style={{ fontWeight: 600, fontSize: "22px" }}>{dialog.title}</Typography>
				{showTitlebarCloseIcon && (
					<Tooltip title={t("app:tooltips.closeButton")}>
						<IconButton
							className={classes.close}
							disabled={clickAwayDisabled}
							aria-label={t("app:tooltips.closeButton")}
							onClick={closeAction}
						>
							<Close />
						</IconButton>
					</Tooltip>
				)}
			</Box>

			<Box className={classes.stage2}>
				<Typography variant="h4">{dialog.row1}</Typography>
				<div className={classes.aPreview}>
					<Typography variant="body2">{dialog.icon2}</Typography>
					<Tooltip title={dialog.agenda1}>
						<span>
							<Icon name="document-agenda-preview" />
						</span>
					</Tooltip>
				</div>
				<div className={classes.aPublish}>
					<Typography variant="body2">{dialog.icon3}</Typography>
					<Tooltip title={dialog.agenda2}>
						<span>
							<Icon name="document-agenda-published" />
						</span>
					</Tooltip>
				</div>
			</Box>

			<Box className={classes.stage3}>
				<Typography variant="h4">{dialog.row2}</Typography>
				<div className={classes.mPreview}>
					<Typography variant="body2">{dialog.icon2}</Typography>
					<Tooltip title={dialog.minutes1}>
						<span>
							<Icon name="document-minutes-preview" />
						</span>
					</Tooltip>
				</div>
				<div className={classes.mPublish}>
					<Typography variant="body2">{dialog.icon3}</Typography>
					<Tooltip title={dialog.minutes2}>
						<span>
							<Icon name="document-minutes-published" />
						</span>
					</Tooltip>
				</div>
				<div className={classes.mAdopted}>
					<Typography variant="body2">{dialog.icon4}</Typography>
					<Tooltip title={dialog.minutes3}>
						<span>
							<Icon name="document-minutes-adopted" />
						</span>
					</Tooltip>
				</div>
			</Box>

			<Box className={classes.stage4}>
				<Typography variant="h4">{dialog.row3}</Typography>
				<div className={classes.goalIcon}>
					<Tooltip title={dialog.goal}>
						<span>
							<Icon name="goal" size="27px" />
						</span>
					</Tooltip>
				</div>
			</Box>
			<Box className={classes.stage5}>
				<Typography variant="h4">{dialog.row4}</Typography>
				<div className={classes.requestIcon}>
					<Tooltip title={dialog.rts}>
						<span>
							<Icon name="request-to-speak" size="27px" />
						</span>
					</Tooltip>
				</div>
			</Box>
		</RightPanelContainer>
	);
};

LegendDialog.propTypes = {
	closeAction: PropTypes.func,
	secondaryAction: PropTypes.func.isRequired,
	clickAwayDisabled: PropTypes.bool,
};
LegendDialog.defaultProps = {
	closeAction: undefined,
	clickAwayDisabled: false,
};

export default LegendDialog;
