import React, { useState, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import makeStyles from "@mui/styles/makeStyles";
import { AppBar, Toolbar, Box, CircularProgress, Typography } from "@mui/material";
import styles from "atlas/assets/jss/components/topNavBarStyle";
import AccessibleIconButton from "atlas/components/Buttons/AccessibleIconButton";
import ButtonWithTooltip from "atlas/components/Buttons/ButtonWithTooltip";
import NonModalMenu from "atlas/components/Menu/NonModalMenu";
import SendFeedbackDialog from "components/Dialogs/SendFeedbackDialog";
import SupportRequestDialog from "components/Dialogs/SupportRequestDialog";
import { SettingsContext } from "contexts/Settings/SettingsContext";
import { UserContext } from "contexts/User/UserContext";
import telemetryAddEvent from "utils/telemetryAddEvent";
import { formatDate } from "utils/date";
import { DRAFT, ADOPTED, RESCINDED, PUBLISHED, POLICY_REVIEW, POLICY_DRAFT, POLICY_RESCINDED } from "utils/enums/PolicyTypes";
import getPolicyType from "views/Policy/utils/getPolicyType";
import { PublicDocumentContext } from "views/Policy/PolicyContainer";
import Icon from "atlas/components/Icon/Icon";
import { useWidthDown } from "atlas/utils/useWidth";
import { reloadViewPageOfPolicyDocument } from "redux/policyGovernance/actions";

const useStyles = makeStyles(styles);
const temp = makeStyles(() => ({
	draftPublishedTag: {
		color: (props) => (getPolicyType(props?.policy?.policyStatus) === DRAFT ? "#000000" : "#ffffff"),
		backgroundColor: (props) => (getPolicyType(props?.policy?.policyStatus) === DRAFT ? "#EAA14B" : "#1C7606"),
		borderRadius: 4,
		height: 24,
		padding: 4,
		fontSize: 12,
		fontWeight: 700,
		fontFamily: "Source Sans Pro",
		marginRight: "12px",
	},
	statusTypeDate: {
		color: (props) => (props?.policy?.rescindedDate ? "#000000" : "#ffffff"),
		backgroundColor: (props) => (props?.policy?.rescindedDate ? "#B3B3B3" : "#1C7606"),
		borderRadius: 4,
		height: 24,
		padding: 4,
		fontSize: 12,
		fontWeight: 700,
		fontFamily: "Source Sans Pro",
		marginRight: 5,
		marginRight: "12px",
	},
	button: {
		minHeight: "36px",
		height: "auto",
	},
	editButton: {
		display: "flex",
		alignItems: "center",
		gap: "8px",
	},
	wordIcon: {
		paddingTop: "4px",
	},
	prevAndNextButtons: {
		color: "white",
		borderColor: "#455D82",
		padding: "8px 12px 8px 12px",
		width: "72px",
		height: "40px",
	},
}));

const DocumentTopBar = (props) => {
	const {
		handlePrevious,
		telemetryPage,
		editDocument,
		editing,
		handleSaveAndClose,
		savingState,
		handleBack,
		isPolicy = false,
		policy,
		extension,
		handlePreviousforPolicy,
		openSidePanelForPublished,
		id = 0,
		editAgendaCoverInWord,
		getPrevAndNextButtons,
		handleSelectedPolicies,
	} = props;
	const {
		adoptedDate = null,
		revisedDate = null,
		rescindedDate = null,
		dateModified = null,
		canUpdate,
		modifiedBy = "",
		publishedGuid = "",
		policyStatus,
		code = "",
		title,
		draftGuid,
		guid,
	} = policy || {};

	const policyDraftEntry = {
		guid: draftGuid,
		status: POLICY_DRAFT,
	};
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const { t: tDocuments } = useTranslation("documents");
	const navigate = useNavigate();
	const [openHelpMenu, setOpenHelpMenu] = useState(false);
	const [helpAnchor, setHelpAnchor] = useState(null);
	const [showSupportRequestDialog, setShowSupportRequestDialog] = useState(false);
	const [showSendFeebackDialog, setShowSendFeebackDialog] = useState(false);
	const { lite } = useContext(SettingsContext);
	const { boardAdmin, publicUser } = useContext(UserContext).user;
	const ispublicDocument = useContext(PublicDocumentContext);
	const classes = useStyles();
	const styleTags = temp({ policy });
	const { publishedPermissionsData: { publishedWrite } = {} } = useSelector((state) => state.policyGovernanceReducer);
	let draftGuidPolicy = null;
	if (policy && policy.extension === ".wopitest") {
		draftGuidPolicy = policy.guid;
	} else {
		draftGuidPolicy = publishedWrite && policy && isPolicy && draftGuid;
	}
	const datePresent = rescindedDate || adoptedDate || revisedDate;
	const [editPublishedDraft, setEditPublishedDraft] = useState(false);
	const [isMobile, setIsMobile] = useState(false);
	const widthDownMd = useWidthDown("md");

	const handleResize = () => {
		if (widthDownMd) setIsMobile(true);
		else setIsMobile(false);
	};

	const getTagForDate = () => {
		const rescindedTag = rescindedDate !== null;
		return !rescindedTag || policyStatus !== POLICY_RESCINDED ? (
			<span className={styleTags.statusTypeDate}>{rescindedTag ? RESCINDED?.toUpperCase() : ADOPTED.toUpperCase()}</span>
		) : null;
	};

	const getModifiedDates = () => {
		return ` Modified by ${modifiedBy}: ${formatDate(dateModified)}`;
	};

	const openNewTab = (url) => {
		window.open(url);
	};

	const closeMenus = () => {
		setHelpAnchor(null);
	};

	const handleToggleHelpMenu = (e) => {
		closeMenus();
		setHelpAnchor(e.currentTarget);
		setOpenHelpMenu((prevOpen) => !prevOpen);
	};

	const handleCloseHelpMenu = () => {
		closeMenus();
		setOpenHelpMenu(false);
	};

	const openSupportRequestDialog = () => {
		telemetryAddEvent(`${telemetryPage} - Open support`);

		setShowSupportRequestDialog(true);
		closeMenus();
	};

	const closeSupportRequestDialog = () => setShowSupportRequestDialog(false);

	const openSendFeedbackDialog = () => {
		telemetryAddEvent(`${telemetryPage} - Open feedback`);

		setShowSendFeebackDialog(true);
		closeMenus();
	};

	const closeSendFeedbackDialog = () => setShowSendFeebackDialog(false);

	const policyStatusTag = getPolicyType(policyStatus)?.toUpperCase();

	const handleOpenDraftPolicy = () => {
		setEditPublishedDraft(true);
		navigate(`/policydoc/${draftGuidPolicy}`);
		openSidePanelForPublished();
		if (draftGuid) {
			handleSelectedPolicies(policyDraftEntry);
		}
	};

	const getButtonText = () => {
		if (extension === ".docx" || extension === ".doc") {
			return t("app:buttons.editInWord");
		} else if (extension === ".pptx" || extension === ".ppt") {
			return t("app:buttons.editInPowerPoint");
		} else return t("app:buttons.edit");
	};
	const getMicrosoftIcon = () => {
		if (extension === ".ppt" || extension === ".pptx") {
			return <Icon name={"powerPoint-Icon"} />;
		} else if (extension === ".docx" || extension === ".doc") {
			return <Icon name={"microsoft-word-topBar"} />;
		}
	};

	const helpMenuOptions = [
		{
			label: t("menu.help"),
			actionFunction: () => {
				telemetryAddEvent(`${telemetryPage} - Help menu`);

				closeMenus();

				return openNewTab(`/help/${lite.enabled ? "Essentials" : ""}${boardAdmin ? "Admin" : "Member"}Help.pdf`);
			},
			external: true,
			dataCy: "helpMenuOption",
		},
		{
			label: t("menu.support"),
			actionFunction: openSupportRequestDialog,
			dataCy: "contactSupportMenuOption",
		},
		{
			label: t("menu.feedback"),
			actionFunction: openSendFeedbackDialog,
			dataCy: "sendFeedbackMenuOption",
		},
	];
	useEffect(() => {
		setEditPublishedDraft(false);
	}, [id]);

	useEffect(() => {
		handleResize();
	}, [widthDownMd]);

	const detailsForPolicy = {};

	if ((isPolicy && editDocument && getPolicyType(policyStatus) === DRAFT) || editPublishedDraft) {
		(detailsForPolicy["onClickAction"] = () => {
			editDocument(draftGuid ? draftGuid : guid);
			dispatch(reloadViewPageOfPolicyDocument(true));
		}),
			(detailsForPolicy["isDraft"] = true),
			(detailsForPolicy["buttonText"] = t("app:buttons.editDraftInWord"));
	} else if (isPolicy && (getPolicyType(policyStatus) === PUBLISHED || RESCINDED) && canUpdate) {
		(detailsForPolicy["onClickAction"] = () => handleOpenDraftPolicy()),
			(detailsForPolicy["isDraft"] = false),
			(detailsForPolicy["buttonText"] = t("app:buttons.openDraftPolicy"));
	} else if (isPolicy && (getPolicyType(policyStatus) === PUBLISHED || RESCINDED) && !canUpdate) {
		(detailsForPolicy["onClickAction"] = () => navigate(-1)),
			(detailsForPolicy["isDraft"] = false),
			(detailsForPolicy["buttonText"] = t("app:buttons.back"));
	}

	return (
		<>
			<SendFeedbackDialog show={showSendFeebackDialog} onClose={closeSendFeedbackDialog} dispatch={dispatch} />
			<SupportRequestDialog show={showSupportRequestDialog} onClose={closeSupportRequestDialog} />
			<AppBar>
				<Toolbar className={classes.headerToolbar}>
					<Box mr={2}>
						<AccessibleIconButton
							id="back"
							color="inherit"
							aria-label="back"
							onClick={() => (isPolicy ? handlePreviousforPolicy() : handlePrevious())}
							iconName="expand-left"
							dataCy="backButton"
							tooltipText={t("menu.previous")}
							isHeader
						/>
					</Box>
					{!isPolicy && editDocument && !editing && !ispublicDocument && (
						<Box mr={2}>
							<ButtonWithTooltip
								primary
								size="small"
								onClick={() => {
									dispatch(reloadViewPageOfPolicyDocument(true));
									editDocument(id);
								}}
								dataCy="editDocumentAction"
								title={""}
							>
								<div className={styleTags.editButton}>
									<span className={styleTags.wordIcon}>{getMicrosoftIcon()}</span>
									{getButtonText()}
								</div>
							</ButtonWithTooltip>
						</Box>
					)}
					{isPolicy && !ispublicDocument && policyStatus !== POLICY_REVIEW && (
						<Box mr={2}>
							<ButtonWithTooltip
								className={styleTags.button}
								primary
								size="small"
								onClick={detailsForPolicy?.onClickAction}
								dataCy="editDocumentAction"
								title={""}
							>
								<div className={styleTags.editButton}>
									{detailsForPolicy?.isDraft && (
										<span className={styleTags.wordIcon}>
											<Icon name={"microsoft-word-topBar"} />
										</span>
									)}
									{detailsForPolicy?.buttonText}
								</div>
							</ButtonWithTooltip>
						</Box>
					)}
					{editing && handleSaveAndClose && (
						<Box mr={2}>
							<ButtonWithTooltip
								primary
								size="small"
								onClick={() => editAgendaCoverInWord(document.guid)}
								dataCy="saveAndCloseDocumentAction"
								title={""}
							>
								<div className={styleTags.editButton}>
									{
										<span className={styleTags.wordIcon}>
											<Icon name={"microsoft-word-topBar"} />
										</span>
									}
									{t("app:buttons.editInWord")}
								</div>
							</ButtonWithTooltip>
						</Box>
					)}

					{isPolicy && policy && !isMobile && (
						<Box sx={{ flexGrow: 5, textAlign: "center" }}>
							<Typography>
								{!editing && (
									<>
										{policyStatusTag && <span className={styleTags.draftPublishedTag}>{policyStatusTag}</span>}
										{datePresent && getTagForDate()}
									</>
								)}
								{code} {title} {canUpdate && `| ${getModifiedDates()}`}
							</Typography>
						</Box>
					)}
					<div className={classes.spacer}></div>

					{isPolicy && !isMobile && getPrevAndNextButtons}
					{!publicUser && (
						<Box ml={2}>
							<AccessibleIconButton
								id="help"
								aria-label={t("menu.help")}
								onClick={handleToggleHelpMenu}
								iconName="help"
								tooltipText={t("menu.help")}
								ref={helpAnchor}
								dataCy="helpIcon"
								isHeader
							/>
						</Box>
					)}
					{!publicUser && helpAnchor && (
						<NonModalMenu
							id="helpMenu"
							className={classes.menu}
							anchorEl={helpAnchor}
							keepMounted
							open={openHelpMenu}
							onClose={handleCloseHelpMenu}
							options={helpMenuOptions}
							position="bottom-end"
						/>
					)}
				</Toolbar>
			</AppBar>
		</>
	);
};

export default DocumentTopBar;
