import React, { useState, useEffect, useRef, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";
import cookie from "react-cookies";
import request from "superagent";
import clsx from "clsx";
import { v4 as uuid } from "uuid";

import makeStyles from "@mui/styles/makeStyles";

import { API_HOST } from "config/env";
import { formatDate } from "utils/date";
import ComponentContainer from "atlas/components/ComponentContainer/ComponentContainer";
import CircularProgressIndicator from "atlas/components/Progress/CircularProgressIndicator";
import { SettingsContext } from "contexts/Settings/SettingsContext";
import { UserContext } from "contexts/User/UserContext";
import AdoptPublishErrorDialog from "components/Dialogs/AdoptPublishErrorDialog";

import { resetPageConfigs, updatePageConfigs, updateTabs } from "redux/app/actions";
import { updatePageHeader } from "redux/pageHeader/actions";
import PdfViewerNew from "components/PdfViewerNew/PdfViewerNew";
import { deleteAnnotation, loadSignatureAnnotations, saveAnnotation } from "components/PdfViewerNew/PdfAPICalls";

const useStyles = makeStyles(() => ({
	frame: {
		display: "block",
		border: "none",
		height: "calc(100vh - 262px)",
		width: "100%",
	},
	pdfFrame: {
		height: "calc(100vh - 310px)",
		"& iframe": {
			border: "none",
		},
	},
	hidden: {
		visibility: "hidden", // Workaround for PDFTron in firefox
		height: "0 !important",
		width: "0 !important",
	},
}));

const SignDocument = (props) => {
	const tabIndexes = {
		public: 0,
		members: 1,
	};

	const { showSignIn } = props;
	const { t } = useTranslation("meetings");
	const location = useLocation();
	const { id } = useParams();
	const [preview, setPreview] = useState(null);
	const [selected, setSelected] = useState({
		tab: tabIndexes.public,
		id: 0,
	});
	const [pdfViewerSettings, setPdfViewerSettings] = useState(null);
	const [adoptPublishError, setAdoptPublishError] = useState(null);

	const dispatch = useDispatch();
	const classes = useStyles();
	const { lite } = useContext(SettingsContext);
	const { user } = useContext(UserContext);
	const previewRef = useRef();

	previewRef.current = preview;

	const tabChange = (_e, tab) => {
		const { public: { documentId: publicDocumentId = 0 } = {}, members: { documentId: membersDocumentId = 0 } = {} } = previewRef.current;
		if ((tab === tabIndexes.public && publicDocumentId > 0) || (tab === tabIndexes.members && membersDocumentId > 0)) {
			setSelected({
				tab,
				id: tab === tabIndexes.public ? publicDocumentId : membersDocumentId,
			});
		}
	};

	const loadPreview = async (meetingId) => {
		setPreview(null);

		try {
			let response = await request.get(`${API_HOST}/api/meeting/${id}/adopt`);
			const {
				meeting,
				public: { documentId: responsePublicDocumentId = 0 } = {},
				members: { documentId: responseMembersDocumentId = 0 } = {},
			} = response.body;
			let publicDocumentId = responsePublicDocumentId;
			let membersDocumentId = responseMembersDocumentId;
			const query = new URLSearchParams(location.search);
			const liveMeeting =
				window.opener === null || (window.opener && window.opener.location.href.indexOf("/home/meeting/live") > 0)
					? query.get("liveMeeting")
					: null;
			if ((user.boardAdmin || user.systemAdministrator) && publicDocumentId === 0 && membersDocumentId === 0 && liveMeeting > 0) {
				const guid = uuid();
				try {
					await request
						.post(`${API_HOST}/api/meeting/${id}/createdrafttosign`)
						.withCredentials()
						.send({ progressGuid: guid, agenda: false });
					response = await request.get(`${API_HOST}/api/meeting/${id}/adopt`);
					if (response && response.body && response.body.pdf && response.body.pdf.errors && liveMeeting > 0) {
						setAdoptPublishError(liveMeeting);
					}
				} catch {
					if (liveMeeting > 0) {
						setAdoptPublishError(liveMeeting);
					}
				}
				const {
					meeting,
					public: { documentId: createdPublicDocumentId = 0 } = {},
					members: { documentId: createdMembersDocumentId = 0 } = {},
				} = response.body;
				publicDocumentId = createdPublicDocumentId;
				membersDocumentId = createdMembersDocumentId;
			}

			const meetingDate = formatDate(null, meeting.startTime, meeting.endTime, t("app:at"), t("from"), t("to"), false);

			const tabsOptions = [];
			if (publicDocumentId > 0) {
				tabsOptions.push({ key: "public", label: t("tabs.publicPdfView"), dataCy: "public-view" });
			}
			if (membersDocumentId > 0) {
				tabsOptions.push({ key: "members", label: t("tabs.membersPdfView"), dataCy: "members-view" });
			}

			let backUrl = `/meeting/${meetingId}`;
			if (window.opener && window.opener.location) {
				backUrl = window.opener.location.href.replace(`${API_HOST}/home`, "");
			} else if (liveMeeting > 0) {
				backUrl = `/meeting/${(cookie.load("old_minutes") || "false") === "false" ? "liveV2" : "live"}/${liveMeeting}`;
			}

			dispatch(
				updatePageConfigs({
					back: {
						url: backUrl,
					},
					title: t(response.body?.public?.adopted || response.body?.members?.adopted ? "adoptedTitle" : "signAndAdoptTitle", {
						meetingName: meeting.name,
					}),
					telemetryPage: "Sign & adopt",
				}),
			);
			dispatch(
				updatePageHeader({
					additionalText: [meetingDate],
				}),
			);

			dispatch(
				updateTabs({
					display: true,
					selectedTab: selected.tab,
					tabsOptions,
					onChange: (_e, newTab) => {
						dispatch(
							updateTabs({
								selectedTab: newTab,
							}),
						);

						tabChange(_e, newTab);
					},
				}),
			);
			// eslint-disable-next-line no-nested-ternary
			setPreview(response.body);
			setSelected({
				tab: publicDocumentId > 0 ? tabIndexes.public : tabIndexes.members,
				id: publicDocumentId > 0 ? publicDocumentId : membersDocumentId,
			});

			setPdfViewerSettings({
				meetingName: meeting.cleanName,
				meetingDate,
				disableAnnotations: true,
				userName: user.username,
				userId: user.id,
			});
		} catch (err) {
			showSignIn(err, () => {
				loadPreview(meetingId);
			});
			console.log(err);
		}
	};

	useEffect(() => {
		dispatch(resetPageConfigs({}));

		loadPreview(id);
	}, [id]);

	return preview ? (
		<>
			<ComponentContainer padding="0">
				<div
					className={clsx(classes.pdfFrame, {
						[classes.hidden]: selected.tab !== tabIndexes.public,
					})}
				>
					{pdfViewerSettings !== null && preview.public && preview.public.documentId && (
						<PdfViewerNew
							id={preview.public.documentId}
							settings={{
								...pdfViewerSettings,
								meetingId: id,
								url: `/document/${preview.public.documentId}`,
								downloadOpensNewTab: true,
								enableSignatures: !lite.enabled,
								members: false,
								loadAllAnnotationVersions: true,
								adopted: preview.public.adopted,
							}}
							isMeetingDocument
							selectedDocumentId={preview.public.documentId}
							loadAnnotationsAction={loadSignatureAnnotations}
							saveAnnotationAction={saveAnnotation}
							deleteAnnotationAction={deleteAnnotation}
							showSignIn={showSignIn}
							customizationOptions={{
								fullScreenCustom: {
									customWrapper: null,
								},
								downloadCustom: {
									onClickUrl: `/document/${preview.public.documentId}`,
								},
							}}
							headerEndDivider
						/>
					)}
				</div>
				<div
					className={clsx(classes.pdfFrame, {
						[classes.hidden]: selected.tab !== tabIndexes.members,
					})}
				>
					{pdfViewerSettings !== null && preview.members && preview.members.documentId > 0 && (
						<PdfViewerNew
							id={preview.members.documentId}
							settings={{
								...pdfViewerSettings,
								meetingId: id,
								url: `/document/${preview.members.documentId}`,
								downloadOpensNewTab: true,
								enableSignatures: !lite.enabled,
								members: true,
								loadAllAnnotationVersions: true,
								adopted: preview.members.adopted,
							}}
							isMeetingDocument
							selectedDocumentId={preview.members.documentId}
							loadAnnotationsAction={loadSignatureAnnotations}
							saveAnnotationAction={saveAnnotation}
							deleteAnnotationAction={deleteAnnotation}
							customizationOptions={{
								fullScreenCustom: {
									customWrapper: null,
									isViewerId: "membersPdfAdopt",
								},
								downloadCustom: {
									onClickUrl: `/document/${preview.members.documentId}`,
								},
							}}
							headerEndDivider
							isViewerId={"membersPdfAdopt"}
						/>
					)}
				</div>
			</ComponentContainer>
			{adoptPublishError > 0 && (
				<AdoptPublishErrorDialog
					meetingId={adoptPublishError}
					handleCancel={() => {
						setAdoptPublishError(null);
					}}
				/>
			)}
		</>
	) : (
		<CircularProgressIndicator />
	);
};

export default SignDocument;
