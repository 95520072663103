import React from "react";
import { useTranslation } from "react-i18next";

import SearchResult from "./SearchResult";

const RequestToSpeakResult = (props) => {
	const { result } = props;
	const { t } = useTranslation();

	return (
		<SearchResult
			result={result}
			icon={{
				name: "request-to-speak",
				tooltip: t("search.tooltips.requestToSpeak"),
			}}
			link={`/requesttospeak/${result.guid}`}
		/>
	);
};

export default React.memo(RequestToSpeakResult);
