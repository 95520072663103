import {
	UPDATE_SETTINGS,
	GET_SETTINGS_PENDING,
	GET_SETTINGS_FULFILLED,
	SAVE_SETTINGS_PENDING,
	VALIDATE_BOXCAST_FULFILLED,
	VALIDATE_YOUTUBE_FULFILLED,
	ADD_YOUTUBE_FULFILLED,
	SET_GENERAL_SETTING_ERROR,
	UPLOAD_LETTERHEAD_PENDING,
	UPLOAD_LETTERHEAD_FULFILLED,
	DELETE_LETTERHEAD_PENDING,
	DELETE_LETTERHEAD_FULFILLED,
} from "./types";

export const generalSettingsReducer = (
	state = {
		contactUsUrl: "",
		emailBcc: "",
		emailDisclaimer: "",
		diligentAdmin: false,
		isBoxCastEnabled: false,
		isYouTubeEnabled: false,
		organizationAddress: "",
		organizationName: "",
		policyURL: "",
		sendReminderNotification: 0,
		testEmailTo: "",
		timeZone: "",
		timeZoneDropdownValues: [],
		validYouTubeCredentials: false,
		updating: false,
		updated: false,
		message: "",
		valid: false,
		url: "",
		hour: 0,
		period: "",
		generalSettingErrors: {},
	},
	action,
) => {
	switch (action.type) {
		case GET_SETTINGS_PENDING: {
			state = {
				...state,
				updating: true,
				updated: false,
			};
			break;
		}
		case GET_SETTINGS_FULFILLED: {
			state = {
				...state,
				...action.payload,
			};
			break;
		}
		case UPDATE_SETTINGS: {
			state = {
				...state,
				...action.payload,
			};
			break;
		}
		case SAVE_SETTINGS_PENDING: {
			let key = Object.keys(action.payload)[0];
			let value = Object.values(action.payload)[0];

			state = {
				...state,
				[key]: value,
			};
			break;
		}
		case VALIDATE_BOXCAST_FULFILLED: {
			const valid = action.payload.Valid;
			const message = action.payload.Message;
			state = {
				...state,
				valid: valid,
				message: message,
			};
			break;
		}
		case ADD_YOUTUBE_FULFILLED: {
			const valid = action.payload.Valid;
			const url = action.payload.AuthCodeUrl;
			state = {
				...state,
				valid: valid,
				url: url,
			};
			break;
		}
		case VALIDATE_YOUTUBE_FULFILLED: {
			const valid = action.payload.Valid;
			state = {
				...state,
				valid: valid,
			};
			break;
		}
		case SET_GENERAL_SETTING_ERROR: {
			state = {
				...state,
				generalSettingErrors: action.payload,
			};
			break;
		}

		case UPLOAD_LETTERHEAD_PENDING:
		case DELETE_LETTERHEAD_PENDING: {
			state = {
				...state,
				updatingLetterhead: true,
			};
			break;
		}

		case UPLOAD_LETTERHEAD_FULFILLED: {
			state = {
				...state,
				updatingLetterhead: false,
				defaultLetterheadTemplate: action.payload,
			};
			break;
		}

		case UPLOAD_LETTERHEAD_FULFILLED: {
			state = {
				...state,
				updatingLetterhead: false,
				defaultLetterheadTemplate: action.payload,
			};
			break;
		}

		case DELETE_LETTERHEAD_FULFILLED: {
			state = {
				...state,
				updatingLetterhead: false,
				defaultLetterheadTemplate: {},
			};
			break;
		}
	}

	return state;
};

export default generalSettingsReducer;
