import isEqual from "lodash/fp/isEqual";

export const useUpdateObject =
	(setObject, sanitizeObject, validateObject, otherActions) =>
	(e, field, isCheckbox, isNumeric, objectToUpdate, valueTransform, objectTransform) => {
		const {
			target: { value, checked },
			target,
		} = e;

		setObject((prev) => {
			const updateObject = objectToUpdate || prev;

			let newValue = isCheckbox ? checked : isNumeric ? parseInt(value, 10) : value;
			newValue = valueTransform ? valueTransform(newValue, updateObject[field]) : newValue;

			if (Array.isArray(updateObject)) {
				let changed = false;
				const valueIsArray = Array.isArray(newValue);
				updateObject.forEach((object) => {
					// Apply the new value to each object in the array
					if (object[field] !== newValue) {
						object[field] = valueIsArray ? [...newValue] : newValue;
						changed = true;
					}
				});

				if (!changed) {
					return prev; // don't re-save unchanged content
				}
			} else if (!valueTransform && isEqual(updateObject[field], newValue)) {
				return prev; // don't re-save unchanged content (valueTransform may transform the existing value in place)
			} else {
				updateObject[field] = newValue;
			}

			// Used to update other fields based on the field being updated
			if (typeof objectTransform === "function") {
				objectTransform(updateObject, prev);
			}

			if (typeof sanitizeObject === "function") {
				sanitizeObject(prev, updateObject, target, newValue);
			}
			if (typeof validateObject === "function") {
				validateObject(prev, !objectToUpdate ? field : undefined);
			}

			return { ...prev };
		});
		if (typeof otherActions === "function") {
			otherActions();
		}
	};

export const initializeValidate = (setErrors, fullValidation, defaultErrors) => {
	if (typeof setErrors === "function") {
		setErrors((prev) => ({
			...(defaultErrors || prev),
			validate: fullValidation ? true : {},
		}));
	}
};

export const setValidate = (errors, field) => {
	if (errors && field && typeof errors.validate === "object") {
		errors.validate[field] = true;
	}
};

export const getRequiredLabel = (t, label) => t("app:entities.required", { entity: label });

export const getHighlightRequired = (entity, errors, field) =>
	entity && field
		? !entity[field] && (errors ? !errors[field] || (typeof errors.validate === "object" && !errors.validate[field]) : true)
		: false;

export const getErrorProps = (errors, field) =>
	errors && field && ((typeof errors.validate === "object" && errors.validate[field]) || typeof errors.validate !== "object")
		? {
				helperText: getHelperText(errors, field),
				error: getError(errors, field),
		  }
		: {};

export const getHelperText = (errors, field) => (errors && field ? errors[field] : undefined);

export const getError = (errors, field) => !!(errors && field && errors[field]);

export const hasError = (errors, field) =>
	errors && field && ((typeof errors.validate === "object" && errors.validate[field]) || typeof errors.validate !== "object")
		? getError(errors, field)
		: false;
