/* eslint-disable no-plusplus */
import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { List, Box, Divider } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import AccessibleIconButton from '../Buttons/AccessibleIconButton';
import leftNavBarStyles from '../../assets/jss/components/leftNavBarStyle';
import Icon from '../Icon/Icon';
import Tooltip from '../Tooltip/Tooltip';
import { useWidthDown } from '../../utils/useWidth';
import typographyStyles from '../../assets/jss/components/typographyStyle';

const useTypographyStyles = makeStyles(typographyStyles);
const useLeftNavBarStyles = makeStyles(leftNavBarStyles);
const maxOrganizationNameLengthBeforeTooltip = 33;

const LeftNavBar = (props) => {
	const { open, setOpen, openTooltip, organizationName, children, diligentLogoName, logoURL } = props;
	const widthDownLg = useWidthDown('lg');
	const typographyClasses = useTypographyStyles();
	const classes = useLeftNavBarStyles();

	const getOrganizationName = () => (
		<span data-cy="customer-site-title" className={clsx(typographyClasses.navigation, classes.organization)}>
			{organizationName}
		</span>
	);
	const getOrganizationLabel = () =>
		organizationName && organizationName.length > maxOrganizationNameLengthBeforeTooltip ? (
			<Tooltip placement="bottom-start" title={organizationName} role="organization-title" >
				{getOrganizationName()}
			</Tooltip>
		) : (
			getOrganizationName()
		);

	const toggleOpen = (e) => {
		e.preventDefault();
		e.stopPropagation();
		setOpen(!open);
	};

	return (
		<>
			<Box role="navigation" title="expandable-leftnav"
				className={clsx(classes.container, {
					[classes.logo]: open,
					[classes.hamburger]: !open,
				})}
				onClick={!open ? toggleOpen : null}
			>
				{open ? (
					logoURL ? (
						<img data-cy="customer-site-logo" src={logoURL} alt="site logo" className={classes.customerLogo} />
					) : (
						getOrganizationLabel()
					)
				) : (
					<AccessibleIconButton id="menu-button" onClick={toggleOpen} iconName="menu" tooltipText={openTooltip} disableRipple />
				)}
			</Box>
			<Divider className={classes.divider} />
			<List component="nav" className={classes.nav} data-cy="leftNav">
				{children}
			</List>
			{open && widthDownLg && (
				<>
					<Divider className={classes.divider} />
					<Box className={classes.bottomLogoContainer}>
						<Icon name={diligentLogoName} size="40" />
					</Box>
				</>
			)}
		</>
	);
};

LeftNavBar.propTypes = {
	open: PropTypes.bool.isRequired,
	setOpen: PropTypes.func.isRequired,
	openTooltip: PropTypes.string.isRequired,
	organizationName: PropTypes.string,
	diligentLogoName: PropTypes.string,
	logoURL: PropTypes.string,
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

LeftNavBar.defaultProps = {
	organizationName: '',
	diligentLogoName: 'diligent-logo',
	logoURL: '',
};

export default LeftNavBar;
