import {
	GET_POLICIES_PENDING,
	GET_POLICIES_FULFILLED,
	GET_PERMISSIONS_PENDING,
	GET_PERMISSIONS_FULFILLED,
	ADD_FOLDER_PENDING,
	ADD_FOLDER_FULFILLED,
	GET_PATH_PENDING,
	GET_PATH_FULFILLED,
	DOWNLOAD_POLICY_PENDING,
	DOWNLOAD_POLICY_FULFILLED,
	POLICY_LOAD_HISTORY_PENDING,
	POLICY_LOAD_HISTORY_FULFILLED,
	POLICY_PUBLISH_PENDING,
	POLICY_PUBLISH_FULFILLED,
	SET_RELOAD_PAGE_AFTER_WOPI_EDIT,
} from "./types";
import request from "superagent";
import { API_HOST } from "config/env";

export const getPolicies = ({ type, parentId, showPolicies, showBooksAndSections } = {}) => {
	return (dispatch) => {
		return new Promise((resolve, reject) =>
			dispatch({
				type: GET_POLICIES_PENDING,
				payload: request
					.get(`${API_HOST}/api/policies`)
					.query({ id: parentId, type, showPolicies, showBooksAndSections })
					.then((response) => response.body)
					.then((json) => {
						dispatch({
							type: GET_POLICIES_FULFILLED,
							payload: json,
						});
						resolve(json);
					})
					.catch((exception) => {
						reject(exception);
					}),
			}),
		);
	};
};

export const getPermissions = (type, id) => {
	return (dispatch) => {
		return new Promise((resolve, reject) =>
			dispatch({
				type: GET_PERMISSIONS_PENDING,
				payload: request
					.get(`${API_HOST}/api/policy/${id || 0}/permissions`)
					.query({ type })
					.then((response) => response.body)
					.then((json) => {
						dispatch({
							type: GET_PERMISSIONS_FULFILLED,
							payload: json,
						});
						resolve(json);
					})
					.catch((exception) => {
						reject(exception);
					}),
			}),
		);
	};
};

export const addFolder = (id, type, requestPayload) => {
	return (dispatch) => {
		return new Promise((resolve, reject) =>
			dispatch({
				type: ADD_FOLDER_PENDING,
				payload: request
					.post(`${API_HOST}/api/policy/${id}/addfolder`)
					.query({ type })
					.withCredentials()
					.send(requestPayload)
					.then((response) => response.body)
					.then((json) => {
						dispatch({
							type: ADD_FOLDER_FULFILLED,
							payload: json,
						});
						resolve(json);
					})
					.catch((exception) => {
						reject(exception);
					}),
			}),
		);
	};
};

export const getPath = (id) => {
	return (dispatch) => {
		return new Promise((resolve, reject) =>
			dispatch({
				type: GET_PATH_PENDING,
				payload: request
					.get(`${API_HOST}/api/policy/${id}/path`)
					.withCredentials()
					.then((response) => response.body)
					.then((json) => {
						dispatch({
							type: GET_PATH_FULFILLED,
							payload: json,
						});
						resolve(json);
					})
					.catch((exception) => {
						reject(exception);
					}),
			}),
		);
	};
};

export const renamePolicyFolder = (guid, reqPayload) => {
	return () => {
		return new Promise((resolve, reject) =>
			request
				.patch(`${API_HOST}/api/policy/${guid}/detail`)
				.withCredentials()
				.send(reqPayload)
				.then((response) => response.body)
				.then((json) => {
					resolve(json);
				})
				.catch((exception) => {
					reject(exception);
				}),
		);
	};
};

export const addNewPolicy = (id, polidyDetails) => {
	return () => {
		return new Promise((resolve, reject) =>
			request
				.post(`${API_HOST}/api/policy/${id}/create`)
				.withCredentials()
				.send(polidyDetails)
				.then((response) => response.body)
				.then((json) => {
					resolve(json);
				})
				.catch((exception) => {
					reject(exception);
				}),
		);
	};
};

export const downloadPolicy = (Guid) => {
	return (dispatch) => {
		return new Promise((resolve, reject) =>
			dispatch({
				type: DOWNLOAD_POLICY_PENDING,
				payload: request
					.get(`${API_HOST}/api/policy/${Guid}/download`)
					.withCredentials()
					.then((response) => response.body)
					.then((json) => {
						dispatch({
							type: DOWNLOAD_POLICY_FULFILLED,
							payload: json,
						});
						resolve(json);
					})
					.catch((exception) => {
						reject(exception);
					}),
			}),
		);
	};
};

export const getLoadHistory = (id, pageToLoad) => {
	return (dispatch) => {
		return new Promise((resolve, reject) =>
			dispatch({
				type: POLICY_LOAD_HISTORY_PENDING,
				payload: request
					.get(`${API_HOST}/api/item/${id}/history`)
					.query({
						page: pageToLoad,
						types: "field,location",
					})
					.withCredentials()
					.then((json) => {
						dispatch({
							type: POLICY_LOAD_HISTORY_FULFILLED,
							payload: json,
						});
						resolve(json);
					})
					.catch((exception) => {
						reject(exception);
					}),
			}),
		);
	};
};

export const publishPolicy = (Guid) => {
	return (dispatch) => {
		return new Promise((resolve, reject) =>
			dispatch({
				type: POLICY_PUBLISH_PENDING,
				payload: request
					.post(`${API_HOST}/api/policy/${Guid}/publish`)
					.withCredentials()
					.then((json) => {
						dispatch({
							type: POLICY_PUBLISH_FULFILLED,
							payload: json,
						});
						resolve(json);
					})
					.catch((exception) => {
						reject(exception);
					}),
			}),
		);
	};
};

export const rescindPolicy = (Guid) => {
	return (dispatch) => {
		return new Promise((resolve, reject) =>
			dispatch({
				type: POLICY_PUBLISH_PENDING,
				payload: request
					.post(`${API_HOST}/api/policy/${Guid}/rescind`)
					.withCredentials()
					.then((json) => {
						dispatch({
							type: POLICY_PUBLISH_FULFILLED,
							payload: json,
						});
						resolve(json);
					})
					.catch((exception) => {
						reject("Error Rescinding policy");
					}),
			}),
		);
	};
};

export const unpublishPolicy = (Guid) => {
	return (dispatch) => {
		return new Promise((resolve, reject) =>
			dispatch({
				type: POLICY_PUBLISH_PENDING,
				payload: request
					.post(`${API_HOST}/api/policy/${Guid}/unpublish`)
					.withCredentials()
					.then((json) => {
						dispatch({
							type: POLICY_PUBLISH_FULFILLED,
							payload: json,
						});
						resolve(json);
					})
					.catch((exception) => {
						reject(exception);
					}),
			}),
		);
	};
};

export const copyForPolicy = (guid, selection) => {
	return () => {
		return new Promise((resolve, reject) =>
			request
				.post(`${API_HOST}/api/policy/${guid}/copy`)
				.withCredentials()
				.send({ parentId: selection })
				.then((json) => {
					resolve(json);
				})
				.catch((exception) => {
					reject(exception);
				}),
		);
	};
};

export const moveForPolicy = (guid, selection) => {
	return () => {
		return new Promise((resolve, reject) =>
			request
				.patch(`${API_HOST}/api/policy/${guid}/detail`)
				.withCredentials()
				.send({ parentId: selection })
				.then((json) => {
					resolve(json);
				})
				.catch((exception) => {
					reject(exception);
				}),
		);
	};
};

export const publishSectionName = (guid) => {
	return () => {
		return new Promise((resolve, reject) =>
			request
				.post(`${API_HOST}/api/policy/${guid}/publish`)
				.withCredentials()
				.send({})
				.then((json) => {
					resolve(json);
				})
				.catch((exception) => {
					reject(exception);
				}),
		);
	};
};

export const restorePolicy = (documents) => {
	return () => {
		return new Promise((resolve, reject) =>
			request
				.post(`${API_HOST}/api/policy/0/restore`)
				.withCredentials()
				.send({ documents: documents.map((document) => document.guid) })
				.then((json) => {
					resolve(json);
				})
				.catch((exception) => {
					reject(exception);
				}),
		);
	};
};

export const loadPolicyBooks = () => {
	return () => {
		return new Promise((resolve, reject) =>
			request
				.get(`${API_HOST}/api/policies?settings=true`)
				.then((json) => {
					resolve(json);
				})
				.catch((exception) => {
					reject(exception);
				}),
		);
	};
};

export const loadPolicyBook = (id, bool) => {
	return () => {
		return new Promise((resolve, reject) =>
			request
				.get(`${API_HOST}/api/policy/${id}/detail`)
				.query(bool && { includePath: true })
				.withCredentials()
				.then((json) => {
					resolve(json);
				})
				.catch((exception) => {
					reject(exception);
				}),
		);
	};
};

export const savePolicyBook = (guid, updatedBook) => {
	return () => {
		return new Promise((resolve, reject) =>
			request
				.put(`${API_HOST}/api/policy/${guid}/detail`)
				.withCredentials()
				.send(updatedBook)
				.then((json) => {
					resolve(json);
				})
				.catch((exception) => {
					reject(exception);
				}),
		);
	};
};

export const loadUsersPolicy = () => {
	return () => {
		return new Promise((resolve, reject) =>
			request
				.get(`${API_HOST}/api/users?includeDeleted=true&includeExternal=true&firstNameLastName=true&emailAddress=true`)
				.withCredentials()
				.then((json) => {
					resolve(json);
				})
				.catch((exception) => {
					reject(exception);
				}),
		);
	};
};

export const policyDelete = (guid, password) => {
	return () => {
		return new Promise((resolve, reject) =>
			request
				.delete(`${API_HOST}/api/policy/${guid}/detail`)
				.withCredentials()
				.send({ password })
				.then((json) => {
					resolve(json);
				})
				.catch((exception) => {
					reject(exception);
				}),
		);
	};
};

export const policyTimeline = (id) => {
	return () => {
		return new Promise((resolve, reject) =>
			request
				.get(`${API_HOST}/api/policy/${id}/timeline`)
				.withCredentials()
				.then((json) => {
					resolve(json);
				})
				.catch((exception) => {
					reject(exception);
				}),
		);
	};
};

export const policyAccessLogs = (id, page) => {
	return () => {
		return new Promise((resolve, reject) =>
			request
				.get(`${API_HOST}/api/policy/${id}/access`)
				.query({
					page,
				})
				.withCredentials()
				.then((json) => {
					resolve(json);
				})
				.catch((exception) => {
					reject(exception);
				}),
		);
	};
};

export const policyRename = (guid, key, value, changeSetId) => {
	return () => {
		return new Promise((resolve, reject) =>
			request
				.patch(`${API_HOST}/api/policy/${guid}/detail`)
				.withCredentials()
				.send({ [key]: value, changeSetId })
				.then((json) => {
					resolve(json);
				})
				.catch((exception) => {
					reject(exception);
				}),
		);
	};
};

export const policiesPermanantlyDelete = (payload, password, guid, other) => {
	return () => {
		return new Promise((resolve, reject) =>
			request
				.delete(`${API_HOST}/api/policy/0/purge`)
				.withCredentials()
				.send({ documents: payload, password, progressGuid: guid, ...other })
				.then((json) => {
					resolve(json);
				})
				.catch((exception) => {
					reject(exception);
				}),
		);
	};
};

export const getPolicyListForPublic = (parentId) => {
	return () => {
		return new Promise((resolve, reject) =>
			request
				.get(`${API_HOST}/api/policies/policyPublicList`)
				.query({ id: parentId })
				.then((response) => response.body)
				.then((json) => {
					resolve(json);
				})
				.catch((exception) => {
					reject(exception);
				}),
		);
	};
};

export const getMeetingGroupDetails = (userId) => {
	return () => {
		return new Promise((resolve, reject) =>
			request
				.get(`${API_HOST}/api/board/${userId}/MeetingGroups`)
				.then((response) => response.body)
				.then((json) => {
					resolve(json);
				})
				.catch((exception) => {
					reject(exception);
				}),
		);
	};
};

export const addPolicyToAgenda = (guid, obj) => {
	return () => {
		return new Promise((resolve, reject) =>
			request
				.post(`${API_HOST}/api/policy/${guid}/addPolicyToAgenda`)
				.send(obj)
				.then((response) => response.body)
				.then((json) => {
					resolve(json);
				})
				.catch((exception) => {
					reject(exception);
				}),
		);
	};
};

export const addPolicyToAgendaItem = (guid) => {
	return () => {
		return new Promise((resolve, reject) =>
			request
				.post(`${API_HOST}/api/policy/${guid}/addPolicyToAgendaItem`)
				.then((response) => response.body)
				.then((json) => {
					resolve(json);
				})
				.catch((exception) => {
					reject(exception);
				}),
		);
	};
};

export const publishPolicyAttachments = (payload) => {
	return (dispatch) => {
		return new Promise((resolve, reject) =>
			dispatch({
				type: POLICY_PUBLISH_PENDING,
				payload: request
					.post(`${API_HOST}/api/policies/publishAgendaPolicy`)
					.withCredentials()
					.send(payload)
					.then((json) => {
						dispatch({
							type: POLICY_PUBLISH_FULFILLED,
							payload: json,
						});
						resolve(json);
					})
					.catch((exception) => {
						reject(exception);
					}),
			}),
		);
	};
};

export const generateAttachments = ({ meetingId, attachedToItemId, usePublished, policyIds }) => {
	return () => {
		return new Promise((resolve, reject) =>
			request
				.post(`${API_HOST}/api/policies/generateattachments`)
				.withCredentials()
				.send({
					meetingId,
					attachedToItemId,
					usePublished,
					policyIds,
				})
				.then((response) => response.body)
				.then((json) => {
					resolve(json);
				})
				.catch((exception) => {
					reject(exception);
				}),
		);
	};
};

export const restorePolicyDetails = (guid, id) => {
	return () => {
		return new Promise((resolve, reject) =>
			request
				.post(`${API_HOST}/api/item/${guid}/history`)
				.withCredentials()
				.send({ changeSetId: id })
				.then((json) => {
					resolve(json);
				})
				.catch((exception) => {
					reject(exception);
				}),
		);
	};
};

export const updateDraftPolicies = (payload) => {
	return () => {
		return new Promise((resolve, reject) =>
			request
				.post(`${API_HOST}/api/policies/updatePolicyAttachments`)
				.withCredentials()
				.send(payload)
				.then((response) => response.body)
				.then((json) => {
					resolve(json);
				})
				.catch((exception) => {
					reject(exception);
				}),
		);
	};
};

export const reloadViewPageOfPolicyDocument = (value) => (dispatch) =>
	dispatch({
		type: SET_RELOAD_PAGE_AFTER_WOPI_EDIT,
		payload: value,
	});
