import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import clsx from "clsx";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";

import entityListStyle from "assets/jss/components/entityListStyle";
import CircularProgressIndicator from "atlas/components/Progress/CircularProgressIndicator";
import MeetingDeleteDialog from "components/Dialogs/MeetingDeleteDialog";
import MeetingListCard from "components/MeetingCard/MeetingListCard";
import { updateTabs, updateToolbar } from "redux/app/actions";
import MeetingListEmpty from "./MeetingListEmpty";

const useEntityListStyles = makeStyles(entityListStyle);

const MeetingList = (props) => {
	const {
		tab,
		tabChange,
		filterClick,
		filtered,
		clearFilterClick,
		meetings,
		more,
		moreClick,
		openFilterClick,
		loading,
		moreLoading,
		meetingFetchHappened,
		removeMeetingFromList,
	} = props;
	const { t } = useTranslation();
	const [showMeetingDeleteDialog, setShowMeetingDeleteDialog] = useState(false);
	const [meetingForDelete, setMeetingForDelete] = useState(null);
	const dispatch = useDispatch();
	const listClasses = useEntityListStyles();

	const openMeetingDeleteDialog = (obj) => {
		setShowMeetingDeleteDialog(true);
		setMeetingForDelete(obj);
	};

	const closeMeetingDeleteDialog = () => {
		setShowMeetingDeleteDialog(false);
	};

	const afterDeleteMeeting = (obj) => {
		const { id } = obj;

		setShowMeetingDeleteDialog(false);
		removeMeetingFromList(id);
	};

	useEffect(() => {
		dispatch(
			updateTabs({
				display: true,
				selectedTab: tab,
				tabsOptions: [
					{ key: "upcoming", label: t("meetings:upcoming"), dataCy: "upcomingMeetings" },
					{ key: "previous", label: t("meetings:previous"), dataCy: "previousMeetings" },
				],
				onChange: (_e, newTab) => {
					dispatch(
						updateTabs({
							selectedTab: newTab,
						}),
					);

					tabChange(_e, newTab);
				},
			}),
		);
		dispatch(
			updateToolbar({
				display: true,
				right: {
					tools: [
						{
							id: "openFilter",
							icon: "filter",
							tooltipText: t("meetings:tooltips.openFilter"),
							ariaLabel: t("meetings:buttons.showHideFilter"),
							onClick: filterClick,
							dataCy: "openFilter",
							badgeProps: {
								display: filtered,
								dataCy: "filtered",
							},
						},
					],
				},
			}),
		);
	}, []);

	return (
		<>
			{showMeetingDeleteDialog && (
				<MeetingDeleteDialog
					show={showMeetingDeleteDialog}
					afterDelete={afterDeleteMeeting}
					onClose={closeMeetingDeleteDialog}
					meeting={meetingForDelete}
					dispatch={dispatch}
				/>
			)}
			<div className="flex">
				<div className={clsx("flex", "grow", listClasses.listContainer)}>
					<Container maxWidth="xl" className="full-width">
						{meetings && meetings.length > 0 && (!loading || moreLoading) ? (
							<List className={listClasses.list}>
								{meetings
									.map((meeting) => (
										<MeetingListCard
											meeting={meeting}
											openMeetingDeleteDialog={openMeetingDeleteDialog}
											showPublishStatus={meeting.administrator}
											data-cy={`meetingCard${meeting.id}`}
										/>
									))
									.concat(
										more && !moreLoading ? (
											<li key="load-more">
												<Grid container justifyContent="center">
													<Button variant="contained" color="primary" onClick={() => moreClick()} data-cy="loadMore">
														{t("meetings:buttons.loadMore")}
													</Button>
												</Grid>
											</li>
										) : null,
									)
									.concat(moreLoading && <CircularProgressIndicator key="loading-more" data-cy="loadingMore" />)}
							</List>
						) : (
							<div style={{ margin: "24px" }}>
								{loading ? (
									<Box mt={4}>
										<CircularProgressIndicator data-cy="loadingMeetings" />
									</Box>
								) : (
									<>
										{filtered ? (
											<>
												<MeetingListEmpty openFilterClick={openFilterClick} clearFilterClick={clearFilterClick} />
												{more && !moreLoading && (
													<Grid key="load-more" container justifyContent="center">
														<Button variant="contained" color="primary" onClick={() => moreClick()} data-cy="loadMore">
															{t("meetings:buttons.loadMore")}
														</Button>
													</Grid>
												)}
												{moreLoading && <CircularProgressIndicator key="loading-more" data-cy="loadingMore" />}
											</>
										) : (
											meetingFetchHappened && (
												<>
													<Box mt={4}>
														<Typography id="no-meeting-title" variant="h3">
															{tab === 0 ? t("meetings:noMeetings.upcoming.title") : t("meetings:noMeetings.previous.title")}
														</Typography>
													</Box>
													<Box mt={3}>
														<Typography id="no-meeting-description" variant="body2">
															{tab === 0 ? t("meetings:noMeetings.upcoming.description") : t("meetings:noMeetings.previous.description")}
														</Typography>
													</Box>
												</>
											)
										)}
									</>
								)}
							</div>
						)}
					</Container>
				</div>
			</div>
		</>
	);
};

export default MeetingList;
