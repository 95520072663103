import React from 'react';
import clsx from 'clsx';

import { TableHead, TableRow, TableCell, Checkbox, TableSortLabel } from '@mui/material';

import MediumText from '../Typography/MediumText';

// headCells example
// const headCells = [
// 	{ id: "name", numeric: false, disablePadding: true, label: "Name", rowColor: blackColor[1], isRowBold: true  },
// 	{ id: "documents", numeric: true, disablePadding: false, label: "Documents", rowColor: blackColor[1], isRowBold: false  },
//  { id: "menu", numeric: true, disablePadding: false, label: "", rowColor: blackColor[1], isRowBold: false  },
// ];

const EnhancedTableHead = (props) => {
	const {
		headCells,
		classes,
		overrideClasses = {},
		onSelectAllClick,
		order,
		orderBy,
		numSelected,
		rowCount,
		onRequestSort,
		withoutMultiSelect = false,
		hasAnyItemSelected,
	} = props;
	const createSortHandler = (property) => (event) => {
		onRequestSort(event, property);
	};
	const paddingTop = hasAnyItemSelected > 0 ? '56px' : '0';

	return (
		<TableHead>
			<TableRow className={overrideClasses.headRow}>
				{!withoutMultiSelect && (
					<TableCell
						className={overrideClasses.headCell}
						component="td"
						key="checkboxes"
						padding="normal"
						style={{ paddingTop, paddingLeft: '4px', paddingBottom: '0' }}
					>
						<Checkbox
							color="primary"
							indeterminate={numSelected > 0 && numSelected < rowCount}
							checked={rowCount > 0 && numSelected === rowCount}
							onChange={onSelectAllClick}
							inputProps={{ 'aria-label': 'select all' }}
							data-cy="tableData-SelectAll"
						/>
					</TableCell>
				)}
				{headCells.map((headCell) => (
					<React.Fragment key={headCell.id}>
						{headCell.id === 'menu' || headCell.sortNotRequired ? (
							<TableCell
								className={clsx(overrideClasses.headCell, headCell.headClassName)}
								component={headCell.label ? 'th' : 'td'}
								align="left"
								style={headCell.width ? { width: headCell.width, paddingTop, paddingBottom: '0' } : { paddingTop, paddingBottom: '0' }}
								padding={headCell.disablePadding ? 'none' : 'normal'}
								aria-label={headCell.ariaLabel}
								data-cy={`header-${headCell.label}`}
							>
								<MediumText>{headCell.retainCase ? headCell.label : headCell.label.toUpperCase()}</MediumText>
							</TableCell>
						) : (
							<TableCell
								className={clsx(overrideClasses.headCell, headCell.headClassName)}
								align="left"
								style={headCell.width ? { width: headCell.width, paddingTop, paddingBottom: '0' } : { paddingTop, paddingBottom: '0' }}
								padding={headCell.disablePadding ? 'none' : 'normal'}
								sortDirection={orderBy === headCell.id ? order : false}
								aria-label={headCell.ariaLabel}
								data-cy={`${headCell.id}`}
							>
								<TableSortLabel
									className={clsx(overrideClasses.sortLabel, classes.sortLabel)}
									active={orderBy === headCell.id}
									direction={orderBy === headCell.id ? order : 'asc'}
									onClick={createSortHandler(headCell.id)}
								>
									<MediumText>{headCell.retainCase ? headCell.label : headCell.label.toUpperCase()}</MediumText>
									{orderBy === headCell.id ? (
										<span className={classes.visuallyHidden}>{order === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>
									) : null}
								</TableSortLabel>
							</TableCell>
						)}
					</React.Fragment>
				))}
			</TableRow>
		</TableHead>
	);
};

export default EnhancedTableHead;
