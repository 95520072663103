import React, { useEffect, useState, useContext, useRef } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import request from "superagent";
import { useTranslation } from "react-i18next";
import queryString from "query-string";

import ComponentContainer from "atlas/components/ComponentContainer/ComponentContainer";
import { API_HOST } from "config/env";
import withErrorHandling from "components/ErrorHOC";
import { mapStateToProps } from "../../redux/app/mapStateToProps";
import { resetPageConfigs, updatePageConfigs, updateTabs } from "redux/app/actions";
import { updatePageHeader } from "redux/pageHeader/actions";
import { SettingsContext } from "contexts/Settings/SettingsContext";
import { UserContext } from "contexts/User/UserContext";
import RequestToSpeak from "./components/RequestToSpeak";
import Voting from "./components/Voting";
import FolderUpload from "./components/FolderUpload";
import Features from "./components/Features";
import PublicSiteSettings from "./components/PublicSiteSettings";
import notifierMessage from "utils/notifierMessage";
import telemetryAddEvent from "utils/telemetryAddEvent";
import { setSnackbarOptions } from "redux/snackBar/actions";
import { getInitialSettings, setUpdatedSettings } from "redux/publicSite/actions";

const settingTypes = {
	features: "features",
	portalStyles: "portal-styles",
	publicRequestToSpeak: "rts",
	voting: "voting",
};

const telemetryPage = "Settings";

const Settings = (props) => {
	const { showSignIn } = props;
	const location = useLocation();
	const { tab: initialTab } = queryString.parse(location.search) || {};
	const [tab, setTab] = useState(-1);
	const [settings, setSettings] = useState(null);
	const [meetingTypes, setMeetingTypes] = useState([]);
	const [saving, setSaving] = useState(false);
	const { lite, salesSite, communityMeetings } = useContext(SettingsContext);
	const { boardAdmin, portalAdmin, userAdmin, systemAdministrator } = useContext(UserContext).user;
	const { t } = useTranslation("settings");
	const dispatch = useDispatch();
	const publicSiteData = useSelector((state) => state.publicSiteReducer);
	const [previousData, setPreviousData] = useState({});
	const [newData, setNewData] = useState({});
	const [disableSave, setDisableSave] = useState(false);
	const performSave = useRef(false);
	const cancelReload = useRef(false);

	// Set up tabs
	const setTabIndex = (tabs, tabName, enabled, index) => {
		tabs[tabName] = enabled ? index : undefined;

		return index + (enabled ? 1 : 0);
	};

	const votingEnabled = communityMeetings && (userAdmin || boardAdmin);
	const requestToSpeakEnabled = communityMeetings && userAdmin && !lite.enabled;
	const folderUploadEnabled = communityMeetings && systemAdministrator;
	const portalStylesEnabled = portalAdmin;
	const featuresEnabled = systemAdministrator || (salesSite && userAdmin);
	const tabs = {};
	let tabIndex = 0;
	tabIndex = setTabIndex(tabs, "voting", votingEnabled, tabIndex);
	tabIndex = setTabIndex(tabs, "requestToSpeak", requestToSpeakEnabled, tabIndex);
	tabIndex = setTabIndex(tabs, "folderUpload", folderUploadEnabled, tabIndex);
	tabIndex = setTabIndex(tabs, "portalStyles", portalStylesEnabled, tabIndex);
	tabIndex = setTabIndex(tabs, "features", featuresEnabled, tabIndex);

	const compareValues = () => {
		setDisableSave(true);
		Object.keys(newData).forEach((key) => {
			if (previousData[key] !== newData[key]) {
				setDisableSave(false);
			}
		});
	};

	const tabChange = (_e, selectedTab) => {
		setTab((prev) => {
			if (prev !== selectedTab) {
				setSettings(null);
			}
			return selectedTab;
		});
	};

	const loadMeetingTypes = () => {
		request
			.get(`${API_HOST}/api/meetingtypes?adminOnly=true`)
			.withCredentials()
			.then((res) => {
				if (res.body) {
					setMeetingTypes(res.body);
				}
			})
			.catch((err) => {
				if (typeof showSignIn === "function") {
					showSignIn(err, () => {
						loadMeetingTypes();
					});
				}
			});
	};

	const loadSettings = () => {
		const queryFilters = {};
		if (tab === tabs.voting) {
			queryFilters.type = settingTypes.voting;
		} else if (tab === tabs.requestToSpeak) {
			queryFilters.type = settingTypes.publicRequestToSpeak;
		} else if (tab === tabs.portalStyles) {
			queryFilters.type = settingTypes.portalStyles;
		} else if (tab === tabs.features) {
			queryFilters.type = settingTypes.features;
		}

		if (queryFilters.type) {
			dispatch(getInitialSettings(queryFilters))
				.then((res) => {
					if (res) {
						setPreviousData({
							lite: res.lite,
							policy: res.policy,
							dropDown: res.activeBoard,
						});

						setSettings(res);
					}
				})
				.catch((err) => {
					if (typeof showSignIn === "function") {
						showSignIn(err, () => {
							loadSettings();
						});
					}
				});
		} else {
			setSettings(null);
		}
	};

	const saveSettings = () => {
		setSaving(true);
		let type = "";
		if (tab === tabs.voting) {
			type = settingTypes.voting;
		} else if (tab === tabs.requestToSpeak) {
			type = settingTypes.publicRequestToSpeak;
		} else if (tab === tabs.portalStyles) {
			type = settingTypes.portalStyles;

			telemetryAddEvent(`${telemetryPage} - Public Site Admin Save`);
		} else if (tab === tabs.features) {
			type = settingTypes.features;
		}
		if (type) {
			if (type == settingTypes.portalStyles) {
				dispatch(setUpdatedSettings(publicSiteData, type))
					.then((res) => {
						setSaving(false);
						if (res) {
							let option = notifierMessage(t("snackbar.success"), "success");
							dispatch(setSnackbarOptions(option));

							if (tab === tabs.features && !cancelReload.current) {
								// Changing the features requires the page to be reloaded
								setTimeout(() => {
									window.location.href = window.location.href;
								}, 2000);
							}

							cancelReload.current = false;
						}
					})
					.catch(() => {
						setSaving(false);
					});
			} else {
				request
					.put(`${API_HOST}/api/settings/save`)
					.withCredentials()
					.send({
						...settings,
						type,
					})
					.then((res) => {
						setSaving(false);

						if (res.status === 200) {
							let option = notifierMessage(t("snackbar.success"), "success");
							dispatch(setSnackbarOptions(option));

							if (tab === tabs.features && !cancelReload.current) {
								// Changing the features requires the page to be reloaded
								setTimeout(() => {
									window.location.href = window.location.href;
								}, 2000);
							}

							cancelReload.current = false;
						}
					})
					.catch(() => {
						setSaving(false);
					});
			}
		}
	};

	const updateSettingsValue = (setting, value, mergeValue, autoSave = false, preventReload = false) => {
		setNewData({
			setting: value,
		});
		if (setting === "digitalVoting") {
			setSettings((prev) => ({
				...prev,
				[setting]: (prev[setting] || []).map((prevMeetingType) => {
					if (prevMeetingType.id === value.meetingTypeId) {
						prevMeetingType.digitalVoting = value.checked;
					}
					return prevMeetingType;
				}),
			}));
		} else {
			setSettings((prev) => ({
				...prev,
				[setting]: mergeValue
					? {
							...(prev[setting] || {}),
							...value,
						}
					: value,
			}));
		}

		if (autoSave) {
			performSave.current = true;
			cancelReload.current = preventReload;
		}
	};

	useEffect(() => {
		loadSettings();
	}, [tab]);

	useEffect(() => {
		let canUpdate = false;
		switch (tab) {
			case tabs.voting:
				canUpdate = userAdmin || boardAdmin;
				break;

			case tabs.requestToSpeak:
				canUpdate = userAdmin;
				break;

			case tabs.portalStyles:
				canUpdate = userAdmin || portalAdmin;
				break;

			case tabs.features:
				canUpdate = systemAdministrator || (salesSite && userAdmin);
				break;

			default:
				canUpdate = false;
				break;
		}

		const errorObject = Object.values(publicSiteData && publicSiteData.publicSiteErrors);
		let isErrorNotPresent = errorObject && errorObject.every((element) => element === null);
		dispatch(
			updatePageHeader({
				primaryAction: canUpdate ? saveSettings : null,
				primaryActionText: canUpdate ? t("buttons.saveSettings") : null,
				primaryActionTooltip: canUpdate ? t("tooltips.saveSettings") : null,
				primaryActionDisabled: (tab == tabs.portalStyles && !isErrorNotPresent) || saving || disableSave,
			}),
		);
	}, [settings, tab, saving, publicSiteData, disableSave]);

	useEffect(() => {
		if (performSave.current) {
			performSave.current = false;
			saveSettings();
		}
	}, [settings]);

	useEffect(() => {
		const selectedTab = initialTab && tabs[initialTab] ? tabs[initialTab] : 0;
		tabChange(null, selectedTab);

		dispatch(
			updateTabs({
				selectedTab,
			}),
		);
	}, [initialTab]);

	useEffect(() => {
		loadMeetingTypes();
		dispatch(resetPageConfigs({ resetBack: true }));
		dispatch(updatePageConfigs({ title: t("title"), telemetryPage }));

		const tabsOptions = [];
		if (votingEnabled) {
			tabsOptions.push({ key: "voting", label: t("tabs.voting"), dataCy: "voting-settings" });
		}
		if (requestToSpeakEnabled) {
			tabsOptions.push({ key: "publicRequestToSpeak", label: t("tabs.publicRequestToSpeak"), dataCy: "publicRequestToSpeak-settings" });
		}
		if (folderUploadEnabled) {
			tabsOptions.push({ key: "folderUpload", label: t("tabs.folderUpload"), dataCy: "folder-upload-settings" });
		}
		if (portalStylesEnabled) {
			tabsOptions.push({ key: "portalStyles", label: t("tabs.portalStyles"), dataCy: "portal-styles-settings" });
		}
		if (featuresEnabled) {
			tabsOptions.push({ key: "features", label: t("tabs.features"), dataCy: "features-settings" });
		}

		const selectedTab = initialTab && tabs[initialTab] ? tabs[initialTab] : 0;
		dispatch(
			updateTabs({
				display: true,
				selectedTab,
				tabsOptions,
				onChange: (_e, newTab) => {
					dispatch(
						updateTabs({
							selectedTab: newTab,
						}),
					);

					tabChange(_e, newTab);
				},
			}),
		);
	}, []);

	useEffect(() => {
		if (tab === tabs.features) {
			compareValues();
		} else setDisableSave(false);
	}, [tab, newData]);

	const displayComponent = () => {
		if (tab === tabs.portalStyles && portalStylesEnabled && settings) {
			return <PublicSiteSettings settings={settings} updateSettingsValue={updateSettingsValue} />;
		}
	};
	return (
		<>
			<ComponentContainer noPaddingTopUp={"sm"}>
				{tab === tabs.voting && votingEnabled && (
					<Voting
						settings={settings}
						updateSettingsValue={updateSettingsValue}
						userAdmin={userAdmin}
						boardAdmin={boardAdmin}
						meetingTypes={meetingTypes}
						lite={lite}
					/>
				)}
				{tab === tabs.requestToSpeak && requestToSpeakEnabled && (
					<RequestToSpeak settings={settings} updateSettingsValue={updateSettingsValue} />
				)}
				{tab === tabs.folderUpload && folderUploadEnabled && <FolderUpload showSignIn={showSignIn} />}
				{displayComponent()}
				{tab === tabs.features && featuresEnabled && <Features settings={settings} updateSettingsValue={updateSettingsValue} />}
			</ComponentContainer>
		</>
	);
};

export default withErrorHandling(connect(mapStateToProps)(Settings));
