import React, { useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { useWidthDown } from "atlas/utils/useWidth";
import SearchInput from "components/Search/SearchInput";
import { SettingsContext } from "contexts/Settings/SettingsContext";
import { updateToolbar } from "redux/app/actions";
import { FOLDER_TYPE_RECYCLE_BIN } from "utils/enums/DocumentFolderTypes";

const DocumentsTopBar = (props) => {
	const {
		id = 0,
		type,
		permissions,
		uploadClick,
		addFolderClick,
		permanentlyDeleteClick,
		restoreClick,
		handleSearch,
		enableRecycleTopBar,
		onClickNewDocument,
	} = props;
	const isMobile = useWidthDown("sm");
	const { t } = useTranslation("documents");
	const [searchFocused, setSearchFocused] = useState(false);
	const [searchKeywords, setSearchKeywords] = useState("");
	const { lite } = useContext(SettingsContext);
	const dispatch = useDispatch();
	const recycleBin = type === FOLDER_TYPE_RECYCLE_BIN;

	const searchFocus = () => setSearchFocused(true);

	const searchBlur = () => setSearchFocused(false);

	const handleSearchChange = (e) => {
		const { value } = e.target;
		setSearchKeywords(value);
	};

	const handleSearchKeyDown = (e) => {
		if (e.key === "Enter") {
			const { value } = e.target;
			handleSearch(value);
		}
	};

	// Display tools
	useEffect(() => {
		if (!isMobile && searchFocused) {
			setSearchFocused(false);
		}

		dispatch(
			updateToolbar({
				display: true,
				left: {
					tools: [
						{
							id: "upload",
							icon: "upload",
							label: !isMobile ? t("app:buttons.upload") : undefined,
							tooltipText: t("tooltips.upload"),
							onClick: uploadClick,
							dataCy: "upload",
							hidden: !permissions.canUpdate || recycleBin || searchFocused,
						},
						{
							id: "add-folder",
							icon: "add-folder",
							label: !isMobile ? t("buttons.addFolder") : undefined,
							tooltipText: t("tooltips.addFolder"),
							onClick: addFolderClick,
							dataCy: "add-folder",
							hidden: !permissions.canUpdate || recycleBin || searchFocused,
						},
						{
							id: "add-document",
							icon: "add-section",
							label: "NEW DOCUMENT",
							tooltipText: t("buttons.addDocument"),
							onClick: onClickNewDocument,
							dataCy: "add-document",
							hidden: lite.enabled || !permissions.canUpdate || recycleBin || searchFocused,
						},
						{
							id: "permanently-delete",
							icon: "trash",
							label: !isMobile ? t("app:buttons.permanentlyDelete") : undefined,
							tooltipText: t("tooltips.permanentlyDelete"),
							onClick: permanentlyDeleteClick,
							dataCy: "permanently-delete",
							hidden: !permissions.canPermanentlyDelete || !recycleBin || searchFocused,
							disabled: !enableRecycleTopBar,
						},
						{
							id: "restore",
							icon: "redo",
							label: !isMobile ? t("app:buttons.restore") : undefined,
							tooltipText: t("tooltips.restore"),
							onClick: restoreClick,
							dataCy: "restore",
							hidden: !permissions.canDelete || !recycleBin || searchFocused,
							disabled: !enableRecycleTopBar,
						},
					],
				},
				right: {
					tools: [
						!recycleBin && {
							component: (
								<SearchInput
									key="toolbar-search"
									size="extra-small"
									fullWidth={isMobile && searchFocused}
									containerFullWidth={isMobile && searchFocused}
									value={searchKeywords || ""}
									showClearIcon={searchKeywords && searchKeywords.length > 0}
									onFocus={isMobile ? searchFocus : undefined}
									onBlur={isMobile ? searchBlur : undefined}
									onKeyDown={handleSearchKeyDown}
									onChange={handleSearchChange}
									onIconClick={() => {
										handleSearch(searchKeywords);
									}}
								/>
							),
						},
					],
				},
			}),
		);
	}, [id, type, permissions, isMobile, searchFocused, searchKeywords, enableRecycleTopBar]);

	return null;
};

export default DocumentsTopBar;
