import React from "react";
import PropTypes from "prop-types";

import { Radio } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { stripHtml } from "utils/processHtml";

import agendaStyle from "../../../assets/jss/components/agendaStyle";
import clsx from "clsx";

const useStyle = makeStyles(agendaStyle);

const LiveMeetingRadioButton = (props) => {
	const { value, label, selected, hasNotes, handleSelect, dataCy, disabled, isMinutesV2 } = props;
	const classes = useStyle({ isMinutesV2: isMinutesV2 });

	return (
		<div className={clsx(classes.checkbox, `live-meeting-item${value ? `-${value}` : ""}`)}>
			<Radio
				checked={selected}
				value={value}
				name={`live-meeting-item${value ? `-${value}` : ""}`}
				onChange={handleSelect}
				disabled={disabled}
				inputProps={{
					"aria-label": label ? stripHtml(label) : "meeting-item",
					"data-cy": dataCy,
					tabIndex: 0,
				}}
			/>
			{hasNotes && <div className={classes.hasNotes} />}
		</div>
	);
};

LiveMeetingRadioButton.propTypes = {
	value: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	selected: PropTypes.bool,
	hasNotes: PropTypes.bool,
	handleSelect: PropTypes.func,
	dataCy: PropTypes.string,
};

LiveMeetingRadioButton.defaultProps = {
	selected: false,
	hasNotes: false,
	handleSelect: undefined,
	dataCy: undefined,
};

export default LiveMeetingRadioButton;
