import forEach from "lodash/fp/forEach";

export function getRootName(inlineFileElem) {
	let elem = inlineFileElem;
	while (elem.parent) {
		elem = elem.parent;
	}
	if (elem) {
		const attr = elem.getAttribute("data-fieldname");
		if (attr) {
			return attr;
		}
	}
	return null;
}

export function getRootNameFromSelection(selection) {
	let element = selection?.editableElement;
	let rootName = null;
	while (element && !rootName) {
		rootName = element.getAttribute("data-fieldname");
		element = element.parent;
	}
	return rootName ? rootName : "main";
}

export const getElementText = (element) => {
	if (!element) {
		return "";
	}

	if (element.data) {
		return element.data;
	}

	let text = [];
	if (element.is("range")) {
		for (const item of element.getItems()) {
			text.push(getElementText(item));
		}

		return text.join("");
	}

	if (!element.is("element")) {
		return "";
	}

	for (let index = 0; index < element.childCount; index++) {
		text.push(getElementText(element.getChild(index)));
	}

	return text.join("");
};

export function getFileHrefInfo(fileHref) {
	let info = { guid: "", name: null, url: "", features: [], tooltip: "" };
	if (fileHref) {
		info = JSON.parse(fileHref);
		if (info.url.indexOf("/document/") === 0) {
			info.url = `/home${info.url}`;
		}
		if (info.url.includes("/document/")) {
			info.tooltip = "Open attachment in a new tab";
		} else if (info.url.includes("/goals/")) {
			info.tooltip = "Open goal in a new tab";
		} else {
			info.tooltip = "Open link in a new tab";
		}
	}
	return info;
}

export function getLinkFileHref(fileHref) {
	const info = { guid: "", name: null, url: "" };
	if (fileHref) {
		const idx = fileHref.indexOf("|");
		if (idx === -1) {
			info.url = fileHref;
		} else {
			info.url = fileHref.substr(0, idx);
			info.name = fileHref.substr(idx + 1);
		}
		info.guid = getHrefGuid(info.url);
	}
	return info;
}

export function getHrefGuid(href) {
	const hrefParts = href.split("/");
	return hrefParts[hrefParts.length - 1];
}

export function setNodeTitle(node, item, isMemberOnlySection, isClosedMeeting, inlineFileTranslations) {
	if (item && item.attachments && item.attachments.length > 0) {
		const hrefGuid = getHrefGuid(node.attribs.href);
		for (let i = 0; i < item.attachments.length; i++) {
			if (item.attachments[i].guid === hrefGuid) {
				const attachment = item.attachments[i];
				let msg = "";
				if (inlineFileTranslations) {
					if (isClosedMeeting || isMemberOnlySection || attachment.closed || item.fields.Closed.Value) {
						msg = inlineFileTranslations.anchorTitleMember;
					} else {
						msg = inlineFileTranslations.anchorTitlePublic;
					}
				}
				node.attribs.name = attachment.name;
				node.attribs.title = `${attachment.name} ${msg}`;
				i = item.attachments.length;
			}
		}
	}
}

export function createFileHref(url, text, name, features) {
	const info = {
		url: url,
		guid: getHrefGuid(url),
		text,
		name: name,
		features: features,
	};
	return info;
}

export function createLinkElement(fileHref, modelWriter, config) {
	let { anchorTitle, featureConfigs } = config;
	const info = getFileHrefInfo(fileHref);
	let className = "";

	if (info && info.url && info.url.includes("/home/document/")) {
		className = "inlineFile";
	}
	if (info && info.url && info.url.includes("/home/goals/")) {
		anchorTitle = "-Public Goal: visible in the public-facing published agenda";
		className = "inlineLink";
	}

	if (info.features) {
		forEach((feature) => {
			const featureConfig = feature.id ? featureConfigs.get(feature.id) : null;
			if (feature.value === true || (featureConfig && featureConfig.isEnabled === false && featureConfig.disabledValue === true)) {
				className = `${className} ${feature.className}`;
				anchorTitle = feature.anchorTitle;
				if (info.url.includes("/home/goals/")) {
					anchorTitle = "-Internal Goal: not visible in the public-facing published agenda";
					if (!feature.className) {
						className = `${className} closed`;
					}
				}
			}
		}, info.features);
	}
	const title = `${info.name} ${anchorTitle}`;
	const linkElement = modelWriter.writer.createAttributeElement(
		"a",
		{ href: info.url, name: info.name, class: className, title: title },
		{ priority: 4 },
	);

	modelWriter.writer.setCustomProperty("inlineFile", true, linkElement);
	return linkElement;
}

export function isLinkElement(node) {
	return node.is("attributeElement") && node.getCustomProperty("inlineFile");
}

export function findLinkRange(position, value, model) {
	return model.createRange(_findBound(position, value, true, model), _findBound(position, value, false, model));
}

function _findBound(position, value, lookBack, model) {
	let node = position.textNode || (lookBack ? position.nodeBefore : position.nodeAfter);
	let lastNode = null;
	while (node && node.getAttribute("fileHref") == value) {
		lastNode = node;
		node = lookBack ? node.previousSibling : node.nextSibling;
	}
	return lastNode ? model.createPositionAt(lastNode, lookBack ? "before" : "after") : position;
}

export const isAttachmentValue = (value) => {
	let isAttachment = false;
	try {
		if (typeof value === "string") {
			const fileHref = JSON.parse(value);
			isAttachment = fileHref?.url?.indexOf("/document/") >= 0;
		}
	} catch {}

	return isAttachment;
};

export const isGoalValue = (value) => {
	let isGoal = false;
	try {
		if (typeof value === "string") {
			const fileHref = JSON.parse(value);
			isGoal = fileHref?.url?.indexOf("/goals/") >= 0;
		}
	} catch {}
	return isGoal;
};
