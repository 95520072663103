import React from "react";
import { useTranslation } from "react-i18next";
import clsx from "clsx";

import makeStyles from "@mui/styles/makeStyles";
import { TableCell } from "@mui/material";

import typographyStyle from "atlas/assets/jss/components/typographyStyle";
import { whiteColor, successColor, errorColor } from "atlas/assets/jss/shared";
import { STATUS_GOOD, STATUS_ERROR } from "atlas/assets/jss/utils/statusIndicators";

const useStyles = makeStyles({
	status: {
		...typographyStyle.status,
		whiteSpace: "nowrap",
		borderRadius: "4px",
		padding: "4px 8px 4px 8px",
		height: "24px",
		verticalAlign: "middle",
		textAlign: "center",
		display: "inline-block",
		boxSizing: "border-box",
		[`&.${STATUS_GOOD}`]: {
			backgroundColor: successColor,
			color: whiteColor,
		},
		[`&.${STATUS_ERROR}`]: {
			backgroundColor: errorColor,
			color: whiteColor,
		},
	},
});

const ActiveLabelCell = (props) => {
	const { value: inactive = false } = props;
	const active = !inactive;
	const { t } = useTranslation();
	const classes = useStyles();

	return (
		<TableCell data-cy="active-label">
			<span className={clsx(classes.status, active ? STATUS_GOOD : STATUS_ERROR)}>{t(`enums.boolean.${active ? "yes" : "no"}`)}</span>
		</TableCell>
	);
};

export default ActiveLabelCell;
