const outlinedInputStyle = () => ({
	container: {
		height: '88px',
	},
	smallContainer: {
		height: '70px',
	},
	compactContainer: {
		height: 'auto',
	},
});

export default outlinedInputStyle;
