const allowedExtensions = [".bmp", ".jpg", ".jpeg", ".png", ".gif", ".pdf", ".doc", ".docx", ".ppt", ".pptx"];
//Wopitest and Wopitestx are used for testing purposes only and will be removed after testing
const allowedExtensionForPolicy = [".docx", ".wopitest", ".wopitestx"];
const allowedExtensionForTemplates = [".docx"];

export const allowedEditorExtensions = allowedExtensions.map((extension) => extension.replace(".", ""));

export const endsWithExtension = (value, extensions) => {
	if (typeof value !== "string" || !value) {
		return false;
	}

	return !!extensions.find((extension) => value.toLowerCase().endsWith(extension));
};

export const endsWithAllowedExtension = (value) => endsWithExtension(value, allowedExtensions);

export const endsWithAllowedExtensionForPolicy = (value) => endsWithExtension(value, allowedExtensionForPolicy);

export const endsWithAllowedExtensionForTemplates = (value) => endsWithExtension(value, allowedExtensionForTemplates);

export const getAllowedAccept = () => allowedExtensions.join(",");
export const getAllowedAcceptForPolicy = () => allowedExtensionForPolicy.join(",");
export const getAllowedExtensionForTemplates = () => allowedExtensionForTemplates.join(",");

export default allowedExtensions;
