import React, { forwardRef } from "react";
import { useTranslation } from "react-i18next";
import clsx from "clsx";

import makeStyles from "@mui/styles/makeStyles";
import { Checkbox, FormControlLabel } from "@mui/material";

import dragAndDropStyle from "atlas/assets/jss/components/dragAndDropStyle";
import AccessibleIconButton from "atlas/components/Buttons/AccessibleIconButton";
import NonModalMenu from "atlas/components/Menu/NonModalMenu";
import MenuItemWithTooltip from "atlas/components/Menu/MenuItemWithTooltip";
import Tooltip from "atlas/components/Tooltip/Tooltip";
import { blackColor, grayColor, primaryColor } from "atlas/assets/jss/shared";
import DragHandle from "atlas/components/DragAndDrop/DragHandle";
import { Check } from "components/Icons";
import GenericAvatar, { GENERIC_AVATAR_BACKGROUNDS } from "components/Avatar/GenericAvatar";
import telemetryAddEvent from "utils/telemetryAddEvent";

const focusableElement = {
	display: "flex",
	alignItems: "center",
	height: "48px",
	"&:focus": {
		backgroundColor: "rgba(0, 0, 0, 0.08)",
	},
};

const useDragAndDropStyles = makeStyles(dragAndDropStyle);
const useStyles = makeStyles(() => ({
	userListItem: {
		margin: "0",
		position: "relative",
		display: "flex",
		alignItems: "center",
		height: "48px",
		"&:hover": {
			backgroundColor: grayColor[4],
		},
	},
	userListDrag: {
		paddingLeft: "12px",
	},
	userListItemAvatar: {
		padding: "0 4px 0 8px",
		...focusableElement,
	},
	avatar: {
		width: "24px",
		height: "24px",
		fontSize: "12px",
		lineHeight: "14px",
		fontWeight: "600",
	},
	userListItemName: {
		flexGrow: "1",
		padding: "0 8px 0 4px",
		wordBreak: "break-word",
		...focusableElement,
	},
	userListMenu: {
		cursor: "pointer",
	},
	readOnly: {
		"& .MuiFormControlLabel-label": {
			marginLeft: 0,
		},
	},
}));

const BlockUser = forwardRef((props, ref) => {
	const {
		className,
		user,
		isFirst,
		isLast,
		allowReadOnly,
		handleUpdate,
		actionIcon,
		actionTooltip,
		handleAction,
		anchor,
		handleMenu,
		handleMoveUp,
		handleMoveDown,
		handleMenuClose,
		sortId,
		dragAttributes = {},
		dragListeners = {},
		canDrag,
		dragPlaceholder,
		dragPresentational,
		telemetryPage,
		...otherProps
	} = props;
	const { t } = useTranslation("workflows");
	const dragAndDropClasses = useDragAndDropStyles();
	const classes = useStyles({ dragPresentational: dragPresentational });

	const menuOptions = [];
	if (anchor) {
		if (!isFirst) {
			menuOptions.push({
				label: t("app:menuOptions.moveUp"),
				tooltip: t("tooltips.moveUserUp"),
				actionFunction: () => handleMoveUp(user.id),
				"data-cy": "move-user-up",
			});
		}
		if (!isLast) {
			menuOptions.push({
				label: t("app:menuOptions.moveDown"),
				tooltip: t("tooltips.moveUserDown"),
				actionFunction: () => handleMoveDown(user.id),
				"data-cy": "move-user-down",
			});
		}
	}

	const getUserName = (user) => (
		<span>
			{user.userDeleted || user.userInactive
				? t(`app:entities.${user.userDeleted ? "deleted" : "inactive"}`, { entity: user.name })
				: user.name}
		</span>
	);

	return user ? (
		<li
			className={clsx(className, classes.userListItem, {
				[dragAndDropClasses.dragPlaceholder]: dragPlaceholder,
				[classes.userListDrag]: canDrag || dragPresentational,
			})}
			ref={ref}
			{...otherProps}
		>
			{anchor && (
				<NonModalMenu
					id="block-user-actions"
					className="overflow-menu"
					anchorEl={anchor}
					keepMounted
					open={Boolean(anchor)}
					onClose={handleMenuClose}
					position="left"
				>
					{menuOptions.map((option) => (
						<MenuItemWithTooltip
							key={option.label}
							tooltip={option.tooltip}
							placement="left"
							onClick={option.actionFunction}
							data-cy={`add-user-menu-option-${option["data-cy"]}`}
						>
							{option.label}
						</MenuItemWithTooltip>
					))}
				</NonModalMenu>
			)}
			<div className={classes.userListItemAvatar} {...dragAttributes} {...dragListeners}>
				{(canDrag || dragPresentational) && <DragHandle />}

				<GenericAvatar
					className={classes.avatar}
					imageUrl={user.profileImageUrl}
					name={user.name}
					initials={`${(user.firstName || "").substr(0, 1)}${(user.lastName || "").substr(0, 1)}`}
					backgroundNumber={((user.number || 0) % GENERIC_AVATAR_BACKGROUNDS) + 1}
				/>
			</div>
			<div className={classes.userListItemName} {...dragAttributes} {...dragListeners}>
				{user.emailAddress ? (
					<Tooltip
						PopperProps={{
							disablePortal: true,
						}}
						title={user.emailAddress}
					>
						{getUserName(user)}
					</Tooltip>
				) : (
					getUserName(user)
				)}
			</div>
			{allowReadOnly && (
				<div className={classes.readOnly}>
					<FormControlLabel
						control={
							<Checkbox
								checkedIcon={<Check fontSize="small" color="primary" />}
								checked={!user.readOnly}
								onChange={(e) => {
									if (!e.target.checked) {
										telemetryAddEvent(`${telemetryPage} - Can Edit selected`, {
											area: "workflows",
										});
									}

									handleUpdate(e, "readOnly", true, false, user, (value) => !value);
								}}
							/>
						}
						label={t("detail.canEditItem")}
						data-cy={`block-user-read-only-${user.id}`}
					/>
				</div>
			)}
			{handleAction && (
				<div className={classes.userListMenu}>
					<AccessibleIconButton
						iconName={actionIcon}
						iconColor={primaryColor[1]}
						tooltipText={actionTooltip}
						onClick={(e) => {
							e.stopPropagation();

							handleAction(e, user.id);
						}}
						dataCy={`action-${user.id}`}
					/>
				</div>
			)}
			{handleMenu && (!isFirst || !isLast) && (
				<div className={classes.userListMenu}>
					<AccessibleIconButton
						iconName="more"
						iconColor={blackColor[1]}
						aria-label={t("app:menu.options")}
						onClick={(e) => {
							e.stopPropagation();

							handleMenu(e, user.id);
						}}
						dataCy={`overflow-menu-${user.id}`}
					/>
				</div>
			)}
		</li>
	) : null;
});

export default React.memo(BlockUser);
