import React from "react";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";

import makeStyles from "@mui/styles/makeStyles";

import accordionStyle from "assets/jss/components/accordionStyle";
import Accordion from "atlas/components/Accordion/Accordion";
import OutlinedInput from "atlas/components/FormControls/OutlinedInput";
import { updateSettings, setGeneralSettingError } from "redux/generalSettings/actions";
import { isUrl } from "utils/validation";
import generalSettingsStyle from "../jss/generalSettingsStyle";

const useStyles = makeStyles(generalSettingsStyle);
const useAccordionStyles = makeStyles(accordionStyle);

const OrganizationInfo = () => {
	const { t } = useTranslation("generalSettings");
	const accordionClasses = useAccordionStyles();
	const classes = useStyles();
	const dispatch = useDispatch();
	const { organizationName, organizationAddress, customerUrl, generalSettingErrors } = useSelector((state) => state.generalSettingsReducer);

	const handleUpdate = (e, name) => {
		const {
			target: { value },
		} = e;
		dispatch(updateSettings({ [name]: value }));
		validate(name, value);
	};

	const validate = (field, value) => {
		let newErrors = {};
		if (field === "organizationName") {
			if (value.length > 0) {
				newErrors[field] = null;
			} else {
				newErrors[field] = t("errorMessage.organizationName");
			}
		} else if (field === "customerUrl") {
			if (isUrl(value) || !value) {
				newErrors[field] = null;
			} else {
				newErrors[field] = t("errorMessage.customerUrl");
			}
		}
		dispatch(setGeneralSettingError({ ...generalSettingErrors, ...newErrors }));
	};

	return (
		<>
			<div className={clsx(classes.section, classes.sectionAccordion)}>
				<Accordion
					classes={accordionClasses}
					className={classes.bold}
					label={t("subtitle.organization")}
					scrollContentIntoView
					overflow={false}
					dataCy="organization"
				>
					<div className={classes.subAccordians}>
						<div className={classes.inputtextarea}>
							<OutlinedInput
								id="organizationName"
								className={clsx(classes.inputfield, classes.outlinedInput)}
								label={t("headings.organizationName")}
								value={organizationName || ""}
								onChange={(e) => handleUpdate(e, "organizationName")}
								helperText={generalSettingErrors.organizationName}
								error={!!generalSettingErrors.organizationName}
								noDefaultClassName
								size="small"
								inputProps={{ "aria-label": t("headings.organizationName") }}
								data-cy="organizationName"
							/>
						</div>
						<div className={classes.inputtextarea}>
							<OutlinedInput
								id="organizationAddress"
								className={clsx(classes.outlinedInput, classes.notes)}
								label={t("headings.organizationAddress")}
								value={organizationAddress || ""}
								onChange={(e) => handleUpdate(e, "organizationAddress")}
								helperText={generalSettingErrors.organizationAddress}
								error={!!generalSettingErrors.organizationAddress}
								noDefaultClassName
								fullWidth
								multiline
								rows={3}
								inputProps={{ "aria-label": t("headings.organizationAddress") }}
								data-cy="organizationAddress"
							/>
						</div>
						<div className={classes.inputtextarea}>
							<OutlinedInput
								id="customer-url"
								className={clsx(classes.inputfield, classes.outlinedInput)}
								label={t("headings.customerUrl")}
								value={customerUrl || ""}
								onChange={(e) => handleUpdate(e, "customerUrl")}
								helperText={generalSettingErrors.customerUrl}
								error={!!generalSettingErrors.customerUrl}
								noDefaultClassName
								size="small"
								inputProps={{ "aria-label": t("headings.customerUrl") }}
								data-cy="customer-url"
							/>
						</div>
					</div>
				</Accordion>
			</div>
		</>
	);
};
export default OrganizationInfo;
