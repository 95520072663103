import React from "react";
import clsx from "clsx";

import makeStyles from "@mui/styles/makeStyles";

import { primaryColor } from "atlas/assets/jss/shared";

const useStyles = makeStyles(() => ({
	voteResult: {},
	voteTypeNumber: {
		display: "flex",
		width: "100%",
		fontWeight: "600",
	},
	voteType: {
		flexGrow: "1",
	},
	voteCount: {},
	voteProportion: {
		margin: "20px 0",
		height: "20px",
		overflow: "hidden",
		backgroundColor: primaryColor[8],
	},
	voteTypeProportion: {
		height: "20px",
		backgroundColor: primaryColor[6],
	},
}));

const VoteResultType = (props) => {
	const { className, label, count, total, names, totalWeight } = props;

	const classes = useStyles();

	const proportion = total <= 0 ? 0 : Math.max(Math.min(totalWeight / total, 1), 0);

	return (
		<div className={clsx(classes.voteResult, className)}>
			<div className={classes.voteTypeNumber}>
				<div className={classes.voteType}>{label}</div>
				<div className={classes.voteCount}>
					{count}({totalWeight})
				</div>
			</div>
			<div className={classes.voteProportion}>
				<div className={classes.voteTypeProportion} style={{ width: `calc(100% * ${proportion})` }}></div>
			</div>
			<div className={classes.voteNames}>{Array.isArray(names) ? names.join(", ") : names}</div>
		</div>
	);
};

export default VoteResultType;
