/* eslint-disable no-plusplus */
import React, { useState, useEffect } from "react";
import { withTranslation } from "react-i18next";
import request from "superagent";
import processHtml, { stripHtml } from "utils/processHtml";
import clsx from "clsx";
import clone from "lodash/fp/clone";

import makeStyles from "@mui/styles/makeStyles";
import { Box, Button, Grid, MenuItem, Typography } from "@mui/material";

import Icon from "atlas/components/Icon/Icon";
import SelectInput from "atlas/components/FormControls/SelectInput";
import { API_HOST } from "config/env";
import GenericDialog from "atlas/components/Dialogs/GenericDialog";
import inputStyle from "atlas/assets/jss/components/inputStyle";
import ButtonWithTooltip from "atlas/components/Buttons/ButtonWithTooltip";
import { useWidthUp } from "atlas/utils/useWidth";
import { nameVideoParts } from "utils/videoTimeStamping";

import BroadcastRow from "../../views/Meetings/components/BroadcastRow";

const useInputStyles = makeStyles(inputStyle);

const useStyles = makeStyles(() => ({
	dialog: {
		"& .MuiDialog-paper": {
			width: "575px",
			maxWidth: "100%",
		},
	},
	menuItem: {
		maxWidth: (props) => (props.selectWidth > 0 ? `${props.selectWidth}px` : undefined),
	},
	menuItemText: {
		overflow: "hidden",
		textOverflow: "ellipsis",
	},
	progressBar: {
		width: "240px",
		margin: "0 auto",
		marginTop: "24px",
	},
	hidden: {
		display: "none",
	},
	broadcastInputLabel: {
		paddingBottom: "8px",
	},
	selectBoxcast: {
		height: "40px !important",
	},
	selectBroadcast: {
		height: "40px !important",
	},
	selectButton: {
		marginBottom: "16px",
	},
	selectButtonMarginLeft: {
		marginLeft: "8px",
	},
	selectButtonAlignCenter: {
		textAlign: "center",
	},
	selectButtonAlignLeft: {
		textAlign: "left",
	},
	selectButtonAlignRight: {
		textAlign: "right",
	},
}));

const BroadcastDialog = (props) => {
	const { t, meeting, show, onClose, saveBroadcast } = props;
	const widthUpSm = useWidthUp("sm");
	const [needsSaving, setNeedsSaving] = useState(false);
	const [isSaving, setIsSaving] = useState(false);
	const [selectWidth, setSelectWidth] = useState(0);
	const [selectedBoxcaster, setSelectedBoxcaster] = useState("");
	const [selectedBroadcastSource, setSelectedBroadcastSource] = useState("");
	const [multipleBoxcasters, setMultipleBoxcasters] = useState(true);
	const [broadcasts, setBroadcasts] = useState([]);
	const [videoList, setVideoList] = useState([{ value: "", label: "" }]);
	const [selectedVideo, setSelectedVideo] = useState("0");
	const [selectedYouTubeAccount, setSelectedYouTubeAccount] = useState("");
	const [nextPageToken, setNextPageToken] = useState(null);
	const classes = useStyles({ selectWidth });
	const inputClasses = useInputStyles({ fullWidth: true });
	const youtubeText = "YouTube - ";
	const boxcastText = "Boxcast - ";

	const loadVideos = () => {
		request
			.get(`${API_HOST}/api/getvideos${nextPageToken ? `?nextPageToken=${nextPageToken}` : ""}`)
			.withCredentials()
			.then((res) => {
				if (res.body) {
					const videoData = res.body;
					setNextPageToken(videoData.nextPageToken);
					if (videoData.videos) {
						setVideoList((prev) => {
							videoData.videos.forEach((video) => {
								if (!videoList.find((v) => v.value === video.value)) {
									prev.push(video);
								}
							});

							return [...prev];
						});
					}
				}
			});
	};

	useEffect(() => {
		let sourceCount = 0;
		if (meeting.boxcasters?.length > 0) {
			setSelectedBoxcaster(meeting.boxcasters[0].id);
			setSelectedBroadcastSource("boxcast");
			sourceCount += meeting.boxcasters.length;
		}

		if (meeting.rtmpSources?.length > 0) {
			if (sourceCount == 0) {
				setSelectedBoxcaster(meeting.rtmpSources[0].id);
				setSelectedBroadcastSource("rtmp");
			}
			sourceCount += meeting.rtmpSources.length;
		}

		setMultipleBoxcasters(sourceCount > 1);
	}, [meeting.boxcasts, meeting.rtmpSources]);

	useEffect(() => {
		setBroadcasts(clone(meeting.broadcasts));
	}, [meeting.broadcasts]);

	useEffect(() => {
		loadVideos();
		return () => {
			setNextPageToken(null);
		};
	}, [meeting]);

	useEffect(() => {
		if (meeting.youtubeAccounts && meeting.youtubeAccounts.length > 0) {
			setSelectedYouTubeAccount(meeting.youtubeAccounts[0].id);
		}
	}, [meeting.youtubeAccounts]);

	const getBoxcasters = () => {
		const boxcasterItems = [];
		if (meeting.boxcasters && meeting.boxcasters.length > 0) {
			meeting.boxcasters.forEach((boxcast) => {
				boxcasterItems.push(
					<MenuItem
						key={`boxcast-${boxcast.id}`}
						className={classes.menuItem}
						source="boxcast"
						value={boxcast.id}
						data-cy={`boxcast-${boxcast.id}`}
					>
						<div className={classes.menuItemText}>{processHtml(stripHtml(boxcast.name))}</div>
					</MenuItem>,
				);
			});
		}
		if (meeting.rtmpSources && meeting.rtmpSources.length > 0) {
			meeting.rtmpSources.forEach((rtmpSource) => {
				boxcasterItems.push(
					<MenuItem
						key={`rtmp-${rtmpSource.id}`}
						className={classes.menuItem}
						source="rtmp"
						value={rtmpSource.id}
						data-cy={`rtmp-${rtmpSource.id}`}
					>
						<div className={classes.menuItemText}>{processHtml(stripHtml(rtmpSource.name))}</div>
					</MenuItem>,
				);
			});
		}
		return boxcasterItems;
	};

	const handleBoxcasterChange = (e) => {
		const {
			target: { value },
		} = e;

		setSelectedBoxcaster(value);
		var boxcastSource = meeting.rtmpSources?.find((obj) => obj.id === value) ? "rtmp" : "boxcast";
		setSelectedBroadcastSource(boxcastSource);
	};

	const handleCreateBroadcast = () => {
		const newBroadcast = {
			broadcastId: "",
			channelId: "",
			youtubeId: "",
			title: meeting.name,
			boxcasterId: selectedBoxcaster,
			source: selectedBroadcastSource,
			youtubeAccountId: selectedYouTubeAccount,
		};

		setBroadcasts((previousBroadcasts) => {
			previousBroadcasts.push(newBroadcast);
			return [...nameVideoParts(previousBroadcasts)];
		});

		setNeedsSaving(true);
	};

	const getVideos = () => {
		const videoItems = [];
		if (videoList && videoList.length > 0) {
			videoItems.push(
				<MenuItem key={`boxcast-0`} className={classes.menuItem} value="0" data-cy={`boxcast-0`}>
					<div className={classes.menuItemText}>
						{videoList.length > 1 ? t("addBroadcastDialog.broadcastSelectBroadcast") : t("addBroadcastDialog.broadcastLoadingBroadcasts")}
					</div>
				</MenuItem>,
			);

			const boxcastVideos = videoList
				.filter((b) => b.type === "boxcast")
				.sort((a, b) => (a.start < b.start ? 1 : a.start > b.start ? -1 : 0));
			boxcastVideos.forEach((boxcastvideo) => {
				videoItems.push(
					<MenuItem
						key={`boxcast-${boxcastvideo.value}`}
						className={classes.menuItem}
						value={boxcastvideo.value}
						data-cy={`boxcast-${boxcastvideo.value}`}
					>
						<div className={classes.menuItemText}>{processHtml(stripHtml(boxcastText + boxcastvideo.label))}</div>
					</MenuItem>,
				);
			});

			const youtubeVideos = videoList
				.filter((b) => b.type === "youtube")
				.sort((a, b) => (a.start < b.start ? 1 : a.start > b.start ? -1 : 0));
			youtubeVideos.forEach((youtubevideo) => {
				videoItems.push(
					<MenuItem
						key={`youtube-${youtubevideo.value}`}
						className={classes.menuItem}
						value={youtubevideo.value}
						data-cy={`youtube-${youtubevideo.value}`}
					>
						<div className={classes.menuItemText}>{processHtml(stripHtml(youtubeText + youtubevideo.label))}</div>
					</MenuItem>,
				);
			});
		}
		return videoItems;
	};

	const handleVideoChange = (e) => {
		const {
			target: { value },
		} = e;

		setSelectedVideo(value);
	};

	const handleAddVideo = () => {
		const video = videoList.find((obj) => obj.value === selectedVideo);
		const boxcast = video.type === "boxcast";

		const newBroadcast = {
			broadcastId: boxcast ? video.value : "",
			channelId: boxcast ? video.channelId : "",
			youtubeId: boxcast ? "" : video.value,
			type: video.type,
			title: boxcast ? video.label.replace(boxcastText) : video.label.replace(youtubeText),
			boxcasterId: boxcast ? selectedBoxcaster : "",
			youtubeAccountId: boxcast ? selectedYouTubeAccount : "",
		};

		setBroadcasts((previousBroadcasts) => {
			previousBroadcasts.push(newBroadcast);
			return [...nameVideoParts(previousBroadcasts)];
		});

		setNeedsSaving(true);
	};

	const handleRemoveBroadcast = (broadcast) => {
		setBroadcasts((previousBroadcasts) => {
			const newBroadcast = broadcast.broadcastId
				? previousBroadcasts.filter((b) => b.broadcastId !== broadcast.broadcastId)
				: broadcast.youtubeId
				? previousBroadcasts.filter((b) => b.youtubeId !== broadcast.youtubeId)
				: [];

			return [...nameVideoParts(newBroadcast)];
		});

		setNeedsSaving(true);
	};

	const saveBroadcastData = (data) => {
		setIsSaving(true);
		saveBroadcast(data);
	};

	const handleCancel = () => {
		onClose();
	};

	const handleLoadMoteVideos = () => {
		loadVideos();
	};

	useEffect(() => {
		// Set the drop-down options width to match the width of the control
		const itemSelect = document.getElementById("item");
		if (itemSelect) {
			setSelectWidth(itemSelect.offsetWidth);
		}
	});

	return (
		<GenericDialog
			className={classes.dialog}
			show={show}
			title={t("addBroadcastDialog.title")}
			primaryAction={() => saveBroadcastData(broadcasts)}
			primaryTitle={isSaving ? t("app:buttons.saving") : t("app:buttons.save")}
			primaryDisabled={!needsSaving || isSaving}
			secondaryAction={handleCancel}
			secondaryTitle={t("app:buttons.cancel")}
			closeIcon={<Icon name="close" />}
			data-cy="add-broadcast-dialog"
			disableAutoFocus
			disableEnforceFocus
		>
			{multipleBoxcasters && (
				<Typography id={"boxcasterSelector-label"} className={classes.broadcastInputLabel} type="h2">
					{t("addBroadcastDialog.boxcastSelectSource")}
				</Typography>
			)}

			<Grid container direction="row" data-cy="boxcasterSelect">
				{multipleBoxcasters && (
					<Grid item sm={7} xs={12}>
						<SelectInput
							id="boxcasterSelector"
							className={clsx(inputClasses.smallInput, classes.selectBoxcast)}
							externalLabel
							size="small"
							value={selectedBoxcaster}
							onChange={(e) => handleBoxcasterChange(e)}
							data-cy="boxcasterSelector"
						>
							{getBoxcasters()}
						</SelectInput>
					</Grid>
				)}
				<Grid
					className={multipleBoxcasters && widthUpSm ? classes.selectButtonAlignRight : classes.selectButtonAlignCenter}
					item
					sm={multipleBoxcasters ? 5 : 12}
					xs={12}
				>
					<ButtonWithTooltip
						className={clsx(classes.selectButton, { [classes.selectButtonMarginLeft]: multipleBoxcasters && widthUpSm })}
						variant="outlined"
						color="primary"
						fullWidth={multipleBoxcasters}
						onClick={handleCreateBroadcast}
						data-cy="create-new-broadcast"
					>
						{t("addBroadcastDialog.createNewBroadcast")}
					</ButtonWithTooltip>
				</Grid>
			</Grid>

			{videoList.length > 1 && (
				<>
					<Typography id={"videosSelector-label"} className={classes.broadcastInputLabel} type="h2">
						{t("addBroadcastDialog.broadcastSelectBroadcast")}
					</Typography>

					<Grid container direction="row" data-cy="broadcastSelect">
						<Grid item sm={7} xs={12}>
							<SelectInput
								id="videosSelector"
								className={clsx(inputClasses.smallInput, classes.selectBroadcast)}
								externalLabel
								size="small"
								value={selectedVideo}
								onChange={(e) => handleVideoChange(e)}
								data-cy="videoSelector"
								disabled={videoList.length === 1}
							>
								{getVideos()}
							</SelectInput>
						</Grid>
						<Grid className={classes.selectButtonAlignRight} item sm={5} xs={12}>
							{nextPageToken && (
								<ButtonWithTooltip
									className={clsx(classes.selectButton, { [classes.selectButtonMarginLeft]: widthUpSm })}
									variant="outlined"
									color="primary"
									onClick={handleLoadMoteVideos}
									data-cy="load-more-broadcast"
								>
									{t("buttons.loadMore")}
								</ButtonWithTooltip>
							)}
							<ButtonWithTooltip
								className={clsx(classes.selectButton, { [classes.selectButtonMarginLeft]: widthUpSm })}
								variant="outlined"
								color="primary"
								fullWidth={!nextPageToken}
								onClick={handleAddVideo}
								data-cy="add-broadcast"
								disabled={!selectedVideo || selectedVideo === "0"}
							>
								{t("addBroadcastDialog.broadcastSelectAdd")}
							</ButtonWithTooltip>
						</Grid>
					</Grid>
				</>
			)}
			{broadcasts.length > 0 && (
				<Box m={0} p={0} data-cy="currentVideos" component="span">
					<Typography type="h2">{t("currentVideos")}</Typography>
					<Box m="14px">
						{broadcasts.map((broadcast) => (
							<BroadcastRow
								key={broadcast.broadcastId}
								broadcast={broadcast}
								removeBroadcast={handleRemoveBroadcast}
								multipleBroadcasts={broadcasts.length > 1}
							/>
						))}
					</Box>
				</Box>
			)}
		</GenericDialog>
	);
};

export default withTranslation("meetings")(BroadcastDialog);
