// Redux stuff:
import { applyMiddleware, createStore } from "redux";

import { composeWithDevTools } from "redux-devtools-extension";

// middlewares
import { thunk } from "redux-thunk";

import { Reducers } from "./reducers";

const middlewares = applyMiddleware(thunk);
const compose = composeWithDevTools(middlewares);
// TODO Implement a proper way of finding out we are in prod to disable devTools
// if (IsProduction) {
// 	compose = middlewares;
// }
export default createStore(Reducers, compose);
