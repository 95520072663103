import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import request from "superagent";

import makeStyles from "@mui/styles/makeStyles";
import withStyles from "@mui/styles/withStyles";
import { Box, Button } from "@mui/material";

import Icon from "atlas/components/Icon/Icon";
import GenericDialog from "atlas/components/Dialogs/GenericDialog";
import { API_HOST } from "config/env";
import { FOLDER_TYPE_CUSTOM } from "utils/enums/DocumentFolderTypes";
import notifierMessage from "utils/notifierMessage";
import { setSnackbarOptions } from "redux/snackBar/actions";

const useStyles = makeStyles(() => ({
	dialog: {
		"& .MuiDialog-paper": {
			width: "400px",
			maxWidth: "100%",
		},
	},
}));

const StyledButton = withStyles({
	root: {
		color: "#ffffff",
		marginTop: 0,
	},
})(Button);

const DocumentDeleteDialog = (props) => {
	const { show = true, document, parentGuid, onClose, afterDelete, undoDelete } = props;
	const { t } = useTranslation("documents");
	const dispatch = useDispatch();
	const [deleting, setDeleting] = useState(false);
	const classes = useStyles();
	const customFolder = document.folderType === FOLDER_TYPE_CUSTOM;

	const handleDelete = () => {
		const action = (key) => (
			<StyledButton
				onClick={() => {
					closeSnackbar(key);
					undoDelete(document, parentGuid);
				}}
				data-cy="undo"
			>
				{t("app:buttons.undo")}
			</StyledButton>
		);

		setDeleting(true);

		request
			.delete(`${API_HOST}/api/document/${document.guid}/detail`)
			.withCredentials()
			.send({})
			.then(() => {
				onClose();
				let option = notifierMessage(
					t(`deleteDocumentDialog.snackbar.success.${document.folder ? "folder" : "document"}`, { title: document.title }),
					"success",
					() => undoDelete(document, parentGuid),
				);
				dispatch(setSnackbarOptions(option));
				afterDelete(document, parentGuid);
			})
			.catch((err) => handleError(err));
	};

	const handleCancel = () => {
		onClose();
	};

	const handleError = (err) => {
		console.log("Error", err);
		onClose();
	};

	const i18n = t("deleteDocumentDialog", { title: document.title });

	const dialog = {
		title: i18n.title[customFolder ? "customFolder" : document.folder ? "folder" : "document"],
		line1: customFolder ? i18n.customFolderLine1 : i18n.line1,
		primaryTitle: t("app:buttons.delete"),
		primaryAction: handleDelete,
		secondaryTitle: t("app:buttons.cancel"),
		secondaryAction: handleCancel,
	};

	return (
		<GenericDialog
			className={classes.dialog}
			show={show}
			title={dialog.title}
			primaryAction={dialog.primaryAction}
			primaryTitle={dialog.primaryTitle}
			primaryDisabled={deleting}
			secondaryAction={dialog.secondaryAction}
			secondaryTitle={dialog.secondaryTitle}
			secondaryDisabled={deleting}
			clickAwayDisabled={deleting}
			closeIcon={<Icon name="close" />}
			data-cy="delete-dialog"
		>
			<Box fontWeight="fontWeightBold">{dialog.line1}</Box>
		</GenericDialog>
	);
};

export default DocumentDeleteDialog;
