import React, { useEffect, useRef } from "react";

import { Button, ButtonGroup } from "@mui/material";

import Icon from "atlas/components/Icon/Icon";
import { handleMoveButtonKeyDown } from "./tocUtils";

const MoveButtons = ({ moveItem, item, showMoveUp = false, showMoveDown = false }) => {
	const retainKeyboardFocus = useRef(null);
	const moveUpButton = useRef(null);
	const moveDownButton = useRef(null);

	const checkKeyboardFocus = (button, moveUp) => {
		if (button?.matches(":focus-visible")) {
			retainKeyboardFocus.current = { moveUp };
		}
	};

	const handleMove = (event, moveUp) => {
		checkKeyboardFocus(event.target, moveUp);

		event.stopPropagation();
		moveItem(item, moveUp);
	};

	useEffect(() => {
		if (retainKeyboardFocus.current) {
			// If the move button was previously keyboard focused, focus it again after the list re-renders.
			const button = retainKeyboardFocus.current.moveUp ? moveUpButton : moveDownButton;
			const otherButton = retainKeyboardFocus.current.moveUp ? moveDownButton : moveUpButton;

			setTimeout(() => {
				const activeButton = button.current || otherButton.current; // If the button is not rendered, focus the other button. This happens at the top and bottom of the list.
				activeButton?.parentNode?.closest("[tabindex]")?.focus({ focusVisible: true });
				activeButton?.focus({ focusVisible: true });
			}, 0);

			retainKeyboardFocus.current = null;
		}
	}, [item.fields.Order.Value]);

	return (
		<ButtonGroup id="moveButtons" className="toc-move-buttons" variant="contained" color="primary" aria-label="split button">
			{showMoveUp && (
				<Button
					ref={moveUpButton}
					disableRipple
					variant="outlined"
					className="toc-move-button"
					onClick={(e) => handleMove(e, true)}
					onKeyDown={handleMoveButtonKeyDown}
					tabIndex={-1}
					data-cy={`move-up-${item.guid}`}
				>
					<Icon name="expand-up" size={24} color={"#455D82"} />
				</Button>
			)}
			{showMoveDown && (
				<Button
					ref={moveDownButton}
					disableRipple
					className="toc-move-button"
					variant="outlined"
					onClick={(e) => handleMove(e, false)}
					onKeyDown={handleMoveButtonKeyDown}
					tabIndex={-1}
					data-cy={`move-down-${item.guid}`}
				>
					<Icon name="expand-down" size={24} color={"#455D82"} />
				</Button>
			)}
		</ButtonGroup>
	);
};

export default MoveButtons;
