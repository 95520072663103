import { v4 as uuid } from "uuid";

import { HEADINGS, MEETING_ITEMS, DECISIONS } from "./enums/ItemTypes";
import { stripHtml } from "./processHtml";
import { customNumberingSelected } from "./customNumberingConversions";

export const ATTACHMENT_CLASS = "inlineFile";
export const MEMBERS_ONLY_ATTACHMENT_CLASS = "closed";
export const STATUS_SAVED_TIMEOUT = 60000;

export const sortMinuteItemsByAgendaItems = (agendaItems, minuteItems) => {
	const agendaMap = new Map();
	agendaItems.forEach((item, index) => {
		agendaMap.set(item.guid, index);
	});

	const matchedItems = [];
	const unmatchedItems = [];

	minuteItems.forEach((item) => {
		if (agendaMap.has(item.agendaItemGuid)) {
			matchedItems.push(item);
		} else {
			unmatchedItems.push(item);
		}
	});
	matchedItems.sort((a, b) => agendaMap.get(a.agendaItemGuid) - agendaMap.get(b.agendaItemGuid));

	const reorderedItems = matchedItems.concat(unmatchedItems);

	updateOrderAndNumbering(
		{ items: reorderedItems, itemIdsToDelete: [] },
		reorderedItems.filter((item) => !item.attributes?.relationshipGuid && !item.deleted),
		undefined,
		reorderedItems,
	);

	return reorderedItems;
};

export const createMeetingElement = (initialData, agenda = true) => {
	initialData = initialData || {};
	let item = {
		guid: initialData.guid || uuid(),
		itemType: initialData.itemType,
		fields: {
			Order: { Value: initialData.order || 1 },
			Indent: { Value: initialData.indent || 0 },
			Number: { Value: initialData.number || "" },
			Name: { Value: initialData.name || "" },
			Text: { Value: initialData.text || null },
			Closed: { Value: initialData.closed || false },
			Consent: { Value: initialData.consent || false },
			PublicComment: { Value: initialData.publicComment || false },
			BoardNotes: agenda ? { Value: initialData.boardNotes || null } : undefined,
			MovedBy: !agenda ? { Value: initialData.movedBy || 0 } : undefined,
			SecondedBy: !agenda ? { Value: initialData.secondedBy || 0 } : undefined,
			Disposition: !agenda ? { Value: initialData.disposition || "" } : undefined,
			RecordedVote: { Value: initialData.recordedVote || false },
		},
		delete: false,
		attributes: {
			relationshipGuid: initialData.parentGuid,
		},
		attachments: [],
		attachmentMapping: initialData.attachmentMapping || undefined,
		added: true,
	};
	if (!agenda && initialData.agendaItemGuid) {
		item.agendaItemGuid = initialData.agendaItemGuid;
	}

	return item;
};

const prepareAttachmentDuplicationInField = (item, field) => {
	const hrefText = "/document/";
	if (item?.fields && item.fields[field]?.Value && item.fields[field].Value.indexOf(ATTACHMENT_CLASS) >= 0) {
		// Get each attachment GUID
		let startPosition = item.fields[field].Value.indexOf("<a");
		while (startPosition >= 0) {
			let endPosition = item.fields[field].Value.indexOf(">", startPosition);
			if (endPosition < 0) {
				break;
			}

			startPosition = item.fields[field].Value.indexOf(hrefText, startPosition);
			if (startPosition >= 0 && startPosition < endPosition) {
				startPosition += hrefText.length;
				let hrefEndPosition = item.fields[field].Value.indexOf('"', startPosition);
				if (hrefEndPosition >= 0) {
					// Create new attachment mapping
					const attachmentGuid = item.fields[field].Value.substring(startPosition, hrefEndPosition);
					const newAttachmentGuid = uuid();

					item.attachmentMapping = item.attachmentMapping || [];
					if (!item.attachmentMapping.find((mapping) => mapping.source === attachmentGuid || mapping.target === attachmentGuid)) {
						// The attachment is not already mapped, so add it to the mapping
						item.attachmentMapping.push({ source: attachmentGuid, target: newAttachmentGuid });
					}
					if (!item.attachmentMapping.find((mapping) => mapping.target === attachmentGuid)) {
						// The attachment has not yet been replaced, so replace it
						item.fields[field].Value = item.fields[field].Value.replace(attachmentGuid, newAttachmentGuid);
					}
				}
			}

			startPosition = item.fields[field].Value.indexOf("<a", endPosition);
		}
	}

	return item;
};

export const prepareAttachmentDuplication = (item) => {
	prepareAttachmentDuplicationInField(item, "Name");
	prepareAttachmentDuplicationInField(item, "Text");
	prepareAttachmentDuplicationInField(item, "BoardNotes");

	return item;
};

const getItem = (items, guid) => (items && guid ? items.find((item) => item.guid === guid) : null);

export const isHeading = (items, guid) => {
	const item = getItem(items, guid);

	return item && HEADINGS.includes(item.itemType) && item.fields?.Indent?.Value === 0;
};

export const isSubHeading = (items, guid) => {
	const item = getItem(items, guid);

	return item && HEADINGS.includes(item.itemType) && item.fields?.Indent?.Value > 0;
};

export const isItem = (items, guid) => {
	const item = getItem(items, guid);

	return item && MEETING_ITEMS.includes(item.itemType);
};

export const isDecision = (items, guid) => {
	const item = getItem(items, guid);

	return item && DECISIONS.includes(item.itemType);
};

export const getAttachmentLinkText = (item, attachmentGuid) => {
	if (!item || !attachmentGuid) {
		return "";
	}

	// Get the link text for the specified attachment GUID
	const itemText = [item.fields?.Text?.Value || "", item.fields?.Name?.Value || ""];
	for (const text of itemText) {
		let position = text.indexOf(attachmentGuid);
		if (position >= 0) {
			position = text.indexOf(">", position);
			if (position >= 0) {
				let endPosition = text.indexOf("</a>", position);
				if (endPosition >= 0) {
					return stripHtml(text.substring(position + 1, endPosition));
				}
			}
		}
	}

	return "";
};

export const mergeMeetingPersistanceData = (currentData, previousData) => {
	// Merge the current persistance data with the old data (that failed to save)
	if (currentData && previousData) {
		const mergedData = { ...previousData, ...currentData }; // Get older data and then override with matching newer data

		if (previousData.items) {
			mergedData.items = mergedData.items || [];
			mergedData.items = mergedData.items.concat(previousData.items.filter((item) => !mergedData.items.find((i) => i.guid === item.guid)));
		}

		if (previousData.itemIdsToDelete) {
			mergedData.itemIdsToDelete = mergedData.itemIdsToDelete || [];
			mergedData.itemIdsToDelete = mergedData.itemIdsToDelete.concat(
				previousData.itemIdsToDelete.filter((oldGuid) => !mergedData.itemIdsToDelete.find((guid) => oldGuid === guid)),
			);
		}

		return mergedData;
	}

	return previousData;
};

export const addTableWidth = (field, fieldValue) => {
	const tableFragment = "<table";
	const cellFragment = "<td";
	const editorToDocumentFontSizeRatio = 1.125; //.25; // The actual ratio is 1.125, but for some reason it is not enough to prevent word wrapping when it should not happen

	let processedValue = fieldValue;
	if (typeof fieldValue === "string" && fieldValue.indexOf(tableFragment) >= 0) {
		// Get the width of each table in the field
		const tableWidths = Array.from(
			document.querySelectorAll(`div[contenteditable='true'][data-fieldname="${field.fieldName}"] table`),
			(table) => ({
				width: table.getBoundingClientRect().width * editorToDocumentFontSizeRatio,
				cells: Array.from(table.querySelectorAll("td"), (cell) => cell.getBoundingClientRect().width * editorToDocumentFontSizeRatio),
			}),
		);

		// Remove existing width data
		processedValue = processedValue.replace(/data-width="[^"]*"/g, "");

		// Add the width data to each table
		let lastPosition = 0;
		let position = processedValue.indexOf(tableFragment);
		let index = 0;
		let finalValue = "";
		while (position >= 0 && index < tableWidths.length) {
			finalValue += processedValue.substring(lastPosition, position) + `${tableFragment} data-width="${tableWidths[index].width}px"`;
			lastPosition = position + tableFragment.length;

			// Add the width data to the cells of the table
			position = processedValue.indexOf(cellFragment, position + 1);
			let cellIndex = 0;
			while (position >= 0 && cellIndex < tableWidths[index].cells.length) {
				finalValue +=
					processedValue.substring(lastPosition, position) +
					`${cellFragment} data-width="${(tableWidths[index].cells[cellIndex] / tableWidths[index].width) * 100}%"`;
				lastPosition = position + cellFragment.length;

				// Only look for the next cell if there are more cells - This is prevent entering the next table
				if (cellIndex < tableWidths[index].cells.length - 1) {
					position = processedValue.indexOf(cellFragment, position + 1);
				}
				cellIndex++;
			}

			position = processedValue.indexOf(tableFragment, position + 1);
			index++;
		}
		finalValue += processedValue.substring(lastPosition);

		processedValue = finalValue;
	}

	return processedValue;
};

export const updateOrderAndNumbering = (persistObject, siblings, parentItem, items, newItemGuid) => {
	updateOrdering(persistObject, siblings, items, newItemGuid);
	updateNumbering(persistObject, getChildNumberingItems(parentItem, items), parentItem, items, newItemGuid);
	updateFlag(persistObject, getChildNumberingItems(parentItem, items), parentItem, items, newItemGuid);
};

export const updateOrdering = (persistObject, siblings, items, newItemGuid) => {
	if (siblings && siblings.length > 0) {
		let newOrder = 1;
		siblings.forEach((sibling) => {
			if (sibling.fields?.Order?.Value !== newOrder || (newItemGuid && sibling.guid === newItemGuid)) {
				// Update order
				sibling.fields.Order.Value = newOrder;
				if (!persistObject.items.includes(sibling)) {
					persistObject.items.push(sibling);
				}
			}

			// Update child ordering
			updateOrdering(
				persistObject,
				items.filter((item) => item.attributes.relationshipGuid === sibling.guid && !item.deleted),
				items,
				newItemGuid,
			);

			newOrder++;
		});
	}
};

const canBeParent = (item, parent) => {
	if (!parent || !item) {
		return false;
	}

	if (HEADINGS.includes(item.itemType)) {
		if (item.fields?.Indent?.Value === 0) {
			// Heading
			return false;
		} else {
			// Sub heading
			return HEADINGS.includes(parent.itemType) && parent.fields?.Indent?.Value === 0;
		}
	} else if (MEETING_ITEMS.includes(item.itemType)) {
		// Item
		return HEADINGS.includes(parent.itemType);
	} else if (DECISIONS.includes(item.itemType)) {
		// Decision
		return !DECISIONS.includes(parent.itemType);
	}
};

export const updateRelationships = (persistObject, items) => {
	let parents = [];
	for (let index = 0; index < items.length; index++) {
		const item = items[index];

		// Non headings
		if (!HEADINGS.includes(item.itemType) || item.fields.Indent.Value > 0) {
			// Find the parent
			while (parents.length > 0 && !canBeParent(item, parents[parents.length - 1])) {
				parents.splice(parents.length - 1, 1);
			}
			const parent = parents.length > 0 ? parents[parents.length - 1] : null;
			if (parent) {
				if (item.attributes.relationshipGuid !== parent.guid) {
					// Update relationship
					item.attributes.relationshipGuid = parent.guid;
					if (!persistObject.items.includes(item)) {
						persistObject.items.push(item);
					}
				}
			}
		}

		parents.push(item);
	}
};

export const getChildNumberingItems = (parent, items) => {
	let children = [];
	if (items && items.length > 0) {
		if (!parent) {
			children = items.filter((item) => !item.attributes?.relationshipGuid && !item.deleted);
		} else if (parent && HEADINGS.includes(parent.itemType)) {
			let parentItem = parent;
			// Get the top-level heading
			if (parent.fields?.Indent?.Value === 1) {
				parentItem = items.find((item) => item.guid === parentItem.attributes.relationshipGuid);
			}

			if (parentItem) {
				let foundParent = false;
				for (let index = 0; index < items.length; index++) {
					const item = items[index];
					if (item.guid === parentItem.guid) {
						foundParent = true;
					} else if (foundParent) {
						if (MEETING_ITEMS.includes(item.itemType) && !item.deleted) {
							children.push(item);
						} else if (HEADINGS.includes(item.itemType) && item.fields?.Indent?.Value === 0) {
							// Next top-level heading so stop
							break;
						}
					}
				}
			}
		}
	}

	return children;
};

export const updateFlag = (persistObject, siblings, parentItem, items, newItemGuid) => {
	if (siblings && siblings.length > 0) {
		const closedValue = parentItem?.fields?.Closed?.Value || "";
		const ConsentValue = parentItem?.fields?.Consent?.Value || "";
		const PublicCommentValue = parentItem?.fields?.PublicComment?.Value || "";

		siblings.forEach((sibling) => {
			if (
				closedValue != sibling.fields?.Closed?.Value ||
				ConsentValue != sibling.fields?.Consent?.Value ||
				PublicCommentValue != sibling.fields?.PublicComment?.Value
			) {
				if (parentItem != undefined) {
					sibling.fields.Closed.Value = closedValue;
					sibling.fields.Consent.Value = ConsentValue;
					sibling.fields.PublicComment.Value = PublicCommentValue;

					if (closedValue) {
						updateAttachmentLinks(sibling);
					}

					if (!persistObject.items.includes(sibling) && !persistObject.items.includes(getAllChildren(parentItem, items))) {
						persistObject.items.push(sibling);
					}
				}
			}

			updateFlag(
				persistObject,
				items.filter((item) => item.attributes.relationshipGuid === sibling.guid && !item.deleted),
				sibling,
				items,
				newItemGuid,
			);
		});
	}
};

export const updateAttachmentLinks = (item, persistItem) => {
	if (item.fields.Name.Value) {
		item.fields.Name.Value = item.fields.Name.Value.replaceAll('class="inlineFile"', 'class="inlineFile closed"');
	}
	if (item.fields.Text.Value) {
		item.fields.Text.Value = item.fields.Text.Value.replaceAll('class="inlineFile"', 'class="inlineFile closed"');
	}
	if (persistItem) {
		persistItem.fields.Name.Value = item.fields.Name.Value;
		persistItem.fields.Text.Value = item.fields.Text.Value;
	}
};

export const updateNumbering = (persistObject, siblings, parentItem, items, newItemGuid) => {
	if (siblings && siblings.length > 0) {
		// Get the heading number
		let parentNumber = parentItem?.fields?.Number?.Value || "";
		if (parentItem?.fields?.Indent?.Value === 1 && HEADINGS.includes(parentItem.itemType)) {
			parentNumber = items.find((item) => item.guid === parentItem.attributes.relationshipGuid)?.fields?.Number?.Value || "";
		}

		let newNumber = 1;
		siblings.forEach((sibling) => {
			const usesNumbering =
				(HEADINGS.includes(sibling.itemType) && sibling.fields.Indent.Value === 0) || MEETING_ITEMS.includes(sibling.itemType);
			let newNumberText = usesNumbering ? (parentNumber ? parentNumber + newNumber.toString() : `${newNumber}.`) : "";

			if (persistObject && persistObject.customNumbering) {
				newNumberText = customNumberingSelected(persistObject.customNumbering, newNumber, usesNumbering, parentNumber);
			}

			if (sibling.fields?.Number?.Value !== newNumberText || (newItemGuid && sibling.guid === newItemGuid)) {
				// Update bullet
				sibling.fields.Number.Value = newNumberText;
				if (!persistObject.items.includes(sibling)) {
					persistObject.items.push(sibling);
				}
			}

			// Update child numbering
			if (usesNumbering && HEADINGS.includes(sibling.itemType)) {
				updateNumbering(persistObject, getChildNumberingItems(sibling, items), sibling, items, newItemGuid);
			}

			if (usesNumbering) {
				newNumber++;
			}
		});
	}
};

export const getAllChildren = (parent, items) => {
	const guid = parent.guid || parent;
	let children = [];
	if (guid && items) {
		items.forEach((item) => {
			if (item.attributes.relationshipGuid === guid) {
				children.push(item);
				children = children.concat(getAllChildren(item, items));
			}
		});
	}

	return children;
};
