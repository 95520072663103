import React, { useState } from "react";
import { adoptMinutes, uploadMinutes } from "components/PdfViewerNew/PdfAPICalls";
import telemetryAddEvent from "utils/telemetryAddEvent";
import { useDispatch } from "react-redux";
import { updatePageConfigs } from "redux/app/actions";
import { displaySuccessNotification } from "views/Policy/utils/getNotification";
import ButtonWithTooltip from "atlas/components/Buttons/ButtonWithTooltip";
import CircularProgressIndicator from "atlas/components/Progress/CircularProgressIndicator";
import { useNavigate } from "react-router-dom";

const SignAndAdopt = (props) => {
	const [uploading, setuploading] = useState(false);
	const [adopting, setadopting] = useState(false);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { enableSignatures, showPrintButton, ti18n, settings, instance, id } = props;
	const { url, members, adopted, meetingId: settingsMettingId, meetingName } = settings;

	//This method will be triggered in sign and adopt page when print button is clicked
	const printDocumentWithSignatures = async () => {
		let documentUrl = url;
		if (enableSignatures) {
			const { PDFNet, documentViewer, annotationManager } = instance.Core;
			await PDFNet.initialize();
			const doc = await documentViewer.getDocument();
			const xfdfString = await annotationManager.exportAnnotations();
			const options = { xfdfString, flatten: true };
			const data = await doc.getFileData(options);
			const arr = new Uint8Array(data);
			const blob = new Blob([arr], { type: "application/pdf" });
			documentUrl = URL.createObjectURL(blob);
		}
		telemetryAddEvent("Minutes Sign and Adopt - Print");
		window.open(documentUrl, "_blank");
	};

	//This method will be triggered when once user has signed the document, to adopt the document
	const adoptDocumentWithSignature = async () => {
		if (enableSignatures) {
			setadopting(true);

			const { PDFNet, documentViewer, annotationManager, SaveOptions } = instance.Core;
			await PDFNet.initialize();
			const doc = await documentViewer.getDocument();
			const xfdfString = await annotationManager.exportAnnotations();
			const options = { xfdfString, flatten: false, flags: SaveOptions.INCREMENTAL };
			const data = await doc.getFileData(options);
			const arr = new Uint8Array(data);
			const file = new File([arr], `${meetingName}-Adopted.pdf`, { type: "application/pdf" });

			const fileData = new FormData();
			fileData.append("file", file);
			fileData.append(
				"data",
				JSON.stringify({
					documentId: id,
					members,
				}),
			);
			adoptMinutes(settingsMettingId, fileData)
				.then((adoptMinutesResponse) => {
					setadopting(false);
					if (adoptMinutesResponse.status === 200) {
						displaySuccessNotification(ti18n("approveMeetingDialog.success.minutes"), dispatch);
						telemetryAddEvent(`Minutes Sign and Adopt - ${adopted ? "Update Adopted" : "Adopt"}`);
						const query = new URLSearchParams(window.location.search);
						const liveMeeting = query.get("liveMeeting");
						setTimeout(() => {
							dispatch(
								updatePageConfigs({
									preferedBack: { url: `${window.location.pathname.replace("/home/", "/")}` },
								}),
							);
							navigate(
								`/meeting/final/${settingsMettingId}/minutes/${members ? "members" : "public"}?adopted=true${
									liveMeeting > 0 ? `&liveMeeting=${liveMeeting}` : ""
								}`,
							);
						}, 500);
					}
				})
				.catch((err) => {
					setadopting(false);
					console.log(err);
				});
		}
	};

	//This method will be called once user selects any document from syatem and upload that in place of existing document
	const handleDraftUpload = () => {
		setuploading(true);
		const fileInput = document.getElementById(`upload-${members ? "members" : "public"}-minutes-file`);
		const selectedFile = fileInput.files[0];
		const fileData = new FormData();
		fileData.append(selectedFile.name, selectedFile);
		fileData.append(
			"data",
			JSON.stringify({
				members,
				adopted: true,
				draft: true,
			}),
		);
		uploadMinutes(settingsMettingId, fileData)
			.then((minutesResponse) => {
				setuploading(false);
				if (minutesResponse.status === 200) {
					displaySuccessNotification(ti18n(`notifications.minutesUploaded`), dispatch);
					telemetryAddEvent("Minutes Sign and Adopt - Upload Draft");
					window.location.reload();
				}
			})
			.catch((exception) => {
				setuploading(false);
			});
	};

	//This method will be triggered when user is opening dialog box for selecting file to upload
	const openDraftAdoptedFileSelect = () => {
		const fileInput = document.getElementById(`upload-${members ? "members" : "public"}-minutes-file`);
		if (fileInput) {
			fileInput.value = null;
			fileInput.click();
		}
	};

	return (
		<>
			{(enableSignatures || showPrintButton) && (
				<div className="pdf-viewer-action-buttons">
					{enableSignatures && (
						<>
							<input
								id={`upload-${members ? "members" : "public"}-minutes-file`}
								type="file"
								onChange={(e) => {
									handleDraftUpload(e);
								}}
								style={{ display: "none" }}
								accept=".pdf"
								title={ti18n("tooltips.uploadAdoptedMemberMinutes")}
							/>
							<ButtonWithTooltip
								className="pdf-viewer-upload"
								primary
								color="primary"
								onClick={() => {
									openDraftAdoptedFileSelect();
								}}
								title={ti18n(`tooltips.upload${members ? "Members" : "Public"}MinutesToAdopt`)}
								disabled={uploading}
								data-cy="upload-draft-minutes"
							>
								{uploading ? <CircularProgressIndicator size={24} color="secondary" /> : ti18n("buttons.uploadDraft")}
							</ButtonWithTooltip>
						</>
					)}
					<span style={{ flexGrow: 1 }} />
					{(enableSignatures || showPrintButton) && (
						<span className="pdf-viewer-print">
							<ButtonWithTooltip
								variant="outlined"
								onClick={() => {
									printDocumentWithSignatures();
								}}
								title={ti18n("inlineFile.tooltips.print")}
								data-cy="print-minutes"
							>
								{ti18n("buttons.print")}
							</ButtonWithTooltip>
						</span>
					)}
					{enableSignatures && (
						<ButtonWithTooltip
							primary
							color="primary"
							onClick={() => {
								adoptDocumentWithSignature();
							}}
							title={ti18n("tooltips.adoptMinutes")}
							disabled={adopting}
							data-cy="adopt-minutes"
						>
							{adopting ? (
								<CircularProgressIndicator size={24} color="secondary" />
							) : (
								ti18n(`app:buttons.${adopted ? "updateAdopt" : "signAdopt"}`)
							)}
						</ButtonWithTooltip>
					)}
				</div>
			)}
		</>
	);
};

export default SignAndAdopt;
