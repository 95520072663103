import React from "react";
import { useTranslation } from "react-i18next";
import clsx from "clsx";

import { Checkbox, FormControlLabel, Radio } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { errorColor } from "atlas/assets/jss/shared";
import { useWidthDown } from "atlas/utils/useWidth";
import { Check } from "components/Icons";

const useStyles = makeStyles((theme) => ({
	userCheckbox: {
		width: "48px",
		textAlign: "center",
		boxSizing: "border-box",
		[theme.breakpoints.down("md")]: {
			width: "100%",
			textAlign: "left",
		},
	},
	userCheckboxDisabled: {
		opacity: "0.6",
	},
	userCheckboxRequired: {
		color: errorColor,
	},
}));

const BoardUserOptions = (props) => {
	const { boardUser, handleUpdate } = props;
	const mobile = useWidthDown("md");
	const { t } = useTranslation("boards");
	const classes = useStyles();

	const getCheckbox = (key, toolTip, labelledBy, checked, onChange, disabled, dataCy) => (
		<Checkbox
			key={key}
			checkedIcon={<Check fontSize="small" color="primary" />}
			inputProps={{
				"aria-label": toolTip,
				"aria-labelledby": labelledBy,
			}}
			checked={checked}
			onChange={onChange}
			disabled={disabled}
			data-cy={dataCy}
		/>
	);

	const getLabeledCheckbox = (key, label, useLabel, toolTip, labelledBy, checked, onChange, disabled, dataCy) =>
		useLabel ? (
			<FormControlLabel control={getCheckbox(key, toolTip, labelledBy, checked, onChange, disabled)} label={label} data-cy={dataCy} />
		) : (
			getCheckbox(key, toolTip, labelledBy, checked, onChange, disabled, dataCy)
		);

	const rollCallOptionsDisabled = !boardUser.member && !boardUser.staff && !boardUser.administrator;

	return boardUser ? (
		<>
			<div
				className={clsx(classes.userCheckbox, {
					[classes.userCheckboxRequired]: rollCallOptionsDisabled && handleUpdate,
					[classes.userCheckboxDisabled]: !handleUpdate,
				})}
			>
				{getLabeledCheckbox(
					`member-${boardUser.userId}`,
					t("detail.member"),
					mobile,
					t("tooltips.member"),
					"member-label",
					boardUser.member,
					(e) =>
						handleUpdate(e, "member", true, false, boardUser, undefined, (objectToUpdate) => {
							if (objectToUpdate.member) {
								objectToUpdate.tieBreaker = false;
							}
							objectToUpdate.votingMember = objectToUpdate.member;
							objectToUpdate.showOnPortal = objectToUpdate.member;
						}),
					!handleUpdate,
					"member",
				)}
			</div>
			<div
				className={clsx(classes.userCheckbox, {
					[classes.userCheckboxRequired]: rollCallOptionsDisabled && handleUpdate,
					[classes.userCheckboxDisabled]: !handleUpdate,
				})}
			>
				{getLabeledCheckbox(
					`staff-${boardUser.userId}`,
					t("detail.staff"),
					mobile,
					t("tooltips.staff"),
					"staff-label",
					boardUser.staff,
					(e) => handleUpdate(e, "staff", true, false, boardUser),
					!handleUpdate,
					"staff",
				)}
			</div>
			<div
				className={clsx(classes.userCheckbox, {
					[classes.userCheckboxRequired]: rollCallOptionsDisabled && handleUpdate,
					[classes.userCheckboxDisabled]: !handleUpdate,
				})}
			>
				{getLabeledCheckbox(
					`administrator-${boardUser.userId}`,
					t("detail.administrator"),
					mobile,
					t("tooltips.administrator"),
					"administrator-label",
					boardUser.administrator,
					(e) => handleUpdate(e, "administrator", true, false, boardUser),
					!handleUpdate,
					"administrator",
				)}
			</div>
			<div
				className={clsx(classes.userCheckbox, {
					[classes.userCheckboxDisabled]: rollCallOptionsDisabled || !handleUpdate,
				})}
			>
				{getLabeledCheckbox(
					`voting-member-${boardUser.userId}`,
					t("detail.votingMember"),
					mobile,
					t("tooltips.votingMember"),
					"voting-member-label",
					boardUser.votingMember && !rollCallOptionsDisabled,
					(e) =>
						handleUpdate(e, "votingMember", true, false, boardUser, undefined, (objectToUpdate) => {
							if (objectToUpdate.votingMember) {
								objectToUpdate.tieBreaker = false;
							}
						}),
					rollCallOptionsDisabled || !handleUpdate,
					"voting-member",
				)}
			</div>
			<div
				className={clsx(classes.userCheckbox, {
					[classes.userCheckboxDisabled]: rollCallOptionsDisabled || !handleUpdate,
				})}
			>
				{getLabeledCheckbox(
					`tie-breaker-${boardUser.userId}`,
					t("detail.tieBreaker"),
					mobile,
					t("tooltips.tieBreaker"),
					"tie-breaker-label",
					boardUser.tieBreaker && !rollCallOptionsDisabled,
					(e) =>
						handleUpdate(e, "tieBreaker", true, false, boardUser, undefined, (objectToUpdate) => {
							if (objectToUpdate.tieBreaker) {
								objectToUpdate.votingMember = false;
							}
						}),
					rollCallOptionsDisabled || !handleUpdate,
					"tie-breaker",
				)}
			</div>
			<div
				className={clsx(classes.userCheckbox, {
					[classes.userCheckboxDisabled]: rollCallOptionsDisabled || !handleUpdate,
				})}
			>
				{getLabeledCheckbox(
					`show-on-portal-${boardUser.userId}`,
					t("detail.showOnPortal"),
					mobile,
					t("tooltips.showOnPortal"),
					"show-on-portal-label",
					boardUser.showOnPortal && !rollCallOptionsDisabled,
					(e) => handleUpdate(e, "showOnPortal", true, false, boardUser),
					rollCallOptionsDisabled || !handleUpdate,
					"show-on-portal",
				)}
			</div>
		</>
	) : null;
};

export default BoardUserOptions;
