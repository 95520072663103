import React from "react";

import ApproversListApprover from "./ApproversListApprover";

const ApproversListApprovers = (props) => {
	const { block, indent } = props;

	return (
		block &&
		block.users.map((user) => (
			<ApproversListApprover key={`workflow-block-${block.id}-user-${user.id}`} block={block} user={user} indent={indent} />
		))
	);
};

export default ApproversListApprovers;
