import request from "superagent";
import { API_HOST } from "config/env";
import { SAVE_IN_PROGRESS } from "./types";

export const getGoal = (id) => {
	return () => {
		return new Promise((resolve, reject) =>
			request
				.get(`${API_HOST}/api/goal/${id}`)
				.withCredentials()
				.then((res) => {
					resolve(res.body.goal);
				})
				.catch((exception) => {
					reject(exception);
				}),
		);
	};
};

export const unarchiveGoal = (id) => {
	return () => {
		return new Promise((resolve, reject) =>
			request
				.post(`${API_HOST}/api/goal/${id}/unarchive`)
				.send({})

				.then((res) => {
					resolve(res.body);
				})
				.catch((exception) => {
					reject(exception);
				}),
		);
	};
};

export const getGoalsToLink = (type) => {
	return () => {
		return new Promise((resolve, reject) =>
			request
				.get(`${API_HOST}/api/goals/Goals?type=${type}`)
				.withCredentials()

				.then((res) => {
					resolve(res);
				})
				.catch((exception) => {
					reject(exception);
				}),
		);
	};
};

export const generateGoalLinks = (data) => {
	return () => {
		return new Promise((resolve, reject) =>
			request
				.post(`${API_HOST}/api/goals/GenerateGoalLinks`)
				.send(data)
				.then((res) => {
					resolve(res);
				})
				.catch((exception) => {
					reject(exception);
				}),
		);
	};
};

export function updateGoalSaveStatus(value) {
	return (dispatch) => {
		dispatch({
			type: SAVE_IN_PROGRESS,
			payload: value,
		});
	};
}
