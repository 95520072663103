import React, { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";

import withErrorHandling from "components/ErrorHOC";
import { resetPageConfigs, updatePageConfigs } from "redux/app/actions";
import { updatePageHeader } from "redux/pageHeader/actions";
import { setSnackbarOptions } from "redux/snackBar/actions";
import { getGeneralSettings, saveGeneralSettings, setGeneralSettingError } from "redux/generalSettings/actions";

import OrganizationInfo from "./components/OrganizationInfo";
import DefaultLetterheadTemplate from "./components/DefaultLetterheadTemplate";
import PolicyUrl from "./components/PolicyUrl";
import PolicyUrlTimeSettings from "./components/PolicyUrlTimeSettings";
import BoxcastSettings from "./components/BoxcastSettings";
import YoutubeSettings from "./components/YoutubeSettings";
import ComponentContainer from "atlas/components/ComponentContainer/ComponentContainer";
import { SettingsContext } from "contexts/Settings/SettingsContext";
import telemetryAddEvent from "utils/telemetryAddEvent";

import notifierMessage from "utils/notifierMessage";
const telemetryPage = "General Settings";

const GeneralSettings = (props) => {
	const { t } = useTranslation("generalSettings");
	const dispatch = useDispatch();
	const generalSettingsData = useSelector((state) => state.generalSettingsReducer);
	const { isBoxCastEnabled } = generalSettingsData;
	const { policyEnabled, communityMeetings } = useContext(SettingsContext);

	const convertTime12to24 = () => {
		let time = generalSettingsData.hour;
		let period = generalSettingsData.period;
		if (period === "PM" && time < 12) {
			return time + 12;
		}
		if (period === "AM" && time === 12) {
			return time - 12;
		}
		return time;
	};

	const saveGeneralSettingsData = () => {
		const sendReminderNotification = convertTime12to24();
		generalSettingsData.sendReminderNotification = sendReminderNotification;

		dispatch(saveGeneralSettings(generalSettingsData))
			.then((res) => {
				if (res) {
					let option = notifierMessage(t("snackbar.success.save"), "success");
					dispatch(setSnackbarOptions(option));

					telemetryAddEvent("Settings General - Saved");
				}
			})
			.catch((exception) => {
				if (exception.response && exception.response.statusCode == 400) {
					const expeptionError = exception.response.body;
					let errors = generalSettingsData.generalSettingErrors;
					dispatch(setGeneralSettingError({ ...errors, ...expeptionError }));
				}
			});
	};

	useEffect(
		() => () => {
			dispatch(setGeneralSettingError({}));
		},
		[],
	);

	useEffect(() => {
		dispatch(getGeneralSettings());
		dispatch(resetPageConfigs({ resetBack: true }));
		dispatch(
			updatePageConfigs({
				title: t("title"),
				telemetryPage,
				preferedBack: { url: "/generalsettings" },
			}),
		);
	}, []);

	useEffect(() => {
		const isErrorNotPresent =
			generalSettingsData && generalSettingsData.generalSettingErrors && generalSettingsData.generalSettingErrors.organizationName;
		dispatch(
			updatePageHeader({
				primaryAction: saveGeneralSettingsData,
				primaryActionText: t("buttons.save"),
				primaryActionTooltip: t("tooltips.save"),
				primaryActionDisabled: isErrorNotPresent,
			}),
		);
	}, [generalSettingsData]);

	return (
		<>
			<ComponentContainer padding="16px" noPaddingTopUp={"sm"}>
				<OrganizationInfo />
				<DefaultLetterheadTemplate />
				{!policyEnabled && <PolicyUrl />}
				<PolicyUrlTimeSettings />
				{communityMeetings && <BoxcastSettings />}
				{communityMeetings && isBoxCastEnabled && <YoutubeSettings />}
			</ComponentContainer>
		</>
	);
};
export default withErrorHandling(GeneralSettings);
