/* eslint-disable no-nested-ternary */
/* eslint-disable no-plusplus */
import React, { useState, useEffect, useRef, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import YouTube from "react-youtube";
import { useNavigate, useMatch } from "react-router-dom";
import cookie from "react-cookies";
import request from "superagent";

import { Grid, Paper, Typography } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import { API_HOST } from "config/env";
import { formatDate } from "utils/date";
import telemetryAddEvent from "utils/telemetryAddEvent";
import { timeStampExists, getTimeStamp, getTimeStampEvent, timeStampStringToSeconds } from "utils/videoTimeStamping";
import MinutesItemTypesEnum from "utils/enums/MinutesItemTypes";
import ComponentContainer from "atlas/components/ComponentContainer/ComponentContainer";
import StyledSwitch from "atlas/components/FormControls/StyledSwitch";
import StyledLink from "atlas/components/Links/StyledLink";
import CircularProgressIndicator from "atlas/components/Progress/CircularProgressIndicator";
import Icon from "atlas/components/Icon/Icon";
import { whiteColor } from "atlas/assets/jss/shared";

import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import typographyStyle from "atlas/assets/jss/components/typographyStyle";

import { findItemByID } from "../MeetingEditor/functions/utils";
import LiveMeetingHeading from "./components/LiveMeetingHeading";
import LiveMeetingMinutesItem from "./components/LiveMeetingMinutesItem";
import LiveMeetingRecommendation from "./components/LiveMeetingRecommendation";

import { resetPageConfigs, updatePageConfigs, updateCurrentTimestampItem } from "redux/app/actions";
import { updatePageHeader } from "redux/pageHeader/actions";
import useWindowDimensions from "../../assets/jss/utils/useWindowDimensions";
import StyledAlert from "../../atlas/components/Alert/StyledAlert";
import { useWidthUp, useWidthDown } from "atlas/utils/useWidth";
import { SettingsContext } from "contexts/Settings/SettingsContext";
import notifierMessage from "utils/notifierMessage";
import { setSnackbarOptions } from "redux/snackBar/actions";

const useStyles = makeStyles((theme) => ({
	agenda: {
		margin: "0",
		padding: "0",
		marginBottom: "16px",
	},
	paper: {
		padding: "16px",
		paddingTop: "8px",
		borderRadius: "2px",
		margin: "0",
		maxWidth: "100%",
		overflow: "auto",
		// videoContainerHeight + padding + headers/title height
		height: (props) => `calc(100vh - ${props.videoHeight + 16 + 172}px)`,
		boxShadow: "0 2px 10px 0 rgba(0, 0, 0, 0.1) !important",
	},
	timestampContainer: {
		display: "flex",
		flexDirection: "row",
	},
	timestampRightPanel: {},
	timestampRightPanel: {},
	thumbnailPanel: {
		margin: "24px",
		padding: 0,
		[theme.breakpoints.down("md")]: {
			margin: "8px",
		},
	},
	videoThumbnailContainer: {
		display: "flex",
		flexDirection: "column",
		cursor: "pointer",
	},
	videoThumbnailTitle: {
		...typographyStyle.semiBold,
		paddingBottom: "8px",
	},
	videoThumbnail: {
		width: "144px",
		height: "80px",
		paddingBottom: "16px",
		paddingRight: "8px",
	},
	timestampPlayer: {
		//paddingTop: "4px",
		//paddingLeft: "16px",
		//paddingRight: "16px",
	},
	timestampControls: {
		display: "flex",
		//paddingLeft: "16px",
		marginBottom: "8px",
		flexDirection: "row",
		[theme.breakpoints.down("md")]: {
			flexDirection: "column",
		},
	},
	showPublicTimestamps: {
		display: "flex",
		alignItems: "center",
		marginRight: "24px",
		minWidth: "265px",
	},
	updateStartTime: {
		marginTop: 0,
		width: "265px",
	},
	switchLabel: {
		...typographyStyle.fieldLabel,
		marginRight: "6px",
	},
	switchInstructions: {
		...typographyStyle.fieldLabel,
	},
	videoContainer: {
		backgroundColor: "black",
		width: "100% !important",
		height: "30vh !important",
		[theme.breakpoints.down("sm")]: {
			height: "25vh !important",
		},
	},
	videoFrameContainer: {
		width: "100% !important",
		height: "30vh !important",
		[theme.breakpoints.down("sm")]: {
			height: "25vh !important",
		},
	},
	limitLines: {
		overflow: "hidden",
		textOverflow: "ellipsis",
		display: "-webkit-box",
		"-webkit-box-orient": "vertical",
		"-webkit-line-clamp": 2,
	},
}));

const TimeStamping = () => {
	const { params: { id } = {} } = useMatch({ path: "/meeting/timestamping/:id", end: true }) || {};
	const widthDownMd = useWidthDown("md");
	const widthUpMd = useWidthUp("md");
	const { t } = useTranslation("meetings");
	const [meetingLoaded, setMeetingLoaded] = useState(false);
	const [meetingData, setMeetingData] = useState(null);
	const [selected, setSelected] = useState("");
	const [itemsToSync, setItemsToSync] = useState([]);
	const [playerOptions, setPlayerOptions] = useState({ playerVars: { autoplay: 1, start: 0 } });
	const [videoPlayerHasError, setVideoPlayerHasError] = useState(false);
	const [hasTimeStamps, setHasTimeStamps] = useState(true);
	const [syncEnabled, setSyncEnabled] = useState(false);
	const [saveSyncEnabled, setSaveSyncEnabled] = useState(false);
	const [timestampOffset, setTimestampOffset] = useState(0);
	const [broadcasts, setBroadcasts] = useState([]);
	const [currentVideo, setCurrentVideo] = useState("");
	const [videoExists, setVideoExists] = useState(false);
	const [showThumbnailsInline, setShowThumbnailsInline] = useState(false);
	const dispatch = useDispatch();
	const appReducer = useSelector((state) => state.appReducer);
	const navigate = useNavigate();
	const { testSite } = useContext(SettingsContext);
	const scrollToSelected = useRef(false);
	const videoControlsContainer = useRef(null);
	const { height } = useWindowDimensions();
	const classes = useStyles({
		height,
		videoHeight: videoControlsContainer ? videoControlsContainer?.current?.clientHeight : 0,
	});
	const playerRef = useRef(null);

	const setCurrentTimestamp = (timestamp) => {
		setPlayerOptions((previousItem) => ({ ...previousItem, playerVars: { ...previousItem.playerVars, start: timestamp } }));
	};

	const showThumbnailsClick = () => {
		setShowThumbnailsInline((previous) => !previous);
	};

	const showVideoButton = () => {
		const showVideoThumbnailButton = widthDownMd;
		dispatch(
			updatePageHeader({
				primaryAction: showVideoThumbnailButton ? showThumbnailsClick : undefined,
				primaryActionText: showVideoThumbnailButton ? <Icon name="video" color={whiteColor} fill={whiteColor} /> : undefined,
				primaryActionTooltip: showVideoThumbnailButton ? t("videos") : undefined,
			}),
		);
	};
	const loadMeeting = async () => {
		try {
			const response = await request.get(`${API_HOST}/api/meeting/${id}/getagendaitems`);
			const { items, meeting } = response.body;
			const meetingDate = formatDate(null, meeting.startTime, meeting.endTime, t("app:at"), t("from"), t("to"), false);

			const minutesResponse = await request.get(`${API_HOST}/api/meeting/${id}/getminutesitems`);
			const minutesItems = minutesResponse.body.items;

			const documentRespose = await request.get(`${API_HOST}/api/meeting/${id}/final?agenda=true&members=false`);
			const { html: { documentId: htmlDocumentId = 0 } = {}, pdf: { documentId: pdfDocumentId = 0 } = {} } = documentRespose.body;

			setMeetingData({
				meeting,
				meetingDate,
				meetingActive: meeting.startTimeStamp <= Math.floor(Date.now() / 1000),
				items,
				htmlDocumentId,
				minutesItems,
			});
			setMeetingLoaded(true);
		} catch (err) {
			console.log(err);
		}
	};

	const getAgendaItem = (minutesItem) => {
		const { items = [] } = meetingData;

		return items.find((item) => minutesItem.agendaItemGuid === item.guid || minutesItem.headingGuid === item.guid) || { attachments: [] };
	};

	const handleShowTimestamps = (e, setting) => {
		const {
			target: { checked },
		} = e;

		if (meetingData.meeting[setting] === checked) {
			return;
		}

		request
			.post(`${API_HOST}/api/savevideoeventsettings`)
			.withCredentials()
			.send({ MeetingId: meetingData.meeting.id, ShowTimeStamps: checked })
			.then((res) => {
				if (res.body) {
					meetingData.meeting[setting] = checked;
					setMeetingData({ ...meetingData });
					let option = notifierMessage(checked ? t("publicTimestampsEnabled") : t("publicTimestampsDisabled"), "success");
					dispatch(setSnackbarOptions(option));
					telemetryAddEvent(checked ? "Timestamping - Enable public on" : "Timestamping - Enable public off");
				}
			});
	};

	const handleEnableSync = () => {
		const { minutesItems = [] } = meetingData;

		if (broadcasts.length > 0) {
			setCurrentVideoData(broadcasts[0]);
		}
		const itemsWithTimestamps = minutesItems.filter((i) => getTimeStamp(i) != null);
		if (itemsWithTimestamps.length > 0) {
			const firstItem = itemsWithTimestamps[0];
			if (firstItem && playerRef?.current?.internalPlayer) {
				setSelected(firstItem.guid);
				setSyncEnabled(true);
				const firstItemTimestamp = getTimeStamp(firstItem);
				setCurrentTimestamp(firstItemTimestamp >= 0 ? firstItemTimestamp : 0);
			}
		}
	};
	const handleUpdateSync = (item) => {
		if (item && timeStampExists(item) && playerRef?.current?.internalPlayer) {
			playerRef.current.internalPlayer.getCurrentTime().then((playerTime) => {
				const timeOffset = Math.floor(playerTime) - getTimeStamp(item);

				const currentYoutubeEvent = broadcasts[0]?.youtubeId;
				const { minutesItems } = meetingData;
				const its = [];
				for (let i = 0; i < minutesItems.length; i++) {
					const minutesItem = minutesItems[i];
					const existingTimeStamp = getTimeStamp(minutesItem);
					const existingTimeStampEvent = getTimeStampEvent(minutesItem);
					if (existingTimeStamp != null && (currentYoutubeEvent == existingTimeStampEvent || existingTimeStampEvent == "")) {
						const newTimeStamp = existingTimeStamp + timeOffset;
						minutesItems[i] = { ...minutesItem, fields: { ...minutesItem.fields, TimeStamp: { Value: newTimeStamp } } };
						its.push({ itemGuid: minutesItem.guid, timestamp: newTimeStamp });
					}
				}
				setMeetingData((previousMeetingData) => ({ ...previousMeetingData, minutesItems }));
				setItemsToSync(its);
				setSaveSyncEnabled(true);
				setTimestampOffset(timeOffset);
			});
		}
	};

	const handleUndoSync = () => {
		const { minutesItems } = meetingData;
		const its = [];
		let hasOnePositiveTimeStamp = false;
		const currentYoutubeEvent = broadcasts[0].youtubeId;
		for (let i = 0; i < minutesItems.length; i++) {
			const minutesItem = minutesItems[i];
			const existingTimeStamp = getTimeStamp(minutesItem);
			const existingTimeStampEvent = getTimeStampEvent(minutesItem);
			if (existingTimeStamp != null && (currentYoutubeEvent == existingTimeStampEvent || existingTimeStampEvent == "")) {
				const newTimeStamp = existingTimeStamp - timestampOffset;
				minutesItems[i] = { ...minutesItem, fields: { ...minutesItem.fields, TimeStamp: { Value: newTimeStamp } } };
				its.push({ itemGuid: minutesItem.guid, timestamp: newTimeStamp });
				if (!hasOnePositiveTimeStamp && newTimeStamp >= 0) {
					hasOnePositiveTimeStamp = true;
				}
			}
		}
		setHasTimeStamps(hasOnePositiveTimeStamp);

		if (its.length > 0) {
			request
				.put(`${API_HOST}/api/meeting/${id}/synctimestamps`)
				.withCredentials()
				.send(its)
				.then((res) => {
					if (res.status === 200) {
						let option = notifierMessage(t("setStartTimeOfMeetingUndone"), "success");
						dispatch(setSnackbarOptions(option));
					}
				})
				.catch((err) => {
					console.log(err);
				});
		}

		setMeetingData((previousMeetingData) => ({ ...previousMeetingData, minutesItems }));
		setItemsToSync([]);
		setSyncEnabled(false);
		setSaveSyncEnabled(false);
		setTimestampOffset(0);
	};

	const handleSaveSync = () => {
		if (itemsToSync.length > 0) {
			request
				.put(`${API_HOST}/api/meeting/${id}/synctimestamps`)
				.withCredentials()
				.send(itemsToSync)
				.then((res) => {
					if (res.status === 200) {
						// No undo button if the timestamp did not change while syncing
						if (timestampOffset === 0) {
							let option = notifierMessage(t("startTimeOfMeetingSaved"), "success");
							dispatch(setSnackbarOptions(option));
						} else {
							let option = notifierMessage(t("startTimeOfMeetingSaved"), "success", () => handleUndoSync());
							dispatch(setSnackbarOptions(option));
						}

						telemetryAddEvent("Timestamping - Set start time");
					}

					document.querySelector('[name="live-meeting-item"]').focus();
				})
				.catch((err) => {
					console.log(err);
				});
		}

		setItemsToSync([]);
		setSyncEnabled(false);
		setSaveSyncEnabled(false);
	};

	const clearTimestamp = (item) => {
		request
			.put(`${API_HOST}/api/meeting/${id}/timestampitem`)
			.withCredentials()
			.send({ itemGuid: item.guid })
			.then((res) => {
				if (res.status === 200) {
					const { minutesItems } = meetingData;
					let hasOnePositiveTimeStamp = false;
					for (let i = 0; i < minutesItems.length; i++) {
						const minutesItem = minutesItems[i];

						if (minutesItem.guid === item.guid) {
							minutesItems[i] = { ...minutesItem, fields: { ...minutesItem.fields, TimeStamp: { Value: res.body?.timestamp } } };
							setMeetingData((previousMeetingData) => ({ ...previousMeetingData, minutesItems }));
						}
						const existingTimeStamp = minutesItem.guid === item.guid ? res.body?.timestamp : getTimeStamp(minutesItem);
						if (!hasOnePositiveTimeStamp && existingTimeStamp !== null && existingTimeStamp >= 0) {
							hasOnePositiveTimeStamp = true;
						}
					}
					setHasTimeStamps(hasOnePositiveTimeStamp);
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const setTimestamp = (item) => {
		if (playerRef?.current?.internalPlayer) {
			playerRef.current.internalPlayer.getCurrentTime().then((currentTime) => {
				if (currentTime >= 0) {
					request
						.put(`${API_HOST}/api/meeting/${id}/timestampitem`)
						.withCredentials()
						.send({ itemGuid: item.guid, eventId: currentVideo, timestamp: currentTime })
						.then((res) => {
							if (res.status === 200) {
								setHasTimeStamps(res.body?.timestamp >= 0);
								const { minutesItems } = meetingData;
								for (let i = 0; i < minutesItems.length; i++) {
									const minutesItem = minutesItems[i];
									if (minutesItem.guid === item.guid) {
										minutesItems[i] = { ...minutesItem, fields: { ...minutesItem.fields, TimeStamp: { Value: res.body?.timestamp } } };
										setMeetingData((previousMeetingData) => ({ ...previousMeetingData, minutesItems }));
										break;
									}
								}
							}
						})
						.catch((err) => {
							console.log(err);
						});
				}
			});
		}
	};

	const handleTimeStampBlur = (event, item) => {
		if (!syncEnabled) {
			const { target } = event;
			const timestamp = timeStampStringToSeconds(target.value);
			if (timestamp !== null) {
				request
					.put(`${API_HOST}/api/meeting/${id}/timestampitem`)
					.withCredentials()
					.send({ itemGuid: item.guid, eventId: currentVideo, timestamp })
					.then((res) => {
						if (res.status === 200) {
							setHasTimeStamps(res.body?.timestamp >= 0);
							const { minutesItems } = meetingData;
							for (let i = 0; i < minutesItems.length; i++) {
								const minutesItem = minutesItems[i];
								if (minutesItem.guid === item.guid) {
									minutesItems[i] = { ...minutesItem, fields: { ...minutesItem.fields, TimeStamp: { Value: res.body?.timestamp } } };
									setMeetingData((previousMeetingData) => ({ ...previousMeetingData, minutesItems }));
									break;
								}
							}
						}
					})
					.catch((err) => {
						console.log(err);
					});
			}
		}
	};

	const onPlayerReady = () => {
		// event.target.pauseVideo(); //this never fully loads the video before going to pause
	};
	const onPlayerError = () => {
		setVideoPlayerHasError(true);
	};

	const handleSelect = (e) => {
		const { minutesItems = [] } = meetingData;

		const updatedSelected = typeof e === "string" ? e : e.target.value;

		setSelected(updatedSelected);
		const minutesItem = minutesItems.find((item) => item.guid === updatedSelected);

		if (playerRef?.current?.internalPlayer && timeStampExists(minutesItem)) {
			const timestamp = getTimeStamp(minutesItem);
			const timestampEvent = getTimeStampEvent(minutesItem);
			setCurrentTimestamp(timestamp);
			if (timestamp >= 0) {
				setCurrentVideoData(broadcasts.find((broadcast) => broadcast.youtubeId == timestampEvent));
				playerRef.current.internalPlayer.seekTo(timestamp);
				playerRef.current.internalPlayer.pauseVideo();
			} else {
				setCurrentTimestamp(null);
			}
		}
	};

	const handleClick = (e, minutesItem) => {
		if (playerRef?.current?.internalPlayer && timeStampExists(minutesItem)) {
			const timestamp = getTimeStamp(minutesItem);
			const timestampEvent = getTimeStampEvent(minutesItem);
			if (!syncEnabled) {
				setSelected(minutesItem.guid);
			}
			setCurrentTimestamp(timestamp);
			if (timestamp >= 0) {
				setCurrentVideoData(broadcasts.find((broadcast) => broadcast.youtubeId == timestampEvent));
				playerRef.current.internalPlayer.seekTo(timestamp);
				playerRef.current.internalPlayer.pauseVideo();
			}
		}
	};

	const setCurrentVideoData = (broadcast) => {
		const pageConfigs = {
			title: broadcast.title,
		};
		dispatch(updatePageConfigs(pageConfigs));

		setCurrentVideo(broadcast.youtubeId);
		//console.log(youtubeId);
	};
	const getVideoThumbnails = () => {
		return broadcasts.map((broadcast, index) => {
			if (broadcast.youtubeId) {
				return (
					<li
						data-cy={"video-thumbnail-" + broadcast.youtubeId}
						key={"video-thumbnail-" + broadcast.youtubeId}
						onClick={() => {
							setShowThumbnailsInline(false);
							setCurrentTimestamp(0);
							setCurrentVideoData(broadcast);
						}}
						className={classes.videoThumbnailContainer}
					>
						<Typography variant="h5" className={classes.videoThumbnailTitle}>
							<span>{broadcast.title}</span>
						</Typography>
						<img
							src={"https://img.youtube.com/vi/" + broadcast.youtubeId + "/1.jpg"}
							alt={"video thumbnail " + broadcast.title}
							className={classes.videoThumbnail}
						/>
					</li>
				);
			}
		});
	};
	const getItems = () => {
		const {
			items,
			minutesItems,
			meeting: { closed: isClosedMeeting },
		} = meetingData;

		const filteredItems = [];
		const previous = {
			heading: {
				item: null,
				numberOfChildren: 0,
			},
		};

		minutesItems.forEach((item) => {
			if (!item.deleted) {
				filteredItems.push(item);
				if (item.itemType === MinutesItemTypesEnum().HEADING.value) {
					// Top level heading
					previous.heading = {
						item,
						numberOfChildren: !item.attributes.relationshipGuid ? 0 : previous.heading.numberOfChildren,
					};
				} else if (previous.heading.item && item.itemType === MinutesItemTypesEnum().ITEM.value) {
					// Count the children of the heading
					previous.heading.numberOfChildren++;
				}
			}
		});

		const lastIndex = filteredItems.length - 1;

		return filteredItems.map((minutesItem, index) => {
			const item = getAgendaItem(minutesItem);
			if (minutesItem.itemType === MinutesItemTypesEnum().HEADING.value) {
				return (
					<LiveMeetingHeading
						key={minutesItem.guid}
						heading={minutesItem}
						isClosedMeeting={isClosedMeeting}
						isSubHeading={Boolean(minutesItem.attributes.relationshipGuid)}
						addBottomBorder={index === lastIndex}
						selected={selected}
						handleSelect={handleSelect}
						handleClick={timeStampExists(minutesItem) ? handleClick : null}
						syncEnabled={syncEnabled}
						saveSyncEnabled={saveSyncEnabled}
						broadcasts={broadcasts}
						videoExists={videoExists}
						youtubePlayerRef={playerRef}
						updateSync={handleUpdateSync}
						saveSync={handleSaveSync}
						handleTimeStampBlur={handleTimeStampBlur}
						clearTimestamp={clearTimestamp}
						setTimestamp={setTimestamp}
						videoControlsDisabled={videoPlayerHasError}
					/>
				);
			}

			if (minutesItem.itemType === MinutesItemTypesEnum().ITEM.value) {
				return (
					<LiveMeetingMinutesItem
						key={minutesItem.guid}
						agendaItem={item}
						minutesItem={minutesItem}
						isClosedMeeting={isClosedMeeting}
						addBottomBorder={index === lastIndex}
						selected={selected}
						handleSelect={handleSelect}
						handleClick={timeStampExists(minutesItem) ? handleClick : null}
						testSite={testSite}
						syncEnabled={syncEnabled}
						saveSyncEnabled={saveSyncEnabled}
						broadcasts={broadcasts}
						videoExists={videoExists}
						youtubePlayerRef={playerRef}
						updateSync={handleUpdateSync}
						saveSync={handleSaveSync}
						handleTimeStampBlur={handleTimeStampBlur}
						clearTimestamp={clearTimestamp}
						setTimestamp={setTimestamp}
						videoControlsDisabled={videoPlayerHasError}
					/>
				);
			}

			if (minutesItem.itemType === MinutesItemTypesEnum().RESOLUTION.value) {
				const parent = findItemByID(minutesItem.attributes.relationshipGuid, items);

				return (
					<LiveMeetingRecommendation
						key={minutesItem.guid}
						recommendation={minutesItem}
						isClosedMeeting={isClosedMeeting}
						isHeadingAction={parent && parent.itemType === MinutesItemTypesEnum().HEADING.value}
						addBottomBorder={index === lastIndex}
						selected={selected}
						handleSelect={null}
						videoControlsDisabled={videoPlayerHasError}
					/>
				);
			}

			return <div key={`item${item.guid}`}>{item.fields.Name.Value}</div>;
		});
	};

	useEffect(() => {
		showVideoButton();
	}, [widthDownMd]);

	useEffect(() => {
		dispatch(resetPageConfigs({}));

		loadMeeting();
		showVideoButton();
	}, [id]);

	useEffect(() => {
		if (selected && selected.length >= 36) {
			const guid = selected.substring(0, 36);
			const element = document.getElementById(`agenda-${guid}`) || document.getElementById(`minutes-${guid}`);
			if (element && scrollToSelected.current) {
				element.scrollIntoView();
				scrollToSelected.current = false;
			}
		}
	}, [selected, meetingData]);

	useEffect(() => {
		// Give playerRef.current a chance to be populated before trying to use it
		if (meetingLoaded) {
			const { meeting, minutesItems } = meetingData;

			const pageConfigs = {
				title: meeting.name,
				back: { url: `/meeting/${id}` },
				telemetryPage: "Timestamping",
				overflowHidden: true,
				contentMaxWidth: "lg",
			};
			dispatch(updatePageConfigs(pageConfigs));

			if (meeting.purchasedBoxcast && meeting?.broadcasts) {
				const broadcastWithYoutubeEvents = meeting.broadcasts.filter((broadcast) => {
					return broadcast.youtubeId.length > 0;
				});
				setBroadcasts(broadcastWithYoutubeEvents);

				if (broadcastWithYoutubeEvents.length > 0) {
					setCurrentVideoData(broadcastWithYoutubeEvents[0]);
					setVideoExists(true);
				}
			} else if (meeting.youtubeEventId) {
				setCurrentVideo(meeting.youtubeEventId);
				setVideoExists(true);
			} else {
				navigate(`/meeting/${id}`);
			}

			const itemsWithTimestamps = minutesItems.filter((i) => getTimeStamp(i) != null);
			setHasTimeStamps(itemsWithTimestamps.length > 0);
			if (meeting.needsTimestampSync && itemsWithTimestamps.length > 0) {
				const firstItem = itemsWithTimestamps[0];
				if (firstItem && playerRef?.current?.internalPlayer) {
					setSelected(firstItem.guid);
					setSyncEnabled(true);
					const firstItemTimestamp = getTimeStamp(firstItem);
					setCurrentTimestamp(firstItemTimestamp >= 0 ? firstItemTimestamp : 0);
				}
			} else {
				const { currentTimestampItem } = appReducer;
				if (currentTimestampItem && playerRef?.current?.internalPlayer && timeStampExists(currentTimestampItem)) {
					const timestamp = getTimeStamp(currentTimestampItem);
					if (timestamp >= 0) {
						setSelected(currentTimestampItem.guid);
						pageConfigs.back = { url: `/meeting/${(cookie.load("old_minutes") || "false") === "false" ? "liveV2" : "live"}/${id}` };
						playerRef.current.internalPlayer.seekTo(timestamp);
					}
					setCurrentTimestamp(timestamp);
					dispatch(updateCurrentTimestampItem(null));
				}
			}
		}
	}, [id, meetingLoaded]);

	return meetingData ? (
		<Grid container direction="row">
			<Grid item xs={12} md={broadcasts.length > 1 && !showThumbnailsInline && !syncEnabled ? 9 : 12}>
				<div className={classes.timestampPlayer} ref={videoControlsContainer}>
					<YouTube
						ref={playerRef}
						className={classes.videoContainer}
						iframeClassName={classes.videoFrameContainer}
						videoId={currentVideo}
						opts={playerOptions}
						onReady={onPlayerReady}
						onError={onPlayerError}
					/>
					{videoPlayerHasError && (
						<StyledAlert
							severity="error"
							message={t("notifications.timeStampingVideoError")}
							iconName="error"
							persist
							dataCy="alert-video-error"
						/>
					)}
					{!hasTimeStamps && !videoPlayerHasError && (
						<StyledAlert
							severity="info"
							message={t("notifications.timeStampingNoTimestampsMsg")}
							iconName="error"
							persist
							dataCy="alert-no-timestamps"
						/>
					)}
				</div>

				<ComponentContainer padding="0">
					<Paper elevation={3} className={classes.paper}>
						{!showThumbnailsInline && !syncEnabled && (
							<div className={classes.timestampControls}>
								<div className={classes.showPublicTimestamps}>
									<Box className={classes.switchLabel} component="span">
										{t("enable")}{" "}
										{meetingData.htmlDocumentId > 0 ? (
											<StyledLink href={"/document/" + meetingData.htmlDocumentId + "?splitscreen=true&media=true"} target="_blank">
												{t("publicTimestamps")}
											</StyledLink>
										) : (
											t("publicTimestamps")
										)}
									</Box>
									<StyledSwitch
										classes={{
											label: classes.switchLabel,
											stateLabel: classes.switchInstructions,
										}}
										inline
										title=""
										size="small"
										objectToUpdate={meetingData.meeting}
										fieldToUpdate="showTimestamps"
										onChange={(checked) => handleShowTimestamps({ target: { checked } }, "showTimestamps")}
										disabled={videoPlayerHasError || !hasTimeStamps}
										data-cy="show-timestamps"
									/>
								</div>

								{!syncEnabled && (
									<Button
										className={classes.updateStartTime}
										variant="outlined"
										size="small"
										color="primary"
										onClick={handleEnableSync}
										data-cy="enableSync"
										fullWidth={false}
										disabled={videoPlayerHasError || !hasTimeStamps}
									>
										{t("updateStartTimeOfMeeting")}
									</Button>
								)}
							</div>
						)}
						<ul
							id="item-list"
							data-cy={showThumbnailsInline && !syncEnabled ? "thumbnail-panel" : "item-list"}
							className={showThumbnailsInline ? classes.thumbnailPanel : classes.agenda}
						>
							{showThumbnailsInline && !syncEnabled ? getVideoThumbnails() : getItems()}
						</ul>
					</Paper>
				</ComponentContainer>
			</Grid>
			{broadcasts.length > 1 && !syncEnabled && widthUpMd && (
				<Grid item md={3}>
					<ul className={classes.thumbnailPanel} data-cy="thumbnail-panel">
						{getVideoThumbnails()}
					</ul>
				</Grid>
			)}
		</Grid>
	) : (
		<CircularProgressIndicator />
	);
};

export default TimeStamping;
