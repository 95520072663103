import React, { useEffect, useState, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import makeStyles from "@mui/styles/makeStyles";
import { Checkbox, Divider, FormControlLabel, MenuItem, Typography } from "@mui/material";

import GenericDialog from "atlas/components/Dialogs/GenericDialog";
import SelectInput from "atlas/components/FormControls/SelectInput";
import CustomNumberingPreview from "components/CustomNumbering/CustomNumberingPreview";
import { Close, Check } from "components/Icons";
import { SettingsContext } from "contexts/Settings/SettingsContext";
import {
	setMeetingTemplateAgendaPublicReleaseDelay,
	setMeetingTemplateAgendaShowConfidentialLabel,
	setMeetingTemplateAgendaHideRecommendations,
} from "redux/meetingTemplate/actions";
import {
	MEETING_PUBLISHED,
	MEETING_DAY,
	RELEASE_ONE_DAY_BEFORE,
	RELEASE_TWO_DAY_BEFORE,
	RELEASE_THREE_DAY_BEFORE,
	RELEASE_FOUR_DAY_BEFORE,
	RELEASE_FIVE_DAY_BEFORE,
	RELEASE_SIX_DAY_BEFORE,
	RELEASE_SEVEN_DAY_BEFORE,
} from "utils/publicReleaseDelayOption";
import telemetryAddEvent from "utils/telemetryAddEvent";

const useStyles = makeStyles(() => ({
	popup: {
		"& .MuiDialog-paper": {
			position: "absolute",
			top: "0px",
			right: "0px",
			margin: "0px",
			minHeight: "100vh",
			width: "496px",
		},
		"& .MuiTypography-h3": {
			fontSize: "22px",
			fontWeight: "600",
		},
		"& .MuiDialogContent-root": {
			padding: "0px",
			overflowX: "hidden",
		},
		"& .MuiFormControlLabel-root": {
			margin: "20px 32px 40px 24px",
		},
		"& .MuiFormControlLabel-label": {
			fontSize: "16px",
			fontWeight: "400",
		},
	},
	selectInput: {
		height: "auto",
		width: "80%",
		marginLeft: "35px",
		marginRight: "35px",
	},
	fieldLabel: {
		margin: "20px 35px 20px  35px ",
		fontSize: "16px",
		fontWeight: "600",
		fontFamily: "Source Sans Pro",
		letterSpacing: "0.1em",
	},
	checkboxLabel: {
		margin: "0px 25px 10px !important",
	},
	accordion: {
		marginLeft: "10px",
	},
}));

const AgendaSettingsDialog = (props) => {
	const { show, onClose, telemetryPage } = props;
	const { t } = useTranslation("agendaItems");
	const handleCancel = () => {
		onClose();
	};
	const dialog = {
		title: t("settingsDialog.title"),
		secondaryAction: handleCancel,
	};
	const classes = useStyles();
	const dispatch = useDispatch();
	const { agenda: { publicReleaseDelay, showClosedItemDescription, hideRecommendationsOnPublic } = {} } = useSelector(
		(state) => state.meetingTemplatesReducer,
	);
	const [option, setOption] = useState(publicReleaseDelay);
	const [showConfidentialItem, setShowConfidentialItem] = useState(showClosedItemDescription);
	const { lite } = useContext(SettingsContext);

	const updateHideRecomendations = (e) => {
		dispatch(setMeetingTemplateAgendaHideRecommendations(e.target.checked));
	};

	useEffect(() => {
		if (publicReleaseDelay !== option) {
			dispatch(setMeetingTemplateAgendaPublicReleaseDelay(option));
		}
	}, [option]);

	useEffect(() => {
		if (showConfidentialItem != showClosedItemDescription) {
			dispatch(setMeetingTemplateAgendaShowConfidentialLabel(showConfidentialItem));
		}
	}, [showConfidentialItem]);

	const getPublicReleaseDelayOptions = () =>
		[
			{ text: t("settingsDialog.options.publicReleaseWhenMeetingIsPublished"), value: MEETING_PUBLISHED },
			{ text: t("settingsDialog.options.publicReleaseOnMeetingDay"), value: MEETING_DAY },
			{ text: t("settingsDialog.options.publicRelease1DayBeforeOfMeeting"), value: RELEASE_ONE_DAY_BEFORE },
			{ text: t("settingsDialog.options.publicRelease2DaysBeforeOfMeeting"), value: RELEASE_TWO_DAY_BEFORE },
			{ text: t("settingsDialog.options.publicRelease3DaysBeforeOfMeeting"), value: RELEASE_THREE_DAY_BEFORE },
			{ text: t("settingsDialog.options.publicRelease4DaysBeforeOfMeeting"), value: RELEASE_FOUR_DAY_BEFORE },
			{ text: t("settingsDialog.options.publicRelease5DaysBeforeOfMeeting"), value: RELEASE_FIVE_DAY_BEFORE },
			{ text: t("settingsDialog.options.publicRelease6DaysBeforeOfMeeting"), value: RELEASE_SIX_DAY_BEFORE },
			{ text: t("settingsDialog.options.publicRelease7DaysBeforeOfMeeting"), value: RELEASE_SEVEN_DAY_BEFORE },
		].map((option) => (
			<MenuItem key={option.value} value={option.value}>
				{option.text}
			</MenuItem>
		));
	return (
		<GenericDialog
			className={classes.popup}
			show={show}
			title={dialog.title}
			secondaryAction={dialog.secondaryAction}
			closeIcon={<Close />}
			fullWidth
		>
			<div className={classes.fieldLabel}>
				<label>{t("settingsDialog.publishSettingsLabel")}</label>
			</div>
			<div className={classes.fieldLabel}>
				<label>{t("settingsDialog.publishPublicAgendaLabel")}</label>
			</div>
			<SelectInput
				id="public-release-delay"
				className={classes.selectInput}
				fullWidth
				size="small"
				externalLabel
				labelSize="large"
				value={option}
				onChange={(e) => {
					setOption(e.target.value);

					telemetryAddEvent("Public Release Delay - Changed");
				}}
			>
				{getPublicReleaseDelayOptions()}
			</SelectInput>

			<FormControlLabel
				id="show-confidential-item"
				className={classes.checkboxLabel}
				control={
					<Checkbox
						checkedIcon={<Check fontSize="small" color="black" />}
						checked={showConfidentialItem}
						onChange={() => setShowConfidentialItem(!showConfidentialItem)}
						value={showConfidentialItem}
					/>
				}
				label={<Typography>{t("settingsDialog.showConfidentialItemLabel")}</Typography>}
			/>
			<FormControlLabel
				id="hide-recommendations"
				className={classes.checkboxLabel}
				control={
					<Checkbox
						checkedIcon={<Check fontSize="small" color="black" />}
						checked={hideRecommendationsOnPublic}
						onChange={updateHideRecomendations}
						value={hideRecommendationsOnPublic}
					/>
				}
				label={<Typography>{t("settingsDialog.hideRecommendations")}</Typography>}
				data-cy="hide-recommendations"
			/>
			<Divider />
			{!lite.enabled && <CustomNumberingPreview />}
		</GenericDialog>
	);
};

export default AgendaSettingsDialog;
