import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import request from "superagent";
import { useTranslation } from "react-i18next";
import queryString from "query-string";

import Typography from "@mui/material/Typography";

import ComponentContainer from "atlas/components/ComponentContainer/ComponentContainer";
import GenericDialog from "atlas/components/Dialogs/GenericDialog";
import Icon from "atlas/components/Icon/Icon";
import withErrorHandling from "components/ErrorHOC";
import { API_HOST } from "config/env";
import telemetryAddEvent from "utils/telemetryAddEvent";
import RequestToSpeakAddToAgendaDialog from "components/Dialogs/RequestToSpeakAddToAgendaDialog";
import RequestToSpeakRequestChangesDialog from "components/Dialogs/RequestToSpeakRequestChangesDialog";
import RequestToSpeakRejectDialog from "components/Dialogs/RequestToSpeakRejectDialog";
import RequestToSpeakList from "./components/RequestToSpeakList";

import notifierMessage from "utils/notifierMessage";
import { setSnackbarOptions } from "redux/snackBar/actions";
import { mapStateToProps } from "../../redux/app/mapStateToProps";
import { resetPageConfigs, updatePageConfigs, updateTabs } from "redux/app/actions";
import { updatePageHeader } from "redux/pageHeader/actions";

const RequestToSpeakModule = (props) => {
	const pageSize = 50;
	const { requestToSpeakAdmin, showSignIn } = props;
	const navigate = useNavigate();
	const location = useLocation();
	const { tab: defaultTab = 0 } = queryString.parse(location.search) || {};
	const [requestToSpeakItems, setRequestToSpeakItems] = useState({ items: [], rowFrom: 0 });
	const [filters, setFilters] = useState(null);
	const [loading, setLoading] = useState({ loading: false, moreLoading: false });
	const [requestToSpeakItemsFetchHappened, setRequestToSpeakItemsFetchHappened] = useState(false);
	const [more, setMore] = useState(false);
	const [tab, setTab] = useState(parseInt(defaultTab, 10));
	const [showAddToAgendaDialog, setShowAddToAgendaDialog] = useState(false);
	const [itemToAdd, setItemToAdd] = useState(null);
	const [showRequestChangesDialog, setShowRequestChangesDialog] = useState(false);
	const [itemToChange, setItemToChange] = useState(null);
	const [showRejectDialog, setShowRejectDialog] = useState(false);
	const [itemToReject, setItemToReject] = useState(null);
	const [showMoveToSubmittedDialog, setShowMoveToSubmittedDialog] = useState(false);
	const [itemToMove, setItemToMove] = useState(null);
	let isComponentMounted = true;

	const { t } = useTranslation("requestToSpeak");
	const dispatch = useDispatch();

	const tabChange = (_e, selectedTab) => {
		setTab(selectedTab);
	};

	const loadRequestToSpeakItems = (loadFilter, loadMore = false) => {
		setLoading({ loading: true, moreLoading: loadMore });

		const queryFilters = {};

		if (tab === 0) {
			queryFilters.submitted = true;
		} else if (tab === 1) {
			queryFilters.added = true;
		} else if (tab === 2) {
			queryFilters.removed = true;
		}

		queryFilters.rowFrom = (loadMore ? requestToSpeakItems.items.length : 0) + 1; // These are inclusive and 1-indexed
		queryFilters.rowTo = requestToSpeakItems.rowFrom + pageSize - 1; // These are inclusive and 1-indexed

		request
			.get(`${API_HOST}/api/requeststospeak`)
			.withCredentials()
			.query(queryFilters)
			.then((res) => {
				if (!isComponentMounted) return;
				setMore(res.body.items.length > 0 && res.body.items.length < res.body.total);
				const newState = {
					items: loadMore ? requestToSpeakItems.items.concat(res.body.items) : res.body.items,
					total: res.body.total,
					rowFrom: queryFilters.rowFrom,
				};

				setLoading({ loading: false, moreLoading: false });
				setRequestToSpeakItemsFetchHappened(true);
				setRequestToSpeakItems(newState);

				const tabsOptions = [
					{ key: "submitted", label: t("tabs.submitted"), dataCy: "submitted-items" },
					{ key: "added", label: t("tabs.added"), dataCy: "added-items" },
					{ key: "removed", label: t("tabs.removed"), dataCy: "removed-items" },
				];

				dispatch(
					updateTabs({
						display: true,
						selectedTab: tab,
						tabsOptions,
						onChange: (_e, newTab) => {
							dispatch(
								updateTabs({
									selectedTab: newTab,
								}),
							);

							tabChange(_e, newTab);
						},
					}),
				);

				const { approveRtsId } = queryString.parse(location.search) || {};
				if (approveRtsId && approveRtsId > 0) {
					const itemToApprove = newState.items.filter((item) => item.id === parseInt(approveRtsId, 10));
					if (itemToApprove != null && itemToApprove.length > 0) {
						navigate(location.pathname);
						setItemToAdd(itemToApprove[0]);
					}
				}
			})
			.catch((err) => {
				if (!isComponentMounted) return;
				showSignIn(err, () => {
					loadRequestToSpeakItems(loadFilter, loadMore);
				});
			});
	};

	const updateItem = (id, data, callback) => {
		request
			.put(`${API_HOST}/api/requesttospeak/${id}`)
			.withCredentials()
			.send(data)
			.then((res) => {
				const { status } = res;
				if (status === 200) {
					let snackbarMessage = "";
					if (data.approve) {
						snackbarMessage = t("snackbar.approve.success");
					} else if (data.remove) {
						snackbarMessage = t("snackbar.remove.success");
					} else if (data.submit) {
						snackbarMessage = t("snackbar.submit.success");
					}
					let option = notifierMessage(snackbarMessage, "success");
					dispatch(setSnackbarOptions(option));

					setRequestToSpeakItems((prevState) => ({
						...prevState,
						items: prevState.items.filter((requestToSpeak) => requestToSpeak.id !== id),
					}));
				}

				if (typeof callback === "function") {
					callback();
				}
			})
			.catch((err) => {
				showSignIn(err, () => {
					updateItem(id, data);
				});
			});
	};

	const approveItem = (id, itemData) => {
		updateItem(id, itemData, () => {
			setItemToAdd(null);
		});
	};

	const removeItem = (id, comment) => {
		updateItem(id, { remove: true, comment }, () => {
			setItemToReject(null);
		});
	};

	const moveToSubmitted = (id) => {
		updateItem(id, { submit: true }, () => {
			setItemToMove(null);
		});
	};

	const requestChanges = (id, data) => {
		request
			.post(`${API_HOST}/api/requesttospeak/${id}/requestchanges`)
			.withCredentials()
			.send(data)
			.then((res) => {
				const { status } = res;
				if (status === 200) {
					let option = notifierMessage(t("snackbar.requestChanges.success"), "success");
					dispatch(setSnackbarOptions(option));
					setItemToChange(null);
					setRequestToSpeakItems((prevState) => ({
						...prevState,
						items: prevState.items.map((requestToSpeak) => {
							if (requestToSpeak.id === id) {
								requestToSpeak.changesRequested = true;
							}

							return requestToSpeak;
						}),
					}));
				}
			})
			.catch((err) => {
				showSignIn(err, () => {
					updateItem(id, data);
				});
			});
	};

	const handleRemoveClick = (item) => {
		setItemToReject(item);
	};

	const handleRequestChangesClick = (item) => {
		setItemToChange(item);
	};

	const handleApproveClick = (item) => {
		setItemToAdd(item);
	};

	const handleMoveItemClick = (item) => {
		setItemToMove(item);
	};

	const handleCancel = () => {
		setItemToAdd(null);
		setItemToChange(null);
		setItemToReject(null);
		setItemToMove(null);
	};

	const moreClick = () => {
		loadRequestToSpeakItems(filters, true);
	};

	const getMenuOptions = (item) => {
		const options = [];

		if (tab === 0) {
			options.push({
				label: t("buttons.requestChanges"),
				tooltip: t("tooltips.requestChanges"),
				ariaLabel: t("tooltips.requestChanges"),
				actionFunction: () => {
					handleRequestChangesClick(item);
				},
				"data-cy": "requestChanges",
			});
		}

		if (tab === 1 || tab === 2) {
			options.push({
				label: t("buttons.moveBackToSubmitted"),
				tooltip: t("tooltips.moveBackToSubmitted"),
				ariaLabel: t("tooltips.moveBackToSubmitted"),
				actionFunction: () => {
					handleMoveItemClick(item);
				},
				"data-cy": "moveBackToSubmitted",
			});
		}

		return options;
	};

	useEffect(() => {
		setShowAddToAgendaDialog(itemToAdd != null);
		setShowRequestChangesDialog(itemToChange != null);
		setShowRejectDialog(itemToReject != null);
		setShowMoveToSubmittedDialog(itemToMove != null);
	}, [itemToAdd, itemToChange, itemToReject, itemToMove]);

	useEffect(() => {
		loadRequestToSpeakItems(filters);
		return () => {
			isComponentMounted = false;
			dispatch(updateTabs({}));
		};
	}, [tab]);

	useEffect(() => {
		dispatch(resetPageConfigs({ resetBack: true }));
		dispatch(updatePageConfigs({ title: t("publicRequestToSpeak"), telemetryPage: "RequestToSpeak" }));
		dispatch(
			updatePageHeader({
				primaryAction: requestToSpeakAdmin
					? () => {
							telemetryAddEvent("RequestToSpeak - Add request to speak");

							navigate("/requesttospeak/create");
					  }
					: undefined,
				primaryActionText: requestToSpeakAdmin ? t("buttons.addRequestToSpeak") : undefined,
				primaryActionTooltip: requestToSpeakAdmin ? t("tooltips.addRequestToSpeak") : undefined,
			}),
		);
	}, []);

	return (
		<>
			{showAddToAgendaDialog && itemToAdd && (
				<RequestToSpeakAddToAgendaDialog
					show={showAddToAgendaDialog}
					item={itemToAdd}
					handleCancel={handleCancel}
					handleAddToAgenda={approveItem}
					canAdd={requestToSpeakAdmin}
				/>
			)}
			{showRequestChangesDialog && itemToChange && (
				<RequestToSpeakRequestChangesDialog
					show={showRequestChangesDialog}
					item={itemToChange}
					handleCancel={handleCancel}
					handleRequestChanges={requestChanges}
				/>
			)}
			{showRejectDialog && itemToReject && (
				<RequestToSpeakRejectDialog show={showRejectDialog} item={itemToReject} handleCancel={handleCancel} handleReject={removeItem} />
			)}
			{showMoveToSubmittedDialog && itemToMove && (
				<GenericDialog
					show={showMoveToSubmittedDialog}
					title={t("moveItemToSubmittedDialog.title")}
					primaryAction={() => {
						moveToSubmitted(itemToMove.id);
					}}
					primaryTitle={t("buttons.move")}
					secondaryAction={handleCancel}
					secondaryTitle={t("app:buttons.cancel")}
					closeIcon={<Icon name="close" />}
					data-cy="move-dialog"
					disableAutoFocus
					disableEnforceFocus
				>
					<Typography type="h1">{t("moveItemToSubmittedDialog.confirmMove")}</Typography>
				</GenericDialog>
			)}
			<ComponentContainer padding="0">
				<RequestToSpeakList
					requestToSpeakAdmin={requestToSpeakAdmin}
					requestToSpeakItems={requestToSpeakItems.items}
					more={more}
					moreClick={moreClick}
					tab={tab}
					loading={loading.loading}
					moreLoading={loading.moreLoading}
					requestToSpeakFetchHappened={requestToSpeakItemsFetchHappened}
					getMenuOptions={getMenuOptions}
					showAddToAgendaDialog={showAddToAgendaDialog}
					handleApproveClick={handleApproveClick}
					handleRemoveClick={handleRemoveClick}
				/>
			</ComponentContainer>
		</>
	);
};

export default withErrorHandling(connect(mapStateToProps)(RequestToSpeakModule));
