import { blackColor, grayColor, whiteColor } from '../shared';

const dragAndDropStyle = {
	draggable: {
		'&:hover': {
			outline: `1px solid ${grayColor[1]}`,
			outlineOffset: '-1px',
			borderRadius: '4px',
			cursor: 'grab',
		},
	},
	dragHandle: {
		position: 'absolute',
		top: (props) => (props && props.dragHandleSize ? `calc(50% - (${props.dragHandleSize} / 2))` : '4px'),
		left: '4px',
	},
	dragPlaceholder: {
		backgroundColor: grayColor[4],
		borderRadius: '4px',
		'& *': {
			visibility: 'hidden',
		},
	},
	dragPresentation: {
		border: `1px solid ${grayColor[1]}`,
		backgroundColor: whiteColor,
		boxShadow: '0px 4px 4px -2px rgba(0, 0, 0, 0.08), 0px 10px 32px -6px rgba(0, 0, 0, 0.32)',
		borderRadius: '4px',
		cursor: 'grabbing',
		"& div[data-role='drag-handle']": {
			display: 'block',
		},
	},
	droppable: {
		margin: '0',
		border: `1px dashed ${grayColor[1]} !important`,
		background: '#f6f6f6',
	},
	droppableOver: {
		margin: '0',
		border: `1px dashed ${blackColor[1]} !important`,
		background: grayColor[4],
	},
	dropPlaceholder: {
		width: (props) => (props && props.width ? props.width : undefined),
		height: (props) => (props && props.height ? props.height : '48px'),
		border: `1px dashed ${grayColor[1]}`,
		backgroundColor: grayColor[4],
		borderRadius: '4px',
	},
};

export default dragAndDropStyle;
