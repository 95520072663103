import React from "react";
import { useTranslation } from "react-i18next";
import clsx from "clsx";

import makeStyles from "@mui/styles/makeStyles";

import Accordion from "atlas/components/Accordion/Accordion";
import AccessibleIconButton from "atlas/components/Buttons/AccessibleIconButton";
import RollCall from "./RollCall";
import { STATUS_PRESENT, STATUS_LATE } from "../utils/rollCallUserStatus";
import typographyStyle from "atlas/assets/jss/components/typographyStyle";
import { blackColor } from "atlas/assets/jss/shared";

const useStyles = makeStyles({
	header: {
		display: "flex",
		alignItems: "center",
		boxSizing: "border-box",
		height: "64px",
		padding: "16px",
		paddingBottom: "0",
	},
	title: {
		...typographyStyle.drawerTitle,
		color: blackColor[3],
		flexGrow: "1",
		display: "flex",
		alignItems: "center",
	},
	close: {
		marginTop: "-4px",
		marginRight: "-12px",
	},
	saveStatus: {
		...typographyStyle.toolbarLabel,
		color: blackColor[3],
		opacity: "1",
		marginRight: "16px",
	},
	saved: {
		opacity: "0",
		transition: "opacity 1s ease-in",
	},
	body: {
		overflow: "auto",
	},
});

const RollCallPanel = (props) => {
	const { rollCall, closePanel, handleUpdate, saveStatus, onlineVoters, votingSettings } = props;
	const { t } = useTranslation("meetings");
	const classes = useStyles();

	const members = rollCall.users.filter((user) => user.votingMember);
	const nonVotingAttendees = rollCall.users.filter((user) => !user.votingMember);

	return (
		<>
			<div className={classes.header} data-cy="roll-call-panel-header">
				<div className={classes.title}>
					<div>{t("rollCall.attendeesRollCall")}</div>
				</div>
				<div className={classes.close}>
					<span
						className={clsx(classes.saveStatus, { [classes.saved]: saveStatus === t("agendaMenu:saved") })}
						data-cy="roll-call-save-status"
					>
						{saveStatus}
					</span>
					<AccessibleIconButton
						aria-label={t("tooltips.closeRollCall")}
						onClick={closePanel}
						iconName="close"
						dataCy="close-notes"
						tooltipText={t("tooltips.closeRollCall")}
						name="close"
					/>
				</div>
			</div>
			<div className={classes.body}>
				<Accordion
					label={t("rollCall.members", {
						present: members.filter((user) => user.status === STATUS_PRESENT || user.status === STATUS_LATE).length,
						total: members.length,
					})}
					startOpen
					overflow={false}
					dataCy={`roll-call-members`}
				>
					<RollCall
						users={members}
						onlineVoters={onlineVoters}
						votingSettings={votingSettings}
						handleUpdate={handleUpdate}
						dataCy="roll-call-members"
					></RollCall>
				</Accordion>
				{nonVotingAttendees.length > 0 && (
					<Accordion
						label={t("rollCall.nonVotingAttendees", {
							present: nonVotingAttendees.filter((user) => user.status === STATUS_PRESENT || user.status === STATUS_LATE).length,
							total: nonVotingAttendees.length,
						})}
						startOpen
						overflow={false}
						dataCy={`roll-call-non-voting-attendees`}
					>
						<RollCall
							users={nonVotingAttendees}
							onlineVoters={onlineVoters}
							handleUpdate={handleUpdate}
							dataCy="roll-call-non-voting-attendees"
						></RollCall>
					</Accordion>
				)}
			</div>
		</>
	);
};

export default RollCallPanel;
