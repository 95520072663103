import React from "react";
import { useTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";
import clsx from "clsx";

import makeStyles from "@mui/styles/makeStyles";

import CircularProgressIndicator from "atlas/components/Progress/CircularProgressIndicator";
import TableData from "atlas/components/TableData/TableData";
import IconButtonCell from "atlas/components/TableData/IconButtonCell";
import { blackColor } from "atlas/assets/jss/shared";
import TextCell from "atlas/components/TableData/TextCell";

const useStyles = makeStyles(() => ({
	headCell: {
		"& div": {
			fontWeight: "600 !important",
			fontSize: "16px !important",
			lineHeight: "20px !important",
			whiteSpace: "nowrap",
		},
	},
	activeHead: {
		textAlign: "center",
	},
	noResults: {
		padding: "16px",
	},
	mobileTable: {
		"& thead": {
			"& th": {
				["@media (max-width:280px)"]: {
					padding: "2px",
				},
			},
		},
		"& tbody": {
			"& td": {
				borderColor: "transparent",
			},
		},
	},
}));

const ICSCalenderTable = (props) => {
	const { folders, handleSort, handleDelete, loading } = props;
	const { t } = useTranslation("settings");
	const classes = useStyles();

	const tableHeadings = [
		{
			id: "url",
			disablePadding: false,
			label: t("ICStable.url"),
			retainCase: true,
			rowColor: blackColor[1],
			component: TextCell,
			ariaLabel: t("ICStable.url"),
		},
		{
			id: "name",
			disablePadding: false,
			label: t("ICStable.name"),
			retainCase: true,
			rowColor: blackColor[1],
			component: TextCell,
		},
		{
			id: "delete",
			disablePadding: false,
			label: "",
			ariaLabel: t("ICStable.actions"),
			icon: "remove",
			tooltip: t("tooltips.remove"),
			handleAction: handleDelete,
			rowColor: blackColor[1],
			width: "76px",
			showButton: true,
			sortNotRequired: true,
			component: IconButtonCell,
		},
	];
	return !loading ? (
		<>
			<div className={clsx({ [classes.mobileTable]: isMobile })}>
				<TableData
					withoutMultiSelect
					showToolbar={false}
					headCells={tableHeadings}
					rows={folders}
					noDataLabels={t("ICSNoDataLable")}
					defaultOrderBy="default"
					sortAction={handleSort}
					useExternalSort
					overrideClasses={classes}
					data-cy="custom-folder-list"
				/>
			</div>
		</>
	) : (
		<CircularProgressIndicator />
	);
};

export default ICSCalenderTable;
