import React from "react";
import SvgIcon from "@mui/material/SvgIcon";

import themeConfig from "../../atlas/assets/jss/theme";
import getOr from "lodash/fp/getOr";

const icon = (props) => {
	const { color } = props;
	const fillColor = color ? getOr(color, ["palette", color, "main"], themeConfig) : "currentColor";

	return (
		<SvgIcon {...props}>
			<svg width="24" height="24" viewBox="0 0 22 26" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M8.22524 17.9103L14.7127 11.4228C14.9225 11.2138 15.0889 10.9654 15.2025 10.6919C15.3161 10.4184 15.3745 10.1252 15.3745 9.82909C15.3745 9.53296 15.3161 9.23974 15.2025 8.96625C15.0889 8.69276 14.9225 8.44437 14.7127 8.23534C14.5037 8.02558 14.2553 7.85915 13.9818 7.74558C13.7083 7.63202 13.4151 7.57357 13.119 7.57357C12.8229 7.57357 12.5296 7.63202 12.2561 7.74558C11.9827 7.85915 11.7343 8.02558 11.5252 8.23534L1.85024 17.9103C1.1518 18.6129 0.759766 19.5634 0.759766 20.5541C0.759766 21.5448 1.1518 22.4952 1.85024 23.1978C2.55285 23.8963 3.50329 24.2883 4.49399 24.2883C5.48468 24.2883 6.43513 23.8963 7.13774 23.1978L19.7002 10.6728C20.1878 10.1853 20.5746 9.60655 20.8384 8.96955C21.1023 8.33256 21.2381 7.64982 21.2381 6.96034C21.2381 6.27085 21.1023 5.58812 20.8384 4.95113C20.5746 4.31413 20.1878 3.73535 19.7002 3.24784C19.2127 2.76028 18.6339 2.37352 17.9969 2.10964C17.36 1.84577 16.6772 1.70996 15.9877 1.70996C15.2983 1.70996 14.6155 1.84577 13.9785 2.10964C13.3415 2.37352 12.7628 2.76028 12.2752 3.24784L2.90024 12.6228"
					stroke="#1E1E1E"
					strokeWidth="1.5"
					strokeMiterlimit="10"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</svg>
		</SvgIcon>
	);
};

export default icon;
