import request from "superagent";
import { API_HOST } from "config/env";

export const loadDocument = (id = 0) => {
	return () => {
		return new Promise((resolve, reject) =>
			request
				.get(`${API_HOST}/api/document/${id}/detail`)
				.query({ includePath: true })
				.withCredentials()
				.then((json) => {
					resolve(json);
				})
				.catch((err) => {
					reject(err);
				}),
		);
	};
};
