import React from "react";
import { useTranslation } from "react-i18next";
import clsx from "clsx";

import MenuItem from "@mui/material/MenuItem";
import makeStyles from "@mui/styles/makeStyles";

import clonedeep from "lodash/cloneDeep";

import inputStyle from "atlas/assets/jss/components/inputStyle";
import SelectInput from "atlas/components/FormControls/SelectInput";
import StackedCheckbox from "atlas/components/FormControls/StackedCheckbox";
import filterStyle from "assets/jss/components/filterStyle";

const useInputStyles = makeStyles(inputStyle);
const useFilterStyles = makeStyles(filterStyle);

export const DEFAULT_SEARCH_FIELD = "search";

const UserFilter = (props) => {
	const { filters, onFilterChange, departments = [], meetingGroups = [] } = props;
	const { t } = useTranslation("users");
	const classes = useFilterStyles();
	const inputClasses = useInputStyles({ fullWidth: true });

	const fieldChange = (e, field, toNumber) => {
		const {
			target: { value },
		} = e;
		const newFilters = clonedeep(filters);

		newFilters[field] = toNumber ? parseInt(value, 10) : value;

		onFilterChange(newFilters, true);
	};

	const meetingGroupClick = (meetingGroup) => {
		const newFilters = clonedeep(filters);

		// Select or deselect the status
		newFilters.boards = newFilters.boards || [];
		const index = newFilters.boards.indexOf(meetingGroup.id);
		if (index >= 0) {
			newFilters.boards.splice(index, 1);
		} else {
			newFilters.boards.push(meetingGroup.id);
		}

		onFilterChange(newFilters, true);
	};

	const userTypes = [
		{
			label: t("app:options.none"),
			id: 0,
		},
		{
			label: t("filter.userTypes.admin"),
			id: 1,
		},
		{
			label: t("filter.userTypes.member"),
			id: 2,
		},
		{
			label: t("filter.userTypes.staff"),
			id: 3,
		},
	];
	const departmentNone = {
		id: 0,
		name: t("app:options.none"),
	};

	return (
		<>
			<div className={classes.section}>
				<div className={classes.fieldHeading}>
					<label id="user-types-label" htmlFor="user-types">
						{t("filter.userType")}
					</label>
				</div>
				<SelectInput
					id="user-types"
					className={inputClasses.smallInput}
					noDefaultClassName
					fullWidth
					labelId="user-types-label"
					value={filters.userType}
					onChange={(e) => fieldChange(e, "userType", true)}
					data-cy="user-types"
				>
					{userTypes.map((userType) => (
						<MenuItem key={`user-type-${userType.id}`} value={userType.id} data-cy={`user-type-${userType.id}`}>
							{userType.label}
						</MenuItem>
					))}
				</SelectInput>
			</div>
			{/* <div className={classes.section}>
				<div className={classes.fieldHeading}>
					<label id="departments-label" htmlFor="departments">
						{t("filter.department")}
					</label>
				</div>
				<SelectInput
					id="departments"
					className={inputClasses.smallInput}
					noDefaultClassName
					fullWidth
					labelId="departments-label"
					value={filters.department}
					onChange={(e) => fieldChange(e, "department", true)}
					data-cy="departments"
				>
					{departments
						? [departmentNone].concat(departments).map((department) => (
								<MenuItem key={`department-${department.id}`} value={department.id} data-cy={`department-${department.id}`}>
									{department.name}
								</MenuItem>
						  ))
						: null}
				</SelectInput>
			</div> */}
			<div className={classes.section}>
				<div className={clsx(classes.fieldHeading, classes.fieldHeadingStackedCheckbox)}>
					<label id="meeting-groups-label" htmlFor="meeting-groups">
						{t("filter.meetingGroup")}
					</label>
				</div>
				<div id="meeting-groups">
					<StackedCheckbox
						options={meetingGroups}
						getChecked={(meetingGroup) => filters.boards && filters.boards.includes(meetingGroup.id)}
						getId={(meetingGroup) => `filter-meeting-group-${meetingGroup.id}`}
						getKey={(meetingGroup) => `filter-meeting-group-${meetingGroup.id}`}
						getValue={(meetingGroup) => meetingGroup.id}
						getDataCy={(meetingGroup) => `filter-meeting-group-${meetingGroup.id}`}
						getLabel={(meetingGroup) => meetingGroup.name}
						handleChange={meetingGroupClick}
					/>
				</div>
			</div>
		</>
	);
};

export default UserFilter;
