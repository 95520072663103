import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AutoSizer, CellMeasurer, CellMeasurerCache, InfiniteLoader, List } from "react-virtualized";

import makeStyles from "@mui/styles/makeStyles";

import styles from "atlas/assets/jss/components/menuStyle";
import NonModalMenu from "atlas/components/Menu/NonModalMenu";
import CircularProgressIndicator from "atlas/components/Progress/CircularProgressIndicator";
import PolicyDetailsHistoryEntry from "./PolicyDetailsHistoryEntry";
import { API_HOST } from "config/env";
import request from "superagent";
import { formatDate } from "utils/date";
import Popper from "@mui/material/Popper";
import telemetryAddEvent from "utils/telemetryAddEvent";
import { POLICY_PUBLISHED, POLICY_HISTORICAL, POLICY_REVIEW } from "utils/enums/PolicyTypes";

const useStyles = makeStyles({
	history: {
		flexGrow: "1",
	},
	menu: {
		...styles.menu,
	},
	comparePopper: {
		width: "100vw",
		height: "100vh",
		position: "fixed",
		zIndex: "5001 !important",
		top: "0px",
		left: "0px",
		"& .MuiPaper-root": {
			margin: "0 auto",
		},
	},
});

const PolicyDetailsHistory = (props) => {
	const { document, history, loadMore, toggleFieldChanges, handleView, handleRestore, setComparePopperDetails } = props;
	const { isText, guid, modifiedBy, dateModified, policyStatus } = document;
	const { t } = useTranslation("documents");
	const [anchor, setAnchor] = useState(null);
	const [apiLoading, setApiLoading] = useState(false);
	const [menuKey, setMenuKey] = useState(null);
	const classes = useStyles();
	const cache = new CellMeasurerCache({
		defaultHeight: 60,
		fixedWidth: true,
	});

	const handleToggleMenu = (e, changeSet) => {
		setAnchor({
			element: e.currentTarget,
			changeSet,
		});
		setMenuKey((prevKey) => (prevKey === null ? 1 : prevKey + 1));
	};

	const closeMenu = () => setAnchor(null);

	const isRowLoaded = ({ index }) => history && history.changeSets && !!history.changeSets[index];

	const convertToByteArray = (fileBytes) => {
		const byteCharacters = atob(fileBytes);
		const byteNumbers = new Array(byteCharacters.length);
		for (let i = 0; i < byteCharacters.length; i++) {
			byteNumbers[i] = byteCharacters.charCodeAt(i);
		}
		const byteArray = new Uint8Array(byteNumbers);
		return byteArray;
	};

	const getHistoryDocumentURL = (guid, changeSet) => {
		setApiLoading(true);
		const { dateChanged, changedBy, changeSetId } = changeSet;
		request
			.get(`${API_HOST}/api/policy/${guid}/policyHistoryPdf/?changeSetId=${changeSetId}`)
			.then((res) => {
				const { Document } = res.body;
				let url1 = `${API_HOST}/document/${guid}/?printPdf=true`;
				let url2 = convertToByteArray(Document);
				let document1Details1 = {
					name: modifiedBy,
					date: formatDate(dateModified),
				};
				let documentDetails2 = {
					name: changedBy,
					date: formatDate(dateChanged),
				};
				setApiLoading(false);
				setComparePopperDetails(url1, url2, document1Details1, documentDetails2, t("policyCompareMode.historyversion"));
			})
			.catch((err) => {
				showSignIn(err, () => {
					getHistoryDocumentURL(guid, changeSet);
				});
			});
	};

	const rowRenderer = (rowProps) => {
		const { key, index, parent, style } = rowProps;
		const changeSet = history.changeSets[index];

		return (
			<CellMeasurer key={key} cache={cache} columnIndex={0} parent={parent} rowIndex={index}>
				<div style={style} role="row" data-cy={`history-change-set`}>
					<div role="cell">
						<PolicyDetailsHistoryEntry
							document={document}
							changeSet={changeSet}
							showRestore={index > 0}
							toggleFieldChanges={toggleFieldChanges}
							handleToggleMenu={(e) => handleToggleMenu(e, changeSet)}
						/>
					</div>
				</div>
			</CellMeasurer>
		);
	};

	const getPendoEvents = () => {
		if (policyStatus === POLICY_PUBLISHED || policyStatus === POLICY_HISTORICAL) {
			return "Policy Published - Activity history - Compare";
		} else if (policyStatus === POLICY_REVIEW) {
			return "Policy Attachment- Activity history - Compare";
		} else {
			return "Policy Draft - Activity history - Compare";
		}
	};

	const getMenuOptions = (changeSet) => [
		{
			label: t("app:menu.view"),
			actionFunction: () => {
				handleView(changeSet);
			},
		},
		{
			label: t("app:menu.compare"),
			actionFunction: () => {
				telemetryAddEvent(getPendoEvents());
				getHistoryDocumentURL(guid, changeSet);
			},
			hidden: !isText,
		},
		{
			label: t("app:menu.restore"),
			actionFunction: () => {
				handleRestore(changeSet);
			},
		},
	];

	useEffect(() => {
		if (!history) {
			loadMore({ startIndex: 0 });
		}
	}, [history]);

	return history ? (
		<>
			{apiLoading && (
				<>
					<Popper
						id="history-compare-loader"
						className={classes.comparePopper}
						open={true}
						role="presentation"
						modifiers={[
							{
								name: "preventOverflow",
								enabled: true,
								options: {
									boundariesElement: "viewport",
								},
							},
						]}
					>
						<CircularProgressIndicator />
					</Popper>
				</>
			)}
			<div className={classes.history}>
				<InfiniteLoader isRowLoaded={isRowLoaded} loadMoreRows={loadMore} rowCount={history.total} minimumBatchSize={25}>
					{({ onRowsRendered, registerChild }) => (
						<AutoSizer>
							{({ height, width }) => (
								<List
									ref={registerChild}
									onRowsRendered={onRowsRendered}
									rowRenderer={rowRenderer}
									rowCount={history.changeSets.length}
									rowHeight={cache.rowHeight}
									height={height}
									width={width}
									deferredMeasurementCache={cache}
									aria-label={t("detail.labels.historyList")}
								/>
							)}
						</AutoSizer>
					)}
				</InfiniteLoader>
				{anchor && (
					<NonModalMenu
						key={menuKey}
						id="change-set-menu"
						className={classes.menu}
						anchorEl={anchor.element}
						keepMounted
						open
						onClose={() => closeMenu(anchor.element)}
						options={getMenuOptions(anchor.changeSet)}
						position="bottom-start"
					/>
				)}
			</div>
		</>
	) : (
		<CircularProgressIndicator />
	);
};

export default PolicyDetailsHistory;
