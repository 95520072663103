import { RESET_PAGE_CONFIG, UPDATE_PAGE_TOOLBAR } from "./types";

const defaultState = {
	toolbar: {
		display: false,
	},
};

export const toolbarReducer = (
	state = {
		...defaultState,
	},
	action,
) => {
	let newState = state;
	switch (action.type) {
		case RESET_PAGE_CONFIG: {
			newState = {
				...defaultState,
			};
			break;
		}

		case UPDATE_PAGE_TOOLBAR: {
			let toolbar = action.payload;
			if (action.payload.badgeUpdates && action.payload.badgeUpdates.length > 0) {
				({ toolbar } = state);
				action.payload.badgeUpdates.forEach((update) => {
					if (toolbar.left && toolbar.left.tools) {
						const found = toolbar.left.tools.find((tool) => tool.id === update.id);
						if (found) {
							found.badgeProps.display = update.display;
						}
					}
					if (toolbar.right && toolbar.right.tools) {
						const found = toolbar.right.tools.find((tool) => tool.id === update.id);
						if (found) {
							found.badgeProps.display = update.display;
						}
					}
				});
			}

			newState = {
				...state,
				toolbar,
			};
			break;
		}

		default:
			break;
	}

	return newState;
};

export default toolbarReducer;
