import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { AutoSizer, CellMeasurer, CellMeasurerCache, InfiniteLoader, List } from "react-virtualized";

import makeStyles from "@mui/styles/makeStyles";

import CircularProgressIndicator from "atlas/components/Progress/CircularProgressIndicator";
import PolicyDetailsAccessLogEntry from "./PolicyDetailsAccessLogEntry";

const useStyles = makeStyles({
	accessLogs: {
		flexGrow: "1",
	},
});

const PolicyDetailsAccessLog = (props) => {
	const { accessLogs, loadMore } = props;
	const { t } = useTranslation("documents");
	const classes = useStyles();
	const cache = new CellMeasurerCache({
		defaultHeight: 60,
		fixedWidth: true,
	});

	const isRowLoaded = ({ index }) => accessLogs && !!accessLogs[index];

	const rowRenderer = (rowProps) => {
		const { key, index, parent, style } = rowProps;
		const accessLog = accessLogs[index];

		return (
			<CellMeasurer key={key} cache={cache} columnIndex={0} parent={parent} rowIndex={index}>
				<div style={style} role="row" data-cy={`history-access-log`}>
					<div role="cell">
						<PolicyDetailsAccessLogEntry accessLog={accessLog} />
					</div>
				</div>
			</CellMeasurer>
		);
	};

	useEffect(() => {
		if (!accessLogs) {
			loadMore({ startIndex: 0 });
		}
	}, [accessLogs]);

	return accessLogs ? (
		<div className={classes.accessLogs}>
			<InfiniteLoader isRowLoaded={isRowLoaded} loadMoreRows={loadMore} rowCount={accessLogs.length} minimumBatchSize={25}>
				{({ onRowsRendered, registerChild }) => (
					<AutoSizer>
						{({ height, width }) => (
							<List
								ref={registerChild}
								onRowsRendered={onRowsRendered}
								rowRenderer={rowRenderer}
								rowCount={accessLogs.length}
								rowHeight={cache.rowHeight}
								height={height}
								width={width}
								deferredMeasurementCache={cache}
								aria-label={t("detail.labels.accessLogList")}
							/>
						)}
					</AutoSizer>
				)}
			</InfiniteLoader>
		</div>
	) : (
		<CircularProgressIndicator />
	);
};

export default PolicyDetailsAccessLog;
