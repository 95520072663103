import { focusColor, primaryColor } from "atlas/assets/jss/shared";
const tableOfContentsStyle = {
	listItem: {
		position: "relative",
		alignItems: "center !important",
		gap: "4px",
		"& .MuiListItemText-root": {
			marginTop: "0px !important",
			marginBottom: "0px !important",
		},
		"& [data-role='drag-handle']": {
			top: "calc(50% - 10px)", // handle height is 20px
			// left: "2px",
			padding: "4px 7px",
			borderRadius: "4px",
			alignItems: "center",
			display: "none",
			width: "24px",
		},
		"& .toc-move-buttons": {
			display: "none",
		},
		"&:hover": {
			backgroundColor: primaryColor[5],
		},
		"&:focus, &:focus-within, &:hover": {
			"& [data-role='drag-handle']": {
				display: "inline-flex",
			},
			"& .toc-move-buttons": {
				display: "inline-flex",
			},
		},
	},
	limitLines: {
		display: "-webkit-box",
		"-webkit-box-orient": "vertical",
		"-webkit-line-clamp": (props) => (props && props.lineLimit ? props.lineLimit : 2),
		overflow: "hidden",
		overflowWrap: "anywhere",
	},
	primaryTextEllipses: {
		whiteSpace: "nowrap",
		overflow: "hidden",
		textOverflow: "ellipsis",
	},
	copyMoved: {
		backgroundColor: "#edf5fA",
	},
	closedChanged: {
		backgroundColor: "#f3bbbb",
	},
	cancelCopyMove: {
		marginRight: "-16px",
	},
	selected: {
		borderLeft: "6px solid #5d7599 !important",
	},
	backgroundColorForSelected: {
		backgroundColor: "#E6E6E6",
	},
	tabFocusStyling: {
		"&:focus-visible": {
			outline: `solid 2px ${focusColor}`,
			outlineOffset: "-2px",
			borderRadius: "4px",
		},
	},
	bullet: {
		alignSelf: "baseline",
	},
	borderOfAttachmentAndRecomm: {
		border: "none",
	},
	itemValueAndTextGapping: {
		gap: "15px",
		borderLeft: "none",
	},
	tocHeaderStyleNonItem: {
		border: "none !important",
		padding: "0",
		gap: "0",
	},
	recommendationPaddingForHeading: {
		paddingLeft: "48px !important",
	},
	recommendationPaddingForItems: {
		paddingLeft: "84px !important",
	},
	tocHeadingLineHeight: {
		"& span, & p": {
			fontWeight: "600 !important",
			fontSize: "16px !important",
		},
	},
};

export default tableOfContentsStyle;
