import {
	RESET_PAGE_CONFIG,
	UPDATE_PAGE_CONFIG,
	UPDATE_APPBAR_TOOLS,
	UPDATE_PAGE_HEADER,
	UPDATE_PAGE_TABS,
	UPDATE_PAGE_TOOLBAR,
	UPDATE_LEFT_NAV,
	UPDATE_NOTICE,
	UPDATE_BOTTOM_NOTICE,
	UPDATE_SIGNALR,
	UPDATE_CURRENT_TIMESTAMP_ITEM,
	UPDATE_SIGN_IN_DIALOG,
	UPDATE_PENDING_APPROVALS_COUNT,
	UPDATE_REQUESTS_TO_SPEAK_COUNT,
	POLL_LIVE_DATA,
	SET_PROGRESS_IDS,
} from "./types";
import request from "superagent";
import { API_HOST } from "config/env";

const POLL_INTERVAL = 60000;

export function resetPageConfigs(config) {
	return (dispatch) => {
		dispatch({
			type: RESET_PAGE_CONFIG,
			payload: config || {},
		});
	};
}

export function updatePageConfigs(config) {
	return (dispatch) => {
		dispatch({
			type: UPDATE_PAGE_CONFIG,
			payload: config,
		});
	};
}

export function updateAppbarTools(config) {
	return (dispatch) => {
		dispatch({
			type: UPDATE_APPBAR_TOOLS,
			payload: config,
		});
	};
}

export function updatePageHeader(config) {
	return (dispatch) => {
		dispatch({
			type: UPDATE_PAGE_HEADER,
			payload: config,
		});
	};
}

export function updateTabs(tabs) {
	return (dispatch) => {
		dispatch({
			type: UPDATE_PAGE_TABS,
			payload: tabs,
		});
	};
}

export function updateToolbar(toolbarConfigs) {
	return (dispatch) => {
		dispatch({
			type: UPDATE_PAGE_TOOLBAR,
			payload: toolbarConfigs,
		});
	};
}

export function updateLeftNav(config) {
	return (dispatch) => {
		dispatch({
			type: UPDATE_LEFT_NAV,
			payload: config,
		});
	};
}

export function updateNotice(config) {
	return (dispatch) => {
		dispatch({
			type: UPDATE_NOTICE,
			payload: config,
		});
	};
}

export function updateBottomNotice(config) {
	return (dispatch) => {
		dispatch({
			type: UPDATE_BOTTOM_NOTICE,
			payload: config,
		});
	};
}

export function updateSignalR(config) {
	return (dispatch) => {
		dispatch({
			type: UPDATE_SIGNALR,
			payload: config,
		});
	};
}

export function updateCurrentTimestampItem(timestampitem) {
	return (dispatch) => {
		dispatch({
			type: UPDATE_CURRENT_TIMESTAMP_ITEM,
			payload: timestampitem,
		});
	};
}

export const updateSignInDialog = (payload) => (dispatch) => {
	dispatch({
		type: UPDATE_SIGN_IN_DIALOG,
		payload,
	});
};

export const updatePendingApprovalsCount = (payload) => (dispatch) => {
	dispatch({
		type: UPDATE_PENDING_APPROVALS_COUNT,
		payload,
	});
};

export const updateRequestsToSpeakCount = (payload) => (dispatch) => {
	dispatch({
		type: UPDATE_REQUESTS_TO_SPEAK_COUNT,
		payload,
	});
};

export const pollLiveData =
	({ callApi = false, resetTimeout = false, cancel = false } = {}) =>
	(dispatch, getState) => {
		const state = getState();
		let {
			appReducer: { liveDataPolling },
		} = state;

		// Set up or cancel polling
		if (!cancel && (resetTimeout || !liveDataPolling.intervalId)) {
			liveDataPolling.endPollingDate = new Date().getTime() + 4 * 60 * 60 * 1000; // Now + 4 hours
		}
		if (cancel || (liveDataPolling.endPollingDate && liveDataPolling.endPollingDate < new Date().getTime() && liveDataPolling.intervalId)) {
			clearInterval(liveDataPolling.intervalId);
			liveDataPolling.intervalId = 0;
		} else if (!cancel && !liveDataPolling.intervalId) {
			liveDataPolling.intervalId = setInterval(() => dispatch(pollLiveData({ callApi: true })), POLL_INTERVAL);
		}

		return new Promise((resolve, reject) => {
			// Only trigger the API request during polling
			if (callApi) {
				request
					.get(`${API_HOST}/api/load/livedata`)
					.withCredentials()
					.then((response) => response.body)
					.then((data) => {
						dispatch({
							type: POLL_LIVE_DATA,
							payload: data,
						});
						resolve(data);
					})
					.catch((exception) => {
						reject({ message: "Error getting live data", exception });
					});
			} else {
				resolve(null);
			}
		});
	};

export const setProgressIds =
	({ progressGuid, resultId, type } = {}) =>
	(dispatch) =>
		dispatch({
			type: SET_PROGRESS_IDS,
			payload: { progressGuid, resultId, type },
		});
