import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import makeStyles from '@mui/styles/makeStyles';

import ListItem from './ListItem';

const useStyles = makeStyles({
	list: {
		padding: '0',
		margin: '0',
		borderRadius: '2px',
		boxShadow: '0 2px 10px 0 rgba(0, 0, 0, 0.1)',
	},
});

const List = (props) => {
	const { className, listItemClasses, items } = props;
	const classes = useStyles();

	return (
		<ul className={clsx(classes.list, className)}>
			{items.map((item) => (
				<ListItem
					classes={item.classes || listItemClasses}
					key={item.key}
					label={item.label}
					labelUrl={item.labelUrl}
					menuOptions={item.menuOptions}
				/>
			))}
		</ul>
	);
};

List.propTypes = {
	className: PropTypes.string,
	listItemClasses: PropTypes.object,
	items: PropTypes.arrayOf(
		PropTypes.shape({
			classes: PropTypes.object,
			key: PropTypes.string.isRequired,
			label: PropTypes.string.isRequired,
			labelUrl: PropTypes.string,
		}),
	).isRequired,
};

List.defaultProps = {
	className: undefined,
	listItemClasses: undefined,
};

export default List;
