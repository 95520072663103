import React from 'react';
import { Box, CircularProgress } from '@mui/material';
import { styled } from '@mui/material/styles';

const SpinnerContainer = styled(Box)({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	height: '75%',
});

const Spinner = () => {
	return (
		<SpinnerContainer>
			<CircularProgress data-cy="Spinner" />
		</SpinnerContainer>
	);
};

export default Spinner;
