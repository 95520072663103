import typographyStyle from './typographyStyle';
import { grayColor, whiteColor, primaryColor, infoColor } from '../shared';

const button = {
	borderRadius: '2px',
	margin: '0',
	marginRight: (props) => (props && props.hasRightMargin ? '8px' : undefined),
	marginTop: (props) => (props && props.hasTopMargin ? '24px' : undefined),
	display: 'inline-flex',
	alignItems: 'center',
};

const defaultButton = {
	...button,
	color: primaryColor[3],
	'&:hover': {
		backgroundColor: primaryColor[5],
	},
	'&:active': {
		backgroundColor: grayColor[4],
	},
	'&.Mui-focusVisible': {
		border: `solid 2px ${infoColor}`,
		margin: '-1px',
		backgroundColor: whiteColor,
	},
	'& > .MuiButton-label > :not(:first-child)': {
		marginLeft: '8px',
	},
};

const buttonStyle = () => ({
	default: {
		...button,
	},
	largeDefault: {
		...typographyStyle.largeButton,
		...defaultButton,
		padding: '16px',
	},
	mediumDefault: {
		...typographyStyle.button,
		...defaultButton,
		padding: '12px',
	},
	smallDefault: {
		...typographyStyle.button,
		...defaultButton,
		padding: '0 16px',
	},
	smallSquareDefault: {
		...typographyStyle.button,
		...defaultButton,
		borderWidth: '1px',
		width: '24px',
		minWidth: '24px',
		height: '24px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		padding: '0',
	},
	verySmallDefault: {
		...typographyStyle.button,
		...defaultButton,
		borderWidth: '1px',
		padding: '0 16px',
		height: '24px',
	},
	fullWidth: {
		width: '100%',
	},
	groupFirst: {
		borderTopRightRadius: '0',
		borderBottomRightRadius: '0',
		borderRight: 'none !important',
	},
	groupMiddle: {
		borderRadius: '0',
		borderRight: 'none !important',
	},
	groupLast: {
		borderTopLeftRadius: '0',
		borderBottomLeftRadius: '0',
	},
	tooltip: {
		display: 'inline-block',
	},
});

export default buttonStyle;
