import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import request from "superagent";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Close } from "components/Icons";
import { API_HOST } from "config/env";
import GenericDialog from "atlas/components/Dialogs/GenericDialog";
import notifierMessage from "utils/notifierMessage";
import { setSnackbarOptions } from "../../redux/snackBar/actions";

class GoalDeleteDialog extends Component {
	constructor(props) {
		super(props);

		const { goal } = props;
		this.state = {
			id: goal.id,
			name: goal.name,
			deletingGoal: false,
		};
	}

	handleDelete = () => {
		const { afterDelete, t, dispatch, onClose } = this.props;
		const { id } = this.state;
		if (!id) this.handleError();

		this.setState({ deletingGoal: true });
		request
			.delete(`${API_HOST}/api/goal/${id}`)
			.send({})
			.then(() => {
				let option = notifierMessage(t("deleteGoalDialog.snackbar.success"), "success");
				dispatch(setSnackbarOptions(option));
				onClose();
				afterDelete({ id });
			})
			.catch((err) => this.handleError(err));
	};

	handleCancel = () => {
		this.props.onClose();
	};

	handleError = (err) => {
		console.log("Error", err);
		this.props.onClose();
	};

	render() {
		const { t, show } = this.props;
		const { name, deletingGoal } = this.state;
		const i18n = t("deleteGoalDialog", { name });

		const dialog = {
			title: i18n.title,
			line1: i18n.line1,
			line2: i18n.line2,
			primaryTitle: i18n.buttons.delete,
			primaryAction: this.handleDelete,
			secondaryTitle: i18n.buttons.cancel,
			secondaryAction: this.handleCancel,
		};

		return (
			<GenericDialog
				show={show}
				title={dialog.title}
				primaryAction={dialog.primaryAction}
				primaryTitle={dialog.primaryTitle}
				secondaryAction={dialog.secondaryAction}
				secondaryTitle={dialog.secondaryTitle}
				secondaryDisabled={deletingGoal}
				clickAwayDisabled={deletingGoal}
				closeIcon={<Close />}
			>
				<Typography variant="h4">
					<Box fontWeight="fontWeightBold">{dialog.line1}</Box>
				</Typography>
				<Typography variant="h5">
					<Box mt={2}>{dialog.line2}</Box>
				</Typography>
			</GenericDialog>
		);
	}
}

export default withTranslation("goals")(GoalDeleteDialog);
