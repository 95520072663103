import React from "react";
import SvgIcon from "@mui/material/SvgIcon";

import themeConfig from "../../atlas/assets/jss/theme";
import getOr from "lodash/fp/getOr";

const icon = (props) => {
	const { color } = props;
	const fillColor = color ? getOr(color, ["palette", color, "main"], themeConfig) : "currentColor";

	return (
		<SvgIcon width="24" height="24" viewBox="0 0 24 24" {...props} data-cy="paperclip">
			<path
				fill-rule="evenodd"
				d="M2.25 0C1.83579 0 1.5 0.335786 1.5 0.75V3.75V15H0.75C0.335786 15 0 15.3358 0 15.75C0 16.1642 0.335786 16.5 0.75 16.5H2.25H10.3299L5.43944 22.0017C5.16425 22.3113 5.19214 22.7854 5.50173 23.0606C5.81131 23.3357 6.28537 23.3079 6.56056 22.9983L11.25 17.7226V23.25C11.25 23.6642 11.5858 24 12 24C12.4142 24 12.75 23.6642 12.75 23.25V17.7226L17.4394 22.9983C17.7146 23.3079 18.1887 23.3357 18.4983 23.0606C18.8079 22.7854 18.8357 22.3113 18.5606 22.0017L13.6701 16.5H21.75H23.25C23.6642 16.5 24 16.1642 24 15.75C24 15.3358 23.6642 15 23.25 15H22.5V3.75V0.75C22.5 0.335786 22.1642 0 21.75 0H2.25ZM3 15V4.5H21V15H3ZM3 3H21V1.5H3V3Z"
			/>
		</SvgIcon>
	);
};

export default icon;
