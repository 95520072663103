import { produce } from "immer";
import { v4 as uuid } from "uuid";

import findIndex from "lodash/fp/findIndex";

import AgendaItemTypesEnum from "utils/enums/AgendaItemTypes";
import { createMeetingElement } from "utils/meetingElement";
import { updateAgenda, reOrderItemArray, findItemByID, updateClosedAttachments } from "./utils";

export function addConsentSection() {
	const currentState = this.state;
	const headerId = uuid();
	const agendaItemId = uuid();
	const recommendationId = uuid();

	const nextState = produce(
		currentState,
		(draft) => {
			const newHeader = createMeetingElement({
				guid: headerId,
				itemType: 10,
				indent: "0",
				order: draft.items.length + 1,
				number: 1,
				name: "<p>Consent Agenda</p>",
				consent: true,
			});

			const newAgendaItem = createMeetingElement({
				guid: agendaItemId,
				itemType: 3,
				indent: "0",
				order: "1",
				number: 1,
				name: "<p>Consent Item</p>",
				consent: true,
				parentGuid: headerId,
			});

			const newRecommendation = createMeetingElement({
				guid: recommendationId,
				itemType: 7,
				indent: "0",
				order: "1",
				number: 1,
				name: "<p>Consent Action</p>",
				consent: true,
				parentGuid: headerId,
			});

			this.itemIdsToUpdate.push(headerId);
			this.itemIdsToUpdate.push(agendaItemId);
			this.itemIdsToUpdate.push(recommendationId);

			draft.items.push(newHeader);
			draft.items.push(newRecommendation);
			draft.items.push(newAgendaItem);
			reOrderItemArray(draft.items, this, currentState.customNumbering);
		},
		(patches, inversePatches) => {
			if (patches.length) {
				this.redoStack.push([...patches]);
				this.undoStack.push([...inversePatches]);
			}
		},
	);

	updateAgenda(this, { items: nextState.items }, headerId);
}

export function addMemberOnlySection() {
	const currentState = this.state;
	const headerId = uuid();

	const nextState = produce(
		currentState,
		(draft) => {
			const newHeader = createMeetingElement({
				guid: headerId,
				itemType: 10,
				indent: "0",
				order: draft.items.length + 1,
				number: 1,
				closed: true,
			});

			this.itemIdsToUpdate.push(headerId);

			draft.items.push(newHeader);
			reOrderItemArray(draft.items, this, currentState.customNumbering);
		},
		(patches, inversePatches) => {
			if (patches.length) {
				this.redoStack.push([...patches]);
				this.undoStack.push([...inversePatches]);
			}
		},
	);

	updateAgenda(this, { items: nextState.items }, headerId);
}

export function addPublicCommentSection() {
	const currentState = this.state;
	const headerId = uuid();

	const nextState = produce(
		currentState,
		(draft) => {
			const newHeader = createMeetingElement({
				guid: headerId,
				itemType: 10,
				indent: "0",
				order: draft.items.length + 1,
				number: 1,
				publicComment: true,
			});

			this.itemIdsToUpdate.push(headerId);

			draft.items.push(newHeader);
			reOrderItemArray(draft.items, this, currentState.customNumbering);
		},
		(patches, inversePatches) => {
			if (patches.length) {
				this.redoStack.push([...patches]);
				this.undoStack.push([...inversePatches]);
			}
		},
	);

	updateAgenda(this, { items: nextState.items }, headerId);
}

export function setSectionPublicStatus(guid, isMemberOnlySection) {
	const currentState = this.state;
	const nextState = produce(
		currentState,
		(draft) => {
			const item = findItemByID(guid, draft.items);
			item.fields.Closed.Value = !isMemberOnlySection;
			updateClosedAttachments(item);
			reOrderItemArray(draft.items, this, currentState.customNumbering);
		},
		(patches, inversePatches) => {
			if (patches.length) {
				this.redoStack.push([...patches]);
				this.undoStack.push([...inversePatches]);
			}
		},
	);
	this.itemIdsToUpdate.push(guid);
	updateAgenda(this, { items: nextState.items });
}
export function setConsentSectionStatus(guid, isConsent) {
	const currentState = this.state;
	const nextState = produce(
		currentState,
		(draft) => {
			const item = findItemByID(guid, draft.items);
			const itemIndex = findIndex((draftItem) => draftItem.guid === guid, draft.items);
			item.fields.Consent.Value = isConsent;

			if (isConsent) {
				let hasRecommendation = false;
				for (let i = itemIndex + 1; i < draft.items.length; i += 1) {
					const currentItem = draft.items[i];
					if (currentItem.itemType === AgendaItemTypesEnum().HEADING.value) break;
					if (currentItem.itemType === AgendaItemTypesEnum().RECOMMENDATION.value && currentItem.attributes.relationshipGuid === item.guid)
						hasRecommendation = true;
				}

				// add recommendation and items if needed - add in reverse order of how they should appear in agenda
				if (!hasRecommendation) {
					const recommendationId = uuid();
					const newRecommendation = createMeetingElement({
						guid: recommendationId,
						itemType: 7,
						indent: "0",
						order: "1",
						number: 1,
						name: "<p>Consent Action</p>",
						consent: true,
						parentGuid: item.guid,
					});
					this.itemIdsToUpdate.push(recommendationId);
					draft.items.splice(itemIndex + 1, 0, newRecommendation);
				}
			}
			reOrderItemArray(draft.items, this, currentState.customNumbering);
		},
		(patches, inversePatches) => {
			if (patches.length) {
				this.redoStack.push([...patches]);
				this.undoStack.push([...inversePatches]);
			}
		},
	);
	this.itemIdsToUpdate.push(guid);
	updateAgenda(this, { items: nextState.items });
}

export function setPublicCommentSectionStatus(guid, isPublicComment) {
	const currentState = this.state;
	const nextState = produce(
		currentState,
		(draft) => {
			const item = findItemByID(guid, draft.items);
			item.fields = {
				...item.fields,
				PublicComment: {
					Value: isPublicComment,
				},
			};

			reOrderItemArray(draft.items, this, currentState.customNumbering);
		},
		(patches, inversePatches) => {
			if (patches.length) {
				this.redoStack.push([...patches]);
				this.undoStack.push([...inversePatches]);
			}
		},
	);
	this.itemIdsToUpdate.push(guid);
	updateAgenda(this, { items: nextState.items });
}
