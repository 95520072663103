/* eslint-disable no-nested-ternary */
/* eslint-disable no-plusplus */
import React, { useState, useEffect, useRef, useContext, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useMatch, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import cookie from "react-cookies";
import request from "superagent";
import SplitPane from "react-split-pane";
import { debounce } from "lodash";
import isEqual from "lodash/fp/isEqual";
import makeStyles from "@mui/styles/makeStyles";
import { API_HOST } from "config/env";
import { formatDate } from "utils/date";
import { format } from "date-fns";
import processHtml from "utils/processHtml";
import telemetryAddEvent from "utils/telemetryAddEvent";
import { whiteColor } from "atlas/assets/jss/shared";
import StyledSwitch from "atlas/components/FormControls/StyledSwitch";
import ComponentContainer from "atlas/components/ComponentContainer/ComponentContainer";
import CircularProgressIndicator from "atlas/components/Progress/CircularProgressIndicator";
import { STATUS_INFO } from "atlas/assets/jss/utils/statusIndicators";
import { BOTTOM_LEFT } from "atlas/assets/jss/utils/placement";
import { ACTION_DISMISS } from "atlas/components/Cards/NoticeCard";
import typographyStyle from "atlas/assets/jss/components/typographyStyle";
import liveMeetingBottomNotice, {
	LIVE_MEETING_DATA_TYPE,
	selectAdministratorsActiveItem,
} from "../LiveMeeting/utils/liveMeetingBottomNotice";
import { resetPageConfigs, updatePageConfigs, updateNotice, updateBottomNotice } from "redux/app/actions";
import { updatePageHeader } from "redux/pageHeader/actions";
import { SettingsContext } from "contexts/Settings/SettingsContext";
import {
	setActive,
	getMeetingAgendaItems,
	getMeetingGroups,
	getMeetingMembers,
	getMeetingMinutesItems,
	getPlaceholdersValues,
	resetMeeting,
	setPresenting,
	setSelected,
	updateMeetingDate,
	updateMotion,
	persistMinutes,
	updateMeetingVotingData,
} from "redux/meeting/actions";
import AdminLiveMeetingTopBar from "./components/AdminLiveMeetingTopBar";
import RightPanelContainer from "components/Panels/RightPanelContainer";
import { getVotingData, getVotingSettings, putRollCall, toggleBoxcastIntermission, updateOnlineVoters } from "redux/liveMeeting/actions";
import RollCallPanel from "./components/RollCallPanel";
import { findPlaceholders } from "./utils/placeholders";
import { Box } from "@mui/material";
import { useWidthDown } from "atlas/utils/useWidth";
import MinutesEditor from "./components/MinutesEditor";
import AddPolicyToAgendaDialog from "components/Dialogs/AddPolicyToAgendaDialog";
import AddGoalsToAgendaDialog from "components/Dialogs/AddGoalsToAgendaDialog";
import FileUploadFailureDialog from "components/Dialogs/FileUploadFailureDialog";
import { finishVote } from "redux/meeting/actions";
import { STATUS_SAVED_TIMEOUT } from "utils/meetingElement";
import notifierMessage from "utils/notifierMessage";
import { setSnackbarOptions } from "redux/snackBar/actions";
import TableOfContents from "components/Meeting/TableOfContents";
import TableOfContentsContainer from "views/MeetingTemplate/components/TableOfContentsContainer";
import { useHotkeys } from "react-hotkeys-hook";

const useStyles = makeStyles(() => ({
	agenda: {
		margin: "0",
		padding: "0",
	},
	stickyToolbar: {
		position: "-webkit-sticky",
		position: "sticky",
		top: 0,
		zIndex: 150,
		marginLeft: (props) => (props.showOutline && !props.showSplitPane ? "265px" : "0"),
		width: (props) => (props.showOutline && !props.showSplitPane ? "calc(100% - 265px)" : "100%"),
		minWidth: "200px",
		padding: undefined,
		boxSizing: (props) => (props.showSplitPane ? "border-box" : undefined),
	},
	tableOfContentContainer: {
		overflow: "auto",
		maxWidth: (props) => (props.showSplitPane ? undefined : "265px"),
		minWidth: (props) => (props.showSplitPane ? undefined : "265px"),
		height: "calc(100vh - 212px)",
		position: (props) => (props.showSplitPane ? undefined : "fixed"),
		borderRight: "1px solid #E6E6E6",
		paddingBottom: "8px",
	},
	contentContainer: {},
	switch: {
		"& .MuiSwitch-thumb": {
			backgroundColor: whiteColor,
		},
	},
	switchLabel: {
		...typographyStyle.fieldLabel,
		color: "#fff",
		marginRight: "8px",
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
	},
	switchInstructions: {
		...typographyStyle.fieldLabel,
		color: "#fff",
	},
}));

const saveDelay = 2000;

const AdminLiveMeetingV2 = (props) => {
	const { showSignIn } = props;
	const showSplitPane = true;
	const widthDownMd = useWidthDown("md");
	const widthDownLg = useWidthDown("lg");
	const navigate = useNavigate();
	const location = useLocation();
	const { params: { id } = {} } = useMatch({ path: "/meeting/liveV2/:id", end: true }) || {};
	const { t } = useTranslation("meetings");

	const isMounted = useRef(null);
	const statusTimeout = useRef(null);

	const [saveStatus, setSaveStatus] = useState({ status: null, tooltip: null });
	const [uploadStatus, setUploadStatus] = useState({ status: null, tooltip: null });
	const [broadcastIntermissionData, setBroadcastIntermissionData] = useState({ intermission: false, hidden: true });
	const [panels, setPanels] = useState({
		rollCall: false,
	});
	const [dialogs, setDialogs] = useState({});
	const [showOutline, setShowOutline] = useState(true);

	const dispatch = useDispatch();
	const meetingsReducer = useSelector((state) => state.meetingsReducer);
	const client = useSelector((state) => state.appReducer.signalR?.client);
	const data = useSelector((state) => state.appReducer.bottomNotice?.data) || {};
	const votingData = useSelector((state) => state.liveMeetingReducer.votingData);
	const onlineVoters = useSelector((state) => state.liveMeetingReducer.onlineVoters);
	const votingSettings = useSelector((state) => state.liveMeetingReducer.votingSettings);
	const adoptUpdating = useSelector((state) => state.liveMeetingReducer.adoptUpdating);
	const {
		updating,
		minutesUpdated,
		active,
		selected,
		presenting,
		shouldPersistMinutes,
		updateVoting,
		finishVoteItem,
		finishVoteDisposition,
		shouldFinishVote,
		minutesItems,
	} = meetingsReducer;
	const hotkeysRef = useRef({ selected, minutesItems, panels }); // Avoid stale references in the hotkeys closures
	const classes = useStyles({ showOutline: showOutline && !widthDownLg, showSplitPane });

	const hotKeyProps = { enableOnContentEditable: true, enableOnFormTags: ["input", "select", "textarea"] };

	const nextAgendaShortCut = {
		keys: t("shortCutKeys.nextAgendaShortCutKey"),
		props: hotKeyProps,
	};
	const previousAgendaShortCut = {
		keys: t("shortCutKeys.previousAgendaShortCutKey"),
		props: hotKeyProps,
	};
	const rollCallShortCut = {
		keys: t("shortCutKeys.rollCallShortCutKey"),
		props: hotKeyProps,
	};

	const setBottomBar = (thisPage, clear, thisData) => {
		const { dataType = "" } = thisData;

		if (dataType === LIVE_MEETING_DATA_TYPE) {
			dispatch(
				updateBottomNotice(
					!clear
						? liveMeetingBottomNotice({
								dispatch,
								t,
								navigate,
								location: thisPage ? location : "/",
								liveMeeting: thisData,
							})
						: {},
				),
			);
		}
	};

	useEffect(() => {
		if (!selected && minutesItems && minutesItems.length > 0) {
			dispatch(setSelected(minutesItems[0].guid));
		}
	}, [!selected]);

	useEffect(() => {
		hotkeysRef.current = { selected, minutesItems, panels };
	}, [minutesItems, selected, panels]);

	const handleSelectedElement = (index) => {
		dispatch(setSelected(hotkeysRef.current.minutesItems[index].guid));
		dispatch(setActive(hotkeysRef.current.minutesItems[index].guid), true);
	};

	const handleScrollElement = (index) => {
		let agendaListItemElement = document.getElementById(`outline-minute-${hotkeysRef.current.minutesItems[index].guid}`);
		let radioButtonElement = document.getElementsByClassName(`live-meeting-item-${hotkeysRef.current.minutesItems[index].guid}`);
		if (agendaListItemElement) {
			agendaListItemElement.scrollIntoView();
		}
		if (radioButtonElement && radioButtonElement[0]) {
			radioButtonElement[0].scrollIntoView({
				block: "center",
			});
		}
	};
	const handleNextAgendaKeyboardShortcut = () => {
		const index = hotkeysRef.current.minutesItems.findIndex((product) => product.guid == hotkeysRef.current.selected);
		if (hotkeysRef.current.minutesItems[index + 1] && hotkeysRef.current.minutesItems[index + 1].guid) {
			handleSelectedElement(index + 1);
			handleScrollElement(index + 1);
		}
	};

	const handlePreviousAgendaKeyboardShortcut = () => {
		const index = hotkeysRef.current.minutesItems.findIndex((product) => product.guid == hotkeysRef.current.selected);
		if (hotkeysRef.current.minutesItems[index - 1] && hotkeysRef.current.minutesItems[index - 1].guid) {
			handleSelectedElement(index - 1);
			handleScrollElement(index - 1);
		}
	};

	const handleRollCallKeyboardShortcut = () => {
		if (hotkeysRef.current.panels.rollCall) {
			closeRollCallPanel();
		} else if (!hotkeysRef.current.panels.rollCall) {
			rollCallClick();
		}
	};
	useHotkeys(nextAgendaShortCut.keys, handleNextAgendaKeyboardShortcut, nextAgendaShortCut.props);
	useHotkeys(previousAgendaShortCut.keys, handlePreviousAgendaKeyboardShortcut, previousAgendaShortCut.props);
	useHotkeys(rollCallShortCut.keys, handleRollCallKeyboardShortcut, rollCallShortCut.props);

	const [newMinutes, setNewMinutes] = useState({ enabled: (cookie.load("old_minutes") || "false") === "false" });

	const bottomBarRef = useRef({ presenting, data }); // Avoid caching these values in closures
	const editorToolbarRef = useRef(null);
	const isSaving = useRef(false);
	const isVotingSaving = useRef(false);
	const keepCheckingVoting = useRef(false);
	const saveQueue = useRef([]);
	const uploadQueue = useRef([]);
	const { policyEnabled, dateFormat } = useContext(SettingsContext);

	const setIntermissionButton = (broadcastDetails) => {
		if (broadcastDetails && broadcastDetails.length > 0) {
			var currentlyInCamera = false;
			var minimuMillisecondsToStart = Number.MAX_SAFE_INTEGER;
			var allBroadcastsEnded = true;

			for (var i = 0; i < broadcastDetails.length; i++) {
				var broadcastDetail = broadcastDetails[i];

				if (
					(broadcastDetail.metadata.mixer != null && broadcastDetail.metadata.mixer.muted) ||
					(broadcastDetail.metadata.overlays != null && broadcastDetail.metadata.overlays.length > 0)
				) {
					currentlyInCamera = true;
				}

				minimuMillisecondsToStart =
					broadcastDetail.millisecondsToStart < minimuMillisecondsToStart ? broadcastDetail.millisecondsToStart : minimuMillisecondsToStart;
				allBroadcastsEnded = allBroadcastsEnded && broadcastDetail.streamEnded;
			}

			if (!allBroadcastsEnded) {
				if (minimuMillisecondsToStart > 0) {
					//enable intermission button and set state to not in intermission
					//setBroadcastIntermissionData((previous) => ({ ...previous, intermission: false, disabled: true, hidden: false })); ----- the disabled button does not look styled correct so the button will be hidden instead
					setTimeout(function () {
						setBroadcastIntermissionData((previous) => ({ ...previous, intermission: false, disabled: false, hidden: false }));
					}, minimuMillisecondsToStart);
				} else {
					//enable intermission button and set state to 'currentlyInCamera'
					setBroadcastIntermissionData((previous) => ({ ...previous, intermission: currentlyInCamera, disabled: false, hidden: false }));
				}
			} else {
				//disable intermission button and set state to not in intermission	----- the disabled button does not look styled correct so the button will be hidden instead
				//setBroadcastIntermissionData((previous) => ({ ...previous, intermission: false, disabled: true, hidden: false }));
			}
		}
	};

	const getBroadcastDetails = (data) => {
		if (data.length > 0) {
			request
				.post(`${API_HOST}/api/broadcastdetails`)
				.send({ Broadcasts: data })
				.then((res) => {
					if (res.body) {
						setIntermissionButton(res.body);
					} else {
						//don't show button
						setBroadcastIntermissionData((previous) => ({ ...previous, intermission: false, disabled: false, hidden: true }));
					}
				})
				.catch((err) => {
					console.log(err);
				});
		} else {
			//don't show button
			setBroadcastIntermissionData((previous) => ({ ...previous, intermission: false, disabled: false, hidden: true }));
		}
	};

	const handleMinutesSettingChange = (checked) => {
		telemetryAddEvent(`Live meeting - New minutes ${checked ? "on" : "off"}`);

		cookie.save("old_minutes", !checked, { path: "/" });
		setNewMinutes({ enabled: checked });
		if (!checked && window.location.pathname.indexOf("/liveV2/") > 0) {
			navigate(`/meeting/live/${id}`);
		} else if (checked && window.location.pathname.indexOf("/live/") > 0) {
			navigate(`/meeting/liveV2/${id}`);
		}

		dispatch(
			updatePageHeader({
				additionalRightAction: (
					<div style={{ display: "flex", color: "#fff" }}>
						<StyledSwitch
							classes={{
								label: classes.switchLabel,
								stateLabel: classes.switchInstructions,
								switch: classes.switch,
							}}
							inline
							label={t("newMinutes")}
							useLabel
							title={t("tooltips.newMinutesOn")}
							size="small"
							objectToUpdate={newMinutes}
							fieldToUpdate="enabled"
							onChange={(checked) => handleMinutesSettingChange(checked)}
							data-cy="community-new-minutes-enabled"
						/>
					</div>
				),
			}),
		);
	};

	const loadMeeting = async () => {
		try {
			dispatch(getMeetingAgendaItems(id)).then((data) => {
				if (isMounted.current) {
					const { meeting } = data;
					const meetingDate = formatDate(null, meeting.startTime, meeting.endTime, t("app:at"), t("from"), t("to"), false);

					dispatch(updateMeetingDate(meetingDate));
					dispatch(
						updatePageConfigs({
							title: t("liveMeeting.title", { name: meeting.name }),
							back: { url: `/meeting/${id}` },
							telemetryPage: "Live meeting",
							contentMaxWidth: "xl",
						}),
					);
					dispatch(
						updatePageHeader({
							additionalText: [meetingDate],
							additionalRightAction: (
								<div style={{ display: "flex", color: "#fff" }}>
									<StyledSwitch
										classes={{
											label: classes.switchLabel,
											stateLabel: classes.switchInstructions,
											switch: classes.switch,
										}}
										inline
										label={t("newMinutes")}
										useLabel
										title={t("tooltips.newMinutesOn")}
										size="small"
										objectToUpdate={newMinutes}
										fieldToUpdate="enabled"
										onChange={(checked) => handleMinutesSettingChange(checked)}
										data-cy="community-new-minutes-enabled"
									/>
								</div>
							),
						}),
					);

					dispatch(
						updateBottomNotice({
							persistentData: {
								fields: [
									{
										name: "hiddenMeetings",
										action: "pop",
										value: parseInt(id, 10),
									},
								],
							},
							update: true,
						}),
					);
					dispatch(setPresenting(meeting.live));

					if (meeting.live) {
						selectAdministratorsActiveItem(meeting.activeGuid);
					}
					if (meeting.purchasedBoxcast && meeting.broadcasts) {
						getBroadcastDetails(meeting.broadcasts);
					}
					if (meeting.votingInRange) {
						client.updateStatusIcon();
					}
				}
			});
			dispatch(getMeetingMinutesItems(id)).then((minutesData) => {
				const minutesItems = minutesData.items;
				const minutesItem = minutesItems.find((item) => item.agendaItemGuid === minutesData.meeting.activeGuid);
				dispatch(setSelected(minutesItem ? minutesItem.guid : minutesData.meeting.activeGuid));
			});

			dispatch(getMeetingMembers(id));
			dispatch(getMeetingGroups());
		} catch (err) {
			console.log(err);
		}
	};

	const loadVotingSettings = () => {
		dispatch(getVotingSettings()).catch((err) => {
			if (typeof showSignIn === "function") {
				showSignIn(err, () => {
					loadVotingSettings();
				});
			}
		});
	};

	const loadPlaceholderValues = () => {
		dispatch(getPlaceholdersValues(id, findPlaceholders(meetingsReducer.minutesItems || [])));
	};

	const videoNotice = (
		<div>
			{processHtml(t("liveMeeting.startPresentingNotification"))}
			<p>{processHtml(t("liveMeeting.startPresentingNotificationEnd"))}</p>
		</div>
	);

	const togglePresenting = (updatedPresenting) => {
		dispatch(
			updateNotice({
				status: STATUS_INFO,
				icon: "status-info",
				label: updatedPresenting ? videoNotice : t("liveMeeting.stopPresentingNotification"),
				complexLabel: true,
				placement: BOTTOM_LEFT,
				actions: [ACTION_DISMISS],
			}),
		);
		client.liveMeetingHub.updateLiveMeeting(id, updatedPresenting, selected);

		if (updatedPresenting) {
			window.open(`${API_HOST}/home/meeting/presentation/${id}`, "_blank");
		}

		telemetryAddEvent(`Live meeting - presenting ${updatedPresenting ? "started" : "stopped"}`);

		dispatch(setPresenting(updatedPresenting));
	};

	const toggleTableOfContentDrawer = () => {
		setShowOutline((prev) => !prev);
	};

	const intermissionClick = () => {
		dispatch(toggleBoxcastIntermission(meetingsReducer.meeting.id, !broadcastIntermissionData.intermission)).then((success) => {
			if (success) {
				let option = notifierMessage(
					!broadcastIntermissionData.intermission ? t("notifications.goToIntermission") : t("notifications.reenablePublicBroadcast"),
					"success",
				);
				dispatch(setSnackbarOptions(option));
				setBroadcastIntermissionData((previous) => ({ ...previous, intermission: !broadcastIntermissionData.intermission }));
			} else {
				let option = notifierMessage(
					!broadcastIntermissionData.intermission ? t("errors.goToIntermission") : t("errors.reenablePublicBroadcast"),
					"success",
				);
				dispatch(setSnackbarOptions(option));
			}
		});
	};

	const rollCallClick = () => {
		setPanels((prev) => ({
			rollCall: true,
		}));

		telemetryAddEvent("Live meeting - Open Roll Call");
	};

	const closeRollCallPanel = () => {
		setPanels((prev) => ({
			...prev,
			rollCall: false,
		}));
	};

	const clearStatus = () => {
		setSaveStatus({ status: null, tooltip: null });
	};

	const saveRollCall = debounce((rollCall) => {
		if (isSaving.current) {
			saveQueue.current.push(() => saveRollCall(rollCall));
		} else {
			clearTimeout(statusTimeout.current);
			setSaveStatus({ status: t("agendaMenu:saving"), tooltip: t("agendaMenu:saveTooltip") });

			dispatch(putRollCall(rollCall, id))
				.then((success) => {
					if (success) {
						isSaving.current = false;

						if (saveQueue.current.length > 0) {
							saveQueue.current.shift()();
						} else {
							setSaveStatus({
								status: t("agendaMenu:saved"),
								tooltip: `${t("agendaMenu:lastSaved")} ${format(new Date(), `${dateFormat} h:mm a`)}`,
							});
							statusTimeout.current = setTimeout(clearStatus, STATUS_SAVED_TIMEOUT);
						}
					}
				})
				.catch((err) => {
					isSaving.current = false;
					showSignIn(err, () => {
						saveRollCall(rollCall);
					});
				});
		}
	}, saveDelay);

	const handleUpdateMotion = useCallback(
		debounce((motion, updatedFields) => {
			if (isSaving.current) {
				saveQueue.current.push(() => handleUpdateMotion(motion, updatedFields));
			} else {
				clearTimeout(statusTimeout.current);
				setSaveStatus({ status: t("agendaMenu:saving"), tooltip: t("agendaMenu:saveTooltip") });
				dispatch(updateMotion(motion, updatedFields))
					.then((success) => {
						if (success) {
							isSaving.current = false;

							if (saveQueue.current.length > 0) {
								saveQueue.current.shift()();
							} else {
								setSaveStatus({
									status: t("agendaMenu:saved"),
									tooltip: `${t("agendaMenu:lastSaved")} ${format(new Date(), `${dateFormat} h:mm a`)}`,
								});
								statusTimeout.current = setTimeout(clearStatus, STATUS_SAVED_TIMEOUT);
							}
						}
					})
					.catch((err) => {
						isSaving.current = false;
						showSignIn(err, () => {
							handleUpdateMotion(motion, updatedFields);
						});
					});
			}
		}, 500),
		[isSaving.current],
	);

	useEffect(() => {
		// Keep this reference up to date
		bottomBarRef.current = { presenting, data };
	}, [presenting, data]);

	const checkVotingData = debounce(async () => {
		dispatch(getVotingData(id, !isVotingSaving.current)).then((data) => {
			const { votingInRange } = data;
			if (keepCheckingVoting.current && votingInRange) {
				checkVotingData();
			}
		});
	}, saveDelay);

	useEffect(() => {
		isMounted.current = true;
		dispatch(resetPageConfigs({}));
		loadMeeting();
		loadVotingSettings();

		setBottomBar(true, false, bottomBarRef.current.data);

		telemetryAddEvent("Live Meeting - Load");

		return () => {
			isMounted.current = false;

			dispatch(updateNotice({}));

			setBottomBar(false, !bottomBarRef.current.presenting, bottomBarRef.current.data);

			dispatch(resetMeeting());

			dispatch(resetPageConfigs());

			dispatch(updatePageHeader({ additionalRightAction: undefined }));

			client.updateStatusIcon();

			keepCheckingVoting.current = false;
			checkVotingData.cancel();

			localStorage.removeItem("splitPos");
		};
	}, [id]);

	useEffect(() => {
		if (meetingsReducer.meeting && meetingsReducer.meeting.digitalVoting && !keepCheckingVoting.current) {
			keepCheckingVoting.current = true;
			checkVotingData();
		}
	}, [
		meetingsReducer.meeting,
		meetingsReducer.meetingDate,
		meetingsReducer.meetingActive,
		meetingsReducer.agendaItems,
		meetingsReducer.requestsToSpeak,
		meetingsReducer.minutesItems,
		meetingsReducer.members,
		meetingsReducer.agendaNumbering,
		meetingsReducer.minutesNumbering,
	]);

	useEffect(() => {
		if (votingData != null && votingSettings) {
			const { onlineMembers, itemInProgress, votingInRange } = votingData;
			if (votingInRange) {
				if (onlineMembers && !isEqual(onlineMembers, onlineVoters)) {
					dispatch(updateOnlineVoters(onlineMembers));
				}

				if (itemInProgress && itemInProgress.itemVotingData && itemInProgress.status != 2 && minutesItems) {
					dispatch(updateMeetingVotingData(itemInProgress, votingSettings, t("voting.quorumNotMet")));
				}
			}
		}
	}, [votingData, votingSettings]);

	useEffect(() => {
		if (shouldFinishVote) {
			dispatch(finishVote(finishVoteItem.guid))
				.then((success) => {
					if (success) {
						client.votingHub.showResults(id, finishVoteItem.guid, finishVoteDisposition);
					}
				})
				.catch((err) => {
					showSignIn(err, () => {
						handleFinishVote(finishVoteItem.guid, finishVoteDisposition);
					});
				});
		}
	}, [shouldFinishVote]);

	const isSmallDevice = widthDownMd;
	const telemetryPage = "Minutes builder";

	const tocHeader = {
		guid: "toc-header",
		fields: {
			Indent: { Value: "0" },
			Order: { Value: " " },
			Name: { Value: t("agendaMenu:meetingHeader") },
		},
	};
	const tocFooter = {
		guid: "toc-footer",
		fields: {
			Indent: { Value: "0" },
			Order: { Value: " " },
			Name: { Value: t("agendaMenu:meetingFooter") },
		},
	};

	const checkUploadStatus = () => {
		let uTooltip = null;
		let uStatus = null;
		for (let i = 0; i < uploadQueue.current.length; i++) {
			if (!uploadQueue.current[i].complete) {
				if (uTooltip) {
					uTooltip += ` / ${uploadQueue.current[i].file.name}`;
				} else {
					uTooltip = uploadQueue.current[i].file.name;
				}
			}
		}
		if (uTooltip) {
			uStatus = t("agendaMenu:uploading");
		}
		setUploadStatus({ status: uStatus, tooltip: uTooltip });
	};

	const sendFiletoAPI = (fileData, isRetry = false) => {
		request
			.post(`${API_HOST}/api/documents/uploadattachments`)
			.withCredentials()
			.send(fileData)
			.then((res) => {
				if (res.status === 200) {
					res.body.Attachments.forEach((attachment) => {
						completeFileUpload(attachment.itemGuid, attachment.guid);
					});
					checkUploadStatus();
				}
			})
			.catch((err) => {
				const fileError = err.status === 400 || err.status === 500 || isRetry;
				if (!fileError) {
					showSignIn(
						err,
						() => {
							sendFiletoAPI(fileData);
						},
						!isRetry
							? () => {
									// Something went wrong, so wait 5 seconds and try again
									setTimeout(() => {
										sendFiletoAPI(fileData, true);
									}, 5000);
								}
							: undefined,
					);
				}
			});
	};

	const queueFileUploads = useCallback((itemGuid, fileUploads, fileData) => {
		for (let i = 0; i < fileUploads.length; i++) {
			uploadQueue.current.push({
				guid: itemGuid,
				fileGuid: fileUploads[i].guid,
				file: fileUploads[i],
				complete: false,
				error: null,
			});
		}
		checkUploadStatus();

		sendFiletoAPI(fileData);
	}, []);

	const completeFileUpload = (itemGuid, fileGuid, error) => {
		for (let i = 0; i < uploadQueue.current.length; i++) {
			if (uploadQueue.current[i].guid === itemGuid && uploadQueue.current[i].fileGuid === fileGuid) {
				uploadQueue.current[i].complete = true;
				uploadQueue.current[i].error = error;
			}
		}
		checkUploadStatus();
	};

	const invalidFileExtension = useCallback(
		(field, fileNames) => {
			if (fileNames.length > 0) {
				setUploadStatus({ ...uploadStatus, failedFileUploads: fileNames });
			}
		},
		[uploadStatus],
	);

	const closeFileUploadFailureDialog = () => setUploadStatus({ ...uploadStatus, failedFileUploads: null });

	const openAddPolicy = useCallback((options) => {
		setDialogs({
			addPolicy: options,
		});
	}, []);

	const openAddGoals = useCallback((options) => {
		setDialogs((prev) => ({
			...prev,
			addGoals: options,
		}));
	}, []);

	const closeAddGoalsDialog = () => {
		setDialogs((prev) => ({
			...prev,
			addGoals: false,
		}));
	};
	const closeDialogs = () => {
		setDialogs({});
	};

	useEffect(() => {
		if (shouldPersistMinutes) {
			clearTimeout(statusTimeout.current);
			setSaveStatus({ status: t("agendaMenu:saving"), tooltip: t("agendaMenu:saveTooltip") });
			dispatch(persistMinutes())
				.then((success) => {
					if (success) {
						setSaveStatus({
							status: t("agendaMenu:saved"),
							tooltip: `${t("agendaMenu:lastSaved")} ${format(new Date(), `${dateFormat} h:mm a`)}`,
						});
						statusTimeout.current = setTimeout(clearStatus, STATUS_SAVED_TIMEOUT);
					}
				})
				.catch((err) => {
					showSignIn(
						err,
						() => {
							dispatch(persistMinutes());
						},
						() => {
							clearTimeout(statusTimeout.current);
							setSaveStatus({
								status: t("agendaMenu:saveError"),
								tooltip: t("agendaMenu:saveErrorTooltip"),
							});
						},
					);
				});
		}
	}, [shouldPersistMinutes]);

	const meetingId = parseInt(id, 10);

	useEffect(() => {
		const pane1 = document.getElementsByClassName("Pane1")[0];
		const pane2 = document.getElementsByClassName("Pane2")[0];
		const minutesEditor = document.getElementById("new-editor-toc-header");
		if (!showOutline || (showOutline && widthDownMd)) {
			if (pane1 && pane2 && minutesEditor) {
				pane1.style.width = 0;
				pane2.style.width = "100%";
				minutesEditor.style.maxWidth = "100%";
			}
		} else {
			if (pane1 && pane2 && minutesEditor) {
				pane1.style.width = "30%";
				pane2.style.width = "70%";
				minutesEditor.style.maxWidth = "8.5in";
			}
		}
	}, [showOutline, widthDownMd]);

	const checkIfMeetingStarted = () => {
		if (meetingsReducer.meeting && meetingsReducer.meeting.startTimeStamp) {
			const meetingStartTimeStamp = meetingsReducer.meeting.startTimeStamp;
			const currentTimeStamp = Math.floor(Date.now() / 1000);
			return meetingStartTimeStamp < currentTimeStamp;
		}
		return false;
	};

	const minutesOutline = () => (
		<TableOfContentsContainer containerClass={classes.tableOfContentContainer} innerId="minute-outline">
			<TableOfContents
				items={minutesItems}
				isClosed={true}
				buttons
				isReorderable={checkIfMeetingStarted()}
				active={active}
				setActive={setActive}
				selected={selected}
				toggleTableOfContent={toggleTableOfContentDrawer}
				telemetryPage={telemetryPage}
				isMinutes
			/>
		</TableOfContentsContainer>
	);

	const minutesBuilder = () => (
		<Box className={classes.contentContainer}>
			<Box className={classes.stickyToolbar} id="toolbar" ref={editorToolbarRef} />
			<MinutesEditor
				showSignIn={showSignIn}
				editorToolbarRef={editorToolbarRef}
				handleUpdateMotion={handleUpdateMotion}
				queueFileUploads={queueFileUploads}
				addPolicy={policyEnabled ? openAddPolicy : undefined}
				addGoals={openAddGoals}
				invalidFileExtension={invalidFileExtension}
				votingData={votingData}
				onlineVoters={onlineVoters}
				votingSettings={votingSettings}
				updateVoting={updateVoting}
				adoptUpdating={adoptUpdating}
				showOutline={showOutline}
				showSplitPane={showSplitPane}
			/>
		</Box>
	);

	return !updating && minutesUpdated ? (
		<>
			<FileUploadFailureDialog
				show={uploadStatus.failedFileUploads && uploadStatus.failedFileUploads.length > 0}
				failedFileUploads={uploadStatus.failedFileUploads}
				onClose={closeFileUploadFailureDialog}
			/>
			{dialogs.addPolicy && (
				<AddPolicyToAgendaDialog meetingId={meetingId} options={dialogs.addPolicy} onClose={closeDialogs} showSignIn={showSignIn} />
			)}
			{dialogs.addGoals && (
				<AddGoalsToAgendaDialog
					meetingId={meetingId}
					options={dialogs.addGoals}
					onClose={closeAddGoalsDialog}
					showSignIn={showSignIn}
					isMinutesPage
				/>
			)}
			<ComponentContainer noPaddingDown="lg" noPaddingTopUp="xs" padding="0">
				<AdminLiveMeetingTopBar
					presenting={presenting}
					setPresenting={togglePresenting}
					broadcastIntermissionData={broadcastIntermissionData}
					intermissionClick={intermissionClick}
					toggleTableOfContent={toggleTableOfContentDrawer}
					rollCallClick={rollCallClick}
					panels={panels}
					saveStatus={saveStatus.status}
					saveTooltip={saveStatus.tooltip}
					showSignIn={showSignIn}
					downloadToolTip={t("tooltips.downloadMinutes")}
				/>

				{showSplitPane && (
					<SplitPane
						className="split-pane"
						allowResize={!widthDownMd}
						split="vertical"
						minSize={220}
						maxSize={820}
						defaultSize={parseInt(localStorage.getItem("splitPos"), 10) || (widthDownMd ? 0 : 360)}
						onChange={(size) => {
							localStorage.setItem("splitPos", size);

							telemetryAddEvent("Minutes builder outline resize");
						}}
					>
						<div className="pane-left">{minutesOutline()}</div>
						<div className="pane-right">{minutesBuilder()}</div>
					</SplitPane>
				)}

				<RightPanelContainer id="right-panel-container" open={panels.rollCall} fullHeight={panels.rollCall} float>
					{panels.rollCall && (
						<RollCallPanel
							closePanel={closeRollCallPanel}
							saveRollCall={saveRollCall}
							saveStatus={saveStatus.status}
							onlineVoters={onlineVoters}
							votingSettings={votingSettings}
						/>
					)}
				</RightPanelContainer>
			</ComponentContainer>
		</>
	) : (
		<CircularProgressIndicator />
	);
};

export default AdminLiveMeetingV2;
