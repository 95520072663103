import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';

import { useWidthDown } from '../../utils/useWidth';
import ButtonWithTooltip from '../Buttons/ButtonWithTooltip';
import { MEDIUM } from '../../utils/buttonSize';
import Tooltip from '../Tooltip/Tooltip';

const useStyles = makeStyles(() => ({
	dialog: {
		'& .MuiDialog-paper': {
			borderRadius: '2px',
		},
	},
	title: {
		'& h2': {
			fontSize: '24px',
		},
	},
	actions: {
		justifyContent: 'flex-end',
		paddingTop: '16px',
		paddingBottom: '16px',
	},
	leftAction: {
		flexGrow: '1',
	},
	titleText: {
		fontSize: '22px',
		fontWeight: '600',
		lineHeight: '26px',
		textWrap: 'noWrap',
		marginRight: '10px',
	},
}));

const GenericDialog = (props) => {
	const {
		className,
		show,
		title,
		closeAction,
		closeIcon,
		primaryAction,
		primaryTitle,
		primaryDisabled,
		primaryTooltip,
		secondaryAction,
		secondaryTitle,
		secondaryDisabled,
		secondaryTooltip,
		leftActionChildren,
		clickAwayDisabled = false,
		fullWidth = false,
		disableAutoFocus = false, // if set to true may cause dialog modal to be less accessible, only use if needed to focus on an element like inputs in a dialog
		disableEnforceFocus = false, // if set to true may cause dialog modal to be less accessible, only use if needed to focus on an element like inputs in a dialog
		children,
		ellipsedTruncatedText,
		isEllipsed = false,
		TransitionComponent,
		isLargeTitle = false,
	} = props;
	const widthDownSm = useWidthDown('sm');
	const { t } = useTranslation();
	const classes = useStyles();

	const showTitlebarCloseIcon = !clickAwayDisabled;

	return (
		show && (
			<>
				<Dialog
					className={clsx(classes.dialog, className)}
					classes={{ root: 'generic-dialog' }}
					fullScreen={widthDownSm}
					fullWidth={fullWidth}
					open={show}
					onClose={(event, reason) => {
						if (!clickAwayDisabled || !['escapeKeyDown', 'backdropClick'].includes(reason)) {
							const onClose = closeAction || secondaryAction;
							if (typeof onClose === 'function') {
								onClose(event, reason);
							}
						}
					}}
					aria-labelledby="alert-dialog-title"
					data-cy={props['data-cy'] || 'dialog'}
					PaperProps={{ square: true, 'aria-modal': 'true' }}
					disableAutoFocus={disableAutoFocus}
					disableEnforceFocus={disableEnforceFocus}
					TransitionComponent={TransitionComponent}
				>
					<DialogTitle id="alert-dialog-title" className={classes.title} data-cy="dialog-title" component="div">
						<Grid container spacing={0} direction="row" justifyContent="flex-start" alignItems="center">
							<Grid item xs={9}>
								{isEllipsed ? (
									<Tooltip disableHoverListener={!ellipsedTruncatedText.isTruncated} title={title}>
										<Typography variant="h2">{ellipsedTruncatedText.text}</Typography>
									</Tooltip>
								) : (
									<Typography className={isLargeTitle ? classes.titleText : ''} variant="h2">
										{title}
									</Typography>
								)}
							</Grid>
							<Grid item style={{ textAlign: 'right' }} xs={3}>
								{showTitlebarCloseIcon && (
									<Tooltip title={t('app:tooltips.closeButton')}>
										<IconButton
											className="align-with-icon"
											disabled={clickAwayDisabled}
											aria-label={t('app:tooltips.closeButton')}
											onClick={closeAction || secondaryAction}
											data-cy="closeIcon"
											size="large"
										>
											{closeIcon}
										</IconButton>
									</Tooltip>
								)}
							</Grid>
						</Grid>
					</DialogTitle>
					<DialogContent dividers tabIndex={0} data-cy="dialog-content">
						{children}
					</DialogContent>
					{(primaryTitle || secondaryTitle) && (
						<DialogActions className={classes.actions} data-cy="dialog-actions">
							<div className={classes.leftAction}>{leftActionChildren}</div>
							{secondaryTitle && (
								<ButtonWithTooltip
									title={secondaryTooltip}
									size={MEDIUM}
									variant="text"
									color="primary"
									onClick={secondaryAction}
									disabled={secondaryDisabled}
									data-cy="secondaryAction"
								>
									{secondaryTitle}
								</ButtonWithTooltip>
							)}
							{primaryTitle && (
								<ButtonWithTooltip
									title={primaryTooltip}
									size={MEDIUM}
									primary
									onClick={primaryAction}
									disabled={primaryDisabled}
									data-cy="primaryAction"
								>
									{primaryTitle}
								</ButtonWithTooltip>
							)}
						</DialogActions>
					)}
				</Dialog>
			</>
		)
	);
};

GenericDialog.propTypes = {
	className: PropTypes.string,
	show: PropTypes.bool,
	title: PropTypes.string.isRequired,
	closeAction: PropTypes.func,
	closeIcon: PropTypes.node,
	primaryAction: PropTypes.func,
	primaryTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
	primaryDisabled: PropTypes.bool,
	primaryTooltip: PropTypes.string,
	secondaryAction: PropTypes.func.isRequired,
	secondaryTitle: PropTypes.string,
	secondaryDisabled: PropTypes.bool,
	secondaryTooltip: PropTypes.string,
	leftActionChildren: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node, PropTypes.string]),
	clickAwayDisabled: PropTypes.bool,
	fullWidth: PropTypes.bool,
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

GenericDialog.defaultProps = {
	className: undefined,
	show: false,
	closeAction: undefined,
	primaryAction: undefined,
	primaryTitle: undefined,
	primaryDisabled: false,
	primaryTooltip: '',
	secondaryTitle: '',
	secondaryDisabled: false,
	secondaryTooltip: '',
	leftActionChildren: null,
	clickAwayDisabled: false,
	fullWidth: false,
};

export default GenericDialog;
