import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import clsx from "clsx";

import makeStyles from "@mui/styles/makeStyles";
import { Box, Typography } from "@mui/material";

import { blackColor, focusColor, primaryColor, infoColor } from "atlas/assets/jss/shared";
import Icon from "atlas/components/Icon/Icon";
import CircularProgressIndicator from "atlas/components/Progress/CircularProgressIndicator";
import Tooltip from "atlas/components/Tooltip/Tooltip";
import processHtml from "utils/processHtml";
import DocumentResult from "./DocumentResult";

const useStyles = makeStyles({
	documentIconContainer: {
		boxSizing: "border-box",
		width: (props) => (props.child ? "48px" : "56px"),
		padding: "8px",
	},
	expandActive: {
		cursor: "pointer",
		"&:focus-visible": {
			outline: `solid 2px ${focusColor}`,
			outlineOffset: "-2px",
			borderRadius: "4px",
		},
	},
	canSelect: {
		cursor: "pointer",
	},
	documentTextContainer: {
		display: "flex",
		flexDirection: "column",
		width: "90%",
	},
	linkRow: {
		overflow: "hidden",
		textOverflow: "ellipsis",
		display: "-webkit-box",
		WebkitBoxOrient: "vertical",
		WebkitLineClamp: 1,
	},
	link: {
		textDecoration: "none",
		fontWeight: "600",
		color: primaryColor[1],
		"& strong": {
			fontWeight: "700",
		},
		"&:focus-visible": {
			outline: `solid 2px ${focusColor}`,
			outlineOffset: "-2px",
			borderRadius: "4px",
		},
	},
	documentSampleHtml: {
		overflow: "hidden",
		textOverflow: "ellipsis",
		display: "-webkit-box",
		WebkitBoxOrient: "vertical",
		WebkitLineClamp: 3,
		marginTop: "8px",
	},
	breadcrumb: {
		fontWeight: "600",
		fontSize: "12px",
		lineHeight: "15px",
		marginTop: "8px",
		"& a": {
			textDecoration: "none",
			color: blackColor[1],
		},
		"& a:hover": {
			textDecoration: "underline",
		},
		"& a:focus-visible": {
			outline: `solid 2px ${focusColor}`,
			outlineOffset: "-2px",
			borderRadius: "4px",
		},
	},
	breadcrumbSeparator: {
		margin: "0 8px",
	},
	attachments: {
		paddingTop: "16px",
	},
	attachment: {
		display: "flex",
		"&:not(:first-child)": {
			marginTop: "12px",
		},
	},
	noData: {
		fontWeight: "600",
		fontSize: "16px",
		lineHeight: "20px",
		textAlign: "center",
		letterSpacing: "1px",
		textTransform: "uppercase",
		paddingTop: "16px",
	},
	loading: {
		height: "52px",
	},
	selected: {
		position: "absolute",
		top: "5px",
		left: "0",
		bottom: "5px",
		right: "0",
		outline: `2px solid ${infoColor}`,
		borderRadius: "2px",
	},
	searchResult: {
		display: "flex",
		alignItems: "center",
		gap: "8px",
		position: "relative",
		marginTop: "8px",
	},
});

const SearchResult = (props) => {
	const {
		result,
		icon: { name, tooltip },
		handleExpand,
		link,
		externalLink = false,
		generatePathLink,
		child,
		isPolicy,
		enableSelect,
		selection,
		setSelectedData = () => {},
		policyAddToAgendaSearch = false,
	} = props;
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [selected, setSelected] = useState(false);
	const classes = useStyles({ child });
	const textWithIcon = policyAddToAgendaSearch && isPolicy && !result?.folder;

	const getProcessedCode = (code) => {
		if (code !== null) {
			code = code + " ";
			return processHtml(code, { highlightStrong: true });
		}
		return "";
	};
	const getProcessedText = () => {
		return (
			<div className={classes.link}>
				{(isPolicy || result.policy) && getProcessedCode(result.code)}
				{processHtml(result.titleHtml, { highlightStrong: true })}
			</div>
		);
	};
	const getExternalLinkIcon = () => {
		return <Icon name="external-link" size="18px" color={infoColor} />;
	};

	const openPage = (e, pageLink, isExternalLink, isSearchResult = false, newTab = false) => {
		e.preventDefault();
		if ((!isExternalLink && isSearchResult && !result?.folder) || newTab) {
			window.open(`${"/home"}${pageLink}`, "_blank");
		} else if (!isExternalLink) {
			navigate(pageLink);
		} else {
			window.location.href = pageLink;
		}
	};

	let path = result.path ? result.path : [];
	const getAppendedValue = (code, title) => {
		if (code !== null && code !== "") {
			let finalTitle = title;
			return code + " " + finalTitle;
		}
		return title;
	};

	const handleSelectForPolicy = () => {
		result.selected = !result.selected;
		setSelected(result.selected);
		setSelectedData(result);
	};

	const handleSelect = policyAddToAgendaSearch
		? handleSelectForPolicy
		: enableSelect && (typeof enableSelect?.allow !== "function" || enableSelect.allow(result))
			? () => {
					result.selected = !result.selected;
					setSelected((prev) => {
						if (typeof enableSelect?.handle === "function") {
							enableSelect.handle(result, !prev);
						}

						return !prev;
					});
					setSelectedData(result);
				}
			: undefined;

	useEffect(() => {
		setSelected(selection && selection === result?.guid);
	}, [selection]);

	return (
		result && (
			<>
				<div
					className={clsx(classes.documentIconContainer, {
						[classes.expandActive]: handleExpand,
						[classes.canSelect]: handleSelect,
					})}
					role={handleExpand ? "button" : undefined}
					tabIndex={handleExpand ? 0 : undefined}
					onClick={handleExpand ? () => handleExpand(result) : handleSelect}
				>
					<Tooltip title={tooltip}>
						<span role="tooltip">
							<Icon name={name} size={child ? "24" : "28"} />
							{handleExpand && <Icon name={result.expanded ? "expand-down" : "expand-right"} size="12" />}
						</span>
					</Tooltip>
				</div>
				<div
					className={clsx(classes.documentTextContainer, {
						[classes.canSelect]: handleSelect,
					})}
					onClick={handleSelect}
				>
					<div className={classes.linkRow}>
						<div className={classes.searchResult}>
							{textWithIcon && getProcessedText()}
							<a
								className={classes.link}
								href={`${!externalLink ? "/home" : ""}${link}`}
								onClick={(e) => openPage(e, link, externalLink, true)}
							>
								{textWithIcon ? getExternalLinkIcon() : getProcessedText()}
							</a>
						</div>
					</div>
					{result.sampleHtml && (
						<div className={classes.documentSampleHtml}>
							<Typography variant="body2">{processHtml(result.sampleHtml, { highlightStrong: true })}</Typography>
						</div>
					)}
					{path && path.length > 0 && (
						<div className={classes.breadcrumb}>
							{path.map((pathItem, index) => {
								const pathLink = generatePathLink(pathItem, index);

								return (
									<React.Fragment key={pathItem.guid}>
										<a
											href={`/home${pathLink}`}
											onClick={(e) => openPage(e, pathLink, undefined, undefined, policyAddToAgendaSearch)}
											target="_blank"
										>
											{isPolicy || result.policy ? getAppendedValue(pathItem.code, pathItem.title) : pathItem.title}
										</a>
										{index < path.length - 1 ? <span className={classes.breadcrumbSeparator}>{">"}</span> : ""}
									</React.Fragment>
								);
							})}
						</div>
					)}
					{result.expanded ? (
						typeof result.attachments !== "undefined" ? (
							result.attachments.length > 0 ? (
								<div className={classes.attachments}>
									{result.attachments.map((attachment) => (
										<div key={attachment.guid} className={classes.attachment}>
											<DocumentResult result={attachment} child />
										</div>
									))}
								</div>
							) : (
								<div className={classes.noData}>
									<Box>{t("search.noAttachments.title")}</Box>
								</div>
							)
						) : (
							<div className={classes.loading}>
								<CircularProgressIndicator minHeight="0px" size={24} padding="8px" />
							</div>
						)
					) : null}
				</div>
				{selected && <div className={classes.selected} onClick={handleSelect}></div>}
			</>
		)
	);
};

export default React.memo(SearchResult);
