import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import request from "superagent";
import { API_HOST } from "config/env";
import telemetryAddEvent from "utils/telemetryAddEvent";

import makeStyles from "@mui/styles/makeStyles";

import { Checkbox, FormControlLabel, Box, List, Typography } from "@mui/material";

import SmsListCard from "./SmsListCard";

import { Check } from "components/Icons";
import Icon from "atlas/components/Icon/Icon";
import GenericDialog from "atlas/components/Dialogs/GenericDialog";
import CircularProgressIndicator from "atlas/components/Progress/CircularProgressIndicator";
import typographyStyles from "atlas/assets/jss/components/typographyStyle";
import clsx from "clsx";
import notifierMessage from "utils/notifierMessage";
import { setSnackbarOptions } from "redux/snackBar/actions";

const useTypographyStyles = makeStyles(typographyStyles);
const useStyles = makeStyles(() => ({
	dialog: {
		"& .MuiDialog-paper": {
			width: "440px",
			maxWidth: "100%",
		},
	},
	title: {
		fontWeight: "bold",
	},
	titleResults: {
		fontWeight: "bold",
		marginBottom: "16px",
	},
	saveNumberLabel: {
		fontWeight: "bold",
		paddingLeft: "2px",
	},
	label: {
		fontWeight: "bold",
	},
}));

const SendReminderDialog = (props) => {
	const { show, item, onClose, userAdmin } = props;
	const dispatch = useDispatch();
	const { t } = useTranslation("agendaItems");
	const [sending, setSending] = useState(false);
	const [users, setUsers] = useState([]);
	const [loading, setLoading] = useState(false);
	const [saveNumbers, setSaveNumbers] = useState(false);
	const [showSaveNumbers, setShowSaveNumbers] = useState(false);
	const [showResults, setShowResults] = useState(false);
	const [results, setResults] = useState(null);
	const classes = useStyles();
	const typographyClasses = useTypographyStyles();

	const handleUpdate = () => {
		setSaveNumbers(!saveNumbers);
	};

	const handleSendReminder = (item, users) => {
		const data = {
			agendaItemId: item.guid,
			users: [],
		};
		users.forEach((user) => {
			if (user.sendMessage && user.phoneNumber !== "" && (!user.smsResult || !user.smsResult.validPhoneNumber)) {
				data.users.push({
					id: user.id,
					phoneNumber: user.phoneNumber,
					formattedPhoneNumber: user.formattedPhoneNumber,
					save: user.save,
				});
			}
		});
		if (data.users.length > 0) {
			request
				.post(`${API_HOST}/api/workflow/${item.workflow.id}/smsreminder`)
				.withCredentials()
				.send(data)
				.then((res) => {
					if (res.status === 200) {
						const rslt = [...users];
						rslt.forEach((user) => {
							const smsResult = res.body.sms.users.find((resp) => resp.id == user.id);
							if (smsResult) {
								user.smsResult = smsResult;
								if (smsResult.validPhoneNumber) {
									user.sendMessage = false;
								}
							}
						});
						setUsers(rslt);
						const item = rslt.find((user) => {
							if (!user.smsResult || !user.smsResult.validPhoneNumber) {
								return true;
							} else {
								return false;
							}
						});
						setShowResults(item ? false : true);
					}
					setSending(false);
					telemetryAddEvent("Agenda Item - Send Urgent SMS");
				})
				.catch((err) => {
					let option = notifierMessage(t("sendReminderDialog.snackbar.failure"), "error");
					dispatch(setSnackbarOptions(option));
					onClose();
				});
		} else {
			setSending(false);
		}
	};

	const handleSend = () => {
		setSending(true);
		const newUsers = [...users];
		newUsers.forEach((user) => {
			if (user.updated) {
				user.save = saveNumbers;
			}
		});
		handleSendReminder(item, newUsers);
	};

	const updateSendMessage = (id, sendMessage) => {
		const newUsers = [...users];
		const newUser = newUsers.find((user) => user.id === id);
		if (newUser) {
			newUser.sendMessage = sendMessage;
			setUsers(newUsers);
		}
	};

	const updateUser = (id, phoneNumber, formattedPhoneNumber) => {
		const newUsers = [...users];
		const newUser = newUsers.find((user) => user.id === id);
		if (newUser) {
			newUser.phoneNumber = phoneNumber;
			newUser.formattedPhoneNumber = formattedPhoneNumber;
			newUser.updated = true;
			setUsers(newUsers);
		}
	};

	useEffect(() => {
		setLoading(true);
		setSaveNumbers(false);
		setShowResults(false);
		setShowSaveNumbers(false);
		const newUsers = [];
		if (item && item.workflow) {
			const block = item.workflow.blocks.find((block) => block.isActive);
			if (block) {
				block.users.forEach((user) => {
					if (user.status === 1) {
						const readonly = user.phoneNumber.length > 0 ? true : false;
						const newUser = {
							id: user.id,
							firstName: user.firstName,
							lastName: user.lastName,
							profileImageUrl: user.profileImageUrl,
							phoneNumber: user.phoneNumber,
							ophoneNumber: user.phoneNumber,
							formattedPhoneNumber: user.formattedPhoneNumber,
							readonly: readonly,
							updated: false,
							save: false,
							sendMessage: user.phoneNumber.length > 0 ? true : false,
						};
						newUsers.push(newUser);
						if (!readonly) {
							setShowSaveNumbers(true);
						}
					}
				});
			}
		}
		setUsers(newUsers);
		setLoading(false);
	}, [item]);
	return (
		<GenericDialog
			className={classes.dialog}
			show={show}
			title={t("sendReminderDialog.title")}
			primaryAction={showResults ? onClose : handleSend}
			primaryTitle={
				showResults ? (
					"Close"
				) : sending ? (
					<CircularProgressIndicator size={20} minHeight="20px" />
				) : (
					t("sendReminderDialog.buttons.sendReminder")
				)
			}
			primaryDisabled={sending || users.length === 0}
			secondaryAction={onClose}
			secondaryTitle={showResults ? null : t("sendReminderDialog.buttons.cancel")}
			secondaryDisabled={sending}
			clickAwayDisabled={sending}
			closeIcon={<Icon name="close" />}
			data-cy="agenda-item-send-reminder-dialog"
		>
			{users && users.length > 0 && !loading ? (
				<>
					<Typography variant="h3" className={clsx(typographyClasses.fieldLabelLarge, classes.title)}>
						{t("sendReminderDialog.instructions")}
					</Typography>
					<List>
						{users.map((user) => {
							return <SmsListCard key={`sms-user-${user.id}`} user={user} updateUser={updateUser} updateSendMessage={updateSendMessage} />;
						})}
					</List>
					{userAdmin && showSaveNumbers && (
						<div>
							<Typography variant="h5" className={clsx(typographyClasses.fieldLabelLarge, classes.saveNumberLabel)}>
								<FormControlLabel
									classes={{ label: classes.label }}
									control={
										<Checkbox
											checkedIcon={<Check fontSize="small" color="primary" />}
											checked={saveNumbers}
											onChange={(e) => handleUpdate(e, "active", true)}
											disabled={false}
										/>
									}
									label={t("sendReminderDialog.saveNumber")}
									data-cy="update-profile"
								/>
							</Typography>
						</div>
					)}
				</>
			) : (
				<div style={{ margin: "24px", textAlign: "center" }}>
					{loading ? (
						<Box mt={4}>
							<CircularProgressIndicator data-cy="loadingUsers" />
						</Box>
					) : (
						<Box>{t("sendReminderDialog.noUsers")}</Box>
					)}
				</div>
			)}
		</GenericDialog>
	);
};

export default SendReminderDialog;
