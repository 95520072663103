import React from "react";
import { useTranslation } from "react-i18next";

import makeStyles from "@mui/styles/makeStyles";

import Icon from "atlas/components/Icon/Icon";
import GenericDialog from "atlas/components/Dialogs/GenericDialog";
import ProgressBar from "atlas/components/Progress/ProgressBar";

const useStyles = makeStyles(() => ({
	dialog: {
		"& .MuiDialog-paper": {
			minWidth: "480px",
		},
	},
	progressBar: {
		width: "300px",
		margin: "0 auto",
		marginTop: "24px",
	},
}));

const DataExportProgress = (props) => {
	const { show, progress, handleClose } = props;
	const { t } = useTranslation("settings");
	const classes = useStyles();
	const progressId = "export-progress";

	const i18n = t("dataExport.exportProgressDialog");

	const dialog = {
		title: i18n.title,
	};

	return (
		<GenericDialog
			className={classes.dialog}
			show={show}
			title={dialog.title}
			clickAwayDisabled={true}
			secondaryTitle={progress.percent >= 100 ? t("app:buttons.close") : undefined}
			secondaryAction={handleClose}
			closeIcon={<Icon name="close" />}
		>
			<ProgressBar
				id={progressId}
				className={classes.progressBar}
				label={progress.label}
				progress={progress.percent}
				maxLabelWidthPercent={100}
			/>
		</GenericDialog>
	);
};

export default DataExportProgress;
