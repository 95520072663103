import React from "react";
import SvgIcon from "@mui/material/SvgIcon";

import themeConfig from "../../atlas/assets/jss/theme";
import getOr from "lodash/fp/getOr";

const icon = (props) => {
	const { color } = props;
	const fillColor = color ? getOr(color, ["palette", color, "main"], themeConfig) : "currentColor";

	return (
		<SvgIcon {...props}>
			<defs>
				<path
					d="M1.875,4.75 C1.39175084,4.75 1,4.35824916 1,3.875 C1,3.39175084 1.39175084,3 1.875,3 L21.875,3 C22.3582492,3 22.75,3.39175084 22.75,3.875 C22.75,4.35824916 22.3582492,4.75 21.875,4.75 L1.875,4.75 Z M1.875,10.7241379 L21.875,10.7241379 C23.0416667,10.7241379 23.0416667,12.4741379 21.875,12.4741379 L1.875,12.4741379 C0.708333333,12.4741379 0.708333333,10.7241379 1.875,10.7241379 Z M1.875,18.4482759 L21.875,18.4482759 C23.0416667,18.4482759 23.0416667,20.1982759 21.875,20.1982759 L1.875,20.1982759 C0.708333333,20.1982759 0.708333333,18.4482759 1.875,18.4482759 Z"
					id="menu-path-1"
				/>
			</defs>
			<g id="menu" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g id="Icons/24px/Unselected/Menu">
					<mask id="menu-mask-2" fill="white">
						<use xlinkHref="#menu-path-1" />
					</mask>
					<use fill="#FFFFFF" fillRule="nonzero" xlinkHref="#menu-path-1" />
					<g mask="url(#menu-mask-2)" fill={fillColor} fillRule="evenodd">
						<rect x="0" y="0" width="24" height="24" />
					</g>
				</g>
			</g>
		</SvgIcon>
	);
};

export default icon;
