import React, { useEffect, useState, useRef, useCallback, useMemo, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { DndContext, DragOverlay, KeyboardSensor, PointerSensor, useSensor, useSensors, rectIntersection } from "@dnd-kit/core";
import { createPortal } from "react-dom";
import { debounce } from "lodash";
import clsx from "clsx";
import { v4 as uuid } from "uuid";

import makeStyles from "@mui/styles/makeStyles";
import { Container, List } from "@mui/material";

import DragPresentation from "atlas/components/DragAndDrop/DragPresentation";
import Droppable from "atlas/components/DragAndDrop/Droppable";
import DropPlaceholder from "atlas/components/DragAndDrop/DropPlaceholder";
import Spinner from "atlas/components/Spinner/Spinner";
import { useWidthUp, useWidthDown } from "atlas/utils/useWidth";
import SingletonEditor from "components/Editor/SingletonEditor";
import HeaderFooter, { ADD_HEADING_BELOW } from "components/Meeting/HeaderFooter";
import MeetingItem from "components/Meeting/MeetingItem";
import { SettingsContext } from "contexts/Settings/SettingsContext";
import {
	setMeetingTemplateAgendaItems,
	addMeetingItem,
	deleteMeetingItem,
	restoreMeetingItem,
	reorderMeetingTemplateAgendaItems,
	persistMeetingTemplateAgendaItems,
	setActive,
} from "redux/meetingTemplate/actions";
import { setSnackbarOptions } from "redux/snackBar/actions";
import { ITEM_TYPES, HEADINGS } from "utils/enums/ItemTypes";
import { getCollisionDetection } from "utils/dragAndDrop";
import { useSignInDialog } from "utils/isSignedIn";
import { isHeading } from "utils/meetingElement";
import notifierMessage from "utils/notifierMessage";
import { scrollToElement2 as scrollToElement } from "utils/scrollToElement";
import telemetryAddEvent from "utils/telemetryAddEvent";

const useEditorStyles = makeStyles((theme) => ({
	editorFieldHide: {
		display: "none",
	},
	loadingIndicator: {
		height: "49.4px",
		width: "100%",
		textAlign: "center",
		display: "flex",
		alignItems: "center",
		position: "absolute",
		left: "0",
		top: "0",
		boxSizing: "border-box",
	},
	editorContentSmUp: {
		minWidth: "450px",
		boxSizing: "border-box",
		overflow: "hidden",
		"-webkit-transform": "translate3d(0, 0, 0)",
		width: (props) => (props.showOutline ? "calc(100% - 265px)" : "100%"),
		marginLeft: (props) => (props.showOutline ? "265px" : "0"),
	},
	contentContainer: {
		maxWidth: "7.5in !important",
		paddingTop: "8px !important",
		paddingBottom: "8px !important",
		margin: "0 auto",
		[theme.breakpoints.down("sm")]: {
			marginRight: "48px",
		},
	},
}));

const saveDelay = 2000;
const tocFooterDropId = "toc-footer";

const AgendaEditor = (props) => {
	const { editorToolbarRef, telemetryPage } = props;
	const widthUpSm = useWidthUp("sm");
	const widthDownMd = useWidthDown("md");
	const { t } = useTranslation("meetings");
	const sensors = useSensors(useSensor(PointerSensor), useSensor(KeyboardSensor));
	const [editorFields, _setEditorFields] = useState([]);
	const [editorInitializing, setEditorInitializing] = useState(true);
	const [elementRefsSet, setElementRefsSet] = useState(null);
	const [triggerEditorChange, setTriggerEditorChange] = useState(false);
	const [deletedFieldIdx, setDeletedFieldIdx] = useState(-1);
	const [menuAnchor, setMenuAnchor] = useState({});
	const [draggedId, setDraggedId] = useState(null);
	const [droppedId, setDroppedId] = useState(null);
	const editorFieldsRef = useRef(editorFields);
	const elementsRef = useRef([]);
	const elementsRefStatus = useRef({ rerender: true, status: [] }); // Used to determine if all expected element refs have been set
	const dragRef = useRef(null);
	const dragDimensionsRef = useRef({});
	const { dateFormat } = useContext(SettingsContext);
	const dispatch = useDispatch();
	const showSignIn = useSignInDialog();
	const { agenda: { items, header, footer, saving, updated, customNumbering } = {}, active: { id: active, field: activeField } = {} } =
		useSelector((state) => state.meetingTemplatesReducer);
	const classes = useEditorStyles({ showOutline: !widthDownMd });

	const afterElementRefSet = useCallback((index) => {
		if (elementsRefStatus.current.rerender) {
			elementsRefStatus.current.status[index] = true;

			if (elementsRefStatus.current.status.filter((value) => !value).length === 0) {
				elementsRefStatus.current.rerender = false;
				setElementRefsSet({}); //Set to a new object reference just to trigger a re-render
			}
		}
	}, []);

	const setEditorFields = (data) => {
		editorFieldsRef.current = data;
		_setEditorFields(data);
	};

	const handleEditorInitialized = (editor) => {
		setEditorInitializing(false);
	};

	const handleFieldChange = (editor) => {
		const fieldData = editor.getFieldData();

		dispatch(setMeetingTemplateAgendaItems(fieldData, editorFieldsRef.current));
		editor.ui.update();

		telemetryAddEvent(`${telemetryPage} - Agenda Add Standing Content`);
	};

	const focusOnEditor = (fieldName) => {
		dispatch(setActive(fieldName, true));
	};

	// TODO Find the reason why this gets triggered twice when dispatching - Debounce is only there to prevent 2nd call. Temporary band-aid...
	const handleFocusChange = debounce(
		useCallback((fieldName, _fieldElement, editor) => {
			if (fieldName && editor?.ui?.focusTracker?.isFocused) {
				dispatch(setActive(fieldName));
			}
		}, []),
		100,
	);

	const updateEditorFields = (itemGuid, isDeleting = false) => {
		const newEditorFields = [...editorFieldsRef.current];
		const editorFieldText = newEditorFields.find((field) => field.name === `${itemGuid}-text`);
		if (editorFieldText) {
			editorFieldText.deleted = isDeleting;
			setEditorFields(newEditorFields);
			setTriggerEditorChange((triggerEditorChange) => !triggerEditorChange);
		}
	};

	const addItemText = useCallback((itemGuid) => {
		updateEditorFields(itemGuid);
		if (itemGuid) {
			dispatch(setActive(itemGuid.indexOf("-text") < 0 ? `${itemGuid}-text` : itemGuid, true));
		}
	}, []);

	const removeItemText = useCallback((itemGuid) => {
		const idx = editorFieldsRef.current.findIndex((f) => f.name === `${itemGuid}-text`);
		setDeletedFieldIdx(idx);
		updateEditorFields(itemGuid, true);
	}, []);

	const handleDeletedFieldIndex = () => {
		setDeletedFieldIdx(-1);
	};

	const handleUndoRedoRoot = (itemGuid, isUndo, isDeletion) => {
		const idx = editorFieldsRef.current.findIndex((f) => f.name === itemGuid);
		if (idx < 0) return;
		const newFields = [...editorFieldsRef.current];
		newFields[idx].deleted = isUndo ? !isDeletion : isDeletion;
		setEditorFields(newFields);
		setTriggerEditorChange((triggerEditorChange) => !triggerEditorChange);
	};

	const setContentAreaOverflow = (value) => {
		let contentArea = document.getElementById("content-area");
		while (contentArea) {
			contentArea.style.overflow = value;
			const element = contentArea;
			setTimeout(() => {
				// Ensure that the scroll position goes back to the top
				element.scrollTop = 0;
			}, 10);
			contentArea = contentArea.parentElement;
		}
	};

	const handleDragStart = (e) => {
		const { active } = e;

		setDraggedId(active.id);

		// Get the dimensions of the active element
		const element = document.getElementById(`meeting-item-${active.id}`);
		if (element) {
			const rect = element.getBoundingClientRect();
			dragDimensionsRef.current = { width: rect.width, height: rect.height };
		}

		// Prevent unwanted text-selection and scrolling
		document.body.style.userSelect = "none";
		setContentAreaOverflow("hidden");
	};

	const handleDragMove = useCallback(
		(e) => {
			const { active, over } = e;

			const activeIndex = items.findIndex((item) => item.guid === active.id);
			const overId = (over?.id || "").replace("-placeholder", "");
			const overIndex = items.findIndex((item) => item.guid === overId);
			if (overId === tocFooterDropId) {
				setDroppedId(overId);
			} else if (activeIndex >= 0 && overIndex >= 0 && overId !== active.id) {
				const moveData = getAvailablePosition(
					activeIndex,
					items[activeIndex].itemType,
					items[activeIndex].fields.Indent.Value > 0,
					overIndex,
					-1,
				);
				setDroppedId(moveData.nextPosition != null ? items[moveData.nextPosition].guid : null);
			} else {
				setDroppedId(null);
			}
		},
		[items],
	);

	const endDrag = () => {
		setDraggedId(null);
		setDroppedId(null);

		document.body.style.userSelect = null;
		setContentAreaOverflow(null);
	};

	const handleDragEnd = useCallback(
		(e) => {
			const { active, over } = e;

			const activeItem = items.find((item) => item.guid === active.id);
			const activeIndex = items.findIndex((item) => item.guid === active.id);
			const overId = (over?.id || "").replace("-placeholder", "");
			const overIndex = items.findIndex((item) => item.guid === overId);
			moveItem(
				activeItem,
				undefined,
				getAvailablePosition(
					activeIndex,
					items[activeIndex].itemType,
					items[activeIndex].fields.Indent.Value > 0,
					overId === tocFooterDropId ? items.length - 1 : overIndex,
					-1,
				),
			);

			endDrag();
		},
		[items],
	);

	const handleDragCancel = (e) => {
		endDrag();
	};

	const getDragComponent = useCallback(() => {
		let component = null;
		if (draggedId) {
			const item = items.find((item) => item.guid === draggedId);
			if (item) {
				const isHeading = item.itemType === ITEM_TYPES.MEETING_TEMPLATE_AGENDA_HEADING;
				const isSubHeading = Boolean(isHeading && item.attributes.relationshipGuid);
				const isItem = item.itemType === ITEM_TYPES.MEETING_TEMPLATE_AGENDA_ITEM;

				component = (
					<MeetingItem
						readOnly
						item={item}
						isHeading={isHeading}
						isSubHeading={isSubHeading}
						isItem={isItem}
						isRecommendation={item.itemType === ITEM_TYPES.RECOMMENDATION}
						addBottomBorder
						dragPresentational
						customNumbering={customNumbering}
					/>
				);
			}
		}

		return component;
	}, [items, draggedId]);

	const handleMenu = useCallback((e, guid, options) => {
		setMenuAnchor(e ? { ...options, anchor: e.currentTarget, guid } : {});
	}, []);

	const initializeFields = (items) => {
		const newFields = [];

		// Header
		newFields.push({
			guid: "toc-header",
			name: "toc-header",
			content: header,
			toolbar: "minutesHeader",
			deleted: false,
		});
		items.forEach((item) => {
			if (!item.deleted) {
				newFields.push({
					guid: item.guid,
					name: item.guid,
					content: item.fields.Name.Value,
					toolbar: "simpleNoAttachments",
					isMemberOnly: item.fields.Closed.Value,
					deleted: false,
				});

				newFields.push({
					guid: item.guid,
					name: `${item.guid}-text`,
					content: item.fields.Text.Value || "",
					toolbar: "itemTextNoAttachments",
					isMemberOnly: item.fields.Closed.Value,
					deleted: item.fields.Text.Value === null,
				});
			}
		});

		// Footer
		newFields.push({
			guid: "toc-footer",
			name: "toc-footer",
			content: footer,
			toolbar: "header",
			deleted: false,
		});
		setEditorFields(newFields);
	};

	const getParentData = useCallback(
		(item, itemType, subHeading, fields) => {
			// Get the item that the new one will be inserted after
			let previousItem = null;
			if (itemType === ITEM_TYPES.MEETING_TEMPLATE_AGENDA_HEADING && !subHeading) {
				// A new heading goes after the previous heading
				if (item) {
					for (let index = 0; index < items.length; index++) {
						const currentItem = items[index];
						if (
							(!previousItem && currentItem.guid === item.guid) ||
							(previousItem && (currentItem.itemType !== ITEM_TYPES.MEETING_TEMPLATE_AGENDA_HEADING || currentItem.fields.Indent.Value > 0))
						) {
							previousItem = currentItem;
						} else if (previousItem) {
							break;
						}
					}
				}
			} else if (itemType === ITEM_TYPES.MEETING_TEMPLATE_AGENDA_HEADING && subHeading) {
				// A new sub-heading the previous heading/item and any child items
				for (let index = 0; index < items.length; index++) {
					const currentItem = items[index];
					if (
						(!previousItem && currentItem.guid === item.guid) ||
						(previousItem &&
							(currentItem.itemType === ITEM_TYPES.RECOMMENDATION || currentItem.itemType === ITEM_TYPES.MEETING_TEMPLATE_AGENDA_ITEM))
					) {
						previousItem = currentItem;
					} else if (previousItem) {
						break;
					}
				}
			} else {
				// A new sub-heading/item/recommendation goes after the previous heading/item and any recommendations
				for (let index = 0; index < items.length; index++) {
					const currentItem = items[index];
					if ((!previousItem && currentItem.guid === item.guid) || (previousItem && currentItem.itemType === ITEM_TYPES.RECOMMENDATION)) {
						previousItem = currentItem;
					} else if (previousItem) {
						break;
					}
				}
			}

			// Get the fields insertion index
			let fieldsInsertIndex = 1; // The insert location is index + 2, so this inserts after the header
			if (previousItem) {
				fieldsInsertIndex =
					fields.findIndex((field) => {
						return field.name === previousItem.guid;
					}) + 2;
			}

			// Get the items insertion index
			let itemsInsertIndex = 0; // Insert at the start of the list
			if (previousItem) {
				// Insert after the previous item
				itemsInsertIndex =
					items.findIndex((item) => {
						return item.guid === previousItem.guid;
					}) + 1;
			}

			// Get the parent
			let parentItem = null;
			if (itemsInsertIndex > 0 && (itemType !== ITEM_TYPES.MEETING_TEMPLATE_AGENDA_HEADING || subHeading)) {
				for (let index = itemsInsertIndex - 1; index >= 0; index--) {
					const currentItem = items[index];
					if (
						(itemType === ITEM_TYPES.MEETING_TEMPLATE_AGENDA_HEADING &&
							subHeading &&
							currentItem.itemType === ITEM_TYPES.MEETING_TEMPLATE_AGENDA_HEADING &&
							currentItem.fields.Indent.Value === 0) ||
						(itemType === ITEM_TYPES.MEETING_TEMPLATE_AGENDA_ITEM && currentItem.itemType === ITEM_TYPES.MEETING_TEMPLATE_AGENDA_HEADING) ||
						(itemType === ITEM_TYPES.RECOMMENDATION && currentItem.itemType !== ITEM_TYPES.RECOMMENDATION)
					) {
						// Set parent - If the parent has been found we can stop searching for both
						parentItem = currentItem;
						break;
					}
				}
			}

			return { fieldsInsertIndex, itemsInsertIndex, parentItem };
		},
		[items],
	);

	const createNewItem = (item, itemType, subHeading = false, duplicate = false, consentAction = false) => {
		const newFields = [...editorFieldsRef.current];
		const { fieldsInsertIndex, itemsInsertIndex, parentItem } = getParentData(item, itemType, subHeading, newFields);
		const newItemGuid = uuid();
		let consentActionItem = null;
		if (consentAction) {
			consentActionItem = {
				guid: newItemGuid,
				itemType: 7,
				fields: {
					Name: {
						Value: "<p>Consent Action</p>",
					},
				},
				deleted: false,
			};
		}
		newFields.splice(
			fieldsInsertIndex,
			0,
			{
				guid: newItemGuid,
				name: newItemGuid,
				content: duplicate ? item.fields?.Name?.Value || "" : consentAction ? "<p>Consent Action</p>" : "",
				toolbar: "simpleNoAttachments",
				isMemberOnly: Boolean(parentItem?.fields?.Closed?.Value),
				deleted: false,
			},
			{
				guid: newItemGuid,
				name: `${newItemGuid}-text`,
				content: duplicate ? item.fields?.Text?.Value || "" : "",
				toolbar: "itemTextNoAttachments",
				isMemberOnly: Boolean(parentItem?.fields?.Closed?.Value),
				deleted: true,
			},
		);
		elementsRef.current.splice(fieldsInsertIndex, 0, null, null);
		elementsRefStatus.current.rerender = true;

		setEditorFields(newFields);
		dispatch(
			addMeetingItem(
				duplicate ? item : consentAction ? consentActionItem : undefined,
				itemType,
				subHeading,
				newItemGuid,
				itemsInsertIndex,
				parentItem,
			),
		);
		focusOnEditor(newItemGuid);
		handleMenu();

		telemetryAddEvent(`${telemetryPage} - Agenda Add Standing Content`);
	};

	const isValidPosition = useCallback(
		(index, itemType, subHeading, currentIndex, numberOfChildren) => {
			if (index < 0 || index >= items.length || (index > currentIndex && index <= currentIndex + numberOfChildren)) {
				return false; // Outside of the list or within it's own children
			}

			let valid = false;
			const item = items[index];
			switch (itemType) {
				case ITEM_TYPES.MEETING_TEMPLATE_AGENDA_HEADING:
					if (!subHeading) {
						// Top-level headings
						if (
							index === items.length - 1 ||
							(item.itemType === ITEM_TYPES.MEETING_TEMPLATE_AGENDA_HEADING && item.fields.Indent.Value === 0)
						) {
							// The bottom or the same position as an existing heading are valid
							valid = true;
						}
					} else {
						// Sub-headings
						if (
							index > 0 &&
							(index === items.length - 1 ||
								item.itemType === ITEM_TYPES.MEETING_TEMPLATE_AGENDA_HEADING ||
								(item.itemType === ITEM_TYPES.MEETING_TEMPLATE_AGENDA_ITEM && isHeading(items, item.attributes?.relationshipGuid)))
						) {
							// The bottom or the same position as an existing heading/sub-heading/top-level and not the top item are valid
							valid = true;
						}
					}
					break;

				case ITEM_TYPES.MEETING_TEMPLATE_AGENDA_ITEM:
					// Items
					if (
						index > 0 &&
						(index === items.length - 1 ||
							item.itemType === ITEM_TYPES.MEETING_TEMPLATE_AGENDA_HEADING ||
							item.itemType === ITEM_TYPES.MEETING_TEMPLATE_AGENDA_ITEM)
					) {
						// The bottom or the same position as an existing heading/sub-heading/item and not the top are valid
						valid = true;
					}
					break;

				case ITEM_TYPES.RECOMMENDATION:
					// Items
					if (index > 0) {
						// Any non-top position is valid
						valid = true;
					}
					break;
			}

			return valid;
		},
		[items],
	);

	const getNumberOfChildren = useCallback(
		(startIndex, itemType, subHeading, deleteItem = false) => {
			let count = 0;
			for (let index = startIndex + 1; index < items.length; index++) {
				const item = items[index];
				switch (itemType) {
					case ITEM_TYPES.MEETING_TEMPLATE_AGENDA_HEADING:
						if (!subHeading) {
							// Top-level headings
							if (item.itemType !== ITEM_TYPES.MEETING_TEMPLATE_AGENDA_HEADING || item.fields.Indent.Value > 0) {
								count++;
							} else {
								return count;
							}
						} else {
							// Sub-headings
							if (item.itemType !== ITEM_TYPES.MEETING_TEMPLATE_AGENDA_HEADING) {
								if (deleteItem) {
									const childItems = items.filter((item) => {
										return item.attributes.relationshipGuid == items[startIndex].guid;
									});
									count = childItems.length;
								} else {
									count++;
								}
							} else {
								return count;
							}
						}
						break;

					case ITEM_TYPES.MEETING_TEMPLATE_AGENDA_ITEM:
						// Items
						if (item.itemType === ITEM_TYPES.RECOMMENDATION) {
							count++;
						} else {
							return count;
						}
						break;
				}
			}

			return count;
		},
		[items],
	);

	const getAvailablePosition = useCallback(
		(itemIndex, itemType, subHeading, startIndex, step) => {
			const numberOfChildren = getNumberOfChildren(itemIndex, itemType, subHeading);
			let nextPosition = null;
			for (let index = startIndex; index >= 0 && index < items.length; index += step) {
				if (isValidPosition(index, itemType, subHeading, itemIndex, numberOfChildren)) {
					nextPosition = index;
					break;
				}
			}

			return {
				currentIndex: itemIndex,
				nextPosition,
				numberToMove: nextPosition !== null ? numberOfChildren + 1 : 0,
				nextPositionBlockSize:
					nextPosition !== null
						? getNumberOfChildren(nextPosition, items[nextPosition].itemType, items[nextPosition].fields.Indent.Value > 0) + 1
						: 0,
			};
		},
		[items],
	);

	const getNextAvailablePosition = useCallback(
		(itemToMove, moveUp = true) => {
			const currentIndex = items.findIndex((item) => item.guid === itemToMove.guid);
			const step = moveUp ? -1 : 1;

			return getAvailablePosition(currentIndex, itemToMove.itemType, itemToMove.fields.Indent.Value > 0, currentIndex + step, step);
		},
		[items],
	);

	const duplicateItem = (item) => createNewItem(item, item.itemType, item.fields.Indent.Value === 1, true);

	const moveItem = useCallback(
		(item, moveUp = true, data) => {
			const moveData = data || getNextAvailablePosition(item, moveUp);

			// Check if the move is valid
			if (moveData.nextPosition != null) {
				// Move items and children
				let reorderedItems = [...items];
				const movedItems = reorderedItems.splice(moveData.currentIndex, moveData.numberToMove);
				reorderedItems.splice(
					moveData.nextPosition < moveData.currentIndex
						? moveData.nextPosition
						: moveData.nextPosition - moveData.numberToMove + moveData.nextPositionBlockSize,
					0,
					...movedItems,
				);

				initializeFields(reorderedItems);

				dispatch(reorderMeetingTemplateAgendaItems(reorderedItems));
			}

			handleMenu();
		},
		[items],
	);

	const deleteItem = useCallback(
		(item) => {
			const newFields = [...editorFieldsRef.current];

			const itemIndex = items.findIndex((current) => current.guid === item.guid);
			const numberOfChildren = getNumberOfChildren(
				itemIndex,
				item.itemType,
				HEADINGS.includes(item.itemType) && item.fields.Indent.Value > 0,
				true,
			);

			dispatch(deleteMeetingItem(item.guid));

			let itemTypeString;
			switch (item.itemType) {
				case ITEM_TYPES.AGENDA_HEADING:
					itemTypeString = item.fields?.Indent?.Value > 0 ? "deleteSubHeading" : "deleteSection";
					break;

				case ITEM_TYPES.RECOMMENDATION:
					itemTypeString = "deleteRecommendation";
					break;

				default:
					itemTypeString = "deleteItem";
					break;
			}

			let option = notifierMessage(
				t(`snackbar.success.${itemTypeString}`, { count: numberOfChildren }),
				"success",
				() => restoreItem(item),
				10000,
			);
			dispatch(setSnackbarOptions(option));

			handleMenu();
		},
		[items],
	);

	const restoreItem = useCallback(
		(deletedItem) => {
			if (deletedItem) {
				const newFields = [...editorFieldsRef.current];

				setEditorFields(newFields);

				dispatch(restoreMeetingItem(deletedItem.guid));
			}
		},
		[items],
	);

	const toggleBooleanField = (item, field) => {
		dispatch(
			setMeetingTemplateAgendaItems(
				[{ nonEditor: true, fieldName: item.guid, name: field, fieldData: !Boolean(item?.fields?.[field]?.Value) }],
				editorFieldsRef.current,
			),
		);
		if (field === "Consent" && item.fields.Consent.Value) {
			const itemIndex = items.findIndex((current) => current.guid === item.guid);
			let hasRecommendation = false;
			for (let i = itemIndex + 1; i < items.length; i += 1) {
				const currentItem = items[i];
				if (currentItem.itemType === ITEM_TYPES.HEADING) break;
				if (currentItem.itemType === ITEM_TYPES.RECOMMENDATION && currentItem.attributes.relationshipGuid === item.guid)
					hasRecommendation = true;
			}
			if (!hasRecommendation) {
				createNewItem(item, ITEM_TYPES.RECOMMENDATION, false, false, true);
			}
		}
		handleMenu();
	};

	const toggleMembersOnly = (item) => toggleBooleanField(item, "Closed");

	const toggleConsent = (item) => toggleBooleanField(item, "Consent");

	const togglePublicComment = (item) => toggleBooleanField(item, "PublicComment");

	const updateTextField = useCallback((item, field, value) => {
		dispatch(
			setMeetingTemplateAgendaItems([{ nonEditor: true, fieldName: item.guid, name: field, fieldData: value }], editorFieldsRef.current),
		);
	}, []);

	const getItemOptions = useCallback(
		({ item, isHeading, isSubHeading, isMemberOnlyHeading, isConsentHeading, isPublicCommentHeading }) => {
			// Item menu options
			const menuOptions = [];
			menuOptions.push({
				label: t("agendaMenu:duplicate"),
				actionFunction: () => duplicateItem(item),
				"data-cy": "duplicate",
			});
			if (getNextAvailablePosition(item, true).nextPosition !== null) {
				menuOptions.push({
					label: t("agendaMenu:moveUp"),
					actionFunction: () => moveItem(item, true),
					"data-cy": "move-up",
				});
			}
			if (getNextAvailablePosition(item, false).nextPosition !== null) {
				menuOptions.push({
					label: t("agendaMenu:moveDown"),
					actionFunction: () => moveItem(item, false),
					"data-cy": "move-down",
				});
			}
			menuOptions.push({
				label: t("agendaMenu:delete"),
				actionFunction: () => deleteItem(item),
				"data-cy": "delete",
			});
			if (isHeading && !isSubHeading) {
				menuOptions.push({
					label: isMemberOnlyHeading ? t("agendaMenu:setPublicSection") : t("agendaMenu:setMemberOnlySection"),
					actionFunction: () => toggleMembersOnly(item),
					separator: true,
					"data-cy": "members-only",
				});
				menuOptions.push({
					label: isConsentHeading ? t("agendaMenu:transformSection") : t("agendaMenu:transformSectionConsent"),
					actionFunction: () => toggleConsent(item),
					"data-cy": "consent",
				});
				menuOptions.push({
					label: isPublicCommentHeading ? t("agendaMenu:transformSection") : t("agendaMenu:transformSectionPublicComment"),
					actionFunction: () => togglePublicComment(item),
					"data-cy": "public-comment",
				});
			}

			return menuOptions;
		},
		[items],
	);

	const addItem = (item) => createNewItem(item, ITEM_TYPES.MEETING_TEMPLATE_AGENDA_ITEM);

	const addHeading = (item) => createNewItem(item, ITEM_TYPES.MEETING_TEMPLATE_AGENDA_HEADING);

	const addSubHeading = (item) => createNewItem(item, ITEM_TYPES.MEETING_TEMPLATE_AGENDA_HEADING, true);

	const addRecommendation = (item) => createNewItem(item, ITEM_TYPES.RECOMMENDATION);

	const addItemMenuOptions = useMemo(
		() => [
			{
				button: t("agendaMenu:addItem"),
				label: t("agendaMenu:addItemMenu"),
				actionFunction: addItem,
				"data-cy": "add-item",
			},
			{
				button: t("agendaMenu:addHeader"),
				label: t("agendaMenu:addHeaderMenu"),
				actionFunction: addHeading,
				"data-cy": "add-heading",
			},
			{
				button: t("agendaMenu:addSubHeading"),
				label: t("agendaMenu:addSubHeadingMenu"),
				actionFunction: addSubHeading,
				"data-cy": "add-sub-heading",
			},
			{
				button: t("agendaMenu:addRecommendedAction"),
				label: t("agendaMenu:addRecommendedActionMenu"),
				actionFunction: addRecommendation,
				"data-cy": "add-recommendation",
			},
		],
		[items],
	);

	const getItems = (items, droppedId) => {
		let isConsentHeading = false;
		let isPublicCommentHeading = false;
		let isMemberOnlyHeading = false;

		const filteredItems = [];
		const previous = {
			heading: {
				item: null,
				numberOfChildren: 0,
			},
		};
		items.forEach((item) => {
			filteredItems.push(item);
			if (!item.deleted) {
				if (item.itemType === ITEM_TYPES.MEETING_TEMPLATE_AGENDA_HEADING) {
					// Top level heading
					previous.heading = {
						item,
						numberOfChildren: !item.attributes.relationshipGuid ? 0 : previous.heading.numberOfChildren,
					};
				} else if (previous.heading.item && item.itemType === ITEM_TYPES.MEETING_TEMPLATE_AGENDA_ITEM) {
					// Count the children of the heading
					previous.heading.numberOfChildren++;
				}
			}
		});

		const lastIndex = filteredItems.length - 1;
		let elementsIndex = 0;
		let domElements = [];

		// Header
		const headerElement = (
			<HeaderFooter
				key="header"
				isHeader
				elementsRef={elementsRef}
				elementsIndex={elementsIndex}
				afterElementRefSet={afterElementRefSet}
				addHeadingPosition={ADD_HEADING_BELOW}
				handleAddHeading={addHeading}
			/>
		);
		domElements.push(headerElement);
		elementsIndex++;

		filteredItems.map((item, index) => {
			if (droppedId === item.guid) {
				domElements.push(
					<Droppable
						dropComponent={DropPlaceholder}
						key={`placeholder-before-${item.guid}`}
						dropId={`${item.guid}-placeholder`}
						component="li"
					></Droppable>,
				);
			}

			const isHeading = item.itemType === ITEM_TYPES.MEETING_TEMPLATE_AGENDA_HEADING;
			const isSubHeading = Boolean(isHeading && item.attributes.relationshipGuid);
			const isItem = item.itemType === ITEM_TYPES.MEETING_TEMPLATE_AGENDA_ITEM;
			isMemberOnlyHeading = Boolean(item?.fields?.Closed?.Value);
			isConsentHeading = Boolean(item?.fields?.Consent?.Value);
			isPublicCommentHeading = Boolean(item?.fields?.PublicComment?.Value);
			let element;

			let editorFieldText = {};

			let neweditorFieldText =
				editorFieldsRef &&
				editorFieldsRef.current &&
				editorFieldsRef.current.find((field) => {
					return field.name === `${item && item.guid}-text`;
				});

			if (neweditorFieldText) {
				editorFieldText = neweditorFieldText;
			}

			element = (
				<MeetingItem
					key={item.guid}
					item={item}
					active={active?.indexOf(item.guid) >= 0 ? active : undefined}
					setActive={setActive}
					isMemberOnlyHeading={isMemberOnlyHeading}
					isConsentHeading={isConsentHeading}
					isPublicCommentHeading={isPublicCommentHeading}
					isHeading={isHeading}
					isSubHeading={isSubHeading}
					isItem={isItem}
					isRecommendation={item.itemType === ITEM_TYPES.RECOMMENDATION}
					showNotes
					handleUpdateNotes={updateTextField}
					showMenu
					menuOptions={getItemOptions}
					addItemMenuOptions={addItemMenuOptions}
					menuAnchor={menuAnchor.guid === item.guid ? menuAnchor : undefined}
					handleMenu={handleMenu}
					canDrag
					canDrop
					addBottomBorder={index === lastIndex}
					elementsRef={elementsRef}
					elementsIndex={elementsIndex}
					afterElementRefSet={afterElementRefSet}
					removeText={removeItemText}
					addText={addItemText}
					editorFieldTextDeleted={editorFieldText.deleted}
					customNumbering={customNumbering}
					items={items}
				/>
			);
			elementsIndex += 2;

			// We now always create the text element, it is just hidden or shown sometimes
			domElements.push(element);
			return element || <div key={`item${item.guid}`}>{item.fields.Name.Value}</div>;
		});

		if (droppedId === tocFooterDropId) {
			domElements.push(
				<Droppable
					dropComponent={DropPlaceholder}
					key={`placeholder-before-${tocFooterDropId}`}
					dropId={tocFooterDropId}
					component="li"
				></Droppable>,
			);
		}

		// Footer
		const footerElement = (
			<HeaderFooter
				key="footer"
				label={t("templateDetail.labels.footer")}
				elementsRef={elementsRef}
				elementsIndex={elementsIndex}
				afterElementRefSet={afterElementRefSet}
				canDrop
			/>
		);
		domElements.push(footerElement);
		elementsIndex++;

		// Initialize the elements ref status
		if (elementsRefStatus.current.rerender) {
			elementsRefStatus.current.status = [];
			for (let index = 0; index < elementsIndex; index++) {
				elementsRefStatus.current.status[index] = false;
			}
		}

		return domElements;
	};

	const scrollToActive = (fieldName, field) => {
		scrollToElement(fieldName);
		const elem = document.getElementById(field ? `${field}-${fieldName}` : `agenda-${fieldName}`);
		if (elem) {
			scrollToElement(elem, document.getElementById("content-scroll"), -48);
		}
		const tocElement = document.getElementById(`outline-minute-${fieldName}`);
		if (tocElement) {
			scrollToElement(tocElement, document.getElementById("minute-outline"));
		}
	};

	const focusEditor = (fieldName, attempts = 0) => {
		const root = window.editor?.model?.document?.getRoot(fieldName);
		let element = null;
		if (root) {
			element = document.querySelectorAll(`[data-fieldname="${fieldName}"]`);
		}
		if (element && element.length > 0) {
			element[0].focus();
			scrollToActive(fieldName);
		} else if (attempts < 10) {
			// Try again
			setTimeout(() => {
				focusEditor(fieldName, attempts + 1);
			}, 100);
		}
	};

	const saveUpdates = debounce(async () => {
		dispatch(persistMeetingTemplateAgendaItems(t, dateFormat)).catch((err) => {
			showSignIn(err, () => {
				dispatch(persistMeetingTemplateAgendaItems(t, dateFormat));
			});
		});
	}, saveDelay);

	useEffect(() => {
		if (window.editor) {
			handleFieldChange(window.editor);
		}
	}, [triggerEditorChange]);

	useEffect(() => {
		if (active && focus) {
			focusEditor(active);
		} else {
			scrollToActive(active, activeField);
		}
	}, [active, focus, activeField]);

	useEffect(() => {
		initializeFields(items);
	}, []);

	useEffect(() => {
		if (!saving && updated) {
			saveUpdates();
		}
	}, [saving, updated]);

	return (
		<>
			{editorInitializing && <Spinner />}
			<div
				className={clsx("flex", "direction-column", "agenda-editor-content", {
					["agenda-editor-content-xs"]: !widthUpSm,
					[classes.editorContentSmUp]: widthUpSm,
					editorFieldHide: editorInitializing,
				})}
			>
				<Container id={"new-editor-toc-header"} maxWidth="lg" className={classes.contentContainer}>
					<DndContext
						sensors={sensors}
						collisionDetection={getCollisionDetection(dragRef, rectIntersection)}
						onDragStart={handleDragStart}
						onDragMove={handleDragMove}
						onDragEnd={handleDragEnd}
						onDragCancel={handleDragCancel}
					>
						<List
							component="ul"
							disablePadding
							classes={{ root: "agenda-items-container agenda-items-board-notes-container" }}
							id="item-list"
							data-cy="item-list"
						>
							{editorFields.length > 0 && getItems(items, droppedId)}
						</List>
						{createPortal(
							<DragOverlay
								style={{ height: `${dragDimensionsRef.current?.height || 50}px`, width: `${dragDimensionsRef.current?.width || 500}px` }}
							>
								<DragPresentation ref={dragRef}>{getDragComponent()}</DragPresentation>
							</DragOverlay>,
							document.body,
						)}
					</DndContext>
				</Container>
				{editorFields.length > 0 && elementsRef.current.length > 0 && (
					<SingletonEditor
						fields={editorFields}
						fieldsRefs={elementsRef}
						fieldRefsSet={elementRefsSet}
						editorToolbarRef={editorToolbarRef}
						onFieldChange={handleFieldChange}
						onEditorInitialized={handleEditorInitialized}
						onFocusChange={handleFocusChange}
						onUndoRedoRoot={handleUndoRedoRoot}
						onDeletedFieldIndex={handleDeletedFieldIndex}
						deletedFieldIdx={deletedFieldIdx}
					/>
				)}
			</div>
		</>
	);
};

export default AgendaEditor;
