import React from "react";
import SvgIcon from "@mui/material/SvgIcon";

import themeConfig from "../../atlas/assets/jss/theme";
import getOr from "lodash/fp/getOr";

const icon = (props) => {
	const { color } = props;
	const fillColor = color ? getOr(color, ["palette", color, "main"], themeConfig) : "currentColor";

	return (
		<SvgIcon width="15" height="16" viewBox="0 0 15 16" {...props} data-cy="paperclip">
			<path
				fillRule="evenodd"
				d="M10,10.1333333 L10,5.86666667 C10,4.61482123 8.98517877,3.6 7.73333333,3.6 C6.4814879,3.6 5.46666667,4.61482123 5.46666667,5.86666667 L5.46666667,13.3333333 C5.46666667,15.1742825 6.95905083,16.6666667 8.8,16.6666667 C9.68405497,16.6666667 10.5319013,16.3154772 11.1570226,15.6903559 C11.7821439,15.0652347 12.1333333,14.2173883 12.1333333,13.3333333 L12.1333333,3.73333333 C12.1333333,1.30328043 10.1633862,-0.666666667 7.73333333,-0.666666667 C5.30328043,-0.666666667 3.33333333,1.30328043 3.33333333,3.73333333 L3.33333333,10.1333333 C3.33333333,10.5015232 3.63181017,10.8 4,10.8 C4.36818983,10.8 4.66666667,10.5015232 4.66666667,10.1333333 L4.66666667,3.73333333 C4.66666667,2.0396601 6.0396601,0.666666667 7.73333333,0.666666667 C9.42700657,0.666666667 10.8,2.0396601 10.8,3.73333333 L10.8,13.3333333 C10.8,13.8637663 10.5892863,14.3724741 10.2142136,14.7475469 C9.83914081,15.1226197 9.33043298,15.3333333 8.8,15.3333333 C7.6954305,15.3333333 6.8,14.4379028 6.8,13.3333333 L6.8,5.86666667 C6.8,5.3512009 7.21786757,4.93333333 7.73333333,4.93333333 C8.2487991,4.93333333 8.66666667,5.3512009 8.66666667,5.86666667 L8.66666667,10.1333333 C8.66666667,10.5015232 8.9651435,10.8 9.33333333,10.8 C9.70152317,10.8 10,10.5015232 10,10.1333333 Z"
				transform="rotate(45 7.233 6.793)"
			/>
		</SvgIcon>
	);
};

export default icon;
