/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

import makeStyles from "@mui/styles/makeStyles";

import Icon from "atlas/components/Icon/Icon";
import { blackColor } from "atlas/assets/jss/shared";
import { getAttachmentLinkText } from "utils/meetingElement";
import LiveMeetingRadioButton from "./LiveMeetingRadioButton";
import LiveMeetingAttachmentPage from "./LiveMeetingAttachmentPage";

import agendaStyle from "../../../assets/jss/components/agendaStyle";

const useStyle = makeStyles(agendaStyle);

const LiveMeetingAttachment = (props) => {
	const {
		attachment,
		item,
		isClosedMeeting,
		isMemberOnlyHeading,
		isConsentHeading,
		isPublicCommentHeading,
		addBottomBorder,
		selected,
		handleSelect,
		presentation,
		noMargin = false,
	} = props;
	const classes = useStyle({});
	const [open, setOpen] = useState(false);

	const toggle = () => {
		setOpen(!open);
	};

	const handleKeyPress = (e) => {
		e.preventDefault();
		e.stopPropagation();

		if (e.key === "Enter") {
			toggle();
		}
	};

	const handleClick = (e) => {
		e.preventDefault();
		e.stopPropagation();

		toggle();
	};

	const addBottomBorderToThis = addBottomBorder && !open;
	const isSelected = Boolean(selected === attachment.guid); // selected should only have a value if the attachment is selected
	const isClosed = isMemberOnlyHeading || (!isMemberOnlyHeading && attachment.closed) || isClosedMeeting;
	const showPages = attachment.pages > 0;

	const getPageItems = () => {
		let pageItems = null;
		if (showPages && open) {
			pageItems = [];
			// eslint-disable-next-line no-plusplus
			for (let index = 1; index <= attachment.pages; index++) {
				pageItems.push(
					<LiveMeetingAttachmentPage
						key={`${attachment.guid}-page-${index}`}
						attachment={attachment}
						page={index}
						isClosedMeeting={isClosedMeeting}
						isMemberOnlyHeading={isMemberOnlyHeading}
						isConsentHeading={isConsentHeading}
						isPublicCommentHeading={isPublicCommentHeading}
						addBottomBorder={addBottomBorder && index === attachment.pages}
						selected={Boolean(selected && selected.endsWith(`-page-${index}`))}
						handleSelect={handleSelect}
						presentation={presentation}
						noMargin={noMargin}
					/>,
				);
			}
		}

		return pageItems;
	};

	if (typeof attachment.linkText === "undefined") {
		attachment.linkText = getAttachmentLinkText(item, attachment.guid);
	}

	let attachmentText = attachment.name;
	if (attachment.linkText && attachment.linkText !== attachmentText) {
		attachmentText = `${attachment.linkText} (${attachmentText})`;
	}
	if (attachmentText.includes("&amp;")) {
		attachmentText = attachmentText.replaceAll("&amp;", "&");
	}

	return (
		!attachment.removed && (
			<>
				<li
					id={`agenda-${attachment.guid}`}
					className={clsx(classes.agendaListItem, {
						[classes.radioListItem]: !noMargin,
						[classes.borderDefault]: !isMemberOnlyHeading && !isConsentHeading && !isPublicCommentHeading && !addBottomBorderToThis,
						[classes.borderDefaultBottomBorder]:
							!isMemberOnlyHeading && !isConsentHeading && !isPublicCommentHeading && addBottomBorderToThis,
						[classes.borderMemberOnly]: isMemberOnlyHeading,
						[classes.borderConsent]: isConsentHeading,
						[classes.borderPublicComment]: isPublicCommentHeading,
						[classes.selected]: isSelected,
					})}
					data-cy={`agenda-${attachment.guid}`}
				>
					<LiveMeetingRadioButton value={attachment.guid} label={attachment.name} selected={isSelected} handleSelect={handleSelect} />
					<div
						className={clsx(classes.item, classes.itemAttachment, classes.rightBorder, {
							[classes.bottomBorder]: addBottomBorderToThis,
						})}
						role={showPages ? "button" : undefined}
						tabIndex={showPages ? 0 : undefined}
						onClick={showPages ? handleClick : undefined}
						onKeyPress={showPages ? handleKeyPress : undefined}
					>
						{showPages && (
							<div data-icon={open ? "collapse" : "expand"}>
								<Icon name={open ? "expand-down" : "expand-right"} color={blackColor[1]} />
							</div>
						)}
						<div className={classes.itemText} data-text>
							{attachmentText}
						</div>
						<div className={classes.itemIcon} data-icon={isClosed ? "closed" : "public"}>
							<Icon name={isClosed ? "locked" : "attach"} size="16px" color={blackColor[1]} />
						</div>
					</div>
				</li>
				{getPageItems()}
			</>
		)
	);
};

LiveMeetingAttachment.propTypes = {
	attachment: PropTypes.object.isRequired,
	isClosedMeeting: PropTypes.bool,
	isMemberOnlyHeading: PropTypes.bool,
	isConsentHeading: PropTypes.bool,
	isPublicCommentHeading: PropTypes.bool,
	addBottomBorder: PropTypes.bool,
	handleSelect: PropTypes.func,
};

LiveMeetingAttachment.defaultProps = {
	isClosedMeeting: false,
	isMemberOnlyHeading: false,
	isConsentHeading: false,
	isPublicCommentHeading: false,
	addBottomBorder: false,
	handleSelect: undefined,
};

export default LiveMeetingAttachment;
