import React, { useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import clsx from "clsx";

import makeStyles from "@mui/styles/makeStyles";

import userPermissionOptionsStyle from "assets/jss/components/userPermissionOptionsStyle";
import Accordion from "atlas/components/Accordion/Accordion";
import Icon from "atlas/components/Icon/Icon";
import { useWidthDown } from "atlas/utils/useWidth";
import accordionStyle from "assets/jss/components/accordionStyle";
import GenericAvatar, { GENERIC_AVATAR_BACKGROUNDS } from "components/Avatar/GenericAvatar";
import { SettingsContext } from "contexts/Settings/SettingsContext";
import { FOLDER_TYPE_PUBLIC, FOLDER_TYPE_INTERNAL, FOLDER_TYPE_CUSTOM } from "utils/enums/DocumentFolderTypes";
import { sortTextFieldCaseInsensitive, sortNumericField } from "utils/sort";
import PermissionOptions from "./PermissionOptions";
import PermissionOptionLabels from "./PermissionOptionLabels";

const useStyles = makeStyles(userPermissionOptionsStyle);
const useAccordionStyles = makeStyles(accordionStyle);

const sortDefault = (a, b) => {
	const sort = sortNumericField(a, b, "type");

	return sort !== 0 ? sort : sortTextFieldCaseInsensitive(a, b, "title");
};

const DocumentLibrarySection = (props) => {
	const { copyId, user = {}, users, handleUpdate, telemetryPage } = props;
	const mobile = useWidthDown("md");
	const { t } = useTranslation("users");
	const [expanded, setExpanded] = useState([]);
	const { lite } = useContext(SettingsContext);
	const classes = useStyles();
	const accordionClasses = useAccordionStyles();

	const toggleFolder = (guid) => {
		setExpanded((prev) => [
			...(!prev.find((prevFolder) => prevFolder === guid) ? prev.concat([guid]) : prev.filter((prevFolder) => prevFolder !== guid)),
		]);
	};

	const alwaysShowView = !!(user.folderPermissions || []).find((folder) => folder.type === FOLDER_TYPE_CUSTOM) && !lite.enabled;

	return (
		user.folderPermissions &&
		users && (
			<Accordion
				classes={accordionClasses}
				label={t("detail.documentLibrary")}
				scrollContentIntoView
				overflow={false}
				dataCy="document-library"
			>
				<div id="document-library">
					<div className={classes.folders}>
						{!mobile && (
							<div className={clsx(classes.sectionRow, classes.userLabels)}>
								<div className={classes.folderLabel} />
								<PermissionOptionLabels hideView={!alwaysShowView} />
							</div>
						)}
						<ol data-cy="folders">
							{user.folderPermissions
								.sort(sortDefault)
								.filter((folder) => !lite.enabled || folder.type === FOLDER_TYPE_PUBLIC)
								.map((folder) => {
									const open = expanded.includes(folder.guid);
									const thisFolderUser = folder.permissions.find(
										(permission) => permission.userId === user.id || (user.id === 0 && permission.userId === copyId),
									);
									const handleFolderClick = () => toggleFolder(folder.guid);
									const otherUsers = open
										? folder.permissions
												.filter((permission) => permission.userId !== user.id)
												.map((permission) => ({
													permission,
													user: users.find((user) => user.id === permission.userId),
												}))
												.filter((userData) => userData.user)
												.sort((a, b) => (a.user.name < b.user.name ? -1 : a.user.name > b.user.name ? 1 : 0))
										: [];

									return (
										<li key={folder.guid}>
											<div className={clsx(classes.sectionRow, classes.folderRow)}>
												<div className={classes.folderIcon} onClick={handleFolderClick} data-cy={`folder-expand-${folder.guid}`}>
													<Icon name={open ? "expand-down" : "expand-right"} />
												</div>
												<div className={classes.folderName} onClick={handleFolderClick} data-cy={`folder-name-${folder.guid}`}>
													{folder.title} - {user.firstName} {user.lastName}
												</div>
												<PermissionOptions
													user={user}
													permission={thisFolderUser}
													alwaysView={[FOLDER_TYPE_PUBLIC, FOLDER_TYPE_INTERNAL].includes(folder.type)}
													showViewPlaceholder={alwaysShowView}
													handleUpdate={handleUpdate}
													telemetryPage={telemetryPage}
												/>
											</div>
											{open && otherUsers.length > 0 && (
												<div>
													<ol data-cy={`folder-users-${folder.guid}`}>
														{otherUsers.map((userData) => {
															const { permission, user } = userData;

															return (
																<li key={user.id} className={clsx(classes.sectionRow, classes.folderPermissionRow)}>
																	<div className={classes.userAvatar}>
																		<GenericAvatar
																			imageUrl={user.profileImageUrl}
																			name={user.name}
																			initials={`${(user.firstName || "").substr(0, 1)}${(user.lastName || "").substr(0, 1)}`}
																			backgroundNumber={((user.number || 0) % GENERIC_AVATAR_BACKGROUNDS) + 1}
																		/>
																	</div>
																	<div className={classes.userName}>
																		{user.deleted || user.inactive || user.external
																			? t(`app:entities.${user.deleted ? "deleted" : user.external ? "external" : "inactive"}`, {
																					entity: user.name,
																			  })
																			: user.name}
																	</div>
																	<PermissionOptions
																		permission={permission}
																		alwaysView={[FOLDER_TYPE_PUBLIC, FOLDER_TYPE_INTERNAL].includes(folder.type)}
																		showViewPlaceholder={alwaysShowView}
																		telemetryPage={telemetryPage}
																	/>
																</li>
															);
														})}
													</ol>
												</div>
											)}
										</li>
									);
								})}
						</ol>
					</div>
				</div>
			</Accordion>
		)
	);
};

export default DocumentLibrarySection;
