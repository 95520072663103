import React from "react";
import { useTranslation } from "react-i18next";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";

import { grayColor, blackColor } from "atlas/assets/jss/shared";

const useStyles = makeStyles(() => ({
	label: {
		textTransform: "uppercase",
		color: blackColor[2],
		padding: "0",
		fontWeight: "600",
		lineHeight: "1.25",
		letterSpacing: "1px",
		fontSize: "16px",
	},
	labelLargeMargin: {
		marginBottom: "16px",
	},
	labelSmallMargin: {
		marginBottom: "8px",
	},
	input: {
		borderRadius: "4px",
		border: `2px solid ${grayColor[0]}`,
		height: "24px",
		marginBottom: "16px",
		padding: "8px",
		display: "flex",
		alignItems: "center",
	},
	textPlaceholder: {
		borderRadius: "4px",
		backgroundColor: grayColor[3],
		height: "24px",
		flexGrow: "1",
	},
}));

const MotionLoading = () => {
	const { t } = useTranslation("meetings");
	const classes = useStyles();

	return (
		<div>
			<div className={classes.label}>{t("movedBy")}</div>
			<div className={classes.input}>
				<div className={classes.textPlaceholder}></div>
			</div>
			<div className={classes.label}>{t("secondedBy")}</div>
			<div className={classes.input}>
				<div className={classes.textPlaceholder}></div>
			</div>
			<div className={clsx(classes.label, classes.labelLargeMargin)}>{t("voting.voters")}</div>
			<div className={clsx(classes.label, classes.labelSmallMargin)}>{t("results")}</div>
			<div className={classes.input}>
				<div className={classes.textPlaceholder}></div>
			</div>
		</div>
	);
};

export default React.memo(MotionLoading);
