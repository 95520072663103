import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import request from "superagent";
import { v4 as uuid } from "uuid";

import makeStyles from "@mui/styles/makeStyles";
import { IconButton, InputAdornment } from "@mui/material";

import Icon from "atlas/components/Icon/Icon";
import OutlinedInput from "atlas/components/FormControls/OutlinedInput";
import GenericDialog from "atlas/components/Dialogs/GenericDialog";
import ProgressBar from "atlas/components/Progress/ProgressBar";
import { API_HOST } from "config/env";
import { Visibility, VisibilityOff } from "components/Icons";
import { updateHandlers, PROGRESS_HUB } from "utils/communication/SignalrClient";
import restorePurgeStyle from "../assets/restorePurge";
import notifierMessage from "utils/notifierMessage";
import { setSnackbarOptions } from "redux/snackBar/actions";

const useStyles = makeStyles(restorePurgeStyle);

const DocumentPermanentlyDeleteDialog = (props) => {
	const { show = true, documents = [], onClose, afterPermanentlyDelete, showSignIn } = props;
	const { t } = useTranslation("documents");
	const [password, setPassword] = useState("");
	const [showPassword, setShowPassword] = useState(false);
	const [errors, setErrors] = useState({});
	const [deleting, setDeleting] = useState(false);
	const [progress, setProgress] = useState({
		label: " ",
		percent: 0,
	});
	const appReducer = useSelector((state) => state.appReducer);
	const {
		signalR: { client, handler },
	} = appReducer;
	const dispatch = useDispatch();
	const guid = useRef();
	const classes = useStyles();
	const progressId = "deleting-progress";

	const updateProgress = (percentage, message) => {
		setProgress({
			label: message,
			percent: percentage,
		});

		if (percentage === 100) {
			let option = notifierMessage(t(`permanentlyDeleteDocumentDialog.snackbar.success`), "success");
			dispatch(setSnackbarOptions(option));
			setTimeout(onClose, 3000);
			afterPermanentlyDelete(documents);
		}
	};

	const handlePermanentlyDelete = () => {
		setDeleting(true);

		request
			.delete(`${API_HOST}/api/document/0/purge`)
			.withCredentials()
			.send({ documents: documents.map((document) => document.guid), password, progressGuid: guid.current })
			.then(() => {})
			.catch((err) => {
				showSignIn(
					err,
					() => {
						handlePermanentlyDelete();
					},
					(err) => {
						if (err.status === 400) {
							const { response: { body: { Message: message } = {} } = {} } = err;
							if (message === "Invalid password") {
								setDeleting(false);
								setErrors((prev) => ({ ...prev, password: true }));
							}
						}
					},
				);
			});
	};

	const handleCancel = () => {
		onClose();
	};

	const handleClickShowPassword = () => {
		setShowPassword((prev) => !prev);
	};

	useEffect(() => {
		guid.current = uuid();

		updateHandlers(dispatch, handler, PROGRESS_HUB, { announceProgress: updateProgress });

		client.ensureStarted().then(() => client.progressHub.registerProgressGuid(guid.current));

		return () => {
			client.ensureStarted().then(() => client.progressHub.clearProgressGuid(guid.current));

			updateHandlers(dispatch, handler, PROGRESS_HUB, { announceProgress: null });
		};
	}, []);

	const dialog = {
		title: t("permanentlyDeleteDocumentDialog.title"),
		primaryTitle: t("app:buttons.permanentlyDelete"),
		primaryAction: handlePermanentlyDelete,
		secondaryTitle: t("app:buttons.cancel"),
		secondaryAction: handleCancel,
	};

	const numberFolders = documents.filter((document) => document.folder).length;
	const numberDocuments = documents.length - numberFolders;
	const numberLabels = [];
	if (numberFolders > 0) {
		numberLabels.push(t(`permanentlyDeleteDocumentDialog.numberFolder${numberFolders > 1 ? "s" : ""}`, { number: numberFolders }));
	}
	if (numberDocuments > 0) {
		numberLabels.push(t(`permanentlyDeleteDocumentDialog.numberDocument${numberDocuments > 1 ? "s" : ""}`, { number: numberDocuments }));
	}

	return (
		<GenericDialog
			className={classes.dialog}
			show={show}
			title={dialog.title}
			primaryAction={dialog.primaryAction}
			primaryTitle={dialog.primaryTitle}
			primaryDisabled={documents.length === 0 || deleting || !password}
			secondaryAction={dialog.secondaryAction}
			secondaryTitle={dialog.secondaryTitle}
			secondaryDisabled={deleting}
			clickAwayDisabled={deleting}
			closeIcon={<Icon name="close" />}
			data-cy="permanently-delete-dialog"
		>
			<div>{numberLabels.join(", ")}</div>
			<ol className={classes.list}>
				{documents.map((document) => (
					<li key={document.guid} className={classes.listItem}>
						<div className={classes.typeIcon} title={!document.folder ? document.extension : undefined}>
							{document.folder ? <Icon name="folder" size="24" /> : <Icon name="document" size="24" />}
						</div>
						<div className={classes.title}>{document.title}</div>
					</li>
				))}
			</ol>
			<OutlinedInput
				id="password"
				label={t("permanentlyDeleteDocumentDialog.labels.password")}
				type={showPassword ? "text" : "password"}
				value={password}
				onChange={(e) => setPassword(e.target.value)}
				noDefaultClassName
				fullWidth
				size="small"
				InputProps={{
					endAdornment: (
						<InputAdornment tabIndex="-1" position="end">
							<IconButton tabIndex="-1" onClick={handleClickShowPassword} aria-label={t("app:buttons.showPassword")}>
								{showPassword ? <VisibilityOff /> : <Visibility />}
							</IconButton>
						</InputAdornment>
					),
				}}
				helperText={errors.password ? t("permanentlyDeleteDocumentDialog.errors.password") : undefined}
				error={errors.password}
				data-cy="password"
			/>
			<div>{t("permanentlyDeleteDocumentDialog.timeWarning")}</div>
			{deleting && <ProgressBar id={progressId} className={classes.progressBar} label={progress.label} progress={progress.percent} />}
		</GenericDialog>
	);
};

export default DocumentPermanentlyDeleteDialog;
