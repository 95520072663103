import React from "react";
import { withTranslation } from "react-i18next";
import getOr from "lodash/fp/getOr";
import omit from "lodash/fp/omit";

import SvgIcon from "@mui/material/SvgIcon";

import themeConfig from "../../atlas/assets/jss/theme";
import Tooltip from "atlas/components/Tooltip/Tooltip";

const icon = (props) => {
	const { className, color, fontSize = "small", t } = props;
	const fillColor = color ? getOr(color, ["palette", color, "main"], themeConfig) : "currentColor";

	return (
		<Tooltip title={t("buttons.newWindow")} aria-label={t("buttons.newWindow")}>
			<SvgIcon className={className} fontSize={fontSize} {...omit(props, t)}>
				<defs>
					<path
						d="M1,2.12820513 C1,1.50511464 1.50946908,1 2.13793103,1 L21.862069,1 C22.4905309,1 23,1.50511464 23,2.12820513 L23,21.8717949 C23,22.4948854 22.4905309,23 21.862069,23 L2.13793103,23 C1.50946908,23 1,22.4948854 1,21.8717949 L1,2.12820513 Z M3.27586207,3.25641026 L3.27586207,20.7435897 L20.7241379,20.7435897 L20.7241379,3.25641026 L3.27586207,3.25641026 Z M13.544837,8.85042735 L10.3563218,8.85042735 C9.72785988,8.85042735 9.2183908,8.34531271 9.2183908,7.72222222 C9.2183908,7.09913174 9.72785988,6.59401709 10.3563218,6.59401709 L16.2735632,6.59401709 C16.9020252,6.59401709 17.4114943,7.09913174 17.4114943,7.72222222 L17.4114943,13.6452991 C17.4114943,14.2683896 16.9020252,14.7735043 16.2735632,14.7735043 C15.6451013,14.7735043 15.1356322,14.2683896 15.1356322,13.6452991 L15.1356322,10.4644876 L8.86365186,16.7426533 C8.42139347,17.1853479 7.70090364,17.1887657 7.25439278,16.7502873 C6.80788191,16.3118089 6.80443459,15.5974771 7.24669297,15.1547826 L13.544837,8.85042735 Z"
						id="external-link-path-1"
					/>
				</defs>
				<g id="external-link" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
					<g>
						<mask id="external-link-mask-2" fill="white">
							<use xlinkHref="#external-link-path-1" />
						</mask>
						<use fill={fillColor} fillRule="nonzero" xlinkHref="#external-link-path-1" />
					</g>
				</g>
			</SvgIcon>
		</Tooltip>
	);
};

export default withTranslation("meetings")(icon);
