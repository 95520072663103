import React, { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import makeStyles from "@mui/styles/makeStyles";

import { grayColor, focusColor, primaryColor } from "atlas/assets/jss/shared";
import ButtonWithTooltip from "atlas/components/Buttons/ButtonWithTooltip";
import OutlinedInput from "atlas/components/FormControls/OutlinedInput";
import Icon from "atlas/components/Icon/Icon";
import { SMALL_SQUARE, VERY_SMALL } from "atlas/utils/buttonSize";
import { getAllowedAccept } from "utils/allowedExtensions";
import { formatDate } from "utils/date";
import getPathType, { generatePathLink } from "../utils/pathType";

const useStyles = makeStyles({
	section: {
		fontSize: "14px",
		fontWeight: "400",
		lineHeight: "16px",
		letterSpacing: "1px",
		borderBottom: `solid 1px ${grayColor[4]}`,
		padding: "16px",
	},
	name: {
		marginTop: "-16px",
	},
	data: {
		display: "flex",
		alignItems: "center",
		boxSizing: "border-box",
		minHeight: "24px",
		padding: "4px 0",
		"& > *:not(:first-child)": {
			marginLeft: "8px",
		},
	},
	dataText: {
		flexGrow: "1",
	},
	fileInput: {
		display: "none",
	},
	path: {
		"& a": {
			textDecoration: "none",
		},
		"& a:hover": {
			textDecoration: "underline",
		},
		"& a:focus-visible": {
			outline: `solid 2px ${focusColor}`,
			outlineOffset: "-2px",
			borderRadius: "4px",
		},
	},
});

const DocumentDetailsInfo = (props) => {
	const { document, handleRename, handleDownload, handleReplace, handleCopyMove } = props;
	const { t } = useTranslation("documents");
	const navigate = useNavigate();
	const [title, setTitle] = useState(document.title);
	const input = useRef(null);
	const classes = useStyles();

	const openPage = (e, pageLink) => {
		e.preventDefault();

		navigate(pageLink);
	};

	const replaceClick = () => {
		input.current.click();
	};

	const rootFolder = getPathType(document.parentFolderType);

	return (
		document && (
			<>
				<div className={classes.section}>
					<div className={classes.name}>
						<OutlinedInput
							id="name"
							label={t("detail.labels.name")}
							value={title}
							onChange={(e) => setTitle(e.target.value)}
							onBlur={() => handleRename(title)}
							noDefaultClassName
							fullWidth
							size="small"
							disabled={!document.canUpdate}
							data-cy="name"
						/>
					</div>
					{!document.folder && (
						<div className={classes.data}>
							<div className={classes.dataText}>{t("detail.labels.type", { type: document.extension.replace(".", "").toUpperCase() })}</div>
							{document.canUpdate && (
								<>
									<ButtonWithTooltip
										id="download"
										title={t("detail.tooltips.download")}
										onClick={handleDownload}
										variant="outlined"
										color="primary"
										size={SMALL_SQUARE}
										dataCy="download"
									>
										<Icon name="arrow-down" size="14" color={primaryColor[3]} />
									</ButtonWithTooltip>
									<ButtonWithTooltip
										id="replace"
										title={t("detail.tooltips.replace")}
										onClick={replaceClick}
										variant="outlined"
										color="primary"
										size={SMALL_SQUARE}
										dataCy="replace"
									>
										<Icon name="upload" size="14" color={primaryColor[3]} />
									</ButtonWithTooltip>
									<input
										className={classes.fileInput}
										id="replace-input"
										type="file"
										ref={input}
										onChange={handleReplace}
										accept={getAllowedAccept()}
										tabIndex={-1}
										aria-label={t("detail.buttons.replace")}
										aria-hidden="true"
									/>
								</>
							)}
						</div>
					)}
					{!document.folder && (
						<div className={classes.data}>
							<div>{t("detail.labels.size", { size: document.size })}</div>
						</div>
					)}
					<div className={classes.data}>
						<div>
							{t("detail.labels.dateModified", {
								date: formatDate(document.dateModified, document.dateModified, undefined, t("app:at"), "", "", true, true, true),
							})}
						</div>
					</div>
				</div>
				<div className={classes.section}>
					<div className={classes.data}>
						<div className={classes.path}>
							{t("detail.labels.path")}
							{document.path.map((pathItem, index) => {
								const pathLink = generatePathLink(pathItem, index, rootFolder, document.parentFolderType);

								return (
									<React.Fragment key={pathItem.guid}>
										{rootFolder ? (
											<a href={`/home${pathLink}`} onClick={(e) => openPage(e, pathLink)}>
												{pathItem.title}
											</a>
										) : (
											<span>{pathItem.title}</span>
										)}
										{index < document.path.length - 1 ? <span>{" > "}</span> : ""}
									</React.Fragment>
								);
							})}
						</div>
					</div>
					{document.canUpdate && (
						<div className={classes.data}>
							<ButtonWithTooltip
								id="copy-move"
								className={classes.button}
								onClick={handleCopyMove}
								variant="outlined"
								color="primary"
								size={VERY_SMALL}
								dataCy="copy-move"
							>
								{t(`detail.buttons.${rootFolder ? "moveTo" : "copyTo"}`)}
							</ButtonWithTooltip>
						</div>
					)}
				</div>
			</>
		)
	);
};

export default DocumentDetailsInfo;
