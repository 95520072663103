import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import request from "superagent";

import makeStyles from "@mui/styles/makeStyles";
import { Checkbox, FormControlLabel, MenuItem } from "@mui/material";

import clsx from "clsx";
import { formatDate } from "utils/date";
import processHtml from "utils/processHtml";
import isEmail from "atlas/utils/isEmail";
import { API_HOST } from "config/env";
import { primaryColor } from "atlas/assets/jss/shared";
import inputStyle from "atlas/assets/jss/components/inputStyle";
import stackedCheckboxStyle from "atlas/assets/jss/components/stackedCheckboxStyle";
import Icon from "atlas/components/Icon/Icon";
import GenericDialog from "atlas/components/Dialogs/GenericDialog";
import InputLabel, { ROLE_HEADING } from "atlas/components/FormControls/InputLabel";
import OutlinedInput from "atlas/components/FormControls/OutlinedInput";
import SelectInput from "atlas/components/FormControls/SelectInput";
import requestToSpeakStyle from "../jss/requestToSpeakStyle";
import { getNoOptionsMenuItem, getDropdownProgressIndicator } from "utils/dropDown";

const useStyles = makeStyles(requestToSpeakStyle);

const useInputStyles = makeStyles(inputStyle);
const useCheckboxStyle = makeStyles(stackedCheckboxStyle);

const RequestToSpeakForm = (props) => {
	const { requestToSpeak, showMeetingAndSections, showMeetings, showCodeOfConduct, centerAlign, handleChange } = props;
	const { t } = useTranslation("requestToSpeak");
	const [meetings, setMeetings] = useState(null);
	const [codeOfConduct, setCodeOfConduct] = useState({ show: false });
	const [errors, setErrors] = useState({});
	const classes = useStyles({ centerAlign });
	const inputClasses = useInputStyles({ fullWidth: true });
	const checkboxClasses = useCheckboxStyle();

	const loadMeetings = () => {
		request
			.get(`${API_HOST}/api/meetings/requesttospeak?includeMeetingId=${requestToSpeak.meetingId}`)
			.withCredentials()
			.then((res) => {
				if (res.body && res.body.meetings) {
					setMeetings(res.body.meetings);
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const loadCodeOfConduct = () => {
		request
			.get(`${API_HOST}/api/settings/codeofconduct`)
			.withCredentials()
			.then((res) => {
				if (res.body) {
					setCodeOfConduct((prev) => ({
						...prev,
						...res.body,
					}));
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const getMeetings = () => {
		const meetingsItems = [];
		if (meetings && meetings.length) {
			meetings.forEach((meeting) => {
				const formattedDate = formatDate(null, meeting.startTime, null, t("app:at"), "", "", false);
				meetingsItems.push(
					<MenuItem key={`meeting-${meeting.id}`} className={classes.menuItem} value={meeting.id} data-cy={`meeting-${meeting.id}`}>
						<div className={classes.menuItemText}>{`${meeting.name} ${formattedDate}`}</div>
					</MenuItem>,
				);
			});
		} else if (meetings && meetings.length === 0) {
			meetingsItems.push(getNoOptionsMenuItem(t));
		} else if (!meetings) {
			meetingsItems.push(getDropdownProgressIndicator("meeting-loading"));
		}

		return meetingsItems;
	};

	const validate = (field, value, returnError) => {
		let newErrors = {};
		let valid = true;

		if (!field) {
			// Validate all fields
			valid = ["firstName", "lastName", "emailAddress", "topic"].reduce((fieldsValid, fieldName) => {
				const result = validate(fieldName, requestToSpeak[fieldName], true);

				newErrors = { ...newErrors, ...result.errors };

				return result.valid && fieldsValid;
			}, true);
		} else {
			// Validate single field
			if ((field && (!value || value.length === 0)) || (field === "emailAddress" && !isEmail(value))) {
				newErrors[field] = t(`publicSubmission.errors.${field}`);
				valid = false;
			} else {
				newErrors[field] = null;
			}

			if (returnError) {
				return { valid, errors: newErrors };
			}
		}

		setErrors({ ...errors, ...newErrors });

		return valid;
	};

	const handleBlur = (e, field) => {
		const {
			target: { value },
		} = e;

		validate(field, value);
	};

	const getMeetingDropdown = (onChange, includeLabel, label) => {
		return (
			<div>
				<SelectInput
					id="meeting"
					className={inputClasses.smallInput}
					noDefaultClassName
					label={includeLabel ? label || t("publicSubmission.meeting") : undefined}
					externalLabel={includeLabel || undefined}
					labelSize={includeLabel ? "large" : undefined}
					bottomSpacing={includeLabel}
					fullWidth
					size="small"
					value={meetings && meetings.length ? requestToSpeak.meetingId : 0}
					onChange={onChange}
					disabled={requestToSpeak.status === 2}
					SelectDisplayProps={{ "aria-required": "true" }}
					data-cy="meeting"
				>
					{getMeetings()}
				</SelectInput>
			</div>
		);
	};

	const openCodeOfConduct = (e) => {
		// Click, space, or enter
		if (!e.keyCode || [13, 32].includes(e.keyCode)) {
			e.preventDefault();

			setCodeOfConduct((prev) => ({
				...prev,
				show: true,
			}));
		}
	};

	const handleCancel = (e) => {
		e.preventDefault();

		setCodeOfConduct((prev) => ({
			...prev,
			show: false,
		}));
	};

	useEffect(() => {
		if (showMeetings || showMeetingAndSections) {
			loadMeetings();
		}
	}, [showMeetings, showMeetingAndSections]);

	useEffect(() => {
		if (showCodeOfConduct) {
			loadCodeOfConduct();
		}
	}, [showCodeOfConduct]);

	return (
		<>
			{codeOfConduct.show && (
				<GenericDialog
					className={classes.dialog}
					show={codeOfConduct.show}
					title={t("publicSubmission.codeOfConduct")}
					secondaryAction={handleCancel}
					secondaryTitle={t("app:buttons.cancel")}
					closeIcon={<Icon name="close" />}
					data-cy="full-code-of-conduct"
				>
					<div className={classes.codeOfConductContent}>{processHtml(codeOfConduct.codeOfConduct)}</div>
				</GenericDialog>
			)}
			<div className={classes.form}>
				<div className={clsx(classes.inputLabelSpacing, classes.inputHeading)}>
					<InputLabel
						id="contact-information"
						size="large"
						label={t("publicSubmission.contactInformation")}
						role={ROLE_HEADING}
						ariaLevel="2"
					/>
				</div>
				<div id="contact-information" className={classes.columns}>
					<div>
						<OutlinedInput
							id="first-name"
							label={t("publicSubmission.firstName")}
							value={requestToSpeak.firstName}
							onChange={(e) => handleChange(e, "firstName")}
							onBlur={(e) => handleBlur(e, "firstName")}
							helperText={errors.firstName}
							error={!!errors.firstName}
							noDefaultClassName
							fullWidth
							autoComplete="given-name"
							size="small"
							required={true}
							ariaRequired={true}
							disabled={requestToSpeak.status === 2}
							data-cy="first-name"
						/>
					</div>
					<div>
						<OutlinedInput
							id="last-name"
							label={t("publicSubmission.lastName")}
							value={requestToSpeak.lastName}
							onChange={(e) => handleChange(e, "lastName")}
							onBlur={(e) => handleBlur(e, "lastName")}
							helperText={errors.lastName}
							error={!!errors.lastName}
							noDefaultClassName
							fullWidth
							size="small"
							autoComplete="family-name"
							required={true}
							ariaRequired={true}
							disabled={requestToSpeak.status === 2}
							data-cy="last-name"
						/>
					</div>
					<div>
						<OutlinedInput
							id="email-address"
							label={t("publicSubmission.emailAddress")}
							value={requestToSpeak.emailAddress}
							onChange={(e) => handleChange(e, "emailAddress")}
							onBlur={(e) => handleBlur(e, "emailAddress")}
							helperText={errors.emailAddress}
							error={!!errors.emailAddress}
							noDefaultClassName
							fullWidth
							size="small"
							autoComplete="email"
							required={true}
							ariaRequired={true}
							disabled={requestToSpeak.status === 2}
							data-cy="email-address"
						/>
					</div>
					<div>
						<OutlinedInput
							id="phone-number"
							label={t("publicSubmission.phoneNumber")}
							value={requestToSpeak.phoneNumber}
							onChange={(e) => handleChange(e, "phoneNumber")}
							noDefaultClassName
							fullWidth
							size="small"
							autoComplete="tel"
							disabled={requestToSpeak.status === 2}
							data-cy="phone-number"
						/>
					</div>
				</div>
				{showMeetingAndSections && (
					<div className={classes.columns}>{getMeetingDropdown((e) => handleChange(e, "meetingId", false, true), true)}</div>
				)}
				<div className={classes.inputLabelSpacing}>
					<OutlinedInput
						id="topic"
						className={classes.topic}
						label={t("publicSubmission.topic")}
						externalLabel
						labelSize="large"
						value={requestToSpeak.topic}
						onChange={(e) => handleChange(e, "topic")}
						onBlur={(e) => handleBlur(e, "topic")}
						helperText={errors.topic}
						error={!!errors.topic}
						noDefaultClassName
						fullWidth
						multiline
						role={ROLE_HEADING}
						ariaLevel="2"
						size="small"
						required={true}
						ariaRequired={true}
						disabled={requestToSpeak.status === 2}
						data-cy="topic"
					/>
				</div>
				{showMeetings && (
					<>
						<div className={classes.columns}>
							{getMeetingDropdown((e) => handleChange(e, "meetingId"), true, t("publicSubmission.meetingInformation"))}
						</div>
					</>
				)}
				{showCodeOfConduct && requestToSpeak.status !== 2 && (
					<div className={classes.columns}>
						<div>
							<div className={clsx(classes.inputLabelSpacing, classes.inputHeading)}>
								<InputLabel
									id="code-of-conduct"
									size="large"
									label={t("publicSubmission.codeOfConduct")}
									role={ROLE_HEADING}
									ariaLevel="2"
									bottomSpacing
								/>
							</div>
							<div className={classes.codeOfConduct}>
								{codeOfConduct.codeOfConductSummary}{" "}
								<span
									className={classes.link}
									role="button"
									tabIndex="0"
									onClick={openCodeOfConduct}
									onKeyDown={openCodeOfConduct}
									data-cy="open-code-of-conduct"
								>
									{t("publicSubmission.buttons.openCodeOfConduct")}
								</span>
							</div>
							<FormControlLabel
								id="code-of-conduct"
								control={
									<Checkbox
										color="primary"
										icon={<Icon name="unchecked" color={primaryColor[0]} />}
										checkedIcon={<Icon name="checked" color={primaryColor[0]} />}
										onChange={(e) => handleChange(e, "accept", true)}
										inputProps={{ "aria-required": "true" }}
										data-cy="code-of-conduct"
									/>
								}
								label={t("publicSubmission.iWillAbideByTheCodeOfConduct")}
								classes={{
									label: checkboxClasses.label,
								}}
							/>
						</div>
					</div>
				)}
			</div>
		</>
	);
};

export default RequestToSpeakForm;
