import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import makeStyles from "@mui/styles/makeStyles";

import { grayColor, focusColor } from "atlas/assets/jss/shared";
import AccessibleIconButton from "atlas/components/Buttons/AccessibleIconButton";
import Icon from "atlas/components/Icon/Icon";
import { formatDate } from "utils/date";
import getPathType, { generatePathLink } from "views/Documents/utils/pathType";
import { generatePathLinkForPolicy } from "../../Documents/utils/pathType";

const defaultFont = {
	fontSize: "11px",
	fontWeight: "400",
	lineHeight: "16px",
	letterSpacing: "1px",
};

const useStyles = makeStyles({
	changSet: {
		display: "flex",
		alignItems: "flex-start",
		padding: "4px 4px 4px 16px",
		borderBottom: `solid 1px ${grayColor[4]}`,
	},
	changeSetDetails: {
		...defaultFont,
		flexGrow: "1",
		"& div:focus-visible, & span:focus-visible, & a:focus-visible": {
			outline: `solid 2px ${focusColor}`,
			borderRadius: "4px",
		},
	},
	updated: {
		display: "flex",
		alignItems: "center",
		fontWeight: "600",
		cursor: "pointer",
		"& > *:not(:first-child)": {
			marginLeft: "4px",
		},
		"&:focus": {
			backgroundColor: "rgba(0, 0, 0, 0.04)",
		},
	},
	fieldChange: {
		marginLeft: "16px",
		"& a": {
			textDecoration: "none",
		},
		"& a:hover": {
			textDecoration: "underline",
		},
	},
	overflow: {
		margin: "-4px 0",
	},
});

const FIELD_NAME = "iCompass.TextBox.DocumentTitle";
const FIELD_CODE = "System.CodeNumber";
const FIELD_ADOPTED_DATE = "System.AdoptedDate";
const FIELD_REVISED_DATE = "System.RevisedDate";
const FIELD_RESCINDED_DATE = "System.RescindedDate";
const FIELD_EXTENSION = "System.FileFormat";
const FIELD_FILE_SIZE = "System.FileSize";
const FIELD_HTML = "iCompass.SmartTextBox.Html";
const FIELD_ORIGINAL_URL = "iCompass.TextBox.OriginalFileUrl";
const CHANGE_TYPE_FILE = 1;
const CHANGE_TYPE_HTML = 2;

const PolicyDetailsHistoryEntry = (props) => {
	const { document, changeSet, showRestore, toggleFieldChanges, handleToggleMenu } = props;
	const { t } = useTranslation("documents");
	const navigate = useNavigate();
	const classes = useStyles();
	const rootFolder = getPathType(document?.parentFolderType);

	const addFieldChange = (changes, referenceName) => {
		if (changeSet) {
			const fieldChange = changeSet.fieldChanges.find((change) => change.referenceName === referenceName);
			if (fieldChange) {
				if (referenceName === FIELD_NAME) {
					fieldChange.name = t("policy.labelText.name");
				} else if (referenceName === FIELD_CODE) {
					fieldChange.name = t("policy.labelText.code");
				} else if (referenceName === FIELD_ADOPTED_DATE) {
					fieldChange.name = t("policyTimeline.labels.adoptionDate");
				} else if (referenceName === FIELD_REVISED_DATE) {
					fieldChange.name = t("policyTimeline.labels.revisedDate");
				} else if (referenceName === FIELD_RESCINDED_DATE) {
					fieldChange.name = t("policyTimeline.labels.rescindedDate");
				} else if (referenceName === FIELD_FILE_SIZE) {
					fieldChange.name = t("history.fileSize");
				} else if (referenceName === FIELD_EXTENSION) {
					fieldChange.name = t("history.format");
				}

				changes.push(fieldChange);
			}
		}
	};

	const addLocationChange = (changes) => {
		if (changeSet) {
			const locationChange = changeSet.relationshipChanges.find((change) => !!change.parents);
			if (locationChange) {
				changes.push(locationChange);
			}
		}
	};

	const handleKeyDown = (e) => {
		if (e.key === " " || e.key === "Enter") {
			toggleFieldChanges(changeSet);
		}
		if (e.key !== "Tab" && e.key !== "Shift") {
			e.preventDefault();
		}
	};

	const openPage = (e, pageLink) => {
		e.preventDefault();

		navigate(pageLink);
	};

	// Only show specific field changes
	const historyChanges = [];
	addFieldChange(historyChanges, FIELD_NAME);
	addFieldChange(historyChanges, FIELD_CODE);
	addFieldChange(historyChanges, FIELD_ADOPTED_DATE);
	addFieldChange(historyChanges, FIELD_REVISED_DATE);
	addFieldChange(historyChanges, FIELD_RESCINDED_DATE);
	addFieldChange(historyChanges, FIELD_EXTENSION);
	addFieldChange(historyChanges, FIELD_FILE_SIZE);
	addFieldChange(historyChanges, FIELD_HTML);
	addFieldChange(historyChanges, FIELD_ORIGINAL_URL);
	addLocationChange(historyChanges);

	return changeSet ? (
		<div className={classes.changSet}>
			<div className={classes.changeSetDetails}>
				<div tabIndex={0}>
					{formatDate(changeSet.dateChanged, changeSet.dateChanged, undefined, t("app:at"), "", "", true, true, false)}
				</div>
				<div tabIndex={0}>{changeSet.changedBy}</div>
				{historyChanges.length > 0 && (
					<div>
						<div
							className={classes.updated}
							tabIndex="0"
							onClick={() => {
								toggleFieldChanges(changeSet);
							}}
							onKeyDown={handleKeyDown}
						>
							<Icon name={`expand-${changeSet.expanded ? "down" : "right"}`} size="8" />
							<div>{t("detail.labels.updated")}</div>
						</div>
						{changeSet.expanded && (
							<div>
								{historyChanges.map((fieldChange) => {
									const { referenceName, type, parents } = fieldChange;
									if (referenceName) {
										let { value } = fieldChange;
										let { name } = fieldChange;

										if (referenceName == FIELD_EXTENSION) {
											value = value.toUpperCase();
										}

										return (
											<div key={fieldChange.referenceName} className={classes.fieldChange}>
												{type === CHANGE_TYPE_FILE || type === CHANGE_TYPE_HTML ? (
													<a href={`/document/${document.guid}?changeSetId=${changeSet.changeSetId}`} target="_blank">
														{value}
													</a>
												) : (
													<span tabIndex={0}>
														{name} {value}
													</span>
												)}
											</div>
										);
									} else {
										return (
											<div key="location" className={classes.fieldChange}>
												{document.path &&
													document.path.map((pathItem, index) => {
														const pathLink = generatePathLinkForPolicy(pathItem, rootFolder);

														return (
															<React.Fragment key={pathItem.guid}>
																{rootFolder ? (
																	<a href={`/home${pathLink}`} onClick={(e) => openPage(e, pathLink)}>
																		{(pathItem?.code !== null ? pathItem.code + " " : "") + pathItem.title}
																	</a>
																) : (
																	<span tabIndex={0}>{pathItem.title}</span>
																)}
																{index < document.path.length - 1 ? <span>{" > "}</span> : ""}
															</React.Fragment>
														);
													})}
											</div>
										);
									}
								})}
							</div>
						)}
					</div>
				)}
			</div>
			{showRestore && (
				<div className={classes.overflow}>
					<AccessibleIconButton
						color="inherit"
						aria-label={t("app:menu.options")}
						onClick={handleToggleMenu}
						iconName="more"
						dataCy="change-set-overflow-menu"
						tooltipText={t("app:menu.options")}
					/>
				</div>
			)}
		</div>
	) : null;
};

export default PolicyDetailsHistoryEntry;
