import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import clsx from "clsx";
import { parseISO } from "date-fns";

import { ListItem, Grid, MenuItem, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import AccessibleIconButton from "atlas/components/Buttons/AccessibleIconButton";
import ButtonWithTooltip from "atlas/components/Buttons/ButtonWithTooltip";
import NonModalMenu from "atlas/components/Menu/NonModalMenu";
import Tooltip from "atlas/components/Tooltip/Tooltip";
import { MEDIUM } from "atlas/utils/buttonSize";
import { formatDate } from "utils/date";
import { useWidthDown } from "atlas/utils/useWidth";

import { grayColor } from "atlas/assets/jss/shared";
import cardStyles from "atlas/assets/jss/components/cardStyle";

const useCardStyles = makeStyles(cardStyles);
const useStyles = makeStyles({
	spacing: {
		margin: "0 8px",
	},
	justifyEnd: {
		display: "flex",
		justifyContent: "flex-end",
		flexDirection: "row",
		alignItems: "center",
	},
	listItem: {
		borderBottom: `1px solid ${grayColor[4]}`,
	},
	container: {
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "center",
	},
	invisible: {
		visibility: "hidden",
	},
});

const RequestToSpeakItemListCard = (props) => {
	const { tab, item, getMenuOptions, handleApproveClick, handleRemoveClick } = props;
	const widthDownSm = useWidthDown("sm");
	const { t } = useTranslation("requestToSpeak");
	const navigate = useNavigate();

	const [anchor, setAnchor] = useState(null);
	const [openMenu, setOpenMenu] = useState(false);

	const { title, subTitle, buttons } = useCardStyles();
	const classes = useStyles();

	const menuOptions = getMenuOptions(item);

	const openItemDetails = (id) => {
		navigate(`/requesttospeak/${id}${tab > 0 ? `?tab=${tab}` : ""}`);
	};

	const handleToggleMenu = (e) => {
		e.stopPropagation();
		e.preventDefault();
		setAnchor(e.currentTarget);
		setOpenMenu((prevOpen) => !prevOpen);
	};

	const handleClose = () => {
		setAnchor(null);
	};

	const today = new Date();
	today.setHours(0, 0, 0, 0);

	return (
		<>
			{anchor && (
				<NonModalMenu id="itemActions" className="overflow-menu" anchorEl={anchor} keepMounted open={openMenu} onClose={handleClose}>
					{menuOptions.map((option) => (
						<Tooltip
							PopperProps={{ disablePortal: true }}
							placement="left"
							title={option.tooltip}
							key={`item-menu-option-s-${item.id}-${option.label}`}
						>
							<span>
								<MenuItem disabled={false} onClick={option.actionFunction} data-cy={`item-menu-option-${option["data-cy"]}${item.id}`}>
									{option.label}
								</MenuItem>
							</span>
						</Tooltip>
					))}
				</NonModalMenu>
			)}

			<ListItem className={classes.listItem} key={`requesttospeakitem-${item.id}`} data-cy={`requesttospeakcard${item.id}`}>
				<Grid container spacing={2} className={classes.container}>
					<Grid item xs={12} sm={9}>
						<a
							className="blockLink noStyle"
							href={`/home/requesttospeak/${item.guid}${tab > 0 ? `?tab=${tab}` : ""}`}
							onClick={(e) => {
								e.preventDefault();

								openItemDetails(item.guid);
							}}
						>
							<div className={title}>
								<span>{`${formatDate(item.dateCreated, null, null, t("app:at"), "", "", false)}`}</span> &bull;{" "}
								<span>{`${item.firstName} ${item.lastName}`}</span>
							</div>
							{!(item.changesRequested && widthDownSm) && <div className={subTitle}>{item.meetingName}</div>}
						</a>
					</Grid>
					<Grid item xs={12} sm={3}>
						{item.changesRequested && item.meetingDate && parseISO(item.meetingDate) >= today ? (
							<Typography textAlign="center" position="relative" right="10px">
								{t("awaitingRequestedChanges")}
							</Typography>
						) : (
							<div className={clsx(buttons, classes.justifyEnd)}>
								{tab === 0 && (
									<>
										{!item.changesRequested && (
											<ButtonWithTooltip
												className={classes.spacing}
												variant="outlined"
												title={t("tooltips.approve")}
												size={MEDIUM}
												onClick={() => {
													handleApproveClick(item);
												}}
												data-cy="approve"
											>
												{t("buttons.approve")}
											</ButtonWithTooltip>
										)}
										<ButtonWithTooltip
											className={classes.spacing}
											variant="outlined"
											title={t("tooltips.remove")}
											size={MEDIUM}
											onClick={() => {
												handleRemoveClick(item);
											}}
											data-cy="remove"
										>
											{t("buttons.remove")}
										</ButtonWithTooltip>
									</>
								)}
								{menuOptions && menuOptions.length > 0 && (
									<AccessibleIconButton
										className={clsx({ [classes.invisible]: item.changesRequested })}
										color="inherit"
										aria-label={t("app:menu.options")}
										onClick={handleToggleMenu}
										iconName="more"
										dataCy="requestToSpeakListItemOverflowMenu"
										tooltipText={t("app:menu.options")}
									/>
								)}
							</div>
						)}
					</Grid>
				</Grid>
			</ListItem>
		</>
	);
};

export default RequestToSpeakItemListCard;
