const getDocumentTooltip = (document) => {
	if (document.folder) {
		return "folder";
	} else if (document.agenda) {
		if (document.preview) {
			return "agendaPreview";
		} else {
			return "agendaPublished";
		}
	} else if (document.minutes) {
		if (document.preview) {
			return "minutesPreview";
		} else if (document.published) {
			return "minutesPublished";
		} else {
			return "minutesAdopted";
		}
	} else {
		const lowerExtension = document.extension.toLowerCase().replace(/\./g, "");
		switch (lowerExtension) {
			case "xls":
			case "xlsx":
				return "excel";

			case "bmp":
			case "gif":
			case "jpg":
			case "jpeg":
			case "png":
			case "tif":
			case "tiff":
				return "image";

			case "pdf":
				return "pdf";

			case "ppt":
			case "pptx":
				return "powerpoint";

			case "doc":
			case "docx":
				return "word";

			default:
				return "document";
		}
	}
};

export default (document) => `search.tooltips.${getDocumentTooltip(document)}`;
