/* eslint-disable no-plusplus */
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import makeStyles from "@mui/styles/makeStyles";
import { Typography } from "@mui/material";

import OutlinedInput from "atlas/components/FormControls/OutlinedInput";
import Icon from "atlas/components/Icon/Icon";
import GenericDialog from "atlas/components/Dialogs/GenericDialog";

import inputStyle from "atlas/assets/jss/components/inputStyle";

const useInputStyles = makeStyles(inputStyle);

const useStyles = makeStyles(() => ({
	dialog: {
		"& .MuiDialog-paper": {
			width: "480px",
			maxWidth: "100%",
		},
	},
	menuItem: {
		maxWidth: (props) => (props.selectWidth > 0 ? `${props.selectWidth}px` : undefined),
	},
	menuItemText: {
		overflow: "hidden",
		textOverflow: "ellipsis",
	},
	progressBar: {
		width: "240px",
		margin: "0 auto",
		marginTop: "24px",
	},
	hidden: {
		display: "none",
	},
}));

const RequestToSpeakRejectDialog = (props) => {
	const { item, show, handleReject, handleCancel } = props;

	const [comment, setComment] = useState(null);
	const [sending, setSending] = useState(false);

	const { t } = useTranslation("requestToSpeak");
	const classes = useStyles();

	const handleChange = (e, field) => {
		const {
			target: { value },
		} = e;
		if (field === "comment") {
			setComment(value);
		}
	};

	return (
		<GenericDialog
			className={classes.dialog}
			show={show}
			title={t("rejectDialog.title")}
			primaryAction={() => {
				setSending(true);
				handleReject(item.id, comment);
			}}
			primaryTitle={t("buttons.reject")}
			secondaryAction={handleCancel}
			secondaryTitle={t("app:buttons.cancel")}
			primaryDisabled={comment == null || comment.length === 0 || sending}
			clickAwayDisabled={sending}
			closeIcon={<Icon name="close" />}
			data-cy="reject-dialog"
			disableAutoFocus
			disableEnforceFocus
		>
			<label htmlFor="comment">
				<Typography type="h1">{t("rejectDialog.commentsToSubmitterRequired")}</Typography>
			</label>
			<OutlinedInput
				autoFocus
				noDefaultClassName
				id="comment"
				placeholder={t("rejectDialog.rejectCommentToSubmitterPlaceholder")}
				value={comment || ""}
				onChange={(e) => handleChange(e, "comment")}
				fullWidth
				size="small"
				data-cy="requestToSpeakAddComment"
				multiline
			/>
		</GenericDialog>
	);
};

export default RequestToSpeakRejectDialog;
