import React from "react";
import { useContext } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import clsx from "clsx";

import { Box, Button, Card, CardHeader, Grid } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { warningColor } from "atlas/assets/jss/shared";

import processHtml from "utils/processHtml";
import { formatDate } from "utils/date";
import telemetryAddEvent from "utils/telemetryAddEvent";
import { isPublished } from "utils/agendaStatuses";
import Accordion from "atlas/components/Accordion/Accordion";
import AccessibleIconButton from "atlas/components/Buttons/AccessibleIconButton";
import ButtonWithTooltip from "atlas/components/Buttons/ButtonWithTooltip";
import NoticeCard from "atlas/components/Cards/NoticeCard";
import List from "atlas/components/List/List";
import { LARGE } from "atlas/utils/buttonSize";
import { primaryColor } from "atlas/assets/jss/shared";
import cardStyles from "atlas/assets/jss/components/cardStyle";
import { STATUS_DEFAULT, STATUS_GOOD, STATUS_WARNING } from "atlas/assets/jss/utils/statusIndicators";
import MeetingPublishStatus from "./MeetingPublishStatus";
import { updateNotice } from "../../redux/app/actions";
import { SettingsContext } from "contexts/Settings/SettingsContext";

const useCardStyles = makeStyles(cardStyles);

const useStyles = makeStyles({
	accordion: {
		margin: "16px -16px 0 -16px",
	},
	itemList: {
		margin: "16px",
	},
	itemListLabel: {
		width: "100%",
	},
	bottomLinks: {
		paddingBottom: "0",
	},
	annotationMarker: {
		position: "absolute",
		left: "-14px",
		top: "-6px",
		width: "16px",
		height: "16px",
		color: primaryColor[4],
	},
});

const MeetingCard = (props) => {
	const { meeting, showArrow, showItems, showAgendaPublishStatus, showMinutesPublishStatus, showLinks, showWarning, showVideo } = props;
	const { t } = useTranslation("meetings");
	const navigate = useNavigate();
	const appReducer = useSelector((state) => state.appReducer);
	const { telemetryPage } = appReducer;
	const { card, title, subTitle, buttons } = useCardStyles();
	const { accordion, itemList, itemListLabel, bottomLinks } = useStyles();

	const currentTimeStamp = Math.floor(Date.now() / 1000);

	const getCardStatusColor = () => {
		let cardStatusColor = STATUS_DEFAULT;
		if (meeting.endTimeStamp <= currentTimeStamp) {
			return cardStatusColor;
		}
		if (
			(meeting.boardAgendaStatus === 6 || meeting.boardAgendaStatus === 9) &&
			(meeting.publicAgendaStatus === 6 || meeting.publicAgendaStatus === 9 || !meeting.publicAgendaStatus) && // null publicAgendaStatus means that this meeting template does not generate a public version
			!meeting.isDraftPolicyUpdated
		) {
			cardStatusColor = STATUS_GOOD;
		} else if (meeting.boardAgendaStatus === 7 || meeting.publicAgendaStatus === 7 || meeting.isDraftPolicyUpdated) {
			cardStatusColor = STATUS_WARNING;
		}
		return cardStatusColor;
	};

	const getWarningLabel = () => {
		if ((meeting.boardAgendaStatus === 7 || meeting.publicAgendaStatus === 7) && meeting.isDraftPolicyUpdated) {
			return (
				<>
					{t("warningPendingChanges")}
					<br></br>
					{t("policyDrafts")}
				</>
			);
		}
		if (meeting.boardAgendaStatus === 7 || meeting.publicAgendaStatus === 7) {
			return `${t("warningPendingChanges")}`;
		}
		if (meeting.isDraftPolicyUpdated) {
			return `${t("warningPolicyDrafts")}`;
		}
	};

	const openMeetingDetails = (id) => {
		navigate(`/meeting/${id}`);
	};

	const openAgendaEdit = () => {
		navigate(`/meeting/detailsV2/${meeting.id}`);
	};

	const openLiveMeeting = () => {
		navigate(`/meeting/liveV2/${meeting.id}`);
	};

	const getMeetingLinkClickHandler = (id) => {
		return (e) => {
			e.preventDefault();

			telemetryAddEvent(`${telemetryPage} - Navigate to meeting details`);

			openMeetingDetails(id);
		};
	};

	const meetingDate = formatDate(null, meeting.startTime, meeting.endTime, t("app:at"), t("from"), t("to"), false);
	const cardStatusColor = getCardStatusColor();
	const arrowId = `header-details${meeting.id}`;

	return (
		<Card
			className={clsx(card, cardStatusColor, {
				[bottomLinks]: showLinks && !(showWarning && cardStatusColor === STATUS_WARNING),
			})}
			data-cy={`meeting${meeting.id}`}
		>
			<a
				className="blockLink noStyle meeting-card-link"
				href={`/home/meeting/${meeting.id}`}
				onClick={() => telemetryAddEvent(`${telemetryPage} - Navigate to meeting details`)}
			>
				{/*meeting.userAnnotations && (
					<Tooltip title={t("meetingContainsAnnotations")}>
						<SvgIcon className={annotationMarker} title>
							<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
								<path d="m0 0h24v24h-24z" fill="none" />
								<path d="m20 2h-16c-1.1 0-2 .9-2 2v18l4-4h14c1.1 0 2-.9 2-2v-12c0-1.1-.9-2-2-2z" />
							</svg>
						</SvgIcon>
					</Tooltip>
				)*/}
				<CardHeader
					onClick={getMeetingLinkClickHandler(meeting.id)}
					className={title}
					action={
						showArrow && (
							<AccessibleIconButton
								id={arrowId}
								key={arrowId}
								color="inherit"
								iconName="arrow-right"
								tooltipText={t("viewMeeting")}
								aria-label={t("viewMeeting")}
								onClick={getMeetingLinkClickHandler(meeting.id)}
								dataCy={`openAgenda${meeting.id}`}
							/>
						)
					}
					title={meeting.cleanName}
					subheader={
						<div className={subTitle} data-cy={`meetingDate${meeting.id}`}>
							{meetingDate}
						</div>
					}
					disableTypography
				/>
			</a>
			{showItems && meeting.items && meeting.items.length > 0 && (
				<Accordion
					className={accordion}
					label={t("submittedItems", { count: meeting.items.length })}
					dataCy={`submittedItems${meeting.id}`}
				>
					<List
						className={itemList}
						listItemClasses={{ labelLink: itemListLabel }}
						items={meeting.items.map((item) => ({
							key: `agenda-item-${item.guid}`,
							label: item.name,
							labelUrl: `/home/agendaitems/edit/${item.guid}`,
						}))}
					/>
				</Accordion>
			)}
			{showAgendaPublishStatus && (
				<MeetingPublishStatus
					hideTypeLabel
					meetingId={meeting.id}
					boardStatus={meeting.boardAgendaStatus}
					publicStatus={meeting.publicAgendaStatus}
					publicReleaseDay={meeting.publicAgendaReleaseDay}
					boardHtmlId={meeting.boardAgendaHtmlId}
					previousUrl="/"
					topMargin={3}
					telemetryPage={telemetryPage}
				/>
			)}
			{showMinutesPublishStatus && (
				<MeetingPublishStatus
					agenda={false}
					hideTypeLabel
					meetingId={meeting.id}
					boardStatus={meeting.boardMinutesStatus}
					publicStatus={meeting.publicMinutesStatus}
					previousUrl="/"
					topMargin={2}
					telemetryPage={telemetryPage}
				/>
			)}
			{showVideo && meeting.zoomLink && meeting.zoomLink.length && (
				<div className="margin-top-bottom-auto">
					<Button
						style={{ backgroundColor: "#fff" }}
						variant="outlined"
						color="primary"
						fullWidth
						onClick={() => {
							window.open(meeting.zoomLink);
						}}
						data-cy="joinVideo"
					>
						{t("meetings:buttons.joinVideo")}
					</Button>
				</div>
			)}
			{showLinks && (
				<Grid className={buttons} container>
					<Grid item xs={6}>
						<Box display="flex" alignItems="center" justifyContent="flex-start" height="100%">
							<ButtonWithTooltip
								id={`detail-${meeting.id}`}
								key={`detail-${meeting.id}`}
								label={t("buttons.viewDetails")}
								title={t("buttons.viewDetails")}
								onClick={getMeetingLinkClickHandler(meeting.id)}
								size={LARGE}
								dataCy="view-details"
							>
								{t("buttons.viewDetails")}
							</ButtonWithTooltip>
						</Box>
					</Grid>
					{isPublished(meeting.publicAgendaStatus) &&
					new Date(formatDate(meeting.date, null, null, "", "", "", true)) >= new Date(new Date().toDateString()) ? (
						<Grid item xs={6}>
							<Box display="flex" alignItems="center" justifyContent="flex-end" height="100%">
								<ButtonWithTooltip
									id={`live-meeting-${meeting.id}`}
									key={`live-meeting-${meeting.id}`}
									label={t("buttons.liveMeeting")}
									title={t("buttons.liveMeeting")}
									onClick={openLiveMeeting}
									size={LARGE}
									dataCy="live-meeting-agenda"
								>
									{t("buttons.liveMeeting")}
								</ButtonWithTooltip>
							</Box>
						</Grid>
					) : (
						<Grid item xs={6}>
							<Box display="flex" alignItems="center" justifyContent="flex-end" height="100%">
								<ButtonWithTooltip
									id={`edit-${meeting.id}`}
									key={`edit-${meeting.id}`}
									label={t("buttons.editAgenda")}
									title={t("buttons.editAgenda")}
									onClick={openAgendaEdit}
									size={LARGE}
									dataCy="edit-agenda"
								>
									{t("buttons.editAgenda")}
								</ButtonWithTooltip>
							</Box>
						</Grid>
					)}
				</Grid>
			)}
			{showWarning && cardStatusColor === STATUS_WARNING && (
				<NoticeCard
					updateNotice={updateNotice}
					thickStatus
					icon="status-alert"
					label={getWarningLabel()}
					processHtml={processHtml}
					iconColor={warningColor}
				/>
			)}
		</Card>
	);
};

export default MeetingCard;
