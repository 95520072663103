import React from 'react';
import PropTypes from 'prop-types';

import { Badge, Box, IconButton } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import Icon from '../Icon/Icon';
import HeaderIconButton from './HeaderIconButton';
import Tooltip from '../Tooltip/Tooltip';
import {
	TOP_START,
	TOP,
	TOP_END,
	RIGHT_START,
	RIGHT,
	RIGHT_END,
	BOTTOM_END,
	BOTTOM,
	BOTTOM_START,
	LEFT_END,
	LEFT,
	LEFT_START,
} from '../../utils/tooltipPlacement';
import iconButtonRippleStyle from '../../assets/jss/components/iconButtonRippleStyle';
import { whiteColor, blackColor, grayColor } from '../../assets/jss/shared';

const useRippleStyle = makeStyles(iconButtonRippleStyle);

const useStyles = makeStyles({
	label: {
		'& span.button-label': {
			// eslint-disable-next-line no-nested-ternary
			color: (props) => (props.isHeader ? (props.disabled ? grayColor[1] : whiteColor) : props.disabled ? grayColor[3] : blackColor[0]),
		},
	},
});
const useSmallIconButtonStyle = makeStyles(() => ({
	root: {
		padding: '3px',
	},
}));

const AccessibleIconButton = (props) => {
	const {
		id,
		className,
		placement,
		iconName,
		iconSize,
		label,
		tooltipText,
		ariaLabel,
		onClick,
		dataCy,
		isHeader,
		isPageHeader,
		badgeProps,
		disabled,
		disableRipple,
		smallIcon,
		iconColor,
		disableTooltipFocusListener = true,
		icon,
		...otherProps
	} = props;
	// eslint-disable-next-line no-nested-ternary
	const iColor = isHeader ? (disabled ? grayColor[1] : iconColor || whiteColor) : disabled ? grayColor[3] : iconColor || blackColor[0];
	const classes = useStyles({ isHeader, disabled });
	const useIcon = icon || (iconName && iconName.length > 0);
	const CurrentIcon = () => <Icon name={iconName} color={iColor} size={iconSize} />;

	const rippleClasses = useRippleStyle();
	const iconButtonClasses = smallIcon ? useSmallIconButtonStyle() : null;

	const getIcon = () => icon || <CurrentIcon />;

	const Content = otherProps.displayIconOnRight ? (
		<>
			{label && label.length > 0 && (
				<Box className={classes.label} ml={useIcon ? 1 : 0}>
					<span className="button-label" style={{ marginRight: '10px' }}>
						{label}
					</span>
				</Box>
			)}
			{useIcon &&
				(badgeProps && badgeProps.display ? (
					<Badge color="secondary" variant="dot" overlap="rectangular" data-cy={badgeProps.dataCy}>
						{getIcon()}
					</Badge>
				) : (
					getIcon()
				))}
		</>
	) : (
		<>
			{useIcon &&
				(badgeProps && badgeProps.display ? (
					<Badge color="secondary" variant="dot" overlap="rectangular" data-cy={badgeProps.dataCy}>
						{getIcon()}
					</Badge>
				) : (
					getIcon()
				))}
			{label && label.length > 0 && (
				<Box className={classes.label} ml={useIcon ? 1 : 0}>
					<span className="button-label">{label}</span>
				</Box>
			)}
		</>
	);
	const Button = isHeader ? (
		<HeaderIconButton
			id={id}
			className={className}
			color="inherit"
			aria-label={ariaLabel || props['aria-label'] || tooltipText}
			onClick={onClick}
			dataCy={dataCy}
			isPageHeader={isPageHeader}
			disabled={disabled}
			disableRipple={disableRipple}
			classes={iconButtonClasses}
			{...otherProps}
		>
			{Content}
		</HeaderIconButton>
	) : (
		<IconButton
            id={id}
            className={className}
            color="inherit"
            aria-label={ariaLabel || props['aria-label'] || tooltipText}
            onClick={onClick}
            data-cy={dataCy}
            TouchRippleProps={{ classes: rippleClasses }}
            disabled={disabled}
            disableRipple={disableRipple}
            classes={iconButtonClasses}
            {...otherProps}
            size="large">
			{Content}
		</IconButton>
	);

	const tooltipTitle =
		tooltipText && tooltipText != label ? tooltipText : label && label.length > 0 ? '' : ariaLabel || props['aria-label'];

	return !disabled && tooltipTitle ? (
		<Tooltip
			disableFocusListener={disableTooltipFocusListener}
			placement={placement}
			title={tooltipTitle}
			PopperProps={otherProps.popperProps || undefined}
		>
			<span>{Button}</span>
		</Tooltip>
	) : (
		Button
	);
};

AccessibleIconButton.propTypes = {
	id: PropTypes.string,
	className: PropTypes.string,
	placement: PropTypes.oneOf([
		TOP_START,
		TOP,
		TOP_END,
		RIGHT_START,
		RIGHT,
		RIGHT_END,
		BOTTOM_END,
		BOTTOM,
		BOTTOM_START,
		LEFT_END,
		LEFT,
		LEFT_START,
	]),
	iconName: PropTypes.string,
	iconSize: PropTypes.string,
	label: PropTypes.string,
	tooltipText: PropTypes.string,
	ariaLabel: PropTypes.string,
	dataCy: PropTypes.string,
	isHeader: PropTypes.bool,
	isPageHeader: PropTypes.bool,
	badgeProps: PropTypes.object,
	disabled: PropTypes.bool,
	disableRipple: PropTypes.bool,
	smallIcon: PropTypes.bool,
};

AccessibleIconButton.defaultProps = {
	id: undefined,
	className: undefined,
	placement: BOTTOM_START,
	iconName: undefined,
	iconSize: undefined,
	label: undefined,
	tooltipText: undefined,
	ariaLabel: undefined,
	dataCy: undefined,
	isHeader: false,
	isPageHeader: false,
	badgeProps: {},
	disabled: false,
	disableRipple: false,
	smallIcon: false,
};

export default AccessibleIconButton;
