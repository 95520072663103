import React from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";

import makeStyles from "@mui/styles/makeStyles";

import ApprovalProgress from "components/Approval/ApprovalProgress";
import { WORKFLOW_STATUS_PENDING, WORKFLOW_STATUS_DENIED } from "utils/workflowStatuses";
import { APPROVAL_STATUS_PENDING } from "utils/approvalStatuses";
import { formatDate } from "utils/date";
import { updatePageConfigs } from "redux/app/actions";

const textFont = {
	fontSize: "12px",
	lineHeight: "15px",
};
const useStyles = makeStyles({
	pendingApproval: {
		...textFont,
		padding: "2px 13px",
	},
	approvalProgress: {
		padding: "0 13px",
	},
	created: {
		...textFont,
		padding: "4px 13px",
	},
});

const ContentAgendaItemSubmissionDetails = (props) => {
	const { item, item: { workflow } = {} } = props;
	const { t } = useTranslation("agendaItems");
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();
	const classes = useStyles();

	const viewItem = (e) => {
		e.preventDefault();
		e.stopPropagation();

		dispatch(
			updatePageConfigs({
				preferedBack: { url: location.pathname.replace("/home/", "/") + location.search },
			}),
		);

		navigate(`/agendaitems/edit/${item.guid}`);
	};

	const getPendingApprovers = () =>
		workflow.blocks
			.reduce(
				(blockList, block) =>
					block.users
						.filter((user) => user.status === WORKFLOW_STATUS_PENDING)
						.reduce((list, user) => {
							list.push(user.name);

							return list;
						}, blockList),
				[],
			)
			.join(", ");

	const getRejectingApprover = () =>
		workflow.blocks
			.reduce(
				(blockList, block) =>
					block.users
						.filter((user) => user.status === WORKFLOW_STATUS_DENIED)
						.reduce((list, user) => {
							list.push(user.name);

							return list;
						}, blockList),
				[],
			)
			.join(", ");

	return (
		item && (
			<>
				{workflow && (
					<>
						<div className={classes.pendingApproval} data-cy="submission-details">
							{item.approvalStatus === APPROVAL_STATUS_PENDING
								? t("submissionDetails.awaitingApprovalFrom", {
										pendingApprovers: getPendingApprovers(),
								  })
								: t("submissionDetails.approvalRejectedBy", {
										rejectingApprover: getRejectingApprover(),
								  })}
						</div>
						<div className={classes.approvalProgress}>
							<ApprovalProgress workflow={workflow} fullWidth />
						</div>
					</>
				)}
				<div className={classes.created} data-cy="submitted-by">
					{item.added && (
						<a href={`/home/agendaitems/edit/${item.guid}`} onClick={viewItem}>
							{t("submissionDetails.itemSubmittedByStart")}
						</a>
					)}
					{t(`submissionDetails.itemSubmittedBy${item.added ? "End" : ""}`, {
						createdBy: item.createdBy,
						dateCreated: formatDate(item.dateCreated, null, null, t("app:at"), t("from"), t("to"), true, false, false),
					})}
				</div>
			</>
		)
	);
};

export default ContentAgendaItemSubmissionDetails;
