import { defaultFont, primaryColor, whiteColor, blackColor, grayColor ,disableColor ,disableBackgroundColor } from '../shared';

const typographyStyle = {
	body: {
		...defaultFont,
		fontWeight: '300',
		lineHeight: '1.25',
		fontSize: '16px',
	},
	billboard: {
		...defaultFont,
		fontWeight: '300',
		fontSize: '64px',
		lineHeight: '80px',
		letterSpacing: '0px',
	},
	displayTitle: {
		...defaultFont,
		fontWeight: '300',
		fontSize: '40px',
		lineHeight: '48px',
		letterSpacing: '0px',
	},
	largeTitle: {
		...defaultFont,
		fontWeight: '300',
		fontSize: '28px',
		lineHeight: '32px',
		letterSpacing: '0px',
	},
	mediumTitle: {
		...defaultFont,
		fontWeight: '300',
		fontSize: '22px',
		lineHeight: '26px',
		letterSpacing: '0px',
	},
	smallTitle: {
		...defaultFont,
		fontWeight: '300',
		fontSize: '18px',
		lineHeight: '24px',
		letterSpacing: '0px',
	},
	textBody: {
		...defaultFont,
		fontWeight: '300',
		fontSize: '16px',
		lineHeight: '20px',
		letterSpacing: '0px',
	},
	mediumText: {
		...defaultFont,
		fontWeight: '300',
		fontSize: '14px',
		lineHeight: '18px',
		letterSpacing: '0px',
	},
	xSmallText: {
		...defaultFont,
		fontWeight: '300',
		fontSize: '12px',
		lineHeight: '14px',
		letterSpacing: '0px',
	},
	largeLabel: {
		...defaultFont,
		fontSize: '16px',
		lineHeight: '20px',
		letterSpacing: '1px',
	},
	smallLabel: {
		...defaultFont,
		fontWeight: '300',
		fontSize: '12px',
		lineHeight: '16px',
		letterSpacing: '1px',
	},
	title: {
		...defaultFont,
		fontWeight: '300',
		fontSize: '18px',
		lineHeight: '1.33',
	},
	// These are actually being used by Community
	appBar: {
		...defaultFont,
		fontWeight: 'normal',
		fontSize: '18px',
		lineHeight: '1.33',
	},
	navigation: {
		...defaultFont,
		fontSize: '18px',
		lineHeight: '1.33',
		fontWeight: 'normal',
		color: blackColor[1],
		whiteSpace: 'nowrap',
	},
	menu: {
		...defaultFont,
		fontSize: '16px',
		lineHeight: '1.25',
		fontWeight: 'normal',
		letterSpacing: 'normal',
		color: blackColor[1],
		whiteSpace: 'nowrap',
	},
	pageTitle: {
		...defaultFont,
		fontSize: '32px',
		lineHeight: '1.2',
		fontWeight: 'normal',
		letterSpacing: 'normal',
		color: whiteColor,
	},
	pageTitleSmall: {
		...defaultFont,
		fontSize: '22px',
		lineHeight: '1.18',
		fontWeight: '600',
		letterSpacing: 'normal',
		color: whiteColor,
	},
	pageTitleXSmall: {
		...defaultFont,
		fontSize: '16px',
		lineHeight: '1.33',
		fontWeight: '400',
		letterSpacing: 'normal',
		color: whiteColor,
	},
	button: {
		...defaultFont,
		fontSize: '12px',
		lineHeight: '1.33',
		fontWeight: 'bold',
		letterSpacing: '1px',
		textAlign: 'center',
		textTransform: 'uppercase',
		whiteSpace: 'nowrap',
		color: whiteColor,
		
	},
	disableButon:{
		...defaultFont,
		fontSize: '12px',
		lineHeight: '1.33',
		fontWeight: 'bold',
		letterSpacing: '1px',
		textAlign: 'center',
		textTransform: 'uppercase',
		whiteSpace: 'nowrap',
		'&.Mui-disabled': {
			backgroundColor : disableBackgroundColor,
			color: disableColor,
		},
	},
	largeButton: {
		...defaultFont,
		fontSize: '12px',
		lineHeight: '1.33',
		fontWeight: 'bold',
		letterSpacing: '1px',
		textAlign: 'center',
		textTransform: 'uppercase',
		whiteSpace: 'nowrap',
		color: whiteColor,
	},
	tab: {
		...defaultFont,
		fontSize: '16px',
		lineHeight: '1.25',
		fontWeight: '600',
		letterSpacing: 'normal',
		color: whiteColor,
		textTransform: 'none',
		opacity: '1',
	},
	toolbar: {
		...defaultFont,
		fontSize: '12px',
		lineHeight: '1.33',
		fontWeight: 'bold',
		letterSpacing: '1px',
		color: whiteColor,
		textTransform: 'uppercase',
	},
	toolbarLabel: {
		...defaultFont,
		fontSize: '14px',
		lineHeight: '1.29',
		fontWeight: 'normal',
		letterSpacing: 'normal',
		color: whiteColor,
	},
	status: {
		...defaultFont,
		fontSize: '12px',
		lineHeight: '1.33',
		fontWeight: 'bold',
		letterSpacing: '1px',
		textTransform: 'uppercase',
	},
	accordian: {
		...defaultFont,
		fontSize: '18px',
		lineHeight: '1.33',
		fontWeight: 'normal',
		letterSpacing: 'normal',
		color: blackColor[1],
	},
	drawerTitle: {
		...defaultFont,
		fontSize: '22px',
		lineHeight: '1.18',
		fontWeight: 'normal',
		letterSpacing: 'normal',
	},
	columnHeaderDiagonal: {
		...defaultFont,
		fontSize: '16px',
		lineHeight: 'normal',
		fontWeight: 'normal',
		letterSpacing: 'normal',
		transform: 'rotate(-45deg)',
	},
	fieldHeading: {
		...defaultFont,
		fontSize: '18px',
		lineHeight: '1.33',
		fontWeight: '600',
		letterSpacing: 'normal',
		color: blackColor[2],
	},
	fieldLabel: {
		...defaultFont,
		fontSize: '13px',
		lineHeight: '1.23',
		fontWeight: 'normal',
		letterSpacing: 'normal',
		color: blackColor[1],
	},
	boldFieldLabel: {
		...defaultFont,
		fontSize: '15px',
		lineHeight: '1.23',
		fontWeight: '600',
		letterSpacing: 'normal',
		color: blackColor[1],
	},
	fieldLabelLarge: {
		...defaultFont,
		fontSize: '16px',
		lineHeight: '1.25',
		fontWeight: '600',
		letterSpacing: '1px',
		color: blackColor[2],
		textTransform: 'uppercase',
	},
	fieldLabelLargeSentence: {
		...defaultFont,
		fontSize: '16px',
		lineHeight: '1.25',
		fontWeight: '600',
		color: blackColor[0],
	},
	link: {
		textDecoration: 'none',
		color: primaryColor[1],
		'&:hover': {
			textDecoration: 'none',
		},
	},
	h1: {
		...defaultFont,
		fontSize: '22px',
		lineHeight: '1.18',
		fontWeight: '600',
		letterSpacing: 'normal',
		color: blackColor[1],
		margin: '0',
	},
	smallH1: {
		...defaultFont,
		fontSize: '18px',
		lineHeight: '1.33',
		fontWeight: 'normal',
		letterSpacing: 'normal',
		color: blackColor[0],
		margin: '0',
	},
	text: {
		...defaultFont,
		lineHeight: '1.25',
		fontSize: '16px',
		fontWeight: 'normal',
		color: blackColor[1],
	},
	smallText: {
		...defaultFont,
		lineHeight: '1.23',
		fontSize: '13px',
		fontWeight: 'normal',
		color: blackColor[1],
	},
	ellipsis: {
		overflow: 'hidden',
		whiteSpace: 'noWrap',
		textOverflow: 'ellipsis',
	},

	primary: {
		color: primaryColor[0],
	},
	primaryAction: {
		color: primaryColor[3],
	},
	black: {
		color: blackColor[1],
	},
	white: {
		color: whiteColor,
	},
	gray: {
		color: grayColor[0],
	},
	blue: {
		color: primaryColor[1],
	},
	lightBlue: {
		color: primaryColor[2],
	},
	semiBold: {
		fontWeight: '600',
	},
	bold: {
		fontWeight: '700',
	},
	fullWidth: {
		width: '100%',
	},
};

export default typographyStyle;
