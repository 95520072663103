import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { withTranslation } from "react-i18next";
import clsx from "clsx";
import { isIOS } from "react-device-detect";

import makeStyles from "@mui/styles/makeStyles";

import { differenceInSeconds } from "date-fns";

import { CLOSED_LEFT_NAV_WIDTH, OPEN_LEFT_NAV_WIDTH, LEFT_NAV_BORDER, BOTTOM_NOTICE_HEIGHT } from "assets/jss/utils/componentSizes";
import { defaultFont, whiteColor, successColor, errorColor, backgroundColor } from "atlas/assets/jss/shared";
import { useWidthDown } from "atlas/utils/useWidth";
import formatTimer from "../utils/formatTimer";

const fontStyles = {
	...defaultFont,
	fontSize: (props) => (props.presentation ? "72px" : "18px"),
	lineHeight: (props) => (props.presentation ? "1" : "1.33"),
	fontWeight: (props) => (props.presentation ? "bold" : "600"),
	color: whiteColor,
};

const getBottomPosition = (props) => {
	let bottom = 16;
	if (props.bottomNotice) {
		bottom += BOTTOM_NOTICE_HEIGHT;
	}

	return bottom;
};

const getLeftPosition = (props) => {
	let left = 16;
	if (props.closedLeftNav) {
		left += CLOSED_LEFT_NAV_WIDTH + LEFT_NAV_BORDER;
	} else if (props.openLeftNav) {
		left += OPEN_LEFT_NAV_WIDTH + LEFT_NAV_BORDER;
	}

	return left;
};

const useStyles = makeStyles((theme) => ({
	container: {
		...fontStyles,
		position: "fixed",
		bottom: (props) => (props.presentation ? "40px" : `${getBottomPosition(props)}px`),
		left: (props) => (props.presentation ? "50%" : `${getLeftPosition(props)}px`),
		transform: (props) => (props.presentation ? "translateX(-50%)" : undefined),
		width: (props) => (props.presentation ? "700px" : "288px"),
		maxWidth: (props) => (props.presentation ? "calc(100% - 80px)" : undefined),
		maxHeight: (props) => (props.presentation ? "216px" : undefined),
		display: (props) => (props.presentation ? "flex" : undefined),
		alignItems: "center",
		boxSizing: "border-box",
		borderRadius: "6px",
		padding: (props) => (props.presentation ? "48px 20px 20px 20px" : "8px 16px"),
		transitionProperty: () => (!isIOS ? "max-height, max-width, bottom, left, border-radius" : undefined), // iOS seems to have issues with transitions
		transitionDuration: () => (!isIOS ? "0.5s" : undefined),
		transitionTimingFunction: () => (!isIOS ? "ease-in" : undefined),
		zIndex: "1201",
	},
	containerFull: {
		height: "100% !important",
		width: "100% !important",
		maxHeight: "100% !important",
		maxWidth: "100% !important",
		bottom: "0 !important",
		left: "0 !important",
		transform: "none !important",
		borderRadius: "0 !important",
	},
	message: {
		position: (props) => (props.presentation ? "absolute" : undefined),
		width: (props) => (props.presentation ? "100%" : undefined),
		top: (props) => (props.presentation ? "25%" : undefined),
		left: (props) => (props.presentation ? "0" : undefined),
		maxHeight: (props) => (props.presentation ? undefined : "0px"),
		overflow: (props) => (props.presentation ? undefined : "hidden"),
		transition: (props) => (props.presentation || isIOS ? undefined : "max-height 0.5s ease-in"),
		[theme.breakpoints.down("md")]: {
			top: (props) => (props.presentation ? "40px" : undefined),
		},
	},
	messageElapsed: {
		maxHeight: "24px !important",
	},
	messageOver: {
		maxHeight: "70px !important",
	},
	containerSmall: {
		width: "auto",
		right: "16px",
	},
	containerGood: {
		backgroundColor: successColor,
	},
	containerError: {
		backgroundColor: errorColor,
	},
	timeElapsed: {
		textAlign: "center",
	},
	timeOver: {
		textAlign: "center",
		textTransform: "uppercase",
		marginTop: "20px",
	},
	timer: {
		display: (props) => (props.presentation ? "block" : "inline-flex"),
		alignItems: (props) => (props.presentation ? undefined : "center"),
		height: (props) => (props.presentation ? "auto" : "14px"),
		width: "100%",
	},
	display: {
		textAlign: (props) => (props.presentation ? "center" : undefined),
		marginBottom: (props) => (props.presentation ? "48px" : undefined),
		marginRight: (props) => (props.presentation ? undefined : "16px"),
	},
	progressBar: {
		flexGrow: "1",
		borderRadius: "6px",
		backgroundColor: backgroundColor[2],
		height: (props) => (props.presentation ? "48px" : "14px"),
		maxWidth: (props) => (props.presentation ? "660px" : undefined),
		margin: (props) => (props.presentation ? "0 auto" : undefined),
		position: "relative",
	},
	progress: {
		position: "absolute",
		top: "0",
		borderRadius: "6px",
		backgroundColor: backgroundColor[1],
		display: "inline-block",
		height: "100%",
	},
	progressNormal: {
		left: "0",
	},
	progressOvertime: {
		right: "0",
	},
}));

const ViewerTimer = (props) => {
	const getTimer = (remaining) => ({
		minutes: remaining >= 0 ? Math.floor(remaining / 60) : Math.ceil(remaining / 60),
		seconds: remaining % 60,
	});

	const { t, timer, presentation } = props;
	const widthDownMd = useWidthDown("md");
	const { totalSeconds, remainingSeconds, active } = timer;
	const [activeTimer, setActiveTimer] = useState(getTimer(remainingSeconds));
	const appReducer = useSelector((state) => state.appReducer);
	const { showLeftNav, openLeftNav, bottomNotice } = appReducer;
	const classes = useStyles({
		presentation,
		closedLeftNav: showLeftNav && !openLeftNav,
		openLeftNav: showLeftNav && openLeftNav,
		bottomNotice: bottomNotice.label && bottomNotice.label.length > 0,
	});
	const timerHandle = useRef();
	const startTime = useRef();

	const calculateRemaining = (elapsed) => getTimer(remainingSeconds - elapsed);

	const updateTimer = () => {
		timerHandle.current = setTimeout(() => {
			const elapsed = differenceInSeconds(new Date(), startTime.current);
			const updatedTimer = calculateRemaining(elapsed);

			setActiveTimer(updatedTimer);

			updateTimer();
		}, 1000);
	};

	const cancelTimer = () => {
		if (timerHandle.current > 0) {
			clearTimeout(timerHandle.current);
			timerHandle.current = 0;
			startTime.current = null;
		}
	};

	const startTimer = () => {
		startTime.current = new Date();

		updateTimer();
	};

	const getProgressWidth = (total, remaining) => {
		if (remaining >= 0) {
			return `${((total - remaining) / total) * 100}%`;
		}

		return `${(1 / 2 ** (-remaining / total)) * 100}%`;
	};

	useEffect(() => {
		if (active) {
			startTimer();
		}

		return cancelTimer;
	}, [timer, presentation]);

	const liveRemainingSeconds = activeTimer.minutes * 60 + activeTimer.seconds;
	const warningTimeRemaining = 30;
	const fullScreenTimeRemaining = 10;

	return (
		<div
			className={clsx(classes.container, {
				[classes.containerFull]: presentation && liveRemainingSeconds <= fullScreenTimeRemaining,
				[classes.containerSmall]: !presentation && widthDownMd,
				[classes.containerGood]: liveRemainingSeconds > warningTimeRemaining,
				[classes.containerError]: liveRemainingSeconds <= warningTimeRemaining,
			})}
		>
			<div
				className={clsx(classes.message, {
					[classes.messageElapsed]: !presentation && liveRemainingSeconds === 0,
					[classes.messageOver]: !presentation && liveRemainingSeconds < 0,
				})}
			>
				{liveRemainingSeconds <= 0 && <div className={classes.timeElapsed}>{t("timeHasElapsed")}</div>}
				{!presentation && liveRemainingSeconds < 0 && <div className={classes.timeOver}>{t("timeOver")}</div>}
			</div>
			<div className={classes.timer}>
				<div className={classes.display} data-cy="timer-display">
					{presentation && liveRemainingSeconds < 0 && `${t("timeOver")} `}
					{formatTimer(activeTimer)}
				</div>
				<div className={classes.progressBar} data-cy="timer-progress-bar">
					<div
						className={clsx(classes.progress, {
							[classes.progressNormal]: liveRemainingSeconds >= 0,
							[classes.progressOvertime]: liveRemainingSeconds < 0,
						})}
						style={{ width: getProgressWidth(totalSeconds, liveRemainingSeconds) }}
						data-cy="timer-progress"
					/>
				</div>
			</div>
		</div>
	);
};

ViewerTimer.propTypes = {
	timer: PropTypes.object.isRequired,
	presentation: PropTypes.bool,
};

ViewerTimer.defaultProps = {
	presentation: false,
};

export default withTranslation("meetings")(ViewerTimer);
