import React, { useState, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";

import makeStyles from "@mui/styles/makeStyles";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";

import OutlinedInput from "atlas/components/FormControls/OutlinedInput";
import GenericDialog from "atlas/components/Dialogs/GenericDialog";
import { Visibility, VisibilityOff } from "components/Icons";

const useStyles = makeStyles(() => ({
	dialog: {
		"& .MuiDialog-paper": {
			width: "600px",
			maxWidth: "100%",
		},
	},
}));

const PdfPasswordDialog = (props) => {
	const { show = true, sendPassword } = props;
	const { t } = useTranslation("documents");
	const [password, setPassword] = useState("");
	const [showPassword, setShowPassword] = useState(false);
	const classes = useStyles();

	const handleClickShowPassword = () => {
		setShowPassword((prev) => !prev);
	};

	const handleSendPassword = useCallback(() => {
		sendPassword(password);
	}, [password]);

	const resizeModal = () => {
		const iframe = document.querySelector("iframe");
		const backdrop = document.querySelector(".MuiBackdrop-root");
		const container = document.querySelector(".MuiDialog-root.MuiModal-root");
		if (iframe && backdrop && container) {
			var rect = iframe.getBoundingClientRect();
			container.style.top = backdrop.style.top = rect.top + "px";
			container.style.bottom = backdrop.style.bottom = window.innerHeight - rect.bottom + "px";
			container.style.left = backdrop.style.left = rect.left + "px";
			container.style.right = backdrop.style.right = window.innerWidth - rect.right + "px";
		}
	};

	useEffect(() => {
		setTimeout(resizeModal, 50);
	});

	const i18n = t("pdfPasswordDialog");
	const dialog = {
		title: i18n.title,
		primaryTitle: t("app:buttons.ok"),
		primaryAction: handleSendPassword,
	};

	return (
		<GenericDialog
			className={classes.dialog}
			show={show}
			title={dialog.title}
			primaryAction={dialog.primaryAction}
			primaryTitle={dialog.primaryTitle}
			data-cy="pdf-password-dialog"
		>
			<OutlinedInput
				id="pdf-password"
				type={showPassword ? "text" : "password"}
				label={t("pdfPasswordDialog.labels.password")}
				value={password}
				onChange={(e) => setPassword(e.target.value)}
				InputProps={{
					endAdornment: (
						<InputAdornment tabIndex="-1" position="end">
							<IconButton tabIndex="-1" onClick={handleClickShowPassword} size="large">
								{showPassword ? <VisibilityOff /> : <Visibility />}
							</IconButton>
						</InputAdornment>
					),
				}}
				noDefaultClassName
				fullWidth
				autoFocus
				size="small"
				data-cy="pdf-password"
			/>
		</GenericDialog>
	);
};

export default PdfPasswordDialog;
