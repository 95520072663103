import React from "react";
import { useTranslation } from "react-i18next";
import clsx from "clsx";

import { Checkbox, FormControlLabel } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { errorColor } from "atlas/assets/jss/shared";
import { useWidthDown } from "atlas/utils/useWidth";
import { Check } from "components/Icons";
import telemetryAddEvent from "utils/telemetryAddEvent";

const useStyles = makeStyles((theme) => ({
	userCheckbox: {
		width: "48px",
		textAlign: "center",
		boxSizing: "border-box",
		[theme.breakpoints.down("md")]: {
			width: "100%",
			textAlign: "left",
		},
	},
	userCheckboxDisabled: {
		opacity: "0.6",
	},
	userCheckboxRequired: {
		color: errorColor,
	},
}));

const PermissionOptions = (props) => {
	const { permission, isPolicy = false, alwaysView, showViewPlaceholder, handleUpdate, telemetryPage } = props;
	const mobile = useWidthDown("md");
	const { t } = useTranslation("users");
	const classes = useStyles();

	const getCheckbox = (key, toolTip, labelledBy, checked, onChange, disabled, dataCy) => (
		<Checkbox
			key={key}
			checkedIcon={<Check fontSize="small" color="primary" />}
			inputProps={{
				"aria-label": toolTip,
				"aria-labelledby": labelledBy,
			}}
			checked={checked}
			onChange={onChange}
			disabled={disabled}
			data-cy={dataCy}
		/>
	);

	const getLabeledCheckbox = (key, label, useLabel, toolTip, labelledBy, checked, onChange, disabled, dataCy) =>
		useLabel ? (
			<FormControlLabel control={getCheckbox(key, toolTip, labelledBy, checked, onChange, disabled)} label={label} data-cy={dataCy} />
		) : (
			getCheckbox(key, toolTip, labelledBy, checked, onChange, disabled, dataCy)
		);

	return permission ? (
		<>
			{(!alwaysView || showViewPlaceholder) && (
				<div
					className={clsx(classes.userCheckbox, {
						[classes.userCheckboxDisabled]: alwaysView || !handleUpdate,
					})}
				>
					{!alwaysView
						? getLabeledCheckbox(
								`view-${permission.userId}`,
								t("detail.canView"),
								mobile,
								t("tooltips.canView"),
								"view-label",
								permission.canView || alwaysView,
								(e) =>
									handleUpdate(e, "canView", true, false, permission, undefined, (objectToUpdate) => {
										objectToUpdate.updated = true;
										if (!objectToUpdate.canView) {
											objectToUpdate.canUpdate = false;
										}
									}),
								alwaysView || !handleUpdate,
								"can-view",
						  )
						: null}
				</div>
			)}
			<div
				className={clsx(classes.userCheckbox, {
					[classes.userCheckboxDisabled]: !handleUpdate,
				})}
			>
				{getLabeledCheckbox(
					`edit-${permission.userId}`,
					t("detail.canEdit"),
					mobile,
					t("tooltips.canEdit"),
					"edit-label",
					permission.canUpdate,
					(e) => {
						const type = isPolicy ? "Policy - Book" : "Library - Folder";
						telemetryAddEvent(`${telemetryPage} - ${type} editor ${e.target.checked ? "On" : "Off"}`, { area: "users" });

						handleUpdate(e, "canUpdate", true, false, permission, undefined, (objectToUpdate) => {
							objectToUpdate.updated = true;
							if (objectToUpdate.canUpdate) {
								objectToUpdate.canView = true;
							}
						});
					},
					!handleUpdate,
					"can-edit",
				)}
			</div>
		</>
	) : null;
};

export default PermissionOptions;
