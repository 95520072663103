import { blackColor, infoColor } from "atlas/assets/jss/shared";

const documentTreeViewStyle = () => ({
	listItem: {
		display: "flex",
		alignItems: "center",
		gap: "8px",
		position: "relative",
		"& > div": {
			display: "flex",
			alignItems: "center",
			height: "38px",
		},
	},
	expandIcon: {
		width: "16px",
		"& > svg": {
			cursor: "pointer",
		},
	},
	title: {
		flexGrow: "1",
		color: infoColor,
		width: "min-content",
		whiteSpace: "nowrap",
		overflow: "hidden",
		textOverflow: "ellipsis",
	},
	canSelect: {
		cursor: "pointer",
	},
	selected: {
		position: "absolute",
		top: "0",
		left: "22px",
		bottom: "0",
		right: "30px",
		outline: `2px solid ${infoColor}`,
		borderRadius: "2px",
	},
	children: {
		marginLeft: "16px",
	},
	noChildren: {
		textAlign: "center",
		textTransform: "uppercase",
		color: blackColor[0],
	},
	listItems: {
		marginTop: "9px",
	},
});

export default documentTreeViewStyle;
