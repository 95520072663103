export default function notifierMessage(
	message,
	type,
	action,
	autoHideDuration,
	secondaryAction,
	secondaryActionLabel,
	clickAwayEnabled = false,
) {
	let obj = {};

	if (type == "success") {
		obj = {
			message: message,
			severity: "success",
			iconName: "status-success",
			action: action,
			autoHideDuration,
			secondaryAction,
			secondaryActionLabel,
			clickAwayEnabled,
		};
	}
	if (type == "error") {
		obj = {
			message: message,
			severity: "error",
			iconName: "status-error",
			action: action,
			autoHideDuration,
		};
	}
	return obj;
}
