export default {
	passwordUpdated: "Password updated",
	buttons: {
		activate: "Activate",
		addBoard: "+ Add meeting group",
		copy: "Copy",
		copyUser: "Copy user",
		deactivate: "Deactivate",
		less: "Less",
		more: "More",
		newUser: "+ New user",
		sendPasswordSetupRequest: "Send password setup request",
	},
	deleteUserDialog: {
		line1: "You are about to delete {{name}}",
		title: "Delete user",
		snackbar: {
			success: "The user {{name}} has been deleted.",
		},
		undo: {
			error: "User undo delete failed",
			successful: "User undo delete successful",
		},
	},
	passwordSetupRequestDialog: {
		title: "Send password setup request",
	},
	detail: {
		about: "About text",
		active: "User active",
		boardsCommittees: "Boards/Committees",
		canEdit: "Can edit",
		canView: "Can view",
		department: "Department",
		documentLibrary: "Document library",
		emailAddress: "Email address",
		firstName: "First name",
		lastName: "Last name",
		meetingGroupMembership: "Meeting group membership",
		meetingGroups: "Meeting groups",
		notes: "Admin notes",
		passwordRequestSent: "Password request sent",
		permanentlyDelete: "Manage recycle bin",
		personInformation: "Person information",
		phoneNumber: "Phone number",
		policySettings: "Policy settings",
		portalAdmin: "Public site administration",
		profilePicture: "Profile picture",
		publicProfileInformation: "Public profile info",
		selectBoard: "Select meeting group",
		notifications: "Notifications",
		smsOnItemApproval: "SMS Item approval",
		smsOnApproveReject: "Approval requested (Approve / reject)",
		smsOnItemDenied: "Item denied",
		smsOnEachItemApproved: "Item approved (each approver)",
		smsOnItemFullyApproved: "Item fully approved",
		smsOnAgendaShared: "Agenda shared",
		smsOnUrgentApproval: "Urgent approval reminder",
		snackbar: {
			saveError: "An error occurred while saving the user",
			userCopied: "User copied",
			userSaved: "User saved",
		},
		title: "Title",
		unsavedChanges: "You have unsaved changes",
		updateProfileImage: "Update the profile image",
		userAdmin: "User administration",
		userName: "User name",
		validation: {
			firstName: "First name required",
			lastName: "Last name required",
			noSelectedBoards: "This person has not been added to any meeting groups",
			userName: "User name required",
			userNameUnique: "Unique user name required",
			emailAddress: "Email address required",
			emailAddressValid: "Valid email address required",
			emailAddressUnique: "Unique email address required",
			noSelectedUserRoles: "You need to select a role for this person",
		},
	},
	filter: {
		department: "Department",
		meetingGroup: "Meeting Group",
		notifications: {
			filterApplied: "User filter applied",
		},
		userType: "User type",
		userTypes: {
			admin: "Administrator",
			member: "Member",
			staff: "Staff",
		},
	},
	list: {
		header: {
			firstName: "First name",
			lastName: "Last name",
			userName: "User name",
			emailAddress: "Email address",
			active: "Active",
			passwordExpiryDate: "Password Status",
		},
		noResults: {
			description: "We couldn’t find any users that match the filters you set.",
			title: "No matches found",
		},
		snackbar: {
			userActivated: "User activated",
			userDeactivated: "User deactivated",
		},
	},
	title: {
		users: "Users",
		usersGroups: "Users/Groups",
		user: "User",
	},
	tooltips: {
		activate: "Activate the selected user(s)",
		addBoard: "Add this person to the selected meeting group",
		canEdit: "Add, update, delete, and restore files in this folder",
		canView: "View files in this folder",
		clearProfileImage: "Clear the profile image",
		copy: "Copy this user",
		copyUser: "Copy the selected user",
		deactivate: "Deactivate the selected active user(s)",
		delete: "Delete this user",
		filter: "Show or hide the filter panel",
		newUser: "Create a new user",
		removeBoard: "Remove this person from this meeting group",
		save: "Save the changes to this user",
		sendMultiplePasswordSetupRequest: "Open dialog to send password setup requests",
		sendPasswordSetupRequest: "Send password setup request to this user",
	},
	notification: {
		passwordSetupSent: "Password setup request sent",
	},
};
