import { STATUS_PRESENT, STATUS_LATE, STATUS_ABSENT } from "./rollCallUserStatus";
import { TYPE_FOR, TYPE_AGAINST, TYPE_ABSTAIN, TYPE_ABSENT, TYPE_COI, TYPE_NONE } from "./voteType";
import { ABSTAIN_FOR, ABSTAIN_AGAINST } from "./abstainType";

export default function checkVotingFinished(motion, rollCall) {
	if (motion && motion.fields && motion.fields.Voting && motion.fields.Voting.Value && rollCall) {
		const members = rollCall.users.filter((user) => user.votingMember && !user.tieBreaker);
		const membersVotes = motion.fields.Voting.Value.filter((vote) => vote.Vote != null && vote.Vote !== TYPE_NONE);
		const votingResults = getVotingResults(motion, rollCall);
		return members.length <= membersVotes.length && !votingResults.tie;
	}

	return false;
}

export function getVotingResults(motion, rollCall, meeting = null) {
	if (motion && motion.fields && motion.fields.Voting && motion.fields.Voting.Value && rollCall) {
		const members = rollCall.users.filter((user) => user.votingMember);
		const membersVotes = motion.fields.Voting.Value.filter((vote) => vote.Vote != null && vote.Vote !== TYPE_NONE);
		const votesFromMembers = motion.fields.Voting.Value.filter((vote) => members.find((member) => member.userId === vote.UserId)) || [];

		const tieBreaker = rollCall.users.find((user) => user.tieBreaker);
		const tieBreakerVote = tieBreaker
			? motion.fields.Voting.Value.find((vote) => vote.Vote != null && vote.Vote !== TYPE_NONE && vote.UserId === tieBreaker.userId)
			: null;

		const forVotes = votesFromMembers.filter((vote) => vote.Vote === TYPE_FOR && vote.UserId !== tieBreaker?.userId) || [];
		const againstVotes = votesFromMembers.filter((vote) => vote.Vote === TYPE_AGAINST && vote.UserId !== tieBreaker?.userId) || [];
		const abstainVotes = votesFromMembers.filter((vote) => vote.Vote === TYPE_ABSTAIN && vote.UserId !== tieBreaker?.userId) || [];
		const absentVotes = votesFromMembers.filter((vote) => vote.Vote === TYPE_ABSENT && vote.UserId !== tieBreaker?.userId) || [];
		const coiVotes = votesFromMembers.filter((vote) => vote.Vote === TYPE_COI && vote.UserId !== tieBreaker?.userId) || [];

		let forVotesCount = 0;
		forVotes.forEach((forVote) => {
			forVotesCount += rollCall.useWeightedVoting ? forVote.Weighting : 1;
		});
		const forVoteNames = forVotes.map((vote) => vote.Name);
		const forVotesWeighted = forVotes.reduce((sum, vote) => sum + (rollCall.useWeightedVoting ? vote.Weighting : 1), 0);

		let againstVotesCount = 0;
		againstVotes.forEach((againstVote) => {
			againstVotesCount += rollCall.useWeightedVoting ? againstVote.Weighting : 1;
		});
		const againstVoteNames = againstVotes.map((vote) => vote.Name);
		const againstVotesWeighted = againstVotes.reduce((sum, vote) => sum + (rollCall.useWeightedVoting ? vote.Weighting : 1), 0);

		let abstainVotesCount = 0;
		abstainVotes.forEach((abstainVote) => {
			abstainVotesCount += rollCall.useWeightedVoting ? abstainVote.Weighting : 1;
		});
		const abstainVoteNames = abstainVotes.map((vote) => vote.Name);
		const abstainVotesWeighted = abstainVotes.reduce((sum, vote) => sum + (rollCall.useWeightedVoting ? vote.Weighting : 1), 0);

		let totalVotes = 0;
		members.forEach((m) => {
			totalVotes += rollCall.useWeightedVoting ? m.weighting : 1;
		});

		let invalidVoteCount = 0;
		absentVotes.forEach((a) => {
			invalidVoteCount += rollCall.useWeightedVoting ? a.Weighting : 1;
		});
		const absentVoteNames = absentVotes.map((vote) => vote.Name);
		coiVotes.forEach((c) => {
			invalidVoteCount += rollCall.useWeightedVoting ? c.Weighting : 1;
		});
		const coiVoteNames = coiVotes.map((vote) => vote.Name);

		const eligibleVotesCount = forVotesCount + againstVotesCount + abstainVotesCount;

		switch (rollCall.abstainCountAs) {
			case ABSTAIN_FOR:
				forVotesCount += abstainVotesCount;
				break;
			case ABSTAIN_AGAINST:
				againstVotesCount += abstainVotesCount;
				break;
			default:
				totalVotes = totalVotes - abstainVotesCount;
				break;
		}

		let totalValidVotes = totalVotes - invalidVoteCount;
		let voteTally = forVotesCount / totalValidVotes;
		let votePassed = false;
		let tie = voteTally == 0.5 && tieBreaker != null && members.length <= membersVotes.length;
		let tieWithoutTieBreaker = tie;
		if (totalValidVotes > 0) {
			if (tie && tieBreakerVote) {
				if (tieBreakerVote.Vote === TYPE_FOR || (tieBreakerVote.Vote === TYPE_ABSTAIN && rollCall.abstainCountAs === ABSTAIN_FOR)) {
					totalValidVotes++;
					forVotesCount += rollCall.useWeightedVoting ? tieBreakerVote.Weighting : 1;
				} else if (
					tieBreakerVote.Vote === TYPE_AGAINST ||
					(tieBreakerVote.Vote === TYPE_ABSTAIN && rollCall.abstainCountAs === ABSTAIN_AGAINST)
				) {
					totalValidVotes++;
					againstVotesCount += rollCall.useWeightedVoting ? tieBreakerVote.Weighting : 1;
				}
				voteTally = forVotesCount / totalValidVotes;
				tie = voteTally == 0.5;
			}

			switch (rollCall.quorumNeeded) {
				case 0: // majority
					votePassed = voteTally > 0.5;
					break;
				case 1: // 2/3
					votePassed = voteTally >= 0.66;
					break;
				case 2: // percent
					votePassed = voteTally > rollCall.quorumNeededValue / 100;
					break;
				case 3: // member count
					tie = forVotesCount / rollCall.quorumNeededValue == 0.5;
					votePassed = forVotesCount >= rollCall.quorumNeededValue;
					break;
			}
		}
		let quorumMet = true;
		// checks quorum based on the meeting quorum rules instead of the rollcall rules
		if (meeting && invalidVoteCount > 0) {
			switch (meeting.quorumNeeded) {
				case 0: // majority
					quorumMet = invalidVoteCount / totalVotes < 0.5;
					break;
				case 1: // 2/3
					quorumMet = invalidVoteCount / totalVotes < 0.33;
					break;
				case 2: // percent
					quorumMet = invalidVoteCount / totalVotes < meeting.quorumNeededValue / 100;
					break;
				case 3: // member count
					quorumMet = totalValidVotes >= meeting.quorumNeededValue;
					break;
			}
		} else {
			quorumMet = invalidVoteCount > 0 ? invalidVoteCount / totalVotes < 0.5 : true;
		}

		return {
			votePassed,
			quorumMet,
			forVotes,
			forVotesCount,
			forVoteNames,
			againstVotes,
			againstVotesCount,
			againstVoteNames,
			abstainVotes,
			abstainVotesCount,
			abstainVoteNames,
			absentVotes,
			absentVoteNames,
			coiVotes,
			coiVoteNames,
			eligibleVotesCount,
			totalVotes,
			totalValidVotes,
			tie,
			tieWithoutTieBreaker,
			forVotesWeighted,
			againstVotesWeighted,
			abstainVotesWeighted,
		};
	}

	return null;
}

export function checkMeetingQuorumMet(meeting, rollCall) {
	let quorumMet = false;

	if (rollCall) {
		const votingMembers = rollCall.users.filter((user) => user.votingMember);
		const members = rollCall.users.filter((user) => user.votingMember && (user.status === STATUS_LATE || user.status === STATUS_PRESENT));

		switch (meeting?.quorumNeeded) {
			case 0: // majority
				quorumMet = members.length / votingMembers.length > 0.5;
				break;
			case 1: // 2/3
				quorumMet = members.length / votingMembers.length > 0.66;
				break;
			case 2: // percent
				quorumMet = members.length / votingMembers.length >= meeting.quorumNeededValue / 100;
				break;
			case 3: // member count
				quorumMet = members.length >= meeting?.quorumNeededValue;
				break;
		}
	}

	return quorumMet;
}

export function checkQuorumMet(rollCall) {
	if (rollCall) {
		const votingMembers = rollCall.users.filter((user) => user.votingMember);
		const members = rollCall.users.filter((user) => user.votingMember && (user.status === STATUS_LATE || user.status === STATUS_PRESENT));
		return members.length / votingMembers.length > 0.5;
	}

	return false;
}
