import BoardAgendaStatusesEnum from "./enums/BoardAgendaStatuses";
import PublicAgendaStatusesEnum from "./enums/PublicAgendaStatuses";

export function isPublished(publicStatus) {
	return (
		publicStatus === PublicAgendaStatusesEnum().PUBLISHED.value ||
		publicStatus === PublicAgendaStatusesEnum().FINALPUBLISHED.value ||
		publicStatus === PublicAgendaStatusesEnum().OUTOFSYNC.value
	);
}

export function isShared(boardStatus) {
	return (
		boardStatus === BoardAgendaStatusesEnum().FINALSHARED.value ||
		boardStatus === BoardAgendaStatusesEnum().SHARED.value ||
		boardStatus === BoardAgendaStatusesEnum().OUTOFSYNC.value
	);
}

export function isPublishedInSync(publicStatus) {
	return publicStatus === PublicAgendaStatusesEnum().PUBLISHED.value || publicStatus === PublicAgendaStatusesEnum().FINALPUBLISHED.value;
}

export function isSharedInSync(boardStatus) {
	return boardStatus === BoardAgendaStatusesEnum().FINALSHARED.value || boardStatus === BoardAgendaStatusesEnum().SHARED.value;
}
