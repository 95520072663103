import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import makeStyles from "@mui/styles/makeStyles";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import { MenuItem } from "@mui/material";

import telemetryAddEvent from "utils/telemetryAddEvent";

import { Check } from "components/Icons";
import InputLabel from "atlas/components/FormControls/InputLabel";
import SelectInput from "atlas/components/FormControls/SelectInput";
import OutlinedInput from "atlas/components/FormControls/OutlinedInput";
import CircularProgressIndicator from "atlas/components/Progress/CircularProgressIndicator";
import settingsStyle from "assets/jss/components/settingsStyle";

const useStyles = makeStyles(settingsStyle);

const Voting = (props) => {
	const { settings, updateSettingsValue, userAdmin, meetingTypes, lite } = props;
	const { t } = useTranslation("settings");
	const navigate = useNavigate();
	const classes = useStyles();

	const handleChange = (e, setting, checkbox, subSetting) => {
		const {
			target: { value, checked },
		} = e;

		if ((subSetting && settings[setting][subSetting] === value) || (!subSetting && settings[setting] === value)) {
			return; // don't re-save unchanged content;
		}

		updateSettingsValue(setting, checkbox ? checked : subSetting ? { [subSetting]: value } : value, Boolean(subSetting));
	};

	const handleDigitalVotingChange = (e, setting, meetingTypeId) => {
		const {
			target: { value, checked },
		} = e;

		updateSettingsValue(setting, { meetingTypeId, checked });
	};

	const isBoardAdmin = (meetingTypeId) => {
		return meetingTypes.findIndex((item) => item.id === meetingTypeId) !== -1;
	};

	return (
		<>
			{settings ? (
				<>
					<div>
						<div>
							<Typography variant="h4" className={classes.bold}>
								{t("voting.votingCustomization")}
							</Typography>
						</div>
						<hr />
					</div>
					<div>
						<div className={classes.sectionLabelLargeSpacing}>
							<InputLabel htmlFor="voting-verbiage" label={t("voting.votingVerbiage")} size="large" />
						</div>
						<div id="voting-verbiage">
							<div className={classes.twoColumns}>
								<div>
									<OutlinedInput
										id="voting-label-for"
										value={settings.votingLabels.for || ""}
										label={t("voting.for")}
										externalLabel
										labelSize="large"
										onChange={(e) => handleChange(e, "votingLabels", false, "for")}
										noDefaultClassName
										size="small"
										data-cy="voting-label-for"
										disabled={!userAdmin}
									/>
									<OutlinedInput
										id="voting-label-against"
										value={settings.votingLabels.against || ""}
										label={t("voting.against")}
										externalLabel
										labelSize="large"
										onChange={(e) => handleChange(e, "votingLabels", false, "against")}
										noDefaultClassName
										size="small"
										data-cy="voting-label-against"
										disabled={!userAdmin}
									/>
								</div>
								<div>
									<OutlinedInput
										id="voting-label-abstain"
										value={settings.votingLabels.abstain || ""}
										label={t("voting.abstain")}
										externalLabel
										labelSize="large"
										onChange={(e) => handleChange(e, "votingLabels", false, "abstain")}
										noDefaultClassName
										size="small"
										data-cy="voting-label-abstain"
										disabled={!userAdmin}
									/>
									<OutlinedInput
										id="voting-label-absent"
										value={settings.votingLabels.absent || ""}
										label={t("voting.absent")}
										externalLabel
										labelSize="large"
										onChange={(e) => handleChange(e, "votingLabels", false, "absent")}
										noDefaultClassName
										size="small"
										data-cy="voting-label-absent"
										disabled={!userAdmin}
									/>
								</div>
							</div>
							<div>
								<div className={classes.twoColumns}>
									<div>
										<OutlinedInput
											id="voting-label-carried"
											value={settings.votingLabels.carried || ""}
											label={t("voting.carried")}
											externalLabel
											labelSize="large"
											onChange={(e) => handleChange(e, "votingLabels", false, "carried")}
											noDefaultClassName
											size="small"
											data-cy="voting-label-carried"
											disabled={!userAdmin}
										/>
									</div>
									<div>
										<OutlinedInput
											id="voting-label-failed"
											value={settings.votingLabels.failed || ""}
											label={t("voting.failed")}
											externalLabel
											labelSize="large"
											onChange={(e) => handleChange(e, "votingLabels", false, "failed")}
											noDefaultClassName
											size="small"
											data-cy="voting-label-failed"
											disabled={!userAdmin}
										/>
									</div>
								</div>
							</div>
						</div>
						<div id="abstain-count-as">
							<SelectInput
								id="abstain-count-as-types"
								className={classes.selectInput}
								fullWidth
								size="small"
								label={t("voting.abstainCountAs.label")}
								externalLabel
								labelSize="large"
								value={settings.abstainCountAs > 0 ? settings.abstainCountAs : 0}
								onChange={(e) => {
									telemetryAddEvent("Settings - Abstain value change");
									handleChange(e, "abstainCountAs", false, null);
								}}
								data-cy="abstain-count-as-types"
								disabled={!userAdmin}
							>
								<MenuItem key={`abstain-count-as-type-0`} value={0} data-cy={`abstain-count-as-type-0`}>
									<div className={classes.menuItemText}>{t("voting.abstainCountAs.none")}</div>
								</MenuItem>
								<MenuItem key={`abstain-count-as-type-1`} value={1} data-cy={`abstain-count-as-type-1`}>
									<div className={classes.menuItemText}>{t("voting.abstainCountAs.for")}</div>
								</MenuItem>
								<MenuItem key={`abstain-count-as-type-2`} value={2} data-cy={`abstain-count-as-type-2`}>
									<div className={classes.menuItemText}>{t("voting.abstainCountAs.against")}</div>
								</MenuItem>
							</SelectInput>
						</div>
						{!lite.enabled && (
							<>
								<div className={classes.sectionLabel}>
									<InputLabel htmlFor="voting" label={t("voting.enableMemberDigitalVoting")} size="large" />
								</div>
								<div id="voting">
									<Box display="flex" justifyContent="flex-start" alignItems="flex-end">
										<FormGroup>
											{settings.digitalVoting.map((meetingType) => {
												const meetingTypeUrl = `/meetingtemplate/${meetingType.id}?minutes=true`;

												return (
													<div className={classes.votingLabels}>
														<Checkbox
															key={`digital-voting-checkbox-${meetingType.id}`}
															checkedIcon={<Check fontSize="small" color="primary" />}
															checked={meetingType.digitalVoting}
															onChange={(e) => handleDigitalVotingChange(e, "digitalVoting", meetingType.id)}
															disabled
															aria-labelledby={`digital-voting-${meetingType.id}`}
															data-cy="digital-voting"
														/>
														{!userAdmin && !isBoardAdmin(meetingType.id) ? (
															<span>{meetingType.name}</span>
														) : (
															<a
																href={`/home${meetingTypeUrl}`}
																className={classes.link}
																onClick={(e) => {
																	e.preventDefault();

																	navigate(meetingTypeUrl);
																}}
															>
																{meetingType.name}
															</a>
														)}
													</div>
												);
											})}
										</FormGroup>
									</Box>
								</div>
							</>
						)}
					</div>
				</>
			) : (
				<CircularProgressIndicator />
			)}
		</>
	);
};

export default Voting;
