import { useEffect, useRef, useCallback } from "react";
import { useNavigate } from "react-router-dom";

const updateHistoryState = (state) => {
	const newUrl = new URL(window.location.protocol + "//" + window.location.host + window.location.pathname);
	window.history.replaceState(state, "", newUrl);
};

const useBackButtonHandler = (savingState, navigatePath, unsavedChangesText) => {
	const navigate = useNavigate();
	const savingStateRef = useRef(savingState);

	useEffect(() => {
		savingStateRef.current = savingState;
	}, [savingState]);

	const onBrowserBackButtonEvent = useCallback(
		(e) => {
			const { isSaving, updated } = savingStateRef.current;

			if (isSaving || updated) {
				e.preventDefault();
				if (window.confirm(unsavedChangesText)) {
					navigate(navigatePath);
				} else {
					updateHistoryState(null, { preventNavigation: true });
					window.history.pushState(null, null, window.location.pathname);
				}
			} else {
				window.removeEventListener("popstate", onBrowserBackButtonEvent);
				window.history.back();
			}
		},
		[navigate, unsavedChangesText, navigatePath],
	);

	useEffect(() => {
		window.history.pushState(null, null, window.location.pathname);
		window.addEventListener("popstate", onBrowserBackButtonEvent);

		return () => {
			window.removeEventListener("popstate", onBrowserBackButtonEvent);
		};
	}, [onBrowserBackButtonEvent]);
};

export default useBackButtonHandler;
