import { Command } from "@ckeditor/ckeditor5-core";
import { findLinkRange } from "./inlineFileUtils";
import toMap from "@ckeditor/ckeditor5-utils/src/tomap";
import Collection from "@ckeditor/ckeditor5-utils/src/collection";

export default class InlineFileCommandUnlink extends Command {
	constructor(editor) {
		super(editor);
		this.rootName = "main";
	}

	refresh() {
		this.isEnabled = this.editor.model.document.selection.hasAttribute("fileHref");
	}

	execute({ info }, { cb }) {
		const editor = this.editor;
		const model = editor.model;
		const selection = model.document.selection;

		model.change((writer) => {
			let rangesToUnlink = null;
			if (selection.isCollapsed) {
				rangesToUnlink = [findLinkRange(selection.getFirstPosition(), selection.getAttribute("fileHref"), model)];
			} else {
				rangesToUnlink = selection.getRanges();
			}
			for (const range of rangesToUnlink) {
				writer.removeAttribute("fileHref", range);
			}
			info.rootName = this.rootName;
			cb(info);
		});
	}
}
