import React from "react";
import { useTranslation } from "react-i18next";
import clsx from "clsx";

import { Badge, Radio, Grid } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import typographyStyle from "atlas/assets/jss/components/typographyStyle";
import { useWidthUp, useWidthDown } from "atlas/utils/useWidth";
import userListStyle from "assets/jss/components/userListStyle";
import GenericAvatar, { GENERIC_AVATAR_BACKGROUNDS } from "components/Avatar/GenericAvatar";
import { TYPE_FOR, TYPE_AGAINST, TYPE_ABSTAIN, TYPE_ABSENT, TYPE_COI, TYPE_NONE } from "../utils/voteType";
import checkVotingFinished, { getVotingResults } from "../utils/votingUtils";
import { ABSTAIN_NONE } from "../utils/abstainType";
import Tooltip from "atlas/components/Tooltip/Tooltip";

const useUserListStyles = makeStyles(userListStyle);
const useStyles = makeStyles({
	flex: {
		display: "flex",
	},
	rollCall: {
		padding: "16px",
	},
	header: {
		display: "flex",
		alignItems: "center",
		height: "48px",
		justifyContent: "flex-end",
		"& > span": {
			flexGrow: 1,
		},
	},
	headerSmall: {
		marginBottom: "16px",
	},
	headerText: {
		...typographyStyle.columnHeaderDiagonal,
		width: "56px",
		fontSize: "14px",
	},
	headerTextMobile: {
		...typographyStyle.columnHeaderDiagonal,
		minWidth: "36px",
		width: "100%",
		fontSize: "14px",
	},
	radioButtonsGrid: {
		justifyContent: "flex-end",
	},
	radioButtons: {
		display: "flex",
		alignItems: "center",
	},
	radioButton: {
		width: "56px",
	},
	footer: {
		display: "flex",
		alignItems: "center",
		justifyContent: "flex-start",
		height: "48px",
		"& > div": {
			"&:first-child": {
				width: "155px",
				marginRight: "43px",
			},
			width: "39px",
			padding: "8px",
		},
	},
	footerSmall: {
		display: "flex",
		alignItems: "center",
		justifyContent: "flex-start",
		height: "48px",
		"& > div": {
			width: "39px",
			padding: "8px",
		},
	},
	voteResultsHighlight: {
		position: "absolute",
		top: 0,
		width: "40px",
		borderRadius: "25px",
		border: "1px solid black",
	},
	highlightFor: {
		left: "0px",
	},
	highlightAgainst: {
		left: "55px",
	},
	userDisconnected: {
		"& .MuiBadge-badge": {
			borderRadius: "5px",
			top: "auto",
			color: "#444444",
			backgroundColor: "#fff",
			border: "3px solid",
		},
	},
	userConnected: {
		"& .MuiBadge-badge": {
			borderRadius: "5px",
			top: "auto",
			color: "#047b55",
			backgroundColor: "#047b55",
		},
	},
	voteColumnHeaders: {
		display: "flex",
	},
	voteColumnHeadersMobile: {
		display: "flex",
		width: "100%",
		marginLeft: "42px",
	},
	radioButtonsMobileGrid: {
		width: "100%",
		marginLeft: "36px",
	},
	radioButtonsMobile: {
		width: "100%",
		minWidth: "36px",
	},
	fullWidth: {
		width: "100%",
	},
	hidden: {
		display: "none",
	},
});

const Voting = (props) => {
	const {
		motion,
		rollCall,
		selectedRollCall,
		voting,
		onlineVoters,
		votingSettings = { votingLabels: {} },
		digitalVoting,
		digitalVotingStarted,
		handleVoteChange,
		showVotesExpanded,
		meeting = null,
	} = props;
	const widthUpLg = useWidthUp("lg");
	const widthDownLg = useWidthDown("lg");
	const { t } = useTranslation("meetings");
	const classes = useStyles();
	const userListClasses = useUserListStyles();

	const findMotionVote = (userId) => (voting ? voting.Value.find((vote) => vote.UserId === userId) : null);

	const voteResults = getVotingResults(motion, selectedRollCall ? selectedRollCall : rollCall, meeting);
	const voteFinished = checkVotingFinished(motion, selectedRollCall ? selectedRollCall : rollCall);
	const tieBreaker = rollCall && rollCall.users.length > 0 ? rollCall.users.find((user) => user.tieBreaker) : {};
	const showTieBreaker = tieBreaker && voteResults && voteResults.tieWithoutTieBreaker;
	const highlightHeight = document.getElementById(`voting-user-list-${motion.guid}`)?.clientHeight ?? 0;

	const getVotes = () =>
		rollCall.users.map((rollCallUser) => {
			if (rollCallUser.votingMember || (rollCallUser.tieBreaker && showTieBreaker)) {
				const selectedRollCallUser = selectedRollCall ? selectedRollCall.users.find((u) => u.id === rollCallUser.id) : null;
				const vote = findMotionVote(rollCallUser.userId);
				if (vote && rollCallUser.status === 0) {
					vote.Vote = TYPE_ABSENT;
				}
				// eslint-disable-next-line no-param-reassign
				rollCallUser.voting = vote || {
					UserId: rollCallUser.userId,
					Name: rollCallUser.name,
					Vote: rollCallUser.status === 0 ? TYPE_ABSENT : !digitalVoting && !rollCallUser.tieBreaker ? TYPE_FOR : null,
					RecordedVote: true,
					Weighting: selectedRollCallUser ? selectedRollCallUser.weighting : rollCallUser.weighting,
					QuorumNeeded: selectedRollCall ? selectedRollCall.quorumNeeded : rollCall.quorumNeeded,
					QuorumNeededValue: selectedRollCall ? selectedRollCall.quorumNeededValue : rollCall.quorumNeededValue,
				};
				if (!vote) {
					if (motion.fields.Voting == null) {
						motion.fields.Voting = { Value: [] };
					}
					const defaultVote = rollCallUser.status === 0 ? TYPE_ABSENT : !digitalVoting && !rollCallUser.tieBreaker ? TYPE_FOR : TYPE_NONE;
					motion.fields.Voting.Value.push({
						UserId: rollCallUser.userId,
						Name: rollCallUser.name,
						Vote: defaultVote,
						RecordedVote: false,
						Weighting: selectedRollCallUser ? selectedRollCallUser.weighting : rollCallUser.weighting,
						QuorumNeeded: selectedRollCall ? selectedRollCall.quorumNeeded : rollCall.quorumNeeded,
						QuorumNeededValue: selectedRollCall ? selectedRollCall.quorumNeededValue : rollCall.quorumNeededValue,
					});
					handleVoteChange(motion.guid, rollCallUser.userId, defaultVote);
				}
			}

			return rollCallUser;
		});

	const getRadioButton = (user, type, vote) => {
		return (
			<Radio
				checked={user.voting.Vote === vote}
				value={vote}
				name={`${motion.guid}-voting-user-options-${user.userId}`}
				onChange={() => {
					handleVoteChange(motion.guid, user.userId, vote);
				}}
				inputProps={{
					"aria-label": t(`voting.${type}`),
					"data-fieldname": `${motion.guid}-voting-user-options-${type}-${user.userId}`,
					"data-cy": `voting-user-options-${type}-${user.userId}`,
				}}
			/>
		);
	};

	const getRadioControl = (user, type, vote) => {
		if (user && user.voting) {
			return (
				<div
					className={clsx({
						[classes.radioButton]: widthUpLg,
						[classes.radioButtonsMobile]: !widthUpLg,
					})}
				>
					{getRadioButton(user, type, vote)}
				</div>
			);
		}
	};

	let voteIndex = 0;

	return (
		<div>
			{showVotesExpanded && (
				<div className={clsx(classes.header, { [classes.headerSmall]: widthDownLg })}>
					<div
						className={clsx(widthUpLg ? classes.voteColumnHeaders : classes.voteColumnHeadersMobile, {
							[classes.hidden]: !showVotesExpanded,
						})}
					>
						<div className={widthUpLg ? classes.headerText : classes.headerTextMobile}>
							{votingSettings.votingLabels.for || t("voting.for")}
						</div>
						<div className={widthUpLg ? classes.headerText : classes.headerTextMobile}>
							{votingSettings.votingLabels.against || t("voting.against")}
						</div>
						<div className={widthUpLg ? classes.headerText : classes.headerTextMobile}>
							{votingSettings.votingLabels.abstain || t("voting.abstain")}
						</div>
						<div className={widthUpLg ? classes.headerText : classes.headerTextMobile}>
							{votingSettings.votingLabels.absent || t("voting.absent")}
						</div>
						<div className={widthUpLg ? classes.headerText : classes.headerTextMobile}>{t("voting.coi")}</div>
					</div>
				</div>
			)}
			<ol id={`voting-user-list-${motion.guid}`} className={clsx(userListClasses.list, { [classes.hidden]: !showVotesExpanded })}>
				{getVotes().map((user) => {
					if (user.status > 0) {
						voteIndex++;
					}
					return (
						user.status > 0 &&
						(user.votingMember || (user.tieBreaker && showTieBreaker)) && (
							<li key={`voting-user-${user.userId}`} data-cy={`voting-user-${user.userId}`}>
								<Grid container>
									<Grid item xs={12} lg={4} className={userListClasses.user}>
										<div className={userListClasses.avatar}>
											<Badge
												className={
													onlineVoters && onlineVoters.indexOf(user.userId) >= 0 ? classes.userConnected : classes.userDisconnected
												}
												overlap="circular"
												anchorOrigin={{
													vertical: "bottom",
													horizontal: "right",
												}}
												variant="dot"
												invisible={!digitalVoting}
												title={
													onlineVoters && onlineVoters.indexOf(user.userId) >= 0
														? t("voting.tooltips.userConnected")
														: t("voting.tooltips.userDisconnected")
												}
											>
												<GenericAvatar
													imageUrl={user.profileImageUrl}
													name={user.name}
													initials={`${(user.firstName || "").substr(0, 1)}${(user.lastName || "").substr(0, 1)}`}
													backgroundNumber={((user.number || 0) % GENERIC_AVATAR_BACKGROUNDS) + 1}
												/>
											</Badge>
										</div>
										<div className={clsx(userListClasses.identifier, classes.flex)}>
											<div className={userListClasses.name}>
												{user.name} {user.tieBreaker ? `(${t("tieBreaker")})` : ""}
											</div>
											<div className={userListClasses.title}></div>
										</div>
									</Grid>
									<Grid
										item
										xs={12}
										lg={8}
										className={clsx(userListClasses.user, classes.radioButtonsGrid, { [classes.radioButtonsMobileGrid]: !widthUpLg })}
									>
										<div className={clsx(classes.radioButtons, { [classes.fullWidth]: !widthUpLg })} style={{ position: "relative" }}>
											{voteIndex === 1 && widthUpLg && voteFinished && voteResults && voteResults.quorumMet && !voteResults.tie && (
												<div
													style={{ height: highlightHeight }}
													className={clsx(classes.voteResultsHighlight, {
														[classes.highlightFor]: voteResults.votePassed,
														[classes.highlightAgainst]: !voteResults.votePassed,
													})}
												></div>
											)}
											<Tooltip PopperProps={{ disablePortal: true }} title={votingSettings.votingLabels.for || t("voting.for")}>
												{getRadioControl(user, "for", TYPE_FOR)}
											</Tooltip>
											<Tooltip PopperProps={{ disablePortal: true }} title={votingSettings.votingLabels.against || t("voting.against")}>
												{getRadioControl(user, "against", TYPE_AGAINST)}
											</Tooltip>
											<Tooltip PopperProps={{ disablePortal: true }} title={t("voting.abstain")}>
												{getRadioControl(user, "abstain", TYPE_ABSTAIN)}
											</Tooltip>
											<Tooltip PopperProps={{ disablePortal: true }} title={t("voting.absent")}>
												{getRadioControl(user, "absent", TYPE_ABSENT)}
											</Tooltip>
											<Tooltip PopperProps={{ disablePortal: true }} title={t("voting.coi")}>
												{getRadioControl(user, "coi", TYPE_COI)}
											</Tooltip>
										</div>
									</Grid>
								</Grid>
							</li>
						)
					);
				})}
			</ol>
			{(digitalVotingStarted || (voteFinished && voteResults)) && (
				<>
					{!widthUpLg ? (
						<>
							<div className={clsx(classes.footer, { [classes.hidden]: !showVotesExpanded })}>
								<div>{digitalVotingStarted ? t("voting.votingStarted") : t("voting.countedVotes")}</div>
							</div>
							<div className={clsx(classes.footerSmall, { [classes.hidden]: !showVotesExpanded })}>
								<div>
									{voteResults.forVotes.length}({voteResults.forVotesWeighted})
								</div>
								<div>
									{voteResults.againstVotes.length}({voteResults.againstVotesWeighted})
								</div>
								<div>
									{voteResults.abstainVotes.length}({voteResults.abstainVotesWeighted})
								</div>
							</div>
							<div className={clsx(classes.footerSmall, { [classes.hidden]: !showVotesExpanded })}>
								<div>
									{voteResults.totalValidVotes > 0
										? Math.min(Math.floor((voteResults.forVotesCount / voteResults.totalValidVotes) * 100), 100)
										: 0}
									%
								</div>
								<div>
									{voteResults.totalValidVotes > 0
										? Math.min(Math.floor((voteResults.againstVotesCount / voteResults.totalValidVotes) * 100), 100)
										: 0}
									%
								</div>
								<div>
									{rollCall.abstainCountAs != ABSTAIN_NONE
										? `${Math.floor((voteResults.abstainVotesCount / voteResults.totalVotes) * 100)}%`
										: ""}
								</div>
							</div>
						</>
					) : (
						<>
							<div className={clsx(classes.footer, { [classes.hidden]: !showVotesExpanded })}>
								<div>{digitalVotingStarted ? t("voting.votingStarted") : t("voting.countedVotes")}</div>
								<div>
									{voteResults.forVotes.length}({voteResults.forVotesWeighted})
								</div>
								<div>
									{voteResults.againstVotes.length}({voteResults.againstVotesWeighted})
								</div>
								<div>
									{voteResults.abstainVotes.length}({voteResults.abstainVotesWeighted})
								</div>
							</div>
							<div className={clsx(classes.footer, { [classes.hidden]: !showVotesExpanded })}>
								<div></div>
								<div>
									{voteResults.totalValidVotes > 0
										? Math.min(Math.floor((voteResults.forVotesCount / voteResults.totalValidVotes) * 100), 100)
										: 0}
									%
								</div>
								<div>
									{voteResults.totalValidVotes > 0
										? Math.min(Math.floor((voteResults.againstVotesCount / voteResults.totalValidVotes) * 100), 100)
										: 0}
									%
								</div>
								<div>
									{rollCall.abstainCountAs != ABSTAIN_NONE
										? `${Math.floor((voteResults.abstainVotesCount / voteResults.totalVotes) * 100)}%`
										: ""}
								</div>
							</div>
						</>
					)}
				</>
			)}
		</div>
	);
};

export default Voting;
