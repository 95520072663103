import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import request from "superagent";

import makeStyles from "@mui/styles/makeStyles";

import { warningColor } from "atlas/assets/jss/shared";
import ListCard from "atlas/components/Cards/ListCard";
import Icon from "atlas/components/Icon/Icon";
import Tooltip from "atlas/components/Tooltip/Tooltip";
import { useWidthDown } from "atlas/utils/useWidth";
import ApprovalProgress from "components/Approval/ApprovalProgress";
import { API_HOST } from "config/env";
import { APPROVAL_STATUS_PENDING, APPROVAL_STATUS_APPROVED, APPROVAL_STATUS_DENIED } from "utils/approvalStatuses";
import { isEmpty } from "utils/html";
import telemetryAddEvent from "utils/telemetryAddEvent";
import RejectedFromMeetingStatus from "./RejectedFromMeetingStatus";

const useStyles = makeStyles((theme) => ({
	progress: {
		margin: "8px 0 0 0",
		display: "flex",
		alignItems: "center",
		[theme.breakpoints.up("md")]: {
			margin: "0 0 0 16px",
		},
	},
	warningIcon: {
		paddingRight: "8px",
		height: "4px",
		paddingTop: "3px",
	},
}));

const AgendaItemListCard = (props) => {
	const {
		tab,
		item,
		approvalProgressExpanded,
		onToggleApprovalProgressExpand,
		openSelectMeetingDialog,
		openSubmitForApprovalDialog,
		openCancelApprovalDialog,
		openItemDeleteDialog,
		openSendReminderDialog,
		showSignIn,
	} = props;
	const widthDownMd = useWidthDown("md");
	const { t } = useTranslation("agendaItems");
	const { t: tMeetings } = useTranslation("meetings");
	const navigate = useNavigate();
	const [anchor, setAnchor] = useState(null);
	const [menuOptions, setMenuOptions] = useState([]);
	const classes = useStyles();

	const handleDuplicate = () => {
		request
			.post(`${API_HOST}/api/agendaitem/${item.id}/copy`)
			.send({})
			.then((res) => {
				navigate(`/agendaitems/edit/${res.body.id}`);
				let option = notifierMessage(t("duplicateDialog.snackbar.success"), "success");
				setSnackbarOption(option);
				telemetryAddEvent("Agenda item - Duplicate", { area: "agendaItems" });
			})
			.catch((err) => {
				showSignIn(err, () => {
					handleDuplicate();
				});
			});
		handleMenuClose();
	};

	const getWarningIcon = () => {
		return item.isDraftPolicyUpdated ? (
			<Tooltip placement="bottom" title={`${tMeetings("warningPolicyDrafts")}`}>
				<div className={classes.warningIcon}>
					<Icon name="status-alert" color={warningColor} size={"14px"} />
				</div>
			</Tooltip>
		) : null;
	};

	const handleChangeMeeting = () => {
		openSelectMeetingDialog(item);
		handleMenuClose();
	};

	const handleCancelApproval = () => {
		openCancelApprovalDialog(item);
		handleMenuClose();
	};

	const handleSubmitForApproval = () => {
		openSubmitForApprovalDialog(item);
		handleMenuClose();
	};

	const handleSendReminder = () => {
		openSendReminderDialog(item);
		handleMenuClose();
	};

	const handleDelete = () => {
		openItemDeleteDialog({
			guid: item.guid,
			name: item.name,
		});
	};

	const openItemDetails = (id) => {
		navigate(`/agendaitems/edit/${id}${tab > 0 ? `?tab=${tab}` : ""}`);
	};

	const handleToggleMenu = (menuAnchor) => setAnchor(menuAnchor);

	const handleMenuClose = () => handleToggleMenu(null);

	useEffect(() => {
		const updatedMenuOptions = [];

		if (item.added) {
			if (item.canUpdate) {
				updatedMenuOptions.push({
					label: t("buttons.duplicate"),
					tooltip: t("tooltips.duplicate"),
					actionFunction: handleDuplicate,
					"data-cy": "duplicate",
				});
			}
		} else {
			if (item.canUpdate) {
				if (item.approvalStatus !== APPROVAL_STATUS_APPROVED && (!isEmpty(item.name) || !isEmpty(item.text))) {
					if (item.meeting) {
						updatedMenuOptions.push({
							label: t("buttons.changeMeeting"),
							tooltip: t("tooltips.changeMeeting"),
							actionFunction: handleChangeMeeting,
							"data-cy": "changeMeeting",
						});
					} else {
						updatedMenuOptions.push({
							label: t("buttons.selectMeeting"),
							tooltip: t("tooltips.selectMeeting"),
							actionFunction: handleChangeMeeting,
							"data-cy": "selectMeeting",
						});
					}
				}
				if (item.workflow && item.approvalStatus === APPROVAL_STATUS_PENDING) {
					updatedMenuOptions.push({
						label: t("buttons.cancelApproval"),
						tooltip: t("tooltips.cancelApproval"),
						actionFunction: handleCancelApproval,
						"data-cy": "cancelApproval",
					});
					updatedMenuOptions.push({
						label: t("buttons.sendSmsReminder"),
						tooltip: t("tooltips.sendSmsReminder"),
						actionFunction: handleSendReminder,
						"data-cy": "sendSmsReminder",
					});
				} else if (item.meeting && (!item.workflow || ![APPROVAL_STATUS_PENDING, APPROVAL_STATUS_APPROVED].includes(item.approvalStatus))) {
					if (item.approvalStatus === APPROVAL_STATUS_DENIED) {
						updatedMenuOptions.push({
							label: t("buttons.resubmitItem"),
							tooltip: t("tooltips.resubmitItem"),
							actionFunction: handleSubmitForApproval,
							"data-cy": "resubmitForApproval",
						});
					} else {
						updatedMenuOptions.push({
							label: t("buttons.submitItem"),
							tooltip: t("tooltips.submitItem"),
							actionFunction: handleSubmitForApproval,
							"data-cy": "submitForApproval",
						});
					}
				}
				updatedMenuOptions.push({
					label: t("buttons.duplicate"),
					tooltip: t("tooltips.duplicate"),
					actionFunction: handleDuplicate,
					"data-cy": "duplicate",
				});
			}
			if (item.canDelete) {
				updatedMenuOptions.push({
					label: t("buttons.delete"),
					tooltip: t("tooltips.delete"),
					actionFunction: handleDelete,
					"data-cy": "delete",
				});
			}
		}

		setMenuOptions(updatedMenuOptions);
	}, [item]);

	return (
		<ListCard
			item={item}
			urlField={(item) => `/home/agendaitems/edit/${item.guid}${tab > 0 ? `?tab=${tab}` : ""}`}
			dateField={(item) => item?.meeting?.meetingDate}
			subTitleField={(item) => (
				<>
					{item.submitter}
					{item.meetingId > 0 ? <> &bull; {item.meeting.meeting}</> : ""}
				</>
			)}
			itemClick={(e) => {
				e.preventDefault();

				openItemDetails(item.guid);
			}}
			secondaryContent={
				item.workflow &&
				item.approvalStatus > 0 && (
					<div className={classes.progress}>
						<ApprovalProgress
							workflow={item.workflow}
							expanded={approvalProgressExpanded}
							maxIcons={widthDownMd ? 4 : undefined}
							onToggleExpand={(expanded) => onToggleApprovalProgressExpand(item, expanded)}
						/>
						<RejectedFromMeetingStatus item={item}></RejectedFromMeetingStatus>
					</div>
				)
			}
			menuOptions={menuOptions}
			overflowTooltip={t("app:menu.options")}
			menuAnchor={anchor}
			handleToggleMenu={handleToggleMenu}
			component="div"
			extraContent={getWarningIcon}
		></ListCard>
	);
};

export default AgendaItemListCard;
