/* eslint-disable no-plusplus */
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { withTranslation } from "react-i18next";
import request from "superagent";

import makeStyles from "@mui/styles/makeStyles";
import { Grid, MenuItem } from "@mui/material";

import Icon from "atlas/components/Icon/Icon";
import SelectInput from "atlas/components/FormControls/SelectInput";
import { API_HOST } from "config/env";
import GenericDialog from "atlas/components/Dialogs/GenericDialog";

import inputStyle from "atlas/assets/jss/components/inputStyle";
import notifierMessage from "utils/notifierMessage";
import { setSnackbarOptions } from "../../redux/snackBar/actions";

const useInputStyles = makeStyles(inputStyle);

const useStyles = makeStyles(() => ({
	dialog: {
		"& .MuiDialog-paper": {
			width: "440px",
		},
	},
}));

const TimerPresetEditDialog = (props) => {
	const { t, show, timers, onClose, afterSave } = props;
	const [presets, setPresets] = useState([...(timers.presets || [])]);
	const dispatch = useDispatch();
	const [saving, setSaving] = useState(false);
	const classes = useStyles();
	const inputClasses = useInputStyles({ fullWidth: true });

	const handleError = (err) => {
		console.log("Error", err);
		onClose();
	};

	const handleFieldChange = (e, field, index) => {
		const {
			target: { value },
		} = e;
		presets[index] = {
			...presets[index],
			[field]: value,
		};

		setPresets([...presets]);
	};

	const handleSave = () => {
		setSaving(true);
		request
			.put(`${API_HOST}/api/users/timers`)
			.send({ presets })
			.then(() => {
				let option = notifierMessage(t("timerPresetEditDialog.snackbar.success"), "success");
				dispatch(setSnackbarOptions(option));
				onClose();
				afterSave(presets);
			})
			.catch((err) => handleError(err));
	};

	const handleCancel = () => {
		onClose();
	};

	const getMinutes = () => {
		const minutes = [];
		for (let minute = 1; minute <= 60; minute++) {
			minutes.push(
				<MenuItem key={`minute-${minute}`} value={minute} data-cy={`minute${minute}`}>
					{minute.toString().padStart(2, "0")}
				</MenuItem>,
			);
		}

		return minutes;
	};

	const getSeconds = () => {
		const seconds = [];
		for (let second = 0; second <= 50; second += 10) {
			seconds.push(
				<MenuItem key={`second-${second}`} value={second} data-cy={`second${second}`}>
					{second.toString().padStart(2, "0")}
				</MenuItem>,
			);
		}

		return seconds;
	};

	return (
		<GenericDialog
			className={classes.dialog}
			show={show}
			title={t("timerPresetEditDialog.title")}
			primaryAction={handleSave}
			primaryTitle={t("app:buttons.save")}
			secondaryAction={handleCancel}
			secondaryTitle={t("app:buttons.cancel")}
			secondaryDisabled={saving}
			clickAwayDisabled={saving}
			closeIcon={<Icon name="close" />}
			data-cy="edit-preset-timers"
		>
			{presets.map((preset, index) => (
				<Grid key={`preset${index + 1}`} container spacing={2}>
					<Grid item xs={6}>
						<SelectInput
							id={`minute${index + 1}`}
							className={inputClasses.smallInput}
							noDefaultClassName
							fullWidth
							label={t("fields.minutes")}
							value={preset.minutes}
							onChange={(e) => handleFieldChange(e, "minutes", index)}
							data-cy={`preset-minutes-${index}`}
						>
							{getMinutes()}
						</SelectInput>
					</Grid>
					<Grid item xs={6}>
						<SelectInput
							id={`second${index + 1}`}
							className={inputClasses.smallInput}
							noDefaultClassName
							fullWidth
							label={t("fields.seconds")}
							value={preset.seconds}
							onChange={(e) => handleFieldChange(e, "seconds", index)}
							data-cy={`preset-seconds-${index}`}
						>
							{getSeconds()}
						</SelectInput>
					</Grid>
				</Grid>
			))}
		</GenericDialog>
	);
};

export default withTranslation("meetings")(TimerPresetEditDialog);
