import React, { useState, useContext, useEffect } from "react";
import { useNavigate, useMatch } from "react-router-dom";
import clsx from "clsx";
import makeStyles from "@mui/styles/makeStyles";
import { useTranslation } from "react-i18next";
import { UserContext } from "contexts/User/UserContext";

import { whiteColor } from "atlas/assets/jss/shared";
import { SettingsContext } from "contexts/Settings/SettingsContext";
import WopiFrame from "../Wopi/WopiFrame";
import GenericEditor from "components/Editor/GenericEditor";
import { FOLDER_TYPE_AGENDA_ITEM_COVER_TEMPLATE } from "utils/enums/DocumentFolderTypes";
import telemetryAddEvent from "utils/telemetryAddEvent";
import PdfViewerNew from "../../components/PdfViewerNew/PdfViewerNew";
import request from "superagent";
import { API_HOST } from "config/env";
import { useDispatch } from "react-redux";
import { loadDocument } from "../../redux/document/actions";

const editors = [];

const useStyles = makeStyles(() => ({
	document: {
		height: "100%",
	},
	unavailable: {
		padding: "24px",
		textAlign: "center",
	},
	frame: {
		display: "block",
		border: "none",
		height: "100%",
		width: "100%",
		backgroundColor: whiteColor,
	},
	pdfFrame: {
		height: "100%",
		width: "100%",
		"& iframe": {
			border: "none",
		},
	},
	editorFrame: {
		"& .ck-content": {
			height: "calc(100vh - 150px)",
			overflow: "auto",
		},
	},
	hidden: {
		visibility: "hidden", // Workaround for PDFTron in firefox
		height: "0 !important",
		width: "0 !important",
	},
	dialog: {
		"& .MuiDialog-paper": {
			width: "440px",
		},
	},
	progressBar: {
		width: "240px",
		margin: "0 auto",
		marginTop: "24px",
	},
	agendaItemDocumentFrame: {
		height: "calc(100vh - 214px)",
	},
}));

const DocumentEditContainer = () => {
	const { params: { id: authenticatedId } = {} } = useMatch({ path: "/document/edit/:id", end: true }) || {};
	const id = authenticatedId;
	const { lite, pdfTronL, wopiSettings, testSite } = useContext(SettingsContext);
	const [document, setDocument] = useState(null);
	const classes = useStyles();
	const { t } = useTranslation("documents");
	const {
		documentLibrary: { viewRecycleBin },
	} = useContext(UserContext).user;
	const setEditorsData = (name, value) => {
		editors[name] = value;
	};
	const dispatch = useDispatch();
	const { dateDeleted, extension, canUpdate, wopiDocumentId, guid, dateModified, title, html, parentFolderType } = document || {};

	const getDocument = (id = 0) => {
		dispatch(loadDocument(id))
			.then((res) => {
				if (res.status === 200 && res?.body?.guid !== null) {
					const { body: document } = res || {};
					if (document) {
						setDocument(document);
					}
				} else {
					throw res;
				}
			})
			.catch(async (err) => {
				console.log(err);
			});
	};

	useEffect(() => {
		getDocument(id);
	}, [id]);
	const getFileContainer = () => {
		if (dateDeleted && !viewRecycleBin) {
			// This document has been deleted
			return <div className={classes.unavailable}>{t("detail.labels.documentDeleted")}</div>;
		}

		let fileContainer = null;

		switch (extension) {
			case ".doc":
			case ".docx":
			case ".wopitestx":
			case ".wopitest":
			case ".ppt":
			case ".pptx":
				if (canUpdate) {
					telemetryAddEvent(
						parentFolderType === FOLDER_TYPE_AGENDA_ITEM_COVER_TEMPLATE
							? `Document Detail - Agenda Item Cover Template Edit`
							: `Document Detail - Document Library Edit Office`,
					);

					fileContainer = (
						<div className={clsx(classes.frame)}>
							<WopiFrame id={wopiDocumentId} wopiSettings={wopiSettings} extension={extension} testSite={testSite} />
						</div>
					);
				}
				break;

			case ".pdf":
				fileContainer = (
					<div className={clsx(classes.pdfFrame)}>
						<PdfViewerNew
							id={id}
							settings={{
								lite,
								pdfTronL,
								url: dateDeleted && viewRecycleBin ? `/document/${guid}?deleted=true` : `/document/${guid}?modified=${dateModified}`,
								downloadOpensNewTab: true,
								showPrintButton: false,
								closeLeftPanel: true,
								disableAnnotations: true,
							}}
							selectedDocumentId={id}
						/>
					</div>
				);
				break;

			case ".htm":
			case ".html":
				if (canUpdate && testSite) {
					fileContainer = (
						<div className={clsx(classes.frame)}>
							<GenericEditor
								guid={guid}
								toolbar="scratchpad"
								name="html"
								content={html || ""}
								title={title}
								setEditorsData={setEditorsData}
								onChange={handleHtmlEdit}
								mt={0}
								loadAsync
								preload={{ staticToolbar: true }}
							/>
						</div>
					);
				}
				break;
		}

		return fileContainer;
	};

	return (
		<>
			<div className={clsx(classes.document)}>{getFileContainer()}</div>
		</>
	);
};

export default DocumentEditContainer;
