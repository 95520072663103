import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import clsx from "clsx";

import makeStyles from "@mui/styles/makeStyles";
import { Box } from "@mui/material";

import { useWidthDown } from "atlas/utils/useWidth";
import TableOfContents from "components/Meeting/TableOfContents";
import { setActive } from "redux/meetingTemplate/actions";
import AgendaEditor from "./components/AgendaEditor";
import TableOfContentsContainer from "./components/TableOfContentsContainer";

const useStyles = makeStyles(() => ({
	tableOfContentContainer: {
		overflow: "auto",
		maxWidth: "265px",
		minWidth: "265px",
		height: "calc(100vh - 212px)",
		position: "fixed",
		borderRight: "1px solid #E6E6E6",
		paddingBottom: "8px",
	},
	stickyToolbar: {
		position: "-webkit-sticky",
		position: "sticky",
		top: 0,
		zIndex: 150,
		marginLeft: (props) => (props.showOutline ? "265px" : "0"),
		width: (props) => (props.showOutline ? "calc(100% - 265px)" : "100%"),
		minWidth: "200px",
	},
}));

const telemetryPage = "Meeting Template Agenda";

const MeetingTemplateAgenda = () => {
	const widthDownMd = useWidthDown("md");
	const editorToolbarRef = useRef(null);
	const { meetingTemplate, agenda: { items } = {} } = useSelector((state) => state.meetingTemplatesReducer);
	const classes = useStyles({ showOutline: !widthDownMd });

	return (
		<>
			<TableOfContentsContainer containerClass={classes.tableOfContentContainer} innerId="outline">
				<TableOfContents items={items} isClosed={meetingTemplate.membersOnly} buttons setActive={setActive} telemetryPage={telemetryPage} />
			</TableOfContentsContainer>
			<Box>
				<Box id="toolbar" className={classes.stickyToolbar} ref={editorToolbarRef} />
				<AgendaEditor editorToolbarRef={editorToolbarRef} telemetryPage={telemetryPage} />
			</Box>
		</>
	);
};

export default MeetingTemplateAgenda;
