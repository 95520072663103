import { scrollToElement2 as scrollToElement } from "utils/scrollToElement";

export const scrollToActive = (fieldName, field) => {
	scrollToElement(fieldName);
	const elem = document.getElementById(field ? `${field}-${fieldName}` : `agenda-${fieldName}`);
	if (elem) {
		scrollToElement(elem, document.getElementById("content-scroll"), -48);
	}
	const tocElement = document.getElementById(`outline-minute-${fieldName}`);
	if (tocElement) {
		scrollToElement(tocElement, document.getElementById("minute-outline"));
	}
};

export const focusEditor = (fieldName, attempts = 0) => {
	const root = window.editor.model.document.getRoot(fieldName);
	let element = null;
	if (root) {
		element = document.querySelectorAll(`[data-fieldname="${fieldName}"]`);
	}
	if (element && element.length > 0) {
		element[0].focus();
		scrollToActive(fieldName);
	} else if (attempts < 10) {
		// Try again
		setTimeout(() => {
			focusEditor(fieldName, attempts + 1);
		}, 100);
	}
};
