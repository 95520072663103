import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import request from "superagent";

import withStyles from "@mui/styles/withStyles";
import Button from "@mui/material/Button";

import isEqual from "lodash/fp/isEqual";
import ComponentContainer from "atlas/components/ComponentContainer/ComponentContainer";
import { whiteColor } from "atlas/assets/jss/shared";
import withErrorHandling from "components/ErrorHOC";
import RightPanelContainer, { MD_DOWN_WIDTH } from "components/Panels/RightPanelContainer";
import FilterPanel, { DEFAULT_SEARCH_FIELD } from "components/Panels/FilterPanel";
import { API_HOST } from "config/env";
import UserList from "./components/UserList";
import UserFilter from "./components/UserFilter";
import telemetryAddEvent from "utils/telemetryAddEvent";
import SendPasswordSetupRequestDialog from "components/Dialogs/SendPasswordSetupRequestDialog";
import { resetPageConfigs, updatePageConfigs, updateToolbar } from "redux/app/actions";
import { updatePageHeader } from "redux/pageHeader/actions";
import notifierMessage from "utils/notifierMessage";
import { setSnackbarOptions } from "redux/snackBar/actions";

const StyledButton = withStyles({
	root: {
		color: whiteColor,
		marginTop: "0",
	},
})(Button);

const telemetryPage = "User List";
const defaultFilter = {
	[DEFAULT_SEARCH_FIELD]: "",
	searchLowerCase: "",
	userType: 0,
	department: 0,
	boards: [],
};

const UsersModule = (props) => {
	const initializeFilter = () => {
		const active = { ...defaultFilter };

		return {
			active,
			stack: [active],
			index: 0,
		};
	};

	const { showSignIn } = props;
	const { t } = useTranslation("users");
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [users, setUsers] = useState(null);
	const [selectedUsers, setSelectedUsers] = useState([]);
	const [filteredUsers, setFilteredUsers] = useState(null);
	const [panels, setPanels] = useState({});
	const [departments, setDepartments] = useState(null);
	const [meetingGroups, setMeetingGroups] = useState(null);
	const [filter, setFilter] = useState(initializeFilter());
	const [filtered, setFiltered] = useState(false);
	const [openSendPasswordSetupRequestDialog, setOpenSendPasswordSetupRequestDialog] = useState(false);

	const loadUsers = () => {
		request
			.get(`${API_HOST}/api/users?details=true`)
			.then((res) => {
				setUsers(
					res.body.map((user) => {
						// Used for filtering
						user.firstNameLowerCase = (user.firstName || "").toLowerCase();
						user.lastNameLowerCase = (user.lastName || "").toLowerCase();
						user.userNameLowerCase = (user.userName || "").toLowerCase();
						user.emailAddressLowerCase = (user.emailAddress || "").toLowerCase();

						user.inactive = !!user.inactive; // Ensure this is set

						return user;
					}),
				);
			})
			.catch((err) => {
				showSignIn(err, () => {
					loadUsers();
				});
			});
	};

	const loadDepartments = () => {
		request
			.get(`${API_HOST}/api/groups?departments=true`)
			.then((res) => {
				setDepartments(res.body);
			})
			.catch((err) => {
				showSignIn(err, () => {
					loadDepartments();
				});
			});
	};

	const loadMeetingGroups = () => {
		request
			.get(`${API_HOST}/api/boards?basic=true`)
			.then((res) => {
				setMeetingGroups(res.body.boards);
			})
			.catch((err) => {
				showSignIn(err, () => {
					loadMeetingGroups();
				});
			});
	};

	const isOnlyInactiveSelected = (selections) =>
		selections.length > 0 &&
		users
			.filter((user) => selections.includes(user.id))
			.reduce((onlyInactiveSelected, user) => onlyInactiveSelected && user.inactive, true);

	const createUser = () => {
		telemetryAddEvent(`${telemetryPage} - Add User`, { area: "users" });

		navigate("/users/edit");
	};

	const copyUser = () => {
		if (selectedUsers.length === 1) {
			telemetryAddEvent(`${telemetryPage} - Copy user`, { area: "users" });

			navigate(`/users/edit?copy=${selectedUsers[0]}`);
		}
	};

	const deactivateUser = () => {
		const onlyInactiveSelected = isOnlyInactiveSelected(selectedUsers);

		request
			.post(`${API_HOST}/api/users/toggleactivation`)
			.send({ selectedUsers, deactivate: !onlyInactiveSelected })
			.then((res) => {
				setUsers((prev) => {
					res.body.forEach((updatedUser) => {
						var user = prev.find((user) => user.id === updatedUser.id);
						if (user) {
							// Update the inactive flag
							user.inactive = !!updatedUser.inactive;
						}
					});

					return [...prev];
				});

				let option = notifierMessage(t(`list.snackbar.${!onlyInactiveSelected ? "userDeactivated" : "userActivated"}`), "success");
				option.autoHideDuration = 5000;
				dispatch(setSnackbarOptions(option));
			})
			.catch((err) => {
				showSignIn(err, () => {
					deactivateUser();
				});
			});
	};

	const userChecked = (ids, checked) => {
		if (ids && ids.length > 0) {
			setSelectedUsers((prev) => {
				let updated;
				if (checked) {
					updated = [...new Set([...prev, ...ids])]; // Set ensures that the merged values are unique
				} else {
					updated = [...prev].reduce((list, value) => {
						if (!ids.includes(value)) {
							list.push(value);
						}

						return list;
					}, []);
				}

				return updated;
			});
		}
	};

	const loadFilteredUsers = (filter) => {
		if (isApiFiltered(filter)) {
			request
				.get(`${API_HOST}/api/users/filter`)
				.query({
					userType: filter.userType ? filter.userType : undefined,
					groups: filter.department ? [filter.department] : undefined,
					boards: filter.boards && filter.boards.length > 0 ? filter.boards : undefined,
				})
				.then((res) => {
					setFilteredUsers(res.body);
				})
				.catch((err) => {
					showSignIn(err, () => {
						loadFilteredUsers();
					});
				});
		} else {
			setFilteredUsers(null);
		}
	};

	const filterClick = () => {
		setPanels((prev) => ({
			filter: !prev.filter,
		}));
	};

	const closeFilter = () => {
		setPanels({
			filter: false,
		});
	};

	const isFiltered = (filter) =>
		Boolean(filter && (filter[DEFAULT_SEARCH_FIELD] || filter.userType || filter.department || (filter.boards && filter.boards.length)));

	const isApiFiltered = (filter) => Boolean(filter && (filter.userType || filter.department || (filter.boards && filter.boards.length)));

	const filterUser = (user) => {
		let passesFilter = true;

		if (filter.active[DEFAULT_SEARCH_FIELD]) {
			passesFilter &=
				user.firstNameLowerCase.indexOf(filter.active.searchLowerCase) >= 0 ||
				user.lastNameLowerCase.indexOf(filter.active.searchLowerCase) >= 0 ||
				user.userNameLowerCase.indexOf(filter.active.searchLowerCase) >= 0 ||
				user.emailAddressLowerCase.indexOf(filter.active.searchLowerCase) >= 0;
		}

		if (filteredUsers) {
			passesFilter &= filteredUsers.includes(user.id);
		}

		return passesFilter;
	};

	const filterNeedsReloadFromApi = (newFilter, prevFilter) =>
		newFilter
			? !prevFilter
				? isApiFiltered(newFilter)
				: newFilter.userType !== prevFilter.userType ||
				  newFilter.department !== prevFilter.department ||
				  !isEqual(newFilter.boards, prevFilter.boards)
			: false;

	const filterChange = (newActive, push, pop, clear) => {
		setFilter((prev) => {
			let newFilter;
			if (clear || (pop && prev.index <= 1) || (!pop && !isFiltered(newActive))) {
				// Clear the filter and undo history
				newFilter = initializeFilter();
			} else if (pop) {
				// Undo the last filter change
				newFilter = {
					active: prev.stack[prev.index - 1],
					stack: prev.stack,
					index: prev.index - 1,
				};
			} else {
				// Update the filter and optionally add a new undo point
				newActive.searchLowerCase = (newActive[DEFAULT_SEARCH_FIELD] || "").toLowerCase();

				const newStack = prev.stack.slice(0, prev.index + 1); // Clear forward undo points
				if (push) {
					newStack.push(newActive);
				}
				newFilter = {
					active: newActive,
					stack: newStack,
					index: prev.index + (push ? 1 : 0),
				};
			}

			if (filterNeedsReloadFromApi(newFilter.active, prev.active)) {
				loadFilteredUsers(newFilter.active);
			}

			const activeFiltered = isFiltered(newFilter.active);
			if (activeFiltered && newFilter.index > prev.index) {
				let option = notifierMessage(t("filter.notifications.filterApplied"), "success", () => undoClick());
				option.autoHideDuration = 5000;
				dispatch(setSnackbarOptions(option));
			}

			setFiltered(activeFiltered);

			return newFilter;
		});

		telemetryAddEvent(`${telemetryPage} - Change filter`, { area: "users" });
	};

	const undoClick = () => filterChange(null, false, true);

	const sendPasswordSetup = (userIds) => {
		if (userIds && userIds.length > 0) {
			telemetryAddEvent(`${telemetryPage} - Password Set-up request ${userIds.length > 1 ? "Group" : "by user"}`, { area: "users" });

			request
				.post(`${API_HOST}/api/users/passwordsetup`)
				.send({ users: userIds })
				.then((res) => {
					setOpenSendPasswordSetupRequestDialog(false);
					let option = notifierMessage(t("notification.passwordSetupSent"), "success");
					dispatch(setSnackbarOptions(option));
					loadUsers();
				})
				.catch((err) => {
					showSignIn(err, () => {
						loadUsers();
					});
				});
		}
	};

	useEffect(() => {
		loadUsers();
		loadDepartments();
		loadMeetingGroups();

		dispatch(resetPageConfigs({ resetBack: true }));
		dispatch(
			updatePageConfigs({
				title: t("title.users"),
				telemetryPage,
				preferedBack: { url: "/users" },
			}),
		);
		dispatch(
			updatePageHeader({
				primaryAction: createUser,
				primaryActionText: t("buttons.newUser"),
				primaryActionTooltip: t("tooltips.newUser"),
			}),
		);
	}, []);

	useEffect(() => {
		const onlyInactiveSelected = isOnlyInactiveSelected(selectedUsers);

		dispatch(
			updateToolbar({
				display: true,
				left: {
					tools: [
						{
							id: "copy-user",
							label: t("buttons.copyUser"),
							tooltipText: t("tooltips.copyUser"),
							onClick: copyUser,
							disabled: selectedUsers.length !== 1,
							dataCy: "copy-user",
						},
						{
							id: "deactivate-user",
							label: t(`buttons.${onlyInactiveSelected ? "activate" : "deactivate"}`),
							tooltipText: t(`tooltips.${onlyInactiveSelected ? "activate" : "deactivate"}`),
							onClick: deactivateUser,
							disabled: selectedUsers.length === 0,
							dataCy: "deactivate-user",
						},
						{
							id: "send-password-setup-request",
							label: t("buttons.sendPasswordSetupRequest"),
							tooltipText: t("tooltips.sendMultiplePasswordSetupRequest"),
							onClick: () => {
								setOpenSendPasswordSetupRequestDialog(true);
							},
							dataCy: "send-password-setup-request",
						},
					],
				},
				right: {
					tools: [
						{
							id: "openFilter",
							icon: "filter",
							tooltipText: t("tooltips.filter"),
							ariaLabel: t("tooltips.filter"),
							onClick: filterClick,
							dataCy: "toggle-filter",
							badgeProps: {
								display: filtered,
								dataCy: "filtered",
							},
						},
					],
				},
			}),
		);
	}, [users, selectedUsers, filtered]);

	return (
		<ComponentContainer padding="0">
			<UserList users={users ? users.filter(filterUser) : null} userChecked={userChecked} sendPasswordSetup={sendPasswordSetup}></UserList>
			<RightPanelContainer id="right-panel-container" open={panels.filter} float drawerWidth={MD_DOWN_WIDTH} fullHeight>
				<FilterPanel filters={filter.active} onFilterChange={filterChange} isFiltered={filtered} closeFilter={closeFilter} useSearch>
					<UserFilter
						filters={filter.active}
						onFilterChange={filterChange}
						departments={departments}
						meetingGroups={meetingGroups}
					></UserFilter>
				</FilterPanel>
			</RightPanelContainer>
			{openSendPasswordSetupRequestDialog && (
				<SendPasswordSetupRequestDialog
					show={openSendPasswordSetupRequestDialog}
					users={users.filter((user) => user.passwordExpiryDate)}
					handleSendPasswordSetup={sendPasswordSetup}
					handleCancel={() => {
						setOpenSendPasswordSetupRequestDialog(false);
					}}
				/>
			)}
		</ComponentContainer>
	);
};

export default withErrorHandling(UsersModule);
