import { blackColor, grayColor } from "atlas/assets/jss/shared";

const border = `solid 1px ${grayColor[1]}`;

const permissionsStyle = (theme) => ({
	columns: {
		display: "flex",
		flexWrap: "wrap",
		gap: "24px",
		[theme.breakpoints.down("sm")]: {
			margin: "0",
		},
		"& > div": {
			boxSizing: "border-box",
			[theme.breakpoints.down("sm")]: {
				margin: "0",
			},
		},
		"& > div:first-child": {
			width: "calc(30% - 24px)",
			[theme.breakpoints.down("sm")]: {
				width: "100%",
			},
		},
		"& > div:not(:first-child)": {
			width: "calc(70% - 24px)",
			[theme.breakpoints.down("sm")]: {
				width: "100%",
			},
		},
	},
	columnsCenterAlign: {
		alignItems: "center",
	},
	inputLabelSpacing: {
		marginTop: "8px",
	},
	userList: {
		border,
		borderRadius: "5px",
		height: "350px",
		overflowY: "auto",
		overflowX: "hidden",
		color: blackColor[1],
		"& ol": {
			margin: "0",
			padding: "0",
			listStyleType: "none",
		},
	},
	userListItem: {
		margin: "0",
		display: "flex",
		alignItems: "center",
		height: "48px",
		"&:hover": {
			backgroundColor: grayColor[4],
		},
	},
	userListItemName: {
		flexGrow: "1",
		padding: "0 8px",
	},
	userListMenu: {
		cursor: "pointer",
	},
	selectedUsers: {
		border,
		borderRadius: "5px",
		"& ol": {
			margin: "0",
			padding: "0",
			listStyleType: "none",
		},
	},
	noUsers: {
		display: "flex",
		alignItems: "center",
		height: "48px",
		textAlign: "center",
		"& > div": {
			flexGrow: "1",
		},
	},
});

export default permissionsStyle;
