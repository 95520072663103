import { primaryColor } from "atlas/assets/jss/shared";

const entityListStyle = {
	listContainer: {
		maxWidth: "100%",
	},
	list: {
		padding: "0",
		maxWidth: "calc(100vw - 32px)", // - 16px on each side
	},
	headCell: {
		display: "none",
	},
	noResults: {
		padding: "16px",
	},
	detail: {
		display: "flex",
		alignItems: "center",
		padding: "10px",
	},
	cell: {
		padding: 0,
		color: primaryColor[1],
	},
	link: {
		color: primaryColor[1],
	},
};

export default entityListStyle;
