export const BOTTOM_LEFT = 'bottom-left';
export const BOTTOM = 'bottom';
export const BOTTOM_RIGHT = 'bottom-right';
export const TOP_LEFT = 'top-left';
export const TOP_RIGHT = 'top-right';
export const TOP = 'top';

const placement = {
	BOTTOM,
	BOTTOM_LEFT,
	BOTTOM_RIGHT,
	TOP_LEFT,
	TOP_RIGHT,
	TOP,
};

export default placement;
