import React, { useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import request from "superagent";

import makeStyles from "@mui/styles/makeStyles";

import OutlinedInput from "atlas/components/FormControls/OutlinedInput";
import Icon from "atlas/components/Icon/Icon";
import { API_HOST } from "config/env";
import GenericDialog from "atlas/components/Dialogs/GenericDialog";
import notifierMessage from "utils/notifierMessage";
import { setSnackbarOptions } from "redux/snackBar/actions";
import ellipsedTruncateText from "utils/ellipsedTruncateText";

const useStyles = makeStyles(() => ({
	dialog: {
		"& .MuiDialog-paper": {
			width: "480px",
			maxWidth: "100%",
		},
	},
}));

const RenameDialog = (props) => {
	const { show = true, document = {}, onClose, afterRename, showSignIn, isEllipsed = false } = props;
	const { t } = useTranslation("documents");
	const dispatch = useDispatch();
	const [title, setTitle] = useState(document.title || "");
	const [renaming, setRenaming] = useState(false);
	const classes = useStyles();

	const handleRename = useCallback(() => {
		setRenaming(true);

		request
			.patch(`${API_HOST}/api/document/${document.guid}/detail`)
			.withCredentials()
			.send({ title })
			.then((res) => {
				let option = notifierMessage(t("renameDialog.snackbar.success", { title }), "success");
				dispatch(setSnackbarOptions(option));
				onClose();

				// Update the object in place with the new title and modified date / modified by
				Object.assign(document, res.body);
				afterRename(document);
			})
			.catch((err) => {
				showSignIn(err, () => {
					handleRename();
				});
			});
	}, [title]);

	const handleCancel = () => {
		onClose();
	};

	const i18n = t("renameDialog", { title: document.title });

	const dialog = {
		title: i18n.title,
		primaryTitle: t("app:buttons.rename"),
		primaryAction: handleRename,
		secondaryTitle: t("app:buttons.cancel"),
		secondaryAction: handleCancel,
	};

	return (
		<GenericDialog
			ellipsedTruncatedText={ellipsedTruncateText(dialog.title)}
			isEllipsed={isEllipsed}
			className={classes.dialog}
			show={show}
			title={dialog.title}
			primaryAction={dialog.primaryAction}
			primaryTitle={dialog.primaryTitle}
			primaryDisabled={renaming || !title}
			secondaryAction={dialog.secondaryAction}
			secondaryTitle={dialog.secondaryTitle}
			secondaryDisabled={renaming}
			clickAwayDisabled={renaming}
			closeIcon={<Icon name="close" />}
			data-cy="rename-dialog"
		>
			<OutlinedInput
				id="name"
				label={t("renameDialog.labels.name")}
				placeholder={t("renameDialog.placeholders.name")}
				value={title}
				onChange={(e) => setTitle(e.target.value)}
				noDefaultClassName
				fullWidth
				size="small"
				autoFocus
				data-cy="name"
			/>
		</GenericDialog>
	);
};

export default RenameDialog;
