// This is intended to be used with query string parameters to allow them to work regardless of the casing of the URL
const getProperty = (obj, prop) => {
	if (!obj || typeof prop !== "string") {
		return undefined;
	}

	const lowerProp = prop.toLowerCase();
	const keys = Object.keys(obj);
	for (let index = 0; index < keys.length; index += 1) {
		if (lowerProp === keys[index].toLowerCase()) {
			return obj[keys[index]];
		}
	}

	return undefined;
};

export default getProperty;
