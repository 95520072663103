import React from "react";
import SvgIcon from "@mui/material/SvgIcon";

import themeConfig from "../../atlas/assets/jss/theme";
import getOr from "lodash/fp/getOr";

const icon = (props) => {
	const { color } = props;
	const fillColor = color ? getOr(color, ["palette", color, "main"], themeConfig) : "currentColor";

	return (
		<SvgIcon width="26" height="26" viewBox="0 0 26 26" {...props}>
			<g fill="none" fillRule="evenodd" stroke={fillColor} strokeLinecap="round" strokeWidth="2" transform="translate(1 1)">
				<path strokeLinejoin="round" d="M8,0 L8,24" />
				<polygon points="0 0 24 0 24 24 0 24" />
			</g>
		</SvgIcon>
	);
};

export default icon;
