const supportedBrowsers = {
	default: { chrome: {}, firefox: {}, electron: {} },
	android: { chrome: {}, firefox: {}, opera: {} },
	windows: { chrome: {}, firefox: {}, edge: {}, opera: {} },
	ios: { safari: {}, "mobile safari": {}, chrome: {} },
	"mac os": { safari: {}, chrome: {} },
};
const chrome = { label: "Google Chrome", link: "https://www.google.com/chrome" };
const firefox = { label: "Mozilla Firefox", link: "https://www.mozilla.org/en-US/firefox/new" };
const edge = { label: "Microsoft Edge", link: "https://www.microsoft.com/en-us/windows/microsoft-edge" };
const safari = { label: "Safari", link: "https://www.apple.com/safari" };
const opera = { label: "Opera", link: "https://www.opera.com/" };

export default function checkBrowserSupport(osName, browserName) {
	let supported = false;

	if (
		(supportedBrowsers[osName.toLowerCase()] && supportedBrowsers[osName.toLowerCase()][browserName.toLowerCase()]) ||
		supportedBrowsers.default[browserName.toLowerCase()]
	) {
		supported = true;
	}

	return supported;
}

export function getSupportedBrowsers(osName) {
	const supportedOSBrowsers = [];

	switch (osName.toLowerCase()) {
		case "android":
			supportedOSBrowsers.push(chrome);
			supportedOSBrowsers.push(firefox);
			supportedOSBrowsers.push(opera); //not officially supported
			break;

		case "windows":
			supportedOSBrowsers.push(chrome);
			supportedOSBrowsers.push(firefox);
			supportedOSBrowsers.push(edge);
			supportedOSBrowsers.push(opera); //not officially supported
			break;

		case "ios":
			supportedOSBrowsers.push(chrome);
			supportedOSBrowsers.push(safari);
			break;

		case "mac os":
			supportedOSBrowsers.push(chrome);
			supportedOSBrowsers.push(safari);
			break;

		default:
			supportedOSBrowsers.push(chrome);
			supportedOSBrowsers.push(firefox);
			break;
	}

	return supportedOSBrowsers;
}
