/* eslint-disable no-param-reassign */
import React, { useState, useEffect, useRef, useCallback } from "react";
import useBackButtonHandler from "utils/hooks/useBackButtonHandler.js";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation, useMatch } from "react-router-dom";
import { useTranslation } from "react-i18next";
import request from "superagent";
import { v4 as uuid } from "uuid";

import Typography from "@mui/material/Typography";
import CircularProgressIndicator from "atlas/components/Progress/CircularProgressIndicator";
import ComponentContainer from "atlas/components/ComponentContainer/ComponentContainer";
import withErrorHandling from "components/ErrorHOC";
import { API_HOST } from "config/env";
import telemetryAddEvent from "utils/telemetryAddEvent";
import Board from "./Board";
import { useUpdateObject, initializeValidate, setValidate } from "utils/updateObject";
import { updateHandlers, PROGRESS_HUB } from "utils/communication/SignalrClient";
import { resetPageConfigs, updatePageConfigs } from "redux/app/actions";
import { updatePageHeader } from "redux/pageHeader/actions";
import BoardSaveProgress from "./components/BoardSaveProgress";
import BoardMeetingTypeReassignDialog from "./components/BoardMeetingTypeReassignDialog";
import BoardDeleteDialog from "./components/BoardDeleteDialog";
import notifierMessage from "utils/notifierMessage";
import { setSnackbarOptions } from "redux/snackBar/actions";

const telemetryPage = "Meeting Group Detail";
const clearErrors = {
	name: "",
	blocks: [],
};
const clearSavingState = {
	initialized: false,
	isSaving: false,
	updated: false,
	canDelete: false,
};
const clearSavingProgress = {
	label: " ",
	percent: 0,
};

const BoardContainer = (props) => {
	const { showSignIn } = props;
	const { params: { id } = {} } = useMatch({ path: "/boards/edit/:id", end: true }) || {};
	const { t } = useTranslation("boards");
	const navigate = useNavigate();
	const location = useLocation();
	const [board, setBoard] = useState(null);
	const [initialMeetingTypes, setInitialMeetingTypes] = useState([]);
	const [reassignments, setReassignments] = useState([]);
	const [meetingTypes, setMeetingTypes] = useState(null);
	const [users, setUsers] = useState(null);
	const [errors, setErrors] = useState({
		...clearErrors,
	});
	const [savingState, setSavingState] = useState({
		...clearSavingState,
	});
	const [progress, setProgress] = useState({
		...clearSavingProgress,
	});
	const [requestError, setRequestError] = useState(null);
	const [dialogs, setDialogs] = useState({});
	const boardRef = useRef(board); // For use in functions that have stale references
	const reassignmentsRef = useRef(reassignments); // For use in functions that have stale references
	const errorsRef = useRef(errors); // For use in functions that have stale references
	const savingStateRef = useRef(savingState); // For use in functions that have stale references
	const saveResult = useRef(null);
	const guid = useRef();
	const appReducer = useSelector((state) => state.appReducer);
	const {
		signalR: { client, handler },
	} = appReducer;
	const dispatch = useDispatch();

	const loadBoard = (id = 0) => {
		request
			.get(`${API_HOST}/api/board/${id}`)
			.withCredentials()
			.then((res) => {
				const { body: board } = res || {};
				if (board) {
					if (location.state) {
						board.users.forEach((boardUser) => {
							// Copy the roll call settings from the new board after history replacement because the asynchronous roll call meeting type update may not be complete yet and the new board object has old data
							const prevBoardUser = location.state.find((prevBoardUser) => prevBoardUser.userId === boardUser.userId);
							if (prevBoardUser) {
								boardUser.votingMember = prevBoardUser.votingMember;
								boardUser.tieBreaker = prevBoardUser.tieBreaker;
								boardUser.showOnPortal = prevBoardUser.showOnPortal;
							}
						});

						navigate(`/boards/edit/${board.id}`, { replace: true, state: null });
					}

					setBoard(board);
					setInitialMeetingTypes([...board.meetingTypes]);
					setReassignments([]);
					initializeValidate(setErrors, board.id > 0, clearErrors);
					setSavingState({
						...clearSavingState,
						canDelete: board.meetingTypes.length === 0,
					});
				}
			})
			.catch((err) => {
				showSignIn(
					err,
					() => {
						loadBoard(id);
					},
					() => {
						const errormsg = err.response.body && err.response.body.Message ? err.response.body.Message : `${err.status} ${err.message}`;
						setRequestError(errormsg);
						setTimeout(() => {
							window.location.href = `${API_HOST}/home/boards`;
						}, 1500);
					},
				);
			});
	};

	const loadMeetingTypes = () => {
		request
			.get(`${API_HOST}/api/meetingtypes`)
			.withCredentials()
			.then((res) => {
				const { body: meetingTypes } = res || {};

				setMeetingTypes(meetingTypes);
			})
			.catch((err) => {
				showSignIn(err, () => {
					loadMeetingTypes();
				});
			});
	};

	const loadUsers = () => {
		request
			.get(`${API_HOST}/api/users?includeDeleted=true&includeExternal=true&firstNameLastName=true`)
			.withCredentials()
			.then((res) => {
				const { body: users } = res || {};
				if (users) {
					// Give each user a number to use for the avatar background
					let number = 0;
					const numberCache = {};
					users.forEach((user) => {
						if (typeof numberCache[user.id] === "undefined") {
							numberCache[user.id] = number;
							number++;
						}
						user.number = numberCache[user.id];
					});

					setUsers(users);
				}
			})
			.catch((err) => {
				showSignIn(err, () => {
					loadUsers();
				});
			});
	};

	const backToBoards = () => {
		const { isSaving, updated } = savingStateRef.current;

		if (isSaving || updated) {
			if (confirm(t("detail.unsavedChanges"))) {
				navigate("/boards");
			} else {
				//Since the page will navigate if we return false
				//Returning undefined to prevent the navigation
				return undefined;
			}
		} else {
			navigate("/boards");
		}

		return true;
	};

	const unsavedChangesText = t("detail.unsavedChanges");
	useBackButtonHandler(savingState, "/boards", unsavedChangesText);

	const handleSave = () => {
		// Enable full validation
		initializeValidate(setErrors, true);

		if (!hasValidationErrors(errorsRef.current)) {
			saveBoard(boardRef.current, reassignmentsRef.current);
		}
	};

	const handleDelete = () => {
		setDialogs({ delete: true });
	};

	const closeDeleteDialog = () => {
		setDialogs({});
	};

	const afterDelete = () => {
		navigate("/boards");
	};

	const undoDelete = (deletedBoard) => {
		request
			.post(`${API_HOST}/api/board/${deletedBoard.id}/restore`)
			.withCredentials()
			.send({ meetingTypes: deletedBoard.meetingTypes })
			.then(() => {
				let option = notifierMessage(t("deleteBoardDialog.undo.successful"), "success");
				dispatch(setSnackbarOptions(option));

				navigate(`/boards/edit/${deletedBoard.id}`);
			})
			.catch((err) => {
				showSignIn(err, () => {
					undoDelete(deletedBoard);
				});
			});
	};

	const clearProgress = (show) => {
		setDialogs({ saveProgress: show });
		setProgress({
			...clearSavingProgress,
		});
	};

	const completeSave = () => {
		if (saveResult.current) {
			const { board, updatedBoard } = saveResult.current;

			if (updatedBoard.id !== board.id) {
				navigate(`/boards/edit/${board.id}`, { replace: true, state: updatedBoard.users });
			}

			setBoard((prev) => {
				board.users.forEach((boardUser) => {
					// Copy the roll call settings because the asynchronous roll call meeting type update may not be complete yet and the new board object has old data
					const prevBoardUser = prev.users.find((prevBoardUser) => prevBoardUser.userId === boardUser.userId);
					if (prevBoardUser) {
						boardUser.votingMember = prevBoardUser.votingMember;
						boardUser.tieBreaker = prevBoardUser.tieBreaker;
						boardUser.showOnPortal = prevBoardUser.showOnPortal;
					}
				});

				return board;
			});
			setInitialMeetingTypes([...board.meetingTypes]);
			setReassignments([]);
			setSavingState((prev) => ({
				...prev,
				initialized: false,
				isSaving: false,
				canDelete: board.meetingTypes.length === 0,
			}));
			clearProgress(false);

			let option = notifierMessage(t("detail.snackbar.saved"), "success");
			dispatch(setSnackbarOptions(option));

			saveResult.current = null;
		}
	};

	const updateProgress = (percentage, message) => {
		setProgress({
			label: message,
			percent: percentage,
		});
		if (percentage >= 100) {
			completeSave();
		}
	};

	const saveBoard = (updatedBoard, updatedReassignments) => {
		setSavingState((prev) => ({
			...prev,
			isSaving: true,
			updated: false,
		}));
		clearProgress(true);

		request
			.put(`${API_HOST}/api/board/${updatedBoard.id}`)
			.withCredentials()
			.send({
				...updatedBoard,
				reassignments: updatedReassignments.filter((reassignment) => !updatedBoard.meetingTypes.includes(reassignment.meetingTypeId)),
				progressGuid: guid.current,
			})
			.then((res) => {
				if (res.status === 200) {
					const { body: board = {} } = res || {};
					saveResult.current = { board, updatedBoard };
					if (!board.updatingPermissions) {
						completeSave();
					}
				}
			})
			.catch((err) => {
				showSignIn(err, () => {
					saveBoard(updatedBoard, updatedReassignments);
				});
			});
	};

	const sanitizeData = (updatedBoard, objectUpdated, updateValue) => {
		// Ensure that there is only one tie breaker
		const tieBreakerId =
			updateValue && updateValue.value && updateValue.value.userId && updateValue.value.tieBreaker
				? updateValue.value.userId
				: objectUpdated && objectUpdated.userId && objectUpdated.tieBreaker
					? objectUpdated.userId
					: 0;
		if (tieBreakerId > 0) {
			updatedBoard.users.forEach((boardUser) => {
				if (boardUser.userId !== tieBreakerId) {
					boardUser.tieBreaker = false;
				}
			});
		}
	};

	const validateBoard = (updatedBoard, updatedField) => {
		setErrors((prev) => {
			if (!updatedBoard.name) {
				prev.name = t("detail.validation.name");
			} else {
				prev.name = "";
			}

			if (updatedBoard.id === 0) {
				if (updatedBoard.meetingTypes.length === 0) {
					prev.meetingTypes = t("detail.validation.meetingTypes");
					prev.noMeetingTypes = true;
				} else {
					prev.meetingTypes = "";
					delete prev.noMeetingTypes;
				}

				if (updatedBoard.users.length === 0) {
					prev.users = t("detail.validation.users");
					prev.noUsers = true;
				} else {
					prev.users = "";
					delete prev.noUsers;
				}
			}

			prev.invalidUsers = !!updatedBoard.users.find((boardUser) => !boardUser.member && !boardUser.staff && !boardUser.administrator);

			setValidate(prev, updatedField);

			return { ...prev };
		});
	};

	const hasValidationErrors = (errorsObject) =>
		errorsObject.name || errorsObject.noMeetingTypes || errorsObject.noUsers || errorsObject.invalidUsers;

	const updateBoard = useUpdateObject(setBoard, sanitizeData, validateBoard, () => {
		setSavingState((prev) => ({
			...prev,
			updated: true,
		}));
	});

	const removeMeetingType = (meetingTypeId) => {
		updateBoard({ target: { value: meetingTypeId } }, "meetingTypes", false, true, undefined, (newValue, prevValue) =>
			prevValue.filter((prevMeetingType) => prevMeetingType !== newValue),
		);
	};

	const handleMeetingTypeRemove = useCallback(
		(meetingTypeId) => {
			if (initialMeetingTypes.includes(meetingTypeId)) {
				setDialogs({
					removeAndReassignMeetingType: meetingTypes.find((meetingType) => meetingType.id === meetingTypeId),
				});
			} else {
				removeMeetingType(meetingTypeId);
			}
		},
		[initialMeetingTypes, meetingTypes],
	);

	const closeRemoveAndReassignDialog = () => {
		setDialogs({});
	};

	const handleRemoveAndReassign = (boardId) => {
		const {
			removeAndReassignMeetingType: { id: meetingTypeId },
		} = dialogs;

		removeMeetingType(meetingTypeId);
		setReassignments((prev) =>
			prev
				.filter((reassignment) => reassignment.meetingTypeId !== dialogs.removeAndReassignMeetingType.id)
				.concat([
					{
						meetingTypeId: dialogs.removeAndReassignMeetingType.id,
						boardId,
					},
				]),
		);
		closeRemoveAndReassignDialog();
	};

	useEffect(() => {
		// Set the block user values for the avatar and labels
		if (board && users && !savingState.initialized) {
			board.users.forEach((boardUser) => {
				const user = users.find((user) => user.id === boardUser.id);
				if (user) {
					boardUser.number = user.number;
					boardUser.userInactive = user.inactive;
					boardUser.userDeleted = user.deleted;
				}
			});

			setSavingState((prev) => ({
				...prev,
				initialized: true,
			}));
		}
	}, [!!board, !!users, savingState.initialized]);

	useEffect(() => {
		boardRef.current = board;
		reassignmentsRef.current = reassignments;
		errorsRef.current = errors;
		savingStateRef.current = savingState;
	}, [board, reassignments, errors, savingState]);

	useEffect(() => {
		guid.current = uuid();

		updateHandlers(dispatch, handler, PROGRESS_HUB, { announceProgress: updateProgress });

		client.ensureStarted().then(() => client.progressHub.registerProgressGuid(guid.current));

		dispatch(resetPageConfigs({}));
		dispatch(
			updatePageConfigs({
				title: t("title.board"),
				back: { action: backToBoards },
				telemetryPage,
			}),
		);

		const boardId = !isNaN(parseInt(id, 10)) ? parseInt(id, 10) : 0;
		loadBoard(boardId);
		loadMeetingTypes();
		loadUsers();

		if (boardId) {
			telemetryAddEvent(`${telemetryPage} - Open`, { area: "meetingGroups" });
		}

		return () => {
			client.ensureStarted().then(() => client.progressHub.clearProgressGuid(guid.current));

			updateHandlers(dispatch, handler, PROGRESS_HUB, { announceProgress: null });

			document.body.style.userSelect = null;
		};
	}, [id]);

	useEffect(() => {
		const menuOptions = [];
		if (board && board.id > 0 && savingState.canDelete) {
			menuOptions.push({
				label: t("app:buttons.delete"),
				tooltip: t("tooltips.delete"),
				actionFunction: handleDelete,
				"data-cy": "delete",
			});
		}

		dispatch(
			updatePageHeader({
				primaryAction: handleSave,
				primaryActionText: !savingState.isSaving ? t("app:buttons.save") : t("app:buttons.saving"),
				primaryActionTooltip: !savingState.isSaving ? t("tooltips.save") : undefined,
				primaryActionDisabled: savingState.isSaving || !savingState.updated,
				menuOptions,
			}),
		);
	}, [id, !!board, savingState.isSaving, savingState.updated, savingState.canDelete]);

	return (
		<ComponentContainer padding="16px">
			{dialogs.saveProgress && progress.percent > 0 && (
				<BoardSaveProgress progress={progress} show={Boolean(dialogs.saveProgress)}></BoardSaveProgress>
			)}
			{dialogs.removeAndReassignMeetingType && (
				<BoardMeetingTypeReassignDialog
					board={board}
					meetingType={dialogs.removeAndReassignMeetingType}
					show={Boolean(dialogs.removeAndReassignMeetingType)}
					handleRemoveAndReassign={handleRemoveAndReassign}
					onClose={closeRemoveAndReassignDialog}
				></BoardMeetingTypeReassignDialog>
			)}
			{dialogs.delete && board && (
				<BoardDeleteDialog
					board={board}
					hasMeetingTypes={board.meetingTypes.length > 0}
					meetingTypes={meetingTypes}
					show={dialogs.delete}
					afterDelete={afterDelete}
					onClose={closeDeleteDialog}
					undoDelete={undoDelete}
				/>
			)}
			{requestError ? (
				<Typography variant="h3" style={{ padding: "24px" }}>
					{requestError}
				</Typography>
			) : board ? (
				<Board
					board={board}
					meetingTypes={meetingTypes}
					users={users}
					errors={errors}
					handleUpdate={updateBoard}
					handleMeetingTypeRemove={handleMeetingTypeRemove}
				/>
			) : (
				<CircularProgressIndicator />
			)}
		</ComponentContainer>
	);
};

export default withErrorHandling(BoardContainer);
