import { STATUS_DEFAULT, STATUS_GOOD, STATUS_WARNING, STATUS_ADDEDTOAGENDA } from "../../atlas/assets/jss/utils/statusIndicators";

const PublicMinutesStatusesEnum = () => ({
	NOTPUBLISHED: {
		value: 2,
		label: "enums.statuses.notPublished",
		color: STATUS_DEFAULT,
	},
	PUBLISHED: {
		value: 6,
		label: "enums.statuses.publishedDraft",
		color: STATUS_GOOD,
	},
	OUTOFSYNC: {
		value: 7,
		label: "enums.statuses.outOfSync",
		color: STATUS_WARNING,
	},
	APPROVED: {
		value: 5,
		label: "enums.statuses.publishedAdopted",
		color: STATUS_GOOD,
	},
	ADOPTED: {
		value: 9,
		label: "enums.statuses.publishedAdopted",
		color: STATUS_GOOD,
	},
	ADDEDTOAGENDA: {
		value: 11,
		label: "enums.statuses.addedToAgenda",
		color: STATUS_ADDEDTOAGENDA,
	},
});
export default PublicMinutesStatusesEnum;
