import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import clsx from "clsx";

import makeStyles from "@mui/styles/makeStyles";

import { grayColor } from "atlas/assets/jss/shared";
import Icon from "atlas/components/Icon/Icon";
import Tooltip from "atlas/components/Tooltip/Tooltip";
import DocumentDetailsInfo from "./DocumentDetailsInfo";
import DocumentDetailsActivity from "./DocumentDetailsActivity";

const ICON_WIDTH = 48;

const titleFont = {
	fontSize: "12px",
	fontWeight: "400",
	lineHeight: "16px",
	letterSpacing: "1px",
};

const useStyles = makeStyles({
	details: {
		height: "100%",
		display: "flex",
		flexDirection: "column",
	},
	sectionTitle: {
		boxSizing: "border-box",
		borderBottom: `solid 1px ${grayColor[2]}`,
		height: `${ICON_WIDTH}px`,
		display: "flex",
		alignItems: "center",
		cursor: "pointer",
		"&:hover, &:focus": {
			backgroundColor: "rgba(0, 0, 0, 0.04)",
		},
	},
	sectionTitleIcon: {
		width: `${ICON_WIDTH}px`,
		minWidth: `${ICON_WIDTH}px`,
		justifyContent: "center",
		display: "flex",
		alignItems: "center",
	},
	sectionTitleText: {
		...titleFont,
		boxSizing: "border-box",
		width: `calc(100% - ${ICON_WIDTH}px)`,
		maxWidth: `calc(100% - ${ICON_WIDTH}px)`,
		height: `${ICON_WIDTH - 1}px`,
		padding: "16px 16px 16px 0",
	},
	sectionTitleDocumentName: {
		fontWeight: "600",
		overflow: "hidden",
		textOverflow: "ellipsis",
		whiteSpace: "nowrap",
		padding: "16px 0 16px 16px",
	},
});

const DocumentDetails = (props) => {
	const {
		document,
		history,
		accessLogs,
		expanded,
		handleExpandCollapse,
		handleRename,
		handleDownload,
		handleReplace,
		handleCopyMove,
		loadMoreHistory,
		toggleFieldChanges,
		handleView,
		handleRestore,
		loadMoreAccessLogs,
		handleExport,
	} = props;
	const { t } = useTranslation("documents");
	const [sections, setSections] = useState({});
	const classes = useStyles();

	const toggleSection = (section) => setSections((prev) => ({ [section]: !prev[section] }));

	const getHandleClick = (section) =>
		section
			? () => {
					if (!expanded) {
						handleExpandCollapse();
					}
					toggleSection(section);
			  }
			: handleExpandCollapse;

	const handleKeyDown = (e, section) => {
		if (e.key === " " || e.key === "Enter") {
			if (section) {
				if (!expanded) {
					handleExpandCollapse();
				}
				toggleSection(section);
			} else {
				handleExpandCollapse();
			}
		}
	};

	const getSectionTitle = (section, icon, label) => (
		<div
			role="button"
			className={classes.sectionTitle}
			onClick={getHandleClick(section)}
			onKeyDown={(e) => handleKeyDown(e, section)}
			tabIndex="0"
			data-cy={`section-${section}`}
		>
			<div className={classes.sectionTitleIcon}>
				<Icon name={icon} size="20" />
			</div>
			<div className={classes.sectionTitleText}>{label}</div>
		</div>
	);

	const documentTitle = `${document.title}${document.extension}`;

	return (
		document && (
			<div className={classes.details}>
				<div
					role="button"
					className={classes.sectionTitle}
					onClick={getHandleClick()}
					onKeyDown={handleKeyDown}
					tabIndex="0"
					data-cy="expand-details"
				>
					{expanded && (
						<div className={clsx(classes.sectionTitleText, classes.sectionTitleDocumentName)} data-cy="details-name">
							<Tooltip title={documentTitle}>
								<span>{documentTitle}</span>
							</Tooltip>
						</div>
					)}
					<div className={classes.sectionTitleIcon}>
						<Icon name={expanded ? "expand-right" : "expand-left"} size="20" />
					</div>
				</div>
				{getSectionTitle("info", "info", t("detail.labels.info"))}
				{expanded && sections.info && (
					<DocumentDetailsInfo
						document={document}
						handleRename={handleRename}
						handleDownload={handleDownload}
						handleReplace={handleReplace}
						handleCopyMove={handleCopyMove}
					/>
				)}
				{getSectionTitle("activity", "activity", t("detail.labels.activity"))}
				{expanded && sections.activity && (
					<DocumentDetailsActivity
						document={document}
						history={history}
						accessLogs={accessLogs}
						loadMoreHistory={loadMoreHistory}
						toggleFieldChanges={toggleFieldChanges}
						handleView={handleView}
						handleRestore={handleRestore}
						loadMoreAccessLogs={loadMoreAccessLogs}
						handleExport={handleExport}
					/>
				)}
			</div>
		)
	);
};

export default DocumentDetails;
