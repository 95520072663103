import React from "react";
import { withTranslation } from "react-i18next";

import SvgIcon from "@mui/material/SvgIcon";

const icon = (props) => {
	const { color, t, tReady, i18n, ...newProps } = props;
	const fillColor = color || "currentColor";
	const title = t("showPasswordIcon");

	return (
		<SvgIcon {...newProps}>
			<title>{title}</title>
			<g id="visibility" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
				<g
					id="view-1460_27218cc7-bc66-42a4-b145-ee468bcb83ac"
					transform="translate(1.000000, 5.000000)"
					stroke={fillColor}
					strokeWidth="1.5"
				>
					<path d="M11,0.183333333 C4.69333333,0.183333333 0.183333333,6.6 0.183333333,6.6 C0.183333333,6.6 4.69333333,13.0166667 11,13.0166667 C17.3066667,13.0166667 21.8166667,6.6 21.8166667,6.6 C21.8166667,6.6 17.3066667,0.183333333 11,0.183333333 Z" />
					<circle cx="11" cy="6.41666667" r="4.4" />
				</g>
			</g>
		</SvgIcon>
	);
};

export default withTranslation("account")(icon);
