import React, { useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { SortableContext, verticalListSortingStrategy } from "@dnd-kit/sortable";
import clsx from "clsx";

import makeStyles from "@mui/styles/makeStyles";
import { Checkbox, FormControlLabel, MenuItem } from "@mui/material";

import OutlinedInput from "atlas/components/FormControls/OutlinedInput";
import SelectInput from "atlas/components/FormControls/SelectInput";
import AccessibleIconButton from "atlas/components/Buttons/AccessibleIconButton";
import Droppable from "atlas/components/DragAndDrop/Droppable";
import Draggable from "atlas/components/DragAndDrop/Draggable";
import Sortable from "atlas/components/DragAndDrop/Sortable";
import { grayColor, primaryColor } from "atlas/assets/jss/shared";
import inputStyle from "atlas/assets/jss/components/inputStyle";
import { Check } from "components/Icons";
import { getRequiredLabel } from "utils/updateObject";
import telemetryAddEvent from "utils/telemetryAddEvent";
import BlockUser from "./BlockUser";

const border = `solid 1px ${grayColor[1]}`;

const useInputStyles = makeStyles(inputStyle);
const useStyles = makeStyles(() => ({
	block: {
		border,
		borderRadius: "5px",
	},
	header: {
		display: "flex",
		alignItems: "center",
		borderBottom: border,
		paddingLeft: "8px",
	},
	headerName: {
		flexGrow: "1",
		margin: "-8px 0",
	},
	body: {
		border: "1px solid transparent",
		"& ol": {
			margin: "0",
			padding: "0",
			listStyleType: "none",
		},
	},
	noUsers: {
		display: "flex",
		alignItems: "center",
		height: "48px",
		textAlign: "center",
		"& > div": {
			flexGrow: "1",
		},
	},
	footer: {
		display: "flex",
		alignItems: "center",
		flexWrap: "wrap",
		justifyContent: "end",
		borderTop: border,
		paddingLeft: "16px",
		"& .MuiFormControlLabel-label": {
			marginLeft: 0,
		},
	},
	failsToTier: {
		minWidth: "100px",
		maxWidth: "200px",
		marginRight: "16px",
	},
}));

const WorkflowBlock = (props) => {
	const {
		block,
		blocks,
		errors,
		failRuleTiered,
		allowReadOnly,
		getDragId,
		dragPlaceholders = [],
		dropTarget,
		handleUpdate,
		handleRemoveBlock,
		handleRemoveUser,
		telemetryPage,
	} = props;
	const [anchor, setAnchor] = useState(null);
	const { t } = useTranslation("workflows");
	const classes = useStyles();
	const inputClasses = useInputStyles();

	const handleMenu = useCallback((e, userId) => {
		setAnchor({ id: userId, target: e.currentTarget });
	}, []);

	const handleRemoveBlockUser = useCallback((_e, userId) => handleRemoveUser(_e, userId, block), [block, handleRemoveUser]);

	const handleMove = useCallback(
		(userId, offset) => {
			handleUpdate(
				{
					target: {
						value: userId,
					},
				},
				"users",
				false,
				false,
				block,
				(newValue, prevValue) =>
					prevValue
						.map((blockUser, index) => {
							// Ensure that the order for this user is before the previous/after the next one
							blockUser.order = index + 1 + (blockUser.id === newValue ? offset : 0);

							return blockUser;
						})
						.sort((a, b) => (a.order < b.order ? -1 : a.order > b.order ? 1 : 0))
						.map((blockUser, index) => {
							// Correct the order to be integers
							blockUser.order = index + 1;

							return blockUser;
						}),
			);

			setAnchor(null);
		},
		[block, handleUpdate],
	);

	const handleMoveUp = useCallback((userId) => handleMove(userId, -1.5), [handleMove]);

	const handleMoveDown = useCallback((userId) => handleMove(userId, 1.5), [handleMove]);

	const handleMenuClose = useCallback((e) => {
		setAnchor(null);
	}, []);

	const getBlockUsers = (sortable) => {
		const BlockUserComponent = sortable ? Sortable : Draggable;

		return block.users.map((blockUser, index) => {
			const dragId = getDragId(block, blockUser);

			return (
				<BlockUserComponent
					key={blockUser.id}
					dragId={dragId}
					dragData={{
						block,
					}}
					dragComponent={BlockUser}
					dragAttributePassthrough
					canDrag
					dragPlaceholder={dragPlaceholders.includes(dragId)}
					user={blockUser}
					block={block}
					isFirst={index === 0}
					isLast={index === block.users.length - 1}
					allowReadOnly={allowReadOnly}
					handleUpdate={handleUpdate}
					actionIcon="remove"
					actionTooltip={t("tooltips.removeUserFromBlock")}
					handleAction={handleRemoveBlockUser}
					anchor={anchor && anchor.id === blockUser.id ? anchor.target : undefined}
					handleMenu={block.ordered ? handleMenu : undefined}
					handleMoveUp={handleMoveUp}
					handleMoveDown={handleMoveDown}
					handleMenuClose={handleMenuClose}
					telemetryPage={telemetryPage}
				/>
			);
		});
	};

	const getFailTiers = () => {
		const failTierMenuItems = [];
		const blockMap = blocks
			.filter((failTierBlock) => failTierBlock.tier <= block.tier)
			.reduce((map, failTierBlock) => {
				// Group up blocks by tier
				map[failTierBlock.tier] = map[failTierBlock.tier] || [];
				map[failTierBlock.tier].push(failTierBlock);

				return map;
			}, {});
		for (let count = 1; count <= block.tier; count++) {
			const failTierBlocks = blockMap[count] || [];
			if (failTierBlocks.length > 0) {
				failTierMenuItems.push(
					<MenuItem key={count} value={count} data-cy={`fails-to-tier-${count}`}>
						<div>{failTierBlocks.map((failTierBlock) => failTierBlock.name).join(", ")}</div>
					</MenuItem>,
				);
			}
		}

		return failTierMenuItems;
	};

	return (
		block && (
			<>
				<div className={classes.block} data-cy="workflow-block">
					<div className={classes.header}>
						<div className={classes.headerName}>
							{blocks.length > 1 && (
								<OutlinedInput
									id={`block-name-${block.id}`}
									label={getRequiredLabel(t, t("detail.blockName"))}
									value={block.name}
									onChange={(e) => handleUpdate(e, "name", false, false, block)}
									helperText={errors.name}
									error={!!errors.name}
									noDefaultClassName
									size="small"
									data-cy={`block-name-${block.id}`}
								/>
							)}
						</div>
						<div>
							<AccessibleIconButton
								iconColor={primaryColor[1]}
								tooltipText={t("tooltips.removeBlock")}
								onClick={() => handleRemoveBlock(block.id)}
								iconName="remove"
								dataCy={`remove-block-${block.id}`}
							/>
						</div>
					</div>
					<Droppable
						className={classes.body}
						dropId={block.id}
						dropData={{
							block,
						}}
						useHighlightDroppable
						highlightDroppable={dropTarget}
						dropComponent="div"
					>
						{block.users.length > 0 ? (
							<ol data-cy="block-user-list">
								{block.ordered && block.users.length > 1 ? (
									<SortableContext
										items={block.users.map((blockUser) => getDragId(block, blockUser))}
										strategy={verticalListSortingStrategy}
									>
										{getBlockUsers(true)}
									</SortableContext>
								) : (
									getBlockUsers()
								)}
							</ol>
						) : (
							<div className={classes.noUsers}>
								<div>{t("detail.noSelectedUsers")}</div>
							</div>
						)}
					</Droppable>
					<div className={classes.footer}>
						<FormControlLabel
							control={
								<Checkbox
									checkedIcon={<Check fontSize="small" color="primary" />}
									disabled={block.requiredApprovers > 0 ? true : false}
									checked={block.ordered}
									onChange={(e) => {
										telemetryAddEvent(`${telemetryPage} - ${e.target.checked ? "Ordered" : "Unordered"} block`, {
											area: "workflows",
										});

										handleUpdate(e, "ordered", true, false, block);
									}}
								/>
							}
							label={t("detail.ordered")}
							data-cy={`block-ordered-${block.id}`}
						/>
						<FormControlLabel
							control={
								<Checkbox
									checkedIcon={<Check fontSize="small" color="primary" />}
									disabled={block.ordered ? true : false}
									checked={block.requiredApprovers > 0}
									onChange={(e) => {
										if (e.target.checked) {
											telemetryAddEvent(`${telemetryPage} - Require Only one Approver`, {
												area: "workflows",
											});
										}

										handleUpdate(e, "requiredApprovers", true, false, block, (value) => (value ? 1 : 0));
									}}
								/>
							}
							label={t("detail.requiredApprovers")}
							data-cy={`block-required-approvers-${block.id}`}
						/>
						{allowReadOnly && (
							<FormControlLabel
								control={
									<Checkbox
										checkedIcon={<Check fontSize="small" color="primary" />}
										checked={block.users.every((blockUser) => !blockUser.readOnly)}
										onChange={(e) => {
											if (!e.target.checked) {
												telemetryAddEvent(`${telemetryPage} - Can Edit selected`, {
													area: "workflows",
												});
											}

											handleUpdate(e, "readOnly", true, false, block.users, (value) => !value);
										}}
									/>
								}
								label={t("detail.canEditItem")}
								data-cy={`block-read-only-${block.id}`}
							/>
						)}
						{failRuleTiered && (
							<SelectInput
								id="fails-to-tier"
								className={clsx(inputClasses.smallInput, classes.failsToTier)}
								noDefaultClassName
								label={t("detail.rejectsToTier")}
								fullWidth
								size="small"
								value={block.failsToTier}
								onChange={(e) => handleUpdate(e, "failsToTier", false, true, block)}
								data-cy="fails-to-tier"
							>
								{getFailTiers()}
							</SelectInput>
						)}
					</div>
				</div>
			</>
		)
	);
};

export default WorkflowBlock;
