import React, { useEffect, useState, useRef, useCallback, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { debounce } from "lodash";
import clsx from "clsx";

import makeStyles from "@mui/styles/makeStyles";
import { Container, List } from "@mui/material";

import Spinner from "atlas/components/Spinner/Spinner";
import { useWidthUp, useWidthDown } from "atlas/utils/useWidth";
import SingletonEditor from "components/Editor/SingletonEditor";
import HeaderFooter from "components/Meeting/HeaderFooter";
import MeetingItem from "components/Meeting/MeetingItem";
import { SettingsContext } from "contexts/Settings/SettingsContext";
import { setMeetingTemplateMinutesItems, persistMeetingTemplateMinutesItems, setActive } from "redux/meetingTemplate/actions";
import { ITEM_TYPES } from "utils/enums/ItemTypes";
import { useSignInDialog } from "utils/isSignedIn";
import { scrollToElement2 as scrollToElement } from "utils/scrollToElement";
import telemetryAddEvent from "utils/telemetryAddEvent";

const useEditorStyles = makeStyles(() => ({
	editorFieldHide: {
		display: "none",
	},
	loadingIndicator: {
		height: "49.4px",
		width: "100%",
		textAlign: "center",
		display: "flex",
		alignItems: "center",
		position: "absolute",
		left: "0",
		top: "0",
		boxSizing: "border-box",
	},
	editorContentSmUp: {
		minWidth: "450px",
		boxSizing: "border-box",
		overflow: "hidden",
		"-webkit-transform": "translate3d(0, 0, 0)",
		width: (props) => (props.showOutline ? "calc(100% - 265px)" : "100%"),
		marginLeft: (props) => (props.showOutline ? "265px" : "0"),
	},
	contentContainer: {
		maxWidth: "7.5in !important",
		paddingTop: "8px !important",
		paddingBottom: "8px !important",
		margin: "0 auto",
	},
}));

const saveDelay = 2000;

const MinutesEditor = (props) => {
	const { editorToolbarRef, setShowToolbar, telemetryPage } = props;
	const widthUpSm = useWidthUp("sm");
	const widthDownMd = useWidthDown("md");
	const { t } = useTranslation("meetings");
	const [editorFields, _setEditorFields] = useState([]);
	const [editorInitializing, setEditorInitializing] = useState(true);
	const [_elementRefsSet, setElementRefsSet] = useState(null);
	const [triggerEditorChange, setTriggerEditorChange] = useState(false);
	const [deletedFieldIdx, setDeletedFieldIdx] = useState(-1);
	const editorFieldsRef = useRef(editorFields);
	const elementsRef = useRef([]);
	const elementsRefStatus = useRef({ rerender: true, status: [] }); // Used to determine if all expected element refs have been set
	const { dateFormat } = useContext(SettingsContext);
	const dispatch = useDispatch();
	const showSignIn = useSignInDialog();
	const { minutes: { items, header, footer, saving, updated } = {}, active: { id: active, field: activeField } = {} } = useSelector(
		(state) => state.meetingTemplatesReducer,
	);
	const classes = useEditorStyles({ showOutline: !widthDownMd });

	const afterElementRefSet = (index) => {
		if (elementsRefStatus.current.rerender) {
			elementsRefStatus.current.status[index] = true;

			if (!elementsRefStatus.current.status.find((value) => !value)) {
				elementsRefStatus.current.rerender = false;
				setElementRefsSet({}); //Set to a new object reference just to trigger a re-render
			}
		}
	};

	const setEditorFields = (data) => {
		editorFieldsRef.current = data;
		_setEditorFields(data);
	};

	const handleEditorInitialized = (editor) => {
		setEditorInitializing(false);
	};

	const handleItemClick = () => {
		setShowToolbar(false);
	};

	const handleFieldChange = (editor) => {
		const fieldData = editor.getFieldData();

		dispatch(setMeetingTemplateMinutesItems(fieldData, editorFieldsRef.current));
		editor.ui.update();

		if (fieldData?.filter((field) => field.fieldName === "toc-header")) {
			telemetryAddEvent(`${telemetryPage} - Minutes Edit Header`);
		}
		if (fieldData?.filter((field) => field.fieldName === "toc-footer")) {
			telemetryAddEvent(`${telemetryPage} - Minutes Edit footer`);
		}
	};

	// TODO Find the reason why this gets triggered twice when dispatching - Debounce is only there to prevent 2nd call. Temporary band-aid...
	const handleFocusChange = debounce(
		useCallback((fieldName, _fieldElement, editor) => {
			if (fieldName && editor?.ui?.focusTracker?.isFocused) {
				dispatch(setActive(fieldName));
				setShowToolbar(true);
			}
		}, []),
		100,
	);

	const updateEditorFields = (itemGuid, isDeleting = false) => {
		const newEditorFields = [...editorFieldsRef.current];
		const editorFieldText = newEditorFields.find((field) => field.name === `${itemGuid}-text`);
		if (editorFieldText) {
			editorFieldText.deleted = isDeleting;
			setEditorFields(newEditorFields);
			setTriggerEditorChange((triggerEditorChange) => !triggerEditorChange);
		}
	};

	const addItemText = (itemGuid) => {
		updateEditorFields(itemGuid);
		if (itemGuid) {
			dispatch(setActive(itemGuid.indexOf("-text") < 0 ? `${itemGuid}-text` : itemGuid, true));
		}
	};

	const removeItemText = (itemGuid) => {
		const idx = editorFieldsRef.current.findIndex((f) => f.name === `${itemGuid}-text`);
		setDeletedFieldIdx(idx);
		updateEditorFields(itemGuid, true);
	};

	const handleDeletedFieldIndex = () => {
		setDeletedFieldIdx(-1);
	};

	const handleUndoRedoRoot = (itemGuid, isUndo, isDeletion) => {
		const idx = editorFieldsRef.current.findIndex((f) => f.name === itemGuid);
		if (idx < 0) return;
		const newFields = [...editorFieldsRef.current];
		newFields[idx].deleted = isUndo ? !isDeletion : isDeletion;
		setEditorFields(newFields);
		setTriggerEditorChange((triggerEditorChange) => !triggerEditorChange);
	};

	const getItems = () => {
		let isConsentHeading = false;
		let isPublicCommentHeading = false;
		let isMemberOnlyHeading = false;

		const filteredItems = [];
		const previous = {
			heading: {
				item: null,
				numberOfChildren: 0,
			},
		};
		items.forEach((item) => {
			if (!item.deleted) {
				filteredItems.push(item);
				if (item.itemType === ITEM_TYPES.MEETING_TEMPLATE_AGENDA_HEADING) {
					// Top level heading
					previous.heading = {
						item,
						numberOfChildren: !item.attributes.relationshipGuid ? 0 : previous.heading.numberOfChildren,
					};
				} else if (previous.heading.item && item.itemType === ITEM_TYPES.MEETING_TEMPLATE_AGENDA_ITEM) {
					// Count the children of the heading
					previous.heading.numberOfChildren++;
				}
			}
		});

		const lastIndex = filteredItems.length - 1;
		let elementsIndex = 0;
		let domElements = [];

		// Header
		const headerElement = (
			<HeaderFooter key="header" isHeader elementsRef={elementsRef} elementsIndex={elementsIndex} afterElementRefSet={afterElementRefSet} />
		);
		domElements.push(headerElement);
		elementsIndex++;

		filteredItems.map((item, index) => {
			const isHeading = item.itemType === ITEM_TYPES.MEETING_TEMPLATE_AGENDA_HEADING;
			const isSubHeading = Boolean(isHeading && item.attributes.relationshipGuid);
			const isItem = item.itemType === ITEM_TYPES.MEETING_TEMPLATE_AGENDA_ITEM;
			isMemberOnlyHeading = Boolean(item?.fields?.Closed?.Value);
			isConsentHeading = Boolean(item?.fields?.Consent?.Value);
			isPublicCommentHeading = Boolean(item?.fields?.PublicComment?.Value);
			let element;
			const editorFieldText = editorFieldsRef.current.find((field) => field.name === `${item.guid}-text`);

			element = (
				<MeetingItem
					key={item.guid}
					readOnly
					item={item}
					active={active}
					setActive={setActive}
					isMemberOnlyHeading={isMemberOnlyHeading}
					isConsentHeading={isConsentHeading}
					isPublicCommentHeading={isPublicCommentHeading}
					isHeading={isHeading}
					isSubHeading={isSubHeading}
					isItem={isItem}
					isRecommendation={item.itemType === ITEM_TYPES.RECOMMENDATION}
					addBottomBorder={index === lastIndex}
					elementsRef={elementsRef}
					elementsIndex={elementsIndex}
					afterElementRefSet={afterElementRefSet}
					removeText={removeItemText}
					addText={addItemText}
					handleItemClick={handleItemClick}
					editorFieldTextDeleted={editorFieldText?.deleted}
				/>
			);

			// We now always create the text element, it is just hidden or shown sometimes
			domElements.push(element);
			return element || <div key={`item${item.guid}`}>{item.fields.Name.Value}</div>;
		});

		// Footer
		const footerElement = (
			<HeaderFooter
				key="footer"
				label={t("templateDetail.labels.footer")}
				elementsRef={elementsRef}
				elementsIndex={elementsIndex}
				afterElementRefSet={afterElementRefSet}
			/>
		);
		domElements.push(footerElement);
		elementsIndex++;

		// Initialize the elements ref status
		if (elementsRefStatus.current.rerender) {
			elementsRefStatus.current.status = [];
			for (let index = 0; index <= elementsIndex; index++) {
				elementsRefStatus.current[index] = false;
			}
		}

		return domElements;
	};

	const scrollToActive = (fieldName, field) => {
		scrollToElement(fieldName);
		const elem = document.getElementById(field ? `${field}-${fieldName}` : `meeting-item-${fieldName}`);
		if (elem) {
			scrollToElement(elem, document.getElementById("content-scroll"), -48);
		}
		const tocElement = document.getElementById(`outline-minute-${fieldName}`);
		if (tocElement) {
			scrollToElement(tocElement, document.getElementById("minute-outline"));
		}
	};

	const focusEditor = useCallback(
		(fieldName, attempts = 0) => {
			const root = window.editor?.model?.document?.getRoot(fieldName);
			let element = null;
			if (root) {
				element = document.querySelectorAll(`[data-fieldname="${fieldName}"]`);
			} else if (!editorFields.find((field) => field.name === fieldName)) {
				// A read-only field
				element = document.querySelectorAll(`[data-fieldname="${fieldName}"]`);
			}
			if (element && element.length > 0) {
				element[0].focus();
				scrollToActive(fieldName);
			} else if (attempts < 10) {
				// Try again
				setTimeout(() => {
					focusEditor(fieldName, attempts + 1);
				}, 100);
			}
		},
		[editorFields],
	);

	const saveUpdates = debounce(async () => {
		dispatch(persistMeetingTemplateMinutesItems(t, dateFormat)).catch((err) => {
			showSignIn(err, () => {
				dispatch(persistMeetingTemplateMinutesItems());
			});
		});
	}, saveDelay);

	useEffect(() => {
		if (window.editor) {
			handleFieldChange(window.editor);
		}
	}, [triggerEditorChange]);

	useEffect(() => {
		if (active && focus) {
			focusEditor(active);
		} else {
			scrollToActive(active, activeField);
		}
	}, [active, focus, activeField]);

	useEffect(() => {
		const newFields = [];

		// Header
		newFields.push({
			guid: "toc-header",
			name: "toc-header",
			content: header,
			toolbar: "minutesHeader",
			deleted: false,
		});

		// Footer
		newFields.push({
			guid: "toc-footer",
			name: "toc-footer",
			content: footer,
			toolbar: "header",
			deleted: false,
		});
		setEditorFields(newFields);
	}, []);

	useEffect(() => {
		if (!saving && updated) {
			saveUpdates();
		}
	}, [saving, updated]);

	return (
		<>
			{editorInitializing && <Spinner />}
			<div
				className={clsx("flex", "direction-column", "agenda-editor-content", {
					["agenda-editor-content-xs"]: !widthUpSm,
					[classes.editorContentSmUp]: widthUpSm,
					editorFieldHide: editorInitializing,
				})}
			>
				<Container id={"new-editor-toc-header"} maxWidth="lg" className={classes.contentContainer}>
					<List component="ul" disablePadding classes={{ root: "agenda-items-container" }} id="item-list" data-cy="item-list">
						{editorFields.length > 0 && getItems()}
					</List>
				</Container>
				{editorFields.length > 0 && elementsRef.current.length > 0 && (
					<SingletonEditor
						fields={editorFields}
						fieldsRefs={elementsRef}
						editorToolbarRef={editorToolbarRef}
						onFieldChange={handleFieldChange}
						onEditorInitialized={handleEditorInitialized}
						onFocusChange={handleFocusChange}
						onUndoRedoRoot={handleUndoRedoRoot}
						onDeletedFieldIndex={handleDeletedFieldIndex}
						deletedFieldIdx={deletedFieldIdx}
					/>
				)}
			</div>
		</>
	);
};

export default MinutesEditor;
