import animateScrollTo from "animated-scroll-to";
import { isEdge } from "react-device-detect";

export default function scrollToElement(elem, isContentWell = false, scrollContainer) {
	if (!elem || !elem.getBoundingClientRect) {
		return;
	}
	let container = scrollContainer;
	if (!container) {
		container = document.body;
	}

	const rect = elem.getBoundingClientRect();
	// This is to calculate the margin top of 154px from the top bar + header. It will make it so it scrolls all the time
	const topBar = isContentWell ? 100 : 154;
	// This is to account header plus min-height of elements
	const minHeight = 275;

	const offset = rect.top - document.body.scrollTop + topBar;
	if (offset >= window.innerHeight || offset < minHeight) {
		animateScrollTo(elem, {
			easing: (t) => {
				return t;
			},
			elementToScroll: container,
			maxDuration: isEdge ? 0 : 500,
			minDuration: isEdge ? 0 : 300,
			verticalOffset: isContentWell ? -50 : -10,
		});
	}
}

export const scrollToElement2 = (element, scrollContainer, verticalOffset) => {
	if (!element || !element.getBoundingClientRect) {
		return;
	}

	const container = scrollContainer || getScrollParent(element) || document.body;
	const containerRect = container.getBoundingClientRect();
	const elementRect = element.getBoundingClientRect();
	const elementTop = elementRect.top + verticalOffset; // Get current top position + offset
	if (elementTop < containerRect.top || elementTop > containerRect.bottom) {
		animateScrollTo(element, {
			easing: (t) => {
				return t;
			},
			elementToScroll: container,
			maxDuration: isEdge ? 0 : 500,
			minDuration: isEdge ? 0 : 300,
			verticalOffset,
		});
	}
};

export const getScrollParent = (node) => {
	const regex = /(auto|scroll)/;
	const parents = (_node, ps) => {
		if (_node.parentNode === null) {
			return ps;
		}
		return parents(_node.parentNode, ps.concat([_node]));
	};

	const style = (_node, prop) => getComputedStyle(_node, null).getPropertyValue(prop);
	const overflow = (_node) => style(_node, "overflow") + style(_node, "overflow-y") + style(_node, "overflow-x");
	const scroll = (_node) => regex.test(overflow(_node));

	const scrollParent = (_node) => {
		if (!(_node instanceof HTMLElement || _node instanceof SVGElement)) {
			return;
		}

		const ps = parents(_node.parentNode, []);

		for (let i = 0; i < ps.length; i += 1) {
			if (scroll(ps[i])) {
				return ps[i];
			}
		}

		return document.scrollingElement || document.documentElement;
	};

	return scrollParent(node);
};
