import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { withTranslation } from "react-i18next";

import { updateToolbar } from "../../../redux/app/actions";

const GoalTopBar = (props) => {
	const { saveStatus, saveTooltip = "", uploadingStatus, uploadingTooltip = "", t } = props;
	const dispatch = useDispatch();

	// Display tools
	useEffect(() => {
		dispatch(
			updateToolbar({
				display: true,
				left: {
					tools: [],
				},
				right: {
					tools: [
						{
							id: "uploading-status",
							label: uploadingStatus,
							tooltipText: uploadingTooltip,
							hidden: !uploadingStatus,
						},
						{
							id: "saving-status",
							label: saveStatus,
							tooltipText: saveTooltip,
							hidden: !saveStatus,
							dataCy: "goalSaveStatus",
						},
					],
				},
			}),
		);
	}, [uploadingStatus, saveStatus]);

	return null;
};

export default withTranslation("goals")(GoalTopBar);
