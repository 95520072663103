import { API_HOST } from "config/env";

class UploadAdapter {
	constructor(loader) {
		this.loader = loader;
	}

	upload() {
		return new Promise((resolve, reject) => {
			this._initRequest();
			this._initListeners(resolve, reject);
			this._sendRequest(reject);
		});
	}

	abort() {
		if (this.xhr) {
			this.xhr.abort();
		}
	}

	_initRequest() {
		this.xhr = new XMLHttpRequest();
		this.xhr.open("POST", `${API_HOST}/api/documents/image`, true);
		this.xhr.responseType = "json";
	}

	// Initializes XMLHttpRequest listeners.
	_initListeners(resolve, reject) {
		const { xhr, loader } = this;
		const genericErrorText = `Couldn't upload file: ${loader.file.name}.`;

		xhr.addEventListener("error", () => reject(genericErrorText));
		xhr.addEventListener("abort", () => reject());
		xhr.addEventListener("load", () => {
			const { response } = xhr;
			if (!response || response.Message) {
				return reject(response && response.Message ? response.Message : genericErrorText);
			}
			resolve({
				default: response.url,
			});
		});

		if (xhr.upload) {
			xhr.upload.addEventListener("progress", (evt) => {
				if (evt.lengthComputable) {
					loader.uploadTotal = evt.total;
					loader.uploaded = evt.loaded;
				}
			});
		}
	}

	// Prepares the data and sends the request.
	_sendRequest(reject) {
		const xhrLocal = this.xhr;

		this.loader.file.then((file) => {
			const data = new FormData();
			data.append("upload", file);
			xhrLocal.send(data);
		}, reject);
	}
}

export default UploadAdapter;
