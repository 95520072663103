export default {
	userProfile: "User Profile",
	chooseNewPassword: "Update your password",
	confirmNewPassword: "Confirm new password",
	currentPassword: "Current password",
	enterNewPassword: "To start, confirm it's you by entering your current password.",
	email: "Email",
	firstName: "First name",
	lastName: "Last name",
	name: "Name",
	newEmail: "New email",
	newPassword: "New password",
	noPictureUploaded: "No Picture Uploaded",
	password: "Password",
	photo: "Photo",
	phoneNumber: "Telephone number",
	phoneNumberLabel: "Phone number",
	updatePhone: "Update your phone number",
	updatePhoneSubMsg: "(Add this to receive text notifications for approvals)",
	updateEmail: "Update email",
	updateEmailConfirm: "Before we update your email, confirm it’s you by entering your password.",
	updateName: "Update your name",
	updatePasswordInstructions:
		"Enter a strong unique password that’s 8 or more characters long.<br /><br />To create a strong password, do not:<ul><li>Include personal information.</li><li>Use common passwords (such as “Password” or “12345678”).</li><li>Reuse passwords from other logins.</li></ul>",
	updatePersonalInfo: "Update your profile",
	updatePhoto: "Update your photo",
	updateUserName: "Update your username",
	userName: "Username",
	verifyPassword: "Verify password",

	buttons: {
		keepCurrent: "Keep Current",
		chooseAnother: "Choose Another",
		photoRemove: "Remove",
		verify: "Verify",
	},
	notifications: {
		emailUpdated: "Email updated successfully",
		nameUpdated: "Name updated successfully",
		passwordUpdated: "Password updated successfully",
		phoneNumberUpdated: "Phone number updated successfully",
		pictureRemoved: "Profile picture removed successfully",
		pictureUpdated: "Profile picture updated successfully",
		smsSettingsUpdated: "Notification settings updated successfully",
		userNameUpdated: "Username updated successfully",
	},
	errors: {
		disabledTooltip: "Fix the errors above to proceed",
		enterCorrectPassword: "Enter your correct password to proceed",
		firstNameEmpty: "First name can't be empty",
		firstNameInvalid: "First name contains invalid text, such as HTML",
		invalidEmail: "That isn't a valid email address",
		invalidNumber: "Phone number is invalid",
		lastNameEmpty: "Last name can't be empty",
		lastNameInvalid: "Last name contains invalid text, such as HTML",
		mustMatchNewPassword: "Error: Enter matching passwords",
		passwordCheckFailed: "That password doesn't match our records",
		passwordContainsEmailAddress: "Error: Cannot include email in password",
		passwordContainsName: "Error: Cannot include name in password",
		passwordContainsUserName: "Error: Cannot include username in password",
		passwordEightCharacter: "Error: Enter a password with 8 or more characters",
		passwordIsCommon: "Error: This password is common. For security reasons please select a more unique password",
		pictureSaveFailed: "Your new photo wasn't saved successfully.",
		userNameEmpty: "Username can't be empty",
		userNameMustBeUnique: "Your username must be unique",
		usernameTaken: "Your new username was taken by someone else before we could save it.",
		validEmail: "Enter a valid email address to proceed",
	},
};
