export const SECTION_FORMAT_ITEM = {
	NumberValue: "Number",
	NumberValueWithBracket: "NumberBracket",
	Alphabets: "Alphabets",
	AlphabetBracket: "AlphabetBracket",
	Roman: "Roman",
};

export const NUMBER_ITEMS_FORMAT = {
	NumberWithNumber: "NumberNumber",
	NumberWithSmallAlphabets: "NumberSmallAlphabets",
	NumberWithCapitalAlphabets: "NumberCapitalAlphabets",
	NumberWithTwoDigits: "NumberTwoDigitNumber",
	NumberWithRoman: "NumberRoman",
	NumberWithCapitalRoman: "NumberCapitalRoman",

	NumberBracketWithSmallAlphabets: "NumberBracketSmallAlphabets",
	NumberBracketWithCapitalAlphabets: "NumberBracketCapitalAlphabets",
	NumberBracketWithTwoDigits: "NumberBracketTwoDigitNumber",
	NumberBracketWithRoman: "NumberBracketRoman",
	NumberBracketWithCapitalRoman: "NumberBracketCapitalRoman",
};

export const ALPHABETS_ITEM_FORMAT = {
	AlphabetWithBracketSmallAlphabets: "AlphabetBracketSmallAlphabet",
	AlphabetWithNumber: "AlphaNumber",
	AlphabetWithDashNumber: "AlphaDashNumber",
	AlphabetWithBracketNumber: "AlphaBracketNumber",
	AlphabetWithBracketCapitalRoman: "AlphabetBracketCapitalRoman",
	AlphabetWithBracketSmallRoman: "AlphabetBracketSmallRoman",

	AlphabetBracketWithBracetSmallAlphabets: "AlphabetBracketSmallAlphabetBracket",
	AlphabetBracketWithNumber: "AlphabetBracketNumber",
	AlphabetBracketWithBracketNumber: "AlphabetBracketNumberBracket",
	AlphabetBracketWithBracketCapitalRoman: "AlphabetBracketCapitalRomanBracket",
	AlphabetBracketWithBracketSmallRoman: "AlphabetBracketSmallRomanBracket",
};

export const ROMAN_ITEM_FORMAT = {
	RomanWithBracketCapitalAlphabets: "RomanCapitalAlphabetsBracket",
	RomanWithBracketSmallAlphabets: "RomanSmallAlphabetsBracket",
	RomanWithBracketSmallRoman: "RomanSmallRomanBracket",
};
