import React from "react";
import SvgIcon from "@mui/material/SvgIcon";

import themeConfig from "../../atlas/assets/jss/theme";
import getOr from "lodash/fp/getOr";

const icon = (props) => {
	const { color } = props;
	const fillColor = color ? getOr(color, ["palette", color, "main"], themeConfig) : "currentColor";

	return (
		<SvgIcon {...props}>
			<defs>
				<polygon
					id="check-path-1"
					points="6.33879781 11.4211886 18.3879781 0 20 1.5245478 6.33879781 14.4444444 0 8.4496124 1.58469945 6.9250646"
				/>
			</defs>
			{color ? <rect x="0" y="0" height="100%" width="100%" fill={fillColor} /> : null}
			<g id="check" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g id="Icons/24px/Unselected/Check">
					<g id="Color/Universal-Greys/00-Black" transform="translate(2.000000, 5.000000)">
						<g>
							<mask id="check-mask-2" fill="white">
								<use xlinkHref="#check-path-1" />
							</mask>
							<use fill="#8E8E8C" fillRule="nonzero" xlinkHref="#path-1" />
							<g mask="url(#check-mask-2)" fill="#FFF">
								<g transform="translate(-3.333333, -6.666667)">
									<rect x="0" y="0" width="27" height="27" />
								</g>
							</g>
						</g>
					</g>
				</g>
			</g>
		</SvgIcon>
	);
};

export default icon;
