import React, { useCallback, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDroppable } from "@dnd-kit/core";
import { useDispatch } from "react-redux";
import Parser from "html-react-parser";
import clsx from "clsx";

import { Box, Button, ButtonGroup, IconButton, ListItem, ListItemSecondaryAction, MenuItem, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import minutesStyle from "assets/jss/components/minutesStyle";
import dragAndDropStyle from "atlas/assets/jss/components/dragAndDropStyle";
import { primaryColor, whiteColor, blackColor, grayColor } from "atlas/assets/jss/shared";
import AccessibleIconButton from "atlas/components/Buttons/AccessibleIconButton";
import Draggable from "atlas/components/DragAndDrop/Draggable";
import DragHandle from "atlas/components/DragAndDrop/DragHandle";
import Icon from "atlas/components/Icon/Icon";
import MenuItemWithTooltip from "atlas/components/Menu/MenuItemWithTooltip";
import NonModalMenu from "atlas/components/Menu/NonModalMenu";
import BoardNotes from "./BoardNotes";
import ContentAgendaItemSubmissionDetails from "../../views/MeetingEditor/components/ContentAgendaItemSubmissionDetails";
import { SECTION_FORMAT_ITEM } from "utils/enums/CustomNumberingTypes";

const useStyle = makeStyles(minutesStyle);
const useDragAndDropStyles = makeStyles(dragAndDropStyle);

const MeetingItem = (props) => {
	const {
		item,
		readOnly = false,
		active,
		setActive,
		isMemberOnlyHeading,
		isConsentHeading,
		isPublicCommentHeading,
		isHeading,
		isSubHeading,
		isProposedSubmitted,
		isItem,
		isRecommendation,
		isResolution,
		showNotes = false,
		handleUpdateNotes,
		showMenu = false,
		showMenuOptions = true,
		menuOptions: getMenuOptions,
		addItemMenuOptions: getAddItemMenuOptions,
		menuAnchor = {},
		handleMenu,
		dragPlaceholder,
		dragPresentational,
		addBottomBorder,
		elementsRef,
		elementsIndex,
		afterElementRefSet,
		removeText,
		addText,
		handleItemClick,
		editorFieldTextDeleted,
		queueFileUploads,
		addPolicy,
		addGoals,
		isMeetingTemplate = true,
		customNumbering = {},
		meetingId,
		onPendingApprovalClick,
		shouldLockAgenda,
		index,
	} = props;
	const canDrag = readOnly ? false : !shouldLockAgenda;
	const { fields } = item;
	const { t } = useTranslation("agendaMenu");
	const [notesAnchor, setNotesAnchor] = useState(null);
	const dispatch = useDispatch();
	const classes = useStyle({ isSubHeading, addTopBorder: !isMemberOnlyHeading && !isConsentHeading });
	const dragAndDropClasses = useDragAndDropStyles();
	const Component = dragPresentational ? "div" : "li";
	const dragProps = canDrag ? { dragId: item.guid, dragComponent: "div" } : {};
	const { setNodeRef } = useDroppable({ id: item.guid });

	const boardNotesGuid = `${item.guid}-board-notes`;
	const addBottomBorderToThis = addBottomBorder && item.attachments.length === 0;
	const isActive = active === item.guid || active === `${item.guid}-text`;
	const isBoardNotesActive = active === boardNotesGuid;
	const overflowMenuOpen = Boolean(menuAnchor?.anchor && menuAnchor.overflow && menuAnchor.guid === item.guid);
	const addMenuOpen = Boolean(menuAnchor?.anchor && !menuAnchor.overflow && menuAnchor.guid === item.guid);
	const hideText = Boolean(!readOnly ? editorFieldTextDeleted : !item.fields?.Text?.Value);
	const hideField = Boolean(!item.added);
	const { Roman } = SECTION_FORMAT_ITEM;
	const menuOptions = showMenuOptions
		? typeof getMenuOptions === "function"
			? getMenuOptions({
					item,
					isProposedSubmitted,
					isHeading,
					isSubHeading,
					isMemberOnlyHeading,
					isConsentHeading,
					isPublicCommentHeading,
					isItem,
					index,
				})
			: getMenuOptions
		: [];
	const addItemMenuOptions = typeof getAddItemMenuOptions === "function" ? getAddItemMenuOptions(item) : getAddItemMenuOptions;

	const handleViewNotes = useCallback(() => {
		dispatch(setActive(isBoardNotesActive ? "" : boardNotesGuid));
	}, [isBoardNotesActive]);

	const closeNotes = () => {
		dispatch(setActive(""));
	};

	const parserOptions = {
		replace: (node) => {
			if (!node.attribs) return;
			if (["img", "br"].includes(node.name) && node.attribs.style) {
				node.attribs.style = "";
			}
			if (node.name === "p") {
				node.attribs.style = "margin-top: 12px; margin-bottom: 12px;";
			}
		},
	};

	useEffect(() => {
		setNotesAnchor(isBoardNotesActive ? document.getElementById(`edit-board-notes-${item.guid}`) : null);
	}, [isBoardNotesActive]);

	const parseFirstAnchorWithEllipsis = (htmlString) => {
		const parser = new DOMParser();
		const doc = parser.parseFromString(htmlString, "text/html");

		const firstAnchor = doc.querySelector("a");

		if (firstAnchor) {
			const parentNode = firstAnchor.parentNode;
			const textBeforeAnchor = parentNode.innerHTML.split(firstAnchor.outerHTML)[0];

			return textBeforeAnchor + firstAnchor.outerHTML + "...";
		} else {
			return htmlString;
		}

		return "";
	};

	return (
		<Component
			className={clsx(classes.itemContainer, {
				[classes.editorFieldHide]: item.deleted,
			})}
			ref={setNodeRef}
			onClick={handleItemClick}
		>
			{isHeading && isMemberOnlyHeading && !isSubHeading && (
				<div className={clsx(classes.headerListItem, classes.memberOnlyHeader)} data-cy={`heading-member-only-${item.guid}`}>
					<Icon name="locked" color={whiteColor} size="16px" />
					<span className={classes.headerTextWithIcon}>{t("memberOnlySection")}</span>
				</div>
			)}
			{isHeading && isConsentHeading && !isSubHeading && (
				<div className={clsx(classes.headerListItem, classes.consentHeader)} data-cy={`heading-consent-${item.guid}`}>
					<span>{t("consentSection")}</span>
				</div>
			)}
			{isHeading && isPublicCommentHeading && !isSubHeading && (
				<div className={clsx(classes.headerListItem, classes.publicCommentHeader)} data-cy={`heading-public-comment-${item.guid}`}>
					<span>{t("publicCommentSection")}</span>
				</div>
			)}
			<div
				id={`meeting-item-${item.guid}`}
				className={clsx(classes.agendaListItem, {
					[dragAndDropClasses.dragPlaceholder]: dragPlaceholder,
					[classes.borderDefault]: !isMemberOnlyHeading && !isConsentHeading && !isPublicCommentHeading && !addBottomBorderToThis,
					[classes.borderDefaultBottomBorder]:
						!isMemberOnlyHeading && !isConsentHeading && !isPublicCommentHeading && addBottomBorderToThis,
					[classes.borderReadOnly]: readOnly,
					[classes.borderMemberOnly]: isMemberOnlyHeading,
					[classes.borderConsent]: isConsentHeading,
					[classes.borderPublicComment]: isPublicCommentHeading,
				})}
				data-cy={`meeting-item-${item.guid}`}
			>
				<div
					className={clsx(classes.item, classes.liveMeetingItem, classes.rightBorder, {
						[classes.itemHeading]: isHeading,
						[classes.itemItem]: isItem,
						[classes.itemRecommendation]: isRecommendation || isResolution,
						[classes.bottomBorder]: addBottomBorderToThis,
						[classes.itemReadOnly]: readOnly,
					})}
				>
					{canDrag && (
						<Draggable {...dragProps}>
							<DragHandle
								className={clsx({
									[classes.dragHandleOffset]: !isRecommendation && !isResolution,
								})}
								role="button"
								aria-label={t("meetings:templateDetail.tooltips.dragItem")}
							/>
						</Draggable>
					)}
					<div className={clsx(classes.textContainer, { [classes.draggableOffset]: canDrag })}>
						{(isHeading || isItem) && fields.Number.Value.length > 0 && (
							<div className={clsx(classes.bullet, { [classes.bulletMargin]: customNumbering && customNumbering.sectionFormat == Roman })}>
								{fields.Number.Value}
							</div>
						)}
						{(isRecommendation || isResolution) && (
							<div className={classes.motionIcon} data-icon="recommendation">
								<Icon name="recommendation" color={readOnly ? grayColor[0] : blackColor[1]} />
							</div>
						)}
						<div
							className={clsx(classes.itemText, {
								[classes.itemTextWithBullet]: !isRecommendation && !isResolution,
							})}
						>
							<Box
								className={clsx(classes.editorFieldContent, classes.fieldContentMargin, {
									[classes.fieldReadOnly]: readOnly,
									[classes.editorFieldHide]: hideField,
								})}
								data-fieldname={item.guid}
								ref={
									!readOnly
										? (el) => {
												afterElementRefSet(elementsIndex);

												return (elementsRef.current[elementsIndex] = el);
											}
										: undefined
								}
							>
								{readOnly ? (
									Parser(parseFirstAnchorWithEllipsis(item.fields?.Name?.Value) || "", parserOptions)
								) : (
									<div className={classes.editorFieldPlaceHolder}></div>
								)}
							</Box>
							{showMenu && menuOptions && menuOptions.length > 0 && (
								<>
									<div className={classes.overflowMenu}>
										<AccessibleIconButton
											iconName="more"
											iconColor={blackColor[1]}
											aria-label={t("app:menu.options")}
											onClick={(e) => {
												e.stopPropagation();

												handleMenu(e, item.guid, { overflow: true }, item);
											}}
											dataCy={`overflow-menu-${item.guid}`}
										/>
									</div>
									{overflowMenuOpen && menuOptions && menuOptions.length > 0 && (
										<NonModalMenu
											id="item-overflow-menu"
											className="overflow-menu"
											anchorEl={menuAnchor.anchor}
											keepMounted
											open={Boolean(menuAnchor.anchor)}
											onClose={handleMenu}
											position="bottom-end"
										>
											{menuOptions.map((option) => (
												<MenuItemWithTooltip
													key={option.label}
													tooltip={option.tooltip}
													placement="left"
													onClick={option.actionFunction}
													separator={option.separator ? true : null}
													data-cy={`item-menu-option-${option["data-cy"]}`}
												>
													{option.label}
												</MenuItemWithTooltip>
											))}
										</NonModalMenu>
									)}
								</>
							)}

							{item.added && item.submission && (
								<div className={classes.submissionDetails}>
									<ContentAgendaItemSubmissionDetails item={item} />
								</div>
							)}
							{showNotes && (
								<div className={classes.boardNotes}>
									<AccessibleIconButton
										id={`edit-board-notes-${item.guid}`}
										aria-label={t("editBoardNotes")}
										onClick={handleViewNotes}
										iconName={item.fields?.BoardNotes?.Value ? "notes" : "notes-add"}
										dataCy="edit-board-notes"
										tooltipText={t("editBoardNotes")}
										name="edit-board-notes"
									/>
									{isBoardNotesActive && (
										<BoardNotes
											anchorEl={notesAnchor}
											item={item}
											isMeetingTemplate={isMeetingTemplate}
											open
											meetingId={meetingId}
											updateNotes={(value, item) => handleUpdateNotes(item, "BoardNotes", value)}
											closeNotes={closeNotes}
											queueFileUploads={queueFileUploads}
											addPolicy={addPolicy}
											addGoals={addGoals}
										/>
									)}
								</div>
							)}
							{!readOnly && isActive && editorFieldTextDeleted && !hideField && (
								<Box
									className="description-container"
									mr={6}
									mt={1}
									tabIndex={0}
									onClick={() => {
										addText(item.guid);
									}}
									onKeyPress={(e) => {
										if (e.key === "Enter" || e.key === " ") {
											addText(item.guid);
											e.preventDefault();
											e.stopPropagation();
										}
									}}
								>
									<Typography component="div">{t("addDescription")}</Typography>
								</Box>
							)}

							<ListItem
								disableGutters
								className={clsx(classes.descriptionContainer, {
									[classes.editorFieldHide]: hideText || hideField,
								})}
							>
								<Box
									className={clsx(classes.editorFieldDescriptionContent, {
										[classes.fieldReadOnly]: readOnly,
									})}
									data-fieldname={`${item.guid}-text`}
									ref={
										!readOnly
											? (el) => {
													afterElementRefSet(elementsIndex + 1);

													return (elementsRef.current[elementsIndex + 1] = el);
												}
											: undefined
									}
								>
									{readOnly ? (
										Parser(item.fields?.Text?.Value || "", parserOptions)
									) : (
										<div className={classes.editorFieldPlaceHolder}></div>
									)}
								</Box>
								<ListItemSecondaryAction
									classes={{ root: "section-overflow-trash" }}
									className={active === `${item.guid}-text` && !hideText && !readOnly ? "" : classes.editorFieldHide}
									onClick={() => {
										removeText(item.guid);
									}}
								>
									<IconButton
										classes={{ root: "content-well-icon-button" }}
										size="large"
										aria-label={t("deleteText")}
										data-cy="delete-text"
									>
										<Icon name="trash" color={blackColor[1]}></Icon>
									</IconButton>
								</ListItemSecondaryAction>
							</ListItem>
							{!item.added && (
								<div
									onClick={(e) => {
										e.stopPropagation();
										onPendingApprovalClick(item.guid);
									}}
								>
									<div className={classes.dottedListItem}>
										<div>
											<Box className={clsx(classes.editorFieldContent, classes.fieldContentMargin, {})} data-fieldname={item.guid}>
												{Parser(item.fields?.Name?.Value || "", parserOptions)}
											</Box>

											<div className={classes.submissionDetails}>
												<ContentAgendaItemSubmissionDetails item={item} />
											</div>
										</div>
										<Box className={clsx(classes.editorFieldContent, classes.fieldContentMargin, {})} data-fieldname={item.guid}>
											{Parser(item.fields?.Text?.Value || "", parserOptions)}
										</Box>
										{item.proposedRecommendation && (
											<div className={clsx(classes.agendaAction, "flex")}>
												<div className={clsx(classes.motionIcon, classes.proposedRecommendationIcon)} data-icon="recommendation">
													<Icon name="recommendation" color={readOnly ? grayColor[0] : blackColor[1]} />
												</div>
												<Box className={clsx(classes.editorFieldContent, classes.fieldContentMargin, {})} data-fieldname={item.guid}>
													{Parser(item.proposedRecommendation ? item.proposedRecommendation : "", parserOptions)}
												</Box>
											</div>
										)}
									</div>
								</div>
							)}
							{!readOnly && isActive && addItemMenuOptions && addItemMenuOptions.length > 0 && !shouldLockAgenda && (
								<div className={classes.addButton}>
									<ButtonGroup variant="contained" color="primary" aria-label="split button">
										<Button
											className="split-button"
											variant="outlined"
											onClick={() => addItemMenuOptions[0].actionFunction(item)}
											data-cy={`main-split-button-${item.guid}`}
										>
											{addItemMenuOptions[0].button}
										</Button>
										<Button
											variant="outlined"
											className="split-icon-button"
											aria-controls={addMenuOpen ? "split-button-menu" : undefined}
											aria-expanded={addMenuOpen ? "true" : undefined}
											aria-label="select agenda action"
											aria-haspopup="menu"
											onClick={(e) => {
												e.stopPropagation();

												handleMenu(e, item.guid, { overflow: false }, item);
											}}
											data-cy={`split-button-arrow-${item.guid}`}
										>
											<Icon name="expand-down" color={primaryColor[0]} />
										</Button>
									</ButtonGroup>
									{addMenuOpen && (
										<NonModalMenu
											id={`add-button-menu-${item.guid}`}
											className="overflow-menu"
											anchorEl={menuAnchor.anchor}
											open={Boolean(menuAnchor.anchor)}
											onClose={handleMenu}
										>
											{addItemMenuOptions.map((option) => (
												<MenuItem
													key={option.label}
													onClick={() => option.actionFunction(item)}
													data-cy={`add-menu-option-${option["data-cy"]}`}
												>
													{option.label}
												</MenuItem>
											))}
										</NonModalMenu>
									)}
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
		</Component>
	);
};

export default React.memo(MeetingItem);
