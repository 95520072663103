import React from "react";
import { useTranslation } from "react-i18next";
import clsx from "clsx";

import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
	userSmallLabel: {
		fontSize: "12px",
		textAlign: "center",
	},
	userCheckbox: {
		width: "48px",
		textAlign: "center",
		boxSizing: "border-box",
		[theme.breakpoints.down("md")]: {
			width: "100%",
		},
	},
}));

const BoardUserOptionLabels = () => {
	const { t } = useTranslation("boards");
	const classes = useStyles();

	return (
		<>
			<div id="member-label" className={clsx(classes.userSmallLabel, classes.userCheckbox)}>
				{t("detail.member")}
			</div>
			<div id="staff-label" className={clsx(classes.userSmallLabel, classes.userCheckbox)}>
				{t("detail.staff")}
			</div>
			<div id="administrator-label" className={clsx(classes.userSmallLabel, classes.userCheckbox)}>
				{t("detail.administrator")}
			</div>
			<div id="voting-member-label" className={clsx(classes.userSmallLabel, classes.userCheckbox)}>
				{t("detail.votingMember")}
			</div>
			<div id="tie-breaker-label" className={clsx(classes.userSmallLabel, classes.userCheckbox)}>
				{t("detail.tieBreaker")}
			</div>
			<div id="show-on-portal-label" className={clsx(classes.userSmallLabel, classes.userCheckbox)}>
				{t("detail.showOnPortal")}
			</div>
		</>
	);
};

export default BoardUserOptionLabels;
