import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Link from "@mui/material/Link";

import { useWidthUp } from "atlas/utils/useWidth";
import { UserContext } from "contexts/User/UserContext";
import { SettingsContext } from "contexts/Settings/SettingsContext";
import { useSelector } from "react-redux";
import LoginAvatar from "components/Avatar/LoginAvatar";
import SignInForm from "components/SignInForm/SignInForm";

export const SkipToContentId = "skiptoContent";

const Login = () => {
	const widthUpSm = useWidthUp("sm");
	const { t } = useTranslation("account");
	const navigate = useNavigate();
	const location = useLocation();
	const appReducer = useSelector((state) => state.appReducer);
	const {
		signIn: { show },
	} = appReducer;

	const redirectAfterSignIn = (user) => {
		// Display skip to content
		var skipToContent = document.getElementById(SkipToContentId);
		if (skipToContent && skipToContent.parentNode) {
			skipToContent.parentNode.style.display = null;
		}

		if (user && user.redirect) {
			window.location.href = user.redirect;
			// } else if (user && !user.boardMember && !user.boardAdmin && !user.systemAdministrator) {
			// 	// Redirect staff to the portal for now
			// 	window.location.href = "/";
		} else if (location && location.state && location.state.from && location.state.from.pathname) {
			navigate(location.state.from.pathname + location.state.from.search);
		} else {
			navigate("/");
		}
	};

	const handleForgotPassword = () => {
		navigate("/forgot-password");
	};

	const SignInDisappear = () => {
		if (show) {
			return null;
		} else {
			if (widthUpSm) {
				return <Card className="login-card">{content}</Card>;
			} else {
				return <div className="login-small">{content}</div>;
			}
		}
	};

	const content = (
		<>
			<LoginAvatar />
			<SettingsContext.Consumer>
				{(settingsContext) => (
					<UserContext.Consumer>
						{(userContext) => (
							<SignInForm
								label={t("signIn")}
								afterSignIn={redirectAfterSignIn}
								settingsContext={settingsContext}
								userContext={userContext}
							/>
						)}
					</UserContext.Consumer>
				)}
			</SettingsContext.Consumer>
			<Box className="text-center" mt={3}>
				<Link component="button" variant="body1" onClick={handleForgotPassword} data-cy="forgotPassword">
					{t("buttons.forgotPassword")}
				</Link>
			</Box>
		</>
	);

	return SignInDisappear();
};

export default Login;
