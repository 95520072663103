import React from "react";
import SvgIcon from "@mui/material/SvgIcon";
import themeConfig from "../../atlas/assets/jss/theme";
import getOr from "lodash/fp/getOr";

const icon = (props) => {
	const { color } = props;
	const fillColor = color ? getOr(color, ["palette", color, "main"], themeConfig) : "currentColor";

	return (
		<SvgIcon {...props}>
			<defs>
				<path
					d="M20.5981308,10.5981308 C21.3723618,10.5981308 22,11.225769 22,12 C22,12.774231 21.3723618,13.4018692 20.5981308,13.4018692 L13.4018692,13.4018692 L13.4018692,20.5981308 C13.4018692,21.3723618 12.774231,22 12,22 C11.225769,22 10.5981308,21.3723618 10.5981308,20.5981308 L10.5981308,13.4018692 L3.40186916,13.4018692 C2.6276382,13.4018692 2,12.774231 2,12 C2,11.225769 2.6276382,10.5981308 3.40186916,10.5981308 L10.5981308,10.5981308 L10.5981308,3.40186916 C10.5981308,2.6276382 11.225769,2 12,2 C12.774231,2 13.4018692,2.6276382 13.4018692,3.40186916 L13.4018692,10.5981308 L20.5981308,10.5981308 Z"
					id="add-path-1"
				/>
			</defs>

			<g id="add" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g id="Icons/24px/Unselected/Add">
					<mask id="add-mask-2" fill="white">
						<use xlinkHref="#add-path-1" />
					</mask>

					<g fillRule="nonzero" />
					<g mask="url(#add-mask-2)" fill={fillColor} fillRule="evenodd">
						<rect x="0" y="0" width="24" height="24" />
					</g>
				</g>
			</g>
		</SvgIcon>
	);
};

export default icon;
