import React from "react";

import Icon from "atlas/components/Icon/Icon";

const ApprovalProgressPendingArrow = (props) => {
	const { dataCy } = props;

	return (
		<li data-cy={dataCy}>
			<Icon name="arrow-right" size="32" />
		</li>
	);
};

export default ApprovalProgressPendingArrow;
