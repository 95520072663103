import React, { useState, useEffect, useContext } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useMatch } from "react-router-dom";
import { resetPageConfigs, updatePageConfigs } from "redux/app/actions";
import { useTranslation } from "react-i18next";
import queryString from "query-string";
import request from "superagent";
import { formatDate } from "utils/date";

import makeStyles from "@mui/styles/makeStyles";

import ComponentContainer from "atlas/components/ComponentContainer/ComponentContainer";
import withErrorHandling from "components/ErrorHOC";
import { API_HOST } from "config/env";
import { UserContext } from "contexts/User/UserContext";
import isSignedIn from "utils/isSignedIn";
import CircularProgressIndicator from "atlas/components/Progress/CircularProgressIndicator";
import requestToSpeakStyle from "./jss/requestToSpeakStyle";

const useStyles = makeStyles(requestToSpeakStyle);

const PublicRequestToSpeakCancel = () => {
	const { setUser } = useContext(UserContext);
	let { user } = useContext(UserContext);
	const { params: { id } = {} } = useMatch({ path: "/public/requesttospeak/:id/cancel", end: true }) || {};
	const location = useLocation();
	const { t } = useTranslation("requestToSpeak");
	const [canceling, setCanceling] = useState(true);
	const [message, setMessage] = useState("");
	const dispatch = useDispatch();
	const classes = useStyles();

	const cancelRequest = () => {
		const { token } = queryString.parse(location.search) || {};

		return request
			.post(`${API_HOST}/api/requesttospeak/${id}/cancel`)
			.withCredentials()
			.send({ token })
			.then((res) => {
				setCanceling(false);

				const { status, body: { meeting: { name, startTime } = {} } = {} } = res;
				if (status === 200) {
					const formattedDate = formatDate(null, startTime, null, t("app:at"), "", "", false);
					setMessage(t("publicSubmission.cancel.success", { meeting: `${name} ${formattedDate}` }));
				} else {
					setMessage(t("publicSubmission.cancel.error"));
				}
			})
			.catch(() => {
				setCanceling(false);

				setMessage(t("publicSubmission.cancel.error"));
			});
	};

	useEffect(() => {
		isSignedIn((res) => {
			if (!user || user.username !== res.body.username) {
				user = setUser({
					username: res.body.username,
					systemAdministrator: res.body.systemAdministrator,
					boardAdmin: res.body.boardAdmin,
					boardMember: res.body.boardMember,
					boardStaff: res.body.boardStaff,
					goalAdmin: res.body.goalAdmin,
					requestToSpeakAdmin: res.body.requestToSpeakAdmin,
					documentLibrary: res.body.documentLibrary,
					publicUser: false,
				});
			}
		});

		dispatch(resetPageConfigs({}));
		dispatch(
			updatePageConfigs({
				title: t("publicSubmission.title"),
				telemetryPage: "Request To Speak Cancel",
			}),
		);

		cancelRequest();
	}, []);

	return (
		<ComponentContainer fullHeight onlyScrollbar>
			{canceling ? (
				<CircularProgressIndicator />
			) : (
				<div className={classes.cancelNotification}>
					<div>{message}</div>
				</div>
			)}
		</ComponentContainer>
	);
};

export default withErrorHandling(PublicRequestToSpeakCancel);
