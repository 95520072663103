import React from 'react';

import { Tooltip as MuiTooltip } from '@mui/material';

const Tooltip = (props) => {
	const {
		disableFocusListener = true,
		disableHoverListener = false,
		PopperProps = { disablePortal: false },
		enterDelay = 500,
		enterNextDelay = 500,
		placement = 'bottom',
		title,
		children,
		...otherProps
	} = props;

	return title ? (
		<MuiTooltip
			{...otherProps}
			disableFocusListener={disableFocusListener}
			disableHoverListener={disableHoverListener}
			PopperProps={PopperProps}
			enterDelay={enterDelay}
			enterNextDelay={enterNextDelay}
			placement={placement}
			title={title}
		>
			{children}
		</MuiTooltip>
	) : (
		children
	);
};

export default Tooltip;
