export const GET_AGENDA_BUILDER_AGENDA_ITEMS_PENDING = "GET_AGENDA_BUILDER_AGENDA_ITEMS_PENDING";
export const GET_AGENDA_BUILDER_AGENDA_ITEMS_FULFILLED = "GET_AGENDA_BUILDER_AGENDA_ITEMS_FULFILLED";
export const UPDATE_AGENDA_BUILDER_AGENDA_ITEMS = "UPDATE_AGENDA_BUILDER_AGENDA_ITEMS";
export const UPDATE_AGENDA_SCRATCHPAD = "UPDATE_AGENDA_SCRATCHPAD";
export const ADD_AGENDA_BUILDER_AGENDA_MEETING_ITEM = "ADD_AGENDA_BUILDER_AGENDA_MEETING_ITEM";
export const PERSIST_AGENDA_BUILDER_AGENDA_ITEMS_PENDING = "PERSIST_AGENDA_BUILDER_AGENDA_ITEMS_PENDING";
export const PERSIST_AGENDA_BUILDER_AGENDA_ITEMS_FULFILLED = "PERSIST_AGENDA_BUILDER_AGENDA_ITEMS_FULFILLED";
export const PERSIST_AGENDA_BUILDER_AGENDA_ITEMS_FAILED = "PERSIST_AGENDA_BUILDER_AGENDA_ITEMS_FAILED";
export const REORDER_AGENDA_BUILDER_AGENDA_ITEMS = "REORDER_AGENDA_BUILDER_AGENDA_ITEMS";
export const DELETE_AGENDA_BUILDER_AGENDA_MEETING_ITEM = "DELETE_AGENDA_BUILDER_AGENDA_MEETING_ITEM";
export const REMOVE_AGENDA_BUILDER_AGENDA_MEETING_ITEM = "REMOVE_AGENDA_BUILDER_AGENDA_MEETING_ITEM";
export const RESTORE_AGENDA_BUILDER_AGENDA_MEETING_ITEM = "RESTORE_AGENDA_BUILDER_AGENDA_MEETING_ITEM";
export const SET_ACTIVE = "SET_AGENDA_BUILDER_ACTIVE";
export const SET_AGENDA_BUILDER_CHANGESET_ID = "SET_AGENDA_BUILDER_CHANGESET_ID";
export const CLEAR_AGENDA_BUILDER_STATUS = "CLEAR_AGENDA_BUILDER_STATUS";
export const UPDATE_OUT_OF_SYNC_STATUS = "UPDATE_OUT_OF_SYNC_STATUS";
export const SYNC_MINUTE_ITEMS = "SYNC_MINUTE_ITEMS";
export const TOGGLE_TABLE_OF_CONTENTS = "TOGGLE_TABLE_OF_CONTENTS";
