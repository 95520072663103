import React from "react";
import { useTranslation } from "react-i18next";

import makeStyles from "@mui/styles/makeStyles";
import { TableCell } from "@mui/material";

import ButtonWithTooltip from "atlas/components/Buttons/ButtonWithTooltip";

const useStyles = makeStyles({
	cellButton: {
		padding: "8px",
		height: "30px",
	},
});

const PasswordSetupButtonCell = (props) => {
	const { row, label, tooltip, onClick } = props;
	const { t } = useTranslation("users");
	const classes = useStyles();

	return (
		<TableCell data-cy="button">
			{row.passwordExpiryDate ? (
				<ButtonWithTooltip
					className={classes.cellButton}
					key={`button-${row.id}`}
					variant="outlined"
					title={tooltip || label}
					onClick={onClick}
					dataCy={`send-password-request-button-${row.id}`}
				>
					{label}
				</ButtonWithTooltip>
			) : (
				<span>{t("passwordUpdated")}</span>
			)}
		</TableCell>
	);
};

export default PasswordSetupButtonCell;
