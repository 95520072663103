import React from "react";
import { Routes, Route } from "react-router-dom";

import Presentation from "views/LiveMeeting/Presentation";

const MeetingPresentationRoutes = () => {
	// Base path = /meeting/presentation/
	return (
		<Routes>
			<Route path=":id" element={<Presentation />} />
		</Routes>
	);
};

export default MeetingPresentationRoutes;
