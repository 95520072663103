import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import request from "superagent";

import { Box } from "@mui/material";

import Icon from "atlas/components/Icon/Icon";
import { API_HOST } from "config/env";
import GenericDialog from "atlas/components/Dialogs/GenericDialog";
import notifierMessage from "utils/notifierMessage";
import { setSnackbarOptions } from "redux/snackBar/actions";

const MeetingTemplateDeleteDialog = (props) => {
	const { show = true, meetingTemplate, onClose, afterDelete, undoDelete } = props;
	const { t } = useTranslation("meetings");
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [deleting, setDeleting] = useState(false);
	const meetingListUrl = `/meetings?meetingType=${meetingTemplate?.id}`;

	const handleDelete = () => {
		setDeleting(true);

		request
			.delete(`${API_HOST}/api/meetingtype/${meetingTemplate.id}`)
			.withCredentials()
			.send({})
			.then(() => {
				let option = notifierMessage(t("deleteMeetingTemplateDialog.snackbar.success", { name: meetingTemplate.name }), "success", () =>
					undoDelete(meetingTemplate),
				);
				dispatch(setSnackbarOptions(option));
				onClose();
				afterDelete(meetingTemplate);
			})
			.catch((err) => handleError(err));
	};

	const handleCancel = () => {
		onClose();
	};

	const handleError = (err) => {
		console.log("Error", err);
		onClose();
	};

	const i18n = t("deleteMeetingTemplateDialog", { name: meetingTemplate.name, meetings: meetingTemplate.meetings });

	const dialog = {
		title: i18n.title,
		line1: i18n.line1,
		line2: i18n.line2,
		primaryTitle: t("app:buttons.delete"),
		primaryAction: handleDelete,
		secondaryTitle: t("app:buttons.cancel"),
		secondaryAction: handleCancel,
	};

	return (
		<GenericDialog
			show={show}
			title={dialog.title}
			primaryAction={dialog.primaryAction}
			primaryTitle={dialog.primaryTitle}
			primaryDisabled={deleting}
			secondaryAction={dialog.secondaryAction}
			secondaryTitle={dialog.secondaryTitle}
			secondaryDisabled={deleting}
			clickAwayDisabled={deleting}
			closeIcon={<Icon name="close" />}
			data-cy="delete-meeting-template"
		>
			<Box fontWeight="fontWeightBold">{dialog.line1}</Box>
			{meetingTemplate.meetings > 0 ? (
				<Box mt="20px">
					<a
						href={`/home${meetingListUrl}`}
						onClick={(e) => {
							e.preventDefault();
							navigate(meetingListUrl);
						}}
					>
						{dialog.line2}
					</a>
				</Box>
			) : null}
		</GenericDialog>
	);
};

export default MeetingTemplateDeleteDialog;
