import { produce } from "immer";
import { v4 as uuid } from "uuid";

import cloneDeep from "lodash/fp/cloneDeep";
import findIndex from "lodash/fp/findIndex";
import { createMeetingElement, prepareAttachmentDuplication } from "utils/meetingElement";
import telemetryAddEvent from "utils/telemetryAddEvent";

import { updateAgenda, reOrderItemArray, findItemByID, isHeading, getSectionLength, getSubsectionLength } from "./utils";

export function addHeader(guid) {
	const currentState = this.state;
	const headerId = uuid();

	telemetryAddEvent("Agenda builder - Add agenda heading");

	const nextState = produce(
		currentState,
		(draft) => {
			const foundIndex = guid === "toc-header" ? 0 : findIndex((item) => item.guid === guid, draft.items);
			const newHeader = createMeetingElement({
				guid: headerId,
				itemType: 10,
				indent: "0",
				order: foundIndex,
				number: 1,
			});

			this.itemIdsToUpdate.push(headerId);
			draft.items.push(newHeader);

			reOrderItemArray(draft.items, this, currentState.customNumbering);
		},
		(patches, inversePatches) => {
			if (patches.length) {
				this.redoStack.push([...patches]);
				this.undoStack.push([...inversePatches]);
			}
		},
	);

	updateAgenda(this, { items: nextState.items }, headerId);
}

export function duplicateHeader(guid) {
	const currentState = this.state;
	const dupeId = uuid();

	const nextState = produce(
		currentState,
		(draft) => {
			const originalItem = findItemByID(guid, draft.items);
			const currentIndex = findIndex((item) => item.guid === guid, draft.items);
			const numberOfItemsToDuplicate = isHeading(originalItem)
				? getSectionLength(guid, draft.items)
				: getSubsectionLength(guid, draft.items);
			const newItems = [];
			for (let i = currentIndex; i < currentIndex + numberOfItemsToDuplicate; i += 1) {
				const dupeItem = prepareAttachmentDuplication(cloneDeep(draft.items[i]));
				if (i === currentIndex) {
					dupeItem.guid = dupeId;
				} else {
					dupeItem.guid = uuid();
				}

				if (dupeItem.attachments) {
					// eslint-disable-next-line no-plusplus
					for (let j = 0; j < dupeItem.attachments.length; j++) {
						dupeItem.attachments[j].itemGuid = dupeItem.guid;
					}
				}
				this.itemIdsToUpdate.push(dupeItem.guid);
				newItems.push(dupeItem);
			}

			draft.items.splice(currentIndex + numberOfItemsToDuplicate, 0, ...newItems);
			reOrderItemArray(draft.items, this, currentState.customNumbering);
		},
		(patches, inversePatches) => {
			if (patches.length) {
				this.redoStack.push([...patches]);
				this.undoStack.push([...inversePatches]);
			}
		},
	);

	updateAgenda(this, { items: nextState.items }, dupeId);
}

export function deleteHeader(guid) {
	const currentState = this.state;
	let newActiveId;
	const nextState = produce(
		currentState,
		(draft) => {
			const itemToDelete = findItemByID(guid, draft.items);
			const numberOfItemsToDelete = isHeading(itemToDelete) ? getSectionLength(guid, draft.items) : getSubsectionLength(guid, draft.items);
			const currentIndex = findIndex((item) => item.guid === guid, draft.items);
			for (let i = currentIndex; i < currentIndex + numberOfItemsToDelete; i += 1) {
				const item = draft.items[i];
				item.deleted = true;
				this.itemIdsToDelete.push(item.guid);
			}

			const nonDeletedItems = draft.items.filter((item) => {
				return !item.deleted;
			});
			draft.items.splice(0, draft.items.length, ...nonDeletedItems);

			if (currentIndex === 0) {
				newActiveId = "toc-header";
			} else {
				newActiveId = draft.items[currentIndex - 1].guid;
			}

			reOrderItemArray(draft.items, this, currentState.customNumbering);
		},
		(patches, inversePatches) => {
			if (patches.length) {
				this.redoStack.push([...patches]);
				this.undoStack.push([...inversePatches]);
			}
		},
	);

	updateAgenda(this, { items: nextState.items }, newActiveId);
}

export function updateHeader(guid, data, cancelNextRender) {
	const currentState = this.state;
	let cancelChanges = false;
	const nextState = produce(
		currentState,
		(draft) => {
			const item = findItemByID(guid, draft.items);
			if (item && item.fields.Name.Value !== data) {
				item.fields.Name.Value = data;
			} else {
				cancelChanges = true;
			}
		},
		(patches, inversePatches) => {
			if (patches.length) {
				if (patches.length === 1) {
					const firstPatch = patches[0].value.includes("<br />") ? patches[0].value.split("<br />").join("<br>") : patches[0].value;
					const firstInversePatches = inversePatches[0].value.includes("<br />")
						? inversePatches[0].value.split("<br />").join("<br>")
						: inversePatches[0].value;
					if (firstPatch !== firstInversePatches) {
						this.redoStack.push([...patches]);
						this.undoStack.push([...inversePatches]);
					}
				} else {
					this.redoStack.push([...patches]);
					this.undoStack.push([...inversePatches]);
				}
			}
		},
	);

	if (!cancelChanges) {
		this.itemIdsToUpdate.push(guid);
		updateAgenda(this, { items: nextState.items }, null, cancelNextRender);
	}
}
