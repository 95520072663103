import React from "react";
import { useTranslation } from "react-i18next";

import makeStyles from "@mui/styles/makeStyles";

import { Typography, Box } from "@mui/material";
import GenericEditor from "components/Editor/GenericEditor";
import OutlinedInput from "atlas/components/FormControls/OutlinedInput";
import StyledSwitch from "atlas/components/FormControls/StyledSwitch";
import CircularProgressIndicator from "atlas/components/Progress/CircularProgressIndicator";
import { useWidthDown } from "atlas/utils/useWidth";
import settingsStyle from "assets/jss/components/settingsStyle";

const useStyles = makeStyles(settingsStyle);

const editors = [];

const RequestToSpeak = (props) => {
	const { settings, updateSettingsValue } = props;
	const widthDownSm = useWidthDown("sm");
	const { t } = useTranslation("settings");
	const classes = useStyles();

	const handleChange = (e, setting, checkbox) => {
		const {
			target: { value, checked },
		} = e;

		if (settings[setting] === value) {
			return; // don't re-save unchanged content;
		}

		updateSettingsValue(setting, checkbox ? checked : value);
	};

	const handleEditorChange = (_event, editor, setting) => {
		const newContent = editor.getData();

		if (settings[setting] === newContent) {
			return; // don't re-save unchanged content;
		}

		updateSettingsValue(setting, newContent);
	};

	const setEditorsData = (name, value) => {
		editors[name] = value;
	};

	return (
		<>
			{settings ? (
				<>
					<Box>
						<div className={widthDownSm ? classes.labelWithOtherContentMobile : classes.labelWithOtherContent}>
							<label className={classes.leftLabel} htmlFor="codeOfConductSummary">
								<Typography variant="h4" className={classes.bold}>
									{t("codeOfConductSummary")}
								</Typography>
							</label>
							<Box className={widthDownSm ? classes.labelSwitchMobile : classes.labelSwitch}>
								<StyledSwitch
									classes={{
										label: classes.switchLabel,
										stateLabel: classes.switchInstructions,
									}}
									inline
									label={t("requestToSpeak")}
									useLabel
									title={t("tooltips.requestToSpeakToggle")}
									size="small"
									objectToUpdate={settings != null && settings.requestToSpeakEnabled != null ? settings : { requestToSpeakEnabled: false }}
									fieldToUpdate="requestToSpeakEnabled"
									onChange={(checked) => handleChange({ target: { checked } }, "requestToSpeakEnabled", true)}
									data-cy="request-to-speak-enabled"
								/>
							</Box>
						</div>
						<hr />
						<OutlinedInput
							id="codeOfConductSummary"
							value={settings.codeOfConductSummary || ""}
							onChange={(e) => handleChange(e, "codeOfConductSummary")}
							noDefaultClassName
							fullWidth
							multiline
							size="small"
							data-cy="codeOfConductSummary"
						/>
					</Box>
					<Box>
						<Typography variant="h4" className={classes.bold}>
							{t("codeOfConduct")}
						</Typography>
						<hr />
						<GenericEditor
							loadAsync
							toolbar="scratchpad"
							name="codeOfConduct"
							content={settings.codeOfConduct || ""}
							onChange={(_event, editor) => handleEditorChange(_event, editor, "codeOfConduct")}
							setEditorsData={setEditorsData}
							features={[]}
							mt={0}
						/>
					</Box>
					{/* <Box mt={2}>
						<Typography variant="h4" className={classes.bold} mt={2}>
							{t("email.rtsRequestChange")}
						</Typography>
						<hr />
						<Typography variant="body1">{t("email.subjectLine")}</Typography>
						<GenericEditor
							toolbar="notificationsSettings"
							name="requestToSpeakNotificationsRequestChangesSubject"
							content={settings.requestToSpeakNotificationsRequestChangesSubject || ""}
							onChange={(_event, editor) => handleEditorChange(_event, editor, "requestToSpeakNotificationsRequestChangesSubject")}
							setEditorsData={setEditorsData}
							mt={0}
						/>
						<Typography variant="body1">{t("email.body")}</Typography>
						<GenericEditor
							toolbar="notificationsSettings"
							name="requestToSpeakNotificationsRequestChangesBody"
							content={settings.requestToSpeakNotificationsRequestChangesBody || ""}
							onChange={(_event, editor) => handleEditorChange(_event, editor, "requestToSpeakNotificationsRequestChangesBody")}
							setEditorsData={setEditorsData}
							mt={0}
						/>
					</Box> */}
					<Box mt={2}>
						<Typography variant="h4" className={classes.bold}>
							{t("email.rtsApproved")}
						</Typography>
						<hr />
						<Typography variant="body1">
							<label htmlFor="requestToSpeakNotificationsApprovedSubject">{t("email.subjectLine")}</label>
						</Typography>
						<OutlinedInput
							id="requestToSpeakNotificationsApprovedSubject"
							value={settings.requestToSpeakNotificationsApprovedSubject || ""}
							onChange={(e) => handleChange(e, "requestToSpeakNotificationsApprovedSubject")}
							noDefaultClassName
							fullWidth
							size="small"
							data-cy="requestToSpeakNotificationsApprovedSubject"
						/>
						<Typography variant="body1">{t("email.body")}</Typography>
						<GenericEditor
							loadAsync
							toolbar="notificationsSettings"
							name="requestToSpeakNotificationsApprovedBody"
							content={settings.requestToSpeakNotificationsApprovedBody || ""}
							onChange={(_event, editor) => handleEditorChange(_event, editor, "requestToSpeakNotificationsApprovedBody")}
							setEditorsData={setEditorsData}
							mt={0}
						/>
					</Box>
					{/* <Box mt={2}>
						<Typography variant="h4" className={classes.bold}>
							{t("email.rtsRejected")}
						</Typography>
						<hr />
						<Typography variant="body1">{t("email.subjectLine")}</Typography>
						<GenericEditor
							toolbar="notificationsSettings"
							name="requestToSpeakNotificationsRejectedSubject"
							content={settings.requestToSpeakNotificationsRejectedSubject || ""}
							onChange={(_event, editor) => handleEditorChange(_event, editor, "requestToSpeakNotificationsRejectedSubject")}
							setEditorsData={setEditorsData}
							mt={0}
						/>
						<Typography variant="body1">{t("email.body")}</Typography>
						<GenericEditor
							toolbar="notificationsSettings"
							name="requestToSpeakNotificationsRejectedBody"
							content={settings.requestToSpeakNotificationsRejectedBody || ""}
							onChange={(_event, editor) => handleEditorChange(_event, editor, "requestToSpeakNotificationsRejectedBody")}
							setEditorsData={setEditorsData}
							mt={0}
						/>
					</Box>
					<Box mt={2}>
						<Typography variant="h4" className={classes.bold}>
							{t("email.rtsCanceled")}
						</Typography>
						<hr />
						<Typography variant="body1">{t("email.subjectLine")}</Typography>
						<GenericEditor
							toolbar="notificationsSettings"
							name="requestToSpeakNotificationsCanceledSubject"
							content={settings.requestToSpeakNotificationsCanceledSubject || ""}
							onChange={(_event, editor) => handleEditorChange(_event, editor, "requestToSpeakNotificationsCanceledSubject")}
							setEditorsData={setEditorsData}
							mt={0}
						/>
						<Typography variant="body1">{t("email.body")}</Typography>
						<GenericEditor
							toolbar="notificationsSettings"
							name="requestToSpeakNotificationsCanceledBody"
							content={settings.requestToSpeakNotificationsCanceledBody || ""}
							onChange={(_event, editor) => handleEditorChange(_event, editor, "requestToSpeakNotificationsCanceledBody")}
							setEditorsData={setEditorsData}
							mt={0}
						/>
					</Box> */}
				</>
			) : (
				<CircularProgressIndicator />
			)}
		</>
	);
};

export default RequestToSpeak;
