import React from "react";
import { useNavigate } from "react-router-dom";

import makeStyles from "@mui/styles/makeStyles";
import { TableCell } from "@mui/material";

import tableDataStyle from "assets/jss/components/tableDataStyle";

const useStyles = makeStyles(tableDataStyle);

const LinkCell = (props) => {
	const { cell, row, rowIndex, value } = props;
	const navigate = useNavigate();
	const classes = useStyles();

	const linkClick = (e) => {
		e.preventDefault();

		navigate(`/users/edit/${row.id}`);
	};

	return (
		<TableCell className={classes.cell}>
			{value && (
				<a
					id={cell.id === "userName" ? `enhanced-table-checkbox-${rowIndex}` : `${cell.id}-${row.id}`}
					className={classes.link}
					href={`/home/users/edit/${row.id}`}
					onClick={linkClick}
				>
					{value}
				</a>
			)}
		</TableCell>
	);
};

export default LinkCell;
