import { Command } from "@ckeditor/ckeditor5-core";
import Collection from "@ckeditor/ckeditor5-utils/src/collection";
import { findLinkRange, getFileHrefInfo, getElementText } from "../inlineFileUtils";
import toMap from "@ckeditor/ckeditor5-utils/src/tomap";

export default class InlineFileCommandLinkMO extends Command {
	constructor(editor) {
		super(editor);
		this.featureConfigs = new Collection();
		this.anchorTitle = "";
		this.rootName = "main";
	}

	refresh() {
		const model = this.editor.model;
		const doc = model.document;

		this.value = doc.selection.getAttribute("fileHref");
		this.isEnabled = model.schema.checkAttributeInSelection(doc.selection, "fileHref");
	}

	getName() {
		const hrefInfo = getFileHrefInfo(this.value);
		return hrefInfo.name;
	}

	execute({ info }, { cb }) {
		const editor = this.editor;
		const model = editor.model;
		const selection = model.document.selection;

		model.change((writer) => {
			const { files, fileHref } = info;
			let fileHrefInfo = null;
			if (typeof fileHref === "string") {
				fileHrefInfo = JSON.parse(fileHref);
			}
			info.oldHref = null;
			if (selection.isCollapsed) {
				const position = selection.getFirstPosition();
				if (selection.hasAttribute("fileHref")) {
					info.oldHref = selection.getAttribute("fileHref");
					const hrefInfo = getFileHrefInfo(info.oldHref);
					let file = null;
					if (files.length > 0) {
						file = files[0];
						if (hrefInfo.guid) {
							// Do a replace
							file.guid = hrefInfo.guid;
							file.url = `${info.urlBase}/${hrefInfo.guid}`;
						}
					}
					const fileHref = getFileHref(file, fileHrefInfo, info);
					const linkRange = findLinkRange(position, selection.getAttribute("fileHref"), model);

					writer.setAttribute("fileHref", fileHref, linkRange);
				} else {
					const docFrag = writer.createDocumentFragment();
					for (let i = 0; i < files.length; i++) {
						const fileHref = getFileHref(files[i], fileHrefInfo, info);
						const attributes = toMap(selection.getAttributes());
						attributes.set("fileHref", fileHref);
						const node = writer.createText(files[i].name, attributes);
						writer.append(node, docFrag);
						writer.appendText(" ", docFrag);
						if (files.length > 1) {
							const range = writer.createRangeOn(node);
							range.end.offset += 1;
							writer.wrap(range, "paragraph");
						}
					}
					model.insertContent(docFrag, position);
				}
			} else {
				let file = null;
				if (files.length > 0) {
					file = files[0];
				}
				const fileHref = getFileHrefJson(file, fileHrefInfo, info);
				const ranges = model.schema.getValidRanges(selection.getRanges(), "fileHref");
				for (const range of ranges) {
					fileHref.text = getElementText(range);
					writer.setAttribute("fileHref", JSON.stringify(fileHref), range);
				}
			}
			info.rootName = this.rootName;
			cb(info);

			function getFileHrefJson(file, fileHrefInfo, info) {
				const { urlBase, featureStatuses, featureConfigs } = info;
				const fileHref = { url: "", guid: "", text: "", name: "", features: [] };
				if (file) {
					fileHref.url = `${urlBase}/${file.guid}`;
					fileHref.guid = file.guid;
					fileHref.name = file.name;
				} else {
					fileHref.url = fileHrefInfo.url;
					fileHref.guid = fileHrefInfo.guid;
					fileHref.name = fileHrefInfo.name;
				}
				for (let i = 0; i < featureStatuses.length; i++) {
					const value = featureStatuses[i].value;
					const feature = featureConfigs.get(featureStatuses[i].id);
					if (feature) {
						fileHref.features.push({ id: feature.id, className: feature.className, value: value, anchorTitle: feature.anchorTitle });
					}
				}
				return fileHref;
			}

			function getFileHref(file, fileHrefInfo, info) {
				return JSON.stringify(getFileHrefJson(file, fileHrefInfo, info));
			}
		});
	}
}
