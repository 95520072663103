import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import request from "superagent";
import { v4 as uuid } from "uuid";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";

import { API_HOST } from "config/env";
import CircularProgressIndicator from "atlas/components/Progress/CircularProgressIndicator";
import GenericDialog from "atlas/components/Dialogs/GenericDialog";
import ProgressBar from "atlas/components/Progress/ProgressBar";
import Icon from "atlas/components/Icon/Icon";
import { updateHandlers, PROGRESS_HUB } from "utils/communication/SignalrClient";

const useStyles = makeStyles(() => ({
	progressBar: {
		width: "240px",
		margin: "0 auto",
		marginTop: "24px",
	},
	agendaMemebrMinutesText: {
		fontWeight: "bold",
		fontSize: "16px",
		marginTop: "7px",
	},
	minutesList: {
		marginLeft: "20px",
	},
}));

const UpdateMinutesDialog = (props) => {
	const {
		show,
		onClose,
		meeting: { id, name },
		meeting,
		afterUpdate,
	} = props;
	const { t } = useTranslation("meetings");
	const [updating, setUpdating] = useState(false);
	const [showMinutesUpdateDialog, setMinutesUpdateDialog] = useState(show);
	const [progress, setProgress] = useState({
		label: " ",
		percent: 0,
	});
	const appReducer = useSelector((state) => state.appReducer);
	const {
		signalR: { client, handler },
	} = appReducer;
	const dispatch = useDispatch();
	const classes = useStyles();
	const isMounted = useRef();
	const progressId = "updateMeeting-progress";
	const guid = useRef();

	useEffect(() => {
		if (updating) {
			window.document.getElementById(progressId)?.scrollIntoView();
		}
	}, [updating]);

	useEffect(() => {
		isMounted.current = true;
		guid.current = uuid();

		updateHandlers(dispatch, handler, PROGRESS_HUB, { announceProgress: updateProgress });

		client.ensureStarted().then(() => client.progressHub.registerProgressGuid(guid.current));

		return () => {
			client.ensureStarted().then(() => client.progressHub.clearProgressGuid(guid.current));

			updateHandlers(dispatch, handler, PROGRESS_HUB, { announceProgress: null });

			isMounted.current = false;
		};
	}, [meeting]);

	const updateProgress = (percentage, message) => {
		setProgress({
			label: message,
			percent: percentage,
		});
	};

	const handleError = (err) => {
		setMinutesUpdateDialog(false);
		setUpdating(false);
		afterUpdate(false, err);
	};

	const handlePublish = () => {
		setUpdating(true);
		request
			.post(`${API_HOST}/api/meeting/${id}/UpdateMinutes`)
			.send({
				progressGuid: guid.current,
			})
			.then((res) => {
				setProgress({
					label: " ",
					percent: 100,
				});
				afterUpdate(true);
				setMinutesUpdateDialog(false);
				setUpdating(false);
			})
			.catch((err) => {
				if ([408, 504].indexOf(err.status) >= 0) {
					err.timeout = true;
				}
				handleError(err);
			});
	};

	const handleCancel = () => {
		onClose();
	};

	const handlePublishWithSave = () => {
		handlePublish();
	};

	const dialog = {
		title: t("updateMinutesDialog.title"),
		line1: t("updateMinutesDialog.line1", { name }),
		line2: t("updateMinutesDialog.line2"),
		primaryTitle: t("updateMinutesDialog.update"),
		primaryAction: () => handlePublishWithSave(),
		secondaryTitle: t("updateMinutesDialog.cancel"),
		secondaryAction: handleCancel,
	};

	return (
		<>
			<GenericDialog
				show={showMinutesUpdateDialog}
				title={dialog.title}
				primaryAction={dialog.primaryAction}
				primaryTitle={updating ? <CircularProgressIndicator color="secondary" size={20} minHeight="20px" /> : dialog.primaryTitle}
				primaryTooltip={dialog.primaryTitle}
				secondaryAction={dialog.secondaryAction}
				secondaryTitle={dialog.secondaryTitle}
				secondaryDisabled={updating}
				clickAwayDisabled={updating}
				closeIcon={<Icon name="close" />}
				data-cy="publish-dialog"
			>
				<>
					<Typography variant="h5">
						<Box mb={1}>{dialog.line1}</Box>
					</Typography>

					<Typography variant="h5">
						<Box mt={1} mb={1}>
							{dialog.line2}
						</Box>
					</Typography>

					<div className={classes.minutesList}>
						{meeting.addToAgendaMemberMinutes.length > 0 && (
							<div className={classes.addAgendaMinutes}>
								<Typography className={classes.agendaMemebrMinutesText} variant="body2" data-cy="mumber-Minutes-Added-To-Text">
									{t("updateMinutesDialog.memberMinutes")}&nbsp;
								</Typography>
								{meeting?.addToAgendaMemberMinutes?.map((info) => {
									return (
										<div className={classes.addToagendaLinks}>
											<Typography variant="body2" data-cy="mumber-Minutes-Added-To-Name">
												{info.Name}
											</Typography>
										</div>
									);
								})}
							</div>
						)}
						{meeting.addToAgendaPublicMinutes.length > 0 && (
							<div>
								<Typography className={classes.agendaMemebrMinutesText} variant="body2" data-cy="public-Minutes-Added-To-Text">
									{t("updateMinutesDialog.publicMinutes")}&nbsp;
								</Typography>
								{meeting?.addToAgendaPublicMinutes?.map((info) => {
									return (
										<div className={classes.addToagendaLinks}>
											<Typography variant="body2" data-cy="public-Minutes-Added-To-Name">
												{info.Name}
											</Typography>
										</div>
									);
								})}
							</div>
						)}
					</div>
					{updating && <ProgressBar id={progressId} className={classes.progressBar} label={progress.label} progress={progress.percent} />}
				</>
			</GenericDialog>
		</>
	);
};

export default UpdateMinutesDialog;
