import typographyStyle from "atlas/assets/jss/components/typographyStyle";
import { blackColor, grayColor } from "atlas/assets/jss/shared";

const filterStyle = {
	container: {
		padding: "16px",
	},
	titleContainer: {
		display: "flex",
		alignItems: "center",
		marginBottom: "24px",
		"& h2": {
			margin: "0",
		},
	},
	title: {
		...typographyStyle.drawerTitle,
		color: blackColor[1],
		flexGrow: "1",
	},
	closeButton: {
		margin: "-12px",
	},
	section: {},
	separator: {
		margin: "8px -16px",
		borderTop: `solid 1px ${grayColor[1]}`,
		height: "0px",
	},
	fieldHeading: {
		...typographyStyle.fieldHeading,
	},
	fieldHeadingStackedCheckbox: {
		...typographyStyle.fieldHeading,
		marginBottom: "0",
	},
	fieldInput: {
		width: "100%",
		marginTop: "0",
		marginBottom: "16px",
		"& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)": {
			transform: "translate(14px, 28px)",
		},
		"& .MuiInputBase-root": {
			height: "40px",
			"& .MuiInputBase-input": {
				boxSizing: "border-box",
				height: "40px",
				paddingTop: "10.5px",
				paddingBottom: "10.5px",
			},
		},
	},
	clearButton: {
		marginTop: "40px",
	},
};

export default filterStyle;
