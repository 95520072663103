/* eslint-disable no-nested-ternary */
import React, { useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import clsx from "clsx";

import { Button, ButtonGroup } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import AccessibleIconButton from "atlas/components/Buttons/AccessibleIconButton";
import ButtonWithTooltip from "atlas/components/Buttons/ButtonWithTooltip";
import NonModalMenu from "atlas/components/Menu/NonModalMenu";
import { primaryColor } from "atlas/assets/jss/shared";
import CircularProgressIndicator from "atlas/components/Progress/CircularProgressIndicator";
import styles from "atlas/assets/jss/components/menuStyle";
import { SettingsContext } from "contexts/Settings/SettingsContext";

import Icon from "atlas/components/Icon/Icon";

const useStyles = makeStyles(styles);
const useOverflowOptionStyles = makeStyles({
	xsOverflowIconPosition: {
		position: "absolute",
		top: "16px",
		right: "16px",
	},
});

const MeetingDetailButtons = (props) => {
	const {
		topRow,
		fullWidth,
		editAgenda,
		shareAgenda,
		publishAgenda,
		unpublishAgenda,
		publishMinutes,
		unpublishMinutes,
		shareMinutes,
		approveMinutes,
		downloadMinutes,
		uploadMinutes,
		editMinutes,
		addToAgenda,
		isShared,
		isPublished,
		isAdopted,
		closed,
		menuOptions,
		minutesAdoptMenuOptions,
		minutesUploadedInPortal,
		uploadingAdoptedMinutes,
		isAddToAgenda,
	} = props;
	const { t } = useTranslation("meetings");
	const [openMenu, setOpenMenu] = useState({});
	const [anchor, setAnchor] = useState({});
	const { lite } = useContext(SettingsContext);
	const classes = useStyles({});
	const overflowIconClasses = useOverflowOptionStyles();

	const handleToggleMenu = (e, type) => {
		e.stopPropagation();
		e.preventDefault();
		setAnchor({ [type]: e.currentTarget });
		setOpenMenu((prevOpen) => ({
			[type]: !prevOpen[type],
		}));
	};

	const handleCloseMenu = () => {
		const { event: { target = {} } = {} } = window;

		let li = {};
		if ((target.tagName || "").toLowerCase() !== "li") {
			li = target.closest("li") || {};
		}

		const stayOpenIds = [
			"preview-agenda",
			"download-minutes",
			"download-member-minutes",
			"download-public-minutes",
			"download-minutes-adopt",
			"upload-minutes",
			"upload-adopted-minutes",
			"preview-minutes",
		];
		if (!stayOpenIds.includes(target.id) && !stayOpenIds.includes(li.id)) {
			setAnchor({});
			setOpenMenu({});
		}
	};

	return (
		<div style={{ whiteSpace: `${fullWidth ? "" : "nowrap"}` }}>
			{editAgenda && (
				<Button
					className={topRow ? "padded-paper-top-margin" : null}
					fullWidth={fullWidth}
					variant="contained"
					color="primary"
					onClick={editAgenda}
					data-cy="editAgenda"
				>
					{t("app:buttons.edit")}
				</Button>
			)}
			{shareAgenda && (
				<ButtonWithTooltip
					className={topRow ? "padded-paper-top-margin" : fullWidth ? "small-top-margin" : null}
					fullWidth={fullWidth}
					variant="outlined"
					color="primary"
					hasRightMargin
					hasTopMargin
					onClick={shareAgenda}
					title={t("tooltips.shareAgenda")}
					data-cy="shareAgenda"
				>
					{t("buttons.share")}
				</ButtonWithTooltip>
			)}
			{publishAgenda && !closed && (
				<ButtonWithTooltip
					className={topRow ? "padded-paper-top-margin" : fullWidth ? "small-top-margin" : null}
					fullWidth={fullWidth}
					variant="outlined"
					color="primary"
					hasRightMargin
					hasTopMargin
					onClick={publishAgenda}
					title={t("tooltips.publishAgenda")}
					data-cy="publishAgenda"
				>
					{t("buttons.publish")}
				</ButtonWithTooltip>
			)}
			{unpublishAgenda && isPublished && (
				<Button
					className={topRow ? "padded-paper-top-margin no-right-margin" : fullWidth ? "small-top-margin" : null}
					fullWidth={fullWidth}
					variant="outlined"
					color="primary"
					onClick={unpublishAgenda}
					data-cy="unpublishAgenda"
				>
					{t("buttons.unpublish")}
				</Button>
			)}
			{editMinutes && (
				<ButtonWithTooltip
					className={topRow ? "padded-paper-top-margin" : fullWidth ? "small-top-margin" : null}
					fullWidth={fullWidth}
					primary
					hasRightMargin
					hasTopMargin
					onClick={editMinutes}
					title={t("tooltips.editMinutes")}
					data-cy="editMinutes"
				>
					{t("app:buttons.edit")}
				</ButtonWithTooltip>
			)}
			{publishMinutes && !isAdopted && !closed && (
				<ButtonWithTooltip
					className={topRow ? "padded-paper-top-margin" : fullWidth ? "small-top-margin" : null}
					fullWidth={fullWidth}
					variant="outlined"
					color="primary"
					hasRightMargin
					hasTopMargin
					onClick={publishMinutes}
					title={t("tooltips.publishMinutes")}
					data-cy="publishMinutes"
					disabled={minutesUploadedInPortal}
				>
					{t("buttons.publish")}
				</ButtonWithTooltip>
			)}
			{unpublishMinutes && isPublished && !isAdopted && (
				<ButtonWithTooltip
					className={topRow ? "padded-paper-top-margin" : fullWidth ? "small-top-margin" : null}
					fullWidth={fullWidth}
					variant="outlined"
					color="primary"
					hasRightMargin
					hasTopMargin
					onClick={unpublishMinutes}
					title={t("tooltips.unpublishMinutes")}
					data-cy="unpublishMinutes"
					disabled={(!isShared && !isPublished) || minutesUploadedInPortal}
				>
					{t("buttons.unpublish")}
				</ButtonWithTooltip>
			)}
			{approveMinutes && (
				<>
					<ButtonGroup
						className="adopt-minutes-button-group"
						variant="contained"
						color="primary"
						fullWidth={fullWidth}
						style={{ marginRight: "8px", verticalAlign: "bottom" }}
						aria-label="Adopt"
					>
						<ButtonWithTooltip
							className={`small-top-margin ${
								(!isShared && !isPublished && isAddToAgenda) || minutesUploadedInPortal ? "adopt-button-disabled" : ""
							}`}
							fullWidth={fullWidth}
							variant="outlined"
							color="primary"
							onClick={approveMinutes}
							title={
								!isAdopted
									? !lite.enabled
										? t("tooltips.signAdoptMinutes")
										: t("tooltips.adoptMinutes")
									: t("tooltips.updateAdoptedMinutes")
							}
							data-cy="approveMinutes"
							disabled={(!isShared && !isPublished && isAddToAgenda) || minutesUploadedInPortal}
						>
							{!isAdopted ? (!lite.enabled ? t(`app:buttons.signAdopt`) : t(`app:buttons.adopt`)) : t("app:buttons.updateAdopt")}
						</ButtonWithTooltip>
						<ButtonWithTooltip
							variant="outlined"
							className="small-top-margin adopt-split-icon-button"
							color="primary"
							title={!lite.enabled ? t("tooltips.adoptMinutesOverflowButton") : undefined}
							aria-controls={anchor.adopt ? "split-button-menu" : undefined}
							aria-expanded={anchor.adopt ? "true" : undefined}
							aria-label="select adopt action"
							aria-haspopup="menu"
							onClick={(e) => {
								handleToggleMenu(e, "adopt");
							}}
							tooltipPlacement="bottom"
							disabled={(!isShared && !isPublished && isAddToAgenda) || minutesUploadedInPortal || uploadingAdoptedMinutes}
						>
							{uploadingAdoptedMinutes ? (
								<CircularProgressIndicator size={20} />
							) : (
								<Icon
									name="expand-down"
									color={(!isShared && !isPublished && isAddToAgenda) || minutesUploadedInPortal ? "rgba(0, 0, 0, 0.12)" : primaryColor[4]}
								/>
							)}
						</ButtonWithTooltip>
					</ButtonGroup>
					{anchor.adopt && (
						<NonModalMenu
							id="meetingDetailAdoptMinutesOverflowMenu"
							className={classes.menu}
							anchorEl={anchor.adopt}
							keepMounted
							open={openMenu.adopt}
							onClose={handleCloseMenu}
							options={minutesAdoptMenuOptions}
							position="bottom-end"
						/>
					)}
				</>
			)}
			{shareMinutes && !isAdopted && (
				<ButtonWithTooltip
					className={topRow ? "padded-paper-top-margin" : fullWidth ? "small-top-margin" : null}
					fullWidth={fullWidth}
					variant="outlined"
					color="primary"
					hasRightMargin
					hasTopMargin
					onClick={shareMinutes}
					title={t("tooltips.shareMinutes")}
					data-cy="shareMinutes"
					disabled={minutesUploadedInPortal}
				>
					{t("buttons.share")}
				</ButtonWithTooltip>
			)}
			{downloadMinutes && (
				<ButtonWithTooltip
					className={topRow ? "padded-paper-top-margin" : fullWidth ? "small-top-margin" : null}
					fullWidth={fullWidth}
					variant="outlined"
					color="primary"
					hasRightMargin
					hasTopMargin
					onClick={downloadMinutes}
					title={t("tooltips.downloadMinutes")}
					data-cy="downloadMinutes"
					disabled={minutesUploadedInPortal}
				>
					{t("app:buttons.download")}
				</ButtonWithTooltip>
			)}
			{uploadMinutes && (
				<ButtonWithTooltip
					className={topRow ? "padded-paper-top-margin" : fullWidth ? "small-top-margin" : null}
					fullWidth={fullWidth}
					variant="outlined"
					color="primary"
					hasRightMargin
					hasTopMargin
					onClick={uploadMinutes}
					title={t("tooltips.uploadMinutes")}
					data-cy="uploadMinutes"
					disabled={minutesUploadedInPortal}
				>
					{t("app:buttons.upload")}
				</ButtonWithTooltip>
			)}
			{addToAgenda && (
				<ButtonWithTooltip
					className={topRow ? "padded-paper-top-margin" : fullWidth ? "small-top-margin" : null}
					fullWidth={fullWidth}
					variant="outlined"
					color="primary"
					hasRightMargin
					hasTopMargin
					onClick={addToAgenda}
					title={t("tooltips.addToAgenda")}
					data-cy="addToAgenda"
					disabled={minutesUploadedInPortal}
				>
					{t("buttons.addToAgenda")}
				</ButtonWithTooltip>
			)}
			{menuOptions && menuOptions.length > 0 && (
				<>
					<AccessibleIconButton
						className={clsx({
							"padded-paper-top-margin": topRow,
							"small-top-margin": !topRow && fullWidth,
							[overflowIconClasses.xsOverflowIconPosition]: fullWidth,
						})}
						color="inherit"
						aria-label={t("app:menu.options")}
						onClick={(e) => {
							handleToggleMenu(e, "overflow");
						}}
						iconName="more"
						dataCy="meetingDetailOverflowMenu"
						tooltipText={t("app:menu.options")}
						disabled={minutesUploadedInPortal}
					/>
					{anchor.overflow && (
						<NonModalMenu
							id="meetingDetailOverflowMenu"
							className={classes.menu}
							anchorEl={anchor.overflow}
							keepMounted
							open={openMenu.overflow}
							onClose={handleCloseMenu}
							options={menuOptions}
							position="bottom-end"
						/>
					)}
				</>
			)}
		</div>
	);
};

export default MeetingDetailButtons;
