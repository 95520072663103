import {
	PUBLICSITE_DATA,
	GET_INITIAL_SETTINGS_PENDING,
	GET_INITIAL_SETTINGS_FULFILLED,
	DISPLAY_OPTIONS,
	SET_SETTINGS_PENDING,
	SET_SETTINGS_SECCESSFULL,
	SET_PUBLIC_SITE_ERROR,
} from "./types";
import request from "superagent";
import { API_HOST } from "config/env";

export const getInitialSettings = (queryFilters) => (dispatch) => {
	return new Promise((resolve, reject) =>
		dispatch({
			type: GET_INITIAL_SETTINGS_PENDING,
			payload: request
				.get(`${API_HOST}/api/settings`)
				.query(queryFilters)
				.then((res) => {
					if (res.body) {
						dispatch({
							type: GET_INITIAL_SETTINGS_FULFILLED,
							payload: res.body,
						});
						resolve(res.body);
					}
				})
				.catch((exception) => {
					reject("Error getting data");
				}),
		}),
	);
};
export const setUpdatedSettings = (publicSiteData, type) => (dispatch) => {
	return new Promise((resolve, reject) =>
		dispatch({
			type: SET_SETTINGS_PENDING,
			payload: request
				.put(`${API_HOST}/api/settings/save`)
				.withCredentials()
				.send({
					...publicSiteData,
					type,
				})
				.then((res) => {
					if (res.status === 200) {
						resolve(true);
					} else {
						reject(false);
					}
				})
				.catch((exception) => {
					reject("Error while updating data");
				}),
		}),
	);
};

export const validateICSCalender = (payload) => (dispatch) => {
	return new Promise((resolve, reject) =>
		dispatch({
			type: SET_SETTINGS_PENDING,
			payload: request
				.post(`${API_HOST}/api/settings/icsfeedvalidatation`)
				.withCredentials()
				.send({ ICSfield: payload.url, ICSName: payload.name })
				.then((res) => {
					if (res.status === 200) {
						resolve(res.body);
					} else {
						reject(false);
					}
				})
				.catch((exception) => {
					reject(exception);
				}),
		}),
	);
};

export const updateInitialSetting = (config) => (dispatch) => {
	dispatch({
		type: PUBLICSITE_DATA,
		payload: config,
	});
};

export const setSettingError = (data) => (dispatch) => {
	dispatch({
		type: SET_PUBLIC_SITE_ERROR,
		payload: data,
	});
};
export const updateInitialSettingValue = (config) => (dispatch) => {
	dispatch({
		type: DISPLAY_OPTIONS,
		payload: config,
	});
};

export const updateSocialMediaUrls = (config) => (dispatch) => {
	dispatch({
		type: PUBLICSITE_DATA,
		payload: config,
	});
};
