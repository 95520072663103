/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import clsx from "clsx";

import makeStyles from "@mui/styles/makeStyles";
import Drawer from "@mui/material/Drawer";

import contentDrawerStyle from "assets/jss/components/contentDrawerStyle";
import { useWidthDown, useWidthUp } from "atlas/utils/useWidth";
import { CONTENT_PAPER_ID, getContentSize } from "utils/contentSize";

export const MD_DOWN_WIDTH = 270;
export const LG_UP_WIDTH = 500;

const useContentDrawerStyles = makeStyles(contentDrawerStyle);
const useStyles = makeStyles({
	drawer: {
		padding: "0 !important",
		zIndex: "5000 !important",
	},
});

const RightPanelContainer = (props) => {
	const {
		id,
		open = false,
		fullHeight = false,
		drawerWidth = LG_UP_WIDTH,
		drawerHeight,
		float = false,
		xsFullWidth = false,
		children,
	} = props;
	const widthUpXs = useWidthUp("xs");
	const widthUpLg = useWidthUp("lg");
	const widthDownSm = useWidthDown("sm");
	const [sizes, setSizes] = useState({ bodyWidth: drawerWidth });
	const appReducer = useSelector((state) => state.appReducer);
	const { bottomNotice } = appReducer;

	const xsDown = widthDownSm;
	const drawerWidthMax = xsDown ? sizes.bodyWidth : widthUpLg ? drawerWidth : sizes.bodyWidth * 0.45;
	const drawerWidthRequested = xsDown && xsFullWidth ? drawerWidthMax : drawerWidth;
	const { drawer, drawerFloat, drawerInContentRight, drawerFlexHeight } = useContentDrawerStyles({
		sizes,
		drawerWidth: drawerWidthMax > drawerWidthRequested ? drawerWidthRequested : drawerWidthMax,
		drawerHeight: typeof drawerHeight === "function" ? drawerHeight(sizes) : drawerHeight,
		float,
	});
	const classes = useStyles();

	const updateSizes = () => setSizes(getContentSize());

	useEffect(() => {
		if (!float) {
			const paper = document.getElementById(CONTENT_PAPER_ID);
			if (paper) {
				paper.style.marginRight = open && widthUpXs ? `${widthUpLg ? `${drawerWidth + 16}px` : "43vw"}` : null;
			}
		}
	}, [open, widthUpXs, widthUpLg, drawerWidth, float]);

	useEffect(() => {
		return () => {
			const paper = document.getElementById(CONTENT_PAPER_ID);
			if (paper) {
				paper.style.marginRight = null;
			}
		};
	}, []);

	useEffect(() => {
		if (open) {
			updateSizes(); // Update the sizes immediately
			setTimeout(updateSizes, 100); // This can end up running before the main content fully resizes due to the bottomNotice, so delay slightly and update again to get the correct content size
		}
	}, [id, open, fullHeight, widthUpXs, widthUpLg, drawerWidth, float, bottomNotice]);

	return (
		<Drawer
			id={id}
			className={clsx({
				[drawer]: xsDown,
				[drawerFloat]: xsDown || float,
				[drawerInContentRight]: !xsDown,
				[drawerFlexHeight]: !fullHeight,
			})}
			anchor="right"
			variant="persistent"
			open={open}
			SlideProps={{ timeout: 0 }}
			PaperProps={{ className: classes.drawer }}
			data-cy="right-panel"
		>
			{open ? children : null}
		</Drawer>
	);
};

export default RightPanelContainer;
