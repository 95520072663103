import React, { Component } from "react";
import { connect } from "react-redux";
import request from "superagent";
import { withTranslation } from "react-i18next";

import GenericAvatar, { GENERIC_AVATAR_BACKGROUNDS } from "components/Avatar/GenericAvatar";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";

import clone from "lodash/fp/clone";
import omit from "lodash/fp/omit";

import isEmail from "atlas/utils/isEmail";
import ComponentContainer from "atlas/components/ComponentContainer/ComponentContainer";
import withErrorHandling from "components/ErrorHOC";
import { API_HOST } from "config/env";
import { CaretRight } from "components/Icons";
import telemetryAddEvent from "utils/telemetryAddEvent";
import { Box } from "@mui/material";
import UsernameUpdate from "./components/usernameUpdate";
import PasswordUpdate from "./components/passwordUpdate";
import VerifyPassword from "./components/verifyPassword";
import EmailUpdate from "./components/EmailUpdate";
import NameUpdate from "./components/NameUpdate";
import PictureUpload from "./components/pictureUpload";
import {
	PASSWORD_CRITERIA_LENGTH,
	PASSWORD_CRITERIA_CONTAINS_PII_NAME,
	PASSWORD_CRITERIA_CONTAINS_PII_USER_NAME,
	PASSWORD_CRITERIA_CONTAINS_PII_EMAIL_ADDRESS,
	PASSWORD_CRITERIA_COMMON,
} from "./utils/passwordCriteria";

import { mapStateToProps } from "../../redux/app/mapStateToProps";
import { resetPageConfigs, updatePageConfigs } from "../../redux/app/actions";
import PhoneUpdate from "./components/PhoneUpdate";
import NotificationSettingsSection from "../Users/components/NotificationSettingsSection";
import notifierMessage from "utils/notifierMessage";
import { setSnackbarOptions } from "redux/snackBar/actions";

class UserProfile extends Component {
	constructor(props) {
		super(props);

		this.state = {
			activeSection: "user-profile",
			errors: {},
			profilePicture: null,
			profileThumbnail: null,
			smsOnApproveReject: false,
			smsOnItemDenied: false,
			smsOnEachItemApproved: false,
			smsOnItemFullyApproved: false,
			smsOnAgendaShared: false,
		};

		this.loadUser = this.loadUser.bind(this);
		this.showSection = this.showSection.bind(this);
		this.handleCancelClick = this.handleCancelClick.bind(this);
		this.handleFieldChange = this.handleFieldChange.bind(this);
		this.handlePhoneNumberChange = this.handlePhoneNumberChange.bind(this);
		this.showSectionIfPasswordVerified = this.showSectionIfPasswordVerified.bind(this);

		this.validateUsernameData = this.validateUsernameData.bind(this);
		this.validatePasswordData = this.validatePasswordData.bind(this);
		this.validateEmailData = this.validateEmailData.bind(this);
		this.validateNameData = this.validateNameData.bind(this);
		this.validatePhoneData = this.validatePhoneData.bind(this);

		this.handlePictureUploadSaveClick = this.handlePictureUploadSaveClick.bind(this);
		this.handlePictureRemoveClick = this.handlePictureRemoveClick.bind(this);
		this.handlePictureUpload = this.handlePictureUpload.bind(this);
		this.handleUpdate = this.handleUpdate.bind(this);
	}

	componentWillMount() {
		const { dispatch, t } = this.props;

		dispatch(resetPageConfigs({ resetBack: true }));
		dispatch(updatePageConfigs({ title: t("userProfile"), telemetryPage: "User profile" }));

		this.loadUser();
	}

	handleCancelClick() {
		this.showSection("user-profile");
		this.setState({
			password: "",
			confirmPassword: "",
			errors: {},
			editing: {},
		});
	}

	handleFieldChange(e) {
		const { editing = {}, errors = {} } = this.state;

		// get all values in edit mode, update just the one that changed.
		const newEditValues = clone(editing);
		newEditValues[e.target.id] = e.target.value;

		// clear validation errors when we change a field. (only the changed field)
		const newErrors = clone(errors);
		newErrors[e.target.id] = null;

		this.setState({
			editing: newEditValues,
			errors: newErrors,
		});
	}

	handlePhoneNumberChange(phoneNumber, formattedPhoneNumber) {
		const { editing = {} } = this.state;

		const newEditValues = clone(editing);
		newEditValues.phoneNumber = phoneNumber;
		newEditValues.formattedPhoneNumber = formattedPhoneNumber;

		this.setState({
			editing: newEditValues,
		});
	}

	// Render Picture Upload Page
	handlePictureUploadSaveClick() {
		const { t, dispatch, showSignIn } = this.props;
		const { userId, editing } = this.state;
		const { profilePicture } = editing;

		const url = `${API_HOST}/api/user/${userId}/userprofile`;
		const data = {
			profilePicture,
		};

		telemetryAddEvent("User Profile - Update photo");

		request
			.post(url)
			.withCredentials()
			.send(data)
			.then((res) => {
				if (res.body.Result) {
					let option = notifierMessage(t("notifications.pictureUpdated"), "success");
					dispatch(setSnackbarOptions(option));
					this.setState({
						// eslint-disable-next-line react/no-unused-state
						profilePictureURL: res.body.userImage.url,
						profilePicture,
						editing: {},
					});
				}
			})
			.catch((err) => {
				showSignIn(err, () => {
					this.handlePictureUploadSaveClick();
				});
			});

		this.showSection("user-profile");
	}

	handlePictureRemoveClick() {
		const { t, dispatch, showSignIn } = this.props;
		const { userId } = this.state;

		telemetryAddEvent("User Profile - Delete photo");

		const url = `${API_HOST}/api/user/${userId}/deleteuserimage`;
		request
			.del(url)
			.withCredentials()
			.then((res) => {
				if (res.body.Result) {
					let option = notifierMessage(t("notifications.pictureRemoved"), "success");
					dispatch(setSnackbarOptions(option));
					this.setState({
						profilePicture: null,
						editing: {},
					});
				}
			})
			.catch((err) => {
				showSignIn(err, () => {
					this.handlePictureRemoveClick();
				});
			});

		this.showSection("user-profile");
	}

	handlePictureUpload(picture, thumbnail) {
		const { editing } = this.state;
		const newEditValues = clone(editing);
		newEditValues.profilePicture = picture;
		newEditValues.profileThumbnail = thumbnail;

		this.setState({
			editing: newEditValues,
		});
	}

	loadUser() {
		const { showSignIn } = this.props;

		const url = `${API_HOST}/api/users/userprofile`;

		request
			.get(url)
			.then((res) => {
				this.setState({
					...res.body,
				});
			})
			.catch((err) => {
				showSignIn(err, () => {
					this.loadUser();
				});
			});
	}

	showSection(section) {
		const { dispatch, t } = this.props;
		const {
			username,
			password,
			confirmPassword,
			verifyPassword,
			email,
			firstname,
			lastname,
			profilePicture,
			profileThumbnail,
			activeSection,
			phoneNumber,
		} = this.state;

		// setup temporary data while editing to allow us to have controlled fields in the sections,
		// without over-writing the 'good' values in state before validating input
		const editing = {};
		// eslint-disable-next-line default-case
		switch (section) {
			case "user-profile":
				dispatch(resetPageConfigs({ resetBack: true }));
				dispatch(updatePageConfigs({ title: t("userProfile"), telemetryPage: "User profile" }));
				break;

			case "username-update":
				editing.username = username;
				dispatch(
					updatePageConfigs({
						title: t("userName"),
						back: { url: "/user/profile", action: this.showSection },
						telemetryPage: "User profile",
					}),
				);
				break;

			case "verify-password-update":
				editing.verifyPassword = activeSection === section ? verifyPassword : "";
				dispatch(
					updatePageConfigs({
						title: t("password"),
						back: { url: "/user/profile", action: this.showSection },
						telemetryPage: "User profile",
					}),
				);
				break;

			case "verify-email-update":
				editing.verifyPassword = activeSection === section ? verifyPassword : "";
				dispatch(
					updatePageConfigs({
						title: t("email"),
						back: { url: "/user/profile", action: this.showSection },
						telemetryPage: "User profile",
					}),
				);
				break;

			case "password-update":
				editing.password = password;
				editing.confirmPassword = confirmPassword;
				dispatch(
					updatePageConfigs({
						title: t("password"),
						back: { url: "/user/profile", action: this.showSection },
						telemetryPage: "User profile",
					}),
				);
				break;

			case "email-update":
				editing.email = email;
				dispatch(
					updatePageConfigs({
						title: t("email"),
						back: { url: "/user/profile", action: this.showSection },
						telemetryPage: "User profile",
					}),
				);
				break;

			case "phone-update":
				editing.phoneNumber = phoneNumber;
				dispatch(
					updatePageConfigs({
						title: t("phoneNumber"),
						back: { url: "/user/profile", action: this.showSection },
						telemetryPage: "User profile",
					}),
				);
				break;

			case "name-update":
				editing.firstname = firstname;
				editing.lastname = lastname;
				dispatch(
					updatePageConfigs({
						title: t("name"),
						back: { url: "/user/profile", action: this.showSection },
						telemetryPage: "User profile",
					}),
				);
				break;

			case "picture-update":
				editing.profilePicture = profilePicture;
				editing.profileThumbnail = profileThumbnail;
				dispatch(
					updatePageConfigs({
						title: t("photo"),
						back: { url: "/user/profile", action: this.showSection },
						telemetryPage: "User profile",
					}),
				);
				break;
		}

		this.setState({
			activeSection: section,
			editing,
		});
	}

	showSectionIfPasswordVerified(nextSection) {
		const { t, showSignIn } = this.props;
		const { editing } = this.state;
		const { verifyPassword } = editing;

		let valid = true;
		const errors = {};

		if (!verifyPassword) {
			errors.verifyPassword = t("errors.passwordCheckFailed");
			valid = false;
		}

		if (!valid) {
			this.setState({
				errors,
			});
			return false;
		}

		const url = `${API_HOST}/api/user/password/validate`;
		const data = {
			password: verifyPassword,
		};

		request
			.post(url)
			.withCredentials()
			.send(data)
			.then((res) => {
				if (!res.body.Result) {
					// not the correct password
					this.setState({
						errors: {
							verifyPassword: t("errors.passwordCheckFailed"),
						},
					});
				} else {
					// password OK
					this.setState({
						editing: {},
						verifyPassword,
					});
					this.showSection(nextSection);
				}
			})
			.catch((err) => {
				showSignIn(err, () => {
					this.showSectionIfPasswordVerified(nextSection);
				});
			});

		return undefined;
	}

	// Render Update Name Page
	validateUsernameData(withSave) {
		const { t, dispatch, showSignIn } = this.props;
		const { editing, userId } = this.state;
		const { username } = editing;
		// validate username

		if (!username) {
			this.setState({
				errors: {
					username: t("errors.userNameEmpty"),
				},
			});
			return false;
		}

		const url = `${API_HOST}/api/user/username/validate`;
		const data = {
			id: userId,
			userName: username,
		};

		request
			.post(url)
			.withCredentials()
			.send(data)
			.then((res) => {
				if (!res.body) {
					// not a valid username
					this.setState({
						errors: {
							username: t("errors.userNameMustBeUnique"),
						},
					});
				} else if (withSave) {
					telemetryAddEvent("User Profile - Update username");

					// username OK
					request
						.post(`${API_HOST}/api/user/${userId}/userprofile`)
						.withCredentials()
						.send(data)
						.then((result) => {
							if (result.body.Result) {
								let option = notifierMessage(t("notifications.userNameUpdated"), "success");
								dispatch(setSnackbarOptions(option));
								this.setState({
									username,
									errors: {},
									editing: {},
								});
							}
						})
						.catch((err) => {
							console.log(err);
						});
					this.showSection("user-profile");
				}
			})
			.catch((err) => {
				showSignIn(err, () => {
					this.validateUsernameData(withSave);
				});
			});

		return undefined;
	}

	// Render Update Password Page
	validatePasswordData(withSave, singleField) {
		const { t, dispatch, showSignIn } = this.props;
		const { editing, userId, verifyPassword } = this.state;
		const { password, confirmPassword } = editing;

		let valid = true;
		// if we are only validating one field onBlur, keep any other errors, otherwise reset all errors
		const errors = singleField ? omit(singleField, this.state.errors) : {};

		if (!singleField || singleField === "confirmPassword") {
			if (!confirmPassword || confirmPassword !== password) {
				errors.confirmPassword = t("errors.mustMatchNewPassword");
				valid = false;
			}
		}

		request
			.post(`${API_HOST}/api/user/passwordstrength/validate`)
			.withCredentials()
			.send({ password })
			.then((res) => {
				if (!res.body.valid) {
					const { failedCriteria } = res.body;
					if (failedCriteria.includes(PASSWORD_CRITERIA_LENGTH)) {
						errors.password = t("errors.passwordEightCharacter");
					} else if (failedCriteria.includes(PASSWORD_CRITERIA_CONTAINS_PII_NAME)) {
						errors.password = t("errors.passwordContainsName");
					} else if (failedCriteria.includes(PASSWORD_CRITERIA_CONTAINS_PII_USER_NAME)) {
						errors.password = t("errors.passwordContainsUserName");
					} else if (failedCriteria.includes(PASSWORD_CRITERIA_CONTAINS_PII_EMAIL_ADDRESS)) {
						errors.password = t("errors.passwordContainsEmailAddress");
					} else if (failedCriteria.includes(PASSWORD_CRITERIA_COMMON)) {
						errors.password = t("errors.passwordIsCommon");
					}

					// not a valid password
					this.setState({
						errors,
					});
				} else if (!valid) {
					// not a valid password
					this.setState({
						errors,
					});
				} else if (withSave) {
					// passed validation
					const data = {
						password: verifyPassword,
						newPassword: password,
					};

					telemetryAddEvent("User Profile - Update password");

					request
						.post(`${API_HOST}/api/user/${userId}/userprofile`)
						.withCredentials()
						.send(data)
						.then((response) => {
							if (response.body.Result) {
								let option = notifierMessage(t("notifications.passwordUpdated"), "success");
								dispatch(setSnackbarOptions(option));
								this.setState({
									verifyPassword: "",
									password: "",
									confirmPassword: "",
									editing: {},
								});
							}
						})
						.catch((err) => {
							showSignIn(err, () => {
								this.validatePasswordData(withSave, singleField);
							});
						});

					this.showSection("user-profile");
				}
			})
			.catch((err) => {
				showSignIn(err, () => {
					this.validatePasswordData(withSave, singleField);
				});
			});

		return undefined;
	}

	// Render Update Email Page
	validateEmailData(withSave) {
		const { t, dispatch, showSignIn } = this.props;
		const { editing, userId, verifyPassword } = this.state;
		const { email } = editing;

		let valid = true;
		const errors = {};

		// validate email
		if (!isEmail(email)) {
			errors.email = t("errors.invalidEmail");
			valid = false;
		}

		if (!valid) {
			this.setState({
				errors,
			});
			return false;
		}

		// passed validation
		if (withSave) {
			const url = `${API_HOST}/api/user/${userId}/userprofile`;
			const data = {
				password: verifyPassword,
				email,
			};

			telemetryAddEvent("User Profile - Update email");

			request
				.post(url)
				.withCredentials()
				.send(data)
				.then((res) => {
					if (res.body.Result) {
						let option = notifierMessage(`${t("notifications.emailUpdated")}: ${email}`);
						dispatch(setSnackbarOptions(option));
						this.setState({
							verifyPassword: "",
							email,
							errors: {},
							editing: {},
						});
					}
				})
				.catch((err) => {
					showSignIn(err, () => {
						this.validateEmailData(withSave);
					});
				});
			this.showSection("user-profile");
		}

		return undefined;
	}

	// Render Update Phone Page
	validatePhoneData(withSave) {
		const { t, dispatch, showSignIn } = this.props;
		const { editing, userId } = this.state;
		const { phoneNumber, formattedPhoneNumber } = editing;
		if (withSave) {
			const url = `${API_HOST}/api/user/${userId}/userprofile`;
			const data = {
				phoneNumber,
				formattedPhoneNumber,
			};

			telemetryAddEvent("User Profile - Update phone number");

			request
				.post(url)
				.withCredentials()
				.send(data)
				.then((res) => {
					if (res.body.Result) {
						let option = notifierMessage(t("notifications.phoneNumberUpdated"), "success");
						dispatch(setSnackbarOptions(option));
						this.setState({
							phoneNumber,
							formattedPhoneNumber,
							editing: {},
						});
					}
				})
				.catch((err) => {
					showSignIn(err, () => {
						this.validatePhoneData(withSave);
					});
				});

			this.showSection("user-profile");
		}

		return undefined;
	}

	// Render Update Name Page
	validateNameData(withSave, singleField) {
		const { t, dispatch, showSignIn } = this.props;
		const { editing, userId } = this.state;
		const { firstname, lastname } = editing;

		let valid = true;
		// if we are only validating one field onBlur, keep any other errors, otherwise reset all errors
		const errors = singleField ? omit(singleField, this.state.errors) : {};

		if (!singleField || singleField === "firstname") {
			if (!firstname) {
				errors.firstname = t("errors.firstNameEmpty");
				valid = false;
			}
		}
		if (!singleField || singleField === "lastname") {
			if (!lastname) {
				errors.lastname = t("errors.lastNameEmpty");
				valid = false;
			}
		}

		if (!valid) {
			this.setState({
				errors,
			});
			return false;
		}

		if (withSave) {
			const url = `${API_HOST}/api/user/${userId}/userprofile`;
			const data = {
				firstname,
				lastname,
			};

			telemetryAddEvent("User Profile - Update name");

			request
				.post(url)
				.withCredentials()
				.send(data)
				.then((res) => {
					if (res.body?.errors?.invalidName) {
						errors.firstname = t("errors.firstNameInvalid");
						errors.lastname = t("errors.lastNameInvalid");

						this.setState({
							errors,
						});
					} else if (res.body.Result) {
						let option = notifierMessage(t("notifications.nameUpdated"), "success");
						dispatch(setSnackbarOptions(option));
						this.setState({
							firstname,
							lastname,
							editing: {},
						});

						this.showSection("user-profile");
					}
				})
				.catch((err) => {
					showSignIn(err, () => {
						this.validateNameData(withSave, singleField);
					});
				});
		}

		return undefined;
	}

	handleUpdate(event, field) {
		const { t, dispatch, showSignIn } = this.props;
		const { userId, smsOnApproveReject, smsOnItemDenied, smsOnEachItemApproved, smsOnItemFullyApproved, smsOnAgendaShared } = this.state;

		const countlyMessages = {
			smsOnApproveReject: "Approval requested",
			smsOnItemDenied: "Item denied",
			smsOnEachItemApproved: "Item approved (each approver)",
			smsOnItemFullyApproved: "Item fully approved",
			smsOnAgendaShared: "Agenda shared",
		};
		const countlyMessage =
			"User Profile - Update SMS Notification " +
			(countlyMessages[field] ? "- " + countlyMessages[field] + " " : "") +
			"turned " +
			(event.target.checked ? "on" : "off");
		telemetryAddEvent(countlyMessage);

		this.setState({ [field]: event.target.checked });
		const data = {};
		data.smsOnApproveReject = smsOnApproveReject;
		data.smsOnItemDenied = smsOnItemDenied;
		data.smsOnEachItemApproved = smsOnEachItemApproved;
		data.smsOnItemFullyApproved = smsOnItemFullyApproved;
		data.smsOnAgendaShared = smsOnAgendaShared;
		data[field] = event.target.checked;
		const url = `${API_HOST}/api/user/${userId}/userprofile`;
		request
			.post(url)
			.withCredentials()
			.send(data)
			.then((res) => {
				if (res.body.Result) {
					let option = notifierMessage(t("notifications.smsSettingsUpdated"), "success");
					dispatch(setSnackbarOptions(option));
				}
			})
			.catch((err) => {
				showSignIn(err, () => {
					this.handleUpdate(event, field);
				});
			});
	}

	render() {
		const { t } = this.props;
		const {
			profilePicture,
			username,
			email,
			firstname,
			lastname,
			formattedPhoneNumber,
			smsOnApproveReject,
			smsOnItemDenied,
			smsOnEachItemApproved,
			smsOnItemFullyApproved,
			smsOnAgendaShared,
		} = this.state;
		const firstInitial = String(firstname).charAt(0); // needed to coerce firstname into a string to avoid browser error.
		// console.log(firstInitial);
		const commonProperties = {
			data: this.state,
			handleChange: this.handleFieldChange,
			handleCancel: this.handleCancelClick,
		};
		// eslint-disable-next-line default-case
		switch (this.state.activeSection) {
			case "username-update": // username
				commonProperties.handleBlur = () => this.validateUsernameData(false);
				commonProperties.handleSave = () => this.validateUsernameData(true);
				return <UsernameUpdate {...commonProperties} />;

			case "verify-password-update": // verify password
				commonProperties.handleSave = () => this.showSectionIfPasswordVerified("password-update");
				commonProperties.messageKey = "enterNewPassword";
				return <VerifyPassword {...commonProperties} />;

			case "password-update": // password
				commonProperties.handleBlur = this.validatePasswordData;
				commonProperties.handleSave = () => this.validatePasswordData(true, null);
				return <PasswordUpdate {...commonProperties} />;

			case "verify-email-update": // verify email
				commonProperties.handleSave = () => this.showSectionIfPasswordVerified("email-update");
				commonProperties.messageKey = "updateEmailConfirm";
				return <VerifyPassword {...commonProperties} />;

			case "email-update": // email
				commonProperties.handleBlur = () => this.validateEmailData(false);
				commonProperties.handleSave = () => this.validateEmailData(true, null);
				return <EmailUpdate {...commonProperties} />;

			case "name-update": // name
				commonProperties.handleBlur = this.validateNameData;
				commonProperties.handleSave = () => this.validateNameData(true, null);
				return <NameUpdate {...commonProperties} />;

			case "picture-update": // picture
				commonProperties.handleChange = this.handlePictureUpload;
				commonProperties.handleSave = this.handlePictureUploadSaveClick;
				commonProperties.handleRemove = this.handlePictureRemoveClick;
				return <PictureUpload {...commonProperties} />;

			case "phone-update": // phoneNumber
				commonProperties.handleChange = this.handlePhoneNumberChange;
				commonProperties.handleSave = () => this.validatePhoneData(true);
				return <PhoneUpdate {...commonProperties} />;

			default:
				// user profile
				// eslint-disable-next-line
				const userProfile = (
					<>
						<List>
							<ListItem>
								<ListItemText>
									<Typography variant="h5" color="textSecondary">
										{t("updatePersonalInfo")}
									</Typography>
								</ListItemText>
							</ListItem>
							<Divider />
							<ListItem id="username-update" button onClick={() => this.showSection("username-update")} data-cy="updateUsername">
								<ListItemText>
									<Typography variant="overline" color="textPrimary">
										{t("userName")}
									</Typography>
									<Typography id="user-profile-username" variant="subtitle1" color="textPrimary">
										{username}
									</Typography>
								</ListItemText>
								<ListItemIcon>
									<CaretRight data-cy="updateUsernameAction" />
								</ListItemIcon>
							</ListItem>
							<Divider />
							<ListItem id="password-update" button onClick={() => this.showSection("verify-password-update")} data-cy="updatePassword">
								<ListItemText>
									<Typography variant="overline" color="textPrimary">
										{t("password")}
									</Typography>
									<Typography variant="subtitle1" color="textPrimary">
										&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;
									</Typography>
								</ListItemText>
								<ListItemIcon>
									<CaretRight data-cy="updatePasswordAction" />
								</ListItemIcon>
							</ListItem>
							<Divider />
							<ListItem id="email-update" button onClick={() => this.showSection("verify-email-update")} data-cy="updateEmail">
								<ListItemText>
									<Typography variant="overline" color="textPrimary">
										{t("email")}
									</Typography>
									<Typography id="user-profile-email" variant="subtitle1" color="textPrimary">
										{email}
									</Typography>
								</ListItemText>
								<ListItemIcon>
									<CaretRight data-cy="updateEmailAction" />
								</ListItemIcon>
							</ListItem>
							<Divider />
							<ListItem id="name-update" button onClick={() => this.showSection("name-update")} data-cy="updateName">
								<ListItemText>
									<Typography variant="overline" color="textPrimary">
										{t("name")}
									</Typography>
									<Typography id="user-profile-name" variant="subtitle1" color="textPrimary">
										{`${firstname} ${lastname}`}
									</Typography>
								</ListItemText>
								<ListItemIcon>
									<CaretRight data-cy="updateNameAction" />
								</ListItemIcon>
							</ListItem>
							<Divider />
							<ListItem id="picture-update" button onClick={() => this.showSection("picture-update")} data-cy="updatePhoto">
								<ListItemText>
									<Typography variant="overline" color="textPrimary">
										{t("photo")}
									</Typography>
									{profilePicture ? (
										<GenericAvatar
											imageUrl={profilePicture}
											name={`${firstname} ${lastname}`}
											backgroundNumber={(0 % GENERIC_AVATAR_BACKGROUNDS) + 1}
										/>
									) : (
										<GenericAvatar
											name={`${firstname} ${lastname}`}
											initials={`${(firstname || "").substr(0, 1)}${(lastname || "").substr(0, 1)}`}
											backgroundNumber={(0 % GENERIC_AVATAR_BACKGROUNDS) + 1}
										/>
									)}
								</ListItemText>
								<ListItemIcon>
									<CaretRight data-cy="updatePhotoAction" />
								</ListItemIcon>
							</ListItem>
							<Divider />
							<ListItem id="phone-update" button onClick={() => this.showSection("phone-update")} data-cy="updatePhone">
								<ListItemText>
									<Typography variant="overline" color="textPrimary">
										{t("phoneNumber")}
									</Typography>
									<Typography id="user-profile-phone" variant="subtitle1" color="textPrimary">
										{formattedPhoneNumber && formattedPhoneNumber.length > 1 ? formattedPhoneNumber : ""}
									</Typography>
								</ListItemText>
								<ListItemIcon>
									<CaretRight data-cy="updatePhoneAction" />
								</ListItemIcon>
							</ListItem>
							<Divider />
						</List>
					</>
				);

				return (
					<ComponentContainer>
						<div className="full-width">{userProfile}</div>
						<Box mt={2}>
							<NotificationSettingsSection
								user={{ smsOnApproveReject, smsOnItemDenied, smsOnEachItemApproved, smsOnItemFullyApproved, smsOnAgendaShared }}
								handleUpdate={this.handleUpdate}
								startOpen
								isUserProfile
							/>
						</Box>
					</ComponentContainer>
				);
		}
	}
}

export default withTranslation("userProfile")(withErrorHandling(connect(mapStateToProps)(UserProfile)));
