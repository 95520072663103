import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import request from "superagent";

import withStyles from "@mui/styles/withStyles";
import makeStyles from "@mui/styles/makeStyles";
import { Box, MenuItem, Button } from "@mui/material";

import inputStyle from "atlas/assets/jss/components/inputStyle";
import Icon from "atlas/components/Icon/Icon";
import SelectInput from "atlas/components/FormControls/SelectInput";
import { blackColor } from "atlas/assets/jss/shared";
import { API_HOST } from "config/env";
import GenericDialog from "atlas/components/Dialogs/GenericDialog";
import { getNoOptionsMenuItem, getDropdownProgressIndicator } from "utils/dropDown";
import notifierMessage from "utils/notifierMessage";
import { setSnackbarOptions } from "redux/snackBar/actions";

const StyledButton = withStyles({
	root: {
		color: "#ffffff",
		marginTop: 0,
	},
})(Button);

const useInputStyles = makeStyles(inputStyle);
const useStyles = makeStyles(() => ({
	meetingTypes: {
		listStyleType: "disc",
		margin: "8px 0 0 0",
		paddingLeft: "30px",
	},
	meetingType: {
		width: "100%",
		boxSizing: "border-box",
	},
	meetingTypeName: {
		flexGrow: "1",
		wordBreak: "break-word",
	},
}));

const BoardDeleteDialog = (props) => {
	const { show, board, hasMeetingTypes, meetingTypes: loadedMeetingTypes, onClose, afterDelete, undoDelete } = props;
	const { t } = useTranslation("boards");
	const dispatch = useDispatch();
	const [boards, setBoards] = useState(null);
	const [meetingTypes, setMeetingTypes] = useState(loadedMeetingTypes);
	const [selectedBoard, setSelectedBoard] = useState(0);
	const [deleting, setDeleting] = useState(false);
	const classes = useStyles();
	const inputClasses = useInputStyles({ fullWidth: true });

	const loadBoards = () => {
		request
			.get(`${API_HOST}/api/boards?basic=true`)
			.withCredentials()
			.then((res) => {
				const {
					body: { boards },
				} = res || {};
				if (boards) {
					setBoards(boards.filter((current) => current.id !== board.id));
				}
			})
			.catch((err) => {
				showSignIn(err, () => {
					loadBoards();
				});
			});
	};

	const loadMeetingTypes = () => {
		request
			.get(`${API_HOST}/api/meetingtypes`)
			.withCredentials()
			.then((res) => {
				const { body: meetingTypes } = res || {};

				setMeetingTypes(meetingTypes);
			})
			.catch((err) => {
				showSignIn(err, () => {
					loadMeetingTypes();
				});
			});
	};

	const handleDelete = () => {
		setDeleting(true);

		request
			.delete(`${API_HOST}/api/board/${board.id}`)
			.withCredentials()
			.send({ newBoard: selectedBoard })
			.then(() => {
				let option = notifierMessage(t("deleteBoardDialog.snackbar.success", { name: board.name }), "success", () => undoDelete(board));
				dispatch(setSnackbarOptions(option));
				onClose();
				afterDelete(board);
			})
			.catch((err) => handleError(err));
	};

	const handleCancel = () => {
		onClose();
	};

	const handleError = (err) => {
		console.log("Error", err);
		onClose();
	};

	const getBoards = () => {
		const menuItems = [];

		if (boards) {
			menuItems.push(getNoOptionsMenuItem(t));

			boards.forEach((board) => {
				menuItems.push(
					<MenuItem key={board.id} value={board.id} data-cy={`board-${board.id}`}>
						<div>{board.name}</div>
					</MenuItem>,
				);
			});
		} else {
			menuItems.push(getDropdownProgressIndicator());
		}

		return menuItems;
	};

	useEffect(() => {
		if (board) {
			loadBoards();
			if (!meetingTypes) {
				loadMeetingTypes();
			}
		}
	}, [board]);

	const i18n = t("deleteBoardDialog", { name: board.name });

	const dialog = {
		title: i18n.title,
		line1: i18n.line1,
		line2: i18n.line2,
		primaryTitle: hasMeetingTypes ? t("buttons.deleteAndMove") : t("app:buttons.delete"),
		primaryAction: handleDelete,
		secondaryTitle: t("app:buttons.cancel"),
		secondaryAction: handleCancel,
	};

	return (
		<GenericDialog
			show={show}
			title={dialog.title}
			primaryAction={dialog.primaryAction}
			primaryTitle={dialog.primaryTitle}
			primaryDisabled={deleting || (hasMeetingTypes && selectedBoard === 0)}
			secondaryAction={dialog.secondaryAction}
			secondaryTitle={dialog.secondaryTitle}
			secondaryDisabled={deleting}
			clickAwayDisabled={deleting}
			closeIcon={<Icon name="close" />}
		>
			<Box fontWeight="fontWeightBold">{dialog.line1}</Box>
			{hasMeetingTypes && meetingTypes && (
				<>
					<Box mt="20px">
						<strong>{i18n.warning}</strong> {dialog.line2}
					</Box>
					<Box mt="8px">{i18n.meetingTypes}</Box>
					<ul className={classes.meetingTypes} data-cy="meeting-types">
						{board.meetingTypes
							.map((meetingTypeId) => meetingTypes.find((meetingType) => meetingType.id === meetingTypeId))
							.sort((a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0))
							.map((meetingType) => (
								<li key={meetingType.id} className={classes.meetingType}>
									<div className={classes.meetingTypeName}>{meetingType.name}</div>
								</li>
							))}
					</ul>
					<Box>
						<SelectInput
							id="boards"
							className={inputClasses.smallInput}
							noDefaultClassName
							fullWidth
							label={i18n.selectBoard}
							size="small"
							value={selectedBoard}
							onChange={(e) => setSelectedBoard(parseInt(e.target.value, 10))}
							data-cy="boards"
						>
							{getBoards()}
						</SelectInput>
					</Box>
				</>
			)}
		</GenericDialog>
	);
};

export default BoardDeleteDialog;
