import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { matchPath } from "react-router-dom";
import { withRouter } from "utils/router";
import request from "superagent";
import queryString from "query-string";
import { API_HOST } from "config/env";
import { closeSnackbar } from "notistack";
import cookie from "react-cookies";
import clsx from "clsx";

import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { withStyles } from "@mui/styles";
import MenuItem from "@mui/material/MenuItem";
import Link from "@mui/material/Link";

import Icon from "atlas/components/Icon/Icon";
import { useWidthDown, useWidthUp } from "atlas/utils/useWidth";
import processHtml from "utils/processHtml";
import withErrorHandling from "components/ErrorHOC";
import { formatDate } from "utils/date";
import GenericDialog from "atlas/components/Dialogs/GenericDialog";
import ComponentContainer from "atlas/components/ComponentContainer/ComponentContainer";

import MeetingPublishStatus from "components/MeetingCard/MeetingPublishStatus";
import BroadcastDialog from "components/Dialogs/BroadcastDialog";
import MeetingDeleteDialog from "components/Dialogs/MeetingDeleteDialog";
import MeetingPublishDialog from "components/Dialogs/MeetingPublishDialog";
import MeetingUnpublishDialog from "components/Dialogs/MeetingUnpublishDialog";
import MeetingShareDialog from "components/Dialogs/MeetingShareDialog";
import SupportRequestDialog from "components/Dialogs/SupportRequestDialog";
import MeetingPreviewDialog from "components/Dialogs/MeetingPreviewDialog";
import MeetingApproveDialog from "components/Dialogs/MeetingApproveDialog";
import UpdateMinutesDialog from "components/Dialogs/UpdateMinutesDialog";
import { isShared, isPublished } from "utils/agendaStatuses";
import { isShared as isMinutesShared, isPublished as isMinutesPublished, isAdopted as isMinutesAdopted } from "utils/minutesStatuses";
import Button from "@mui/material/Button";
import telemetryAddEvent from "utils/telemetryAddEvent";
import Grid from "@mui/material/Grid";

import clone from "lodash/fp/clone";

import { grayColor, warningColor, defaultFont } from "atlas/assets/jss/shared";
import CircularProgressIndicator from "atlas/components/Progress/CircularProgressIndicator";
import NoticeCard, { ICON_POSITION_MIDDLE, ACTION_CLOSE_MIDDLE } from "atlas/components/Cards/NoticeCard";

import typographyStyle from "atlas/assets/jss/components/typographyStyle";
import { STATUS_WARNING } from "atlas/assets/jss/utils/statusIndicators";
import { getMeetingOutputI18nLabel } from "utils/meetingOutputText";
import getProperty from "utils/caseInsensitiveProperty";
import { nameVideoParts } from "utils/videoTimeStamping";
import NonModalMenu from "atlas/components/Menu/NonModalMenu";
import { BOTTOM } from "atlas/assets/jss/utils/placement";
import downloadFile from "utils/download";
import BroadcastPanel from "./components/BroadcastPanel";
import MeetingDetailButtons from "./components/MeetingDetailButtons";
import { mapStateToProps } from "../../redux/app/mapStateToProps";
import { resetPageConfigs, updatePageConfigs, updateNotice, updateBottomNotice } from "redux/app/actions";
import { updatePageHeader } from "redux/pageHeader/actions";
import AddToAgendaDialog from "../../components/Dialogs/AddToAgendaDialog";
import notifierMessage from "utils/notifierMessage";
import { setSnackbarOptions } from "redux/snackBar/actions";
import { SettingsContext } from "contexts/Settings/SettingsContext";
import { setSourceMeetingDetails } from "redux/NewCopyAndMove/actions";

const withWidth = () => (WrappedComponent) => (props) => {
	const widthDownSm = useWidthDown("sm");
	const widthDownXl = useWidthDown("xl");
	const widthUpSm = useWidthUp("sm");

	return <WrappedComponent {...props} widthDownSm={widthDownSm} widthDownXl={widthDownXl} widthUpSm={widthUpSm} />;
};

const styles = () => ({
	smallText: {
		...typographyStyle.smallText,
		marginBottom: "-8px",
	},
	fieldInput: {
		width: "100%",
		marginTop: "0",
		marginBottom: "16px",
		"& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)": {
			transform: "translate(14px, 28px)",
		},
		"& .MuiInputBase-root": {
			height: "40px",
			"& .MuiInputBase-input": {
				boxSizing: "border-box",
				height: "40px",
				paddingTop: "10.5px",
				paddingBottom: "10.5px",
			},
		},
	},
	noTopMargin: {
		marginTop: "0 !important",
	},
	detailContainer: {
		display: "flex",
		alignItems: "flex-start",
	},
	detail: {
		flexGrow: "1",
	},
	videoContainer: {
		marginBottom: "16px",
		// this overrides the default padding 0 !important and is enough to make container size behave normally
		paddingBottom: "1px !important",
	},
	statusContainer: {
		display: "flex",
		alignItems: "center",
		marginTop: "16px",
	},
	statusNotice: {
		flexGrow: "1",
	},
	republishLink: {
		...defaultFont,
		whiteSpace: "nowrap",
		fontSize: "14px",
		fontWeight: "normal",
		lineHeight: "1.29",
		alignSelf: "center",
		padding: "0 16px",
	},
	whiteBackground: {
		backgroundColor: "#fff",
		borderRadius: "2px",
	},
	cardBorder: {
		border: `1px solid ${grayColor[4]}`,
	},
	addToagendaLinks: {
		display: "flex",
		alignItems: "flex-start",
		marginLeft: "10px",
		"& a": {
			color: "#385F99",
		},
	},
	agendaMemebrMinutesText: {
		textWrap: "nowrap",
		fontWeight: "700",
	},
	meetingLink: {
		"&:hover": {
			textDecoration: "underline",
			color: "#294772",
		},
	},
	addAgendaMinutes: {
		marginBottom: "8px",
	},
});

class MeetingDetail extends Component {
	constructor(props) {
		super(props);

		const { location } = props;
		const search = queryString.parse(location.search) || {};
		const action = (getProperty(search, "action") || "").toLowerCase();

		this.state = {
			isFetchingMeeting: true,
			meeting: { id: 0 },
			meetingGroups: [],
			showBroadcastDialog: false,
			showMeetingDeleteDialog: false,
			showMeetingPublishDialog: null,
			showMeetingUnpublishDialog: null,
			showMeetingShareDialog: null,
			showSupportRequestDialog: false,
			showMeetingPreviewDialog: false,
			showMeetingApproveDialog: false,
			showConfirmAdoptMinutesDialog: false,
			preview: {},
			savingMeeting: false,
			showAddToAgendaDialog: action === "addtoagenda",
			anchor: {},
			downloading: {},
			uploadingAdoptedMinutes: false,
			showEditTimeStamp: false,
			currentYoutubeLink: null,
			showUpdateMinutesDialog: false,
		};
	}

	componentDidMount() {
		const { dispatch, location } = this.props;
		const { params: { id = "0" } = {} } = matchPath({ path: "/meeting/:id", end: true }, location.pathname);
		const { meeting } = this.state;
		if (meeting.id !== parseInt(id, 10)) {
			this.loadMeeting(id, true);
		}

		this.loadMeetingGroups();

		dispatch(resetPageConfigs({}));
		dispatch(updatePageConfigs({ back: { url: "/meetings" }, telemetryPage: "Meeting details" }));
		dispatch(
			updateBottomNotice({
				persistentData: {
					fields: [
						{
							name: "hiddenMeetings",
							action: "pop",
							value: parseInt(id, 10),
						},
					],
				},
				update: true,
			}),
		);
	}

	componentDidUpdate() {
		const { location } = this.props;
		const { params: { id = "0" } = {} } = matchPath({ path: "/meeting/:id", end: true }, location.pathname);
		const { meeting } = this.state;
		if (meeting.id !== 0 && meeting.id !== parseInt(id, 10)) {
			this.loadMeeting(id, true);
		}
	}

	loadMeeting = (id, autoShowPublish) => {
		const { navigate, showSignIn, dispatch } = this.props;
		const menuOptions = [];

		request
			.get(`${API_HOST}/api/meeting/${id}`)
			.withCredentials()
			.then((res) => {
				if (res.body) {
					dispatch(setSourceMeetingDetails(res.body));
					this.setState(
						{
							meeting: res.body,
							isFetchingMeeting: false,
						},
						() => {
							const { t, dispatch, location, widthDownSm } = this.props;
							const { meeting } = this.state;
							const meetingTime = formatDate(null, meeting.startTime, meeting.endTime, t("app:at"), t("from"), t("to"), false);
							// add delete option if meeting is not published
							if (!isPublished(meeting.publicAgendaStatus)) {
								menuOptions.push({ label: t("menu.deleteMeeting"), actionFunction: this.deleteMeeting, cypressId: "delete" });
							}

							let primaryAction = undefined;
							if (widthDownSm) {
								menuOptions.unshift({
									label: t("buttons.editMeetingDetails"),
									actionFunction: this.editMeetingDetails,
									cypressId: "edit",
								});
							} else {
								primaryAction = () => {
									this.editMeetingDetails();
								};
							}

							dispatch(updatePageConfigs({ title: meeting.name, telemetryPage: "Meeting details", contentPaper: { transparent: true } }));
							dispatch(
								updatePageHeader({
									primaryAction: primaryAction,
									primaryActionText: t("buttons.editMeetingDetails"),
									primaryActionTooltip: t("tooltips.editMeetingDetails"),
									additionalText: [meetingTime, meeting.location],
									menuOptions,
								}),
							);

							if (meeting.boardAgendaStatus === 1) {
								this.finishMeetingCreation(id);
							}
							if (meeting.youtubeLink) {
								this.activateTimeStamping(meeting.youtubeLink);
							}

							if (autoShowPublish) {
								const queryStringValues = queryString.parse(location.search);
								if (queryStringValues && queryStringValues.publishAgenda) {
									if (meeting.closed) {
										this.openMeetingShareDialog(true);
									} else {
										this.openMeetingPublishDialog(true);
									}
								}
							}
						},
					);
				}
			})
			.catch((err) => {
				if (err.status === 401) {
					navigate("/");
				} else {
					showSignIn(
						err,
						() => {
							this.loadMeeting(id, autoShowPublish);
						},
						() => {
							this.setState({ requestError: err });
						},
					);
				}
			});
	};

	loadMeetingGroups = () => {
		const { showSignIn } = this.props;

		request
			.get(`${API_HOST}/api/boards`)
			.withCredentials()
			.then((res) => {
				if (res.body) {
					this.setState({
						meetingGroups: res.body.boards,
					});
				}
			})
			.catch((err) => {
				showSignIn(
					err,
					() => {
						this.loadMeetingGroups();
					},
					() => {
						this.setState({ requestError: err });
					},
				);
			});
	};

	editMeetingDetails = () => {
		const { navigate, dispatch, location } = this.props;
		const { meeting } = this.state;

		telemetryAddEvent("Meeting details - Edit meeting details");

		dispatch(
			updatePageConfigs({
				preferedBack: { url: location.pathname + location.search },
			}),
		);

		navigate(`/meeting/edit/${meeting.id}`);
	};

	openLiveMeeting = () => {
		const { dispatch, navigate, location } = this.props;
		const { meeting } = this.state;
		const { policyEnabled } = this.context;

		dispatch(
			updatePageConfigs({
				preferedBack: { url: location.pathname + location.search },
			}),
		);
		if (policyEnabled) {
			navigate(`/meeting/liveV2/${meeting.id}`);
		} else {
			navigate(`/meeting/${(cookie.load("old_minutes") || "false") === "false" ? "liveV2" : "live"}/${meeting.id}`);
		}
	};

	viewMeetingHistory = () => {
		const { navigate } = this.props;
		const { meeting } = this.state;

		navigate(`/meeting/history/${meeting.id}`);
	};

	viewNotificationsHistory = () => {
		const { navigate } = this.props;
		const { meeting } = this.state;

		navigate(`/meeting/history/${meeting.id}/notifications`);
	};

	editAgenda = () => {
		const { navigate } = this.props;
		const { meeting } = this.state;

		navigate(`/meeting/detailsV2/${meeting.id}`);
	};

	publishAgenda = () => {
		telemetryAddEvent("Meeting details - Open publish");

		this.openMeetingPublishDialog(true);
	};

	unpublishAgenda = () => {
		telemetryAddEvent("Meeting details - Open unpublish");

		this.openMeetingUnpublishDialog(true);
	};

	shareAgenda = () => {
		telemetryAddEvent("Meeting details - Open share");

		this.openMeetingShareDialog(true);
	};

	previewMemberAgenda = () => {
		telemetryAddEvent("Meeting details - Preview member agenda");

		this.openMeetingPreviewDialog(true, true);
	};

	previewPublicAgenda = () => {
		telemetryAddEvent("Meeting details - Preview public agenda");

		this.openMeetingPreviewDialog(true, false);
	};

	previewMemberMinutes = () => {
		telemetryAddEvent("Meeting details - Preview member minutes");

		this.openMeetingPreviewDialog(false, true);
	};

	previewPublicMinutes = () => {
		telemetryAddEvent("Meeting details - Preview public minutes");

		this.openMeetingPreviewDialog(false, false);
	};

	publishMinutes = () => {
		telemetryAddEvent("Meeting details - Open publish minutes");

		this.openMeetingPublishDialog(false);
	};

	unpublishMinutes = () => {
		telemetryAddEvent("Meeting details - Open unpublish minutes");

		this.openMeetingUnpublishDialog(false);
	};

	shareMinutes = () => {
		telemetryAddEvent("Meeting details - Open share minutes");

		this.openMeetingShareDialog(false);
	};

	updateMinutes = () => {
		this.openUpdateMinutesDialog(true);
	};

	approveMinutes = () => {
		this.openMeetingApproveDialog();
	};

	handleMinutesApproved = () => {
		telemetryAddEvent(`Meeting details - Minutes adopted`);

		const { navigate, lite } = this.props;
		const {
			meeting: { id },
		} = this.state;
		this.closeMeetingApproveDialog();
		this.loadMeeting(id);

		if (lite.enabled) {
			navigate(`/meeting/final/${id}/minutes/members?adopted=true$`);
		} else {
			navigate(`/meeting/adopt/${id}/minutes`);
		}
	};

	downloadMinutes = (members, adopted) => {
		const { meeting } = this.state;

		request
			.post(`${API_HOST}/api/meeting/${meeting.id}/download`)
			.withCredentials()
			.send({ agenda: false, members, adopted })
			.then((res) => {
				if (res.status === 200) {
					downloadFile(
						res.body.name,
						adopted ? "application/pdf" : "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
						res.body.document,
					);
				}

				this.setState({
					anchor: {},
					downloading: {},
				});
			})
			.catch((err) => {
				console.log(err);

				this.setState({
					anchor: {},
					downloading: {},
				});
			});
	};

	uploadMinutes = (member, adopted) => {
		const fileInput = document.getElementById(`upload-${member ? "member" : "public"}${adopted ? "-adopted" : ""}-minutes-file`);
		if (fileInput) {
			fileInput.value = null;
			fileInput.click();
		}
	};

	handleMinutesUpload = (e, member, adopted) => {
		const { t, dispatch } = this.props;
		const { meeting } = this.state;

		telemetryAddEvent(`Meeting details - ${member ? "Member" : "Public"} ${adopted ? "adopted (Pdf)" : "draft (Word)"} minutes uploaded`);

		const fileInput = document.getElementById(`upload-${member ? "member" : "public"}${adopted ? "-adopted" : ""}-minutes-file`);
		const selectedFile = fileInput.files[0];
		const fileData = new FormData();
		fileData.append(selectedFile.name, selectedFile);
		fileData.append(
			"data",
			JSON.stringify({
				members: member,
				adopted,
			}),
		);

		if (adopted) {
			this.setState({ uploadingAdoptedMinutes: true });
		}

		request
			.post(`${API_HOST}/api/meeting/${meeting.id}/uploadminutes`)
			.withCredentials()
			.send(fileData)
			.then((res) => {
				if (res.status === 200) {
					let option = notifierMessage(t(`notifications.minutesUploaded`), "success");
					dispatch(setSnackbarOptions(option));
					this.handleCloseMenu();
					this.loadMeeting(meeting.id);
					if (adopted) {
						this.setState({ uploadingAdoptedMinutes: false });
					}
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	editMinutes = () => {
		const { navigate, dispatch, location } = this.props;
		const { meeting } = this.state;

		telemetryAddEvent("Minutes - Edit minutes");

		dispatch(
			updatePageConfigs({
				preferedBack: { url: location.pathname + location.search },
			}),
		);

		navigate(`/meeting/${(cookie.load("old_minutes") || "false") === "false" ? "liveV2" : "live"}/${meeting.id}`);
	};

	addToAgenda = () => {
		this.setState({ showAddToAgendaDialog: true });
	};

	deleteMeeting = () => {
		this.openMeetingDeleteDialog();
	};

	finishMeetingCreation = (id) => {
		const { meeting } = this.state;
		request
			.post(`${API_HOST}/api/meeting/${id}/finishmeetingcreation`)
			.withCredentials()
			.send({})
			.then((res) => {
				if (res.body) {
					meeting.boardAgendaStatus = 2;
					this.setState({ meeting });
				}
			});
	};

	activateTimeStamping = (youtubeLink) => {
		this.setState({ showEditTimeStamp: true, currentYoutubeLink: youtubeLink });
	};

	handleFieldChange = (e) => {
		const { meeting = {}, errors = {} } = this.state;
		const { target } = e;
		const name = target.id || target.name;

		// get all values in edit mode, update just the one that changed.
		const newEditValues = clone(meeting);
		newEditValues[name] = target.value;

		const newErrors = clone(errors);
		newErrors[name] = null;

		this.setState({
			meeting: newEditValues,
			errors: newErrors,
		});
	};

	handleSaveMeeting = () => {
		telemetryAddEvent("Meeting details - Save meeting video");

		const { t, dispatch, showSignIn } = this.props;
		const { meeting = {}, errors = {} } = this.state;
		const url = `${API_HOST}/api/meeting/${meeting.id}`;
		let linksValid = true;
		let youtubeLinkValid = meeting.youtubeLink && meeting.youtubeLink.length > 0;
		meeting.zoomLink =
			meeting.zoomLink && meeting.zoomLink.length > 0 && !/(http|https)/.test(meeting.zoomLink)
				? `https://${meeting.zoomLink}`
				: (meeting.zoomLink || "").replace("http://", "https://");
		if (meeting.zoomLink.length > 0 && !this.matchZoomUrl(meeting.zoomLink)) {
			errors.zoomLink = t("errors.invalidZoom");
			linksValid = false;
		}

		meeting.youtubeLink =
			meeting.youtubeLink && meeting.youtubeLink.length > 0 && !/(http|https)/.test(meeting.youtubeLink)
				? `https://${meeting.youtubeLink}`
				: (meeting.youtubeLink || "").replace("http://", "https://");
		if (meeting.youtubeLink.length > 0 && !this.matchYoutubeUrl(meeting.youtubeLink)) {
			errors.youtubeLink = t("errors.invalidYoutube");
			linksValid = false;
			youtubeLinkValid = false;
		}

		if (linksValid) {
			this.setState({ savingMeeting: true });

			request
				.put(url)
				.withCredentials()
				.send(meeting)
				.then((res) => {
					this.setState({ savingMeeting: false, showEditTimeStamp: youtubeLinkValid, currentYoutubeLink: meeting.youtubeLink });

					if (res.status === 200) {
						let option = notifierMessage(t(`notifications.meetingSaved`), "success");
						dispatch(setSnackbarOptions(option));
					}
				})
				.catch((err) => {
					showSignIn(err, () => {
						this.handleSaveMeeting();
					});
				});
		} else {
			this.setState({ errors });
		}
	};

	testZoom = () => {
		telemetryAddEvent("Meeting details - Test zoom");

		const { t } = this.props;
		const { meeting = {}, errors = {} } = this.state;
		meeting.zoomLink =
			meeting.zoomLink && meeting.zoomLink.length > 0 && !/(http|https)/.test(meeting.zoomLink)
				? `https://${meeting.zoomLink}`
				: (meeting.zoomLink || "").replace("http://", "https://");
		if (this.matchZoomUrl(meeting.zoomLink)) {
			window.open(meeting.zoomLink);
		} else {
			errors.zoomLink = t("errors.invalidZoom");
			this.setState({ errors });
		}
	};

	testYouTube = () => {
		telemetryAddEvent("Meeting details - Test youtube");

		const { t } = this.props;
		const { meeting = {}, errors = {} } = this.state;
		meeting.youtubeLink =
			meeting.youtubeLink && meeting.youtubeLink.length > 0 && !/(http|https)/.test(meeting.youtubeLink)
				? `https://${meeting.youtubeLink}`
				: (meeting.youtubeLink || "").replace("http://", "https://");
		if (this.matchYoutubeUrl(meeting.youtubeLink)) {
			window.open(meeting.youtubeLink);
		} else {
			errors.youtubeLink = t("errors.invalidYoutube");
			this.setState({ errors });
		}
	};

	matchYoutubeUrl = (url) => {
		const regex = /^(?:https:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
		if (regex.exec(url) !== null) {
			return true;
		}
		return false;
	};

	matchZoomUrl = (url) => {
		const regex = /^(?:https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
		if (regex.exec(url) !== null) {
			return true;
		}
		return false;
	};

	// Meeting Broadcast dialog ------------------------------------------------------------------
	editTimestamps = () => {
		const { navigate } = this.props;
		const { meeting } = this.state;

		navigate(`/meeting/timestamping/${meeting.id}`);
	};

	openBroadcastDialog = () => {
		this.setState({
			showBroadcastDialog: true,
		});
	};

	closeBroadcastDialog = () => {
		this.setState({
			showBroadcastDialog: false,
		});
	};

	handleUndoRemoveBroadcast = () => {
		const { meeting, previousBroadcastsToRevert } = this.state;

		if (meeting && previousBroadcastsToRevert) {
			request
				.post(`${API_HOST}/api/savevideoeventsettings`)
				.withCredentials()
				.send({ MeetingId: meeting.id, Events: previousBroadcastsToRevert })
				.then((res) => {
					if (res.body) {
						closeSnackbar();
						meeting.broadcasts = previousBroadcastsToRevert;
						this.setState({ meeting, previousBroadcastsToRevert: null });
					}
				});
		}
	};

	handleRemoveBroadcast = (broadcast) => {
		const { t, dispatch } = this.props;
		const { meeting } = this.state;

		this.setState({ previousBroadcastsToRevert: meeting.broadcasts });
		const updatedBroadcasts = nameVideoParts(
			broadcast.broadcastId
				? meeting.broadcasts.filter((b) => b.broadcastId !== broadcast.broadcastId)
				: broadcast.youtubeId
					? meeting.broadcasts.filter((b) => b.youtubeId !== broadcast.youtubeId)
					: [],
		);

		request
			.post(`${API_HOST}/api/savevideoeventsettings`)
			.withCredentials()
			.send({ MeetingId: meeting.id, Events: updatedBroadcasts })
			.then((res) => {
				if (res.body) {
					meeting.broadcasts = updatedBroadcasts;
					this.setState({ meeting });
					let option = notifierMessage(t("broadcastRemovedSuccessfully"), "success", () => this.handleUndoRemoveBroadcast());
					dispatch(setSnackbarOptions(option));
				}
			});
	};

	handleSaveBroadcast = (broadcasts) => {
		const { t, dispatch } = this.props;
		const { meeting } = this.state;
		request
			.post(`${API_HOST}/api/savevideoeventsettings`)
			.withCredentials()
			.send({ MeetingId: meeting.id, Events: broadcasts })
			.then((res) => {
				if (res.body) {
					meeting.broadcasts = res.body.length > 0 ? res.body : [];

					this.setState({
						meeting,
						showBroadcastDialog: false,
					});
					let option = notifierMessage(t("broadcastUpdatedSuccessfully"), "success");
					dispatch(setSnackbarOptions(option));
				}
			});
	};

	handleToggleMenu = (e, type) => {
		e.preventDefault();
		e.stopPropagation();
		this.setState({
			anchor: { [type]: e.currentTarget },
		});
	};

	handleCloseMenu = () => {
		this.setState({
			anchor: {},
		});
	};

	// Support Request dialog ------------------------------------------------------------------
	openSupportRequestDialog = () =>
		this.setState({
			// close any other dialog
			showBroadcastDialog: false,
			showMeetingDeleteDialog: false,
			showMeetingPublishDialog: null,
			showMeetingUnpublishDialog: null,
			showMeetingShareDialog: null,

			// show support request dialog
			showSupportRequestDialog: true,
		});

	closeSupportRequestDialog = () => this.setState({ showSupportRequestDialog: false });

	// Meeting Publish dialog ------------------------------------------------------------------
	openMeetingPublishDialog = (agenda) =>
		this.setState({
			showMeetingPublishDialog: { agenda },
		});

	closeMeetingPublishDialog = () =>
		this.setState({
			showMeetingPublishDialog: null,
		});

	afterPublishMeeting = ({ agenda, wasAlsoShared, agendaNotice, error }) => {
		const { t, dispatch } = this.props;
		const {
			meeting: { id },
		} = this.state;
		this.loadMeeting(id);
		if (agendaNotice && agendaNotice.documentId > 0) {
			window.open(`/document/${agendaNotice.documentId}`, "_blank");
		}
		if (!error) {
			if (wasAlsoShared) {
				let option = notifierMessage(t(getMeetingOutputI18nLabel("publishMeetingDialog.snackbar.successWithShare", agenda)), "success");
				dispatch(setSnackbarOptions(option));
			} else {
				let option = notifierMessage(t(getMeetingOutputI18nLabel("publishMeetingDialog.snackbar.success", agenda)), "success");
				dispatch(setSnackbarOptions(option));
			}
		}
	};

	afterMinutesUpdate = (status, err) => {
		const { t, dispatch } = this.props;
		const {
			meeting: { id },
		} = this.state;

		this.setState({ showUpdateMinutesDialog: false });
		if (status) {
			this.loadMeeting(id);
			let option = notifierMessage(t("updateMinutesDialog.snackbar.success"), "success");
			dispatch(setSnackbarOptions(option));
		} else {
			let option = notifierMessage(t("updateMinutesDialog.snackbar.error"), "error");
			dispatch(setSnackbarOptions(option));
		}
	};

	// Meeting Unpublish dialog ------------------------------------------------------------------
	openMeetingUnpublishDialog = (agenda) =>
		this.setState({
			showMeetingUnpublishDialog: { agenda },
		});

	closeMeetingUnpublishDialog = () =>
		this.setState({
			showMeetingUnpublishDialog: null,
		});

	afterUnpublishMeeting = ({ agenda, meetings }) => {
		const { t, dispatch, navigate, classes } = this.props;
		const {
			meeting: { id },
		} = this.state;
		this.loadMeeting(id);
		if (agenda || meetings.length === 0) {
			let option = notifierMessage(t(getMeetingOutputI18nLabel("unpublishMeetingDialog.snackbar.success", agenda)), "success");
			dispatch(setSnackbarOptions(option));
		} else {
			dispatch(
				updateNotice({
					status: STATUS_WARNING,
					icon: "status-alert",
					iconPlacement: ICON_POSITION_MIDDLE,
					label: (
						<>
							<div>{t("unpublishMeetingDialog.snackbar.republishAgenda")}</div>
							<div className={classes.republishLink}>
								<a
									href={`/home/meeting/${meetings[0]}?publishAgenda=true`}
									onClick={(e) => {
										e.preventDefault();
										e.stopPropagation();

										navigate(`/meeting/${meetings[0]}?publishAgenda=true`);

										dispatch(updateNotice({}));
									}}
								>
									{t("unpublishMeetingDialog.snackbar.republishAgendaLink")}
								</a>
							</div>
						</>
					),
					complexLabel: true,
					placement: BOTTOM,
					actions: [ACTION_CLOSE_MIDDLE],
					maxWidth: "548px",
				}),
			);
		}
	};

	// Meeting Share dialog ------------------------------------------------------------------
	openMeetingShareDialog = (agenda) =>
		this.setState({
			showMeetingShareDialog: { agenda },
		});

	closeMeetingShareDialog = () =>
		this.setState({
			showMeetingShareDialog: null,
		});

	afterShareMeeting = ({ agenda, error }) => {
		const { t, dispatch } = this.props;
		const {
			meeting: { id },
		} = this.state;

		this.loadMeeting(id);
		if (!error) {
			let option = notifierMessage(t(getMeetingOutputI18nLabel("shareMeetingDialog.snackbar.success", agenda)), "success");
			dispatch(setSnackbarOptions(option));
		}
	};

	// Meeting Delete dialog ------------------------------------------------------------------
	openMeetingDeleteDialog = () =>
		this.setState({
			showMeetingDeleteDialog: true,
		});

	closeMeetingDeleteDialog = () =>
		this.setState({
			showMeetingDeleteDialog: false,
		});

	afterDeleteMeeting = () => {
		const { navigate } = this.props;
		navigate(`/meetings`);
	};

	// Meeting Delete dialog ------------------------------------------------------------------
	openMeetingPreviewDialog = (agenda, members) =>
		this.setState({
			showMeetingPreviewDialog: true,
			preview: {
				agenda,
				members,
			},
		});

	closeMeetingPreviewDialog = () =>
		this.setState({
			showMeetingPreviewDialog: false,
			preview: {},
		});

	// Meeting Approve dialog ------------------------------------------------------------------
	openMeetingApproveDialog = () => {
		this.setState({
			showMeetingApproveDialog: true,
		});
	};

	closeMeetingApproveDialog = () => {
		this.setState({
			showMeetingApproveDialog: false,
		});
	};

	openConfirmAdoptMinutesDialog = () => {
		this.setState({
			showConfirmAdoptMinutesDialog: true,
		});
	};

	openUpdateMinutesDialog = () => {
		this.setState({ showUpdateMinutesDialog: true });
	};

	closeMinutesUpdateDialog = () =>
		this.setState({
			showUpdateMinutesDialog: false,
		});

	render() {
		const { t, showSignIn, lite, location, classes, dispatch, widthDownSm, widthDownXl, widthUpSm } = this.props;
		const {
			errors = {},
			isFetchingMeeting,
			meeting,
			meetingGroups,
			showBroadcastDialog,
			showMeetingDeleteDialog,
			showMeetingPublishDialog,
			showMeetingUnpublishDialog,
			showMeetingShareDialog,
			showSupportRequestDialog,
			showMeetingPreviewDialog,
			showMeetingApproveDialog,
			showConfirmAdoptMinutesDialog,
			preview,
			requestError,
			savingMeeting,
			showAddToAgendaDialog,
			anchor,
			downloading,
			uploadingAdoptedMinutes,
			showEditTimeStamp,
			currentYoutubeLink,
			showUpdateMinutesDialog,
		} = this.state;

		if (requestError) {
			const errormsg =
				requestError.response.body && requestError.response.body.Message
					? requestError.response.body.Message
					: `${requestError.status} ${requestError.message}`;
			return (
				<Typography variant="h3" style={{ padding: "24px" }}>
					{errormsg}
				</Typography>
			);
		}
		if (isFetchingMeeting) {
			return <CircularProgressIndicator />;
		}

		const telemetryPage = "Meeting details";
		const getWarningLabel = () => {
			if ((meeting.boardAgendaStatus === 7 || meeting.publicAgendaStatus === 7) && meeting.isDraftPolicyUpdated) {
				return (
					<>
						{t("outofsyncWarning")}
						<br></br>
						{t("policyDrafts")}
					</>
				);
			}
			if (meeting.boardAgendaStatus === 7 || meeting.publicAgendaStatus === 7) {
				return `${t("outofsyncWarning")}`;
			}
			if (meeting.isDraftPolicyUpdated) {
				return `${t("warningPolicyDrafts")}`;
			}
		};

		const getAgendaPreviewOptions = () => {
			const agendaPreviewOptions = [
				{
					label: t("memberAgenda"),
					ariaLabel: t("tooltips.previewMemberAgenda"),
					actionFunction: this.previewMemberAgenda,
					"data-cy": "previewMemberAgenda",
				},
			];

			if (!meeting.closed) {
				agendaPreviewOptions.push({
					label: t("publicAgenda"),
					ariaLabel: t("tooltips.previewPublicAgenda"),
					actionFunction: this.previewPublicAgenda,
					"data-cy": "previewPublicAgenda",
				});
			}

			return agendaPreviewOptions;
		};

		const getAgendaOverflowOptions = () => {
			const agendaOptions = [
				{
					id: "preview-agenda",
					icon: "expand-down",
					label: t("buttons.previewDraft"),
					ariaLabel: t("tooltips.previewAgendaDraft"),
					ariaHasPopup: "true",
					ariaExpanded: anchor.previewAgenda ? "true" : "false",
					type: "button",
					actionFunction: (e) => {
						this.handleToggleMenu(e, "previewAgenda");
					},
					"data-cy": "previewAgenda",
				},
			];

			return agendaOptions;
		};

		const getMinutesPreviewOptions = () => {
			const minutesPreviewOptions = [
				{
					label: t("memberMinutes"),
					ariaLabel: t("tooltips.previewMemberMinutes"),
					actionFunction: this.previewMemberMinutes,
					"data-cy": "previewMemberMinutes",
				},
			];

			if (isPublished(meeting.publicAgendaStatus)) {
				minutesPreviewOptions.push({
					label: t("publicMinutes"),
					ariaLabel: t("tooltips.previewPublicMinutes"),
					actionFunction: this.previewPublicMinutes,
					"data-cy": "previewPublicMinutes",
				});
			}

			return minutesPreviewOptions;
		};

		const getMinutesOverflowOptions = () => {
			const minutesOptions = [
				{
					id: "preview-minutes",
					icon: "expand-down",
					label: t("buttons.previewDraft"),
					ariaLabel: t("tooltips.previewMinutesDraft"),
					ariaHasPopup: "true",
					ariaExpanded: anchor.previewMinutes ? "true" : "false",
					type: "button",
					actionFunction: (e) => {
						this.handleToggleMenu(e, "previewMinutes");
					},
					"data-cy": "previewMinutes",
				},
				{
					id: "download-minutes",
					icon: "expand-down",
					label: t("buttons.downloadDraft"),
					ariaLabel: t("tooltips.downloadMinutesDraft"),
					ariaHasPopup: "true",
					ariaExpanded: anchor.download ? "true" : "false",
					type: "button",
					actionFunction: (e) => {
						this.handleToggleMenu(e, "download");
					},
					"data-cy": "downloadMinutes",
				},
				{
					id: "upload-minutes",
					icon: "expand-down",
					label: t("buttons.uploadDraft"),
					ariaLabel: t("tooltips.uploadMinutesDraft"),
					ariaHasPopup: "true",
					ariaExpanded: anchor.upload ? "true" : "false",
					type: "button",
					actionFunction: (e) => {
						this.handleToggleMenu(e, "upload");
					},
					"data-cy": "uploadMinutes",
				},
			];

			if (
				(meeting.boardMinutesStatus == 7 ||
					meeting.boardMinutesStatus == 11 ||
					meeting.publicMinutesStatus == 11 ||
					meeting.publicMinutesStatus == 7) &&
				!meeting.onPublishShowDraftMinutes
			) {
				minutesOptions.push({
					label: t("buttons.updateMinutes"),
					ariaLabel: t("tooltips.updateMinutes"),
					actionFunction: this.updateMinutes,
					"data-cy": "updateMinutes",
				});
			} else {
				if (
					meeting.onPublishShowDraftMinutes &&
					!isMinutesAdopted(meeting.boardMinutesStatus) &&
					(isShared(meeting.boardAgendaStatus) || isPublished(meeting.publicAgendaStatus))
				) {
					minutesOptions.push({
						label: t("buttons.share"),
						ariaLabel: t("tooltips.shareMinutes"),
						actionFunction: this.shareMinutes,
						"data-cy": "shareMinutes",
					});
				}

				if (
					meeting.onPublishShowDraftMinutes &&
					!meeting.closed &&
					!isMinutesAdopted(meeting.publicMinutesStatus) &&
					(isShared(meeting.boardAgendaStatus) || isPublished(meeting.publicAgendaStatus))
				) {
					minutesOptions.push({
						label: t("buttons.publish"),
						ariaLabel: t("tooltips.publishMinutes"),
						actionFunction: this.publishMinutes,
						"data-cy": "publishMinutes",
					});
				}

				if (isMinutesPublished(meeting.publicMinutesStatus) && meeting.onPublishShowDraftMinutes) {
					minutesOptions.push({
						label: t("buttons.unpublish"),
						ariaLabel: t("tooltips.unpublishMinutes"),
						actionFunction: this.unpublishMinutes,
						"data-cy": "unpublishMinutes",
					});
				}
			}

			return minutesOptions;
		};

		const getMinutesAdoptOptions = () => {
			const minutesAdoptOptions = [
				{
					id: "download-minutes-adopt",
					icon: "expand-down",
					label: t("buttons.downloadMinutesToAdopt"),
					ariaLabel: t("tooltips.downloadMinutesToAdopt"),
					actionFunction: (e) => {
						this.handleToggleMenu(e, "download");
					},
					"data-cy": "downloadMinutesToAdopt",
				},
				{
					id: "upload-adopted-minutes",
					icon: "expand-down",
					label: t("buttons.uploadAdoptedMinutes"),
					ariaLabel: t("tooltips.uploadAdoptedMinutes"),
					actionFunction: (e) => {
						this.handleToggleMenu(e, "upload");
					},
					"data-cy": "uploadAdoptedMinutes",
				},
			];

			return minutesAdoptOptions;
		};

		return (
			<>
				{showBroadcastDialog && (
					<BroadcastDialog
						meeting={meeting}
						show={showBroadcastDialog}
						onClose={this.closeBroadcastDialog}
						saveBroadcast={this.handleSaveBroadcast}
					/>
				)}
				{showSupportRequestDialog && <SupportRequestDialog show={showSupportRequestDialog} onClose={this.closeSupportRequestDialog} />}

				{showMeetingDeleteDialog && (
					<MeetingDeleteDialog
						show={showMeetingDeleteDialog}
						afterDelete={this.afterDeleteMeeting}
						onClose={this.closeMeetingDeleteDialog}
						meeting={meeting}
						dispatch={dispatch}
					/>
				)}

				{showMeetingPublishDialog && (
					<MeetingPublishDialog
						showSignIn={showSignIn}
						show={!!showMeetingPublishDialog}
						agenda={showMeetingPublishDialog.agenda}
						openSupportRequestDialog={this.openSupportRequestDialog}
						afterPublish={this.afterPublishMeeting}
						onClose={this.closeMeetingPublishDialog}
						meeting={meeting}
						telemetryPage={telemetryPage}
					/>
				)}

				{showMeetingUnpublishDialog && (
					<MeetingUnpublishDialog
						show={!!showMeetingUnpublishDialog}
						agenda={showMeetingUnpublishDialog.agenda}
						afterUnpublish={this.afterUnpublishMeeting}
						onClose={this.closeMeetingUnpublishDialog}
						meeting={meeting}
						telemetryPage={telemetryPage}
					/>
				)}

				{showMeetingShareDialog && (
					<MeetingShareDialog
						show={!!showMeetingShareDialog}
						agenda={showMeetingShareDialog.agenda}
						openSupportRequestDialog={this.openSupportRequestDialog}
						afterShare={this.afterShareMeeting}
						onClose={this.closeMeetingShareDialog}
						meeting={meeting}
						telemetryPage={telemetryPage}
					/>
				)}

				{showMeetingPreviewDialog && (
					<MeetingPreviewDialog
						show={showMeetingPreviewDialog}
						meeting={meeting}
						agenda={preview.agenda}
						members={preview.members}
						onClose={this.closeMeetingPreviewDialog}
						showSignIn={showSignIn}
						location={location}
						openSupportRequestDialog={this.openSupportRequestDialog}
					/>
				)}

				{showMeetingApproveDialog && (
					<MeetingApproveDialog
						show={showMeetingApproveDialog}
						meeting={meeting}
						onApproved={this.handleMinutesApproved}
						onClose={this.closeMeetingApproveDialog}
						showSignIn={showSignIn}
						openSupportRequestDialog={this.openSupportRequestDialog}
						isLite={lite.enabled}
					/>
				)}

				{showUpdateMinutesDialog && (
					<UpdateMinutesDialog
						showSignIn={showSignIn}
						show={!!showUpdateMinutesDialog}
						onClose={this.closeMinutesUpdateDialog}
						meeting={meeting}
						telemetryPage={telemetryPage}
						afterUpdate={this.afterMinutesUpdate}
					/>
				)}

				{showAddToAgendaDialog && (
					<AddToAgendaDialog
						meetingId={meeting.id}
						meetingGroupId={meeting.boardId}
						meetingDate={meeting.date}
						meetingStartTime={meeting.startTime}
						publicMinutesHtml={meeting.publicDraftMinutesHtml}
						membersMinutesHtml={meeting.membersDraftMinutesHtml}
						show
						fromDetail
						meetingGroups={meetingGroups}
						onClose={() => {
							this.setState({ showAddToAgendaDialog: false });
							const {
								meeting: { id },
							} = this.state;
							this.loadMeeting(id);
						}}
						showSignIn={showSignIn}
					/>
				)}

				{showConfirmAdoptMinutesDialog && (
					<GenericDialog
						show={showConfirmAdoptMinutesDialog}
						title={t("approveMeetingDialog.title.minutes")}
						primaryAction={() => {
							this.setState({ showConfirmAdoptMinutesDialog: false });
							this.approveMinutes();
						}}
						primaryTitle={t("app:buttons.adopt")}
						secondaryAction={() => {
							this.setState({ showConfirmAdoptMinutesDialog: false });
						}}
						secondaryTitle={t("app:buttons.cancel")}
						closeIcon={<Icon name="close" />}
						data-cy="confirm-adopt-minutes"
					>
						<Typography>{t("confirmAdoptMinutesDialog.body")}</Typography>
					</GenericDialog>
				)}

				{anchor.previewAgenda && (
					<NonModalMenu
						id="previewAgendaMenu"
						className={classes.splitButtonMenu}
						anchorEl={anchor.previewAgenda}
						open={Boolean(anchor.previewAgenda)}
						onClose={this.handleCloseMenu}
						options={getAgendaPreviewOptions()}
					/>
				)}

				{anchor.download && (
					<NonModalMenu
						id="split-button-menu-download-minutes"
						className={classes.splitButtonMenu}
						anchorEl={anchor.download}
						open={Boolean(anchor.download)}
						onClose={this.handleCloseMenu}
					>
						<MenuItem
							id="download-member-minutes"
							key="download-member-minutes"
							onClick={() => {
								this.setState({ downloading: { members: true } });
								this.downloadMinutes(true, anchor.download.id === "download-minutes-adopt");
							}}
							data-cy="split-button-option-member-minutes"
						>
							{downloading.members ? <CircularProgressIndicator size={20} /> : t("memberMinutes")}
						</MenuItem>
						{isPublished(meeting.publicAgendaStatus) ? (
							<MenuItem
								id="download-public-minutes"
								key="download-public-minutes"
								onClick={() => {
									this.setState({ downloading: { public: true } });
									this.downloadMinutes(false, anchor.download.id === "download-minutes-adopt");
								}}
								data-cy="split-button-option-public-minutes"
							>
								{downloading.public ? <CircularProgressIndicator size={20} /> : t("publicMinutes")}
							</MenuItem>
						) : null}
					</NonModalMenu>
				)}

				{anchor.upload && (
					<>
						<NonModalMenu
							id="split-button-menu-upload-minutes"
							className={classes.splitButtonMenu}
							anchorEl={anchor.upload}
							open={Boolean(anchor.upload)}
							onClose={this.handleCloseMenu}
						>
							<MenuItem
								key="upload-member-minutes"
								onClick={() => this.uploadMinutes(true, anchor.upload.id === "upload-adopted-minutes")}
								data-cy="split-button-option-member-minutes"
							>
								{t("memberMinutes")}
							</MenuItem>
							{isPublished(meeting.publicAgendaStatus) ? (
								<MenuItem
									key="upload-public-minutes"
									onClick={() => this.uploadMinutes(false, anchor.upload.id === "upload-adopted-minutes")}
									data-cy="split-button-option-public-minutes"
								>
									{t("publicMinutes")}
								</MenuItem>
							) : null}
						</NonModalMenu>
					</>
				)}

				{anchor.previewMinutes && (
					<NonModalMenu
						id="previewMinutesMenu"
						className={classes.splitButtonMenu}
						anchorEl={anchor.previewMinutes}
						open={Boolean(anchor.previewMinutes)}
						onClose={this.handleCloseMenu}
						options={getMinutesPreviewOptions()}
					/>
				)}

				<div className={classes.whiteBackground}>
					<ComponentContainer onlyScrollbar>
						{meeting.boardAgendaStatus > 1 ? (
							<>
								<Container maxWidth="xl" style={{ overflow: "hidden" }}>
									<Paper className={clsx("padded", "contained", { mobile: widthDownSm }, classes.cardBorder)}>
										<div className={classes.detailContainer}>
											<div className={classes.detail}>
												<Typography variant="h3">{t("agenda")}</Typography>
												{meeting.agendaLastUpdated ? (
													<Typography variant="body2" className="grey" data-cy="dateLastEdited">
														{`${t("lastEdited")} ${formatDate(
															meeting.agendaLastUpdated,
															null,
															null,
															t("app:at"),
															"",
															"",
															false,
															true,
															true,
														)}`}
													</Typography>
												) : null}
												{meeting.agendaLastPublished ? (
													<Typography variant="body2" className="grey" data-cy="dateLastPublished">
														{`${t("lastPublished")} ${formatDate(
															meeting.agendaLastPublished,
															null,
															null,
															t("app:at"),
															"",
															"",
															false,
															true,
															true,
														)}`}
													</Typography>
												) : null}
												{meeting.agendaLastShared ? (
													<Typography variant="body2" className="grey" data-cy="dateLastShared">
														{`${t("lastShared")} ${formatDate(
															meeting.agendaLastShared,
															null,
															null,
															t("app:at"),
															"",
															"",
															false,
															true,
															true,
														)}`}
													</Typography>
												) : null}
											</div>
											{!widthDownXl && !meeting.historic && (
												<MeetingDetailButtons
													topRow
													isPublished={isPublished(meeting.publicAgendaStatus)}
													editAgenda={this.editAgenda}
													shareAgenda={this.shareAgenda}
													publishAgenda={this.publishAgenda}
													unpublishAgenda={this.unpublishAgenda}
													closed={meeting.closed}
													menuOptions={getAgendaOverflowOptions()}
												/>
											)}
										</div>
										{widthDownXl && widthUpSm && !meeting.historic && (
											<MeetingDetailButtons
												isPublished={isPublished(meeting.publicAgendaStatus)}
												editAgenda={this.editAgenda}
												shareAgenda={this.shareAgenda}
												publishAgenda={this.publishAgenda}
												unpublishAgenda={this.unpublishAgenda}
												closed={meeting.closed}
												menuOptions={getAgendaOverflowOptions()}
											/>
										)}
										<div className={classes.statusContainer}>
											{widthUpSm && (
												<div className={classes.statusNotice}>
													{(meeting.boardAgendaStatus === 7 || meeting.publicAgendaStatus === 7 || meeting.isDraftPolicyUpdated) && (
														<NoticeCard
															updateNotice={updateNotice}
															icon="status-alert"
															label={getWarningLabel()}
															processHtml={processHtml}
															status={STATUS_WARNING}
															thickStatus
															iconColor={warningColor}
															limitSizeToText
														/>
													)}
												</div>
											)}
											<MeetingPublishStatus
												topMargin={0}
												width="auto"
												meetingId={meeting.id}
												boardStatus={meeting.boardAgendaStatus}
												publicStatus={meeting.publicAgendaStatus}
												publicReleaseDay={meeting.publicAgendaReleaseDay}
												boardHtmlId={meeting.boardAgendaHtmlId}
												telemetryPage={telemetryPage}
											/>
										</div>
										{!widthUpSm && !meeting.historic && (
											<MeetingDetailButtons
												fullWidth
												isPublished={isPublished(meeting.publicAgendaStatus)}
												editAgenda={this.editAgenda}
												shareAgenda={this.shareAgenda}
												publishAgenda={this.publishAgenda}
												unpublishAgenda={this.unpublishAgenda}
												closed={meeting.closed}
												menuOptions={getAgendaOverflowOptions()}
											/>
										)}
									</Paper>
								</Container>

								{meeting.purchasedBoxcast && (
									<BroadcastPanel
										meeting={meeting}
										classes={classes}
										fullWidth={widthDownSm}
										editTimestamps={this.editTimestamps}
										openBroadcastDialog={this.openBroadcastDialog}
										removeBroadcast={this.handleRemoveBroadcast}
										showEditTimeStamp={showEditTimeStamp}
										errors={errors}
										currentYoutubeLink={currentYoutubeLink}
										savingMeeting={savingMeeting}
										handleFieldChange={this.handleFieldChange}
										testZoom={this.testZoom}
										testYouTube={this.testYouTube}
										matchYoutubeUrl={this.matchYoutubeUrl}
										handleSaveMeeting={this.handleSaveMeeting}
									/>
								)}

								<Container maxWidth="xl" style={{ overflow: "hidden", position: "relative" }}>
									<Paper className={clsx("padded", "contained", { mobile: widthDownSm }, classes.noTopMargin, classes.cardBorder)}>
										<div className={classes.detailContainer}>
											<div className={classes.detail}>
												<Typography variant="h3">{t("minutes")}</Typography>
												{meeting.minutesMembersLastUploaded ? (
													<Typography variant="body2" className="grey" data-cy="minutesMembersLastUploaded">
														{`${t("membersLastUploaded")} ${formatDate(
															meeting.minutesMembersLastUploaded,
															null,
															null,
															t("app:at"),
															"",
															"",
															false,
															true,
															true,
														)}`}
													</Typography>
												) : null}
												{meeting.minutesMembersFileName && meeting.minutesMembersFileName.length > 0 ? (
													<Typography variant="body2" className="grey" data-cy="minutesMembersFileName">
														{`${t("membersFileNameUploaded")} ${meeting.minutesMembersFileName}`}
													</Typography>
												) : null}
												{meeting.minutesPublicLastUploaded ? (
													<Typography variant="body2" className="grey" data-cy="minutesPublicLastUploaded">
														{`${t("publicLastUploaded")} ${formatDate(
															meeting.minutesPublicLastUploaded,
															null,
															null,
															t("app:at"),
															"",
															"",
															false,
															true,
															true,
														)}`}
													</Typography>
												) : null}
												{meeting.minutesPublicFileName && meeting.minutesPublicFileName.length > 0 ? (
													<Typography variant="body2" className="grey" data-cy="minutesPublicFileName">
														{`${t("publicFileNameUploaded")} ${meeting.minutesPublicFileName}`}
													</Typography>
												) : null}
												{meeting.minutesAdoptedMembersLastUploaded && meeting.minutesAdoptedMembersLastUploaded.length > 0 ? (
													<Typography variant="body2" className="grey" data-cy="minutesAdoptedMembersLastUploaded">
														{`${t("adoptedMembersLastUploaded")} ${formatDate(
															meeting.minutesAdoptedMembersLastUploaded,
															null,
															null,
															t("app:at"),
															"",
															"",
															false,
															true,
															true,
														)}`}
													</Typography>
												) : null}
												{meeting.minutesAdoptedMembersFileName && meeting.minutesAdoptedMembersFileName.length > 0 ? (
													<Typography variant="body2" className="grey" data-cy="minutesAdoptedMembersFileName">
														{`${t("adoptedMembersFileNameUploaded")} ${meeting.minutesAdoptedMembersFileName}`}
													</Typography>
												) : null}
												{meeting.minutesAdoptedPublicLastUploaded && meeting.minutesAdoptedPublicLastUploaded.length > 0 ? (
													<Typography variant="body2" className="grey" data-cy="minutesAdoptedPublicLastUploaded">
														{`${t("adoptedPublicLastUploaded")} ${formatDate(
															meeting.minutesAdoptedPublicLastUploaded,
															null,
															null,
															t("app:at"),
															"",
															"",
															false,
															true,
															true,
														)}`}
													</Typography>
												) : null}
												{meeting.minutesAdoptedPublicFileName && meeting.minutesAdoptedPublicFileName.length > 0 ? (
													<Typography variant="body2" className="grey" data-cy="minutesAdoptedPublicFileName">
														{`${t("adoptedPublicFileNameUploaded")} ${meeting.minutesAdoptedPublicFileName}`}
													</Typography>
												) : null}

												{meeting.addToAgendaMemberMinutes && meeting.addToAgendaMemberMinutes.length > 0 && (
													<div className={classes.addAgendaMinutes}>
														<Typography
															className={clsx(classes.agendaMemebrMinutesText, "grey")}
															variant="body2"
															data-cy="mumber-Minutes-Added-To-Text"
														>
															{t("memberMinutesAddedTo")}&nbsp;
														</Typography>
														{meeting?.addToAgendaMemberMinutes?.map((info) => {
															return (
																<div className={classes.addToagendaLinks}>
																	{info.CanView ? (
																		<Link
																			className={classes.meetingLink}
																			target="_blank"
																			variant="body2"
																			underline="none"
																			href={`${API_HOST}/home/meeting/detailsV2/${info.MeetingId}`}
																		>
																			{info.Name}
																		</Link>
																	) : (
																		<Typography variant="body2" data-cy="mumber-Minutes-Added-To-Name">
																			{info.Name}
																		</Typography>
																	)}
																</div>
															);
														})}
													</div>
												)}
												{meeting.addToAgendaPublicMinutes && meeting.addToAgendaPublicMinutes.length > 0 && (
													<div>
														<Typography
															className={clsx(classes.agendaMemebrMinutesText, "grey")}
															variant="body2"
															data-cy="public-Minutes-Added-To-Text"
														>
															{t("publicMinutesAddedTo")}&nbsp;
														</Typography>
														{meeting?.addToAgendaPublicMinutes?.map((info) => {
															return (
																<div className={classes.addToagendaLinks}>
																	{info.CanView ? (
																		<Link
																			className={classes.meetingLink}
																			variant="body2"
																			underline="none"
																			href={`${API_HOST}/home/meeting/detailsV2/${info.MeetingId}`}
																			target="_blank"
																		>
																			{info.Name}
																		</Link>
																	) : (
																		<Typography variant="body2" data-cy="public-Minutes-Added-To-Name">
																			{info.Name}
																		</Typography>
																	)}
																</div>
															);
														})}
													</div>
												)}
											</div>
											{!widthDownXl && !meeting.historic && (
												<MeetingDetailButtons
													topRow
													isShared={isMinutesShared(meeting.boardMinutesStatus)}
													isPublished={isMinutesPublished(meeting.publicMinutesStatus)}
													isAdopted={isMinutesAdopted(meeting.publicMinutesStatus) || isMinutesAdopted(meeting.boardMinutesStatus)}
													isAddToAgenda={
														!(
															(meeting.boardMinutesStatus == 11 || meeting.publicMinutesStatus == 11) &&
															meeting.onPublishShowDraftMinutes == false
														)
													}
													minutesUploadedInPortal={meeting.minutesUploadedInPortal}
													previewMinutesOptions={getMinutesPreviewOptions()}
													editMinutes={this.editMinutes}
													approveMinutes={
														isMinutesAdopted(meeting.boardMinutesStatus) || isMinutesAdopted(meeting.publicMinutesStatus)
															? this.openConfirmAdoptMinutesDialog
															: this.approveMinutes
													}
													addToAgenda={this.addToAgenda}
													closed={meeting.closed}
													menuOptions={getMinutesOverflowOptions()}
													minutesAdoptMenuOptions={getMinutesAdoptOptions()}
													uploadingAdoptedMinutes={uploadingAdoptedMinutes}
												/>
											)}
										</div>
										{widthDownXl && widthUpSm && !meeting.historic && (
											<MeetingDetailButtons
												isShared={isMinutesShared(meeting.boardMinutesStatus)}
												isPublished={isMinutesPublished(meeting.publicMinutesStatus)}
												isAdopted={isMinutesAdopted(meeting.publicMinutesStatus) || isMinutesAdopted(meeting.boardMinutesStatus)}
												isAddToAgenda={
													!(
														(meeting.boardMinutesStatus == 11 || meeting.publicMinutesStatus == 11) &&
														meeting.onPublishShowDraftMinutes == false
													)
												}
												minutesUploadedInPortal={meeting.minutesUploadedInPortal}
												previewMinutesOptions={getMinutesPreviewOptions()}
												editMinutes={this.editMinutes}
												approveMinutes={
													isMinutesAdopted(meeting.boardMinutesStatus) || isMinutesAdopted(meeting.publicMinutesStatus)
														? this.openConfirmAdoptMinutesDialog
														: this.approveMinutes
												}
												addToAgenda={this.addToAgenda}
												closed={meeting.closed}
												menuOptions={getMinutesOverflowOptions()}
												minutesAdoptMenuOptions={getMinutesAdoptOptions()}
												uploadingAdoptedMinutes={uploadingAdoptedMinutes}
											/>
										)}
										<div className={classes.statusContainer}>
											{widthUpSm && (
												<div className={classes.statusNotice}>
													{(meeting.boardMinutesStatus === 7 || meeting.publicMinutesStatus === 7 || meeting.minutesUploadedInPortal) && (
														<NoticeCard
															updateNotice={updateNotice}
															icon="status-alert"
															label={
																meeting.minutesUploadedInPortal ? (
																	<span>
																		{t("portalMinutesWarning")}{" "}
																		<Link
																			href={`${API_HOST}/Portal/MeetingInformation.aspx?Org=Cal&Id=${meeting.id}&minutes=true`}
																			target="_blank"
																		>
																			{t("portalMinutesWarningLink")}
																		</Link>
																	</span>
																) : (
																	t("outofsyncWarning")
																)
															}
															processHtml={processHtml}
															status={STATUS_WARNING}
															thickStatus
															iconColor={warningColor}
															limitSizeToText
														/>
													)}
												</div>
											)}
											<MeetingPublishStatus
												agenda={false}
												topMargin={0}
												width="auto"
												meetingId={meeting.id}
												boardStatus={meeting.boardMinutesStatus}
												publicStatus={meeting.publicMinutesStatus}
												boardHtmlId={meeting.boardMinutesHtmlId}
												minutesUploadedInPortal={meeting.minutesUploadedInPortal}
												telemetryPage={telemetryPage}
											/>
										</div>
										{!widthUpSm && !meeting.historic && (
											<MeetingDetailButtons
												fullWidth
												isShared={isMinutesShared(meeting.boardMinutesStatus)}
												isPublished={isMinutesPublished(meeting.publicMinutesStatus)}
												isAdopted={isMinutesAdopted(meeting.publicMinutesStatus) || isMinutesAdopted(meeting.boardMinutesStatus)}
												isAddToAgenda={
													!(
														(meeting.boardMinutesStatus == 11 || meeting.publicMinutesStatus == 11) &&
														meeting.onPublishShowDraftMinutes == false
													)
												}
												minutesUploadedInPortal={meeting.minutesUploadedInPortal}
												previewMinutesOptions={getMinutesPreviewOptions()}
												approveMinutes={
													isMinutesAdopted(meeting.boardMinutesStatus) || isMinutesAdopted(meeting.publicMinutesStatus)
														? this.openConfirmAdoptMinutesDialog
														: this.approveMinutes
												}
												editMinutes={this.editMinutes}
												addToAgenda={this.addToAgenda}
												closed={meeting.closed}
												menuOptions={getMinutesOverflowOptions()}
												minutesAdoptMenuOptions={getMinutesAdoptOptions()}
												uploadingAdoptedMinutes={uploadingAdoptedMinutes}
											/>
										)}
									</Paper>
								</Container>
								{!meeting.historic &&
									isPublished(meeting.publicAgendaStatus) &&
									new Date(formatDate(meeting.date, null, null, "", "", "", true)) >= new Date(new Date().toDateString()) && (
										<Container maxWidth="xl" style={{ overflow: "hidden", position: "relative" }}>
											<Paper className={clsx("padded", "contained", widthDownSm ? " mobile" : "", classes.noTopMargin, classes.cardBorder)}>
												<Grid container>
													<Grid item sm={6} xs={12} style={{ marginBottom: widthDownSm ? "16px" : "" }}>
														<Typography variant="h3">{t("liveMeeting.label")}</Typography>
													</Grid>
													<Grid item sm={6} xs={12} style={{ textAlign: "right" }}>
														<Button
															id={`live-meeting-${meeting.id}`}
															key={`live-meeting-${meeting.id}`}
															label={t("buttons.launchLiveMeeting")}
															title={t("buttons.launchLiveMeeting")}
															onClick={this.openLiveMeeting}
															size="small"
															variant="contained"
															color="primary"
															fullWidth={widthDownSm}
															style={{ marginTop: "0" }}
														>
															{t("buttons.launchLiveMeeting")}
														</Button>
													</Grid>
												</Grid>
											</Paper>
										</Container>
									)}

								{!meeting.purchasedBoxcast && (
									<BroadcastPanel
										meeting={meeting}
										classes={classes}
										fullWidth={widthDownSm}
										editTimestamps={this.editTimestamps}
										openBroadcastDialog={this.openBroadcastDialog}
										removeBroadcast={this.handleRemoveBroadcast}
										showEditTimeStamp={showEditTimeStamp}
										errors={errors}
										currentYoutubeLink={currentYoutubeLink}
										savingMeeting={savingMeeting}
										handleFieldChange={this.handleFieldChange}
										testZoom={this.testZoom}
										testYouTube={this.testYouTube}
										matchYoutubeUrl={this.matchYoutubeUrl}
										handleSaveMeeting={this.handleSaveMeeting}
									/>
								)}

								<input
									id="upload-member-minutes-file"
									type="file"
									onChange={(e) => {
										this.handleMinutesUpload(e, true, false);
									}}
									style={{ display: "none" }}
									accept=".doc, .docx"
									title={t("tooltips.uploadMemberMinutes")}
								/>
								<input
									id="upload-public-minutes-file"
									type="file"
									onChange={(e) => {
										this.handleMinutesUpload(e, false, false);
									}}
									style={{ display: "none" }}
									accept=".doc, .docx"
									title={t("tooltips.uploadPublicMinutes")}
								/>
								<input
									id="upload-member-adopted-minutes-file"
									type="file"
									onChange={(e) => {
										this.handleMinutesUpload(e, true, true);
									}}
									style={{ display: "none" }}
									accept=".pdf"
									title={t("tooltips.uploadAdoptedMemberMinutes")}
								/>
								<input
									id="upload-public-adopted-minutes-file"
									type="file"
									onChange={(e) => {
										this.handleMinutesUpload(e, false, true);
									}}
									style={{ display: "none" }}
									accept=".pdf"
									title={t("tooltips.uploadAdoptedPublicMinutes")}
								/>
							</>
						) : (
							<Container maxWidth="lg">
								<Paper className={`padded contained text-center${widthDownSm ? " mobile" : ""}`}>
									<CircularProgressIndicator />
								</Paper>
							</Container>
						)}
					</ComponentContainer>
				</div>
			</>
		);
	}
}
MeetingDetail.contextType = SettingsContext;
export default withRouter(
	withTranslation("meetings")(withWidth()(withErrorHandling(connect(mapStateToProps)(withStyles(styles)(MeetingDetail))))),
);
