import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import request from "superagent";
import { styled } from "@mui/styles";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";

import { API_HOST } from "config/env";
import CircularProgressIndicator from "atlas/components/Progress/CircularProgressIndicator";
import { Close } from "components/Icons";
import OutlinedInput from "atlas/components/FormControls/OutlinedInput";
import { mapStateToProps } from "redux/app/mapStateToProps";
import GenericDialog from "atlas/components/Dialogs/GenericDialog";
import notifierMessage from "utils/notifierMessage";
import { setSnackbarOptions } from "../../redux/snackBar/actions";

class SupportRequestDialog extends Component {
	state = {
		feedback: "",
		urgency: "",
		errors: {},
		sendingSupportRequest: false,
	};

	handleSend = () => {
		const { feedback, urgency, errors } = this.state;
		const { t, dispatch } = this.props;

		this.validate();
		if (Object.keys(errors).length) return;

		this.setState({ sendingSupportRequest: true });
		request
			.post(`${API_HOST}/api/system/support`)
			.withCredentials()
			.send({
				feedback,
				urgency,
			})
			.then(() => {
				let option = notifierMessage(t("supportRequestDialog.successMessage"), "success");
				dispatch(setSnackbarOptions(option));
				this.setState({ sendingSupportRequest: false }, () => {
					this.handleCancel();
				});
			})
			.catch((err) => this.handleError(err));
	};

	handleCancel = () => {
		this.setState(
			{
				feedback: "",
				urgency: "",
				errors: {},
			},
			this.props.onClose,
		);
	};

	handleError = (err) => {
		console.log("Error", err);
		this.props.onClose();
	};

	handleFieldChange = (e) => {
		const { errors = {} } = this.state;
		const { name, value } = e.target;
		const { [name]: thisError, ...otherErrors } = errors; // clear error for this entry

		this.setState(
			{
				[name]: value,
				errors: otherErrors,
			},
			() => this.validate(name),
		);
	};

	validate = (singleField = null) => {
		const { t } = this.props;
		const { feedback, urgency } = this.state;
		let { errors } = this.state;

		let valid = true;

		if (!singleField || singleField === "feedback") {
			if (feedback.length === 0) {
				errors.feedback = t("supportRequestDialog.errors.feedback");
				valid = false;
			}
		}

		if (!singleField || singleField === "urgency") {
			if (urgency.length === 0) {
				errors.urgency = t("supportRequestDialog.errors.urgency");
				valid = false;
			}
		}

		this.setState({ errors });

		return valid;
	};

	render() {
		const { t, show } = this.props;
		const { feedback, urgency, errors, sendingSupportRequest } = this.state;
		const i18n = t("supportRequestDialog");

		const dialog = {
			title: i18n.title,
			issueTitle: i18n.issueTitle,
			issueLabel: i18n.issueLabel,
			urgencyTitle: i18n.urgencyTitle,
			urgencyResponses: i18n.urgencyResponses,
			primaryTitle: i18n.buttons.send,
			primaryAction: this.handleSend,
			primaryDisabled: !!(sendingSupportRequest || Object.keys(errors).length),
			primaryTooltip: errors.feedback || errors.urgency ? t("app:tooltips.fixErrors") : "",
			secondaryTitle: t("buttons.cancel"),
			secondaryAction: this.handleCancel,
		};

		const QuestionText = (props) => (
			<Typography variant="h4">
				<Box fontWeight="600">{props.text}</Box>
			</Typography>
		);

		const ErrorRadio = styled(Radio)({ color: "#d22c2c !important" });

		return (
			<GenericDialog
				show={show}
				title={dialog.title}
				primaryAction={dialog.primaryAction}
				primaryTitle={sendingSupportRequest ? <CircularProgressIndicator color="secondary" size={20} /> : dialog.primaryTitle}
				primaryDisabled={dialog.primaryDisabled}
				primaryTooltip={dialog.primaryTooltip}
				secondaryAction={dialog.secondaryAction}
				secondaryTitle={dialog.secondaryTitle}
				secondaryDisabled={sendingSupportRequest}
				clickAwayDisabled={sendingSupportRequest}
				closeIcon={<Close />}
				fullWidth
			>
				<QuestionText text={dialog.issueTitle} />

				<FormControl error={!!errors.feedback} fullWidth>
					<OutlinedInput
						id="feedback"
						name="feedback"
						autoFocus
						noDefaultClassName
						className="no-margin-top"
						multiline
						rows={5}
						fullWidth
						label={dialog.issueLabel}
						value={feedback}
						onChange={(e) => this.handleFieldChange(e)}
						error={!!errors.feedback}
					/>
					<FormHelperText>{errors.feedback}</FormHelperText>
				</FormControl>

				<Box mt={2} />

				<QuestionText text={dialog.urgencyTitle} />

				<FormControl error={!!errors.urgency}>
					<RadioGroup aria-label="urgency" id="urgency" name="urgency" value={urgency} onChange={(e) => this.handleFieldChange(e)}>
						{Object.entries(dialog.urgencyResponses).map((entry) => {
							const [value, label] = entry;
							return <FormControlLabel value={value} key={value} control={!!errors.urgency ? <ErrorRadio /> : <Radio />} label={label} />;
						})}
					</RadioGroup>
					<FormHelperText>{errors.urgency}</FormHelperText>
				</FormControl>
			</GenericDialog>
		);
	}
}

export default withTranslation()(connect(mapStateToProps)(SupportRequestDialog));
