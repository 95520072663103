import React from "react";
import clsx from "clsx";

import makeStyles from "@mui/styles/makeStyles";
import { Checkbox, TableSortLabel } from "@mui/material";

import { focusColor } from "atlas/assets/jss/shared";
import { DOCUMENT_TREE_GAP } from "./DocumentTreeItem";

const useStyles = makeStyles(() => ({
	header: {
		display: "flex",
		alignItems: "center",
		boxSizing: "border-box",
		width: "100%",
		paddingInline: "16px",
		gap: `${DOCUMENT_TREE_GAP}px`,
		backgroundColor: ({ inPortal, isPolicy }) => (inPortal ? "#6a6b6d" : isPolicy ? "#ebeef2" : undefined),
		"& *": {
			fontFamily: ({ inPortal }) => (inPortal ? "Segoe UI, Helvetica, Tahoma, Arial, Calibri, Verdana, sans-serif !important" : undefined),
			fontWeight: ({ inPortal }) => (inPortal ? "bold" : undefined),
		},
	},
	headerColumn: {
		display: "flex",
		alignItems: "center",
		height: "40px",
		color: ({ inPortal }) => (inPortal ? "#ffffff" : undefined),
		"& .MuiTableSortLabel-root.Mui-active": {
			color: ({ inPortal }) => (inPortal ? "#ffffff" : undefined),
			"& .MuiTableSortLabel-icon": {
				color: ({ inPortal }) => (inPortal ? "#ffffff !important" : "rgba(0, 0, 0, 0.8)"),
			},
		},
		"& .MuiTableSortLabel-root:not(.Mui-active)": {
			"&:hover, &:focus": {
				"& .MuiTableSortLabel-icon": {
					opacity: "1",
				},
			},
		},
		"& .MuiTableSortLabel-root:focus": {
			color: ({ inPortal }) => (!inPortal ? "rgba(0, 0, 0, 0.7)" : undefined),
		},
		"& div, & span": {
			fontWeight: ({ inPortal }) => (inPortal ? "bold" : "600 !important"),
			textTransform: ({ inPortal }) => (inPortal ? "uppercase" : undefined),
			fontSize: ({ inPortal }) => (inPortal ? "16.25px" : "16px !important"),
			lineHeight: "20px !important",
			whiteSpace: "nowrap",
		},
	},
	headerFill: {
		flexGrow: "1",
	},
	headerButton: {
		cursor: "pointer",
	},
	sortLabel: {
		"&:focus-visible": {
			outline: `solid 2px ${focusColor}`,
			borderRadius: "4px",
		},
		"&:hover, &:focus": {
			color: ({ inPortal }) => (inPortal ? "#eeeeee" : undefined),
		},
	},
	visuallyHidden: {
		border: 0,
		clip: "rect(0 0 0 0)",
		height: 1,
		margin: -1,
		overflow: "hidden",
		padding: 0,
		position: "absolute",
		top: 20,
		width: 1,
	},
}));

const DocumentListHeader = (props) => {
	const { inPortal, isPolicy, order, documents, columns } = props;
	const classes = useStyles({ inPortal, isPolicy });

	const allDocuments = documents
		? Object.values(documents)
				.flat()
				.filter((document) => document.guid)
		: [];
	const numSelected = allDocuments.filter((document) => document.selected).length;

	return documents?.root ? (
		<div className={classes.header} data-cy="document-list-header">
			{columns.map((column) => {
				const isButton = typeof column.action === "function";

				return (
					<div
						key={column.id}
						className={clsx(classes.headerColumn, {
							[classes.headerFill]: !Boolean(column.width),
							[classes.headerButton]: isButton,
						})}
						id={column.id}
						style={{ width: column.width }}
						data-cy={column.id}
					>
						{column.isSort ? (
							<TableSortLabel
								className={classes.sortLabel}
								active={order.field === column.id}
								direction={order.field === column.id && order.descending ? "desc" : "asc"}
								onClick={() => column.action(column.id)}
							>
								<span>{!inPortal ? column.label : column.label.toUpperCase()}</span>
								{order.field === column.id ? (
									<span className={classes.visuallyHidden} role="status" aria-live="polite">
										{order.descending ? "sorted descending" : "sorted ascending"}
									</span>
								) : null}
							</TableSortLabel>
						) : column.isSelect ? (
							<Checkbox
								color="primary"
								indeterminate={numSelected > 0 && numSelected < allDocuments.length}
								checked={allDocuments.length > 0 && numSelected === allDocuments.length}
								onChange={(e) =>
									column.action(
										documents.root.map((document) => document.guid),
										e.target.checked,
									)
								}
								inputProps={{ "aria-label": "select all" }}
								data-cy="select-all"
							/>
						) : (
							column.label
						)}
					</div>
				);
			})}
		</div>
	) : null;
};

export default DocumentListHeader;
