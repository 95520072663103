export const STATUS_NONE = 'none';
export const STATUS_DEFAULT = 'default';
export const STATUS_INFO = 'info';
export const STATUS_GOOD = 'good';
export const STATUS_WARNING = 'warning';
export const STATUS_ERROR = 'error';
export const STATUS_ADDEDTOAGENDA = 'addedToAgenda';
export const STATUS_PENDING =  'pending';
export const STATUS_INTERNAL = 'internal';
export const STATUS_ARCHIVED = 'archived';
export const STATUS_NOTSHARED = 'notShared';


const statusIndicators = {
	STATUS_NONE,
	STATUS_DEFAULT,
	STATUS_INFO,
	STATUS_GOOD,
	STATUS_WARNING,
	STATUS_ERROR,
	STATUS_ADDEDTOAGENDA,
	STATUS_PENDING,
	STATUS_INTERNAL,
	STATUS_ARCHIVED,
	STATUS_NOTSHARED,
};

export default statusIndicators;
