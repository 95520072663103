import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { withTranslation } from "react-i18next";
import clsx from "clsx";

import makeStyles from "@mui/styles/makeStyles";

import { addMilliseconds, differenceInMilliseconds, differenceInSeconds } from "date-fns";

import AccessibleIconButton from "atlas/components/Buttons/AccessibleIconButton";

import { defaultFont, primaryColor, whiteColor, errorColor } from "atlas/assets/jss/shared";
import { updateAppbarTools } from "redux/app/actions";
import formatTimer from "../utils/formatTimer";
import { useHotkeys } from "react-hotkeys-hook";

const useStyles = makeStyles(() => ({
	container: {
		display: "inline-flex",
		alignItems: "center",
		height: "40px",
		boxSizing: "border-box",
		border: (props) => `solid 1px ${props.overTime ? errorColor : whiteColor}`,
		borderRadius: "20px",
		backgroundColor: (props) => (props.overTime ? errorColor : primaryColor[0]),
		zIndex: "1",
	},
	display: {
		padding: "0 24px",
		...defaultFont,
		fontSize: "26px",
		lineHeight: "1.23",
		fontWeight: "600",
	},
	button: {
		padding: "4px",
		borderRadius: "16px",
		"&:focus": {
			backgroundColor: "#808080",
		},
	},
	closeButton: {
		margin: "0 8px",
	},
}));

const AdminTimer = (props) => {
	const { t, timer, meetingId, startTime: previousStartTime } = props;

	const calculateRemaining = (elapsed) => {
		const remaining = timer.minutes * 60 + timer.seconds - elapsed;

		return {
			minutes: remaining >= 0 ? Math.floor(remaining / 60) : Math.ceil(remaining / 60),
			seconds: remaining % 60,
		};
	};

	const [activeTimer, setActiveTimer] = useState(
		previousStartTime ? calculateRemaining(differenceInSeconds(new Date(), previousStartTime)) : { ...timer },
	);
	const [playing, setPlaying] = useState(false);
	const classes = useStyles({ overTime: activeTimer.minutes < 0 || activeTimer.seconds < 0 });
	const appReducer = useSelector((state) => state.appReducer);
	const {
		signalR: { client },
	} = appReducer;
	const dispatch = useDispatch();
	const playingRef = useRef(playing);
	const timerHandle = useRef();
	const startTime = useRef();
	const pauseTime = useRef();
	const hotKeyProps = { enableOnContentEditable: true, enableOnFormTags: ["input", "select", "textarea"] };

	const playPauseTimerShortCut = {
		keys: t("shortCutKeys.playPauseTimerShortCutKey"),
		props: hotKeyProps,
	};

	useEffect(() => {
		playingRef.current = playing;
		if (!playing) {
			document.getElementById("timer-start").focus();
		} else if (playing) {
			document.getElementById("timer-pause").focus();
		}
	}, [playing]);

	useHotkeys(
		playPauseTimerShortCut.keys,
		() => {
			if (!playingRef.current) {
				handleStart();
			} else if (playingRef.current) {
				handlePause();
			}
		},
		playPauseTimerShortCut.props,
	);

	const broadcastTimer = (updatedTimer, isPlaying, isCanceled) => {
		client.liveMeetingHub.updateTimer(
			meetingId,
			timer.minutes * 60 + timer.seconds,
			updatedTimer ? updatedTimer.minutes * 60 + updatedTimer.seconds : 0,
			isPlaying,
			isCanceled,
		);
	};

	const updateTimer = () => {
		timerHandle.current = setTimeout(() => {
			const elapsed = differenceInSeconds(new Date(), startTime.current);
			const updatedTimer = calculateRemaining(elapsed);

			setActiveTimer(updatedTimer);

			updateTimer();

			if (elapsed % 10 === 0) {
				// Syncronize other browsers every 10 seconds
				broadcastTimer(updatedTimer, true, false);
			}
		}, 1000);
	};

	const cancelTimer = (isPause) => {
		if (timerHandle.current > 0) {
			clearTimeout(timerHandle.current);
			timerHandle.current = 0;
		}

		broadcastTimer(null, false, !isPause);
	};

	const handleStart = (_, time) => {
		if (time) {
			startTime.current = time;
		} else if (!startTime.current) {
			startTime.current = new Date();
		} else if (pauseTime.current) {
			startTime.current = addMilliseconds(new Date(), -differenceInMilliseconds(pauseTime.current, startTime.current));
		}

		setPlaying(true);

		updateTimer();

		broadcastTimer(activeTimer, true, false);
	};

	const handlePause = () => {
		cancelTimer(true);

		setPlaying(false);

		pauseTime.current = new Date();
	};

	const handleStop = () => {
		cancelTimer();

		setPlaying(false);
		setActiveTimer({ ...timer });

		startTime.current = null;
		pauseTime.current = null;
	};

	const handleClose = () => {
		cancelTimer();

		startTime.current = null;
		pauseTime.current = null;

		dispatch(updateAppbarTools({ appbarTools: {} }));
	};

	useEffect(() => {
		return () => {
			if (startTime.current) {
				dispatch(
					updateAppbarTools({
						appbarTools: {
							timer: {
								timer,
								meetingId,
								startTime: startTime.current || undefined,
							},
						},
					}),
				);
			}

			cancelTimer(false);
		};
	}, []);

	useEffect(() => {
		handleStop();
		if (previousStartTime) {
			handleStart(undefined, previousStartTime);
		}
	}, [timer, meetingId, previousStartTime]);

	return (
		<div className={classes.container}>
			<div className={classes.display} data-cy="timer-display">
				{formatTimer(activeTimer)}
			</div>
			{!playing && (
				<AccessibleIconButton
					id="timer-start"
					className={classes.button}
					color="inherit"
					iconName="play"
					tooltipText={t("tooltips.timerStart")}
					aria-label={t("tooltips.timerStart")}
					onClick={handleStart}
					dataCy="timer-start"
					isHeader
				/>
			)}
			{playing && (
				<AccessibleIconButton
					id="timer-pause"
					className={classes.button}
					color="inherit"
					iconName="pause"
					tooltipText={t("tooltips.timerPause")}
					aria-label={t("tooltips.timerPause")}
					onClick={handlePause}
					dataCy="timer-pause"
					isHeader
				/>
			)}
			<AccessibleIconButton
				id="timer-stop"
				className={classes.button}
				color="inherit"
				iconName="stop"
				tooltipText={t("tooltips.timerStop")}
				aria-label={t("tooltips.timerStop")}
				onClick={handleStop}
				dataCy="timer-stop"
				isHeader
			/>
			<AccessibleIconButton
				id="timer-close"
				className={clsx(classes.button, classes.closeButton)}
				color="inherit"
				iconName="close"
				tooltipText={t("tooltips.timerClose")}
				aria-label={t("tooltips.timerClose")}
				onClick={handleClose}
				dataCy="timer-close"
				isHeader
			/>
		</div>
	);
};

AdminTimer.propTypes = {
	timer: PropTypes.object.isRequired,
	meetingId: PropTypes.number.isRequired,
	startTime: PropTypes.object,
};

AdminTimer.defaultProps = {
	startTime: undefined,
};

export default withTranslation("meetings")(AdminTimer);
