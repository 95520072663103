export const SHORTCUTKEY_DIALOG_HEADING = "Keyboard Shortcuts";
export const shortcutKeyBoardSectionHeading = [
	{
		headingKey: "Basic",
		headingValue: "Basics (Replace Ctrl with Cmd on Mac)",
	},
	{
		headingKey: "Live_Meeting_Controls",
		headingValue: "Live Meeting Controls",
	},
	{
		headingKey: "Text_Editor",
		headingValue: "Text Editor (Replace Ctrl with Cmd on Mac)",
	},
];

export const shortCutKeyMapping = {
	Basic: [
		{
			name: "Keyboard shortcuts",
			keyboardKeys: ["Ctrl", "/"],
		},
		{
			name: "Close dialog",
			keyboardKeys: ["Esc"],
		},
		{
			name: "Select next element",
			keyboardKeys: ["Tab"],
		},
		{
			name: "Select previous element",
			keyboardKeys: ["Shift", "Tab"],
		},
	],
	Live_Meeting_Controls: [
		{
			name: "Next agenda item",
			keyboardKeys: ["Alt", "Z"],
		},
		{
			name: "Previous agenda item",
			keyboardKeys: ["Alt", "A"],
		},
		{
			name: "Start / Stop Timer",
			keyboardKeys: ["Alt", "T"],
		},
		{
			name: "Open / close roll call",
			keyboardKeys: ["Alt", "C"],
		},
		{
			name: "Show results",
			keyboardKeys: ["Alt", "R"],
		},
	],

	Text_Editor: [
		{
			name: "Bold",
			keyboardKeys: ["Ctrl", "B"],
		},
		{
			name: "Italic",
			keyboardKeys: ["Ctrl", "I"],
		},
		{
			name: "Select All",
			keyboardKeys: ["Ctrl", "A"],
		},
		{
			name: "Redo",
			keyboardKeys: ["Ctrl", "Shift", "Z"],
		},
		{
			name: "Undo",
			keyboardKeys: ["Ctrl", "Z"],
		},
		{
			name: "Move focus to the toolbar (may require fn)",
			keyboardKeys: ["Alt", "F10"],
		},
	],
};
