import { blackColor } from '../shared';

const radioStyle = () => ({
	label: {
		whiteSpace: 'nowrap',
		fontSize: '14px !important',
		padding: '4px 0',
		lineHeight: '1.29',
		letterSpacing: '0.7px !important',
		verticalAlign: 'middle',
		display: 'inline-block',
		color: blackColor[1],
	},
});

export default radioStyle;
