import React, { useState, useEffect } from "react";

import Drawer from "@mui/material/Drawer";
import makeStyles from "@mui/styles/makeStyles";

import { useWidthUp } from "atlas/utils/useWidth";
import contentDrawerStyle from "assets/jss/components/contentDrawerStyle";
import { getContentSize } from "utils/contentSize";

const mdDownWidth = 270;
const lgUpWidth = 500;

const useStyles = makeStyles(contentDrawerStyle);

const ScratchpadContainer = (props) => {
	const { showScratchpad, toggleScratchpadDrawer, children } = props;
	const widthUpMd = useWidthUp("md");
	const widthUpLg = useWidthUp("lg");
	const [sizes, setSizes] = useState({});
	const { drawer, drawerInContentRight } = useStyles({
		sizes,
		drawerWidth: widthUpLg ? lgUpWidth : mdDownWidth,
	});

	useEffect(() => {
		setSizes(getContentSize());
	}, [showScratchpad, toggleScratchpadDrawer, widthUpMd, widthUpLg]);

	return (
		<>
			{!widthUpMd ? (
				<Drawer
					id="scratchpad"
					className={drawer}
					variant="temporary"
					anchor="right"
					open={showScratchpad}
					onClose={() => toggleScratchpadDrawer()}
					ModalProps={{
						keepMounted: true,
					}}
					SlideProps={{ timeout: 0 }}
				>
					{children}
				</Drawer>
			) : (
				<Drawer
					id="scratchpad"
					className={drawerInContentRight}
					anchor="right"
					variant="persistent"
					open={showScratchpad}
					SlideProps={{ timeout: 0 }}
				>
					{children}
				</Drawer>
			)}
		</>
	);
};

export default ScratchpadContainer;
