export default {
	title: "Subscribers",
	selectedPeople: "Selected people",
	availablePeople: "Available people",
	noSelectedUsers: "No selected people",
	sendVerification: "Send verification email",
	addExternalEmailAddresses: "Add external email addresses",
	meetingTypes: "Meeting templates",
	noEmailsAddedPlaceholder: "No email addresses added",
	addSubscribers: {
		title: "Add subscribers",
	},
	buttons: {
		addNewSubscription: "+ New subscription",
		active: "Active",
		inactive: "Inactive",
		sendConfirmation: "Send confirmation",
		close: "Close",
		create: "Create",
		creating: "Creating",
	},
	tooltips: {
		addNewSubscription: "Add a new subscription",
		active: "Active subscription. Click to deactivate",
		inactive: "Inactive subscription. Click to activate",
		sendConfirmation: "Inactive subscription. Send confirmation email to the subscriber",
		close: "Close",
		filter: "Filter the list",
		delete: "Delete subscription",
		addUser: "Add user",
		removeUser: "Remove user",
		create: "Create new subscription",
	},
	list: {
		header: {
			emailAddress: "Email address",
			meetingTypes: "Meeting templates",
			public: "Public",
			active: "Active",
		},
		cell: {
			yes: "Yes",
			no: "No",
			public: "Public",
		},
		notification: {
			deleteSuccess: "Subscription deleted successfully",
			confirmationSent: "Confirmation email sent successfully",
			activeSuccess: "Subscription activated successfully",
			inactiveSuccess: "Subscription deactivated successfully",
			restoreSuccess: "Subscription restored successfully",
		},
		noResults: {
			title: "No subscriptions",
			description: "We couldn't find any subscriptions",
		},
	},
	filter: {
		emailAddress: "Email address",
		meetingType: "Meeting template",
		public: "Public",
		active: "Active",
		yes: "Yes",
		no: "No",
	},
	create: {
		notification: {
			createSuccess: "Subscriptions created successfully",
			externalEmailError: "Email addresses not correctly formatted",
		},
	},
};
