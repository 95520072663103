import React from "react";
import SvgIcon from "@mui/material/SvgIcon";

import themeConfig from "../../atlas/assets/jss/theme";
import getOr from "lodash/fp/getOr";

const icon = (props) => {
	const { color } = props;
	const fillColor = color ? getOr(color, ["palette", color, "main"], themeConfig) : "currentColor";

	return (
		<SvgIcon {...props}>
			<defs>
				<path d="M7.33050009,5.33388824 L14.7693657,-2.56990651 C15.0905502,-2.91116504 15.074277,-3.44818122 14.7330185,-3.76936572 C14.3917599,-4.09055022 13.8547438,-4.07427701 13.5335593,-3.73301847 L5,5.33388824 L13.5335593,14.4007949 C13.8547438,14.7420535 14.3917599,14.7583267 14.7330185,14.4371422 C15.074277,14.1159577 15.0905502,13.5789415 14.7693657,13.237683 L7.33050009,5.33388824 Z" />
			</defs>
			<g id="caret-down" stroke="none" strokeWidth="1" fill="none" transform="rotate(180, 12, 12)" fillRule="evenodd">
				<g id="Icons/24px/Unselected/Caret/Down" transform="translate(2.000000, 7.000000)">
					<mask id="caret=down-mask-2" fill="white">
						<use xlinkHref="#caret-down-path-1" />
					</mask>
					<use
						fill={fillColor}
						fillRule="nonzero"
						transform="translate(10.000000, 5.333888) rotate(-90.000000) translate(-10.000000, -5.333888) "
						xlinkHref="#caret-down-path-1"
					/>
				</g>
			</g>
		</SvgIcon>
	);
};

export default icon;
