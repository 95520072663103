import { blackColor, grayColor, whiteColor, focusColor } from './shared';

const fontFamily = ['Source Sans Pro', 'Verdana', 'Helvetica', 'Arial', 'sans-serif'].join(',');

const menuItemFocusVisibleHover = {
	backgroundColor: 'rgba(0, 0, 0, 0.6)',
	color: whiteColor,
};

const themeConfig = {
	spacing: 8,
	palette: {
		primary: {
			main: '#5d7599',
			disabled: '#767676',
		},
		secondary: {
			main: '#76b1e2',
		},
		text: {
			main: '#000000',
			secondary: 'rgba(0, 0, 0, 0.6)',
		},
		grey: {
			600: '#767676',
		},
		error: {
			main: '#d22c2c',
		},
		disabled: {
			main: '#676767',
		},
	},
	breakpoints: {
		values: {
			xs: 0,
			sm: 600,
			md: 768,
			lg: 1024,
			xl: 1280,
		},
	},
	components: {
		MuiAppBar: {
			styleOverrides: {
				root: {
					margin: '0 0 0 0',
					maxWidth: '100%',
				},
				colorPrimary: {
					backgroundColor: '#2f3b4d',
				},
			},
		},
		MuiBadge: {
			styleOverrides: {
				colorSecondary: {
					backgroundColor: '#76b1e2',
				},
				badge: {
					height: '14px',
				},
				dot: {
					height: '12px',
					minWidth: '12px',
					top: '6px',
					right: '4px',
				},
			},
		},
		MuiButtonGroup: {
			styleOverrides: {
				contained: {
					boxShadow: 'none',
				},
			},
		},
		MuiButton: {
			styleOverrides: {
				root: {
					letterSpacing: '1.5px',
					fontSize: '12px',
					fontWeight: 'bold',
					lineHeight: '1.33',
					padding: '0 16px',
					marginRight: '8px',
					marginTop: '24px',
					borderRadius: '2px',
					height: '40px',
					alignItems: 'center',
					'&.padded-paper-top-margin': {
						marginTop: '9px',
					},
					'&.medium-top-margin': {
						marginTop: '16px',
					},
					'&.small-top-margin': {
						marginTop: '8px',
					},
					'&.no-right-margin': {
						marginRight: '0',
					},
					'&.has-tooltip': {
						marginTop: '0',
						marginRight: '0',
					},
				},
				contained: {
					'&.Mui-disabled': {
						color: 'rgba(255, 255, 255, 1.0)',
					},
					boxShadow: 'none',
				},
				containedPrimary: {
					'&:hover': {
						backgroundColor: '#455772',
					},
					'&:active': {
						backgroundColor: '#37465b',
					},
					'&:focus': {
						backgroundColor: '#455772',
						color: whiteColor,
						boxShadow: '0 2px 10px 0 rgba(0, 0, 0, 0.5)',
						'& .MuiTouchRipple-ripple.MuiTouchRipple-rippleVisible': {
							opacity: '0.15',
						},
					},
				},
				outlined: {
					boxShadow: 'none',
					padding: '0 16px',
				},
				outlinedPrimary: {
					color: '#385f99',
					'&:hover': {
						borderWidth: '2px',
						borderColor: '#455772',
						backgroundColor: '#f3f3f3',
					},
					'&:active': {
						borderColor: '#37465b',
						backgroundColor: '#e7e7e7',
					},
					'&:focus': {
						boxShadow: '0 2px 10px 0 rgba(0, 0, 0, 0.5)',
					},
					borderWidth: '2px',
					borderColor: '#385f99',
				},
				sizeSmall: {
					height: '36px',
				},
				sizeLarge: {
					height: '56px',
					textTransform: 'none',
					fontSize: '16px',
					letterSpacing: 'normal',
					fontWeight: 'normal',
				},
			},
		},
		MuiCard: {
			styleOverrides: {
				root: {
					padding: '24px',
				},
			},
		},
		MuiCardHeader: {
			styleOverrides: {
				root: {
					padding: '0',
				},
			},
		},
		MuiCheckbox: {
			styleOverrides: {
				root: {
					width: '42px', // 24px width + 9px padding x2
					height: '42px',
					'&.Mui-focusVisible': {
						outline: `2px solid ${focusColor}`,
						outlineOffset: '-2px',
						borderRadius: '50%',
					},
					'&:hover': {
						backgroundColor: 'transparent',
					},
				},
				colorSecondary: {
					'&$checked svg': {
						padding: '4px',
						width: '1em',
					},
				},
			},
		},
		MuiContainer: {
			styleOverrides: {
				root: {
					padding: '0 !important',
					'&.padded-bottom': {
						paddingBottom: '24px !important',
						'&.mobile': {
							paddingBottom: '16px !important',
						},
					},
					'&.padded-top': {
						paddingTop: '24px !important',
						'&.mobile': {
							paddingTop: '16px !important',
						},
					},
				},
				maxWidthXs: {
					'&.narrow-form': {
						maxWidth: '427px', // 395px + 16px padding * 2
						paddingLeft: '16px !important',
						paddingRight: '16px !important',
					},
				},
			},
		},
		MuiDialogActions: {
			styleOverrides: {
				root: {
					padding: '24px',
					paddingTop: '0',
					justifyContent: 'center',
				},
			},
		},
		MuiDialogContent: {
			styleOverrides: {
				root: {
					padding: '24px',
					paddingBottom: '1px', // Prevents overflow for some reason
					'&.login-card': {
						paddingBottom: '24px',
					},
				},
				dividers: {
					padding: '16px 24px',
					borderTop: '2px solid #e6e6e6',
					borderBottom: '2px solid #e6e6e6',
				},
			},
		},
		MuiDialogContentText: {
			styleOverrides: {
				root: {
					color: '#000',
				},
			},
		},
		MuiDialogTitle: {
			styleOverrides: {
				root: {
					padding: '16px 24px',
					textTransform: 'initial',
					letterSpacing: '0',
				},
			},
		},
		MuiDrawer: {
			styleOverrides: {
				paper: {
					padding: '16px',
					width: '265px',
					boxShadow: '0 1px 5px 0 rgba(0, 0, 0, 0.13), 0 2px 4px 0 rgba(0, 0, 0, 0.2)',
				},
				paperAnchorDockedLeft: {
					boxShadow: 'none',
					border: '0',
				},
			},
		},
		MuiFab: {
			styleOverrides: {
				root: {
					position: 'fixed',
					right: 16,
					bottom: 16,
					fontWeight: 'bold',
					fontSize: '16px',
					lineHeight: '1.13',
					letterSpacing: '1.25px',
					'@media (min-width:600px)': {
						right: 24,
						bottom: 24,
					},
				},
				extended: {
					padding: '0 20px',
				},
			},
		},
		MuiFormControl: {
			styleOverrides: {
				root: {
					fontFamily,
					marginTop: '16px',
					'&.card-textfield': {
						marginBottom: '0px',
					},
					'&.small-margin-top': {
						marginTop: '8px',
					},
					'&.no-margin-top': {
						marginTop: '0',
					},
					'&.overflow-helper-text': {
						'& .MuiFormHelperText-root': {
							whiteSpace: 'nowrap',
						},
					},
					'&:hover': {
						'& .MuiFormLabel-root:not(.Mui-disabled)': {
							color: '#000000',
						},
					},
				},
			},
		},
		MuiFormControlLabel: {
			styleOverrides: {
				label: {
					fontFamily,
					height: '16px',
					fontSize: '14px',
					lineHeight: '1.14',
					fontWeight: 'normal',
					marginLeft: '8px',
				},
			},
		},
		MuiFormHelperText: {
			styleOverrides: {
				root: {
					color: '#000',
				},
				contained: {
					marginTop: '4px',
				},
			},
		},
		MuiFormLabel: {
			styleOverrides: {
				root: {
					color: '#767676',
					'&$focused': {
						color: '#000000',
					},
				},
			},
		},
		MuiGrid: {
			styleOverrides: {
				root: {
					'&.community-logo': {
						padding: '16px 24px',
					},
				},
			},
		},
		MuiIconButton: {
			styleOverrides: {
				root: {
					color: 'inherit !important',
					'&.align-with-icon': {
						margin: '-16px',
					},
					'&.filter-button': {
						padding: '12px 0',
						borderRadius: '0',
						minHeight: '42px',
						'&:hover': {
							backgroundColor: 'rgba(0, 0, 0, 0.04)',
						},
					},
				},
			},
		},
		MuiInputBase: {
			styleOverrides: {
				root: {
					backgroundColor: '#FFFFFF',
					borderRadius: '3px',
					border: 0,
				},
			},
		},
		MuiInputLabel: {
			styleOverrides: {
				outlined: {
					'&$disabled': {
						color: '#767676',
					},
					'&$error': {
						color: '#d22c2c !important',
					},
				},
			},
		},
		MuiList: {
			styleOverrides: {
				root: {
					paddingTop: '0',
					paddingBottom: '0',
					'.overflow-menu &': {
						paddingTop: '0',
						paddingBottom: '0',
					},
				},
			},
		},
		MuiListItem: {
			styleOverrides: {
				root: {
					paddingTop: '12px',
					paddingBottom: '12px',
					borderLeft: '6px solid transparent', // need to match width of selected to keep consistent placement
					'&$selected': {
						backgroundColor: 'rgba(0, 0, 0, 0.08)',
						borderLeft: '6px solid #5d7599 ',
					},
				},
			},
		},
		MuiListItemIcon: {
			styleOverrides: {
				root: {
					marginRight: '0px',
				},
			},
		},
		MuiMenu: {
			styleOverrides: {
				root: {
					'& .MuiPaper-root': {
						margin: '0',
					},
				},
			},
		},
		MuiMenuItem: {
			styleOverrides: {
				root: {
					fontSize: '16px',
					lineHeight: '1.5',
					minHeight: '36px',
					'& svg.external': {
						marginLeft: '8px',
					},
					'.overflow-menu &': {
						height: '16px !important',
						fontSize: '14px !important',
						lineHeight: '1.14 !important',
						color: '#000000',
						minHeight: '40px',
					},
					'&.Mui-focusVisible': {
						...menuItemFocusVisibleHover,
					},
					'&:hover': {
						...menuItemFocusVisibleHover,
					},
					'&.Mui-selected': {
						backgroundColor: 'rgba(93, 117, 153)',
						color: whiteColor,
						'&:hover': {
							backgroundColor: 'rgba(93, 117, 153, 0.9)',
						},
						'&.Mui-focusVisible': {
							backgroundColor: 'rgba(0, 0, 0, 0.6)',
						},
					},
				},
			},
		},
		MuiOutlinedInput: {
			styleOverrides: {
				root: {
					'&.Mui-focused': {
						boxShadow: '0 2px 10px 0 rgba(0, 0, 0, 0.5)',
						'&$error': {
							'& $notchedOutline': {
								borderColor: '#d22c2c',
							},
						},
						'& $notchedOutline': {
							borderColor: '#000000',
						},
					},
					'&$disabled': {
						color: '#767676',
						borderColor: '#e6e6e6',
						'& $notchedOutline': {
							borderColor: '#e6e6e6',
						},
						'& svg': {
							color: '#e6e6e6',
							fill: '#e6e6e6',
						},
					},
				},
				notchedOutline: {
					color: grayColor[0],
					borderColor: grayColor[0],
					borderWidth: '2px',
				},
				multiline: {
					height: 'auto !important',
				},
			},
		},
		MuiPaper: {
			styleOverrides: {
				root: {
					color: '#000',
					margin: '24px auto',
					maxWidth: '960px',
					'&.padded': {
						padding: '24px',
					},
					'&.contained': {
						margin: '24px',
						'&.mobile': {
							margin: '16px',
						},
					},
				},
				elevation1: {
					boxShadow: '0 2px 10px 0 rgba(0, 0, 0, 0.1)',
				},
			},
		},
		MuiListItemSecondaryAction: {
			styleOverrides: {
				root: {
					cursor: 'pointer',
				},
			},
		},
		MuiSelect: {
			styleOverrides: {
				icon: {
					top: 'calc(50% - .5em - 2px)',
					color: 'inherit !important',
					fill: 'currentColor',
				},
				iconOpen: {
					top: 'calc(50% - .5em - 10px)',
				},
			},
		},
		MuiSvgIcon: {
			styleOverrides: {
				fontSizeSmall: {
					verticalAlign: 'sub',
					width: '16px',
					fontSize: '16px',
				},
			},
		},
		MuiSwitch: {
			styleOverrides: {
				thumb: {
					backgroundColor: blackColor[0],
				},
			},
		},
		MuiTabs: {
			styleOverrides: {
				root: {
					minHeight: '42px',
				},
				indicator: {
					backgroundColor: '#ffffff',
					height: '2px',
				},
			},
		},
		MuiTextField: {
			styleOverrides: {
				root: {
					'& .MuiInputBase-adornedEnd': {
						paddingRight: '0',
					},
				},
			},
		},
		MuiTooltip: {
			styleOverrides: {
				tooltip: {
					fontSize: '14px',
				},
				tooltipPlacementLeft: { backgroundColor: grayColor[0] },
				tooltipPlacementRight: { backgroundColor: grayColor[0] },
				tooltipPlacementTop: { backgroundColor: grayColor[0] },
				tooltipPlacementBottom: { backgroundColor: grayColor[0] },
				popper: {
					zIndex: 9001,
				},
			},
		},
		MuiTypography: {
			styleOverrides: {
				root: {
					'&.grey': {
						color: '#767676',
					},
				},
			},
		},
	},
	overrides: {},
	typography: {
		useNextVariants: true,
		fontFamily,
		h1: {
			fontSize: '64px',
			lineHeight: 1.13,
		},
		h2: {
			fontSize: '40px',
			lineHeight: 1.2,
			fontWeight: 'normal',
		},
		h3: {
			fontSize: '24px',
			lineHeight: 1.33,
		},
		h4: {
			fontSize: '18px',
			lineHeight: 1.33,
		},
		h5: {
			fontSize: '16px',
			lineHeight: 1.5,
		},
		h6: {
			fontSize: '14px',
			fontWeight: 'bold',
			lineHeight: 1.14,
			letterSpacing: '1px',
			textTransform: 'uppercase',
		},
		body1: {
			fontSize: '16px',
			lineHeight: 1.5,
		},
		body2: {
			fontSize: '14px',
			lineHeight: 1.14,
		},
		subtitle1: {
			fontSize: '18px',
			lineHeight: 1.33,
		},
		subtitle2: {
			color: 'rgba(0, 0, 0, 0.6)',
			fontSize: '14.6px',
			lineHeight: 1.65,
			letterSpacing: '0.1px',
		},
		button: {
			fontSize: '14.6px',
			lineHeight: 1.1,
			letterSpacing: '1.25px',
		},
		caption: {
			fontSize: '12px',
			lineHeight: 1.33,
		},
		overline: {
			color: 'rgba(0, 0, 0, 0.6)',
			fontSize: '12.5px',
			lineHeight: 1.28,
			letterSpacing: '2px',
		},
	},
};

export default themeConfig;
