import React from "react";
import { Routes, Route } from "react-router-dom";

import PublicPolicyModule from "views/Policy/PublicPolicyModule";

const PublicPolicyRoutes = (props) => {
	// Base path = /policies/policybook/
	return (
		<Routes>
			<Route path="/" element={<PublicPolicyModule />} />
			<Route path=":id" element={<PublicPolicyModule />} />
		</Routes>
	);
};

export default PublicPolicyRoutes;
