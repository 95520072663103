import React from "react";
import SvgIcon from "@mui/material/SvgIcon";

import themeConfig from "../../atlas/assets/jss/theme";
import getOr from "lodash/fp/getOr";

const icon = (props) => {
	const { color } = props;
	const fillColor = color ? getOr(color, ["palette", color, "main"], themeConfig) : "currentColor";

	return (
		<SvgIcon {...props}>
			<defs>
				<path
					d="M15.8416681,14.7804626 L22.2803525,21.2196924 C22.5732334,21.512598 22.5732133,21.9874717 22.2803076,22.2803525 C21.987402,22.5732334 21.5125283,22.5732133 21.2196475,22.2803076 L14.7810565,15.8411714 C13.3234316,17.06031 11.4459402,17.7941176 9.39705882,17.7941176 C4.75949129,17.7941176 1,14.0346264 1,9.39705882 C1,4.75949129 4.75949129,1 9.39705882,1 C14.0346264,1 17.7941176,4.75949129 17.7941176,9.39705882 C17.7941176,11.445662 17.0605093,13.3229217 15.8416681,14.7804626 Z M9.39705882,16.2941176 C13.2061992,16.2941176 16.2941176,13.2061992 16.2941176,9.39705882 C16.2941176,5.58791842 13.2061992,2.5 9.39705882,2.5 C5.58791842,2.5 2.5,5.58791842 2.5,9.39705882 C2.5,13.2061992 5.58791842,16.2941176 9.39705882,16.2941176 Z"
					id="search-path-1"
				/>
			</defs>
			<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g transform="translate(-418.000000, -119.000000)">
					<g transform="translate(417.000000, 118.000000)">
						<mask id="search-mask-2" fill="white">
							<use xlinkHref="#search-path-1" />
						</mask>
						<use fill="#6D717A" fillRule="nonzero" xlinkHref="#search-path-1" />
						<g mask="url(#search-mask-2)" fill={fillColor} fillRule="evenodd">
							<rect x="0" y="0" width="24" height="24" />
						</g>
					</g>
				</g>
			</g>
		</SvgIcon>
	);
};
export default icon;
