import { produce } from "immer";
import { updateAgenda, findItemByID, checkAttachments } from "./utils";
import { isEmpty } from "utils/html";

export function addText(guid) {
	const currentState = this.state;

	const nextState = produce(
		currentState,
		(draft) => {
			const item = findItemByID(guid, draft.items);
			if (!item) return;
			item.fields.Text.Value = item.fields.Text.Value || "<p></p>";
		},
		(patches, inversePatches) => {
			if (patches.length) {
				this.redoStack.push([...patches]);
				this.undoStack.push([...inversePatches]);
			}
		},
	);

	updateAgenda(this, { items: nextState.items }, `${guid}-text`, undefined, true);
	this.itemIdsToUpdate.push(guid);
}

export function updateText(guid, data, cancelNextRender, lastUpdateWasEmpty = false, field = "Text") {
	const currentState = this.state;
	let cancelChanges = false;
	let cancelDirty = false;
	const nextState = produce(
		currentState,
		(draft) => {
			const parentItem = findItemByID(guid, draft.items);
			const textWasEmpty = isEmpty(parentItem ? parentItem.fields[field].Value : "");
			const textWillBeEmpty = isEmpty(data);

			if (lastUpdateWasEmpty) {
				parentItem.fields[field].Value = null;
				if (textWasEmpty) {
					cancelDirty = true; // No actual changes
				}
				checkAttachments(parentItem);
			} else if (
				(parentItem && parentItem.fields[field].Value != null && parentItem.fields[field].Value !== data) ||
				textWasEmpty !== textWillBeEmpty
			) {
				parentItem.fields[field].Value = textWillBeEmpty ? null : data;
				if (textWasEmpty && textWillBeEmpty) {
					cancelDirty = true; // No actual changes
				}
				checkAttachments(parentItem);
			} else {
				cancelChanges = true;
			}
		},
		(patches, inversePatches) => {
			if (patches.length) {
				if (patches.length === 1) {
					const firstPatch =
						patches[0].value && patches[0].value.includes("<br />") ? patches[0].value.split("<br />").join("<br>") : patches[0].value;
					const firstInversePatches =
						inversePatches[0].value && inversePatches[0].value.includes("<br />")
							? inversePatches[0].value.split("<br />").join("<br>")
							: inversePatches[0].value;
					if (firstPatch !== firstInversePatches) {
						this.redoStack.push([...patches]);
						this.undoStack.push([...inversePatches]);
					}
				} else {
					this.redoStack.push([...patches]);
					this.undoStack.push([...inversePatches]);
				}
			}
		},
	);

	if (!cancelChanges) {
		this.itemIdsToUpdate.push(guid);
		updateAgenda(this, { items: nextState.items }, null, cancelNextRender, cancelDirty);
	}
}

export function removeText(guid) {
	const currentState = this.state;
	const nextState = produce(
		currentState,
		(draft) => {
			const parentItem = findItemByID(guid, draft.items);
			parentItem.fields.Text.Value = null;
			checkAttachments(parentItem);
		},
		(patches, inversePatches) => {
			if (patches.length) {
				this.redoStack.push([...patches]);
				this.undoStack.push([...inversePatches]);
			}
		},
	);
	this.itemIdsToUpdate.push(guid);
	updateAgenda(this, { items: nextState.items }, guid);
}
