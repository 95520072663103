import React from 'react';
import PropTypes from 'prop-types';

import makeStyles from '@mui/styles/makeStyles';
import { IconButton } from '@mui/material';

import { buttonStyle, pageHeaderButtonStyle, touchRippleStyle } from '../../assets/jss/components/headerIconButtonStyle';

const useStyles = makeStyles(touchRippleStyle);
const useButtonStyles = makeStyles(buttonStyle);
const usePageHeaderButtonStyle = makeStyles(pageHeaderButtonStyle);

const HeaderIconButton = (props) => {
	const { isPageHeader, disabled, disableRipple, dataCy, children, ...otherProps } = props;
	const rippleClasses = useStyles();
	const buttonClasses = isPageHeader ? usePageHeaderButtonStyle() : useButtonStyles();
	return (
        <IconButton
            classes={buttonClasses}
            TouchRippleProps={{ classes: rippleClasses }}
            data-cy={dataCy}
            disabled={disabled}
            disableRipple={disableRipple}
            {...otherProps}
            size="large">
			{children}
		</IconButton>
    );
};

HeaderIconButton.propTypes = {
	isPageHeader: PropTypes.bool,
	disabled: PropTypes.bool,
	disableRipple: PropTypes.bool,
	dataCy: PropTypes.string,
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

HeaderIconButton.defaultProps = {
	isPageHeader: false,
	disabled: false,
	disableRipple: false,
	dataCy: undefined,
};

export default HeaderIconButton;
