import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import Parser, { domToReact } from 'html-react-parser';

import makeStyles from '@mui/styles/makeStyles';

import AccessibleIconButton from '../Buttons/AccessibleIconButton';
import NonModalMenu from '../Menu/NonModalMenu';

import typographyStyle from '../../assets/jss/components/typographyStyle';
import menuStyle from '../../assets/jss/components/menuStyle';
import { grayColor } from '../../assets/jss/shared';

const labelStyle = {
	...typographyStyle.text,
	height: `${typographyStyle.text.lineHeight}em`,
	textOverflow: 'ellipsis',
	overflow: 'hidden',
	whiteSpace: 'nowrap',
	'& > p:first-child': {
		marginTop: '0',
	},
	'& > p:last-child': {
		marginBottom: '0',
	},
	'& p': {
		display: 'inline',
	},
};

const useStyles = makeStyles({
	listItem: {
		listStyleType: 'none',
		height: '64px',
		padding: (props) => (props.useOverflowMenu ? '20px 12px 20px 32px' : '20px 24px 20px 32px'),
		margin: '0',
		boxSizing: 'border-box',
		display: 'flex',
		alignItems: 'center',
		borderBottom: `solid 1px ${grayColor[4]}`,
		'&:last-child': {
			borderBottom: 'none',
		},
	},
	label: {
		...labelStyle,
	},
	labelLink: {
		...labelStyle,
		...typographyStyle.link,
	},
	menu: {
		...menuStyle.menu,
	},
});

const ListItem = (props) => {
	const { className, classes: optionalClasses, label, labelUrl, menuOptions } = props;
	const { t } = useTranslation();
	const [anchor, setAnchor] = useState(null);
	const classes = useStyles({ useOverflowMenu: menuOptions && menuOptions.length > 0 });

	const parserOptions = {
		replace: (node) => {
			if (!node.attribs) {
				return;
			}

			if (['img', 'br'].includes(node.name) && node.attribs.style) {
				node.attribs.style = '';
			}

			if (node.name === 'br') {
				// eslint-disable-next-line consistent-return
				return React.createElement('span', {}, ' ');
			}

			if (node.attribs.style === 'margin-top: 0; margin-bottom: 0;') {
				// eslint-disable-next-line consistent-return
				return <node.name>{domToReact(node.children, parserOptions)}</node.name>;
			}
		},
	};

	const getLabelText = () => Parser(label, parserOptions);

	const getLabel = () => {
		if (labelUrl) {
			return (
				<a className={clsx(classes.labelLink, optionalClasses.labelLink)} href={labelUrl}>
					{getLabelText()}
				</a>
			);
		}

		return <span className={clsx(classes.label, optionalClasses.label)}>{getLabelText()}</span>;
	};

	const handleToggleOverflowMenu = (e) => {
		setAnchor(e.currentTarget);
	};

	const handleCloseOverflowMenu = () => {
		setAnchor(null);
	};

	return (
		<li className={clsx(classes.listItem, className)}>
			{getLabel()}
			{menuOptions && menuOptions.length > 0 && (
				<>
					<AccessibleIconButton
						color="inherit"
						aria-label={t('app:menu.options')}
						onClick={(e) => handleToggleOverflowMenu(e)}
						iconName="more"
						dataCy="listItemOverflowMenuButton"
						tooltipText={t('app:menu.options')}
					/>
					{anchor && (
						<NonModalMenu
							id="pageHeaderOverflowMenu"
							className={classes.menu}
							anchorEl={anchor}
							keepMounted
							open={!!anchor}
							onClose={handleCloseOverflowMenu}
							options={menuOptions}
							position="bottom-end"
						/>
					)}
				</>
			)}
		</li>
	);
};

ListItem.propTypes = {
	className: PropTypes.string,
	classes: PropTypes.object,
	label: PropTypes.string.isRequired,
	labelUrl: PropTypes.string,
	menuOptions: PropTypes.arrayOf(PropTypes.object),
};

ListItem.defaultProps = {
	className: undefined,
	classes: {},
	labelUrl: undefined,
	menuOptions: undefined,
};

export default ListItem;
