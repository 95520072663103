import React from "react";
import { useTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";
import clsx from "clsx";

import makeStyles from "@mui/styles/makeStyles";

import CircularProgressIndicator from "atlas/components/Progress/CircularProgressIndicator";
import TableData from "atlas/components/TableData/TableData";
import IconButtonCell from "atlas/components/TableData/IconButtonCell";
import { blackColor } from "atlas/assets/jss/shared";
import { FOLDER_TYPE_CUSTOM } from "utils/enums/DocumentFolderTypes";
import LinkCell from "components/TableData/LinkCell";

const useStyles = makeStyles(() => ({
	headCell: {
		display: "none",
	},
	activeHead: {
		textAlign: "center",
	},
	noResults: {
		padding: "16px",
	},
	mobileTable: {
		"& tbody": {
			"& td": {
				borderColor: "transparent",
			},
		},
	},
}));

const CustomFolderList = (props) => {
	const { folders, handleSort, handleDelete } = props;
	const { t } = useTranslation("documents");
	const classes = useStyles();

	const tableHeadings = [
		{
			id: "title",
			disablePadding: false,
			label: t("customFolderList.header.name"),
			retainCase: true,
			rowColor: blackColor[1],
			navigateUrl: (row) => `/documents/customfolder/${row.guid}`,
			component: LinkCell,
		},
		{
			id: "delete",
			disablePadding: false,
			label: "",
			icon: "remove",
			tooltip: t("tooltips.deleteCustomFolder"),
			handleAction: handleDelete,
			rowColor: blackColor[1],
			width: "76px",
			showButton: (folder) => folder.folderType === FOLDER_TYPE_CUSTOM,
			component: IconButtonCell,
		},
	];

	return folders ? (
		<>
			<div className={clsx({ [classes.mobileTable]: isMobile })}>
				<TableData
					withoutMultiSelect
					showToolbar={false}
					headCells={tableHeadings}
					rows={folders}
					noDataLabels={t("customFolderList.noResults.description")}
					defaultOrderBy="default"
					sortAction={handleSort}
					useExternalSort
					overrideClasses={classes}
					data-cy="custom-folder-list"
				/>
			</div>
		</>
	) : (
		<CircularProgressIndicator />
	);
};

export default CustomFolderList;
