import ButtonView from "@ckeditor/ckeditor5-ui/src/button/buttonview";

class AttachFilePlugin {
	constructor(editor) {
		this.editor = editor;
	}

	init() {
		const { editor } = this;
		editor.ui.componentFactory.add("attachFile", (locale) => {
			const view = new ButtonView(locale);
			view.set({
				label: "Attach File",
				icon: `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="21px" height="24px" viewBox="0 0 21 24" version="1.1"><path d="M15,15.2 L15,8.8 C15,6.92223185 13.4777681,5.4 11.6,5.4 C9.72223185,5.4 8.2,6.92223185 8.2,8.8 L8.2,20 C8.2,22.7614237 10.4385763,25 13.2,25 C14.5260824,25 15.797852,24.4732158 16.7355339,23.5355339 C17.6732158,22.597852 18.2,21.3260824 18.2,20 L18.2,5.6 C18.2,1.95492065 15.2450793,-1 11.6,-1 C7.95492065,-1 5,1.95492065 5,5.6 L5,15.2 C5,15.7522847 5.44771525,16.2 6,16.2 C6.55228475,16.2 7,15.7522847 7,15.2 L7,5.6 C7,3.05949015 9.05949015,1 11.6,1 C14.1405098,1 16.2,3.05949015 16.2,5.6 L16.2,20 C16.2,20.7956495 15.8839295,21.5587112 15.3213203,22.1213203 C14.7587112,22.6839295 13.9956495,23 13.2,23 C11.5431458,23 10.2,21.6568542 10.2,20 L10.2,8.8 C10.2,8.02680135 10.8268014,7.4 11.6,7.4 C12.3731986,7.4 13,8.02680135 13,8.8 L13,15.2 C13,15.7522847 13.4477153,16.2 14,16.2 C14.5522847,16.2 15,15.7522847 15,15.2 Z" transform="translate(11.600000, 12.000000) rotate(-315.000000) translate(-11.600000, -12.000000) "/></svg>`,
				tooltip: true,
			});
			view.on("execute", () => {
				const { functions } = editor.community;
				const fileUpload = document.createElement("input");
				fileUpload.type = "file";
				fileUpload.multiple = true;
				fileUpload.accept = ".bmp, .jpg, .jpeg, .png, .gif, .pdf, .doc, .docx, .ppt, .pptx,.wopitest,.wopitestx";
				fileUpload.addEventListener("change", (e) => {
					functions.addFile(e.target.files);
				});

				fileUpload.click();
			});
			return view;
		});
	}
}

export default AttachFilePlugin;
