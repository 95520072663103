import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import parse from "html-react-parser";

import { Dialog, DialogActions, DialogContent, DialogTitle, Typography, Chip, Grid, IconButton } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { useWidthDown } from "atlas/utils/useWidth";
import { MEDIUM } from "atlas/utils/buttonSize";
import ButtonWithTooltip from "atlas/components/Buttons/ButtonWithTooltip";
import Icon from "atlas/components/Icon/Icon";
import NoticeCard from "atlas/components/Cards/NoticeCard";
import Tooltip from "atlas/components/Tooltip/Tooltip";
import { primaryColor, successColor, errorColor, warningColor } from "atlas/assets/jss/shared";
import { STATUS_WARNING, STATUS_GOOD, STATUS_ERROR } from "atlas/assets/jss/utils/statusIndicators";
import { updateNotice } from "redux/app/actions";
import processHtml from "utils/processHtml";
import { TYPE_FOR, TYPE_AGAINST, TYPE_ABSTAIN } from "views/LiveMeeting/utils/voteType";
import checkVotingFinished, { getVotingResults } from "views/LiveMeeting/utils/votingUtils";

const useStyles = makeStyles(() => ({
	dialog: {
		pointerEvents: "none",
	},
	dialogTitle: {
		display: "inline-flex",
		textTransform: "none",
	},
	leftDialogBorder: {
		borderLeft: `8px solid ${primaryColor[0]}`,
	},
	actions: {
		justifyContent: "center",
		paddingTop: "16px",
		paddingBottom: "16px",
		pointerEvents: "auto",
	},
	chip: {
		color: "#fff",
		borderRadius: "4px",
		float: "right",
	},
	votePassed: {
		backgroundColor: successColor,
	},
	voteFailed: {
		backgroundColor: errorColor,
	},
	colorInherit: {
		color: "inherit",
	},
	quorumNotMet: {
		backgroundColor: warningColor,
		color: "#000",
	},
	voteResults: {
		margin: "16px 0",
		"& > div": {
			marginTop: "8px",
		},
	},
	resultsLabel: {
		fontWeight: "bold",
	},
	noticeCard: {
		margin: "16px 0",
		pointerEvents: "auto",
	},
	itemText: {
		"& > p": {
			margin: "0",
		},
		"& > p:first-child": {
			display: "inline-block",
		},
	},
	closeIcon: {
		margin: "-12px",
		pointerEvents: "auto",
	},
}));

const VotingDialog = (props) => {
	const { show, userId, votingData, rollCall, votingSettings = { votingLabels: {} }, handleVoteClick, votingDialogClose } = props;
	const widthDownSm = useWidthDown("sm");
	const { t } = useTranslation("meetings");
	const [votingResults, setVotingResults] = useState(null);
	const [votingFinished, setVotingFinished] = useState(false);
	const [currentUserVote, setCurrentUserVote] = useState("");
	const [voteError, setVoteError] = useState(false);

	const classes = useStyles();

	const voteCallback = (res) => {
		if (res == null || res.status != 200 || !res.body) {
			setVoteError(true);
		} else {
			setTimeout(() => {
				votingDialogClose();
			}, 2500);
		}
	};

	useEffect(() => {
		if (votingData) {
			const results = getVotingResults({ fields: { Voting: { Value: votingData.itemVotingData } } }, rollCall);
			const tieBreaker =
				rollCall && rollCall.users.length > 0 ? rollCall.users.filter((user) => user.tieBreaker && user.id === userId) : null;
			setVotingResults(results);
			if ((results && !results.tie) || !tieBreaker) {
				setVotingFinished(
					votingData.itemVotingData && votingData.itemVotingData.length > 0
						? checkVotingFinished({ fields: { Voting: { Value: votingData.itemVotingData } } }, rollCall)
						: votingData.showResults
						? true
						: false,
				);
			}

			if (votingData.itemVotingData) {
				const currentUserData = votingData.itemVotingData.find((voteData) => voteData.UserId === userId);
				if (currentUserData) {
					let userVoteLabel = "";
					switch (currentUserData.Vote) {
						case TYPE_FOR:
							userVoteLabel = votingSettings.votingLabels.for || t("voting.for");
							break;
						case TYPE_AGAINST:
							userVoteLabel = votingSettings.votingLabels.against || t("voting.against");
							break;
						case TYPE_ABSTAIN:
							userVoteLabel = votingSettings.votingLabels.abstain || t("voting.abstain");
							break;
					}
					setCurrentUserVote(userVoteLabel);
				}
			}
		}
	}, [votingData]);

	return (
		show &&
		votingData && (
			<>
				<Dialog
					className={classes.dialog}
					maxWidth="sm"
					fullWidth
					open={show}
					fullScreen={widthDownSm}
					data-cy="voting-dialog"
					BackdropProps={{ invisible: true }}
					PaperProps={{ className: clsx({ [classes.leftDialogBorder]: votingResults && votingFinished }) }}
				>
					<DialogTitle>
						<Grid container spacing={0} direction="row" justifyContent="flex-start">
							<Grid item xs={11}>
								<Typography variant="h4" className={classes.dialogTitle}>
									{votingResults && votingFinished
										? t("votingDialog.resultsTitle")
										: t(
												`votingDialog.${
													votingResults &&
													votingResults.tie &&
													rollCall &&
													rollCall.users.length > 0 &&
													rollCall.users.filter((user) => user.tieBreaker && user.id === userId) != null
														? "tieBreakerTitle"
														: "title"
												}`,
										  )}
								</Typography>
								{votingResults && votingFinished && (
									<Chip
										size="small"
										className={clsx(classes.chip, {
											[classes.votePassed]:
												votingResults.quorumMet &&
												votingResults.votePassed &&
												votingData.itemVotingData &&
												votingData.itemVotingData.length > 0,
											[classes.voteFailed]:
												votingResults.quorumMet &&
												!votingResults.votePassed &&
												votingData.itemVotingData &&
												votingData.itemVotingData.length > 0,
											[classes.quorumNotMet]: !votingResults.quorumMet,
											[classes.colorInherit]:
												votingData.showResults && (votingData.itemVotingData == null || votingData.itemVotingData.length === 0),
										})}
										label={votingData.disposition}
										data-cy={`chip-disposition`}
									/>
								)}
							</Grid>
							<Grid item style={{ textAlign: "right" }} xs={1}>
								<Tooltip PopperProps={{ disablePortal: true }} title={t("app:tooltips.closeButton")}>
									<IconButton
										className={classes.closeIcon}
										aria-label={t("app:tooltips.closeButton")}
										onClick={votingDialogClose}
										data-cy="closeIcon"
										size="large"
									>
										{<Icon name="close" />}
									</IconButton>
								</Tooltip>
							</Grid>
						</Grid>
					</DialogTitle>
					<DialogContent>
						<div>
							<div className={classes.itemText}>{parse(votingData.agendaItemText || "")}</div>
							<div className={classes.itemText}>
								{`${t("motions.panel.title")}: `}
								{parse(votingData.itemText || "")}
							</div>
						</div>
						{votingResults && votingFinished && votingData.itemVotingData && votingData.itemVotingData.length > 0 && (
							<div className={classes.voteResults}>
								<div>
									<span className={classes.resultsLabel}>{`${votingResults.forVotesCount} votes ${
										votingSettings.votingLabels.for || t("voting.for")
									}: `}</span>
									{votingResults.forVotes.map((vote, index) => {
										return `${index > 0 ? ", " : ""}${vote.Name}`;
									})}
								</div>
								<div>
									<span className={classes.resultsLabel}>{`${votingResults.againstVotesCount} votes ${
										votingSettings.votingLabels.against || t("voting.against")
									}: `}</span>
									{votingResults.againstVotes.map((vote, index) => {
										return `${index > 0 ? ", " : ""}${vote.Name}`;
									})}
								</div>
								<div>
									<span className={classes.resultsLabel}>{`${votingResults.abstainVotesCount} votes ${
										votingSettings.votingLabels.abstain || t("voting.abstain")
									}: `}</span>
									{votingResults.abstainVotes.map((vote, index) => {
										return `${index > 0 ? ", " : ""}${vote.Name}`;
									})}
								</div>
							</div>
						)}
						{votingData.showResults && (votingData.itemVotingData == null || votingData.itemVotingData.length === 0) && (
							<div className={classes.voteResults}>
								<div style={{ paddingLeft: "16px" }}>
									<Typography variant="h4" className={classes.resultsLabel}>{`${t("movedBy")}: ${votingData.movedBy}`}</Typography>
								</div>
								<div style={{ paddingLeft: "16px" }}>
									<Typography variant="h4" className={classes.resultsLabel}>{`${t("secondedBy")}: ${votingData.secondedBy}`}</Typography>
								</div>
							</div>
						)}
						{votingResults != null && (!votingFinished || !votingResults.quorumMet) && (
							<NoticeCard
								className={classes.noticeCard}
								updateNotice={updateNotice}
								icon={`${voteError ? "status-error" : votingResults.quorumMet ? "status-success" : "status-alert"}`}
								label={
									voteError
										? t("votingDialog.notice.error")
										: votingResults.quorumMet
										? t("votingDialog.notice.success", { voteType: currentUserVote })
										: t("votingDialog.notice.quorumLost")
								}
								processHtml={processHtml}
								status={voteError ? STATUS_ERROR : votingResults.quorumMet ? STATUS_GOOD : STATUS_WARNING}
								thickStatus
								iconColor={voteError ? errorColor : votingResults.quorumMet ? successColor : warningColor}
								dataCy="vote-recorded"
							/>
						)}
					</DialogContent>
					{((!votingFinished && !votingData.showResults) || !votingData.itemVotingData) && (
						<DialogActions className={classes.actions}>
							<Grid container spacing={2}>
								<Grid item sm={4}>
									<ButtonWithTooltip
										className={classes.button}
										title={votingSettings.votingLabels.for || t("voting.for")}
										size={MEDIUM}
										primary
										variant="outlined"
										fullWidth
										onClick={() => {
											handleVoteClick(TYPE_FOR, voteCallback);
											setCurrentUserVote(votingSettings.votingLabels.for || t("voting.for"));
										}}
										dataCy="for-vote"
									>
										{votingSettings.votingLabels.for || t("voting.for")}
									</ButtonWithTooltip>
								</Grid>
								<Grid item sm={4}>
									<ButtonWithTooltip
										className={classes.button}
										title={votingSettings.votingLabels.against || t("voting.against")}
										size={MEDIUM}
										primary
										variant="outlined"
										fullWidth
										onClick={() => {
											handleVoteClick(TYPE_AGAINST, voteCallback);
											setCurrentUserVote(votingSettings.votingLabels.against || t("voting.against"));
										}}
										dataCy="against-vote"
									>
										{votingSettings.votingLabels.against || t("voting.against")}
									</ButtonWithTooltip>
								</Grid>
								<Grid item sm={4}>
									<ButtonWithTooltip
										className={classes.button}
										title={votingSettings.votingLabels.abstain || t("voting.abstain")}
										size={MEDIUM}
										primary
										variant="outlined"
										fullWidth
										onClick={() => {
											handleVoteClick(TYPE_ABSTAIN, voteCallback);
											setCurrentUserVote(votingSettings.votingLabels.abstain || t("voting.abstain"));
										}}
										dataCy="abstain-vote"
									>
										{votingSettings.votingLabels.abstain || t("voting.abstain")}
									</ButtonWithTooltip>
								</Grid>
							</Grid>
						</DialogActions>
					)}
				</Dialog>
			</>
		)
	);
};

export default VotingDialog;
