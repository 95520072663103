import React from 'react';
import { useDraggable } from '@dnd-kit/core';
import clsx from 'clsx';

import makeStyles from '@mui/styles/makeStyles';

import dragAndDropStyle from '../../assets/jss/components/dragAndDropStyle';

const useDragAndDropStyles = makeStyles(dragAndDropStyle);

const Draggable = (props) => {
	const { dragId: id, dragData: data, dragComponent: Component, dragAttributePassthrough, children, className, ...otherProps } = props;
	const { attributes, listeners, setNodeRef } = useDraggable({
		id,
		data,
	});
	const dragAndDropClasses = useDragAndDropStyles();

	// Prevents an error in the console when the component is a DOM element
	if (dragAttributePassthrough) {
		otherProps.dragAttribute = attributes;
		otherProps.dragListeners = listeners;
	}

	return (
		<Component
			ref={setNodeRef}
			{...(!dragAttributePassthrough ? attributes : {})}
			{...(!dragAttributePassthrough ? listeners : {})}
			{...otherProps}
			className={clsx(className, dragAndDropClasses.draggable)}
		>
			{children}
		</Component>
	);
};

export default Draggable;
