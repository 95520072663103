import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import request from "superagent";

import makeStyles from "@mui/styles/makeStyles";
import { Box, MenuItem } from "@mui/material";

import inputStyle from "atlas/assets/jss/components/inputStyle";
import Icon from "atlas/components/Icon/Icon";
import SelectInput from "atlas/components/FormControls/SelectInput";
import { API_HOST } from "config/env";
import GenericDialog from "atlas/components/Dialogs/GenericDialog";
import { getNoOptionsMenuItem, getDropdownProgressIndicator } from "utils/dropDown";

const useInputStyles = makeStyles(inputStyle);

const BoardMeetingTypeReassignDialog = (props) => {
	const { show, board, meetingType, onClose, handleRemoveAndReassign } = props;
	const { t } = useTranslation("boards");
	const [boards, setBoards] = useState(null);
	const [selectedBoard, setSelectedBoard] = useState(0);
	const inputClasses = useInputStyles({ fullWidth: true });

	const loadBoards = () => {
		request
			.get(`${API_HOST}/api/boards?basic=true`)
			.withCredentials()
			.then((res) => {
				const {
					body: { boards },
				} = res || {};
				if (boards) {
					setBoards(boards.filter((current) => current.id !== board.id));
				}
			})
			.catch((err) => {
				showSignIn(err, () => {
					loadBoards();
				});
			});
	};

	const handleCancel = () => {
		onClose();
	};

	const getBoards = () => {
		const menuItems = [];

		if (boards) {
			menuItems.push(getNoOptionsMenuItem(t));

			boards.forEach((board) => {
				menuItems.push(
					<MenuItem key={board.id} value={board.id} data-cy={`board-${board.id}`}>
						<div>{board.name}</div>
					</MenuItem>,
				);
			});
		} else {
			menuItems.push(getDropdownProgressIndicator());
		}

		return menuItems;
	};

	useEffect(() => {
		if (board) {
			loadBoards();
		}
	}, [board]);

	const i18n = t("reassignMeetingTypeDialog", { name: meetingType.name });

	const dialog = {
		title: i18n.title,
		line1: i18n.line1,
		primaryTitle: i18n.buttons.removeAndReassign,
		primaryAction: () => handleRemoveAndReassign(selectedBoard),
		secondaryTitle: t("app:buttons.cancel"),
		secondaryAction: handleCancel,
	};

	return (
		<GenericDialog
			show={show}
			title={dialog.title}
			primaryAction={dialog.primaryAction}
			primaryTitle={dialog.primaryTitle}
			primaryDisabled={selectedBoard === 0}
			secondaryAction={dialog.secondaryAction}
			secondaryTitle={dialog.secondaryTitle}
			closeIcon={<Icon name="close" />}
		>
			<Box fontWeight="fontWeightBold">{dialog.line1}</Box>
			<Box>
				<SelectInput
					id="boards"
					className={inputClasses.smallInput}
					noDefaultClassName
					fullWidth
					label={i18n.selectBoard}
					size="small"
					value={selectedBoard}
					onChange={(e) => setSelectedBoard(parseInt(e.target.value, 10))}
					data-cy="boards"
				>
					{getBoards()}
				</SelectInput>
			</Box>
		</GenericDialog>
	);
};

export default BoardMeetingTypeReassignDialog;
