import request from "superagent";
import { API_HOST } from "config/env";

export function updateSelectedMeeting(info, success, error) {
	const url = `${API_HOST}/api/agendaitem/${info.agendaItemId}/meeting`;
	const data = {
		meetingId: info.meetingId,
		headingId: info.sectionId,
		comment: info.comment,
	};

	request
		.post(url)
		.withCredentials()
		.send(data)
		.then((res) => {
			let response = {};
			if (res.body) {
				response = res.body;
			}
			success(response);
		})
		.catch((err) => {
			error(err);
		});
}
