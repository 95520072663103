import React from "react";
import SvgIcon from "@mui/material/SvgIcon";

import themeConfig from "../../atlas/assets/jss/theme";
import getOr from "lodash/fp/getOr";

const icon = (props) => {
	const { color, lcolor } = props;
	const fillColor = color ? getOr(color, ["palette", color, "main"], themeConfig) : "currentColor";
	return (
		<SvgIcon {...props}>
			<defs>
				<path
					d="M11.3046247,14.7295238 C12.1954913,14.7295238 12.9173139,15.4520405 12.9173139,16.3428571 C12.9173139,17.2336738 12.1954913,17.9561905 11.3046247,17.9561905 C10.413758,17.9561905 9.69193541,17.2336738 9.69193541,16.3428571 C9.69193541,15.4520405 10.413758,14.7295238 11.3046247,14.7295238 Z M11.3046247,4.35809524 C11.9989255,4.35809524 12.5617675,4.92093727 12.5617675,5.6152381 L12.5617675,5.6152381 L12.5617675,11.9009528 C12.5617675,12.5952536 11.9989255,13.1580957 11.3046247,13.1580957 C10.6103238,13.1580957 10.0474818,12.5952536 10.0474818,11.9009528 L10.0474818,11.9009528 L10.0474818,5.6152381 C10.0474818,4.92093727 10.6103238,4.35809524 11.3046247,4.35809524 Z M11,0 C4.92437604,0 0,4.92480125 0,11 C0,17.0751987 4.92437604,22 11,22 C17.0747828,22 22,17.0747828 22,11 C22,4.92521724 17.0747828,0 11,0 Z M11,1.88571429 C16.0333315,1.88571429 20.1142857,5.96666848 20.1142857,11 C20.1142857,16.0333315 16.0333315,20.1142857 11,20.1142857 C5.96586373,20.1142857 1.88571429,16.033784 1.88571429,11 C1.88571429,5.96621605 5.96586373,1.88571429 11,1.88571429 Z"
					id="error-path-1"
				/>
			</defs>
			<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g>
					<mask id="error-mask-2" fill="white">
						<use xlinkHref="#error-path-1" />
					</mask>
					<use xlinkHref="#error-path-1" />
					<g mask="url(#error-mask-2)" fill={lcolor || fillColor} fillRule="evenodd">
						<rect x="0" y="0" width="24" height="24" />
					</g>
				</g>
			</g>
		</SvgIcon>
	);
};

export default icon;
