import React, { useEffect } from "react";
import { withTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import cookie from "react-cookies";
import clsx from "clsx";

import ErrorBoundary from "components/ErrorBoundary";
import isSignedIn from "utils/isSignedIn";
import { SkipToContentId } from "views/Account/Login";

const LoginLayout = (props) => {
	const { children, i18n } = props;
	const navigate = useNavigate();

	useEffect(() => {
		const defaultLanguage = cookie.load("maple_language");
		if (defaultLanguage) {
			i18n.changeLanguage(defaultLanguage);
		}

		isSignedIn(() => {
			// Redirect to home if you are already signed in
			navigate("/");
		});

		// Hide skip to content
		var skipToContent = document.getElementById(SkipToContentId);
		if (skipToContent && skipToContent.parentNode) {
			skipToContent.parentNode.style.display = "none";
		}
	}, []);

	return (
		<div id="components-container" className={clsx("flex", "direction-column", "login-container")}>
			<ErrorBoundary>{children}</ErrorBoundary>
		</div>
	);
};

export default withTranslation()(LoginLayout);
