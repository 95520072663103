import React, { useRef } from "react";
import { useTranslation } from "react-i18next";

import TextField from "@mui/material/TextField";
import makeStyles from "@mui/styles/makeStyles";

import clonedeep from "lodash/cloneDeep";

import AccessibleIconButton from "atlas/components/Buttons/AccessibleIconButton";
import ButtonWithTooltip from "atlas/components/Buttons/ButtonWithTooltip";
import filterStyle from "assets/jss/components/filterStyle";

const useFilterStyles = makeStyles(filterStyle);

export const DEFAULT_SEARCH_FIELD = "search";

const FilterPanel = (props) => {
	const {
		filters,
		onFilterChange,
		isFiltered,
		closeFilter,
		useSearch,
		searchLabel,
		searchTooltip,
		searchPlaceholder,
		searchField = DEFAULT_SEARCH_FIELD,
		children,
	} = props;
	const { t } = useTranslation();
	const classes = useFilterStyles();
	const searchValueRef = useRef(null);

	const clearClick = (e) => {
		e.preventDefault();

		onFilterChange(null, false, false, true);
	};

	const searchChange = (e) => {
		const newFilters = clonedeep(filters);

		newFilters[searchField] = e.target.value;

		onFilterChange(newFilters, e.key === "Enter");
	};

	const searchKeyPress = (e) => {
		if (e.key === "Enter") {
			const newFilters = clonedeep(filters);

			newFilters[searchField] = e.target.value;

			onFilterChange(newFilters, true);
		}
	};

	const searchFocus = (e) => {
		searchValueRef.current = e.target.value;
	};

	const searchBlur = (e) => {
		const newFilters = clonedeep(filters);

		newFilters[searchField] = e.target.value;
		if (newFilters[searchField] !== searchValueRef.current) {
			onFilterChange(newFilters, true);
		}

		searchValueRef.current = null;
	};

	return (
		<div className={classes.container}>
			<div className={classes.titleContainer}>
				<h2 className={classes.title}>{t("filter.title")}</h2>
				<div className={classes.closeButton}>
					<AccessibleIconButton
						id="close-filter"
						iconName="close"
						tooltipText={t("buttons.close")}
						aria-label={t("buttons.close")}
						onClick={closeFilter}
						dataCy="filterClose"
					/>
				</div>
			</div>
			{useSearch && (
				<div className={classes.section}>
					<div className={classes.fieldHeading}>
						<label htmlFor="filter-search">{searchLabel || t("filter.search")}</label>
					</div>
					<TextField
						id="filter-search"
						className={classes.fieldInput}
						inputProps={{ "aria-label": searchTooltip }}
						placeholder={searchPlaceholder}
						value={filters[searchField]}
						margin="normal"
						variant="outlined"
						fullWidth
						size="small"
						onChange={searchChange}
						onKeyPress={searchKeyPress}
						onFocus={searchFocus}
						onBlur={searchBlur}
						data-cy="search-text"
					/>
				</div>
			)}
			{children}
			{isFiltered ? (
				<div className={classes.clearButton}>
					<ButtonWithTooltip
						id="clear-filter"
						label={t("filter.buttons.clearFilters")}
						title={t("filter.tooltips.clearFilters")}
						onClick={clearClick}
						fullWidth
						variant="outlined"
						color="primary"
						dataCy="filterClearAll"
					>
						{t("filter.buttons.clearFilters")}
					</ButtonWithTooltip>
				</div>
			) : null}
		</div>
	);
};

export default FilterPanel;
