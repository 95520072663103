import React, { useContext, useCallback } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

import { UserContext } from "contexts/User/UserContext";
import { SettingsContext } from "contexts/Settings/SettingsContext";
import EmptyPage from "layouts/EmptyPage";
import { FOLDER_TYPE_RECYCLE_BIN, FOLDER_TYPE_PUBLIC, FOLDER_TYPE_INTERNAL, FOLDER_TYPE_CUSTOM } from "utils/enums/DocumentFolderTypes";
import { useSignInDialog } from "utils/isSignedIn";
import AdminLanding from "views/AdminLanding/AdminLanding";
import AdminLiveMeeting from "views/LiveMeeting/AdminLiveMeeting";
import AdminLiveMeetingV2 from "views/LiveMeetingV2/AdminLiveMeetingV2";
import MeetingsModule from "views/Meetings/MeetingsModule";
import CreateMeeting from "views/Meetings/CreateMeeting";
import MeetingPreview from "views/MeetingDocument/MeetingPreview";
import MeetingDocument from "views/MeetingDocument/MeetingDocument";
import SignDocument from "views/MeetingDocument/SignDocument";
import MeetingEditorContainerV2 from "views/MeetingEditor/MeetingEditorContainerV2";
import UserProfile from "views/UserProfile/UserProfile";
import Presentation from "views/LiveMeeting/Presentation";
import TimeStamping from "views/LiveMeeting/TimeStamping";
import BoardContainer from "views/Boards/BoardContainer";
import BoardsModule from "views/Boards/BoardsModule";
import MeetingDetail from "views/Meetings/MeetingDetail";
import GoalsModule from "views/Goals/GoalsModule";
import GoalContainer from "views/Goals/GoalContainer";
import GoalViewContainer from "views/Goals/GoalViewContainer";
import AgendaItemsModule from "views/AgendaItems/AgendaItemsModule";
import AgendaItemContainer from "views/AgendaItems/AgendaItemContainer";
import RequestToSpeakModule from "views/RequestToSpeak/RequestToSpeakModule";
import RequestToSpeak from "views/RequestToSpeak/RequestToSpeak";
import GeneralSettings from "views/GeneralSettings/GeneralSettings";
import Settings from "views/Settings/Settings";
import UsersModule from "views/Users/UsersModule";
import UserContainer from "views/Users/UserContainer";
import WorkflowsModule from "views/Workflows/WorkflowsModule";
import WorkflowContainer from "views/Workflows/WorkflowContainer";
import SubscribersModule from "views/Subscribers/SubscribersModule";
import SubscribersContainer from "views/Subscribers/SubscribersContainer";
import DocumentsModule from "views/Documents/DocumentsModule";
import CustomFoldersModule from "views/Documents/CustomFoldersModule";
import CustomFolderContainer from "views/Documents/CustomFolderContainer";
import PolicyModule from "views/Policy/PolicyModule";
import PolicySettingsModule from "views/Policy/PolicySettingsModule";
import PolicySettingsContainer from "views/Policy/PolicySettingsContainer";
import SearchModule from "views/Search/SearchModule";
import MeetingTemplateModule from "views/MeetingTemplate/MeetingTemplateModule";
import MeetingTemplateContainer from "views/MeetingTemplate/MeetingTemplateContainer";
import WopiContainer from "views/Wopi/wopiContainer";
import PolicyOnlyRoute from "./PolicyOnlyRoute";

const CommunityRoutes = () => {
	const showSignIn = useSignInDialog();
	const policyData = useSelector((state) => state.policyGovernanceReducer?.policyData);
	const { lite, policyEnabled, testSite, communityMeetings } = useContext(SettingsContext);
	const {
		boardAdmin,
		goalAdmin,
		requestToSpeakAdmin,
		userAdmin,
		workflowAdmin,
		boardMember,
		boardStaff,
		systemAdministrator,
		publicUser,
		documentLibrary: { viewRecycleBin },
	} = useContext(UserContext).user;

	// Users that are only board members cannot add agenda items
	const canAddAgendaItems = !publicUser && (boardStaff || boardAdmin || systemAdministrator || !boardMember);

	const getRootElement = useCallback(() => {
		if (!communityMeetings) {
			return <PolicyOnlyRoute />;
		}
		if (boardAdmin) {
			return <AdminLanding boardAdmin={boardAdmin} showSignIn={showSignIn} />;
		}

		if (boardMember || boardStaff) {
			return <MeetingsModule boardAdmin={boardAdmin} boardMember={boardMember} showSignIn={showSignIn} />;
		}

		if (!publicUser && !lite.enabled) {
			return <AgendaItemsModule showSignIn={showSignIn} />;
		}

		if (policyEnabled) {
			return <PolicyModule showSignIn={showSignIn} />;
		}

		return <EmptyPage />;
	}, [boardAdmin, boardMember, boardStaff, publicUser, lite.enabled, policyEnabled]);

	return (
		<Routes>
			<Route path="/" element={getRootElement()} />
			<Route path="/user/profile" element={<UserProfile showSignIn={showSignIn} />} />
			<Route path="/meeting/document/:id" element={<MeetingDocument showSignIn={showSignIn} />} />
			<Route path="/meeting/presentation/:id" element={<Presentation />} />
			<Route path="/settings" element={<Settings showSignIn={showSignIn} />} />
			{communityMeetings ? (
				<>
					{boardAdmin && (
						<>
							<Route path="/meetings" element={<MeetingsModule boardAdmin={boardAdmin} showSignIn={showSignIn} />} />
							<Route path="/meeting/agenda/:id" element={<MeetingEditorContainerV2 showSignIn={showSignIn} oldPath />} />
							<Route path="/meeting/details/:id" element={<MeetingEditorContainerV2 showSignIn={showSignIn} oldPath />} />
							<Route path="/meeting/detailsV2/:id" element={<MeetingEditorContainerV2 showSignIn={showSignIn} />} />
							<Route path="/meeting/edit/:id" element={<CreateMeeting showSignIn={showSignIn} />} />
							<Route path="/meeting/preview/:id/:type/:target" element={<MeetingPreview showSignIn={showSignIn} />} />
							<Route path="/meeting/adopt/:id/:type" element={<SignDocument showSignIn={showSignIn} adopt />} />
							<Route path="/meeting/live/:id" element={<AdminLiveMeeting showSignIn={showSignIn} />} />
							<Route path="/meeting/liveV2/:id" element={<AdminLiveMeetingV2 showSignIn={showSignIn} />} />
							<Route path="/meeting/create" element={<CreateMeeting showSignIn={showSignIn} />} />
							<Route path="/meeting/:id" element={<MeetingDetail showSignIn={showSignIn} lite={lite} testSite={testSite} />} />
						</>
					)}
					{(boardAdmin || boardMember) && (
						<Route path="/meeting/final/:id/:type/:target" element={<MeetingPreview showSignIn={showSignIn} final />} />
					)}
					{(boardAdmin || boardMember || boardStaff) && !lite.enabled && (
						<Route path="/meeting/draftreview/:id/:type/:target" element={<MeetingPreview review showSignIn={showSignIn} />} />
					)}
					{(boardAdmin || workflowAdmin) && !lite.enabled && (
						<>
							<Route path="/workflows/edit/:id" element={<WorkflowContainer showSignIn={showSignIn} />} />
							{workflowAdmin && (
								<>
									<Route path="/workflows/edit" element={<WorkflowContainer showSignIn={showSignIn} />} />
									<Route path="/workflows" element={<WorkflowsModule showSignIn={showSignIn} />} />
								</>
							)}
						</>
					)}
					{boardAdmin && !lite.enabled && <Route path="/meeting/timestamping/:id" element={<TimeStamping showSignIn={showSignIn} />} />}
					{requestToSpeakAdmin && !lite.enabled && (
						<>
							<Route path="/requesttospeak/:id" element={<RequestToSpeak showSignIn={showSignIn} />} />
							<Route
								path="/requesttospeak"
								element={<RequestToSpeakModule requestToSpeakAdmin={requestToSpeakAdmin} showSignIn={showSignIn} />}
							/>
						</>
					)}
					{!lite.enabled && (
						<>
							<Route path="/goals" element={<GoalsModule goalAdmin={goalAdmin} showSignIn={showSignIn} />} />
							{goalAdmin && (
								<>
									<Route path="/goals/create" element={<GoalContainer goalAdmin={goalAdmin} showSignIn={showSignIn} />} />
									<Route path="/goals/edit/:id" element={<GoalContainer goalAdmin={goalAdmin} showSignIn={showSignIn} />} />
								</>
							)}
							<Route path="/goals/view/:id" element={<GoalViewContainer goalAdmin={goalAdmin} showSignIn={showSignIn} />} />
						</>
					)}
					{userAdmin && (
						<>
							<Route path="/boards/edit/:id" element={<BoardContainer showSignIn={showSignIn} />} />
							<Route path="/boards/edit" element={<BoardContainer showSignIn={showSignIn} />} />
							<Route path="/boards" element={<BoardsModule showSignIn={showSignIn} />} />
							<Route path="/subscribers/create" element={<SubscribersContainer showSignIn={showSignIn} />} />
							{!lite.enabled && <Route path="/subscribers" element={<SubscribersModule showSignIn={showSignIn} />} />}
							{!lite.enabled && <Route path="/wopi" element={<WopiContainer showSignIn={showSignIn} />} />}
						</>
					)}
					,
					{!publicUser && !lite.enabled && (
						<>
							<Route path="/agendaitems" element={<AgendaItemsModule showSignIn={showSignIn} />} />
							{canAddAgendaItems && <Route path="/agendaitems/create" element={<AgendaItemContainer showSignIn={showSignIn} />} />}
							<Route path="/agendaitems/edit/:id" element={<AgendaItemContainer showSignIn={showSignIn} boardAdmin={boardAdmin} />} />
							<Route path="/agendaitems/approve/:id" element={<AgendaItemContainer showSignIn={showSignIn} boardAdmin={boardAdmin} />} />
						</>
					)}
					{<Route path="/documents/public" element={<DocumentsModule showSignIn={showSignIn} type={FOLDER_TYPE_PUBLIC} />} />}
					{<Route path="/documents/public/:id" element={<DocumentsModule showSignIn={showSignIn} type={FOLDER_TYPE_PUBLIC} />} />}
					{!lite.enabled && (
						<>
							<Route path="/documents/internal" element={<DocumentsModule showSignIn={showSignIn} type={FOLDER_TYPE_INTERNAL} />} />
							<Route path="/documents/internal/:id" element={<DocumentsModule showSignIn={showSignIn} type={FOLDER_TYPE_INTERNAL} />} />
							<Route path="/documents/custom/:id" element={<DocumentsModule showSignIn={showSignIn} type={FOLDER_TYPE_CUSTOM} />} />
						</>
					)}
					{viewRecycleBin && (
						<>
							<Route path="/documents/recyclebin" element={<DocumentsModule showSignIn={showSignIn} type={FOLDER_TYPE_RECYCLE_BIN} />} />
							<Route
								path="/documents/recyclebin/:id"
								element={<DocumentsModule showSignIn={showSignIn} type={FOLDER_TYPE_RECYCLE_BIN} />}
							/>
						</>
					)}
					{(boardAdmin || userAdmin) && (
						<>
							<Route path="/meetingtemplates" element={<MeetingTemplateModule />} />{" "}
							<Route path="/meetingtemplate/:id" element={<MeetingTemplateContainer />} />
						</>
					)}
					{<Route path="/documents/search/:rootFolder" element={<SearchModule showSignIn={showSignIn} />} />}
					{<Route path="/documents/search/:rootFolder/:id" element={<SearchModule showSignIn={showSignIn} />} />}
					{userAdmin && (
						<>
							<Route path="/documents/customFolders" element={<CustomFoldersModule showSignIn={showSignIn} />} />
							{!lite.enabled && <Route path="/documents/customFolder" element={<CustomFolderContainer showSignIn={showSignIn} />} />}
							<Route path="/documents/customFolder/:id" element={<CustomFolderContainer showSignIn={showSignIn} />} />
						</>
					)}
				</>
			) : (
				policyData && <Route path="*" element={<Navigate to={`/policy/policybook/${policyData?.[0]?.guid}`} />} />
			)}

			{policyEnabled && (
				<>
					<Route path="/policy/policybook" element={<PolicyModule showSignIn={showSignIn} />} />
					<Route path="/policy/policybook/:id" element={<PolicyModule showSignIn={showSignIn} />} />
					<Route path="/policy/recyclebin" element={<PolicyModule showSignIn={showSignIn} type={FOLDER_TYPE_RECYCLE_BIN} />} />
					<Route path="/policy/settings" element={<PolicySettingsModule showSignIn={showSignIn} />} />
					<Route path="/policy/settings/book" element={<PolicySettingsContainer showSignIn={showSignIn} />} />
					<Route path="/policy/settings/book/:id" element={<PolicySettingsContainer showSignIn={showSignIn} />} />
					<Route path="/policy/search/:rootFolder" element={<SearchModule showSignIn={showSignIn} isPolicy />} />
					<Route path="/policy/search/:rootFolder/:id" element={<SearchModule showSignIn={showSignIn} isPolicy />} />
				</>
			)}
			<Route path="/search" element={<SearchModule showSignIn={showSignIn} />} />
			{userAdmin && (
				<>
					<Route path="/users" element={<UsersModule showSignIn={showSignIn} />} />
					<Route path="/generalsettings" element={<GeneralSettings showSignIn={showSignIn} />} />
					<Route path="/users/edit/:id" element={<UserContainer showSignIn={showSignIn} />} />
					<Route path="/users/edit" element={<UserContainer showSignIn={showSignIn} />} />
				</>
			)}
		</Routes>
	);
};

export default CommunityRoutes;
