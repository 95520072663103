import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import makeStyles from "@mui/styles/makeStyles";

import { grayColor, focusColor } from "atlas/assets/jss/shared";
import AccessibleIconButton from "atlas/components/Buttons/AccessibleIconButton";
import Icon from "atlas/components/Icon/Icon";
import { formatDate } from "utils/date";
import getPathType, { generatePathLink } from "../utils/pathType";

const defaultFont = {
	fontSize: "11px",
	fontWeight: "400",
	lineHeight: "16px",
	letterSpacing: "1px",
};

const useStyles = makeStyles({
	changSet: {
		display: "flex",
		alignItems: "flex-start",
		padding: "4px 4px 4px 16px",
		borderBottom: `solid 1px ${grayColor[4]}`,
	},
	changeSetDetails: {
		...defaultFont,
		flexGrow: "1",
		"& div:focus-visible, & span:focus-visible, & a:focus-visible": {
			outline: `solid 2px ${focusColor}`,
			borderRadius: "4px",
		},
	},
	updated: {
		display: "flex",
		alignItems: "center",
		fontWeight: "600",
		cursor: "pointer",
		"& > *:not(:first-child)": {
			marginLeft: "4px",
		},
		"&:focus": {
			backgroundColor: "rgba(0, 0, 0, 0.04)",
		},
	},
	fieldChange: {
		marginLeft: "16px",
		"& a": {
			textDecoration: "none",
		},
		"& a:hover": {
			textDecoration: "underline",
		},
	},
	overflow: {
		margin: "-4px 0",
	},
});

const FIELD_NAME = "iCompass.TextBox.DocumentTitle";
const FIELD_EXTENSION = "System.FileFormat";
const FIELD_FILE_SIZE = "System.FileSize";
const FIELD_HTML = "iCompass.SmartTextBox.Html";
const FIELD_ORIGINAL_URL = "iCompass.TextBox.OriginalFileUrl";
const CHANGE_TYPE_FILE = 1;
const CHANGE_TYPE_HTML = 2;

const DocumentDetailsHistoryEntry = (props) => {
	const { document, changeSet, showRestore, toggleFieldChanges, handleToggleMenu } = props;
	const { t } = useTranslation("documents");
	const navigate = useNavigate();
	const classes = useStyles();

	const addFieldChange = (changes, referenceName) => {
		if (changeSet) {
			const fieldChange = changeSet.fieldChanges.find((change) => change.referenceName === referenceName);
			if (fieldChange) {
				changes.push(fieldChange);
			}
		}
	};

	const addLocationChange = (changes) => {
		if (changeSet) {
			const locationChange = changeSet.relationshipChanges.find((change) => !!change.parents);
			if (locationChange) {
				changes.push(locationChange);
			}
		}
	};

	const handleKeyDown = (e) => {
		if (e.key === " " || e.key === "Enter") {
			toggleFieldChanges(changeSet);
		}
		if (e.key !== "Tab" && e.key !== "Shift") {
			e.preventDefault();
		}
	};

	const openPage = (e, pageLink) => {
		e.preventDefault();

		navigate(pageLink);
	};

	// Only show specific field changes
	const historyChanges = [];
	addFieldChange(historyChanges, FIELD_NAME);
	addFieldChange(historyChanges, FIELD_EXTENSION);
	addFieldChange(historyChanges, FIELD_FILE_SIZE);
	addFieldChange(historyChanges, FIELD_HTML);
	addFieldChange(historyChanges, FIELD_ORIGINAL_URL);
	addLocationChange(historyChanges);

	return changeSet ? (
		<div className={classes.changSet}>
			<div className={classes.changeSetDetails}>
				<div tabIndex={0}>
					{formatDate(changeSet.dateChanged, changeSet.dateChanged, undefined, t("app:at"), "", "", true, true, false)}
				</div>
				<div tabIndex={0}>{changeSet.changedBy}</div>
				{historyChanges.length > 0 && (
					<div>
						<div className={classes.updated} tabIndex="0" onClick={() => toggleFieldChanges(changeSet)} onKeyDown={handleKeyDown}>
							<Icon name={`expand-${changeSet.expanded ? "down" : "right"}`} size="8" />
							<div>{t("detail.labels.updated")}</div>
						</div>
						{changeSet.expanded && (
							<div>
								{historyChanges.map((fieldChange) => {
									const { referenceName, type, parents } = fieldChange;
									if (referenceName) {
										let { value } = fieldChange;

										if (referenceName == FIELD_EXTENSION) {
											value = value.toUpperCase();
										}

										return (
											<div key={fieldChange.referenceName} className={classes.fieldChange}>
												{type === CHANGE_TYPE_FILE || type === CHANGE_TYPE_HTML ? (
													<a href={`/document/${document.guid}?changeSetId=${changeSet.changeSetId}`} target="_blank">
														{value}
													</a>
												) : (
													<span tabIndex={0}>{value}</span>
												)}
											</div>
										);
									} else {
										return (
											<div key="location" className={classes.fieldChange}>
												{parents.map((parent, index) => {
													const rootFolder = getPathType(parent.folderType);
													const pathLink = generatePathLink(parent, index, rootFolder, parent.folderType);

													return (
														<React.Fragment key={parent.guid}>
															{rootFolder ? (
																<a href={`/home${pathLink}`} onClick={(e) => openPage(e, pathLink)}>
																	{parent.title}
																</a>
															) : (
																<span tabIndex={0}>{parent.title}</span>
															)}
															{index < parents.length - 1 ? <span>{" > "}</span> : ""}
														</React.Fragment>
													);
												})}
											</div>
										);
									}
								})}
							</div>
						)}
					</div>
				)}
			</div>
			{showRestore && (
				<div className={classes.overflow}>
					<AccessibleIconButton
						color="inherit"
						aria-label={t("app:menu.options")}
						onClick={handleToggleMenu}
						iconName="more"
						dataCy="change-set-overflow-menu"
						tooltipText={t("app:menu.options")}
					/>
				</div>
			)}
		</div>
	) : null;
};

export default DocumentDetailsHistoryEntry;
