export const NONE = "None";
export const DRAFT = "Draft";
export const ADOPTED = "Adopted";
export const PUBLISHED = "Published";
export const RESCINDED = "Rescinded";
export const REVIEW = "Review";
export const HISTORICAL = "Historical";

export const POLICY_NONE = 0;
export const POLICY_DRAFT = 1;
export const POLICY_ADOPTED = 2;
export const POLICY_PUBLISHED = 3;
export const POLICY_RESCINDED = 4;
export const POLICY_REVIEW = 5;
export const POLICY_HISTORICAL = 6;
export const POLICY_REVISED = 7;
