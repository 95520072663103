import React, { useState, useEffect, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { AutoSizer, CellMeasurer, CellMeasurerCache, InfiniteLoader, List } from "react-virtualized";
import { useTranslation, Trans } from "react-i18next";
import request from "superagent";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";

import { API_HOST } from "config/env";
import telemetryAddEvent from "utils/telemetryAddEvent";
import CircularProgressIndicator from "atlas/components/Progress/CircularProgressIndicator";
import { useWidthDown } from "atlas/utils/useWidth";
import AgendaItemListCard from "./AgendaItemListCard";
import AgendaItemSelectMeetingDialog from "./AgendaItemSelectMeetingDialog";
import SubmitForApprovalDialog from "./SubmitForApprovalDialog";
import CancelApprovalDialog from "./CancelApprovalDialog";
import AgendaItemDeleteDialog from "./AgendaItemDeleteDialog";
import SendReminderDialog from "./SendReminderDialog";
import SupportRequestDialog from "components/Dialogs/SupportRequestDialog";
import { updateSelectedMeeting } from "../functions/meetings";
import { updateTabs } from "redux/app/actions";
import { UserContext } from "contexts/User/UserContext";
import notifierMessage from "utils/notifierMessage";
import { setSnackbarOptions } from "redux/snackBar/actions";

export const BATCH_SIZE = 50;

const AgendaItemList = (props) => {
	const { items: itemsData, loadMore, removeItemFromList, restoreItemToList, tab, tabChange, showSignIn, closeSnackbar } = props;
	const widthDownMd = useWidthDown("md");
	const { items, total } = itemsData || {};
	const { t } = useTranslation("agendaItems");
	const [dialogs, setDialogs] = useState({});
	const [item, setItem] = useState(null);
	const [itemForDelete, setItemForDelete] = useState(null);
	const [approvalProgressExpandedItem, setApprovalProgressExpandedItem] = useState(null);
	const dispatch = useDispatch();
	const { userAdmin } = useContext(UserContext).user;
	const pendingAgendaItemApprovalsCount = useSelector((state) => state.appReducer.pendingAgendaItemApprovalsCount);
	const cache = new CellMeasurerCache({
		defaultHeight: 73, // 72px height + 1px border
		minHeight: 73,
		fixedWidth: true,
	});

	const handleError = () => {};

	const handleToggleApprovalProgressExpand = (item, expanded) => {
		setApprovalProgressExpandedItem(expanded ? item : null);
	};

	const openSelectMeetingDialog = (item) => {
		setItem(item);
		setDialogs({
			selectMeeting: true,
		});
	};

	const closeSelectMeetingDialog = () => {
		setItem(null);
		setDialogs((prev) => ({
			...prev,
			selectMeeting: false,
		}));
	};

	const selectMeetingCB = (updatedItem) => {
		const changed = item.meetingId > 0;

		item.meetingId = updatedItem.meeting.id;
		item.meeting = updatedItem.meeting;
		item.meetingInPast = updatedItem.meetingInPast;
		item.headingId = updatedItem.headingId;

		closeSelectMeetingDialog();

		let option = notifierMessage(t("selectMeetingDialog.snackbar.success"), "success");
		dispatch(setSnackbarOptions(option));

		telemetryAddEvent(`Agenda item - ${changed ? "Change" : "Select"} meeting`, { area: "agendaItems" });
	};

	const handleSelectMeetingUpdate = (selection) => {
		const data = {
			...selection,
			agendaItemId: item.guid,
		};

		updateSelectedMeeting(data, selectMeetingCB, handleError);
	};

	const openSubmitForApprovalDialog = (item) => {
		setItem(item);
		setDialogs({
			submitForApproval: true,
		});
	};

	const closeSubmitForApprovalDialog = () => {
		setItem(null);
		setDialogs((prev) => ({
			...prev,
			submitForApproval: false,
		}));
	};

	const openSupportRequestDialog = () => {
		setDialogs({
			supportRequest: true,
		});
	};

	const closeSupportRequestDialog = () => {
		setDialogs((prev) => ({
			...prev,
			supportRequest: false,
		}));
	};

	const handleSubmitForApproval = (selection, selectedItem) => {
		const data = {
			...selection,
			agendaItemId: selectedItem.guid,
		};

		request
			.post(`${API_HOST}/api/workflow/${selection.workflowId}/submit`)
			.withCredentials()
			.send(data)
			.then((res) => {
				if (res.status === 200) {
					removeItemFromList(selectedItem.guid);

					let option = notifierMessage(t("submitForApprovalDialog.snackbar.success"), "success");
					dispatch(setSnackbarOptions(option));

					telemetryAddEvent("Agenda item - Submit for approval", { area: "agendaItems" });
				}
				closeSubmitForApprovalDialog();
			})
			.catch((err) => {
				showSignIn(err, () => {
					handleSubmitForApproval(selection, selectedItem);
				});
			});
	};

	const openCancelApprovalDialog = (item) => {
		setItem(item);
		setDialogs({
			cancelApproval: true,
		});
	};

	const closeCancelApprovalDialog = () => {
		setItem(null);
		setDialogs((prev) => ({
			...prev,
			cancelApproval: false,
		}));
	};

	const handleCancelApproval = (selectedItem) => {
		const data = {
			agendaItemId: selectedItem.guid,
		};

		request
			.post(`${API_HOST}/api/workflow/${selectedItem.workflow.id}/cancel`)
			.withCredentials()
			.send(data)
			.then((res) => {
				if (res.status === 200) {
					removeItemFromList(selectedItem.guid);
					let option = notifierMessage(t("cancelApprovalDialog.snackbar.success"), "success");
					dispatch(setSnackbarOptions(option));
				}
				closeCancelApprovalDialog();
			})
			.catch((err) => {
				showSignIn(err, () => {
					handleCancelApproval(selectedItem);
				});
			});
	};

	const openSendReminderDialog = (item) => {
		setItem(item);
		setDialogs({
			sendReminder: true,
		});
	};

	const closeSendReminderDialog = () => {
		setItem(null);
		setDialogs((prev) => ({
			...prev,
			sendReminder: false,
		}));
	};

	const openItemDeleteDialog = (obj) => {
		setItemForDelete(obj);
		setDialogs({
			delete: true,
		});
	};

	const closeItemDeleteDialog = () => {
		setItemForDelete(null);
		setDialogs((prev) => ({
			...prev,
			delete: false,
		}));
	};

	const afterDeleteItem = (obj) => {
		const { id } = obj;

		closeItemDeleteDialog();
		removeItemFromList(id);
	};

	const undoDeleteItem = (obj) => {
		const { id, sbKey } = obj;

		restoreItemToList(id);
		closeSnackbar(sbKey);
	};

	useEffect(() => {
		dispatch(
			updateTabs({
				display: true,
				selectedTab: tab,
				scrollButtons: widthDownMd ? "on" : "auto",
				tabsOptions: [
					{ key: "draft", label: t("tabs.draft"), dataCy: "notSubmittedAgendaItems" },
					{
						key: "submitted",
						label: t("tabs.submitted"),
						dataCy: "submittedAgendaItems",
					},
					{
						key: "pending",
						label: t("tabs.pending"),
						badgeProps: { content: pendingAgendaItemApprovalsCount, color: "error" },
						dataCy: "pendingAgendaItems",
					},
					{
						key: "rejected",
						label: t("tabs.rejected"),
						dataCy: "rejectedAgendaItems",
					},
					{ key: "added", label: t("tabs.added"), dataCy: "addedAgendaItems" },
				],
				onChange: (_e, newTab) => {
					dispatch(
						updateTabs({
							selectedTab: newTab,
						}),
					);

					tabChange(_e, newTab);
				},
			}),
		);
	}, [tab, items, pendingAgendaItemApprovalsCount, widthDownMd]);

	useEffect(() => {
		if (!items) {
			loadMore({ startIndex: 0 });
		}
	}, [items]);

	const isRowLoaded = ({ index }) => items && !!items[index];

	const rowRenderer = (rowProps) => {
		const { key, index, parent, style } = rowProps;
		const item = items[index];

		return (
			<CellMeasurer key={key} cache={cache} columnIndex={0} parent={parent} rowIndex={index}>
				<div style={style} role="row">
					<div role="cell">
						<AgendaItemListCard
							key={`agendaItemCard${item.id}`}
							item={item}
							approvalProgressExpanded={item === approvalProgressExpandedItem}
							onToggleApprovalProgressExpand={handleToggleApprovalProgressExpand}
							openSelectMeetingDialog={openSelectMeetingDialog}
							openSubmitForApprovalDialog={openSubmitForApprovalDialog}
							openCancelApprovalDialog={openCancelApprovalDialog}
							openItemDeleteDialog={openItemDeleteDialog}
							openSendReminderDialog={openSendReminderDialog}
							showSignIn={showSignIn}
							data-cy={`agendaItemCard${item.id}`}
							tab={tab}
						/>
					</div>
				</div>
			</CellMeasurer>
		);
	};

	let noItemTab = "noItems.draft";
	if (tab === 1) {
		noItemTab = "noItems.submitted";
	} else if (tab === 2) {
		noItemTab = "noItems.pending";
	} else if (tab === 3) {
		noItemTab = "noItems.rejected";
	} else if (tab === 4) {
		noItemTab = "noItems.added";
	}

	return (
		<>
			{dialogs.supportRequest && <SupportRequestDialog show={dialogs.supportRequest} onClose={closeSupportRequestDialog} />}

			{dialogs.selectMeeting && item && (
				<AgendaItemSelectMeetingDialog
					show={dialogs.selectMeeting}
					item={item}
					onClose={closeSelectMeetingDialog}
					onSelect={handleSelectMeetingUpdate}
				/>
			)}
			{dialogs.submitForApproval && item && (
				<SubmitForApprovalDialog
					show={dialogs.submitForApproval}
					item={item}
					onSubmit={handleSubmitForApproval}
					onClose={closeSubmitForApprovalDialog}
				/>
			)}
			{dialogs.cancelApproval && item && (
				<CancelApprovalDialog
					show={dialogs.cancelApproval}
					item={item}
					onCancel={handleCancelApproval}
					onClose={closeCancelApprovalDialog}
				/>
			)}
			{dialogs.sendReminder && item && (
				<SendReminderDialog show={dialogs.sendReminder} item={item} onClose={closeSendReminderDialog} userAdmin={userAdmin} />
			)}
			{dialogs.delete && itemForDelete && (
				<AgendaItemDeleteDialog
					show={dialogs.delete}
					afterDelete={afterDeleteItem}
					onClose={closeItemDeleteDialog}
					item={itemForDelete}
					undoDeleteItem={undoDeleteItem}
				/>
			)}
			{items && items.length > 0 && typeof total === "number" ? (
				<InfiniteLoader isRowLoaded={isRowLoaded} loadMoreRows={loadMore} rowCount={total} minimumBatchSize={BATCH_SIZE}>
					{({ onRowsRendered, registerChild }) => (
						<AutoSizer>
							{({ height, width }) => (
								<List
									ref={registerChild}
									onRowsRendered={onRowsRendered}
									rowRenderer={rowRenderer}
									rowCount={items.length}
									rowHeight={cache.rowHeight}
									height={height}
									width={width}
									deferredMeasurementCache={cache}
								/>
							)}
						</AutoSizer>
					)}
				</InfiniteLoader>
			) : (
				<div style={{ margin: "24px", textAlign: "center" }}>
					{!items || typeof total !== "number" ? (
						<Box mt={4}>
							<CircularProgressIndicator data-cy="loadingAgendaItems" />
						</Box>
					) : (
						<>
							<Box mt={4}>
								<Typography id="no-item-title" variant="h3">
									{t(`${noItemTab}.title`)}
								</Typography>
							</Box>
							<Box mt={3}>
								<Typography id="no-item-description" variant="body2">
									{tab === 0 ? (
										<Trans i18nKey="noItems.draft.description">
											{t(`${noItemTab}.description`)}{" "}
											<Link className="cursor-pointer" underline="always" onClick={openSupportRequestDialog}>
												{t("noItems.draft.implementationOrSupport")}
											</Link>
										</Trans>
									) : (
										t(`${noItemTab}.description`)
									)}
								</Typography>
							</Box>
						</>
					)}
				</div>
			)}
		</>
	);
};

export default AgendaItemList;
