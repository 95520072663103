import React, { useState, useEffect, useRef, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import request from "superagent";
import { v4 as uuid } from "uuid";

import { SettingsContext } from "contexts/Settings/SettingsContext";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";

import { STATUS_WARNING } from "atlas/assets/jss/utils/statusIndicators";
import NoticeCard from "atlas/components/Cards/NoticeCard";
import processHtml from "utils/processHtml";
import { Check } from "components/Icons";
import { isSharedInSync } from "utils/agendaStatuses";
import { API_HOST } from "config/env";
import telemetryAddEvent from "utils/telemetryAddEvent";
import CircularProgressIndicator from "atlas/components/Progress/CircularProgressIndicator";
import GenericDialog from "atlas/components/Dialogs/GenericDialog";
import ProgressBar from "atlas/components/Progress/ProgressBar";
import Icon from "atlas/components/Icon/Icon";
import StyledSwitch from "atlas/components/FormControls/StyledSwitch";
import { getMeetingOutputText } from "utils/meetingOutputText";
import { updateHandlers, PROGRESS_HUB } from "utils/communication/SignalrClient";
import ErrorDialogMeetingPublish from "./ErrorDialogMeetingPublish";
import GroupMemberList from "./GroupMemberList";
import { updateNotice } from "redux/app/actions";
import { useAsyncResult } from "utils/useAsyncResult";

const useStyles = makeStyles(() => ({
	progressBar: {
		width: "240px",
		margin: "0 auto",
		marginTop: "24px",
	},
}));

const MeetingPublishDialog = (props) => {
	const {
		show,
		agenda = true,
		afterPublish,
		onClose,
		meeting: { id, boardAgendaStatus, name, pendingItems },
		meeting,
		showSignIn,
		openSupportRequestDialog,
		editorFunctions,
		telemetryPage = "",
	} = props;
	const share = agenda && !isSharedInSync(boardAgendaStatus);
	const { t } = useTranslation("meetings");
	const [agendaNotice, setAgendaNotice] = useState(false);
	const [notifyMembers, setNotifyMembers] = useState(share); // If we are not sharing, set this to false
	const [notifyPublic, setNotifyPublic] = useState(false);
	const [showOnPortal, setShowOnPortal] = useState(meeting.showOnPortal);
	const [publishing, setPublishing] = useState(false);
	const [showPublishDialog, setShowPublishDialog] = useState(show);
	const [errors, setErrors] = useState(null);
	const [progress, setProgress] = useState({
		label: " ",
		percent: 0,
	});
	const appReducer = useSelector((state) => state.appReducer);
	const {
		signalR: { client, handler },
	} = appReducer;
	const dispatch = useDispatch();
	const classes = useStyles();
	const isMounted = useRef();
	const guid = useRef();
	const { lite } = useContext(SettingsContext);

	const progressId = "publishing-progress";

	const requestFinished = (res) => {
		if (isMounted.current) {
			setPublishing(false);
		}

		onClose();
		afterPublish({
			agenda,
			wasAlsoShared: agenda && !isSharedInSync(boardAgendaStatus),
			agendaNotice: res.agendaNotice || {},
		});
	};

	const previewSuccess = (res) => {
		setProgress({
			label: " ",
			percent: 100,
		});
		if (res && res.body && res.body.pdf && res.body.pdf.errors) {
			// backend returns both item and attachment errors under the "pdf.errors" section
			setErrors(res.body.pdf.errors);
			setShowPublishDialog(false);
			setPublishing(false);
		} else {
			requestFinished(res.body);
		}
	};

	const previewError = (err) => {
		if ([408, 504].indexOf(err.status) >= 0) {
			err.timeout = true;
		}
		handleError(err);
	};

	const [startResultCheck, resultCheckNow] = useAsyncResult(previewSuccess, previewError);

	const updateProgress = (percentage, message) => {
		setProgress({
			label: message,
			percent: percentage,
		});

		if (percentage >= 100) {
			resultCheckNow();
		}
	};

	const handleError = (err) => {
		if (isMounted.current) {
			setErrors(err);
			setShowPublishDialog(false);
			setPublishing(false);
		}
	};

	const handlePublish = (notify) => {
		telemetryAddEvent(`${telemetryPage} - Publish ${agenda ? "agenda" : "minutes"}`);

		if (!id) {
			handleError();
		}
		setPublishing(true);

		request
			.post(`${API_HOST}/api/meeting/${id}/publish`)
			.send({
				progressGuid: guid.current,
				agenda,
				agendaNotice: agenda && notify.agendaNotice,
				sendNotifications: agenda && notify.public,
				sendMembersNotifications: agenda && notify.members,
				showOnPortal,
			})
			.then(startResultCheck)
			.catch(previewError);
	};

	const handleCancel = () => {
		onClose();
	};

	const handlePublishWithSave = (notify) => {
		// Check if editor function exists, if we are publishing from agenda editor we need to save, if from meeting details we don't need to save
		if (editorFunctions) {
			const { saveAgenda } = editorFunctions;

			saveAgenda({
				success: () => handlePublish(notify),
				data: null,
			});
		} else {
			handlePublish(notify);
		}
	};

	useEffect(() => {
		isMounted.current = true;
		guid.current = uuid();

		updateHandlers(dispatch, handler, PROGRESS_HUB, { announceProgress: updateProgress });

		client.ensureStarted().then(() => client.progressHub.registerProgressGuid(guid.current));

		return () => {
			client.ensureStarted().then(() => client.progressHub.clearProgressGuid(guid.current));

			updateHandlers(dispatch, handler, PROGRESS_HUB, { announceProgress: null });

			isMounted.current = false;
		};
	}, [meeting, agenda]);

	useEffect(() => {
		if (publishing) {
			window.document.getElementById(progressId)?.scrollIntoView();
		}
	}, [publishing]);

	const i18n = t("publishMeetingDialog", { name });
	const dialog = {
		title: getMeetingOutputText(i18n.title, agenda),
		line1: getMeetingOutputText(share ? i18n.line1v2 : i18n.line1, agenda),
		line2: getMeetingOutputText(i18n.line2, agenda),
		confInfo: getMeetingOutputText(i18n.info.confidential, agenda),
		pubInfo: getMeetingOutputText(i18n.info.public, agenda),
		notify: getMeetingOutputText(i18n.notify, agenda),
		agendaNotice: i18n.agendaNotice,
		notifyMembers: getMeetingOutputText(i18n.notifyMembers, agenda),
		primaryTitle: getMeetingOutputText(i18n.buttons.publish, agenda),
		primaryAction: () => handlePublishWithSave({ agendaNotice, members: notifyMembers, public: notifyPublic }),
		secondaryTitle: getMeetingOutputText(i18n.buttons.cancel, agenda),
		secondaryAction: handleCancel,
	};

	return (
		<>
			{errors && (
				<ErrorDialogMeetingPublish
					agenda={agenda}
					errors={errors}
					handleClose={dialog.secondaryAction} // error dialog close also triggers meeting publish cancel
					show
					openSupportRequestDialog={openSupportRequestDialog}
					meeting={meeting}
					triggeredBy="publish"
					editorFunctions={editorFunctions}
				/>
			)}

			<GenericDialog
				show={showPublishDialog}
				title={dialog.title}
				primaryAction={dialog.primaryAction}
				primaryTitle={publishing ? <CircularProgressIndicator color="secondary" size={20} minHeight="20px" /> : dialog.primaryTitle}
				primaryTooltip={meeting.showPublicSiteToggle && !showOnPortal ? t("publishMeetingDialog.warningShowOnPortal") : undefined}
				primaryDisabled={publishing || pendingItems > 0 || (meeting.showPublicSiteToggle && !showOnPortal)}
				secondaryAction={dialog.secondaryAction}
				secondaryTitle={dialog.secondaryTitle}
				secondaryDisabled={publishing}
				clickAwayDisabled={publishing}
				leftActionChildren={
					meeting.showPublicSiteToggle &&
					!meeting.showOnPortal && (
						<StyledSwitch
							useLabel={true}
							label={t("publicSite")}
							onLabel={t("show")}
							offLabel={t("hide")}
							inline
							title=""
							size="small"
							objectToUpdate={{ showOnPortal }}
							fieldToUpdate="showOnPortal"
							onChange={(checked) => setShowOnPortal(checked)}
							data-cy="showOnPortal"
						/>
					)
				}
				closeIcon={<Icon name="close" />}
				data-cy="publish-dialog"
			>
				{pendingItems === 0 ? (
					<>
						<Typography variant="h5">
							<Box mb={1}>{dialog.line1}</Box>
						</Typography>

						<Typography variant="h5">
							<Box mt={1} mb={1}>
								{dialog.line2}
							</Box>
						</Typography>

						{share && (
							<>
								<GroupMemberList agenda={agenda} meeting={meeting} showSignIn={showSignIn} telemetryPage={telemetryPage} />
								<Divider />
							</>
						)}

						<Box mt={2} mb={6} display="flex">
							<Box mt="auto" mb="auto" mr={2}>
								<Icon name="unlocked" />
							</Box>
							<Typography variant="body1">{dialog.pubInfo}</Typography>
						</Box>

						{agenda && (
							<Box display="flex" justifyContent="flex-start" alignItems="flex-end">
								<FormGroup>
									<FormControlLabel
										id="agenda-notice"
										control={
											<Checkbox
												checkedIcon={<Check fontSize="small" color="primary" />}
												checked={agendaNotice}
												disabled={publishing}
												onChange={() => {
													telemetryAddEvent(`Publish Agenda - ${agendaNotice ? "Uncheck" : "Check"} with download print outline`);

													setAgendaNotice((prev) => !prev);
												}}
											/>
										}
										label={dialog.agendaNotice}
										data-cy="dialog-agenda-notice"
									/>
								</FormGroup>
							</Box>
						)}

						{agenda && (
							<>
								<Typography>{t("notifications.emailNotifications")}</Typography>
								{share && (
									<Box display="flex" justifyContent="flex-start" alignItems="flex-end">
										<FormGroup>
											<FormControlLabel
												id="notifyMembers"
												control={
													<Checkbox
														checkedIcon={<Check fontSize="small" color="primary" />}
														checked={notifyMembers}
														disabled={publishing}
														onChange={() => {
															telemetryAddEvent(`${telemetryPage} - ${!notifyMembers ? "Uncheck" : "Check"} notify members`);

															setNotifyMembers(!notifyMembers);
														}}
													/>
												}
												label={dialog.notifyMembers}
												data-cy="dialog-notify-members"
											/>
										</FormGroup>
									</Box>
								)}

								{!lite.enabled && (
									<Box display="flex" justifyContent="flex-start" alignItems="flex-end">
										<FormGroup>
											<FormControlLabel
												id="notifyPublic"
												control={
													<Checkbox
														checkedIcon={<Check fontSize="small" color="primary" />}
														checked={notifyPublic}
														disabled={publishing}
														onChange={() => {
															telemetryAddEvent(`${telemetryPage} - ${!notifyMembers ? "Uncheck" : "Check"} notify public`);

															setNotifyPublic(!notifyPublic);
														}}
													/>
												}
												label={dialog.notify}
												data-cy="dialog-notify-public"
											/>
										</FormGroup>
									</Box>
								)}
							</>
						)}
						{publishing && (
							<ProgressBar id={progressId} className={classes.progressBar} label={progress.label} progress={progress.percent} />
						)}
					</>
				) : (
					<NoticeCard
						updateNotice={updateNotice}
						status={STATUS_WARNING}
						thickStatus
						icon="status-alert"
						label={t("publishMeetingDialog.warningPendingItems", { count: pendingItems })}
						processHtml={processHtml}
					/>
				)}
			</GenericDialog>
		</>
	);
};

export default MeetingPublishDialog;
