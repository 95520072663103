import React from "react";
import { useTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";
import clsx from "clsx";

import makeStyles from "@mui/styles/makeStyles";

import CircularProgressIndicator from "atlas/components/Progress/CircularProgressIndicator";
import TableData from "atlas/components/TableData/TableData";
import { blackColor } from "atlas/assets/jss/shared";
import LinkCell from "components/TableData/LinkCell";
import IconButtonCell from "../../../atlas/components/TableData/IconButtonCell";

const useStyles = makeStyles(() => ({
	headCell: {
		display: "none",
	},
	activeHead: {
		textAlign: "center",
	},
	noResults: {
		padding: "16px",
	},
	mobileTable: {
		"& tbody": {
			"& td": {
				borderColor: "transparent",
			},
		},
	},
}));

const PolicySettingsList = (props) => {
	const { books, handleSort, handleDelete } = props;
	const { t } = useTranslation("documents");
	const classes = useStyles();

	let tableHeadings = [];
	{
		books?.length > 1
			? (tableHeadings = [
					{
						id: "title",
						disablePadding: false,
						label: t("policySettings.header.name"),
						retainCase: true,
						rowColor: blackColor[1],
						navigateUrl: (row) => `/policy/settings/book/${row.guid}`,
						component: LinkCell,
					},
					{
						id: "delete",
						disablePadding: false,
						label: "",
						icon: "remove",
						tooltip: t("tooltips.deletePolicyBook"),
						handleAction: handleDelete,
						rowColor: blackColor[1],
						width: "76px",
						// showButton: (books) => books?.length >1,
						component: IconButtonCell,
					},
			  ])
			: (tableHeadings = [
					{
						id: "title",
						disablePadding: false,
						label: t("policySettings.header.name"),
						retainCase: true,
						rowColor: blackColor[1],
						navigateUrl: (row) => `/policy/settings/book/${row.guid}`,
						component: LinkCell,
					},
			  ]);
	}

	return books ? (
		<>
			<div className={clsx({ [classes.mobileTable]: isMobile })}>
				<TableData
					withoutMultiSelect
					showToolbar={false}
					headCells={tableHeadings}
					minHeight=""
					rows={books}
					noDataLabels={t("policySettings.noResults.description")}
					defaultOrderBy="default"
					sortAction={handleSort}
					useExternalSort
					overrideClasses={classes}
					data-cy="policy-settings-list"
				/>
			</div>
		</>
	) : (
		<CircularProgressIndicator />
	);
};

export default PolicySettingsList;
