import React, { useRef, useEffect } from "react";

import makeStyles from "@mui/styles/makeStyles";

import { focusColor } from "atlas/assets/jss/shared";
import DocumentTree from "./DocumentTree";

export const DOCUMENT_TREE_GAP = 12;
export const DOCUMENT_TREE_INLINE_PADDING = 16;
const useStyles = makeStyles(() => ({
	listItem: {
		"&:focus-visible": {
			outline: `solid 2px ${focusColor}`,
			outlineOffset: "-2px",
			borderRadius: "4px",
		},
	},
	item: {
		display: "flex",
		alignItems: "center",
		boxSizing: "border-box",
		minHeight: "48px",
		width: "100%",
		padding: `12px ${DOCUMENT_TREE_INLINE_PADDING}px`,
		gap: `${DOCUMENT_TREE_GAP}px`,
		borderTop: ({ inPortal }) => (!inPortal ? "1px solid rgba(224, 224, 224, 1)" : undefined),
		"&:hover": {
			backgroundColor: "rgba(0, 0, 0, 0.04)",
		},
	},
}));

const DocumentTreeItem = (props) => {
	const { documents, document, columns, type, inPortal, depth = 0, handleEvent, anchor, setAnchor, childRendered, telemetryPage } = props;
	const iconSize = inPortal ? 20 : 24;
	const classes = useStyles({ iconSize, inPortal, depth });
	const listItemRef = useRef(null);
	const canExpand = document && document.folder && document.hasChildren;

	const handleKeyDown = (e) => {
		switch (e.key) {
			case "ArrowLeft":
				if (canExpand && document.expanded) {
					// Expand the folder
					e.preventDefault();
					e.stopPropagation();
					handleEvent({ eventName: "expand", document, expand: false, keyboard: true });
				} else {
					// Focus on the parent
					e.target.parentNode?.closest("li")?.focus();
				}
				break;

			case "ArrowRight":
				if (canExpand) {
					if (!document.expanded) {
						// Collapse the folder
						e.preventDefault();
						e.stopPropagation();
						handleEvent({ eventName: "expand", document, expand: true, keyboard: true });
					} else {
						// Focus on first child
						e.target.getElementsByTagName("li")?.[0]?.focus();
					}
				}
				break;

			case "ArrowUp":
				const previousSibling = e.target.previousSibling;
				if (previousSibling) {
					const descendants = previousSibling.getElementsByTagName("li");
					if (descendants.length > 0) {
						// Focus on last descendant of previous sibling
						descendants[descendants.length - 1].focus();
					} else {
						// Focus on previous sibling
						previousSibling.focus();
					}
				} else {
					// Focus on the parent
					e.target.parentNode?.closest("li")?.focus();
				}
				break;

			case "ArrowDown":
				const descendants = e.target.getElementsByTagName("li");
				if (descendants.length > 0) {
					// Focus on first descendant
					descendants[0].focus();
				} else {
					// Find next sibling and focus on it if it exists
					let listItem = e.target;
					while (listItem && !listItem.nextSibling) {
						listItem = listItem.parentNode?.closest("li");
					}
					const nextSibling = listItem?.nextSibling;
					if (nextSibling) {
						nextSibling.focus();
					}
				}
				break;

			case "Home":
				// Focus on first list item
				e.preventDefault();
				e.stopPropagation();
				e.target.closest("ul[role='tree']").firstChild.focus();
				break;

			case "End":
				// Focus on last list item
				e.preventDefault();
				e.stopPropagation();
				const treeDescendants = e.target.closest("ul[role='tree']").getElementsByTagName("li");
				treeDescendants[treeDescendants.length - 1].focus();
				break;

			case "Enter":
				e.preventDefault();
				e.stopPropagation();
				if (canExpand) {
					// Toggle the node expansion
					handleEvent({ eventName: "expand", document, keyboard: true });
				} else {
					// Open the document
					e.target.getElementsByTagName("a")?.[0]?.click();
				}
				break;
		}
	};

	useEffect(() => {
		if (childRendered && listItemRef.current) {
			childRendered(listItemRef.current);
		}
	}, [childRendered, listItemRef.current]);

	return document && columns ? (
		<li
			id={`tree-item-${document.guid}`}
			className={classes.listItem}
			tabIndex="0"
			role="treeitem"
			aria-expanded={canExpand ? `${document.expanded ? document.expanded : false}` : undefined}
			onKeyDown={handleKeyDown}
			data-cy={`document-tree-item-${document.guid}`}
			ref={childRendered ? listItemRef : undefined}
		>
			<div className={classes.item}>
				{columns.map((column) => {
					const Component = column.component;

					return (
						<Component
							key={column.id}
							document={document}
							value={document[column.id]}
							type={type}
							inPortal={inPortal}
							depth={depth}
							options={column.options}
							menuTooltipText={column.menuTooltipText}
							width={column.width}
							handleEvent={handleEvent}
							action={column.action}
							anchor={anchor?.document === document ? anchor.anchor : undefined}
							setAnchor={setAnchor}
							telemetryPage={telemetryPage}
						/>
					);
				})}
			</div>
			{canExpand && document.expanded && (
				<div>
					<DocumentTree
						documents={documents}
						parent={document}
						columns={columns}
						type={type}
						inPortal={inPortal}
						depth={depth + 1}
						handleEvent={handleEvent}
						anchor={anchor}
						setAnchor={setAnchor}
					/>
				</div>
			)}
		</li>
	) : null;
};

export default DocumentTreeItem;
