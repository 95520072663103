import React from "react";
import SvgIcon from "@mui/material/SvgIcon";

import themeConfig from "../../atlas/assets/jss/theme";
import getOr from "lodash/fp/getOr";

const icon = (props) => {
	const { lcolor } = props;
	const fillColor = lcolor ? getOr(lcolor, ["palette", lcolor, "main"], themeConfig) : "currentColor";

	return (
		<SvgIcon {...props}>
			<defs>
				<path
					d="M6.32608696,10.5 L6.32608696,5.625 C6.32608696,2.51839828 8.80798671,0 11.8695652,0 C14.9311437,0 17.4130435,2.51839828 17.4130435,5.625 L17.4130435,6.25 C17.4130435,6.66421356 17.0821235,7 16.673913,7 C16.2657026,7 15.9347826,6.66421356 15.9347826,6.25 L15.9347826,5.625 C15.9347826,3.34682541 14.1147228,1.5 11.8695652,1.5 C9.62440765,1.5 7.80434783,3.34682541 7.80434783,5.625 L7.80434783,10.5 L20,10.5 C20.4082105,10.5 20.7391304,10.8357864 20.7391304,11.25 L20.7391304,23.25 C20.7391304,23.6642136 20.4082105,24 20,24 L3.73913043,24 C3.33091997,24 3,23.6642136 3,23.25 L3,11.25 C3,10.8357864 3.33091997,10.5 3.73913043,10.5 L6.32608696,10.5 Z M4.47826087,12 L4.47826087,22.5 L19.2608696,22.5 L19.2608696,12 L4.47826087,12 Z M12.6086957,15.375 L12.6086957,19.125 C12.6086957,20.125 11.1304348,20.125 11.1304348,19.125 L11.1304348,15.375 C11.1304348,14.375 12.6086957,14.375 12.6086957,15.375 Z"
					id="unlocked-path-1"
				/>
			</defs>
			<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g transform="translate(-3.000000, 0.000000)">
					<mask id="unlocked-mask-2" fill="white">
						<use xlinkHref="#unlocked-path-1" />
					</mask>
					<g fillRule="nonzero" />
					<g mask="url(#unlocked-mask-2)" fill={fillColor} fillRule="evenodd">
						<rect x="0" y="0" width="24" height="24" />
					</g>
				</g>
			</g>
		</SvgIcon>
	);
};
export default icon;
