const restorePurgeStyle = () => ({
	dialog: {
		"& .MuiDialog-paper": {
			width: "560px",
			maxWidth: "100%",
		},
	},
	list: {
		listStyleType: "none",
		margin: "16px 0 0 0",
		paddingLeft: "0",
		overflow: "auto",
		maxHeight: "300px",
	},
	listItem: {
		display: "flex",
		alignItems: "center",
		height: "40px",
	},
	typeIcon: {
		display: "flex",
		alignItems: "center",
		marginRight: "12px",
	},
	title: {
		overflow: "hidden",
		textOverflow: "ellipsis",
		display: "-webkit-box",
		WebkitBoxOrient: "vertical",
		WebkitLineClamp: 1,
	},
	progressBar: {
		width: "240px",
		margin: "24px auto",
	},
});

export default restorePurgeStyle;
