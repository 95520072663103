import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import request from "superagent";

import { Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import settingsStyle from "assets/jss/components/settingsStyle";
import ButtonWithTooltip from "atlas/components/Buttons/ButtonWithTooltip";
import { MEDIUM } from "atlas/utils/buttonSize";
import { API_HOST } from "config/env";
import { setSnackbarOptions } from "redux/snackBar/actions";
import notifierMessage from "utils/notifierMessage";
import telemetryAddEvent from "utils/telemetryAddEvent";
import DataExportProgress from "./DataExportProgress";

const useStyles = makeStyles(settingsStyle);

const clearExportingProgress = {
	label: " ",
	percent: 0,
};
const statusCheckTimeout = 5000;

const telemetryPage = "Data export";

const DataExport = () => {
	const { t } = useTranslation("settings");
	const [exporting, setExporting] = useState({ active: false });
	const [showDownload, setShowDownload] = useState(false);
	const [dialogs, setDialogs] = useState({});
	const [progress, setProgress] = useState({
		...clearExportingProgress,
	});
	const statusCheckHandle = useRef();
	const active = useRef(true);
	const errors = useRef(0);
	const dispatch = useDispatch();
	const classes = useStyles();

	const checkExportStatus = () => {
		request
			.get(`${API_HOST}/api/system/dataexportstatus`)
			.withCredentials()
			.then((res) => {
				const {
					body: { percentage, step, active },
				} = res;

				setProgress({ percent: percentage, label: step });

				if (percentage >= 100) {
					if (statusCheckHandle.current) {
						// This is a proxy to see if we have reached 100% once already to avoid duplicate snackbars
						let option = notifierMessage(t("dataExport.snackbar.success"), "success");
						dispatch(setSnackbarOptions(option));
					}

					clearStatusCheck();

					setExporting((prev) => ({ ...prev, active: false }));
					setShowDownload(true);
				} else {
					if (active) {
						setDialogs({ exportProgress: true });
						scheduleStatusCheck();
					}
				}

				errors.current = 0;
			})
			.catch((err) => {
				if (err.status === 500 && errors.current < 10) {
					errors.current++; // Retry 10 times

					setDialogs({ exportProgress: true });
					scheduleStatusCheck();
				}
			});
	};

	const scheduleStatusCheck = () => {
		statusCheckHandle.current = setTimeout(checkExportStatus, statusCheckTimeout);
	};

	const clearStatusCheck = () => {
		if (statusCheckHandle.current) {
			clearTimeout(statusCheckHandle.current);
			statusCheckHandle.current = null;
		}
	};

	const handleExport = () => {
		setExporting((prev) => ({ ...prev, active: true }));
		clearProgress(true);

		telemetryAddEvent(`${telemetryPage} - Data Export`);

		request
			.post(`${API_HOST}/api/system/dataexport`)
			.withCredentials()
			.send({})
			.then((res) => {
				const { status } = res;

				if (status === 200) {
					// Start the periodic status check
					scheduleStatusCheck();
				} else {
					setExporting((prev) => ({ ...prev, active: false }));
				}
			})
			.catch((err) => {
				setExporting((prev) => ({ ...prev, active: false }));

				if (err.status === 409) {
					let option = notifierMessage(t("dataExport.snackbar.exportActive"), "error");
					dispatch(setSnackbarOptions(option));
				} else if (err.status === 500) {
					let option = notifierMessage(t("dataExport.snackbar.unableToExport"), "error");
					dispatch(setSnackbarOptions(option));
				}
			});
	};

	const handleCloseProgress = () => {
		clearProgress();
		loadLogs();
	};

	const clearProgress = () => {
		setDialogs({ exportProgress: false });
		setProgress({
			...clearExportingProgress,
		});
	};

	useEffect(() => {
		checkExportStatus();

		return () => {
			// Clear any pending status check timeouts
			clearStatusCheck();

			active.current = false;
		};
	}, []);

	return (
		<>
			{dialogs.exportProgress && progress.percent > 0 && (
				<DataExportProgress
					progress={progress}
					show={Boolean(dialogs.exportProgress)}
					handleClose={handleCloseProgress}
				></DataExportProgress>
			)}
			<div className={classes.labelWithOtherContent}>
				<Typography variant="h4" className={classes.bold}>
					{t("dataExport.labels.title")}
				</Typography>
			</div>
			<div className={classes.subSection}>
				<ButtonWithTooltip
					variant="outlined"
					title={t("dataExport.tooltips.exportData")}
					size={MEDIUM}
					onClick={handleExport}
					data-cy="data-export"
					disabled={exporting.active}
				>
					{t("dataExport.buttons.exportData")}
				</ButtonWithTooltip>
			</div>
			{showDownload && (
				<div>
					<a href="/api/system/dataexportdownload">{t("dataExport.labels.download")}</a>
				</div>
			)}
		</>
	);
};

export default DataExport;
