export default {
	agendaItem: "Agenda item",
	agendaItems: "Agenda items",
	from: "from",
	to: "to",
	unsavedChanges: "Changes you made may not be saved.",
	unsavedUploading:
		"One or more attachments are currently being uploaded. Navigating away from this page will cause the attachment upload to fail. We recommend staying on this page until the upload is completed.",
	coverTemplateUploadSuccess: "Cover report template uploaded",
	coverTemplateWrongExtension: "Unsupported document type",
	coverTemplateCreateSuccess: "Cover report template created",
	agendaStatus: {
		notPublished: "Not published",
		published: "Published",
	},
	approveDialog: {
		buttons: {
			cancel: "Cancel",
			approveItem: "Approve item",
		},
		labels: {
			comments: "Comments (optional)",
		},
		title: "Approve",
	},
	buttons: {
		addItem: "+ agenda item",
		addToAgenda: "Add to agenda",
		approveItem: "Approve",
		cancel: "Cancel",
		cancelApproval: "Cancel approval",
		changeMeeting: "Change meeting",
		delete: "Delete",
		duplicate: "Duplicate",
		editWorkflow: "Edit workflow",
		loadMore: "Load more",
		openAgendaItemEditor: "Open agenda item editor",
		rejectItem: "Reject",
		resubmitItem: "Resubmit for approval",
		selectMeeting: "Select meeting",
		sendSmsReminder: "Send SMS reminder to next approver",
		showHideFilter: "Show/Hide Filter",
		submitItem: "Submit item",
		update: "Update",
		createCover: "Create cover",
		editCover: "Edit cover",
		replace: "Replace",
		addNewCoverReportTemplate: "Upload template",
		createTemplate: "Create template",
	},
	coverTemplateList: {
		noResults: {
			title: "No cover templates found",
			description: "We couldn't find any cover templates",
		},
		header: {
			name: "Name",
		},
	},
	cancelApprovalDialog: {
		buttons: {
			cancel: "Cancel",
			cancelApproval: "Cancel approval",
		},
		instructions: "Are you sure you want to cancel the approval and send the item back to draft?",
		snackbar: {
			success: "Agenda item approval canceled successfully",
		},
		title: "Cancel approval",
	},
	deleteItemDialog: {
		line1: "You are about to delete ",
		line2: "This will remove all information associated with this item.",
		title: "Delete agenda item",
		buttons: {
			cancel: "Cancel",
			delete: "Delete",
		},
		snackbar: {
			success: "Agenda item deleted successfully",
		},
		undo: {
			error: "Agenda item undo delete failed",
			successful: "Agenda item undo delete successful",
		},
	},
	duplicateDialog: {
		snackbar: {
			success: "Agenda item duplicated successfully",
		},
	},
	settingsDialog: {
		hideRecommendations: "Hide recommended action on the public agenda",
		title: "Agenda settings",
		publishPublicAgendaLabel: "Publish public agenda release day",
		showConfidentialItemLabel: "Show member only item titles on public agenda documents",
		publishSettingsLabel: "Publish settings:",
		options: {
			publicReleaseWhenMeetingIsPublished: "No delay, When meeting is published",
			publicReleaseOnMeetingDay: "Day of the meeting",
			publicRelease1DayBeforeOfMeeting: "1 day before the meeting",
			publicRelease2DaysBeforeOfMeeting: "2 days before the meeting",
			publicRelease3DaysBeforeOfMeeting: "3 days before the meeting",
			publicRelease4DaysBeforeOfMeeting: "4 days before the meeting",
			publicRelease5DaysBeforeOfMeeting: "5 days before the meeting",
			publicRelease6DaysBeforeOfMeeting: "6 days before the meeting",
			publicRelease7DaysBeforeOfMeeting: "7 days before the meeting",
		},
	},
	editItem: {
		agendaStatus: "Agenda status",
		approvalTitle: "Approve agenda item",
		attachmentsDocumentLabel: "Attachments document",
		commentsHeading: "Comments",
		descriptionLabel: "Agenda item description",
		detailHeading: "Agenda item details",
		itemStatus: "Item status",
		meeting: "Meeting",
		nameLabel: "Agenda item title",
		recommendedActionLabel: "Recommended action",
		section: "Section",
		title: "Edit agenda item",
		titleAdded: "Added agenda item",
		addAttachments: "Add attachments",
		addAttachmentsToDescription: "Add Attachments to Agenda Item Description",
		coverTemplate: "Cover template",
		coverReportTemplate: "Cover report template",
		coverAttachments: "Cover & attachments",
		selectTemplate: "Select template",
	},
	errorDialog: {
		button: "Got it",
		buttonFromList: "Go to agenda item",
		descriptionFailedFiles: "The following attachments were not uploaded successfully:",
		titleFailedUploads: "Agenda Item has invalid attachments",
	},
	itemStatus: {
		added: "Added",
		draft: "Draft",
		submitted: "Submitted",
	},
	meetingStatus: {
		notSelected: "Not selected",
	},
	noItems: {
		added: {
			title: "No items",
			description:
				"There are no added agenda items. When an item is approved by the entire approval group, it will show here, and the item will be added to the meeting agenda.",
		},
		filter: {
			title: "No matches found",
			description: "We couldn't find any agenda items that match the filters you set",
		},
		draft: {
			title: "Welcome!",
			description:
				"There are no draft agenda items. To create one, select “+ Agenda item”. For assistance with approvals, please contact the <1>{{support}}</1>",
			implementationOrSupport: "implementation or support team",
		},
		rejected: {
			title: "No items",
			description:
				"There are no rejected agenda items. When an item is rejected by one or more members of the approval group, it will show here.",
		},
		submitted: {
			title: "No items",
			description:
				"There are no submitted agenda items. To create one, select “+Agenda item”. To submit an existing one, go to the Draft tab, select an agenda item, and then select “Submit item”.",
		},
		pending: {
			title: "Congratulations",
			description:
				"There are no pending agenda items for you to review. When a new agenda item is submitted for you, you can approve or reject it here.",
		},
	},
	rejectDialog: {
		buttons: {
			cancel: "Cancel",
			rejectItem: "Reject item",
		},
		errors: {
			comments: "Please enter a comment",
		},
		labels: {
			comments: "Comments",
		},
		title: "Reject",
	},
	rejectedFromAgenda: "Rejected from agenda",
	sectionStatus: {
		notSelected: "Not selected",
	},
	selectMeetingDialog: {
		buttons: {
			cancel: "Cancel",
			gotIt: "Got it",
			selectMeeting: "Select Meeting",
		},
		comments: "Comments (optional)",
		meeting: "Meeting",
		meetingGroup: "Meeting Group",
		message: "Select a meeting group and meeting to add this item to. Note: This item has not yet been added to the agenda.",
		noMeetings: {
			message: "We couldn't find any upcoming meetings.",
			title: "No meetings found",
		},
		options: {
			none: "None",
		},
		section: "Section",
		snackbar: {
			success: "Agenda item meeting selected successfully",
		},
		titleChange: "Change meeting",
		titleSelect: "Select meeting",
	},
	sendReminderDialog: {
		buttons: {
			cancel: "Cancel",
			sendReminder: "Send",
		},
		instructions: "Phone number(s) for reminder",
		noUsers: "No Users Available",
		results: {
			failed: "SMS failed",
			instructions: "Message status",
			invalid: "number invalid",
			profileUpdated: "Profile updated",
			success: "SMS sent",
		},
		saveNumber: "Save number to profile of user",
		snackbar: {
			failure: "SMS reminder had an problem",
			success: "SMS reminder sent to next approver(s)",
			profileUpdated: "Phone number saved to {{name}}'s profile",
		},
		title: "Send Urgent Reminder",
	},
	submissionDetails: {
		approvalRejectedBy: "Approval rejected by: {{rejectingApprover}}",
		awaitingApprovalFrom: "Awaiting approval from: {{pendingApprovers}}",
		itemSubmittedBy: "Item submitted by: {{createdBy}}, {{dateCreated}}",
		itemSubmittedByEnd: " submitted by: {{createdBy}}, {{dateCreated}}",
		itemSubmittedByStart: "Item",
	},
	submitForApprovalDialog: {
		buttons: {
			cancel: "Cancel",
			submitForApproval: "Submit for approval",
		},
		instructions: "Please select the approval group you want for this item:",
		labels: {
			workflows: "Approval group",
		},
		snackbar: {
			success: "Agenda item submitted for approval successfully",
		},
		title: "Submit for approval",
		warnings: {
			meetingInPast:
				"You are submitting this agenda item to a meeting that has already taken place. Please submit your item to a future meeting.",
		},
	},
	deleteCoverTemplateDialog: {
		line1: "You are about to delete the cover template {{name}}",
		title: "Delete cover template",
		snackbar: {
			success: "The cover template has been deleted.",
		},
	},
	tabs: {
		added: "Added",
		filter: "Filter",
		rejected: "Rejected",
		submitted: "Submitted",
		pending: "My Pending",
		draft: "Draft",
		workflows: "Workflows",
		coverTemplates: "Cover reports",
	},
	toolbar: {
		cancelApproval: "Cancel Approval",
		commentsDetails: "Comments/Details",
		redo: "redo",
		resubmitItem: "Resubmit Item",
		selectMeeting: "Select Meeting",
		submitItem: "Submit Item",
		undo: "undo",
	},
	tooltips: {
		addItem: "Add a new agenda item",
		addToAgenda: "Add this item to the agenda",
		approveItem: "Approve this item",
		cancelApproval: "Cancel the approval for this item and send back to draft",
		changeMeeting: "Change meeting",
		commentsDetails: "View the comments and item details",
		delete: "Delete",
		duplicate: "Duplicate",
		editWorkflow: "Edit the approval workflow for this item",
		filter: "Show or hide the filter panel",
		openAgendaItemEditor: "Open the agenda item editor",
		rejectedFromAgenda: "Rejected by {{name}} from {{meeting}}",
		rejectItem: "Reject this item",
		resubmitItem: "Resubmit this item for approval",
		selectMeeting: "Select meeting",
		sendSmsReminder: "Send SMS reminder to next approver",
		showHideFilter: "Show/Hide Filter",
		submitItem: "Submit this item for approval",
		updateAttachmentsPdf: "Update attachments document",
		createCover: "Copy selected cover template",
		editCover: "Edit agenda item cover",
		replaceCoverTemplate: "Replace cover report template",
		deleteCoverTemplate: "Delete cover report template",
		addNewCoverReportTemplate: "Upload a new cover report template",
		createTemplate: "Create a new cover report template",
	},
	noMeetingsSelected: {
		noGroupSelected: "No group selected",
		noMeetingSelected: "No meeting selected",
		noSectionSlected: "No section selected",
	},
};
