import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import makeStyles from "@mui/styles/makeStyles";

import Icon from "atlas/components/Icon/Icon";
import OutlinedInput from "atlas/components/FormControls/OutlinedInput";
import GenericDialog from "atlas/components/Dialogs/GenericDialog";
import CircularProgressIndicator from "atlas/components/Progress/CircularProgressIndicator";

const useStyles = makeStyles(() => ({
	dialog: {
		"& .MuiDialog-paper": {
			width: "440px",
			maxWidth: "100%",
		},
	},
}));

const AgendaItemApproveDialog = (props) => {
	const { show, item, onApprove, onClose } = props;
	const { t } = useTranslation("agendaItems");
	const [comment, setComment] = useState("");
	const [approving, setApproving] = useState(false);
	const classes = useStyles();

	const handleFieldChange = (e) => {
		const {
			target: { value },
		} = e;

		setComment(value);
	};

	return (
		<GenericDialog
			className={classes.dialog}
			show={show}
			title={t("approveDialog.title")}
			primaryAction={() => {
				setApproving(true);
				onApprove(comment, item);
			}}
			primaryTitle={approving ? <CircularProgressIndicator size={20} minHeight="20px" /> : t("approveDialog.buttons.approveItem")}
			primaryDisabled={approving}
			secondaryAction={onClose}
			secondaryTitle={t("approveDialog.buttons.cancel")}
			secondaryDisabled={approving}
			clickAwayDisabled={approving}
			closeIcon={<Icon name="close" />}
			data-cy="approve-dialog"
		>
			<OutlinedInput
				noDefaultClassName
				id="comment"
				label={t("approveDialog.labels.comments")}
				externalLabel
				labelSize="large"
				value={comment}
				onChange={(e) => handleFieldChange(e)}
				fullWidth
				size="small"
				data-cy="approve-comment"
				multiline
			/>
		</GenericDialog>
	);
};

export default AgendaItemApproveDialog;
