import React from "react";
import { useTranslation } from "react-i18next";

import Droppable from "atlas/components/DragAndDrop/Droppable";
import Draggable from "atlas/components/DragAndDrop/Draggable";
import FolderPermissionUser from "./FolderPermissionUser";

const FolderPermissionUsers = (props) => {
	const {
		permissionUsers,
		editEnabled,
		handleUpdate,
		handleRemoveUser,
		classes,
		enableDragAndDrop,
		highlightDroppable,
		dragPlaceholders,
		telemetryPage,
	} = props;
	const { t } = useTranslation("documents");

	const removeProps = handleRemoveUser
		? {
				actionIcon: "remove",
				actionTooltip: t("tooltips.removeUserFromFolder"),
				handleAction: (e, userId) => {
					e.stopPropagation();

					handleRemoveUser(userId);
				},
		  }
		: {};

	const getContent = () =>
		permissionUsers.length > 0 ? (
			<ol id="selected-people" data-cy="folder-users">
				{permissionUsers.map(({ user, permissionUser }) =>
					enableDragAndDrop ? (
						<Draggable
							key={user.id}
							dragId={user.id}
							dragComponent={FolderPermissionUser}
							dragAttributePassthrough
							canDrag
							dragPlaceholder={dragPlaceholders.includes(user.id)}
							permissionUser={permissionUser}
							user={user}
							showEdit
							editEnabled={editEnabled}
							handleUpdate={handleUpdate}
							{...removeProps}
							telemetryPage={telemetryPage}
						></Draggable>
					) : (
						<FolderPermissionUser
							key={user.id}
							permissionUser={permissionUser}
							user={user}
							showEdit
							editEnabled={editEnabled}
							handleUpdate={handleUpdate}
							{...removeProps}
							telemetryPage={telemetryPage}
						></FolderPermissionUser>
					),
				)}
			</ol>
		) : (
			<div className={classes.noUsers}>
				<div>{t("customFolderDetail.noSelectedUsers")}</div>
			</div>
		);

	return enableDragAndDrop ? (
		<Droppable
			className={classes.selectedUsers}
			dropId="folder-users"
			useHighlightDroppable
			highlightDroppable={highlightDroppable}
			dropComponent="div"
		>
			{getContent()}
		</Droppable>
	) : (
		<div className={classes.selectedUsers}>{getContent()}</div>
	);
};

export default FolderPermissionUsers;
