import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import SearchInput from "components/Search/SearchInput";
import { updateToolbar } from "redux/app/actions";

const SearchTopBar = (props) => {
	const { keywords, handleSearch, isPolicy, onClearSearchClick } = props;
	const [searchKeywords, setSearchKeywords] = useState(keywords);
	const { t } = useTranslation("documents");
	const dispatch = useDispatch();

	const handleKeyDown = (e) => {
		if (e.key === "Enter") {
			handleSearch(searchKeywords);
		}
	};

	const handleChange = (e) => {
		const { value } = e.target;
		setSearchKeywords(value);
	};

	useEffect(() => {
		setSearchKeywords(keywords);
	}, [keywords]);

	// Display tools
	useEffect(() => {
		dispatch(
			updateToolbar({
				display: true,
				left: {},
				right: {
					tools: [
						{
							component: (
								<SearchInput
									key="toolbar-search"
									size="extra-small"
									value={searchKeywords || ""}
									showClearIcon={searchKeywords && searchKeywords.length > 0}
									onChange={handleChange}
									onKeyDown={handleKeyDown}
									onClearSearchClick={onClearSearchClick}
									onIconClick={() => {
										handleSearch(searchKeywords);
									}}
									onClearClick={() => {
										setSearchKeywords("");
									}}
									isPolicy={isPolicy}
								/>
							),
						},
					],
				},
			}),
		);
	}, [searchKeywords]);

	return null;
};

export default SearchTopBar;
