import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import clsx from "clsx";

import makeStyles from "@mui/styles/makeStyles";

import processHtml from "utils/processHtml";
import Icon from "atlas/components/Icon/Icon";
import { blackColor } from "atlas/assets/jss/shared";
import LiveMeetingRadioButton from "./LiveMeetingRadioButton";
import LiveMeetingAttachments from "./LiveMeetingAttachments";

import ButtonWithTooltip from "atlas/components/Buttons/ButtonWithTooltip";

import agendaStyle from "../../../assets/jss/components/agendaStyle";

const useStyle = makeStyles(agendaStyle);

const LiveMeetingAgendaItem = (props) => {
	const {
		item,
		isClosedMeeting,
		isMemberOnlyHeading,
		isConsentHeading,
		isPublicCommentHeading,
		hasMotions,
		addBottomBorder,
		selected,
		hasNotes,
		handleSelect,
		presentation,
		moveConsentItemClick,
		testSite,
	} = props;
	const { t } = useTranslation();
	const { fields, attachments } = item;
	const classes = useStyle({ addTopBorder: true });

	const [showMoveButton, setShowMoveButton] = useState(false);

	const isSelected = selected === item.guid;
	const addBottomBorderToThis = addBottomBorder && item.attachments.length === 0;

	return (
		!item.deleted && (
			<>
				<li
					id={`agenda-${item.guid}`}
					className={clsx(classes.agendaListItem, {
						[classes.radioListItem]: !presentation,
						[classes.borderDefault]: !isMemberOnlyHeading && !isConsentHeading && !isPublicCommentHeading && !addBottomBorderToThis,
						[classes.borderDefaultBottomBorder]:
							!isMemberOnlyHeading && !isConsentHeading && !isPublicCommentHeading && addBottomBorderToThis,
						[classes.borderMemberOnly]: isMemberOnlyHeading,
						[classes.borderConsent]: isConsentHeading,
						[classes.borderPublicComment]: isPublicCommentHeading,
						[classes.selected]: isSelected && !presentation,
						[classes.presentationSelected]: isSelected && presentation,
					})}
					data-cy={`agenda-${item.guid}`}
					onMouseEnter={() => {
						setShowMoveButton(true);
					}}
					onMouseLeave={() => {
						setShowMoveButton(false);
					}}
				>
					{!presentation && (
						<LiveMeetingRadioButton
							value={item.guid}
							label={fields.Name.Value}
							selected={isSelected}
							hasNotes={hasNotes}
							handleSelect={handleSelect}
						/>
					)}
					<div
						className={clsx(classes.item, classes.itemItem, classes.rightBorder, {
							[classes.bottomBorder]: addBottomBorderToThis,
						})}
					>
						{fields.Number.Value.length > 0 && <div className={classes.bullet}>{fields.Number.Value}</div>}
						<div className={classes.itemText}>
							{processHtml(fields.Name.Value, {
								disableLinks: !presentation,
								classes: { link: classes.disabledLink },
								excludeMemberOnlyAttachments: presentation,
							})}
							{presentation && processHtml(fields.Text.Value, { excludeMemberOnlyAttachments: presentation })}
						</div>
						{testSite && isConsentHeading && !presentation && (isSelected || showMoveButton) && (
							<ButtonWithTooltip
								className={classes.button}
								variant="outlined"
								color="primary"
								size="small"
								onClick={() => moveConsentItemClick(item)}
								dataCy="moveConsentItem"
								title={t("meetings:tooltips.moveConsentItem")}
							>
								{t("meetings:buttons.move")}
							</ButtonWithTooltip>
						)}
						{hasMotions && (
							<div
								className={clsx(classes.itemMotionIcon, classes.itemIcon)}
								title={t("meetings:tooltips.itemHasMotions")}
								data-icon="vote"
								data-cy="has-motions"
							>
								<Icon name={hasMotions.approved ? "vote" : "motion"} color={blackColor[1]} />
							</div>
						)}
					</div>
				</li>
				{!presentation && (
					<LiveMeetingAttachments
						attachments={attachments}
						item={item}
						isClosedMeeting={isClosedMeeting}
						isMemberOnlyHeading={isMemberOnlyHeading}
						isConsentHeading={isConsentHeading}
						isPublicCommentHeading={isPublicCommentHeading}
						addBottomBorder={addBottomBorder}
						selected={selected}
						handleSelect={handleSelect}
						presentation={presentation}
					/>
				)}
			</>
		)
	);
};

LiveMeetingAgendaItem.propTypes = {
	item: PropTypes.object.isRequired,
	isClosedMeeting: PropTypes.bool,
	isMemberOnlyHeading: PropTypes.bool,
	isConsentHeading: PropTypes.bool,
	isPublicCommentHeading: PropTypes.bool,
	addBottomBorder: PropTypes.bool,
	selected: PropTypes.string.isRequired,
	hasNotes: PropTypes.bool,
	handleSelect: PropTypes.func,
};

LiveMeetingAgendaItem.defaultProps = {
	isClosedMeeting: false,
	isMemberOnlyHeading: false,
	isConsentHeading: false,
	isPublicCommentHeading: false,
	addBottomBorder: false,
	hasNotes: false,
	handleSelect: undefined,
};

export default LiveMeetingAgendaItem;
