import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import Icon from "atlas/components/Icon/Icon";
import ProgressBar from "atlas/components/Progress/ProgressBar";
import { useTranslation } from "react-i18next";

const useEditorStyles = makeStyles(() => ({
	goalName: {
		display: "flex",
		alignItems: "center",
		gap: "8px",
		marginBottom: "16px",
	},
	goalNameText: {
		fontWeight: "600",
		fontSize: "18px",
		wordBreak: "break-word",
	},
	progressText: {
		fontWeight: "600",
		fontSIze: "16px",
	},
	progressTextAndPercent: {
		display: "flex",
		justifyContent: "space-between",
	},
	progressBar: {
		marginTop: "24px",
	},
}));

const GoalHeadingData = (props) => {
	const { goal } = props;
	const { name, completionPercentage } = goal;
	const { t } = useTranslation("goals");
	const editorClasses = useEditorStyles();
	return (
		<>
			<div className={editorClasses.goalName}>
				<div>{goal.closed ? <Icon name={"goal-name-locked"} /> : <Icon name={"goal-name"} />}</div>
				<div className={editorClasses.goalNameText}>{name}</div>
			</div>
			{completionPercentage !== -1 && (
				<div>
					<div className={editorClasses.progressTextAndPercent}>
						<div className={editorClasses.progressText}>{t("progress")}</div>
						<div className={editorClasses.progressText}>{completionPercentage + "%"}</div>
					</div>
					<div>
						<ProgressBar className={editorClasses.progressBar} progress={completionPercentage} maxLabelWidthPercent={100} />
					</div>
				</div>
			)}
		</>
	);
};

export default GoalHeadingData;
