import React from "react";
import clsx from "clsx";

import makeStyles from "@mui/styles/makeStyles";
import { Avatar } from "@mui/material";

import { blackColor, whiteColor, backgroundColor } from "atlas/assets/jss/shared";
import Icon from "atlas/components/Icon/Icon";

export const GENERIC_AVATAR_BACKGROUNDS = 5;

const useStyles = makeStyles(() => ({
	avatar: {
		fontSize: "1rem",
		fontWeight: "600",
		backgroundColor: backgroundColor[3],
	},
	backgroundBlank: {
		backgroundColor: whiteColor,
		color: blackColor[0],
	},
	"background-1": {
		"&.MuiAvatar-colorDefault": {
			backgroundColor: "#d7dde7",
			color: blackColor[0],
		},
	},
	"background-2": {
		"&.MuiAvatar-colorDefault": {
			backgroundColor: "#efb5b6",
			color: blackColor[0],
		},
	},
	"background-3": {
		"&.MuiAvatar-colorDefault": {
			backgroundColor: "#f1c189",
			color: blackColor[0],
		},
	},
	"background-4": {
		"&.MuiAvatar-colorDefault": {
			backgroundColor: "#c0dbd3",
			color: blackColor[0],
		},
	},
	"background-5": {
		"&.MuiAvatar-colorDefault": {
			backgroundColor: "#a5ccec",
			color: blackColor[0],
		},
	},
	"background-1-disabled": {
		"&.MuiAvatar-colorDefault": {
			backgroundColor: "#d7dde799",
			color: blackColor[0],
		},
	},
	"background-2-disabled": {
		"&.MuiAvatar-colorDefault": {
			backgroundColor: "#efb5b699",
			color: blackColor[0],
		},
	},
	"background-3-disabled": {
		"&.MuiAvatar-colorDefault": {
			backgroundColor: "#f1c18999",
			color: blackColor[0],
		},
	},
	"background-4-disabled": {
		"&.MuiAvatar-colorDefault": {
			backgroundColor: "#c0dbd399",
			color: blackColor[0],
		},
	},
	"background-5-disabled": {
		"&.MuiAvatar-colorDefault": {
			backgroundColor: "#a5ccec99",
			color: blackColor[0],
		},
	},
}));

const GenericAvatar = (props) => {
	const { className, id, imageUrl, name = "", initials, initialsSource, initialsLength = 1, backgroundNumber, disabled = false } = props;
	const classes = useStyles();

	const initialsText = !imageUrl ? (initials || String(initialsSource || name).substr(0, initialsLength)).toUpperCase() : "";

	return imageUrl ? (
		<Avatar className={className} id={id} alt={name} src={imageUrl} />
	) : (
		<Avatar
			className={clsx(classes.avatar, className, {
				[classes.backgroundBlank]: !initialsText,
				[classes[`background-${backgroundNumber}${disabled ? "-disabled" : ""}`]]: initialsText,
			})}
			id={id}
		>
			{initialsText || <Icon name="avatar" size="48" />}
		</Avatar>
	);
};

export default GenericAvatar;
