/* eslint-disable react/require-default-props */
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { ButtonBase } from '@mui/material';
import withStyles from '@mui/styles/withStyles';

import Icon from '../Icon/Icon';

import { whiteColor } from '../../assets/jss/shared';

export const styles = {
	/* Styles applied to the root element. */
	root: {
		width: 40,
		flexShrink: 0,
		'&$disabled': {
			opacity: 0,
		},
	},
	/* Styles applied to the root element if `orientation="vertical"`. */
	vertical: {
		width: '100%',
		height: 40,
		'& svg': {
			transform: 'rotate(90deg)',
		},
	},
	/* Pseudo-class applied to the root element if `disabled={true}`. */
	disabled: {},
};

const TabsHeaderScrollButton = React.forwardRef(function TabsHeaderScrollButton(props, ref) {
	const { tReady, classes, className: classNameProp, direction, orientation, disabled, i18n, visible, ...other } = props;
	const { t } = useTranslation();

	const isDisabled = typeof disabled !== 'undefined' ? disabled : !visible; // I don't know why "visible" is being passed in by the Tabs component instead of "disabled", so this check is to support both if Material UI version updates changes this behavior

	return (
		<ButtonBase
			component="div"
			className={clsx(
				classes.root,
				{
					[classes.vertical]: orientation === 'vertical',
					[classes.disabled]: isDisabled,
				},
				classNameProp,
			)}
			ref={ref}
			role="button"
			aria-label={t(direction === 'left' ? 'tabs.scrollLeft' : 'tabs.scrollLeft')}
			tabIndex={null}
			{...other}
		>
			<Icon name={direction === 'left' ? 'expand-left' : 'expand-right'} color={whiteColor} />
		</ButtonBase>
	);
});

TabsHeaderScrollButton.propTypes = {
	// ----------------------------- Warning --------------------------------
	// | These PropTypes are generated from the TypeScript type definitions |
	// |     To update them edit the d.ts file and run "yarn proptypes"     |
	// ----------------------------------------------------------------------
	/**
	 * The content of the component.
	 */
	children: PropTypes.node,
	/**
	 * Override or extend the styles applied to the component.
	 * See [CSS API](#css) below for more details.
	 */
	classes: PropTypes.object,
	/**
	 * @ignore
	 */
	className: PropTypes.string,
	/**
	 * Which direction should the button indicate?
	 */
	direction: PropTypes.oneOf(['left', 'right']).isRequired,
	/**
	 * If `true`, the element will be disabled.
	 */
	disabled: PropTypes.bool,
	/**
	 * The tabs orientation (layout flow direction).
	 */
	orientation: PropTypes.oneOf(['horizontal', 'vertical']).isRequired,
};

export default withStyles(styles, { name: 'MuiTabScrollButton' })(TabsHeaderScrollButton);
