import React, { Fragment } from "react";

import ApprovalProgressApprover from "./ApprovalProgressApprover";
import ApprovalProgressPendingArrow from "./ApprovalProgressPendingArrow";
import { WORKFLOW_STATUS_PENDING, WORKFLOW_STATUS_APPROVED, WORKFLOW_STATUS_DENIED } from "utils/workflowStatuses";

const ApprovalProgressApprovers = (props) => {
	const { block, tooltip, highlightPending, groupInactive, expanded } = props;
	const activeStatuses = [WORKFLOW_STATUS_PENDING, WORKFLOW_STATUS_APPROVED, WORKFLOW_STATUS_DENIED];

	return (
		block &&
		block.users
			.filter((user) => user.visible)
			.map((user, index) => (
				<Fragment key={`workflow-block-${block.id}-user-${user.id}`}>
					{user.showPendingArrow && <ApprovalProgressPendingArrow dataCy={`workflow-block-${block.id}-pending`} />}
					<ApprovalProgressApprover
						block={block}
						user={user}
						tooltip={tooltip}
						highlightPending={highlightPending}
						inactiveData={{
							group: groupInactive,
							previousInactive: index > 0 && !activeStatuses.includes(block.users[index - 1].status),
							inactive: !activeStatuses.includes(user.status),
							zIndex: block.users.length - index,
						}}
						expanded={expanded}
					/>
				</Fragment>
			))
	);
};

export default ApprovalProgressApprovers;
