import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { RgbStringColorPicker } from "react-colorful";
import { useDropzone } from "react-dropzone";
import clsx from "clsx";

import makeStyles from "@mui/styles/makeStyles";

import { useSelector, useDispatch } from "react-redux";
import settingsStyle from "assets/jss/components/settingsStyle";
import { primaryColor } from "atlas/assets/jss/shared";
import ButtonWithTooltip from "atlas/components/Buttons/ButtonWithTooltip";
import Typography from "@mui/material/Typography";
import Accordion from "atlas/components/Accordion/Accordion";
import accordionStyle from "assets/jss/components/accordionStyle";
import OutlinedInput from "atlas/components/FormControls/OutlinedInput";
import Icon from "atlas/components/Icon/Icon";
import { MEDIUM } from "atlas/utils/buttonSize";
import { processImage } from "utils/image";
import { updateInitialSetting, setSettingError } from "redux/publicSite/actions";
import { useEffect } from "react";

import notifierMessage from "utils/notifierMessage";
import { setSnackbarOptions } from "redux/snackBar/actions";

const useStyles = makeStyles(settingsStyle);

const contrastLevels = {
	fail: {
		range: [0, 4.5],
		color: "hsl(0, 100%, 40%)",
		text: "contrast.fail",
	},
	aa: {
		range: [4.5, 7],
		color: "hsl(80, 60%, 30%)",
		text: "contrast.aa",
	},
	aaa: {
		range: [7, 22],
		color: "hsl(95, 60%, 30%)",
		text: "contrast.aaa",
	},
	uploadError: {
		color: "#d22c2c",
	},
};
const CONTRAST_COLOR = "rgb(255, 255, 255)";

const DisplayOptions = (props) => {
	const getComponents = (color) => {
		let components = [];
		if (color) {
			if (color.indexOf("rgb(") === 0) {
				components = color.substring(color.indexOf("(") + 1, color.length - 1).split(",");
				for (let index = 0; index < 3 && index < components.length; index++) {
					let rgb = components[index].replace(/^\s+/, "");
					components[index] = parseInt(rgb, 10);
				}
			} else if (color.indexOf("#") === 0 && [4, 7].indexOf(color.length)) {
				const short = color.length === 4;
				components = [
					color.substr(1, short ? 1 : 2),
					color.substr(short ? 2 : 3, short ? 1 : 2),
					color.substr(short ? 3 : 5, short ? 1 : 2),
				];
				for (let index = 0; index < components.length; index++) {
					let rgb = components[index];
					components[index] = parseInt(rgb, 16);
				}
			}
		}

		return components.length === 3 && typeof components.find((rgb) => !(rgb >= 0 && rgb <= 255)) === "undefined" ? components : [];
	};

	const rgbToHex = (components) => {
		let hex = "#000000";
		if (components && components.length === 3) {
			hex = `#${components[0].toString(16).padStart(2, "0")}${components[1].toString(16).padStart(2, "0")}${components[2]
				.toString(16)
				.padStart(2, "0")}`;
		}

		return hex;
	};

	const hexToRgb = (components) => {
		let rgb = "rgb(0,0,0)";
		if (components && components.length === 3) {
			rgb = `rgb(${components[0]},${components[1]},${components[2]})`;
		}

		return rgb;
	};

	const { settings, startOpen = false } = props;
	const { t } = useTranslation("settings");
	const [errors, setErrors] = useState({});
	const [hexColor, setHexColor] = useState(rgbToHex(getComponents(settings.backgroundColor)));
	const classes = useStyles();
	const useAccordionStyles = makeStyles(accordionStyle);
	const accordionClasses = useAccordionStyles();
	const dispatch = useDispatch();
	const { backgroundColor, backgroundImageUrl, logoImageUrl, publicSiteErrors } = useSelector((state) => state.publicSiteReducer);
	const handleChange = (e, name) => {
		const {
			target: { value },
		} = e;
		dispatch(updateInitialSetting({ [name]: value }));
	};

	useEffect(() => {
		const contrastRatio = calculateContrastRatio(backgroundColor);
		const contrastValue = contrastRatio.contrast;

		let newErrors = {};
		if (contrastValue < 4.5) {
			newErrors["contrastRatioError"] = t("errorMessage.contrastRatio");
			dispatch(setSettingError({ ...publicSiteErrors, ...newErrors }));
		} else {
			newErrors["contrastRatioError"] = null;
			dispatch(setSettingError({ ...publicSiteErrors, ...newErrors }));
		}
	}, [backgroundColor]);

	const getLuminance = (rgbaColor) => {
		const rgba = getComponents(rgbaColor);

		if (rgba.length < 3) {
			return NaN;
		}

		for (let index = 0; index < 3; index++) {
			let rgb = rgba[index];
			rgb /= 255;
			rgb = rgb < 0.03928 ? rgb / 12.92 : Math.pow((rgb + 0.055) / 1.055, 2.4);
			rgba[index] = rgb;
		}

		return 0.2126 * rgba[0] + 0.7152 * rgba[1] + 0.0722 * rgba[2];
	};

	const getContrast = (luminance1, luminance2) => {
		let modifiedLuminance1 = luminance1 + 0.05;
		let modifiedLuminance2 = luminance2 + 0.05;
		let ratio = modifiedLuminance1 / modifiedLuminance2;
		if (modifiedLuminance2 > modifiedLuminance1) {
			ratio = 1 / ratio;
		}
		return ratio.toFixed(1);
	};

	const calculateContrastRatio = (rgbaColor) => {
		//WCAG 2.0 - External Link level AA requires a contrast ratio of 4.5:1 for normal text and 3:1 for large text. Level AAA requires a contrast ratio of 7:1 for normal text and 4.5:1 for large text.
		const luminance1 = getLuminance(rgbaColor);
		const luminance2 = getLuminance(CONTRAST_COLOR);
		const contrast = getContrast(luminance1, luminance2);
		const result = { contrast, level: contrastLevels.fail };

		for (const level in contrastLevels) {
			if (contrastLevels.hasOwnProperty(level)) {
				const bounds = contrastLevels && contrastLevels[level].range,
					lower = bounds && bounds[0],
					upper = bounds && bounds[1];

				if (contrast < upper && contrast >= lower) {
					result.level = contrastLevels[level];
				}
			}
		}
		return result;
	};

	const handleBackgroundImageDrop = useCallback(
		(acceptedFiles) => {
			let newErrors = {};
			const reader = new FileReader();

			reader.onabort = () => console.log("file reading was aborted");
			reader.onerror = () => console.log("file reading has failed");
			reader.onload = () => {
				processImage(true, reader.result, (image) => {
					if (image.valid) {
						handleChange({ target: { value: image.data } }, "backgroundImageUrl");
						newErrors["backgroundImage"] = null;
						setErrors({ newErrors });
						let option = notifierMessage(t("snackbarMessage.backgroundImageAdded"), "success");
						dispatch(setSnackbarOptions(option));
					} else {
						newErrors["backgroundImage"] = t("errorMessage.backgroundImage");
						let option = notifierMessage(t("snackbarMessage.imageUploadError"), "error");
						dispatch(setSnackbarOptions(option));
						setErrors({ newErrors });
					}
				});
			};

			acceptedFiles.forEach((file) => reader.readAsDataURL(file));
		},
		[handleChange],
	);

	const { getRootProps: getBackgroundImageRootProps, getInputProps: getBackgroundImageInputProps } = useDropzone({
		accept: {
			"image/*": [".jpeg", ".png", ".jpg"],
		},
		onDrop: handleBackgroundImageDrop,
	});
	const backgroundImageRootProps = settings ? getBackgroundImageRootProps() : {};

	const handleLogoDrop = useCallback(
		(acceptedFiles) => {
			const reader = new FileReader();

			reader.onabort = () => console.log("file reading was aborted");
			reader.onerror = () => console.log("file reading has failed");
			reader.onload = () => {
				processImage(false, reader.result, (image) => {
					handleChange({ target: { value: image.data } }, "logoImageUrl");
					let option = notifierMessage(t("snackbarMessage.logoAdded"), "success");
					dispatch(setSnackbarOptions(option));
				});
			};

			acceptedFiles.forEach((file) => reader.readAsDataURL(file));
		},
		[handleChange],
	);

	const { getRootProps: getLogoRootProps, getInputProps: getLogoInputProps } = useDropzone({
		accept: {
			"image/*": [".jpeg", ".png", ".jpg"],
		},
		onDrop: handleLogoDrop,
	});

	const removeImage = () => {
		handleChange({ target: { value: "" } }, "backgroundImageUrl");
		let option = notifierMessage(t("snackbarMessage.imageDeleted"), "success");
		dispatch(setSnackbarOptions(option));
	};
	const logoRootProps = settings ? getLogoRootProps() : {};

	const contrastRatio = calculateContrastRatio(backgroundColor);
	const validColor = !isNaN(contrastRatio.contrast);

	return (
		<>
			<div className={clsx(classes.section, classes.sectionAccordion)}>
				<Accordion classes={accordionClasses} label={t("titles.display")} startOpen={startOpen} overflow={false} dataCy="display-options">
					<div>
						<Typography class="sm"> {t("upload.portalColor")} </Typography>
						<div className={classes.displayOptionIndent}>
							<div className={classes.colorPicker}>
								<RgbStringColorPicker
									color={backgroundColor}
									onChange={(color) => {
										handleChange({ target: { value: color } }, "backgroundColor");
										setHexColor(rgbToHex(getComponents(color)));
									}}
								/>
							</div>
							<div className={classes.additionalContent}>
								<div>
									<OutlinedInput
										id="portal-color-string"
										value={backgroundColor}
										onChange={(e) => {
											handleChange(e, "backgroundColor");
											setHexColor(rgbToHex(getComponents(e.target.value)));
										}}
										InputProps={{
											className: clsx(classes.colorIndicator, {
												[classes.colorIndicatorInvalid]: !validColor,
											}),
											style: { backgroundColor: validColor ? backgroundColor : undefined },
											inputProps: { "aria-label": t("labels.portalRgbColor") },
										}}
										noDefaultClassName
										externalLabel
										placeholder="rgb(0,0,0)"
										helperText={!validColor ? t("rgb") : " "}
										error={!validColor}
										size="small"
										data-cy="portal-color-string"
									/>
								</div>

								<div>
									<OutlinedInput
										id="portal-color-string-hex"
										value={hexColor}
										onChange={(e) => {
											handleChange({ target: { value: hexToRgb(getComponents(e.target.value)) } }, "backgroundColor");
											setHexColor(e.target.value);
										}}
										InputProps={{
											className: clsx(classes.colorIndicator, {
												[classes.colorIndicatorInvalid]: !validColor,
											}),
											style: { backgroundColor: validColor ? backgroundColor : undefined },
											inputProps: { "aria-label": t("labels.portalHexColor") },
										}}
										noDefaultClassName
										externalLabel
										placeholder="#000000"
										helperText={!validColor ? t("validation.invalidHexColor") : " "}
										error={!validColor}
										size="small"
										data-cy="portal-color-string-hex"
									/>
								</div>
								<Typography>{t("reloadText")}</Typography>
								<div className={classes.colorContrast}>
									<div className={classes.contrastRatio} style={{ backgroundColor: contrastRatio.level.color }} data-cy="contrast-ratio">
										{validColor ? contrastRatio.contrast : "0.0"}
									</div>
									<div className={classes.contrastMessage}>{t(contrastRatio.level.text)}</div>
								</div>
							</div>
						</div>
						<div className={classes.sectionLabel}>
							<Typography class="sm"> {t("upload.portalBackgroundImage")} </Typography>
						</div>
						<div className={classes.labelWithOtherContent}>
							<div className={classes.publicProfilePicture} {...backgroundImageRootProps}>
								{backgroundImageUrl ? (
									<img className={classes.image} src={backgroundImageUrl} alt={t("portalBackgroundImage")} data-cy="logo-image" />
								) : (
									<div className={classes.imageDropZone} data-cy="logo-image-dropzone">
										<span>{t("upload.uploadImage")}</span>
									</div>
								)}
								<input id="portal-background-image" aria-label={t("tooltips.updateBackgroundImage")} {...getBackgroundImageInputProps()} />
							</div>
							<div className={clsx(classes.additionalContent)}>
								<div className={classes.addImage}>
									<ButtonWithTooltip
										title={t("tooltips.updateBackgroundImage")}
										size={MEDIUM}
										onClick={backgroundImageRootProps.onClick}
										data-cy="upload-background-image"
										fullWidth
									>
										<Icon name="upload" color={primaryColor[3]} />
									</ButtonWithTooltip>
								</div>
								{backgroundImageUrl && (
									<div className={classes.removeImage}>
										<ButtonWithTooltip
											title={t("tooltips.deleteBackgroundImage")}
											size={MEDIUM}
											onClick={() => removeImage()}
											data-cy="delete-background-image"
											fullWidth
										>
											<Icon name="remove" color={primaryColor[3]} />
										</ButtonWithTooltip>
									</div>
								)}
							</div>
						</div>
						<Typography>{t("errorMessage.backgroundImageFileType")}</Typography>
						<Typography>{t("errorMessage.backgroundImage")}</Typography>
						<div className={classes.logoSection}>
							<Typography class="sm"> {t("upload.agendaLogo")} </Typography>
							<div className={classes.additionalContent}>
								<div className={classes.addLogo}>
									<ButtonWithTooltip
										title={t("tooltips.updateAgendaLogoImage")}
										size={MEDIUM}
										onClick={logoRootProps.onClick}
										data-cy="upload-agenda-logo"
									>
										<Icon name="upload" color={primaryColor[3]} />
									</ButtonWithTooltip>
								</div>
							</div>
						</div>
						<div className={classes.labelWithOtherContent}>
							<div className={classes.publicProfilePicture} {...logoRootProps}>
								{logoImageUrl ? (
									<img className={classes.image} src={logoImageUrl} alt={t("agendaLogo")} data-cy="logo-image" />
								) : (
									<div className={classes.imageDropZone} data-cy="logo-image-dropzone">
										<span>{t("app:uploadLogo")}</span>
									</div>
								)}
								<input id="logo-image" aria-label={t("tooltips.updateAgendaLogoImage")} {...getLogoInputProps()} />
							</div>
						</div>
					</div>
				</Accordion>
			</div>
		</>
	);
};

export default DisplayOptions;
