import React from "react";
import clsx from "clsx";
import parse from "html-react-parser";

const noEvent = (e) => {
	e.preventDefault();
	e.stopPropagation();
};

const parserOptions = (options) => ({
	replace: (node) => {
		if (!node.attribs) {
			return undefined;
		}
		if (node.name === "p") {
			// eslint-disable-next-line no-param-reassign
			node.attribs.style = "margin-top: 0; margin-bottom: 0;";
		}
		if (["img", "br"].includes(node.name) && node.attribs.style) {
			node.attribs.style = "";
		}
		if (node.name === "a" && node.attribs && node.attribs.class === "inlineFile closed" && options.excludeMemberOnlyAttachments) {
			return <span />;
		}
		if (node.name === "a" && node.attribs && options.disableLinks) {
			return (
				<a
					className={clsx(node.attribs.class, options.classes ? options.classes.link : undefined)}
					href={node.attribs.href}
					target={node.attribs.target}
					onClick={noEvent}
				>
					{node.children[0].data}
				</a>
			);
		}

		if (options?.highlightStrong && node.name === "strong") {
			node.attribs.style = "background-color:yellow;";
		}

		return undefined;
	},
});

const breakHtmlRegex = /<br\s*\/>/gi;
const blockHtmlRegex = /(<\/(p|li|div)>)/gi;
const stripHtmlRegex = /(<([^>]+)>)/gi;

const processHtml = (html, options = {}) => parse(html || "", parserOptions(options));

export const stripHtml = (html, replaceBrWithNewline) => {
	let text = html || "";

	return text
		.replace(breakHtmlRegex, replaceBrWithNewline ? "\n" : " ")
		.replace(blockHtmlRegex, " ")
		.replace(stripHtmlRegex, "");
};

export const normalizeLinks = (html) =>
	html && typeof html === "string" ? html.replaceAll('href="/home/document', 'href="/document') : html;

export default processHtml;
