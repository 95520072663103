/* eslint-disable no-param-reassign */
import { produce } from "immer";

import findIndex from "lodash/fp/findIndex";
import getOr from "lodash/fp/getOr";
import includes from "lodash/fp/includes";

import AgendaItemTypesEnum from "utils/enums/AgendaItemTypes";
import {
	findItemByID,
	isHeading,
	isSubHeading,
	updateAgenda,
	reOrderItemArray,
	isValidSubsectionLocation,
	isValidItemLocation,
} from "./utils";

function isSameSection(drag, drop, items) {
	const dropItemToCheck =
		isSubHeading(drop) || drop.itemType === AgendaItemTypesEnum().RECOMMENDATION.value
			? findItemByID(drop.attributes.relationshipGuid, items)
			: drop;
	const dragSection = drag.fields.Number.Value.split(".")[0];
	const dropSection = dropItemToCheck.fields.Number.Value.split(".")[0];

	return dragSection === dropSection;
}

function shouldMove(draggingItem, item, itemIDsToMove) {
	if (isHeading(draggingItem)) {
		return !isHeading(item);
	}
	if (isSubHeading(draggingItem)) {
		return item.attributes.relationshipGuid === draggingItem.guid || includes(item.attributes.relationshipGuid, itemIDsToMove);
	}
	return item.itemType === AgendaItemTypesEnum().RECOMMENDATION.value && item.attributes.relationshipGuid === draggingItem.guid;
}

const clearDraggingElements = (all = false) => {
	const dragElements = document.querySelectorAll(".isDragging");
	if (dragElements && (all || dragElements.length > 1)) {
		dragElements.forEach((element) => {
			if (all || element.outerHTML.indexOf("translate3d(0px, 0px, 0px)") > 0) {
				element.remove();
			}
		});
	}
};

const handleMouseUp = () => {
	setTimeout(() => {
		clearDraggingElements(true);
	}, 100);
	document.removeEventListener("mouseup", handleMouseUp);
};

export function onDragStart(drag) {
	document.addEventListener("mouseup", handleMouseUp);

	const { items } = this.state;
	const draggingItem = items[drag.index];
	draggingItem.numberOfItemsToMove = 1;

	if (draggingItem.itemType !== AgendaItemTypesEnum().RECOMMENDATION.value) {
		// get collection of this heading/subheading/item's associated items and recommendations
		const itemIDsToMove = [];
		for (let i = findIndex((item) => item.guid === draggingItem.guid, items) + 1; i < items.length; i += 1) {
			const item = items[i];
			if (shouldMove(draggingItem, item, itemIDsToMove)) {
				itemIDsToMove.push(item.guid);
				item.willMoveWithDrag = true;
			} else {
				break;
			}
		}

		if (itemIDsToMove.length > 0) {
			draggingItem.numberOfItemsToMove += itemIDsToMove.length;
		}

		items.forEach((item) => {
			if (item.willMoveWithDrag) {
				item.invalidDropLocation = true;
			} else {
				let isDroppable = true;
				if (isHeading(draggingItem)) {
					isDroppable = isHeading(item);
				} else if (isSubHeading(draggingItem)) {
					isDroppable = isValidSubsectionLocation(item, items);
				} else if (draggingItem.itemType === AgendaItemTypesEnum().ITEM.value) {
					isDroppable = isValidItemLocation(item);
				}
				if (!isDroppable) item.invalidDropLocation = true;
			}
		});

		items.forEach((item) => {
			item.itemIDsToMove = [];
			for (let i = findIndex((obj) => obj.guid === item.guid, items) + 1; i < items.length; i += 1) {
				const kid = items[i];
				if (kid.invalidDropLocation) {
					item.itemIDsToMove.push(kid.guid);
				} else {
					break;
				}
			}
		});

		const DOMElements = document.getElementsByClassName("white-background");
		for (let index = 0; index < DOMElements.length; index += 1) {
			const elem = DOMElements[index];
			elem.classList.add("no-hover-effect");
		}
		const containers = [
			"heading-container",
			"member-only-heading-container",
			"consent-agenda-item-container",
			"subheading-container",
			"heading-recommendation-container",
			"agenda-item-container",
			"recommendation-container",
			"with-content",
		];
		for (let containerIndex = 0; containerIndex < containers.length; containerIndex += 1) {
			const containerClassName = containers[containerIndex];
			const hoverContainers = document.getElementsByClassName(containerClassName);
			for (let index = 0; index < hoverContainers.length; index += 1) {
				const elem = hoverContainers[index];
				elem.classList.add("no-hover");
			}
		}
	}
	draggingItem.willMoveWithDrag = true;

	clearDraggingElements();
}

export function onDragUpdate(event) {
	const { newIndex, index } = event;
	const { items } = this.state;
	const dropItem = items[newIndex];
	const draggingItem = items[index];

	/* Notes about the animations --
	The react-sortable-hoc uses vanilla JS for its animations, and there are no react re-renders while dragging.
	Therefore, we also must do our additional animations in vanilla JS.
	There are two pieces to each animation - animate when needed and reverse it when no longer needed. 
	The temporary properties used to managed animated states are removed with a 'delete' rather than setting them to null, 
	in order to not leave any drag/drop data on the objects once React regains control. 
	*/

	// 1. Reset all animations, if needed.
	items.forEach((item) => {
		item.isCurrentDropItem = false;
		let shouldResetAnimations;
		if (!item.movedTo) {
			shouldResetAnimations = false;
		} else if (newIndex === index) {
			shouldResetAnimations = true;
		} else if (newIndex > index) {
			shouldResetAnimations = newIndex < item.movedIndex;
		} else {
			shouldResetAnimations = newIndex > item.movedIndex;
		}
		if (shouldResetAnimations) {
			const DOMElement = document.getElementById(`agenda-${item.guid}`).closest(".white-background");
			DOMElement.animate([{ transform: item.movedTo }, { transform: "translateY(0px)" }], {
				duration: 200,
				fill: "forwards",
			});
			delete item.movedTo;
			delete item.movedIndex;
		}

		if (item.movedButton) {
			const DOMElement = document.getElementById(`agenda-${item.guid}`).closest(".white-background");
			if (DOMElement) {
				const buttonDOM = DOMElement.children[0].lastChild;
				buttonDOM.animate([{ transform: "translateY(20px)" }, { transform: "translateY(0px)" }], {
					duration: 200,
					fill: "forwards",
				});
				delete item.movedButton;
			}
		}
	});
	dropItem.isCurrentDropItem = true;
	this.isDropDisabled = dropItem.invalidDropLocation;

	// 2. animate elements that we are dragging over
	if (getOr(0, "length", dropItem.itemIDsToMove) > 0) {
		dropItem.itemIDsToMove.forEach((guid) => {
			const kid = findItemByID(guid, items);
			if (!kid.movedTo) {
				const kidDOMElement = document.getElementById(`agenda-${guid}`).closest(".white-background");
				if (kidDOMElement) {
					kid.movedTo = `translateY(${newIndex > index ? -80 : 80}px)`;
					kid.movedIndex = newIndex;
					kidDOMElement.animate([{ transform: "translateY(0px)" }, { transform: kid.movedTo }], {
						duration: 200,
						fill: "forwards",
					});
				}
			}
		});
	}

	// 3. remove existing placeholder
	const agendaObjectList = document.getElementById("agenda-object-list");
	const oldPlaceholder = document.getElementById("drag-placeholder");
	if (oldPlaceholder) {
		oldPlaceholder.parentNode.removeChild(oldPlaceholder);
	}

	// 4. Create and place new placeholder
	// if we are falling betweens sections, sometimes we want to operate on the prior element.
	let actualDropItem = dropItem;
	if (!isHeading(draggingItem)) {
		if (
			(isSameSection(draggingItem, dropItem, items) && isHeading(dropItem)) ||
			(!isSameSection(draggingItem, dropItem, items) && newIndex < index && newIndex > 0)
		) {
			actualDropItem = items[newIndex - 1];
		}
	}

	if (newIndex !== index) {
		const placeholder = document.createElement("div");
		placeholder.id = "drag-placeholder";
		if (newIndex === 0 || isHeading(draggingItem)) {
			// simple placeholder between sections
			placeholder.innerHTML = "&nbsp;";
			placeholder.classList.add("dropTarget");
			placeholder.style.width = `${agendaObjectList.getBoundingClientRect().width}px`;
		} else {
			// white-background div wrapped around placeholder
			placeholder.innerHTML = "<div class='dropTarget'>&nbsp;</div>";
			placeholder.classList.add("dropTarget-container");
			placeholder.style.width = `${agendaObjectList.getBoundingClientRect().width - 2}px`;
			placeholder.style.position = "relative";
			if (actualDropItem.isLastItem) {
				placeholder.classList.add("last-item");
			}
		}

		if (newIndex === 0) {
			// first item in agenda - place below the header
			const prevDOMElement = document.getElementById(`agenda-toc-header`);
			placeholder.position = "absolute";
			placeholder.style.top = `${prevDOMElement.getBoundingClientRect().bottom + 8}`;
			agendaObjectList.insertBefore(placeholder, agendaObjectList.children[newIndex]);
		} else if (!isHeading(draggingItem) && actualDropItem.isLastItem) {
			// last item in a section - place between item and split button
			actualDropItem.movedButton = true;
			placeholder.style.top = "0px";
			const DOMIndex = dropItem === actualDropItem ? newIndex : newIndex - 1;
			const dropLocationDOM = agendaObjectList.children[DOMIndex].children[0];
			const buttonDOM = dropLocationDOM.lastChild;
			buttonDOM.animate([{ transform: "translateY(0px)" }, { transform: "translateY(20px)" }], {
				duration: 200,
				fill: "forwards",
			});
			dropLocationDOM.insertBefore(placeholder, buttonDOM);
		} else if (newIndex < index) {
			// dragged up - find previous item and place 8 px below its bottom
			const prevItem = items[newIndex - 1];
			const prevDOMElement = document.getElementById(`agenda-${prevItem.guid}`).closest(".white-background");
			placeholder.position = "absolute";
			placeholder.style.top = `${prevDOMElement.getBoundingClientRect().bottom + 8}`;
			agendaObjectList.insertBefore(placeholder, agendaObjectList.children[newIndex]);
		} else {
			// dragged down - find last item in section and place 8px below its bottom
			const lastMovingItem = items[newIndex + getOr(0, "length", actualDropItem.itemIDsToMove)];
			const lastDOMElement = document.getElementById(`agenda-${lastMovingItem.guid}`).closest(".white-background");
			placeholder.style.position = "relative";
			placeholder.style.top = isHeading(actualDropItem) ? "-72px" : "-80px";
			lastDOMElement.parentNode.insertBefore(placeholder, lastDOMElement.nextSibling);
		}
	}

	// 5. animate items that will move along with the dragging item.
	if (newIndex < index) {
		const movingItems = document.getElementsByClassName("willmove");
		if (!items[index].movedChildren) {
			for (let i = 0; i < movingItems.length; i += 1) {
				const item = movingItems[i];
				if (!item.classList.contains("isDragging")) {
					item.animate([{ transform: "translateY(0px)" }, { transform: "translateY(80px)" }], {
						duration: 200,
						fill: "forwards",
					});
				}
				items[index].movedChildren = true;
			}
		}
	} else if (newIndex === index) {
		const movingItems = document.getElementsByClassName("willmove");
		for (let i = 0; i < movingItems.length; i += 1) {
			const item = movingItems[i];
			if (!item.classList.contains("isDragging")) {
				item.animate([{ transform: "translateY(80px)" }, { transform: "translateY(0px)" }], {
					duration: 200,
					fill: "forwards",
				});
			}
		}
		delete items[index].movedChildren;
	}

	clearDraggingElements();
}

export function onDragEnd(result) {
	let { newIndex, oldIndex } = result;

	const { items } = this.state;
	items.forEach((item) => {
		delete item.willMoveWithDrag;
		delete item.invalidDropLocation;
		delete item.isCurrentDropItem;
		delete item.movedTo;
		delete item.movedIndex;
	});

	if (newIndex === oldIndex || this.isDropDisabled) {
		// clear drag data on a cancelled drag.
		const droppedItem = items[oldIndex];
		delete droppedItem.numberOfItemsToMove;
		this.isDropDisabled = false;
		this.forceUpdate(); // to re-render without the .willmove CSS we just cleared
		return;
	}

	const currentState = this.state;
	const nextState = produce(
		currentState,
		(draft) => {
			const droppedItem = draft.items[oldIndex];
			const itemsToMove = [droppedItem];

			// Only headings can be dropped on top. Anything else gets put under the 1st heading.
			if (newIndex === 0 && !isHeading(droppedItem)) {
				newIndex = 1;
			}

			// get the collection of items to move
			if (droppedItem.numberOfItemsToMove > 1) {
				const startIndex = findIndex((item) => item.guid === droppedItem.guid, draft.items);
				for (let i = startIndex + 1; i < startIndex + droppedItem.numberOfItemsToMove; i += 1) {
					const item = draft.items[i];
					delete item.willMoveWithDrag;
					itemsToMove.push(item);
				}
			}

			if (oldIndex < newIndex) {
				const dropItem = draft.items[newIndex];
				newIndex += getOr(0, "length", dropItem.itemIDsToMove);
				draft.items.splice(oldIndex, droppedItem.numberOfItemsToMove);
				draft.items.splice(newIndex - droppedItem.numberOfItemsToMove + 1, 0, ...itemsToMove);
			} else {
				draft.items.splice(oldIndex, droppedItem.numberOfItemsToMove);
				draft.items.splice(newIndex, 0, ...itemsToMove);
			}

			// avoid sending extra data to the API on save.
			delete droppedItem.numberOfItemsToMove;

			reOrderItemArray(draft.items, this, currentState.customNumbering);
		},
		(patches, inversePatches) => {
			if (patches.length) {
				this.redoStack.push([...patches]);
				this.undoStack.push([...inversePatches]);
			}
		},
	);

	updateAgenda(this, { items: nextState.items });

	clearDraggingElements(true);
}
