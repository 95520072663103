import request from "superagent";
import { API_HOST } from "config/env";

/*
Usage:

This loads the settings/localization for a page.

"page" is a string with the page to load settings for
"onLoad" is a function that should set the state with the returned settings

import loadSettings from './utils/loadSettings';
*/

export default function loadSettings(page, location, onLoad, showSignIn) {
	if (typeof page !== "string" || page.length === 0) {
		console.error("Loading: Invalid page.");
	}
	if (!location) {
		console.error("Loading: Invalid location.");
	}

	request
		.get(`${API_HOST}/api/load/${page}${location.search || ""}`)
		.then((response) => {
			if (typeof onLoad === "function") {
				onLoad(response);
			}
		})
		.catch((err) => {
			if ("function" === typeof showSignIn) {
				showSignIn(err, () => {
					loadSettings(page, location, onload, showSignIn);
				});
			}
		});
}
