export const formatDateForPolicy = (date) => {
	const splitDate = date?.split ? date?.split("T") : null;
	if (splitDate) {
		return splitDate && splitDate[0];
	}
	return null;
};

export const formatDate = (date) => {
	if (date !== null) {
		let d = new Date(date);
		let month = d.toLocaleString("en-us", { month: "short" });
		let day = d.getDate();
		let year = d.getFullYear();
		return month + " " + day + ", " + year;
	}
};
