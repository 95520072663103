import React, { Component } from "react";
import { withTranslation, Trans } from "react-i18next";
import queryString from "query-string";
import request from "superagent";
import cookie from "react-cookies";
import { withRouter } from "utils/router";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";

import isEmail from "atlas/utils/isEmail";
import Tooltip from "atlas/components/Tooltip/Tooltip";
import { useWidthUp } from "atlas/utils/useWidth";
import LoginAvatar from "components/Avatar/LoginAvatar";
import { API_HOST } from "config/env";
import OutlinedInput from "atlas/components/FormControls/OutlinedInput";
import telemetryAddEvent from "utils/telemetryAddEvent";

const withWidth = () => (WrappedComponent) => (props) => {
	const widthUpSm = useWidthUp("sm");

	return <WrappedComponent {...props} widthUpSm={widthUpSm} />;
};

class ForgotPassword extends Component {
	constructor(props) {
		super(props);
		let avatar = cookie.load("avatar");
		let queryStringValues = queryString.parse(this.props.location.search);
		this.state = {
			sentRequest: false,
			email: "",
			// eslint-disable-next-line react/no-unused-state
			avatar: avatar !== undefined ? avatar : null,
			invalidToken: queryStringValues ? queryStringValues.invalidToken : "",
		};

		this.handleEmailChange = this.handleEmailChange.bind(this);
		this.handleBlur = this.handleBlur.bind(this);
		this.handleSendInstructions = this.handleSendInstructions.bind(this);
		this.handleCancel = this.handleCancel.bind(this);
		this.validateEmail = this.validateEmail.bind(this);
	}

	componentDidMount() {}

	validateEmail(email) {
		const { t } = this.props;
		let error;
		let valid = true;
		// validate email
		if (!email) {
			error = t("errors.emailRequired");
			valid = false;
		} else if (!isEmail(email)) {
			error = t("errors.invalidEmail");
			valid = false;
		}
		if (!valid) {
			this.setState({
				error,
			});
		}
		return valid;
	}

	handleKeyPress = (event) => {
		if (event.key === "Enter") {
			this.handleSendInstructions();
		}
	};

	handleSendInstructions() {
		const { email } = this.state;
		if (this.validateEmail(email)) {
			this.setState({ sentRequest: true });
		}

		telemetryAddEvent("Forgot password - Send reset email");

		request
			.post(`${API_HOST}/api/users/sendResetPassword`)
			.send({ emailAddress: email })
			.then((res) => {
				console.log("request sent");
				this.setState({ sentRequest: true });
			})
			.catch((err) => {
				console.log(err);
			});
	}

	handleEmailChange(e) {
		this.setState({
			email: e.target.value,
			error: undefined,
		});
	}

	handleBlur() {
		const { email } = this.state;
		this.validateEmail(email);
	}

	handleCancel() {
		this.props.navigate("/sign-in");
	}

	render() {
		const { t, widthUpSm } = this.props;
		const { sentRequest, email, error, invalidToken } = this.state;
		let productName = t("app:productName");

		let content = (
			<React.Fragment>
				<LoginAvatar />

				<Box mt={2}>
					<Typography variant="h3" align="center">
						{sentRequest ? t("forgotPassword.checkInbox") : t("buttons.forgotPassword")}
					</Typography>
				</Box>
				{invalidToken && (
					<Box mt={2}>
						<Typography color="error" variant="caption" data-cy="errorTokenExpired">
							{t("resetPassword.tokenExpired")}
						</Typography>
					</Box>
				)}
				<Box mt={2}>
					<Typography variant="body1" data-cy="instructions">
						{!sentRequest ? (
							t("forgotPassword.instruction")
						) : (
							<Trans i18nKey="forgotPassword.sentRequest" email={email} productName={productName}>
								If <strong>{{ email }}</strong> is associated with a {{ productName }} account, we’ll send an email with instructions to
								reset your password.
							</Trans>
						)}
					</Typography>
				</Box>
				{sentRequest && (
					<Box mt={2}>
						<Typography variant="body1" data-cy="sentRequest">
							{t("forgotPassword.sentRequest2")}
						</Typography>
					</Box>
				)}

				{!sentRequest && (
					<React.Fragment>
						<OutlinedInput
							className="small-margin-top"
							autoFocus
							id="email"
							label={t("forgotPassword.emailAddress")}
							value={email}
							onChange={this.handleEmailChange}
							onBlur={this.handleBlur}
							onKeyPress={this.handleKeyPress}
							fullWidth
							helperText={error}
							error={error !== undefined}
							data-cy="emailAddress"
						/>

						<Tooltip
							PopperProps={{
								disablePortal: true,
							}}
							title={error ? t("forgotPassword.tooltip") : ""}
						>
							<span>
								<Button
									className="signin-button medium-top-margin"
									id="send-instructions"
									onClick={this.handleSendInstructions}
									variant="contained"
									disabled={!!error}
									size="large"
									color="primary"
									data-cy="sendInstructions"
								>
									{t("buttons.sendInstructions")}
								</Button>
							</span>
						</Tooltip>
					</React.Fragment>
				)}

				<Box className="text-center" mt={3}>
					<Link component="button" variant="body1" onClick={this.handleCancel} data-cy="backToSignIn">
						{t("buttons.returnSignIn")}
					</Link>
				</Box>
			</React.Fragment>
		);

		return widthUpSm ? (
			<Card className={`password-card${sentRequest ? " sent-request" : ""}`}>{content}</Card>
		) : (
			<div className="login-small">{content}</div>
		);
	}
}

export default withRouter(withTranslation("account")(withWidth()(ForgotPassword)));
