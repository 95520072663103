import React, { useState } from "react";

import clsx from "clsx";
import makeStyles from "@mui/styles/makeStyles";

import settingsStyle from "assets/jss/components/settingsStyle";
import { blackColor } from "atlas/assets/jss/shared";
import { useTranslation } from "react-i18next";
import { getErrorProps } from "utils/updateObject";
import Accordion from "atlas/components/Accordion/Accordion";

import { Typography } from "@mui/material";
import accordionStyle from "assets/jss/components/accordionStyle";
import AccessibleIconButton from "atlas/components/Buttons/AccessibleIconButton";
import OutlinedInput from "atlas/components/FormControls/OutlinedInput";
import ButtonWithTooltip from "atlas/components/Buttons/ButtonWithTooltip";
import { useSelector, useDispatch } from "react-redux";
import { updateInitialSetting, validateICSCalender } from "redux/publicSite/actions";
import ICSCalenderTable from "./ICSCalenderTable";

import notifierMessage from "utils/notifierMessage";
import { setSnackbarOptions } from "redux/snackBar/actions";

import { Grid } from "@mui/material";

const ICS_UNKNOWN_ERROR = 1;
const ICS_CLOUDFLARE_ERROR = 2;

const useStyles = makeStyles(settingsStyle);
const ICSCalender = (props) => {
	const [errors, setErrors] = useState({});
	const [loading, setLoading] = useState(false);
	const [helpAnchor, setHelpAnchor] = useState(null);
	const classes = useStyles();
	const useAccordionStyles = makeStyles(accordionStyle);
	const accordionClasses = useAccordionStyles();
	const { t } = useTranslation("settings");
	const { ICSExternalCalendars, ICSFeedURL } = useSelector((state) => state.publicSiteReducer);
	const dispatch = useDispatch();
	const icsCalenderDataInitialState = {
		url: "",
		name: "",
	};

	const [icsCalenderData, setIcsCalenderData] = useState({
		url: "",
		name: "",
	});

	const parsedICSExternalCalendars = JSON.parse(ICSExternalCalendars);
	const closeMenus = () => {
		setHelpAnchor(null);
	};

	const handleToggleHelpMenu = (e) => {
		closeMenus();
		setHelpAnchor(e.currentTarget);
	};

	const handleChange = (e, name) => {
		const {
			target: { value },
		} = e;

		if (name == "url" && value == "") {
			setErrors({});
		}

		if (name == "ICSFeedURL") {
			dispatch(updateInitialSetting({ [name]: value }));
		} else {
			setIcsCalenderData({ ...icsCalenderData, [name]: value });
		}
	};

	const validate = (calanderObject) => {
		let isDataPresent = parsedICSExternalCalendars && parsedICSExternalCalendars.some((val) => val.url === calanderObject.url);
		let newErrors = {};
		let valid = true;

		if (calanderObject.url) {
			if (isDataPresent) {
				newErrors["url"] = t("errorMessage.ICSUrlExist");
				valid = false;
			} else {
				newErrors["url"] = null;
				valid = true;
			}
		}
		setErrors(newErrors);
		return valid;
	};

	const copyText = () => {
		navigator.clipboard.writeText(ICSFeedURL);
	};
	const submitICSCAlenderData = () => {
		let isValidated = validate(icsCalenderData);

		if (isValidated) {
			let newErrors = {};
			const arr = [];
			let initial = -100;
			icsCalenderData.id = initial - parsedICSExternalCalendars.length;
			icsCalenderData.name = icsCalenderData.name.trim();
			icsCalenderData.url = icsCalenderData.url.trim();

			arr.push(...parsedICSExternalCalendars, icsCalenderData);
			setLoading(true);
			dispatch(validateICSCalender(icsCalenderData))
				.then((res) => {
					newErrors["name"] = null;
					newErrors["url"] = null;
					setLoading(false);
					if (res.Result) {
						setIcsCalenderData(icsCalenderDataInitialState);
						dispatch(updateInitialSetting({ ICSExternalCalendars: JSON.stringify(arr) }));
						let option = notifierMessage(t("snackbarMessage.calanderAdded"), "success");
						dispatch(setSnackbarOptions(option));
					} else {
						newErrors["url"] = t("errorMessage.ICSURLInvalid");
					}
					setErrors({ ...errors, ...newErrors });
				})
				.catch((exception) => {
					setLoading(false);
					if (exception.response && exception.response.statusCode == 400) {
						const { code } = exception.response.body;
						let option = notifierMessage(
							t(`snackbarMessage.${code === ICS_CLOUDFLARE_ERROR ? "icsCloudflareError" : "invalidICSURL"}`),
							"error",
						);
						dispatch(setSnackbarOptions(option));
					}
				});
		}
	};

	const handleDelete = (data) => {
		const filteredPeople = parsedICSExternalCalendars.filter((item) => item.id !== data.id);
		dispatch(updateInitialSetting({ ICSExternalCalendars: JSON.stringify(filteredPeople) }));
		let option = notifierMessage(t("snackbarMessage.calanderDeleted"), "success");
		dispatch(setSnackbarOptions(option));
	};

	const isAddButtonDisable = () => {
		return !(icsCalenderData.url && icsCalenderData.name);
	};
	return (
		<>
			<div className={clsx(classes.section, classes.sectionAccordion)}>
				<Accordion
					classes={accordionClasses}
					className={classes.bold}
					label={t("titles.calenderSettings")}
					overflow={false}
					startOpen={false}
				>
					<div className={classes.socialMediaInputBox}>
						<div className={classes.mainCard}>
							<Grid spacing={{ md: 1 }} container>
								<Grid item xs={12} md={6}>
									<OutlinedInput
										disabled
										id="ICS"
										className={clsx(classes.icsFeed, classes.outlinedInput)}
										label={t("ICSSettings.icsMeetings")}
										onChange={(e) => handleChange(e, "ICSFeedURL")}
										{...getErrorProps(errors, "ICS")}
										noDefaultClassName
										size="small"
										value={ICSFeedURL || ""}
										containerFullWidth={"100%"}
										inputProps={{ "aria-label": t("ICSSettings.icsMeetings") }}
									/>
								</Grid>
								<Grid item xs={12} md={3}>
									<ButtonWithTooltip
										id="copy-btn"
										onClick={copyText}
										className={clsx(classes.addButton, classes.icsDisableButton)}
										variant="outlined"
										color="primary"
										title={t("tooltips.copy")}
									>
										{t("buttons.copy")}
									</ButtonWithTooltip>
								</Grid>
							</Grid>
							<Typography variant="h2" className={clsx(classes.bold, classes.subTitle)}>
								{t("ICSSettings.subtitle")}
							</Typography>
							<div className={classes.ICSInput}>
								<Grid spacing={{ md: 2 }} container>
									<Grid item xs={12} md={4} sm={4}>
										<OutlinedInput
											id="ICSUrl"
											className={clsx(classes.ICSField, classes.outlinedInput)}
											label={t("ICSSettings.url")}
											onChange={(e) => handleChange(e, "url")}
											helperText={errors.url}
											error={!!errors.url}
											noDefaultClassName
											size="small"
											value={icsCalenderData.url}
											containerFullWidth={"100%"}
											inputProps={{ "aria-label": t("ICSSettings.url") }}
										/>
									</Grid>
									<Grid item xs={12} md={4} sm={4}>
										<OutlinedInput
											id="ICSName"
											className={clsx(classes.ICSField, classes.outlinedInput)}
											label={t("ICSSettings.name")}
											onChange={(e) => handleChange(e, "name")}
											helperText={errors.name}
											error={!!errors.name}
											noDefaultClassName
											size="small"
											value={icsCalenderData.name}
											containerFullWidth={"100%"}
											inputProps={{ "aria-label": t("ICSSettings.name") }}
										/>
									</Grid>

									<Grid item xs={12} md={3}>
										<ButtonWithTooltip
											disabled={isAddButtonDisable()}
											id="add-btn"
											onClick={submitICSCAlenderData}
											className={classes.addButton}
											variant="outlined"
											color="primary"
											title={t("tooltips.add")}
										>
											{t("buttons.add")}
										</ButtonWithTooltip>
									</Grid>
								</Grid>
							</div>
						</div>
					</div>
					<ICSCalenderTable loading={loading} folders={parsedICSExternalCalendars} handleDelete={handleDelete}></ICSCalenderTable>
				</Accordion>
			</div>
		</>
	);
};
export default ICSCalender;
