import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import request from "superagent";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import Icon from "atlas/components/Icon/Icon";
import { API_HOST } from "config/env";
import GenericDialog from "atlas/components/Dialogs/GenericDialog";

const CoverTemplateDeleteDialog = (props) => {
	const { show, coverTemplate, onClose, afterDelete } = props;
	const { t } = useTranslation("agendaItems");
	const [deleting, setDeleting] = useState(false);

	const handleDelete = () => {
		setDeleting(true);

		request
			.delete(`${API_HOST}/api/agendaitem/${coverTemplate.guid}/covertemplate`)
			.send({})
			.then(() => {
				onClose();
				afterDelete();
			})
			.catch((err) => handleError(err));
	};

	const handleCancel = () => {
		onClose();
	};

	const handleError = (err) => {
		console.log("Error", err);
		onClose();
	};

	const i18n = t("deleteCoverTemplateDialog", { name: coverTemplate.name });

	const dialog = {
		title: i18n.title,
		line1: i18n.line1,
		primaryTitle: t("buttons.delete"),
		primaryAction: handleDelete,
		secondaryTitle: t("app:buttons.cancel"),
		secondaryAction: handleCancel,
	};

	return (
		<GenericDialog
			show={show}
			title={dialog.title}
			primaryAction={dialog.primaryAction}
			primaryTitle={dialog.primaryTitle}
			secondaryAction={dialog.secondaryAction}
			secondaryTitle={dialog.secondaryTitle}
			secondaryDisabled={deleting}
			clickAwayDisabled={deleting}
			closeIcon={<Icon name="close" />}
		>
			<Typography variant="body">
				<Box fontWeight="fontWeightBold">{dialog.line1}</Box>
			</Typography>
		</GenericDialog>
	);
};

export default CoverTemplateDeleteDialog;
