import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

import makeStyles from "@mui/styles/makeStyles";

import typographyStyle from "atlas/assets/jss/components/typographyStyle";
import { useWidthUp } from "atlas/utils/useWidth";
import AdminTimer from "./AdminTimer";

const useStyles = makeStyles(() => ({
	container: {
		position: "absolute",
		top: "0",
		left: "calc(50% + 130px)", // Logo width (197px) / 2 + 32 spacing
		display: "inline-flex",
		alignItems: "center",
		height: "72px",
		"& > :not(:last-child)": {
			marginRight: "72px",
		},
	},
	containerCenterTimer: {
		display: "inline-flex",
		alignItems: "center",
	},
	containerRightTime: {
		display: "inline-flex",
		alignItems: "center",
		paddingRight: "12px",
	},
	liveTime: {
		...typographyStyle.appBar,
	},
}));

const AdminAppbarTimer = (props) => {
	const { timer, meetingId, startTime, hideTimer } = props;
	const widthUpXl = useWidthUp("xl");
	const classes = useStyles({});

	return (
		<div
			className={clsx({
				[classes.container]: widthUpXl,
				[classes.containerCenterTimer]: !widthUpXl && !hideTimer,
			})}
		>
			{!hideTimer && <AdminTimer timer={timer} meetingId={meetingId} startTime={startTime} />}
		</div>
	);
};

AdminAppbarTimer.propTypes = {
	timer: PropTypes.object.isRequired,
	meetingId: PropTypes.number.isRequired,
	startTime: PropTypes.object,
	hideTimer: PropTypes.bool,
	hideTime: PropTypes.bool,
};

AdminAppbarTimer.defaultProps = {
	startTime: undefined,
	hideTimer: false,
	hideTime: false,
};

export default AdminAppbarTimer;
