import React from "react";

import makeStyles from "@mui/styles/makeStyles";

import userListStyle from "assets/jss/components/userListStyle";
import GenericAvatar, { GENERIC_AVATAR_BACKGROUNDS } from "components/Avatar/GenericAvatar";

const useUserListStyles = makeStyles(userListStyle);
const useStyles = makeStyles(() => ({
	blockToggleSpacer: {
		width: "48px",
	},
}));

const ApproversListApprover = (props) => {
	const { block, user, indent } = props;
	const classes = useStyles();
	const userListClasses = useUserListStyles();

	return (
		user && (
			<li className={userListClasses.user} data-cy={`workflow-block-${block.id}-user-${user.id}`}>
				{indent && <div className={classes.blockToggleSpacer}></div>}
				<div className={userListClasses.avatar}>
					<GenericAvatar
						imageUrl={user.profileImageUrl}
						name={user.name}
						initials={`${(user.firstName || "").substr(0, 1)}${(user.lastName || "").substr(0, 1)}`}
						backgroundNumber={((user.number || 0) % GENERIC_AVATAR_BACKGROUNDS) + 1}
					/>
				</div>
				<div className={userListClasses.identifier} tabIndex="0">
					<div className={userListClasses.name}>{user.name}</div>
					<div className={userListClasses.title}>{user.title}</div>
				</div>
			</li>
		)
	);
};

export default ApproversListApprover;
