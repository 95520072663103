import React from 'react';
import { useDroppable } from '@dnd-kit/core';
import clsx from 'clsx';

import makeStyles from '@mui/styles/makeStyles';

import dragAndDropStyle from '../../assets/jss/components/dragAndDropStyle';

const useDragAndDropStyles = makeStyles(dragAndDropStyle);

const Droppable = (props) => {
	const {
		dropId: id,
		dropData: data,
		useHighlightDroppable,
		highlightDroppable,
		useIsOverStyle = true,
		dropComponent: Component,
		children,
		className,
		...otherProps
	} = props;
	const { setNodeRef, isOver } = useDroppable({
		id,
		data,
	});
	const dragAndDropClasses = useDragAndDropStyles();

	return (
		<Component
			ref={setNodeRef}
			{...otherProps}
			className={clsx(className, {
				[dragAndDropClasses.droppable]: useHighlightDroppable && highlightDroppable && !(useIsOverStyle && isOver),
				[dragAndDropClasses.droppableOver]:
					(!useHighlightDroppable || (useHighlightDroppable && highlightDroppable)) && useIsOverStyle && isOver,
			})}
		>
			{children}
		</Component>
	);
};

export default Droppable;
