import { STATUS_DEFAULT, STATUS_GOOD, STATUS_WARNING } from "../../atlas/assets/jss/utils/statusIndicators";

const BoardAgendaStatusesEnum = () => ({
	NOTSHARED: {
		value: 2,
		label: "enums.statuses.notShared",
		color: STATUS_DEFAULT,
	},
	SHARED: {
		value: 6,
		label: "enums.statuses.shared",
		color: STATUS_GOOD,
	},
	OUTOFSYNC: {
		value: 7,
		label: "enums.statuses.outOfSync",
		color: STATUS_WARNING,
	},
	FINALSHARED: {
		value: 9,
		label: "enums.statuses.shared",
		color: STATUS_GOOD,
	},
});
export default BoardAgendaStatusesEnum;
