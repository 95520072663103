/* eslint-disable react/no-unused-state */
import React, { Component } from "react";
import { createRoot } from "react-dom/client";
import request from "superagent";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { osName, browserName } from "react-device-detect";
import { enablePatches } from "immer";

import { SnackbarProvider } from "notistack";
import { ThemeProvider, StyledEngineProvider, createTheme } from "@mui/material/styles";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import CircularProgressIndicator from "atlas/components/Progress/CircularProgressIndicator";

import { I18nextProvider } from "react-i18next";
import { Provider } from "react-redux";
import Countly from "countly-sdk-web";
import { loadReCaptcha } from "react-recaptcha-v3";

import { COUNTLY_KEY, COUNTLY_TEST_KEY, API_HOST } from "config/env";

import SignInDialog from "components/Dialogs/SignInDialog";
import GetNotifier from "components/GetNotifier/GetNotifier";
import { UserContext } from "contexts/User/UserContext";
import { SettingsContext } from "contexts/Settings/SettingsContext";
import ApplicationLayout, { UnauthenticatedApplicationLayout } from "layouts/ApplicationLayout";
import EmptyLayout, { AuthenticatedEmptyLayout } from "layouts/EmptyLayout";
import LoginLayout from "layouts/LoginLayout";
import DocumentDetailRoutes from "layouts/DocumentDetailRoutes";
import MeetingPresentationRoutes from "layouts/MeetingPresentationRoutes";
import PublicMeetingDocumentRoutes from "layouts/PublicMeetingDocumentRoutes";
import PublicRequestToSpeakRoutes from "layouts/PublicRequestToSpeakRoutes";
import PublicDocumentRoutes from "layouts/PublicDocumentRoutes";
import checkBrowserSupport from "utils/browserSupport";
import showRecaptcha from "utils/showRecaptcha";
import ForgotPassword from "views/Account/ForgotPassword";
import Login from "views/Account/Login";
import ResetPassword from "views/Account/ResetPassword";
import UnsupportedBrowser from "views/Account/UnsupportedBrowser";

import i18n from "i18n";
import themeConfig from "./atlas/assets/jss/theme";

import Store from "./redux/store";

import "./atlas/assets/less/shared.less";
import "./css/style.less";
import PolicyDetailRoutes from "layouts/PolicyDetailsRoutes";
import PublicPolicyRoutes from "./layouts/PublicPolicyRoutes";
import LayoutContainer from "./components/CopyMove/LayoutContainer";

const theme = createTheme(themeConfig);

enablePatches();

class App extends Component {
	constructor(props) {
		super(props);

		this.state = {
			userData: {
				user: {
					id: 0,
					username: "",
					systemAdministrator: false,
					boardAdmin: false,
					boardMember: false,
					boardStaff: false,
					goalAdmin: false,
					requestToSpeakAdmin: false,
					portalAdmin: false,
					publicUser: true,
					userAdmin: false,
					workflowAdmin: false,
					documentLibrary: {
						viewInternal: false,
						viewRecycleBin: false,
					},
					policySite: {
						viewInternal: false,
						viewRecycleBin: false,
					},
				},
				setUser: (newUser) => {
					const { userData, settings } = this.state;
					const { user } = userData;

					// Prevent full page reloads if we are signing in as the same user again
					if (!user || user.username !== newUser.username) {
						if (newUser.systemAdministrator) {
							this.initializeCountly();
						} else {
							// Count.ly user segmentation
							Countly.q.push(["change_id", window.location.host + "-" + newUser.username]);
							Countly.q.push(function () {
								Countly.user_details({
									username: newUser.username,
									organization: window.location.host,
									custom: {
										boardAdmin: newUser.boardAdmin,
										boardMember: newUser.boardMember,
										boardStaff: newUser.boardStaff,
										goalAdmin: newUser.goalAdmin,
										requestToSpeakAdmin: newUser.requestToSpeakAdmin,
										portalAdmin: newUser.portalAdmin,
										publicUser: newUser.publicUser,
										userAdmin: newUser.userAdmin,
										workflowAdmin: newUser.workflowAdmin,
										documentLibrary: newUser.documentLibrary,
										policySite: newUser.policySite,
									},
								});
							});

							const accountSuffix = settings?.testSite ? "-CMTestSite" : settings?.salesSite ? "-CMDemoSite" : "";
							pendo.initialize({
								visitor: {
									id: window.location.host + "-" + newUser.username,
									// email:        // Recommended if using Pendo Feedback, or NPS Email
									// full_name:    // Recommended if using Pendo Feedback
									// role:         // Optional
									CM_BoardAdmin: newUser.boardAdmin,
									CM_BoardMember: newUser.boardMember,
									CM_BoardStaff: newUser.boardStaff,
									CM_GoalAdmin: newUser.goalAdmin,
									CM_RequestToSpeakAdmin: newUser.requestToSpeakAdmin,
									CM_PortalAdmin: newUser.portalAdmin,
									CM_PublicUser: newUser.publicUser,
									CM_UserAdmin: newUser.userAdmin,
									CM_WorkflowAdmin: newUser.workflowAdmin,
									CM_DocumentLibrary: newUser.documentLibrary,
									CM_PolicySite: newUser.policySite,
									// You can add any additional visitor level key-values here,
									// as long as it's not one of the above reserved names.
								},

								account: {
									// Required if using Pendo Feedback, default uses the value 'ACCOUNT-UNIQUE-ID'
									id: window.location.host,
									name: newUser.organizationName + accountSuffix, // Optional
									// is_paying:    // Recommended if using Pendo Feedback
									// monthly_value:// Recommended if using Pendo Feedback
									// planLevel:    // Optional
									// planPrice:    // Optional
									// creationDate: // Optional

									// You can add any additional account level key-values here,
									// as long as it's not one of the above reserved names.
									CM_Essentials: settings?.lite?.enabled || false,
									CM_BoardDocsImport: settings?.boardDocsCompanyCode || "",
									CM_BoardPaqImport: settings?.boardPaqImportEnabled || false,
									CM_BoxCastEnabled: settings?.video?.boxcastEnabled || false,
									CM_YoutubeEnabled: settings?.video?.youtubeEnabled || false,
									CM_SwagitEnabled: settings?.video?.swagitEnabled || false,
									CM_PolicyPublisher: settings?.policyEnabled || false,
									CM_MeetingGroups: settings?.lite?.allowedBoards || 0,
								},
								excludeAllText: true,
							});

							// if (!newUser.publicUser) {
							// 	///Fetch user's NPS and Survey feedbacks from the server
							// 	Countly.q.push(["get_available_feedback_widgets", feedbackWidgetsCallback]);

							// 	//Surveys feedback callback function
							// 	function feedbackWidgetsCallback(countlyPresentableFeedback, err) {
							// 		if (err) {
							// 			console.log(err);
							// 			return;
							// 		}

							// 		//The available feedback types are nps and survey, decide which one to show
							// 		var countlyFeedbackWidget = countlyPresentableFeedback[0];

							// 		//Display the feedback widget to the end user
							// 		Countly.present_feedback_widget(countlyFeedbackWidget);
							// 	}
							// }
						}

						userData.user = newUser;
						this.setState({ userData });

						if (typeof newUser.callback === "function") {
							newUser.callback();
						}
					}

					return newUser;
				},
			},
			settings: {},
		};
	}

	componentDidMount() {
		this.loadGeneralSettings();
	}

	initializeCountly = (key) => {
		if (key && key.length > 0) {
			// Enable Countly
			Countly.init({
				app_key: key,
				url: "https://diligent.count.ly/",
				debug: false,
			});

			// log the page view that we opened in the browser
			Countly.q.push(["track_pageview", `${window.location.pathname}${window.location.hash}`]);
			// Countly.q.push(["track_sessions"]); adds eventhandler to keydown which causes some performance degradation
			Countly.q.push(["track_errors"]);
		} else {
			// Disable Countly
			Countly.app_key = null;
		}
	};

	loadGeneralSettings = () => {
		const url = `${API_HOST}/api/load/general`;

		request
			.get(url)
			.then((res) => {
				const host = res.body.host || "";
				if (host.length > 0 && window.location.hostname !== host) {
					window.location.href = `https://${host}${window.location.pathname}`; // Redirect to the correct hostname
				} else {
					// If this is a test site update the key
					if (res.body.testSite) {
						this.initializeCountly(COUNTLY_TEST_KEY);
					} else {
						this.initializeCountly(COUNTLY_KEY);
					}

					const { recaptchaKey } = res.body; // TODO: Move the key to the settings api

					if (recaptchaKey && recaptchaKey.length > 0 && showRecaptcha(window.location.pathname)) {
						loadReCaptcha(recaptchaKey);
					}

					this.setState({
						settings: {
							organizationName: res.body.organizationName,
							dateFormat: res.body.dateFormat,
							supportFrom: res.body.supportFrom,
							supportTo: res.body.supportTo,
							startDay: res.body.startDay,
							endDay: res.body.endDay,
							ckEditorArguments: res.body.ckEditorArguments,
							lite: res.body.lite,
							requestToSpeak: res.body.requestToSpeak,
							policyUrl: res.body.policyUrl,
							pdfTronL: res.body.pdfTronL,
							recaptchaKey,
							testSite: res.body.testSite,
							salesSite: res.body.salesSite,
							secondLayerUrl: "",
							previousUrl: "",
							wopiSettings: res.body.wopiSettings,
							loadSettings: this.loadGeneralSettings,
							policyEnabled: res.body?.policy?.enabled,
							communityMeetings: res?.body?.communityMeetings?.enabled,
							video: res?.body?.video,
							boardDocsCompanyCode: res?.body?.boardDocsCompanyCode,
							boardPaqImportEnabled: res?.body?.boardPaqImportEnabled,
						},
					});
				}
			})
			.catch(() => {});
	};

	render() {
		const { settings, userData } = this.state;

		return (
			<Provider store={Store}>
				<BrowserRouter basename="/home/">
					<LocalizationProvider dateAdapter={AdapterDateFns}>
						<I18nextProvider i18n={i18n}>
							<StyledEngineProvider injectFirst>
								<ThemeProvider theme={theme}>
									{!settings.organizationName || (userData.user && !settings.ckEditorArguments) ? (
										<CircularProgressIndicator />
									) : (
										<SettingsContext.Provider value={settings}>
											<UserContext.Provider value={userData}>
												<SnackbarProvider
													maxSnack={3}
													anchorOrigin={{
														vertical: "bottom",
														horizontal: "center",
													}}
													SnackbarProps={{
														"data-cy": "snackbar",
													}}
												>
													<SignInDialog />
													<GetNotifier />
													{!checkBrowserSupport(osName, browserName) ? (
														<div id="components-container" className="flex direction-column unsupported-browser-container">
															<UnsupportedBrowser />
														</div>
													) : (
														<Routes>
															<Route
																path="/sign-in"
																element={
																	<LoginLayout>
																		<Login />
																	</LoginLayout>
																}
															/>
															<Route
																path="/forgot-password"
																element={
																	<LoginLayout>
																		<ForgotPassword />
																	</LoginLayout>
																}
															/>
															<Route
																path="/reset-password"
																element={
																	<LoginLayout>
																		<ResetPassword />
																	</LoginLayout>
																}
															/>
															<Route
																path="/public/requesttospeak/*"
																element={
																	<EmptyLayout>
																		<PublicRequestToSpeakRoutes />
																	</EmptyLayout>
																}
															/>
															<Route
																path="/public/documents/*"
																element={
																	<EmptyLayout>
																		<PublicDocumentRoutes list />
																	</EmptyLayout>
																}
															/>
															<Route
																path="/public/document/*"
																element={
																	<EmptyLayout>
																		<PublicDocumentRoutes />
																	</EmptyLayout>
																}
															/>
															<Route
																path="/public/meeting/document/*"
																element={
																	<UnauthenticatedApplicationLayout>
																		<PublicMeetingDocumentRoutes />
																	</UnauthenticatedApplicationLayout>
																}
															/>
															<Route
																path="/meeting/presentation/*"
																element={
																	<AuthenticatedEmptyLayout>
																		<MeetingPresentationRoutes />
																	</AuthenticatedEmptyLayout>
																}
															/>
															<Route
																path="/document/*"
																element={
																	<AuthenticatedEmptyLayout>
																		<DocumentDetailRoutes />
																	</AuthenticatedEmptyLayout>
																}
															/>
															<Route
																exact
																path="/policydoc/*"
																element={
																	<AuthenticatedEmptyLayout>
																		<PolicyDetailRoutes />
																	</AuthenticatedEmptyLayout>
																}
															/>
															<Route
																exact
																path="/policies/policydoc/*"
																element={
																	<EmptyLayout>
																		<PolicyDetailRoutes />
																	</EmptyLayout>
																}
															/>
															<Route
																path="/policies/policybook/*"
																element={
																	<EmptyLayout>
																		<PublicPolicyRoutes />
																	</EmptyLayout>
																}
															/>
															<Route
																exact
																path="/meeting/move/:id"
																element={
																	<AuthenticatedEmptyLayout>
																		<LayoutContainer />
																	</AuthenticatedEmptyLayout>
																}
															/>
															<Route
																exact
																path="/meeting/copy/:id"
																element={
																	<AuthenticatedEmptyLayout>
																		<LayoutContainer />
																	</AuthenticatedEmptyLayout>
																}
															/>
															<Route path="/*" element={<ApplicationLayout />} />
														</Routes>
													)}
												</SnackbarProvider>
											</UserContext.Provider>
										</SettingsContext.Provider>
									)}
								</ThemeProvider>
							</StyledEngineProvider>
						</I18nextProvider>
					</LocalizationProvider>
				</BrowserRouter>
			</Provider>
		);
	}
}

export default App;

const root = createRoot(document.getElementById("wrapper"));
root.render(<App />);
