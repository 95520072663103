/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import cookie from "react-cookies";
import Countly from "countly-sdk-web";
import { useTranslation } from "react-i18next";
import clsx from "clsx";

import { AppBar, Container, SwipeableDrawer, Box, Paper } from "@mui/material";
import { styled } from "@mui/material/styles";
import withTheme from "@mui/styles/withTheme";
import makeStyles from "@mui/styles/makeStyles";

import { CLOSED_LEFT_NAV_WIDTH, OPEN_LEFT_NAV_WIDTH, LEFT_NAV_BORDER, BOTTOM_NOTICE_HEIGHT } from "assets/jss/utils/componentSizes";
import { backgroundColor } from "atlas/assets/jss/shared";
import NoticeCard from "atlas/components/Cards/NoticeCard";
import BottomNoticeBar from "atlas/components/Layout/BottomNoticeBar";
import PageHeader from "atlas/components/Layout/PageHeader";
import TabsHeader from "atlas/components/Layout/TabsHeader";
import ToolbarHeader from "atlas/components/Layout/ToolbarHeader";
import { useWidthDown } from "atlas/utils/useWidth";
import ErrorBoundary from "components/ErrorBoundary";
import { resetPageConfigs, updateLeftNav, updateNotice, updatePendingApprovalsCount, updateRequestsToSpeakCount } from "redux/app/actions";
import { mapStateToProps } from "redux/app/mapStateToProps";
import { updateHandlers, GENERAL_HUB } from "utils/communication/SignalrClient";
import { CONTENT_SCROLL_ID, CONTENT_PAPER_ID } from "utils/contentSize";
import processHtml from "utils/processHtml";
import authentication from "./components/Authentication";
import LeftNavigation from "./components/LeftNavigation";
import TopNavBar from "./components/TopNavBar";
import Routes from "./Routes";

const MainContainer = styled(Box)({
	display: "flex",
	justifyContent: "flex-start",
	height: "100%",
	padding: 0,
	backgroundColor: backgroundColor[0],
	zIndex: 1,
	width: "100%",
	marginRight: "auto",
	marginLeft: "auto",
});

const BlueHeader = styled(Box)({
	height: "88px",
	backgroundColor: "#2f3b4d",
	width: "100%",
	flexShrink: "0",
});

const useStyles = makeStyles((theme) => ({
	contentArea: {
		flexGrow: "1",
		overflowY: "hidden",
	},
	container: {
		padding: "0",
		position: "relative",
	},
	fullHeight: {
		height: "100%",
	},
	fullHeightBackgroundContainer: {
		minHeight: "100%",
		display: "flex",
		flexDirection: "column",
	},
	fullHeightBackground: {
		flexGrow: "1",
	},
	content: {
		position: "relative",
		top: "-88px",
		marginBottom: "-88px",
		paddingTop: "0",
		paddingBottom: "0",
		[theme.breakpoints.down("sm")]: {
			paddingLeft: "16px",
			paddingRight: "16px",
		},
		flexGrow: "1",
		flexShrink: "1",
		minHeight: "40%", // No matter how large the page header gets, make sure that the content has some height
		paddingLeft: "24px",
		paddingRight: "24px",
		overflow: (props) => (props.overflowHidden ? "hidden" : "auto"), // Overlay breaks drag/drop scrolling on the agenda edit
		scrollbarGutter: "stable both-edges",
	},
	paper: {
		borderRadius: "2px",
		margin: "0",
		maxWidth: "100%",
		boxShadow: "0 2px 10px 0 rgba(0, 0, 0, 0.1) !important",
	},
	paperTransparent: {
		backgroundColor: backgroundColor[0],
		boxShadow: "none !important",
	},
}));

const useDrawerStyles = makeStyles((theme) => ({
	appBar: {
		[theme.breakpoints.only("sm")]: {
			width: `calc(100% - ${CLOSED_LEFT_NAV_WIDTH + LEFT_NAV_BORDER}px) !important`,
		},
		[theme.breakpoints.down("sm")]: {
			width: "100% !important",
		},
		width: (props) => props.appBarSize,
		zIndex: theme.zIndex.drawer + 1,
		transition: theme.transitions.create(["width", "margin"], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		boxShadow: "0 2px 10px 0 rgba(0, 0, 0, 0.3)",
	},
	appBarShift: {
		[theme.breakpoints.up("md")]: {
			marginLeft: OPEN_LEFT_NAV_WIDTH + LEFT_NAV_BORDER,
			width: `calc(100% - ${OPEN_LEFT_NAV_WIDTH + LEFT_NAV_BORDER}px)`,
			transition: theme.transitions.create(["width", "margin"], {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.enteringScreen,
			}),
		},
	},
	drawer: {
		width: OPEN_LEFT_NAV_WIDTH,
		flexShrink: 0,
		whiteSpace: "nowrap",
	},
	drawerOpen: {
		width: OPEN_LEFT_NAV_WIDTH,
		transition: theme.transitions.create("width", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
		overflowX: "hidden",
	},
	drawerClose: {
		transition: theme.transitions.create("width", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		overflowX: "hidden",
		width: CLOSED_LEFT_NAV_WIDTH,
	},
	paper: {
		padding: 0,
		margin: 0,
	},
	content: {
		marginTop: "72px",
		width: "100%",
		position: "relative",
		display: "flex",
		flexDirection: "column",
		height: (props) => `calc(100% - 72px - ${props.showBottomNotice ? BOTTOM_NOTICE_HEIGHT : 0}px)`,
	},
}));

const ApplicationLayout = (props) => {
	const { children } = props;
	const widthDownSm = useWidthDown("sm");
	const widthDownMd = useWidthDown("md");
	const { t } = useTranslation();
	const location = useLocation();
	const [showMenu, setShowMenu] = useState(false);
	const dispatch = useDispatch();

	const defaultLanguage = cookie.load("maple_language");
	if (defaultLanguage) {
		props.i18n.changeLanguage(defaultLanguage);
	}

	useEffect(() => {
		if (Countly.app_key) {
			Countly.q.push(["track_pageview", `${location.pathname}${location.hash}`]);
		}
	}, [location.pathname]);

	const toggleMenu = () => {
		setShowMenu(!showMenu);
	};

	const appReducer = useSelector((state) => state.appReducer);
	const {
		showLeftNav,
		openLeftNav,
		reloadLeftNav,
		overflowHidden,
		contentMaxWidth,
		contentPaper,
		notice,
		bottomNotice,
		signalR: { client, handler },
	} = appReducer;
	const classes = useStyles({
		overflowHidden,
	});
	const drawerClasses = useDrawerStyles({
		appBarSize: showLeftNav
			? `calc(100vw - ${openLeftNav ? OPEN_LEFT_NAV_WIDTH : CLOSED_LEFT_NAV_WIDTH}px - ${LEFT_NAV_BORDER}px)`
			: "100vw",
		showBottomNotice:
			(!bottomNotice.show || bottomNotice.show(bottomNotice.data, bottomNotice.persistentData)) &&
			bottomNotice.label &&
			bottomNotice.label.length > 0,
	});

	const setOpen = (open) => {
		dispatch(
			updateLeftNav({
				openLeftNav: open,
			}),
		);
	};

	const toggleDrawer = (openDrawer) => (event) => {
		if (event && event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
			return;
		}

		setOpen(openDrawer);
	};

	const requestUpdatedOutstandingAgendaItemApprovalsCount = () => {
		client.generalHub.updateOutstandingAgendaItemApprovalsCount();
	};

	const updateOutstandingAgendaItemApprovalsCount = (count) => {
		dispatch(updatePendingApprovalsCount({ count: count || 0 }));
	};

	const updateSubmittedRTSCount = (count) => {
		dispatch(updateRequestsToSpeakCount({ count: count || 0 }));
	};

	useEffect(() => {
		if (handler) {
			updateHandlers(dispatch, handler, GENERAL_HUB, {
				requestUpdatedOutstandingAgendaItemApprovalsCount,
				updateOutstandingAgendaItemApprovalsCount,
				updateSubmittedRTSCount,
			});
			client.ensureStarted().then(() => {
				client.generalHub.addUserToGeneralViewers(); // This also updates the navigation badges
			});
		}
	}, [handler]);

	const leftNavIsOverlay = widthDownSm || (widthDownMd && openLeftNav);

	return (
		<>
			<MainContainer>
				{showLeftNav && (
					<SwipeableDrawer
						anchor={leftNavIsOverlay ? "left" : undefined}
						variant={leftNavIsOverlay ? undefined : "permanent"}
						className={clsx(drawerClasses.drawer, {
							[drawerClasses.drawerOpen]: openLeftNav,
							[drawerClasses.drawerClose]: !openLeftNav,
						})}
						classes={{
							paper: clsx(drawerClasses.paper, {
								[drawerClasses.drawerOpen]: openLeftNav,
								[drawerClasses.drawerClose]: !openLeftNav,
							}),
						}}
						open={openLeftNav}
						onClose={toggleDrawer(false)}
						onOpen={toggleDrawer(true)}
					>
						<LeftNavigation open={openLeftNav} setOpen={setOpen} closeOnAction={leftNavIsOverlay} reload={reloadLeftNav} />
					</SwipeableDrawer>
				)}
				<div id="content-area" className={classes.contentArea}>
					<AppBar
						className={clsx(drawerClasses.appBar, {
							[drawerClasses.appBarShift]: openLeftNav,
						})}
						data-cy="appBarContainer"
						aria-label="appbarHeader"
					>
						<TopNavBar open={openLeftNav} setOpen={setOpen} toggleMenu={toggleMenu} resetPageConfigs={resetPageConfigs} />
					</AppBar>

					<main className={drawerClasses.content} data-cy="contentContainer">
						<PageHeader />
						<TabsHeader />
						<ToolbarHeader />
						<BlueHeader />
						<div id={CONTENT_SCROLL_ID} className={classes.content}>
							<Container
								id="content-container"
								className={clsx(
									classes.container,
									{
										[classes.fullHeight]: contentPaper.fullHeight,
									},
									classes.fullHeightBackgroundContainer,
								)}
								maxWidth={contentMaxWidth}
							>
								<Paper
									id={CONTENT_PAPER_ID}
									className={clsx(
										classes.paper,
										{
											[classes.paperTransparent]: contentPaper.transparent,
											[classes.fullHeight]: contentPaper.fullHeight,
										},
										classes.fullHeightBackground,
									)}
								>
									<ErrorBoundary>{children || <Routes />}</ErrorBoundary>
								</Paper>
							</Container>
						</div>
						<NoticeCard
							updateNotice={updateNotice}
							status={notice.status}
							icon={notice.icon}
							iconSize={notice.iconSize}
							iconColor={notice.iconColor}
							iconPosition={notice.iconPosition}
							label={notice.label}
							complexLabel={notice.complexLabel}
							processHtml={processHtml}
							placement={notice.placement}
							actions={notice.actions}
							onDismiss={notice.onDismiss}
							maxWidth={notice.maxWidth}
							dataCy="global-notice-card"
						/>
					</main>
					<BottomNoticeBar
						show={bottomNotice.show}
						icon={bottomNotice.icon}
						label={bottomNotice.label}
						preText={bottomNotice.preText}
						postText={bottomNotice.postText}
						action={bottomNotice.action}
						canClose={bottomNotice.canClose}
						onClose={bottomNotice.onClose}
						data={bottomNotice.data}
						persistentData={bottomNotice.persistentData}
						closeTooltip={t("app:tooltips.closeButton")}
					/>
				</div>
			</MainContainer>
		</>
	);
};

export const UnauthenticatedApplicationLayout = withTheme(connect(mapStateToProps)(ApplicationLayout));

export default authentication(UnauthenticatedApplicationLayout);
