import React, { forwardRef } from "react";
import { useTranslation } from "react-i18next";
import clsx from "clsx";

import makeStyles from "@mui/styles/makeStyles";
import { Tooltip } from "@mui/material";

import dragAndDropStyle from "atlas/assets/jss/components/dragAndDropStyle";
import AccessibleIconButton from "atlas/components/Buttons/AccessibleIconButton";
import { grayColor, primaryColor } from "atlas/assets/jss/shared";
import DragHandle from "atlas/components/DragAndDrop/DragHandle";
import GenericAvatar, { GENERIC_AVATAR_BACKGROUNDS } from "components/Avatar/GenericAvatar";

const focusableElement = {
	display: "flex",
	alignItems: "center",
	height: "48px",
	"&:focus": {
		backgroundColor: "rgba(0, 0, 0, 0.08)",
	},
};

const useDragAndDropStyles = makeStyles(dragAndDropStyle);
const useStyles = makeStyles(() => ({
	userListItem: {
		margin: "0",
		position: "relative",
		display: "flex",
		alignItems: "center",
		height: "48px",
		"&:hover": {
			backgroundColor: grayColor[4],
		},
	},
	userListDrag: {
		paddingLeft: "12px",
	},
	userListItemAvatar: {
		padding: "0 4px 0 8px",
		...focusableElement,
	},
	avatar: {
		width: "24px",
		height: "24px",
		fontSize: "12px",
		lineHeight: "14px",
		fontWeight: "600",
	},
	userListItemName: {
		flexGrow: "1",
		padding: "0 8px 0 4px",
		wordBreak: "break-word",
		...focusableElement,
	},
	userListMenu: {
		cursor: "pointer",
	},
}));

const SubscribersUser = forwardRef((props, ref) => {
	const {
		className,
		user,
		handleUpdate,
		actionIcon,
		actionTooltip,
		handleAction,
		dragAttributes = {},
		dragListeners = {},
		canDrag,
		dragPlaceholder,
		dragPresentational,
		telemetryPage,
		...otherProps
	} = props || {};

	const { t } = useTranslation("subscribers");
	const dragAndDropClasses = useDragAndDropStyles();
	const classes = useStyles({ dragPresentational: dragPresentational });

	return (
		<li
			className={clsx(classes.userListItem, {
				[dragAndDropClasses.dragPlaceholder]: dragPlaceholder,
				[dragAndDropClasses.draggable]: canDrag,
				[classes.userListDrag]: canDrag,
			})}
			ref={ref}
			{...otherProps}
		>
			<div className={classes.userListItemAvatar} {...dragAttributes} {...dragListeners}>
				{canDrag && <DragHandle />}
				<GenericAvatar
					className={classes.avatar}
					imageUrl={user.profileImageUrl}
					name={user.name}
					initials={`${(user.firstName || "").substr(0, 1)}${(user.lastName || "").substr(0, 1)}`}
					backgroundNumber={((user.number || 0) % GENERIC_AVATAR_BACKGROUNDS) + 1}
				/>
			</div>
			<div className={classes.userListItemName} {...dragAttributes} {...dragListeners}>
				<span title={user.emailAddress}>
					{user.userDeleted || user.userInactive
						? t(`app:entities.${user.userDeleted ? "deleted" : "inactive"}`, { entity: user.name })
						: user.name}
				</span>
			</div>
			<div className={classes.userListMenu}>
				<AccessibleIconButton
					iconName={actionIcon}
					iconColor={primaryColor[1]}
					tooltipText={actionTooltip}
					onClick={(e) => {
						handleAction();
					}}
					dataCy={`action-${user.id}`}
				/>
			</div>
		</li>
	);
});

export default React.memo(SubscribersUser);
