import React from "react";

export const ArrowDropdown = () => (
	<svg width="6" height="4" viewBox="0 0 6 4" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M3.55407 3.52147C3.42634 3.66703 3.24531 3.75 3.05545 3.75C2.86558 3.75 2.68455 3.66703 2.55682 3.52147C1.92393 2.80023 0.837828 1.56248 0.198466 0.83388C0.108774 0.731639 0.085695 0.584194 0.139638 0.457833C0.193614 0.331507 0.314404 0.25 0.447762 0.25C1.74451 0.25 4.36638 0.25 5.66313 0.25C5.79649 0.25 5.91728 0.331507 5.97125 0.457833C6.0252 0.584194 6.00212 0.731639 5.91243 0.83388C5.27306 1.56248 4.18696 2.80023 3.55407 3.52147Z"
			fill="white"
		/>
	</svg>
);
