import React from "react";
import Container from "@mui/material/Container";

import ComponentContainer from "atlas/components/ComponentContainer/ComponentContainer";

const EmptyPage = () => {
	return (
		<ComponentContainer isAdminLanding>
			<Container maxWidth="xl"></Container>
		</ComponentContainer>
	);
};

export default EmptyPage;
