import React, { useState, useEffect } from "react";
import { API_HOST } from "config/env";

import withErrorHandling from "components/ErrorHOC";

const WopiFrame = (props) => {
	const {
		id,
		wopiSettings: { siteWopiUrl, useTestWopiDiscoveryUrl, wopiAccessToken, wopiAccessTokenTTL },
		extension,
		testSite,
		isPolicyDate = false,
		handleClose = () => {},
	} = props;

	const siteUrl = siteWopiUrl && siteWopiUrl.length ? siteWopiUrl : `${window.location.protocol}${API_HOST}`;
	//
	let actionUrl = `https://${
		useTestWopiDiscoveryUrl ? "FFC-" : ""
	}onenote.officeapps.live.com/hosting/WopiTestFrame.aspx?ui=en-us&IsLicensedUser=1&wopisrc=${encodeURIComponent(
		`${siteUrl}/wopi/files/${id}`,
	)}`;

	if (id !== "1") {
		switch (extension) {
			case ".doc":
			case ".docx":
				actionUrl = `https://${
					useTestWopiDiscoveryUrl ? "FFC-" : ""
				}word-edit.officeapps.live.com/we/wordeditorframe.aspx?ui=en-us&IsLicensedUser=1&wopisrc=${encodeURIComponent(
					`${siteUrl}/wopi/files/${id}`,
				)}`;
				break;

			case ".wopitestx":
			case ".wopitest":
				actionUrl =
					`https://${useTestWopiDiscoveryUrl ? "FFC-" : ""}onenote.officeapps.live.com/hosting/WopiTestFrame.aspx` +
					`?ui=en-us&IsLicensedUser=1&wopisrc=${encodeURIComponent(`${siteUrl}/wopi/files/${id}`)}`;
				break;
			case ".ppt":
			case ".pptx":
				actionUrl = `https://${
					useTestWopiDiscoveryUrl ? "FFC-" : ""
				}powerpoint.officeapps.live.com/p/PowerPointFrame.aspx?PowerPointView=EditView&New=1&ui=en-us&IsLicensedUser=1&wopisrc=${encodeURIComponent(
					`${siteUrl}/wopi/files/${id}`,
				)}`;
				break;
		}
	}
	const [officeActionUrl, setOfficeActionUrl] = useState(actionUrl);

	useEffect(() => {
		const frameholder = document.getElementById(`frameholder_${id}`);
		const office_frame = document.createElement("iframe");
		office_frame.name = `office_frame_${id}`;
		office_frame.id = `office_frame_${id}`;
		office_frame.title = "Office Online Frame";
		office_frame.style = "width: 100%; height: 98%";
		office_frame.setAttribute("allowfullscreen", "true");
		office_frame.setAttribute(
			"sandbox",
			"allow-scripts allow-same-origin allow-forms allow-popups allow-top-navigation allow-popups-to-escape-sandbox",
		);
		frameholder.appendChild(office_frame);
		document.getElementById(`office_form_${id}`).submit();
	}, []);

	useEffect(() => {
		window.addEventListener("blur", () => {
			handleClose(true);
			let doc = document.getElementsByClassName("date-popover");
			if (doc[0]) {
				doc[0].style = "display: none";
			}
		});
	}, [isPolicyDate]);

	useEffect(() => {
		window.addEventListener("message", handlePostMessage, false);
		function handlePostMessage(e) {
			if (e.data) {
				var msgId = e.data.MessageId;
				if (msgId === "App_IsFrameTrusted") {
					console.log(msgId, " matched post message Id");
					var msg = {
						MessageId: "Host_IsFrameTrusted",
						SendTime: Date.now(),
						Values: {
							isTopFrameTrusted: true,
						},
					};
					e.source.postMessage(msg, e.origin);
				}
			}
		}
	});
	return (
		<>
			<span id="cont" />
			<form
				id={`office_form_${id}`}
				name={`office_form_${id}`}
				target={`office_frame_${id}`}
				action={officeActionUrl + "&sftc=1"}
				method="post"
			>
				<input name="access_token" value={encodeURIComponent(wopiAccessToken)} type="hidden" />
				<input name="access_token_ttl" value={wopiAccessTokenTTL} type="hidden" />
			</form>
			<span id={`frameholder_${id}`} />
		</>
	);
};

export default withErrorHandling(WopiFrame);
