export default {
	addFolderDialog: {
		labels: {
			name: "Name",
			code: "Code",
		},
		placeholders: {
			folderName: "Add folder name here",
			name: "Add policy name here",
			code: "Add policy code name",
			documentName: "Add document name here",
		},
		snackbar: {
			error: "Failed to add folder {{title}}",
			success: "Added folder {{title}}",
			successForNewSection: "New section created",
		},
		title: "Create folder",
		titleForNewDocument: "Create new document",
		titleForPolicy: "Create new section",
		titleForNewPolicy: "Create new policy",
		titleForRenameDialog: "Rename {{title}}",
	},
	buttons: {
		addBook: "Add book",
		addCustomFolder: "+ New folder",
		addFolder: "New folder",
		addSection: "New section",
		addDocument: "Add new document",
		previousButton: "PREV",
		nextButton: "NEXT",
	},
	copyMoveDocumentDialog: {
		labels: {
			documentLibrary: "Document library",
			noDocumentsFound: "No documents found",
			noFoldersFound: "No folders found",
			noSectionsFound: "No sections found",
		},
		line1: {
			copy: "Copy {{title}} to:",
			move: "Move {{title}} to:",
		},
		title: {
			copy: "Copy",
			move: "Move",
			duplicate: "Duplicate",
		},
		warnings: {
			permissions: "Moving will alter existing permissions.",
		},
	},
	customFolderDetail: {
		availablePeople: "Available users",
		canEdit: "Can edit",
		name: "Folder name",
		noSelectedUsers: "No selected users",
		options: {
			editAccess: "Edit access",
			hasAccess: "Has access",
		},
		placeholders: {
			name: "Add a folder name",
			searchUsers: "Search users",
		},
		selectedPeople: "Selected users with view access",
		selectedPeopleViewAccess: "Users with view access",
		peopleWithEditAccess: "Selected users with edit access",
		selectedUsers: "Selected users",
		snackbar: {
			saved: "Folder saved",
		},
		title: "New folder",
		unsavedChanges: "You have unsaved changes",
		validation: {
			title: "Folder name required",
		},
	},
	customFolderList: {
		header: {
			name: "Name",
		},
		noResults: {
			description: "No folders have been added",
		},
		title: "Document library settings",
	},
	deleteDocumentDialog: {
		customFolderLine1:
			"Are you sure you want to delete this folder and all the files it contains? Pressing delete will move it to the recycle bin where it can be permanently deleted.",
		line1: "You are about to delete {{title}}",
		sectionDeleteMsg:
			"Note that any draft/published policies within this section will also get deleted and when deleted will go to the recycle bin.",
		snackbar: {
			success: {
				document: "The file {{title}} has been deleted.",
				folder: "The folder {{title}} has been deleted.",
			},
		},
		title: {
			customFolder: "Move folder to recycle bin",
			document: "Delete file",
			folder: "Delete folder",
			policybook: "Delete policybook",
			sectionDelete: "Confirm delete",
			deletePolicy: "Delete Policy",
		},
		undo: {
			error: "Undo delete failed",
			successful: "Undo delete successful",
		},
		button: {
			confirmDelete: "Confirm",
		},
	},
	detail: {
		buttons: {
			accessLog: "Access log",
			copyTo: "Copy to",
			history: "History",
			moveTo: "Move to",
			replace: "Replace",
		},
		labels: {
			accessLog: "Date / Accessed / IP Address",
			accessLogList: "Access log list",
			activity: "Activity",
			dateModified: "Modified: {{date}}",
			documentDeleted: "This document has been deleted.",
			filterField: "Filter",
			history: "Date / User / Status",
			historyList: "History list",
			info: "Info",
			policyinfo: "Policy Info",
			name: "Name",
			path: "Path: ",
			size: "Size: {{size}}",
			type: "File type: {{type}}",
			updated: "Updated",
			policyTimeline: "Policy Timeline",
		},
		options: {
			all: "All",
			dateAccessed: "Date accessed",
			ipAddress: "IP address",
			name: "Name",
		},
		snackbar: {
			copy: {
				success: "Copied to {{path}}",
			},
			move: {
				success: "Moved to {{path}}",
			},
			rename: {
				success: "Renamed to {{title}}",
			},
			restore: {
				success: "Restored history from {{dateChanged}}",
			},
			save: {
				success: "Saved",
			},
		},
		tooltips: {
			download: "Download this document",
			exportToExcel: "Export to Excel",
			replace: "Replace this document",
			showAccessLog: "Display the access log",
			showHistory: "Display the history",
			downloadPolicy: "Download this policy",
			replacePolicy: "Replace this policy",
			deletePolicy: "Delete this policy",
		},
	},
	pdfPasswordDialog: {
		labels: {
			password: "PDF password",
		},
		title: "Enter the password to open this PDF file",
	},
	newDocument: {
		createDocument: "Unable to Create New Document. Please try again",
		commonMsg: "Something went wrong. Please try again",
	},
	policyDetails: {
		labels: {
			draftLastUpdated: "Draft Last Updated: {{date}}",
		},
	},
	policy: {
		policyrescind: "Publish & Rescind",
		labels: {
			adoptionDate: "Adopted date: ",
			adoptionDateField: "Adoption date",
			code: "Add policy code here",
			draft: " Draft: ",
			lastReviewedDate: "Last reviewed date: ",
			lastReviewedDateField: "Last reviewed",
			modifiedBy: " Modified by ",
			name: "Add policy name here",
			nextReviewDate: "Next review date: ",
			nextReviewDateField: "Next review",
			rescindedDate: "rescinded date: ",
			rescindedDateField: "Rescinded date",
			revisedDate: "Last revised date: ",
			revisedDateField: "Last revised",
		},
		successMsg: {
			publish: "Publish successful",
			publishrescind: "Rescinded successful",
			updatePolicy: "Policy Updated Successfully",
			unpublishPolicy: "Unpublish successful",
			duplicatePolicy: "Duplication successful",
			createSection: "New section created {{title}}",
			renameSection: "Renamed to {{code}} {{title}}",
			copyPolicy: "Copied to {{path}}",
			movePolicy: "Moved to {{path}}",
			publishSection: "Section Published {{title}}",
			permanantlyDeleted: "Policy Book Permanently Deleted",
			addToAgendagendaSuccess: "Added Successfully",
			updateDraftSuccess: "Draft Updated Successfully",
		},
		errorMsg: {
			commonMsg: "Something went wrong. Please try again",
			publish: "Unable to Publish Policy. Please try again",
			publishrescind: "Unable to Publish and Rescind Policy. Please try again",
			downloadPolicy: "Unable to Download Policy. Please try again",
			updatePolicy: "Unable to Update Policy. Please try again",
			unpublishPolicy: "Unable to Unpublish Policy. Please try again",
			createPolicy: "Unable to Create New Policy. Please try again",
			createSection: "Unable to Create New Section. Please try again",
			renameSection: "Unable to Rename Section. Please try again",
			deletePolicy: "Unable to Delete Policy. Please try again",
			childPath: "Unable to Get Child Paths. Please try again",
			duplicatePolicy: "Unable to Duplicate the Policy. Please try again",
			movePolicy: "Unable to Move the Policy. Please try again",
			emptyPolicyName: "Policy name can't be empty",
			permanantlyDeleteErr: "Unable to Permanently Delete the Policy. Please try again",
			restorePolicyErr: "Unable to Restore. Please try again",
			accessDenied: "Access Denied",
			updateDraftPolicy: "Unable to Update Draft Policy. Please try again",
		},
		buttons: {
			publish: "Publish",
			addToAgenda: "Add to Agenda",
		},
		labelText: {
			code: "Code: ",
			name: "Name: ",
			status: "Status: ",
		},
		apiResponse: {
			invalidPassword: "An error has occurred.",
		},
		tooltips: {
			adoptionDate: "Adopted date",
			lastReviewedDate: "Last reviewed date",
			nextReviewDate: "Next review date",
			rescindedDate: "rescinded date",
			revisedDate: "Last revised date",
		},
		comparePolicyDialog: {
			title: "Compare policy",
			compareButton: "Compare Selected",
			cancel: "Cancel",
			emptyList: "There are no earlier versions of this policy.",
			currentPublishVersion: "Current Published Version",
		},
	},
	policyRescinded: {
		label: " (Rescinded)",
	},
	list: {
		header: {
			dateDeleted: "Date deleted",
			deletedBy: "Deleted by",
			lastModified: "Last modified",
			modifiedBy: "Modified by",
			name: "Name",
		},
		noResults: {
			description: "This folder is empty",
		},
		tooltips: {
			select: "Select this document or folder",
		},
	},
	liveMeetingPublishPolicyDialog: {
		buttons: {
			publish: "Publish",
		},
		line1: "Are you sure you want to publish this policy?",
		line1_other: "Are you sure you want to publish these policies?",
		title: "Publish policy",
	},
	policyList: {
		tableHeader: {
			lastModified: "Last modified",
			modifiedBy: "Modified by",
		},
		noSectionFound: "CREATE A NEW SECTION TO ADD OR CREATE A NEW POLICY",
		noPolicyFound: "UPLOAD OR CREATE A NEW POLICY",
		emptyFolder: "EMPTY FOLDER",
		emptyFilterResult: "Your filter returned no results.",
	},
	policySettings: {
		header: {
			name: "Name",
		},
		noResults: {
			description: "No books have been added",
		},
		title: "Policy settings",
	},
	policySettingsDetail: {
		availablePeople: "Available users",
		canEdit: "Can edit",
		internalBook: "All internal users book",
		makePublicDialog: {
			addPublic: {
				title: "Show to the public?",
				line1: "Are you sure that you want to make this book available to the public?",
			},
			removePublic: {
				title: "Hide from the public?",
				line1: "Are you sure that you want to hide this book from the public?",
			},
		},
		name: "Book name",
		noSelectedUsers: "No selected users",
		options: {
			editAccess: "Edit access",
			hasAccess: "Has access",
		},
		placeholders: {
			name: "Add book name",
			searchUsers: "Search users",
		},
		privateBook: "Private book",
		publicBook: "Public and all internal users book",
		selectedPeople: "Selected users with view access",
		selectedPeopleViewAccess: "Users with view access",
		snackbar: {
			saved: "Book saved",
		},
		title: "New book",
		unsavedChanges: "You have unsaved changes",
		validation: {
			title: "Book name required",
		},
	},
	policyTimeline: {
		labels: {
			adopted: "Adopted",
			adoptionDate: "Adoption date: ",
			agenda: "Agenda",
			created: "Policy created",
			currentStatus: "Current status: ",
			draft: "Draft",
			minutes: "Minutes",
			policy: "Policy",
			published: "Published",
			publishDate: "Publish date: ",
			rescinded: "Rescinded",
			rescindedDate: "Rescind date: ",
			revised: "Revised",
			revisedDate: "Revised date: ",
			status: "Status: ",
			currentPublished: "Current Published",
		},
		menuOptions: {
			compare: "Compare with this version",
			openAgenda: "Open meeting Agenda",
			openMinutes: "Open meeting Minutes",
			openInNewWindow: "Open in new window",
			download: "Download",
		},
	},
	policyCompareMode: {
		timelineversion: "Timeline version comparison",
		historyversion: "Activity history comparison",
	},
	permanentlyDeleteDocumentDialog: {
		errors: {
			password: "Invalid password",
		},
		labels: {
			password: "Confirm password",
		},
		numberDocument: "{{number}} file",
		numberDocuments: "{{number}} files",
		numberFolder: "{{number}} folder",
		numberFolders: "{{number}} folders",
		timeWarning: "Permanent deletion may take several minutes.",
		title: "Permanently delete files and folders",
		titleForPolicy: "Permanently delete Policy Book",
		snackbar: {
			success: "The files and folders have been permanently deleted",
		},
	},
	permanentlyDeletePolicyDialog: {
		snackbar: {
			success: "The policies have been permanently deleted",
		},
	},
	publishSectionNameDialog: {
		buttons: {
			publish: "Publish",
		},
		line1: "Are you sure that you want to publish the changes to this section name?",
		title: "Publish section name",
		snackbar: {
			success: "Name change published for {{title}}",
		},
	},
	renameDialog: {
		labels: {
			name: "Name",
		},
		placeholders: {
			name: "Name",
		},
		snackbar: {
			success: "Renamed to {{title}}",
		},
		title: 'Rename "{{title}}"',
	},
	renameDialogForPolicy: {
		snackbar: {
			success: "Renamed to {{code}} {{title}}",
		},
	},
	updateDialogForPolicy: {
		title: "New Policy Drafts available",
	},
	restoreDocumentDialog: {
		numberDocument: "{{number}} file",
		numberDocuments: "{{number}} files",
		numberFolder: "{{number}} folder",
		numberFolders: "{{number}} folders",
		title: "Restore files and folders",
		snackbar: {
			success: "The files and folders have been restored",
		},
	},
	restorePolicyDialog: {
		snackbar: {
			success: "The policies have been restored",
		},
	},
	title: {
		internal: "Internal",
		public: "Public",
		recycleBin: "Recycle bin",
		draftPolicies: "Policy - Drafts",
	},
	tooltips: {
		addCustomFolder: "Add a new folder",
		addUserToFolder: "Add this person to the folder access list",
		addFolder: "Add a new folder to the current folder",
		delete: "Send this file or folder to the recycle bin",
		deleteCustomFolder: "Send this folder to the recycle bin",
		deletePolicyBook: "Permanently delete the selected PolicyBook",
		documentOptions: "Document and folder options",
		listMenu: "Additional options menu",
		move: "Move this folder or document to another folder",
		policyOptions: "Policy and section options",
		expandCollapse: "Toggle expand and collapse",
		permanentlyDelete: "Permanently delete the selected files and folders",
		permanentlyDeleteDocument: "Permanently delete this file or folder",
		publishSectionName: "Publish changes to the section name",
		removeUserFromFolder: "Remove this person from the folder access list",
		rename: "Change the name of this file or folder",
		restore: "Restore the selected files and folders",
		restoreDocument: "Restore this file or folder",
		saveBook: "Save this book",
		saveFolder: "Save this folder",
		upload: "Upload a file to the current folder",
		unpublish: "Unpublish",
		addBook: "Add a book",
	},
	uploadDialog: {
		failedUploads: "Failed uploads:",
		title: "Uploading files",
		uploadComplete: "Upload complete",
		uploading: "Uploading {{title}}",
	},
	history: {
		format: "Type:",
		fileSize: "File size:",
	},
};
