import notifierMessage from "utils/notifierMessage";
import { setSnackbarOptions } from "redux/snackBar/actions";

export const displayErrorNotification = (errorMessage, dispatch, action) => {
	let option = notifierMessage(errorMessage, "error", action);
	dispatch(setSnackbarOptions(option));
};

export const displaySuccessNotification = (successMessage, dispatch, action) => {
	let option = notifierMessage(successMessage, "success", action);
	dispatch(setSnackbarOptions(option));
};
