import React from "react";
import { useTranslation } from "react-i18next";
import clsx from "clsx";

import makeStyles from "@mui/styles/makeStyles";

import permissionsStyle from "assets/jss/components/permissionsStyle";
import OutlinedInput from "atlas/components/FormControls/OutlinedInput";
import RadioButton from "atlas/components/FormControls/RadioButton";
import telemetryAddEvent from "utils/telemetryAddEvent";
import { getRequiredLabel, getErrorProps } from "utils/updateObject";
import FolderPermissions from "views/Documents/components/FolderPermissions";

const useStyles = makeStyles(permissionsStyle);

export const PERMISSION_PUBLIC = 1;
export const PERMISSION_INTERNAL = 2;
export const PERMISSION_PRIVATE = 3;

const PolicySettings = (props) => {
	const { book = {}, users, errors = {}, handleUpdate, telemetryPage } = props;
	const { t } = useTranslation("documents");
	const classes = useStyles();

	const updatePermissionFields = (book) => {
		book.isPublic = book.permissionType === PERMISSION_PUBLIC;
		book.isInternal = book.permissionType === PERMISSION_INTERNAL;
	};

	return (
		<>
			<div>
				<div id="details" className={clsx(classes.columns, classes.columnsCenterAlign)}>
					<div>
						<div>
							<OutlinedInput
								id="title"
								label={getRequiredLabel(t, t("policySettingsDetail.name"))}
								placeholder={t("policySettingsDetail.placeholders.name")}
								value={book.title}
								onChange={(e) => handleUpdate(e, "title")}
								{...getErrorProps(errors, "title")}
								noDefaultClassName
								fullWidth
								size="small"
								data-cy="title"
							/>
						</div>
					</div>
					<div>
						<RadioButton
							row
							options={[
								{ text: t("policySettingsDetail.publicBook"), value: PERMISSION_PUBLIC, key: "public" },
								{ text: t("policySettingsDetail.internalBook"), value: PERMISSION_INTERNAL, key: "internal" },
								{ text: t("policySettingsDetail.privateBook"), value: PERMISSION_PRIVATE, key: "private" },
							]}
							getId={(option) => `permission-${option.key || option.value}`}
							getKey={(option) => option.key || option.value}
							getValue={(option) => option.value}
							getDataCy={(option) => `permission-${option.key || option.value}`}
							getLabel={(option) => option.text}
							handleChange={(value) => {
								telemetryAddEvent(`${telemetryPage} - Public book ${value === PERMISSION_PUBLIC ? "On" : "Off"}`, { area: "policy" });

								handleUpdate({ target: { value } }, "permissionType", false, true, undefined, undefined, updatePermissionFields);
							}}
							objectToUpdate={book}
							field="permissionType"
							sx={{ formControl: { margin: 0 } }}
						/>
					</div>
				</div>
				{book.folderPermissions && users && (
					<FolderPermissions
						folderPermissions={
							book.permissionType !== PERMISSION_PRIVATE
								? book.folderPermissions.filter((permission) => permission.canUpdate)
								: book.folderPermissions
						}
						updateOnly={book.permissionType !== PERMISSION_PRIVATE}
						users={users}
						handleUpdate={handleUpdate}
						telemetryPage={telemetryPage}
					/>
				)}
			</div>
		</>
	);
};

export default PolicySettings;
