import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import request from "superagent";

import makeStyles from "@mui/styles/makeStyles";

import Icon from "atlas/components/Icon/Icon";
import { API_HOST } from "config/env";
import GenericDialog from "atlas/components/Dialogs/GenericDialog";
import restorePurgeStyle from "../assets/restorePurge";
import notifierMessage from "utils/notifierMessage";
import { setSnackbarOptions } from "redux/snackBar/actions";

const useStyles = makeStyles(restorePurgeStyle);

const DocumentRestoreDialog = (props) => {
	const { show = true, documents = [], onClose, afterRestore, showSignIn } = props;
	const { t } = useTranslation("documents");
	const dispatch = useDispatch();
	const [restoring, setRestoring] = useState(false);
	const classes = useStyles();

	const handleRestore = () => {
		setRestoring(true);

		request
			.post(`${API_HOST}/api/document/0/restore`)
			.withCredentials()
			.send({ documents: documents.map((document) => document.guid) })
			.then(() => {
				let option = notifierMessage(t(`restoreDocumentDialog.snackbar.success`), "success");
				dispatch(setSnackbarOptions(option));
				onClose();
				afterRestore(documents);
			})
			.catch((err) => {
				showSignIn(err, () => {
					handleRestore();
				});
			});
	};

	const handleCancel = () => {
		onClose();
	};

	const dialog = {
		title: t("restoreDocumentDialog.title"),
		primaryTitle: t("app:buttons.restore"),
		primaryAction: handleRestore,
		secondaryTitle: t("app:buttons.cancel"),
		secondaryAction: handleCancel,
	};

	const numberFolders = documents.filter((document) => document.folder).length;
	const numberDocuments = documents.length - numberFolders;
	const numberLabels = [];
	if (numberFolders > 0) {
		numberLabels.push(t(`restoreDocumentDialog.numberFolder${numberFolders > 1 ? "s" : ""}`, { number: numberFolders }));
	}
	if (numberDocuments > 0) {
		numberLabels.push(t(`restoreDocumentDialog.numberDocument${numberDocuments > 1 ? "s" : ""}`, { number: numberDocuments }));
	}

	return (
		<GenericDialog
			className={classes.dialog}
			show={show}
			title={dialog.title}
			primaryAction={dialog.primaryAction}
			primaryTitle={dialog.primaryTitle}
			primaryDisabled={documents.length === 0 || restoring}
			secondaryAction={dialog.secondaryAction}
			secondaryTitle={dialog.secondaryTitle}
			secondaryDisabled={restoring}
			clickAwayDisabled={restoring}
			closeIcon={<Icon name="close" />}
			data-cy="restore-dialog"
		>
			<div>{numberLabels.join(", ")}</div>
			<ol className={classes.list}>
				{documents.map((document) => (
					<li key={document.guid} className={classes.listItem}>
						<div className={classes.typeIcon} title={!document.folder ? document.extension : undefined}>
							{document.folder ? <Icon name="folder" size="24" /> : <Icon name="document" size="24" />}
						</div>
						<div className={classes.title}>{document.title}</div>
					</li>
				))}
			</ol>
		</GenericDialog>
	);
};

export default DocumentRestoreDialog;
