import React from "react";
import { useNavigate } from "react-router-dom";

import makeStyles from "@mui/styles/makeStyles";
import { TableCell } from "@mui/material";

import tableDataStyle from "assets/jss/components/tableDataStyle";

const useStyles = makeStyles(tableDataStyle);

const LinkCell = (props) => {
	const { cell, row, value } = props;
	const navigate = useNavigate();
	const classes = useStyles();

	const url = typeof cell.navigateUrl === "function" ? cell.navigateUrl(row) : cell.navigateUrl;

	return (
		<TableCell className={classes.cell}>
			<a
				className={classes.link}
				href={`/home${url}`}
				onClick={(e) => {
					navigate(url);

					e.preventDefault();
				}}
			>
				{value}
			</a>
		</TableCell>
	);
};

export default LinkCell;
