/* eslint-disable no-plusplus */
/* eslint-disable no-alert */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-param-reassign */
import React, { Component } from "react";
import { connect } from "react-redux";
import request from "superagent";
import { withTranslation } from "react-i18next";
import { matchPath } from "react-router-dom";
import { withRouter } from "utils/router";

import Typography from "@mui/material/Typography";

// import styled from "styled-components";
import { debounce } from "lodash";
import forEach from "lodash/fp/forEach";
import { format } from "date-fns";
import { v4 as uuid } from "uuid";

import CircularProgressIndicator from "atlas/components/Progress/CircularProgressIndicator";
import ComponentContainer from "atlas/components/ComponentContainer/ComponentContainer";
import { useWidthDown } from "atlas/utils/useWidth";
import FileUploadFailureDialog from "components/Dialogs/FileUploadFailureDialog";
import withErrorHandling from "components/ErrorHOC";
import { SettingsContext } from "contexts/Settings/SettingsContext";
import { API_HOST } from "config/env";
import { STATUS_SAVED_TIMEOUT } from "utils/meetingElement";
import telemetryAddEvent from "utils/telemetryAddEvent";

import Goal from "./Goal";
import GoalTopBar from "./components/GoalTopBar";

import { mapStateToProps } from "redux/app/mapStateToProps";
import { resetPageConfigs, updatePageConfigs } from "redux/app/actions";
import { updatePageHeader } from "redux/pageHeader/actions";
import notifierMessage from "utils/notifierMessage";
import { setSnackbarOptions } from "redux/snackBar/actions";
import { updateGoalSaveStatus } from "redux/goals/actions";
import GoalStatusesEnum from "utils/enums/GoalStatuses";

const withWidth = () => (WrappedComponent) => (props) => {
	const widthDownSm = useWidthDown("sm");

	return <WrappedComponent {...props} widthDownSm={widthDownSm} />;
};

class GoalContainer extends Component {
	uploadQueue = [];

	pendingSave = false;

	isSaving = false;

	isUpdating = false;

	triggerSave = debounce(() => {
		this.saveGoal();
	}, 5000);

	constructor(props) {
		super(props);
		const query = new URLSearchParams(props.location.search);
		const withoutErrorMsg = query.get("withoutErrorMsg");
		const { params: { id } = {} } = matchPath({ path: "/goals/edit/:id", end: true }, props.location.pathname) || {};
		this.state = {
			isFetchingGoal: true,
			goal: {
				id,
				name: "",
				guid: uuid(),
				completionPercentage: "",
				description: "",
				outcomes: "",
				actions: "",
				status: 1,
				closed,
				enableInternalOnly: true,
			},
			dialogs: {},
			showErrorGoalDialog: false,
			errors: { name: "", completionPercentage: "", description: "", outcomes: "", actions: "" },
			uploadingStatus: null,
			uploadingTooltip: "",
			failedFileUploads: null,
			isForPublish: false,
			withoutErrorMsg,
			isClosed: false,
		};
	}

	componentDidMount() {
		const { goalAdmin, t, dispatch } = this.props;
		const { goal } = this.state;

		dispatch(resetPageConfigs({}));

		if (goal && goal.id) {
			this.loadGoal(goal.id);
		} else {
			this.saveGoal(true);
		}
		window.addEventListener("beforeunload", this.checkUnsavedChangesBeforeClosing);
		window.addEventListener("beforeunload", this.checkUploadingBeforeClosing);

		this._isMounted = true;
	}

	updatePageHeaderButton = (disabled, closed) => {
		// Don't update the page header if the component is unmounted
		if (!this._isMounted) {
			return;
		}

		const { dispatch, t, goalAdmin } = this.props;
		const { goal, isClosed } = this.state;
		this.state.isClosed = closed;
		dispatch(
			updatePageHeader({
				primaryAction: goalAdmin ? this.handlePublish : undefined,
				primaryActionText: goalAdmin ? (closed ? t("share") : t("publish")) : undefined,
				primaryActionTooltip: goalAdmin ? (closed ? t("share") : t("publish")) : undefined,
				primaryActionDisabled: disabled,
			}),
		);
	};

	backButtonRoute = (status, id) => {
		switch (status) {
			case GoalStatusesEnum().PUBLISHED.value:
			case GoalStatusesEnum().OUTOFSYNC.value:
			case GoalStatusesEnum().INTERNAL.value:
				return `/goals/view/${id}`;

			default:
				return "/goals";
		}
	};

	componentWillUnmount() {
		window.removeEventListener("beforeunload", this.checkUnsavedChangesBeforeClosing);
		window.removeEventListener("beforeunload", this.checkUploadingBeforeClosing);

		this._isMounted = false;
	}

	updateGoal = (value, field) => {
		const { goal } = this.state;

		goal[field] = value; // Update the live goal

		this.setState({ goal }, this.requestSave); // Save the live goal to state
	};

	backToGoals = () => {
		const { t } = this.props;
		if (this.isSaving || this.pendingSave) {
			if (confirm(t("unsavedChanges"))) {
				this.props.navigate(`/goals`);
			}
		} else if (this.isUploading) {
			if (confirm(t("unsavedUploading"))) {
				this.props.navigate(`/goals`);
			}
		} else {
			this.props.navigate(`/goals`);
		}
	};

	loadGoal = (id, afterSave = false) => {
		const { showSignIn, t, dispatch, goalAdmin } = this.props;
		const { withoutErrorMsg } = this.state;

		request
			.get(`${API_HOST}/api/goal/${id}`)
			.withCredentials()
			.then((res) => {
				if (res.body) {
					this.setState({
						goal: res.body.goal,
						failedUploads: res.body.failedUploads,
						showErrorGoalDialog: !afterSave && !withoutErrorMsg && res.body.failedUploads && res.body.failedUploads.length > 0, // Only show attachment errors on the initial load, not during saving when the upload may still be in progress
						isFetchingGoal: false,
					});
					this.updatePageHeaderButton(false, res.body.goal.closed);
					if (this._isMounted) {
						dispatch(
							updatePageConfigs({
								title: t("editGoal"),
								back: {
									url: goalAdmin ? this.backButtonRoute(res.body.goal?.status, res.body.goal?.id) : "/",
									action: this.backToGoals,
								},
								telemetryPage: "Edit goal",
							}),
						);
					}
				}
			})
			.catch((err) => {
				showSignIn(
					err,
					() => {
						this.loadGoal(id);
					},
					() => {
						this.setState({ requestError: err });
					},
				);
			});
	};

	checkUnsavedChangesBeforeClosing = (event) => {
		if (this.isSaving || this.pendingSave) {
			event.preventDefault();
			event.returnValue = "";
		}
	};

	checkUploadingBeforeClosing = (event) => {
		if (this.isUploading) {
			event.preventDefault();
			event.returnValue = "";
		}
	};

	requestSave = () => {
		this.pendingSave = true;

		this.triggerSave();
	};

	clearStatus = () => {
		this.setState({ saveStatus: undefined, saveTooltip: undefined, statusTimeout: undefined });
	};

	saveGoal = (initialSave = false) => {
		const { t, showSignIn, dispatch } = this.props;
		const { statusTimeout } = this.state;
		const { dateFormat } = this.context;
		const { goal } = this.state; // Get the latest version of the goal

		const editing = goal.id && goal.id > 0;
		const url = `${API_HOST}/api/goal${editing ? `/${goal.id}` : `/${goal.guid}`}`;
		const requestObject = editing ? request.put(url) : request.post(url);
		this.isSaving = true;
		this.pendingSave = false;
		clearTimeout(statusTimeout);
		this.setState({ saveStatus: t("agendaMenu:saving"), saveTooltip: t("saveTooltip") });
		this.updatePageHeaderButton(true, goal.closed);
		dispatch(updateGoalSaveStatus(true));

		if (goal.name !== "") {
			return requestObject
				.withCredentials()
				.send(goal)
				.then((res) => {
					dispatch(updateGoalSaveStatus(false));
					if (res.status === 200) {
						if (initialSave) {
							this.setState({ isFetchingGoal: false });
						}
						if (!goal.id) {
							this.setState({ goal: res.body.goal });
						}
						this.isSaving = false;
						this.setState({
							saveStatus: t("agendaMenu:saved"),
							saveTooltip: `${t("agendaMenu:lastSaved")} ${format(new Date(), `${dateFormat} h:mm a`)}`,
							statusTimeout: setTimeout(this.clearStatus, STATUS_SAVED_TIMEOUT),
						});
					} else {
						this.setState({
							saveStatus: t("agendaMenu:saveError"),
							saveTooltip: t("agendaMenu:saveErrorTooltip"),
							statusTimeout: undefined,
						});
						this.triggerSave();
					}
					this.loadGoal(res.body.goal.id, true);
				})
				.catch((err) => {
					this.isSaving = false;
					dispatch(updateGoalSaveStatus(false));
					showSignIn(err, this.triggerSave, () => {
						this.setState({
							saveStatus: t("agendaMenu:saveError"),
							saveTooltip: t("agendaMenu:saveErrorTooltip"),
							statusTimeout: undefined,
						});
						this.triggerSave();
					});
				});
		} else {
			//Since the goal name is empty and there is nothing to save, we should update the save status to false
			dispatch(updateGoalSaveStatus(false));
			this.setState({ isFetchingGoal: false, saveStatus: "", saveTooltip: "", statusTimeout: undefined });
			this.isSaving = false;
		}
	};

	handlePublish = () => {
		const { dispatch, t } = this.props;
		const { goal, isClosed } = this.state;

		dispatch(
			updatePageHeader({
				primaryActionDisabled: true,
			}),
		);

		if (!goal.name) {
			this.handleShowErrorGoalDialog();
		} else {
			telemetryAddEvent(`Goal - ${isClosed ? "Share" : "Publish"} Goal`);
			request
				.post(`${API_HOST}/api/goal/${goal.id}/publish`)
				.send({})
				.then((res) => {
					dispatch(
						updatePageHeader({
							primaryActionDisabled: false,
						}),
					);
					if (res.body.failedUploads) {
						this.setState({ failedUploads: res.body.failedUploads, isForPublish: true, showErrorGoalDialog: true });
					} else {
						let option = isClosed
							? notifierMessage(t("shareGoalDialog.snackbar.success"), "success")
							: notifierMessage(t("publishGoalDialog.snackbar.success"), "success");
						dispatch(setSnackbarOptions(option));
					}
				})
				.catch((err) => {
					console.log("Error", err);
				});
		}
	};

	handleEditGoal = () => {
		const { goal } = this.state;
		navigate(goalAdmin && `/goals/edit/${goal.id}`);
	};

	sendFiletoAPI = (fileData, isRetry = false) => {
		const { showSignIn } = this.props;
		this.isUploading = true;
		request
			.post(`${API_HOST}/api/documents/uploadattachments`)
			.withCredentials()
			.send(fileData)
			.then((res) => {
				if (res.status === 200) {
					this.setState((prev) => ({
						dialogs: {
							failedUploads: [
								...(prev.dialogs.failedUploads || []).concat(
									(res.body.invalidFiles || []).map((name) => ({
										name,
									})),
								),
							],
						},
					}));
					this.isUploading = false;
					forEach((attachment) => {
						this.completeFileUpload(attachment.itemGuid, attachment.guid);
					}, res.body.Attachments);
					this.checkUploadStatus();
				}
			})
			.catch((err) => {
				const fileError = err.status === 400 || err.status === 500 || isRetry;
				this.setState((prev) => ({
					dialogs: {
						failedUploads: [
							...(prev.dialogs.failedUploads || []).concat(
								fileError
									? (err.response.body.invalidFiles || []).map((name) => ({
											name,
										}))
									: [],
							),
						],
					},
				}));

				this.isUploading = false;
				if (!fileError) {
					showSignIn(
						err,
						() => {
							this.sendFiletoAPI(fileData);
						},
						!isRetry
							? () => {
									// Something went wrong, so wait 5 seconds and try again
									setTimeout(() => {
										this.sendFiletoAPI(fileData, true);
									}, 5000);
								}
							: undefined,
					);
				}
			});
	};

	queueFileUploads = (guid, fileUploads, fileData) => {
		for (let i = 0; i < fileUploads.length; i++) {
			this.uploadQueue.push({
				guid,
				fileGuid: fileUploads[i].guid,
				file: fileUploads[i],
				complete: false,
				error: null,
			});
		}
		this.checkUploadStatus();

		this.setState({ dialogs: {} });
		this.sendFiletoAPI(fileData);
	};

	checkUploadStatus = () => {
		const { t } = this.props;
		let status = null;
		let tooltip = null;
		for (let i = 0; i < this.uploadQueue.length; i++) {
			if (!this.uploadQueue[i].complete) {
				if (tooltip) {
					tooltip += ` / ${this.uploadQueue[i].file.name}`;
				} else {
					tooltip = this.uploadQueue[i].file.name;
				}
			}
		}
		if (tooltip) {
			status = t("agendaMenu:uploading");
		}
		this.setState({
			uploadingStatus: status,
			uploadingTooltip: tooltip,
		});
	};

	invalidFileExtension = (field, fileNames) => {
		if (fileNames.length > 0) {
			this.setState({ failedFileUploads: fileNames });
		}
	};

	closeFileUploadFailureDialog = () => this.setState({ failedFileUploads: null, isForPublish: false });

	handleShowErrorGoalDialog = () => {
		this.setState({ showErrorGoalDialog: true, isForPublish: false, failedUploads: null });
	};

	handleCloseErrorGoalDialog = () => {
		this.setState({ showErrorGoalDialog: false, isForPublish: false, failedUploads: null });
	};

	closeDialogs = () => {
		this.setState({ dialogs: {} });
	};

	completeFileUpload(itemGuid, fileGuid, error) {
		for (let i = 0; i < this.uploadQueue.length; i++) {
			if (this.uploadQueue[i].guid === itemGuid && this.uploadQueue[i].fileGuid === fileGuid) {
				this.uploadQueue[i].complete = true;
				this.uploadQueue[i].error = error;
			}
		}
		this.checkUploadStatus();
	}

	render() {
		const { showSignIn, widthDownSm } = this.props;
		const {
			id,
			goal,
			errors,
			saveStatus,
			saveTooltip,
			uploadingStatus,
			uploadingTooltip,
			requestError,
			isFetchingGoal,
			failedFileUploads,
			showErrorGoalDialog,
			failedUploads,
			isForPublish,
			isClosed,
		} = this.state;

		if (requestError) {
			const errormsg =
				requestError.response.body && requestError.response.body.Message
					? requestError.response.body.Message
					: `${requestError.status} ${requestError.message}`;
			return (
				<Typography variant="h3" style={{ padding: "24px" }}>
					{errormsg}
				</Typography>
			);
		}

		return (
			<>
				<FileUploadFailureDialog
					show={failedFileUploads && failedFileUploads.length > 0}
					failedFileUploads={failedFileUploads}
					onClose={this.closeFileUploadFailureDialog}
				/>
				<GoalTopBar
					id={id}
					showSignIn={showSignIn}
					saveStatus={saveStatus}
					saveTooltip={saveTooltip}
					handlePublish={this.handlePublish}
					uploadingStatus={uploadingStatus}
					uploadingTooltip={uploadingTooltip}
				/>
				<ComponentContainer padding={widthDownSm ? "24px 8px" : undefined} maxWidth="628px">
					{goal && !isFetchingGoal ? (
						<>
							<Goal
								goal={goal}
								errors={errors}
								isFetchingGoal={isFetchingGoal}
								updateGoal={this.updateGoal}
								queueFileUploads={this.queueFileUploads}
								invalidFileExtension={this.invalidFileExtension}
								handleCloseErrorGoalDialog={this.handleCloseErrorGoalDialog}
								showErrorGoalDialog={showErrorGoalDialog}
								failedUploads={failedUploads}
								isForPublish={isForPublish}
								showSignIn={showSignIn}
								updatePageHeaderButton={this.updatePageHeaderButton}
							/>
						</>
					) : (
						<CircularProgressIndicator />
					)}
				</ComponentContainer>
			</>
		);
	}
}
GoalContainer.contextType = SettingsContext;

export default withRouter(withTranslation("goals")(withWidth()(withErrorHandling(connect(mapStateToProps)(GoalContainer)))));
