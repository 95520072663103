import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import request from "superagent";

import withStyles from "@mui/styles/withStyles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

import Icon from "atlas/components/Icon/Icon";
import { API_HOST } from "config/env";
import GenericDialog from "atlas/components/Dialogs/GenericDialog";
import notifierMessage from "utils/notifierMessage";
import { setSnackbarOptions } from "redux/snackBar/actions";

const StyledButton = withStyles({
	root: {
		color: "#ffffff",
		marginTop: 0,
	},
})(Button);

const UserDeleteDialog = (props) => {
	const { show, user, onClose, afterDelete, undoDelete } = props;
	const { t } = useTranslation("users");
	const dispatch = useDispatch();
	const [deleting, setDeleting] = useState(false);

	const handleDelete = () => {
		setDeleting(true);
		request
			.delete(`${API_HOST}/api/user/${user.id}`)
			.withCredentials()
			.send({})
			.then(() => {
				onClose();
				let option = notifierMessage(t("deleteUserDialog.snackbar.success", { name: user.name }), "success", () => undoDelete(user));
				dispatch(setSnackbarOptions(option));
				afterDelete(user);
			})
			.catch((err) => handleError(err));
	};

	const handleCancel = () => {
		onClose();
	};

	const handleError = (err) => {
		console.log("Error", err);
		onClose();
	};

	const i18n = t("deleteUserDialog", { name: user.name });

	const dialog = {
		title: i18n.title,
		line1: i18n.line1,
		primaryTitle: t("app:buttons.delete"),
		primaryAction: handleDelete,
		secondaryTitle: t("app:buttons.cancel"),
		secondaryAction: handleCancel,
	};

	return (
		<GenericDialog
			show={show}
			title={dialog.title}
			primaryAction={dialog.primaryAction}
			primaryTitle={dialog.primaryTitle}
			primaryDisabled={deleting}
			secondaryAction={dialog.secondaryAction}
			secondaryTitle={dialog.secondaryTitle}
			secondaryDisabled={deleting}
			clickAwayDisabled={deleting}
			closeIcon={<Icon name="close" />}
		>
			<Box fontWeight="fontWeightBold">{dialog.line1}</Box>
		</GenericDialog>
	);
};

export default UserDeleteDialog;
