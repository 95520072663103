import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { formatDate } from "utils/date";
import request from "superagent";

import makeStyles from "@mui/styles/makeStyles";
import { Box, MenuItem } from "@mui/material";

import { API_HOST } from "config/env";
import processHtml, { stripHtml } from "utils/processHtml";
import Icon from "atlas/components/Icon/Icon";
import inputStyle from "atlas/assets/jss/components/inputStyle";
import OutlinedInput from "atlas/components/FormControls/OutlinedInput";
import CircularProgressIndicator from "atlas/components/Progress/CircularProgressIndicator";
import GenericDialog from "atlas/components/Dialogs/GenericDialog";
import SelectInput from "atlas/components/FormControls/SelectInput";

const useInputStyles = makeStyles(inputStyle);

const useStyles = makeStyles(() => ({
	dialog: {
		"& .MuiDialog-paper": {
			width: "480px",
			maxWidth: "100%",
		},
	},
	menuItem: {
		width: "432px",
		maxWidth: "100%",
		whiteSpace: "break-spaces",
	},
	progressBar: {
		width: "240px",
		margin: "0 auto",
		marginTop: "24px",
	},
	hidden: {
		display: "none",
	},
	createMeeting: {
		marginTop: "8px",
	},
}));

const AgendaItemSelectMeetingDialog = (props) => {
	const {
		show,
		item: { meeting },
		item,
		onSelect,
		onClose,
	} = props;
	const { t } = useTranslation("agendaItems");
	const [meetingGroups, setMeetingGroups] = useState(null);
	const [meetings, setMeetings] = useState(null);
	const [noMeetings, setNoMeetings] = useState(false);
	const [sections, setSections] = useState(null);
	const [noSections, setNoSections] = useState(false);
	const [selection, setSelection] = useState({
		meetingGroupId: 0,
		meetingId: 0,
		sectionId: 0,
		comment: "",
	});
	const [updatingItem, setUpdatingItem] = useState(false);
	const [selectWidth, setSelectWidth] = useState(0);
	const liveSelection = useRef(selection);
	const classes = useStyles({ selectWidth });
	const inputClasses = useInputStyles({ fullWidth: true });

	const loadMeetingGroups = () => {
		request
			.get(`${API_HOST}/api/boards?basic=true`)
			.withCredentials()
			.then((res) => {
				const { boards } = res.body;
				setMeetingGroups(boards);
				setSelection({
					meetingGroupId: meeting && meeting.boardId ? meeting.boardId : 0,
					meetingId: 0,
					sectionId: 0,
				});
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const handleMeetingGroupChange = (meetingGroupId) => {
		if (meetingGroupId > 0) {
			setSelection((prev) => ({
				...prev,
				meetingId: 0,
				sectionId: 0,
			}));
			setMeetings(null);
			setSections(null);
			setNoMeetings(false);
			setNoSections(false);

			request
				.get(`${API_HOST}/api/meetings/byboard?boardId=${meetingGroupId}`)
				.withCredentials()
				.then((res) => {
					if (liveSelection.current.meetingGroupId !== meetingGroupId) {
						// If the selection has changed since the request was made, return
						return;
					}

					if (res.body && res.body.meetings && res.body.meetings.length) {
						const currentTimeStamp = Math.floor(Date.now() / 1000);
						let hasMeetingId = false;
						const futureMeetings = res.body.meetings.reduce((list, current) => {
							if (currentTimeStamp < current.startTimeStamp) {
								if (meeting && current.id === meeting.id) {
									hasMeetingId = true;
								}
								list.push(current);
							}

							return list;
						}, []);
						setMeetings(futureMeetings);
						if (futureMeetings.length === 0) {
							setNoMeetings(true);
						}

						if (hasMeetingId) {
							setSelection((prev) => ({
								...prev,
								meetingId: meeting.id,
								sectionId: 0,
							}));
						}
					} else {
						setNoMeetings(true);
					}
				})
				.catch((err) => {
					console.log(err);
				});
		}
	};

	const handleMeetingChange = (meetingId) => {
		if (selection.meetingId > 0) {
			setSelection((prev) => ({
				...prev,
				sectionId: 0,
			}));
			setSections(null);
			request
				.get(`${API_HOST}/api/meeting/${meetingId}/agendaheadings`)
				.withCredentials()
				.then((res) => {
					if (liveSelection.current.meetingId !== meetingId) {
						// If the selection has changed since the request was made, return
						return;
					}

					if (res.body && res.body.headings && res.body.headings.length) {
						setSections(res.body.headings);

						if (res.body.headings.find((heading) => heading.id === item.headingId)) {
							setSelection((prev) => ({
								...prev,
								sectionId: item.headingId,
							}));
						}
					} else {
						setNoSections(true);
					}
				})
				.catch((err) => {
					console.log(err);
				});
		}
	};

	const handleFieldChange = (e, field) => {
		const {
			target: { value },
		} = e;

		setSelection((prev) => ({
			...prev,
			[field]: value,
		}));
	};

	const getNoOptionsMenuItem = () => (
		<MenuItem key="no-item-option" value="">
			{t("selectMeetingDialog.options.none")}
		</MenuItem>
	);

	const getDropdownProgressIndicator = (name) => (
		<MenuItem key={name} value={0} data-cy={name}>
			<CircularProgressIndicator minHeight="0px" size={24} padding="8px" />
		</MenuItem>
	);

	const getSections = () => {
		const sectionsItems = [];
		if (sections && sections.length) {
			sections.forEach((section) => {
				sectionsItems.push(
					<MenuItem key={`section-${section.id}`} className={classes.menuItem} value={section.id} data-cy={`section-${section.id}`}>
						<div className={classes.menuItemText}>{processHtml(stripHtml(section.name))}</div>
					</MenuItem>,
				);
			});
		} else if (noSections) {
			sectionsItems.push(getNoOptionsMenuItem());
		} else if (selection.meetingId > 0 && !sections) {
			sectionsItems.push(getDropdownProgressIndicator("section-loading"));
		}

		return sectionsItems;
	};

	const getMeetings = () => {
		const meetingsItems = [];
		if (meetings && meetings.length) {
			meetings.forEach((meeting) => {
				const formattedDate = formatDate(null, meeting.startTime, null, t("app:at"), "", "", false);

				meetingsItems.push(
					<MenuItem key={`meeting-${meeting.id}`} className={classes.menuItem} value={meeting.id} data-cy={`meeting-${meeting.id}`}>
						<div className={classes.menuItemText}>{`${meeting.name} ${formattedDate}`}</div>
					</MenuItem>,
				);
			});
		} else if (noMeetings) {
			meetingsItems.push(getNoOptionsMenuItem());
		} else if (selection.meetingGroupId > 0 && !meetings) {
			meetingsItems.push(getDropdownProgressIndicator("meeting-loading"));
		}

		return meetingsItems;
	};

	const getMeetingGroups = () => {
		const meetingGroupsItems = [];
		if (meetingGroups && meetingGroups.length) {
			meetingGroups.forEach((meetingGroup) => {
				meetingGroupsItems.push(
					<MenuItem
						key={`meeting-group-${meetingGroup.id}`}
						className={classes.menuItem}
						value={meetingGroup.id}
						data-cy={`meeting-group-${meetingGroup.id}`}
					>
						<div className={classes.menuItemText}>{meetingGroup.name}</div>
					</MenuItem>,
				);
			});
		}

		return meetingGroupsItems;
	};

	const handleSelectMeeting = () => {
		onSelect(selection);
		setUpdatingItem(true);
	};

	const handleCancel = () => {
		onClose();
	};

	useEffect(() => {
		liveSelection.current = selection;
	}, [selection]);

	useEffect(() => {
		handleMeetingGroupChange(selection.meetingGroupId);
	}, [selection.meetingGroupId]);

	useEffect(() => {
		handleMeetingChange(selection.meetingId);
	}, [selection.meetingId]);

	useEffect(() => {
		loadMeetingGroups();
	}, []);

	useEffect(() => {
		// Set the drop-down options width to match the width of the control
		const itemSelect = document.getElementById("item");
		if (itemSelect) {
			setSelectWidth(itemSelect.offsetWidth);
		}
	});

	const i18n = t("selectMeetingDialog");
	const dialog = {
		title: item.meeting ? i18n.titleChange : i18n.titleSelect,
		message: i18n.message,
		meetingGroup: i18n.meetingGroup,
		meeting: i18n.meeting,
		section: i18n.section,
		comments: i18n.comments,
		primaryTitle: i18n.buttons.selectMeeting,
		primaryAction: handleSelectMeeting,
		secondaryTitle: i18n.buttons.cancel,
		secondaryAction: handleCancel,
	};

	return (
		<GenericDialog
			className={classes.dialog}
			show={show}
			title={dialog.title}
			primaryAction={dialog.primaryAction}
			primaryTitle={updatingItem ? <CircularProgressIndicator size={20} minHeight="20px" /> : dialog.primaryTitle}
			primaryDisabled={!selection.sectionId || updatingItem}
			secondaryAction={dialog.secondaryAction}
			secondaryTitle={dialog.secondaryTitle}
			secondaryDisabled={updatingItem}
			clickAwayDisabled={updatingItem}
			closeIcon={<Icon name="close" />}
			data-cy="agendaItemSelectMeetingDialog"
		>
			{meetingGroups ? (
				<>
					<SelectInput
						id="meeting-group"
						className={inputClasses.smallInput}
						noDefaultClassName
						fullWidth
						size="small"
						label={dialog.meetingGroup}
						externalLabel
						labelSize="large"
						value={selection.meetingGroupId > 0 ? selection.meetingGroupId : ""}
						onChange={(e) => handleFieldChange(e, "meetingGroupId")}
						data-cy="meeting-group"
					>
						{getMeetingGroups()}
					</SelectInput>
					{noMeetings && <Box pb={2}>{i18n.noMeetings.message}</Box>}
					{!noMeetings && (
						<SelectInput
							id="meeting"
							className={inputClasses.smallInput}
							noDefaultClassName
							fullWidth
							size="small"
							label={dialog.meeting}
							externalLabel
							labelSize="large"
							value={selection.meetingId > 0 ? selection.meetingId : ""}
							onChange={(e) => handleFieldChange(e, "meetingId")}
							data-cy="meeting"
							disabled={noMeetings || selection.meetingGroupId === 0}
						>
							{getMeetings()}
						</SelectInput>
					)}
					<SelectInput
						id="section"
						className={inputClasses.smallInput}
						noDefaultClassName
						fullWidth
						size="small"
						label={dialog.section}
						externalLabel
						labelSize="large"
						value={selection.sectionId > 0 ? selection.sectionId : ""}
						onChange={(e) => handleFieldChange(e, "sectionId")}
						data-cy="section"
						disabled={noMeetings || selection.meetingId === 0}
					>
						{getSections()}
					</SelectInput>
					<OutlinedInput
						noDefaultClassName
						id="comment"
						label={dialog.comments}
						externalLabel
						labelSize="large"
						value={selection.comment || ""}
						onChange={(e) => handleFieldChange(e, "comment")}
						fullWidth
						size="small"
						data-cy="select-meeting-comment"
						multiline
					/>
				</>
			) : (
				<CircularProgressIndicator />
			)}
		</GenericDialog>
	);
};

export default AgendaItemSelectMeetingDialog;
