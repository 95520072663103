/* eslint-disable no-param-reassign */
import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useMatch } from "react-router-dom";
import { useTranslation } from "react-i18next";
import request from "superagent";
import useBackButtonHandler from "utils/hooks/useBackButtonHandler.js";
import withStyles from "@mui/styles/withStyles";
import { Button } from "@mui/material";

import CircularProgressIndicator from "atlas/components/Progress/CircularProgressIndicator";
import ComponentContainer from "atlas/components/ComponentContainer/ComponentContainer";
import withErrorHandling from "components/ErrorHOC";
import { API_HOST } from "config/env";
import telemetryAddEvent from "utils/telemetryAddEvent";
import processHtml, { stripHtml } from "utils/processHtml";
import { useUpdateObject, initializeValidate, setValidate } from "utils/updateObject";
import Workflow, { FAIL_RULE_NONE, FAIL_RULE_FULL, FAIL_RULE_CURRENT, FAIL_RULE_TIERED } from "./Workflow";
import WorkflowNotifications from "./WorkflowNotifications";
import WorkflowDeleteDialog from "./components/WorkflowDeleteDialog";
import { resetPageConfigs, updatePageConfigs, updateTabs } from "redux/app/actions";
import { updatePageHeader } from "redux/pageHeader/actions";
import notifierMessage from "utils/notifierMessage";
import { setSnackbarOptions } from "redux/snackBar/actions";

const StyledButton = withStyles({
	root: {
		color: "#ffffff",
		marginTop: 0,
	},
})(Button);

const telemetryPage = "Workflow Detail";
const clearErrors = {
	name: "",
	blocks: [],
};
const clearSavingState = {
	initialized: false,
	isSaving: false,
	updated: false,
};
const tabs = {
	workflow: 0,
	notifications: 1,
};

const WorkflowContainer = (props) => {
	const { showSignIn } = props;
	const { params: { id } = {} } = useMatch({ path: "/workflows/edit/:id", end: true }) || {};
	const { t } = useTranslation("workflows");
	const navigate = useNavigate();
	const [workflow, setWorkflow] = useState(null);
	const [users, setUsers] = useState(null);
	const [errors, setErrors] = useState({
		...clearErrors,
	});
	const [savingState, setSavingState] = useState({
		...clearSavingState,
	});
	const [tab, setTab] = useState(0);
	const [dialogs, setDialogs] = useState({});
	const workflowRef = useRef(workflow); // For use in functions that have stale references
	const errorsRef = useRef(errors); // For use in functions that have stale references
	const savingStateRef = useRef(savingState); // For use in functions that have stale references
	const dispatch = useDispatch();

	const loadWorkflow = (id = 0) => {
		request
			.get(`${API_HOST}/api/workflow/${id}`)
			.withCredentials()
			.then((res) => {
				const { body: workflow } = res || {};
				if (workflow) {
					setWorkflow(workflow);
					initializeValidate(setErrors, workflow.id > 0, clearErrors);
					setSavingState({
						...clearSavingState,
					});
				}
			})
			.catch((err) => {
				showSignIn(err, () => {
					loadWorkflow(id);
				});
			});
	};

	const loadUsers = () => {
		request
			.get(`${API_HOST}/api/users?firstNameLastName=true&emailAddress=true&includeDeleted=true`)
			.withCredentials()
			.then((res) => {
				const { body: users } = res || {};
				if (users) {
					// Give each user a number to use for the avatar background
					let number = 0;
					const numberCache = {};
					users.forEach((user) => {
						if (typeof numberCache[user.id] === "undefined") {
							numberCache[user.id] = number;
							number++;
						}
						user.number = numberCache[user.id];
					});

					setUsers(users);
				}
			})
			.catch((err) => {
				showSignIn(err, () => {
					loadUsers();
				});
			});
	};

	const backToWorkflows = () => {
		const { isSaving, updated } = savingStateRef.current;

		if (isSaving || updated) {
			if (confirm(t("detail.unsavedChanges"))) {
				navigate("/workflows");
			} else {
				return;
			}
		} else {
			navigate("/workflows");
		}

		return true;
	};

	const handleSave = () => {
		// Enable full validation
		initializeValidate(setErrors, true);

		if (!hasValidationErrors(errorsRef.current)) {
			saveWorkflow(workflowRef.current);
		}
	};

	const handleDelete = () => {
		setDialogs({ delete: true });
	};

	const closeDeleteDialog = () => {
		setDialogs({});
	};

	const afterDelete = () => {
		telemetryAddEvent(`${telemetryPage} - Delete`, { area: "workflows" });

		navigate("/workflows");
	};

	const undoDelete = (deletedWorkflow) => {
		request
			.post(`${API_HOST}/api/workflow/${deletedWorkflow.id}/restore`)
			.withCredentials()
			.send({})
			.then(() => {
				let option = notifierMessage(t("deleteWorkflowDialog.undo.successful"), "success");
				dispatch(setSnackbarOptions(option));

				navigate(`/workflows/edit/${deletedWorkflow.id}`);
			})
			.catch((err) => {
				showSignIn(err, () => {
					undoDelete(deletedWorkflow);
				});
			});
	};

	const tabChange = (_e, selectedTab) => {
		setTab(selectedTab);
	};

	const saveWorkflow = (updatedWorkflow) => {
		setSavingState((prev) => ({
			...prev,
			isSaving: true,
			updated: false,
		}));

		telemetryAddEvent(`${telemetryPage} - Save`, { area: "workflows" });

		request
			.put(`${API_HOST}/api/workflow/${updatedWorkflow.id}`)
			.withCredentials()
			.send(updatedWorkflow)
			.then((res) => {
				if (res.status === 200) {
					const {
						body: { workflow },
					} = res || {};

					if (updatedWorkflow.id !== workflow.id) {
						navigate(`/workflows/edit/${workflow.id}`, { replace: true });
					}

					setWorkflow(workflow);
					setSavingState((prev) => ({
						...prev,
						initialized: false,
						isSaving: false,
					}));

					let option = notifierMessage(t("detail.snackbar.saved"), "success");
					dispatch(setSnackbarOptions(option));
				}
			})
			.catch((err) => {
				showSignIn(err, () => {
					saveWorkflow(updatedWorkflow);
				});
			});
	};

	const sanitizeData = (updatedWorkflow) => {
		// Set the first workflow block name
		if (workflow.blocks.length === 1 && !workflow.blocks[0].name) {
			workflow.blocks[0].name = updatedWorkflow.name;
		}

		// Set the correct fail rule
		switch (workflow.blocks.length) {
			case 0:
				workflow.failRule = FAIL_RULE_NONE;
				break;

			case 1:
				if (![FAIL_RULE_NONE, FAIL_RULE_CURRENT].includes(workflow.failRule)) {
					workflow.failRule = FAIL_RULE_CURRENT;
				}
				break;

			case 2:
				if (![FAIL_RULE_NONE, FAIL_RULE_CURRENT, FAIL_RULE_FULL].includes(workflow.failRule)) {
					workflow.failRule = FAIL_RULE_CURRENT;
				}
				break;
		}

		const failRuleTiered = updatedWorkflow.failRule === FAIL_RULE_TIERED;
		updatedWorkflow.blocks.forEach((block) => {
			// Set appropriate failsToTier values
			block.failsToTier = failRuleTiered ? Math.min(Math.max(block.failsToTier, 1), block.tier) : 0;
		});
	};

	const sanitizeDataOnAddBlock = (updatedWorkflow) => {
		// Clear the first block name if it matches the workflow name
		if (workflow.blocks.length > 1 && workflow.blocks[0].name === updatedWorkflow.name) {
			workflow.blocks[0].name = "";
		}
	};

	const validateWorkflow = (updatedWorkflow, updatedField) => {
		setErrors((prev) => {
			if (!updatedWorkflow.name) {
				prev.name = t("detail.validation.name");
			} else {
				prev.name = "";
			}

			prev.blocks = updatedWorkflow.blocks.map((block) => ({
				id: block.id,
				name: !block.name ? t("detail.validation.blockName") : "",
			}));

			setValidate(prev, updatedField);

			return { ...prev };
		});
	};

	const hasValidationErrors = (errorsObject) =>
		errorsObject.name || errorsObject.blocks.length === 0 || errorsObject.blocks.filter((blockError) => blockError.name).length > 0;

	const updateWorkflow = useUpdateObject(setWorkflow, sanitizeData, validateWorkflow, () => {
		setSavingState((prev) => ({
			...prev,
			updated: true,
		}));
	});

	const restoreBlock = (block, updateTiers) => {
		if (block) {
			updateWorkflow(
				{
					target: {
						value: { block, updateTiers },
					},
				},
				"blocks",
				false,
				false,
				undefined,
				(newValue, prevValue) => {
					const blockIndex = prevValue.findIndex((workflowBlock) => workflowBlock.tier === newValue.block.tier); // Find the block to remove
					if (blockIndex >= 0) {
						prevValue.splice(blockIndex, 0, newValue.block);
					} else {
						prevValue.push(newValue.block);
					}
					if (newValue.updateTiers) {
						// Update the block tier numbers if needed
						prevValue.forEach((workflowBlock) => {
							if (workflowBlock.tier >= newValue.block.tier && workflowBlock.id !== newValue.block.id) {
								workflowBlock.tier += 1;
							}
						});
					}

					return prevValue;
				},
				sanitizeDataOnAddBlock,
			);
		}
	};

	const removeBlock = (blockId) => {
		updateWorkflow(
			{
				target: {
					value: blockId,
				},
			},
			"blocks",
			false,
			false,
			undefined,
			(newValue, prevValue) => {
				const blockIndex = prevValue.findIndex((block) => block.id === newValue); // Find the block to remove
				let block = null;
				let updateTiers = false;
				if (blockIndex >= 0) {
					block = prevValue[blockIndex];

					const removedBlockTier = prevValue[blockIndex].tier;

					prevValue.splice(blockIndex, 1);

					updateTiers = !prevValue.find((block) => block.tier === removedBlockTier); // Check if the higher tiers need to be adjusted
					if (updateTiers) {
						prevValue
							.filter((block) => block.tier > removedBlockTier)
							.forEach((block) => {
								block.tier -= 1;
							});
					}
				}

				let option = notifierMessage(t("detail.snackbar.blockRemoved"), "success", () => restoreBlock(block, updateTiers));
				dispatch(setSnackbarOptions(option));

				return prevValue;
			},
		);
	};

	const addBlock = () => {
		telemetryAddEvent(`${telemetryPage} - Add Approval Block`, { area: "workflows" });

		updateWorkflow(
			{
				target: {
					value: null,
				},
			},
			"blocks",
			false,
			false,
			undefined,
			(_newValue, prevValue) => {
				const tempId = Math.min(...prevValue.map((block) => block.id).concat([0])) - 1; // Use negative numbers to indicate new blocks

				prevValue.push({
					id: tempId,
					name: "",
					users: [],
					requiredApprovers: 0,
					ordered: false,
					tier: prevValue.length + 1,
					failsToTier: 0,
				});

				return prevValue;
			},
			sanitizeDataOnAddBlock,
		);
	};

	useEffect(() => {
		if (workflow) {
			let itemDescription = !workflow.template ? stripHtml(workflow.itemDescription) : "";
			if (itemDescription.length > 50) {
				itemDescription = processHtml(`${itemDescription.substr(0, 50)}&hellip;`);
			}

			dispatch(
				updatePageConfigs({
					title: t(`title.workflow${itemDescription ? "ItemName" : workflow.template && workflow.name ? "Name" : ""}`, {
						name: workflow.name,
						itemName: itemDescription,
					}),
					telemetryPage,
				}),
			);
		}
	}, [(workflow || {}).name || ""]);

	useEffect(() => {
		// Set the block user values for the avatar and labels
		if (workflow && users && !savingState.initialized) {
			workflow.blocks.forEach((block) => {
				block.users.forEach((blockUser) => {
					const user = users.find((user) => user.id === blockUser.id);
					if (user) {
						blockUser.number = user.number;
						blockUser.userInactive = user.inactive;
						blockUser.userDeleted = user.deleted;
					}
				});
			});

			setSavingState((prev) => ({
				...prev,
				initialized: true,
			}));
		}
	}, [!!workflow, !!users, savingState.initialized]);

	useEffect(() => {
		workflowRef.current = workflow;
		errorsRef.current = errors;
		savingStateRef.current = savingState;
	}, [workflow, errors, savingState]);

	useEffect(() => {
		dispatch(resetPageConfigs({}));
		dispatch(
			updatePageConfigs({
				title: t("title.workflow"),
				back: { action: backToWorkflows },
				telemetryPage,
			}),
		);
		dispatch(
			updateTabs({
				display: true,
				selectedTab: 0,
				tabsOptions: [
					{ key: "workflow", label: t("tabs.workflow"), dataCy: "tab-workflow" },
					{ key: "notifications", label: t("tabs.notifications"), dataCy: "tab-notifications" },
				],
				onChange: (_e, newTab) => {
					dispatch(
						updateTabs({
							selectedTab: newTab,
						}),
					);

					tabChange(_e, newTab);
				},
			}),
		);

		const workflowId = !isNaN(parseInt(id, 10)) ? parseInt(id, 10) : 0;
		loadWorkflow(id);
		loadUsers();

		if (workflowId) {
			telemetryAddEvent(`${telemetryPage} - Open`, { area: "workflows" });
		}

		return () => {
			document.body.style.userSelect = null;
		};
	}, [id]);

	useEffect(() => {
		const menuOptions = [];
		if (workflow && workflow.id > 0 && workflow.template) {
			menuOptions.push({
				label: t("app:buttons.delete"),
				tooltip: t("tooltips.delete"),
				actionFunction: handleDelete,
				"data-cy": "delete",
			});
		}

		dispatch(
			updatePageHeader({
				primaryAction: handleSave,
				primaryActionText: !savingState.isSaving ? t("app:buttons.save") : t("app:buttons.saving"),
				primaryActionTooltip: !savingState.isSaving ? t("tooltips.save") : undefined,
				primaryActionDisabled: savingState.isSaving || !savingState.updated,
				menuOptions,
			}),
		);
	}, [id, !!workflow, savingState.isSaving, savingState.updated]);

	const unsavedChangesText = t("detail.unsavedChanges");
	useBackButtonHandler(savingState, "/workflows", unsavedChangesText);

	return (
		<ComponentContainer padding="16px">
			{dialogs.delete && workflow && (
				<WorkflowDeleteDialog
					workflow={workflow}
					show={dialogs.delete}
					afterDelete={afterDelete}
					onClose={closeDeleteDialog}
					undoDelete={undoDelete}
				/>
			)}
			{workflow ? (
				<>
					{tab === tabs.workflow && (
						<Workflow
							workflow={workflow}
							users={users}
							errors={errors}
							handleUpdate={updateWorkflow}
							handleRemoveBlock={removeBlock}
							handleAddBlock={addBlock}
							telemetryPage={telemetryPage}
						/>
					)}
					{tab === tabs.notifications && (
						<WorkflowNotifications workflow={workflow} users={users} errors={errors} handleUpdate={updateWorkflow} />
					)}
				</>
			) : (
				<CircularProgressIndicator />
			)}
		</ComponentContainer>
	);
};

export default withErrorHandling(WorkflowContainer);
