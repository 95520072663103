import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import clsx from "clsx";
import ButtonWithTooltip from "atlas/components/Buttons/ButtonWithTooltip";
import { MEDIUM } from "atlas/utils/buttonSize";
import { useTranslation } from "react-i18next";
import Parser from "html-react-parser";

const useStyles = makeStyles((theme) => ({
	tableCell: {
		borderBottom: "none",
	},
	tableFirstRow: {
		fontWeight: "600",
		color: "#000000",
		fontSize: "15px",
		lineHeight: "16px",
	},
	btn: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		position: "relative",
	},
	tableTd: {
		padding: "0px",
		textAlign: "left",
		fontWeight: "400",
		fontSize: "16px",
		paddingLeft: "25px",
	},
	tableTh: {
		padding: "0px",
		paddingTop: "6px",
		paddingBottom: "8px",
		paddingLeft: "10px",
	},
}));
export default function SelectedMeetingDetails(props) {
	const { heading, meeting } = props.item;
	const { meetingGroups, handleSelectMeeting } = props;
	const { t } = useTranslation("agendaItems");

	const [rows, setRows] = useState([]);
	useEffect(() => {
		const meetingGroup =
			meetingGroups &&
			meetingGroups.filter((groupItem) => {
				return meeting && meeting.boardId == groupItem.id;
			});
		const selectedMeetingGroup = meetingGroup && meetingGroup[0] && meetingGroup[0].name;

		const rows = [
			{
				name: t("selectMeetingDialog.meetingGroup"),
				value: selectedMeetingGroup ? selectedMeetingGroup : t("noMeetingsSelected.noGroupSelected"),
			},
			{
				name: t("selectMeetingDialog.meeting"),
				value: meeting && meeting.meeting ? meeting.meeting : t("noMeetingsSelected.noMeetingSelected"),
			},
			{
				name: t("selectMeetingDialog.section"),
				value: heading ? Parser(heading) : t("noMeetingsSelected.noSectionSlected"),
			},
		];
		setRows(rows);
	}, [meetingGroups, heading, meeting, props.item]);
	const classes = useStyles();
	return (
		<Box sx={{ border: "1px solid #B3B3B3", borderRadius: "5px", marginBottom: "12px" }}>
			<Grid container spacing={2}>
				<Grid item xs sx={{ minWidth: "60%" }}>
					<TableContainer>
						<Table aria-label="selected-item-table">
							<TableBody>
								{rows.map((row) => (
									<TableRow key={row.name} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
										<TableCell component="th" scope="row" className={clsx(classes.tableCell, classes.tableFirstRow, classes.tableTh)}>
											{row.name}
										</TableCell>
										<TableCell align="right" className={clsx(classes.tableCell, classes.tableTd)}>
											{row.value}
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>
				</Grid>
				{handleSelectMeeting && (
					<Grid className={classes.btn} xs>
						<ButtonWithTooltip
							className={classes.btn}
							variant="outlined"
							title={t("toolbar.selectMeeting")}
							size={MEDIUM}
							hasTopMargin
							onClick={handleSelectMeeting}
							data-cy="menu-select-meeting"
							fullWidth
						>
							{t("toolbar.selectMeeting")}
						</ButtonWithTooltip>
					</Grid>
				)}
			</Grid>
		</Box>
	);
}
