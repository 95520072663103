import { produce } from "immer";

import findIndex from "lodash/fp/findIndex";

import {
	updateAgenda,
	reOrderItemArray,
	findItemByID,
	getSection,
	getSectionLength,
	getSubsection,
	getSubsectionLength,
	getDistanceUpToValidSubsectionLocation,
	getItem,
	getItemLength,
	getDistanceUpToValidItemLocation,
} from "./utils";

export function moveHeadingUp(guid) {
	const currentState = this.state;
	const nextState = produce(
		currentState,
		(draft) => {
			const currentIndex = findIndex((item) => item.guid === guid, draft.items);
			const movingItems = getSection(guid, draft.items);
			const prevSectionLength = getSectionLength(draft.items[currentIndex - 1].guid, draft.items);
			const moveToIndex = currentIndex - prevSectionLength;

			draft.items.splice(currentIndex, movingItems.length);
			draft.items.splice(moveToIndex, 0, ...movingItems);
			reOrderItemArray(draft.items, this, currentState.customNumbering);
		},
		(patches, inversePatches) => {
			if (patches.length) {
				this.redoStack.push([...patches]);
				this.undoStack.push([...inversePatches]);
			}
		},
	);
	this.itemIdsToUpdate.push(guid);
	updateAgenda(this, { items: nextState.items });
}

export function moveHeadingDown(guid) {
	const currentState = this.state;
	const nextState = produce(
		currentState,
		(draft) => {
			const currentIndex = findIndex((item) => item.guid === guid, draft.items);
			const movingItems = getSection(guid, draft.items);
			const nextHeadingIndex = currentIndex + movingItems.length;
			const nextSectionLength = getSectionLength(draft.items[nextHeadingIndex].guid, draft.items);
			const moveToIndex = nextHeadingIndex + nextSectionLength - movingItems.length;

			draft.items.splice(currentIndex, movingItems.length);
			draft.items.splice(moveToIndex, 0, ...movingItems);

			reOrderItemArray(draft.items, this, currentState.customNumbering);
		},
		(patches, inversePatches) => {
			if (patches.length) {
				this.redoStack.push([...patches]);
				this.undoStack.push([...inversePatches]);
			}
		},
	);
	this.itemIdsToUpdate.push(guid);
	updateAgenda(this, { items: nextState.items });
}

export function moveSubheadingUp(guid) {
	const currentState = this.state;
	const nextState = produce(
		currentState,
		(draft) => {
			const currentIndex = findIndex((item) => item.guid === guid, draft.items);
			const distanceToMove = getDistanceUpToValidSubsectionLocation(draft.items[currentIndex].guid, draft.items);
			let moveToIndex = currentIndex - distanceToMove;
			if (moveToIndex === 0) moveToIndex = 1; // menu should be disabled if this were possible, so this is just a safety net.
			const movingItems = getSubsection(guid, draft.items);

			draft.items.splice(currentIndex, movingItems.length);
			draft.items.splice(moveToIndex, 0, ...movingItems);
			reOrderItemArray(draft.items, this, currentState.customNumbering);
		},
		(patches, inversePatches) => {
			if (patches.length) {
				this.redoStack.push([...patches]);
				this.undoStack.push([...inversePatches]);
			}
		},
	);
	this.itemIdsToUpdate.push(guid);
	updateAgenda(this, { items: nextState.items });
}

export function moveSubheadingDown(guid) {
	const currentState = this.state;
	const nextState = produce(
		currentState,
		(draft) => {
			const currentIndex = findIndex((item) => item.guid === guid, draft.items);
			const movingItems = getSubsection(guid, draft.items);
			const nextHeadingIndex = currentIndex + movingItems.length;
			const nextSectionLength = getSubsectionLength(draft.items[nextHeadingIndex].guid, draft.items);
			const moveToIndex = nextHeadingIndex + nextSectionLength - movingItems.length;

			draft.items.splice(currentIndex, movingItems.length);
			draft.items.splice(moveToIndex, 0, ...movingItems);

			reOrderItemArray(draft.items, this, currentState.customNumbering);
		},
		(patches, inversePatches) => {
			if (patches.length) {
				this.redoStack.push([...patches]);
				this.undoStack.push([...inversePatches]);
			}
		},
	);
	this.itemIdsToUpdate.push(guid);
	updateAgenda(this, { items: nextState.items });
}

export function moveItemUp(guid) {
	const currentState = this.state;
	const nextState = produce(
		currentState,
		(draft) => {
			const currentIndex = findIndex((item) => item.guid === guid, draft.items);
			const distanceToMove = getDistanceUpToValidItemLocation(draft.items[currentIndex].guid, draft.items);
			let moveToIndex = currentIndex - distanceToMove;
			if (moveToIndex === 0) moveToIndex = 1; // menu should be disabled if this were possible, so this is just a safety net.
			const movingItems = getItem(guid, draft.items);

			draft.items.splice(currentIndex, movingItems.length);
			draft.items.splice(moveToIndex, 0, ...movingItems);
			reOrderItemArray(draft.items, this, currentState.customNumbering);
		},
		(patches, inversePatches) => {
			if (patches.length) {
				this.redoStack.push([...patches]);
				this.undoStack.push([...inversePatches]);
			}
		},
	);

	updateAgenda(this, { items: nextState.items });
}

export function moveItemDown(guid) {
	const currentState = this.state;
	const nextState = produce(
		currentState,
		(draft) => {
			const currentIndex = findIndex((item) => item.guid === guid, draft.items);
			const movingItems = getItem(guid, draft.items);
			const nextItemIndex = currentIndex + movingItems.length;
			const nextItemLength = getItemLength(draft.items[nextItemIndex].guid, draft.items);
			const moveToIndex = nextItemIndex + nextItemLength - movingItems.length;

			draft.items.splice(currentIndex, movingItems.length);
			draft.items.splice(moveToIndex, 0, ...movingItems);

			reOrderItemArray(draft.items, this, currentState.customNumbering);
		},
		(patches, inversePatches) => {
			if (patches.length) {
				this.redoStack.push([...patches]);
				this.undoStack.push([...inversePatches]);
			}
		},
	);

	updateAgenda(this, { items: nextState.items });
}

export function moveRecommendationUp(guid) {
	const currentState = this.state;
	const nextState = produce(
		currentState,
		(draft) => {
			const currentIndex = findIndex((item) => item.guid === guid, draft.items);
			if (currentIndex === 1) return; // don't move above 1st section
			const recommendation = draft.items[currentIndex];
			draft.items.splice(currentIndex, 1);
			draft.items.splice(currentIndex - 1, 0, recommendation);
			reOrderItemArray(draft.items, this, currentState.customNumbering);
		},
		(patches, inversePatches) => {
			if (patches.length) {
				this.redoStack.push([...patches]);
				this.undoStack.push([...inversePatches]);
			}
		},
	);

	updateAgenda(this, { items: nextState.items });
}

export function moveRecommendationDown(guid) {
	const currentState = this.state;
	const nextState = produce(
		currentState,
		(draft) => {
			const currentIndex = findIndex((item) => item.guid === guid, draft.items);
			const recommendation = draft.items[currentIndex];
			draft.items.splice(currentIndex, 1);
			draft.items.splice(currentIndex + 1, 0, recommendation);

			reOrderItemArray(draft.items, this, currentState.customNumbering);
		},
		(patches, inversePatches) => {
			if (patches.length) {
				this.redoStack.push([...patches]);
				this.undoStack.push([...inversePatches]);
			}
		},
	);

	updateAgenda(this, { items: nextState.items });
}
