import React from "react";
import { useTranslation } from "react-i18next";

import makeStyles from "@mui/styles/makeStyles";
import { TableCell } from "@mui/material";

import tableDataStyle from "assets/jss/components/tableDataStyle";
import { formatDate } from "utils/date";

const useStyles = makeStyles(tableDataStyle);

const TimeCell = (props) => {
	const { value } = props;
	const { t } = useTranslation();
	const classes = useStyles();

	return <TableCell className={classes.cell}>{formatDate(null, value, null, t("app:at"), "", "", true, true, false)}</TableCell>;
};

export default TimeCell;
