import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import cookie from "react-cookies";

import { useWidthUp } from "atlas/utils/useWidth";
import MeetingPreviewDialog from "components/Dialogs/MeetingPreviewDialog";
import MeetingPublishDialog from "components/Dialogs/MeetingPublishDialog";
import MeetingUnpublishDialog from "components/Dialogs/MeetingUnpublishDialog";
import MeetingShareDialog from "components/Dialogs/MeetingShareDialog";
import SupportRequestDialog from "components/Dialogs/SupportRequestDialog";
import ErrorDialogMeetingPublish from "components/Dialogs/ErrorDialogMeetingPublish";
import { isPublished } from "utils/agendaStatuses";
import { formatDate } from "utils/date";
import PublicAgendaStatusesEnum from "utils/enums/PublicAgendaStatuses";
import BoardAgendaStatusesEnum from "utils/enums/BoardAgendaStatuses";
import telemetryAddEvent from "utils/telemetryAddEvent";
import { updateToolbar, updatePageConfigs } from "../../../redux/app/actions";
import notifierMessage from "utils/notifierMessage";
import { setSnackbarOptions } from "redux/snackBar/actions";

const AgendaTopBar = (props) => {
	const {
		showSignIn,
		meeting,
		items,
		agendaNumbering,
		badAttachmentsErrors,
		editorFunctions: { saveAgenda, undo, redo } = {},
		editorFunctions,
		saveStatus,
		saveTooltip = "",
		canUndo,
		canRedo,
		uploadingStatus,
		uploadingTooltip = "",
		reloadItems,
		oldAgenda = false,
		showUndoRedo = true,
		toggleSidebar,
	} = props;
	const widthUpMd = useWidthUp("md");
	const widthUpLg = useWidthUp("lg");
	const { t } = useTranslation("agendaMenu");
	const navigate = useNavigate();
	const location = useLocation();
	const [dialogs, setDialogs] = useState({});
	const [showedError, setShowedError] = useState(false);
	const [publicAgendaStatus, setPublicAgendaStatus] = useState(meeting ? meeting.publicAgendaStatus : null);
	const dispatch = useDispatch();
	const meetingActive = meeting ? meeting.startTimeStamp <= Math.floor(Date.now() / 1000) : false;

	const { agenda: { saving, updated, error, status } = {} } = useSelector((state) => state.agendaBuilderReducer);
	const closeDialogs = () => setDialogs({});

	// Support Request dialog ------------------------------------------------------------------
	const openSupportRequestDialog = () => setDialogs({ supportRequest: true });

	const closeSupportRequestDialog = () => closeDialogs();

	const closeMeetingPreviewDialog = () => closeDialogs();

	// Bad attachments dialog
	const closeBadAttachmentsDialog = () => closeDialogs();

	// Meeting Publish dialog ------------------------------------------------------------------
	const openMeetingPublishDialog = () => setDialogs({ publish: true });

	const closeMeetingPublishDialog = () => closeDialogs();

	const afterPublishMeeting = ({ error, agendaNotice }) => {
		setPublicAgendaStatus(PublicAgendaStatusesEnum().PUBLISHED.value);

		if (agendaNotice && agendaNotice.documentId > 0) {
			window.open(`/document/${agendaNotice.documentId}`, "_blank");
		}

		if (!error) {
			let option = notifierMessage(t("meetings:publishMeetingDialog.snackbar.success.agenda"), "success");
			dispatch(setSnackbarOptions(option));
		}
	};

	// Meeting Unpublish dialog ------------------------------------------------------------------
	const openMeetingUnpublishDialog = () => setDialogs({ unpublish: true });

	const closeMeetingUnpublishDialog = () => closeDialogs();

	const afterUnpublishMeeting = () => {
		setPublicAgendaStatus(PublicAgendaStatusesEnum().NOTPUBLISHED.value);

		let option = notifierMessage(t("meetings:unpublishMeetingDialog.snackbar.success.agenda"), "success");
		dispatch(setSnackbarOptions(option));
	};

	const closeCopyMoveDialog = () => closeDialogs();

	const afterCopyMove = () => reloadItems();

	// Meeting Share dialog ------------------------------------------------------------------
	const openMeetingShareDialog = () => setDialogs({ share: true });

	const closeMeetingShareDialog = () => closeDialogs();

	const afterShareMeeting = ({ error }) => {
		meeting.boardAgendaStatus = BoardAgendaStatusesEnum().SHARED.value;

		if (!error) {
			let option = notifierMessage(t("meetings:shareMeetingDialog.snackbar.success.agenda"), "success");
			dispatch(setSnackbarOptions(option));
		}
	};

	const previewAgenda = (members) => setDialogs({ preview: { members } });

	const previewAgendaWithSave = (members) => {
		saveAgenda({
			success: previewAgenda,
			data: members,
		});
	};

	const openLiveMeeting = () => {
		dispatch(
			updatePageConfigs({
				preferedBack: { url: location.pathname + location.search },
			}),
		);

		navigate(`/meeting/${(cookie.load("old_minutes") || "false") === "false" ? "liveV2" : "live"}/${meeting.id}`);
	};

	// Display tools
	useEffect(() => {
		dispatch(
			updateToolbar({
				display: true,
				left: {
					tools: [
						{
							id: "table-of-contents-toggle",
							icon: "split-pane-white",
							label: t("tableOfContents"),
							tooltipText: t("tableOfContents"),
							onClick: toggleSidebar,
							dataCy: "table-of-contents-toggle",
							hidden: !toggleSidebar,
						},
						{
							separator: true,
						},
						{
							id: "undo",
							icon: "undo",
							tooltipText: t("undo"),
							ariaLabel: t("undo"),
							disabled: !canUndo,
							onClick: undo,
							dataCy: "undo",
							hidden: !showUndoRedo,
						},
						{
							id: "redo",
							icon: "redo",
							tooltipText: t("redo"),
							ariaLabel: t("redo"),
							disabled: !canRedo,
							onClick: redo,
							dataCy: "redo",
							hidden: !showUndoRedo,
						},
						{
							separator: true,
							hidden: !widthUpMd || widthUpLg,
						},
						{
							id: "toggle-outline",
							label: t("outline"),
							tooltipText: t("outline"),
							ariaLabel: t("outline"),
							onClick: () => {
								editorFunctions.toggleTableOfContentDrawer();
							},
							dataCy: "menu-toc",
							hidden: widthUpLg,
							overflow: !widthUpMd,
						},
						{
							separator: true,
							hidden: !showUndoRedo,
						},
						{
							id: "agenda-preview",
							label: t("preview"),
							tooltipText: uploadingStatus ? t("meetings:tooltips.uploadInProgress") : t("previewTooltip"),
							ariaLabel: uploadingStatus ? t("meetings:tooltips.uploadInProgress") : t("previewTooltip"),
							dataCy: "preview",
							disabled: uploadingStatus,
							menu: {
								position: "bottom-start",
								options: [
									{
										label: `${t("member")} ${t("agenda").toLowerCase()}`,
										actionFunction: () => {
											previewAgendaWithSave(true);
										},
										dataCy: "menu-preview-member-agenda",
									},
									{
										label: `${t("public")} ${t("agenda").toLowerCase()}`,
										actionFunction: () => {
											previewAgendaWithSave(false);
										},
										hidden: !(meeting && !meeting.closed),
										dataCy: "menu-preview-public-agenda",
									},
								],
							},
						},
						{
							id: "agenda-share",
							label: t("share"),
							ariaLabel: uploadingStatus ? t("meetings:tooltips.uploadInProgress") : t("meetings:tooltips.shareAgenda"),
							onClick: openMeetingShareDialog,
							dataCy: "menu-share",
							disabled: uploadingStatus,
						},
						{
							id: "agenda-publish",
							label: t("publish"),
							ariaLabel: uploadingStatus ? t("meetings:tooltips.uploadInProgress") : t("meetings:tooltips.publishAgenda"),
							onClick: openMeetingPublishDialog,
							dataCy: "menu-publish",
							hidden: !(meeting && !meeting.closed),
							overflow: !widthUpMd,
							disabled: uploadingStatus,
						},
						{
							id: "agenda-unpublish",
							label: t("unpublish"),
							tooltipText: t("unpublishTooltip"),
							onClick: openMeetingUnpublishDialog,
							dataCy: "menu-unpublish",
							hidden: !(meeting && isPublished(publicAgendaStatus)),
							overflow: !widthUpMd,
						},
						{
							separator: true,
						},
						{
							id: "menu-copyAndMove",
							label: t(`app:buttons:copyAndMove`),
							tooltipText: t(`app:buttons:copyMoveTooltip`),
							ariaLabel: t(`app:buttons:copyAndMove`),
							icon: "cheveron-down",
							menu: {
								position: "bottom-end",
								options: [
									{
										label: t(`app:buttons:copyItems`),
										actionFunction: () => {
											telemetryAddEvent("Agenda builder - Copy Items");
											navigate(`/meeting/copy/${meeting.id}`);
										},
										icon: "copy-agenda-items-icon",
										dataCy: "menu-copy-agenda-items",
									},
									{
										label: t(`app:buttons:moveItems`),
										actionFunction: meetingActive
											? undefined
											: () => {
													telemetryAddEvent("Agenda builder - Move Items");
													navigate(`/meeting/move/${meeting.id}`);
												},
										icon: "move-agenda-items-icon",
										dataCy: "menu-move-agenda-items",
										disableTooltipText: meetingActive ? "Move not available after meeting start time" : false,
									},
								],
							},
							dataCy: "menu-transferitems",
							overflow: !widthUpMd,
						},
						{
							separator: true,
							hidden: !(
								meeting &&
								isPublished(publicAgendaStatus) &&
								new Date(formatDate(meeting.date, null, null, "", "", "", true)) >= new Date(new Date().toDateString())
							),
						},
						{
							id: "live-meeting",
							label: t("meetings:liveMeeting.label"),
							onClick: openLiveMeeting,
							dataCy: "menu-live-meeting",
							hidden: !(
								meeting &&
								isPublished(publicAgendaStatus) &&
								new Date(formatDate(meeting.date, null, null, "", "", "", true)) >= new Date(new Date().toDateString())
							),
						},
						{
							separator: true,
							hidden: !widthUpMd,
						},
						{
							id: "toggle-scratchpad",
							label: t("scratchpad"),
							tooltipText: t("scratchpadTooltip"),
							ariaLabel: t("scratchpadTooltip"),
							onClick: () => {
								telemetryAddEvent("Agenda builder - Toggle scratchpad");

								editorFunctions.toggleScratchpadDrawer();
							},
							dataCy: "menu-scratchpad",
							overflow: !widthUpMd,
						},
						{
							separator: true,
							hidden: !widthUpMd,
						},
						{
							id: "toggle-board-notes",
							label: t("boardNotes"),
							tooltipText: t("membersBriefingNotes"),
							ariaLabel: t("membersBriefingNotes"),
							onClick: () => {
								telemetryAddEvent("Agenda builder - Toggle Members briefing notes");

								editorFunctions.toggleBoardNotes();
							},
							dataCy: "menu-board-notes",
							overflow: !widthUpMd,
						},
					],
				},
				right: {
					tools: [
						{
							overflowMenu: true,
						},
						{
							id: "uploading-status",
							label: uploadingStatus,
							tooltipText: uploadingTooltip,
							hidden: !uploadingStatus,
						},
						{
							id: "saving-status",
							label: oldAgenda ? saveStatus : status && status.label,
							tooltipText: oldAgenda ? saveTooltip : status && status.tooltip,
							hidden: oldAgenda ? !saveStatus : updated && !error,
						},
					],
				},
			}),
		);
	}, [
		editorFunctions,
		widthUpMd,
		widthUpLg,
		canUndo,
		canRedo,
		uploadingStatus,
		saveStatus,
		publicAgendaStatus,
		updated,
		saving,
		error,
		status,
		meeting,
	]);

	useEffect(() => {
		if (badAttachmentsErrors && badAttachmentsErrors.length > 0 && !showedError) {
			setDialogs({ badAttachments: true });
			setShowedError(true);
		}
	}, [badAttachmentsErrors, showedError]);

	useEffect(() => {
		if (meeting) {
			setPublicAgendaStatus(meeting.publicAgendaStatus);
		}
	}, [meeting]);

	return meeting && editorFunctions ? (
		<>
			{dialogs.supportRequest && <SupportRequestDialog show onClose={closeSupportRequestDialog} />}

			{dialogs.badAttachments && (
				<ErrorDialogMeetingPublish
					errors={badAttachmentsErrors}
					handleClose={closeBadAttachmentsDialog}
					show
					openSupportRequestDialog={openSupportRequestDialog}
					meeting={meeting}
					triggeredBy="badAttachment"
					title={t}
					editorFunctions={editorFunctions}
				/>
			)}

			{dialogs.preview && (
				<MeetingPreviewDialog
					show
					meeting={meeting}
					agenda
					members={dialogs.preview.members}
					onClose={closeMeetingPreviewDialog}
					showSignIn={showSignIn}
					openSupportRequestDialog={openSupportRequestDialog}
					editorFunctions={editorFunctions}
				/>
			)}

			{dialogs.publish && (
				<MeetingPublishDialog
					showSignIn={showSignIn}
					show
					openSupportRequestDialog={openSupportRequestDialog}
					afterPublish={afterPublishMeeting}
					onClose={closeMeetingPublishDialog}
					meeting={meeting}
					editorFunctions={editorFunctions}
					telemetryPage="Agenda builder"
				/>
			)}

			{dialogs.unpublish && (
				<MeetingUnpublishDialog
					show
					afterUnpublish={afterUnpublishMeeting}
					onClose={closeMeetingUnpublishDialog}
					meeting={meeting}
					telemetryPage="Agenda builder"
				/>
			)}

			{dialogs.share && (
				<MeetingShareDialog
					show
					openSupportRequestDialog={openSupportRequestDialog}
					afterShare={afterShareMeeting}
					onClose={closeMeetingShareDialog}
					meeting={meeting}
					editorFunctions={editorFunctions}
					telemetryPage="Agenda builder"
				/>
			)}
		</>
	) : null;
};

export default AgendaTopBar;
