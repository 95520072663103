/* eslint-disable import/prefer-default-export */
import { combineReducers } from "redux";

import { appReducer } from "./app/reducers";
import { liveMeetingReducer } from "./liveMeeting/reducers";
import { meetingsReducer } from "./meeting/reducers";
import { meetingTemplatesReducer } from "./meetingTemplate/reducers";
import { policyGovernanceReducer } from "./policyGovernance/reducers";
import { pageHeaderReducer } from "./pageHeader/reducers";
import { snackbarReducer } from "./snackBar/reducers";
import { toolbarReducer } from "./toolbar/reducers";
import { publicSiteReducer } from "./publicSite/reducers";
import { agendaBuilderReducer } from "./agendaBuilder/reducers";
import { generalSettingsReducer } from "./generalSettings/reducers";
import { goalReducer } from "./goals/reducers";
import { newCopyAndMoveReducer } from "./NewCopyAndMove/reducers";

export const Reducers = combineReducers({
	appReducer,
	liveMeetingReducer,
	meetingsReducer,
	meetingTemplatesReducer,
	pageHeaderReducer,
	snackbarReducer,
	toolbarReducer,
	publicSiteReducer,
	agendaBuilderReducer,
	generalSettingsReducer,
	policyGovernanceReducer,
	goalReducer,
	newCopyAndMoveReducer,
});
