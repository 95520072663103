const formatSize = (size, defaultValue) => (size || size === 0 ? (typeof size === "number" ? `${size}px` : size) : defaultValue);

const subtractSizes = (initial, toSubtract) =>
	initial ? (toSubtract ? `calc(${initial} - ${Array.isArray(toSubtract) ? toSubtract.join(" - ") : toSubtract})` : initial) : undefined;

const baseStyle = {
	margin: "0",
	padding: (props) => props.padding || "0",
	overflowY: "auto",
	overflowX: "hidden",
	boxSizing: "border-box",
};
const inContentStyle = {
	...baseStyle,
	top: (props) => (props?.sizes?.top ? `${props.sizes.top}px` : "0"),
	height: (props) => props.drawerHeight || props?.sizes?.height || undefined,
	width: (props) => formatSize(props.drawerWidth),
	padding: (props) => props.padding || "24px",
};

const contentDrawerStyle = () => ({
	drawer: {
		"& .MuiDrawer-paper": {
			...baseStyle,
		},
	},
	drawerFloat: {
		"& .MuiDrawer-paper": {
			width: (props) => formatSize(props.drawerWidth),
			top: (props) => (props?.sizes?.top ? `${props.sizes.top}px` : "0"),
			maxHeight: (props) => props.drawerHeight || props?.sizes?.height || "100vh",
		},
	},
	drawerInContentLeft: {
		"& .MuiDrawer-paper": {
			...inContentStyle,
			left: (props) => (props?.sizes?.left ? `${props.sizes.left}px` : "0"),
		},
	},
	drawerInContentRight: {
		"& .MuiDrawer-paper": {
			...inContentStyle,
			right: (props) =>
				subtractSizes(
					props?.sizes?.right ? `${props.sizes.right}px` : "0",
					typeof props.float !== "undefined" && !props.float ? [formatSize(props.drawerWidth), "16px"] : undefined,
				),
		},
	},
	drawerFlexHeight: {
		"& .MuiDrawer-paper": {
			height: "auto !important",
			maxHeight: (props) => props.drawerHeight || props?.sizes?.height || "100vh",
		},
	},
});

export default contentDrawerStyle;
