import React, { useContext } from "react";

import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";
import clsx from "clsx";

import { updateSettings, validateBoxcastCredentials } from "redux/generalSettings/actions";
import { useSelector, useDispatch } from "react-redux";
import Accordion from "atlas/components/Accordion/Accordion";
import OutlinedInput from "atlas/components/FormControls/OutlinedInput";
import ButtonWithTooltip from "atlas/components/Buttons/ButtonWithTooltip";
import { UserContext } from "contexts/User/UserContext";

import accordionStyle from "assets/jss/components/accordionStyle";
import generalSettingsStyle from "../jss/generalSettingsStyle";
import makeStyles from "@mui/styles/makeStyles";
import notifierMessage from "utils/notifierMessage";
import { setSnackbarOptions } from "redux/snackBar/actions";
import { Checkbox } from "@mui/material";

const useStyles = makeStyles(generalSettingsStyle);

const BoxcastSettings = () => {
	const dispatch = useDispatch();
	const useAccordionStyles = makeStyles(accordionStyle);
	const { diligentAdmin, apiKey, apiSecret, isBoxCastEnabled } = useSelector((state) => state.generalSettingsReducer);
	const { userAdmin } = useContext(UserContext).user;
	const accordionClasses = useAccordionStyles();
	const { t } = useTranslation("generalSettings");
	const classes = useStyles();

	const handleUpdate = (e, name) => {
		const {
			target: { value },
		} = e;
		dispatch(updateSettings({ [name]: value }));
	};

	const handleToggle = (e, name) => {
		const {
			target: { checked },
		} = e;
		dispatch(updateSettings({ [name]: checked }));
	};

	const handleDisable = () => {
		return !(apiKey && apiSecret);
	};

	const validateBoxcast = () => {
		dispatch(validateBoxcastCredentials(apiKey, apiSecret)).then((res) => {
			if (res.Valid) {
				let option = notifierMessage(res.Message, "success");
				dispatch(setSnackbarOptions(option));
			} else {
				let option = notifierMessage(res.Message, "error");
				dispatch(setSnackbarOptions(option));
				dispatch(
					updateSettings({
						apiKey: "",
						apiSecret: "",
					}),
				);
			}
		});
	};

	return diligentAdmin || (userAdmin && isBoxCastEnabled) ? (
		<div>
			{isBoxCastEnabled ? (
				<div className={clsx(classes.section, classes.sectionAccordion)}>
					<Accordion
						id="boxcast-settings"
						classes={accordionClasses}
						className={classes.bold}
						label={t("subtitle.boxcastSettings")}
						scrollContentIntoView
						overflow={false}
						dataCy="boxcast-settings"
						handleToggle={handleToggle}
						enableCheckbox={isBoxCastEnabled}
						showCheckbox={diligentAdmin}
						checkboxName="isBoxCastEnabled"
					>
						<div className={classes.subAccordians}>
							<div className={classes.inputtextarea}>
								<OutlinedInput
									id="api-key"
									className={clsx(classes.inputfield, classes.outlinedInput)}
									label={t("headings.apiKey")}
									value={apiKey || ""}
									onChange={(e) => handleUpdate(e, "apiKey")}
									noDefaultClassName
									size="small"
									inputProps={{ "aria-label": t("headings.apiKey") }}
								/>
							</div>
							<div className={classes.inputtextarea}>
								<OutlinedInput
									id="api-secret"
									className={clsx(classes.inputfield, classes.outlinedInput)}
									label={t("headings.apiSectet")}
									value={apiSecret || ""}
									onChange={(e) => handleUpdate(e, "apiSecret")}
									noDefaultClassName
									size="small"
									inputProps={{ "aria-label": t("headings.apiSectet") }}
								/>
							</div>
							<Typography className={classes.boxCastText}>{t("boxcast")}</Typography>
							<ButtonWithTooltip
								disabled={handleDisable()}
								id="validate-btn"
								className={classes.validateButton}
								onClick={validateBoxcast}
								variant="outlined"
								color="primary"
								title={t("tooltips.validate")}
							>
								{t("buttons.validate")}
							</ButtonWithTooltip>
						</div>
					</Accordion>
				</div>
			) : (
				<div>
					{diligentAdmin ? (
						<div className={classes.boxCastCheckbox}>
							<div className={classes.boxCastTitle}>{t("subtitle.boxcastSettings")}</div>
							<Checkbox
								checked={isBoxCastEnabled}
								onChange={(e) => handleToggle(e, "isBoxCastEnabled")}
								color="primary"
								dataCy="boxcast-Settings"
								inputProps={{
									"aria-label": t("subtitle.boxcastSettings"),
									"aria-labelledby": "boxcastSettings",
								}}
							/>
						</div>
					) : null}
				</div>
			)}
		</div>
	) : null;
};
export default BoxcastSettings;
