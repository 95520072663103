const tableDataStyle = {
	cell: {
		fontSize: "16px",
		lineHeight: "20px",
		fontWeight: "normal",
		wordBreak: "break-word",
	},
	link: {
		color: "#385F99",
		textDecoration: "none",
		"&:hover,&:visited": {
			color: "#385F99",
		},
	},
};

export default tableDataStyle;
