import React from "react";

export const PolicyCompare = () => (
	<svg width={20} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M23.9841 11.2334C23.9777 11.1957 23.9758 11.1563 23.9615 11.1221L20.6816 3.26218H22.5558C22.8211 3.26218 23.0358 3.04315 23.0358 2.77303V0.489148C23.0358 0.218998 22.8211 0 22.5558 0H1.44468C1.17938 0 0.964657 0.218998 0.964657 0.489148V2.77303C0.964657 3.04315 1.17938 3.26218 1.44468 3.26218H3.31873L0.0384637 11.1221C0.0241631 11.1563 0.0222963 11.1957 0.0159209 11.2334C0.0114475 11.2603 7.04837e-05 11.2868 3.52232e-05 11.3136C3.52232e-05 13.586 1.81452 15.4353 4.04436 15.4353C6.27419 15.4353 8.08829 13.5862 8.08829 11.3138C8.08829 11.2895 8.07751 11.2652 8.07385 11.2408C8.06765 11.2003 8.06501 11.1585 8.04972 11.122L4.76988 3.26218H10.2652V20.6461H7.60736C6.25546 20.6461 5.1553 21.7672 5.1553 23.1454V23.5109C5.1553 23.7812 5.36998 24 5.63532 24H18.3427C18.608 24 18.8227 23.7812 18.8227 23.5109V23.1454C18.8227 21.7672 17.7225 20.6461 16.3702 20.6461H13.7353V3.26218H19.2305L15.9503 11.122C15.935 11.1585 15.9323 11.2003 15.9261 11.2408C15.9225 11.2652 15.9118 11.2892 15.9118 11.3135C15.9118 13.5859 17.7263 15.4353 19.9561 15.4353C22.1859 15.4353 24 13.5862 24 11.3138C24 11.287 23.9886 11.2603 23.9841 11.2334ZM4.04436 4.02291L6.88264 10.8246H1.20565L4.04436 4.02291ZM4.04436 14.457C2.50687 14.457 1.22858 13.3048 0.997485 11.8029H7.09077C6.85967 13.3048 5.58139 14.457 4.04436 14.457ZM1.92466 0.978295H22.0758V2.28385H13.2553H10.7451H1.92466V0.978295ZM17.858 23.0217H6.11999C6.18233 22.2407 6.82547 21.6244 7.60736 21.6244H10.7451H13.2553H16.3702C17.152 21.6244 17.7956 22.2407 17.858 23.0217ZM12.7753 20.6461H11.2252V3.26218H12.7753V20.6461ZM22.7944 10.8246H17.1174L19.9561 4.02291L22.7944 10.8246ZM19.9561 14.457C18.4186 14.457 17.1403 13.3048 16.9092 11.8029H23.0025C22.7714 13.3048 21.4931 14.457 19.9561 14.457Z"
			fill="white"
		/>
	</svg>
);
