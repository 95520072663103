import liveMeetingBottomNotice from "../../views/LiveMeeting/utils/liveMeetingBottomNotice";

import { updateBottomNotice } from "../../redux/app/actions";

const updateLiveMeetingBottomNotice = (props, liveMeeting) => {
	props.dispatch(
		updateBottomNotice(
			liveMeetingBottomNotice({
				dispatch: props.dispatch,
				t: props.t,
				navigate: props.navigate,
				location: window.location.pathname,
				liveMeeting,
			}),
		),
	);
};

const getMeetingControlPanelHandlers = (props) => ({
	setLiveMeetings: (liveMeetings) => {
		if (liveMeetings.length > 0) {
			updateLiveMeetingBottomNotice(props, liveMeetings[0]);
		}
	},
	updateActiveItem: (liveMeeting) => {
		if (liveMeeting) {
			updateLiveMeetingBottomNotice(props, liveMeeting);
		}
	},
	clearActiveItem: () => {
		props.dispatch(updateBottomNotice({}));
	},
});

export default getMeetingControlPanelHandlers;
