export default {
	goal: "Goal",
	goals: "Goals",
	editGoal: "Edit goal",
	editor: {
		delete: "Delete",
		menu: "Menu",
	},
	undo: "Undo",
	redo: "Redo",
	publish: "Publish",
	share: "Share",
	goalName: "Goal name (required)",
	progress: "Progress",
	description: "Description",
	outcomes: "Outcomes",
	actions: "Actions",
	noGoalName: "This goal has no name yet",
	unsavedChanges: "Changes you made may not be saved.",
	meetingsLinkedToThisGoal: "Meetings linked to this goal",
	publishedGoal: "Published goal",
	archivedGoal: "Archived goal",
	internalGoal: "Internal goal",
	unArchiveGoal: "Unarchive goal",
	InternalOnly: "Internal Only",
	unsavedUploading:
		"One or more attachments are currently being uploaded. Navigating away from this page will cause the attachment upload to fail. We recommend staying on this page until the upload is completed.",
	saveTooltip: "Saving your changes now",
	outOfSync: "OUT OF SYNC",
	noGoals: {
		title: "No Goals",
		description: "We couldn't find any goals that you have access to",
	},
	notifications: {
		noMatchesFound: "No matches found",
		noGoalsFound: "We couldn’t find any goals",
	},
	buttons: {
		addGoal: "+ goal",
		delete: "Delete",
		loadMore: "Load more",
		publish: "Publish",
		unpublish: "Unpublish",
		share: "Share",
		unshare: "Unshare",
		editGoal: "Edit goal",
		archive: "Archive",
		unArchive: "Unarchive",
	},
	tooltips: {
		addGoal: "Create a new goal",
		delete: "Delete goal",
		draftCantBePublished: "Draft goals can't be unpublished",
		publish: "Publish goal",
		publishedCantBeDeleted: "Published goals can't be deleted",
		unpublish: "Unpublish goal",
		internalOrPublicToggle: "Select the toggle for internal or public",
		goalAgendaDescription: "A goal cannot be changed to internal or public after linking to published agendas. The goal can be duplicated.",
	},
	deleteGoalDialog: {
		title: "Delete goal",
		line1: "You are about to delete {{name}}.",
		line2: "This will remove all information associated with this goal.",
		buttons: {
			delete: "Delete",
			cancel: "Cancel",
		},
		snackbar: {
			success: "Goal deleted successfully",
		},
	},
	options: {
		empty: "Empty",
	},
	publishGoalDialog: {
		snackbar: {
			success: "Goal published successfully",
		},
	},
	shareGoalDialog: {
		snackbar: {
			success: "Goal shared successfully",
		},
	},
	archivedGoalDialog: {
		snackbar: {
			success: "Goal archived successfully",
		},
	},
	unpublishGoalDialog: {
		snackbar: {
			success: "Goal unpublished successfully",
		},
	},
	unshareGoalDialog: {
		snackbar: {
			success: "Goal unshared successfully",
		},
	},
	unarchiveGoalDialog: {
		snackbar: {
			success: "Goal unarchived successfully",
		},
	},
	errorDialog: {
		titlePublish: "Goal not published",
		titleFailedUploads: "Goal has invalid attachments",
		title: "Goal name missing",
		description: "You haven’t yet named the goal you are trying to publish. Goals need a name to show up properly on the public site.",
		descriptionFailedFiles: "The following attachments were not uploaded successfully:",
		button: "Got it",
		buttonFromList: "Go to goal",
	},
	errors: {
		requiredName: "Name is required",
	},
	goalViewDialog: {
		labels: {
			primaryTitle: "Edit Goal",
		},
		title: "Goal",
		removedGoal: "Goal Removed",
	},
};
