import typographyStyle from './typographyStyle';
import menuStyle from './menuStyle';

const pageTitle = {
	display: 'inline',
};

const pageHeaderStyle = (theme) => ({
	root: {
		backgroundColor: '#2f3b4d',
		width: '100%',
		boxSizing: 'border-box',
		minHeight: '80px',
		paddingLeft: '24px',
		paddingRight: '24px',
		paddingTop: '24px',
		[theme.breakpoints.down('sm')]: {
			minHeight: '72px',
			paddingLeft: '16px',
			paddingRight: '16px',
			paddingTop: '16px',
		},
		paddingBottom: '16px',
		position: 'relative',
		flexShrink: '0',
	},
	container: {
		padding: '0',
	},
	grid: {
		alignItems: 'center',
	},
	pageTitleGrid: {
		marginTop: '-8px',
		[theme.breakpoints.down('sm')]: {
			marginTop: '0',
		},
	},
	buttonGrid: {
		alignSelf: 'flex-start',
	},
	pageTitle: {
		...typographyStyle.pageTitle,
		...pageTitle,
	},
	pageTitleSmall: {
		...typographyStyle.pageTitleSmall,
		...pageTitle,
	},
	pageTitleXSmall: {
		...typographyStyle.pageTitleXSmall,
		...pageTitle,
	},
	button: {
		...typographyStyle.button,
		margin: '0',
		height: '40px',
		boxShadow: 'none !important',
		whiteSpace: 'nowrap',
		[theme.breakpoints.down('sm')]: {
			padding: '2px',
			height: '50px',
			width: '30px',
			whiteSpace: 'normal',
		},
	},
	disableButon: {
		...typographyStyle.disableButon,
		margin: '0',
		height: '40px',
		boxShadow: 'none !important',
		whiteSpace: 'nowrap',
		[theme.breakpoints.down('sm')]: {
			padding: '4px',
			fontSize: '10px',
		},
	},
	menu: {
		...menuStyle.menu,
	},
	hidden: {
		display: 'hidden',
	},
	buttonWithIcon: {
		display: 'flex',
		gap: '8px',
		fontWeight: '600px',
		fontSize: '12px',
		alignItems: 'center',
		letterSpacing: '0.5px',
		[theme.breakpoints.down('md')]: {
			fontSize: '10px',
		},
	},
	UploadUsersListButton: {
		padding: '8px 12px',
		borderRadius: '2px',
		[theme.breakpoints.down('sm')]: {
			padding: '2px',
			height: '50px',
		},
	},
	UsersListDownloadButton: {
		padding: '8px 12px',
		borderRadius: '2px',
		[theme.breakpoints.down('sm')]: {
			padding: '2px',
			height: '50px',
		},
	},
	refreshButton: {
		borderRadius: 2,
		backgroundColor: 'transparent',
		border: '1px solid #FFFFFF',
		minWidth: 0,
		'&:hover': {
			backgroundColor: 'transparent',
		},
		[theme.breakpoints.down('sm')]: {
			padding: 8,
			height: 48,
		},
	},
	refreshButtonInProgress: {
		animation: 'spin 4s linear infinite',
	},
	ExportButton: {
		...typographyStyle.button,
		padding: ' 8px 12px',
		borderRadius: '2px',
		backgroundColor: 'transparent',
		color: '#FFFFFF',
		border: '1px solid #FFFFFF',
		'&:hover': {
			backgroundColor: 'transparent',
		},
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		gap: '8px',
		[theme.breakpoints.down('sm')]: {
			padding: '2px',
			height: '40px',
		},
	},
	ExportButtonWithIcon: {
		display: 'flex',
		gap: '8px',
		alignItems: 'center',
		[theme.breakpoints.down('sm')]: {
			fontSize: '10px',
		},
	},
});

export default pageHeaderStyle;
