import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import request from "superagent";
import { v4 as uuid } from "uuid";

import makeStyles from "@mui/styles/makeStyles";
import { IconButton, InputAdornment } from "@mui/material";

import Icon from "atlas/components/Icon/Icon";
import OutlinedInput from "atlas/components/FormControls/OutlinedInput";
import GenericDialog from "atlas/components/Dialogs/GenericDialog";
import ProgressBar from "atlas/components/Progress/ProgressBar";
import { API_HOST } from "config/env";
import { Visibility, VisibilityOff } from "components/Icons";
import { updateHandlers, PROGRESS_HUB } from "utils/communication/SignalrClient";
import restorePurgeStyle from "views/Documents/assets/restorePurge";
import notifierMessage from "utils/notifierMessage";
import { setSnackbarOptions } from "redux/snackBar/actions";
import { policiesPermanantlyDelete } from "redux/policyGovernance/actions";
import { displayErrorNotification } from "views/Policy/utils/getNotification";
import { updateLeftNav } from "redux/app/actions";
import { displaySuccessNotification } from "../../Policy/utils/getNotification";

const useStyles = makeStyles(restorePurgeStyle);

const PolicyPermanentlyDeleteDialog = (props) => {
	const { show = true, policies = [], onClose, afterPermanentlyDelete, showSignIn, isPolicy, afterPolicyBookDelete } = props;
	const { t } = useTranslation("documents");
	const [password, setPassword] = useState("");
	const [showPassword, setShowPassword] = useState(false);
	const [errors, setErrors] = useState({});
	const [deleting, setDeleting] = useState(false);
	const [progress, setProgress] = useState({
		label: " ",
		percent: 0,
	});
	const appReducer = useSelector((state) => state.appReducer);
	const {
		signalR: { client, handler },
	} = appReducer;
	const dispatch = useDispatch();
	const guid = useRef();
	const classes = useStyles();
	const progressId = "deleting-progress";

	const updateProgress = (percentage, message) => {
		setProgress({
			label: message,
			percent: percentage,
		});

		if (percentage === 100) {
			setTimeout(onClose, 3000);
			displaySuccessNotification(t(`permanentlyDeletePolicyDialog.snackbar.success`), dispatch);
			afterPermanentlyDelete(policies);
		}
	};

	const handlePermanentlyDeleteForPolicy = () => {
		setDeleting(true);
		const guids = policies.map((policy) => policy.guid);
		dispatch(policiesPermanantlyDelete(guids, password, guid.current))
			.then(() => {
				// handleCancel()
				// let option = notifierMessage("Policy is Permanently Deleted", "success");
				// dispatch(setSnackbarOptions(option));
			})
			.catch((err) => {
				if (err.status === 500) {
					const { response: { body: { Message: message } = {} } = {} } = err;
					if (message === t(`policy.apiResponse.invalidPassword`)) {
						setDeleting(false);
						setErrors((prev) => ({ ...prev, password: true }));
					}
				} else if (err.status === 400) {
					displayErrorNotification(t(`policy.errMsg.permanantlyDeleteErr`), dispatch);
					onClose();
				} else {
					displayErrorNotification(err?.response?.body || t(`policy.errMsg.commonMsg`), dispatch);
					onClose();
				}
			});
	};

	const handlePermanentlyDeleteForPolicyBook = () => {
		setDeleting(true);
		const policyBookGuid = [policies.guid];
		dispatch(policiesPermanantlyDelete(policyBookGuid, password, guid.current, { isPolicySetting: true }))
			.then((res) => {
				handleCancel();
				displaySuccessNotification(t(`policy.successMsg.permanantlyDeleted`), dispatch);
				setTimeout(() => {
					dispatch(
						updateLeftNav({
							reloadLeftNav: { policies: Date.now() },
						}),
					);
				}, 5000);
				afterPolicyBookDelete(policies.guid);
			})
			.catch((err) => {
				if (err.status === 500) {
					const { response: { body: { Message: message } = {} } = {} } = err;
					if (message === t(`policy.apiResponse.invalidPassword`)) {
						setDeleting(false);
						setErrors((prev) => ({ ...prev, password: true }));
					}
				} else {
					displayErrorNotification(err?.response?.body || t(`policy.errMsg.commonMsg`), dispatch);
					onClose();
				}
			});
	};

	const handleCancel = () => {
		onClose();
	};

	const handleClickShowPassword = () => {
		setShowPassword((prev) => !prev);
	};

	useEffect(() => {
		guid.current = uuid();

		updateHandlers(dispatch, handler, PROGRESS_HUB, { announceProgress: updateProgress });

		client.ensureStarted().then(() => client.progressHub.registerProgressGuid(guid.current));

		return () => {
			client.ensureStarted().then(() => client.progressHub.clearProgressGuid(guid.current));

			updateHandlers(dispatch, handler, PROGRESS_HUB, { announceProgress: null });
		};
	}, []);
	let dialog = isPolicy
		? {
				title: t("permanentlyDeleteDocumentDialog.titleForPolicy"),
				primaryTitle: t("app:buttons.permanentlyDelete"),
				primaryAction: handlePermanentlyDeleteForPolicyBook,
				secondaryTitle: t("app:buttons.cancel"),
				secondaryAction: handleCancel,
		  }
		: {
				title: t("permanentlyDeleteDocumentDialog.title"),
				primaryTitle: t("app:buttons.permanentlyDelete"),
				primaryAction: handlePermanentlyDeleteForPolicy,
				secondaryTitle: t("app:buttons.cancel"),
				secondaryAction: handleCancel,
		  };

	let numberFolders = 0;
	let numberDocuments = 0;
	let numberLabels = [];
	if (!isPolicy) {
		numberFolders = policies.filter((policy) => policy.folder).length;
		numberDocuments = policies.length - numberFolders;
	}

	if (numberFolders > 0) {
		numberLabels.push(t(`permanentlyDeleteDocumentDialog.numberFolder${numberFolders > 1 ? "s" : ""}`, { number: numberFolders }));
	}
	if (numberDocuments > 0) {
		numberLabels.push(t(`permanentlyDeleteDocumentDialog.numberDocument${numberDocuments > 1 ? "s" : ""}`, { number: numberDocuments }));
	}

	return (
		<GenericDialog
			className={classes.dialog}
			show={show}
			title={dialog.title}
			primaryAction={dialog.primaryAction}
			primaryTitle={dialog.primaryTitle}
			primaryDisabled={policies.length === 0 || deleting || !password}
			secondaryAction={dialog.secondaryAction}
			secondaryTitle={dialog.secondaryTitle}
			secondaryDisabled={deleting}
			clickAwayDisabled={deleting}
			closeIcon={<Icon name="close" />}
			data-cy="permanently-delete-dialog"
		>
			{!isPolicy && <div>{numberLabels.join(", ")}</div>}
			<ol className={classes.list}>
				{isPolicy ? (
					<li className={classes.listItem}>
						<div className={classes.typeIcon} title={!policies.folder ? policies.extension : undefined}>
							{policies.folder ? <Icon name="folder" size="24" /> : <Icon name="document" size="24" />}
						</div>
						<div className={classes.title}>{policies.title}</div>
					</li>
				) : (
					policies.map(({ guid, folder, title, extension }) => (
						<li key={guid} className={classes.listItem}>
							<div className={classes.typeIcon} title={!folder ? extension : undefined}>
								{folder ? <Icon name="folder" size="24" /> : <Icon name="document" size="24" />}
							</div>
							<div className={classes.title}>{title}</div>
						</li>
					))
				)}
			</ol>
			<OutlinedInput
				id="password"
				label={t("permanentlyDeleteDocumentDialog.labels.password")}
				type={showPassword ? "text" : "password"}
				value={password}
				onChange={(e) => setPassword(e.target.value)}
				noDefaultClassName
				fullWidth
				size="small"
				InputProps={{
					endAdornment: (
						<InputAdornment tabIndex="-1" position="end">
							<IconButton tabIndex="-1" onClick={handleClickShowPassword} aria-label={t("app:buttons.showPassword")}>
								{showPassword ? <VisibilityOff /> : <Visibility />}
							</IconButton>
						</InputAdornment>
					),
				}}
				helperText={errors.password ? t("permanentlyDeleteDocumentDialog.errors.password") : undefined}
				error={errors.password}
				data-cy="password"
			/>
			<div>{t("permanentlyDeleteDocumentDialog.timeWarning")}</div>
			{deleting && <ProgressBar id={progressId} className={classes.progressBar} label={progress.label} progress={progress.percent} />}
		</GenericDialog>
	);
};

export default PolicyPermanentlyDeleteDialog;
