import React, { useState, useEffect, useCallback, useRef } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import request from "superagent";
import clsx from "clsx";

import { Tabs, Tab } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import searchAndTreeDialogStyle from "assets/jss/components/searchAndTreeDialogStyle";
import Icon from "atlas/components/Icon/Icon";
import CircularProgressIndicator from "atlas/components/Progress/CircularProgressIndicator";
import GenericDialog from "atlas/components/Dialogs/GenericDialog";
import SearchInput from "components/Search/SearchInput";
import { API_HOST } from "config/env";
import { getPolicies, generateAttachments } from "redux/policyGovernance/actions";
import { FOLDER_TYPE_DRAFT_POLICY, FOLDER_TYPE_PUBLISHED_POLICY } from "utils/enums/DocumentFolderTypes";
import { sortTitle } from "views/Policy/PolicySettingsModule";
import SearchResults from "views/Search/components/SearchResults";
import PolicyTree from "./PolicyTree";
import { GET_ATTACHMENTS_MINUTES_FULFILLED } from "../../redux/policyGovernance/types";
import telemetryAddEvent from "utils/telemetryAddEvent";

const useStyles = makeStyles(searchAndTreeDialogStyle);

const AddPolicyToAgendaDialog = (props) => {
	const {
		show = true,
		meetingId,
		options: { itemGuid, callback },
		onClose,
		showSignIn,
	} = props;
	const { t } = useTranslation("meetings");
	const [selectedTab, setSelectedTab] = useState(0);
	const [searchKeywords, setSearchKeywords] = useState("");
	const [policies, setPolicies] = useState(null);
	const [policyEditor, setPolicyEditor] = useState(false);
	const [searching, setSearching] = useState(false);
	const [searchResults, setSearchResults] = useState(null);
	const [total, setTotal] = useState(0);
	const [page, setPage] = useState(0);
	const [adding, setAdding] = useState(false);
	const i18n = t("addPolicyToAgendaDialog");
	const [buttonTitle, setButtonTitle] = useState(i18n.buttons.addCopyOfDraft);
	const [selectedPolicies, setSelectedPolicies] = useState({});
	const lastSearch = useRef("");
	const dispatch = useDispatch();
	const classes = useStyles();
	const size = Object.keys(selectedPolicies).length;

	useEffect(() => {
		switch (selectedTab) {
			case 0:
				if (size === 1 || size === 0) {
					setButtonTitle(i18n.buttons.addCopyOfDraft);
				} else if (size > 1) {
					setButtonTitle(i18n.buttons.addCopiesofDraft);
				}
				break;
			case 1:
				if (size === 1 || size === 0) {
					setButtonTitle(i18n.buttons.addCopy);
				} else if (size > 1) {
					setButtonTitle(i18n.buttons.addCopies);
				}
				break;
			default:
				setButtonTitle(i18n.buttons.addCopyOfDraft);
		}
	}, [selectedTab, selectedPolicies]);

	useEffect(() => {
		setSelectedPolicies({});
	}, [selectedTab]);

	const setSelectedData = (policy) => {
		const guid = policy.guid;
		if (policy.selected) {
			setSelectedPolicies((prev) => ({ ...prev, [guid]: true }));
		} else {
			let temp = { ...selectedPolicies };
			temp[guid] && delete temp[guid];
			setSelectedPolicies(temp);
		}
	};

	const loadPolicies = (parent) => {
		return new Promise((resolve, reject) => {
			dispatch(getPolicies({ parentId: parent ? parent.guid : undefined }))
				.then((res) => {
					let policies = res || [];
					policies = policies.sort(sortTitle);

					if (parent) {
						parent.children = policies;
					} else {
						setPolicies(policies);

						const canEditDrafts = Boolean(policies.find((policy) => policy.canUpdate));
						setPolicyEditor(canEditDrafts);
						if (!canEditDrafts) {
							setSelectedTab(0); // Reset the tab selection
						}
					}

					resolve(policies);
				})
				.catch((err) => {
					showSignIn(
						err,
						() => {
							loadPolicies(parent);
						},
						reject,
					);
				});
		});
	};

	const onTabChange = (_e, newTab) => {
		setSelectedTab(newTab);
	};

	const handleSearchChange = (e) => {
		const { value } = e.target;
		setSearchKeywords(value);
		if (!value) {
			setSearching(false);
		}
	};

	const handleSearchKeyDown = (e) => {
		if (e.key === "Enter") {
			const { value } = e.target;
			handleSearch(value);
		}
	};

	const onClearSearchClick = () => {
		setSearchKeywords("");
		setSearching(false);
	};

	const handleSearch = useCallback(
		(value, pageLoadResolver) => {
			setSearching(true);
			lastSearch.current = value;

			if (value) {
				if (!pageLoadResolver) {
					setSearchResults(null); // New search/filter, so show the loading indicator
				}

				request
					.get(`${API_HOST}/api/items/search`)
					.withCredentials()
					.query({
						keyWords: value,
						includeTotals: true,
						searchTypes: 10,
						folderTypes: !policyEditor || selectedTab === 1 ? FOLDER_TYPE_PUBLISHED_POLICY : FOLDER_TYPE_DRAFT_POLICY,
						page: pageLoadResolver ? page + 1 : undefined,
					})
					.then((res) => {
						const { results, policies } = res.body;

						if (!pageLoadResolver) {
							setSearchResults(results || []);
							setTotal(policies);
							setPage(1);
						} else {
							setSearchResults((prev) => (prev || []).concat(results || []));
							pageLoadResolver();
							setPage((prev) => prev + 1);
						}
					})
					.catch((err) => {
						showSignIn(
							err,
							() => {
								handleSearch(value);
							},
							() => {
								setSearchResults([]);
								setTotal(0);
								setPage(0);
							},
						);
					});
			} else {
				setSearchResults([]);
			}
		},
		[policyEditor, selectedTab],
	);

	const loadMore = () =>
		new Promise((resolve) => {
			handleSearch(lastSearch.current, resolve);
		});

	const getSelectedPolicies = (policies, selectedPolicies) => {
		policies.forEach((policy) => {
			if (policy.selected) {
				selectedPolicies.push(policy.guid);
			}

			getSelectedPolicies(policy.children || [], selectedPolicies);
		});
	};

	const handleAddItem = useCallback(() => {
		setAdding(true);

		const selectedPolicies = [];
		if (!searching) {
			telemetryAddEvent(`Policy - Add to Agenda item - Add policy without search`);
			getSelectedPolicies(policies, selectedPolicies);
		} else {
			telemetryAddEvent(`Policy - Add to Agenda Item - Add policy using Search`);
			searchResults.forEach((result) => {
				if (result.selected) {
					selectedPolicies.push(result.guid);
				}
			});
		}

		// Generate attachable versions of the selected policies
		dispatch(
			generateAttachments({
				meetingId,
				attachedToItemId: itemGuid,
				usePublished: !policyEditor || selectedTab === 1,
				policyIds: selectedPolicies,
			}),
		)
			.then((attachments) => {
				attachments.forEach((attachment) => {
					attachment.itemGuid = itemGuid;
				});
				dispatch({
					type: GET_ATTACHMENTS_MINUTES_FULFILLED,
					payload: attachments,
				});
				// Create attachment versions of the policies, then add them to the ckeditor as links
				if (typeof callback === "function") {
					callback(attachments);
				}

				setAdding(false);

				onClose();
			})
			.catch((err) => {
				console.log(err);

				onClose();
			});
	}, [searching, policies, searchResults, policyEditor, selectedTab]);

	const handleCancel = () => {
		onClose();
	};

	useEffect(() => {
		if (searching) {
			handleSearch(lastSearch.current);
		}
	}, [searching, policyEditor, selectedTab]);

	useEffect(() => {
		loadPolicies();
	}, []);

	const dialog = {
		title: i18n.title,
		primaryTitle: buttonTitle,
		primaryAction: handleAddItem,
		secondaryTitle: t("app:buttons.cancel"),
		secondaryAction: handleCancel,
	};

	return (
		<GenericDialog
			className={classes.dialog}
			show={show}
			title={dialog.title}
			primaryAction={dialog.primaryAction}
			primaryTitle={buttonTitle}
			primaryDisabled={adding || size === 0}
			secondaryAction={dialog.secondaryAction}
			secondaryTitle={dialog.secondaryTitle}
			secondaryDisabled={adding}
			clickAwayDisabled={adding}
			closeIcon={<Icon name="close" />}
			data-cy="add-policy-to-agenda-dialog"
		>
			<div className={classes.content}>
				{policies ? (
					<div>
						<Tabs className={classes.tabs} aria-label={i18n.tabs.ariaLabel} value={selectedTab} onChange={onTabChange}>
							{policyEditor && <Tab label={i18n.tabs.draft} data-cy="tab-draft" />}
							<Tab label={i18n.tabs.published} data-cy="tab-published" />
						</Tabs>
					</div>
				) : null}
				<div
					className={clsx({
						[classes.searchContainerLoading]: !policies,
					})}
				>
					<SearchInput
						size="extra-small"
						fullWidth
						value={searchKeywords || ""}
						showClearIcon={searchKeywords && searchKeywords.length > 0}
						onKeyDown={handleSearchKeyDown}
						onChange={handleSearchChange}
						onIconClick={() => {
							handleSearch(searchKeywords);
						}}
						onClearClick={onClearSearchClick}
						data-cy="policy-search"
					/>
				</div>
				{!searching ? (
					policies ? (
						<div className={classes.dataContainer} data-cy="policy-tree">
							<PolicyTree
								policies={policies}
								handleLoadChildren={loadPolicies}
								hideDraft={!policyEditor || selectedTab === 1}
								setSelectedData={setSelectedData}
							/>
						</div>
					) : (
						<CircularProgressIndicator />
					)
				) : null}
				{searching ? (
					searchResults ? (
						<div className={classes.dataContainer} data-search data-cy="policy-search-results">
							<SearchResults
								results={searchResults}
								total={total}
								loadMore={loadMore}
								isPolicy
								compact={{ height: "250px" }}
								enableSelect={{
									allow: (result) => !result.folder,
									handle: (result, selected) => {
										result.selected = selected;
									},
								}}
								setSelectedData={setSelectedData}
								policyAddToAgendaSearch
							/>
						</div>
					) : (
						<CircularProgressIndicator />
					)
				) : null}
			</div>
		</GenericDialog>
	);
};

export default AddPolicyToAgendaDialog;
