import React from "react";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { Grid, Box, MenuItem } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { updateSettings } from "redux/generalSettings/actions";

import Accordion from "atlas/components/Accordion/Accordion";
import SelectInput from "atlas/components/FormControls/SelectInput";
import processHtml, { stripHtml } from "utils/processHtml";

import accordionStyle from "assets/jss/components/accordionStyle";
import generalSettingsStyle from "../jss/generalSettingsStyle";
import inputStyle from "atlas/assets/jss/components/inputStyle";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles(generalSettingsStyle);
const useAccordionStyles = makeStyles(accordionStyle);
const useInputStyles = makeStyles(inputStyle);

const PolicyUrlTimeSettings = (props) => {
	const { t } = useTranslation("generalSettings");
	const accordionClasses = useAccordionStyles();
	const classes = useStyles();
	const dispatch = useDispatch();
	const { timeZone, timeZoneDropdownValues, hour, period } = useSelector((state) => state.generalSettingsReducer);
	const inputClasses = useInputStyles({ fullWidth: true });
	const periods = [
		{ label: t("AM"), value: "AM" },
		{ label: t("PM"), value: "PM" },
	];
	const hours = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

	const getTimeZones = () => {
		const timeZoneValues = [];

		if (timeZoneDropdownValues && timeZoneDropdownValues.length > 0) {
			timeZoneDropdownValues.forEach((item) => {
				timeZoneValues.push(
					<MenuItem key={`time-zone-${item.item}`} className={classes.menuItem} source="timeZone" value={item.value} data-cy={"time-zone"}>
						<div className={classes.menuItemText}>{processHtml(stripHtml(item.item))}</div>
					</MenuItem>,
				);
			});
		}
		return timeZoneValues;
	};

	const handleUpdate = (e, name) => {
		const {
			target: { value },
		} = e;
		dispatch(updateSettings({ [name]: value }));
	};

	return (
		<>
			<div className={clsx(classes.section, classes.sectionAccordion)}>
				<Accordion
					classes={accordionClasses}
					className={classes.bold}
					label={t("subtitle.setTimeZone")}
					scrollContentIntoView
					overflow={false}
					dataCy="time-zone"
				>
					<div className={classes.subAccordians}>
						<div className={classes.inputtextarea}>
							<SelectInput
								id="time-zone"
								className={inputClasses.smallInput}
								noDefaultClassName
								fullWidth
								label={t("headings.siteTimeZone")}
								value={timeZone || ""}
								onChange={(e) => handleUpdate(e, "timeZone")}
								data-cy="time-zone"
							>
								{getTimeZones()}
							</SelectInput>
						</div>
						<Grid container rowSpacing={1} xs={8} md={8} columnSpacing={{ xs: 1, sm: 4, md: 3 }}>
							<Grid item className="absolute-container">
								<SelectInput
									id="hour"
									name="hour"
									className={clsx(classes.fieldInput, classes.timeInput)}
									label={t("headings.setNotificationHour")}
									value={hour || ""}
									onChange={(e) => handleUpdate(e, "hour")}
									noDefaultClassName
									fullWidth
									size="small"
									data-cy="startTimeHour"
								>
									{hours.map((data) => (
										<MenuItem key={`hour-${data}`} value={data} data-cy={`startTimeHour${data}`}>
											{data}
										</MenuItem>
									))}
								</SelectInput>
							</Grid>

							<Grid item>
								<SelectInput
									id="period"
									name="period"
									className={clsx(classes.fieldInput, classes.timeInput)}
									label={t("headings.setNotificationPeriod")}
									value={period || ""}
									onChange={(e) => handleUpdate(e, "period")}
									noDefaultClassName
									fullWidth
									size="small"
									data-cy="startTimePeriod"
								>
									{periods.map((period) => (
										<MenuItem key={`period-${period.value}`} value={period.value} data-cy={`startTimePeriod${period.value}`}>
											{period.label}
										</MenuItem>
									))}
								</SelectInput>
							</Grid>
						</Grid>
					</div>
				</Accordion>
			</div>
		</>
	);
};
export default PolicyUrlTimeSettings;
