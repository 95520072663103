/* eslint-disable no-plusplus */
import React, { useState, useEffect, useRef } from "react";
import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useMatch } from "react-router-dom";
import request from "superagent";
import { debounce } from "lodash";
import isEqual from "lodash/fp/isEqual";

import makeStyles from "@mui/styles/makeStyles";

import { formatDate } from "utils/date";
import scrollToElement from "utils/scrollToElement";
import { API_HOST } from "config/env";
import { updateSignalR } from "redux/app/actions";
import AgendaItemTypesEnum from "utils/enums/AgendaItemTypes";
import MinutesItemTypesEnum from "utils/enums/MinutesItemTypes";

import { updateHandlers, LIVE_MEETING_HUB, VOTING_HUB } from "utils/communication/SignalrClient";
import ComponentContainer from "atlas/components/ComponentContainer/ComponentContainer";
import CircularProgressIndicator from "atlas/components/Progress/CircularProgressIndicator";
import { findItemByID } from "../MeetingEditor/functions/utils";
import LiveMeetingHeading from "./components/LiveMeetingHeading";
import LiveMeetingAgendaItem from "./components/LiveMeetingAgendaItem";
import LiveMeetingRecommendation from "./components/LiveMeetingRecommendation";
import LiveMeetingRequestToSpeak from "./components/LiveMeetingRequestToSpeak";
import ViewerTimer from "./components/ViewerTimer";
import VotingResultsPresentationDialog from "components/Dialogs/VotingResultsPresentationDialog";

import checkVotingFinished from "views/LiveMeeting/utils/votingUtils";
import notifierMessage from "utils/notifierMessage";
import { setSnackbarOptions } from "redux/snackBar/actions";

const useStyles = makeStyles(() => ({
	agenda: {
		backgroundColor: "#fff",
		margin: "0",
		padding: "0",
		height: "95vh",
		overflow: "auto",
		"& div, span": {
			fontSize: "40px !important",
			lineHeight: "48px !important",
		},
		"& a.inlineFile": {
			color: "#000",
			cursor: "default",
			pointerEvents: "none",
		},
		"& a.inlineFile:after, & a.inlineLink:after": {
			width: "35px",
			height: "35px",
		},
		"& li:last-child": {
			marginBottom: "250px",
		},
	},
	agendaHeaderInitial: {
		display: "flex",
		alignItems: "center",
		flexDirection: "column",
		fontSize: "40px !important",
		lineHeight: "48px !important",
		"& img": {
			marginBottom: "96px",
		},
	},
	agendaHeader: {
		display: "flex",
		flexDirection: "row",
		"& img": {
			margin: "24px",
		},
	},
	meetingInfoContainer: {
		display: "flex",
		flexDirection: "column",
		flexGrow: "1",
		alignItems: "center",
		margin: "auto",
	},
	meetingName: {
		fontWeight: "bold",
	},
	attachmentFrame: {
		width: "100%",
		height: "96vh",
		border: "none",
	},
}));

const Presentation = (props) => {
	const { t } = props;
	const { params: { id } = {} } = useMatch({ path: "/meeting/presentation/:id", end: true }) || {};
	const [meetingData, setMeetingData] = useState(null);
	const [selected, setSelected] = useState("");
	const [timer, setTimer] = useState(null);
	const [votingDataResponse, setVotingDataResponse] = useState(null);
	const [votingData, setVotingData] = useState(null);
	const [showVotingResultsDialog, setShowVotingResultsDialog] = useState(false);
	const [votingSettings, setVotingSettings] = useState({});
	const [votingFinishedData, setVotingFinishedData] = useState(null);
	const dispatch = useDispatch();
	const classes = useStyles();
	const appReducer = useSelector((state) => state.appReducer);
	const {
		signalR: { client, handler },
	} = appReducer;
	const live = useRef();

	const addRequestsToSpeak = (filteredItems, headingData, defaultPublicCommentHeading) => {
		const { requestsToSpeak } = meetingData;
		const { item, numberOfChildren } = headingData;

		if (item) {
			requestsToSpeak
				.filter(
					(requestToSpeak) =>
						requestToSpeak.headingGuid === item.guid ||
						(requestToSpeak.headingGuid.length === 0 && defaultPublicCommentHeading && defaultPublicCommentHeading.guid === item.guid),
				)
				.forEach((requestToSpeak, index) => {
					const requestToSpeakFilteredItem = filteredItems.filter((filteredItem) => filteredItem.guid === requestToSpeak.guid);
					if (requestToSpeakFilteredItem == null || requestToSpeakFilteredItem.length === 0) {
						filteredItems.push({
							...requestToSpeak,
							order: numberOfChildren + index + 1,
							fields: {
								Consent: {
									Value: item.fields.Consent.Value,
								},
								PublicComment: {
									Value: item.fields.PublicComment.Value,
								},
								Closed: {
									Value: item.fields.Closed.Value,
								},
							},
						});
					}
				});
		}
	};

	const getItemByGuid = (guid, agenda) => {
		const { minutesItems = [], items = [] } = meetingData;
		const searchItems = agenda ? items : minutesItems;

		return searchItems.find((item) => item.guid === guid);
	};

	const getMinutesItem = (agendaItem) => {
		const { minutesItems = [] } = meetingData;

		return minutesItems.find((item) => item.agendaItemGuid === agendaItem.guid);
	};

	const getItems = () => {
		const {
			items,
			meeting: { closed: isClosedMeeting },
		} = meetingData;
		let isConsentHeading = false;
		let isPublicCommentHeading = false;
		let isMemberOnlyHeading = false;
		const defaultPublicCommentHeading = items.find(
			(item) => !item.deleted && item.itemType === AgendaItemTypesEnum().HEADING.value && item.fields.PublicComment.Value,
		);
		const filteredItems = [];
		const previous = {
			heading: {
				item: null,
				numberOfChildren: 0,
			},
		};

		items.forEach((item) => {
			if (!item.deleted) {
				filteredItems.push(item);
				if (item.itemType === AgendaItemTypesEnum().HEADING.value) {
					// Top level heading
					previous.heading = {
						item,
						numberOfChildren: !item.attributes.relationshipGuid ? 0 : previous.heading.numberOfChildren,
					};
					addRequestsToSpeak(filteredItems, previous.heading, defaultPublicCommentHeading);
				} else if (previous.heading.item && item.itemType === AgendaItemTypesEnum().ITEM.value) {
					// Count the children of the heading
					previous.heading.numberOfChildren++;
				}
			}
		});
		addRequestsToSpeak(filteredItems, previous.heading, defaultPublicCommentHeading); // Add requests to the last heading if needed
		const lastIndex = filteredItems.length - 1;

		// Find consent items that may have been moved in minutes and move them on presentation view
		const consentItems = filteredItems.filter((item) => item.itemType === AgendaItemTypesEnum().ITEM.value && item.fields.Consent.Value);
		consentItems.forEach((item) => {
			if (item.itemType === AgendaItemTypesEnum().ITEM.value) {
				const agendaItemHeading = getItemByGuid(item.attributes.relationshipGuid, true);
				const minutesItem = getMinutesItem(item);
				const minutesItemHeading = minutesItem ? getItemByGuid(minutesItem.attributes.relationshipGuid, false) : null;
				if (agendaItemHeading && minutesItemHeading && agendaItemHeading.guid !== minutesItemHeading.agendaItemGuid) {
					let nonConsentAgendaHeading =
						getItemByGuid(minutesItemHeading.agendaItemGuid) ||
						filteredItems.find((item) => item.guid === minutesItemHeading.agendaItemGuid);
					// Find the non consent heading if it is not on agenda but has been added to the list
					nonConsentAgendaHeading = nonConsentAgendaHeading
						? nonConsentAgendaHeading
						: filteredItems.find((nonConsentItem) => nonConsentItem.guid === minutesItemHeading.guid);
					// Add the minutes heading if the agenda does not exist
					if (!nonConsentAgendaHeading) {
						// Add minutes heading to the item list at the next index from the current heading
						const nextHeading = filteredItems.find(
							(filteredItem) =>
								filteredItem.itemType === AgendaItemTypesEnum().HEADING.value &&
								filteredItem.fields.Order.Value === agendaItemHeading.fields.Order.Value + 1,
						);
						const nextHeadingIndex = filteredItems.findIndex((indexItem) => indexItem.guid === nextHeading.guid);
						const itemIndex = filteredItems.findIndex((indexItem) => indexItem.guid === item.guid);
						filteredItems.splice(nextHeadingIndex, 0, minutesItemHeading);
						filteredItems.splice(nextHeadingIndex, 0, filteredItems.splice(itemIndex, 1)[0]);
					} else {
						const nonConsentAgendaHeadingIndex = filteredItems.findIndex((indexItem) => indexItem.guid === nonConsentAgendaHeading.guid);
						const itemIndex = filteredItems.findIndex((indexItem) => indexItem.guid === item.guid);
						filteredItems.splice(nonConsentAgendaHeadingIndex + 1, 0, filteredItems.splice(itemIndex, 1)[0]);
					}
				}
			}
		});

		return filteredItems.map((item, index) => {
			isConsentHeading = item && item.fields && item.fields.Consent && item.fields.Consent.Value;
			isPublicCommentHeading = item && item.fields && item.fields.PublicComment && item.fields.PublicComment.Value;
			isMemberOnlyHeading = item.fields.Closed.Value;

			if (typeof item.topic === "string") {
				// Request to speak
				return (
					<LiveMeetingRequestToSpeak
						key={item.guid}
						requestToSpeak={item}
						isMemberOnlyHeading={isMemberOnlyHeading}
						isConsentHeading={isConsentHeading}
						isPublicCommentHeading={isPublicCommentHeading}
						addBottomBorder={index === lastIndex}
						selected={selected}
						presentation
					/>
				);
			}

			if (item.itemType === AgendaItemTypesEnum().HEADING.value || item.itemType === MinutesItemTypesEnum().HEADING.value) {
				return (
					<LiveMeetingHeading
						key={item.guid}
						heading={item}
						isClosedMeeting={isClosedMeeting}
						isMemberOnlyHeading={isMemberOnlyHeading}
						isConsentHeading={isConsentHeading}
						isPublicCommentHeading={isPublicCommentHeading}
						isSubHeading={Boolean(item.attributes.relationshipGuid)}
						addBottomBorder={index === lastIndex}
						selected={selected}
						presentation
					/>
				);
			}

			if (item.itemType === AgendaItemTypesEnum().ITEM.value && !isMemberOnlyHeading) {
				return (
					<LiveMeetingAgendaItem
						key={item.guid}
						item={item}
						isClosedMeeting={isClosedMeeting}
						isMemberOnlyHeading={isMemberOnlyHeading}
						isConsentHeading={isConsentHeading}
						isPublicCommentHeading={isPublicCommentHeading}
						addBottomBorder={index === lastIndex}
						selected={selected}
						presentation
					/>
				);
			}

			if (item.itemType === AgendaItemTypesEnum().RECOMMENDATION.value && !isMemberOnlyHeading) {
				const parent = findItemByID(item.attributes.relationshipGuid, items);

				return (
					<LiveMeetingRecommendation
						key={item.guid}
						recommendation={item}
						isClosedMeeting={isClosedMeeting}
						isMemberOnlyHeading={isMemberOnlyHeading}
						isConsentHeading={isConsentHeading}
						isPublicCommentHeading={isPublicCommentHeading}
						isHeadingAction={parent && parent.itemType === AgendaItemTypesEnum().HEADING.value}
						addBottomBorder={index === lastIndex}
						selected={selected}
						presentation
					/>
				);
			}

			return null;
		});
	};

	const setSelectedItem = (guid) => {
		setSelected(guid);
		if (guid) {
			scrollToElement(document.getElementById(`agenda-${guid}`), false, document.getElementById("agenda-items"));
		}
	};

	const loadMeeting = async () => {
		try {
			const response = await request.get(`${API_HOST}/api/meeting/${id}/getagendaitems`);
			const { items, meeting, requestsToSpeak } = response.body;

			const minutesResponse = await request.get(`${API_HOST}/api/meeting/${id}/getminutesitems`);
			const minutesItems = minutesResponse.body.items;

			setMeetingData({ meeting, items, minutesItems, requestsToSpeak });

			if (meeting.votingInRange) {
				client.updateStatusIcon();
			}

			const done = () => {
				client.liveMeetingHub.addUserToMcpViewers(id, true);
				client.votingHub.addUserToPresentationViewers(id);
				if (meeting.activeGuid && meeting.activeGuid.length > 0) {
					setSelectedItem(meeting.activeGuid);
				}
			};

			handler.meetingControlPanelHub.updateActiveItem = (activeItem) => {
				live.current = true;
				const guid = !activeItem.closed ? activeItem.activeGuid : activeItem.headingGuid;
				setSelectedItem(guid);
			};

			handler.meetingControlPanelHub.clearActiveItem = () => {
				live.current = false;
				setSelected("");
				setShowVotingResultsDialog(false);
				setVotingFinishedData(null);
				setVotingData(null);
			};

			handler.meetingControlPanelHub.loadMeetingItems = async () => {
				let option = notifierMessage(t("consentAgendaItemMoved"), "success");
				dispatch(setSnackbarOptions(option));
				const response = await request.get(`${API_HOST}/api/meeting/${id}/getagendaitems`);
				const { items, meeting, requestsToSpeak } = response.body;

				const minutesResponse = await request.get(`${API_HOST}/api/meeting/${id}/getminutesitems`);
				const minutesItems = minutesResponse.body.items;

				setMeetingData({ meeting, items, minutesItems, requestsToSpeak });
			};

			handler.votingHub.showResults = showResults;

			dispatch(
				updateSignalR({
					client,
					handler,
					done,
				}),
			);
		} catch (err) {
			console.log(err);
		}
	};

	const loadVotingSettings = () => {
		request
			.get(`${API_HOST}/api/settings`)
			.query({ type: "voting" })
			.then((res) => {
				if (res.body) {
					setVotingSettings(res.body);
				}
			})
			.catch((err) => {
				if (typeof showSignIn === "function") {
					showSignIn(err, () => {
						loadVotingSettings();
					});
				}
			});
	};

	const updateTimer = (timerMeetingId, timerTotalSeconds, timerRemainingSeconds, timerActive, timerCanceled) => {
		if (timerCanceled || parseInt(id, 10) !== timerMeetingId) {
			setTimer(null);

			return;
		}

		setTimer({
			totalSeconds: timerTotalSeconds,
			remainingSeconds: timerRemainingSeconds,
			active: timerActive,
		});
	};

	const checkLiveMeetingData = debounce(async () => {
		const res = await request.get(`${API_HOST}/api/meeting/${id}/live`);
		const { activeGuid } = res.body;

		setSelectedItem(activeGuid || "");

		checkLiveMeetingData();
	}, 5000);

	const checkVotingData = debounce(async () => {
		const res = await request.get(`${API_HOST}/api/voting/votingdata/${id}?page=presentation`);
		const { votingInRange } = res.body;

		if (votingInRange) {
			setVotingDataResponse((prev) => {
				if (!isEqual(res.body, prev)) {
					return res.body;
				}
				return prev;
			});

			checkVotingData();
		}
	}, 2000);

	const showResults = (hubVotingData) => {
		if (live.current) {
			//Checking if voting is finished and then showing the results
			const isVotingFinished = checkVotingFinished(
				{ fields: { Voting: { Value: hubVotingData.itemInProgress.itemVotingData } } },
				hubVotingData.rollCall,
			);
			if (typeof isVotingFinished === "boolean" && isVotingFinished) {
				hubVotingData.showResults = true;
			}
			setVotingData(hubVotingData);
			setShowVotingResultsDialog(true);
		}
	};

	const hideResults = () => {
		setShowVotingResultsDialog(false);
	};

	useEffect(() => {
		updateHandlers(dispatch, handler, LIVE_MEETING_HUB, { updateTimer });
		updateHandlers(dispatch, handler, VOTING_HUB, { showResults, hideResults });

		loadMeeting();
		loadVotingSettings();

		return () => {
			updateHandlers(dispatch, handler, LIVE_MEETING_HUB, { updateTimer: null });
			updateHandlers(dispatch, handler, VOTING_HUB, { showResults: null, hideResults: null });
			checkLiveMeetingData.cancel();
			checkVotingData.cancel();
			client.updateStatusIcon();
		};
	}, [id]);

	useEffect(() => {
		live.current = meetingData ? meetingData.meeting.live : false;
		if (live.current) {
			checkLiveMeetingData();
		}
		if (meetingData && meetingData.meeting.digitalVoting) {
			checkVotingData();
		}
	}, [meetingData]);

	useEffect(() => {
		if (
			votingDataResponse &&
			votingDataResponse.itemInProgress &&
			(votingDataResponse.itemInProgress.status != 2 || !(votingData != null && votingData.showResults))
		) {
			setVotingData(votingDataResponse);
		}
	}, [votingDataResponse]);

	useEffect(() => {
		if (votingData && votingData.itemInProgress && live.current) {
			const finished =
				checkVotingFinished({ fields: { Voting: { Value: votingData.itemInProgress.itemVotingData } } }, votingData.rollCall) ||
				votingData.showResults;
			const timeNow = new Date().getTime();
			if (finished) {
				if (votingFinishedData == null) {
					setVotingFinishedData({
						selectedItem: selected,
						finishedTime: timeNow,
					});
					setShowVotingResultsDialog(true);
				} else if (votingFinishedData != null && selected != votingFinishedData.selectedItem) {
					const timeLimit = votingFinishedData.finishedTime - timeNow - 10000;
					setTimeout(
						() => {
							setShowVotingResultsDialog(false);
							setVotingFinishedData(null);
							setVotingData(null);
						},
						timeLimit < 1000 ? 1000 : timeLimit,
					);
				}
			} else if (votingFinishedData != null) {
				setVotingFinishedData(null);
			}
		}
	}, [votingData, selected]);

	const presentationContent = () => {
		const activeGuid = meetingData.activeGuid || selected;
		if (live.current && activeGuid != null && activeGuid.length > 0) {
			const foundItem = meetingData.items.filter((meetingItem) => {
				if (meetingItem.guid === activeGuid) {
					return meetingItem;
				}
				if (meetingItem.guid !== activeGuid && meetingItem.attachments.length > 0 && !meetingItem.fields.Closed.Value) {
					const foundAttachment = meetingItem.attachments.filter(
						(attachment) => activeGuid.indexOf(attachment.guid) >= 0 && !attachment.closed,
					)[0];
					if (foundAttachment != null) {
						return foundAttachment;
					}
				}
				return null;
			})[0];

			if (foundItem && foundItem.guid !== activeGuid && foundItem.attachments.length > 0) {
				const foundAttachment = foundItem.attachments.filter((attachment) => activeGuid.indexOf(attachment.guid) >= 0)[0];
				const page = activeGuid.replace(`${foundAttachment.guid}-page-`, "");
				return (
					<iframe
						className={classes.attachmentFrame}
						src={`/document/${foundAttachment.guid}?printPdf=${foundAttachment.extension !== ".pdf"}&page=${page > 0 ? page : "1"}#page=${
							page > 0 ? page : "1"
						}&view=fit&zoom=page-fit`}
						title={foundAttachment.itemName}
					/>
				);
			}

			return (
				<ul id="agenda-items" className={classes.agenda}>
					<li>
						<div className={classes.agendaHeader}>
							<img src={`${API_HOST}/content/large.jpg`} alt={meetingData.Name} />
							<div className={classes.meetingInfoContainer}>
								<div className={classes.meetingName} data-cy="meeting-name">
									{meetingData.meeting.name}
								</div>
								<div>{formatDate(null, meetingData.meeting.startTime, null, "", "", "", true)}</div>
							</div>
						</div>
					</li>
					{getItems()}
				</ul>
			);
		}

		return (
			<div className={classes.agendaHeaderInitial}>
				<img src={`${API_HOST}/content/large.jpg`} alt={meetingData.Name} />
				<div data-cy="meeting-name" className={classes.meetingName}>
					{meetingData.meeting.name}
				</div>
				<div>{formatDate(null, meetingData.meeting.startTime, null, "", "", "", true)}</div>
			</div>
		);
	};

	return meetingData ? (
		<>
			<ComponentContainer padding="16px">
				{presentationContent()}
				{timer && live.current && <ViewerTimer timer={timer} presentation />}
				{votingData && showVotingResultsDialog && (
					<VotingResultsPresentationDialog votingData={votingData} votingSettings={votingSettings} meeting={meetingData.meeting} />
				)}
			</ComponentContainer>
		</>
	) : (
		<CircularProgressIndicator />
	);
};

export default withTranslation("meetings")(Presentation);
