import React from "react";
import SvgIcon from "@mui/material/SvgIcon";

import themeConfig from "../../atlas/assets/jss/theme";
import getOr from "lodash/fp/getOr";

const icon = (props) => {
	const { color, ...otherProps } = props;
	const fillColor = color ? getOr(color, ["palette", color, "main"], themeConfig) : "currentColor";

	return (
		<SvgIcon {...otherProps}>
			<g fill="none" fillRule="evenodd">
				<path d="M0-3h24v24H0z" />
				<path
					stroke={fillColor}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="2"
					d="M20.043 11.675A9.377 9.377 0 1 0 4.102 17.23M23 7.708l-2.885 4.328L16.11 8.43"
				/>
			</g>
		</SvgIcon>
	);
};

export default icon;
