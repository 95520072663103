import { format } from "date-fns";
import isEqual from "lodash/fp/isEqual";
import {
	createMeetingElement,
	updateOrderAndNumbering,
	updateRelationships,
	updateFlag,
	updateAttachmentLinks,
	getAllChildren,
	mergeMeetingPersistanceData,
	STATUS_SAVED_TIMEOUT,
	addTableWidth,
} from "utils/meetingElement";
import {
	GET_AGENDA_BUILDER_AGENDA_ITEMS_FULFILLED,
	UPDATE_AGENDA_BUILDER_AGENDA_ITEMS,
	UPDATE_AGENDA_SCRATCHPAD,
	PERSIST_AGENDA_BUILDER_AGENDA_ITEMS_PENDING,
	PERSIST_AGENDA_BUILDER_AGENDA_ITEMS_FULFILLED,
	PERSIST_AGENDA_BUILDER_AGENDA_ITEMS_FAILED,
	ADD_AGENDA_BUILDER_AGENDA_MEETING_ITEM,
	REORDER_AGENDA_BUILDER_AGENDA_ITEMS,
	DELETE_AGENDA_BUILDER_AGENDA_MEETING_ITEM,
	REMOVE_AGENDA_BUILDER_AGENDA_MEETING_ITEM,
	RESTORE_AGENDA_BUILDER_AGENDA_MEETING_ITEM,
	SET_ACTIVE,
	SET_AGENDA_BUILDER_CHANGESET_ID,
	CLEAR_AGENDA_BUILDER_STATUS,
	UPDATE_OUT_OF_SYNC_STATUS,
	SYNC_MINUTE_ITEMS,
	TOGGLE_TABLE_OF_CONTENTS,
} from "./types";
import { normalizeLinks } from "utils/processHtml";
import { parseTextBookmarks } from "utils/parseTextBookmarks";

export const agendaBuilderReducer = (state = { minutesBeforeSync: [], showTableOfContents: true }, action) => {
	switch (action.type) {
		case SYNC_MINUTE_ITEMS: {
			state = {
				...state,
				minutesBeforeSync: action.payload,
			};
			break;
		}
		case TOGGLE_TABLE_OF_CONTENTS: {
			state = {
				...state,
				showTableOfContents: !state.showTableOfContents,
			};
			break;
		}
		case UPDATE_OUT_OF_SYNC_STATUS: {
			state = {
				...state,
				agenda: {
					...(state?.agenda || {}),
					minutesOutOfSync: action.status,
				},
			};
			break;
		}
		case GET_AGENDA_BUILDER_AGENDA_ITEMS_FULFILLED: {
			const {
				id,
				meeting: { changeSetId = 0, isStarted, startTime, startTimeStamp, endTime, minutesOutOfSync },
				items,
				agendaHeader,
				agendaFooter,
				agendaNumbering,
				agendaScratchpad,
				customNumbering,
				isCorrectional,
			} = action.payload;
			const persistObject = { items: [], itemIdsToDelete: [] };
			persistObject.customNumbering = customNumbering;

			// Set any missing or incorrect numbering and save it
			updateOrderAndNumbering(
				persistObject,
				items.filter((item) => !item.attributes?.relationshipGuid && !item.deleted),
				undefined,
				items,
			);

			items.forEach((item) => {
				item.fields.Name.Value = item.fields.Name.Value.replaceAll("</a><", "</a>&nbsp;<");
			});

			const checkItemsForPersistObject = (persistObject, items) =>
				persistObject.items.some((updatedItem) => {
					const originalItem = items.find((item) => item.guid === updatedItem.guid);
					return isEqual(originalItem, updatedItem);
				});

			state = {
				...state,
				agenda: {
					...(state?.agenda || {}),
					id: parseInt(id, 10),
					changeSetId,
					items,
					agendaHeader,
					agendaFooter,
					agendaNumbering,
					agendaScratchpad,
					persistObject: persistObject,
					saving: false,
					updated: checkItemsForPersistObject(persistObject, items),
					customNumbering: customNumbering,
					isStarted,
					startTime,
					endTime,
					minutesOutOfSync,
					startTimeStamp,
					isCorrectional,
				},
				active: undefined,
			};
			break;
		}
		case PERSIST_AGENDA_BUILDER_AGENDA_ITEMS_FULFILLED: {
			const { t, dateFormat, changeSetId, clearStatus } = action;

			clearTimeout(state.agenda?.status?.timeout);

			state = {
				...state,
				agenda: {
					...state.agenda,
					changeSetId,
					saving: false,
					status: {
						label: t("agendaMenu:saved"),
						tooltip: `${t("agendaMenu:lastSaved")} ${format(new Date(), `${dateFormat} h:mm a`)}`,
						timeout: typeof clearStatus === "function" ? setTimeout(clearStatus, STATUS_SAVED_TIMEOUT) : undefined,
					},
				},
			};
			break;
		}

		case PERSIST_AGENDA_BUILDER_AGENDA_ITEMS_FAILED: {
			const { t, persistObject } = action;

			const mergedObject = mergeMeetingPersistanceData(state.agenda?.persistObject, persistObject);

			clearTimeout(state.agenda?.status?.timeout);

			state = {
				...state,
				agenda: {
					...state.agenda,
					persistObject: mergedObject,
					saving: false,
					error: true,
					updated: Boolean(
						mergedObject.agendaHeader ||
							mergedObject.agendaFooter ||
							mergedObject.items.length > 0 ||
							mergedObject.itemIdsToDelete.length > 0,
					),
					status: {
						label: t("agendaMenu:saveError"),
						tooltip: t("agendaMenu:saveErrorTooltip"),
					},
				},
			};
			break;
		}

		case UPDATE_AGENDA_BUILDER_AGENDA_ITEMS: {
			const { fieldData, editorFields } = action;
			const {
				agenda: { items, agendaHeader, agendaFooter, customNumbering, persistObject = { items: [], itemIdsToDelete: [] } } = {},
				active: { id: activeId } = {},
			} = state;
			let isValueUpdated = false;
			if (activeId) {
				isValueUpdated = true;
			}
			persistObject.customNumbering = customNumbering;
			let newFooter = agendaFooter;
			let newHeader = agendaHeader;
			fieldData.forEach((field) => {
				const editorField = editorFields.find((f) => f.name === field.fieldName);
				const guid = field.fieldName.replace("-text", "");
				if (guid === "toc-header" || guid === "toc-footer") {
					if (guid === "toc-header") {
						if (agendaHeader.replace(/ \/>/g, ">") === field.fieldData) return;
						newHeader = field.fieldData;
						persistObject.agendaHeader = addTableWidth(field, field.fieldData);
						return;
					}
					if (guid === "toc-footer") {
						if (agendaFooter.replace(/ \/>/g, ">") === field.fieldData) return;
						newFooter = field.fieldData;
						persistObject.agendaFooter = addTableWidth(field, field.fieldData);
						return;
					}
				} else {
					const item = items.find((item) => item.guid === guid);
					const persistItem = persistObject.items.find((item) => item.guid === guid);
					if (item) {
						if (field.nonEditor) {
							if (normalizeLinks(item.fields[field.name].Value) === normalizeLinks(field.fieldData)) return;
							item.fields[field.name].Value = addTableWidth(field, field.fieldData);
							if (persistItem) {
								persistItem.fields[field.name].Value = addTableWidth(field, field.fieldData);
							} else {
								persistObject.items.push(item);
							}

							// Ensure that if the item is closed, the attachment link classes are also set to closed
							if (field.name === "Closed" && field.fieldData === true) {
								updateAttachmentLinks(item, persistItem);
							}

							isValueUpdated = true; // non-editor fields are always updated, even if no item is active
						} else {
							if (field.fieldName.includes("-text")) {
								if (activeId && activeId === field.fieldName && field.fieldData === "") {
									isValueUpdated = false;
								}
								if (
									!editorField ||
									(!editorField.deleted &&
										item.fields.Text.Value &&
										normalizeLinks(item.fields.Text.Value.replace(/ \/>/g, ">")) === normalizeLinks(field.fieldData)) ||
									(!editorField.deleted && item.fields.Text.Value === null && field.fieldData === "") ||
									(editorField.deleted && item.fields.Text.Value === null)
								)
									return;
								item.fields.Text.Value = editorField.deleted ? null : addTableWidth(field, field.fieldData);
								if (persistItem) {
									persistItem.fields.Text.Value = editorField.deleted ? null : addTableWidth(field, field.fieldData);
								} else {
									persistObject.items.push(item);
								}
								return;
							}
							if (
								(item.fields.Name.Value &&
									normalizeLinks(item.fields.Name.Value.replace(/ \/>/g, ">")) === normalizeLinks(field.fieldData)) ||
								(!item.fields.Name.Value && !field.fieldData)
							)
								return;

							let parsedData = "";
							if (field.fieldData) {
								parsedData = addTableWidth(field, parseTextBookmarks(field.fieldData));
							}
							item.fields.Name.Value = parsedData ? parsedData : addTableWidth(field, field.fieldData);
							if (persistItem) {
								persistItem.fields.Name.Value = parsedData ? parsedData : addTableWidth(field, field.fieldData);
							} else {
								persistObject.items.push(item);
							}
							return;
						}
					}
				}
			});
			updateFlag(
				persistObject,
				items.filter((item) => !item.attributes?.relationshipGuid && !item.deleted),
				undefined,
				items,
			);

			state = {
				...state,
				agenda: {
					...(state.agenda || {}),
					items,
					agendaHeader: newHeader,
					agendaFooter: newFooter,
					persistObject: persistObject,
					updated: isValueUpdated,
					customNumbering,
				},
				minutes: undefined, // Ensure that the minutes get reloaded after agenda changes
			};
			break;
		}

		case UPDATE_AGENDA_SCRATCHPAD: {
			const { value } = action;
			const { agenda: { agendaScratchpad, customNumbering, persistObject = { items: [], itemIdsToDelete: [] } } = {} } = state;

			let newAgendaScratchpad = agendaScratchpad;

			// If there are no changes don't update anything
			if (agendaScratchpad.replace(/ \/>/g, ">") === value) break;

			newAgendaScratchpad = value;
			persistObject.customNumbering = customNumbering;
			persistObject.agendaScratchpad = newAgendaScratchpad;

			state = {
				...state,
				agenda: {
					...(state.agenda || {}),
					agendaScratchpad: value,
					persistObject: persistObject,
					updated: true,
				},
			};
			break;
		}

		case REORDER_AGENDA_BUILDER_AGENDA_ITEMS: {
			const { payload: items } = action;

			const {
				agenda: { customNumbering, persistObject = { items: [], itemIdsToDelete: [] } },
			} = state;
			persistObject.customNumbering = customNumbering;
			// Update relationships, order, and numbering
			updateRelationships(persistObject, items);
			updateOrderAndNumbering(
				persistObject,
				items.filter((item) => !item.attributes?.relationshipGuid && !item.deleted),
				undefined,
				items,
			);

			state = {
				...state,
				agenda: {
					...state.agenda,
					items: [...items],
					persistObject,
					updated: true,
					customNumbering,
				},
				minutes: undefined, // Ensure that the minutes get reloaded after agenda changes
			};
			break;
		}

		case REMOVE_AGENDA_BUILDER_AGENDA_MEETING_ITEM:
		case DELETE_AGENDA_BUILDER_AGENDA_MEETING_ITEM: {
			const { payload: guid, deleteItem } = action;

			const {
				agenda: { items, customNumbering, persistObject = { items: [], itemIdsToDelete: [] }, deletedItems = {} },
			} = state;
			persistObject.customNumbering = customNumbering;
			const index = items.findIndex((item) => item.guid === guid);
			const itemToDelete = items.find((item) => item.guid === guid);
			const parentItem = items.find((item) => item.guid === itemToDelete.attributes.relationshipGuid);

			const itemsToDelete = [itemToDelete].concat(getAllChildren(guid, items)); // Get the item and all of it's children
			itemsToDelete.forEach((item) => {
				if (deleteItem) {
					persistObject.itemIdsToDelete.push(item.guid);
				}
				item.deleted = true;
			});

			if (index >= 0 && deleteItem) {
				deletedItems[guid] = itemsToDelete;
			}

			// Update numbering
			updateOrderAndNumbering(
				persistObject,
				items.filter(
					(item) =>
						((parentItem && item.attributes.relationshipGuid === parentItem.guid) || (!parentItem && !item.attributes?.relationshipGuid)) &&
						!item.deleted,
				),
				parentItem,
				items,
			);

			state = {
				...state,
				agenda: {
					...state.agenda,
					items: [...items],
					persistObject,
					deletedItems,
					updated: true,
					customNumbering,
				},
				minutes: undefined, // Ensure that the minutes get reloaded after agenda changes
			};
			break;
		}

		case RESTORE_AGENDA_BUILDER_AGENDA_MEETING_ITEM: {
			const { payload: guid } = action;

			const {
				agenda: { items, customNumbering, persistObject = { items: [], itemIdsToDelete: [] }, deletedItems = {} },
			} = state;
			persistObject.customNumbering = customNumbering;

			const itemsToRestore = deletedItems[guid];
			if (itemsToRestore && itemsToRestore.length > 0) {
				const parentItem = items.find((item) => item.guid === itemsToRestore[0].attributes.relationshipGuid);

				// Restore the deleted items
				itemsToRestore.forEach((item) => {
					item.deleted = false;
					persistObject.items.push(item);
					if (persistObject.itemIdsToDelete.includes(item.guid)) {
						persistObject.itemIdsToDelete = persistObject.itemIdsToDelete.filter((id) => id !== item.guid);
					}
				});

				// Update numbering
				updateOrderAndNumbering(
					persistObject,
					items.filter(
						(item) =>
							((parentItem && item.attributes.relationshipGuid === parentItem.guid) ||
								(!parentItem && !item.attributes?.relationshipGuid)) &&
							!item.deleted,
					),
					parentItem,
					items,
				);

				state = {
					...state,
					agenda: {
						...state.agenda,
						items: [...items],
						persistObject,
						deletedItems,
						updated: true,
						customNumbering,
					},
					minutes: undefined, // Ensure that the minutes get reloaded after agenda changes
				};
			}
			break;
		}

		case PERSIST_AGENDA_BUILDER_AGENDA_ITEMS_PENDING: {
			const { noChanges, t } = action;
			const { items } = state.agenda;

			// Clear the attachment mapping
			items.forEach((item) => {
				delete item.attachmentMapping;
			});

			clearTimeout(state.agenda?.status?.timeout);

			state = {
				...state,
				agenda: {
					...state.agenda,
					persistObject: undefined, // This has been sent to the server, so allow a new persistObject to be created
					saving: !noChanges ? true : false,
					updated: false,
					error: false,
					isCorrectional: false,
					status: !noChanges
						? {
								label: t("agendaMenu:saving"),
								tooltip: t("agendaMenu:saveTooltip"),
							}
						: state.agenda?.status,
				},
			};
			break;
		}

		case ADD_AGENDA_BUILDER_AGENDA_MEETING_ITEM: {
			const { sourceItems, newItemGuid, itemsInsertIndex, parentItem } = action.payload;

			const {
				agenda: { customNumbering, items, persistObject = { items: [], itemIdsToDelete: [] } },
			} = state;

			persistObject.customNumbering = customNumbering;
			items.splice(
				itemsInsertIndex,
				0,
				...sourceItems.map(({ sourceItem, itemType, subHeading, newItemGuid, parentItem, parentGuid }) => {
					const newItem = createMeetingElement({
						guid: newItemGuid,
						itemType,
						name: sourceItem?.fields?.Name?.Value || "",
						text: sourceItem?.fields?.Text?.Value || null,
						indent: subHeading ? 1 : undefined,
						closed: Boolean(parentItem?.fields?.Closed?.Value),
						consent: Boolean(parentItem?.fields?.Consent?.Value),
						publicComment: Boolean(parentItem?.fields?.PublicComment?.Value),
						parentGuid,
						attachmentMapping: sourceItem?.attachmentMapping,
					});
					persistObject.items.push(newItem);

					return newItem;
				}),
			);

			//Get siblings and update ordering
			updateOrderAndNumbering(
				persistObject,
				items.filter(
					(item) =>
						((parentItem && item.attributes.relationshipGuid === parentItem.guid) || (!parentItem && !item.attributes?.relationshipGuid)) &&
						!item.deleted,
				),
				parentItem,
				items,
				newItemGuid,
			);

			state = {
				...state,
				agenda: {
					...state.agenda,
					items: [...items],
					persistObject,
					updated: true,
					customNumbering,
				},
				minutes: undefined, // Ensure that the minutes get reloaded after agenda changes
			};
			break;
		}
		case SET_ACTIVE: {
			const { guid, focus, field } = action.payload;

			state = {
				...state,
				active: guid ? { id: guid, focus, field } : undefined,
			};
			break;
		}
		case SET_AGENDA_BUILDER_CHANGESET_ID: {
			const { changeSetId } = action.payload;

			state = {
				...state,
				agenda: {
					...state.agenda,
					changeSetId,
				},
			};
			break;
		}

		case CLEAR_AGENDA_BUILDER_STATUS: {
			state = {
				...state,
				agenda: {
					...state.agenda,
					status: {},
				},
			};
			break;
		}
	}

	return state;
};

export default agendaBuilderReducer;
