import React from "react";
import { useTranslation } from "react-i18next";

import { Box, Checkbox, FormControlLabel } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import clsx from "clsx";

import Accordion from "atlas/components/Accordion/Accordion";
import InputLabel, { STYLE_SENTENCE_CASE, SIZE_LARGE } from "atlas/components/FormControls/InputLabel";
import { whiteColor } from "atlas/assets/jss/shared";
import { Check } from "components/Icons";
import AccordionStyle from "../../../assets/jss/components/accordionStyle";
import telemetryAddEvent from "utils/telemetryAddEvent";

const useStyles = makeStyles((theme) => ({
	section: {
		backgroundColor: whiteColor,
		boxShadow: (props) => (props.isUserProfile ? "none" : "0px 2px 10px rgba(0, 0, 0, 0.1)"),
		borderRadius: "2px",
		padding: "16px",
		marginBottom: "16px",
	},
	sectionAccordion: {
		padding: "0 16px",
	},
	columns: {
		display: "flex",
		flexWrap: "wrap",
		margin: "0 -12px",
		[theme.breakpoints.down("sm")]: {
			margin: "0",
		},
		"& > div": {
			width: "calc(50% - 24px)",
			boxSizing: "border-box",
			margin: "0 12px",
			[theme.breakpoints.down("sm")]: {
				width: "100%",
				margin: "0",
			},
		},
	},
}));

const useAccordionStyle = makeStyles(AccordionStyle);

const NotificationSettingsSection = (props) => {
	const { user, handleUpdate, startOpen = false, isUserProfile = false, telemetryPage } = props;
	const classes = useStyles({ isUserProfile });
	const accordionClasses = useAccordionStyle();

	const { t } = useTranslation("users");

	return (
		<div className={clsx(classes.section, classes.sectionAccordion)}>
			<Accordion
				classes={accordionClasses}
				label={t("detail.notifications")}
				scrollContentIntoView
				startOpen={startOpen}
				overflow={false}
				dataCy="notification-panel"
			>
				<fieldset>
					<legend>
						<InputLabel htmlFor="smsOnItemApproval" style={STYLE_SENTENCE_CASE} size={SIZE_LARGE} label={t("detail.smsOnItemApproval")} />
					</legend>
					<div className={classes.columns}>
						<div>
							<Box pl={2}>
								<FormControlLabel
									control={
										<Checkbox
											checkedIcon={<Check fontSize="small" color="primary" />}
											checked={user.smsOnApproveReject}
											onChange={(e) => {
												telemetryAddEvent(`${telemetryPage} - ${e.target.checked ? "Enable" : "Disable"} SMS notifications`, {
													area: "users",
												});

												handleUpdate(e, "smsOnApproveReject", true);
											}}
										/>
									}
									label={t("detail.smsOnApproveReject")}
									data-cy="smsOnApproveReject"
								/>
							</Box>
							<Box pl={2}>
								<FormControlLabel
									control={
										<Checkbox
											checkedIcon={<Check fontSize="small" color="primary" />}
											checked={user.smsOnItemDenied}
											onChange={(e) => {
												telemetryAddEvent(`${telemetryPage} - ${e.target.checked ? "Enable" : "Disable"} SMS notifications`, {
													area: "users",
												});

												handleUpdate(e, "smsOnItemDenied", true);
											}}
										/>
									}
									label={t("detail.smsOnItemDenied")}
									data-cy="smsOnItemDenied"
								/>
							</Box>
							<Box pl={2}>
								<FormControlLabel
									control={
										<Checkbox
											checkedIcon={<Check fontSize="small" color="primary" />}
											checked={user.smsOnEachItemApproved}
											onChange={(e) => {
												telemetryAddEvent(`${telemetryPage} - ${e.target.checked ? "Enable" : "Disable"} SMS notifications`, {
													area: "users",
												});

												handleUpdate(e, "smsOnEachItemApproved", true);
											}}
										/>
									}
									label={t("detail.smsOnEachItemApproved")}
									data-cy="smsOnEachItemApproved"
								/>
							</Box>
							<Box pl={2}>
								<FormControlLabel
									control={
										<Checkbox
											checkedIcon={<Check fontSize="small" color="primary" />}
											checked={user.smsOnItemFullyApproved}
											onChange={(e) => {
												telemetryAddEvent(`${telemetryPage} - ${e.target.checked ? "Enable" : "Disable"} SMS notifications`, {
													area: "users",
												});

												handleUpdate(e, "smsOnItemFullyApproved", true);
											}}
										/>
									}
									label={t("detail.smsOnItemFullyApproved")}
									data-cy="smsOnItemFullyApproved"
								/>
							</Box>
							<Box pl={2}>
								<FormControlLabel
									disabled
									control={
										<Checkbox
											disabled
											checked
											onChange={(e) => {
												telemetryAddEvent(`${telemetryPage} - ${e.target.checked ? "Enable" : "Disable"} SMS notifications`, {
													area: "users",
												});

												handleUpdate(e, "smsOnUrgentApproval", true);
											}}
										/>
									}
									label={t("detail.smsOnUrgentApproval")}
									data-cy="smsOnUrgentApproval"
								/>
							</Box>
						</div>
						<div>
							<Box>
								<FormControlLabel
									control={
										<Checkbox
											checkedIcon={<Check fontSize="small" color="primary" />}
											checked={user.smsOnAgendaShared}
											onChange={(e) => {
												telemetryAddEvent(
													`${telemetryPage} - ${e.target.checked ? "Enabled SMS on Agenda Share" : "Disable SMS notifications"}`,
													{ area: "users" },
												);

												handleUpdate(e, "smsOnAgendaShared", true);
											}}
										/>
									}
									label={t("detail.smsOnAgendaShared")}
									data-cy="smsOnAgendaShared"
								/>
							</Box>
						</div>
					</div>
				</fieldset>
			</Accordion>
		</div>
	);
};

export default NotificationSettingsSection;
