/* eslint-disable no-plusplus */
import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import cookie from "react-cookies";

import makeStyles from "@mui/styles/makeStyles";

import Icon from "atlas/components/Icon/Icon";
import GenericDialog from "atlas/components/Dialogs/GenericDialog";

const useStyles = makeStyles(() => ({
	dialog: {
		"& .MuiDialog-paper": {
			width: "440px",
			maxWidth: "100%",
		},
	},
}));

const AdoptPublishErrorDialog = (props) => {
	const { meetingId, handleCancel } = props;

	const classes = useStyles();
	const { t } = useTranslation("meetings");
	const navigate = useNavigate();

	const handleEditMinutesClick = () => {
		if (meetingId > 0) {
			navigate(`/meeting/${(cookie.load("old_minutes") || "false") === "false" ? "liveV2" : "live"}/${meetingId}`);
		}
	};

	return (
		<GenericDialog
			className={classes.dialog}
			show={meetingId > 0}
			clickAwayDisabled
			title={t("publishErrorDialog.title.error")}
			primaryAction={handleEditMinutesClick}
			primaryTitle={t("app:buttons.edit")}
			secondaryAction={handleCancel}
			secondaryTitle={t("app:buttons.cancel")}
			closeIcon={<Icon name="close" />}
			data-cy="approve-publish-error"
		>
			{t("approveMeetingDialog.error.minutes")}
		</GenericDialog>
	);
};

export default AdoptPublishErrorDialog;
