import React from "react";
import { useTranslation } from "react-i18next";
import clsx from "clsx";

import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
	userSmallLabel: {
		fontSize: "12px",
		textAlign: "center",
	},
	userCheckbox: {
		width: "48px",
		textAlign: "center",
		[theme.breakpoints.down("md")]: {
			width: "100%",
		},
		boxSizing: "border-box",
	},
}));

const PermissionOptionLabels = (props) => {
	const { hideView } = props;
	const { t } = useTranslation("users");
	const classes = useStyles();

	return (
		<>
			{!hideView && (
				<div id="view-label" className={clsx(classes.userSmallLabel, classes.userCheckbox)}>
					{t("detail.canView")}
				</div>
			)}
			<div id="edit-label" className={clsx(classes.userSmallLabel, classes.userCheckbox)}>
				{t("detail.canEdit")}
			</div>
		</>
	);
};

export default PermissionOptionLabels;
