import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import Popper from "@mui/material/Popper";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";

import telemetryAddEvent from "utils/telemetryAddEvent";
import Icon from "atlas/components/Icon/Icon";
import Tooltip from "atlas/components/Tooltip/Tooltip";
import GenericEditor from "components/Editor/GenericEditor";
import processHtml, { stripHtml } from "utils/processHtml";
import { grayColor, blackColor } from "atlas/assets/jss/shared";
import AccessibleIconButton from "atlas/components/Buttons/AccessibleIconButton";
import { isHtmlEqual } from "utils/html";

const useStyles = makeStyles(() => ({
	notesContainer: {
		display: "flex",
		flexDirection: "column",
		zIndex: "1001",
		backgroundColor: "#fff",
		boxShadow: "0 2px 10px 0 rgba(0, 0, 0, 0.5)",
		width: "370px",
		minHeight: "280px",
		maxHeight: "calc(50vh)", // Half page height
		minWidth: "406px",
		overflow: "hidden",
	},
	notesHeader: {
		display: "flex",
		flexDirection: "row",
	},
	itemName: {
		display: "flex",
		flexGrow: "1",
		margin: "8px 16px 8px 8px",
		marginLeft: "16px",
		alignItems: "center",
		color: grayColor[0],
		fontWeight: "normal",
		overflow: "hidden",
	},
	itemNameContainer: {
		fontSize: "22px",
		fontWeight: "normal",
		color: blackColor[4],
		whiteSpace: "nowrap",
		overflow: "hidden",
		textOverflow: "ellipsis",
		maxWidth: "340px",
		marginLeft: "8px",
	},
	closeButton: {
		margin: "0 8px",
	},
	icon: {
		cursor: "pointer",
	},
	notesContent: {
		flexGrow: "1",
		"& .ck-rounded-corners  .ck.ck-editor__top .ck-sticky-panel .ck-toolbar": {
			borderRadius: "0",
		},
		"& .ck-editor__editable": {
			minHeight: `${280 - 48 - 48}px`, // Popover height - header height - editor toolbar height
			maxHeight: "calc(50vh - 48px - 48px)", // Half page height - header height - editor toolbar height
		},
	},
	popperZindex: {
		zIndex: 1111,
	},
}));

const BoardNotes = (props) => {
	const {
		anchorEl,
		item,
		isMeetingTemplate = false,
		meetingId,
		updateNotes,
		closeNotes,
		open,
		queueFileUploads,
		invalidFileExtension,
		addPolicy,
		addGoals,
		disabled,
	} = props;
	const { t } = useTranslation("meetings");
	const classes = useStyles();

	const handleNotesEditorChange = (_event, editor) => {
		let newContent = editor.getData();
		let boardNotesData = "";
		if (!isMeetingTemplate && newContent) {
			let temp = document.createElement("div");
			temp.innerHTML = newContent;
			if (temp.childNodes && temp.childNodes.length > 0) {
				temp.childNodes.forEach((pNode) => {
					if (pNode.nodeName === "P") {
						pNode.childNodes.forEach((e) => {
							if (e.nodeName === "A" && e.className === "inlineFile") {
								e.className = "inlineFile closed";
							}
						});
					}
					boardNotesData += pNode.outerHTML;
				});
			}
		}

		if (isHtmlEqual(boardNotesData ? boardNotesData : newContent, item.fields.BoardNotes.Value)) {
			return; // don't re-save unchanged content;
		}

		updateNotes(boardNotesData ? boardNotesData : newContent, item);

		telemetryAddEvent("Agenda Edit - Edit briefing notes");
	};

	useEffect(() => {
		telemetryAddEvent("Agenda Edit - View briefing notes");
	}, []);

	return (
		<>
			{open && item != null && (
				<Popper className={classes.popperZindex} open={Boolean(anchorEl)} anchorEl={anchorEl} placement="left-start">
					<div id={`board-notes-panel-${item.guid}`} className={classes.notesContainer} data-cy="board-notes-panel">
						<div className={classes.notesHeader}>
							<Tooltip
								PopperProps={{
									disablePortal: true,
								}}
								title={processHtml(stripHtml(item.fields.Name.Value))}
							>
								<h1 className={classes.itemName}>
									<Icon name="locked" size="24px" />
									<div className={classes.itemNameContainer}>
										{`${t("notes")} - `}
										{processHtml(stripHtml(item.fields.Name.Value))}
									</div>
								</h1>
							</Tooltip>
							<AccessibleIconButton
								aria-label={t("tooltips.closeNotes")}
								onClick={closeNotes}
								iconName="close"
								dataCy="close-notes"
								tooltipText={t("tooltips.closeNotes")}
								className={classes.icon}
								name="close"
							/>
						</div>
						<div
							className={clsx(classes.notesContent, {
								"member-only-agenda-section": true,
							})}
						>
							<GenericEditor
								meetingId={meetingId}
								guid={item.guid}
								toolbar={isMeetingTemplate ? "meetingTemplateNotes" : "notes"}
								name="board-notes"
								content={item.fields.BoardNotes.Value}
								features={
									!isMeetingTemplate
										? [
												{
													id: "MOA",
													label: t("inlineFile.features.MOA.featureLabel"),
													className: "closed",
													defaultValue: true,
													disabledValue: true,
													isEnabled: false,
													anchorTitle: t("inlineFile.features.MOA.anchorTitleMember"),
													tooltipDisabledOn: t("inlineFile.features.MOA.tooltipDisabledOn"),
												},
										  ]
										: undefined
								}
								onChange={handleNotesEditorChange}
								queueFileUploads={(guid, fileUploads, fileData) => queueFileUploads(guid, fileUploads, fileData, item.guid)}
								invalidFileExtension={invalidFileExtension}
								addPolicy={addPolicy}
								addGoals={addGoals}
								mt={0}
								loadAsync
								preload={{ staticToolbar: !disabled }}
								disabled={disabled}
							/>
						</div>
					</div>
				</Popper>
			)}
		</>
	);
};

export default BoardNotes;
