import React from "react";
import { Divider, FormControlLabel, RadioGroup, FormControl, Radio } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Icon from "atlas/components/Icon/Icon";
import Tooltip from "atlas/components/Tooltip/Tooltip";

const useStyles = makeStyles(() => ({

    listItems: {
        display: "flex",
        justifyContent: "space-between",
        margin: "0px 18px 0px 34px",
    },
    icon: {
        margin: "13px 0px 15px 0px",
    },
    radioButton: {
        height: "18px",
        width: "18px",
        position: "relative",
        top: "12px",
    },
    line1: {
        margin: "0px",
        fontWeight: "600",
        fontSize: "16px",
        textWrap: "nowrap",
    },
    line2: {
        margin: "0px",
        fontWeight: "400",
        fontSize: "16px",
    },
    radioParent: {
        marginTop: "4px !important",
        width: "100%"
    },
    radioGroup: {
        width: "100%"
    },
    listItemParent: {
        marginTop: "8px"
    }
}));


const RadioButtonLIst = (props) => {
    const {
        handleRadioChange,
        getListOfOptions,
        defaultSelectedValue = null
    } = props;

    const classes = useStyles();
    return (
        <>
            <FormControl component="fieldset" className={classes.radioParent}>
                <RadioGroup className={classes.radioGroup} aria-label={"type"} name={"type"} onChange={handleRadioChange} row={false} defaultValue={defaultSelectedValue}>
                    {getListOfOptions &&
                        getListOfOptions.map((option,i) => {
                            return (
                                <div key={i} className={classes.listItemParent}>
                                    <div className={classes.listItems} >
                                        <div key={option.key}>
                                            <FormControlLabel
                                                key={option.key}
                                                value={option.key}
                                                control={<Radio color="primary" className={classes.radioButton} />}
                                                label={
                                                    <div>
                                                        <Tooltip disableHoverListener={!option.text1.isTruncated} title={option.originalText1}>
                                                            <p className={classes.line1}>{option.text1.text}</p>
                                                        </Tooltip>

                                                        <Tooltip disableHoverListener={!option.text2.isTruncated} title={option.originalText2}>
                                                            <p className={classes.line2}>{option.text2.text}</p>
                                                        </Tooltip>
                                                    </div>
                                                }
                                            />
                                        </div>
                                        <div className={classes.icon}>
                                            <Icon name="document" size={"24"} />
                                        </div>
                                    </div>
                                    <Divider />
                                </div>
                            );
                        })}
                </RadioGroup>
            </FormControl>
        </>
    );
};


export default RadioButtonLIst;
