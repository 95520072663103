import { produce } from "immer";
import { v4 as uuid } from "uuid";

import findIndex from "lodash/fp/findIndex";
import { createMeetingElement } from "utils/meetingElement";
import telemetryAddEvent from "utils/telemetryAddEvent";

import { updateAgenda, reOrderItemArray, findItemByID } from "./utils";

// eslint-disable-next-line import/prefer-default-export
export function addSubheader(guid) {
	const currentState = this.state;
	const subheaderId = uuid();

	telemetryAddEvent("Agenda builder - Add agenda subheading");

	const nextState = produce(
		currentState,
		(draft) => {
			let parent;
			if (!guid && draft.active.selected === "toc-header" && draft.items.length) {
				// add to the first section in the agenda
				parent = draft.items[0];
			} else {
				parent = findItemByID(guid || draft.active.selected, draft.items);
			}

			const selectedIndex = parent ? findIndex((item) => item.guid === parent.guid, draft.items) : 0;

			const newHeader = createMeetingElement({
				guid: subheaderId,
				itemType: 10,
				indent: "1",
				order: selectedIndex + 1,
				number: 1,
				parentGuid: "temp", // re-ordering will set relationship based on location in agenda, as long as we have some value here
			});

			this.itemIdsToUpdate.push(newHeader.guid);
			draft.items.splice(selectedIndex + 1, 0, newHeader);
			reOrderItemArray(draft.items, this, currentState.customNumbering);
		},
		(patches, inversePatches) => {
			if (patches.length) {
				this.redoStack.push([...patches]);
				this.undoStack.push([...inversePatches]);
			}
		},
	);

	updateAgenda(this, { items: nextState.items }, subheaderId);
}
