import React from "react";
import { useTranslation } from "react-i18next";

import { Box } from "@mui/material";

import Icon from "atlas/components/Icon/Icon";
import CircularProgressIndicator from "atlas/components/Progress/CircularProgressIndicator";
import GenericDialog from "atlas/components/Dialogs/GenericDialog";

const MeetingTemplateDuplicateDialog = (props) => {
	const { show = true, meetingTemplate, onClose } = props;
	const { t } = useTranslation("meetings");

	const handleCancel = () => {
		onClose();
	};

	const i18n = t("duplicateMeetingTemplateDialog", { name: meetingTemplate.name, meetings: meetingTemplate.meetings });

	const dialog = {
		title: i18n.title,
		line1: i18n.line1,
		secondaryTitle: t("app:buttons.cancel"),
		secondaryAction: handleCancel,
	};

	return (
		<GenericDialog
			show={show}
			title={dialog.title}
			secondaryAction={dialog.secondaryAction}
			secondaryTitle={dialog.secondaryTitle}
			closeIcon={<Icon name="close" />}
			data-cy="duplicate-meeting-template"
		>
			<Box fontWeight="fontWeightBold">{dialog.line1}</Box>
			<CircularProgressIndicator />
		</GenericDialog>
	);
};

export default MeetingTemplateDuplicateDialog;
