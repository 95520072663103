/* eslint-disable no-param-reassign */
import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation, useMatch } from "react-router-dom";
import request from "superagent";
import queryString from "query-string";

import CircularProgressIndicator from "atlas/components/Progress/CircularProgressIndicator";
import ComponentContainer from "atlas/components/ComponentContainer/ComponentContainer";
import isEmail from "atlas/utils/isEmail";
import withErrorHandling from "components/ErrorHOC";
import { API_HOST } from "config/env";
import telemetryAddEvent from "utils/telemetryAddEvent";
import { useUpdateObject, initializeValidate, setValidate } from "utils/updateObject";
import User from "./User";
import UserDeleteDialog from "./components/UserDeleteDialog";
import { resetPageConfigs, updatePageConfigs } from "redux/app/actions";
import { updatePageHeader } from "redux/pageHeader/actions";
import notifierMessage from "utils/notifierMessage";
import { setSnackbarOptions } from "../../redux/snackBar/actions";
import useBackButtonHandler from "utils/hooks/useBackButtonHandler.js";

const telemetryPage = "User Detail";
const clearErrors = {
	firstName: "",
	lastName: "",
	userName: "",
	userNameNeedsValidation: false,
	userNameLastValidated: "",
	emailAddress: "",
	emailAddressNeedsValidation: false,
	emailAddressLastValidated: "",
};

const UserContainer = (props) => {
	const { showSignIn } = props;
	const { params: { id } = {} } = useMatch({ path: "/users/edit/:id", end: true }) || {};
	const navigate = useNavigate();
	const location = useLocation();
	let { copy: copyId = 0 } = queryString.parse(location.search) || {};
	copyId = parseInt(copyId, 10);
	const { t } = useTranslation("users");
	const [user, setUser] = useState(null);
	const [userPhoneValid, setUserPhoneValid] = useState(true);
	const [departments, setDepartments] = useState(null);
	const [boards, setBoards] = useState(null);
	const [users, setUsers] = useState(null);
	const [errors, setErrors] = useState({
		...clearErrors,
	});
	const [savingState, setSavingState] = useState({
		saveAttempted: false,
		performSaveWhenAble: false,
		isSaving: false,
		updated: false,
	});
	const [passwordSetupSent, setPasswordSetupSent] = useState(false);
	const [dialogs, setDialogs] = useState({});
	const userRef = useRef(user); // For use in functions that have stale references
	const userPhoneValidRef = useRef(userPhoneValid); // For use in functions that have stale references
	const boardsRef = useRef(boards); // For use in functions that have stale references
	const errorsRef = useRef(errors); // For use in functions that have stale references
	const savingStateRef = useRef(savingState); // For use in functions that have stale references
	const dispatch = useDispatch();

	const loadUser = (id = 0, copy = false) => {
		request
			.get(`${API_HOST}/api/user/${id}`)
			.withCredentials()
			.then((res) => {
				const { body: user } = res || {};
				if (user) {
					if (copy) {
						user.id = 0;
						user.firstName = "";
						user.lastName = "";
						user.userName = "";
						user.emailAddress = "";
						user.profileImageUrl = "";
						user.phoneNumber = "";
						user.about = "";
						user.boardsCommittees = [];

						let option = notifierMessage(t("detail.snackbar.userCopied"), "success");
						dispatch(setSnackbarOptions(option));
					}
					if (id === 0) {
						user.active = true; // New user
					}
					setUser(user);
					setUserPhoneValid(true);
					initializeValidate(setErrors, user.id > 0, clearErrors);
				}
			})
			.catch((err) => {
				showSignIn(err, () => {
					loadUser(id, copy);
				});
			});
	};

	const loadDepartments = () => {
		request
			.get(`${API_HOST}/api/groups?departments=true`)
			.then((res) => {
				setDepartments(res.body);
			})
			.catch((err) => {
				showSignIn(err, () => {
					loadDepartments();
				});
			});
	};

	const loadBoards = () => {
		request
			.get(`${API_HOST}/api/boards?extendeduserdata=true`)
			.then((res) => {
				setBoards(res.body.boards);
			})
			.catch((err) => {
				showSignIn(err, () => {
					loadBoards();
				});
			});
	};

	const loadUsers = () => {
		request
			.get(`${API_HOST}/api/users?includeDeleted=true&includeExternal=true&firstNameLastName=true`)
			.withCredentials()
			.then((res) => {
				const { body: users } = res || {};
				if (users) {
					// Give each user a number to use for the avatar background
					let number = 0;
					const numberCache = {};
					users.forEach((user) => {
						if (typeof numberCache[user.id] === "undefined") {
							numberCache[user.id] = number;
							number++;
						}
						user.number = numberCache[user.id];
					});

					setUsers(users);
				}
			})
			.catch((err) => {
				showSignIn(err, () => {
					loadUsers();
				});
			});
	};

	const backToUsers = () => {
		const { isSaving, updated } = savingStateRef.current;

		if (isSaving || updated) {
			if (confirm(t("detail.unsavedChanges"))) {
				navigate("/users");
			} else {
				return;
			}
		} else {
			navigate("/users");
		}

		return true;
	};
	const unsavedChangesText = t("detail.unsavedChanges");
	useBackButtonHandler(savingStateRef.current, "/users", unsavedChangesText);

	const checkUnsavedChangesBeforeClosing = (event) => {
		const { isSaving, updated } = savingStateRef.current;

		if (isSaving || updated) {
			event.preventDefault();
			event.returnValue = "";
		}
	};

	const handleSave = () => {
		// Enable full validation
		initializeValidate(setErrors, true);

		// Check if async validation is in progress
		if (isValidationInProgress(errorsRef)) {
			setSavingState((prev) => ({
				...prev,
				performSaveWhenAble: true,
			}));
		} else if (!hasValidationErrors(errorsRef.current) && userPhoneValidRef.current) {
			saveUser(userRef.current);
		} else {
			setSavingState((prev) => ({
				...prev,
				saveAttempted: true,
			}));
		}
	};

	const handleCopy = (e) => {
		telemetryAddEvent(`${telemetryPage} - Copy user`, { area: "users" });

		navigate(`/users/edit?copy=${user.id}`);

		e.preventDefault();
	};

	const handleSendPasswordSetup = () => {
		if (user && user.id > 0 && user.emailAddress && user.emailAddress.length > 0 && isEmail(user.emailAddress)) {
			telemetryAddEvent(`${telemetryPage} - Password Set-up request`, { area: "users" });

			request
				.post(`${API_HOST}/api/users/passwordsetup`)
				.send({ users: [user.id] })
				.then((res) => {
					let option = notifierMessage(t("notification.passwordSetupSent"), "success");
					dispatch(setSnackbarOptions(option));
					setPasswordSetupSent(true);
				})
				.catch((err) => {
					showSignIn(err, () => {
						loadUsers();
					});
				});
		}
	};

	const handleDelete = () => {
		setDialogs({ delete: true });
	};

	const handleValidatePhoneNumber = (isValid) => {
		setUserPhoneValid(isValid);
	};

	const closeDeleteDialog = () => {
		setDialogs({});
	};

	const afterDelete = () => {
		telemetryAddEvent(`${telemetryPage} - Delete`, { area: "users" });

		navigate("/users");
	};

	const undoDelete = (deletedUser) => {
		request
			.post(`${API_HOST}/api/user/${deletedUser.id}/restore`)
			.withCredentials()
			.send({})
			.then(() => {
				let option = notifierMessage(t("deleteUserDialog.undo.successful"), "success");
				dispatch(setSnackbarOptions(option));

				navigate(`/users/edit/${deletedUser.id}`);
			})
			.catch((err) => {
				showSignIn(err, () => {
					undoDelete(deletedUser);
				});
			});
	};

	const saveUser = (updatedUser) => {
		setSavingState((prev) => ({
			...prev,
			saveAttempted: false,
			performSaveWhenAble: false,
			isSaving: true,
			updated: false,
		}));

		telemetryAddEvent(`${telemetryPage} - Save`, { area: "users" });

		request
			.put(`${API_HOST}/api/user/${updatedUser.id}`)
			.withCredentials()
			.send({
				...updatedUser,
				updatedBoards: (updatedUser.updatedBoards || []).map((boardId) => ({
					boardId,
					...(boardsRef.current
						.find((board) => board.id === boardId)
						.users.find(
							(boardUser) =>
								(boardUser.userId === updatedUser.id || (updatedUser.id === 0 && boardUser.userId === copyId)) &&
								(boardUser.member || boardUser.staff || boardUser.administrator),
						) || {}),
					userId: 0,
				})),
			})
			.then((res) => {
				if (res.status === 200) {
					const {
						body: { user },
					} = res || {};

					if (updatedUser.id !== user.id) {
						navigate(`/users/edit/${user.id}`, { replace: true });
					}

					let option = notifierMessage(t("detail.snackbar.userSaved"), "success");
					dispatch(setSnackbarOptions(option));

					setUser(user);
					setSavingState((prev) => ({
						...prev,
						isSaving: false,
					}));
				}
			})
			.catch((err) => {
				if (err.status === 400) {
					let option = notifierMessage(t("detail.snackbar.saveError"), "error");
					dispatch(setSnackbarOptions(option));

					setSavingState((prev) => ({
						...prev,
						isSaving: false,
					}));
				} else {
					showSignIn(err, () => {
						saveUser(updatedItem);
					});
				}
			});
	};

	const validateUser = (updatedUser, updatedField) => {
		setErrors((prev) => {
			if (!updatedUser.firstName) {
				prev.firstName = t("detail.validation.firstName");
			} else {
				prev.firstName = "";
			}
			if (!updatedUser.lastName) {
				prev.lastName = t("detail.validation.lastName");
			} else {
				prev.lastName = "";
			}
			if (!updatedUser.userName) {
				prev.userName = t("detail.validation.userName");
				prev.userNameLastValidated = "";
			} else if (updatedUser.userName !== prev.userNameLastValidated) {
				prev.userNameNeedsValidation = true;
				validateUserName(updatedUser.userName);
			}
			if (!updatedUser.emailAddress) {
				prev.emailAddress = t("detail.validation.emailAddress");
				prev.emailAddressLastValidated = "";
			} else if (!isEmail(updatedUser.emailAddress, true)) {
				prev.emailAddress = t("detail.validation.emailAddressValid");
				prev.emailAddressLastValidated = "";
			} else if (updatedUser.emailAddress !== prev.emailAddressLastValidated) {
				prev.emailAddressNeedsValidation = true;
				validateEmailAddress(updatedUser.emailAddress);
			}

			prev.invalidBoards = !!boardsRef.current.find((board) =>
				board.users.find(
					(boardUser) => boardUser.userId === updatedUser.id && !boardUser.member && !boardUser.staff && !boardUser.administrator,
				),
			);

			setValidate(prev, updatedField);

			return { ...prev };
		});
	};

	const hasValidationErrors = (errorsObject) =>
		errorsObject.firstName || errorsObject.lastName || errorsObject.userName || errorsObject.emailAddress || errorsObject.invalidBoards;
	const isValidationInProgress = (errorsObject) => errorsObject.userNameNeedsValidation || errorsObject.emailAddressNeedsValidation;

	const validateUserName = (userName) => {
		request
			.post(`${API_HOST}/api/user/username/validate`)
			.withCredentials()
			.send({ id: user.id, userName })
			.then((res) => {
				// Only update the errors if this is still the current user name
				if (userName === userRef.current.userName) {
					setErrors((prev) => ({
						...prev,
						userName: !res.body ? t("detail.validation.userNameUnique") : "",
						userNameNeedsValidation: false,
						userNameLastValidated: userName,
					}));
				}
			})
			.catch((err) => {
				showSignIn(err, () => {
					validateUserName();
				});
			});
	};

	const validateEmailAddress = (emailAddress) => {
		request
			.post(`${API_HOST}/api/user/emailaddress/validate`)
			.withCredentials()
			.send({ id: user.id, emailAddress })
			.then((res) => {
				// Only update the errors if this is still the current email address
				if (emailAddress === userRef.current.emailAddress) {
					setErrors((prev) => ({
						...prev,
						emailAddress: !res.body ? t("detail.validation.emailAddressUnique") : "",
						emailAddressNeedsValidation: false,
						emailAddressLastValidated: emailAddress,
					}));
				}
			})
			.catch((err) => {
				showSignIn(err, () => {
					validateEmailAddress();
				});
			});
	};

	const updateUser = useUpdateObject(setUser, undefined, validateUser, () => {
		setSavingState((prev) => ({
			...prev,
			updated: true,
		}));
	});

	useEffect(() => {
		userRef.current = user;
		userPhoneValidRef.current = userPhoneValid;
		boardsRef.current = boards;
		errorsRef.current = errors;
		savingStateRef.current = savingState;

		// Initialize the updated boards for copied users
		if (user && boards && user.id === 0 && copyId > 0 && !user.updatedBoards) {
			user.updatedBoards = boards
				.filter((board) => board.users.find((boardUser) => boardUser.userId === user.id || (user.id === 0 && boardUser.userId === copyId)))
				.map((board) => board.id);
		}

		// Continue save once async validation is complete
		if (savingState.performSaveWhenAble && !isValidationInProgress(errors) && userPhoneValid) {
			saveUser(user);
		}
	}, [user, userPhoneValid, boards, errors, savingState]);

	useEffect(() => {
		dispatch(resetPageConfigs({}));
		dispatch(
			updatePageConfigs({
				title: t("title.user"),
				back: { action: backToUsers },
				telemetryPage,
				contentPaper: { transparent: true },
			}),
		);

		const copy = !(parseInt(id, 10) > 0) && copyId > 0;
		const userId = copy ? copyId : id;

		loadUser(userId, copy);
		loadDepartments();
		loadBoards();
		loadUsers();

		if (userId) {
			telemetryAddEvent(`${telemetryPage} - Open`, { area: "users" });
		}

		window.addEventListener("beforeunload", checkUnsavedChangesBeforeClosing);

		return () => {
			window.removeEventListener("beforeunload", checkUnsavedChangesBeforeClosing);
		};
	}, [id, copyId]);

	useEffect(() => {
		dispatch(
			updatePageHeader({
				primaryAction: handleSave,
				primaryActionText: !savingState.isSaving ? t("app:buttons.save") : t("app:buttons.saving"),
				primaryActionTooltip: !savingState.isSaving ? t("tooltips.save") : undefined,
				primaryActionDisabled: savingState.isSaving || !savingState.updated,
			}),
		);
	}, [id, copyId, savingState.isSaving, savingState.updated]);

	return (
		<ComponentContainer padding="0">
			{dialogs.delete && user && (
				<UserDeleteDialog user={user} show={dialogs.delete} afterDelete={afterDelete} onClose={closeDeleteDialog} undoDelete={undoDelete} />
			)}
			{user ? (
				<User
					user={user}
					copyId={copyId}
					departments={departments}
					boards={boards}
					users={users}
					errors={errors}
					saveAttempted={savingState.saveAttempted}
					handleUpdate={updateUser}
					handleCopy={handleCopy}
					handleDelete={handleDelete}
					handleValidatePhoneNumber={handleValidatePhoneNumber}
					handleSendPasswordSetup={handleSendPasswordSetup}
					passwordSetupSent={passwordSetupSent}
					telemetryPage={telemetryPage}
				/>
			) : (
				<CircularProgressIndicator />
			)}
		</ComponentContainer>
	);
};

export default withErrorHandling(UserContainer);
