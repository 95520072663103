import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import makeStyles from "@mui/styles/makeStyles";

import Icon from "atlas/components/Icon/Icon";
import OutlinedInput from "atlas/components/FormControls/OutlinedInput";
import GenericDialog from "atlas/components/Dialogs/GenericDialog";
import CircularProgressIndicator from "atlas/components/Progress/CircularProgressIndicator";
import { isEmptyOrWhitespace } from "utils/html";

const useStyles = makeStyles(() => ({
	dialog: {
		"& .MuiDialog-paper": {
			width: "440px",
			maxWidth: "100%",
		},
	},
}));

const AgendaItemRejectDialog = (props) => {
	const { show, item, title, onApprove, onClose } = props;
	const { t } = useTranslation("agendaItems");
	const [comment, setComment] = useState("");
	const [errors, setErrors] = useState({});
	const [rejecting, setRejecting] = useState(false);
	const classes = useStyles();

	const handleFieldChange = (e) => {
		const {
			target: { value },
		} = e;

		setComment(value);
		setErrors((prev) => ({
			...prev,
			comment: isEmptyOrWhitespace(value) ? t("rejectDialog.errors.comments") : undefined,
		}));
	};

	return (
		<GenericDialog
			className={classes.dialog}
			show={show}
			title={title || t("rejectDialog.title")}
			primaryAction={() => {
				if (!isEmptyOrWhitespace(comment)) {
					setRejecting(true);
					onApprove(comment, item);
				}
			}}
			primaryTitle={rejecting ? <CircularProgressIndicator size={20} minHeight="20px" /> : t("rejectDialog.buttons.rejectItem")}
			primaryDisabled={rejecting || isEmptyOrWhitespace(comment)}
			secondaryAction={onClose}
			secondaryTitle={t("rejectDialog.buttons.cancel")}
			secondaryDisabled={rejecting}
			clickAwayDisabled={rejecting}
			closeIcon={<Icon name="close" />}
			data-cy="reject-dialog"
		>
			<OutlinedInput
				noDefaultClassName
				id="comment"
				label={t("rejectDialog.labels.comments")}
				externalLabel
				labelSize="large"
				value={comment}
				onChange={(e) => handleFieldChange(e)}
				fullWidth
				size="small"
				data-cy="reject-comment"
				multiline
				helperText={errors.comment}
				error={!!errors.comment}
			/>
		</GenericDialog>
	);
};

export default AgendaItemRejectDialog;
