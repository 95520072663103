import React, { useState, useRef, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";
import queryString from "query-string";
import { SettingsContext } from "contexts/Settings/SettingsContext";

import makeStyles from "@mui/styles/makeStyles";

import { grayColor, focusColor, primaryColor } from "atlas/assets/jss/shared";
import ButtonWithTooltip from "atlas/components/Buttons/ButtonWithTooltip";
import NonModalMenu from "atlas/components/Menu/NonModalMenu";
import { Button, ButtonGroup, Box, Typography } from "@mui/material";
import OutlinedInput from "atlas/components/FormControls/OutlinedInput";
import Icon from "atlas/components/Icon/Icon";
import { SMALL_SQUARE, VERY_SMALL, SMALL } from "atlas/utils/buttonSize";
import { getAllowedAcceptForPolicy } from "utils/allowedExtensions";
import OutlinedDatePicker from "atlas/components/FormControls/OutlinedDatePicker";
import { formatDate } from "utils/date";
import { format } from "date-fns";
import getPathType, { generatePathLinkForPolicy } from "views/Documents/utils/pathType";
import getPolicyType from "../utils/getPolicyType";

import AddToAgendaDialog from "components/Dialogs/AddToAgendaDialog";
import { useDispatch, useSelector } from "react-redux";
import { PublicDocumentContext } from "views/Policy/PolicyContainer";
import { getMeetingGroupDetails } from "redux/policyGovernance/actions";
import { UserContext } from "contexts/User/UserContext";
import { addPolicyToAgendaItem } from "redux/policyGovernance/actions";
import getProperty from "utils/caseInsensitiveProperty";
import { displayErrorNotification } from "views/Policy/utils/getNotification";
import { formatDateForPolicy } from "../utils/getFormattedDate";
import CircularProgressIndicator from "atlas/components/Progress/CircularProgressIndicator";
import Tooltip from "atlas/components/Tooltip/Tooltip";
import telemetryAddEvent from "utils/telemetryAddEvent";
import clsx from "clsx";

const useStyles = makeStyles((props) => ({
	section: {
		fontSize: "14px",
		fontWeight: "400",
		lineHeight: "16px",
		letterSpacing: "1px",
		borderBottom: `solid 1px ${grayColor[4]}`,
		padding: "16px",
	},
	name: {
		marginTop: "-16px",
	},

	data: {
		marginTop: "14px",
		display: "flex",
		alignItems: "center",
		boxSizing: "border-box",
		minHeight: "24px",
		padding: "4px 0",
		"& > *:not(:first-child)": {
			marginLeft: "8px",
		},
	},
	dataText: {
		flexGrow: "1",
	},
	fileInput: {
		display: "none",
	},
	path: {
		"& a": {
			textDecoration: "none",
		},
		"& a:hover": {
			textDecoration: "underline",
		},
		"& a:focus-visible": {
			outline: `solid 2px ${focusColor}`,
			outlineOffset: "-2px",
			borderRadius: "4px",
		},
	},
	actionButtons: {
		width: "32px",
		height: "32px",
		padding: "4px",
		border: "1px solid #455D82",
	},
	publishButton: {
		width: "78px",
		height: "32px",
		padding: "4px 12px 4px 12px",
		border: "1px solid #455D82",
		fontSize: "12px",
		fontWeight: "600",
	},
	splitButton: {
		width: "32px",
		height: "32px",
		padding: "4px",
		border: "1px solid #455D82",
		marginLeft: "2px",
	},
	addToAgendaButton: {
		width: "114px",
		height: "32px",
		padding: "4px 12px 4px 12px",
		borderRadius: "2px",
		border: "1px solid #455D82",
		fontSize: "12px",
		fontWeight: "600",
		marginLeft: "15px",
		marginTop: (props) => (props?.unpublished ? "0px" : "10px"),
	},
	downloadReadPolicyOnly: {
		width: "121px",
		height: "32px",
		padding: "4px 8px 4px 8px",
		borderRadius: "2px",
		border: "1px solid #455D82",
		fontSize: "12px",
		fontWeight: "600",
		marginRight: "60px",
		marginBottom: "10px",
		// marginTop: "10px",
		marginLeft: (props) => (!props?.communityMeetings && props?.unpublished ? "8px" : "0px"),
	},
	downloadRead: {
		width: "32px",
		height: "30px",
		padding: "4px",
		borderRadius: "2px",
		border: "2px solid #385F99",
		fontSize: "12px",
		fontWeight: "600",
		marginLeft: "10px",
		marginRight: "60px",
		marginBottom: "10px",
		marginTop: "10px",
	},
	copyMoveButton: {
		width: "80px",
		height: "32px",
		padding: "4px, 12px, 4px, 12px",
		border: "1px solid #455D82",
		fontWeight: "600",
	},
	readOnlyValuesContainer: {
		marginTop: "14px 0",
		lineHeight: "25px",
	},
	readOnlyContainer: {
		display: "flex",
		flexDirection: "column",
		margin: "10px 0px 10px 0px ",
	},
	readOnlyParentContainer: {
		marginBottom: "20px",
	},
	truncatedText: {
		overflow: "hidden",
		textOverflow: "ellipsis",
		whiteSpace: "nowrap",
	},
	downloadButtonText: {
		fontWeight: "600",
		fontSize: "12px",
	},
	downloadButtonTextAndIcon: {
		display: "flex",
		alignItems: "center",
		gap: "12px",
	},
	downloadIcon: {
		paddingTop: "3px",
	},
}));

const PolicyDetailsInfo = (props) => {
	const {
		document,
		handleRename,
		handleDownload,
		handleReplace,
		handleCopyMove,
		deleteClick,
		handlePublishPolicy,
		handleunpublishPolicy,
		handleMenuClose,
		openMenu,
	} = props;
	const {
		adoptedDate,
		revisedDate: revisedDateApi,
		rescindedDate: rescindedDateApi,
		lastReviewedDate: lastReviewedDateApi,
		nextReviewDate: nextReviewDateApi,
		code: codeApi = "",
		title: titleApi,
		parentFolderType,
		policyStatus = "",
		dateModified,
		folder,
		fileSize,
		extension,
		path,
		publishedGuid = null,
		isAdmin,
		canUpdate,
		guid,
		isStaff,
	} = document;
	const { t } = useTranslation("documents");
	const navigate = useNavigate();
	const location = useLocation();
	const search = queryString.parse(location.search) || {};
	const action = (getProperty(search, "action") || "").toLowerCase();
	const [title, setTitle] = useState(titleApi);
	const [code, setCode] = useState(codeApi);
	const [adoptionDate, setAdoptionDate] = useState(adoptedDate);
	const [revisedDate, setRevisedDate] = useState(revisedDateApi);
	const [rescindedDate, setRescindedDate] = useState(rescindedDateApi);
	const [lastReviewedDate, setLastReviewedDate] = useState(lastReviewedDateApi);
	const [nextReviewDate, setNextReviewDate] = useState(nextReviewDateApi);
	const input = useRef(null);
	const [selectedMenuOption, setSelectedMenuOption] = useState({});
	const [isEmpty, setIsEmpty] = useState(false);
	const [maxDateAdopted, setMaxDateAdopted] = useState(null);
	const [minDateRescinded, setMinDateRescinded] = useState(null);
	const [minDateRevised, setMinDateRevised] = useState(null);
	const [maxDateRevised, setMaxDateRevised] = useState(null);
	const [minDateNextReview, setMinDateNextReview] = useState(null);
	const [showAddToAgendaDialog, setShowAddToAgendaDialog] = useState(false);
	const [meetingGroups, setMeetingGroups] = useState([]);
	const [loading, setLoading] = useState(false);
	const [truncatedText, setTruncatedText] = useState("");
	const [truncatedCode, setTruncatedCode] = useState("");
	const titleLength = 20;
	const codeLength = 20;
	const { publishedPermissionsData: { publishedWrite, publishedRead, unpublished } = {} } = useSelector(
		(state) => state.policyGovernanceReducer,
	);
	const { id: userId, boardAdmin } = useContext(UserContext).user;

	const ispublicDocument = useContext(PublicDocumentContext);
	const dispatch = useDispatch();
	const { lite, communityMeetings } = useContext(SettingsContext);
	const classes = useStyles({ unpublished, communityMeetings });
	let today = new Date();
	today = today.toISOString();

	const displayAddToAgendaButton = () =>
		(canUpdate || isStaff) && ((lite.enabled && boardAdmin && !ispublicDocument) || (!lite.enabled && !ispublicDocument));

	useEffect(() => {
		if (rescindedDate && revisedDate && adoptionDate) {
			setMaxDateRevised(rescindedDate);
			setMinDateRevised(adoptionDate);
			setMaxDateAdopted(revisedDate);
			setMinDateRescinded(revisedDate);
		} else if (revisedDate && adoptionDate) {
			setMinDateRevised(adoptionDate);
			setMaxDateAdopted(revisedDate);
			setMinDateRescinded(revisedDate);
		} else if (rescindedDate && adoptionDate) {
			setMinDateRescinded(adoptionDate);
			setMaxDateAdopted(rescindedDate);
			setMinDateRevised(adoptionDate);
			setMaxDateRevised(rescindedDate);
		} else if (rescindedDate && revisedDate) {
			setMinDateRescinded(revisedDate);
			setMaxDateRevised(rescindedDate);
			setMaxDateAdopted(revisedDate);
		} else if (adoptionDate) {
			setMinDateRescinded(adoptionDate);
			setMinDateRevised(adoptionDate);
			setMaxDateAdopted(null);
		} else if (revisedDate) {
			setMaxDateAdopted(revisedDate);
			setMinDateRescinded(revisedDate);
			setMinDateRevised(null);
			setMaxDateRevised(null);
		} else if (rescindedDate) {
			setMaxDateAdopted(rescindedDate);
			setMaxDateRevised(rescindedDate);
			setMinDateRescinded(null);
		} else {
			setMaxDateRevised(null);
			setMinDateRevised(null);
			setMaxDateAdopted(null);
			setMinDateRescinded(null);
		}
	}, [adoptionDate, revisedDate, rescindedDate]);

	useEffect(() => {
		if (lastReviewedDate) {
			setMinDateNextReview(lastReviewedDate);
		} else {
			setMinDateNextReview(null);
		}
	}, [lastReviewedDate]);

	useEffect(() => {
		if (meetingGroups.length > 0) {
			setShowAddToAgendaDialog(true);
		}
	}, [meetingGroups]);

	useEffect(() => {
		if (titleApi?.length > titleLength) {
			setTruncatedText(titleApi);
		}
		if (codeApi?.length > codeLength) {
			setTruncatedCode(codeApi);
		}
		if (document && action === "addtoagenda") {
			onAddToAgendaClick();
		}
	}, [document]);

	const setDate = (date) => {
		try {
			return date ? format(date, "yyyy-MM-dd") : null;
		} catch {
			// ignored
		}

		return null;
	};

	const onAddToAgendaClick = () => {
		if (isAdmin && canUpdate) {
			setLoading(true);
			dispatch(getMeetingGroupDetails(userId)).then((res) => {
				setLoading(false);
				setMeetingGroups(res);
			});
		} else if (canUpdate || isStaff) {
			setLoading(true);
			dispatch(addPolicyToAgendaItem(guid)).then((res) => {
				setLoading(false);
				navigate(`/agendaitems/edit/${res.itemGuid}`);
			});
		} else {
			displayErrorNotification("Access Denied", dispatch);
		}
	};

	const openPage = (e, pageLink) => {
		e.preventDefault();

		navigate(pageLink);
	};

	const handleInputChange = (event) => {
		setTitle(event);
		setIsEmpty(event.trim() === "");
	};

	const replacepolicyClick = () => {
		input.current.click();
	};

	const rootFolder = getPathType(parentFolderType);

	const publishPolicyMenuOptions = [
		{
			id: "publish-policy",
			label: "Unpublish",
			ariaLabel: t("tooltips.unpublish"),
			actionFunction: (e) => {
				handleToggleMenu(e, "unpublish");
			},
			"data-cy": "publish-policy",
		},
	];

	const handleToggleMenu = (e, type) => {
		e.stopPropagation();
		e.preventDefault();
		if (type === "publish") {
			telemetryAddEvent(`Policy - Policy Document Publish`);
			handlePublishPolicy();
		} else {
			telemetryAddEvent(`Policy - Policy Document Unpublish`);
			handleunpublishPolicy();
		}
	};

	const handleDateChange = (date, setMethod, field) => {
		if (date) {
			const formattedDate = setDate(date);
			setMethod(formattedDate || date);
			if (formattedDate) {
				handleRename(field, formattedDate);
			}
		} else {
			setMethod(null);
			handleRename(field, date);
		}
	};

	const getDownloadButton = () => {
		return (
			<ButtonWithTooltip
				id="download"
				title={t("detail.tooltips.downloadPolicy")}
				onClick={handleDownload}
				variant="outlined"
				color="primary"
				dataCy="download"
				className={clsx({
					[classes.downloadReadPolicyOnly]: !communityMeetings,
					[classes.downloadRead]: communityMeetings,
				})}
			>
				<div
					className={clsx({
						[classes.downloadButtonTextAndIcon]: !communityMeetings,
					})}
				>
					<div className={classes.downloadIcon}>
						<Icon name="arrow-down-policy" size="32" color={primaryColor[3]} />
					</div>
					{!communityMeetings && <div className={classes.downloadButtonText}>{t("app:buttons.download")}</div>}
				</div>
			</ButtonWithTooltip>
		);
	};

	return (
		document && (
			<>
				<div className={classes.section}>
					{unpublished ? (
						<>
							<div className={classes.name}>
								<OutlinedInput
									id="code"
									label={t("policy.labels.code")}
									value={code}
									onChange={(e) => setCode(e.target.value)}
									onBlur={() => handleRename("code", code)}
									noDefaultClassName
									fullWidth
									size="small"
									disabled={publishedWrite || publishedRead}
									data-cy="code"
								/>
							</div>
							<div className={classes.name}>
								<OutlinedInput
									id="name"
									label={t("policy.labels.name")}
									value={title}
									onChange={(e) => handleInputChange(e.target.value)}
									onBlur={() => handleRename("title", title)}
									noDefaultClassName
									fullWidth
									size="small"
									disabled={publishedWrite || publishedRead}
									data-cy="name"
									required={true}
									error={isEmpty}
									helperText={isEmpty ? t("policy.errorMsg.emptyPolicyName") : ""}
								/>
							</div>
						</>
					) : (
						<div className={classes.readOnlyParentContainer}>
							<div className={classes.readOnlyContainer}>
								<div>{t("policy.labelText.code")}</div>
								<div>
									<Tooltip title={truncatedCode} placement="top" disableHoverListener={codeApi.length < codeLength}>
										<div className={classes.truncatedText}>{codeApi}</div>
									</Tooltip>
								</div>
							</div>
							<div className={classes.readOnlyContainer}>
								<div>{t("policy.labelText.name")}</div>
								<div>
									<Tooltip title={truncatedText} placement="top" disableHoverListener={titleApi.length < titleLength}>
										<div className={classes.truncatedText}>{titleApi}</div>
									</Tooltip>
								</div>
							</div>
						</div>
					)}
					<div className={classes.data}>
						<ButtonGroup
							className={classes.name}
							variant="contained"
							color="primary"
							style={{ verticalAlign: "bottom" }}
							aria-label="Publish"
						>
							{unpublished && (
								<>
									<ButtonWithTooltip
										variant="outlined"
										color="primary"
										onClick={(e) => {
											handleToggleMenu(e, "publish");
										}}
										title="Publish"
										data-cy="publishPolicy"
										size="small"
										className={classes.publishButton}
									>
										{t("policy.buttons.publish")}
									</ButtonWithTooltip>
									<ButtonWithTooltip
										variant="outlined"
										color="primary"
										title={"Select Policy publish option"}
										aria-controls="split-button-menu"
										aria-expanded="true"
										aria-label="select publish action"
										aria-haspopup="menu"
										onClick={(e) => {
											handleMenuClose(openMenu);
											setSelectedMenuOption({ ["publish"]: e.currentTarget });
										}}
										tooltipPlacement="bottom"
										size="small"
										className={classes.splitButton}
									>
										<Icon name="expand-down" color={primaryColor[4]} size={15} />
									</ButtonWithTooltip>
								</>
							)}
							{!unpublished && getDownloadButton()}

							{communityMeetings && displayAddToAgendaButton() ? (
								<ButtonWithTooltip
									variant="outlined"
									color="primary"
									title="Add to Agenda"
									aria-label="Add to Agenda"
									onClick={() => {
										onAddToAgendaClick();
									}}
									tooltipPlacement="bottom"
									size="small"
									className={classes.addToAgendaButton}
								>
									{loading ? <CircularProgressIndicator minHeight="0px" size={20} padding="6px" /> : t("policy.buttons.addToAgenda")}
								</ButtonWithTooltip>
							) : (
								unpublished && getDownloadButton()
							)}
						</ButtonGroup>
					</div>
					{showAddToAgendaDialog && (
						<AddToAgendaDialog
							show
							meetingDate={today}
							meetingStartTime={today}
							meetingGroups={meetingGroups}
							isPolicy
							policyGuid={guid}
							finishPolicyUrl={`/policydoc/${guid}`}
							onClose={() => setShowAddToAgendaDialog(false)}
						/>
					)}
					{openMenu && (
						<NonModalMenu
							id="publishunpublishmenuoption"
							anchorEl={selectedMenuOption.publish}
							keepMounted
							open={openMenu}
							onClose={handleMenuClose}
							options={publishPolicyMenuOptions}
							position="bottom-end"
							disabled={publishedWrite}
						/>
					)}
					{!unpublished && (
						<div className={classes.readOnlyValuesContainer}>
							<>
								{rescindedDateApi && <div>{`${t("policy.labels.rescindedDate")}${formatDate(rescindedDateApi)}`}</div>}
								{lastReviewedDateApi && <div>{`${t("policy.labels.lastReviewedDate")}${formatDate(lastReviewedDateApi)}`}</div>}
								{revisedDateApi && <div>{`${t("policy.labels.revisedDate")}${formatDate(revisedDateApi)}`}</div>}
								{nextReviewDateApi && <div>{`${t("policy.labels.nextReviewDate")}${formatDate(nextReviewDateApi)}`}</div>}
								{adoptedDate && <div>{`${t("policy.labels.adoptionDate")}${formatDate(adoptedDate)}`}</div>}
							</>
						</div>
					)}
					{unpublished && (
						<>
							<div className={classes.readOnlyValuesContainer}>
								<div>
									{t("policy.labelText.status")}
									{getPolicyType(policyStatus)}
								</div>
							</div>
							<OutlinedDatePicker
								id="date"
								className={classes.fieldInput}
								label={t("policy.labels.adoptionDateField")}
								iconTooltip={t("policy.tooltips.adoptionDate")}
								dateFormat="yyyy-MM-dd"
								value={formatDateForPolicy(adoptionDate)}
								maxDate={maxDateAdopted}
								onChange={(e) => handleDateChange(e, setAdoptionDate, "adoptedDate")}
								dataCy="date"
								disabled={publishedWrite}
							></OutlinedDatePicker>
							<OutlinedDatePicker
								id="date"
								className={classes.fieldInput}
								label={t("policy.labels.lastReviewedDateField")}
								iconTooltip={t("policy.tooltips.lastReviewedDate")}
								dateFormat="yyyy-MM-dd"
								value={formatDateForPolicy(lastReviewedDate)}
								onChange={(e) => handleDateChange(e, setLastReviewedDate, "lastReviewedDate")}
								dataCy="date"
								disabled={publishedWrite}
							></OutlinedDatePicker>
							<OutlinedDatePicker
								id="date"
								className={classes.fieldInput}
								label={t("policy.labels.revisedDateField")}
								iconTooltip={t("policy.tooltips.revisedDate")}
								dateFormat="yyyy-MM-dd"
								value={formatDateForPolicy(revisedDate)}
								minDate={minDateRevised}
								maxDate={maxDateRevised}
								onChange={(e) => handleDateChange(e, setRevisedDate, "revisedDate")}
								dataCy="date"
								disabled={publishedWrite}
							></OutlinedDatePicker>
							<OutlinedDatePicker
								id="date"
								className={classes.fieldInput}
								label={t("policy.labels.nextReviewDateField")}
								iconTooltip={t("policy.tooltips.nextReviewDate")}
								dateFormat="yyyy-MM-dd"
								value={formatDateForPolicy(nextReviewDate)}
								minDate={minDateNextReview}
								onChange={(e) => handleDateChange(e, setNextReviewDate, "nextReviewDate")}
								dataCy="date"
								disabled={publishedWrite}
							></OutlinedDatePicker>
							<OutlinedDatePicker
								id="date"
								className={classes.fieldInput}
								label={t("policy.labels.rescindedDateField")}
								iconTooltip={t("policy.tooltips.rescindedDate")}
								dateFormat="yyyy-MM-dd"
								value={formatDateForPolicy(rescindedDate)}
								minDate={minDateRescinded}
								onChange={(e) => handleDateChange(e, setRescindedDate, "rescindedDate")}
								dataCy="date"
								disabled={publishedWrite}
							></OutlinedDatePicker>
							<div className={classes.data}>
								<div>
									{t("policyDetails.labels.draftLastUpdated", {
										date: formatDate(dateModified, dateModified, undefined, t("app:at"), "", "", true, true, true),
									})}
								</div>
							</div>
							{!folder && (
								<div className={classes.data}>
									<div className={classes.dataText}>{t("detail.labels.type", { type: extension.replace(".", "").toUpperCase() })}</div>
								</div>
							)}
							{!folder && (
								<div className={classes.data}>
									<div>{t("detail.labels.size", { size: fileSize })}</div>
								</div>
							)}
							<div className={classes.data}>
								<ButtonWithTooltip
									id="download"
									title={t("detail.tooltips.downloadPolicy")}
									onClick={handleDownload}
									variant="outlined"
									color="primary"
									dataCy="download"
									className={classes.actionButtons}
									disabled={publishedWrite}
									size={SMALL_SQUARE}
								>
									<Icon name="arrow-down-policy" size="32" color={primaryColor[3]} />
								</ButtonWithTooltip>
								<ButtonWithTooltip
									id="replace"
									title={t("detail.tooltips.replacePolicy")}
									onClick={replacepolicyClick}
									variant="outlined"
									color="primary"
									dataCy="replace"
									className={classes.actionButtons}
									disabled={publishedWrite}
									size={SMALL_SQUARE}
								>
									<Icon name="arrow-up-policy" size="32" color={primaryColor[3]} />
								</ButtonWithTooltip>
								<input
									className={classes.fileInput}
									id="replace-input"
									type="file"
									ref={input}
									onChange={handleReplace}
									accept={getAllowedAcceptForPolicy()}
									tabIndex={-1}
									aria-label={t("detail.buttons.replace")}
									aria-hidden="true"
								/>
								<ButtonWithTooltip
									id="delete"
									title={t("detail.tooltips.deletePolicy")}
									onClick={(event) => {
										deleteClick("delete", document);
									}}
									variant="outlined"
									color="primary"
									dataCy="replace"
									className={classes.actionButtons}
									size={SMALL_SQUARE}
								>
									<Icon name="delete-policy" size="32" color={primaryColor[3]} />
								</ButtonWithTooltip>
							</div>
						</>
					)}
				</div>

				{unpublished && (
					<>
						<div className={classes.section}>
							<div className={classes.data}>
								<div className={classes.path}>
									{t("detail.labels.path")}
									{path &&
										path.map((pathItem, index) => {
											const pathLink = generatePathLinkForPolicy(pathItem, rootFolder);

											return (
												<React.Fragment key={pathItem.guid}>
													{rootFolder ? (
														<a href={`/home${pathLink}`} onClick={(e) => openPage(e, pathLink)}>
															{(pathItem?.code !== null ? pathItem.code + " " : "") + pathItem.title}
														</a>
													) : (
														<span>{pathItem.title}</span>
													)}
													{index < path.length - 1 ? <span>{" > "}</span> : ""}
												</React.Fragment>
											);
										})}
								</div>
							</div>

							<div className={classes.data}>
								{unpublished && (
									<ButtonWithTooltip
										id="copy-move"
										onClick={handleCopyMove}
										variant="outlined"
										color="primary"
										size={VERY_SMALL}
										dataCy="copy-move"
										className={classes.copyMoveButton}
										disabled={publishedWrite}
									>
										{t(`detail.buttons.moveTo`)}
									</ButtonWithTooltip>
								)}
							</div>
						</div>
					</>
				)}
			</>
		)
	);
};

export default PolicyDetailsInfo;
