import React, { useState, useRef, useEffect } from "react";
import { createPortal } from "react-dom";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { DndContext, DragOverlay, KeyboardSensor, PointerSensor, useSensor, useSensors, rectIntersection } from "@dnd-kit/core";

import makeStyles from "@mui/styles/makeStyles";

import permissionsStyle from "assets/jss/components/permissionsStyle";
import InputLabel from "atlas/components/FormControls/InputLabel";
import Draggable from "atlas/components/DragAndDrop/Draggable";
import Droppable from "atlas/components/DragAndDrop/Droppable";
import DragPresentation from "atlas/components/DragAndDrop/DragPresentation";
import { useWidthDown } from "atlas/utils/useWidth";
import { FOLDER_TYPE_PUBLIC, FOLDER_TYPE_INTERNAL } from "utils/enums/DocumentFolderTypes";
import { getCollisionDetection } from "utils/dragAndDrop";
import { useUpdateObject } from "utils/updateObject";
import FolderPermissionsFilter from "./FolderPermissionsFilter";
import FolderPermissionUser from "./FolderPermissionUser";
import FolderPermissionUsers from "./FolderPermissionUsers";
import telemetryAddEvent from "utils/telemetryAddEvent";
const useStyles = makeStyles(permissionsStyle);

const defaultFilter = {
	search: "",
	editOnly: false,
};

const FolderPermissions = (props) => {
	const { folderType, folderPermissions, users, handleUpdate, updateOnly = false, telemetryPage } = props;
	const publicOrInternal = [FOLDER_TYPE_PUBLIC, FOLDER_TYPE_INTERNAL].includes(folderType);
	const mobile = useWidthDown("md");
	const { t } = useTranslation("documents");
	const sensors = useSensors(useSensor(PointerSensor), useSensor(KeyboardSensor));
	const [filter, setFilter] = useState({ ...defaultFilter });
	const [draggedId, setDraggedId] = useState(null);
	const [dropTargets, setDropTargets] = useState([]);
	const [dropDownMenu, setDropDownMenu] = useState(1);
	const [label, setLabel] = useState(t("customFolderDetail.selectedPeople"));

	const dragRef = useRef(null);
	const classes = useStyles();

	const updateLabelText = (newstate) => {
		setDropDownMenu(newstate);
	};

	useEffect(() => {
		updateOnly
			? setLabel(t("selectedUsers"))
			: dropDownMenu === 1
			? setLabel(t("customFolderDetail.selectedPeople"))
			: setLabel(t("customFolderDetail.peopleWithEditAccess"));
	}, [dropDownMenu]);

	const updateFilter = useUpdateObject(setFilter);

	const handleDragStart = (e) => {
		const { active } = e;

		setDraggedId(active.id);
		if (!folderPermissions.find((permissionUser) => permissionUser.userId === active.id)) {
			// Adding
			setDropTargets(["folder-users"]);
		} else {
			// Removing
			setDropTargets(["custom-folder"]);
		}

		document.body.style.userSelect = "none";
	};

	const endDrag = () => {
		setDraggedId(null);
		setDropTargets([]);

		document.body.style.userSelect = null;
	};

	const handleDragEnd = (e) => {
		const { active, over } = e;

		if (over) {
			if (over.id === "custom-folder") {
				handleRemoveUser(active.id);
			} else {
				handleAddUser(undefined, active.id);
			}
		}

		endDrag();
	};

	const handleDragCancel = () => {
		endDrag();
	};

	const handleAddUser = (_e, userId) => {
		telemetryAddEvent(`Policy - Add Policy User`);
		handleUpdate(
			{
				target: {
					value: {
						userId,
						canView: true,
						canUpdate: dropDownMenu === 2 ? true : updateOnly,
					},
				},
			},
			"folderPermissions",
			false,
			false,
			undefined,
			(newValue, prevValue) =>
				!prevValue.find((prevUser) => prevUser.userId === newValue.userId) ? prevValue.concat([newValue]) : prevValue,
		);
	};

	const handleRemoveUser = (userId) => {
		telemetryAddEvent(`Policy - Remove Policy User`);
		handleUpdate(
			{
				target: {
					value: userId,
				},
			},
			"folderPermissions",
			false,
			false,
			undefined,
			(newValue, prevValue) => prevValue.filter((prevUser) => prevUser.userId !== newValue),
		);
	};

	const search = filter.search?.toLocaleLowerCase();
	const permissionUsers = users
		? folderPermissions
				.filter((permissionUser) => permissionUser.userId > 0)
				.map((permissionUser) => ({ user: users.find((user) => user.id === permissionUser.userId), permissionUser }))
				.filter(
					({ user, permissionUser }) =>
						!!user &&
						(!search || user.name.toLocaleLowerCase().includes(search) || user.emailAddress.toLocaleLowerCase().includes(search)) &&
						(!filter.editOnly || permissionUser.canUpdate),
				)
				.sort((a, b) => (a.user.name < b.user.name ? -1 : a.user.name > b.user.name ? 1 : 0))
		: [];

	const getAvailableUsers = (canDragAndDrop) => (
		<ol id="available-people" data-cy="user-list">
			{users
				.filter(
					(user) =>
						!user.inactive &&
						!user.deleted &&
						!folderPermissions.find((permissionUser) => permissionUser.userId === user.id) &&
						(!search || user.name.toLocaleLowerCase().includes(search)),
				)
				.map((user) =>
					canDragAndDrop ? (
						<Draggable
							key={user.id}
							dragId={user.id}
							dragComponent={FolderPermissionUser}
							dragAttributePassthrough
							canDrag
							dragPlaceholder={draggedId === user.id}
							user={user}
							actionIcon="add-circle"
							actionTooltip={t("tooltips.addUserToFolder")}
							handleAction={handleAddUser}
						></Draggable>
					) : (
						<FolderPermissionUser
							key={user.id}
							user={user}
							actionIcon="add-circle"
							actionTooltip={t("tooltips.addUserToFolder")}
							handleAction={handleAddUser}
						></FolderPermissionUser>
					),
				)}
		</ol>
	);

	const getUserLists = (canDragAndDrop) => (
		<>
			<div>
				<div className={classes.inputLabelSpacing}>
					<InputLabel htmlFor="available-people" size="large" label={t("customFolderDetail.availablePeople")} />
				</div>
				{users &&
					(canDragAndDrop ? (
						<Droppable
							className={classes.userList}
							dropId="custom-folder"
							useHighlightDroppable
							highlightDroppable={dropTargets.includes("custom-folder")}
							dropComponent="div"
						>
							{getAvailableUsers(canDragAndDrop)}
						</Droppable>
					) : (
						<div className={classes.userList}>{getAvailableUsers(canDragAndDrop)}</div>
					))}
			</div>
			{getSelectedUsers()}
		</>
	);

	const getSelectedUsers = () => {
		const dragAndDropProps = {
			enableDragAndDrop: !publicOrInternal && !mobile,
			highlightDroppable: !publicOrInternal && !mobile ? dropTargets.includes("folder-users") : undefined,
			dragPlaceholders: !publicOrInternal && !mobile ? [draggedId] : undefined,
		};

		return (
			<div>
				<div className={classes.inputLabelSpacing}>
					<InputLabel htmlFor="selected-people" size="large" label={label} />
				</div>
				<FolderPermissionUsers
					permissionUsers={permissionUsers}
					editEnabled={!updateOnly}
					handleUpdate={handleUpdate}
					handleRemoveUser={!publicOrInternal ? handleRemoveUser : undefined}
					classes={classes}
					{...dragAndDropProps}
					telemetryPage={telemetryPage}
				></FolderPermissionUsers>
			</div>
		);
	};

	return (
		<>
			<FolderPermissionsFilter filter={filter} updateFilter={updateFilter} updateLabelText={updateLabelText} updateOnly={updateOnly} />
			<div className={clsx({ [classes.columns]: !publicOrInternal })}>
				{!publicOrInternal ? (
					!mobile ? (
						<DndContext
							sensors={sensors}
							collisionDetection={getCollisionDetection(dragRef, rectIntersection)}
							onDragStart={handleDragStart}
							onDragEnd={handleDragEnd}
							onDragCancel={handleDragCancel}
						>
							{getUserLists(true)}
							{createPortal(
								<DragOverlay>
									<DragPresentation ref={dragRef}>
										{draggedId ? (
											<FolderPermissionUser dragPresentational user={users.find((user) => user.id === draggedId)}></FolderPermissionUser>
										) : null}
									</DragPresentation>
								</DragOverlay>,
								document.body,
							)}
						</DndContext>
					) : (
						getUserLists(false)
					)
				) : (
					getSelectedUsers()
				)}
			</div>
		</>
	);
};

export default React.memo(FolderPermissions);
