import React, { useState } from "react";

import clsx from "clsx";
import settingsStyle from "assets/jss/components/settingsStyle";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import makeStyles from "@mui/styles/makeStyles";

import { primaryColor } from "atlas/assets/jss/shared";
import { Box } from "@mui/material";
import Icon from "atlas/components/Icon/Icon";
import OutlinedInput from "atlas/components/FormControls/OutlinedInput";
import Accordion from "atlas/components/Accordion/Accordion";
import accordionStyle from "assets/jss/components/accordionStyle";
import { updateInitialSetting, setSettingError } from "redux/publicSite/actions";
import isEmail from "atlas/utils/isEmail";

const useStyles = makeStyles(settingsStyle);

const SocialMedia = (props) => {
	const { startOpen = false, error } = props;
	const classes = useStyles();
	const useAccordionStyles = makeStyles(accordionStyle);
	const accordionClasses = useAccordionStyles();
	const { t } = useTranslation("settings");
	const dispatch = useDispatch();
	const { facebookUrl, linkedInUrl, twitterUrl, youtubeUrl, instagramUrl, email, publicSiteErrors } = useSelector(
		(state) => state.publicSiteReducer,
	);

	const handleUpdate = (e, name) => {
		const {
			target: { value },
		} = e;
		dispatch(updateInitialSetting({ [name]: value }));
		validate(name, value);
	};

	const handleBlur = (e, field) => {
		const {
			target: { value },
		} = e;

		validate(field, value);
	};

	const validate = (field, value) => {
		let newErrors = {};
		let valid = true;

		if (field == "facebookUrl") {
			const regex = new RegExp("(?:(?:http|https)://)?(?:www.)?facebook.com/?");
			if (!regex.test(value) && value != "") {
				newErrors[field] = t("errorMessage.facebook");
				valid = false;
			} else {
				newErrors[field] = null;
				valid = true;
			}
		}
		if (field == "linkedInUrl") {
			const regex = new RegExp("(?:(?:http|https)://)?(?:www.)?linkedin.com/?");
			if (!regex.test(value) && value != "") {
				newErrors[field] = t("errorMessage.linkedin");
				valid = false;
			} else {
				newErrors[field] = null;
				valid = true;
			}
		}
		if (field == "twitterUrl") {
			const regex = new RegExp("(?:(?:http|https)://)?(?:www.)?twitter.com/?");
			if (!regex.test(value) && value != "") {
				newErrors[field] = t("errorMessage.twitter");
				valid = false;
			} else {
				newErrors[field] = null;
				valid = true;
			}
		}
		if (field == "youtubeUrl") {
			const regex = new RegExp("(?:(?:http|https)://)?(?:www.)?youtube.com/?");
			if (!regex.test(value) && value != "") {
				newErrors[field] = t("errorMessage.youtube");
				valid = false;
			} else {
				newErrors[field] = null;
				valid = true;
			}
		}
		if (field == "instagramUrl") {
			const regex = new RegExp("(?:(?:http|https)://)?(?:www.)?instagram.com/?");
			if (!regex.test(value) && value != "") {
				newErrors[field] = t("errorMessage.instagram");
				valid = false;
			} else {
				newErrors[field] = null;
				valid = true;
			}
		}
		if (field == "email") {
			if (!isEmail(value) && value != "") {
				newErrors[field] = t("errorMessage.email");
				valid = false;
			} else {
				newErrors[field] = null;
				valid = true;
			}
		}
		dispatch(setSettingError({ ...publicSiteErrors, ...newErrors }));
		return valid;
	};
	return (
		<>
			<div className={clsx(classes.section, classes.sectionAccordion)}>
				<Accordion
					classes={accordionClasses}
					className={classes.bold}
					label={t("titles.socialMedia")}
					overflow={false}
					startOpen={startOpen}
				>
					<div className={classes.mainCard}>
						<div className={classes.subCard}>
							<OutlinedInput
								id="fbUrl"
								className={clsx(classes.inputfield, classes.outlinedInput)}
								label={t("url.fbUrl")}
								onChange={(e) => handleUpdate(e, "facebookUrl")}
								helperText={publicSiteErrors.facebookUrl}
								error={!!publicSiteErrors.facebookUrl}
								onBlur={(e) => handleBlur(e, "facebookUrl")}
								value={facebookUrl || ""}
								noDefaultClassName
								size="small"
								inputProps={{ "aria-label": t("url.fbUrl") }}
							/>
							{facebookUrl ? (
								<Box className={classes.redirectLink}>
									<a href={`${facebookUrl}`} target="_blank" aria-label={t("url.fbUrlLink")}>
										<Icon name="external-link" size="24px" color={primaryColor[3]} data-cy="externalLink" />
									</a>
								</Box>
							) : null}
						</div>

						<div className={classes.subCard}>
							<OutlinedInput
								id="linkUrl"
								className={clsx(classes.inputfield, classes.outlinedInput)}
								label={t("url.linkUrl")}
								helperText={publicSiteErrors.linkedInUrl}
								error={!!publicSiteErrors.linkedInUrl}
								onBlur={(e) => handleBlur(e, "linkedInUrl")}
								value={linkedInUrl || ""}
								onChange={(e) => handleUpdate(e, "linkedInUrl")}
								noDefaultClassName
								size="small"
								inputProps={{ "aria-label": t("url.linkUrl") }}
							/>
							{linkedInUrl ? (
								<Box className={classes.redirectLink}>
									<a href={`${linkedInUrl}`} target="_blank" aria-label={t("url.linkUrlLink")}>
										<Icon name="external-link" size="24px" color={primaryColor[3]} data-cy="externalLink" />
									</a>
								</Box>
							) : null}
						</div>
						<div className={classes.subCard}>
							<OutlinedInput
								id="twitUrl"
								className={clsx(classes.inputfield, classes.outlinedInput)}
								label={t("url.twitUrl")}
								value={twitterUrl || ""}
								onChange={(e) => handleUpdate(e, "twitterUrl")}
								helperText={publicSiteErrors.twitterUrl}
								error={!!publicSiteErrors.twitterUrl}
								onBlur={(e) => handleBlur(e, "twitterUrl")}
								noDefaultClassName
								size="small"
								inputProps={{ "aria-label": t("url.twitUrl") }}
							/>
							{twitterUrl ? (
								<Box className={classes.redirectLink}>
									<a href={`${twitterUrl}`} target="_blank" aria-label={t("url.twitUrlLink")}>
										<Icon name="external-link" size="24px" color={primaryColor[3]} data-cy="externalLink" />
									</a>
								</Box>
							) : null}
						</div>
						<div className={classes.subCard}>
							<OutlinedInput
								id="youtubeUrl"
								className={clsx(classes.inputfield, classes.outlinedInput)}
								label={t("url.utubeUrl")}
								value={youtubeUrl || ""}
								onChange={(e) => handleUpdate(e, "youtubeUrl")}
								helperText={publicSiteErrors.youtubeUrl}
								error={!!publicSiteErrors.youtubeUrl}
								onBlur={(e) => handleBlur(e, "youtubeUrl")}
								noDefaultClassName
								size="small"
								inputProps={{ "aria-label": t("url.utubeUrl") }}
							/>
							{youtubeUrl ? (
								<Box className={classes.redirectLink}>
									<a href={`${youtubeUrl}`} target="_blank" aria-label={t("url.utubeUrlLink")}>
										<Icon name="external-link" size="24px" color={primaryColor[3]} data-cy="externalLink" />
									</a>
								</Box>
							) : null}
						</div>

						<div className={classes.subCard}>
							<OutlinedInput
								id="instaUrl"
								className={clsx(classes.inputfield, classes.outlinedInput)}
								label={t("url.instaUrl")}
								value={instagramUrl || ""}
								onChange={(e) => handleUpdate(e, "instagramUrl")}
								helperText={publicSiteErrors.instagramUrl}
								error={!!publicSiteErrors.instagramUrl}
								onBlur={(e) => handleBlur(e, "instagramUrl")}
								noDefaultClassName
								size="small"
								inputProps={{ "aria-label": t("url.instagramUrl") }}
							/>
							{instagramUrl ? (
								<Box className={classes.redirectLink}>
									<a href={`${instagramUrl}`} target="_blank" aria-label={t("url.instaUrlLink")}>
										<Icon name="external-link" size="24px" color={primaryColor[3]} data-cy="externalLink" />
									</a>
								</Box>
							) : null}
						</div>
						<div className={classes.subCard}>
							<OutlinedInput
								id="email"
								className={clsx(classes.inputfield, classes.outlinedInput)}
								label={t("url.email")}
								value={email || ""}
								onChange={(e) => handleUpdate(e, "email")}
								helperText={publicSiteErrors.email}
								error={!!publicSiteErrors.email}
								onBlur={(e) => handleBlur(e, "email")}
								noDefaultClassName
								size="small"
								inputProps={{ "aria-label": t("url.email") }}
							/>
							{error && (
								<h2 aria-label={error} style={{ color: "red", fontSize: "12px" }}>
									{error}
								</h2>
							)}
						</div>
					</div>
				</Accordion>
			</div>
		</>
	);
};
export default SocialMedia;
