import React from 'react';
import PropTypes from 'prop-types';

import { ListItemIcon, Badge } from '@mui/material';

import {
	TOP_START,
	TOP,
	TOP_END,
	RIGHT_START,
	RIGHT,
	RIGHT_END,
	BOTTOM_END,
	BOTTOM,
	BOTTOM_START,
	LEFT_END,
	LEFT,
	LEFT_START,
} from '../../utils/tooltipPlacement';
import Icon from '../Icon/Icon';
import Tooltip from '../Tooltip/Tooltip';

const LeftNavigationAccessibleIcon = (props) => {
	const { className, name, placement, tooltipText, badgeProps } = props;
	// aria-label={null} is to prevent this from being copied from the tooltip
	const listIcon = (
		<ListItemIcon className={className} aria-label={badgeProps ? badgeProps.content : null}>
			{badgeProps ? (
				<Badge
					badgeContent={badgeProps.content}
					color={badgeProps.color || 'default'}
					showZero={false}
					variant={badgeProps.content ? 'standard' : 'dot'}
					max={9}
					overlap="rectangular"
				>
					<Icon name={name} />
				</Badge>
			) : (
				<Icon name={name} />
			)}
		</ListItemIcon>
	);

	return tooltipText && tooltipText.length > 0 ? (
		<Tooltip placement={placement} title={tooltipText}>
			{listIcon}
		</Tooltip>
	) : (
		listIcon
	);
};

LeftNavigationAccessibleIcon.propTypes = {
	className: PropTypes.string,
	name: PropTypes.string.isRequired,
	placement: PropTypes.oneOf([
		TOP_START,
		TOP,
		TOP_END,
		RIGHT_START,
		RIGHT,
		RIGHT_END,
		BOTTOM_END,
		BOTTOM,
		BOTTOM_START,
		LEFT_END,
		LEFT,
		LEFT_START,
	]),
	tooltipText: PropTypes.string,
};

LeftNavigationAccessibleIcon.defaultProps = {
	className: undefined,
	placement: BOTTOM_START,
	tooltipText: undefined,
};

export default LeftNavigationAccessibleIcon;
