import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";

import { updateTabs } from "redux/app/actions";

import entityListStyle from "assets/jss/components/entityListStyle";
import CircularProgressIndicator from "atlas/components/Progress/CircularProgressIndicator";
import GoalDeleteDialog from "components/Dialogs/GoalDeleteDialog";
import GoalListCard from "./GoalListCard";
import { useSelector } from "react-redux";

const useEntityListStyles = makeStyles(entityListStyle);

const GoalList = (props) => {
	const {
		goals,
		more,
		moreClick,
		loading,
		moreLoading,
		goalFetchHappened,
		goalAdmin,
		telemetryPage,
		removeGoalFromList,
		dispatch,
		tab,
		tabChange,
		loadGoals,
	} = props;

	const { t } = useTranslation("goals");
	const [showGoalDeleteDialog, setShowGoalDeleteDialog] = useState(false);
	const [goalForDelete, setGoalForDelete] = useState(null);
	const listClasses = useEntityListStyles();

	const goalReducer = useSelector((state) => state.goalReducer);
	const { isSaving } = goalReducer;
	useEffect(() => {
		loadGoals();
	}, [isSaving]);

	useEffect(() => {
		let tabsOptions = [
			{ key: "All", label: "All", dataCy: "notSubmittedAgendaItems" },
			{
				key: "submitted",
				label: "Published",
				dataCy: "submittedAgendaItems",
			},
			{
				key: "pending",
				label: "Internal",

				dataCy: "pendingAgendaItems",
			},
			{
				key: "draft",
				label: "Draft",
				dataCy: "Draft",
			},
			{ key: "added", label: "Archived", dataCy: "addedAgendaItems" },
		];

		if (!goalAdmin) {
			tabsOptions = tabsOptions.filter((option) => option.key !== "draft");
		}

		dispatch(
			updateTabs({
				display: true,
				selectedTab: 0,
				scrollButtons: false ? "on" : "auto",
				tabsOptions: tabsOptions,
				onChange: (_e, newTab) => {
					dispatch(
						updateTabs({
							selectedTab: newTab,
						}),
					);

					tabChange(_e, newTab);
				},
			}),
		);
	}, []);

	const openGoalDeleteDialog = (obj) => {
		setShowGoalDeleteDialog(true);
		setGoalForDelete(obj);
	};

	const closeGoalDeleteDialog = () => {
		setShowGoalDeleteDialog(false);
	};

	const afterDeleteGoal = (obj) => {
		const { id } = obj;

		setShowGoalDeleteDialog(false);
		removeGoalFromList(id);
	};
	return (
		<>
			{showGoalDeleteDialog && (
				<GoalDeleteDialog
					show={showGoalDeleteDialog}
					afterDelete={afterDeleteGoal}
					onClose={closeGoalDeleteDialog}
					goal={goalForDelete}
					dispatch={dispatch}
				/>
			)}
			<div className="flex">
				<div className="flex grow">
					<Container maxWidth="xl" className="full-width">
						{goals && goals.length > 0 && (!loading || moreLoading) && !isSaving ? (
							<List className={listClasses.list}>
								{goals
									.map((goal) => (
										<GoalListCard
											key={`goal-${goal.id}`}
											goal={goal}
											goalAdmin={goalAdmin}
											openGoalDeleteDialog={openGoalDeleteDialog}
											showPublishStatus={goalAdmin}
											data-cy={`goalCard${goal.id}`}
											telemetryPage={telemetryPage}
											tab={tab}
											loadGoals={loadGoals}
										/>
									))
									.concat(
										more && !moreLoading ? (
											<li key="load-more">
												<Grid container justifyContent="center">
													<Button variant="contained" color="primary" onClick={() => moreClick()} data-cy="loadMore">
														{t("buttons.loadMore")}
													</Button>
												</Grid>
											</li>
										) : null,
									)
									.concat(moreLoading && <CircularProgressIndicator key="loading-more" data-cy="loadingMore" />)}
							</List>
						) : (
							<div style={{ margin: "24px" }}>
								{loading || isSaving ? (
									<Box mt={4}>
										<CircularProgressIndicator data-cy="loadingGoals" />
									</Box>
								) : (
									<>
										{goalFetchHappened && (
											<>
												<Box mt={4}>
													<Typography id="no-goal-title" variant="h3">
														{t("noGoals.title")}
													</Typography>
												</Box>
												<Box mt={3}>
													<Typography id="no-goal-description" variant="body2">
														{t("noGoals.description")}
													</Typography>
												</Box>
											</>
										)}
									</>
								)}
							</div>
						)}
					</Container>
				</div>
			</div>
		</>
	);
};

export default GoalList;
