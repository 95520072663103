/* eslint-disable react/jsx-no-bind */
import React, { Component } from "react";
import { withTranslation } from "react-i18next";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import Typography from "@mui/material/Typography";

import ComponentContainer from "atlas/components/ComponentContainer/ComponentContainer";
import Dropzone from "atlas/components/Dropzone/Dropzone";
import { useWidthUp } from "atlas/utils/useWidth";
import SaveCancelButtons from "./SaveCancelButtons";
import { processProfileImage } from "utils/image";

const withWidth = () => (WrappedComponent) => (props) => {
	const widthUpSm = useWidthUp("sm");

	return <WrappedComponent {...props} widthUpSm={widthUpSm} />;
};

class PictureUpload extends Component {
	render() {
		const { t, data, widthUpSm, handleChange, handleCancel, handleSave, handleRemove } = this.props;
		const { editing, errors } = data;
		// eslint-disable-next-line no-unused-vars
		const { profilePicture } = editing;

		const content = (
			<>
				<FormControl component="fieldset">
					<Typography variant="h5" color="textSecondary">
						{t("updatePhoto")}
					</Typography>
					<Box mt={2} data-cy="photoDropZone">
						<Dropzone onFileLoad={(file) => processProfileImage(file, handleChange)} color="textSecondary" className="drop-zone" />
					</Box>
					<Box mt={2}>
						<Typography variant="caption" color="textSecondary" className="user-profile-photo-label">
							{t("photo")}
						</Typography>
					</Box>
					{profilePicture ? (
						<>
							<img alt="profile" src={profilePicture} />
							<Button
								id="remove-profile-picture-button"
								onClick={() => handleRemove()}
								size="small"
								color="primary"
								variant="text"
								className="picture-remove-button"
								data-cy="removePhoto"
							>
								{t("buttons.photoRemove")}
							</Button>
						</>
					) : (
						t("noPictureUploaded")
					)}
				</FormControl>

				<SaveCancelButtons
					save={handleSave}
					saveEnabled={!errors.picture && !errors.picture}
					disabledTooltip={errors.picture ? t("errors.disabledTooltip") : ""}
					cancel={handleCancel}
					saveLabel={t("app:buttons.save")}
					cancelLabel={t("app:buttons.cancel")}
				/>
			</>
		);

		return <ComponentContainer maxWidth={widthUpSm ? "288px" : null}>{content}</ComponentContainer>;
	}
}

export default withWidth()(withTranslation("userProfile")(PictureUpload));
