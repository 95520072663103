export const ITEM_TYPES = {
	AGENDA_HEADING: 10,
	AGENDA_ITEM: 3,
	RECOMMENDATION: 7,
	MINUTES_HEADING: 11,
	MINUTES_ITEM: 4,
	MOTION: 8,
	MEETING_TEMPLATE_AGENDA_HEADING: 9,
	MEETING_TEMPLATE_AGENDA_ITEM: 6,
	MEETING_TEMPLATE_MINITES_HEADING: 20,
	MEETING_TEMPLATE_MINUTES_ITEM: 21,
};

export const HEADINGS = [
	ITEM_TYPES.AGENDA_HEADING,
	ITEM_TYPES.MINUTES_HEADING,
	ITEM_TYPES.MEETING_TEMPLATE_AGENDA_HEADING,
	ITEM_TYPES.MEETING_TEMPLATE_MINITES_HEADING,
];
export const MEETING_ITEMS = [
	ITEM_TYPES.AGENDA_ITEM,
	ITEM_TYPES.MINUTES_ITEM,
	ITEM_TYPES.MEETING_TEMPLATE_AGENDA_ITEM,
	ITEM_TYPES.MEETING_TEMPLATE_MINUTES_ITEM,
];
export const DECISIONS = [ITEM_TYPES.RECOMMENDATION, ITEM_TYPES.MOTION];
