import React from 'react';
import PropTypes from 'prop-types';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import outlinedInputStyle from '../../assets/jss/components/outlinedInputStyle';

const useOutlinedInputStyles = makeStyles(outlinedInputStyle);

const OutlinedSelect = (props) => {
	const {
		id,
		label,
		className,
		value,
		onChange,
		options,
		placeholder,
		fieldToUpdate,
		objectToUpdate,
		fullWidth,
		size,
		compactSpacing,
		disabled,
		persistOnChange,
	} = props;

	const classes = useOutlinedInputStyles();

	const handleOnChange = (event) => {
		const selectedOption = options.find((o) => o.value === event.target.value);
		if (selectedOption.onClick) {
			selectedOption.onClick();
		} else {
			onChange(event.target.value, objectToUpdate, fieldToUpdate, persistOnChange);
		}
	};

	const classNames = [compactSpacing ? classes.compactContainer : size === 'small' ? classes.smallContainer : classes.container];

	if (className) {
		classNames.push(className);
	}

	return (
		<FormControl variant="outlined" fullWidth={fullWidth} className={classNames.join(' ')}>
			{label && <InputLabel htmlFor={`outlined-select-input-${id}`}>{label}</InputLabel>}
			<Select
				value={value}
				onChange={handleOnChange}
				label={label}
				displayEmpty={placeholder !== undefined}
				disabled={disabled}
				inputProps={{
					name: id,
					id: `outlined-select-input-${id}`,
				}}
				MenuProps={{
					anchorOrigin: {
						vertical: 'bottom',
						horizontal: 'left',
					},
					transformOrigin: {
						vertical: 'top',
						horizontal: 'left',
					},
					getContentAnchorEl: null,
				}}
			>
				{placeholder && (
					<MenuItem disabled value="">
						{placeholder}
					</MenuItem>
				)}
				{options.map((option) => (
					<MenuItem key={`select-item-${option.label}`} value={option.value} data-cy={`select-item-${option.value}`}>
						{option.label}
					</MenuItem>
				))}
			</Select>
		</FormControl>
	);
};

OutlinedSelect.propTypes = {
	id: PropTypes.string.isRequired,
	label: PropTypes.string,
	className: PropTypes.string,
	value: PropTypes.string.isRequired,
	onChange: PropTypes.func,
	options: PropTypes.array.isRequired,
	placeholder: PropTypes.string,
	fieldToUpdate: PropTypes.string,
	objectToUpdate: PropTypes.object,
	fullWidth: PropTypes.bool,
	size: PropTypes.string,
	compactSpacing: PropTypes.bool,
	persistOnChange: PropTypes.bool,
};

OutlinedSelect.defaultProps = {
	fullWidth: false,
	size: 'medium',
	compactSpacing: false,
	placeholder: '',
	className: '',
	objectToUpdate: undefined,
	fieldToUpdate: undefined,
	onChange: undefined,
	label: undefined,
	persistOnChange: false,
};

export default OutlinedSelect;
