export const RESET_PAGE_CONFIG = "RESET_PAGE_CONFIG";
export const UPDATE_PAGE_CONFIG = "UPDATE_PAGE_CONFIG";
export const UPDATE_APPBAR_TOOLS = "UPDATE_APPBAR_TOOLS";
export const UPDATE_PAGE_HEADER = "UPDATE_PAGE_HEADER";
export const UPDATE_PAGE_TABS = "UPDATE_PAGE_TABS";
export const UPDATE_PAGE_TOOLBAR = "UPDATE_PAGE_TOOLBAR";
export const UPDATE_SIZES = "UPDATE_SIZES";
export const UPDATE_LEFT_NAV = "UPDATE_LEFT_NAV";
export const UPDATE_NOTICE = "UPDATE_NOTICE";
export const UPDATE_BOTTOM_NOTICE = "UPDATE_BOTTOM_NOTICE";
export const UPDATE_SIGNALR = "UPDATE_SIGNALR";
export const UPDATE_CURRENT_TIMESTAMP_ITEM = "UPDATE_CURRENT_TIMESTAMP_ITEM";
export const UPDATE_SIGN_IN_DIALOG = "UPDATE_SIGN_IN_DIALOG";
export const UPDATE_PENDING_APPROVALS_COUNT = "UPDATE_PENDING_APPROVALS_COUNT";
export const UPDATE_REQUESTS_TO_SPEAK_COUNT = "UPDATE_REQUESTS_TO_SPEAK_COUNT";
export const POLL_LIVE_DATA = "POLL_LIVE_DATA";
export const SET_PROGRESS_IDS = "SET_PROGRESS_IDS";
