import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import clsx from "clsx";

import makeStyles from "@mui/styles/makeStyles";
import { Box } from "@mui/material";
import { useWidthDown } from "atlas/utils/useWidth";
import TableOfContents from "components/Meeting/TableOfContents";
import RightPanelContainer, { MD_DOWN_WIDTH } from "components/Panels/RightPanelContainer";
import { setActive } from "redux/meetingTemplate/actions";
import MeetingTemplateMinutesOptionsPanel from "./components/MeetingTemplateMinutesOptionsPanel";
import MinutesEditor from "./components/MinutesEditor";
import TableOfContentsContainer from "./components/TableOfContentsContainer";

const useStyles = makeStyles(() => ({
	tableOfContentContainer: {
		overflow: "auto",
		maxWidth: "265px",
		minWidth: "265px",
		height: "calc(100vh - 212px)",
		position: "fixed",
		borderRight: "1px solid #E6E6E6",
		paddingBottom: "8px",
	},
	stickyToolbar: {
		position: "-webkit-sticky",
		position: "sticky",
		top: 0,
		zIndex: 150,
		marginLeft: (props) => (props.showOutline ? "265px" : "0"),
		width: (props) => (props.showOutline ? "calc(100% - 265px)" : "100%"),
		minWidth: "200px",
	},
	inactiveToolbar: {
		display: "none",
	},
}));

const telemetryPage = "Meeting Template Minutes";

const MeetingTemplateMinutes = (props) => {
	const { showOptions, boards, handleClose } = props;
	const { t } = useTranslation("meetings");
	const widthDownMd = useWidthDown("md");
	const [showToolbar, setShowToolbar] = useState(false);
	const editorToolbarRef = useRef(null);
	const { meetingTemplate, minutes: { items } = {} } = useSelector((state) => state.meetingTemplatesReducer);
	const classes = useStyles({ showOutline: !widthDownMd });

	return (
		<>
			<TableOfContentsContainer containerClass={classes.tableOfContentContainer} innerId="outline">
				<TableOfContents items={items} isClosed={meetingTemplate.membersOnly} buttons setActive={setActive} telemetryPage={telemetryPage} />
			</TableOfContentsContainer>
			<Box>
				<Box
					id="toolbar"
					className={clsx(classes.stickyToolbar, {
						[classes.inactiveToolbar]: !showToolbar,
					})}
					ref={editorToolbarRef}
				/>
				<MinutesEditor editorToolbarRef={editorToolbarRef} setShowToolbar={setShowToolbar} telemetryPage={telemetryPage} />
			</Box>
			{showOptions && <MeetingTemplateMinutesOptionsPanel boards={boards} handleClose={handleClose} telemetryPage={telemetryPage} />}
		</>
	);
};

export default MeetingTemplateMinutes;
