/* eslint-disable no-param-reassign */
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { setAutoFreeze } from "immer";
import { withRouter } from "utils/router";

import Typography from "@mui/material/Typography";

import clone from "lodash/fp/clone";

import { useWidthUp, useWidthDown } from "atlas/utils/useWidth";
import { updateAgendaScratchpad } from "redux/agendaBuilder/actions";
import { updatePageConfigs } from "redux/app/actions";
import withErrorHandling from "components/ErrorHOC";
import { EditorFunctionsContext } from "contexts/EditorFunctions/EditorFunctionsContext";
import AddPolicyToAgendaDialog from "components/Dialogs/AddPolicyToAgendaDialog";
import AddGoalsToAgendaDialog from "components/Dialogs/AddGoalsToAgendaDialog";
import { SettingsContext } from "contexts/Settings/SettingsContext";
import scrollToElement from "utils/scrollToElement";
import editorFunctions from "./functions";

import AgendaEditorV2 from "./components/AgendaEditorV2";
import ScratchPad from "./components/ScratchPad";
import ScratchpadContainer from "./components/ScratchpadContainer";
import { connect } from "react-redux";
import telemetryAddEvent from "utils/telemetryAddEvent";

const withWidth = () => (WrappedComponent) => (props) => {
	const widthUpMd = useWidthUp("md");
	const widthUpLg = useWidthUp("lg");
	const widthDownMd = useWidthDown("md");

	return <WrappedComponent {...props} widthUpMd={widthUpMd} widthUpLg={widthUpLg} widthDownMd={widthDownMd} />;
};

class MeetingEditor extends Component {
	constructor(props) {
		super(props);

		// we get initial load from the Container, then maintain in state;
		this.state = {
			...props,
			active: {
				selected: "toc-header",
			},
			showTableOfContent: props.widthUpMd,
			showScratchpad: false,
			showNotes: true,
			dialogs: {},
		};

		// non-state variables - supporting operations that update the agenda data, but are not needed for rendering (and do not require a re-render when updated).
		this.undoStack = [];
		this.redoStack = [];
		this.undoPointer = -1;
		this.fileQueue = [];
		this.isDirty = false;
		this.itemIdsToUpdate = [];
		this.itemIdsToDelete = [];
		this.cancelUpdateAgenda = false;
		this.agendaHeaderNeedsUpdate = false;
		this.agendaFooterNeedsUpdate = false;
		this.agendaScratchpadNeedsUpdate = false;
		this.invalidClearActiveTargets = [
			"ck",
			"heading-container",
			"member-only-heading-container",
			"subheading-container",
			"agenda-item-container",
			"recommendation-container",
			"heading-recommendation-container",
			"description-container",
			"heading-text-container",
			"subheading-text-container",
			"agenda-item-text-container",
			"agenda-header",
			"agenda-footer",
			"MuiButtonBase-root",
		];

		// local functions - New Test Comment
		this.openItem = this.openItem.bind(this);
		this.handleAgendaChange = this.handleAgendaChange.bind(this);
		this.toggleTableOfContentDrawer = this.toggleTableOfContentDrawer.bind(this);
		this.toggleScratchpadDrawer = this.toggleScratchpadDrawer.bind(this);
		this.toggleBoardNotes = this.toggleBoardNotes.bind(this);
		this.saveAgenda = this.saveAgenda.bind(this);
		this.checkCancelUpdateAgenda = this.checkCancelUpdateAgenda.bind(this);

		// Setting up an object of bound functions, imported from ./functions, to share via Context,
		// this allows these functions to do a this.setState on MeetingEditor's copy of the Agenda JSON
		this.editorFunctions = clone(editorFunctions);

		this.editorFunctions.container = props.container;

		for (const key in editorFunctions) {
			if (this.editorFunctions.hasOwnProperty(key)) {
				this.editorFunctions[key] = this.editorFunctions[key].bind(this);
			}
		}

		// Also tying in any functions that setState in MeetingEditor, but needs to be called from AgendaTopBar.
		this.editorFunctions.toggleScratchpadDrawer = this.toggleScratchpadDrawer;
		this.editorFunctions.toggleBoardNotes = this.toggleBoardNotes;
		this.editorFunctions.toggleTableOfContentDrawer = this.toggleTableOfContentDrawer;
		this.editorFunctions.escapeEditor = this.escapeEditor;

		this.props.linkTopBarToEditorFunctions(this.editorFunctions);

		this.saveTimer = setInterval(this.saveAgenda, 5000);

		setAutoFreeze(false); // prevents immer from freezing the state when making updates
	}

	componentDidMount() {
		document.addEventListener("keydown", this.handleKeyDown);
		window.addEventListener("beforeunload", this.saveBeforeClosing);
	}

	shouldComponentUpdate() {
		if (this.cancelRender) {
			this.cancelRender = false;
			return false;
		}
		return true;
	}

	componentDidUpdate(_prevProps, prevState) {
		if (prevState.active.selected !== this.state.active.selected) {
			this.scrollToActiveElement();
		}
	}

	componentWillUnmount() {
		clearInterval(this.saveTimer);
		document.removeEventListener("keydown", this.handleKeyDown);
		window.removeEventListener("beforeunload", this.saveBeforeClosing);
		this.saveAgenda();
	}

	checkCancelUpdateAgenda() {
		if (this.cancelUpdateAgenda) {
			this.cancelUpdateAgenda = false;
			return true;
		}
		return false;
	}

	saveBeforeClosing = (event) => {
		// best attempt to save all content is saved even if the browser is closed
		// it is not 100% reliable for CKE updates, due to the 500ms debounce on CKE, but works for other agenda updates
		if (this.isDirty) {
			this.saveAgenda();
			event.preventDefault();
			event.returnValue = "";
		}
	};

	closeFileUploadFailureDialog = () => this.setState({ failedFileUploads: null });

	handleKeyDown = (e) => {
		if (e.metaKey) {
			if (e.code === "KeyZ" || e.key === "z") {
				this.editorFunctions.undo();
				e.stopPropagation();
				return false;
			}
		}
		if (e.shiftKey && e.metaKey) {
			if (e.code === "KeyZ" || e.key === "z") {
				this.editorFunctions.redo();
				e.stopPropagation();
				return false;
			}
		}

		if (e.ctrlKey) {
			if (e.code === "KeyZ" || e.key === "z") {
				this.editorFunctions.undo();
				e.stopPropagation();
				return false;
			}
			if (e.code === "KeyY" || e.key === "y") {
				this.editorFunctions.redo();
				e.stopPropagation();
				return false;
			}
		}
		return true;
	};

	clearActive = (e) => {
		let clear = true;
		let { target } = e;
		// Check that the target and it's parents do not contain invalid values in their classes
		while (target && target.classList && clear) {
			// eslint-disable-next-line no-loop-func
			clear = !this.invalidClearActiveTargets.find((invalidTarget) => target.classList.contains(invalidTarget));
			target = target.parentNode;
		}
		if (clear) {
			// click outside of any agenda elements
			this.setState({
				active: {
					selected: null,
					text: false,
					boardNotes: false,
				},
			});
		}
	};

	escapeEditor = () => {
		// when hitting Esc from an editor, close the editor and select its containing section/subcsection/item
		// TODO - verify this works/looks as expected once we re-do the UI on the agenda - until then, there is no visual indicator that anything is selected
		let nextElement = document.activeElement.closest("li"); // all agenda item types
		if (!nextElement) nextElement = document.activeElement.closest(".with-content"); // fallback to header/footer
		this.setState(
			{
				active: {
					selected: null,
					text: false,
					boardNotes: false,
				},
			},
			() => {
				if (nextElement) {
					nextElement.focus();
				}
			},
		);
	};

	clearScratchpadActive = (e) => {
		if (e.target.classList.contains("scratchpad-drawer") || e.target.classList.contains("agenda-editor-content")) {
			// click outside of any agenda elements
			this.setState({
				active: {
					selected: null,
					text: false,
					boardNotes: false,
				},
			});
		}
	};

	toggleScratchpadDrawer() {
		const { widthUpLg } = this.props;
		const { showTableOfContent, showScratchpad } = this.state;

		this.setState({
			showTableOfContent: widthUpLg ? showTableOfContent : false,
			showScratchpad: !showScratchpad,
		});
	}

	toggleBoardNotes() {
		this.setState((prev) => ({
			showNotes: !prev.showNotes,
		}));
	}

	toggleTableOfContentDrawer() {
		const { widthUpLg } = this.props;
		const { showTableOfContent, showScratchpad } = this.state;

		this.setState({
			showTableOfContent: !showTableOfContent,
			showScratchpad: widthUpLg ? showScratchpad : false,
		});
	}

	openItem(guid) {
		const { navigate, dispatch } = this.props;

		dispatch(
			updatePageConfigs({
				preferedBack: { url: window.location.pathname.replace("/home/", "/") + window.location.search },
			}),
		);

		navigate(`/agendaitems/edit/${guid}`);
	}

	handleAgendaChange(_event, editor) {
		const { dispatch } = this.props;
		const newContent = editor.getData();

		dispatch(updateAgendaScratchpad(newContent));
	}

	saveAgenda(callback) {
		const { saveAgenda } = this.editorFunctions;

		saveAgenda(callback);
	}

	scrollToActiveElement = () => {
		const { active } = this.state;
		const elem = document.getElementById(`agenda-${active.selected}`);
		if (elem) {
			scrollToElement(elem, true, document.getElementById("content-scroll"));
		}
		const tocElement = document.getElementById(`outline-agenda-${active.selected}`);
		if (tocElement) {
			scrollToElement(tocElement, true, document.getElementById("outline").firstElementChild);
		}
	};

	openAddPolicy = (options) => {
		telemetryAddEvent(`Policy - Agenda Builder - Add Policy Clicked`);
		this.setState({
			dialogs: {
				addPolicy: options,
			},
		});
	};

	openAddGoals = (options) => {
		telemetryAddEvent(`Goals - Agenda Builder - Add Goals Clicked`);
		this.setState({
			dialogs: {
				addGoals: options,
			},
		});
	};

	closeDialogs = () => {
		this.setState({ dialogs: {} });
	};

	render() {
		const { id, widthUpMd, widthUpLg, updateMeeting, queueFileUploads, showSignIn } = this.props;
		const { requestedItemGuid, items, active, showTableOfContent, showScratchpad, showNotes, requestError, dialogs } = this.state;
		const { policyEnabled } = this.context;

		if (requestError) {
			const errormsg =
				requestError.response.body && requestError.response.body.Message
					? requestError.response.body.Message
					: `${requestError.status} ${requestError.message}`;
			return <Typography variant="h3">{errormsg}</Typography>;
		}

		if (!items) return null;

		const showOutline = showTableOfContent || widthUpLg;

		const {
			agenda: { agendaScratchpad },
		} = this.props.agendaBuilder;

		const meetingId = parseInt(id, 10);

		return (
			<EditorFunctionsContext.Provider value={this.editorFunctions}>
				{dialogs.addPolicy && (
					<AddPolicyToAgendaDialog meetingId={meetingId} options={dialogs.addPolicy} onClose={this.closeDialogs} showSignIn={showSignIn} />
				)}
				{dialogs.addGoals && (
					<AddGoalsToAgendaDialog meetingId={meetingId} options={dialogs.addGoals} onClose={this.closeDialogs} showSignIn={showSignIn} />
				)}
				<AgendaEditorV2
					isOutlineOpenAndPermanent={showOutline && widthUpMd}
					isScratchPadOpenAndPermanent={showScratchpad && widthUpLg}
					showNotes={showNotes}
					requestedItemGuid={requestedItemGuid}
					meetingId={meetingId}
					updateMeeting={updateMeeting}
					queueFileUploads={queueFileUploads}
					addPolicy={policyEnabled ? this.openAddPolicy : undefined}
					addGoals={this.openAddGoals}
				/>
				<ScratchpadContainer showScratchpad={showScratchpad} toggleScratchpadDrawer={this.toggleScratchpadDrawer}>
					<ScratchPad
						active={active}
						scratchPadContent={agendaScratchpad}
						toggleScratchpadDrawer={this.toggleScratchpadDrawer}
						handleChange={this.handleAgendaChange}
						onClickAction={this.clearScratchpadActive}
						showScratchpad={showScratchpad}
					/>
				</ScratchpadContainer>
			</EditorFunctionsContext.Provider>
		);
	}
}
MeetingEditor.contextType = SettingsContext;
const mapStateToProps = (state) => ({ agendaBuilder: state.agendaBuilderReducer });

export default withRouter(
	withTranslation(["meetings", "agendaMenu"])(withWidth()(connect(mapStateToProps)(withErrorHandling(MeetingEditor)))),
);
