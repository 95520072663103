import { useRef, useEffect } from "react";
import request from "superagent";

import { API_HOST } from "config/env";

const RESULT_CHECK_SHORT_INTERVAL = 500;
const RESULT_CHECK_INTERVAL = 5000;

export const useAsyncResult = (handleSuccess, handleError, interval = RESULT_CHECK_INTERVAL) => {
	const ref = useRef({});

	const checkCompletion = () => {
		request
			.get(`${API_HOST}/api/system/result/${ref.current.resultId}`)
			.withCredentials()
			.then((res) => {
				if (res.status === 200) {
					if (res.body.pending) {
						ref.current.timeout = setTimeout(checkCompletion, interval);
					} else {
						ref.current = {};
						handleSuccess(res);
					}
				}
			})
			.catch((err) => {
				handleError(err);
			});
	};

	const startResultCheck = (res) => {
		ref.current = {
			resultId: res.body || res.text,
			timeout: setTimeout(checkCompletion, interval),
		};

		return ref.current.resultId;
	};

	const resultCheckNow = () => {
		cancelResultCheck();

		ref.current.timeout = setTimeout(checkCompletion, RESULT_CHECK_SHORT_INTERVAL);
	};

	const cancelResultCheck = () => {
		clearTimeout(ref.current.timeout);
	};

	useEffect(() => {
		return () => {
			cancelResultCheck();
		};
	}, []);

	return [startResultCheck, resultCheckNow];
};
