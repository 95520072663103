import React from "react";

import makeStyles from "@mui/styles/makeStyles";

import CircularProgressIndicator from "atlas/components/Progress/CircularProgressIndicator";
import DocumentTreeItem from "./DocumentTreeItem";

const useStyles = makeStyles(() => ({
	list: {
		listStyleType: "none",
		margin: "0",
		padding: "0",
	},
}));

const DocumentTree = (props) => {
	const { documents, parent, columns, label, type, inPortal, depth, handleEvent, anchor, setAnchor, telemetryPage } = props;
	const classes = useStyles();

	const documentList = documents?.[parent?.guid || "root"];

	const childRendered = (childElement) => {
		// Focus on the first child when the parent is expanded
		if (parent?.expanded && parent?.focusChild && childElement) {
			childElement.focus({ focusVisible: parent.focusKeyboard });
			parent.focusChild = false;
			parent.focusKeyboard = false;
		}
	};

	return documentList ? (
		<ul className={classes.list} role={!parent ? "tree" : "group"} aria-label={label} data-cy="document-list">
			{documentList.map((document, index) => (
				<DocumentTreeItem
					key={document.guid}
					documents={documents}
					document={document}
					columns={columns}
					type={type}
					inPortal={inPortal}
					depth={depth}
					handleEvent={handleEvent}
					anchor={anchor}
					setAnchor={setAnchor}
					telemetryPage={telemetryPage}
					childRendered={index === 0 ? childRendered : undefined}
				/>
			))}
		</ul>
	) : (
		<CircularProgressIndicator />
	);
};

export default DocumentTree;
