import { updatePageConfigs, updateBottomNotice } from "../../../redux/app/actions";
import cookie from "react-cookies";
import { scrollToElement2 as scrollToElement } from "utils/scrollToElement";

export const LIVE_MEETING_DATA_TYPE = "live-meeting";

const trySelectMembersActiveItem = (guid) => {
	if (guid) {
		// Highlight the selected item if we are on the right page
		const outlineItem = document.querySelector(`li#outline-${guid}`);
		if (outlineItem) {
			outlineItem.click();

			return true;
		}
	}

	return false;
};

export const selectMembersActiveItem = ({ activeGuid, activeMinutesGuid }) =>
	trySelectMembersActiveItem(activeGuid) || trySelectMembersActiveItem(activeMinutesGuid);

export const selectAdministratorsActiveItem = ({ activeGuid, activeMinutesGuid }) => {
	if (activeMinutesGuid) {
		const activeElement =
			document.querySelector(`[data-cy="minutes-${activeMinutesGuid}"]`) ||
			document.querySelector(`[data-cy="agenda-${activeMinutesGuid}"]`);
		if (activeElement) {
			scrollToElement(activeElement, undefined, -50);

			return true;
		}
	}

	if (activeGuid) {
		const activeElement = document.querySelector(`[data-cy="agenda-${activeGuid}"]`);
		if (activeElement) {
			scrollToElement(activeElement, undefined, -50);

			return true;
		}
	}

	return false;
};

const isLiveMeetingAdminPage = (location, meetingId) => {
	let pathname = "";
	if (location) {
		if (typeof location === "string") {
			pathname = location;
		} else if (typeof location.pathname === "string") {
			({ pathname } = location);
		}
	}

	return pathname.endsWith(`/meeting/liveV2/${meetingId}`) || pathname.endsWith(`/meeting/live/${meetingId}`);
};

const isMeetingDocumentPage = (location, meetingId) => {
	let pathname = "";
	if (location) {
		if (typeof location === "string") {
			pathname = location;
		} else if (typeof location.pathname === "string") {
			({ pathname } = location);
		}
	}

	return pathname.endsWith(`/meeting/document/${meetingId}`);
};

const liveMeetingBottomNotice = (props) => {
	const { dispatch, t, navigate, location, liveMeeting } = props;
	const { meetingId, meetingName, activeGuid, activeMinutesGuid, itemName, bullet, administrator, member } = liveMeeting;
	const liveMeetingAdminPage = isLiveMeetingAdminPage(location, meetingId);
	const meetingDocumentPage = isMeetingDocumentPage(location, meetingId);

	const selectActiveItem = () => {
		// Highlight the selected item if we are on a live meeting page
		if ((member || administrator) && isMeetingDocumentPage(window.location, meetingId) && selectMembersActiveItem({ activeGuid })) {
			return true;
		}

		if (administrator && isLiveMeetingAdminPage(window.location, meetingId)) {
			selectAdministratorsActiveItem({ activeGuid, activeMinutesGuid });
			return true;
		}

		return false;
	};

	return {
		show: (data, persistentData) => {
			// Check if this meeting has had the close button clicked
			const { meetingId: id = 0 } = data;
			const { hiddenMeetings = [] } = persistentData;

			return !hiddenMeetings.find((meeting) => meeting === id);
		},
		icon: "arrow-right",
		label:
			// eslint-disable-next-line no-nested-ternary
			administrator && !meetingDocumentPage
				? t(`meetings:liveMeeting.${liveMeetingAdminPage ? "meeting" : "backToMeeting"}`, {
						meetingName,
						bullet,
				  })
				: itemName,
		preText: t(`meetings:liveMeeting.${administrator && !meetingDocumentPage ? "liveMeetingInProgress" : "currentTopic"}`),
		action: () => {
			if (selectActiveItem()) {
				return;
			}

			// Redirect to the live meeting page - set the back button first
			dispatch(
				updatePageConfigs({
					preferedBack: { url: window.location.pathname.replace("/home/", "/") + window.location.search },
				}),
			);

			navigate(
				administrator
					? `/meeting/${(cookie.load("old_minutes") || "false") === "false" ? "liveV2" : "live"}/${meetingId}`
					: `${!member ? "/public" : ""}/meeting/document/${meetingId}`,
			);
		},
		actionAfterNavigate: selectActiveItem,
		canClose: true,
		onClose: (e) => {
			e.stopPropagation();
			e.preventDefault();

			dispatch(
				updateBottomNotice({
					update: true,
					persistentData: {
						fields: [
							{
								name: "hiddenMeetings",
								action: "push",
								value: meetingId,
							},
						],
					},
				}),
			);
		},
		data: {
			...liveMeeting,
			dataType: LIVE_MEETING_DATA_TYPE,
		},
	};
};

export default liveMeetingBottomNotice;
