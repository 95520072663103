import React from "react";
import SvgIcon from "@mui/material/SvgIcon";

import themeConfig from "../../atlas/assets/jss/theme";
import getOr from "lodash/fp/getOr";

const icon = (props) => {
	const { color } = props;
	const fillColor = color ? getOr(color, ["palette", color, "main"], themeConfig) : "currentColor";

	return (
		<SvgIcon {...props}>
			<defs>
				<path
					d="M2.59767939,12.6121099 L2.59767939,21.083403 C2.59767939,21.5896657 3.00808639,22.0000727 3.51434909,22.0000727 L8.61864246,22.0000727 C9.12490515,22.0000727 9.53531216,21.5896657 9.53531216,21.083403 L9.53531216,16.0891336 L12.4659799,16.0891336 L12.4659799,21.083403 C12.4659799,21.5896657 12.8763869,22.0000727 13.3826496,22.0000727 L18.486943,22.0000727 C18.9932057,22.0000727 19.4036127,21.5896657 19.4036127,21.083403 L19.4036127,12.6121106 L20.9989359,12.6121106 C21.4107552,12.612966 21.7793623,12.3596211 21.9302392,11.9786384 C22.0784413,11.6044102 21.9893158,11.1764085 21.7013638,10.8928181 L11.6664902,0.286642019 C11.304855,-0.0955517836 10.6964199,-0.0955466449 10.3347912,0.286653266 L0.277631847,10.9159125 C0.0123165122,11.1764085 -0.0768089048,11.6044102 0.0713931227,11.9786384 C0.222270046,12.3596211 0.590877144,12.612966 1.00459605,12.6121087 L2.59767939,12.6121099 Z M11.0006314,2.25037866 L19.0702727,10.7787717 L18.486943,10.7787712 C17.9806803,10.7787712 17.5702733,11.1891782 17.5702733,11.6954409 L17.5702733,20.1667333 L14.2993193,20.1667333 L14.2993193,15.1724639 C14.2993193,14.6662012 13.8889123,14.2557942 13.3826496,14.2557942 L8.61864246,14.2557942 C8.11237977,14.2557942 7.70197277,14.6662012 7.70197277,15.1724639 L7.70197277,20.1667333 L4.43101878,20.1667333 L4.43101878,11.6954409 C4.43101878,11.1891782 4.02061178,10.7787712 3.51434909,10.7787712 L2.93063475,10.7787712 L11.0006314,2.25037866 Z"
					id="current-meetings-path-1"
				/>
			</defs>
			<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g>
					<g transform="translate(1.000000, 1.000000)">
						<mask id="mask-2" fill="white">
							<use xlinkHref="#current-meetings-path-1" />
						</mask>
						<use fill={fillColor} fillRule="nonzero" xlinkHref="#current-meetings-path-1" />
						<g mask="url(#mask-2)" fill="#000000">
							<g transform="translate(-1.000000, -1.000000)">
								<rect x="0" y="0" width="24" height="24" />
							</g>
						</g>
					</g>
				</g>
			</g>
		</SvgIcon>
	);
};

export default icon;
