export const GET_SETTINGS_PENDING = "GET_SETTINGS_PENDING";
export const GET_SETTINGS_FULFILLED = "GET_SETTINGS_FULFILLED";
export const UPDATE_SETTINGS = "UPDATE_SETTINGS";
export const SAVE_SETTINGS_PENDING = "SAVE_SETTINGS_PENDING";
export const SAVE_SETTINGS_FULFILLED = "SAVE_SETTINGS_FULFILLED";
export const VALIDATE_BOXCAST_PENDING = "VALIDATE_BOXCAST_PENDING";
export const VALIDATE_BOXCAST_FULFILLED = "VALIDATE_BOXCAST_FULFILLED";
export const VALIDATE_YOUTUBE_PENDING = "VALIDATE_YOUTUBE_PENDING";
export const VALIDATE_YOUTUBE_FULFILLED = "VALIDATE_YOUTUBE_FULFILLED";
export const ADD_YOUTUBE_PENDING = "ADD_YOUTUBE_PENDING";
export const ADD_YOUTUBE_FULFILLED = "ADD_YOUTUBE_FULFILLED";
export const SENT_EMAIL_FULFILLED = "SENT_EMAIL_FULFILLED";
export const SENT_EMAIL_PENDING = "SENT_EMAIL_PENDING";
export const SET_GENERAL_SETTING_ERROR = "SET_GENERAL_SETTING_ERROR";
export const UPLOAD_LETTERHEAD_PENDING = "UPLOAD_LETTERHEAD_PENDING";
export const UPLOAD_LETTERHEAD_FULFILLED = "UPLOAD_LETTERHEAD_FULFILLED";
export const DELETE_LETTERHEAD_PENDING = "DELETE_LETTERHEAD_PENDING";
export const DELETE_LETTERHEAD_FULFILLED = "DELETE_LETTERHEAD_FULFILLED";
