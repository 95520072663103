import React from "react";
import { useTranslation } from "react-i18next";

import makeStyles from "@mui/styles/makeStyles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import processHtml, { stripHtml } from "utils/processHtml";
import Icon from "atlas/components/Icon/Icon";
import GenericDialog from "atlas/components/Dialogs/GenericDialog";

const useStyles = makeStyles(() => ({
	dialog: {
		"& .MuiDialog-paper": {
			width: "320px",
			maxWidth: "100%",
		},
	},
}));

const MotionDeleteDialog = (props) => {
	const { show, motion, deleteMotion, onClose } = props;
	const { t } = useTranslation("meetings");
	const classes = useStyles();

	const handleDelete = () => {
		deleteMotion(motion);
		onClose();
	};

	const handleCancel = () => {
		onClose();
	};

	const i18n = t("deleteMotionDialog");

	const dialog = {
		title: i18n.title,
		line1: i18n.line1,
		line2: processHtml(stripHtml(motion.fields.Name.Value)),
		primaryTitle: i18n.buttons.delete,
		primaryAction: handleDelete,
		secondaryTitle: i18n.buttons.cancel,
		secondaryAction: handleCancel,
	};

	return (
		<GenericDialog
			className={classes.dialog}
			show={show}
			title={dialog.title}
			primaryAction={dialog.primaryAction}
			primaryTitle={dialog.primaryTitle}
			secondaryAction={dialog.secondaryAction}
			secondaryTitle={dialog.secondaryTitle}
			closeIcon={<Icon name="close" />}
			data-cy="motion-delete"
		>
			<Typography variant="body">
				<Box>{dialog.line1}</Box>
				<Box>{dialog.line2}</Box>
			</Typography>
		</GenericDialog>
	);
};

export default MotionDeleteDialog;
