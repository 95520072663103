import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import makeStyles from "@mui/styles/makeStyles";
import Icon from "atlas/components/Icon/Icon";
import GenericDialog from "atlas/components/Dialogs/GenericDialog";
import restorePurgeStyle from "views/Documents/assets/restorePurge";
import { restorePolicy } from "redux/policyGovernance/actions";

import { displayErrorNotification, displaySuccessNotification } from "views/Policy/utils/getNotification";
const useStyles = makeStyles(restorePurgeStyle);

const PolicyRestoreDialog = (props) => {
	const { show = true, policies = [], onClose, afterRestore, showSignIn } = props;
	const { t } = useTranslation("documents");
	const dispatch = useDispatch();
	const [restoring, setRestoring] = useState(false);
	const classes = useStyles();

	const handleRestore = () => {
		setRestoring(true);
		dispatch(restorePolicy(policies))
			.then(() => {
				displaySuccessNotification(t("restorePolicyDialog.snackbar.success"), dispatch);
				onClose();
				afterRestore(policies);
			})
			.catch((err) => {
				if (err.status === 500) {
					displayErrorNotification("Unable to Restore the Policy. Please try again", dispatch);
				} else {
					displayErrorNotification(err?.response?.body || "Something went wrong. Please try again", dispatch);
				}
				onClose();
			});
	};

	const handleCancel = () => {
		onClose();
	};

	const dialog = {
		title: t("restoreDocumentDialog.title"),
		primaryTitle: t("app:buttons.restore"),
		primaryAction: handleRestore,
		secondaryTitle: t("app:buttons.cancel"),
		secondaryAction: handleCancel,
	};

	const numberFolders = policies.filter((policy) => policy.folder).length;
	const numberDocuments = policies.length - numberFolders;
	const numberLabels = [];
	if (numberFolders > 0) {
		numberLabels.push(t(`restoreDocumentDialog.numberFolder${numberFolders > 1 ? "s" : ""}`, { number: numberFolders }));
	}
	if (numberDocuments > 0) {
		numberLabels.push(t(`restoreDocumentDialog.numberDocument${numberDocuments > 1 ? "s" : ""}`, { number: numberDocuments }));
	}

	return (
		<GenericDialog
			className={classes.dialog}
			show={show}
			title={dialog.title}
			primaryAction={dialog.primaryAction}
			primaryTitle={dialog.primaryTitle}
			primaryDisabled={policies.length === 0 || restoring}
			secondaryAction={dialog.secondaryAction}
			secondaryTitle={dialog.secondaryTitle}
			secondaryDisabled={restoring}
			clickAwayDisabled={restoring}
			closeIcon={<Icon name="close" />}
			data-cy="restore-dialog"
		>
			<div>{numberLabels.join(", ")}</div>
			<ol className={classes.list}>
				{policies.map(({ guid, folder, extension, title }) => (
					<li key={guid} className={classes.listItem}>
						<div className={classes.typeIcon} title={!folder ? extension : undefined}>
							{folder ? <Icon name="folder" size="24" /> : <Icon name="document" size="24" />}
						</div>
						<div className={classes.title}>{title}</div>
					</li>
				))}
			</ol>
		</GenericDialog>
	);
};

export default PolicyRestoreDialog;
