import {
	UPDATE_SETTINGS,
	GET_SETTINGS_PENDING,
	GET_SETTINGS_FULFILLED,
	SAVE_SETTINGS_PENDING,
	VALIDATE_BOXCAST_PENDING,
	VALIDATE_BOXCAST_FULFILLED,
	VALIDATE_YOUTUBE_PENDING,
	VALIDATE_YOUTUBE_FULFILLED,
	ADD_YOUTUBE_PENDING,
	ADD_YOUTUBE_FULFILLED,
	SENT_EMAIL_PENDING,
	SET_GENERAL_SETTING_ERROR,
	UPLOAD_LETTERHEAD_PENDING,
	UPLOAD_LETTERHEAD_FULFILLED,
	DELETE_LETTERHEAD_PENDING,
	DELETE_LETTERHEAD_FULFILLED,
} from "./types";
import request from "superagent";
import { API_HOST } from "config/env";

export const updateSettings = (config) => (dispatch) => {
	dispatch({
		type: UPDATE_SETTINGS,
		payload: config,
	});
};

export const sentEmail = (generalSettingsData) => (dispatch) => {
	return new Promise((resolve, reject) =>
		dispatch({
			type: SENT_EMAIL_PENDING,
			payload: request
				.post(`${API_HOST}/api/settings/sendemail`)
				.withCredentials()
				.send({
					...generalSettingsData,
				})
				.then((res) => {
					if (res.status === 200) {
						resolve(true);
					} else {
						reject(false);
					}
				})
				.catch((exception) => {
					reject("Error while sending email");
				}),
		}),
	);
};

const convertTime24to12 = (time) => {
	const convertedTime = {};
	convertedTime.period = time >= 12 ? "PM" : "AM";
	convertedTime.hour = ((time + 11) % 12) + 1;
	return convertedTime;
};

export const getGeneralSettings = () => {
	return (dispatch) => {
		return new Promise((resolve, reject) =>
			dispatch({
				type: GET_SETTINGS_PENDING,
				payload: request
					.get(`${API_HOST}/api/settings/getgeneralsettingsdata`)
					.withCredentials()
					.then((res) => {
						let data = res.body;
						const time = convertTime24to12(res.body.sendReminderNotification);
						data.hour = time.hour;
						data.period = time.period;
						dispatch({
							type: GET_SETTINGS_FULFILLED,
							payload: data,
						});
						resolve(res.body);
					})
					.catch((exception) => {
						reject(exception);
					}),
			}),
		);
	};
};

export const setGeneralSettingError = (data) => (dispatch) => {
	dispatch({
		type: SET_GENERAL_SETTING_ERROR,
		payload: data,
	});
};
export const saveGeneralSettings = (generalSettingsData) => (dispatch) => {
	return new Promise((resolve, reject) =>
		dispatch({
			type: SAVE_SETTINGS_PENDING,
			payload: request
				.put(`${API_HOST}/api/settings/savegeneralsettingsdata`)
				.withCredentials()
				.send({
					...generalSettingsData,
				})
				.then((res) => {
					if (res.status === 200) {
						resolve(true);
					} else {
						reject(false);
					}
				})
				.catch((exception) => {
					reject(exception);
				}),
		}),
	);
};

export function validateBoxcastCredentials(apiKey, apiSecret) {
	const data = { apiKey: apiKey, apiSecret: apiSecret };
	return (dispatch) => {
		return new Promise((resolve, reject) =>
			dispatch({
				type: VALIDATE_BOXCAST_PENDING,
				payload: request
					.post(`${API_HOST}/api/boxCastCredentialsValidation`)
					.send(data)
					.withCredentials()
					.then((response) => {
						if (response.body) {
							dispatch({
								type: VALIDATE_BOXCAST_FULFILLED,
								payload: response.body,
							});
							resolve(response.body);
						} else {
							resolve(false);
						}
					})
					.catch((exception) => {
						reject(exception);
					}),
			}),
		);
	};
}

export function addYoutubeCredentials(fileData) {
	return (dispatch) => {
		return new Promise((resolve, reject) =>
			dispatch({
				type: ADD_YOUTUBE_PENDING,
				payload: request
					.post(`${API_HOST}/api/youtubeCredentialsValidation`)
					.withCredentials()
					.send(fileData)
					.then((response) => {
						if (response.status === 200) {
							dispatch({
								type: ADD_YOUTUBE_FULFILLED,
								payload: response.body,
							});
							resolve(response.body);
						} else {
							resolve(response);
						}
					})
					.catch((exception) => {
						reject(exception);
					}),
			}),
		);
	};
}

export function validateYoutubeCredentials() {
	return (dispatch) => {
		return new Promise((resolve, reject) =>
			dispatch({
				type: VALIDATE_YOUTUBE_PENDING,
				payload: request
					.post(`${API_HOST}/api/youtubeCredentialsValidation`)
					.send()
					.withCredentials()
					.then((response) => {
						if (response.body) {
							dispatch({
								type: VALIDATE_YOUTUBE_FULFILLED,
								payload: response.body.Valid,
							});
							resolve(response.body);
						}
					})
					.catch((exception) => {
						reject(exception);
					}),
			}),
		);
	};
}

export const uploadDefaultLetterheadTemplate = (fileData) => (dispatch) =>
	new Promise((resolve, reject) =>
		dispatch({
			type: UPLOAD_LETTERHEAD_PENDING,
			payload: request
				.put(`${API_HOST}/api/settings/defaultLetterheadTemplate`)
				.withCredentials()
				.send(fileData)
				.then((response) => {
					if (response.status === 200) {
						dispatch({
							type: UPLOAD_LETTERHEAD_FULFILLED,
							payload: response.body,
						});
						resolve(response.body);
					} else {
						resolve(response);
					}
				})
				.catch((exception) => {
					reject(exception);
				}),
		}),
	);

export const deleteDefaultLetterheadTemplate = () => (dispatch) =>
	new Promise((resolve, reject) =>
		dispatch({
			type: DELETE_LETTERHEAD_PENDING,
			payload: request
				.delete(`${API_HOST}/api/settings/defaultLetterheadTemplate`)
				.withCredentials()
				.then((response) => {
					if (response.status === 200) {
						dispatch({
							type: DELETE_LETTERHEAD_FULFILLED,
							payload: response.body,
						});
						resolve(response.body);
					} else {
						resolve(response);
					}
				})
				.catch((exception) => {
					reject(exception);
				}),
		}),
	);
