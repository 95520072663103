import React from "react";
import PropTypes from "prop-types";

import { Box, Container } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import minutesStyle from "../../../assets/jss/components/minutesStyle";

const useStyle = makeStyles(minutesStyle);

const LiveMeetingHeader = (props) => {
	const { isHeader, elementsRef, elementsIndex } = props;

	const classes = useStyle();

	return (
		<>
			<Container id="minutes-header" className="agenda-header" style={{ marginBottom: "32px", marginTop: "8px" }} maxWidth="lg">
				<Box
					className={`${classes.editorFieldContent} ${classes.editorFieldContentHeader}`}
					data-fieldname={isHeader ? "toc-header" : "toc-footer"}
					ref={(el) => (elementsRef.current[elementsIndex] = el)}
				/>
			</Container>
		</>
	);
};

LiveMeetingHeader.propTypes = {
	isHeader: PropTypes.bool,
};

LiveMeetingHeader.defaultProps = {
	isHeader: false,
};

export default React.memo(LiveMeetingHeader);
