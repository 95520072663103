import React from "react";
import { useTranslation } from "react-i18next";
import { osName } from "react-device-detect";

import { Box, Card, Link, Typography } from "@mui/material";

import { useWidthUp } from "atlas/utils/useWidth";
import LoginAvatar from "components/Avatar/LoginAvatar";
import { getSupportedBrowsers } from "utils/browserSupport";

const withWidth = () => (WrappedComponent) => (props) => {
	const widthUpSm = useWidthUp("sm");

	return <WrappedComponent {...props} widthUpSm={widthUpSm} />;
};

const UnsupportedBrowser = (props) => {
	const { widthUpSm } = props;
	const { t } = useTranslation();

	const content = (
		<>
			<LoginAvatar />
			<Box mt={2}>
				<Typography variant="h3" align="center">
					{t("app:unsupportedBrowser:title")}
				</Typography>
			</Box>
			<Box mt={2}>
				<Typography variant="body1">
					{t("app:unsupportedBrowser:optimizedMessage")}
					<br />
					<br />
					{t("app:unsupportedBrowser:browserListMessage")}
				</Typography>
				<ul style={{ marginTop: "0" }}>
					{getSupportedBrowsers(osName).map((browser) => {
						return (
							<li key={browser.label} style={{ fontWeight: "bold" }}>
								<Link href={browser.link} target="_blank">
									{browser.label}
								</Link>
							</li>
						);
					})}
				</ul>
			</Box>
		</>
	);

	return widthUpSm ? (
		<Card className="unsupported-browser-card">{content}</Card>
	) : (
		<div className="unsupported-browser-small">{content}</div>
	);
};

export default withWidth()(UnsupportedBrowser);
