import { blackColor, grayColor, whiteColor, primaryColor } from "../shared";

export const CALENDAR_ICON_SMALL = "small";

const calendarIconStyles = () => ({
	root: {
		display: "inline-flex",
		alignItems: "center",
		flexWrap: "wrap",
		width: (props) => (props && props.size === CALENDAR_ICON_SMALL ? "40px" : "60px"),
		minWidth: (props) => (props && props.size === CALENDAR_ICON_SMALL ? "40px" : "60px"),
		height: (props) => (props && props.size === CALENDAR_ICON_SMALL ? "48px" : "72px"),
		marginRight: (props) => (props ? props.rightSpacing : undefined),
		backgroundColor: grayColor[4],
		borderRadius: "3px",
		overflow: "hidden",
		"& *": {
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
			width: "100%",
			fontStyle: "normal",
		},
		"& h5": {
			height: "24px",
			fontSize: (props) => (props && props.size === CALENDAR_ICON_SMALL ? "12px" : "16px"),
			color: whiteColor,
			textTransform: "uppercase",
			backgroundColor: primaryColor[3],
		},
		"& h4": {
			letterSpacing: "-0.05em",
			fontSize: (props) => (props && props.size === CALENDAR_ICON_SMALL ? "16px" : "24px"),
			height: (props) => (props && props.size === CALENDAR_ICON_SMALL ? "24px" : "48px"),
			fontWeight: "600",
			color: blackColor[5],
		},
	},
});

export default calendarIconStyles;
