import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { blackColor, infoColor } from "atlas/assets/jss/shared";

const useStyles = makeStyles((theme) => ({
	tree: {
		margin: "0",
		padding: "0",
		listStyleType: "none",
		"& > li": {
			width: "440px",
			maxWidth: "100%",
		},
	},
	listItem: {
		display: "flex",
		alignItems: "center",
		gap: "8px",
		position: "relative",
		"& > div": {
			display: "flex",
			alignItems: "center",
			height: "38px",
		},
	},
	title: {
		flexGrow: "1",
		color: infoColor,
		width: "min-content",
		whiteSpace: "nowrap",
		overflow: "hidden",
		textOverflow: "ellipsis",
		color: blackColor[0],
		paddingLeft: "8px",
	},
	canSelect: {
		cursor: "pointer",
	},
	selected: {
		outline: `2px solid ${infoColor}`,
		borderRadius: "2px",
	},

	listItems: {
		marginTop: "9px",
	},
	outBadge: {
		marginLeft: "10px",
	},
}));

const GoalsList = ({ onSelectedGoals, goals }) => {
	const { t } = useTranslation("goals");
	const handleGoalClick = (index) => {
		const updatedGoals = [...goals];
		updatedGoals[index].selected = !updatedGoals[index].selected;
		onSelectedGoals(updatedGoals);
	};

	const classes = useStyles();
	return (
		<>
			<ol className={classes.tree}>
				{goals &&
					goals.map((data, index) => {
						return (
							<li className={classes.listItems} onClick={() => handleGoalClick(index)}>
								<div className={clsx(classes.listItem, { [classes.selected]: data.selected })}>
									<div className={clsx(classes.title, classes.canSelect)}>
										{data.name}
										{data.status == 3 && (
											<span className={clsx("goal-status", "warning", classes.outBadge)} data-cy="status">
												{t("outOfSync")}
											</span>
										)}
									</div>
								</div>
							</li>
						);
					})}
			</ol>
		</>
	);
};

export default GoalsList;
