import { SNACKBAR_OPTION } from "./types";

const defaultState = {
	snackbarOption: {},
};

export const snackbarReducer = (
	state = {
		...defaultState,
	},
	action,
) => {
	switch (action.type) {
		case SNACKBAR_OPTION: {
			state = {
				...defaultState,
				snackbarOption: action.payload,
			};
			break;
		}

		default:
			break;
	}

	return state;
};

export default snackbarReducer;
