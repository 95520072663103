import React, { useEffect, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import GenericDialog from "atlas/components/Dialogs/GenericDialog";
import Icon from "atlas/components/Icon/Icon";
import { useTranslation } from "react-i18next";
import TableData from "atlas/components/TableData/TableData";
import TextCell from "atlas/components/TableData/TextCell";
import { blackColor } from "atlas/assets/jss/shared";
import DraftTitle from "views/Policy/components/DraftTitle";
import { warningColor } from "atlas/assets/jss/shared";
import { useDispatch } from "react-redux";
import { updateDraftPolicies } from "redux/policyGovernance/actions";
import DraftDate from "views/Policy/components/DraftDate";
import { displaySuccessNotification, displayErrorNotification } from "views/Policy/utils/getNotification";

const useStyles = makeStyles(() => ({
	dialog: {
		"& .MuiDialog-paper": {
			maxWidth: "100%",
			width: "629px",
			height: "400px",
		},
	},
	table: {
		"& *": {
			border: "none !important",
		},
		overflowY: "scroll !important",
	},
	headCell: {
		backgroundColor: "#EBEEF2",
		"& div": {
			fontWeight: "600 !important",
			fontSize: "16px !important",
			lineHeight: "20px !important",
			whiteSpace: "nowrap",
		},
		border: "none !important",
	},
	activeHead: {
		textAlign: "center",
	},
	row: {
		border: "none !important",
		whiteSpace: "nowrap",
	},
	cell: {
		border: "none !important",
		whiteSpace: "nowrap",
	},
	link: {
		color: "#385F99",
	},
	mobileTable: {
		"& tbody": {
			"& td": {
				border: "none !important",
			},
		},
	},
	tableContainer: {
		overflowY: "scroll",
	},
	warningContainer: {
		display: "flex",
		alignItems: "center",
	},
	warningIcon: {
		marginRight: "8px",
	},
}));

const UpdateDraftPolicyDialog = (props) => {
	const { show = true, onClose, draftPolicyList, uploadPayloadKey, itemId } = props;
	const [uploading, setUploading] = useState(false);
	const [checkedItems, setCheckedItems] = useState();
	const { t } = useTranslation("documents");
	const classes = useStyles();
	const dispatch = useDispatch();

	useEffect(() => {
		setCheckedItems(draftPolicyList.map((policy) => policy.id));
	}, []);

	const handlePolicyUpdate = () => {
		setUploading(true);
		const payload = {
			[uploadPayloadKey]: itemId,
			policyIds: checkedItems.map((policy) => policy.toString()),
		};
		dispatch(updateDraftPolicies(payload))
			.then((res) => {
				setUploading(false);
				onClose();
				let reload = false;
				reload = res && res.some((e) => e.isNameUpdated);
				if (reload) {
					window.location.reload();
				}
				displaySuccessNotification(t(`policy.successMsg.updateDraftSuccess`), dispatch);
			})
			.catch((err) => {
				setUploading(false);
				if (err.status === 500) {
					displayErrorNotification(t("policy.errorMsg.updateDraftPolicy"), dispatch);
				} else {
					displayErrorNotification(t("policy.errorMsg.commonMsg"), dispatch);
				}
			});
	};

	const handleCancel = () => {
		onClose();
	};

	const handleCheck = (guids = [], checked = false) => {
		if (checked) {
			if (guids.length > 1) {
				setCheckedItems(guids);
			} else {
				setCheckedItems([...checkedItems, ...guids]);
			}
		} else {
			if (guids.length > 1) {
				setCheckedItems([]);
			} else {
				const tempSelected = checkedItems.filter((item) => !guids.includes(item));
				setCheckedItems(tempSelected);
			}
		}
	};

	const i18n = t("updateDialogForPolicy");

	const dialog = {
		title: i18n.title,
		primaryTitle: t("app:buttons.policyUpdate"),
		primaryAction: handlePolicyUpdate,
		secondaryTitle: t("app:buttons.cancel"),
		secondaryAction: handleCancel,
	};

	const tableHeadings = [
		{
			id: "title",
			disablePadding: false,
			label: t("list.header.name"),
			retainCase: true,
			rowColor: blackColor[1],
			classes,
			component: DraftTitle,
			sortNotRequired: true,
		},
		{
			id: "modifiedBy",
			disablePadding: false,
			label: t("policyList.tableHeader.modifiedBy"),
			retainCase: true,
			rowColor: blackColor[1],
			component: TextCell,
			sortNotRequired: true,
		},
		{
			id: "dateModified",
			disablePadding: false,
			label: t("policyList.tableHeader.lastModified"),
			retainCase: true,
			rowColor: blackColor[1],
			component: DraftDate,
			sortNotRequired: true,
		},
	];

	return (
		checkedItems &&
		Array.isArray(checkedItems) && (
			<GenericDialog
				className={classes.dialog}
				show={show}
				title={dialog.title}
				primaryAction={dialog.primaryAction}
				primaryTitle={dialog.primaryTitle}
				primaryDisabled={uploading || checkedItems.length === 0}
				secondaryAction={dialog.secondaryAction}
				secondaryTitle={dialog.secondaryTitle}
				secondaryDisabled={uploading}
				clickAwayDisabled={uploading}
				closeIcon={<Icon name="close" />}
				data-cy="policy-draft-dialog"
			>
				<div className={classes.warningContainer}>
					<div className={classes.warningIcon}>
						<Icon name="status-alert" color={warningColor} />
					</div>
					<p>Do you want to update the attached policies to latest drafts?</p>
				</div>
				<div className={classes.tableContainer}>
					<TableData
						checkedAction={handleCheck}
						itemsSelected={checkedItems}
						showToolbar={false}
						headCells={tableHeadings}
						rows={draftPolicyList}
						selectProperty="id"
						defaultOrderBy="default"
						useExternalSort={false}
						overrideClasses={classes}
						initialSelection={checkedItems}
					/>
				</div>
			</GenericDialog>
		)
	);
};

export default UpdateDraftPolicyDialog;
