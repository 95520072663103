import React, { useState, useEffect, useContext } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import request from "superagent";

import ComponentContainer from "atlas/components/ComponentContainer/ComponentContainer";
import { API_HOST } from "config/env";
import { SettingsContext } from "contexts/Settings/SettingsContext";
import { resetPageConfigs, updatePageConfigs, updateLeftNav } from "redux/app/actions";
import { updatePageHeader } from "redux/pageHeader/actions";
import { FOLDER_TYPE_PUBLIC, FOLDER_TYPE_INTERNAL, FOLDER_TYPE_CUSTOM } from "utils/enums/DocumentFolderTypes";
import telemetryAddEvent from "utils/telemetryAddEvent";
import { sortTextFieldCaseInsensitive, sortBooleanField, sortNumericField } from "utils/sort";
import CustomFolderList from "./components/CustomFolderList";
import DocumentDeleteDialog from "./components/DocumentDeleteDialog";
import notifierMessage from "utils/notifierMessage";
import { setSnackbarOptions } from "../../redux/snackBar/actions";

const telemetryPage = "Custom Folder List";

const sortDefault = (a, b) => {
	let sort = sortBooleanField(a, b, "folder", true);
	if (sort === 0) {
		sort = sortNumericField(a, b, "folderType");
	}

	return sort !== 0 ? sort : sortTextFieldCaseInsensitive(a, b, "title");
};
const getSortMethod = (field, descending) => {
	let method = sortDefault;
	switch (field) {
		case "title":
			method = (a, b) => sortTextFieldCaseInsensitive(a, b, "title", descending);
			break;
	}

	return method;
};

const CustomFoldersModule = (props) => {
	const { showSignIn } = props;
	const { t } = useTranslation("documents");
	const navigate = useNavigate();
	const [folders, setFolders] = useState(null);
	const [order, setOrder] = useState({});
	const [dialogs, setDialogs] = useState({});
	const { lite } = useContext(SettingsContext);
	const dispatch = useDispatch();

	const loadFolders = () => {
		request
			.get(`${API_HOST}/api/documents`)
			.query({
				types: (lite.enabled ? [FOLDER_TYPE_PUBLIC] : [FOLDER_TYPE_PUBLIC, FOLDER_TYPE_INTERNAL, FOLDER_TYPE_CUSTOM]).join(","),
				all: true,
			})
			.then((res) => {
				setFolders(res.body.sort(getSortMethod(order.field, order.descending)));
			})
			.catch((err) => {
				showSignIn(err, () => {
					loadFolders();
				});
			});
	};

	const createCustomFolder = () => {
		telemetryAddEvent(`${telemetryPage} - Library - Add Custom Folder`, { area: "documents" });

		navigate("/documents/customfolder");
	};

	const handleSort = (field, orderType) => {
		setOrder({ field: field, descending: orderType !== "asc" });
	};

	const closeDialogs = () => {
		setDialogs({});
	};

	const handleDelete = (folder) => {
		setDialogs({ delete: folder });
	};

	const reloadLeftNavigation = () => {
		dispatch(
			updateLeftNav({
				reloadLeftNav: { customFolders: Date.now() },
			}),
		);
	};

	const afterDelete = (folder) => {
		telemetryAddEvent(`${telemetryPage} - Library - Deleted custom folder`, { area: "documents" });

		setFolders((prev) => {
			const index = prev.indexOf(folder);
			if (index >= 0) {
				prev.splice(index, 1);
			}

			return [...prev];
		});

		reloadLeftNavigation();
	};

	const undoDelete = (folder) => {
		request
			.post(`${API_HOST}/api/document/${folder.guid}/restore`)
			.withCredentials()
			.send({})
			.then(() => {
				let option = notifierMessage(t("deleteDocumentDialog.undo.successful"), "success");
				dispatch(setSnackbarOptions(option));

				setFolders((prev) => [...prev.concat([folder]).sort(getSortMethod(order.field, order.descending))]);

				reloadLeftNavigation();
			})
			.catch((err) => {
				showSignIn(err, () => {
					undoDelete(folder);
				});
			});
	};

	useEffect(() => {
		const sortMethod = getSortMethod(order.field, order.descending);

		setFolders((prev) => (prev || []).sort(sortMethod));
	}, [order]);

	useEffect(() => {
		dispatch(resetPageConfigs({ resetBack: true }));
		dispatch(
			updatePageConfigs({
				title: t("customFolderList.title"),
				telemetryPage,
			}),
		);
		dispatch(
			updatePageHeader({
				primaryAction: createCustomFolder,
				primaryActionText: t("buttons.addCustomFolder"),
				primaryActionTooltip: t("tooltips.addCustomFolder"),
			}),
		);

		loadFolders();
	}, []);

	return (
		<ComponentContainer padding="0">
			<CustomFolderList folders={folders} handleSort={handleSort} handleDelete={handleDelete}></CustomFolderList>
			{dialogs.delete && (
				<DocumentDeleteDialog document={dialogs.delete} onClose={closeDialogs} afterDelete={afterDelete} undoDelete={undoDelete} />
			)}
		</ComponentContainer>
	);
};

export default CustomFoldersModule;
