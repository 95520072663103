import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { startsWith } from "lodash";

import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const PREFIX = "StyledPhoneInput";
const classes = {
	container: `${PREFIX}-container`,
	error: `${PREFIX}-error`,
};
const StyledBox = styled(Box)(({ _theme }) => ({
	[`&.${classes.container}`]: {
		height: "60px",
	},
}));
const StyledDiv = styled("div")(({ _theme }) => ({
	[`&.${classes.error}`]: {
		fontFamily: "Source Sans Pro, Verdana, Helvetica, Arial, sans-serif",
		fontStyle: "normal",
		fontWeight: "normal",
		fontSize: "12px",
		lineHeight: "12px",
		textAlign: "left",
		color: "#d22c2c",
		marginTop: "-10px",
		marginLeft: "14px",
	},
}));

const StyledPhoneInput = React.forwardRef((props, ref) => {
	const {
		id,
		regions,
		onChange,
		phoneValue,
		label,
		phoneCountry,
		errorMsg,
		validateCountry = false,
		dataCy,
		disabled = false,
		compact = false,
		placeholder = "1 (555) 555-5555",
		onValidate = null,
		invalidWhenBlank = false,
		invalidNumbers = [],
		autoComplete,
		...otherProps
	} = props;

	const [flagString, setFlagString] = useState(phoneCountry ? `${phoneCountry.iso2} :` : "us :");
	const [isCountryValid, setIsCountryValid] = useState(true);

	useEffect(() => {
		setFlagString(phoneCountry ? `${phoneCountry.iso2} :` : "us :");
	}, [phoneCountry]);

	useEffect(() => {
		if (onValidate) {
			onValidate(isCountryValid);
		}
	}, [isCountryValid]);

	const validate = (inputNumber, country, countries) => {
		let isValid = true;
		if (!countries.some((c) => startsWith(inputNumber, c.dialCode) || startsWith(c.dialCode, inputNumber))) {
			isValid = false;
		}
		if (invalidWhenBlank && inputNumber === "") {
			isValid = false;
		}
		if (invalidNumbers.findIndex((item) => item === inputNumber) > -1) {
			isValid = false;
		}
		if (isCountryValid !== isValid) {
			setIsCountryValid(isValid);
		}
		return isValid;
	};

	const getContainerStyle = () => {
		const cs = { height: "40px" };
		if (compact) {
			cs.margin = "0";
		}
		if (disabled) {
			cs.color = "#676767";
		}
		return cs;
	};

	const getButtonStyle = () => {
		const cs = {};
		if (disabled) {
			cs.color = "#676767";
			cs.backgroundColor = "#ffffff";
			cs.cursor = "default";
		}
		return cs;
	};

	const getInputStyle = () => {
		const cs = { height: "40px" };
		if (disabled) {
			cs.color = "#676767";
			cs.backgroundColor = "#ffffff";
			cs.cursor = "default";
		}
		return cs;
	};

	return (
		<StyledBox data-cy={dataCy} className={classes.container}>
			<PhoneInput
				id={id}
				ref={ref}
				country={1}
				containerStyle={getContainerStyle()}
				inputStyle={getInputStyle()}
				buttonStyle={getButtonStyle()}
				specialLabel={label}
				regions={regions}
				value={phoneValue}
				onChange={(value, country, e, formattedValue) => onChange({ target: { value, id, formattedValue, country } })}
				renderStringAsFlag={flagString}
				isValid={validateCountry ? validate : true}
				containerClass={!isCountryValid ? "invalid-number" : ""}
				inputClass={disabled ? "phone-disabled" : ""}
				buttonClass={disabled ? "phone-disabled" : ""}
				placeholder={placeholder}
				inputProps={
					disabled ? { "aria-label": "Phone Number", readOnly: "readonly" } : { "aria-label": "Phone Number", autocomplete: autoComplete }
				}
				disabled={disabled}
				{...otherProps}
			/>
			{!isCountryValid && (
				<StyledDiv className={classes.error} data-cy="phone-error-msg">
					{errorMsg}
				</StyledDiv>
			)}
		</StyledBox>
	);
});

StyledPhoneInput.propTypes = {
	regions: PropTypes.array,
	onChange: PropTypes.func.isRequired,
	phoneValue: PropTypes.string,
};

StyledPhoneInput.defaultProps = {
	regions: ["america", "europe"],
	phoneValue: "",
};

export default StyledPhoneInput;
