import { grayColor, whiteColor } from '../shared';

export const touchRippleStyle = () => ({
	child: {
		backgroundColor: whiteColor,
	},
	rippleVisible: {
		opacity: 0.2,
	},
	'@keyframes mui-ripple-enter': {
		'0%': {
			transform: 'scale(0)',
			opacity: 0.1,
		},
		'100%': {
			transform: 'scale(1)',
			opacity: 0.9,
		},
	},
});

export const buttonStyle = () => ({
	root: {
		borderRadius: '2px',
		'&:hover': {
			backgroundColor: '#455772',
		},
		'&:active': {
			backgroundColor: '#202935',
		},
		'&.Mui-focusVisible': {
			border: `solid 1px ${grayColor[4]}`,
			borderRadius: '2px',
			margin: '-1px',
		},
	},
});

export const pageHeaderButtonStyle = () => ({
	root: {
		padding: '8px',
		borderRadius: '2px',
		'&:hover': {
			backgroundColor: '#455772',
		},
		'&:active': {
			backgroundColor: '#202935',
		},
		'&.Mui-focusVisible': {
			border: `solid 1px ${grayColor[4]}`,
			borderRadius: '2px',
			margin: '-1px',
		},
	},
});
