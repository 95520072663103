import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import parse from "html-react-parser";
import { useTranslation } from "react-i18next";

const useEditorStyles = makeStyles(() => ({
	goalsDescriptionText: {
		marginBottom: "10px",
		fontWeight: "400",
		fontSize: "18px",
	},
	goalsDescriptionSection: {
		marginTop: "25px",
	},
}));

const GoalContentData = (props) => {
	const { goal } = props;
	const { actions, description, outcomes } = goal;
	const { t } = useTranslation("goals");

	const editorClasses = useEditorStyles();
	return (
		<>
			{description && (
				<div className={editorClasses.goalsDescriptionSection}>
					<div className={editorClasses.goalsDescriptionText}>{t("description")}</div>
					<div>{parse(description)}</div>
				</div>
			)}
			{outcomes && (
				<div className={editorClasses.goalsDescriptionSection}>
					<div className={editorClasses.goalsDescriptionText}>{t("outcomes")}</div>
					<div>{parse(outcomes)}</div>
				</div>
			)}
			{actions && (
				<div className={editorClasses.goalsDescriptionSection}>
					<div className={editorClasses.goalsDescriptionText}>{t("actions")}</div>
					<div>{parse(actions)}</div>
				</div>
			)}
		</>
	);
};

export default GoalContentData;
