export const GET_TIMERS_FULFILLED = "GET_TIMERS_FULFILLED";
export const GET_TIMERS_PENDING = "GET_TIMERS_PENDING";
export const GET_VOTING_DATA_FULFILLED = "GET_VOTING_DATA_FULFILLED";
export const GET_VOTING_DATA_PENDING = "GET_VOTING_DATA_PENDING";
export const GET_VOTING_SETTINGS_FULFILLED = "GET_VOTING_SETTINGS_FULFILLED";
export const GET_VOTING_SETTINGS_PENDING = "GET_VOTING_SETTINGS_PENDING";

export const PERSIST_ROLLCALL_FULFILLED = "PERSIST_ROLLCALL_FULFILLED";
export const PERSIST_ROLLCALL_PENDING = "PERSIST_ROLLCALL_PENDING";

export const POST_BOXCAST_INTERMISSION_FULFILLED = "POST_BOXCAST_INTERMISSION_FULFILLED";
export const POST_BOXCAST_INTERMISSION_PENDING = "POST_BOXCAST_INTERMISSION_PENDING";

export const UPDATE_ONLINE_VOTERS = "UPDATE_ONLINE_VOTERS";
export const UPDATE_TIMERS = "UPDATE_TIMERS";

export const ADOPT_PUBLISH_PREVIOUS_MINUTES_PENDING = "ADOPT_PUBLISH_PREVIOUS_MINUTES_PENDING";
export const ADOPT_PUBLISH_PREVIOUS_MINUTES_FULFILLED = "ADOPT_PUBLISH_PREVIOUS_MINUTES_FULFILLED";
export const OPEN_DRAFT_ADOPT_MINUTES_PENDING = "OPEN_DRAFT_ADOPT_MINUTES_PENDING";
export const OPEN_DRAFT_ADOPT_MINUTES_FULFILLED = "OPEN_DRAFT_ADOPT_MINUTES_FULFILLED";
