import React from "react";
import SvgIcon from "@mui/material/SvgIcon";

import themeConfig from "../../atlas/assets/jss/theme";
import getOr from "lodash/fp/getOr";

const icon = (props) => {
	const { color } = props;
	const fillColor = color ? getOr(color, ["palette", color, "main"], themeConfig) : "currentColor";

	return (
		<SvgIcon {...props}>
			<defs>
				<path
					d="M16.6509612,15.0498119 C16.9438411,15.3427184 16.9438195,15.8175922 16.650913,16.1104721 C16.3580064,16.403352 15.8831327,16.4033304 15.5902528,16.1104238 L12.283548,12.8034184 L8.97651845,16.110448 C8.68362524,16.4033412 8.2087515,16.4033412 7.91585828,16.110448 C7.62296506,15.8175547 7.62296506,15.342681 7.91585828,15.0497878 L11.222936,11.74271 L7.91618301,8.4356564 C7.62330311,8.14274987 7.62332469,7.66787614 7.91623123,7.37499623 C8.20913776,7.08211633 8.68401149,7.08213791 8.9768914,7.37504444 L12.2835962,10.6820499 L15.5902769,7.37536917 C15.8831701,7.08247596 16.3580438,7.08247596 16.6509371,7.37536917 C16.9438303,7.66826239 16.9438303,8.14313613 16.6509371,8.43602935 L13.3442082,11.7427582 L16.6509612,15.0498119 Z M12.0930233,23.0150257 C18.2869609,23.0150257 23.3081395,17.9938471 23.3081395,11.7999094 C23.3081395,5.60597169 18.2869609,0.584793095 12.0930233,0.584793095 C5.89908557,0.584793095 0.877906977,5.60597169 0.877906977,11.7999094 C0.877906977,17.9938471 5.89908557,23.0150257 12.0930233,23.0150257 Z M12.0930233,21.5150257 C6.72751269,21.5150257 2.37790698,17.1654199 2.37790698,11.7999094 C2.37790698,6.43439881 6.72751269,2.08479309 12.0930233,2.08479309 C17.4585338,2.08479309 21.8081395,6.43439881 21.8081395,11.7999094 C21.8081395,17.1654199 17.4585338,21.5150257 12.0930233,21.5150257 Z"
					id="close-path-1"
				/>
			</defs>
			<g id="close" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g id="Icons/24px/Unselected/Close">
					<mask id="close-mask-2" fill="white">
						<use xlinkHref="#close-path-1" />
					</mask>
					<use fill={fillColor} fillRule="nonzero" xlinkHref="#close-path-1" />
					<g mask="url(#close-mask-2)" fill="#000000">
						<rect x="0" y="0" width="24" height="24" />
					</g>
				</g>
			</g>
		</SvgIcon>
	);
};

export default icon;
