import typographyStyle from "atlas/assets/jss/components/typographyStyle";
import { grayColor } from "atlas/assets/jss/shared";

const userListStyle = () => ({
	list: {
		margin: "0",
		padding: "0",
		"& > li": {
			listStyleType: "none",
			marginBottom: "16px",
		},
	},
	user: {
		display: "flex",
		alignItems: "center",
	},
	avatar: {
		marginRight: "8px",
	},
	identifier: {
		flexGrow: "1",
	},
	name: {
		...typographyStyle.text,
	},
	title: {
		lineHeight: "1.23",
		fontSize: "13px",
		fontWeight: "normal",
		color: grayColor[0],
		"&:empty:before": {
			content: '"\\00a0"',
		},
	},
});

export default userListStyle;
