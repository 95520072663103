import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import request from "superagent";
import cookie from "react-cookies";
import clsx from "clsx";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import makeStyles from "@mui/styles/makeStyles";
import Typography from "@mui/material/Typography";

import clone from "lodash/fp/clone";
import omit from "lodash/fp/omit";

import { blackColor } from "atlas/assets/jss/shared";
import CircularProgressIndicator from "atlas/components/Progress/CircularProgressIndicator";
import { Visibility, VisibilityOff } from "components/Icons";
import OutlinedInput from "atlas/components/FormControls/OutlinedInput";
import { pollLiveData } from "redux/app/actions";
import telemetryAddEvent from "utils/telemetryAddEvent";
import { API_HOST } from "config/env";
import setupSignalR from "utils/communication/SignalrClient";

const useStyles = makeStyles({
	password: {
		"& .MuiTouchRipple-ripple.MuiTouchRipple-rippleVisible": {
			opacity: "0.42",
			"& .MuiTouchRipple-child": {
				backgroundColor: blackColor[0],
			},
		},
	},
});

const SignInForm = (props) => {
	const { label, afterSignIn, settingsContext, userContext } = props;
	const { t } = useTranslation("account");
	const navigate = useNavigate();
	const username = cookie.load("username");
	const [showPassword, setShowPassword] = useState(false);
	const [editing, setEditing] = useState({ username: username || "", password: "" });
	const [errors, setErrors] = useState({});
	const [failedAttempt, setFailedAttempt] = useState(false);
	const [pending, setPending] = useState(false);
	const dispatch = useDispatch();
	const focusPassword = username !== undefined;
	const classes = useStyles();

	const handleKeyPress = (event) => {
		if (event.key === "Enter") {
			handleSignIn();
		}
	};

	const handleBlur = (_e, field) => {
		validate(field);
	};

	const validate = (singleField = null) => {
		const { username, password } = editing;

		// if we are only validating one field onBlur, keep any other errors, otherwise reset all errors
		const newErrors = singleField ? omit(singleField, errors) : {};
		let valid = true;

		if (!singleField || singleField === "username") {
			if (username.length === 0) {
				newErrors.username = t("errors.required", { fieldName: t("username") });
				valid = false;
			}
		}

		if (!singleField || singleField === "password") {
			if (password.length === 0) {
				newErrors.password = t("errors.required", { fieldName: t("password") });
				valid = false;
			}
		}

		setErrors(newErrors);

		return valid;
	};

	const handleFieldChange = (e) => {
		// get all values in edit mode, update just the one that changed.
		const newEditValues = clone(editing);
		newEditValues[e.target.id] = e.target.value;

		setEditing(newEditValues);
	};

	useEffect(() => {
		// When on chrome, the text is sometimes of a different font size on username and the password label is over the auto-filled password.
		// This will trigger a click in each field so react properly sets value to the inputs.
		// It's a known chrome autofill bug and Material will not do anything about it.
		// Nested setTimeouts are to bypass a timing issue with the Chrome bug, where it autofills after one setTimeout, so we do two to be sure our clicks happen last.
		setTimeout(() => {
			setTimeout(() => {
				clickElement(document.getElementById("password"));
				clickElement(document.getElementById("username"));
			}, 100);
		}, 100);
	}, []);

	const handleSignIn = () => {
		const { username, password } = editing;
		const { setUser } = userContext;
		const { loadSettings } = settingsContext;

		telemetryAddEvent("Sign in - Sign in attempt");

		if (validate()) {
			setPending(true);
			request
				.post(`${API_HOST}/api/user/signin`)
				.send({ userName: username, password })
				.then((res) => {
					setPending(false);
					if (res.body.authenticated) {
						const user = setUser({
							id: res.body.id,
							name: res.body.name,
							username: res.body.username,
							systemAdministrator: res.body.systemAdministrator,
							boardAdmin: res.body.boardAdmin,
							boardMember: res.body.boardMember,
							boardStaff: res.body.boardStaff,
							goalAdmin: res.body.goalAdmin,
							requestToSpeakAdmin: res.body.requestToSpeakAdmin,
							portalAdmin: res.body.portalAdmin,
							userAdmin: res.body.userAdmin,
							workflowAdmin: res.body.workflowAdmin,
							documentLibrary: res.body.documentLibrary,
							policySite: res.body.policySite,
							redirect: res.body.redirect,
							callback: () => {
								setupSignalR({ dispatch, t, navigate });
							},
						});

						loadSettings();
						dispatch(pollLiveData({ resetTimeout: true }));

						afterSignIn(user);
					} else {
						setFailedAttempt(true);
					}
				})
				.catch((err) => {
					console.log(err);
				});
		}
	};

	const handleClickShowPassword = () => {
		setShowPassword((prev) => !prev);
	};

	const clickElement = (el) => {
		if (el) {
			el.click();
		}
	};

	return (
		<form action="/" method="POST">
			<Box id="sign-in-title" mt={2}>
				<Typography variant="h3" align="center">
					{label}
				</Typography>
			</Box>

			{failedAttempt && (
				<Box mt={2}>
					<Typography color="error" variant="caption" data-cy="errorUserNamePassword" role="status">
						{t("errors.wrongUsernamePassword")}
					</Typography>
				</Box>
			)}
			<OutlinedInput
				autoFocus
				id="username"
				label={t("username")}
				value={editing.username}
				onChange={handleFieldChange}
				onKeyPress={handleKeyPress}
				onBlur={(e) => handleBlur(e, "username")}
				fullWidth
				helperText={errors.username}
				error={!!errors.username}
				required={true}
				ariaRequired={true}
				data-cy="username"
			/>
			<OutlinedInput
				className={clsx("no-margin-top", classes.password)}
				id="password"
				fullWidth
				autoFocus={focusPassword}
				type={showPassword ? "text" : "password"}
				label={t("password")}
				value={editing.password}
				onChange={handleFieldChange}
				onKeyPress={handleKeyPress}
				onBlur={(e) => handleBlur(e, "password")}
				required={true}
				ariaRequired={true}
				InputProps={{
					endAdornment: (
						<InputAdornment position="end">
							<IconButton
								id="showPassword"
								tabIndex="0"
								aria-label={showPassword ? t("hidePasswordIcon") : t("showPasswordIcon")}
								onClick={handleClickShowPassword}
								size="large"
							>
								{showPassword ? <VisibilityOff color={blackColor[0]} /> : <Visibility color={blackColor[0]} />}
							</IconButton>
						</InputAdornment>
					),
				}}
				helperText={errors.password}
				error={!!errors.password}
				data-cy="password"
			/>
			<Button
				className={clsx("signin-button", "medium-top-margin")}
				id="sign-in-button"
				onClick={handleSignIn}
				variant="contained"
				size="large"
				color="primary"
				disabled={pending}
				data-cy="signIn"
			>
				{pending ? <CircularProgressIndicator data-cy="loading" /> : t("buttons.signIn")}
			</Button>
		</form>
	);
};

SignInForm.propTypes = {
	label: PropTypes.string.isRequired,
	afterSignIn: PropTypes.func.isRequired,
};

export default SignInForm;
