import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import makeStyles from "@mui/styles/makeStyles";
import { Box, IconButton, InputAdornment } from "@mui/material";

import GenericDialog from "atlas/components/Dialogs/GenericDialog";
import Icon from "atlas/components/Icon/Icon";
import OutlinedInput from "atlas/components/FormControls/OutlinedInput";
import { Visibility, VisibilityOff } from "components/Icons";
import { policyDelete } from "redux/policyGovernance/actions";
import { FOLDER_TYPE_CUSTOM } from "utils/enums/DocumentFolderTypes";
import { displayErrorNotification, displaySuccessNotification } from "views/Policy/utils/getNotification";

const useStyles = makeStyles(() => ({
	dialog: {
		"& .MuiDialog-paper": {
			width: "400px",
			maxWidth: "100%",
		},
	},
}));

const PolicyDeleteDialog = (props) => {
	const { show = true, document, parentGuid, onClose, afterDelete, isSection } = props;
	const { t } = useTranslation("documents");
	const dispatch = useDispatch();
	const [password, setPassword] = useState("");
	const [showPassword, setShowPassword] = useState(false);
	const [errors, setErrors] = useState({});
	const [deleting, setDeleting] = useState(false);
	const classes = useStyles();
	const customFolder = document.folderType === FOLDER_TYPE_CUSTOM;

	const handleDelete = () => {
		setDeleting(true);

		dispatch(policyDelete(document.guid, password))
			.then(() => {
				onClose();
				displaySuccessNotification(
					t(`deleteDocumentDialog.snackbar.success.${document.folder ? "folder" : "document"}`, { title: document.title }),
					dispatch,
				);
				afterDelete(document, parentGuid);
			})
			.catch((err) => {
				if (err.status === 500) {
					const { response: { body: { Message: message } = {} } = {} } = err;
					if (message === t(`policy.apiResponse.invalidPassword`)) {
						setDeleting(false);
						setErrors((prev) => ({ ...prev, password: true }));
					} else {
						displayErrorNotification(t("policy.errorMsg.deletePolicy"), dispatch);
						onClose();
					}
				} else {
					displayErrorNotification(t("policy.errorMsg.commonMsg"), dispatch);
					onClose();
				}
			});
	};

	const handleCancel = () => {
		onClose();
	};

	const handleClickShowPassword = () => {
		setShowPassword((prev) => !prev);
	};

	const i18n = t("deleteDocumentDialog", { title: document.title });

	const dialog = {
		title: isSection ? t("deleteDocumentDialog.title.sectionDelete") : t("deleteDocumentDialog.title.deletePolicy"),
		line1: isSection ? t("deleteDocumentDialog.sectionDeleteMsg") : customFolder ? i18n.customFolderLine1 : i18n.line1,
		primaryTitle: isSection ? t("deleteDocumentDialog.button.confirmDelete") : t("app:buttons.delete"),
		primaryAction: handleDelete,
		secondaryTitle: t("app:buttons.cancel"),
		secondaryAction: handleCancel,
	};

	return (
		<GenericDialog
			className={classes.dialog}
			show={show}
			title={dialog.title}
			primaryAction={dialog.primaryAction}
			primaryTitle={dialog.primaryTitle}
			primaryDisabled={deleting || !password}
			secondaryAction={dialog.secondaryAction}
			secondaryTitle={dialog.secondaryTitle}
			secondaryDisabled={deleting}
			clickAwayDisabled={deleting}
			closeIcon={<Icon name="close" />}
			data-cy="delete-dialog"
		>
			<Box fontWeight="fontWeightBold">{dialog.line1}</Box>
			<OutlinedInput
				id="password"
				label={t("permanentlyDeleteDocumentDialog.labels.password")}
				type={showPassword ? "text" : "password"}
				value={password}
				onChange={(e) => setPassword(e.target.value)}
				noDefaultClassName
				fullWidth
				size="small"
				InputProps={{
					endAdornment: (
						<InputAdornment tabIndex="-1" position="end">
							<IconButton tabIndex="-1" onClick={handleClickShowPassword} aria-label={t("app:buttons.showPassword")}>
								{showPassword ? <VisibilityOff /> : <Visibility />}
							</IconButton>
						</InputAdornment>
					),
				}}
				helperText={errors.password ? t("permanentlyDeleteDocumentDialog.errors.password") : undefined}
				error={errors.password}
				data-cy="password"
			/>
		</GenericDialog>
	);
};

export default PolicyDeleteDialog;
