import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import request from "superagent";
import clsx from "clsx";
import { v4 as uuid } from "uuid";

import { Box, Typography, Grid, TableCell } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { API_HOST } from "config/env";
import CircularProgressIndicator from "atlas/components/Progress/CircularProgressIndicator";
import TableData from "atlas/components/TableData/TableData";
import MenuCell from "atlas/components/TableData/MenuCell";
import ButtonWithTooltip from "atlas/components/Buttons/ButtonWithTooltip";
import { blackColor } from "atlas/assets/jss/shared";
import entityListStyle from "assets/jss/components/entityListStyle";
import CoverTemplateDeleteDialog from "./CoverTemplateDeleteDialog";
import notifierMessage from "utils/notifierMessage";
import { setSnackbarOptions } from "redux/snackBar/actions";
import RenameDialog from "views/Documents/components/RenameDialog";
import { updatePageHeader } from "redux/pageHeader/actions";
import styles from "atlas/assets/jss/components/pageHeaderStyle";

import { useWidthDown } from "atlas/utils/useWidth";
import telemetryAddEvent from "utils/telemetryAddEvent";

const useEntityListStyleStyles = makeStyles(entityListStyle);
const usePageHeaderStyles = makeStyles(styles);
const useStyles = makeStyles(() => ({
	headerButton: {
		margin: "0 8px",
	},
}));

const telemetryPage = "Agenda item cover template list";

const CoverTemplateList = (props) => {
	const { coverTemplates, loadCoverTemplates, showSignIn } = props;
	const dispatch = useDispatch();
	const { t } = useTranslation("agendaItems");
	const navigate = useNavigate();
	const widthDownSm = useWidthDown("sm");
	const [dialogs, setDialogs] = useState({});
	const [showCoverTemplateDeleteDialog, setShowCoverTemplateDeleteDialog] = useState(false);
	const [coverTemplateToReplace, setCoverTemplateToReplace] = useState(null);
	const [uploading, setUploading] = useState(false);
	const [creating, setCreating] = useState(false);
	const input = useRef();
	const entityListClasses = useEntityListStyleStyles();
	const pageHeaderClasses = usePageHeaderStyles();
	const classes = useStyles();

	const openDeleteDialog = (template) => {
		setDialogs({ delete: template });
		setShowCoverTemplateDeleteDialog(true);
	};

	const closeDialogs = () => {
		setDialogs({});
	};

	const afterDelete = () => {
		loadCoverTemplates();
		closeDialogs();
		let option = notifierMessage(t("deleteCoverTemplateDialog.snackbar.success"), "success");
		dispatch(setSnackbarOptions(option));
	};

	const handleReplace = (template) => {
		setCoverTemplateToReplace(template);
		uploadClick();
	};

	const handleRename = (template) => {
		setDialogs({ rename: { guid: template.guid, title: template.name } });
	};

	const afterRename = () => {
		loadCoverTemplates();
		closeDialogs();
	};

	const uploadClick = () => {
		if (!uploading) {
			input.current.click();
		}
	};

	const uploadInputChange = (e) => {
		const { target: { files = [] } = {} } = e;
		if (files && files.length > 0) {
			const file = files[0];
			if (file.name.endsWith(".doc") || file.name.endsWith(".docx")) {
				const fileData = new FormData();
				fileData.append(uuid(), file);

				setUploading(true);

				telemetryAddEvent(`${telemetryPage} - Agenda Item Cover Template Upload`);

				request
					.post(`${API_HOST}/api/agendaitem/${coverTemplateToReplace ? coverTemplateToReplace.guid : "0"}/uploadcovertemplate`)
					.withCredentials()
					.send(fileData)
					.then((res) => {
						if (res.status === 200) {
							loadCoverTemplates();
							setUploading(false);
							let option = notifierMessage(t("coverTemplateUploadSuccess"), "success");
							dispatch(setSnackbarOptions(option));
							input.current.value = null;
						}
					})
					.catch((err) => {
						showSignIn(err, () => {
							uploadInputChange(e);
						});
					});
			} else {
				let option = notifierMessage(t("coverTemplateWrongExtension"), "error");
				dispatch(setSnackbarOptions(option));
			}
		}
	};

	const createClick = () => {
		setCreating(true);

		request
			.get(`${API_HOST}/api/agendaitems/createtemplate`)
			.withCredentials()
			.then((res) => {
				if (res.status === 200) {
					const { id, guid, name } = res.body;
					setCreating(false);
					let option = notifierMessage(t("coverTemplateCreateSuccess"), "success");
					dispatch(setSnackbarOptions(option));
					navigate(`/document/${guid}`);
				}
			})
			.catch((err) => {
				showSignIn(err, () => {
					createClick();
				});
			});
	};

	useEffect(() => {
		const menuOptions = [
			{
				label: !uploading ? t("buttons.addNewCoverReportTemplate") : t("agendaMenu:uploading"),
				tooltip: !uploading ? t("tooltips.addNewCoverReportTemplate") : t("agendaMenu:uploading"),
				actionFunction: uploadClick,
				"data-cy": "add-cover-template",
			},
		];

		dispatch(
			updatePageHeader({
				primaryAction: !uploading && !widthDownSm ? uploadClick : undefined,
				primaryActionText: !uploading ? t("buttons.addNewCoverReportTemplate") : t("agendaMenu:uploading"),
				primaryActionTooltip: !uploading ? t("tooltips.addNewCoverReportTemplate") : t("agendaMenu:uploading"),
				primaryActionDisabled: uploading,
				menuOptions: widthDownSm ? menuOptions : undefined,
			}),
		);
	}, [coverTemplates, uploading]);

	const coverTemplatesTableHeadings = [
		{
			id: "name",
			disablePadding: false,
			label: t("coverTemplateList.header.name"),
			retainCase: true,
			rowColor: blackColor[1],
			component: (props) => {
				const { row } = props;

				return (
					<TableCell className={entityListClasses.cell}>
						<Grid container spacing={2}>
							<Grid container item xs={12}>
								<div className={entityListClasses.detail} style={{ flexGrow: 1 }}>
									<a
										className={clsx("noStyle", entityListClasses.link)}
										href={`/home/document/${row.guid}`}
										onClick={(e) => {
											navigate(`/document/${row.guid}`);

											e.preventDefault();
										}}
									>
										<Typography variant="body1">{row.name}</Typography>
									</a>
								</div>
							</Grid>
						</Grid>
					</TableCell>
				);
			},
		},
		{
			id: "menu",
			disablePadding: false,
			label: "",
			rowColor: blackColor[1],
			width: "76px",
			component: MenuCell,
		},
	];

	const menuOptions = (template) => [
		{
			id: `replace-cover-template-${template.id}`,
			key: `replace-cover-template-${template.id}`,
			label: t("buttons.replace"),
			ariaLabel: t("tooltips.replaceCoverTemplate"),
			actionFunction: (template) => {
				handleReplace(template);
			},
			"data-cy": "replace-cover-template",
		},
		{
			id: `rename-cover-template-${template.id}`,
			key: `rename-cover-template-${template.id}`,
			label: t("app:buttons.rename"),
			actionFunction: (template) => {
				handleRename(template);
			},
			"data-cy": "rename-cover-template",
		},
		{
			id: `delete-cover-template-${template.id}`,
			key: `delete-cover-template-${template.id}`,
			label: t("buttons.delete"),
			ariaLabel: t("tooltips.deleteCoverTemplate"),
			actionFunction: (template) => {
				openDeleteDialog(template);
			},
			"data-cy": "delete-cover-template",
		},
	];

	return (
		<>
			{coverTemplates ? (
				coverTemplates.length > 0 ? (
					<>
						<TableData
							showToolbar={false}
							headCells={coverTemplatesTableHeadings}
							rows={coverTemplates}
							selectProperty="id"
							defaultOrderBy="name"
							rowClickAction={() => {}}
							withoutMultiSelect={true}
							overrideClasses={entityListClasses}
							menuOptions={menuOptions}
							iconName="remove"
							data-cy="cover-template-list"
						/>
					</>
				) : (
					<div className={entityListClasses.noResults}>
						<Box style={{ textAlign: "center" }}>
							<ButtonWithTooltip
								className={clsx(pageHeaderClasses.button, classes.headerButton, { [pageHeaderClasses.disableButton]: creating })}
								primary
								onClick={() => createClick()}
								dataCy="create-cover-template"
								title={t("tooltips.createTemplate")}
								disabled={creating}
							>
								{creating ? <CircularProgressIndicator size={20} /> : t("buttons.createTemplate")}
							</ButtonWithTooltip>
						</Box>
						<Box>
							<Typography variant="h3">{t("coverTemplateList.noResults.title")}</Typography>
						</Box>
						<Box mt={3}>
							<Typography variant="body2">{t("coverTemplateList.noResults.description")}</Typography>
						</Box>
					</div>
				)
			) : (
				<CircularProgressIndicator />
			)}
			{dialogs.delete && (
				<CoverTemplateDeleteDialog
					coverTemplate={dialogs.delete}
					show={showCoverTemplateDeleteDialog}
					afterDelete={afterDelete}
					onClose={closeDialogs}
				/>
			)}
			{dialogs.rename && (
				<RenameDialog document={dialogs.rename} onClose={closeDialogs} afterRename={afterRename} showSignIn={showSignIn} />
			)}
			<input
				style={{ display: "none" }}
				id="upload-input"
				type="file"
				ref={input}
				onChange={uploadInputChange}
				accept={[".doc", ".docx"]}
				tabIndex={-1}
				aria-label={t("app:buttons.upload")}
				aria-hidden="true"
			/>
		</>
	);
};

export default CoverTemplateList;
