export default {
	userProfile: "Profil utilisateur",
	chooseNewPassword: "Update your password",
	confirmNewPassword: "Confirmez votre mot de passe",
	currentPassword: "Mot de passe actuel",
	enterNewPassword: "Confirmez votre identité en entrant votre mot de passe actuel",
	email: "Courriel",
	firstName: "Prénom",
	lastName: "Nom de famille",
	name: "Nom",
	newEmail: "Nouveau courriel",
	newPassword: "Nouveau mot de passe",
	noPictureUploaded: "Aucune photo téléversée",
	password: "Mot de passe",
	photo: "Photo",
	phoneNumber: "Telephone number",
	phoneNumberLabel: "Phone number",
	updatePhone: "Update your phone number",
	updatePhoneSubMsg: "(Add this to receive text notifications for approvals)",
	updateEmail: "Mise à jour du courriel",
	updateEmailConfirm: "Avant de mettre à jour votre courriel, veuillez confirmez votre identié en entrant votre mot de passe actuel",
	updateName: "Mise à jour de votre nom",
	updatePasswordInstructions:
		"Enter a strong unique password that’s 8 or more characters long.<br /><br />To create a strong password, do not:<ul><li>Include personal information.</li><li>Use common passwords (such as “Password” or “12345678”).</li><li>Reuse passwords from other logins.</li></ul>",
	updatePersonalInfo: "Mise à jour de votre profile",
	updatePhoto: "Mise à jour de votre photo",
	updateUserName: "Mise à jour de votre nom d'utilisateur",
	userName: "Nom d'utilisateur",
	verifyPassword: "Vérification du mot de passe",

	buttons: {
		chooseAnother: "En choisir une autre",
		photoRemove: "Retirer",
		verify: "Vérifier",
	},
	notifications: {
		emailUpdated: "Courriel mis à jour avec succès",
		nameUpdated: "Nom mis à jour avec succès",
		passwordUpdated: "Mot de passe mis à jour avec succès",
		phoneNumberUpdated: "Phone number updated successfully",
		pictureRemoved: "Photo de profile effacée avec succès",
		pictureUpdated: "Photo de profile mise à jour avec succès",
		smsSettingsUpdated: "Notification settings updated successfully",
		userNameUpdated: "Nom d'utilisateur mis à jour avec succès",
	},
	errors: {
		disabledTooltip: "Réparer les erreurs ci-haut pour continuer",
		enterCorrectPassword: "Entrer votre mot de passe actuel pour continuer",
		firstNameEmpty: "Le prénom ne peut pas être vide",
		firstNameInvalid: "First name contains invalid text, such as HTML",
		invalidEmail: "Ce n'est pas une adresse courriel valide",
		invalidNumber: "Phone number is invalid",
		lastNameEmpty: "Le nom de famille ne peut pas être vide",
		lastNameInvalid: "Last name contains invalid text, such as HTML",
		mustMatchNewPassword: "Error: Enter matching passwords",
		passwordCheckFailed: "That password doesn't match our records",
		passwordContainsEmailAddress: "Error: Cannot include email in password",
		passwordContainsName: "Error: Cannot include name in password",
		passwordContainsUserName: "Error: Cannot include username in password",
		passwordEightCharacter: "Error: Enter a password with 8 or more characters",
		passwordIsCommon: "Error: This password is common. For security reasons please select a more unique password",
		pictureSaveFailed: "Votre nouvelle photo de profile ne s'est pas enregistrée avec succès",
		userNameEmpty: "Le nom d'utilisateur ne peut pas être vide",
		userNameMustBeUnique: "Votre nom d'utilisateur doit être unique",
		usernameTaken: "Le nom d'utilisateur saisis est déjà utilisé par quelqu'un d'autre",
		validEmail: "Entrer une adresse courriel valide pour continuer",
	},
};
