import React from "react";
import clsx from "clsx";

import makeStyles from "@mui/styles/makeStyles";
import { Badge } from "@mui/material";

import { blackColor, whiteColor, successColor, warningColor, errorColor } from "atlas/assets/jss/shared";
import Icon from "atlas/components/Icon/Icon";
import GenericAvatar, { GENERIC_AVATAR_BACKGROUNDS } from "components/Avatar/GenericAvatar";
import { WORKFLOW_STATUS_PENDING, WORKFLOW_STATUS_APPROVED, WORKFLOW_STATUS_DENIED } from "utils/workflowStatuses";

const useStyles = makeStyles(() => ({
	avatar: {
		width: "24px",
		height: "24px",
		fontSize: "12px",
		lineHeight: "14px",
		fontWeight: "600",
	},
	avatarNone: {
		border: `2px solid ${whiteColor}`,
		opacity: (props) => (!props.highlightPending ? "0.75" : undefined),
	},
	avatarPending: {
		border: `2px solid ${warningColor}`,
	},
	avatarApproved: {
		border: `2px solid ${successColor}`,
	},
	avatarDenied: {
		border: `2px solid ${errorColor}`,
	},
	badge: {
		verticalAlign: "initial",
	},
	statusBadge: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		borderRadius: "6px",
		height: "12px",
		width: "12px",
		backgroundColor: whiteColor,
		boxSizing: "border-box",
	},
	statusBadgeApproved: {
		border: `1px solid ${successColor}`,
	},
	statusBadgeDenied: {
		border: `1px solid ${errorColor}`,
	},
}));

const ApprovalProgressApproverAvatar = (props) => {
	const { user, highlightPending } = props;
	const classes = useStyles({ highlightPending });

	return (
		user && (
			<Badge
				className={classes.badge}
				overlap="circular"
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "right",
				}}
				invisible={![WORKFLOW_STATUS_APPROVED, WORKFLOW_STATUS_DENIED].includes(user.status)}
				badgeContent={
					<div
						className={clsx(classes.statusBadge, {
							[classes.statusBadgeApproved]: user.status === WORKFLOW_STATUS_APPROVED,
							[classes.statusBadgeDenied]: user.status === WORKFLOW_STATUS_DENIED,
						})}
					>
						<Icon
							name={user.status === WORKFLOW_STATUS_APPROVED ? "check" : "close-no-circle"}
							size={user.status === WORKFLOW_STATUS_APPROVED ? "12" : "6"}
							color={blackColor[1]}
						/>
					</div>
				}
			>
				<GenericAvatar
					className={clsx(classes.avatar, {
						[classes.avatarNone]: ![WORKFLOW_STATUS_PENDING, WORKFLOW_STATUS_APPROVED, WORKFLOW_STATUS_DENIED].includes(user.status),
						[classes.avatarPending]: user.status === WORKFLOW_STATUS_PENDING,
						[classes.avatarApproved]: user.status === WORKFLOW_STATUS_APPROVED,
						[classes.avatarDenied]: user.status === WORKFLOW_STATUS_DENIED,
					})}
					imageUrl={user.profileImageUrl}
					name={user.name}
					initials={`${(user.firstName || "").substr(0, 1)}${(user.lastName || "").substr(0, 1)}`}
					backgroundNumber={((user.number || 0) % GENERIC_AVATAR_BACKGROUNDS) + 1}
				/>
			</Badge>
		)
	);
};

export default ApprovalProgressApproverAvatar;
