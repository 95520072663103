import React from "react";
import { useTranslation } from "react-i18next";

import makeStyles from "@mui/styles/makeStyles";

import OutlinedInput from "atlas/components/FormControls/OutlinedInput";
import { FOLDER_TYPE_PUBLIC, FOLDER_TYPE_INTERNAL } from "utils/enums/DocumentFolderTypes";
import { getRequiredLabel, getErrorProps } from "utils/updateObject";
import FolderPermissions from "./components/FolderPermissions";

const useStyles = makeStyles((theme) => ({
	columns: {
		display: "flex",
		flexWrap: "wrap",
		margin: "0 -12px",
		[theme.breakpoints.down("md")]: {
			margin: "0",
		},
		"& > div": {
			boxSizing: "border-box",
			margin: "0 12px",
			width: "calc(50% - 24px)",
			[theme.breakpoints.down("md")]: {
				margin: "0",
				width: "100%",
			},
		},
	},
}));

const CustomFolder = (props) => {
	const { folder = {}, users, errors = {}, handleUpdate, telemetryPage } = props;
	const { t } = useTranslation("documents");
	const classes = useStyles();

	const publicOrInternal = [FOLDER_TYPE_PUBLIC, FOLDER_TYPE_INTERNAL].includes(folder.folderType);

	return (
		<>
			<div>
				{!publicOrInternal && (
					<div id="details" className={classes.columns}>
						<div>
							<div>
								<OutlinedInput
									id="title"
									label={getRequiredLabel(t, t("customFolderDetail.name"))}
									placeholder={t("customFolderDetail.placeholders.name")}
									value={folder.title}
									onChange={(e) => handleUpdate(e, "title")}
									{...getErrorProps(errors, "title")}
									noDefaultClassName
									fullWidth
									disabled={publicOrInternal}
									size="small"
									data-cy="title"
								/>
							</div>
						</div>
					</div>
				)}
				{folder.folderPermissions && users && (
					<FolderPermissions
						folderType={folder.folderType}
						folderPermissions={folder.folderPermissions}
						users={users}
						handleUpdate={handleUpdate}
						telemetryPage={telemetryPage}
					/>
				)}
			</div>
		</>
	);
};

export default CustomFolder;
