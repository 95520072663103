import {
	GET_POLICIES_FULFILLED,
	GET_POLICIES_PENDING,
	GET_PERMISSIONS_PENDING,
	GET_PERMISSIONS_FULFILLED,
	ADD_FOLDER_PENDING,
	ADD_FOLDER_FULFILLED,
	GET_PATH_PENDING,
	GET_PATH_FULFILLED,
	GET_LANDING_PAGE,
	GET_PUBLISHED_PERMISSIONS,
	GET_MULTIPLE_POLICY_ATTACHMENTS_FULFILLED,
	GET_ATTACHMENTS_MINUTES_FULFILLED,
	SET_RELOAD_PAGE_AFTER_WOPI_EDIT,
} from "./types";

export const policyGovernanceReducer = (
	state = {
		policyData: null,
		policyPermissionsData: null,
		landingPage: true,
		pathData: null,
		updated: false,
		updating: false,
		publishedPermissionsData: {
			publishedWrite: false,
			publishedRead: false,
			unpublished: false,
		},
		multiplePolicyData: [],
		policyAttachmentsMinutes: [],
		reloadPageAfterWOPIEdit: false,
	},
	action,
) => {
	switch (action.type) {
		case GET_POLICIES_PENDING: {
			state = {
				...state,
				updated: false,
				updating: true,
			};
			break;
		}
		case GET_POLICIES_FULFILLED: {
			state = {
				...state,
				policyData: action.payload,
				updated: true,
				updating: false,
			};
			break;
		}

		case GET_PERMISSIONS_PENDING: {
			state = {
				...state,
				updated: false,
				updating: true,
			};
			break;
		}

		case GET_PERMISSIONS_FULFILLED: {
			state = {
				...state,
				policyPermissionsData: action.payload,
				updated: true,
				updating: false,
			};
			break;
		}

		case ADD_FOLDER_PENDING: {
			state = {
				...state,
				updated: false,
				updating: true,
			};
			break;
		}

		case ADD_FOLDER_FULFILLED: {
			state = {
				...state,
				...action.payload,
				updated: true,
				updating: false,
			};
			break;
		}

		case GET_PATH_PENDING: {
			state = {
				...state,
				updated: false,
				updating: true,
			};
			break;
		}

		case GET_PATH_FULFILLED: {
			state = {
				...state,
				pathData: action.payload,
				updated: true,
				updating: false,
			};
			break;
		}

		case GET_LANDING_PAGE: {
			const mainPage = action.payload.id && action.payload.path && action.payload.path.length <= 2 ? true : false;
			state = {
				...state,
				landingPage: mainPage,
				updated: true,
				updating: false,
			};
			break;
		}

		case GET_PUBLISHED_PERMISSIONS: {
			const { draftGuid, canUpdate, reviewStatus } = action.payload;
			let publishObj = {
				publishedWrite: false,
				publishedRead: false,
				unpublished: false,
			};

			if (canUpdate) {
				if (draftGuid) {
					publishObj.publishedWrite = true;
				} else if (reviewStatus) {
					publishObj.publishedRead = true;
				} else {
					publishObj.unpublished = true;
				}
			} else {
				publishObj.publishedRead = true;
			}
			state = {
				...state,
				publishedPermissionsData: publishObj,
				updated: true,
				updating: false,
			};
			break;
		}

		case SET_RELOAD_PAGE_AFTER_WOPI_EDIT: {
			state = {
				...state,
				reloadPageAfterWOPIEdit: action.payload,
			};
			break;
		}

		case GET_MULTIPLE_POLICY_ATTACHMENTS_FULFILLED: {
			state = {
				...state,
				multiplePolicyData: action.payload,
				updated: true,
				updating: false,
			};
		}

		case GET_ATTACHMENTS_MINUTES_FULFILLED: {
			state = {
				...state,
				policyAttachmentsMinutes: action.payload,
				updated: true,
				updating: false,
			};
		}
	}
	return state;
};

export default policyGovernanceReducer;
