import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import clsx from "clsx";

import makeStyles from "@mui/styles/makeStyles";

import userPermissionOptionsStyle from "assets/jss/components/userPermissionOptionsStyle";
import Accordion from "atlas/components/Accordion/Accordion";
import Icon from "atlas/components/Icon/Icon";
import { useWidthDown } from "atlas/utils/useWidth";
import accordionStyle from "assets/jss/components/accordionStyle";
import GenericAvatar, { GENERIC_AVATAR_BACKGROUNDS } from "components/Avatar/GenericAvatar";
import { sortTextFieldCaseInsensitive } from "utils/sort";
import PermissionOptions from "./PermissionOptions";
import PermissionOptionLabels from "./PermissionOptionLabels";

const useStyles = makeStyles(userPermissionOptionsStyle);
const useAccordionStyles = makeStyles(accordionStyle);

const sortDefault = (a, b) => {
	let sort = sortTextFieldCaseInsensitive(a, b, "code", false);

	return sort !== 0 ? sort : sortTextFieldCaseInsensitive(a, b, "title", false);
};

const PolicySettingsSection = (props) => {
	const { copyId, user = {}, users, handleUpdate, telemetryPage } = props;
	const mobile = useWidthDown("md");
	const { t } = useTranslation("users");
	const [expanded, setExpanded] = useState([]);
	const classes = useStyles();
	const accordionClasses = useAccordionStyles();

	const toggleBook = (guid) => {
		setExpanded((prev) => [
			...(!prev.find((prevBook) => prevBook === guid) ? prev.concat([guid]) : prev.filter((prevBook) => prevBook !== guid)),
		]);
	};

	const alwaysShowView = Boolean((user.policyPermissions || []).find((book) => book.private));

	return (
		user.policyPermissions &&
		users && (
			<Accordion classes={accordionClasses} label={t("detail.policySettings")} scrollContentIntoView overflow={false} dataCy="policy">
				<div id="policy">
					<div className={classes.folders}>
						{!mobile && (
							<div className={clsx(classes.sectionRow, classes.userLabels)}>
								<div className={classes.folderLabel} />
								<PermissionOptionLabels hideView={!alwaysShowView} />
							</div>
						)}
						<ol data-cy="books">
							{user.policyPermissions.sort(sortDefault).map((book) => {
								const open = expanded.includes(book.guid);
								const thisBookUser = book.permissions.find(
									(permission) => permission.userId === user.id || (user.id === 0 && permission.userId === copyId),
								);
								const handleBookClick = () => toggleBook(book.guid);
								const otherUsers = open
									? book.permissions
											.filter((permission) => permission.userId !== user.id)
											.map((permission) => ({
												permission,
												user: users.find((user) => user.id === permission.userId),
											}))
											.filter((userData) => userData.user)
											.sort((a, b) => (a.user.name < b.user.name ? -1 : a.user.name > b.user.name ? 1 : 0))
									: [];

								return (
									<li key={book.guid}>
										<div className={clsx(classes.sectionRow, classes.folderRow)}>
											<div className={classes.folderIcon} onClick={handleBookClick} data-cy={`book-expand-${book.guid}`}>
												<Icon name={open ? "expand-down" : "expand-right"} />
											</div>
											<div className={classes.folderName} onClick={handleBookClick} data-cy={`book-name-${book.guid}`}>
												{book.code} {book.title} - {user.firstName} {user.lastName}
											</div>
											<PermissionOptions
												user={user}
												permission={thisBookUser}
												isPolicy
												alwaysView={!book.private}
												showViewPlaceholder={alwaysShowView}
												handleUpdate={handleUpdate}
												telemetryPage={telemetryPage}
											/>
										</div>
										{open && otherUsers.length > 0 && (
											<div>
												<ol data-cy={`book-users-${book.guid}`}>
													{otherUsers.map((userData) => {
														const { permission, user } = userData;

														return (
															<li key={user.id} className={clsx(classes.sectionRow, classes.folderPermissionRow)}>
																<div className={classes.userAvatar}>
																	<GenericAvatar
																		imageUrl={user.profileImageUrl}
																		name={user.name}
																		initials={`${(user.firstName || "").substr(0, 1)}${(user.lastName || "").substr(0, 1)}`}
																		backgroundNumber={((user.number || 0) % GENERIC_AVATAR_BACKGROUNDS) + 1}
																	/>
																</div>
																<div className={classes.userName}>
																	{user.deleted || user.inactive || user.external
																		? t(`app:entities.${user.deleted ? "deleted" : user.external ? "external" : "inactive"}`, {
																				entity: user.name,
																		  })
																		: user.name}
																</div>
																<PermissionOptions
																	permission={permission}
																	isPolicy
																	alwaysView={!book.private}
																	showViewPlaceholder={alwaysShowView}
																	telemetryPage={telemetryPage}
																/>
															</li>
														);
													})}
												</ol>
											</div>
										)}
									</li>
								);
							})}
						</ol>
					</div>
				</div>
			</Accordion>
		)
	);
};

export default PolicySettingsSection;
