import { grayColor } from "atlas/assets/jss/shared";

const accordionStyle = () => ({
	header: {
		borderBottom: "none",
		height: "auto",
		padding: "8px 16px",
		margin: "0 -16px",
		"&:hover": {
			backgroundColor: "rgba(0, 0, 0, 0.08)",
		},
		"&:focus": {
			backgroundColor: grayColor[1],
		},
	},
	icon: {
		marginRight: "8px",
	},
	label: {
		fontSize: "16px",
		fontWeight: "600",
		lineHeight: "1.25",
	},
	content: {
		paddingBottom: "16px",
	},
});

export default accordionStyle;
