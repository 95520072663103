import React from 'react';
import PropTypes from 'prop-types';

import makeStyles from '@mui/styles/makeStyles';
import { MenuItem } from '@mui/material';

import Tooltip from '../Tooltip/Tooltip';

const useStyles = makeStyles(() => ({
	menuItem: {
		overflow: 'visible',
	},
	childContainer: {
		display: 'flex',
		alignItems: 'center',
		width: '100%',
		minHeight: '40px',
		margin: '0 -12px 0 -18px',
		padding: '0 12px 0 18px',
	},
}));

const MenuItemWithTooltip = (props) => {
	const { disabled, autoFocus, tooltip, placement, onClick, 'data-cy': dataCy, separator, children } = props;
	const classes = useStyles({});

	return (
		<MenuItem
			className={classes.menuItem}
			disabled={disabled}
			autoFocus={autoFocus}
			onClick={onClick}
			data-separator={separator ? true : undefined}
			data-cy={dataCy}
		>
			<Tooltip PopperProps={{ disablePortal: true }} title={tooltip} placement={placement}>
				<div className={classes.childContainer}>{children}</div>
			</Tooltip>
		</MenuItem>
	);
};

MenuItemWithTooltip.propTypes = {
	disabled: PropTypes.bool,
	autoFocus: PropTypes.bool,
	tooltip: PropTypes.string,
	placement: PropTypes.string,
	position: PropTypes.string,
	onClick: PropTypes.func.isRequired,
	'data-cy': PropTypes.string,
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

MenuItemWithTooltip.defaultProps = {
	disabled: undefined,
	autoFocus: undefined,
	placement: 'right',
	'data-cy': undefined,
	children: undefined,
};

export default MenuItemWithTooltip;
