import React, { useState, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useMatch } from "react-router-dom";
import { useTranslation } from "react-i18next";
import request from "superagent";

import makeStyles from "@mui/styles/makeStyles";

import { API_HOST } from "config/env";
import { FOLDER_TYPE_PUBLIC } from "utils/enums/DocumentFolderTypes";
import { sortTextFieldCaseInsensitive, sortBooleanField } from "utils/sort";
import Breadcrumbs from "atlas/components/Breadcrumbs/Breadcrumbs";
import ComponentContainer from "atlas/components/ComponentContainer/ComponentContainer";
import { useWidthDown } from "atlas/utils/useWidth";
import { resetPageConfigs, updatePageConfigs } from "redux/app/actions";
import { updatePageHeader } from "redux/pageHeader/actions";
import DocumentList from "./components/DocumentList";

const useStyles = makeStyles({
	breadcrumbs: {
		height: "40px",
		padding: "0 8px",
		"& *": {
			fontFamily: "Segoe UI, Helvetica, Tahoma, Arial, Calibri, Verdana, sans-serif !important",
			fontSize: "13px",
		},
	},
	breadcrumb: {
		color: "rgb(100, 101, 103)",
		"& div": {
			letterSpacing: "inherit !important",
		},
	},
	breadcrumbSelected: {
		color: "rgb(100, 101, 103)",
		"& div": {
			letterSpacing: "inherit !important",
		},
	},
	fileInput: {
		display: "none",
	},
});

const telemetryPage = "Document List";

const sortDefault = (a, b) => {
	const folderSort = sortBooleanField(a, b, "folder", true);

	return folderSort !== 0 ? folderSort : sortTextFieldCaseInsensitive(a, b, "title");
};
const getSortMethod = (field, descending) => {
	let method = sortDefault;
	switch (field) {
		case "title":
			method = (a, b) => sortTextFieldCaseInsensitive(a, b, "title", descending);
			break;
	}

	return method;
};

const PublicDocumentsModule = (props) => {
	const { showSignIn } = props;
	const widthDownSm = useWidthDown("sm");
	const { params: { id } = {} } = useMatch({ path: "/public/documents/:id", end: true }) || {};
	const { t } = useTranslation("documents");
	const navigate = useNavigate();
	const [documents, setDocuments] = useState(null);
	const [path, setPath] = useState(null);
	const [order, setOrder] = useState({});
	const dispatch = useDispatch();
	const classes = useStyles();

	const backToParent = useCallback(() => {
		if (path && path.length > 1 && path[path.length - 2].url) {
			navigate(path[path.length - 2].url);
		}

		return true;
	}, [path]);

	const setTitle = useCallback(
		(title) => {
			dispatch(
				updatePageConfigs({
					title,
					telemetryPage,
					back: path && path.length > 1 ? { action: backToParent } : undefined,
				}),
			);
		},
		[path],
	);

	const loadDocuments = (parentId, root, parent) => {
		request
			.get(`${API_HOST}/api/documents`)
			.query({ id: parentId, type: FOLDER_TYPE_PUBLIC })
			.then((res) => {
				setDocuments((prev) => {
					// Check if the page has changed. If it has make no changes to the documents
					if (prev.folder.id !== id) {
						return prev;
					}

					const newDocuments = root ? { folder: prev.folder } : { ...prev };
					newDocuments[root || !parentId ? "root" : parentId] = res.body.sort(getSortMethod(order.field, order.descending));

					return newDocuments;
				});
			})
			.catch((err) => {
				showSignIn(err, () => {
					loadDocuments(parentId, root, parent);
				});
			});
	};

	const loadPath = () => {
		const rootName = t("title.public");
		if (!id) {
			// Root folder
			setPath([
				{
					name: "resource-center",
					icon: true,
					size: "20px",
					title: rootName,
				},
			]);
		} else {
			request
				.get(`${API_HOST}/api/document/${id}/path`)
				.then((res) => {
					setPath(
						res.body.map((path, index) =>
							index === 0
								? {
										name: "resource-center",
										icon: true,
										size: "20px",
										title: rootName,
										url: `/public/documents`,
								  }
								: {
										name: path.title,
										url: index < res.body.length - 1 ? `/public/documents/${path.guid}` : undefined,
								  },
						),
					);
				})
				.catch((err) => {
					showSignIn(err, () => {
						loadPath();
					});
				});
		}
	};

	const handleDocumentEvent = ({ eventName, document, expand, keyboard }) => {
		switch (eventName) {
			case "expand":
				document.expanded = typeof expand !== "undefined" ? expand : !document.expanded;
				document.focusChild = document.expanded;
				document.focusKeyboard = keyboard;

				// Trigger re-render
				setDocuments((prev) => {
					// Load children if needed
					if (document.expanded && !prev[document.guid]) {
						loadDocuments(document.guid, false, document);
					}

					return { ...prev };
				});
				break;
		}
	};

	const handleSort = (field) => {
		// If sorting by the same field switch between ascending, descending, and default order. New sort fields start sorted ascending.
		setOrder((prev) => ({
			field: prev.field !== field || !prev.descending ? field : undefined,
			descending: Boolean(prev.field === field && !prev.descending),
		}));
	};

	const handleResize = () => {
		if (window.top && window.top !== window && typeof window.top.resizePublicDocuments === "function") {
			window.top.resizePublicDocuments({ height: document.body.scrollHeight });
		}
	};

	useEffect(() => {
		const sortMethod = getSortMethod(order.field, order.descending);

		setDocuments((prev) => {
			if (prev) {
				Object.keys(prev).forEach((key) => {
					if (Array.isArray(prev[key])) {
						prev[key].sort(sortMethod);
					}
				});

				return { ...prev };
			} else {
				return prev;
			}
		});
	}, [order]);

	useEffect(() => {
		if (path && path.length > 0) {
			setTitle(path[path.length - 1].name);
		}
		dispatch(
			updatePageHeader({
				leftMenuOptions:
					path && widthDownSm
						? path
								.filter((folder) => folder.url)
								.map((folder) => ({
									label: folder.name,
									actionFunction: () => navigate(folder.url),
								}))
						: [],
			}),
		);
	}, [path, widthDownSm]);

	useEffect(() => {
		dispatch(resetPageConfigs({ resetBack: true }));

		setDocuments({ folder: { id } });

		loadDocuments(id, true);
		loadPath();

		if (id && window.top && window.top !== window && typeof window.top.updateHistory === "function") {
			window.top.updateHistory({ id });
		}
	}, [id]);

	useEffect(() => {
		document.body.classList.remove("maple-background");
		document.body.classList.add("portal-background");

		return () => {
			document.body.classList.remove("portal-background");
			document.body.classList.add("maple-background");
		};
	}, []);

	useEffect(() => {
		setTimeout(window.requestAnimationFrame(handleResize));
	}); // There is no body resize event yet, so update the iframe size after every render

	const getChildDocuments = useCallback(
		(list, childDocuments, depth = 0) => {
			let updatedList = list;

			if (childDocuments) {
				for (let index = 0; index < childDocuments.length; index++) {
					const childDocument = childDocuments[index];

					childDocument.depth = depth;

					updatedList.push(childDocument);

					if (childDocument.folder && childDocument.expanded) {
						updatedList = getChildDocuments(updatedList, documents[childDocument.guid], depth + 1);
					}
				}
			} else {
				updatedList.push({ loading: true });
			}

			return updatedList;
		},
		[documents],
	);

	return (
		<ComponentContainer padding="0">
			{documents && (
				<Breadcrumbs
					className={classes.breadcrumbs}
					classes={classes}
					showMenu
					items={path}
					separator={<span className={classes.breadcrumbSeparator}>/</span>}
				></Breadcrumbs>
			)}
			<DocumentList
				label={path?.[0]?.title || ""}
				type={FOLDER_TYPE_PUBLIC}
				inPortal
				order={order}
				documents={documents}
				handleEvent={handleDocumentEvent}
				handleSort={handleSort}
				telemetryPage={telemetryPage}
			></DocumentList>
		</ComponentContainer>
	);
};

export default PublicDocumentsModule;
