import React, { Component } from "react";
import { withTranslation } from "react-i18next";

import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Typography from "@mui/material/Typography";

import ComponentContainer from "atlas/components/ComponentContainer/ComponentContainer";
import { Visibility, VisibilityOff } from "components/Icons";
import OutlinedInput from "atlas/components/FormControls/OutlinedInput";
import { useWidthUp } from "atlas/utils/useWidth";
import SaveCancelButtons from "./SaveCancelButtons";

const withWidth = () => (WrappedComponent) => (props) => {
	const widthUpSm = useWidthUp("sm");

	return <WrappedComponent {...props} widthUpSm={widthUpSm} />;
};

class VerifyPassword extends Component {
	state = { showPassword: false };

	handleSubmit = (e) => {
		const { handleSave } = this.props;

		if (typeof handleSave === "function") {
			handleSave();
		}

		e.preventDefault();

		return false;
	};

	handleClickShowPassword = () => {
		this.setState((state) => ({ showPassword: !state.showPassword }));
	};

	render() {
		const { t, data, widthUpSm, messageKey, handleCancel, handleSave, handleChange } = this.props;
		const { editing = {}, errors = {} } = data;

		const content = (
			<form action="/" method="POST" onSubmit={this.handleSubmit}>
				<Typography variant="h5" color="textSecondary">
					{t("verifyPassword")}
				</Typography>
				<Box mt={2}>
					<Typography variant="body2">{t(messageKey)}</Typography>
				</Box>
				<OutlinedInput
					autoFocus
					className="card-textfield"
					id="verifyPassword"
					label={t("currentPassword")}
					value={editing.verifyPassword || ""}
					type={this.state.showPassword ? "text" : "password"}
					helperText={errors.verifyPassword}
					onChange={handleChange}
					fullWidth
					InputProps={{
						endAdornment: (
							<InputAdornment tabIndex="-1" position="end">
								<IconButton tabIndex="-1" onClick={this.handleClickShowPassword} size="large">
									{this.state.showPassword ? <VisibilityOff /> : <Visibility />}
								</IconButton>
							</InputAdornment>
						),
					}}
					error={!!errors.verifyPassword}
					data-cy="verifyPassword"
				/>

				<SaveCancelButtons
					save={handleSave}
					saveEnabled={!errors.verifyPassword}
					disabledTooltip={errors.verifyPassword ? t("errors.enterCorrectPassword") : ""}
					cancel={handleCancel}
					saveLabel={t("buttons.verify")}
					cancelLabel={t("app:buttons.cancel")}
				/>
			</form>
		);

		return <ComponentContainer maxWidth={widthUpSm ? "288px" : null}>{content}</ComponentContainer>;
	}
}

export default withWidth()(withTranslation("userProfile")(VerifyPassword));
