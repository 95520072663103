/* eslint-disable no-nested-ternary */
/* eslint-disable no-plusplus */
import React, { useState, useEffect, useRef, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation, useMatch } from "react-router-dom";
import cookie from "react-cookies";
import request from "superagent";
import { debounce } from "lodash";
import forEach from "lodash/fp/forEach";
import isEqual from "lodash/fp/isEqual";
import queryString from "query-string";
import { v4 as uuid } from "uuid";

import makeStyles from "@mui/styles/makeStyles";
import withStyles from "@mui/styles/withStyles";
import { Typography, Button } from "@mui/material";

import { API_HOST } from "config/env";
import { formatDate } from "utils/date";
import processHtml from "utils/processHtml";
import { timeStampExists } from "utils/videoTimeStamping";
import { createMeetingElement } from "utils/meetingElement";
import telemetryAddEvent from "utils/telemetryAddEvent";
import AgendaItemTypesEnum from "utils/enums/AgendaItemTypes";
import MinutesItemTypesEnum from "utils/enums/MinutesItemTypes";
import ComponentContainer from "atlas/components/ComponentContainer/ComponentContainer";
import CircularProgressIndicator from "atlas/components/Progress/CircularProgressIndicator";
import { ACTION_DISMISS } from "atlas/components/Cards/NoticeCard";
import UploadErrorDialog from "components/Dialogs/UploadErrorDialog";
import TimerPresetEditDialog from "components/Dialogs/TimerPresetEditDialog";
import TimerCustomDialog from "components/Dialogs/TimerCustomDialog";
import { STATUS_INFO } from "atlas/assets/jss/utils/statusIndicators";
import { BOTTOM_LEFT } from "atlas/assets/jss/utils/placement";
import Icon from "atlas/components/Icon/Icon";
import GenericDialog from "atlas/components/Dialogs/GenericDialog";
import FileUploadFailureDialog from "components/Dialogs/FileUploadFailureDialog";
import SupportRequestDialog from "components/Dialogs/SupportRequestDialog";
import MeetingPreviewDialog from "components/Dialogs/MeetingPreviewDialog";
import MoveConsentItemDialog from "components/Dialogs/MoveConsentItemDialog";
import getProperty from "utils/caseInsensitiveProperty";
import downloadFile from "utils/download";
import { findItemByID } from "../MeetingEditor/functions/utils";
import AddToAgendaDialog from "../../components/Dialogs/AddToAgendaDialog";
import AdminLiveMeetingTopBar from "./components/AdminLiveMeetingTopBar";
import RightPanelContainer from "components/Panels/RightPanelContainer";
import RollCallPanel from "./components/RollCallPanel";
import MotionPanel from "./components/MotionPanel";
import MinutesPanel from "./components/MinutesPanel";
import LiveMeetingHeading from "./components/LiveMeetingHeading";
import LiveMeetingMinutesItem from "./components/LiveMeetingMinutesItem";
import LiveMeetingRecommendation from "./components/LiveMeetingRecommendation";
import LiveMeetingRequestToSpeak from "./components/LiveMeetingRequestToSpeak";
import liveMeetingBottomNotice, { LIVE_MEETING_DATA_TYPE, selectAdministratorsActiveItem } from "./utils/liveMeetingBottomNotice";
import { whiteColor } from "atlas/assets/jss/shared";
import StyledSwitch from "atlas/components/FormControls/StyledSwitch";
import typographyStyle from "atlas/assets/jss/components/typographyStyle";
import AdoptPublishErrorDialog from "components/Dialogs/AdoptPublishErrorDialog";
import notifierMessage from "../../utils/notifierMessage";
import { resetPageConfigs, updatePageConfigs, updateAppbarTools, updateNotice, updateBottomNotice } from "redux/app/actions";
import { updatePageHeader } from "redux/pageHeader/actions";
import { setSnackbarOptions } from "redux/snackBar/actions";
import checkVotingFinished, { checkMeetingQuorumMet, getVotingResults } from "./utils/votingUtils";
import { SettingsContext } from "contexts/Settings/SettingsContext";

const useStyles = makeStyles(() => ({
	agenda: {
		margin: "0",
		padding: "0",
	},
	switchLabel: {
		...typographyStyle.fieldLabel,
		color: "#fff",
		marginRight: "8px",
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
	},
	switchInstructions: {
		...typographyStyle.fieldLabel,
		color: "#fff",
	},
	switch: {
		"& .MuiSwitch-thumb": {
			backgroundColor: whiteColor,
		},
	},
}));

const StyledButton = withStyles({
	root: {
		color: "#ffffff",
		marginTop: 0,
	},
})(Button);

const saveDelay = 2000;

const AdminLiveMeeting = (props) => {
	const { showSignIn } = props;
	const { params: { id } = {} } = useMatch({ path: "/meeting/live/:id", end: true }) || {};
	const navigate = useNavigate();
	const location = useLocation();
	const search = queryString.parse(location.search) || {};
	const action = (getProperty(search, "action") || "").toLowerCase();
	const { t } = useTranslation("meetings");
	const [meetingData, setMeetingData] = useState(null);
	const [placeholders, setPlaceholders] = useState([]);
	const [timers, setTimers] = useState({});
	const [showEditPresetTimersDialog, setShowEditPresetTimersDialog] = useState(false);
	const [showCustomTimerDialog, setShowCustomTimerDialog] = useState(false);
	const [presenting, setPresenting] = useState(false);
	const [selected, setSelected] = useState("");
	const [selectedItems, setSelectedItems] = useState({});
	const [saveStatus, setSaveStatus] = useState({ status: null });
	const [minutesUploadStatus, setMinutesUploadStatus] = useState({});
	const [failedFileUploads, setFailedFileUploads] = useState(null);
	const [failedUploads, setFailedUploads] = useState([]);
	const [panels, setPanels] = useState({
		rollCall: false,
		viewAllNotes: action === "addtoagenda",
	});
	const [dialogs, setDialogs] = useState({});
	const [showAddToAgendaDialog, setShowAddToAgendaDialog] = useState(action === "addtoagenda");
	const [showSupportRequestDialog, setShowSupportRequestDialog] = useState(false);
	const [showMeetingPreviewDialog, setShowMeetingPreviewDialog] = useState(false);
	const [showMoveConsentItemDialog, setShowMoveConsentItemDialog] = useState(false);
	const [consentItemMoving, setConsentItemMoving] = useState(null);
	const [defaultMoveConsentItemSection, setDefaultMoveConsentItemSection] = useState(null);
	const [preview, setPreview] = useState({});
	const [meetingGroups, setMeetingGroups] = useState([]);
	const [votingSettings, setVotingSettings] = useState({});
	const [votingDataResponse, setVotingDataResponse] = useState(null);
	const [onlineVoters, setOnlineVoters] = useState([]);
	const [showRollCallQuorumLostDialog, setShowRollCallQuorumLostDialog] = useState(false);
	const [broadcastIntermissionData, setBroadcastIntermissionData] = useState({ intermission: false, hidden: true });
	const [newMinutes, setNewMinutes] = useState({ enabled: (cookie.load("old_minutes") || "false") === "false" });
	const [adoptPublishError, setAdoptPublishError] = useState(null);

	const dispatch = useDispatch();
	const appReducer = useSelector((state) => state.appReducer);
	const {
		signalR: { client },
		bottomNotice: { data = {} },
	} = appReducer;
	const isMounted = useRef();
	const bottomBarRef = useRef({ presenting, data }); // Avoid caching these values in closures
	const isSaving = useRef(false);
	const isVotingSaving = useRef(false);
	const keepCheckingVoting = useRef(false);
	const pendingUpdates = useRef(null);
	const saveQueue = useRef([]);
	const uploadQueue = useRef([]);
	const panelsRef = useRef(panels);
	const scrollToSelected = useRef(false);
	const classes = useStyles();
	const { testSite } = useContext(SettingsContext);

	const loadTimers = async () => {
		try {
			const response = await request.get(`${API_HOST}/api/users/timers`);
			const { presets, custom } = response.body;

			setTimers({
				presets,
				custom,
			});
		} catch (err) {
			console.log(err);
		}
	};

	const loadMeeting = async () => {
		try {
			const response = await request.get(`${API_HOST}/api/meeting/${id}/getagendaitems`);
			const { items, meeting, requestsToSpeak, agendaNumbering } = response.body;
			const meetingDate = formatDate(null, meeting.startTime, meeting.endTime, t("app:at"), t("from"), t("to"), false);

			const minutesResponse = await request.get(`${API_HOST}/api/meeting/${id}/getminutesitems`);
			const minutesItems = minutesResponse.body.items;
			const minutesNumbering = minutesResponse.body.numbering;

			const meetingGroupsResponse = await request.get(`${API_HOST}/api/boards`);
			const { boards } = meetingGroupsResponse.body;

			const membersResponse = await request.get(`${API_HOST}/api/meeting/${id}/members?simple=true`);
			const members = membersResponse.body;

			// Don't load data if the component has been unmounted
			if (isMounted.current) {
				dispatch(
					updatePageConfigs({
						title: t("liveMeeting.title", { name: meeting.name }),
						back: { url: `/meeting/${id}` },
						telemetryPage: "Live meeting",
						contentMaxWidth: "xl",
					}),
				);

				dispatch(
					updatePageHeader({
						additionalText: [meetingDate],
						additionalRightAction: (
							<div style={{ display: "flex", color: "#fff" }}>
								<StyledSwitch
									classes={{
										label: classes.switchLabel,
										stateLabel: classes.switchInstructions,
										switch: classes.switch,
									}}
									inline
									label={t("newMinutes")}
									useLabel
									title={t("tooltips.newMinutesOn")}
									size="small"
									objectToUpdate={newMinutes}
									fieldToUpdate="enabled"
									onChange={(checked) => handleMinutesSettingChange(checked)}
									data-cy="community-new-minutes-enabled"
								/>
							</div>
						),
					}),
				);
				dispatch(
					updateBottomNotice({
						persistentData: {
							fields: [
								{
									name: "hiddenMeetings",
									action: "pop",
									value: parseInt(id, 10),
								},
							],
						},
						update: true,
					}),
				);

				if (meeting && meeting.rollCall) {
					// Give each user a number to use for the avatar background
					let number = 0;
					const numberCache = {};
					meeting.rollCall.users.forEach((user) => {
						if (typeof numberCache[user.userId] === "undefined") {
							numberCache[user.userId] = number;
							number++;
						}
						user.number = numberCache[user.userId];
					});
				}

				setMeetingData({
					meeting,
					meetingDate,
					meetingActive: meeting.startTimeStamp <= Math.floor(Date.now() / 1000),
					items,
					requestsToSpeak,
					minutesItems,
					members,
					agendaNumbering,
					minutesNumbering,
				});
				setPresenting(meeting.live);

				const minutesItem = minutesItems.find((item) => item.agendaItemGuid === meeting.activeGuid);
				setSelected(minutesItem ? minutesItem.guid : meeting.activeGuid);
				setMeetingGroups(boards);

				if (meeting.live) {
					selectAdministratorsActiveItem(meeting.activeGuid);
				}

				if (meeting.purchasedBoxcast && meeting.broadcasts) {
					getBroadcastDetails(meeting.broadcasts);
				}
			}
		} catch (err) {
			console.log(err);
		}
	};

	const loadVotingSettings = () => {
		request
			.get(`${API_HOST}/api/settings`)
			.query({ type: "voting" })
			.then((res) => {
				if (res.body) {
					setVotingSettings(res.body);
				}
			})
			.catch((err) => {
				if (typeof showSignIn === "function") {
					showSignIn(err, () => {
						loadVotingSettings();
					});
				}
			});
	};

	const getPlaceholdersFromText = (text) => {
		const placeholderTokens = [];

		let startPosition = text.indexOf("[", 0);
		while (startPosition >= 0) {
			const endPosition = text.indexOf("]", startPosition + 1);
			if (endPosition >= 0) {
				placeholderTokens.push(text.substring(startPosition, endPosition + 1));
				startPosition = text.indexOf("[", endPosition + 1);
			} else {
				break;
			}
		}

		return placeholderTokens;
	};

	const findPlaceholders = () => {
		const { minutesItems } = meetingData || {};

		return (minutesItems || []).reduce((placeholderTokens, item) => {
			if (item && item.fields && item.fields.Text && item.fields.Text.Value && item.fields.Text.Value.length) {
				placeholderTokens.push(...getPlaceholdersFromText(item.fields.Text.Value));
			}

			return placeholderTokens;
		}, []);
	};

	const loadPlaceholderValues = () => {
		request
			.post(`${API_HOST}/api/meeting/${id}/placeholders`)
			.send({ placeholders: findPlaceholders() })
			.then((res) => {
				if (res.body) {
					setPlaceholders(res.body);
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const timeStampItem = (item) => {
		request
			.put(`${API_HOST}/api/meeting/${meetingData.meeting.id}/timestampitem`)
			.withCredentials()
			.send({ itemGuid: item.guid, autoTimestamp: true })
			.then((res) => {
				if (res.status === 200 && res.body?.timestamp) {
					const timeStampedItem = meetingData.minutesItems.find((timeStampedItem) => timeStampedItem.guid === res.body?.itemGuid);
					if (timeStampedItem) {
						timeStampedItem.fields.TimeStamp = { Value: res.body.timestamp };
					}
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const intermissionClick = () => {
		request
			.post(`${API_HOST}/api/toggleboxcastintermission`)
			.send({ MeetingId: meetingData.meeting.id, InCamera: !broadcastIntermissionData.intermission })
			.then((res) => {
				if (res.body && res.body.success) {
					let option = notifierMessage(
						!broadcastIntermissionData.intermission ? t("notifications.goToIntermission") : t("notifications.reenablePublicBroadcast"),
						"success",
					);
					dispatch(setSnackbarOptions(option));
					setBroadcastIntermissionData((previous) => ({ ...previous, intermission: !broadcastIntermissionData.intermission }));
				} else {
					let option = notifierMessage(
						!broadcastIntermissionData.intermission ? t("errors.goToIntermission") : t("errors.reenablePublicBroadcast"),
						"success",
					);
					dispatch(setSnackbarOptions(option));
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};
	const setIntermissionButton = (broadcastDetails) => {
		if (broadcastDetails && broadcastDetails.length > 0) {
			var currentlyInCamera = false;
			var minimuMillisecondsToStart = Number.MAX_SAFE_INTEGER;
			var allBroadcastsEnded = true;

			for (var i = 0; i < broadcastDetails.length; i++) {
				var broadcastDetail = broadcastDetails[i];

				if (
					(broadcastDetail.metadata.mixer != null && broadcastDetail.metadata.mixer.muted) ||
					(broadcastDetail.metadata.overlays != null && broadcastDetail.metadata.overlays.length > 0)
				) {
					currentlyInCamera = true;
				}

				minimuMillisecondsToStart =
					broadcastDetail.millisecondsToStart < minimuMillisecondsToStart ? broadcastDetail.millisecondsToStart : minimuMillisecondsToStart;
				allBroadcastsEnded = allBroadcastsEnded && broadcastDetail.streamEnded;
			}

			if (!allBroadcastsEnded) {
				if (minimuMillisecondsToStart > 0) {
					//enable intermission button and set state to not in intermission
					//setBroadcastIntermissionData((previous) => ({ ...previous, intermission: false, disabled: true, hidden: false })); ----- the disabled button does not look styled correct so the button will be hidden instead
					setTimeout(function () {
						setBroadcastIntermissionData((previous) => ({ ...previous, intermission: false, disabled: false, hidden: false }));
					}, minimuMillisecondsToStart);
				} else {
					//enable intermission button and set state to 'currentlyInCamera'
					setBroadcastIntermissionData((previous) => ({ ...previous, intermission: currentlyInCamera, disabled: false, hidden: false }));
				}
			} else {
				//disable intermission button and set state to not in intermission	----- the disabled button does not look styled correct so the button will be hidden instead
				//setBroadcastIntermissionData((previous) => ({ ...previous, intermission: false, disabled: true, hidden: false }));
			}
		}
	};

	const getBroadcastDetails = (broadcasts) => {
		if (broadcasts.length > 0) {
			request
				.post(`${API_HOST}/api/broadcastdetails`)
				.send({ Broadcasts: broadcasts })
				.then((res) => {
					if (res.body) {
						setIntermissionButton(res.body);
					} else {
						//don't show button
						setBroadcastIntermissionData((previous) => ({ ...previous, intermission: false, disabled: false, hidden: true }));
					}
				})
				.catch((err) => {
					console.log(err);
				});
		} else {
			//don't show button
			setBroadcastIntermissionData((previous) => ({ ...previous, intermission: false, disabled: false, hidden: true }));
		}
	};
	const handleTimer = (options) => {
		const { timer, edit, custom } = options;

		if (timer) {
			dispatch(
				updateAppbarTools({
					appbarTools: {
						timer: {
							timer,
							meetingId: parseInt(id, 10),
							startTime: custom ? new Date() : undefined,
						},
					},
				}),
			);
			telemetryAddEvent(`Live meeting - Timer used`);
		}

		if (edit) {
			setShowEditPresetTimersDialog(true);
		}

		if (custom) {
			if (timer) {
				let { custom: customTimers = [] } = timers;
				customTimers.push(timer);
				if (customTimers.length > 2) {
					customTimers = customTimers.slice(-2); // Maximum of two custom timers
				}

				setTimers({
					...timers,
					custom: customTimers,
				});
			} else {
				setShowCustomTimerDialog(true);
			}
		}
	};

	const handleSelect = (e, options) => {
		const { minutesItems = [] } = meetingData;

		const updatedSelected = typeof e === "string" ? e : e.target.value;

		setSelected(updatedSelected);
		scrollToSelected.current = Boolean(options && options.scroll);

		const minutesItem = minutesItems.find((item) => item.guid === updatedSelected);
		client.liveMeetingHub.updateLiveMeeting(id, presenting, minutesItem ? minutesItem.agendaItemGuid : updatedSelected);
		if (presenting && meetingData.meeting.startTimeStamp <= Math.floor(Date.now() / 1000) && !timeStampExists(minutesItem)) {
			timeStampItem(minutesItem);
		}
	};

	const setBottomBar = (thisPage, clear, thisData) => {
		const { dataType = "" } = thisData;

		if (dataType === LIVE_MEETING_DATA_TYPE) {
			dispatch(
				updateBottomNotice(
					!clear
						? liveMeetingBottomNotice({
								dispatch,
								t,
								navigate,
								location: thisPage ? location : "/",
								liveMeeting: thisData,
							})
						: {},
				),
			);
		}
	};
	const handleMinutesSettingChange = (checked) => {
		telemetryAddEvent(`Live meeting - New minutes ${checked ? "on" : "off"}`);

		cookie.save("old_minutes", !checked, { path: "/" });
		setNewMinutes({ enabled: checked });
		if (!checked && window.location.pathname.indexOf("/liveV2/") > 0) {
			navigate(`/meeting/live/${id}`);
		} else if (checked && window.location.pathname.indexOf("/live/") > 0) {
			navigate(`/meeting/liveV2/${id}`);
		}

		dispatch(
			updatePageHeader({
				additionalRightAction: (
					<div style={{ display: "flex", color: "#fff" }}>
						<StyledSwitch
							classes={{
								label: classes.switchLabel,
								stateLabel: classes.switchInstructions,
								switch: classes.switch,
							}}
							inline
							label={t("newMinutes")}
							useLabel
							title={t("tooltips.newMinutesOn")}
							size="small"
							objectToUpdate={newMinutes}
							fieldToUpdate="enabled"
							onChange={(checked) => handleMinutesSettingChange(checked)}
							data-cy="community-new-minutes-enabled"
						/>
					</div>
				),
			}),
		);
	};
	//future work to get video icon aligned correctly
	//const videoNotice = <div>{processHtml(t("liveMeeting.startPresentingNotification"))}<p><span className={classes.videoNotice}><Icon name="video" /></span> {processHtml(t("liveMeeting.startPresentingNotificationEnd"))}</p></div>
	const videoNotice = (
		<div>
			{processHtml(t("liveMeeting.startPresentingNotification"))}
			<p>{processHtml(t("liveMeeting.startPresentingNotificationEnd"))}</p>
		</div>
	);
	const togglePresenting = (updatedPresenting) => {
		dispatch(
			updateNotice({
				status: STATUS_INFO,
				icon: "status-info",
				label: updatedPresenting ? videoNotice : t("liveMeeting.stopPresentingNotification"),
				complexLabel: true,
				placement: BOTTOM_LEFT,
				actions: [ACTION_DISMISS],
			}),
		);

		client.liveMeetingHub.updateLiveMeeting(id, updatedPresenting, selected);

		if (updatedPresenting) {
			window.open(`${API_HOST}/home/meeting/presentation/${id}`, "_blank");
		}

		telemetryAddEvent(`Live meeting - presenting ${updatedPresenting ? "started" : "stopped"}`);

		setPresenting(updatedPresenting);
	};

	const setMotionSaved = () => {
		setPanels((prev) => ({
			...prev,
			motion: {
				...prev.motion,
				saved: true,
			},
		}));
		setSaveStatus({ status: t("agendaMenu:saved") });
	};

	const saveItems = (payload, callback, motion) => {
		if (isSaving.current) {
			saveQueue.current.push(() => saveItems(payload, callback, motion));
		} else {
			if (motion) {
				setSaveStatus({ status: t("agendaMenu:saving") });
			}

			request
				.put(`${API_HOST}/api/meeting/${id}/saveminutesitems`)
				.withCredentials()
				.send(payload)
				.then((res) => {
					if (res.status === 200) {
						isSaving.current = false;
						if (callback && typeof callback === "function") {
							callback();
						}

						if (saveQueue.current.length > 0) {
							saveQueue.current.shift()();
						}

						loadPlaceholderValues();
					}
				})
				.catch((err) => {
					isSaving.current = false;
					showSignIn(err, () => {
						saveItems(payload, callback, motion);
					});
				});
		}
	};

	const accumulateUpdates = (items, itemIdsToDelete, callback, motion) => {
		const accumulatedUpdates = pendingUpdates.current || {
			items: [],
			itemIdsToDelete: [],
			motion: false,
		};

		(items || []).forEach((item) => {
			if (!accumulatedUpdates.items.includes(item)) {
				accumulatedUpdates.items.push(item);
			}
		});
		(itemIdsToDelete || []).forEach((itemId) => {
			if (!accumulatedUpdates.itemIdsToDelete.includes(itemId)) {
				accumulatedUpdates.itemIdsToDelete.push(itemId);
			}
		});
		accumulatedUpdates.callback = callback || accumulatedUpdates.callback;
		accumulatedUpdates.motion = motion || accumulatedUpdates.motion;

		pendingUpdates.current = accumulatedUpdates;
	};

	const updateNotes = (value, item) => {
		if (item) {
			if (typeof value !== "undefined") {
				item.fields.Text.Value = value;
			}

			accumulateUpdates([item], []);
			updateItems();
		}
	};

	const updateItems = debounce(() => {
		const accumulatedUpdates = pendingUpdates.current;

		// Reset the pending updates
		pendingUpdates.current = null;

		if (accumulatedUpdates) {
			const { items = [], itemIdsToDelete = [], callback, motion = false } = accumulatedUpdates;

			saveItems(
				{
					items,
					itemIdsToDelete,
				},
				callback,
				motion,
			);
		}
	}, saveDelay);

	const checkUploadStatus = () => {
		let tooltip = null;
		for (let i = 0; i < uploadQueue.current.length; i++) {
			if (!uploadQueue.current[i].complete) {
				if (tooltip) {
					tooltip += ` / ${uploadQueue.current[i].file.name}`;
				} else {
					tooltip = uploadQueue.current[i].file.name;
				}
			}
		}
	};

	const completeFileUpload = (itemGuid, fileGuid, error) => {
		for (let i = 0; i < uploadQueue.current.length; i++) {
			if (uploadQueue.current[i].guid === itemGuid && uploadQueue.current[i].fileGuid === fileGuid) {
				uploadQueue.current[i].complete = true;
				uploadQueue.current[i].error = error;
				if (uploadQueue.current[i].saveGuid) {
					// Save the item after uploading to ensure that the attachment is associated
					const { minutesItems } = meetingData;

					const item = minutesItems.find((minutesItem) => minutesItem.guid === uploadQueue.current[i].saveGuid);
					if (item && item.itemType === 8) {
						// eslint-disable-next-line no-use-before-define
						updateMotion(item, {});
					} else {
						updateNotes(undefined, item);
					}
				}
			}
		}
		checkUploadStatus();
	};

	const sendFiletoAPI = (fileData, isRetry = false) => {
		request
			.post(`${API_HOST}/api/documents/uploadattachments`)
			.withCredentials()
			.send(fileData)
			.then((res) => {
				if (res.status === 200) {
					setFailedUploads((prev) => [
						...prev.concat(
							(res.body.invalidFiles || []).map((name) => ({
								name,
							})),
						),
					]);
					forEach((attachment) => {
						completeFileUpload(attachment.itemGuid, attachment.guid);
					}, res.body.Attachments);
					checkUploadStatus();
				}
			})
			.catch((err) => {
				const fileError = err.status === 400 || err.status === 500 || isRetry;
				setFailedUploads((prev) => [
					...prev.concat(
						fileError
							? (err.response.body.invalidFiles || []).map((name) => ({
									name,
								}))
							: [],
					),
				]);

				if (!fileError) {
					showSignIn(
						err,
						() => {
							sendFiletoAPI(fileData);
						},
						!isRetry
							? () => {
									// Something went wrong, so wait 5 seconds and try again
									setTimeout(() => {
										sendFiletoAPI(fileData, true);
									}, 5000);
								}
							: undefined,
					);
				}
			});
	};

	const queueFileUploads = (guid, fileUploads, fileData, saveGuid) => {
		for (let i = 0; i < fileUploads.length; i++) {
			uploadQueue.current.push({
				guid,
				fileGuid: fileUploads[i].guid,
				file: fileUploads[i],
				complete: false,
				error: null,
				saveGuid,
			});
		}
		checkUploadStatus();

		setFailedUploads([]);
		sendFiletoAPI(fileData);
	};

	const closeFileUploadFailureDialog = () => {
		setFailedFileUploads(null);
	};

	const saveRollCall = debounce((rollCall) => {
		if (isSaving.current) {
			saveQueue.current.push(() => saveRollCall(rollCall));
		} else {
			setSaveStatus({ status: t("agendaMenu:saving") });

			request
				.put(`${API_HOST}/api/meeting/${id}/rollcall`)
				.withCredentials()
				.send(rollCall)
				.then((res) => {
					if (res.status === 200) {
						isSaving.current = false;

						if (saveQueue.current.length > 0) {
							saveQueue.current.shift()();
						} else {
							setSaveStatus({ status: t("agendaMenu:saved") });
						}

						loadPlaceholderValues();
					}
				})
				.catch((err) => {
					isSaving.current = false;
					showSignIn(err, () => {
						saveRollCall(rollCall);
					});
				});
		}
	}, saveDelay);

	const rollCallClick = () => {
		setPanels((prev) => ({
			rollCall: true,
			motion: {
				...prev.motion,
				show: false,
			},
			viewAllNotes: false,
		}));

		telemetryAddEvent("Live meeting - Open Roll Call");
	};

	const closeRollCallPanel = () => {
		setPanels((prev) => ({
			...prev,
			rollCall: false,
		}));
	};

	const updateRollCall = (e, userId) => {
		const {
			target: { value },
		} = e;
		const {
			meeting: {
				rollCall: { users },
			},
			meeting,
		} = meetingData;

		// Update status
		const user = users.find((user) => user.userId === userId);
		if (user) {
			user.status = parseInt(value, 10);
		}

		// Save roll call
		saveRollCall(meeting.rollCall);

		// Trigger rerender
		setMeetingData((prev) => ({
			...prev,
			meeting: {
				...meeting,
			},
		}));

		if (!checkMeetingQuorumMet(meeting, meeting.rollCall)) {
			setShowRollCallQuorumLostDialog(true);
		}
	};

	const updateVote = (motionGuid, userId, vote) => {
		setMeetingData((prev) => ({
			...prev,
			minutesItems: (prev && prev.minutesItems ? prev.minutesItems : []).map((item) => {
				if (item.guid === motionGuid) {
					if (item.fields.Voting && item.fields.Voting.Value) {
						item.fields.Voting.Value.forEach((motionVoting) => {
							if (motionVoting.UserId === userId && motionVoting.Vote !== vote) {
								motionVoting.Vote = vote;
							}
						});
					}
				}

				return item;
			}),
		}));

		isVotingSaving.current = true;
		saveVote(motionGuid, userId, vote);
	};

	const saveVote = debounce((motionGuid, userId, vote) => {
		if (isSaving.current) {
			saveQueue.current.push(() => saveVote(motionGuid, userId, vote));
		} else {
			setSaveStatus({ status: t("agendaMenu:saving") });

			request
				.post(`${API_HOST}/api/voting/vote/${motionGuid}`)
				.withCredentials()
				.send({ meetingId: meetingData.meeting.id, userId, vote })
				.then((res) => {
					if (res.status === 200) {
						isVotingSaving.current = false;
						isSaving.current = false;
						if (saveQueue.current.length > 0) {
							saveQueue.current.shift()();
						} else {
							setSaveStatus({ status: t("agendaMenu:saved") });
						}
					}
				})
				.catch((err) => {
					isVotingSaving.current = false;
					isSaving.current = false;
					showSignIn(err, () => {
						saveVote(motionGuid, userId, vote);
					});
				});
		}
	}, 500);

	const sendForVote = (motion, hideSnackbar) => {
		request
			.post(`${API_HOST}/api/voting/start/${motion.guid}?meetingId=${meetingData.meeting.id}`)
			.withCredentials()
			.send({})
			.then((res) => {
				if (res.status === 200) {
					if (!hideSnackbar) {
						let option = notifierMessage(t("voting.digitalVotingStarted"), "success");
						dispatch(setSnackbarOptions(option));
					}

					client.votingHub.reSendForVote(meetingData.meeting.id, motion.guid);

					const votingResults = getVotingResults(motion, selectedRollCall ? selectedRollCall : rollCall);
					if (votingResults && votingResults.tie) {
						handleStartTieBreakerVote(motion.guid);
					}
				}
			})
			.catch((err) => {
				showSignIn(err, () => {
					sendForVote(motion);
				});
			});
	};

	const stopVote = (motion, hideSnackbar, callback) => {
		request
			.post(`${API_HOST}/api/voting/stop/${motion.guid}?meetingId=${meetingData.meeting.id}`)
			.withCredentials()
			.send({})
			.then((res) => {
				if (res.status === 200) {
					if (!hideSnackbar) {
						let option = notifierMessage(t("voting.digitalVotingStopped"), "success");
						dispatch(setSnackbarOptions(option));
					}

					if (callback && typeof callback === "function") {
						callback();
					}
				}
			})
			.catch((err) => {
				showSignIn(err, () => {
					stopVote(motion);
				});
			});
	};

	const finishVote = (motion, disposition) => {
		request
			.post(`${API_HOST}/api/voting/finish/${motion.guid}?meetingId=${meetingData.meeting.id}`)
			.withCredentials()
			.send({})
			.then((res) => {
				if (meetingData && meetingData.meeting && meetingData.meeting.digitalVoting) {
					client.votingHub.showResults(meetingData.meeting.id, motion.guid, disposition);
				}
			})
			.catch((err) => {
				showSignIn(err, () => {
					finishVote(motion, disposition);
				});
			});
	};

	const showVotingResults = (motion, disposition) => {
		if (motion) {
			if (votingDataResponse && votingDataResponse.itemInProgress) {
				stopVote({ guid: votingDataResponse.itemInProgress.guid }, true, () => {
					client.votingHub.showResults(meetingData.meeting.id, motion.guid, disposition);
				});
			} else {
				client.votingHub.showResults(meetingData.meeting.id, motion.guid, disposition);
			}
		}
	};

	const startTieBreakerVote = (motion) => {
		request
			.post(`${API_HOST}/api/voting/startTieBreakerVote/${motion.guid}?meetingId=${meetingData.meeting.id}`)
			.withCredentials()
			.send({})
			.then((res) => {})
			.catch((err) => {});
	};

	const checkVotingData = debounce(async () => {
		const res = await request.get(`${API_HOST}/api/voting/votingdata/${id}?page=admin`);
		const { votingInRange } = res.body;

		if (!isVotingSaving.current) {
			setVotingDataResponse((prev) => {
				if (!isEqual(res.body, prev)) {
					return res.body;
				}
				return prev;
			});
		}

		if (keepCheckingVoting.current && votingInRange) {
			checkVotingData();
		}
	}, 2000);

	const motionClick = () => {
		setPanels((prev) => ({
			rollCall: false,
			motion: {
				...prev.motion,
				show: prev.motion ? !prev.motion.show : true,
			},
			viewAllNotes: false,
		}));
	};

	const closeMotionPanel = () => {
		setPanels((prev) => ({
			...prev,
			motion: {
				...prev.motion,
				show: false,
			},
		}));
	};

	const addMotion = (parentItem, order) => {
		const { items, minutesItems, meeting } = meetingData;

		const agendaItem = items.find((item) => item.guid === parentItem.agendaItemGuid) || {};
		const recommendation = items.find((item) => item.itemType === 7 && item.attributes.relationshipGuid === agendaItem.guid) || {
			fields: {
				Name: { Value: "" },
				Text: { Value: "" },
			},
		};

		// Find the insertion index
		let found = false;
		let index = 0;
		for (; index < minutesItems.length; index++) {
			if (!found && minutesItems[index].guid === parentItem.guid) {
				// Found the parent
				found = true;
			} else if (found && minutesItems[index].itemType !== 8) {
				// Found the next non-motion (or the end of the array)
				break;
			}
		}

		minutesItems.splice(
			index,
			0,
			createMeetingElement(
				{
					itemType: 8,
					order,
					name: recommendation.fields.Name.Value,
					text: recommendation.fields.Text.Value,
					closed: parentItem.fields.Closed.Value,
					parentGuid: parentItem.guid,
					recordedVote: meeting.alwaysUseRecordedVote,
				},
				false,
			),
		);

		setMeetingData({ ...meetingData });
	};

	const updateMotion = (motion, updatedFields) => {
		// eslint-disable-next-line no-param-reassign
		motion.fields = {
			...motion.fields,
			...updatedFields,
		};

		accumulateUpdates([motion], [], setMotionSaved, true);
		updateItems();
	};

	const deleteMotion = (motion) => {
		const { minutesItems } = meetingData;

		const index = minutesItems.findIndex((item) => item.guid === motion.guid);

		if (index >= 0) {
			minutesItems.splice(index, 1);
			setMeetingData({ ...meetingData });
		}

		accumulateUpdates([], [motion.guid], setMotionSaved, true);
		updateItems();
	};

	const viewAllNotesClick = () => {
		setPanels((prev) => ({
			rollCall: false,
			motion: {
				...prev.motion,
				show: false,
			},
			viewAllNotes: !prev.viewAllNotes,
		}));
	};

	const closeMinutesPanel = () => {
		setPanels((prev) => ({
			...prev,
			viewAllNotes: false,
		}));
	};

	const addMinutesToAgenda = () => {
		setShowAddToAgendaDialog(true);
	};

	const downloadMinutes = async (members, onComplete) => {
		request
			.post(`${API_HOST}/api/meeting/${id}/download`)
			.withCredentials()
			.send({ agenda: false, members })
			.then((res) => {
				if (res.status === 200) {
					downloadFile(res.body.name, "application/vnd.openxmlformats-officedocument.wordprocessingml.document", res.body.document);
				}

				onComplete();
			})
			.catch((err) => {
				console.log(err);

				onComplete();
			});
	};

	const uploadMinutes = (member) => {
		const fileInput = document.getElementById(`upload-${member ? "member" : "public"}-minutes-file`);
		if (fileInput) {
			fileInput.value = null;
			fileInput.click();
		}
	};

	const handleMinutesUpload = (_e, member) => {
		setMinutesUploadStatus({ uploading: true });

		const fileInput = document.getElementById(`upload-${member ? "member" : "public"}-minutes-file`);
		const selectedFile = fileInput.files[0];
		const fileData = new FormData();
		fileData.append(selectedFile.name, selectedFile);
		fileData.append(
			"data",
			JSON.stringify({
				members: member,
			}),
		);

		request
			.post(`${API_HOST}/api/meeting/${meetingData.meeting.id}/uploadminutes`)
			.withCredentials()
			.send(fileData)
			.then((res) => {
				if (res.status === 200) {
					let option = notifierMessage(t(`notifications.minutesUploaded`), "success");
					dispatch(setSnackbarOptions(option));

					setMinutesUploadStatus({ uploaded: true });
				} else {
					setMinutesUploadStatus({});
				}
			})
			.catch((err) => {
				console.log(err);

				setMinutesUploadStatus({});
			});
	};

	const adoptPublishPreviousMinutes = async (previousMeetingId) => {
		const guid = uuid();

		request
			.post(`${API_HOST}/api/meeting/${previousMeetingId}/adopt`)
			.withCredentials()
			.send({ progressGuid: guid, agenda: false })
			.then((res) => {
				if (res.status === 200) {
					if (res && res.body && res.body.pdf && res.body.pdf.errors) {
						setAdoptPublishError(previousMeetingId);
					} else {
						let option = notifierMessage(t("approveMeetingDialog.success.minutes"), "success");
						dispatch(setSnackbarOptions(option));
					}
				}
			})
			.catch((err) => {
				setAdoptPublishError(previousMeetingId);
			});
	};

	const openDraftAdoptMinutesToSign = async (previousMeetingId) => {
		const guid = uuid();

		const action = (key) => (
			<>
				<StyledButton
					onClick={() => {
						navigate(`/meeting/liveV2/${previousMeetingId}`);
					}}
				>
					Details
				</StyledButton>
			</>
		);

		request
			.post(`${API_HOST}/api/meeting/${previousMeetingId}/createdrafttosign`)
			.withCredentials()
			.send({ progressGuid: guid, agenda: false })
			.then((res) => {
				if (res.status === 200) {
					if (res && res.body && res.body.pdf && res.body.pdf.errors) {
						setAdoptPublishError(previousMeetingId);
					} else {
						navigate(`/meeting/adopt/${previousMeetingId}/minutes`);
					}
				}
			})
			.catch((err) => {
				console.log(err);
				setAdoptPublishError(previousMeetingId);
			});
	};

	// Support Request dialog ------------------------------------------------------------------
	const openSupportRequestDialog = () => {
		setShowEditPresetTimersDialog(false);
		setShowCustomTimerDialog(false);
		setShowAddToAgendaDialog(false);
		setShowMeetingPreviewDialog(false);
		setShowSupportRequestDialog(true);
	};

	const closeSupportRequestDialog = () => setShowSupportRequestDialog(false);

	const openMeetingPreviewDialog = (agenda, members) => {
		setShowMeetingPreviewDialog(true);
		setPreview({
			agenda,
			members,
		});
	};

	const closeMeetingPreviewDialog = () => {
		setShowMeetingPreviewDialog(false);
		setPreview({});
	};

	const previewMemberMinutes = () => {
		telemetryAddEvent("Live meeting - Preview member minutes");

		openMeetingPreviewDialog(false, true);
	};

	const previewPublicMinutes = () => {
		telemetryAddEvent("Live meeting - Preview public minutes");

		openMeetingPreviewDialog(false, false);
	};

	const closeDialogs = () => {
		setDialogs({});
	};

	const getMinutesPreviewOptions = () => [
		{
			label: t("memberMinutes"),
			ariaLabel: t("tooltips.previewMemberMinutes"),
			actionFunction: previewMemberMinutes,
			"data-cy": "previewMemberMinutes",
		},
		{
			label: t("publicMinutes"),
			ariaLabel: t("tooltips.previewPublicMinutes"),
			actionFunction: previewPublicMinutes,
			"data-cy": "previewPublicMinutes",
		},
	];

	const hasMotions = (minutesItem) => {
		const { minutesItems } = meetingData;
		const motions = minutesItems.filter((item) => item.itemType === 8 && item.attributes.relationshipGuid === minutesItem.guid);

		return motions.length > 0
			? {
					approved: motions.every(
						(motion) => motion.fields.MovedBy.Value > 0 && motion.fields.SecondedBy.Value > 0 && motion.fields.Disposition.Value.length > 0,
					),
				}
			: null;
	};

	const addRequestsToSpeak = (filteredItems, headingData, defaultPublicCommentHeading) => {
		const { requestsToSpeak } = meetingData;
		const { item, numberOfChildren } = headingData;

		if (item) {
			const agendaItem = getAgendaItem(item);

			requestsToSpeak
				.filter(
					(requestToSpeak) =>
						requestToSpeak.headingGuid === agendaItem.guid ||
						(requestToSpeak.headingGuid.length === 0 &&
							defaultPublicCommentHeading &&
							defaultPublicCommentHeading.guid === agendaItem.guid),
				)
				.forEach((requestToSpeak, index) => {
					const requestToSpeakFilteredItem = filteredItems.filter((filteredItem) => filteredItem.guid === requestToSpeak.guid);
					if (requestToSpeakFilteredItem == null || requestToSpeakFilteredItem.length === 0) {
						filteredItems.push({
							...requestToSpeak,
							order: numberOfChildren + index + 1,
							fields: {
								Consent: {
									Value: agendaItem.fields.Consent.Value,
								},
								PublicComment: {
									Value: agendaItem.fields.PublicComment.Value,
								},
								Closed: {
									Value: agendaItem.fields.Closed.Value,
								},
							},
						});
					}
				});
		}
	};

	const openMoveConsentItemDialog = (consentItem) => {
		setConsentItemMoving(consentItem);
		setShowMoveConsentItemDialog(true);
	};

	const closeMoveConsentItemDialog = () => {
		setConsentItemMoving(null);
		setShowMoveConsentItemDialog(false);
		loadMeeting();
	};

	const getSelectedItem = () => {
		const { minutesItems = [] } = meetingData;
		const selectedGuid = selected.substring(0, 36);

		return (
			minutesItems.find((item) => item.guid === selectedGuid || item.attachments.find((attachment) => attachment.guid === selectedGuid)) ||
			{}
		);
	};

	const getSiblingItems = () => {
		const { minutesItems = [] } = meetingData;
		const selectedGuid = selected.substring(0, 36);
		const siblings = {
			previousItem: null,
			nextItem: null,
		};

		// Find heading or item index
		const index = minutesItems.findIndex(
			(item) => item.guid === selectedGuid || item.attachments.find((attachment) => attachment.guid === selectedGuid),
		);
		if (index > 0) {
			// Find previous heading or item
			for (let previousIndex = index - 1; previousIndex >= 0; previousIndex--) {
				if ([4, 11].includes(minutesItems[previousIndex].itemType)) {
					siblings.previousItem = minutesItems[previousIndex];
					break;
				}
			}
		}
		if (index < minutesItems.length - 1) {
			// Find next heading or item
			for (let nextIndex = index + 1; nextIndex < minutesItems.length; nextIndex++) {
				if ([4, 11].includes(minutesItems[nextIndex].itemType)) {
					siblings.nextItem = minutesItems[nextIndex];
					break;
				}
			}
		}

		return siblings;
	};

	const getMinutesHeadingOrItem = (selectedItem) => {
		const { minutesItems = [] } = meetingData;

		// Find heading or item
		let minutesItem = selectedItem || getSelectedItem();
		if (minutesItem.itemType && ![4, 11].includes(minutesItem.itemType)) {
			minutesItem = minutesItems.find((item) => item.guid === minutesItem.attributes.relationshipGuid) || {};
		}

		return minutesItem;
	};

	const getMinutesItem = (selectedItem) => {
		const { minutesItems = [] } = meetingData;
		// eslint-disable-next-line no-param-reassign
		selectedItem = selectedItem || getSelectedItem();

		return minutesItems.find((item) => item.agendaItemGuid === selectedItem.guid) || {};
	};

	const getAgendaItem = (minutesItem) => {
		const { items = [] } = meetingData;

		return items.find((item) => minutesItem.agendaItemGuid === item.guid || minutesItem.headingGuid === item.guid) || { attachments: [] };
	};

	const getSelectedMotions = (minutesItem) => {
		const { minutesItems = [] } = meetingData;
		// eslint-disable-next-line no-param-reassign
		minutesItem = minutesItem || getMinutesHeadingOrItem();

		return minutesItems.filter((item) => item.itemType === 8 && item.attributes.relationshipGuid === minutesItem.guid);
	};

	const getSelectedItems = () => {
		const item = getSelectedItem();
		const motionParentItem = getMinutesHeadingOrItem(item);

		return {
			item,
			...getSiblingItems(),
			motionParentItem,
			motions: getSelectedMotions(motionParentItem),
		};
	};

	const hasNotes = (item) => {
		const minutesItem = [MinutesItemTypesEnum().HEADING.value, MinutesItemTypesEnum().ITEM.value].includes(item.itemType) ? item : null;

		return minutesItem && minutesItem.fields && (minutesItem.fields.Text.Value || "").length > 0;
	};

	const getDefaultPublicCommentHeading = (items) =>
		items.find((item) => !item.deleted && item.itemType === AgendaItemTypesEnum().HEADING.value && item.fields.PublicComment.Value);

	const getItems = () => {
		const {
			items,
			minutesItems,
			meeting: { closed: isClosedMeeting },
			meetingActive,
		} = meetingData;
		let isConsentHeading = false;
		let isPublicCommentHeading = false;
		let isMemberOnlyHeading = false;
		let videoExists = false;
		if (meetingData?.meeting.purchasedBoxcast && meetingData?.meeting.broadcasts) {
			//any broadcast with youtube video
			if (meetingData?.meeting.broadcasts.length > 0 && meetingData?.meeting.broadcasts[0].youtubeId) {
				videoExists = true;
			}
		} else if (meetingData?.meeting?.youtubeLink && meetingData?.meeting?.youtubeLink != null) {
			videoExists = true;
		}

		const defaultPublicCommentHeading = getDefaultPublicCommentHeading(items);
		const filteredItems = [];
		const previous = {
			heading: {
				item: null,
				numberOfChildren: 0,
			},
		};

		minutesItems.forEach((item) => {
			if (!item.deleted) {
				filteredItems.push(item);
				if (item.itemType === MinutesItemTypesEnum().HEADING.value) {
					// Top level heading
					previous.heading = {
						item,
						numberOfChildren: !item.attributes.relationshipGuid ? 0 : previous.heading.numberOfChildren,
					};
					addRequestsToSpeak(filteredItems, previous.heading, defaultPublicCommentHeading);
				} else if (previous.heading.item && item.itemType === MinutesItemTypesEnum().ITEM.value) {
					// Count the children of the heading
					previous.heading.numberOfChildren++;
				}
			}
		});
		addRequestsToSpeak(items, previous.heading, defaultPublicCommentHeading); // Add requests to the last heading if needed
		const lastIndex = filteredItems.length - 1;

		return filteredItems.map((minutesItem, index) => {
			const item = getAgendaItem(minutesItem);
			isConsentHeading = minutesItem && minutesItem.fields && minutesItem.fields.Consent && minutesItem.fields.Consent.Value;
			isPublicCommentHeading =
				(minutesItem && minutesItem.fields && minutesItem.fields.PublicComment && minutesItem.fields.PublicComment.Value) ||
				(item && item.fields && item.fields.PublicComment && item.fields.PublicComment.Value);
			isMemberOnlyHeading = item && item.fields && item.fields.Closed.Value;

			if (typeof minutesItem.topic === "string") {
				// Request to speak
				return (
					<LiveMeetingRequestToSpeak
						key={minutesItem.guid}
						requestToSpeak={minutesItem}
						isMemberOnlyHeading={isMemberOnlyHeading}
						isConsentHeading={isConsentHeading}
						isPublicCommentHeading={isPublicCommentHeading}
						addBottomBorder={index === lastIndex}
						selected={selected}
						handleSelect={handleSelect}
					/>
				);
			}

			if (minutesItem.itemType === MinutesItemTypesEnum().HEADING.value) {
				return (
					<LiveMeetingHeading
						key={minutesItem.guid}
						heading={minutesItem}
						isClosedMeeting={isClosedMeeting}
						isMemberOnlyHeading={isMemberOnlyHeading}
						isConsentHeading={isConsentHeading}
						isPublicCommentHeading={isPublicCommentHeading}
						isSubHeading={Boolean(minutesItem.attributes.relationshipGuid)}
						hasMotions={hasMotions(minutesItem)}
						addBottomBorder={index === lastIndex}
						selected={selected}
						hasNotes={hasNotes(minutesItem)}
						handleSelect={handleSelect}
						presenting={presenting}
						videoExists={videoExists}
					/>
				);
			}

			if (minutesItem.itemType === MinutesItemTypesEnum().ITEM.value) {
				return (
					<LiveMeetingMinutesItem
						key={minutesItem.guid}
						agendaItem={item}
						minutesItem={minutesItem}
						isClosedMeeting={isClosedMeeting}
						isMemberOnlyHeading={isMemberOnlyHeading}
						isConsentHeading={isConsentHeading}
						isPublicCommentHeading={isPublicCommentHeading}
						hasMotions={hasMotions(minutesItem)}
						addBottomBorder={index === lastIndex}
						selected={selected}
						hasNotes={hasNotes(minutesItem)}
						handleSelect={handleSelect}
						moveConsentItemClick={meetingActive ? openMoveConsentItemDialog : undefined}
						testSite={testSite}
						presenting={presenting}
						videoExists={videoExists}
					/>
				);
			}

			if (minutesItem.itemType === MinutesItemTypesEnum().RESOLUTION.value) {
				const parent = findItemByID(minutesItem.attributes.relationshipGuid, items);

				return (
					<LiveMeetingRecommendation
						key={minutesItem.guid}
						recommendation={minutesItem}
						isClosedMeeting={isClosedMeeting}
						isMemberOnlyHeading={isMemberOnlyHeading}
						isConsentHeading={isConsentHeading}
						isPublicCommentHeading={isPublicCommentHeading}
						isHeadingAction={parent && parent.itemType === MinutesItemTypesEnum().HEADING.value}
						addBottomBorder={index === lastIndex}
						selected={selected}
						hasNotes={hasNotes(minutesItem)}
						handleSelect={handleSelect}
					/>
				);
			}

			return <div key={`item${item.guid}`}>{item.fields.Name.Value}</div>;
		});
	};

	useEffect(() => {
		if (votingDataResponse != null) {
			const { onlineMembers, itemInProgress, votingInRange } = votingDataResponse;
			if (votingInRange) {
				if (onlineMembers && !isEqual(onlineMembers, onlineVoters)) {
					setOnlineVoters(onlineMembers);
				}

				if (itemInProgress && itemInProgress.itemVotingData && itemInProgress.status != 2 && meetingData && meetingData.minutesItems) {
					setMeetingData((prev) => ({
						...prev,
						minutesItems: (prev && prev.minutesItems ? prev.minutesItems : []).map((item) => {
							if (item.guid === itemInProgress.guid) {
								if (item.fields.Voting && item.fields.Voting.Value) {
									item.fields.Voting.Value.forEach((motionVoting) => {
										const serverVotingData = itemInProgress.itemVotingData.find(
											(itemVotingData) => itemVotingData.UserId === motionVoting.UserId,
										);
										if (serverVotingData && motionVoting.Vote !== serverVotingData.Vote) {
											motionVoting.Vote = serverVotingData.Vote;
										}
									});
								} else {
									item.fields.Voting = { Value: itemInProgress.itemVotingData };
								}

								const votingFinished = checkVotingFinished(item, meetingData.meeting.rollCall);
								if (votingSettings) {
									const selectedRollCall = meetingData.meeting.otherRollCallTypes
										? meetingData.meeting.otherRollCallTypes.find((r) => r.id == item.fields.SelectedRollCallId)
										: null;

									const votingResults = getVotingResults(
										item,
										selectedRollCall ? selectedRollCall : meetingData.meeting.rollCall,
										meetingData.meeting,
									);
									if (votingFinished) {
										const disposition = votingResults.quorumMet
											? votingResults.votePassed
												? votingSettings.votingLabels.carried
												: votingSettings.votingLabels.failed
											: t("voting.quorumNotMet");
										if (item.fields.Disposition.Value != disposition) {
											item.fields.Disposition.Value = disposition;
											item.forceUpdate = true; // Force the motion component to update it's fields
										}
										accumulateUpdates([item], [], setMotionSaved, true);
										updateItems();
										finishVote(item, disposition);
									} else if (votingResults && votingResults.tie) {
										startTieBreakerVote(item);
									}
								}
							}

							return item;
						}),
					}));
				}
			}
		}
	}, [votingDataResponse]);

	useEffect(() => {
		if (failedUploads && failedUploads.length > 0) {
			setDialogs({
				failedUploads,
			});
		}
	}, [failedUploads]);

	useEffect(() => {
		// Keep this reference up to date
		bottomBarRef.current = { presenting, data };
	}, [presenting, data]);

	useEffect(() => {
		panelsRef.current = panels;

		if (panels.motion) {
			if (!panels.motion.show && panels.motion.saved) {
				let option = notifierMessage(t("motions.snackbar.saved"), "success");
				dispatch(setSnackbarOptions(option));
				setPanels({
					...panels,
					motion: null,
				});
			}
		}
	}, [panels]);

	useEffect(() => {
		dispatch(resetPageConfigs({}));
		loadTimers();
		loadMeeting();
		loadVotingSettings();

		// Update the bottom bar
		setBottomBar(true, false, bottomBarRef.current.data);

		telemetryAddEvent("Live Meeting - Load");

		return () => {
			dispatch(updateNotice({}));

			// Reset the bottom bar
			setBottomBar(false, !bottomBarRef.current.presenting, bottomBarRef.current.data);

			dispatch(resetPageConfigs());

			keepCheckingVoting.current = false;
			checkVotingData.cancel();
		};
	}, [id]);

	useEffect(() => {
		if (meetingData) {
			setSelectedItems(getSelectedItems());
		}
		if (selected && selected.length >= 36) {
			var guid = selected.substring(0, 36);
			const element = document.getElementById(`agenda-${guid}`) || document.getElementById(`minutes-${guid}`);
			if (element && scrollToSelected.current) {
				element.scrollIntoView();
				scrollToSelected.current = false;
			}
		}
	}, [selected, meetingData]);

	useEffect(() => {
		loadPlaceholderValues();
		if (meetingData && meetingData.meeting.digitalVoting && !keepCheckingVoting.current) {
			keepCheckingVoting.current = true;
			checkVotingData();
		}
	}, [meetingData]);

	useEffect(() => {
		isMounted.current = true;

		return () => {
			isMounted.current = false;

			dispatch(updatePageHeader({ additionalRightAction: undefined }));
		};
	}, []);

	return meetingData ? (
		<>
			{showSupportRequestDialog && <SupportRequestDialog show={showSupportRequestDialog} onClose={closeSupportRequestDialog} />}

			<FileUploadFailureDialog
				show={failedFileUploads && failedFileUploads.length > 0}
				failedFileUploads={failedFileUploads}
				onClose={closeFileUploadFailureDialog}
			/>
			{dialogs.failedUploads && <UploadErrorDialog failedUploads={dialogs.failedUploads} onClose={closeDialogs} />}
			{showEditPresetTimersDialog && (
				<TimerPresetEditDialog
					show
					timers={timers}
					onClose={() => setShowEditPresetTimersDialog(false)}
					afterSave={(newPresets) =>
						setTimers({
							...timers,
							presets: newPresets,
						})
					}
				/>
			)}
			{showCustomTimerDialog && (
				<TimerCustomDialog
					show
					onClose={() => setShowCustomTimerDialog(false)}
					afterSave={(timer) =>
						handleTimer({
							timer,
							custom: true,
						})
					}
				/>
			)}
			{showAddToAgendaDialog && (
				<AddToAgendaDialog
					meetingId={id}
					meetingGroupId={meetingData.meeting.boardId}
					meetingDate={meetingData.meeting.date}
					meetingStartTime={meetingData.meeting.startTime}
					publicMinutesHtml={meetingData.meeting.publicDraftMinutesHtml}
					membersMinutesHtml={meetingData.meeting.membersDraftMinutesHtml}
					show
					fromLive
					meetingGroups={meetingGroups}
					onClose={() => setShowAddToAgendaDialog(false)}
					showSignIn={showSignIn}
				/>
			)}

			{showMeetingPreviewDialog && (
				<MeetingPreviewDialog
					show={showMeetingPreviewDialog}
					meeting={meetingData.meeting}
					agenda={preview.agenda}
					members={preview.members}
					onClose={closeMeetingPreviewDialog}
					showSignIn={showSignIn}
					location={location}
					openSupportRequestDialog={openSupportRequestDialog}
				/>
			)}
			{showMoveConsentItemDialog && (
				<MoveConsentItemDialog
					show={showMoveConsentItemDialog}
					meetingId={meetingData.meeting.id}
					item={consentItemMoving}
					onClose={closeMoveConsentItemDialog}
					items={meetingData.minutesItems}
					numbering={meetingData.minutesNumbering}
					defaultMoveConsentItemSection={defaultMoveConsentItemSection}
					setDefaultMoveConsentItemSection={setDefaultMoveConsentItemSection}
				/>
			)}
			{showRollCallQuorumLostDialog && (
				<GenericDialog
					show
					title={t("rollCallQuorumLostDialog.title")}
					primaryAction={() => {
						setShowRollCallQuorumLostDialog(false);
					}}
					primaryTitle={t("rollCallQuorumLostDialog.buttonText")}
					secondaryAction={null}
					secondaryTitle={null}
					closeIcon={<Icon name="close" />}
					data-cy="roll-call-quorum-lost"
				>
					<Typography>{t("rollCallQuorumLostDialog.body")}</Typography>
				</GenericDialog>
			)}
			<ComponentContainer padding="16px">
				<AdminLiveMeetingTopBar
					presenting={presenting}
					setPresenting={togglePresenting}
					timers={timers}
					broadcastIntermissionData={broadcastIntermissionData}
					intermissionClick={intermissionClick}
					handleTimer={handleTimer}
					rollCallClick={rollCallClick}
					motionClick={motionClick}
					viewAllNotesClick={viewAllNotesClick}
					panels={panels}
				/>
				<ul id="item-list" className={classes.agenda}>
					{getItems()}
				</ul>
				<RightPanelContainer
					id="right-panel-container"
					open={panels.rollCall || (panels.motion && panels.motion.show) || panels.viewAllNotes}
					fullHeight={panels.rollCall || panels.viewAllNotes}
					xsFullWidth
				>
					{panels.rollCall && (
						<RollCallPanel
							rollCall={meetingData.meeting.rollCall}
							closePanel={closeRollCallPanel}
							handleUpdate={updateRollCall}
							saveStatus={saveStatus.status}
							onlineVoters={onlineVoters}
							votingSettings={votingSettings}
							digitalVoting={meetingData.meeting.digitalVoting}
						/>
					)}
					{panels.motion && panels.motion.show && (
						<MotionPanel
							meetingId={id}
							meetingDate={meetingData.meeting.date}
							meeting={meetingData.meeting}
							closed={meetingData.meeting.closed}
							selectedItems={panels.motion && panels.motion.show ? selectedItems : undefined}
							rollCall={meetingData.meeting.rollCall}
							rollCallTypes={meetingData.meeting.otherRollCallTypes}
							members={meetingData.members}
							closePanel={closeMotionPanel}
							handleSelect={handleSelect}
							addMotion={addMotion}
							updateMotion={updateMotion}
							deleteMotion={deleteMotion}
							queueFileUploads={queueFileUploads}
							saveStatus={saveStatus.status}
							updateVote={updateVote}
							sendForVote={sendForVote}
							finishVote={finishVote}
							stopVote={stopVote}
							showVotingResults={showVotingResults}
							digitalVoting={meetingData.meeting.digitalVoting}
							votingSettings={votingSettings}
							votingInRange={meetingData.meeting.votingInRange}
							onlineVoters={onlineVoters}
							adoptPublishPreviousMinutes={adoptPublishPreviousMinutes}
							openDraftAdoptMinutesToSign={openDraftAdoptMinutesToSign}
							startTieBreakerVote={startTieBreakerVote}
						/>
					)}
					{panels.viewAllNotes && (
						<MinutesPanel
							closeMinutesPanel={closeMinutesPanel}
							items={{
								minutesItems: meetingData.minutesItems,
								requestsToSpeak: meetingData.requestsToSpeak,
								defaultPublicCommentHeading: getDefaultPublicCommentHeading(meetingData.items),
							}}
							placeholders={placeholders}
							addToAgendaClick={addMinutesToAgenda}
							downloadClick={downloadMinutes}
							uploadClick={uploadMinutes}
							handleMinutesUpload={handleMinutesUpload}
							previewMinutesOptions={getMinutesPreviewOptions()}
							minutesUploadStatus={minutesUploadStatus}
							members={meetingData.members}
							votingSettings={votingSettings}
						/>
					)}
					{adoptPublishError > 0 && (
						<AdoptPublishErrorDialog
							meetingId={adoptPublishError}
							handleCancel={() => {
								setAdoptPublishError(null);
							}}
						/>
					)}
				</RightPanelContainer>
			</ComponentContainer>
		</>
	) : (
		<CircularProgressIndicator />
	);
};

export default AdminLiveMeeting;
