import React from "react";
import { useTranslation } from "react-i18next";

import makeStyles from "@mui/styles/makeStyles";

import { formatDate } from "utils/date";

const defaultFont = {
	fontSize: "11px",
	fontWeight: "400",
	lineHeight: "16px",
	letterSpacing: "1px",
};

const useStyles = makeStyles({
	accessLog: {
		...defaultFont,
		padding: "4px 16px 4px 16px",
	},
});

const DocumentDetailsAccessLogEntry = (props) => {
	const { accessLog } = props;
	const { t } = useTranslation();
	const classes = useStyles();

	return accessLog ? (
		<div className={classes.accessLog}>
			<div tabIndex={0}>{formatDate(accessLog.dateAccessed, accessLog.dateAccessed, undefined, t("at"), "", "", true, true, false)}</div>
			<div tabIndex={0}>{accessLog.name}</div>
			<div tabIndex={0}>{accessLog.ipAddress}</div>
		</div>
	) : null;
};

export default DocumentDetailsAccessLogEntry;
