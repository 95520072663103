import React, { useEffect, useRef, useState } from "react";
import { infoColor } from "atlas/assets/jss/shared";
import Icon from "atlas/components/Icon/Icon";
import { TableCell } from "@mui/material";
import tableDataStyle from "assets/jss/components/tableDataStyle";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";
import Tooltip from "atlas/components/Tooltip/Tooltip";

const useStyles = makeStyles({
	...tableDataStyle,
	content: {
		display: "flex",
		alignItems: "center",
	},
	title: {
		textOverflow: "ellipsis",
		whiteSpace: "nowrap",
		overflow: "hidden",
		width: "120px",
	},
});

const DraftTitle = (props) => {
	const { row, className } = props;
	const classes = useStyles();
	const textElementRef = useRef();
	const [hoverStatus, setHover] = useState(false);

	useEffect(() => {
		if (textElementRef.current) {
			const compare = textElementRef.current.scrollWidth > textElementRef.current.clientWidth;
			setHover(compare);
		}
	}, []);

	return (
		<TableCell className={clsx(classes.cell, className)}>
			<div className={classes.content}>
				<Tooltip disableHoverListener={!hoverStatus} placement={"top"} title={row.name}>
					<div className={classes.title} ref={textElementRef}>
						{row.name}
					</div>
				</Tooltip>
				<a href={`/home/policydoc/${row.parentGuid}`} target="_blank" title={row.name}>
					<Icon name="external-link" size="24px" color={infoColor} />
				</a>
			</div>
		</TableCell>
	);
};

export default DraftTitle;
