export function getPlaceholdersFromText(text) {
	const placeholderTokens = [];

	let startPosition = text.indexOf("[", 0);
	while (startPosition >= 0) {
		const endPosition = text.indexOf("]", startPosition + 1);
		if (endPosition >= 0) {
			placeholderTokens.push(text.substring(startPosition, endPosition + 1));
			startPosition = text.indexOf("[", endPosition + 1);
		} else {
			break;
		}
	}

	return placeholderTokens;
}

export function findPlaceholders(minutesItems) {
	return (minutesItems || []).reduce((placeholderTokens, item) => {
		if (item && item.fields && item.fields.Text && item.fields.Text.Value && item.fields.Text.Value.length) {
			placeholderTokens.push(...getPlaceholdersFromText(item.fields.Text.Value));
		}

		return placeholderTokens;
	}, []);
}
