import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import makeStyles from "@mui/styles/makeStyles";

import Icon from "atlas/components/Icon/Icon";
import GenericDialog from "atlas/components/Dialogs/GenericDialog";
import CircularProgressIndicator from "atlas/components/Progress/CircularProgressIndicator";

const useStyles = makeStyles(() => ({
	dialog: {
		"& .MuiDialog-paper": {
			width: "440px",
			maxWidth: "100%",
		},
	},
	instructions: {
		marginBottom: "8px",
	},
}));

const CancelApprovalDialog = (props) => {
	const { show, item, onCancel, onClose } = props;
	const { t } = useTranslation("agendaItems");
	const [canceling, setCanceling] = useState(false);
	const classes = useStyles();

	return (
		<GenericDialog
			className={classes.dialog}
			show={show}
			title={t("cancelApprovalDialog.title")}
			primaryAction={() => {
				setCanceling(true);
				onCancel(item);
			}}
			primaryTitle={canceling ? <CircularProgressIndicator size={20} minHeight="20px" /> : t("cancelApprovalDialog.buttons.cancelApproval")}
			primaryDisabled={canceling}
			secondaryAction={onClose}
			secondaryTitle={t("cancelApprovalDialog.buttons.cancel")}
			secondaryDisabled={canceling}
			clickAwayDisabled={canceling}
			closeIcon={<Icon name="close" />}
			data-cy="agenda-item-cancel-approval-dialog"
		>
			<div className={classes.instructions}>{t("cancelApprovalDialog.instructions")}</div>
		</GenericDialog>
	);
};

export default CancelApprovalDialog;
