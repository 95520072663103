import React, { useState, useEffect, useRef, useContext, useCallback } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation, useMatch } from "react-router-dom";
import request from "superagent";
import queryString from "query-string";
import { isValid, format } from "date-fns";
import isEqual from "lodash/fp/isEqual";

import makeStyles from "@mui/styles/makeStyles";
import { Button, MenuItem } from "@mui/material";

import ComponentContainer from "atlas/components/ComponentContainer/ComponentContainer";
import OutlinedDatePicker from "atlas/components/FormControls/OutlinedDatePicker";
import RadioButton from "atlas/components/FormControls/RadioButton";
import SelectInput from "atlas/components/FormControls/SelectInput";
import { API_HOST } from "config/env";
import { SettingsContext } from "contexts/Settings/SettingsContext";
import { UserContext } from "contexts/User/UserContext";
import { resetPageConfigs, updatePageConfigs, updateAppbarTools } from "redux/app/actions";

import getDateRange, {
	ISO_DATE_FORMAT,
	DATE_RANGE_NONE,
	DATE_RANGE_TODAY,
	DATE_RANGE_THIS_WEEK,
	DATE_RANGE_THIS_MONTH,
	DATE_RANGE_LAST_MONTH,
	DATE_RANGE_CUSTOM,
} from "utils/customDateRange";
import { stripHtml } from "utils/processHtml";
import { useUpdateObject } from "utils/updateObject";
import { getFolderType } from "views/Documents/utils/pathType";
import SearchTopBar from "./components/SearchTopBar";
import SearchResults from "./components/SearchResults";
import telemetryAddEvent from "utils/telemetryAddEvent";
import LegendDialog from "../../components/Dialogs/LegendDialog";
import { FOLDER_TYPE_CUSTOM } from "utils/enums/DocumentFolderTypes";

const useStyles = makeStyles({
	filter: {
		padding: "0 16px",
		display: "flex",
		alignItems: "center",
		flexWrap: "wrap",
		"& > div:not(:first-child)": {
			marginLeft: "16px",
			marginRight: "16px",
		},
	},
	selectInput: {
		minWidth: "150px",
		height: "auto",
	},
	dateInput: {
		minWidth: "150px",
		width: "150px",
		height: "auto",
		marginTop: "-16px",
		"& .MuiInputLabel-outlined:not(.MuiFormLabel-filled)": {
			transform: "translate(14px, 28px)",
		},
		"& .MuiOutlinedInput-root": {
			height: "40px",
			"& .MuiOutlinedInput-input": {
				boxSizing: "border-box",
				height: "40px",
				paddingTop: "10.5px",
				paddingBottom: "10.5px",
			},
		},
	},
	button: {
		marginBottom: "24px",
	},
});

const telemetryPage = "Search";
const TYPE_OPTION_SYSTEM_FOLDERS = 2;
const TYPE_OPTION_AGENDAS = 4;
const TYPE_OPTION_MINUTES = 5;
const TYPE_OPTION_AGENDA_ITEMS = 6;
const TYPE_OPTION_GOALS = 7;
const TYPE_OPTION_REQUESTS_TO_SPEAK = 8;
const TYPE_OPTION_PORTAL_ITEMS = 9;
const TYPE_OPTION_POLICIES = 10;

const defaultTotals = {
	total: 0,
	policies: 0,
	agendas: 0,
	minutes: 0,
	agendaItems: 0,
	goals: 0,
	requestsToSpeak: 0,
	portalItems: 0,
};

const SearchModule = (props) => {
	const { showSignIn, topRow, fullWidth, isPolicy } = props;
	const { params: { rootFolder: rootFolderNoId } = {} } =
		useMatch({ path: "/documents/search/:rootFolder", end: true }) || useMatch({ path: "/policy/search/:rootFolder", end: true }) || {};
	const { params: { rootFolder: rootFolderId, id } = {} } =
		useMatch({ path: "/documents/search/:rootFolder/:id", end: true }) ||
		useMatch({ path: "/policy/search/:rootFolder/:id", end: true }) ||
		{};
	const rootFolder = rootFolderNoId || rootFolderId;
	const folderType = getFolderType(rootFolder);
	const systemFolderSearch = rootFolder && folderType;
	const { t } = useTranslation();
	const navigate = useNavigate();
	const location = useLocation();
	const [results, setResults] = useState(null);
	const [totals, setTotals] = useState({ ...defaultTotals });
	const [page, setPage] = useState(0);
	const [filter, setFilter] = useState(null);
	const [show, setShow] = useState(false);
	const [dateSettings, setDateSettings] = useState({
		date: {
			from: null,
			to: null,
		},
		focused: {
			from: false,
			to: false,
		},
	});
	const [keyWords, setKeyWords] = useState(null);
	const { dateFormat, policyEnabled, communityMeetings } = useContext(SettingsContext);
	const { portalAdmin } = useContext(UserContext).user;
	const lastSearch = useRef(null);
	const dispatch = useDispatch();
	const classes = useStyles();

	const handleToggleLegend = () => {
		telemetryAddEvent("Document icon infos - Open legend");

		setShow((prev) => !prev);
	};

	const getFilterFromQueryString = (queryString) =>
		queryString
			? {
					fileType: queryString.fileType || "",
					customDateRange: !systemFolderSearch ? parseInt(queryString.customDateRange || 0, 10) : undefined,
					from: !systemFolderSearch ? queryString.from || "" : undefined,
					to: !systemFolderSearch ? queryString.to || "" : undefined,
					type: !systemFolderSearch ? parseInt(queryString.type || 0, 10) : undefined,
				}
			: null;

	const sanitizeFilter = (prev) => {
		if (prev.customDateRange === DATE_RANGE_NONE) {
			delete prev.from;
			delete prev.to;
		} else {
			let dateRange = null;
			if (prev.customDateRange === DATE_RANGE_TODAY) {
				// Today
				dateRange = getDateRange("today");
			} else if (prev.customDateRange === DATE_RANGE_THIS_WEEK) {
				// This week
				dateRange = getDateRange("thisWeek");
			} else if (prev.customDateRange === DATE_RANGE_THIS_MONTH) {
				// This month
				dateRange = getDateRange("thisMonth");
			} else if (prev.customDateRange === DATE_RANGE_LAST_MONTH) {
				// This month
				dateRange = getDateRange("lastMonth");
			}
			if (dateRange) {
				prev.from = format(dateRange.from, ISO_DATE_FORMAT);
				prev.to = format(dateRange.to, ISO_DATE_FORMAT);
			}
		}
	};

	const updateFilter = useUpdateObject(setFilter, sanitizeFilter);

	const updateLocation = (searchOptions) => {
		const searchParams = new URLSearchParams("");
		searchParams.set("keywords", searchOptions.keyWords);
		if (searchOptions.filter.fileType) {
			searchParams.set("fileType", searchOptions.filter.fileType);
		}
		if (!systemFolderSearch) {
			if (searchOptions.filter.customDateRange > 0) {
				searchParams.set("customDateRange", searchOptions.filter.customDateRange);
				if (searchOptions.filter.from) {
					searchParams.set("from", searchOptions.filter.from);
				}
				if (searchOptions.filter.to) {
					searchParams.set("to", searchOptions.filter.to);
				}
			}
			if (searchOptions.filter.type > 0) {
				searchParams.set("type", searchOptions.filter.type);
			}
		}
		const search = searchParams.toString();
		const searchurl = isPolicy
			? systemFolderSearch && id && search
				? `/policy/search/${rootFolder}/${id}?${search}`
				: `/search?${search}`
			: `${systemFolderSearch ? "/documents" : ""}/search${systemFolderSearch ? `/${rootFolder}${id ? `/${id}` : ""}` : ""}${
					search ? "?" : ""
				}${search}`;
		navigate(searchurl);
	};

	const handleDateChange = (field) => (date) => {
		updateFilter({ target: { value: date && isValid(date) ? format(date, "yyyy-MM-dd") : date } }, field);

		setDateSettings((prev) => ({
			...prev,
			date: {
				...prev.date,
				[field]: date,
			},
		}));
	};

	const handleSearch = useCallback(
		(search, pageLoadResolver) => {
			lastSearch.current = search;

			if (search && search.keyWords && search.keyWords.length > 0) {
				if (!pageLoadResolver) {
					setResults(null); // New search/filter, so show the loading indicator
				} else {
					search.page = page + 1; // Get the next page
				}

				request
					.get(`${API_HOST}/api/items/search`)
					.query(search)
					.then((res) => {
						const { results, total, agendas, minutes, agendaItems, goals, requestsToSpeak, portalItems, policies } = res.body;
						if (!pageLoadResolver) {
							if (!communityMeetings) {
								setResults(results.filter((obj) => obj.policy === true));
							} else setResults(results || []);

							setTotals({
								total,
								policies,
								agendas,
								minutes,
								agendaItems,
								goals,
								requestsToSpeak,
								portalItems,
							});
							setPage(1);
						} else {
							if (communityMeetings) {
								setResults((prev) => (prev || []).concat(results || []));
							} else {
								setResults((prev) => (prev || []).concat(results.filter((obj) => obj.policy === true) || []));
							}
							pageLoadResolver();
							setPage((prev) => prev + 1);
						}
					})
					.catch((err) => {
						showSignIn(
							err,
							() => {
								handleSearch(search, pageLoadResolver);
							},
							() => {
								setResults([]);
								setTotals({ ...defaultTotals });
							},
						);
					});
			} else {
				setResults([]);
			}
		},
		[page],
	);

	const handleToolbarSearch = useCallback(
		(searchKeyWords) => {
			updateLocation({ keyWords: searchKeyWords, filter });
		},
		[filter],
	);

	const handleAttachmentSearch = (search, meetingId) => {
		if (search && search.keyWords && search.keyWords.length > 0 && meetingId > 0) {
			const { searchTypes, page, ...attachmentSearch } = search;
			attachmentSearch.attachedToMeetingId = meetingId;
			attachmentSearch.pageSize = 100; // Assuming a max of 100 attachments for now

			request
				.get(`${API_HOST}/api/items/search`)
				.query(attachmentSearch)
				.then((res) => {
					const { results: attachmentResults } = res.body;
					setResults((prev) => {
						prev.forEach((result) => {
							if (result.document && result.agenda && result.meetingId === meetingId) {
								result.attachments = attachmentResults || [];
							}
						});

						return [...prev];
					});
					setResults(results || []);
				})
				.catch((err) => {
					showSignIn(err, () => {
						handleAttachmentSearch(search, meetingId);
					});
				});
		}
	};

	const loadMore = () =>
		new Promise((resolve) => {
			handleSearch(lastSearch.current, resolve);
		});

	const handleExpand = (result) => {
		result.expanded = !result.expanded;
		if (result.expanded && typeof result.attachments === "undefined") {
			handleAttachmentSearch(lastSearch.current, result.meetingId);
		}

		setResults((prev) => [...prev]);

		if (result.expanded) {
			telemetryAddEvent("Search - Expand Result");
		}
	};

	const hasSearchChanged = (prev, current) =>
		current &&
		(!prev ||
			current.keyWords !== prev.keyWords ||
			current.fileType !== prev.fileType ||
			current.from !== prev.from ||
			current.to !== prev.to ||
			!isEqual(current.searchTypes, prev.searchTypes));

	useEffect(() => {
		dispatch(resetPageConfigs({}));
	}, []);

	useEffect(() => {
		if (keyWords != null && filter != null) {
			const search = {
				keyWords,
				includeTotals: true,
				fileType: filter.fileType ? filter.fileType : undefined,
				from: filter.from ? filter.from : undefined,
				to: filter.to ? filter.to : undefined,
				searchTypes: systemFolderSearch ? [TYPE_OPTION_SYSTEM_FOLDERS] : filter.type > 0 ? [filter.type] : undefined,
				folderTypes: systemFolderSearch ? (Array.isArray(folderType) ? folderType.join(",") : folderType) : undefined,
				location: systemFolderSearch ? id : undefined,
			};
			if (hasSearchChanged(lastSearch.current, search)) {
				handleSearch(search);

				updateLocation({ keyWords, filter });
			}
		}
	}, [keyWords, filter]);

	useEffect(() => {
		if (!systemFolderSearch && keyWords !== null) {
			dispatch(
				updateAppbarTools({
					appbarTools: {
						keyWords,
					},
				}),
			);
		}
	}, [keyWords]);

	useEffect(() => {
		const queryStringValues = queryString.parse(location.search) || {};
		setFilter(getFilterFromQueryString(queryStringValues));
		setKeyWords(
			queryStringValues && queryStringValues.keywords ? stripHtml(decodeURIComponent(encodeURIComponent(queryStringValues.keywords))) : "",
		);

		dispatch(
			updatePageConfigs({
				title: t("search.title"),
				telemetryPage,
				back: {
					action: () => {
						navigate(-1);

						return false;
					},
				},
			}),
		);
	}, [location.search]);

	const getFileTypes = () =>
		[
			{ text: t("search.options.all"), value: "all" },
			{ text: t("search.options.word"), value: "word" },
			{ text: t("search.options.pdf"), value: "pdf" },
			{ text: t("search.options.ppt"), value: "ppt" },
			{ text: t("search.options.image"), value: "img" },
			{ text: t("search.options.other"), value: "other" },
		].map((option) => (
			<MenuItem key={option.key || option.value} value={option.value} data-cy={`filter-file-type-${option.value}`}>
				{option.text}
			</MenuItem>
		));

	const getDateRangeOptions = () =>
		[
			{ text: t("options.none"), value: DATE_RANGE_NONE },
			{ text: t("options.today"), value: DATE_RANGE_TODAY },
			{ text: t("options.thisWeek"), value: DATE_RANGE_THIS_WEEK },
			{ text: t("options.thisMonth"), value: DATE_RANGE_THIS_MONTH },
			{ text: t("options.lastMonth"), value: DATE_RANGE_LAST_MONTH },
			{ text: t("options.custom"), value: DATE_RANGE_CUSTOM },
		].map((option) => (
			<MenuItem key={option.value} value={option.value} data-cy={`filter-date-range-${option.value}`}>
				{option.text}
			</MenuItem>
		));

	const getTypeOptions = useCallback(() => {
		const typeOptions = [
			{ text: t(`search.options.allCount`, { count: totals.total }), value: 0, key: "all" },
			{ text: t(`search.options.agendasCount`, { count: totals.agendas }), value: TYPE_OPTION_AGENDAS },
			{ text: t(`search.options.minutesCount`, { count: totals.minutes }), value: TYPE_OPTION_MINUTES },
			{
				text: t(`search.options.agendaItemsCount`, { count: totals.agendaItems }),
				value: TYPE_OPTION_AGENDA_ITEMS,
			},
			{ text: t(`search.options.goalsCount`, { count: totals.goals }), value: TYPE_OPTION_GOALS },
			{
				text: t(`search.options.requestsToSpeakCount`, { count: totals.requestsToSpeak }),
				value: TYPE_OPTION_REQUESTS_TO_SPEAK,
			},
		];

		if (portalAdmin) {
			typeOptions.push({
				text: t(`search.options.portalItemsCount`, { count: totals.portalItems }),
				value: TYPE_OPTION_PORTAL_ITEMS,
				tooltip: t(`search.tooltips.portalItems`),
			});
		}
		if (policyEnabled) {
			typeOptions &&
				typeOptions.splice(1, 0, { text: t(`search.options.policies`, { count: totals.policies }), value: TYPE_OPTION_POLICIES });
		}
		return typeOptions;
	}, [totals]);

	const getSearchTypeTelemetryValue = (value) => {
		let processedValue = typeof value === "string" ? parseInt(value, 10) : value;

		switch (processedValue) {
			case TYPE_OPTION_AGENDAS:
				return "Agendas";

			case TYPE_OPTION_MINUTES:
				return "Minutes";

			case TYPE_OPTION_AGENDA_ITEMS:
				return "Agenda items";

			case TYPE_OPTION_GOALS:
				return "Goals";

			case TYPE_OPTION_REQUESTS_TO_SPEAK:
				return "RTS";

			case TYPE_OPTION_PORTAL_ITEMS:
				return "Public Site";

			case TYPE_OPTION_POLICIES:
				return "Policies";

			default:
				return "All";
		}
	};

	const onClearSearchClick = () => {
		if (isPolicy) {
			navigate(`/policy/policybook/${id}`);
		} else {
			if (folderType === FOLDER_TYPE_CUSTOM) {
				navigate(`/documents/${rootFolder}/${id}`);
			} else {
				navigate(`/documents/${rootFolder}`);
			}
		}
	};

	return (
		<ComponentContainer padding="0">
			{systemFolderSearch && (
				<SearchTopBar keywords={keyWords} handleSearch={handleToolbarSearch} isPolicy={isPolicy} onClearSearchClick={onClearSearchClick} />
			)}
			{filter && ((results && results.length > 0) || filter.fileType || filter.customDateRange || filter.type > 0) && (
				<>
					<div className={classes.filter}>
						{communityMeetings && !props.isPolicy && (
							<SelectInput
								id="filter-file-type"
								className={classes.selectInput}
								fullWidth
								size="small"
								labelSize="large"
								label={t("search.fileType")}
								value={filter.fileType ? filter.fileType : ""}
								onChange={(e) => {
									updateFilter(e, "fileType");

									telemetryAddEvent("Search - File type");
								}}
								data-cy="filter-file-type"
							>
								{getFileTypes()}
							</SelectInput>
						)}
						<SelectInput
							id="filter-date-range"
							className={classes.selectInput}
							fullWidth
							size="small"
							labelSize="large"
							label={t("search.dateRange")}
							value={filter.customDateRange || 0}
							onChange={(e) => {
								updateFilter(e, "customDateRange", false, true);

								telemetryAddEvent("Search - Date range");
							}}
							data-cy="filter-date-range"
						>
							{getDateRangeOptions()}
						</SelectInput>
						{communityMeetings && !systemFolderSearch && (
							<Button
								className={topRow ? "padded-paper-top-margin no-right-margin" : fullWidth ? "small-top-margin" : null || classes.button}
								fullWidth={fullWidth}
								variant="outlined"
								color="primary"
								onClick={handleToggleLegend}
								data-cy="legend"
							>
								{t("app:search.legendDialog.buttons.legend")}
							</Button>
						)}
					</div>
					{filter.customDateRange === DATE_RANGE_CUSTOM && (
						<div className={classes.filter}>
							<div>
								<OutlinedDatePicker
									label={t("search.from")}
									dateFormat={dateFormat}
									value={filter.from}
									onChange={handleDateChange("from")}
									dataCy="filter-date-range-from"
								></OutlinedDatePicker>
							</div>
							<div>
								<OutlinedDatePicker
									label={t("search.to")}
									dateFormat={dateFormat}
									value={filter.to}
									minDate={dateSettings.date.from}
									onChange={handleDateChange("to")}
									dataCy="filter-date-range-to"
								></OutlinedDatePicker>
							</div>
						</div>
					)}
					{communityMeetings && !systemFolderSearch && (
						<div className={classes.filter}>
							<RadioButton
								row
								options={getTypeOptions()}
								getId={(option) => `search-type-${option.key || option.value}`}
								getKey={(option) => option.key || option.value}
								getValue={(option) => option.value}
								getDataCy={(option) => `search-type-${option.key || option.value}`}
								getLabel={(option) => option.text}
								handleChange={(value) => {
									updateFilter({ target: { value } }, "type", false, true);

									telemetryAddEvent(`Search - ${getSearchTypeTelemetryValue(value)}`);
								}}
								objectToUpdate={filter}
								field="type"
							/>
						</div>
					)}
				</>
			)}
			<SearchResults results={results} total={totals.total} loadMore={loadMore} handleExpand={handleExpand} isPolicy={props.isPolicy} />
			<LegendDialog show={show} closeAction={() => handleToggleLegend(true)} />
		</ComponentContainer>
	);
};

export default SearchModule;
