import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import makeStyles from "@mui/styles/makeStyles";
import OutlinedInput from "atlas/components/FormControls/OutlinedInput";
import Icon from "atlas/components/Icon/Icon";
import GenericDialog from "atlas/components/Dialogs/GenericDialog";
import ellipsedTruncateText from "utils/ellipsedTruncateText";

const useStyles = makeStyles(() => ({
	dialog: {
		"& .MuiDialog-paper": {
			width: "480px",
			maxWidth: "100%",
		},
	},
}));

const AddPolicyDialog = ({ show, onClose, onSubmit, setPolicyDetails, isEllipsed = false }) => {
	const { t } = useTranslation("documents");
	const [policyData, setPolicyData] = useState({
		code: "",
		title: "",
	});
	const [adding, setAdding] = useState(false);
	const classes = useStyles();
	const onlySpacesRegex = /^\s*$/;

	const validateInput = (title) => {
		if (title.length < 1 || onlySpacesRegex.test(title)) return true;
		return false;
	};

	const onChangeFunc = (key, value) => {
		if (key === "title" && value === " ") {
			return;
		}
		setPolicyData((prev) => ({
			...prev,
			[key]: value,
		}));
		setPolicyDetails((prev) => ({
			...prev,
			[key]: value,
		}));
	};

	const handleCancel = () => {
		onClose();
	};

	const dialogConfig = {
		title: t("addFolderDialog.titleForNewPolicy"),
		primaryTitle: t("app:buttons.create"),
		primaryAction: onSubmit,
		secondaryTitle: t("app:buttons.cancel"),
		secondaryAction: handleCancel,
	};

	return (
		<GenericDialog
			ellipsedTruncatedText={ellipsedTruncateText(dialogConfig.title)}
			isEllipsed={isEllipsed}
			className={classes.dialog}
			show={show}
			title={dialogConfig.title}
			primaryAction={dialogConfig.primaryAction}
			primaryTitle={dialogConfig.primaryTitle}
			primaryDisabled={validateInput(policyData.title) || adding}
			secondaryAction={dialogConfig.secondaryAction}
			secondaryTitle={dialogConfig.secondaryTitle}
			secondaryDisabled={adding}
			clickAwayDisabled={adding}
			closeIcon={<Icon name="close" />}
			data-cy="add-policy-dialog"
		>
			<OutlinedInput
				id="code"
				label={t("addFolderDialog.labels.code")}
				placeholder={t("addFolderDialog.placeholders.code")}
				value={policyData.code}
				onChange={(e) => onChangeFunc("code", e.target.value)}
				noDefaultClassName
				fullWidth
				autoFocus
				size="small"
				data-cy="code"
			/>
			<OutlinedInput
				id="name"
				label={t("addFolderDialog.labels.name")}
				placeholder={t("addFolderDialog.placeholders.name")}
				value={policyData.title}
				onChange={(e) => onChangeFunc("title", e.target.value)}
				noDefaultClassName
				fullWidth
				size="small"
				data-cy="name"
			/>
		</GenericDialog>
	);
};

export default AddPolicyDialog;
