import React from "react";
import clsx from "clsx";

import makeStyles from "@mui/styles/makeStyles";

import userListStyle from "assets/jss/components/userListStyle";
import ApproversListBlock from "./ApproversListBlock";

const useUserListStyles = makeStyles(userListStyle);
const useStyles = makeStyles(() => ({
	list: {
		maxHeight: "200px",
		overflowX: "auto",
		margin: "0 -24px -16px -24px",
		padding: "0 24px",
	},
}));

const ApproversList = (props) => {
	const { workflow } = props;
	const classes = useStyles();
	const userListClasses = useUserListStyles();

	const singleBlock = workflow && workflow.blocks.length === 1;
	const hasMultipleUsersInABlock = workflow && workflow.blocks.find((block) => block.users.length > 1);

	return (
		workflow && (
			<ol className={clsx(userListClasses.list, classes.list)}>
				{workflow.blocks.map((block) => (
					<ApproversListBlock
						key={`workflow-block-${block.id}`}
						block={block}
						usersOnly={singleBlock || block.users.length == 1}
						indentUsers={!singleBlock && hasMultipleUsersInABlock}
					/>
				))}
			</ol>
		)
	);
};

export default ApproversList;
