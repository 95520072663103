import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import clsx from "clsx";

import makeStyles from "@mui/styles/makeStyles";

import { grayColor } from "atlas/assets/jss/shared";
import Icon from "atlas/components/Icon/Icon";
import Tooltip from "atlas/components/Tooltip/Tooltip";
import PolicyDetailsActivity from "./PolicyDetailsActivity";
import PolicyDetailsInfo from "./PolicyDetailsInfo";
import PolicyDetailsTimeline from "./PolicyDetailsTimeline";
import { useNavigate } from "react-router-dom";
import PolicyDeleteDialog from "./PolicyDeleteDialog";
import { useDispatch, useSelector } from "react-redux";
import { setSnackbarOptions } from "redux/snackBar/actions";
import notifierMessage from "utils/notifierMessage";
import telemetryAddEvent from "utils/telemetryAddEvent";
import { PublicDocumentContext } from "views/Policy/PolicyContainer";
import DocumentCompare from "views/DocumentCompare/DocumentCompare";
import Popper from "@mui/material/Popper";
const ICON_WIDTH = 48;

const titleFont = {
	fontSize: "12px",
	fontWeight: "400",
	lineHeight: "16px",
	letterSpacing: "1px",
};

const useStyles = makeStyles({
	details: {
		height: "100%",
		display: "flex",
		flexDirection: "column",
	},
	sectionTitle: {
		boxSizing: "border-box",
		borderBottom: `solid 1px ${grayColor[2]}`,
		height: `${ICON_WIDTH}px`,
		display: "flex",
		alignItems: "center",
		cursor: "pointer",
		"&:hover, &:focus": {
			backgroundColor: "rgba(0, 0, 0, 0.04)",
		},
	},
	sectionTitleIcon: {
		width: `${ICON_WIDTH}px`,
		minWidth: `${ICON_WIDTH}px`,
		justifyContent: "center",
		display: "flex",
		alignItems: "center",
	},
	sectionTitleText: {
		...titleFont,
		boxSizing: "border-box",
		width: `calc(100% - ${ICON_WIDTH}px)`,
		maxWidth: `calc(100% - ${ICON_WIDTH}px)`,
		height: `${ICON_WIDTH - 1}px`,
		padding: "16px 16px 16px 0",
	},
	sectionTitleDocumentName: {
		fontWeight: "600",
		overflow: "hidden",
		textOverflow: "ellipsis",
		whiteSpace: "nowrap",
		padding: "16px 0 16px 16px",
	},
	comparePopper: {
		width: "100vw",
		height: "100vh",
		position: "fixed",
		zIndex: "5001 !important",
		top: "0px",
		left: "0px",
		"& .MuiPaper-root": {
			margin: "0 auto",
		},
	},
	webviewerContainer: {
		height: "100%",
	},
});

const PolicyDetails = (props) => {
	const {
		policyDetail,
		timeline,
		history,
		accessLogs,
		expanded,
		handleExpandCollapse,
		handleRename,
		handleDownload,
		handleReplace,
		handleCopyMove,
		handleLoadTimeline,
		loadMoreHistory,
		toggleFieldChanges,
		handleView,
		handleRestore,
		loadMoreAccessLogs,
		handleExport,
		handlePublishPolicy,
		handleunpublishPolicy,
		openMenu,
		handleMenuClose,
		openPolicyInfo,
		closePolicyInfo,
		handleSelectedPolicies,
		selectedPolicies,
		backButtonClicked,
	} = props;
	const { t } = useTranslation("documents");
	const [sections, setSections] = useState({});
	const [dialogs, setDialogs] = useState({});
	const classes = useStyles();
	const [showCompareWindow, setShowCompareWindow] = useState(false);
	const [compareUrls, setCompareUrls] = useState({ url1: "", url2: "" });
	const [compareDocuments, setCompareDocuments] = useState({ document1: null, document2: null });
	const [headerTitle, setHeaderTitle] = useState(null);
	const navigate = useNavigate();
	const dispatch = useDispatch();

	useEffect(() => {
		if (!expanded) {
			setSections({});
		}
	}, [expanded]);

	const { publishedPermissionsData: { publishedWrite, publishedRead, unpublished } = {} } = useSelector(
		(state) => state.policyGovernanceReducer,
	);
	const ispublicDocument = useContext(PublicDocumentContext);

	const toggleSection = (section) => {
		setSections((prev) => ({ [section]: !prev[section] }));
		closePolicyInfo();
	};

	const getHandleClick = (section) =>
		section
			? () => {
					if (!expanded) {
						handleExpandCollapse();
					}
					if (section === "policyTimeline" && !sections.policyTimeline) {
						telemetryAddEvent(`Policy - view timeline`);
					}
					if (section === "activity" && !sections.activity) {
						telemetryAddEvent(`Policy - view activity`);
					}
					toggleSection(section);
				}
			: handleExpandCollapse;

	const handleKeyDown = (e, section) => {
		if (e.key === " " || e.key === "Enter") {
			if (section) {
				if (!expanded) {
					handleExpandCollapse();
				}
				toggleSection(section);
			} else {
				handleExpandCollapse();
			}
		}
	};

	const deleteClick = (event, policy) => {
		if (event === "delete") {
			setDialogs({ delete: policy });
		}
	};
	const telemetryPage = "Policy Details";

	const afterDelete = async (policy, parentGuid) => {
		telemetryAddEvent(`${telemetryPage} - Deleted document`, { area: "policy" });
		let option = notifierMessage("Deleted Successfully", "success");
		await dispatch(setSnackbarOptions(option));
		if (window.history.length > 1) {
			navigate(-1);
		}
	};

	const undoDelete = (policy, parentGuid) => {
		request
			.post(`${API_HOST}/api/document/${policy.guid}/restore`)
			.withCredentials()
			.send({})
			.then(() => {
				let option = notifierMessage(t("deleteDocumentDialog.undo.successful"), "success");
				dispatch(setSnackbarOptions(option));
			})
			.catch((err) => {
				showSignIn(err, () => {
					undoDelete(policy, parentGuid);
				});
			});
	};

	const closeDialogs = () => {
		setDialogs({});
	};

	const getSectionTitle = (section, icon, label) => (
		<div
			role="button"
			className={classes.sectionTitle}
			onClick={getHandleClick(section)}
			onKeyDown={(e) => handleKeyDown(e, section)}
			tabIndex="0"
			data-cy={`section-${section}`}
		>
			<div className={classes.sectionTitleIcon}>
				<Icon name={icon} size="20" />
			</div>
			<div className={classes.sectionTitleText}>{label}</div>
		</div>
	);

	const documentTitle = `${policyDetail.title}${policyDetail.extension}`;

	const disableCompareWindow = () => {
		setShowCompareWindow(false);
		setCompareUrls({ url1: "", url2: "" });
		setCompareDocuments({ document1: null, document2: null });
	};

	const setComparePopperDetails = (url1, url2, document1, document2, title) => {
		setShowCompareWindow(true);
		setCompareUrls({
			url1: url1,
			url2: url2,
		});
		setCompareDocuments({
			document1: document1,
			document2: document2,
		});
		setHeaderTitle(title);
	};

	return (
		policyDetail && (
			<div className={classes.details}>
				<div
					role="button"
					className={classes.sectionTitle}
					onClick={getHandleClick()}
					onKeyDown={handleKeyDown}
					tabIndex="0"
					data-cy="expand-details"
				>
					{expanded && (
						<div className={clsx(classes.sectionTitleText, classes.sectionTitleDocumentName)} data-cy="details-name">
							<Tooltip title={documentTitle}>
								<span>{documentTitle}</span>
							</Tooltip>
						</div>
					)}
					<div className={classes.sectionTitleIcon}>
						<Icon name={expanded ? "expand-right" : "expand-left"} size="20" />
					</div>
				</div>
				{getSectionTitle("info", "info", t("detail.labels.policyinfo"))}
				{((expanded && sections.info) || openPolicyInfo) && (
					<PolicyDetailsInfo
						openMenu={openMenu}
						handleMenuClose={handleMenuClose}
						document={policyDetail}
						handleRename={handleRename}
						handleDownload={handleDownload}
						handleReplace={handleReplace}
						handleCopyMove={handleCopyMove}
						deleteClick={deleteClick}
						handlePublishPolicy={handlePublishPolicy}
						handleunpublishPolicy={handleunpublishPolicy}
					/>
				)}
				{!ispublicDocument && (
					<>
						{getSectionTitle("policyTimeline", "policy-site", t("detail.labels.policyTimeline"))}
						{expanded && sections.policyTimeline && (
							<PolicyDetailsTimeline
								timeline={timeline}
								loadTimeline={handleLoadTimeline}
								policyDetail={policyDetail}
								setComparePopperDetails={setComparePopperDetails}
								handleSelectedPolicies={handleSelectedPolicies}
								selectedPolicies={selectedPolicies}
								backButtonClicked={backButtonClicked}
							/>
						)}
					</>
				)}
				{!publishedRead && !ispublicDocument && (
					<>
						{getSectionTitle("activity", "activity", t("detail.labels.activity"))}
						{expanded && sections.activity && (
							<PolicyDetailsActivity
								document={policyDetail}
								history={history}
								accessLogs={accessLogs}
								loadMoreHistory={loadMoreHistory}
								toggleFieldChanges={toggleFieldChanges}
								handleView={handleView}
								handleRestore={handleRestore}
								loadMoreAccessLogs={loadMoreAccessLogs}
								handleExport={handleExport}
								setComparePopperDetails={setComparePopperDetails}
							/>
						)}
					</>
				)}
				{dialogs.delete && (
					<PolicyDeleteDialog document={dialogs.delete} onClose={closeDialogs} afterDelete={afterDelete} undoDelete={undoDelete} />
				)}
				{showCompareWindow && compareUrls.url1 && compareUrls.url2 && (
					<Popper
						id="document-compare"
						className={classes.comparePopper}
						open={true}
						role="presentation"
						modifiers={[
							{
								name: "preventOverflow",
								enabled: true,
								options: {
									boundariesElement: "viewport",
								},
							},
						]}
					>
						<DocumentCompare
							styleClass={classes.webviewerContainer}
							url1={compareUrls.url1}
							url2={compareUrls.url2}
							compareDocumentsDetails={compareDocuments}
							disableCompareWindow={disableCompareWindow}
							policyTitle={policyDetail.title}
							headerTitle={headerTitle}
						/>
					</Popper>
				)}
			</div>
		)
	);
};

export default PolicyDetails;
