import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import request from "superagent";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";

import { API_HOST } from "config/env";
import CircularProgressIndicator from "atlas/components/Progress/CircularProgressIndicator";
import { Close } from "components/Icons";
import OutlinedInput from "atlas/components/FormControls/OutlinedInput";
import GenericDialog from "atlas/components/Dialogs/GenericDialog";
import notifierMessage from "utils/notifierMessage";
import { setSnackbarOptions } from "../../redux/snackBar/actions";

class SendFeedbackDialog extends Component {
	state = {
		feedback: "",
		errors: {},
		sendingFeedback: false,
	};

	handleFieldChange = (e) => {
		const { errors = {} } = this.state;
		const { name, value } = e.target;
		const { [name]: thisError, ...otherErrors } = errors; // clear error for this entry

		this.setState(
			{
				[name]: value,
				errors: otherErrors,
			},
			() => this.validate(name),
		);
	};

	handleSend = () => {
		const { feedback, errors } = this.state;
		const { t, dispatch } = this.props;

		this.validate();
		if (Object.keys(errors).length) return;

		this.setState({ sendingFeedback: true });
		request
			.post(`${API_HOST}/api/system/feedback`)
			.withCredentials()
			.send({
				feedback,
			})
			.then(() => {
				let option = notifierMessage(t("sendFeedback.successMessage"), "success");
				dispatch(setSnackbarOptions(option));
				this.setState({ sendingFeedback: false }, () => {
					this.handleCancel();
				});
			})
			.catch((err) => this.handleError(err));
	};

	handleCancel = () => {
		this.setState(
			{
				feedback: "",
				errors: {},
			},
			this.props.onClose,
		);
	};

	handleError = (err) => {
		console.log("Error", err);
		this.props.onClose();
	};

	validate = (singleField = null) => {
		const { t } = this.props;
		const { feedback } = this.state;
		let { errors } = this.state;

		let valid = true;

		if (!singleField || singleField === "feedback") {
			if (feedback.length === 0) {
				errors.feedback = t("supportRequestDialog.errors.feedback");
				valid = false;
			}
		}

		this.setState({ errors });

		return valid;
	};

	render() {
		const { t, show } = this.props;
		const { feedback, errors, sendingFeedback } = this.state;

		const dialog = {
			title: t("sendFeedback.title"),
			message: t("sendFeedback.message"),
			label: t("sendFeedback.label"),
			primaryTitle: t("sendFeedback.buttons.send"),
			primaryAction: this.handleSend,
			primaryDisabled: !!(sendingFeedback || Object.keys(errors).length),
			primaryTooltip: errors.feedback ? t("app:tooltips.fixErrors") : "",
			secondaryTitle: t("buttons.cancel"),
			secondaryAction: this.handleCancel,
		};

		const QuestionText = (props) => (
			<Typography variant="h4">
				<Box fontWeight="600">{props.text}</Box>
			</Typography>
		);

		return (
			<>
				<GenericDialog
					show={show}
					title={dialog.title}
					primaryAction={dialog.primaryAction}
					primaryTitle={sendingFeedback ? <CircularProgressIndicator color="secondary" size={20} /> : dialog.primaryTitle}
					primaryDisabled={dialog.primaryDisabled}
					primaryTooltip={dialog.primaryTooltip}
					secondaryAction={dialog.secondaryAction}
					secondaryTitle={dialog.secondaryTitle}
					secondaryDisabled={sendingFeedback}
					clickAwayDisabled={sendingFeedback}
					closeIcon={<Close />}
				>
					<QuestionText text={dialog.message} />

					<FormControl error={!!errors.feedback} fullWidth>
						<OutlinedInput
							id="feedback"
							name="feedback"
							noDefaultClassName
							autoFocus
							className="no-margin-top"
							multiline
							rows={5}
							fullWidth
							label={dialog.label}
							value={feedback}
							onChange={(e) => this.handleFieldChange(e)}
							error={!!errors.feedback}
						/>
						<FormHelperText>{errors.feedback}</FormHelperText>
					</FormControl>
				</GenericDialog>
			</>
		);
	}
}

export default withTranslation()(SendFeedbackDialog);
