import React from "react";
import { useTranslation } from "react-i18next";

import SearchResult from "./SearchResult";

const TYPE_WELCOME = 1;
const TYPE_SUBSCRIBE = 2;
const TYPE_CITIZEN_ENGAGEMENT = 3;
const TYPE_VIRTUAL_LIBRARY = 4;
const TYPE_ATTENDANCE_AND_VOTING_RECORDS = 5;
const TYPE_MEETING_SCHEDULE = 6;
const TYPE_MEETINGS = 7;
const TYPE_MY_ACTIONS = 14;
const TYPE_MY_APPROVALS = 15;
const TYPE_MY_REMINDERS = 16;
const TYPE_CITIZEN_ENGAGEMENT_ITEM = 17;
const TYPE_MEETING_TYPE = 18;
const TYPE_MEETING_TYPE_LIST = 19;
const TYPE_DEFAULT = 21;
const TYPE_ATTENDANCE_RECORDS = 27;
const TYPE_CUSTOM = 29;
const TYPE_EXTERNAL_MEETING_TYPE = 30;
const TYPE_MEMBERS = 31;
const TYPE_VIDEO = 32;
const TYPE_BOARD_APPLICATION = 33;
const TYPE_DOCUMENT_LIBRARY = 34;

const PortalItemResult = (props) => {
	const { result } = props;
	const { t } = useTranslation();

	let link = "/Portal/";
	switch (result.portalItemType) {
		case TYPE_SUBSCRIBE:
			link = "/Portal/Subscribe.aspx";
			break;

		case TYPE_CITIZEN_ENGAGEMENT:
		case TYPE_CITIZEN_ENGAGEMENT_ITEM:
			link = "/Portal/CitizenEngagement.aspx";
			break;

		case TYPE_ATTENDANCE_AND_VOTING_RECORDS:
		case TYPE_ATTENDANCE_RECORDS:
			link = "/Portal/VotingRecords.aspx";
			break;

		case TYPE_MEETING_SCHEDULE:
			link = "/Portal/MeetingSchedule.aspx";
			break;

		case TYPE_MEETINGS:
		case TYPE_MEETING_TYPE:
		case TYPE_MEETING_TYPE_LIST:
		case TYPE_EXTERNAL_MEETING_TYPE:
		case TYPE_MEMBERS:
			link = "/Portal/MeetingTypeList.aspx";
			break;

		case TYPE_CUSTOM:
			link = `Portal/${result.id}/`;
			break;

		case TYPE_BOARD_APPLICATION:
			link = "/Portal/BoardApplication/";
			break;

		case TYPE_VIDEO:
			link = "/Portal/Video.aspx";
			break;

		case TYPE_DOCUMENT_LIBRARY:
			link = "/Portal/DocumentLibrary.aspx";
			break;
	}

	return (
		<SearchResult
			result={result}
			icon={{
				name: "document",
				tooltip: t("search.tooltips.portalItem"),
			}}
			link={link}
			externalLink
		/>
	);
};

export default React.memo(PortalItemResult);
