import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Box, Collapse, Link } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Alert from '@mui/material/Alert';
import Icon from '../Icon/Icon';
import AccessibleIconButton from '../Buttons/AccessibleIconButton';

import { blackColor, warningColor, successColor, errorColor } from '../../assets/jss/shared';

import alertStyle from '../../assets/jss/components/alertStyle';

const useAlertStyle = makeStyles(alertStyle);

const StyledAlert = React.forwardRef((props, ref) => {
	const {
		key,
		severity = 'success',
		action,
		actionLabel,
		actionIconName,
		iconName,
		message,
		persist = false,
		dismissAction,
		elevation,
		variant,
		dataCy,
	} = props;
	const { t } = useTranslation();
	const classes = useAlertStyle();
	const [open, setOpen] = React.useState(true);
	let iconColor = successColor;
	switch (severity) {
		case 'error':
			iconColor = errorColor;
			break;
		case 'info':
			iconColor = '#244C66';
			break;
		case 'warning':
			iconColor = warningColor;
			break;
		default: {
			iconColor = successColor;
		}
	}
	let actionProp = null;
	if (action && (actionLabel || actionIconName)) {
		actionProp = (
			<Box ml={1} component="span">
				<Link
					ml={3}
					component="button"
					className={classes.action}
					onClick={(e) => {
						action(e);
						dismissAction(key);
					}}
					data-cy={`alertAction-${dataCy}`}
				>
					{actionIconName && <Icon name={actionIconName} color={blackColor[1]} />}
					{actionLabel && actionLabel}
				</Link>
			</Box>
		);
	}
	let closeAction;
	if (persist) {
		closeAction = (
			<AccessibleIconButton
				id="alert-close"
				onClick={() => {
					setOpen(false);
					if (dismissAction) {
						dismissAction();
					}
				}}
				iconName="close"
				iconColor={iconColor}
				tooltipText={t('buttons.close')}
				dataCy={`alert-close-${dataCy}`}
				smallIcon
			/>
		);
	}
	return (
		<Box>
			<Collapse in={open}>
				<Alert
					classes={classes}
					variant={variant}
					elevation={elevation}
					icon={false}
					severity={severity}
					ref={ref}
					data-cy={dataCy}
					action={closeAction}
				>
					{iconName && <Icon name={iconName} color={iconColor} size="17px" />}
					{message}
					{actionProp && actionProp}
				</Alert>
			</Collapse>
		</Box>
	);
});

StyledAlert.propTypes = {
	severity: PropTypes.string,
	action: PropTypes.func,
	actionLabel: PropTypes.string,
	actionIconName: PropTypes.string,
	iconName: PropTypes.string,
	message: PropTypes.string,
	elevation: PropTypes.number,
	variant: PropTypes.string,
	dataCy: PropTypes.string,
};

StyledAlert.defaultProps = {
	severity: 'success',
	variant: 'filled',
	action: undefined,
	iconName: undefined,
	message: undefined,
	actionLabel: undefined,
	actionIconName: undefined,
	elevation: 0,
	dataCy: undefined,
};

export default StyledAlert;
