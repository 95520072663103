import React from 'react';

// Prevents injected props (such as from cloneElement) from being passed into a child component
const PropSoak = (props) => {
	const { children } = props;

	return <>{children}</>;
};

export default PropSoak;
