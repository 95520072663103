import { RESET_PAGE_CONFIG, UPDATE_PAGE_HEADER } from "./types";

const defaultState = {
	additionalText: [],
	primaryAction: null,
	primaryActionText: "",
	primaryActionTooltip: "",
	primaryActionDisabled: false,
	leftMenuOptions: [],
	menuOptions: [],
	displayPageHeader: true,
	additionalRightAction: null,
	primaryActionDisabledBackground: false,
};

export const pageHeaderReducer = (
	state = {
		...defaultState,
	},
	action,
) => {
	let newState = state;
	switch (action.type) {
		case RESET_PAGE_CONFIG: {
			newState = {
				...defaultState,
			};
			break;
		}

		case UPDATE_PAGE_HEADER: {
			const {
				payload: {
					additionalText = state.additionalText,
					primaryAction = state.primaryAction,
					primaryActionText = state.primaryActionText,
					primaryActionTooltip = state.primaryActionTooltip,
					primaryActionDisabled = state.primaryActionDisabled,
					leftMenuOptions = state.leftMenuOptions,
					menuOptions = state.menuOptions,
					displayPageHeader = state.displayPageHeader,
					additionalRightAction = state.additionalRightAction,
					primaryActionDisabledBackground = state.primaryActionDisabledBackground,
				} = {},
			} = action || {};

			newState = {
				...state,
				additionalText,
				primaryAction,
				primaryActionText,
				primaryActionTooltip,
				primaryActionDisabled,
				leftMenuOptions,
				menuOptions,
				displayPageHeader,
				additionalRightAction,
				primaryActionDisabledBackground,
			};
			break;
		}

		default:
			break;
	}

	return newState;
};

export default pageHeaderReducer;
