import { grayColor } from '../shared';
import typographyStyle from './typographyStyle';

const menuStyle = {
	default: {
		zIndex: '10001 !important',
		'& .MuiPaper-root': {
			margin: '0 auto',
		},
	},
	menu: {
		minWidth: '160px',
		...typographyStyle.menu,
		'& ul': {
			padding: '0',
		},
		'& li': {
			height: '40px',
			padding: '10px 12px',
			'&[data-separator]': {
				borderTop: `solid 1px ${grayColor[4]}`,
				height: '41px',
			},
		},
		'& a, & a:hover': {
			color: 'inherit',
			textDecoration: 'none',
		},
	},
	badgeContainer: {
		display: 'flex',
		position: 'relative',

	},

	badgeContent: {
		'& span': {
			position: 'absolute',
			right: '-15px',
		}
	}
};

export default menuStyle;
