export function isEmpty(data) {
	return !data || data === "" || data === "<p></p>";
}

export function isEmptyOrWhitespace(data) {
	const filteredData = (data || "").replace(/\s/g, ""); // Clear the whitespace
	return !filteredData || filteredData === "" || filteredData === "<p></p>";
}

export function isHtmlEqual(html1, html2) {
	return html1 === html2 || (isEmpty(html1) && isEmpty(html2));
}
