export const processProfileImage = (file, callback) => {
	// files come in as data URLs.
	const img = new Image();
	img.src = file;
	img.onload = () => {
		let canvas = document.createElement("canvas");
		let { width, height } = img;

		// first, resize to 215 pixels and save base64.
		const largeSize = 215;
		if (width > height) {
			if (width > largeSize) {
				height *= largeSize / width;
				width = largeSize;
			}
		} else if (height > largeSize) {
			width *= largeSize / height;
			height = largeSize;
		}
		canvas.width = width;
		canvas.height = height;
		const ctx = canvas.getContext("2d");
		ctx.drawImage(img, 0, 0, width, height);

		const profilePicture = canvas.toDataURL("image/jpeg");

		// resize again to 50 pixels and save
		const smallSize = 50;
		if (width > height) {
			if (width > smallSize) {
				height *= smallSize / width;
				width = smallSize;
			}
		} else if (height > smallSize) {
			width *= smallSize / height;
			height = smallSize;
		}
		canvas.width = width;
		canvas.height = height;
		ctx.drawImage(img, 0, 0, width, height);

		callback(profilePicture, canvas.toDataURL("image/jpeg"));

		canvas = null;
	};
};

export const processImage = (applicable = false, file, callback, size) => {
	// files come in as data URLs.
	const img = new Image();
	let valid = true;
	let recommendedImgWidth = 1920;
	let recommendedImgHeight = 1080;
	const isPng = file.indexOf("image/png") > -1;
	img.src = file;
	img.onload = () => {
		let canvas = document.createElement("canvas");
		let { width, height } = img;
		if (applicable && (width < recommendedImgWidth || height < recommendedImgHeight)) {
			valid = false;
		}
		if (size) {
			if (width > height) {
				if (width > size) {
					height *= size / width;
					width = size;
				}
			} else if (height > size) {
				width *= size / height;
				height = size;
			}
		}
		canvas.width = width;
		canvas.height = height;
		const ctx = canvas.getContext("2d");
		ctx.drawImage(img, 0, 0, width, height);
		callback({
			data: canvas.toDataURL(isPng ? "image/png" : "image/jpeg"),
			valid: valid,
		});

		canvas = null;
	};
};
