import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import makeStyles from '@mui/styles/makeStyles';
import { Table, TableBody, TableCell, TableContainer, TableRow, TableFooter, TablePagination, Checkbox, Box } from '@mui/material';
import Icon from '../Icon/Icon';

import { focusColor } from '../../assets/jss/shared';
import CircularProgressIndicator from '../Progress/CircularProgressIndicator';
import EnhancedTableToolbar from './EnhancedTableToolbar';
import EnhancedTableHead from './EnhancedTableHead';
import { getComparator, stableSort } from './sortFunctions';
import TextBody from '../Typography/TextBody';
import Spinner from '../Spinner/Spinner';

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
	},
	paper: {
		width: '100%',
		marginBottom: theme.spacing(2),
	},
	tableContainer: {
		height: 'calc(100% - 64px)',
		overflowX: 'inherit',
	},
	table: {
		'& tbody tr:last-of-type > td': {
			borderBottom: 'none',
		},
	},
	sortLabel: {
		display: 'flex',
		height: '100%',
		minHeight: '40px',
		'&:focus-visible': {
			outline: `solid 2px ${focusColor}`,
			borderRadius: '4px',
		},
	},
	visuallyHidden: {
		border: 0,
		clip: 'rect(0 0 0 0)',
		height: 1,
		margin: -1,
		overflow: 'hidden',
		padding: 0,
		position: 'absolute',
		top: 20,
		width: 1,
	},
	selectedValue: {
		backgroundColor: 'rgba(0, 0, 0, 0.04)',
	},
	noData: {
		fontWeight: '600',
		fontSize: '16px',
		lineHeight: '20px',
		textAlign: 'center',
		letterSpacing: '1px',
		textTransform: 'uppercase',
		paddingTop: '54px', // 54 + 16 = 70
	},
	loading: {
		padding: '0',
		height: '56px',
	},
}));

const TableData = (props) => {
	const {
		showToolbar = true,
		title,
		showTitle,
		headCells,
		rows,
		noDataLabels,
		menuOptions,
		selectProperty,
		selectedValue,
		rowClickAction,
		handleRowEvent,
		toolbarOptions,
		iconProperty = null,
		iconName = 'attach',
		checkedAction,
		itemsSelected,
		isPartiallySelected = undefined,
		getNbrSelected = undefined,
		menuTooltipText = '',
		withoutMultiSelect = false,
		nbrElementsSelected = 0,
		exportAction = undefined,
		filterAction = undefined,
		isLoading = false,
		minHeight = '72px',
		numFilters = 0,
		numDefaultFilters = 0,
		defaultOrderBy = 'number',
		overrideClasses = {},
		paginationProps = undefined,
		sortAction = undefined,
		useExternalSort = false, // Allow the higher level component to handle sorting
		truncatedTextList,
		enableToolTipOnTextTruncate = false,
		initialSelection = [],
		userImportPage = false,
	} = props;
	const classes = useStyles();
	const { t } = useTranslation();
	const [order, setOrder] = useState('asc');
	const [orderBy, setOrderBy] = useState(defaultOrderBy);
	const [selected, setSelected] = useState(initialSelection);
	const [menuAnchor, setMenuAnchor] = useState({});

	useEffect(() => {
		if (itemsSelected) {
			setSelected(itemsSelected);
		}
	}, [itemsSelected]);

	useEffect(() => {
		if (menuAnchor.row && (!rows || !rows.includes(menuAnchor.row))) {
			setMenuAnchor({});
		}
	}, [rows]);

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === 'asc'; // If we haven't changed the order by field and it's currently in ascending order, change to the descending order
		const resetToDefault = orderBy === property && order === 'desc'; // If we haven't changed the order by field and it's currently in descending order, change back to the default order
		const newOrder = !resetToDefault && isAsc ? 'desc' : 'asc';
		const newOrderBy = !resetToDefault ? property : defaultOrderBy;
		setOrder(newOrder);
		setOrderBy(newOrderBy);
		if (sortAction) {
			sortAction(newOrderBy, newOrder);
		}
	};

	const handleSelectAllClick = (event) => {
		const newSelecteds = rows.map((n) => n[selectProperty]);
		checkedAction(newSelecteds, event.target.checked, true);
		if (event.target.checked) {
			setSelected(newSelecteds);
			return;
		}
		setSelected([]);
	};

	const handleCheckboxClick = (event, name) => {
		event.stopPropagation();
		checkedAction([name], event.target.checked);
		const selectedIndex = selected.indexOf(name);
		let newSelected = [];

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, name);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1));
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
		}

		setSelected(newSelected);
	};

	const handleKeyDown = (event, row) => {
		if (event.key === 'Enter' || event.keyCode === 32) {
			rowClickAction(event, row);
		}
	};

	// Ensure that only a single menu can be opened at a time
	const handleSetMenuAnchor = (row, anchor) =>
		setMenuAnchor((prev) => (!anchor || (prev.row === row && prev.anchor) ? {} : { row, anchor }));

	const isSelected = (name) => selected.indexOf(name) !== -1;
	return (
		<>
			{showToolbar && (
				<Box>
					<EnhancedTableToolbar
						title={title}
						numSelected={nbrElementsSelected}
						toolbarOptions={toolbarOptions}
						exportAction={exportAction}
						filterAction={filterAction}
						numFilters={numFilters}
						showTitle={showTitle}
					/>
				</Box>
			)}
			<Box data-cy={props['data-cy']}>
				<TableContainer className={classes.tableContainer}>
					<Table
						className={clsx(classes.table, overrideClasses.table)}
						size="medium"
						aria-label="table data"
						stickyHeader
						height={isLoading ? '100%' : minHeight ? minHeight : undefined}
					>
						<EnhancedTableHead
							headCells={headCells}
							classes={classes}
							overrideClasses={overrideClasses}
							numSelected={selected.length}
							order={order}
							orderBy={orderBy}
							onSelectAllClick={handleSelectAllClick}
							onRequestSort={handleRequestSort}
							rowCount={rows.length}
							withoutMultiSelect={withoutMultiSelect}
							hasAnyItemSelected={nbrElementsSelected > 0}
							showTitle={showTitle}
						/>
						{isLoading && (
							<TableBody>
								<TableRow>
									<TableCell colSpan={headCells.length + 1}>
										<Spinner />
									</TableCell>
								</TableRow>
							</TableBody>
						)}
						{!isLoading && rows.length > 0 && (
							<TableBody>
								{(!useExternalSort ? stableSort(rows, getComparator(order, orderBy)) : rows).map((row, index) => {
									const isItemSelected = isSelected(row[selectProperty]);
									let isItemIndeterminate = false;
									let nbrSelected = 0;
									// If function was provided
									if (getNbrSelected) {
										nbrSelected = getNbrSelected(row[selectProperty]);
									}
									if (isPartiallySelected) {
										isItemIndeterminate = isPartiallySelected(row[selectProperty], row);
									}

									const labelId = `enhanced-table-checkbox-${index}`;

									return (
										<TableRow
											hover
											role={rowClickAction ? 'checkbox' : undefined}
											aria-checked={rowClickAction ? isItemSelected : undefined}
											tabIndex={rowClickAction ? 0 : undefined}
											key={`${row[selectProperty]}-${index}`}
											selected={isItemSelected || isItemIndeterminate}
											onKeyDown={rowClickAction ? (e) => handleKeyDown(e, row) : undefined}
											onClick={rowClickAction ? (e) => rowClickAction(e, row) : undefined}
											data-cy={`row-${row[selectProperty]}`}
											className={clsx(overrideClasses.row, {
												[classes.selectedValue]: selectedValue === row[selectProperty],
												[classes.selectedValue]: selectedValue === 'selected-details-row',
											})}
										>
											{row.loading ? (
												<TableCell
													key={`loading-${index}`}
													className={classes.loading}
													colSpan={headCells.length + (!withoutMultiSelect ? 1 : 0)}
												>
													<CircularProgressIndicator minHeight="0" />
												</TableCell>
											) : (
												<>
													{!withoutMultiSelect &&  (
														<TableCell key={`row-${row[selectProperty]}-checkbox`} padding="checkbox">
															<Checkbox
																color="primary"
																indeterminate={isItemIndeterminate}
																checked={isItemSelected}
																inputProps={{ 'aria-labelledby': labelId }}
																onClick={(event) => handleCheckboxClick(event, row[selectProperty])}
																data-cy={`row-${row[selectProperty]}-checkbox`}
															/>
														</TableCell>
													)}
													{headCells.map((cell, cellIndex) => {
														// renders custom cell components
														if (cell.component) {
															const CustomCell = cell.component;
															return (
																<CustomCell
																	key={`row-${row[selectProperty]}-cell-${cell.id}`}
																	//TO-DO change this code of value when backend will be ready
																	value={ row[cell.id]}
																	selector={row[selectProperty]}
																	cell={cell}
																	cellIndex={cellIndex}
																	rowIndex={index}
																	row={row}
																	nbrSelected={nbrSelected}
																	className={overrideClasses.cell}
																	menuOptions={menuOptions}
																	menuTooltipText={menuTooltipText}
																	anchor={menuAnchor.row === row ? menuAnchor.anchor : null}
																	setAnchor={handleSetMenuAnchor}
																	isItemSelected={isItemSelected}
																	isItemIndeterminate={isItemIndeterminate}
																	handleRowEvent={handleRowEvent}
																	truncatedTextList={truncatedTextList}
																	enableToolTipOnTextTruncate={enableToolTipOnTextTruncate}
																	userImportPage={userImportPage}
																/>
															);
														}
														// renders default cell (Just text with an icon option)
														return (
															<TableCell
																key={`row-${row[selectProperty]}-cell-${cell.id}`}
																className={overrideClasses.cell}
																data-cy={`row-${row[selectProperty]}-cell-${cellIndex}`}
															>
																<TextBody color={cell.rowColor}>{row[cell.id]}</TextBody>
																{cell.hasIcon && row[iconProperty] && (
																	<Box ml={1} display="inline" data-cy="attachmentIcon">
																		<Icon name={iconName} size="16px" />
																	</Box>
																)}
															</TableCell>
														);
													})}
												</>
											)}
										</TableRow>
									);
								})}
							</TableBody>
						)}
						{!isLoading && rows.length === 0 && (
							<TableBody>
								<TableRow>
									<TableCell colSpan={headCells.length + 1}>
										<Box height="75%" display="flex" justifyContent="center" alignItems="center">
											<div className={clsx(classes.noData, overrideClasses.noData)}>
												{typeof noDataLabels === 'string'
													? noDataLabels
													: numFilters !== numDefaultFilters
													? noDataLabels.filtered || t('tableData.filterNoData')
													: noDataLabels.unfiltered || t('tableData.noData')}
											</div>
										</Box>
									</TableCell>
								</TableRow>
							</TableBody>
						)}
						{paginationProps && (
							<TableFooter>
								<TableRow>
									<TablePagination {...paginationProps} />
								</TableRow>
							</TableFooter>
						)}
					</Table>
				</TableContainer>
			</Box>
		</>
	);
};

export default TableData;
