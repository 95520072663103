import { produce } from "immer";
import { v4 as uuid } from "uuid";

import cloneDeep from "lodash/fp/cloneDeep";
import findIndex from "lodash/fp/findIndex";
import { createMeetingElement, prepareAttachmentDuplication } from "utils/meetingElement";
import telemetryAddEvent from "utils/telemetryAddEvent";

import AgendaItemTypesEnum from "utils/enums/AgendaItemTypes";
import { updateAgenda, findItemByID, reOrderItemArray, checkAttachments } from "./utils";

export function addRecommendation(guid) {
	const currentState = this.state;
	const recommendationId = uuid();

	telemetryAddEvent("Agenda builder - Add recommendation");

	const nextState = produce(
		currentState,
		(draft) => {
			const newRecommendation = createMeetingElement({
				guid: recommendationId,
				itemType: 7,
				indent: "0",
				order: "1",
				number: 1,
				parentGuid: guid,
			});

			// if header selected add immediately after first heading in draft.items (which will be 1st item)
			// if another item is selected, add it immediately after that item
			let selectedIndex;
			if (guid === "toc-header") {
				// I don't think this is going to be supported in the long run. For the time being, I'm matching the logic from agenda items.
				if (draft.items.length) {
					selectedIndex = 0; // 1st item will always be a heading.
				} else {
					// this should never happen, but putting it in as a graceful failure until just in case
					// eslint-disable-next-line no-param-reassign
					if (!draft.items) draft.items = [];
					draft.items.push(newRecommendation);
					return;
				}
			} else {
				selectedIndex = findIndex((item) => item.guid === guid, draft.items);

				// if we re placing ourselves after an existing recommendations, the location is valid, but we need to update the relationship
				if (draft.items[selectedIndex].itemType === AgendaItemTypesEnum().RECOMMENDATION.value) {
					newRecommendation.attributes.relationshipGuid = draft.items[selectedIndex].attributes.relationshipGuid;
				}
			}

			draft.items.splice(selectedIndex + 1, 0, newRecommendation);
			reOrderItemArray(draft.items, this, currentState.customNumbering);

			this.itemIdsToUpdate.push(recommendationId);
		},
		(patches, inversePatches) => {
			if (patches.length) {
				this.redoStack.push([...patches]);
				this.undoStack.push([...inversePatches]);
			}
		},
	);

	updateAgenda(this, { items: nextState.items }, recommendationId);
}

export function deleteRecommendation(guid) {
	const currentState = this.state;
	let parentId;

	const nextState = produce(
		currentState,
		(draft) => {
			const recToDelete = findItemByID(guid, draft.items);
			parentId = recToDelete.attributes.relationshipGuid;
			recToDelete.deleted = true;
			this.itemIdsToDelete.push(recToDelete.guid);

			const nonDeletedItems = draft.items.filter((item) => {
				return !item.deleted;
			});
			draft.items.splice(0, draft.items.length, ...nonDeletedItems);

			reOrderItemArray(draft.items, this, currentState.customNumbering);
		},
		(patches, inversePatches) => {
			if (patches.length) {
				this.redoStack.push([...patches]);
				this.undoStack.push([...inversePatches]);
			}
		},
	);

	updateAgenda(this, { items: nextState.items }, parentId);
}

export function duplicateRecommendation(guid) {
	const currentState = this.state;
	const dupeId = uuid();

	const nextState = produce(
		currentState,
		(draft) => {
			const originalItem = findItemByID(guid, draft.items);
			const selectedIndex = findIndex((item) => item.guid === guid, draft.items);
			const dupeItem = prepareAttachmentDuplication(cloneDeep(originalItem));
			dupeItem.guid = dupeId;

			if (dupeItem.attachments) {
				// eslint-disable-next-line no-plusplus
				for (let j = 0; j < dupeItem.attachments.length; j++) {
					dupeItem.attachments[j].itemGuid = dupeItem.guid;
				}
			}
			this.itemIdsToUpdate.push(dupeItem.guid);

			draft.items.splice(selectedIndex + 1, 0, dupeItem);
			reOrderItemArray(draft.items, this, currentState.customNumbering);
		},
		(patches, inversePatches) => {
			if (patches.length) {
				this.redoStack.push([...patches]);
				this.undoStack.push([...inversePatches]);
			}
		},
	);

	updateAgenda(this, { items: nextState.items }, dupeId);
}

export function updateRecommendation(guid, parentId, data, cancelNextRender) {
	const currentState = this.state;
	let cancelChanges = false;
	const nextState = produce(
		currentState,
		(draft) => {
			const recIndex = findIndex((rec) => rec.guid === guid, draft.items);
			const recToUpdate = draft.items[recIndex];
			if (recToUpdate && recToUpdate.fields.Name.Value !== data) {
				recToUpdate.fields.Name.Value = data;
				checkAttachments(recToUpdate);
				this.itemIdsToUpdate.push(recToUpdate.guid);
			} else {
				cancelChanges = true;
			}
		},
		(patches, inversePatches) => {
			if (patches.length) {
				if (patches.length === 1) {
					const firstPatch = patches[0].value.includes("<br />") ? patches[0].value.split("<br />").join("<br>") : patches[0].value;
					const firstInversePatches = inversePatches[0].value.includes("<br />")
						? inversePatches[0].value.split("<br />").join("<br>")
						: inversePatches[0].value;
					if (firstPatch !== firstInversePatches) {
						this.redoStack.push([...patches]);
						this.undoStack.push([...inversePatches]);
					}
				} else {
					this.redoStack.push([...patches]);
					this.undoStack.push([...inversePatches]);
				}
			}
		},
	);

	if (!cancelChanges) {
		updateAgenda(this, { items: nextState.items }, null, cancelNextRender);
	}
}
