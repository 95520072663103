import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import clsx from "clsx";

import makeStyles from "@mui/styles/makeStyles";
import { MenuItem } from "@mui/material";

import { grayColor, primaryColor } from "atlas/assets/jss/shared";
import AccessibleIconButton from "atlas/components/Buttons/AccessibleIconButton";
import ButtonWithTooltip, { GROUP_POSITION_FIRST, GROUP_POSITION_LAST } from "atlas/components/Buttons/ButtonWithTooltip";
import SelectInput from "atlas/components/FormControls/SelectInput";
import { VERY_SMALL } from "atlas/utils/buttonSize";
import SearchInput from "components/Search/SearchInput";
import DocumentDetailsHistory from "./DocumentDetailsHistory";
import DocumentDetailsAccessLog from "./DocumentDetailsAccessLog";

const defaultFont = {
	fontSize: "12px",
	fontWeight: "400",
	lineHeight: "16px",
	letterSpacing: "1px",
};

const useStyles = makeStyles({
	section: {
		fontSize: "14px",
		fontWeight: "400",
		lineHeight: "16px",
		letterSpacing: "1px",
		borderBottom: `solid 1px ${grayColor[4]}`,
		flexGrow: "1",
		display: "flex",
		flexDirection: "column",
	},
	buttons: {
		textAlign: "center",
		padding: "16px",
		"& button:not(:first-of-type):not(:last-of-type)": {
			borderRadius: "0",
		},
		"& button:not(:last-of-type)": {
			borderRight: "none",
		},
	},
	button: {
		width: "94px",
		padding: "0",
	},
	buttonToggled: {
		"&.MuiButton-root.Mui-disabled": {
			color: primaryColor[6],
			backgroundColor: "rgba(148, 148, 148, 0.32)",
			border: "1px solid rgb(56, 95, 153)",
		},
	},
	accessLogFilter: {
		padding: "0 16px",
	},
	accessLogFilterExport: {
		display: "flex",
		alignItems: "center",
	},
	accessLogFilterType: {
		flexGrow: "1",
		marginRight: "4px",
	},
	filterType: {
		width: "100%",
		height: "auto",
	},
	dataTitle: {
		...defaultFont,
		backgroundColor: grayColor[4],
		height: "24px",
		padding: "0 16px",
		display: "flex",
		alignItems: "center",
	},
});

const FILTER_TYPE_ALL = 0;
const FILTER_TYPE_DATE = 1;
const FILTER_TYPE_NAME = 2;
const FILTER_TYPE_IP_ADDRESS = 3;

const DocumentDetailsActivity = (props) => {
	const {
		document,
		history,
		accessLogs,
		loadMoreHistory,
		toggleFieldChanges,
		handleView,
		handleRestore,
		loadMoreAccessLogs,
		handleExport,
	} = props;
	const { t } = useTranslation("documents");
	const [sections, setSections] = useState({ history: true });
	const [filter, setFilter] = useState({ search: "", type: FILTER_TYPE_ALL });
	const classes = useStyles();

	const showHistory = () => setSections({ history: true });

	const showAccessLog = () => setSections({ accessLog: true });

	const handleSearchChange = (e) =>
		setFilter((prev) => ({
			...prev,
			search: e.target.value,
		}));

	const handleSearchClear = () =>
		setFilter((prev) => ({
			...prev,
			search: "",
		}));

	const handleTypeChange = (e) =>
		setFilter((prev) => ({
			...prev,
			type: parseInt(e.target.value, 10),
		}));

	const getFilterFields = () =>
		[
			{ text: t("detail.options.all"), value: FILTER_TYPE_ALL },
			//// Removed dateAccessed filter option (MAP-6458)
			// { text: t("detail.options.dateAccessed"), value: FILTER_TYPE_DATE },
			{ text: t("detail.options.name"), value: FILTER_TYPE_NAME },
			{ text: t("detail.options.ipAddress"), value: FILTER_TYPE_IP_ADDRESS },
		].map((option) => (
			<MenuItem key={option.value} value={option.value} data-cy={`filter-field-${option.value}`}>
				{option.text}
			</MenuItem>
		));

	const applyFilter = (logs) => {
		if (filter.search) {
			return logs.filter((accessLog) => {
				let match = false;
				const search = filter.search.toLowerCase();

				if (filter.type === FILTER_TYPE_ALL || filter.type === FILTER_TYPE_DATE) {
					match = match || accessLog.dateAccessed.toLowerCase().indexOf(search) >= 0;
				}
				if (filter.type === FILTER_TYPE_ALL || filter.type === FILTER_TYPE_NAME) {
					match = match || accessLog.name.toLowerCase().indexOf(search) >= 0;
				}
				if (filter.type === FILTER_TYPE_ALL || filter.type === FILTER_TYPE_IP_ADDRESS) {
					match = match || accessLog.ipAddress.toLowerCase().indexOf(search) >= 0;
				}

				return match;
			});
		} else {
			return logs;
		}
	};

	return (
		document && (
			<div className={classes.section}>
				<div className={classes.buttons}>
					<ButtonWithTooltip
						id="history"
						className={clsx(classes.button, {
							[classes.buttonToggled]: sections.history,
						})}
						title={t("detail.tooltips.showHistory")}
						onClick={showHistory}
						variant="outlined"
						color="primary"
						size={VERY_SMALL}
						groupPosition={GROUP_POSITION_FIRST}
						disabled={sections.history}
						dataCy="history"
					>
						{t("detail.buttons.history")}
					</ButtonWithTooltip>
					<ButtonWithTooltip
						id="access-log"
						className={clsx(classes.button, {
							[classes.buttonToggled]: sections.accessLog,
						})}
						title={t("detail.tooltips.showAccessLog")}
						onClick={showAccessLog}
						variant="outlined"
						color="primary"
						size={VERY_SMALL}
						groupPosition={GROUP_POSITION_LAST}
						disabled={sections.accessLog}
						dataCy="access-log"
					>
						{t("detail.buttons.accessLog")}
					</ButtonWithTooltip>
				</div>
				{sections.accessLog && (
					<div className={classes.accessLogFilter}>
						<div>
							<SearchInput
								size="small"
								value={filter.search}
								showClearIcon={filter.search?.length > 0}
								onChange={handleSearchChange}
								onClearClick={handleSearchClear}
								data-cy="access-log-search"
							/>
						</div>
						<div className={classes.accessLogFilterExport}>
							<div className={classes.accessLogFilterType}>
								<SelectInput
									id="filter-type"
									className={classes.filterType}
									fullWidth
									size="small"
									label={t("detail.labels.filterField")}
									value={filter.type}
									onChange={handleTypeChange}
									data-cy="filter-type"
								>
									{getFilterFields()}
								</SelectInput>
							</div>
							<div>
								<AccessibleIconButton
									iconName="export"
									aria-label={t("detail.tooltips.exportToExcel")}
									tooltipText={t("detail.tooltips.exportToExcel")}
									onClick={handleExport}
									dataCy="export-access-logs"
								/>
							</div>
						</div>
					</div>
				)}
				<div className={classes.dataTitle}>{t(`detail.labels.${sections.history ? "history" : "accessLog"}`)}</div>
				{sections.history && (
					<DocumentDetailsHistory
						document={document}
						history={history}
						loadMore={loadMoreHistory}
						toggleFieldChanges={toggleFieldChanges}
						handleView={handleView}
						handleRestore={handleRestore}
					/>
				)}
				{sections.accessLog && (
					<DocumentDetailsAccessLog document={document} accessLogs={applyFilter(accessLogs)} loadMore={loadMoreAccessLogs} />
				)}
			</div>
		)
	);
};

export default DocumentDetailsActivity;
