import { useCallback } from "react";
import { useDispatch } from "react-redux";
import request from "superagent";

import { API_HOST } from "config/env";
import { updateSignInDialog } from "redux/app/actions";

/*
Usage:

This checks if the user is signed in or not.

"onSignedIn" is an optional function that is run if the user is signed in
"onNotSignedIn" is an optional function that is run if the user is not signed in
"onError" is an optional function that is run if an error occurs

import isSignedIn from "utils/isSignedIn";
*/

export default function isSignedIn(onSignedIn, onNotSignedIn, onError) {
	request
		.get(`${API_HOST}/api/user/issignedin`)
		.withCredentials()
		.then((res) => {
			if (res.body.isSignedIn) {
				if (typeof onSignedIn === "function") {
					onSignedIn(res);
				}
			} else if (typeof onNotSignedIn === "function") {
				onNotSignedIn(res);
			}
		})
		.catch((err) => {
			if (typeof onError === "function") {
				onError(err);
			} else {
				console.log(err);
			}
		});
}

export const useSignInDialog = () => {
	const dispatch = useDispatch();
	return useCallback((requestError, newAfterSignIn, onOtherError) => {
		if (requestError.status === 403) {
			isSignedIn(
				() => {
					console.log(requestError);
					if (typeof onOtherError === "function") {
						onOtherError(requestError);
					}
				},
				() => {
					dispatch(updateSignInDialog({ show: true, after: newAfterSignIn }));
				},
			);
		} else {
			console.log(requestError);
			if (typeof onOtherError === "function") {
				onOtherError(requestError);
			}
		}
	}, []);
};
