import React, { useState, useEffect, useContext } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import request from "superagent";
import { useTranslation } from "react-i18next";
import clsx from "clsx";

import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import makeStyles from "@mui/styles/makeStyles";
import { API_HOST } from "config/env";
import telemetryAddEvent from "utils/telemetryAddEvent";
import { SettingsContext } from "contexts/Settings/SettingsContext";
import { MenuItem } from "@mui/material";

import CircularProgressIndicator from "atlas/components/Progress/CircularProgressIndicator";
import ComponentContainer from "atlas/components/ComponentContainer/ComponentContainer";
import { useWidthDown } from "atlas/utils/useWidth";
import withErrorHandling from "components/ErrorHOC";
import MeetingCards from "./MeetingCards";

import { resetPageConfigs, updatePageConfigs } from "redux/app/actions";
import { updatePageHeader } from "redux/pageHeader/actions";

import typographyStyle from "atlas/assets/jss/components/typographyStyle";
import { backgroundColor } from "atlas/assets/jss/shared";
import SelectInput from "atlas/components/FormControls/SelectInput";

import { UserContext } from "contexts/User/UserContext";

const useStyles = makeStyles((theme) => ({
	heading: {
		...typographyStyle.h1,
	},
	smallHeading: {
		...typographyStyle.smallH1,
	},
	defaultHeading: {
		backgroundColor: backgroundColor[1],
		boxSizing: "border-box",
		height: "64px",
		padding: "24px",
		[theme.breakpoints.down("sm")]: {
			height: "48px",
			padding: "16px",
		},
		display: "flex",
		alignItems: "center",
	},
	topHeading: {
		margin: "-24px -24px 0 -24px",
		[theme.breakpoints.down("sm")]: {
			margin: "0",
		},
		borderRadius: "2px 2px 0 0",
	},
	otherHeadings: {
		margin: "0 -24px",
		[theme.breakpoints.down("sm")]: {
			margin: "0",
		},
	},
	selectInput: {
		minWidth: "300px",
		width: "100%",
		height: "auto",
		marginTop: "0px",
	},
	top: {
		display: "flex",
		justifyContent: "space-between",
		flexWrap: "wrap",
	},
	boards: {
		marginLeft: "16px",
	},
}));

const AdminLanding = (props) => {
	const { boardAdmin } = props;
	const widthDownSm = useWidthDown("sm");
	const { t } = useTranslation("meetings");
	const navigate = useNavigate();
	const { organizationName } = useContext(SettingsContext);
	const classes = useStyles();
	const dispatch = useDispatch();
	const [loaded, setLoaded] = useState(false);
	const [upcomingMeetings, setUpcomingMeetings] = useState([]);
	const [recentMeetings, setRecentMeetings] = useState([]);
	const [filteredUpcomingMeetings, setFilteredUpcomingMeetings] = useState([]);
	const [filteredRecentMeetings, setFilteredRecentMeetings] = useState([]);
	const [boards, setBoards] = useState(null);
	const [selections, setSelections] = useState(t("meetings:AllMeetingGroups"));
	let { user } = useContext(UserContext);

	const loadMeetings = () => {
		const url = `${API_HOST}/api/meetings/adminlist`;

		request
			.get(url)
			.then((res) => {
				setLoaded(true);
				setUpcomingMeetings(res.body.upcomingMeetings);
				setRecentMeetings(res.body.recentMeetings);
				setFilteredUpcomingMeetings(res.body.upcomingMeetings);
				setFilteredRecentMeetings(res.body.recentMeetings);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const loadBoards = () => {
		request
			.get(`${API_HOST}/api/boards`)
			.then((res) => {
				setBoards(res.body.boards);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const getFilteredMeetingGroup = () => {
		let filteredMeetingGroupItems = [];
		let groupItems = [];
		if (boards) {
			groupItems = boards;
			//If user is a system adminstrator then all meeting group will populated in dropdown
			if (!user.systemAdministrator) {
				const filteredBoaredList = boards.filter((board) => board.users.find((boardUser) => boardUser.userId === user.id));
				groupItems = filteredBoaredList;
			}
			//if more then one meeting group then only show dropdown
			if (groupItems.length > 1) {
				filteredMeetingGroupItems.push(
					<MenuItem key="all-meeting-group" value={t("meetings:AllMeetingGroups")}>
						{t("meetings:AllMeetingGroups")}
					</MenuItem>,
				);
				groupItems.forEach((board) => {
					filteredMeetingGroupItems.push(
						<MenuItem key={board.id} value={board.name} data-cy={`board-${board.id}`}>
							{board.name}
						</MenuItem>,
					);
				});
			}
		}
		return filteredMeetingGroupItems;
	};

	useEffect(() => {
		dispatch(resetPageConfigs({ resetBack: true }));
		dispatch(
			updatePageConfigs({
				title: t("app:menu.currentMeetings"),
				telemetryPage: "Current meetings",
				preferedBack: { url: "/" },
			}),
		);
		dispatch(
			updatePageHeader({
				primaryAction: boardAdmin
					? () => {
							telemetryAddEvent(`Current meetings - Add meeting`);

							navigate("/meeting/create");
					  }
					: undefined,
				primaryActionText: boardAdmin ? t("buttons.addMeeting") : undefined,
				primaryActionTooltip: boardAdmin ? t("tooltips.createMeeting") : undefined,
			}),
		);
	}, [boardAdmin]);

	const updateSelections = (e) => {
		setSelections(e.target.value);
		if (e.target.value == t("meetings:AllMeetingGroups")) {
			setFilteredUpcomingMeetings(upcomingMeetings);
			setFilteredRecentMeetings(recentMeetings);
		} else {
			const filteredUpcomingMeeting = upcomingMeetings.filter((item) => item.boardName == e.target.value);
			const filteredRecenteMeetings = recentMeetings.filter((item) => item.boardName == e.target.value);
			setFilteredUpcomingMeetings(filteredUpcomingMeeting);
			setFilteredRecentMeetings(filteredRecenteMeetings);
		}
	};
	useEffect(() => {
		loadBoards();
		loadMeetings();
	}, []);

	return (
		<ComponentContainer noPaddingDown="sm">
			{loaded ? (
				<Container maxWidth="xl">
					<div className={classes.top}>
						<h1
							className={clsx(
								{
									[classes.heading]: !widthDownSm,
									[classes.smallHeading]: widthDownSm,
								},
								classes.defaultHeading,
								classes.topHeading,
							)}
						>{`${t("meetings:upcomingMeetings")}`}</h1>
						{getFilteredMeetingGroup().length > 1 && (
							<div className={classes.boards}>
								<SelectInput
									id="boards"
									fullWidth
									className={classes.selectInput}
									labelSize="large"
									noDefaultClassName
									label={t("meetings:filterMeetingGroup")}
									size="small"
									value={selections}
									onChange={(e) => updateSelections(e)}
									data-cy="boards"
								>
									{getFilteredMeetingGroup()}
								</SelectInput>
							</div>
						)}
					</div>
					<MeetingCards
						meetings={filteredUpcomingMeetings}
						showItems
						showAgendaPublishStatus
						showLinks
						showWarning
						showVideo
						data-cy="upcomingMeetings"
					/>
					{filteredUpcomingMeetings.length === 0 && (
						<div data-cy="noUpcomingMeetings">
							<Typography className="noUpcoming-meetings" display="block" paragraph>
								{`${t("meetings:notifications.noUpcomingMeetings")}`}
							</Typography>
							<Typography display="block" paragraph>
								{`${t("meetings:notifications.notSeeingMeeting")}`}{" "}
								<a href="./meetings">{`${t("meetings:buttons.seeAllMeetings", { customer: organizationName })}`}</a>.
							</Typography>
						</div>
					)}

					<h1
						className={clsx(
							{
								[classes.heading]: !widthDownSm,
								[classes.smallHeading]: widthDownSm,
							},
							classes.defaultHeading,
							classes.otherHeadings,
						)}
					>
						<span>{`${t("meetings:recentMeetings")}`}</span>
					</h1>
					<MeetingCards showMinutesPublishStatus meetings={filteredRecentMeetings} showArrow data-cy="recentMeetings" />
					{filteredRecentMeetings.length === 0 && (
						<div data-cy="noRecentMeetings">
							<Typography className="noRecent-meetings" display="block" paragraph>
								{`${t("meetings:notifications.noRecentMeetings")}`}
							</Typography>
							<Typography display="block" paragraph>
								{`${t("meetings:notifications.notSeeingMeeting")}`}{" "}
								<a href="./meetings">{`${t("meetings:buttons.seeAllMeetings", { customer: organizationName })}`}</a>.
							</Typography>
						</div>
					)}
				</Container>
			) : (
				<CircularProgressIndicator data-cy="loadingMeetings" />
			)}
		</ComponentContainer>
	);
};

export default withErrorHandling(AdminLanding);
