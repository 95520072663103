import React, { useState, useEffect, useContext } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import request from "superagent";

import ComponentContainer from "atlas/components/ComponentContainer/ComponentContainer";
import { API_HOST } from "config/env";
import telemetryAddEvent from "utils/telemetryAddEvent";
import BoardList from "./components/BoardList";
import { SettingsContext } from "contexts/Settings/SettingsContext";
import { resetPageConfigs, updatePageConfigs } from "redux/app/actions";
import { updatePageHeader } from "redux/pageHeader/actions";

const telemetryPage = "Meeting Group List";

const BoardsModule = (props) => {
	const { showSignIn } = props;
	const { t } = useTranslation("boards");
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const [boards, setBoards] = useState(null);
	const { lite } = useContext(SettingsContext);

	const loadBoards = () => {
		request
			.get(`${API_HOST}/api/boards`)
			.then((res) => {
				setBoards(res.body.boards);

				showHeaderButton(lite.allowedBoards > res.body.boards.length);
			})
			.catch((err) => {
				showSignIn(err, () => {
					loadBoards();
				});
			});
	};

	const createBoard = () => {
		telemetryAddEvent(`${telemetryPage} - Create meeting group`, { area: "meetingGroups" });

		navigate("/boards/edit");
	};

	const showHeaderButton = (show) => {
		dispatch(
			updatePageHeader({
				primaryAction: show ? createBoard : undefined,
				primaryActionText: show ? t("buttons.newBoard") : undefined,
				primaryActionTooltip: show ? t("tooltips.newBoard") : undefined,
			}),
		);
	};

	useEffect(() => {
		loadBoards();

		dispatch(resetPageConfigs({ resetBack: true }));
		dispatch(
			updatePageConfigs({
				title: t("title.boards"),
				telemetryPage,
				preferedBack: { url: "/boards" },
			}),
		);
		showHeaderButton(lite.allowedBoards === 0);
	}, []);

	return (
		<ComponentContainer>
			<BoardList boards={boards} loadBoards={loadBoards} showSignIn={showSignIn} />
		</ComponentContainer>
	);
};

export default BoardsModule;
