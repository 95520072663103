import React from "react";
import { withTranslation } from "react-i18next";

import SvgIcon from "@mui/material/SvgIcon";

const icon = (props) => {
	const { color, t, tReady, i18n, ...newProps } = props;
	const fillColor = color || "currentColor";
	const title = t("hidePasswordIcon");

	return (
		<SvgIcon {...newProps}>
			<title>{title}</title>
			<g id="visibility-off" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
				<g
					id="view-1460_27218cc7-bc66-42a4-b145-ee468bcb83ac"
					transform="translate(1.000000, 5.000000)"
					stroke={fillColor}
					strokeWidth="1.5"
				>
					<path
						d="M16.1111326,1.56581716 C14.6172809,0.759159487 12.8892561,0.183333333 11,0.183333333 C4.69333333,0.183333333 0.183333333,6.6 0.183333333,6.6 C0.183333333,6.6 2.45219909,9.82806104 6.02176079,11.7050016 M9.75514086,12.9338173 C10.161452,12.9877565 10.5766786,13.0166667 11,13.0166667 C17.3066667,13.0166667 21.8166667,6.6 21.8166667,6.6 C21.8166667,6.6 20.7797673,5.12473661 19.0050869,3.58895951"
						id="Shape"
					/>
				</g>
				<path d="M23,1 L1,23" id="Path" stroke={fillColor} strokeWidth="1.5" />
				<path
					d="M12,15.8076322 C14.4300529,15.8076322 16.4,13.8376851 16.4,11.4076322 M15.1166667,8.29096556 C13.3953792,6.56967813 10.6046208,6.56967815 8.88333338,8.2909656 C7.16204593,10.0122531 7.16204591,12.8030114 8.88333333,14.5242989"
					id="Shape"
					stroke={fillColor}
					strokeWidth="1.5"
				/>
				<path d="M23,1 L1,23" id="Path" stroke={fillColor} strokeWidth="1.5" />
			</g>
		</SvgIcon>
	);
};
export default withTranslation("account")(icon);
