const downloadFile = (fileName, mimeType, fileBytes) => {
	if (window.navigator && window.navigator.msSaveOrOpenBlob) {
		const byteCharacters = atob(fileBytes);
		const byteNumbers = new Array(byteCharacters.length);
		// eslint-disable-next-line no-plusplus
		for (let i = 0; i < byteCharacters.length; i++) {
			byteNumbers[i] = byteCharacters.charCodeAt(i);
		}
		const byteArray = new Uint8Array(byteNumbers);
		const blob = new Blob([byteArray], {
			type: mimeType,
		});

		window.navigator.msSaveOrOpenBlob(blob, fileName);
	} else {
		const downloadUrl = `data:${mimeType};base64,${fileBytes}`;
		const downloadLink = document.createElement("a");
		downloadLink.href = downloadUrl;
		downloadLink.download = fileName;
		document.body.appendChild(downloadLink);
		downloadLink.click();
		document.body.removeChild(downloadLink);
	}
};

export default downloadFile;
