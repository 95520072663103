import React from "react";
import { useTranslation } from "react-i18next";
import { AutoSizer, CellMeasurer, CellMeasurerCache, InfiniteLoader, List } from "react-virtualized";
import clsx from "clsx";

import makeStyles from "@mui/styles/makeStyles";
import { Box } from "@mui/material";

import CircularProgressIndicator from "atlas/components/Progress/CircularProgressIndicator";
import DocumentResult from "./DocumentResult";
import AgendaItemResult from "./AgendaItemResult";
import GoalResult from "./GoalResult";
import RequestToSpeakResult from "./RequestToSpeakResult";
import PortalItemResult from "./PortalItemResult";

const useStyles = makeStyles({
	listContainer: {
		height: "calc(100vh - 265px)",
	},
	listContainerCompact: {
		height: (props) => props?.compact?.height || "100%",
	},
	documentListItem: {
		margin: "0 8px",
		paddingBottom: "16px",
		display: "flex",
		position: "relative",
	},
	noData: {
		fontWeight: "600",
		fontSize: "16px",
		lineHeight: "20px",
		textAlign: "center",
		letterSpacing: "1px",
		textTransform: "uppercase",
		paddingTop: "54px", // 54 + 16 = 70
	},
	noDataCompact: {
		paddingTop: "0",
	},
});

const SearchResults = (props) => {
	const {
		results,
		total,
		loadMore,
		handleExpand,
		isPolicy,
		compact,
		enableSelect,
		selection,
		setSelectedData = () => {},
		policyAddToAgendaSearch,
	} = props;
	const { t } = useTranslation();
	const classes = useStyles({ compact });
	const cache = new CellMeasurerCache({
		defaultHeight: 60,
		fixedWidth: true,
	});

	const isRowLoaded = ({ index }) => !!results[index];

	const rowRenderer = (rowProps) => {
		const { key, index, parent, style } = rowProps;
		const result = results[index];
		const ResultComponent =
			result.document || result.policy
				? DocumentResult
				: result.agendaItem
					? AgendaItemResult
					: result.goal
						? GoalResult
						: result.requestToSpeak
							? RequestToSpeakResult
							: PortalItemResult;

		return (
			<CellMeasurer key={key} cache={cache} columnIndex={0} parent={parent} rowIndex={index}>
				<div style={style} role="row" data-cy={`search-result`}>
					<div className={classes.documentListItem} role="cell">
						<ResultComponent
							result={result}
							handleExpand={result.document && result.agenda ? handleExpand : undefined}
							isPolicy={isPolicy}
							enableSelect={enableSelect}
							selection={selection}
							setSelectedData={setSelectedData}
							policyAddToAgendaSearch={policyAddToAgendaSearch}
						></ResultComponent>
					</div>
				</div>
			</CellMeasurer>
		);
	};

	return results ? (
		results.length > 0 ? (
			<div
				className={clsx({
					[classes.listContainer]: !compact,
					[classes.listContainerCompact]: compact,
				})}
			>
				<InfiniteLoader isRowLoaded={isRowLoaded} loadMoreRows={loadMore} rowCount={total} minimumBatchSize={25}>
					{({ onRowsRendered, registerChild }) => (
						<AutoSizer>
							{({ height, width }) => (
								<List
									ref={registerChild}
									onRowsRendered={onRowsRendered}
									rowRenderer={rowRenderer}
									rowCount={results.length}
									rowHeight={cache.rowHeight}
									height={height}
									width={width}
									deferredMeasurementCache={cache}
								/>
							)}
						</AutoSizer>
					)}
				</InfiniteLoader>
			</div>
		) : (
			<div
				className={clsx(classes.noData, {
					[classes.noDataCompact]: compact,
				})}
			>
				<Box>{t("search.noResults.title")}</Box>
			</div>
		)
	) : (
		<CircularProgressIndicator />
	);
};

export default SearchResults;
