/* eslint-disable no-nested-ternary */
import React, { useContext, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import clsx from "clsx";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";

import OutlinedInput from "atlas/components/FormControls/OutlinedInput";
import { useWidthDown } from "atlas/utils/useWidth";
import { SettingsContext } from "contexts/Settings/SettingsContext";

import BroadcastRow from "./BroadcastRow";

const useStyles = makeStyles(() => ({
	topMargin: {
		marginTop: "8px",
	},
}));

const BroadcastPanel = (props) => {
	const {
		meeting,
		fullWidth,
		classes,
		editTimestamps,
		openBroadcastDialog,
		removeBroadcast,
		showEditTimeStamp,
		errors,
		savingMeeting,
		currentYoutubeLink,
		handleFieldChange,
		testZoom,
		testYouTube,
		matchYoutubeUrl,
		handleSaveMeeting,
	} = props;
	const widthDownSm = useWidthDown("sm");
	const widthDownXl = useWidthDown("xl");
	const { t } = useTranslation("meetings");
	const { lite } = useContext(SettingsContext);

	const timeClasses = useStyles();

	const [broadcasts, setBroadcasts] = useState([]);
	const [youtubeIdExists, setYoutubeIdExists] = useState(false);

	useEffect(() => {
		setBroadcasts(meeting.broadcasts);

		const youtubeIds = meeting.broadcasts?.find((broadcast) => {
			return broadcast.youtubeId.length > 0;
		});
		setYoutubeIdExists(youtubeIds);
	}, [meeting.broadcasts]);

	return (
		<Container maxWidth="xl" style={{ overflow: "hidden" }}>
			<Paper className={clsx("padded", "contained", { mobile: widthDownSm }, classes.noTopMargin, classes.cardBorder)}>
				<div className={classes.detail}>
					<Typography variant="h3">{t("video")}</Typography>
				</div>

				{!lite.enabled && meeting.purchasedBoxcast && (meeting.boxcasters?.length > 0 || meeting.rtmpSources?.length > 0) ? (
					<>
						<div className={classes.detailContainer}>
							<div className={classes.detail}></div>
							{!widthDownXl ? (
								<>
									{youtubeIdExists && (
										<Button
											className="padded-paper-top-margin"
											fullWidth={fullWidth}
											variant="outlined"
											color="primary"
											onClick={editTimestamps}
											data-cy="editTimestamps"
										>
											{t("buttons.editTimestamps")}
										</Button>
									)}

									<Button
										className="padded-paper-top-margin"
										fullWidth={fullWidth}
										variant="outlined"
										color="primary"
										onClick={openBroadcastDialog}
										data-cy="addBroadcast"
									>
										{t("buttons.addBroadcast")}
									</Button>
								</>
							) : null}
						</div>
						{widthDownXl ? (
							<>
								{youtubeIdExists && (
									<Button
										className={fullWidth ? "small-top-margin" : null}
										fullWidth={fullWidth}
										variant="outlined"
										color="primary"
										onClick={editTimestamps}
										data-cy="editTimestamps"
									>
										{t("buttons.editTimestamps")}
									</Button>
								)}
								<Button
									className={fullWidth ? "small-top-margin" : null}
									fullWidth={fullWidth}
									variant="outlined"
									color="primary"
									onClick={openBroadcastDialog}
									data-cy="addBroadcast"
								>
									{t("buttons.addBroadcast")}
								</Button>
							</>
						) : null}

						{broadcasts.length > 0 && (
							<Box m={0} p={0} data-cy="currentVideos" component="span">
								<Typography className={timeClasses.topMargin} type="h2">
									{t("currentVideos")}
								</Typography>
								<Box mt="14px" ml="14px" mr="14px">
									{broadcasts.map((broadcast) => (
										<BroadcastRow
											key={broadcast.broadcastId}
											broadcast={broadcast}
											removeBroadcast={removeBroadcast}
											multipleBroadcasts={broadcasts.length > 1}
										/>
									))}
								</Box>
							</Box>
						)}
					</>
				) : null}

				<Grid container direction="row" spacing={1} data-cy="links">
					<Grid item sm={8} xs={12}>
						<OutlinedInput
							noDefaultClassName
							id="zoomLink"
							label={t("zoomConferenceLink")}
							placeholder="https://zoom.us/j/xxxxxxxxx?pwd=xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx"
							value={meeting.zoomLink || ""}
							helperText={errors.zoomLink}
							onChange={handleFieldChange}
							fullWidth
							size="small"
							error={!!errors.zoomLink}
							data-cy="zoom"
						/>
					</Grid>
					<Grid item sm={4} xs={12}>
						<Button
							style={{
								marginTop: !widthDownSm ? "16px" : "0",
								minWidth: "137px",
							}}
							variant="outlined"
							color="primary"
							onClick={testZoom}
							data-cy="testZoom"
							fullWidth
						>
							{t("buttons.testZoom")}
						</Button>
					</Grid>
					{!meeting.closed && !meeting.purchasedBoxcast && (
						<>
							<Grid item sm={8} xs={12}>
								<OutlinedInput
									noDefaultClassName
									id="youtubeLink"
									label={t("youTubeBroadcastLink")}
									placeholder="https://youtube.com/watch?v=xxxxxxxxxx"
									value={meeting.youtubeLink || ""}
									helperText={errors.youtubeLink}
									onChange={handleFieldChange}
									fullWidth
									size="small"
									error={!!errors.youtubeLink}
									data-cy="youtube"
								/>
							</Grid>
							<Grid item sm={4} xs={12}>
								<Button
									style={{
										marginTop: !widthDownSm ? "16px" : "0",
										minWidth: "137px",
									}}
									variant="outlined"
									color="primary"
									onClick={testYouTube}
									data-cy="testYoutube"
									fullWidth
								>
									{t("buttons.testYouTube")}
								</Button>
							</Grid>
						</>
					)}
					{!lite.enabled &&
						!meeting.purchasedBoxcast &&
						meeting.youtubeLink &&
						meeting.youtubeLink.length > 0 &&
						matchYoutubeUrl(meeting.youtubeLink) &&
						meeting.youtubeLink === currentYoutubeLink &&
						showEditTimeStamp && (
							<>
								<Grid item sm={8} xs={12} />
								<Grid item sm={4} xs={12}>
									<Button
										style={{
											marginTop: !widthDownSm ? "16px" : "0",
											minWidth: "137px",
										}}
										variant="outlined"
										color="primary"
										onClick={editTimestamps}
										data-cy="editTimestamps"
										fullWidth
									>
										{t("buttons.editTimestamps")}
									</Button>
								</Grid>
							</>
						)}
					<Grid item sm={8} xs={12} />
					<Grid item sm={4} xs={12}>
						<Button
							style={{
								marginTop: !widthDownSm ? "32px" : "0",
								minWidth: "137px",
							}}
							id="save-meeting"
							variant="contained"
							color="primary"
							disabled={savingMeeting}
							onClick={handleSaveMeeting}
							data-cy="save"
							fullWidth
						>
							{t("app:buttons.save")}
						</Button>
					</Grid>
				</Grid>
			</Paper>
		</Container>
	);
};

export default BroadcastPanel;
