import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";

import { Dialog, DialogContent } from "@mui/material";

import { useWidthDown } from "atlas/utils/useWidth";
import SignInForm from "components/SignInForm/SignInForm";
import { UserContext } from "contexts/User/UserContext";
import { SettingsContext } from "contexts/Settings/SettingsContext";

export const SkipToContentId = "skiptoContent";

const SignInDialog = () => {
	const widthDownSm = useWidthDown("sm");
	const { t } = useTranslation("account");
	const navigate = useNavigate();
	const location = useLocation();
	const appReducer = useSelector((state) => state.appReducer);
	const {
		signIn: { show, after },
	} = appReducer;

	const afterSignIn = (user) => {
		// Display skip to content
		var skipToContent = document.getElementById(SkipToContentId);
		if (skipToContent && skipToContent.parentNode) {
			skipToContent.parentNode.style.display = null;
		}

		if (user && user.redirect) {
			window.location.href = user.redirect;
			// } else if (user && !user.boardMember && !user.boardAdmin && !user.systemAdministrator) {
			// 	// Redirect staff to the portal for now
			// 	window.location.href = "/";
		} else if (location && location.state && location.state.from && location.state.from.pathname) {
			navigate(location.state.from.pathname + location.state.from.search);
		} else {
			navigate("/");
		}
	};

	return show ? (
		<Dialog classes={{ root: "generic-dialog" }} fullScreen={widthDownSm} open={show} aria-labelledby="sign-in-title">
			<DialogContent className="login-card" dividers>
				<SettingsContext.Consumer>
					{(settingsContext) => (
						<UserContext.Consumer>
							{(userContext) => (
								<SignInForm
									label={t("sessionTimedOutSignIn")}
									afterSignIn={afterSignIn}
									settingsContext={settingsContext}
									userContext={userContext}
								/>
							)}
						</UserContext.Consumer>
					)}
				</SettingsContext.Consumer>
			</DialogContent>
		</Dialog>
	) : null;
};

export default SignInDialog;
