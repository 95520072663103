import {
	SET_MEETING_DETAILS,
	SET_TO_AGENDA_WARNING_MESSAGE,
	CLEAR_WARNING_MESSAGE,
	SET_OVERALL_SELECTED_MEETING_ITEMS,
	SET_SMART_SELECTED_ITEMS,
	SET_SMART_SELECT_TOGGLE,
	RESET_STATE,
	SET_LEFT_AGENDA_OBJECT,
	SET_RIGHT_AGENDA_MAP,
	SET_LEFT_MEETING_AGENDA,
	SET_RIGHT_MEETING_AGENDA,
	SET_FINAL_RIGHT_MEETING_AGENDA,
	SET_SOURCE_MEETING_DETAILS,
	SET_NEW_MEETING_TAG,
	RESET_LEFT_AGENDA_OBJECT,
	CREATE_ITEMS_FOR_OVERFLOW,
	SET_FIRST_DELETE_BUTTON_INDEX,
	CLEAR_SELECTED_MEETING,
	CREATE_MEETING_IN_PROGRESS,
	SET_FIRST_SELECTED_BUTTON_INDEX,
} from "./types";

export const newCopyAndMoveReducer = (
	state = {
		selectedMeeting: null,
		selectedMeetingId: null,
		sourceMeeting: null,
		selectedMeetingBoardAgendaStatus: null,
		selectedMeetingPublicAgendaStatus: null,
		toAgendaWarning: {},
		fromAgendaWarning: [],
		defaultSmartSelectEnabled: true,
		smartSelectedItems: {},
		leftAgendaObject: {},
		rightAgendaMap: new Map(),
		originalLeftMeetingAgendaItems: {},
		originalRightMeetingAgendaItems: null,
		finalRightMeetingAgendaItems: [],
		finalLeftMeetingAgendaItems: {},
		isNewMeeting: false,
		agendaItemsForOverflow: [],
		firstDeleteButtonIndex: null,
		firstSelectedButtonIndex: null,
		creatingMeeting: false,
	},
	action,
) => {
	switch (action.type) {
		case SET_MEETING_DETAILS: {
			state = {
				...state,
				selectedMeeting: action.payload,
				selectedMeetingId: action.payload.id,
				selectedMeetingBoardAgendaStatus: action.payload.boardAgendaStatus,
				selectedMeetingPublicAgendaStatus: action.payload.publicAgendaStatus,
			};
			break;
		}

		case SET_SOURCE_MEETING_DETAILS: {
			state = {
				...state,
				sourceMeeting: action.payload,
			};
			break;
		}

		case SET_TO_AGENDA_WARNING_MESSAGE: {
			state = {
				...state,
				toAgendaWarning: {
					...state.toAgendaWarning,
					[action.payload.id]: action.payload.message,
				},
			};
			break;
		}
		case CLEAR_WARNING_MESSAGE: {
			state = {
				...state,
				toAgendaWarning: {},
			};
			break;
		}
		case SET_OVERALL_SELECTED_MEETING_ITEMS: {
			state = {
				...state,
				overallSelectedMeetingItems: action.payload,
			};
			break;
		}
		case SET_SMART_SELECT_TOGGLE: {
			state = {
				...state,
				defaultSmartSelectEnabled: action.payload,
			};
			break;
		}
		case SET_SMART_SELECTED_ITEMS: {
			state = {
				...state,
				smartSelectedItems: action.payload,
			};
			break;
		}
		case SET_LEFT_AGENDA_OBJECT: {
			state = {
				...state,
				leftAgendaObject: action.payload,
			};
			break;
		}
		case SET_RIGHT_AGENDA_MAP: {
			state = {
				...state,
				rightAgendaMap: action.payload,
			};
			break;
		}
		case RESET_STATE: {
			state = {
				...state,
				selectedMeeting: null,
				selectedMeetingId: null,
				selectedMeetingBoardAgendaStatus: null,
				selectedMeetingPublicAgendaStatus: null,
				toAgendaWarning: {},
				fromAgendaWarning: [],
				overallSelectedMeetingItems: {},
				defaultSmartSelectEnabled: true,
				smartSelectedItems: {},
				leftAgendaObject: {},
				rightAgendaMap: new Map(),
				originalRightMeetingAgendaItems: null,
				isNewMeeting: false,
				firstDeleteButtonIndex: null,
				firstSelectedButtonIndex: null,
				finalRightMeetingAgendaItems: [],
			};
			break;
		}
		case SET_LEFT_MEETING_AGENDA: {
			state = {
				...state,
				originalLeftMeetingAgendaItems: action.payload,
			};
			break;
		}
		case SET_RIGHT_MEETING_AGENDA: {
			state = {
				...state,
				originalRightMeetingAgendaItems: action.payload,
			};
			break;
		}
		case SET_FINAL_RIGHT_MEETING_AGENDA: {
			state = {
				...state,
				finalRightMeetingAgendaItems: action.payload,
			};
			break;
		}
		case SET_NEW_MEETING_TAG: {
			state = {
				...state,
				isNewMeeting: action.payload,
			};
			break;
		}
		case RESET_LEFT_AGENDA_OBJECT: {
			state = {
				...state,
				originalLeftMeetingAgendaItems: {},
			};
			break;
		}
		case CREATE_ITEMS_FOR_OVERFLOW: {
			state = {
				...state,
				agendaItemsForOverflow: action.payload,
			};
			break;
		}
		case SET_FIRST_DELETE_BUTTON_INDEX: {
			state = {
				...state,
				firstDeleteButtonIndex: action.payload,
			};
			break;
		}
		case SET_FIRST_SELECTED_BUTTON_INDEX: {
			state = {
				...state,
				firstSelectedButtonIndex: action.payload,
			};
			break;
		}
		case CLEAR_SELECTED_MEETING: {
			state = {
				...state,
				selectedMeetingId: null,
				selectedMeeting: null,
			};
			break;
		}
		case CREATE_MEETING_IN_PROGRESS: {
			state = {
				...state,
				creatingMeeting: action.payload,
			};
			break;
		}
	}
	return state;
};

export default newCopyAndMoveReducer;
