import React, { useEffect, useState, useCallback } from "react";

import SelectInput from "atlas/components/FormControls/SelectInput";
import inputStyle from "atlas/assets/jss/components/inputStyle";
import clsx from "clsx";
import makeStyles from "@mui/styles/makeStyles";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import telemetryAddEvent from "utils/telemetryAddEvent";
import CircularProgressIndicator from "atlas/components/Progress/CircularProgressIndicator";

import { setCustomNumberingOption } from "../../redux/meetingTemplate/actions";
import { SECTION_FORMAT_ITEM, NUMBER_ITEMS_FORMAT, ALPHABETS_ITEM_FORMAT, ROMAN_ITEM_FORMAT } from "utils/enums/CustomNumberingTypes";

import { MenuItem } from "@mui/material";

const useStyles = makeStyles(() => ({
	centerStyle: {
		"& .MuiInputLabel-shrink": {
			top: "0px",
		},
		"& label": {
			position: "absolute",
			top: "-8px",
		},
	},
	formatPreview: {
		border: "1px solid #949494",
		borderRadius: "5px",
		width: "92px",
		height: "100px",
		float: "right",
	},
	formatPreviewWidth: {
		width: "108px !important",
	},
	number: {
		position: "relative",
		left: "7px",
	},

	noneNumber: {
		fontWeight: "bold",
		position: "relative",
		left: "10px",
	},
	formatSectionLine: {
		border: "1px solid #000000",
		position: "relative",
		width: "57px",
		bottom: "16px",
		left: "7px",
	},
	agendaItemSection: {
		position: "relative",
		bottom: "18px",
		left: "7px",
		height: "24px",
	},
	agendaSectionLine: {
		border: "1px solid #000000",
		position: "relative",
		width: "41px",
		left: "8px",
		bottom: "16px",
	},
	formatSection: {
		marginTop: "10px",
		marginBottom: "15px",
	},
	numberingPreview: {
		width: "80%",
		marginLeft: "35px",
		marginRight: "35px",
		marginTop: "10px",
	},
	numberingSettingLable: {
		fontFamily: "Source Sans Pro",
		fontStyle: "normal",
		fontWeight: "600",
		fontSize: "16px",
		lineHeight: "16px",
		color: "#000000",
	},
	formatSectionNoneLine: {
		border: "1px solid #000000",
		position: "relative",
		width: "57px",
		top: "3px",
		marginBottom: "30px",
	},
	agendaSectionNoneLine: {
		border: "1px solid #000000",
		position: "relative",
		width: "44px",
		top: "13px",
	},
}));

const useInputStyles = makeStyles(inputStyle);

const CustomNumberingPreview = ({}) => {
	const [sectionFormat, setSectionFormat] = useState("");
	const [agendaItemFormat, setAgendaItemFormat] = useState("");
	const [agendaItemList, setAgendaItemList] = useState([]);
	const dispatch = useDispatch();
	const { t } = useTranslation("meetings");
	const { agenda: { customNumbering } = {} } = useSelector((state) => state.meetingTemplatesReducer);
	const { RomanWithBracketCapitalAlphabets, RomanWithBracketSmallAlphabets, RomanWithBracketSmallRoman } = ROMAN_ITEM_FORMAT;
	const {
		NumberWithNumber,
		NumberWithSmallAlphabets,
		NumberBracketWithSmallAlphabets,
		NumberWithCapitalAlphabets,
		NumberBracketWithCapitalAlphabets,
		NumberWithTwoDigits,
		NumberBracketWithTwoDigits,
		NumberWithRoman,
		NumberWithCapitalRoman,
		NumberBracketWithRoman,
		NumberBracketWithCapitalRoman,
	} = NUMBER_ITEMS_FORMAT;
	const {
		AlphabetWithBracketSmallAlphabets,
		AlphabetWithNumber,
		AlphabetWithRoman,
		AlphabetWithDashNumber,
		AlphabetWithBracketNumber,
		AlphabetWithBracketCapitalRoman,
		AlphabetWithBracketSmallRoman,

		AlphabetBracketWithBracetSmallAlphabets,
		AlphabetBracketWithNumber,
		AlphabetBracketWithBracketNumber,
		AlphabetBracketWithBracketCapitalRoman,
		AlphabetBracketWithBracketSmallRoman,
	} = ALPHABETS_ITEM_FORMAT;

	const { NumberValue, NumberValueWithBracket, Alphabets, AlphabetBracket, Roman } = SECTION_FORMAT_ITEM;
	const classes = useStyles();
	const inputClasses = useInputStyles({ fullWidth: true });
	const sectionList = [
		{
			name: "1,2,3,...",
			value: NumberValue,
			preview: "1.",
		},
		{
			name: "1),2),3),...",
			value: NumberValueWithBracket,
			preview: "1)",
		},
		{
			name: "A,B,C...",
			value: Alphabets,
			preview: "A.",
		},
		{
			name: "A),B),C),...",
			value: AlphabetBracket,
			preview: "A)",
		},
		{
			name: "I,II,III...",
			value: Roman,
			preview: "I.",
		},
	];

	const decimalNumberList = [
		{
			name: "1,2,3,...",
			value: NumberWithNumber,
			preview: ["1.1", "1.2"],
		},
		{
			name: "a,b,c,...",
			value: NumberWithSmallAlphabets,
			preview: ["a.", "b."],
		},
		{
			name: "A,B,C,...",
			value: NumberWithCapitalAlphabets,
			preview: ["A.", "B."],
		},
		{
			name: "01,02,03,...",
			value: NumberWithTwoDigits,
			preview: ["1.01", "1.02"],
		},
		{
			name: "I,II,III,...",
			value: NumberWithCapitalRoman,
			preview: ["I.", "II."],
		},
		{
			name: "i,ii,iii,...",
			value: NumberWithRoman,
			preview: ["i.", "ii."],
		},
	];

	const decimalNumberListWithBracket = [
		{
			name: "a),b),c),...",
			value: NumberBracketWithSmallAlphabets,
			preview: ["a)", "b)"],
		},
		{
			name: "A),B),C),...",
			value: NumberBracketWithCapitalAlphabets,
			preview: ["A)", "B)"],
		},
		{
			name: "01),02),03),...",
			value: NumberBracketWithTwoDigits,
			preview: ["01)", "02)"],
		},
		{
			name: "I),II),III),...",
			value: NumberBracketWithCapitalRoman,
			preview: ["I)", "II)"],
		},
		{
			name: "i),ii),iii),...",
			value: NumberBracketWithRoman,
			preview: ["i)", "ii)"],
		},
	];

	const alphabetsNumerals = [
		{
			name: "a),b),c),...",
			value: AlphabetWithBracketSmallAlphabets,
			preview: ["a)", "b)"],
		},
		{
			name: "1,2,3,...",
			value: AlphabetWithNumber,
			preview: ["1.", "2."],
		},
		{
			name: "1),2),3),...",
			value: AlphabetWithBracketNumber,
			preview: ["1)", "2)"],
		},
		{
			name: "-1,-2,-3,...",
			value: AlphabetWithDashNumber,
			preview: ["A-1", "A-2"],
		},
		{
			name: "I,II,III,...",
			value: AlphabetWithBracketCapitalRoman,
			preview: ["I.", "II."],
		},
		{
			name: "i,ii,iii...",
			value: AlphabetWithBracketSmallRoman,
			preview: ["i.", "ii."],
		},
	];

	const alphabetsBracketNumerals = [
		{
			name: "a),b),c),...",
			value: AlphabetBracketWithBracetSmallAlphabets,
			preview: ["a)", "b)"],
		},
		{
			name: "1,2,3,...",
			value: AlphabetBracketWithNumber,
			preview: ["1.", "2."],
		},
		{
			name: "1),2),3),...",
			value: AlphabetBracketWithBracketNumber,
			preview: ["1)", "2)"],
		},
		{
			name: "I),II),III),...",
			value: AlphabetBracketWithBracketCapitalRoman,
			preview: ["I)", "II)"],
		},

		{
			name: "i),ii),iii),...",
			value: AlphabetBracketWithBracketSmallRoman,
			preview: ["i)", "ii)"],
		},
	];
	const romanNumerals = [
		{
			name: "A),B),C)...",
			value: RomanWithBracketCapitalAlphabets,
			preview: ["A)", "B)"],
		},
		{
			name: "a),b),c)...",
			value: RomanWithBracketSmallAlphabets,
			preview: ["a)", "b)"],
		},
		{
			name: "i),ii),iii)...",
			value: RomanWithBracketSmallRoman,
			preview: ["i)", "ii)"],
		},
	];

	useEffect(() => {
		if (customNumbering.sectionFormat) {
			setSectionFormat(customNumbering.sectionFormat);
		}
	}, [customNumbering.sectionFormat]);

	useEffect(() => {
		if (customNumbering.agendaItemFormat) {
			setAgendaItemFormat(customNumbering.agendaItemFormat);
		}
	}, [customNumbering.agendaItemFormat]);

	useEffect(() => {
		if (sectionFormat && agendaItemFormat && customNumbering.agendaItemFormat != agendaItemFormat) {
			const customNumberingFormat = {
				sectionFormat: sectionFormat,
				agendaItemFormat: agendaItemFormat,
			};

			const defaultCustomNumbering = {
				sectionFormat: "Number",
				agendaItemFormat: "NumberNumber",
			};
			dispatch(setCustomNumberingOption(customNumberingFormat));
		}
	}, [sectionFormat, agendaItemFormat]);

	useEffect(() => {
		let agendaItemFormatSelected = "";
		if (sectionFormat) {
			switch (sectionFormat) {
				case NumberValue:
					agendaItemFormatSelected = decimalNumberList;
					break;
				case NumberValueWithBracket:
					agendaItemFormatSelected = decimalNumberListWithBracket;
					break;
				case Alphabets:
					agendaItemFormatSelected = alphabetsNumerals;
					break;
				case AlphabetBracket:
					agendaItemFormatSelected = alphabetsBracketNumerals;
					break;
				case Roman:
					agendaItemFormatSelected = romanNumerals;
					break;
			}
		}

		setAgendaItemList(agendaItemFormatSelected);
	}, [sectionFormat]);

	const displaySectionFormat = useCallback(() => {
		let result = sectionList.filter((data) => {
			return data.value == sectionFormat;
		});
		let final = result && result[0] && result[0].preview;

		return final;
	}, [sectionFormat]);

	const displayAgendaItem = () => {
		let result =
			agendaItemList &&
			agendaItemList.filter((data) => {
				return data.value == agendaItemFormat;
			});
		let final = result && result[0] && result[0].preview;
		return final;
	};

	const getSections = () => {
		const sectionsItems = [];
		if (sectionList && sectionList.length) {
			sectionList.forEach((section) => {
				sectionsItems.push(
					<MenuItem key={`section-${section.value}`} value={section.value}>
						<div>{section.name}</div>
					</MenuItem>,
				);
			});
		}

		return sectionsItems;
	};

	const getAgendaList = () => {
		const agendaItems = [];
		if (agendaItemList && agendaItemList.length) {
			agendaItemList.forEach((item) => {
				agendaItems.push(
					<MenuItem key={`item-${item.value}`} value={item.value}>
						<div>{item.name}</div>
					</MenuItem>,
				);
			});
		}

		return agendaItems;
	};

	const handleFieldChange = (e, field) => {
		const {
			target: { value },
		} = e;

		if (field == "sectionFormat") {
			telemetryAddEvent(`Meeting Template - "Customer Numbering Section format"`);
			setSectionFormat(value);
			setAgendaItemFormat("");
		} else if (field == "agendaItemFormat") {
			telemetryAddEvent(`Meeting Template - "Customer Numbering Agenda Item format"`);
			setAgendaItemFormat(value);
		}
	};

	const setFormatPreviewWidth = () => {
		if (agendaItemFormat == NumberBracketWithTwoDigits || agendaItemFormat == NumberWithTwoDigits) {
			return clsx(classes.formatPreviewWidth, classes.formatPreview);
		}
		return classes.formatPreview;
	};

	return (
		<div className={classes.numberingPreview}>
			<div className={classes.numberingSettingLable}>{t("NumberingSettings")}</div>

			{displaySectionFormat() ? (
				<div className={setFormatPreviewWidth()}>
					<div className={classes.formatSection}>
						<span className={classes.number}>{displaySectionFormat()}</span>
						<hr className={classes.formatSectionLine} />
					</div>

					{displayAgendaItem() &&
						displayAgendaItem().length > 0 &&
						displayAgendaItem().map((number) => {
							return (
								<div className={classes.agendaItemSection}>
									<span className={classes.number}>{number}</span>
									<hr className={classes.agendaSectionLine} />
								</div>
							);
						})}
				</div>
			) : (
				<CircularProgressIndicator data-cy="loading" />
			)}

			<SelectInput
				id="section-format"
				className={clsx(inputClasses.smallInput, classes.centerStyle)}
				noDefaultClassName
				fullWidth
				label={"Section Format"}
				value={sectionFormat}
				onChange={(e) => handleFieldChange(e, "sectionFormat")}
				data-cy="section-format"
			>
				{getSections()}
			</SelectInput>
			<SelectInput
				id="agenda-list"
				className={clsx(inputClasses.smallInput, classes.centerStyle)}
				noDefaultClassName
				fullWidth
				label={"Agenda Item Format"}
				value={agendaItemFormat}
				onChange={(e) => handleFieldChange(e, "agendaItemFormat")}
				data-cy="section-format"
			>
				{getAgendaList()}
			</SelectInput>
		</div>
	);
};

export default CustomNumberingPreview;
