import GoalStatusesEnum from "./enums/GoalStatuses";

export function isPublished(publicStatus) {
	return publicStatus === GoalStatusesEnum().PUBLISHED.value || publicStatus === GoalStatusesEnum().OUTOFSYNC.value;
}

export function isPublishedInSync(publicStatus) {
	return publicStatus === GoalStatusesEnum().PUBLISHED.value;
}

export function isShared(publicStatus) {
	return publicStatus === GoalStatusesEnum().INTERNAL.value || publicStatus === GoalStatusesEnum().OUTOFSYNC.value;
}

export function isSharedInSync(publicStatus) {
	return publicStatus === GoalStatusesEnum().INTERNAL.value;
}
