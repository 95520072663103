import React from "react";
import { useTranslation } from "react-i18next";

import Droppable from "atlas/components/DragAndDrop/Droppable";
import Draggable from "atlas/components/DragAndDrop/Draggable";
import BlockUser from "./BlockUser";

const NotificationUsers = (props) => {
	const { notificationUsers, handleRemoveUser, classes, highlightDroppable, dragPlaceholders } = props;
	const { t } = useTranslation("workflows");

	return (
		<Droppable
			className={classes.selectedUsers}
			dropId="notification-users"
			useHighlightDroppable
			highlightDroppable={highlightDroppable}
			dropComponent="div"
		>
			{notificationUsers.length > 0 ? (
				<ol data-cy="notification-users">
					{notificationUsers.map((user) => (
						<Draggable
							key={user.id}
							dragId={user.id}
							dragComponent={BlockUser}
							dragAttributePassthrough
							canDrag
							dragPlaceholder={dragPlaceholders.includes(user.id)}
							user={user}
							actionIcon="remove"
							actionTooltip={t("tooltips.removeUserFromNotifications")}
							handleAction={(e) => {
								e.stopPropagation();

								handleRemoveUser(user.id);
							}}
						></Draggable>
					))}
				</ol>
			) : (
				<div className={classes.noUsers}>
					<div>{t("detail.noSelectedUsers")}</div>
				</div>
			)}
		</Droppable>
	);
};

export default NotificationUsers;
