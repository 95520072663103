import React from "react";

import ComponentContainer from "atlas/components/ComponentContainer/ComponentContainer";
import { useWidthUp } from "atlas/utils/useWidth";

const TableOfContentsContainer = (props) => {
	const { innerId, children, containerClass } = props;
	const widthUpMd = useWidthUp("md");

	return widthUpMd ? (
		<ComponentContainer containerClass={containerClass} id={innerId} onlyScrollbar>
			{children}
		</ComponentContainer>
	) : null;
};

export default TableOfContentsContainer;
