import React from "react";
import { useTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";
import clsx from "clsx";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";

import CircularProgressIndicator from "atlas/components/Progress/CircularProgressIndicator";
import MenuCell from "atlas/components/TableData/MenuCell";
import TableData from "atlas/components/TableData/TableData";
import TextCell from "atlas/components/TableData/TextCell";
import { blackColor } from "atlas/assets/jss/shared";
import LinkCell from "components/TableData/LinkCell";
import TimeCell from "components/TableData/TimeCell";

const useStyles = makeStyles({
	headCell: {
		"& div": {
			fontWeight: "600 !important",
			fontSize: "16px !important",
			lineHeight: "20px !important",
			whiteSpace: "nowrap",
		},
	},
	activeHead: {
		textAlign: "center",
	},
	noResults: {
		padding: "16px",
	},
	mobileTable: {
		"& thead th:not(:first-child)": {
			display: "none",
		},
		"& tbody": {
			display: "grid",
			"& td": {
				display: "block",
			},
			"& td:nth-child(-n+3)": {
				display: "inline-grid",
			},
			"& td:not(:last-child)": {
				borderColor: "transparent",
			},
		},
	},
});

const MeetingTemplateList = (props) => {
	const { meetingTemplates, handleMeetingTemplateEvent } = props;
	const { t } = useTranslation("meetings");
	const classes = useStyles();

	const tableHeadings = [
		{
			id: "name",
			disablePadding: false,
			label: t("templateList.header.name"),
			retainCase: true,
			rowColor: blackColor[1],
			navigateUrl: (row) => `/meetingtemplate/${row.id}`,
			component: LinkCell,
		},
		{
			id: "boardName",
			disablePadding: false,
			label: t("templateList.header.board"),
			retainCase: true,
			rowColor: blackColor[1],
			navigateUrl: (row) => `/boards/edit/${row.boardId}`,
			component: LinkCell,
		},
		{
			id: "location",
			disablePadding: false,
			label: t("templateList.header.location"),
			retainCase: true,
			rowColor: blackColor[1],
			component: TextCell,
		},
		{
			id: "startTime",
			disablePadding: false,
			label: t("templateList.header.startTime"),
			retainCase: true,
			rowColor: blackColor[1],
			component: TimeCell,
		},
		{
			id: "menu",
			disablePadding: false,
			label: "",
			rowColor: blackColor[1],
			width: "76px",
			component: MenuCell,
		},
	];

	const menuOptions = [
		{
			label: t("app:buttons.duplicate"),
			tooltip: t("templateList.tooltips.duplicate"),
			actionFunction: (meetingTemplate) => handleMeetingTemplateEvent({ eventName: "duplicate", meetingTemplate }),
			"data-cy": "duplicate",
		},
	];
	if (meetingTemplates && meetingTemplates.length > 1) {
		menuOptions.push({
			label: t("app:buttons.delete"),
			tooltip: t("templateList.tooltips.delete"),
			actionFunction: (meetingTemplate) => handleMeetingTemplateEvent({ eventName: "delete", meetingTemplate }),
			"data-cy": "delete",
		});
	}

	return meetingTemplates ? (
		meetingTemplates.length > 0 ? (
			<div className={clsx({ [classes.mobileTable]: isMobile })}>
				<TableData
					showToolbar={false}
					headCells={tableHeadings}
					menuOptions={menuOptions}
					menuTooltipText={t("templateList.tooltips.meetingTemplateOptions")}
					rows={meetingTemplates}
					selectProperty="id"
					defaultOrderBy="name"
					rowClickAction={() => {}}
					withoutMultiSelect
					overrideClasses={classes}
					data-cy="meeting-template-list"
				/>
			</div>
		) : (
			<div className={classes.noResults}>
				<Box>
					<Typography variant="h3">{t("templateList.noResults.title")}</Typography>
				</Box>
				<Box mt={3}>
					<Typography variant="body2">{t("templateList.noResults.description")}</Typography>
				</Box>
			</div>
		)
	) : (
		<CircularProgressIndicator />
	);
};

export default MeetingTemplateList;
