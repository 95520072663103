import View from "@ckeditor/ckeditor5-ui/src/view";
import "@ckeditor/ckeditor5-ui/theme/components/input/input.css"; // Ckeditor 34 ++
// import "@ckeditor/ckeditor5-ui/theme/components/inputtext/inputtext.css";

const linkIcon = `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="21px" height="24px" viewBox="0 0 21 24" version="1.1"><path d="M15,15.2 L15,8.8 C15,6.92223185 13.4777681,5.4 11.6,5.4 C9.72223185,5.4 8.2,6.92223185 8.2,8.8 L8.2,20 C8.2,22.7614237 10.4385763,25 13.2,25 C14.5260824,25 15.797852,24.4732158 16.7355339,23.5355339 C17.6732158,22.597852 18.2,21.3260824 18.2,20 L18.2,5.6 C18.2,1.95492065 15.2450793,-1 11.6,-1 C7.95492065,-1 5,1.95492065 5,5.6 L5,15.2 C5,15.7522847 5.44771525,16.2 6,16.2 C6.55228475,16.2 7,15.7522847 7,15.2 L7,5.6 C7,3.05949015 9.05949015,1 11.6,1 C14.1405098,1 16.2,3.05949015 16.2,5.6 L16.2,20 C16.2,20.7956495 15.8839295,21.5587112 15.3213203,22.1213203 C14.7587112,22.6839295 13.9956495,23 13.2,23 C11.5431458,23 10.2,21.6568542 10.2,20 L10.2,8.8 C10.2,8.02680135 10.8268014,7.4 11.6,7.4 C12.3731986,7.4 13,8.02680135 13,8.8 L13,15.2 C13,15.7522847 13.4477153,16.2 14,16.2 C14.5522847,16.2 15,15.7522847 15,15.2 Z" transform="translate(11.600000, 12.000000) rotate(-315.000000) translate(-11.600000, -12.000000) "/></svg>`;

export default class InputFileView extends View {
	constructor(locale, allowedFileExtensions, translations) {
		super(locale);

		this.set("files", null);
		this.set("placeholder");
		this.set("hasError", false);
		this.set("multiple", false);
		this.set("ariaDescribedById");
		this.set("accept", "");

		this.locale = locale;
		this.translations = translations;
		this.setAcceptableFileExtensions(allowedFileExtensions);
		this.children = this.createCollection();

		this.fileInputDiv = this._createFileInputDiv();
		this.previewDiv = this._createPreviewDiv();

		this.children.add(this.previewDiv);
		this.children.add(this.fileInputDiv);

		this.listenTo(this.fileInputDiv, "edit", () => {
			this.click();
		});

		this.fileInputView.element.addEventListener("change", () => {
			const curFiles = this.fileInputView.element.files;
			this.set("files", curFiles);
			if (curFiles.length === 0) {
				this.setPreviewText("Select file to upload");
			} else {
				let previewText = curFiles[0].name;
				for (let i = 1; i < curFiles.length; i++) {
					previewText += `<br/>${curFiles[i].name}`;
				}
				this.setPreviewText(previewText);
			}
		});

		this.setTemplate({
			tag: "div",
			attributes: {
				class: [],
				tabindex: "-1",
			},
			children: this.children,
		});
	}

	render() {
		super.render();
	}

	getFiles() {
		return this.fileInputView.element.files;
	}

	resetFiles() {
		this.fileInputView.element.value = "";
		this.set("files", null);
	}

	select() {
		return this.fileInputView.element.select();
	}

	focus() {
		return this.fileInputView.element.focus();
	}

	click() {
		return this.fileInputView.element.click();
	}

	setPreviewText(text) {
		this.previewDiv.element.innerHTML = text;
	}

	enableMultipleSelect(status) {
		this.set("multiple", status);
	}

	setAcceptableFileExtensions(extensions) {
		let accept = "";
		for (let i = 0; i < extensions.length; i++) {
			accept += `.${extensions[i]},`;
		}
		this.set("accept", accept);
	}

	_createFileInputDiv() {
		const fileInputDiv = new View();
		const children = this.createCollection();
		const t = this.t;

		const fileInput = this._createFileInput();

		const label = this.translations ? this.translations.buttons.selectFile : "Select File...";
		const button = new View(this.locale);
		const bind = button.bindTemplate;
		button.setTemplate({
			tag: "button",
			attributes: {
				type: "button",
				class: ["ck-file-select-btn", "MuiButtonBase-root", "MuiButton-root", "MuiButton-contained", "MuiButton-containedPrimary"],
				title: label,
			},
			children: [
				{
					tag: "span",
					attributes: { class: ["MuiButton-label"] },
					children: [{ text: label }],
				},
			],
			on: {
				click: bind.to("clicked"),
			},
		});

		const fileInputLbl = new View();
		fileInputLbl.setTemplate({
			tag: "label",
			attributes: {
				for: "",
				class: [],
			},
			children: [
				{
					tag: "span",
					attributes: { class: [] },
				},
				fileInput,
			],
		});

		children.add(fileInputLbl);
		children.add(button);

		fileInputDiv.setTemplate({
			tag: "div",
			attributes: {
				class: ["ck-file-input"],
			},
			children: children,
		});

		this.fileInputView = fileInput;

		button.delegate("clicked").to(fileInputDiv, "edit");

		return fileInputDiv;
	}

	_createFileInput() {
		const fileInputView = new View();
		const bind = this.bindTemplate;
		fileInputView.setTemplate({
			tag: "input",
			attributes: {
				type: "file",
				class: [],
				accept: bind.to("accept"),
				files: bind.to("files"),
				multiple: bind.to("multiple"),
				tabindex: -1,
			},
			on: {
				input: bind.to("input"),
			},
		});

		return fileInputView;
	}

	_createPreviewDiv() {
		const t = this.t;
		const previewDiv = new View();
		const previewP = new View();
		const children = this.createCollection();
		const bind = this.bindTemplate;

		previewP.setTemplate({
			tag: "p",
			attributes: {
				class: [],
			},
		});

		previewDiv.set("hasError", false);
		previewDiv.set("ariaDescribedById");
		previewDiv.setTemplate({
			tag: "div",
			attributes: {
				class: ["ck-file-preview", bind.if("hasError", "ck-error")],
				"aria-invalid": bind.if("has-error", true),
				"aria-describedby": bind.to("ariaDescribedById"),
			},
			children: children,
		});

		return previewDiv;
	}
}
