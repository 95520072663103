import React from "react";
import { useTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";
import clsx from "clsx";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";

import CircularProgressIndicator from "atlas/components/Progress/CircularProgressIndicator";
import TableData from "atlas/components/TableData/TableData";
import { blackColor } from "atlas/assets/jss/shared";
import LinkCell from "./LinkCell";
import ActiveLabelCell from "./ActiveLabelCell";
import PasswordSetupButtonCell from "./PasswordSetupButtonCell";

const useStyles = makeStyles({
	headCell: {
		"& div": {
			fontWeight: "600 !important",
			fontSize: "16px !important",
			lineHeight: "20px !important",
			whiteSpace: "nowrap",
		},
	},
	activeHead: {
		textAlign: "center",
	},
	noResults: {
		padding: "16px",
	},
	mobileTable: {
		"& thead th:not(:first-child)": {
			display: "none",
		},
		"& tbody": {
			display: "grid",
			"& td": {
				display: "block",
			},
			"& td:nth-child(-n+3)": {
				display: "inline-grid",
			},
			"& td:not(:last-child)": {
				borderColor: "transparent",
			},
		},
	},
});

const UserList = (props) => {
	const { users, userChecked, sendPasswordSetup } = props;
	const { t } = useTranslation("users");
	const classes = useStyles();

	const userTableHeadings = [
		{
			id: "firstName",
			disablePadding: false,
			label: t("list.header.firstName"),
			retainCase: true,
			rowColor: blackColor[1],
			component: LinkCell,
		},
		{
			id: "lastName",
			disablePadding: false,
			label: t("list.header.lastName"),
			retainCase: true,
			rowColor: blackColor[1],
			component: LinkCell,
		},
		{
			id: "userName",
			disablePadding: false,
			label: t("list.header.userName"),
			retainCase: true,
			rowColor: blackColor[1],
			component: LinkCell,
		},
		{
			id: "emailAddress",
			disablePadding: false,
			label: t("list.header.emailAddress"),
			retainCase: true,
			rowColor: blackColor[1],
			component: LinkCell,
		},
		{
			id: "inactive",
			disablePadding: false,
			label: t("list.header.active"),
			headClassName: classes.activeHead,
			retainCase: true,
			rowColor: blackColor[1],
			component: ActiveLabelCell,
		},
		{
			id: "passwordExpiry",
			disablePadding: false,
			label: t("list.header.passwordExpiryDate"),
			retainCase: true,
			rowColor: blackColor[1],
			component: (cellProps) => {
				const { row } = cellProps;
				return (
					<PasswordSetupButtonCell
						{...cellProps}
						label={t("buttons.sendPasswordSetupRequest")}
						tooltip={t("tooltips.sendPasswordSetupRequest")}
						onClick={() => {
							sendPasswordSetup([row.id]);
						}}
					/>
				);
			},
		},
	];

	return users ? (
		users.length > 0 ? (
			<div className={clsx({ [classes.mobileTable]: isMobile })}>
				<TableData
					showToolbar={false}
					headCells={userTableHeadings}
					rows={users}
					selectProperty="id"
					defaultOrderBy="firstName"
					checkedAction={userChecked}
					rowClickAction={() => {}}
					overrideClasses={classes}
					data-cy="user-list"
				/>
			</div>
		) : (
			<div className={classes.noResults}>
				<Box>
					<Typography variant="h3">{t("list.noResults.title")}</Typography>
				</Box>
				<Box mt={3}>
					<Typography variant="body2">{t("list.noResults.description")}</Typography>
				</Box>
			</div>
		)
	) : (
		<CircularProgressIndicator />
	);
};

export default UserList;
