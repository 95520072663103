import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { useDispatch } from "react-redux";

import { Tabs, Tab } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import typographyStyle from "atlas/assets/jss/components/typographyStyle";
import { infoColor } from "atlas/assets/jss/shared";
import Icon from "atlas/components/Icon/Icon";
import GenericDialog from "atlas/components/Dialogs/GenericDialog";
import SearchInput from "components/Search/SearchInput";
import GoalsList from "./GoalsList";
import { getGoalsToLink, generateGoalLinks } from "redux/goals/actions";
import Typography from "@mui/material/Typography";
import telemetryAddEvent from "utils/telemetryAddEvent";

import CircularProgressIndicator from "atlas/components/Progress/CircularProgressIndicator";

const useStyles = makeStyles(() => ({
	dialog: {
		"& .MuiDialog-paper": {
			width: "440px",
			maxWidth: "100%",
		},
		"& .MuiDialogContent-root": {
			padding: "0",
		},
	},
	content: {
		display: "flex",
		flexDirection: "column",
		flexShrink: "1",
		gap: "16px",
		"& > div:not([data-search])": {
			paddingInline: "24px",
		},
		"& > div[data-search]": {
			paddingLeft: "8px",
		},
	},
	tabs: {
		"& .MuiTab-root": {
			...typographyStyle.tab,
			color: infoColor,
			minWidth: "auto",
			minHeight: "auto",
			height: "40px",
			padding: "8px 0",
			margin: "0 16px",
			"&.Mui-selected": {
				...typographyStyle.tab,
				color: infoColor,
			},
		},
		"& .MuiTabs-indicator": {
			backgroundColor: infoColor,
		},
	},
	searchContainerLoading: {
		marginTop: "16px",
	},
	dataContainer: {
		flexGrow: "1",
		overflow: "auto",
		paddingBottom: "16px",
		maxHeight: "250px",
	},
}));

const AddGoalsToAgendaDialog = (props) => {
	const {
		show = true,
		options: { callback, itemGuid },
		onClose,
		showSignIn,
		meetingId,
		isAgendaItem = false,
		isMinutesPage = false,
	} = props;
	const { t } = useTranslation("meetings");
	const [selectedTab, setSelectedTab] = useState(0);
	const [searchKeywords, setSearchKeywords] = useState("");
	const i18n = t("addPolicyToAgendaDialog");
	const classes = useStyles();
	const dispatch = useDispatch();
	const [internalGoals, setInternalGoals] = useState([]);
	const [publishedGoals, setPublishedGoals] = useState([]);
	const [internalGoalsSelected, setInternalGoalsSelected] = useState([]);
	const [publishedGoalsSelected, setPublishedGoalsSelected] = useState([]);
	const [searchPublishedGoals, setSearchPublishedGoals] = useState([]);
	const [searchInternalGoals, setSearchInternalGoals] = useState([]);
	const [loading, setLoading] = useState(true);
	const [adding, setAdding] = useState(false);
	const publishApiType = "2";
	const internalApiType = "3";

	useEffect(() => {
		loadGoals(0);
	}, []);

	const loadGoals = (tabType) => {
		let type = tabType === 1 ? internalApiType : publishApiType;
		setLoading(true);
		dispatch(getGoalsToLink(type))
			.then((res) => {
				setLoading(false);
				if (type === "2") {
					res.body.forEach(() => {
						if (publishedGoals.length > 0) {
							const up = publishedGoals.map((item) => {
								return { ...item, selected: item.selected || false };
							});
							setPublishedGoals(up);
						} else {
							const updatedGoals = res.body.map((goal) => ({
								...goal,
								selected: goal.selected || false,
							}));
							setPublishedGoals(updatedGoals);
						}
					});
				} else {
					setLoading(false);
					res.body.forEach(() => {
						if (internalGoals.length > 0) {
							const up = internalGoals.map((item) => {
								return { ...item, selected: item.selected || false };
							});
							setInternalGoals(up);
						} else {
							const updatedGoals = res.body.map((goal) => ({
								...goal,
								selected: goal.selected || false,
							}));
							setInternalGoals(updatedGoals);
						}
					});
				}
			})
			.catch((err) => {
				showSignIn(err, () => {
					this.loadGoals(more);
					setLoading(false);
				});
			});
	};

	const renderGoalList = () => {
		if (searchKeywords.length > 0) {
			return selectedTab == 1 ? searchInternalGoals : searchPublishedGoals;
		} else {
			return selectedTab == 1 ? internalGoals : publishedGoals;
		}
	};

	const onSelectedGoals = (goals, type) => {
		let filerselectedItems = goals.filter((item) => item.selected);

		if (selectedTab == 1) {
			if (searchKeywords.length > 0) {
				setInternalGoalsSelected((prevSelected) => [...new Set([...prevSelected, ...filerselectedItems])]);
			} else {
				setInternalGoalsSelected([...new Set(filerselectedItems)]);
			}
		} else {
			if (searchKeywords.length > 0) {
				setPublishedGoalsSelected((prevSelected) => [...new Set([...prevSelected, ...filerselectedItems])]);
			} else {
				setPublishedGoalsSelected([...new Set(filerselectedItems)]);
			}
		}
	};

	const onTabChange = (_e, newTab) => {
		setSearchKeywords("");
		setSelectedTab(newTab);
		loadGoals(newTab);
	};

	const handleSearchChange = (e) => {
		const { value } = e.target;
		setSearchKeywords(value);
		const lowerCaseValue = value.toLowerCase();

		if (selectedTab == 1) {
			const filteredPeople = internalGoals.filter((item) => item.name.toLowerCase().includes(lowerCaseValue));
			setSearchInternalGoals(filteredPeople);
		}
		const filteredPeople = publishedGoals.filter((item) => item.name.toLowerCase().includes(lowerCaseValue));
		setSearchPublishedGoals(filteredPeople);
	};

	const onClearSearchClick = () => {
		setSearchKeywords("");
	};
	const handleTeleMetryMessage = () => {
		if (isMinutesPage) {
			return "Goal linked";
		} else if (isAgendaItem) return "Agenda Item - Goal linked";
		else return "Agenda Edit - Goal linked";
	};

	const handleAddItem = () => {
		telemetryAddEvent(handleTeleMetryMessage());
		setAdding(true);
		let selectedItems = [...publishedGoalsSelected, ...internalGoalsSelected];
		let goalsids = [];
		selectedItems.forEach((item) => {
			(item.closed = item.closed), (item.title = item.name);
			item.itemGuid = item.guid;
			goalsids.push(item.id);
		});

		let agendaData = {
			meetingId: meetingId,
			linkedToItemId: itemGuid,
			goalIds: goalsids,
		};

		dispatch(generateGoalLinks(agendaData))
			.then(() => {
				callback(selectedItems);
				onClose();
				setAdding(false);
			})
			.catch((err) => {
				console.log(err);
				onClose();
			});
	};

	const handleCancel = () => {
		onClose();
	};
	const dialog = {
		title: i18n.title,
		primaryTitle: t("addGoalsToAgendaDialog.buttons.addGoal"),
		primaryAction: handleAddItem,
		secondaryTitle: t("app:buttons.cancel"),
		secondaryAction: handleCancel,
	};
	return (
		<GenericDialog
			className={classes.dialog}
			show={show}
			title={t("addGoalsToAgendaDialog.title")}
			primaryAction={dialog.primaryAction}
			primaryTitle={t("addGoalsToAgendaDialog.buttons.addGoal")}
			primaryDisabled={adding || (publishedGoalsSelected.length <= 0 && internalGoalsSelected.length <= 0)}
			secondaryAction={dialog.secondaryAction}
			secondaryTitle={dialog.secondaryTitle}
			closeIcon={<Icon name="close" />}
			data-cy="add-Goal-to-agenda-dialog"
		>
			<div className={classes.content}>
				<div>
					<Tabs className={classes.tabs} aria-label={i18n.tabs.ariaLabel} value={selectedTab} onChange={onTabChange}>
						<Tab label={t("addGoalsToAgendaDialog.publishedLabel")} data-cy="tab-published" />
						<Tab label={t("addGoalsToAgendaDialog.InternalLabel")} data-cy="tab-internal" />
					</Tabs>
				</div>
				<div
					className={clsx({
						[classes.searchContainerLoading]: false,
					})}
				>
					<SearchInput
						size="extra-small"
						fullWidth
						value={searchKeywords || ""}
						showClearIcon={searchKeywords && searchKeywords.length > 0}
						onChange={handleSearchChange}
						onIconClick={() => {
							handleSearch(searchKeywords);
						}}
						onClearClick={onClearSearchClick}
						data-cy="goal-search"
					/>
				</div>
				{!loading ? (
					<div className={classes.dataContainer} data-cy="goal-tree">
						{renderGoalList() && renderGoalList().length > 0 ? (
							<GoalsList onSelectedGoals={onSelectedGoals} selectedTab={selectedTab} goals={renderGoalList()} />
						) : (
							<Typography variant="h5">No Goal Added</Typography>
						)}
					</div>
				) : (
					<CircularProgressIndicator data-cy="loading" />
				)}
			</div>
		</GenericDialog>
	);
};

export default AddGoalsToAgendaDialog;
