import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { useWidthUp } from "atlas/utils/useWidth";
import { updateToolbar } from "redux/app/actions";
import { updatePageHeader } from "redux/pageHeader/actions";

const AgendaItemTopBar = (props) => {
	const {
		saveStatus,
		saveTooltip = "",
		uploadingStatus,
		uploadingTooltip = "",
		isResubmit,
		openSubmitForApprovalDialog,
		openCancelApprovalDialog,
		handleCommentsDetails,
		panels = {},
	} = props;
	const widthUpMd = useWidthUp("md");
	const { t } = useTranslation("agendaItems");
	const dispatch = useDispatch();

	// Display tools
	useEffect(() => {
		dispatch(
			updatePageHeader({
				primaryAction: openSubmitForApprovalDialog,
				primaryActionText: openSubmitForApprovalDialog ? t(`toolbar.${isResubmit ? "resubmitItem" : "submitItem"}`) : undefined,
				primaryActionTooltip: openSubmitForApprovalDialog ? t(`toolbar.${isResubmit ? "resubmitItem" : "submitItem"}`) : undefined,
				primaryActionDisabled: !openSubmitForApprovalDialog,
				primaryActionDisabledBackground: !openSubmitForApprovalDialog,
			}),
		);

		dispatch(
			updateToolbar({
				display: true,
				left: {
					tools: [
						{
							id: "menu-cancel-approval",
							label: t("toolbar.cancelApproval"),
							tooltipText: t("tooltips.cancelApproval"),
							ariaLabel: t("tooltips.cancelApproval"),
							onClick: openCancelApprovalDialog,
							dataCy: "menu-cancel-approval",
							hidden: !openCancelApprovalDialog,
						},
					],
				},
				right: {
					tools: [
						{
							id: "comments-details",
							label: t("toolbar.commentsDetails"),
							tooltipText: t("tooltips.commentsDetails"),
							onClick: handleCommentsDetails,
							dataCy: "comments-details",
							hidden: !widthUpMd,
							highlight: panels.commentsDetails,
						},
						{
							id: "uploading-status",
							label: uploadingStatus,
							tooltipText: uploadingTooltip,
							hidden: !uploadingStatus,
						},
						{
							id: "saving-status",
							label: saveStatus,
							tooltipText: saveTooltip,
							hidden: !saveStatus,
							dataCy: "goalSaveStatus",
						},
					],
				},
			}),
		);
	}, [widthUpMd, uploadingStatus, saveStatus, panels, openSubmitForApprovalDialog !== null]);

	return null;
};

export default AgendaItemTopBar;
