import { blackColor } from '../shared';

const iconButtonRippleStyle = () => ({
	root: {},
	child: {
		backgroundColor: blackColor[0],
	},
	rippleVisible: {
		opacity: 0.1,
		transform: 'scale(1)',
	},
	'@keyframes mui-ripple-enter': {
		'0%': {
			transform: 'scale(0)',
			opacity: 0.1,
		},
		'100%': {
			transform: 'scale(1)',
			opacity: 0.9,
		},
	},
	'@keyframes pulsate': {
		'0%': {
			transform: 'scale(1)',
		},
		'50%': {
			transform: 'scale(0.92)',
		},
		'100%': {
			transform: 'scale(1)',
		},
	},
});

export default iconButtonRippleStyle;
