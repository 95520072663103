import React from 'react';
import clsx from 'clsx';

import makeStyles from '@mui/styles/makeStyles';
import { TableCell } from '@mui/material';

import AccessibleIconButton from '../Buttons/AccessibleIconButton';
import tableDataStyle from 'assets/jss/components/tableDataStyle';

const useStyles = makeStyles({
	...tableDataStyle,
	iconCell: {
		textAlign: 'right',
	},
	button: {
		margin: '-12px 0',
	},
});

const IconButtonCell = (props) => {
	const { cell, row } = props;
	const classes = useStyles();

	const handleClick = (e) => {
		e.stopPropagation();
		e.preventDefault();

		if (typeof cell.handleAction === 'function') {
			cell.handleAction(row, e);
		}
	};

	const showIcon = typeof cell.showButton === 'function' ? cell.showButton(row) : true;

	return (
		<TableCell className={clsx(classes.cell, classes.iconCell)}>
			{showIcon && (
				<AccessibleIconButton
					className={classes.button}
					iconColor="inherit"
					aria-label={cell.tooltip}
					onClick={handleClick}
					iconName={cell.icon}
					iconSize="20px"
					dataCy="row-icon-button"
				/>
			)}
		</TableCell>
	);
};

export default IconButtonCell;
