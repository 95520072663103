import React from "react";
import { Routes, Route } from "react-router-dom";

import PublicDocument from "views/MeetingDocument/PublicDocument";

const PublicMeetingDocumentRoutes = () => {
	// Base path = /public/meeting/document/
	return (
		<Routes>
			<Route path=":id" element={<PublicDocument />} />
		</Routes>
	);
};

export default PublicMeetingDocumentRoutes;
