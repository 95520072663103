/* eslint-disable no-param-reassign */
import React, { useState, useEffect, useRef, useCallback, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate, useMatch } from "react-router-dom";
import queryString from "query-string";
import request from "superagent";
import { v4 as uuid } from "uuid";
import Tooltip from "atlas/components/Tooltip/Tooltip";
import Icon from "atlas/components/Icon/Icon";
import IconButton from "@mui/material/IconButton";
import { whiteColor } from "atlas/assets/jss/shared";
import AgendaSettingsDialog from "../../components/Dialogs/AgendaSettingsDialog";
import CircularProgressIndicator from "atlas/components/Progress/CircularProgressIndicator";
import ComponentContainer from "atlas/components/ComponentContainer/ComponentContainer";
import withErrorHandling from "components/ErrorHOC";
import { API_HOST } from "config/env";
import { resetPageConfigs, updatePageConfigs, updateTabs, updateToolbar } from "redux/app/actions";
import { updatePageHeader } from "redux/pageHeader/actions";
import {
	getMeetingTemplate,
	getMeetingTemplateAgendaItems,
	getMeetingTemplateMinutesItems,
	setMeetingTemplate,
	persistMeetingTemplate,
} from "redux/meetingTemplate/actions";
import { updateHandlers, PROGRESS_HUB } from "utils/communication/SignalrClient";
import { useSignInDialog } from "utils/isSignedIn";
import telemetryAddEvent from "utils/telemetryAddEvent";
import { useUpdateObject, initializeValidate, setValidate } from "utils/updateObject";
import BoardSaveProgress from "views/Boards/components/BoardSaveProgress";
import MeetingTemplateAgenda from "./MeetingTemplateAgenda";
import MeetingTemplateDetail from "./MeetingTemplateDetail";
import MeetingTemplateMinutes from "./MeetingTemplateMinutes";
import useBackButtonHandler from "utils/hooks/useBackButtonHandler.js";

const telemetryPage = "Meeting Template";

const tabs = {
	detail: 0,
	agenda: 1,
	minutes: 2,
};
const clearErrors = {
	name: "",
};
const clearSavingProgress = {
	label: " ",
	percent: 0,
};

const MeetingTemplateContainer = () => {
	const { params: { id } = {} } = useMatch({ path: "/meetingtemplate/:id", end: true }) || {};
	const { minutes: showMinutes } = queryString.parse(location.search) || {};
	const { t } = useTranslation("meetings");
	const navigate = useNavigate();
	const [tab, setTab] = useState(showMinutes ? tabs.minutes : tabs.detail);
	const [boards, setBoards] = useState(null);
	const [errors, setErrors] = useState({
		...clearErrors,
	});
	const [progress, setProgress] = useState({
		...clearSavingProgress,
	});
	const [dialogs, setDialogs] = useState({});
	const [panels, setPanels] = useState({});
	const [showAgendaSettingsDialog, setAgendaSettingsDialog] = useState(false);
	const guid = useRef();
	const saveState = useRef({});
	const dispatch = useDispatch();
	const showSignIn = useSignInDialog();
	const meetingTemplatesReducer = useSelector((state) => state.meetingTemplatesReducer);
	const { meetingTemplate, saving, updated, agenda = {}, minutes = {} } = meetingTemplatesReducer;
	const appReducer = useSelector((state) => state.appReducer);
	const {
		signalR: { client, handler },
	} = appReducer;

	const checkUnsavedChangesBeforeClosing = (event) => {
		const { saving, updated } = saveState.current;

		if (saving || updated) {
			event.preventDefault();
			event.returnValue = "";
		}
	};

	const handlePrevious = () => {
		const { saving, updated } = saveState.current;

		if (saving || updated) {
			if (confirm(t("templateDetail.unsavedChanges"))) {
				navigate("/meetingtemplates");
			} else {
				return;
			}
		} else {
			navigate("/meetingtemplates");
		}

		return true;
	};

	const changeTab = (newTab) => {
		const { saving, updated } = saveState.current;

		if ((!saving && !updated) || confirm(t("templateDetail.unsavedChanges"))) {
			dispatch(
				updateTabs({
					selectedTab: newTab,
				}),
			);

			setTab(newTab);
		}
	};

	const optionsClick = () =>
		setPanels((prev) => ({
			options: Boolean(!prev.options),
		}));

	const loadBoards = () => {
		request
			.get(`${API_HOST}/api/boards`)
			.query({ boardAdminOnly: true })
			.then((res) => {
				setBoards(res.body.boards);
			})
			.catch((err) => {
				showSignIn(err, () => {
					loadBoards();
				});
			});
	};

	const openAgendaSettingsDialog = () => {
		telemetryAddEvent(`${telemetryPage} - Agenda Settings Dialog`);

		setAgendaSettingsDialog(true);
	};
	const closeAgendaSettingsDialog = () => setAgendaSettingsDialog(false);

	const handleSave = useCallback(() => {
		// Enable full validation
		initializeValidate(setErrors, true);

		if (!hasValidationErrors(errors)) {
			dispatch(persistMeetingTemplate(guid.current));

			telemetryAddEvent(`${telemetryPage} - Meeting Template Detail Saved`);
		}
	}, [errors]);

	const clearProgress = () => {
		setDialogs({ saveProgress: false });
		setProgress({
			...clearSavingProgress,
		});
	};

	const updateProgress = (percentage, message) => {
		setProgress({
			label: message,
			percent: percentage,
		});
		if (percentage >= 100) {
			clearProgress(false);
		} else if (percentage > 0 || message) {
			setDialogs({ saveProgress: true });
		}
	};

	const validateMeetingTemplate = (updatedMeetingTemplate, updatedField) => {
		// Wrap this in setTimeout to avoid a white screen when updating from a CKEditor. I don't know why it happens.
		setTimeout(() => {
			setErrors((prev) => {
				if (!updatedMeetingTemplate.boardId) {
					prev.boardId = t("templateDetail.validation.meetingGroup");
				} else {
					prev.boardId = "";
				}

				if (!updatedMeetingTemplate.name) {
					prev.name = t("templateDetail.validation.name");
				} else {
					prev.name = "";
				}

				if (
					typeof updatedMeetingTemplate.hour !== "number" ||
					isNaN(updatedMeetingTemplate.hour) ||
					typeof updatedMeetingTemplate.minute !== "number" ||
					isNaN(updatedMeetingTemplate.minute) ||
					typeof updatedMeetingTemplate.period !== "number" ||
					isNaN(updatedMeetingTemplate.period)
				) {
					prev.hour = t("templateDetail.validation.startTime");
					prev.minute = " ";
					prev.period = " ";
				} else {
					prev.hour = "";
					prev.minute = "";
					prev.period = "";
				}

				if (!updatedMeetingTemplate.location) {
					prev.location = t("templateDetail.validation.location");
				} else {
					prev.location = "";
				}

				setValidate(prev, updatedField);

				return { ...prev };
			});
		});
	};

	const hasValidationErrors = (errorsObject) => errorsObject.boardId || errorsObject.name || errorsObject.hour || errorsObject.location;

	const updateMeetingTemplate = useUpdateObject(
		(meetingTemplate) => dispatch(setMeetingTemplate(meetingTemplate)),
		undefined,
		validateMeetingTemplate,
	);

	useEffect(() => {
		guid.current = uuid();

		updateHandlers(dispatch, handler, PROGRESS_HUB, { announceProgress: updateProgress });

		client.ensureStarted().then(() => client.progressHub.registerProgressGuid(guid.current));

		dispatch(resetPageConfigs({}));

		loadBoards();

		window.addEventListener("beforeunload", checkUnsavedChangesBeforeClosing);

		return () => {
			client.ensureStarted().then(() => client.progressHub.clearProgressGuid(guid.current));

			updateHandlers(dispatch, handler, PROGRESS_HUB, { announceProgress: null });

			window.removeEventListener("beforeunload", checkUnsavedChangesBeforeClosing);
		};
	}, [id]);
	const unsavedChangesText = t("templateDetail.unsavedChanges");
	useBackButtonHandler(saveState.current, "/meetingtemplates", unsavedChangesText);

	useEffect(() => {
		dispatch(
			updateTabs({
				display: true,
				selectedTab: tab,
				tabsOptions: [
					{ key: "detail", label: t("templateDetail.tabs.detail"), dataCy: "detail-tab" },
					{ key: "agenda", label: t("templateDetail.tabs.agenda"), dataCy: "agenda-tab" },
					{ key: "minutes", label: t("templateDetail.tabs.minutes"), dataCy: "minutes-tab" },
					{
						key: "status",
						status: true,
						label: tab === tabs.agenda ? agenda?.status?.label : tab === tabs.minutes ? minutes?.status?.label : null,
						tooltip: tab === tabs.agenda ? agenda?.status?.tooltip : tab === tabs.minutes ? minutes?.status?.tooltip : null,
						dataCy: "status-tab",
					},
				],
				onChange: (_e, newTab) => changeTab(newTab),
			}),
		);
	}, [tab, agenda?.status, minutes?.status]);

	useEffect(() => {
		if (!meetingTemplate || meetingTemplate.id !== parseInt(id, 10)) {
			dispatch(getMeetingTemplate(id, showSignIn));
		}
	}, [id, meetingTemplate]);

	useEffect(() => {
		dispatch(
			updatePageConfigs({
				title: t("templateDetail.title", { name: meetingTemplate?.name || "" }),
				back: {
					action: handlePrevious,
				},
				telemetryPage,
				contentPaper: { transparent: false },
				contentMaxWidth: "xl",
			}),
		);
	}, [meetingTemplate?.name]);

	useEffect(() => {
		dispatch(
			updatePageHeader({
				primaryAction: tab === tabs.detail ? handleSave : null,
				primaryActionText: tab === tabs.agenda ? null : !saving ? t("app:buttons.save") : t("app:buttons.saving"),
				primaryActionTooltip: !saving ? t("templateDetail.tooltips.save") : undefined,
				primaryActionDisabled: tab === tabs.detail ? saving || !updated : null,
				additionalRightAction:
					tab === tabs.agenda ? (
						<Tooltip title={t("tooltips.agendaSettings")}>
							<IconButton
								id="agenda-settings-button"
								aria-label={t("tooltips.agendaSettings")}
								onClick={openAgendaSettingsDialog}
								size="large"
							>
								<Icon name="settings" color={whiteColor}></Icon>
							</IconButton>
						</Tooltip>
					) : null,
			}),
		);
	}, [id, saving, updated, tab, handleSave, openAgendaSettingsDialog]);

	useEffect(() => {
		saveState.current = { saving, updated };
	}, [saving, updated]);

	useEffect(() => {
		if (tab === tabs.agenda && (!agenda.items || agenda.id !== parseInt(id, 10))) {
			dispatch(getMeetingTemplateAgendaItems(id, showSignIn));
		}
	}, [id, tab, agenda.items]);

	useEffect(() => {
		if (tab === tabs.minutes && (!minutes.items || minutes.id !== parseInt(id, 10))) {
			dispatch(getMeetingTemplateMinutesItems(id, showSignIn));
		}
	}, [id, tab, minutes.items]);

	useEffect(() => {
		dispatch(
			updateToolbar({
				display: tab === tabs.minutes,
				right: {
					tools: [
						{
							id: "open-options",
							icon: "settings",
							tooltipText: t("templateDetail.tooltips.minutesOptions"),
							ariaLabel: t("templateDetail.tooltips.minutesOptions"),
							onClick: optionsClick,
							dataCy: "toggle-options",
						},
					],
				},
			}),
		);
	}, [tab]);

	const loaded = meetingTemplate && meetingTemplate.id === parseInt(id, 10) && boards;
	const agendaLoaded = agenda.items && agenda.id === parseInt(id, 10);
	const minutesLoaded = minutes.items && minutes.id === parseInt(id, 10);

	return (
		<>
			{tab === tabs.agenda && showAgendaSettingsDialog && agendaLoaded ? (
				<AgendaSettingsDialog show={openAgendaSettingsDialog} onClose={closeAgendaSettingsDialog} telemetryPage={telemetryPage} />
			) : null}
			<ComponentContainer padding={`${tab === tabs.detail ? "16" : "0"}px`}>
				{loaded ? (
					<>
						{tab === tabs.detail && (
							<>
								{dialogs.saveProgress && progress.percent > 0 && (
									<BoardSaveProgress progress={progress} show={Boolean(dialogs.saveProgress)}></BoardSaveProgress>
								)}
								<MeetingTemplateDetail
									meetingTemplate={meetingTemplate}
									boards={boards}
									errors={errors}
									handleUpdate={updateMeetingTemplate}
								/>
							</>
						)}
						{tab === tabs.agenda ? agendaLoaded ? <MeetingTemplateAgenda /> : <CircularProgressIndicator /> : null}
						{tab === tabs.minutes ? (
							minutesLoaded ? (
								<MeetingTemplateMinutes showOptions={panels.options} boards={boards} handleClose={optionsClick} />
							) : (
								<CircularProgressIndicator />
							)
						) : null}
					</>
				) : (
					<CircularProgressIndicator />
				)}
			</ComponentContainer>
		</>
	);
};

export default withErrorHandling(MeetingTemplateContainer);
