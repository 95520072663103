import React, { useState, Fragment } from "react";
import { withTranslation } from "react-i18next";
import clsx from "clsx";

import { Divider, Grid, MenuItem } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import processHtml from "utils/processHtml";
import CircularProgressIndicator from "atlas/components/Progress/CircularProgressIndicator";
import AccessibleIconButton from "atlas/components/Buttons/AccessibleIconButton";
import typographyStyle from "atlas/assets/jss/components/typographyStyle";
import { blackColor } from "atlas/assets/jss/shared";
import ButtonWithTooltip from "atlas/components/Buttons/ButtonWithTooltip";
import NonModalMenu from "atlas/components/Menu/NonModalMenu";
import { MEDIUM } from "atlas/utils/buttonSize";
import meetingContentStyle from "assets/jss/components/meetingContentStyle";
import { TYPE_FOR, TYPE_AGAINST } from "../utils/voteType";

const useStyles = makeStyles({
	header: {
		display: "flex",
		alignItems: "middle",
		boxSizing: "border-box",
		height: "64px",
		padding: "16px",
		paddingBottom: "0",
		margin: "-16px",
		marginBottom: "0",
	},
	title: {
		...typographyStyle.drawerTitle,
		color: blackColor[3],
		flexGrow: "1",
		display: "flex",
		alignItems: "center",
	},
	close: {
		marginTop: "-4px",
		marginRight: "-12px",
	},
	divider: {
		margin: "0 -24px",
		marginTop: "8px",
		height: "2px",
	},
	bottomDivider: {
		margin: "0 -24px",
		height: "2px",
	},
	topMargin: {
		marginTop: "16px",
	},
	content: {
		...meetingContentStyle,
	},
	uploadStatus: {
		...typographyStyle.toolbarLabel,
		color: blackColor[3],
		opacity: "1",
		marginRight: "16px",
	},
	uploaded: {
		opacity: "0",
		transition: "opacity 1s ease-in",
	},
});

const MinutesPanel = (props) => {
	const {
		t,
		items,
		placeholders,
		closeMinutesPanel,
		addToAgendaClick,
		downloadClick,
		uploadClick,
		handleMinutesUpload,
		previewMinutesOptions,
		minutesUploadStatus = {},
		members = [],
		votingSettings,
	} = props;
	const [openMenu, setOpenMenu] = useState({});
	const [anchor, setAnchor] = useState({});
	const [downloading, setDownloading] = useState({});
	const classes = useStyles();

	const getMemberName = (memberId) => {
		const foundMember = members.find((member) => member.id === memberId);
		const name = foundMember ? foundMember.name : "";

		return name;
	};

	const handleToggleMenu = (e, type) => {
		e.stopPropagation();
		e.preventDefault();
		setAnchor({ ...anchor, [type]: e.currentTarget });
		setOpenMenu((prevOpen) => ({
			...openMenu,
			[type]: !prevOpen[type],
		}));
	};

	const handleCloseMenu = () => {
		const { event: { target = {} } = {} } = window;

		let li = {};
		if ((target.tagName || "").toLowerCase() !== "li") {
			li = target.closest("li") || {};
		}

		if (!["upload-minutes"].includes(target.id) && !["upload-minutes"].includes(li.id)) {
			setAnchor({});
			setOpenMenu({});
		}
	};

	const getRequestToSpeakItems = (minutesItem) => {
		const { requestsToSpeak, defaultPublicCommentHeading } = items;

		return requestsToSpeak.filter(
			(requestToSpeak) =>
				requestToSpeak.headingGuid === minutesItem.agendaItemGuid ||
				(requestToSpeak.headingGuid.length === 0 &&
					defaultPublicCommentHeading &&
					defaultPublicCommentHeading.guid === minutesItem.agendaItemGuid),
		);
	};

	const replacePlaceholders = (text) => {
		let replacedText = text;

		// Only replace if the text might have a placeholder
		if (text && text.indexOf("[") >= 0) {
			placeholders.forEach((placeholder) => {
				replacedText = replacedText.replace(placeholder.placeholder, placeholder.value);
			});
		}

		return replacedText;
	};

	const menuOptions = [
		{
			id: "upload-minutes",
			icon: "expand-down",
			label: t("app:buttons.upload"),
			ariaLabel: t("tooltips.uploadMinutes"),
			actionFunction: (e) => {
				handleToggleMenu(e, "upload");
			},
			"data-cy": "uploadMinutes",
		},
	];

	return (
		<>
			<Grid container direction="column" style={{ height: "100%", flexWrap: "nowrap", padding: "16px" }}>
				<div className={classes.header} datacy="notes-panel-header">
					<div className={classes.title}>
						<div>{t("minutes")}</div>
					</div>
					<div className={classes.close}>
						{(minutesUploadStatus.uploading || minutesUploadStatus.uploaded) && (
							<span
								className={clsx(classes.uploadStatus, { [classes.uploaded]: minutesUploadStatus.uploaded })}
								data-cy="minutes-upload-status"
							>
								{t(`agendaMenu:${minutesUploadStatus.uploading ? "uploading" : "uploaded"}`)}
							</span>
						)}
						<AccessibleIconButton
							aria-label={t("tooltips.closeNotes")}
							onClick={closeMinutesPanel}
							iconName="close"
							dataCy="close-notes"
							tooltipText={t("tooltips.closeNotes")}
							className={classes.icon}
							name="close"
						/>
					</div>
				</div>
				<Divider className={classes.divider} />
				<Grid item style={{ flex: "1", overflow: "auto", margin: "0 -16px", padding: "16px" }} tabIndex="0">
					<ul className={classes.content} style={{ padding: "0" }}>
						{items.minutesItems.map((item) => {
							return (
								<Fragment key={`fragment-${item.guid}`}>
									<li key={`minutes-${item.guid}`} style={{ listStyle: "none", margin: "8px 0" }} data-cy="minutes-item">
										<span>{item.fields.Number ? item.fields.Number.Value : ""}&nbsp;</span>
										<div style={{ display: "inline-block" }}>
											{item.fields.Name ? processHtml(replacePlaceholders(item.fields.Name.Value)) : ""}
										</div>
										<div>{item.fields.Text ? processHtml(replacePlaceholders(item.fields.Text.Value)) : ""}</div>
										{item.fields.MovedBy && item.fields.MovedBy.Value > 0 && (
											<div>
												{t("movedBy")}:&nbsp;{getMemberName(item.fields.MovedBy.Value)}
											</div>
										)}
										{item.fields.SecondedBy && item.fields.SecondedBy.Value > 0 && (
											<div>
												{t("secondedBy")}:&nbsp;{getMemberName(item.fields.SecondedBy.Value)}
											</div>
										)}
										{item.fields.Voting && item.fields.Voting.Value && item.fields.Voting.Value.length > 0 && (
											<>
												<div>
													{votingSettings.votingLabels.for || t("voting.for")}:&nbsp;
													{(item.fields.Voting.Value.filter((vote) => vote.Vote === TYPE_FOR) || []).map((forVote, index) => {
														return `${index > 0 ? ", " : ""}${forVote.Name}`;
													})}
												</div>
												<div>
													{votingSettings.votingLabels.against || t("voting.against")}:&nbsp;
													{(item.fields.Voting.Value.filter((vote) => vote.Vote === TYPE_AGAINST) || []).map((againstVote, index) => {
														return `${index > 0 ? ", " : ""}${againstVote.Name}`;
													})}
												</div>
											</>
										)}
										{item.fields.Disposition && <span>{item.fields.Disposition.Value}</span>}
									</li>
									{getRequestToSpeakItems(item).map((requestToSpeakItem) => {
										return (
											<li
												key={`request-to-speak-${requestToSpeakItem.guid}`}
												style={{ listStyle: "none", margin: "8px 0" }}
												data-cy="request-to-speak-item"
											>
												<div>{`${requestToSpeakItem.firstName} ${requestToSpeakItem.lastName}`}</div>
												<div>{requestToSpeakItem.topic}</div>
											</li>
										);
									})}
								</Fragment>
							);
						})}
					</ul>
				</Grid>
				<Grid item style={{ flex: "0", flexWrap: "wrap" }}>
					<Divider className={classes.bottomDivider} />
					<Grid container spacing={1} direction="row">
						<Grid item xm={12} lg={4}>
							<ButtonWithTooltip
								variant="outlined"
								title={t("tooltips.addToAgenda")}
								size={MEDIUM}
								hasTopMargin
								onClick={addToAgendaClick}
								data-cy="addToAgenda"
								fullWidth
							>
								{t("buttons.addToAgenda")}
							</ButtonWithTooltip>
						</Grid>
						<Grid item xm={12} lg={4}>
							<ButtonWithTooltip
								variant="outlined"
								title={t("tooltips.downloadMinutes")}
								size={MEDIUM}
								hasTopMargin
								onClick={(e) => {
									handleToggleMenu(e, "download");
								}}
								data-cy="download"
								fullWidth
							>
								{t("app:buttons.download")}
							</ButtonWithTooltip>
							{anchor.download && (
								<NonModalMenu
									id="split-button-menu-download-minutes"
									className={classes.splitButtonMenu}
									anchorEl={anchor.download}
									open={Boolean(anchor.download)}
									onClose={handleCloseMenu}
								>
									<MenuItem
										key="download-member-minutes"
										onClick={() => {
											setDownloading({ members: true });
											downloadClick(true, () => setDownloading({}));
										}}
										data-cy="split-button-option-member-minutes"
									>
										{downloading.members ? <CircularProgressIndicator size={20} /> : t("memberMinutes")}
									</MenuItem>
									<MenuItem
										key="download-public-minutes"
										onClick={() => {
											setDownloading({ public: true });
											downloadClick(false, () => setDownloading({}));
										}}
										data-cy="split-button-option-public-minutes"
									>
										{downloading.public ? <CircularProgressIndicator size={20} /> : t("publicMinutes")}
									</MenuItem>
								</NonModalMenu>
							)}
						</Grid>
						<Grid item xm={10} lg={3}>
							<ButtonWithTooltip
								variant="outlined"
								title={t("tooltips.previewMinutes")}
								size={MEDIUM}
								hasTopMargin
								onClick={(e) => {
									handleToggleMenu(e, "previewMinutes");
								}}
								data-cy="preview"
								fullWidth
							>
								{t("app:buttons.preview")}
							</ButtonWithTooltip>
							{anchor.previewMinutes && (
								<NonModalMenu
									id="previewMinutesMenu"
									className={classes.menu}
									anchorEl={anchor.previewMinutes}
									keepMounted
									open={openMenu.previewMinutes}
									onClose={handleCloseMenu}
									options={previewMinutesOptions}
									position="top-end"
								/>
							)}
						</Grid>
						{menuOptions && menuOptions.length > 0 && (
							<Grid item lg={1}>
								<AccessibleIconButton
									className={classes.topMargin}
									color="inherit"
									aria-label={t("app:menu.options")}
									onClick={(e) => {
										handleToggleMenu(e, "overflow");
									}}
									iconName="more"
									dataCy="allNotesOverflowMenu"
									tooltipText={t("app:menu.options")}
								/>
								{anchor.overflow && (
									<NonModalMenu
										id="allNotesOverflowMenu"
										className={classes.menu}
										anchorEl={anchor.overflow}
										keepMounted
										open={openMenu.overflow}
										onClose={handleCloseMenu}
										options={menuOptions}
										position="bottom-end"
									/>
								)}
							</Grid>
						)}
						{anchor.upload && (
							<NonModalMenu
								id="split-button-menu-upload-minutes"
								className={classes.splitButtonMenu}
								anchorEl={anchor.upload}
								open={Boolean(anchor.upload)}
								onClose={handleCloseMenu}
							>
								<MenuItem key="upload-member-minutes" onClick={() => uploadClick(true)} data-cy="split-button-option-member-minutes">
									{t("memberMinutes")}
								</MenuItem>
								<MenuItem key="upload-public-minutes" onClick={() => uploadClick(false)} data-cy="split-button-option-public-minutes">
									{t("publicMinutes")}
								</MenuItem>
							</NonModalMenu>
						)}
						<input
							id="upload-member-minutes-file"
							type="file"
							onChange={(e) => {
								handleMinutesUpload(e, true);
								handleCloseMenu();
							}}
							style={{ display: "none" }}
							accept=".doc, .docx"
						/>
						<input
							id="upload-public-minutes-file"
							type="file"
							onChange={(e) => {
								handleMinutesUpload(e, false);
								handleCloseMenu();
							}}
							style={{ display: "none" }}
							accept=".doc, .docx"
						/>
					</Grid>
				</Grid>
			</Grid>
		</>
	);
};

export default withTranslation("meetings")(MinutesPanel);
