import React from 'react';
import PropTypes from 'prop-types';
import { format, getDate, parseISO } from 'date-fns';

import Typography from '@mui/material/Typography';

const CalendarIcon = (props) => {
	// The 'calendar-icon' class is not used by Community or Policy. If it is not used by any other projects, it can be removed
	const { className = 'calendar-icon', date, ...otherProps } = props;

	return (
		<div className={className} {...otherProps}>
			<Typography variant="h5">{format(parseISO(date), 'MMM')}</Typography>
			<Typography variant="h4">{getDate(parseISO(date))}</Typography>
		</div>
	);
};

CalendarIcon.propTypes = {
	className: PropTypes.string,
	date: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]).isRequired,
};

export default CalendarIcon;
