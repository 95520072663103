import React from 'react';
import PropTypes from 'prop-types';

import { FormControlLabel, Box, FormControl, RadioGroup, Radio } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import statusIndicatorStyle from '../../assets/jss/components/statusIndicatorStyle';
import stackedRadioStyle from '../../assets/jss/components/radioStyle';
import { infoColor } from '../../assets/jss/shared';
import Icon from '../Icon/Icon';
import LargeLabel from '../Typography/LargeLabel';
import Tooltip from '../Tooltip/Tooltip';

const useStyles = makeStyles(statusIndicatorStyle);
const useRadioStyle = makeStyles(stackedRadioStyle);

const RadioButton = (props) => {
	const {
		labelText,
		infoText = '',
		options,
		getId = (option) => `radio-${option}`,
		getKey = (option) => `key-${option}`,
		getValue = (option) => option,
		getDataCy = (option) => `radio-${option}`,
		getLabel = (option) => option,
		objectToUpdate,
		field,
		isForStatus,
		handleChange,
		row,
		labelPlacement,
		sx = {},
	} = props;

	const classes = useStyles();
	const radioClasses = useRadioStyle();

	const getStatusOptionColor = (option) => {
		if (!isForStatus) return '';
		const optionValue = option.value || option;
		if (optionValue.toLowerCase() === 'published') {
			return classes.success;
		}
		if (optionValue.toLowerCase() === 'draft') {
			return classes.draft;
		}
		return classes.disabled;
	};

	const getLabelClasses = (option) => {
		const optionColor = getStatusOptionColor(option);
		if (isForStatus) {
			return `${classes.label} ${optionColor}`;
		}
		return radioClasses.label;
	};

	return (
		<>
			{labelText && (
				<Box mb={1}>
					<LargeLabel color="black" semiBold component="span">
						{labelText}
					</LargeLabel>
					{infoText && (
						<Tooltip title={infoText}>
							<Box ml={1} style={{ verticalAlign: 'middle' }} component="span">
								<Icon name="status-info" size="16" color={infoColor} />
							</Box>
						</Tooltip>
					)}
				</Box>
			)}
			<FormControl component="fieldset" sx={sx.formControl}>
				<RadioGroup
					aria-label={field}
					name={field}
					value={objectToUpdate[field]}
					onChange={(event) => handleChange(event.target.value, objectToUpdate, field)}
					row={row}
				>
					{options.map((option) => {
						return (
							<Tooltip key={getKey(option)} title={option.tooltip}>
								<FormControlLabel
									id={getId(option)}
									value={getValue(option)}
									data-cy={getDataCy(option)}
									control={<Radio color="primary" />}
									label={getLabel(option)}
									labelPlacement={labelPlacement}
									classes={{
										label: getLabelClasses(option),
									}}
								/>
							</Tooltip>
						);
					}, this)}
				</RadioGroup>
			</FormControl>
		</>
	);
};

RadioButton.propTypes = {
	labelText: PropTypes.string,
	labelPlacement: PropTypes.string,
	options: PropTypes.array.isRequired,
	getId: PropTypes.func,
	getKey: PropTypes.func,
	getValue: PropTypes.func,
	getDataCy: PropTypes.func,
	getLabel: PropTypes.func,
	objectToUpdate: PropTypes.object,
	field: PropTypes.string,
	isForStatus: PropTypes.bool,
	handleChange: PropTypes.func.isRequired,
	row: PropTypes.bool,
};

RadioButton.defaultProps = {
	labelText: undefined,
	getId: (option) => `radio-${option}`,
	getKey: (option) => `key-${option}`,
	getValue: (option) => option,
	getDataCy: (option) => `radio-${option}`,
	getLabel: (option) => option,
	objectToUpdate: undefined,
	field: '',
	isForStatus: false,
	row: false,
	labelPlacement: 'end',
};

export default RadioButton;
