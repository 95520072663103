import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import makeStyles from '@mui/styles/makeStyles';
import { FormHelperText } from '@mui/material';

import Icon from '../Icon/Icon';
import ExternalInputLabel from './InputLabel';
import Tooltip from '../Tooltip/Tooltip';
import {
	TOP_START,
	TOP,
	TOP_END,
	RIGHT_START,
	RIGHT,
	RIGHT_END,
	BOTTOM_END,
	BOTTOM,
	BOTTOM_START,
	LEFT_END,
	LEFT,
	LEFT_START,
} from '../../utils/tooltipPlacement';
import inputStyle from '../../assets/jss/components/inputStyle';
import { grayColor } from '../../assets/jss/shared';

const useInputStyles = makeStyles(inputStyle);

const SelectInput = (props) => {
	const {
		noDefaultClassName,
		className,
		id,
		label,
		tooltipText,
		tooltipPlacement,
		size,
		externalLabel,
		externalLabelStyle,
		labelSize,
		bottomSpacing,
		preText,
		postText,
		helperText,
		error,
		marginTop,
		disabled,
		children,
		...newProps
	} = props;
	const [tooltipOpen, setTooltipOpen] = useState(false);
	const classes = useInputStyles({ marginTop });

	const useExternalLabel = label && externalLabel;

	const getSelect = () => (
		<Select
			{...newProps}
			id={id}
			label={!externalLabel ? label : undefined}
			labelId={`${id}-label`}
			inputProps={{ 'aria-labelledby': `${id}-label` }}
			variant="outlined"
			error={error}
			disabled={disabled}
			IconComponent={(iconProps) => (
				<div className={classes.selectIcon} {...iconProps}>
					<Icon name="expand-down" color={disabled ? grayColor[4] : undefined} />
				</div>
			)}
		>
			{children}
		</Select>
	);

	const getFieldWithTooltip = () =>
		tooltipText ? (
			<Tooltip placement={tooltipPlacement} title={tooltipText} open={tooltipOpen}>
				<div onMouseEnter={() => setTooltipOpen(true)} onMouseLeave={() => setTooltipOpen(false)} onClick={() => setTooltipOpen(false)}>
					{getSelect()}
				</div>
			</Tooltip>
		) : (
			getSelect()
		);

	const getField = () => (
		<FormControl
			variant="outlined"
			size={size}
			className={clsx(
				{
					[classes.defaultInput]: !noDefaultClassName,
				},
				className,
				{
					[classes.smallInput]: size === 'small',
					[classes.smallInputExternalLabel]: externalLabel && size === 'small',
					[classes.disabled]: disabled,
				},
			)}
			disabled={disabled}
		>
			{!externalLabel && label && (
				<InputLabel className={classes.outlinedLabel} id={`${id}-label`} htmlFor={id}>
					{label}
				</InputLabel>
			)}
			{getFieldWithTooltip()}
			{helperText && <FormHelperText error={error}>{helperText}</FormHelperText>}
		</FormControl>
	);

	const getFieldContainer = () =>
		preText || postText ? (
			<div className={classes.inputRowContainer}>
				{preText && <span className={classes.preText}>{preText}</span>}
				{getField()}
				{postText && <span className={classes.postText}>{postText}</span>}
			</div>
		) : (
			getField()
		);

	const getExternalLabel = () =>
		useExternalLabel ? (
			<ExternalInputLabel htmlFor={id} label={label} size={labelSize} style={externalLabelStyle} bottomSpacing={bottomSpacing} />
		) : null;

	return (
		<div className={classes.container}>
			{useExternalLabel && getExternalLabel()}
			{getFieldContainer()}
		</div>
	);
};

SelectInput.propTypes = {
	noDefaultClassName: PropTypes.bool,
	className: PropTypes.string,
	id: PropTypes.string,
	label: PropTypes.string,
	tooltipText: PropTypes.string,
	tooltipPlacement: PropTypes.oneOf([
		TOP_START,
		TOP,
		TOP_END,
		RIGHT_START,
		RIGHT,
		RIGHT_END,
		BOTTOM_END,
		BOTTOM,
		BOTTOM_START,
		LEFT_END,
		LEFT,
		LEFT_START,
	]),
	size: PropTypes.string,
	externalLabel: PropTypes.bool,
	externalLabelStyle: PropTypes.string,
	labelSize: PropTypes.string,
	bottomSpacing: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
	preText: PropTypes.string,
	postText: PropTypes.string,
	helperText: PropTypes.string,
	error: PropTypes.bool,
	marginTop: PropTypes.string,
	disabled: PropTypes.bool,
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

SelectInput.defaultProps = {
	noDefaultClassName: false,
	className: undefined,
	id: undefined,
	label: undefined,
	tooltipText: undefined,
	tooltipPlacement: BOTTOM,
	size: undefined,
	externalLabel: false,
	externalLabelStyle: 'uppercase',
	labelSize: undefined,
	preText: undefined,
	postText: undefined,
	helperText: undefined,
	error: false,
	marginTop: undefined,
	disabled: false,
};

export default SelectInput;
