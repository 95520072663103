export function timeStampExists(item) {
	return parseInt(item?.fields?.TimeStamp?.Value || "", 10) >= 0;
}

export function getTimeStamp(item) {
	if (timeStampExists(item)) {
		const timestamp = item?.fields?.TimeStamp?.Value;
		return !isNaN(parseInt(timestamp, 10)) ? parseInt(timestamp, 10) : null;
	}
	return null;
}

export function getTimeStampEvent(item) {
	const timestampevent = item?.fields?.TimeStampEvent?.Value;
	return timestampevent ? timestampevent : "";
}

export function getTimeStampPrefix(item, broadcasts) {
	const partsText = ["Part 1: ", "Part 2: ", "Part 3: ", "Part 4: ", "Part 5: ", "Part 6: ", "Part 7: ", "Part 8: ", "Part 9: "];
	let timestampPrefix = "";

	if (broadcasts.length > 1) {
		const timestampEvent = item?.fields?.TimeStampEvent?.Value;
		if (timestampEvent) {
			const broadcastIndex = broadcasts.findIndex((broadcast) => broadcast.youtubeId == timestampEvent);
			if (broadcastIndex > -1 && broadcastIndex < partsText.length) {
				timestampPrefix = partsText[broadcastIndex];
			}
		}
	}

	return timestampPrefix;
}

export function nameVideoParts(broadcasts) {
	const partsText = [" (Part 1)", " (Part 2)", " (Part 3)", " (Part 4)", " (Part 5)", " (Part 6)", " (Part 7)", " (Part 8)", " (Part 9)"];

	if (broadcasts.length < 10) {
		for (var i = 0; i < broadcasts.length; i++) {
			let videoName = broadcasts[i].title;
			const partName = videoName.substring(videoName.length - partsText[0].length, videoName.length);
			if (partsText.indexOf(partName) > -1) {
				videoName = videoName.substring(0, videoName.length - partsText[0].length);
			}

			const newVideoName = videoName + (broadcasts.length > 1 ? partsText[i] : "");
			broadcasts[i].title = newVideoName;
		}
	}

	return broadcasts;
}

export function timeStampToString(secs, preText) {
	let long = "";
	if (secs >= 3600) {
		const h = Math.floor(secs / 3600);
		var m = Math.floor((secs % 3600) / 60);
		var s = Math.floor((secs % 3600) % 60);
		long = `${h > 9 ? h : `0${h}`}h${m > 9 ? m : `0${m}`}m${s > 9 ? s : `0${s}`}s`;
	} else if (secs >= 60) {
		var m = Math.floor((secs % 3600) / 60);
		var s = Math.floor((secs % 3600) % 60);
		long = `00h${m > 9 ? m : `0${m}`}m${s > 9 ? s : `0${s}`}s`;
	} else {
		var s = Math.floor((secs % 3600) % 60);
		long = `00h00m${s > 9 ? s : `0${s}`}s`;
	}

	return preText ? preText + long : long;
}

export function timeStampStringToSeconds(timestring) {
	const h = parseInt(timestring.substring(0, timestring.indexOf("h")).replace(/-/g, "").replace(/\D/g, ""));
	const m = parseInt(timestring.substring(timestring.indexOf("h"), timestring.indexOf("m")).replace(/-/g, "").replace(/\D/g, ""));
	const s = parseInt(timestring.substring(timestring.indexOf("m"), timestring.indexOf("s")).replace(/-/g, "").replace(/\D/g, ""));

	let secs = 0;
	let saveSecs = false;
	if (!isNaN(h)) {
		secs += h * 3600;
		saveSecs = true;
	}
	if (!isNaN(m)) {
		secs += m * 60;
		saveSecs = true;
	}
	if (!isNaN(s)) {
		secs += s;
		saveSecs = true;
	}

	return saveSecs == true ? secs : null;
}

export function setTimeStampInputSelection(timestampinput) {
	const tmptimestampval = timestampinput.value;
	const hms = ["h", "m", "s"];

	let { selectionStart } = timestampinput;
	var leftChar = tmptimestampval.charAt(selectionStart - 1);
	const rightChar = tmptimestampval.charAt(selectionStart);

	if (hms.indexOf(rightChar) >= 0 && hms.indexOf(leftChar) >= 0) {
		// do nothing
	} else if (hms.indexOf(rightChar) >= 0 && hms.indexOf(leftChar) < 0) {
		var secondLeftChar = tmptimestampval.charAt(selectionStart - 2);
		if (hms.indexOf(secondLeftChar) < 0) {
			timestampinput.setSelectionRange(selectionStart - 2, selectionStart);
		} else {
			timestampinput.setSelectionRange(selectionStart - 1, selectionStart);
		}
	} else if (hms.indexOf(leftChar) >= 0 && hms.indexOf(rightChar) < 0) {
		if (leftChar == "s") {
			selectionStart -= 1;
			var leftChar = tmptimestampval.charAt(selectionStart - 1);
			var secondLeftChar = tmptimestampval.charAt(selectionStart - 2);
			if (hms.indexOf(secondLeftChar) < 0) {
				timestampinput.setSelectionRange(selectionStart - 2, selectionStart);
			} else {
				timestampinput.setSelectionRange(selectionStart, selectionStart);
			}
		} else {
			var secondRightChar = tmptimestampval.charAt(selectionStart + 1);
			if (hms.indexOf(secondRightChar) < 0) {
				timestampinput.setSelectionRange(selectionStart, selectionStart + 2);
			} else {
				timestampinput.setSelectionRange(selectionStart, selectionStart + 1);
			}
		}
	} else if (selectionStart == 0 && hms.indexOf(rightChar) < 0) {
		var secondRightChar = tmptimestampval.charAt(selectionStart + 1);
		if (hms.indexOf(secondRightChar) < 0) {
			timestampinput.setSelectionRange(selectionStart, selectionStart + 2);
		} else {
			timestampinput.setSelectionRange(selectionStart, selectionStart + 1);
		}
	} else if (hms.indexOf(rightChar) < 0 && hms.indexOf(leftChar) < 0) {
		timestampinput.setSelectionRange(selectionStart - 1, selectionStart + 1);
	}
}

export function timeStampInputKeyDown(e, inputRef) {
	const timestampinput = inputRef.current;

	const tmptimestampval = timestampinput.value;

	const deleteKeyCodes = [8, 46];
	const arrowKeyCodes = [37, 39];
	const hms = ["h", "m", "s"];

	let { selectionStart } = timestampinput;
	const { selectionEnd } = timestampinput;

	if (isNaN(e.key) && deleteKeyCodes.indexOf(e.keyCode) < 0 && arrowKeyCodes.indexOf(e.keyCode) < 0 && e.key !== "Tab") {
		e.preventDefault();
		return false;
	}
	if (e.keyCode == 8 && selectionStart > 0 && selectionStart == selectionEnd) {
		// backspace
		selectionStart -= 1;
		var charToRemove = tmptimestampval.charAt(selectionStart);
		if (hms.indexOf(charToRemove) >= 0) {
			timestampinput.setSelectionRange(selectionStart, selectionStart);
			e.preventDefault();
			return false;
		}
	} else if (e.keyCode == 46 && selectionStart == selectionEnd) {
		// delete
		var charToRemove = tmptimestampval.charAt(selectionStart);
		if (hms.indexOf(charToRemove) >= 0) {
			selectionStart += 1;
			const nextChar = tmptimestampval.charAt(selectionStart);
			timestampinput.setSelectionRange(selectionStart, selectionStart);
			if (hms.indexOf(nextChar) >= 0) {
				e.preventDefault();
				return false;
			}
		}
	}
}

export function timeStampInputKeyUp(e, inputRef) {
	const timestampinput = inputRef.current;
	const tmptimestampval = timestampinput.value;
	const deleteKeyCodes = [8, 46];
	const arrowKeyCodes = [37, 39];
	const hms = ["h", "m", "s"];

	if (arrowKeyCodes.indexOf(e.keyCode) >= 0) {
		var { selectionStart } = timestampinput;
		const leftChar = tmptimestampval.charAt(selectionStart - 1);
		const rightChar = tmptimestampval.charAt(selectionStart);
		if (hms.indexOf(rightChar) < 0 || hms.indexOf(leftChar) < 0) {
			if (e.keyCode == 37) {
				// left
				timestampinput.setSelectionRange(selectionStart - 1, selectionStart - 1);
			} else if (e.keyCode == 39) {
				// right
				timestampinput.setSelectionRange(selectionStart + 1, selectionStart + 1);
			}
		}
		setTimeStampInputSelection(timestampinput);
	} else if (deleteKeyCodes.indexOf(e.keyCode) < 0) {
		var { selectionStart } = timestampinput;
		const timestring = timestampinput.value;
		let h = timestring.substring(0, timestring.indexOf("h")).replace(/-/g, "").replace(/\D/g, "");
		let m = timestring.substring(timestring.indexOf("h"), timestring.indexOf("m")).replace(/-/g, "").replace(/\D/g, "");
		let s = timestring.substring(timestring.indexOf("m"), timestring.indexOf("s")).replace(/-/g, "").replace(/\D/g, "");
		let timeStampValue = null;
		if (s.length > 2) {
			if (h.length < 2 || (h.length == 2 && h[0] == 0)) {
				h = h.length == 0 ? "--" : h.length == 1 ? `-${h}` : h;
				m = m.length == 0 ? "--" : m.length == 1 ? `-${m}` : m;
				s = s.split("");
				m = m.split("");
				h = h.split("");
				h.shift();
				h.push(m.shift());
				m.push(s.shift());
				timeStampValue = `${h.join("")}h${m.join("")}m${s.join("")}s`;
				timestampinput.setSelectionRange(timeStampValue.indexOf("s"), timeStampValue.indexOf("s"));
			} else {
				timeStampValue = `${h}h${m}m${s.substring(0, 2)}s`;
				timestampinput.setSelectionRange(timeStampValue.indexOf("s"), timeStampValue.indexOf("s"));
			}
		} else if (m.length > 2) {
			timeStampValue = `${h}h${m.substring(0, 2)}m${s}s`;
			timestampinput.setSelectionRange(timeStampValue.indexOf("m"), timeStampValue.indexOf("m"));
		}
		return timeStampValue || timestring;
	}
}
