/* eslint-disable no-plusplus */
import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import request from "superagent";
import { v4 as uuid } from "uuid";

import makeStyles from "@mui/styles/makeStyles";

import Icon from "atlas/components/Icon/Icon";
import { API_HOST } from "config/env";
import GenericDialog from "atlas/components/Dialogs/GenericDialog";
import ProgressBar from "atlas/components/Progress/ProgressBar";
import ErrorDialogMeetingPublish from "components/Dialogs/ErrorDialogMeetingPublish";

import { defaultFont } from "atlas/assets/jss/shared";
import { updatePageConfigs } from "redux/app/actions";
import { setProgressIds } from "redux/app/actions";
import { updateHandlers, PROGRESS_HUB } from "utils/communication/SignalrClient";
import { useAsyncResult } from "utils/useAsyncResult";

const useStyles = makeStyles(() => ({
	dialog: {
		"& .MuiDialog-paper": {
			width: "440px",
		},
	},
	meetingName: {
		...defaultFont,
		fontSize: "22px",
		lineHeight: "1.18",
		fontWeight: "nromal",
		letterSpacing: "normal",
		textAlign: "center",
	},
	progressBar: {
		width: "240px",
		margin: "0 auto",
		marginTop: "24px",
	},
}));

export const PROGRESS_TYPE_PREVIEW = "preview";

const MeetingPreviewDialog = (props) => {
	const { show, meeting, agenda, members, onClose, openSupportRequestDialog, editorFunctions } = props;
	const { t } = useTranslation("meetings");
	const navigate = useNavigate();
	const location = useLocation();
	const [progress, setProgress] = useState({
		label: " ",
		percent: 0,
	});
	const [errors, setErrors] = useState(null);
	const progressIds = useRef({});
	const canceled = useRef(false);
	const { client, handler } = useSelector((state) => state.appReducer.signalR);
	const dispatch = useDispatch();
	const classes = useStyles();

	const navigateToPreview = () => {
		dispatch(
			updatePageConfigs({
				preferedBack: { url: location.pathname + location.search },
			}),
		);

		navigate(`/meeting/preview/${meeting.id}/${agenda ? "agenda" : "minutes"}/${members ? "members" : "public"}`);
	};

	const previewSuccess = (res) => {
		if (res.status === 200) {
			setProgress({
				label: " ",
				percent: 100,
			});

			if (res && res.body && res.body.pdf && res.body.pdf.errors) {
				// backend returns both item and attachment errors under the "pdf.errors" section
				setErrors(res.body.pdf.errors);
			} else {
				setErrors(null);

				if (!canceled.current) {
					navigateToPreview();
				}
			}

			dispatch(setProgressIds());
		}
	};

	const previewError = (err) => {
		if ([408, 504].indexOf(err.status) >= 0) {
			err.timeout = true;
		}
		setErrors(err);
	};

	const [startResultCheck, resultCheckNow] = useAsyncResult(previewSuccess, previewError);

	const updateProgress = (percentage, message) => {
		setProgress({
			label: message,
			percent: percentage,
		});

		if (percentage >= 100) {
			resultCheckNow();
		}

		if (percentage >= 50) {
			dispatch(
				setProgressIds({
					...progressIds.current,
					type: PROGRESS_TYPE_PREVIEW,
				}),
			);

			// Html is complete
			navigateToPreview();
		}
	};
	const clampedProgress = Math.min(progress.percent, 100);

	const generatePreview = (guid) => {
		request
			.post(`${API_HOST}/api/meeting/${meeting.id}/preview`)
			.withCredentials()
			.send({ progressGuid: guid, agenda, members })
			.then((res) => {
				if (res.status === 200) {
					progressIds.current = {
						progressGuid: guid,
						resultId: startResultCheck(res),
					};
				}
			})
			.catch(previewError);
	};

	const handleCancel = () => {
		canceled.current = true;
		onClose();
	};

	useEffect(() => {
		const guid = uuid();

		updateHandlers(dispatch, handler, PROGRESS_HUB, { announceProgress: updateProgress });

		client.ensureStarted().then(() => client.progressHub.registerProgressGuid(guid));

		generatePreview(guid);

		return () => {
			client.ensureStarted().then(() => client.progressHub.clearProgressGuid(guid));

			updateHandlers(dispatch, handler, PROGRESS_HUB, { announceProgress: null });
		};
	}, [meeting, agenda, members]);

	return (
		<>
			{errors && (
				<ErrorDialogMeetingPublish
					agenda={agenda}
					errors={errors}
					handleClose={handleCancel}
					show
					openSupportRequestDialog={openSupportRequestDialog}
					meeting={meeting}
					triggeredBy="preview"
					editorFunctions={editorFunctions}
				/>
			)}
			{!errors && (
				<GenericDialog
					className={classes.dialog}
					show={show}
					title={t(`meetingPreviewDialog.${agenda ? "agenda" : "minutes"}Title`)}
					secondaryAction={handleCancel}
					secondaryTitle={t("app:buttons.cancel")}
					closeIcon={<Icon name="close" />}
					data-cy="meeting-preview"
				>
					<div className={classes.meetingName}>
						{t(`meetingPreviewDialog.${agenda ? "agenda" : "minutes"}Label`, { meetingName: meeting.cleanName })}
					</div>
					<ProgressBar className={classes.progressBar} label={progress.label} progress={clampedProgress} maxLabelWidthPercent={150} />
				</GenericDialog>
			)}
		</>
	);
};

export default MeetingPreviewDialog;
