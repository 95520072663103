import React from "react";
import { useTranslation } from "react-i18next";

import SearchResult from "./SearchResult";

const GoalResult = (props) => {
	const { result } = props;
	const { t } = useTranslation();

	const link = result.canUpdate ? `/goals/edit/${result.guid}` : `/item/${result.guid}/detailpublic`;
	const externalLink = !result.canUpdate;

	return (
		<SearchResult
			result={result}
			icon={{
				name: "goal",
				tooltip: t("search.tooltips.goal"),
			}}
			link={link}
			externalLink={externalLink}
		/>
	);
};

export default React.memo(GoalResult);
