import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
	root: {
		"& .Mui-disabled .MuiStepIcon-root": { color: "#676767" },
		"& .MuiStepLabel-label.Mui-active": {
			color: "#1E1E1E !important",
			fontWeight: 600,
		},
		"& .Mui-completed .MuiStepLabel-label": {
			color: "#1E1E1E",
			fontWeight: 400,
		},
		"& .MuiStepConnector-line": {
			borderColor: "#949494",
		},
	},
}));

const LinearStepper = ({ steps = [], stepNumber }) => {
	//stepper component will start working with index1
	const [activeStep, setActiveStep] = useState(1);
	const classes = useStyles();

	useEffect(() => {
		if (stepNumber) {
			setActiveStep(stepNumber);
		}
	}, [stepNumber]);

	return (
		<Box sx={{ width: "100%" }}>
			<Stepper activeStep={activeStep - 1} className={classes.root}>
				{steps?.map((label, index) => (
					<Step key={label}>
						<StepLabel StepIconProps={{ icon: index + 1 }}>{label}</StepLabel>
					</Step>
				))}
			</Stepper>
		</Box>
	);
};

export default LinearStepper;
