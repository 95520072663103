import React from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import Parser from "html-react-parser";
import { useNavigate, useLocation } from "react-router-dom";

import makeStyles from "@mui/styles/makeStyles";

import { formatDate } from "utils/date";
import { blackColor, primaryColor } from "atlas/assets/jss/shared";
import GenericAvatar, { GENERIC_AVATAR_BACKGROUNDS } from "components/Avatar/GenericAvatar";
import { stripHtml } from "utils/processHtml";
import { updatePageConfigs } from "redux/app/actions";

const defaultFont = {
	fontSize: "14px",
	lineHeight: "18px",
	letterSpacing: "0.875px",
	color: blackColor[2],
};

const useStyles = makeStyles(() => ({
	container: {
		padding: (props) => (!props.inMainContent ? "16px" : "0"),
		paddingBottom: (props) => (props.hideComments ? "16px" : undefined),
		paddingTop: (props) => (props.hideDetails ? "16px" : undefined),
	},
	sectionLabel: {
		fontSize: "16px",
		letterSpacing: "1px",
		fontWeight: "600",
		color: blackColor[2],
		marginBottom: "8px",
	},
	section: {
		...defaultFont,
		marginBottom: "16px",
	},
	comment: {
		marginBottom: "8px",
	},
	lastSection: {
		marginBottom: "0",
	},
	user: {
		display: "flex",
		alignItems: "center",
	},
	avatar: {
		marginRight: "8px",
	},
	userDetail: {
		flexGrow: "1",
	},
	userName: {
		...defaultFont,
		fontSize: "16px",
		lineHeight: "1.25",
		fontWeight: "600",
		color: primaryColor[9],
	},
	title: {
		...defaultFont,
		fontWeight: "600",
		color: blackColor[1],
	},
	date: {
		...defaultFont,
		color: blackColor[0],
	},
	commentText: {
		...defaultFont,
		fontSize: "15px",
		lineHeight: "19px",
	},
}));

const AgendaItemCommentsDetails = (props) => {
	const {
		item = {},
		item: { meeting, headingId, heading } = {},
		comments = [],
		inMainContent,
		hideDetails,
		hideComments,
		linkMeeting,
	} = props;
	const { t } = useTranslation("agendaItems");
	const navigate = useNavigate();
	const location = useLocation();
	const dispatch = useDispatch();
	const classes = useStyles({ inMainContent, hideDetails, hideComments });

	const meetingClick = (e) => {
		e.preventDefault();

		if (meeting) {
			dispatch(
				updatePageConfigs({
					preferedBack: { url: location.pathname.replace("/home/", "/") + location.search },
				}),
			);

			navigate(`/meeting/${meeting.id}`);
		}
	};

	let itemStatus = t("itemStatus.draft");
	let meetingStatus = t("meetingStatus.notSelected");
	let sectionStatus = t("sectionStatus.notSelected");
	let agendaStatus = t("agendaStatus.notPublished");
	if (item.added) {
		itemStatus = t("itemStatus.added");
	} else if (item.submitted) {
		itemStatus = t("itemStatus.submitted");
	}
	if (meeting) {
		const meetingDate = formatDate(null, meeting.startTime, meeting.endTime, t("app:at"), t("from"), t("to"), false);
		meetingStatus =
			linkMeeting && meeting.administrator ? (
				<a href={`/home/meeting/${meeting.id}`} onClick={meetingClick}>{`${meeting.meeting} ${meetingDate}`}</a>
			) : (
				`${meeting.meeting} ${meetingDate}`
			);
		if (meeting.agendaPublished) {
			agendaStatus = t("agendaStatus.published");
		}
	}
	if (headingId > 0) {
		sectionStatus = stripHtml(heading);
	}

	const filteredComments = comments ? comments.filter((comment) => comment.comment) : [];

	return (
		<div className={classes.container}>
			{!hideDetails && (
				<>
					<div className={classes.sectionLabel}>{t("editItem.detailHeading")}</div>
					<div
						className={clsx(classes.section, {
							[classes.lastSection]: hideComments || !comments || comments.length === 0,
						})}
					>
						<div data-cy="item-status">
							<span className={classes.fieldLabel}>{t("editItem.itemStatus")}</span>
							{`: ${itemStatus}`}
						</div>
						<div data-cy="meeting-status">
							<span className={classes.fieldLabel}>{t("editItem.meeting")}</span>
							{": "}
							{meetingStatus}
						</div>
						<div data-cy="section-status">
							<span className={classes.fieldLabel}>{t("editItem.section")}</span>
							{": "}
							{sectionStatus}
						</div>
						<div data-cy="agenda-status">
							<span className={classes.fieldLabel}>{t("editItem.agendaStatus")}</span>
							{`: ${agendaStatus}`}
						</div>
					</div>
				</>
			)}
			{!hideComments && filteredComments.length > 0 && (
				<>
					<div className={classes.sectionLabel}>{t("editItem.commentsHeading")}</div>
					{filteredComments.map((comment, index) => {
						const commentDate = formatDate(comment.dateRecorded, null, null, t("app:at"), t("from"), t("to"), true, true, true);

						return (
							<div
								className={clsx(classes.comment, {
									[classes.lastSection]: filteredComments.length === index + 1,
								})}
								key={`comment-${comment.userId}-${comment.dateRecorded}`}
							>
								<div className={classes.user}>
									<div className={classes.avatar}>
										<GenericAvatar
											imageUrl={comment.profileImageUrl}
											name={comment.name}
											initials={`${(comment.firstName || "").substr(0, 1)}${(comment.lastName || "").substr(0, 1)}`}
											backgroundNumber={((comment.number || 0) % GENERIC_AVATAR_BACKGROUNDS) + 1}
										/>
									</div>
									<div className={classes.userDetail}>
										<div className={classes.userName}>{comment.name}</div>
										{comment.title && <div className={classes.title}>{comment.title}</div>}
										<div className={classes.date}>{commentDate}</div>
									</div>
								</div>
								<div className={classes.commentText}>{Parser(comment.comment.replace(/\n/g, "<br />"))}</div>
							</div>
						);
					})}
				</>
			)}
		</div>
	);
};

export default AgendaItemCommentsDetails;
