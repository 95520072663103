import View from "@ckeditor/ckeditor5-ui/src/view";
import ViewCollection from "@ckeditor/ckeditor5-ui/src/viewcollection";
import ButtonView from "@ckeditor/ckeditor5-ui/src/button/buttonview";

import FocusTracker from "@ckeditor/ckeditor5-utils/src/focustracker";
import FocusCycler from "@ckeditor/ckeditor5-ui/src/focuscycler";
import KeystrokeHandler from "@ckeditor/ckeditor5-utils/src/keystrokehandler";

import pencilIcon from "@ckeditor/ckeditor5-core/theme/icons/pencil.svg";
import { getFileHrefInfo } from "./inlineFileUtils";

const unlinkIcon = `<svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M11.077 15l.991-1.416a.75.75 0 1 1 1.229.86l-1.148 1.64a.748.748 0 0 1-.217.206 5.251 5.251 0 0 1-8.503-5.955.741.741 0 0 1 .12-.274l1.147-1.639a.75.75 0 1 1 1.228.86L4.933 10.7l.006.003a3.75 3.75 0 0 0 6.132 4.294l.006.004zm5.494-5.335a.748.748 0 0 1-.12.274l-1.147 1.639a.75.75 0 1 1-1.228-.86l.86-1.23a3.75 3.75 0 0 0-6.144-4.301l-.86 1.229a.75.75 0 0 1-1.229-.86l1.148-1.64a.748.748 0 0 1 .217-.206 5.251 5.251 0 0 1 8.503 5.955zm-4.563-2.532a.75.75 0 0 1 .184 1.045l-3.155 4.505a.75.75 0 1 1-1.229-.86l3.155-4.506a.75.75 0 0 1 1.045-.184zm4.919 10.562l-1.414 1.414a.75.75 0 1 1-1.06-1.06l1.414-1.415-1.415-1.414a.75.75 0 0 1 1.061-1.06l1.414 1.414 1.414-1.415a.75.75 0 0 1 1.061 1.061l-1.414 1.414 1.414 1.415a.75.75 0 0 1-1.06 1.06l-1.415-1.414z"/></svg>`;
const linkIcon = `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="21px" height="24px" viewBox="0 0 21 24" version="1.1"><path d="M15,15.2 L15,8.8 C15,6.92223185 13.4777681,5.4 11.6,5.4 C9.72223185,5.4 8.2,6.92223185 8.2,8.8 L8.2,20 C8.2,22.7614237 10.4385763,25 13.2,25 C14.5260824,25 15.797852,24.4732158 16.7355339,23.5355339 C17.6732158,22.597852 18.2,21.3260824 18.2,20 L18.2,5.6 C18.2,1.95492065 15.2450793,-1 11.6,-1 C7.95492065,-1 5,1.95492065 5,5.6 L5,15.2 C5,15.7522847 5.44771525,16.2 6,16.2 C6.55228475,16.2 7,15.7522847 7,15.2 L7,5.6 C7,3.05949015 9.05949015,1 11.6,1 C14.1405098,1 16.2,3.05949015 16.2,5.6 L16.2,20 C16.2,20.7956495 15.8839295,21.5587112 15.3213203,22.1213203 C14.7587112,22.6839295 13.9956495,23 13.2,23 C11.5431458,23 10.2,21.6568542 10.2,20 L10.2,8.8 C10.2,8.02680135 10.8268014,7.4 11.6,7.4 C12.3731986,7.4 13,8.02680135 13,8.8 L13,15.2 C13,15.7522847 13.4477153,16.2 14,16.2 C14.5522847,16.2 15,15.7522847 15,15.2 Z" transform="translate(11.600000, 12.000000) rotate(-315.000000) translate(-11.600000, -12.000000) "/></svg>`;

export default class InlineFileViewActions extends View {
	constructor(editor, translations) {
		super(editor.locale);
		this.editor = editor;
		const t = editor.locale.t;

		this.set("href");
		this.translations = translations;
		this.focusTracker = new FocusTracker();
		this.keystrokes = new KeystrokeHandler();

		this.previewButtonView = this._createPreviewButton();
		this.unlinkButtonView = this._createButton(this.translations ? this.translations.tooltips.remove : "Remove Link", unlinkIcon, "unlink");
		this.editButtonView = this._createButton(this.translations ? this.translations.tooltips.edit : "Edit Attachment", pencilIcon, "edit");

		this._focusables = new ViewCollection();
		this._focusCycler = new FocusCycler({
			focusables: this._focusables,
			focusTracker: this.focusTracker,
			keystrokeHandler: this.keystrokes,
			actions: {
				focusPrevious: "shift + tab",
				focusNext: "tab",
			},
		});

		this.setTemplate({
			tag: "div",
			attributes: {
				class: ["ck", "ck-file-actions", "ck-inlinefile-actions"],
				tabindex: "-1",
			},
			children: [this.previewButtonView, this.editButtonView, this.unlinkButtonView],
		});
	}

	render() {
		super.render();

		const childViews = [this.previewButtonView, this.editButtonView, this.unlinkButtonView];

		childViews.forEach((v) => {
			this._focusables.add(v);
			this.focusTracker.add(v.element);
		});

		this.keystrokes.listenTo(this.element);
	}

	focus() {
		this._focusCycler.focusFirst();
	}

	_createButton(label, icon, eventName) {
		const button = new ButtonView(this.locale);

		button.set({
			label,
			icon,
			tooltip: true,
		});

		button.delegate("execute").to(this, eventName);

		return button;
	}

	_createPreviewButton() {
		const button = new ButtonView(this.locale);
		const bind = this.bindTemplate;
		const t = this.t;

		button.set({
			withText: true,
		});

		button.extendTemplate({
			attributes: {
				class: ["ck", "ck-link-actions__preview"],
				href: bind.to("href", (href) => {
					const hrefInfo = getFileHrefInfo(href);
					return hrefInfo.url;
				}),
				target: "_blank",
				rel: "noopener noreferer",
				"data-cke-tooltip-text": bind.to("href", (href) => {
					const hrefInfo = getFileHrefInfo(href);
					return hrefInfo.tooltip;
				}),
			},
		});

		button.bind("label").to(this, "href", (href) => {
			const hrefInfo = getFileHrefInfo(href);
			let fileName = hrefInfo.url;
			if (hrefInfo.name) {
				fileName = hrefInfo.name;
			}
			if (fileName && hrefInfo.text && fileName !== hrefInfo.text) {
				fileName = `${hrefInfo.text} (${fileName})`;
			}
			if (fileName) {
				return fileName;
			} else {
				return this.translations ? this.translations.labels.noUrl : "This link has no URL";
			}
		});

		button.bind("isEnabled").to(this, "href", (href) => !!href);

		button.template.tag = "a";
		button.template.eventListeners = {};
		return button;
	}
}
