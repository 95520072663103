import typographyStyle from "atlas/assets/jss/components/typographyStyle";
import { grayColor, blackColor, whiteColor, infoColor, linkColor } from "atlas/assets/jss/shared";

const formWidth = {
	maxWidth: "648px",
	padding: "0 24px",
	boxSizing: "border-box",
	margin: "0 auto",
};

const requestToSpeakStyle = (theme) => ({
	content: {
		backgroundColor: whiteColor,
		height: "100%",
		overflow: "auto",
	},
	mobileHeader: {
		display: "flex",
		alignItems: "center",
		height: "64px",
		padding: "0 24px",
	},
	mobileContentLabel: {
		...typographyStyle.h1,
		flexGrow: "1",
	},
	closeIcon: {
		marginRight: "-12px",
	},
	contentLabel: {
		...typographyStyle.h1,
		padding: "24px",
		paddingBottom: "12px",
	},
	details: {
		padding: "0 24px 24px 40px",
		fontSize: "18px",
		lineHeight: "1.33",
		fontWeight: "normal",
		letterSpacing: "normal",
		color: blackColor[5],
	},
	form: {
		...formWidth,
		paddingBottom: "24px",
		[theme.breakpoints.down("sm")]: {
			paddingTop: "12px",
		},
	},
	columns: {
		display: "flex",
		flexWrap: "wrap",
		margin: "0 -12px",
		[theme.breakpoints.down("sm")]: {
			margin: "0",
		},
		"& > div": {
			width: "calc(50% - 24px)",
			boxSizing: "border-box",
			margin: "0 12px",
			[theme.breakpoints.down("sm")]: {
				width: "100%",
				margin: "0",
			},
		},
	},
	inputLabelSpacing: {
		marginTop: "8px",
	},
	inputHeading: {
		fontSize: "1em",
		fontWeight: "600",
	},
	menuItem: {
		maxWidth: "497px",
		whiteSpace: "break-spaces",
	},
	topic: {
		"& textarea": {
			minHeight: "124px",
		},
	},
	codeOfConduct: {
		fontSize: "13px",
		fontWeight: "normal",
		lineHeight: "1.23",
		letterSpacing: "normal",
		color: blackColor[2],
	},
	link: {
		cursor: "pointer",
		textDecoration: "underline",
		color: "#385F99",
		"&:hover,&:focus": {
			color: "#294772",
		},
	},
	separator: {
		borderTop: `solid 1px ${grayColor[4]}`,
	},
	buttons: {
		...formWidth,
		display: "flex",
		alignItems: "center",
		justifyContent: "flex-end",
		height: "88px",
		"& > button": {
			marginLeft: "24px",
		},
	},
	buttonSpacer: {
		marginRight: "20px",
		"&:focus": {
			marginRight: "20px",
		},
	},
	button: {
		whiteSpace: "nowrap",
	},
	cancelNotification: {
		minHeight: "200px",
		height: "100%",
		width: "100%",
		"& > div": {
			top: "50%",
			left: "50%",
			position: "absolute",
			transform: "translate(-50%, -50%)",
		},
	},
	codeOfConductContent: {
		"& ol li": {
			listStyleType: "decimal",
		},
		"& ol ol li": {
			listStyleType: "lower-alpha",
		},
		"& ol ol ol li": {
			listStyleType: "lower-roman",
		},
	},
	dialog: {
		"& .MuiDialog-container": {
			alignItems: "flex-start",
		},
		"& .MuiDialog-paper": {
			maxHeight: "500px",
		},
	},
});

export default requestToSpeakStyle;
