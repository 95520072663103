import React from "react";

import makeStyles from "@mui/styles/makeStyles";

import PolicyTreeNode from "./PolicyTreeNode";

const useStyles = makeStyles((theme) => ({
	tree: {
		margin: "0",
		padding: "0",
		listStyleType: "none",
		"& > li": {
			width: "440px",
			maxWidth: "100%",
		},
	},
}));

const PolicyTree = (props) => {
	const { policies, booksAndSectionsOnly, canSelect, selection, handleLoadChildren, hideDraft, setSelectedData } = props;
	const classes = useStyles();

	return policies ? (
		<ol className={classes.tree}>
			{policies
				.filter((policy) => !hideDraft || policy.publishedGuid || policy.status === 3)
				.map((policy) => (
					<PolicyTreeNode
						key={policy.guid}
						policy={policy}
						booksAndSectionsOnly={booksAndSectionsOnly}
						canSelect={canSelect}
						selection={selection}
						handleLoadChildren={handleLoadChildren}
						hideDraft={hideDraft}
						setSelectedData={setSelectedData}
					/>
				))}
		</ol>
	) : null;
};

export default PolicyTree;
