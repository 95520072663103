import {
	FOLDER_TYPE_PUBLIC,
	FOLDER_TYPE_INTERNAL,
	FOLDER_TYPE_CUSTOM,
	FOLDER_TYPE_RECYCLE_BIN,
	FOLDER_TYPE_DRAFT_POLICY,
	FOLDER_TYPE_PUBLISHED_POLICY,
	FOLDER_TYPE_POLICY_REVISION_HISTORY,
} from "utils/enums/DocumentFolderTypes";

export const getFolderType = (rootFolder) => {
	switch (rootFolder) {
		case "internal":
			return FOLDER_TYPE_INTERNAL;

		case "custom":
			return FOLDER_TYPE_CUSTOM;

		case "recyclebin":
			return FOLDER_TYPE_RECYCLE_BIN;

		case "public":
			return FOLDER_TYPE_PUBLIC;

		case "policybook":
			return [FOLDER_TYPE_DRAFT_POLICY, FOLDER_TYPE_PUBLISHED_POLICY, FOLDER_TYPE_POLICY_REVISION_HISTORY];

		default:
			return undefined;
	}
};

const getPathType = (type) => {
	switch (type) {
		case FOLDER_TYPE_INTERNAL:
			return "internal";

		case FOLDER_TYPE_CUSTOM:
			return "custom";

		case FOLDER_TYPE_RECYCLE_BIN:
			return "recyclebin";

		case FOLDER_TYPE_PUBLIC:
			return "public";

		case FOLDER_TYPE_DRAFT_POLICY:
		case FOLDER_TYPE_PUBLISHED_POLICY:
		case FOLDER_TYPE_POLICY_REVISION_HISTORY:
			return "policybook";

		default:
			return undefined;
	}
};

export const generatePathLinkForPolicy = (pathItem, rootFolder) => `/policy/${rootFolder}/${pathItem.guid}`;

export const generatePathLink = (pathItem, index, rootFolder, folderType) =>
	`/documents/${rootFolder}${index > 0 || folderType === FOLDER_TYPE_CUSTOM ? `/${pathItem.guid}` : ""}`;

export default getPathType;
