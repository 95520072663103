import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";
import request from "superagent";
import queryString from "query-string";

import { API_HOST } from "config/env";
import telemetryAddEvent from "utils/telemetryAddEvent";
import ComponentContainer from "atlas/components/ComponentContainer/ComponentContainer";
import WorkflowList from "./components/WorkflowList";
import CoverTemplateList from "views/AgendaItems/components/CoverTemplateList";
import { resetPageConfigs, updatePageConfigs, updateTabs } from "redux/app/actions";
import { updatePageHeader } from "redux/pageHeader/actions";

const telemetryPage = "Workflow List";

const tabs = {
	workflows: 0,
	coverTemplates: 1,
};

const WorkflowsModule = (props) => {
	const { showSignIn } = props;
	const { t } = useTranslation("workflows");
	const navigate = useNavigate();
	const location = useLocation();
	const dispatch = useDispatch();
	const { tab: defaultTab = 0 } = queryString.parse(location.search) || {};
	const [tab, setTab] = useState(parseInt(defaultTab, 10) || 0);
	const [workflows, setWorkflows] = useState(null);
	const [coverTemplates, setCoverTemplates] = useState(null);

	const loadWorkflows = () => {
		request
			.get(`${API_HOST}/api/workflows`)
			.then((res) => {
				setWorkflows(res.body.workflows);
			})
			.catch((err) => {
				showSignIn(err, () => {
					loadWorkflows();
				});
			});
	};

	const createWorkflow = () => {
		telemetryAddEvent(`${telemetryPage} - Add new Workflow`, { area: "workflows" });

		navigate("/workflows/edit");
	};

	const loadCoverTemplates = () => {
		request
			.get(`${API_HOST}/api/agendaitems/covertemplates`)
			.withCredentials()
			.then((res) => {
				if (res.body) {
					setCoverTemplates(res.body);
				}
			})
			.catch((err) => {
				showSignIn(
					err,
					() => {
						loadCoverTemplates(itemRef.current.guid);
					},
					() => {},
				);
			});
	};

	const tabChange = (_e, selectedTab) => {
		setTab(selectedTab);
	};

	useEffect(() => {
		navigate(`/workflows?tab=${tab}`, { replace: true, state: null });
		if (tab === tabs.workflows) {
			dispatch(
				updatePageHeader({
					primaryAction: createWorkflow,
					primaryActionText: t("buttons.addNewWorkflow"),
					primaryActionTooltip: t("tooltips.addNewWorkflow"),
				}),
			);
		}
	}, [tab]);

	useEffect(() => {
		loadWorkflows();
		loadCoverTemplates();

		dispatch(resetPageConfigs({ resetBack: true }));
		dispatch(
			updatePageConfigs({
				title: t("title.workflowsCoverTemplates"),
				telemetryPage,
				preferedBack: { url: "/workflows" },
			}),
		);
		if (tab === tabs.workflows) {
			dispatch(
				updatePageHeader({
					primaryAction: createWorkflow,
					primaryActionText: t("buttons.addNewWorkflow"),
					primaryActionTooltip: t("tooltips.addNewWorkflow"),
				}),
			);
		}

		dispatch(
			updateTabs({
				display: true,
				selectedTab: tab,
				tabsOptions: [
					{ key: "workflows", label: t("agendaItems:tabs.workflows"), dataCy: "tab-workflows" },
					{ key: "coverTemplates", label: t("agendaItems:tabs.coverTemplates"), dataCy: "tab-coverTemplates" },
				],
				onChange: (_e, newTab) => {
					dispatch(
						updateTabs({
							selectedTab: newTab,
						}),
					);

					tabChange(_e, newTab);
				},
			}),
		);
	}, []);

	return (
		<ComponentContainer>
			{tab === tabs.workflows && <WorkflowList workflows={workflows} loadWorkflows={loadWorkflows} showSignIn={showSignIn} />}
			{tab === tabs.coverTemplates && (
				<CoverTemplateList coverTemplates={coverTemplates} loadCoverTemplates={loadCoverTemplates} showSignIn={showSignIn} />
			)}
		</ComponentContainer>
	);
};

export default WorkflowsModule;
