import { defaultFont, primaryColor, blackColor, grayColor, whiteColor } from "atlas/assets/jss/shared";

const listItem = {
	listStyleType: "none",
	display: "flex",
	position: "relative",
	boxSizing: "border-box",
};
const defaultBorder = `solid 1px ${grayColor[4]}`;
const header = {
	...defaultFont,
	fontSize: "12px",
	lineHeight: "1.33",
	fontWeight: "600",
	letterSpacing: "1px",
	textTransform: "capitalize",
	padding: "0 24px 0 18px",
	minHeight: "36px",
};
const borderPseudo = {
	content: "''",
	alignSelf: "stretch",
};
const defaultBorderPseudo = {
	...borderPseudo,
	borderTop: (props) => (props.addTopBorder ? defaultBorder : undefined),
	borderLeft: defaultBorder,
	paddingLeft: "7px", // 1px border + 7px padding = 8px total
};
const font = {
	...defaultFont,
	fontSize: "18px",
	lineHeight: "1.33",
	fontWeight: "normal",
};
const headingFont = {
	...font,
	fontWeight: "600",
	color: blackColor[1],
};
const itemFont = {
	...font,
	color: blackColor[2],
};
const pageFont = {
	...defaultFont,
	fontSize: "14px",
	lineHeight: "1.29",
	fontWeight: "normal",
	color: blackColor[1],
};
const itemPadding = "12px 16px 12px 24px";
const itemPaddingXs = "6px 8px 6px 20px";

const agendaStyle = (theme) => ({
	radioListItem: {
		marginLeft: "40px",
	},
	listItemClickable: {
		color: `${primaryColor[1]}`,
		"&:hover": {
			color: "#294772",
			textDecoration: "underline",
			cursor: "pointer",
		},
	},
	headerListItem: {
		...listItem,
		alignItems: "center",
	},
	memberOnlyHeader: {
		...header,
		color: whiteColor,
		backgroundColor: blackColor[0],
	},
	consentHeader: {
		...header,
		color: blackColor[0],
		backgroundColor: grayColor[4],
	},
	publicCommentHeader: {
		...header,
		color: blackColor[0],
		backgroundColor: "#6790cc",
	},
	headerTextWithIcon: {
		marginLeft: "8px",
	},
	agendaListItem: {
		...listItem,
		minHeight: "48px",
		display: "flex",
		alignItems: "stretch",
	},
	rightBorder: {
		borderRight: defaultBorder,
	},
	bottomBorder: {
		borderBottom: defaultBorder,
	},
	borderDefault: {
		"&::before": {
			...defaultBorderPseudo,
		},
	},
	borderDefaultBottomBorder: {
		"&::before": {
			...defaultBorderPseudo,
			borderBottom: defaultBorder,
		},
	},
	borderMemberOnly: {
		"&::before": {
			...borderPseudo,
			borderLeft: `solid 8px ${blackColor[0]}`,
			paddingLeft: "0",
		},
	},
	borderConsent: {
		"&::before": {
			...borderPseudo,
			borderLeft: `solid 8px ${grayColor[4]}`,
			paddingLeft: "0",
		},
	},
	borderPublicComment: {
		"&::before": {
			...borderPseudo,
			borderLeft: `solid 8px #6790cc`,
			paddingLeft: "0",
		},
	},
	selected: {
		backgroundColor: grayColor[4],
	},
	presentationSelected: {
		backgroundColor: grayColor[1],
	},
	checkbox: {
		width: "40px",
		position: "absolute",
		left: "-44px",
		top: (props) => (props.isMinutesV2 ? "18px" : "4px"),
	},
	hasNotes: {
		position: "absolute",
		width: "8px",
		height: "8px",
		top: "8px",
		right: "8px",
		backgroundColor: primaryColor[0],
		boxSizing: "border-box",
		border: `solid 1px ${whiteColor}`,
	},
	item: {
		display: "flex",
		alignItems: "first baseline",
		flexGrow: "1",
		borderTop: (props) => (props.addTopBorder ? defaultBorder : undefined),
	},
	itemText: {
		flexGrow: "1",
		flexShrink: "1",
	},
	itemIcon: {
		width: "24px",
		textAlign: "center",
	},
	itemHeading: {
		...headingFont,
		padding: itemPadding,
		paddingLeft: (props) => `${props.isSubHeading ? 40 : 24}px`,
		[theme.breakpoints.down("md")]: {
			flexDirection: "column",
			paddingLeft: (props) => `${props.isSubHeading ? 24 : 12}px`,
		},
	},
	itemItem: {
		...itemFont,
		padding: itemPadding,
		paddingLeft: "40px",
		[theme.breakpoints.down("md")]: {
			flexDirection: "column",
		},
		[theme.breakpoints.down("sm")]: {
			flexDirection: "column",
			padding: itemPaddingXs,
		},
	},
	liveMeetingItem: {
		minHeight: "36px",
	},
	itemMotionIcon: {
		alignSelf: "center",
		marginLeft: "16px",
	},
	timestampButton: {
		padding: "0",
	},
	itemRecommendation: {
		...itemFont,
		padding: itemPadding,
		paddingLeft: (props) => `${(props.isHeadingAction ? 24 : 40) + 32}px`,
		position: "relative",
		"& [data-icon='recommendation']": {
			position: "absolute",
			height: "24px",
			width: "24px",
			top: "12px",
			left: (props) => `${props.isHeadingAction ? 24 : 40}px`,
		},
		[theme.breakpoints.down("sm")]: {
			padding: itemPaddingXs,
			paddingLeft: (props) => `${(props.isHeadingAction ? 20 : 36) + 32}px`,
		},
	},
	itemAttachment: {
		...itemFont,
		padding: itemPadding,
		paddingLeft: `${24 + 44}px`,
		position: "relative",
		cursor: "pointer",
		"& [data-icon='expand']": {
			position: "absolute",
			height: "24px",
			width: "24px",
			top: "12px",
			left: "24px",
		},
		"& [data-icon='collapse']": {
			position: "absolute",
			height: "24px",
			width: "24px",
			top: "14px",
			left: "24px",
		},
		"& [data-text]": {
			flexGrow: "1",
		},
	},
	itemAttachmentPage: {
		...pageFont,
		padding: itemPadding,
		paddingLeft: "96px",
		alignSelf: "center",
		minHeight: "48px",
		boxSizing: "border-box",
	},
	bullet: {
		"&::after": {
			content: '"\\00a0"', // Non-breaking space
		},
	},
	disabledLink: {
		cursor: "not-allowed",
	},
	moveButtonMargin: {
		paddingRight: "102px",
	},
	textContainer: {
		display: "flex",
		flexGrow: 1,
	},
	textTruncate: {
		overflow: "hidden",
		textOverflow: "ellipsis",
		display: "-webkit-box",
		WebkitBoxOrient: "vertical",
		WebkitLineClamp: 2,
	},
});

export default agendaStyle;
