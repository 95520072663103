import React from 'react';
import PropTypes from 'prop-types';
import { parseISO } from 'date-fns';
import clsx from 'clsx';

import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
	dateField: {
		'& .MuiInputBase-adornedEnd': {
			paddingRight: '14px',
		},
	},
	datePopover: {
		zIndex: '5000',
	},
});

const OutlinedDatePicker = (props) => {
	const {
		id,
		className,
		label,
		iconTooltip,
		dateFormat,
		value,
		minDate,
		maxDate,
		objectToUpdate,
		fieldToUpdate,
		onChange,
		invalidDateMessage,
		openPickerIconComponent,
		dataCy,
		...otherProps
	} = props;
	const classes = useStyles();

	const fieldValue = value || objectToUpdate?.[fieldToUpdate];

	return (
		<DatePicker
			id={id || fieldToUpdate}
			label={label}
			format={dateFormat}
			disableMaskedInput
			value={fieldValue ? parseISO(fieldValue) : null}
			minDate={typeof minDate === 'string' ? parseISO(minDate) : minDate || new Date(1900, 0, 1)}
			maxDate={typeof maxDate === 'string' ? parseISO(maxDate) : maxDate || new Date(3000, 0, 1)}
			onChange={(date) => onChange(date, objectToUpdate, fieldToUpdate)}
			slotProps={{
				textField: {
					className: clsx(classes.dateField, className),
					size: 'small',
					fullWidth: true,
					placeholder: dateFormat,
					helperText: invalidDateMessage ? invalidDateMessage : '',
					error: !!invalidDateMessage,
					'data-cy': dataCy || fieldToUpdate,
				},
				popper: {
					className: clsx('date-popover', classes.datePopover),
				},
				openPickerButton: {
					'aria-label': iconTooltip,
				},
			}}
			components={{
				OpenPickerIcon: openPickerIconComponent,
			}}
			{...otherProps}
		></DatePicker>
	);
};

OutlinedDatePicker.propTypes = {
	id: PropTypes.string,
	label: PropTypes.string,
	iconTooltip: PropTypes.string.isRequired,
	dateFormat: PropTypes.string,
	onChange: PropTypes.func.isRequired,
	value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	objectToUpdate: PropTypes.object,
	fieldToUpdate: PropTypes.string,
	minDate: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	maxDate: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	invalidDateMessage: PropTypes.string,
	openPickerIconComponent: PropTypes.elementType,
};

OutlinedDatePicker.defaultProps = {
	id: undefined,
	label: null,
	dateFormat: 'MM-dd-yyyy',
	value: undefined,
	objectToUpdate: undefined,
	fieldToUpdate: undefined,
	minDate: undefined,
	maxDate: undefined,
	invalidDateMessage: '',
};

export default OutlinedDatePicker;
