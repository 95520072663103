import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import request from "superagent";
import Parser, { domToReact } from "html-react-parser";

import withStyles from "@mui/styles/withStyles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

import Icon from "atlas/components/Icon/Icon";
import { API_HOST } from "config/env";
import GenericDialog from "atlas/components/Dialogs/GenericDialog";
import notifierMessage from "utils/notifierMessage";
import { setSnackbarOptions } from "redux/snackBar/actions";

const StyledButton = withStyles({
	root: {
		color: "#ffffff",
		marginTop: 0,
	},
})(Button);

const AgendaItemDeleteDialog = (props) => {
	const { show, item, onClose, afterDelete, undoDeleteItem } = props;
	const dispatch = useDispatch();
	const { t } = useTranslation("agendaItems");
	const [deleting, setDeleting] = useState(false);

	const handleDelete = () => {
		const action = (key) => (
			<>
				<StyledButton
					onClick={() => {
						undoDeleteItem({ id: item.guid, sbKey: key });
					}}
				>
					Undo
				</StyledButton>
			</>
		);

		if (!item.guid) {
			handleError();
		}

		setDeleting(true);

		request
			.delete(`${API_HOST}/api/agendaitem/${item.guid}`)
			.send({})
			.then(() => {
				onClose();
				let option = notifierMessage(t("deleteItemDialog.snackbar.success"), "success", () => undoDeleteItem({ id: item.guid }));
				dispatch(setSnackbarOptions(option));
				afterDelete({ id: item.guid });
			})
			.catch((err) => handleError(err));
	};

	const handleCancel = () => {
		onClose();
	};

	const handleError = (err) => {
		console.log("Error", err);
		onClose();
	};

	const i18n = t("deleteItemDialog", { name: item.name });

	const dialog = {
		title: i18n.title,
		line1: i18n.line1,
		line2: i18n.line2,
		primaryTitle: i18n.buttons.delete,
		primaryAction: handleDelete,
		secondaryTitle: i18n.buttons.cancel,
		secondaryAction: handleCancel,
	};
	const parserOptions = {
		replace: (node) => {
			if (!node.attribs) return;
			if (["img", "br"].includes(node.name) && node.attribs.style) {
				node.attribs.style = "";
			}
			if (node.attribs.style === "margin-top: 0; margin-bottom: 0;") {
				// eslint-disable-next-line consistent-return
				return <node.name>{domToReact(node.children, parserOptions)}</node.name>;
			}
		},
	};

	return (
		<GenericDialog
			show={show}
			title={dialog.title}
			primaryAction={dialog.primaryAction}
			primaryTitle={dialog.primaryTitle}
			secondaryAction={dialog.secondaryAction}
			secondaryTitle={dialog.secondaryTitle}
			secondaryDisabled={deleting}
			clickAwayDisabled={deleting}
			closeIcon={<Icon name="close" />}
		>
			<Typography variant="body">
				<Box fontWeight="fontWeightBold">{dialog.line1}</Box>
				<Box>{Parser(item.name || "<p></p>", parserOptions)}</Box>
				<Box mt={2}>{dialog.line2}</Box>
			</Typography>
		</GenericDialog>
	);
};

export default AgendaItemDeleteDialog;
