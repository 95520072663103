const MinutesItemTypesEnum = () => ({
	ITEM: {
		value: 4,
	},
	RESOLUTION: {
		value: 8,
	},
	HEADING: {
		value: 11,
	},
});
export default MinutesItemTypesEnum;
