export default {
	buttons: {
		addNewBlock: "+ Approval block",
		addNewWorkflow: "+ New workflow",
	},
	deleteWorkflowDialog: {
		line1: "You are about to delete the workflow {{name}}",
		line2: "Deleting this template workflow will not affect ongoing workflows and approvals.",
		title: "Delete workflow",
		snackbar: {
			success: "The workflow {{name}} has been deleted.",
		},
		undo: {
			error: "Workflow undo delete failed",
			successful: "Workflow undo delete successful",
		},
	},
	detail: {
		addExternalEmailAddress: "Add external email address",
		approvalBlocks: "Approval blocks",
		availablePeople: "Available People",
		blockName: "Name",
		canEditItem: "Can edit item",
		failRule: "Reject rule",
		name: "Workflow name",
		noSelectedExternalEmailAddresses: "No added email addresses",
		noSelectedUsers: "No selected people",
		notifyApproversOnDenial: "For approvers, send a notification if the item has been rejected",
		notifyOnComment: "Send a notification for each new comment",
		notifyOnSubmitted: "Send a notification when submitted for approval",
		notifyPerApproval: "Send a notification for each approval",
		notifySubmitterOnItemChange: "For the submitter, send a notification if the related item has changed",
		ordered: "Ordered approval",
		placeholders: {
			addEmailAddress: "Add email address",
		},
		rejectsToTier: "Rejects to",
		requiredApprovers: "Requires only 1 approver",
		selectedExternalEmailAddresses: "Added email addresses",
		selectedPeople: "Selected people",
		sendNotificationToBelow: "Send cc notification to selected people",
		sendNotificationWhen: "Submitter/approver optional notifications",
		snackbar: {
			blockRemoved: "Approval block deleted",
			blockRestored: "Approval block restored",
			saved: "Workflow saved",
		},
		unsavedChanges: "You have unsaved changes",
		validation: {
			blockName: "Block name required",
			name: "Workflow name required",
			externalEmailAddress: "Valid email address required",
		},
	},
	list: {
		header: {
			name: "Name",
		},
		noResults: {
			description: "We couldn’t find any workflows",
			title: "No workflows found",
		},
	},
	options: {
		current: "Reset current approval",
		full: "Reset entire approval",
		none: "Return to approver",
		tiered: "Custom approval order",
	},
	snackbar: {
		workflowDeleted: "Workflow deleted",
	},
	tabs: {
		workflow: "Workflow",
		notifications: "Notifications",
	},
	title: {
		workflows: "Workflows",
		workflow: "Workflow",
		workflowItemName: "Workflow for {{itemName}}",
		workflowName: "Workflow: {{name}}",
		workflowsCoverTemplates: "Workflows & cover report template",
	},
	tooltips: {
		addExternalEmailAddress: "Add this external email address",
		addNewBlock: "Add a new block to this workflow",
		addNewWorkflow: "Add new workflow",
		addUserToBlock: "Add this person to a block",
		addUserToNotifications: "Add this person to the notifications",
		addUserToSpecificBlock: "Add this person to the block {{name}}",
		delete: "Delete workflow",
		failRuleCurrent: "The approval process will restart from the most recent approval block",
		failRuleFull: "The approval process will restart from the beginning",
		failRuleNone: "The approval process will restart from the most recent approver",
		failRuleTiered: "The approval process will restart from the tier number in the most recent approval block",
		moveUserUp: "Move this person up",
		moveUserDown: "Move this person down",
		removeBlock: "Remove this block",
		removeExternalEmailAddress: "Remove this email address from the notifications",
		removeUserFromBlock: "Remove this person from this block",
		removeUserFromNotifications: "Remove this person from the notifications",
		save: "Save this workflow",
	},
};
