import React from 'react';
import PropTypes from 'prop-types';

import { FormGroup, FormControlLabel, Checkbox, Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import statusIndicatorStyle from '../../assets/jss/components/statusIndicatorStyle';
import stackedCheckboxStyle from '../../assets/jss/components/stackedCheckboxStyle';
import { primaryColor, infoColor } from '../../assets/jss/shared';
import Icon from '../Icon/Icon';
import LargeLabel from '../Typography/LargeLabel';
import Tooltip from '../Tooltip/Tooltip';

const useStyles = makeStyles(statusIndicatorStyle);
const useCheckboxStyle = makeStyles(stackedCheckboxStyle);

const StackedCheckbox = (props) => {
	const {
		labelText,
		infoText = '',
		options,
		getChecked: isChecked,
		getId = (option) => `checkbox-${option}`,
		getKey = (option) => `key-${option}`,
		getValue = (option) => option,
		getDataCy = (option) => `checkbox-${option}`,
		getLabel = (option) => option,
		objectToUpdate,
		field,
		isForStatus,
		selectAllChange,
		selectAllLabel = '',
		handleChange,
	} = props;

	const classes = useStyles();
	const checkboxClasses = useCheckboxStyle();

	const getChecked = (option) => {
		if (typeof isChecked === 'function') {
			return isChecked(option);
		}

		let checked = false;
		if (objectToUpdate[field] && objectToUpdate[field].length > 0) {
			const values = typeof objectToUpdate[field] === 'string' ? objectToUpdate[field].split(',') : objectToUpdate[field];
			checked = values.indexOf(option) > -1;
		} else if (objectToUpdate.fieldName && objectToUpdate.fieldName === field && objectToUpdate.value) {
			checked = objectToUpdate.value.toLowerCase().split('~').indexOf(option.toLowerCase()) > -1;
		}
		return checked;
	};

	const getStatusOptionColor = (option) => {
		if (!isForStatus) return '';
		if (option.value.toLowerCase() === 'published') {
			return classes.success;
		}
		if (option.value.toLowerCase() === 'draft') {
			return classes.draft;
		}
		return classes.disabled;
	};

	const getLabelClasses = (option) => {
		const optionColor = getStatusOptionColor(option);
		if (isForStatus) {
			return `${classes.label} ${optionColor}`;
		}
		return checkboxClasses.label;
	};

	return (
		<>
			{labelText && (
				<Box mb={1}>
					<LargeLabel color="black" semiBold component="span">
						{labelText}
					</LargeLabel>
					{infoText && (
						<Tooltip title={infoText}>
							<Box ml={1} style={{ verticalAlign: 'middle' }} component="span">
								<Icon name="status-info" size="16" color={infoColor} />
							</Box>
						</Tooltip>
					)}
				</Box>
			)}
			<FormGroup>
				{typeof selectAllChange === 'function' && options.length > 0 && (
					<FormControlLabel
						id={getId('selectAll')}
						key={getKey('selectAll')}
						control={
							<Checkbox
								color="primary"
								icon={<Icon name="unchecked" color={primaryColor[0]} />}
								checkedIcon={<Icon name="checked" color={primaryColor[0]} />}
								// checked={false}
								onChange={(e) => selectAllChange(e.target.checked, options, objectToUpdate, field)}
								value={getValue('selectAll')}
								data-cy={getDataCy('selectAll')}
							/>
						}
						label={selectAllLabel}
						classes={{
							label: getLabelClasses('selectAll'),
						}}
					/>
				)}

				{options.map((option) => {
					const checked = getChecked(option);

					return (
						<FormControlLabel
							id={getId(option)}
							key={getKey(option)}
							control={
								<Checkbox
									color="primary"
									icon={<Icon name="unchecked" color={primaryColor[0]} />}
									checkedIcon={<Icon name="checked" color={primaryColor[0]} />}
									checked={checked}
									onChange={() => handleChange(option, !checked, objectToUpdate, field)}
									value={getValue(option)}
									data-cy={getDataCy(option)}
								/>
							}
							label={getLabel(option)}
							classes={{
								label: getLabelClasses(option),
							}}
						/>
					);
				}, this)}
			</FormGroup>
		</>
	);
};

StackedCheckbox.propTypes = {
	labelText: PropTypes.string,
	options: PropTypes.array.isRequired,
	getChecked: PropTypes.func,
	getId: PropTypes.func,
	getKey: PropTypes.func,
	getValue: PropTypes.func,
	getDataCy: PropTypes.func,
	getLabel: PropTypes.func,
	objectToUpdate: PropTypes.object,
	field: PropTypes.string,
	isForStatus: PropTypes.bool,
	handleChange: PropTypes.func.isRequired,
};

StackedCheckbox.defaultProps = {
	labelText: undefined,
	getChecked: undefined,
	getId: (option) => `checkbox-${option}`,
	getKey: (option) => `key-${option}`,
	getValue: (option) => option,
	getDataCy: (option) => `checkbox-${option}`,
	getLabel: (option) => option,
	objectToUpdate: undefined,
	field: '',
	isForStatus: false,
};

export default StackedCheckbox;
