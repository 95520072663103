import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import clsx from "clsx";
import makeStyles from "@mui/styles/makeStyles";
import styles from "atlas/assets/jss/components/menuStyle";

import { grayColor, successColor, errorColor } from "atlas/assets/jss/shared";
import Icon from "atlas/components/Icon/Icon";
import { focusColor } from "atlas/assets/jss/shared";
import { formatDate } from "utils/date";
import {
	POLICY_DRAFT,
	POLICY_ADOPTED,
	POLICY_PUBLISHED,
	POLICY_RESCINDED,
	POLICY_REVIEW,
	POLICY_HISTORICAL,
	POLICY_REVISED,
} from "utils/enums/PolicyTypes";
import AccessibleIconButton from "atlas/components/Buttons/AccessibleIconButton";
import NonModalMenu from "atlas/components/Menu/NonModalMenu";
import Tooltip from "atlas/components/Tooltip/Tooltip";
import { downloadPolicy } from "redux/policyGovernance/actions";
import downloadFile from "utils/download";
import { displayErrorNotification } from "views/Policy/utils/getNotification";
import processHtml, { stripHtml } from "utils/processHtml";
import { API_HOST } from "config/env";
import CircularProgressIndicator from "atlas/components/Progress/CircularProgressIndicator";
import telemetryAddEvent from "utils/telemetryAddEvent";

const sectionPadding = 15;
const documentGap = 16;
const lineWidth = 3;
const shapeSize = 16;
const shapeColorLight = grayColor[2];
const shapeColorDark = grayColor[0];
const limitLength = 20;
const useStyles = makeStyles(() => ({
	section: {
		margin: "0",
		padding: "0",
		fontSize: "14px",
		fontWeight: "400",
		lineHeight: "16px",
		letterSpacing: "1px",
		borderBottom: `solid 1px ${grayColor[2]}`,
		flexGrow: "1",
		display: "flex",
		flexDirection: "column",
	},
	content: {
		padding: `${sectionPadding}px`,
	},
	entry: {
		position: "relative",
		"&:not(:last-child)": {},
	},
	statusPublished: {
		color: successColor,
		fontWeight: "600",
	},
	statusRescinded: {
		color: errorColor,
		fontWeight: "600",
	},
	meetingName: {
		fontWeight: "600",
	},
	documents: {
		paddingBlock: "16px",
		display: "flex",
		gap: `${documentGap}px`,
		alignItems: "center",
		flexWrap: "wrap",
	},
	document: {
		display: "flex",
		gap: "16px",
		alignItems: "center",
		width: `calc(50% - ${documentGap / 2}px)`,
		lineHeight: "1",
	},
	lineContainer: {
		position: "absolute",
		width: `${lineWidth}px`,
		height: `calc(100% + ${documentGap}px)`,
		left: `-${sectionPadding / 2}px`,
		top: `${shapeSize / 2}px`,
	},
	line: {
		backgroundColor: shapeColorLight,
	},
	shapeContainer: {
		position: "absolute",
		left: `-${(shapeSize - lineWidth) / 2}px`,
		top: `-${shapeSize / 2}px`,
	},
	diamond: {
		width: "0",
		height: "0",
		border: `${shapeSize / 2}px solid transparent`,
		borderBottomColor: shapeColorLight,
		position: "relative",
		top: `-${shapeSize / 2}px`,
		"&:after": {
			content: "''",
			position: "absolute",
			left: `-${shapeSize / 2}px`,
			top: `${shapeSize / 2}px`,
			width: "0",
			height: "0",
			border: `${shapeSize / 2}px solid transparent`,
			borderTopColor: shapeColorLight,
		},
	},
	square: {
		width: `${shapeSize}px`,
		height: `${shapeSize}px`,
		backgroundColor: shapeColorDark,
	},
	squareLight: {
		width: `${shapeSize}px`,
		height: `${shapeSize}px`,
		backgroundColor: shapeColorLight,
	},
	policyStatus: {
		fontWeight: "600",
		size: "16px",
		width: "162px",
	},
	timelineRow: {
		display: "flex",
		alignItems: "center",
		gap: "16px",
		height: "60px",
		flexGrow: "0",
		flexShrink: "0",
	},
	timelineRowHover: {
		backgroundColor: "#94949429",
	},

	activeRow: {
		backgroundColor: "#94949452",
	},
	meetingNameText: {
		width: "165px",
		overflow: "hidden",
		textOverflow: "ellipsis",
		whiteSpace: " nowrap",
		fontWeight: "600",
		size: "16px",
	},
	meetingSubHeading: {
		width: "165px",
		overflow: "hidden",
		textOverflow: "ellipsis",
		whiteSpace: " nowrap",
		fontWeight: "400",
		size: "16px",
	},
	tabFocus: {
		width: "350px",
		"&:focus-visible": {
			outline: `solid 2px ${focusColor}`,
			outlineOffset: "-2px",
			borderRadius: "4px",
			padding: "2px 0px 2px 4px",
		},
	},
	comparePopper: {
		width: "100vw",
		height: "100vh",
		position: "fixed",
		zIndex: "5001 !important",
		top: "0px",
		left: "0px",
		"& .MuiPaper-root": {
			margin: "0 auto",
		},
	},
	webviewerContainer: {
		height: "100%",
	},

	history: {
		flexGrow: "1",
	},
	menu: {
		...styles.menu,
	},
}));

const getStatusLabel = (status, t) => {
	switch (status) {
		case POLICY_DRAFT:
			return t("policyTimeline.labels.created");

		case POLICY_ADOPTED:
			return t("policyTimeline.labels.adopted");

		case POLICY_PUBLISHED:
		case POLICY_HISTORICAL:
			return t("policyTimeline.labels.published");

		case POLICY_RESCINDED:
			return t("policyTimeline.labels.rescinded");

		case POLICY_REVIEW:
			return t("policyTimeline.labels.review");

		case POLICY_REVISED:
			return t("policyTimeline.labels.revised");

		default:
			return "";
	}
};
const getIcon = (policyStatus) => {
	switch (policyStatus) {
		case POLICY_DRAFT:
			return "policy-created";

		case POLICY_ADOPTED:
		case POLICY_HISTORICAL:
		case POLICY_PUBLISHED:
		case POLICY_REVISED:
			return "policy-published";

		case POLICY_RESCINDED:
			return "policy-rescinded";

		case POLICY_REVIEW:
			return "policy-review";

		default:
			return "meeting-attached-policy";
	}
};

const getActiveIcon = (policyStatus) => {
	switch (policyStatus) {
		case POLICY_DRAFT:
			return "draft-selected";

		case POLICY_ADOPTED:
		case POLICY_PUBLISHED:
		case POLICY_HISTORICAL:
		case POLICY_REVISED:
			return "current-published-policy";

		case POLICY_RESCINDED:
			return "rescinded-selected";

		case POLICY_REVIEW:
			return "policy-review";

		default:
			return "meeting-attached-policy";
	}
};

const PolicyDetailsTimeline = (props) => {
	const {
		timeline,
		loadTimeline,
		setComparePopperDetails,
		policyDetail: { isText, policyStatus },
		handleSelectedPolicies,
		selectedPolicies,
		backButtonClicked,
	} = props;
	const { t } = useTranslation("documents");
	const navigate = useNavigate();
	const [anchor, setAnchor] = useState(null);
	const [showMenu, setShowMenu] = useState(false);
	const [hover, setHover] = useState(null);
	const [policyDetails, setPolicyDetails] = useState({});
	const classes = useStyles();
	const [selectedRow, setSelectedRow] = useState(selectedPolicies[selectedPolicies.length - 1] || {});
	const dispatch = useDispatch();

	useEffect(() => {
		if (timeline) setTimelineDetails(timeline);
	}, [timeline]);

	useEffect(() => {
		setSelectedRow(selectedPolicies[selectedPolicies.length - 1] || {});
	}, [selectedPolicies]);

	const openPolicy = (guid) => {
		if (guid) {
			navigate(`/policydoc/${guid}`);
		}
	};
	useEffect(() => {
		if (selectedPolicies.length > 0) {
			setSelectedRow({
				guid: selectedPolicies[selectedPolicies.length - 1].guid,
				status: selectedPolicies[selectedPolicies.length - 1].status,
			});
		}
	}, [backButtonClicked]);

	const handleDownload = (guid) => {
		dispatch(downloadPolicy(guid))
			.then((res) => {
				const { Name, mimeType, Document } = res;
				downloadFile(Name, mimeType, Document);
			})
			.catch((err) => {
				if (err.status === 500) {
					displayErrorNotification(t("policy.errorMsg.downloadPolicy"), dispatch);
				} else {
					displayErrorNotification(t("policy.errorMsg.commonMsg"), dispatch);
				}
			});
	};

	const getPendoEvents = () => {
		if (policyStatus === POLICY_HISTORICAL || policyStatus === POLICY_PUBLISHED) {
			return "Policy Published - Timeline - Compare";
		} else if (policyStatus === POLICY_REVIEW) {
			return "Policy Attachment - Timeline - Compare";
		} else {
			return "Policy Draft - Timeline - Compare";
		}
	};

	const getMenuOptions = (entry, index) => [
		{
			label: t("policyTimeline.menuOptions.compare"),
			actionFunction: () => {
				telemetryAddEvent(getPendoEvents());
				setShowMenu(false);
				let url1 = `${API_HOST}/document/${selectedRow.guid}/?printPdf=true`;
				let url2 = `${API_HOST}/document/${entry.guid}/?printPdf=true`;
				let document1Details = policyDetails[`${selectedRow.guid}-${selectedRow.status}`];
				let document2Details = policyDetails[`${entry.guid}-${entry.status}`];
				setComparePopperDetails(url1, url2, document1Details, document2Details, t("policyCompareMode.timelineversion"));
			},
			hidden: !isText || (entry.guid === selectedRow.guid && entry.status === selectedRow.status),
		},
		{
			label: t("policyTimeline.menuOptions.openAgenda"),
			actionFunction: () => {
				navigate(`/document/${entry.agendaGuid}`);
				setShowMenu(false);
			},
			hidden: !entry.agendaGuid,
		},
		{
			label: t("policyTimeline.menuOptions.openMinutes"),
			actionFunction: () => {
				navigate(`/document/${entry.minutesGuid}`);
				setShowMenu(false);
			},
			hidden: !entry.minutesGuid,
		},
		{
			label: t("policyTimeline.menuOptions.openInNewWindow"),
			actionFunction: () => {
				window.open(`/document/${entry.guid}`, "_blank");
				setShowMenu(false);
			},
		},
		{
			label: t("policyTimeline.menuOptions.download"),
			actionFunction: () => {
				handleDownload(entry.guid);
				setShowMenu(false);
			},
		},
	];

	const handleToggleMenu = (e, entry, index) => {
		if (!anchor && !showMenu) {
			setAnchor({
				element: e.currentTarget,
				entry,
				index,
			});
			setShowMenu((prev) => !prev);
		} else if (anchor && anchor.index === index) {
			setAnchor(null);
			setShowMenu((prev) => !prev);
		} else {
			setAnchor({
				element: e.currentTarget,
				entry,
				index,
			});
		}
	};

	const closeMenu = () => {
		setAnchor(null);
		setShowMenu(false);
	};

	useEffect(() => {
		if (!timeline) {
			loadTimeline();
		}
	}, [timeline]);

	const onRowClick = (entry) => {
		handleSelectedPolicies(entry);
		setSelectedRow({
			guid: entry.guid,
			status: entry.status,
			id: entry.id,
			title: entry.title,
		});
	};

	const checkActiveRow = (entry, index) => {
		if (entry.guid === selectedRow.guid && entry.status === selectedRow.status) return true;
		else false;
	};

	const convertDateStringToObject = (date) => {
		const dateObject = new Date(date);
		return dateObject;
	};

	const setTimelineDetails = (timeline) => {
		let latestPublishedGuid = null;
		let latestPublishedDate = null;
		let policyDetails = {};
		timeline.forEach((entry) => {
			const { status, meetingName = null, itemBulletNumber = null, itemTitle = null, date, guid } = entry;
			if (!meetingName && status === 3 && convertDateStringToObject(date) > latestPublishedDate) {
				latestPublishedGuid = `${guid}-${status}`;
				latestPublishedDate = date;
			}
			if (meetingName) {
				policyDetails[`${guid}-${status}`] = {
					name: meetingName,
					date: itemBulletNumber && itemTitle && itemBulletNumber + " " + processHtml(stripHtml(itemTitle)),
				};
			} else {
				policyDetails[`${guid}-${status}`] = {
					name: getStatusLabel(status, t),
					date: formatDate(date, null, null, t("app:at"), "", "", true, true, true, true),
				};
			}
		});
		if (policyDetails && policyDetails[latestPublishedGuid]) {
			policyDetails[latestPublishedGuid].name = t("policyTimeline.labels.currentPublished");
		}
		return setPolicyDetails(policyDetails);
	};

	const handleMouseEnter = (index) => {
		setHover(index);
	};
	const handleMouseLeave = () => {
		setHover(null);
	};

	const handleKeyDown = (e, entry) => {
		switch (e.key) {
			case "ArrowUp":
				const previousSibling = e.target.previousSibling;
				if (previousSibling) {
					const descendants = previousSibling.getElementsByTagName("li");
					if (descendants.length > 0) {
						// Focus on last descendant of previous sibling
						descendants[descendants.length - 1].focus();
					} else {
						// Focus on previous sibling
						previousSibling.focus();
					}
				} else {
					// Focus on the parent
					e.target.parentNode?.closest("li")?.focus();
				}
				break;

			case "ArrowDown":
				const descendants = e.target.getElementsByTagName("li");
				if (descendants.length > 0) {
					// Focus on first descendant
					descendants[0].focus();
				} else {
					// Find next sibling and focus on it if it exists
					let listItem = e.target;
					while (listItem && !listItem.nextSibling) {
						listItem = listItem.parentNode?.closest("li");
					}
					const nextSibling = listItem?.nextSibling;
					if (nextSibling) {
						nextSibling.focus();
					}
				}
				break;

			case "Enter":
				e.preventDefault();
				e.stopPropagation();
				openPolicy(entry.guid);
				onRowClick(entry);

				break;
		}
	};

	const getRowIcon = (entry, index) => {
		if (checkActiveRow(entry, index)) {
			if (entry.meetingName) return <Icon name={"meeting-selected"} />;
			else {
				return <Icon name={getActiveIcon(entry.status)} />;
			}
		} else {
			if (entry.meetingName) return <Icon name={"meeting-attached-policy"} />;
			else {
				return <Icon name={getIcon(entry.status)} />;
			}
		}
	};

	const checkTooltipDisable = (entry, forMeetingName) => {
		if (forMeetingName) {
			if (entry.meetingName && entry.meetingName.length >= limitLength) return false;
			else true;
		} else {
			if (entry.itemBulletNumber && entry.itemTitle && processHtml(stripHtml(entry.itemTitle)).length >= limitLength) return false;
			else true;
		}
	};

	const getTimeLineRow = (entry, index) => {
		return (
			<>
				<li
					className={clsx(
						classes.timelineRow,
						checkActiveRow(entry, index) ? classes.activeRow : "",
						hover === index ? classes.timelineRowHover : null,
					)}
					onClick={() => {
						if (entry.guid) {
							openPolicy(entry.guid);
							onRowClick(entry);
						}
					}}
					tabIndex="-1"
				>
					<div style={{ paddingLeft: "17px" }}> {getRowIcon(entry, index)}</div>
					<div
						className={classes.tabFocus}
						onKeyDown={(e) => handleKeyDown(e, entry)}
						tabIndex="0"
						onMouseEnter={() => handleMouseEnter(index)}
						onMouseLeave={() => handleMouseLeave(index)}
					>
						<Tooltip disableHoverListener={checkTooltipDisable(entry, true)} title={entry.meetingName}>
							<div className={classes.meetingNameText}>{policyDetails && policyDetails[`${entry.guid}-${entry.status}`]?.name}</div>
						</Tooltip>
						<div>
							<Tooltip
								disableHoverListener={checkTooltipDisable(entry, false)}
								title={entry.itemTitle && processHtml(stripHtml(entry.itemTitle))}
							>
								<div className={classes.meetingSubHeading}>{policyDetails && policyDetails[`${entry.guid}-${entry.status}`]?.date}</div>
							</Tooltip>
						</div>
					</div>
					{entry.guid && (
						<div>
							<AccessibleIconButton
								color="inherit"
								aria-label={t("app:menu.options")}
								iconName="more"
								dataCy="change-set-overflow-menu"
								onClick={(e) => {
									e.stopPropagation();
									handleToggleMenu(e, entry, index);
								}}
								tabIndex="0"
							/>
						</div>
					)}
				</li>
			</>
		);
	};

	return (
		<>
			{timeline && policyDetails ? (
				<ul className={clsx(classes.section)}>
					{timeline.map((entry, index) => (
						<div key={`${entry.guid}-${entry.date}`} className={classes.entry} data-cy="timeline-entry">
							{<>{getTimeLineRow(entry, index)}</>}
						</div>
					))}
				</ul>
			) : (
				<CircularProgressIndicator />
			)}
			{anchor && showMenu && (
				<NonModalMenu
					id="change-set-menu"
					className={classes.menu}
					anchorEl={anchor.element}
					keepMounted
					open
					onClose={() => closeMenu(anchor.element)}
					options={getMenuOptions(anchor.entry)}
					position="bottom-start"
				/>
			)}
		</>
	);
};

export default PolicyDetailsTimeline;
