import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import clsx from "clsx";

import makeStyles from "@mui/styles/makeStyles";
import { Checkbox, FormControlLabel, Grid, MenuItem } from "@mui/material";

import inputStyle from "atlas/assets/jss/components/inputStyle";
import { grayColor } from "atlas/assets/jss/shared";
import InputLabel, { STYLE_SENTENCE_CASE, SIZE_LARGE } from "atlas/components/FormControls/InputLabel";
import OutlinedInput from "atlas/components/FormControls/OutlinedInput";
import SelectInput from "atlas/components/FormControls/SelectInput";
import Icon from "atlas/components/Icon/Icon";
import Tooltip from "atlas/components/Tooltip/Tooltip";
import GenericEditor from "components/Editor/GenericEditor";
import { Check } from "components/Icons";
import { SettingsContext } from "contexts/Settings/SettingsContext";
import { getDropdownProgressIndicator } from "utils/dropDown";
import { getDurations } from "utils/meetingTime";
import { getRequiredLabel, getErrorProps } from "utils/updateObject";

const useInputStyles = makeStyles(inputStyle);
const useStyles = makeStyles((theme) => ({
	columns: {
		display: "flex",
		alignItems: "center",
		flexWrap: "wrap",
	},
	columnsAlignTop: {
		display: "flex",
		alignItems: "top",
		flexWrap: "wrap",
	},
	columnSection: {
		marginRight: "24px",
	},
	externalLink: {
		paddingLeft: "16px",
		[theme.breakpoints.down("sm")]: {
			paddingLeft: "0",
		},
		"& a": {
			display: "flex",
			alignItems: "center",
			textDecoration: "none",
			"&:hover": {
				textDecoration: "underline",
			},
			"& > :not(:first-child)": {
				marginLeft: "8px",
			},
		},
	},
	externalLinkTop: {
		marginTop: "16px",
	},
	headerTitle: {
		flexGrow: "1",
	},
	inputLabelSpacing: {
		marginTop: "8px",
	},
	formControl: {
		maxWidth: "400px",
		minWidth: "400px",
		[theme.breakpoints.down("sm")]: {
			maxWidth: "100%",
			minWidth: "100%",
		},
	},
	formControlGrid: {
		maxWidth: "424px",
		minWidth: "424px",
		[theme.breakpoints.down("sm")]: {
			maxWidth: "calc(100% + 24px)",
			minWidth: "calc(100% + 24px)",
		},
	},
	hoursMinutesColon: {
		position: "absolute",
		right: "-14px",
		top: "48px",
	},
}));

const telemetryPage = "Meeting Template Details";

const MeetingTemplateDetail = (props) => {
	const { meetingTemplate, boards, errors = {}, handleUpdate } = props;
	const { t } = useTranslation("meetings");
	const { lite } = useContext(SettingsContext);
	const classes = useStyles();
	const inputClasses = useInputStyles({ fullWidth: true });
	const hours = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
	const minutes = [
		{ label: "00", value: 0 },
		{ label: "15", value: 0.25 },
		{ label: "30", value: 0.5 },
		{ label: "45", value: 0.75 },
	];
	const periods = [
		{ label: t("am"), value: 1 },
		{ label: t("pm"), value: 2 },
	];
	const durations = getDurations(t);

	const getBoards = () => {
		const boardMenuItems = [];

		if (boards) {
			boards.forEach((board) => {
				boardMenuItems.push(
					<MenuItem key={board.id} value={board.id} data-cy={`board-${board.id}`}>
						<div>{board.name}</div>
					</MenuItem>,
				);
			});
		} else {
			boardMenuItems.push(getDropdownProgressIndicator("boards-loading"));
		}

		return boardMenuItems;
	};

	return (
		<div>
			<div id="details">
				<div className={clsx(classes.headerTitle, classes.inputLabelSpacing)}>
					<InputLabel htmlFor="details" style={STYLE_SENTENCE_CASE} size={SIZE_LARGE} label={t("templateDetail.labels.meetingDetails")} />
				</div>
				<div className={classes.columns}>
					<div className={classes.formControl}>
						<SelectInput
							id="boards"
							className={inputClasses.smallInput}
							label={getRequiredLabel(t, t("templateDetail.labels.meetingGroup"))}
							value={meetingTemplate.boardId}
							onChange={(e) => handleUpdate(e, "boardId", false, true)}
							{...getErrorProps(errors, "boardId")}
							noDefaultClassName
							fullWidth
							size="small"
							data-cy="boards"
						>
							{getBoards()}
						</SelectInput>
					</div>
					<div className={classes.externalLink}>
						<a href={`/home/boards/edit/${meetingTemplate.boardId}`} target="_blank">
							<Icon name="external-link" size="18px" color={grayColor[1]} data-cy="externalLink" />
							<span>{boards.find((board) => board.id === meetingTemplate.boardId)?.name}</span>
						</a>
					</div>
				</div>
				<div className={classes.formControl}>
					<OutlinedInput
						id="name"
						className={classes.outlinedInput}
						label={getRequiredLabel(t, t("templateDetail.labels.name"))}
						value={meetingTemplate.name}
						onChange={(e) => handleUpdate(e, "name")}
						{...getErrorProps(errors, "name")}
						noDefaultClassName
						fullWidth
						size="small"
						data-cy="name"
					/>
				</div>
				<Grid className={clsx(classes.formControl, classes.formControlGrid)} container spacing={3}>
					<Grid item xs={4} className="absolute-container">
						<SelectInput
							id="hour"
							className={clsx(inputClasses.smallInput, inputClasses.helperTextNoWrap)}
							label={t("hour")}
							value={meetingTemplate.hour}
							onChange={(e) => handleUpdate(e, "hour", false, true)}
							{...getErrorProps(errors, "hour")}
							noDefaultClassName
							fullWidth
							size="small"
							data-cy="startTimeHour"
						>
							{hours.map((hour) => (
								<MenuItem key={`hour-${hour}`} value={hour} data-cy={`startTimeHour${hour}`}>
									{hour}
								</MenuItem>
							))}
						</SelectInput>
						<div className={classes.hoursMinutesColon}>:</div>
					</Grid>
					<Grid item xs={4}>
						<SelectInput
							id="minute"
							className={inputClasses.smallInput}
							label={t("templateDetail.labels.minute")}
							value={meetingTemplate.minute}
							onChange={(e) => handleUpdate(e, "minute")}
							{...getErrorProps(errors, "minute")}
							noDefaultClassName
							fullWidth
							size="small"
							data-cy="startTimeMinute"
						>
							{minutes.map((minute) => (
								<MenuItem key={`minute-${minute.value}`} value={minute.value} data-cy={`startTimeMinute${minute.value}`}>
									{minute.label}
								</MenuItem>
							))}
						</SelectInput>
					</Grid>
					<Grid item xs={4}>
						<SelectInput
							id="period"
							className={inputClasses.smallInput}
							label={t("templateDetail.labels.period")}
							value={meetingTemplate.period || ""}
							onChange={(e) => handleUpdate(e, "period", false, true)}
							{...getErrorProps(errors, "period")}
							noDefaultClassName
							fullWidth
							size="small"
							data-cy="startTimePeriod"
						>
							{periods.map((period) => (
								<MenuItem key={`period-${period.value}`} value={period.value} data-cy={`startTimePeriod${period.value}`}>
									{period.label}
								</MenuItem>
							))}
						</SelectInput>
					</Grid>
				</Grid>
				<div className={classes.formControl}>
					<SelectInput
						id="duration"
						className={inputClasses.smallInput}
						label={t("templateDetail.labels.durationOptional")}
						value={meetingTemplate.duration || ""}
						onChange={(e) => handleUpdate(e, "duration")}
						{...getErrorProps(errors, "duration")}
						noDefaultClassName
						fullWidth
						size="small"
						data-cy="duration"
					>
						{durations.map((duration) => (
							<MenuItem key={`duration-${duration.value}`} value={duration.value || ""} data-cy={`duration${duration.value}`}>
								{duration.label}
							</MenuItem>
						))}
					</SelectInput>
				</div>
				<div className={classes.formControl}>
					<OutlinedInput
						id="location"
						className={classes.outlinedInput}
						label={getRequiredLabel(t, t("templateDetail.labels.location"))}
						value={meetingTemplate.location}
						onChange={(e) => handleUpdate(e, "location")}
						{...getErrorProps(errors, "location")}
						noDefaultClassName
						fullWidth
						size="small"
						data-cy="location"
					/>
				</div>
			</div>
			<div className={classes.columns}>
				<div id="security" className={classes.columnSection}>
					<div className={clsx(classes.headerTitle, classes.inputLabelSpacing)}>
						<InputLabel htmlFor="security" style={STYLE_SENTENCE_CASE} size={SIZE_LARGE} label={t("templateDetail.labels.security")} />
					</div>
					<div>
						<FormControlLabel
							control={
								<Checkbox
									checkedIcon={<Check fontSize="small" color="primary" />}
									checked={meetingTemplate.membersOnly}
									onChange={(e) => handleUpdate(e, "membersOnly", true)}
								/>
							}
							label={t("templateDetail.labels.membersOnlyMeeting")}
							data-cy="members-only"
						/>
					</div>
				</div>
				{!lite.enabled ? (
					<>
						<div id="publicRequestToSpeak" className={classes.columnSection}>
							<div className={clsx(classes.headerTitle, classes.inputLabelSpacing)}>
								<InputLabel
									htmlFor="publicRequestToSpeak"
									style={STYLE_SENTENCE_CASE}
									size={SIZE_LARGE}
									label={t("templateDetail.labels.publicRequestToSpeak")}
								/>
							</div>
							<div>
								<FormControlLabel
									control={
										<Checkbox
											checkedIcon={<Check fontSize="small" color="primary" />}
											checked={meetingTemplate.requestToSpeak}
											onChange={(e) => handleUpdate(e, "requestToSpeak", true)}
										/>
									}
									label={t("templateDetail.labels.publicRequestToSpeakEnabled")}
									data-cy="request-to-speak"
								/>
							</div>
						</div>
						<div id="archived" className={classes.columnSection}>
							<div className={clsx(classes.headerTitle, classes.inputLabelSpacing)}>
								<InputLabel htmlFor="archived" style={STYLE_SENTENCE_CASE} size={SIZE_LARGE} label={t("templateDetail.labels.archived")} />
							</div>
							<div>
								<Tooltip title={t("templateDetail.tooltips.archived")}>
									<span>
										<FormControlLabel
											control={
												<Checkbox
													checkedIcon={<Check fontSize="small" color="primary" />}
													checked={meetingTemplate.archived}
													onChange={(e) => handleUpdate(e, "archived", true)}
												/>
											}
											label={t("templateDetail.labels.archivedEnabled")}
											data-cy="archived"
										/>
									</span>
								</Tooltip>
							</div>
						</div>
					</>
				) : null}
			</div>
			<div id="public-site">
				<div className={clsx(classes.headerTitle, classes.inputLabelSpacing)}>
					<InputLabel htmlFor="public-site" style={STYLE_SENTENCE_CASE} size={SIZE_LARGE} label={t("templateDetail.labels.publicSite")} />
				</div>
				<div className={classes.columnsAlignTop}>
					<div className={classes.formControl} data-cy="description">
						<GenericEditor
							toolbar="meetingTemplateNotes"
							name="Description"
							title={t("templateDetail.labels.description")}
							content={meetingTemplate.description || ""}
							onChange={(_event, editor) => {
								const newContent = editor.getData();

								if (meetingTemplate.description === newContent) {
									return; // don't re-save unchanged content;
								}

								handleUpdate({ target: { value: newContent } }, "description");
							}}
							mt={0}
							loadAsync
							preload={{ staticToolbar: true }}
						/>
					</div>
					<div className={clsx(classes.externalLink, classes.externalLinkTop)}>
						<a href={`/portal/MeetingInformation.aspx?type=${meetingTemplate.id}`} target="_blank">
							<Icon name="external-link" size="18px" color={grayColor[1]} data-cy="externalLink" />
							<span>{t("templateDetail.labels.publicSite")}</span>
						</a>
					</div>
				</div>
			</div>
		</div>
	);
};

export default MeetingTemplateDetail;
