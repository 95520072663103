import React from "react";
import { useNavigate, useLocation } from "react-router-dom";

// This is for using navigate with class components
export const withRouter = (Component) => {
	const Wrapper = (props) => {
		const navigate = useNavigate();
		const location = useLocation();

		return <Component navigate={navigate} location={location} {...props} />;
	};

	return Wrapper;
};
