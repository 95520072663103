/* eslint-disable no-plusplus */
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import request from "superagent";
import processHtml, { stripHtml } from "utils/processHtml";

import { API_HOST } from "config/env";

import makeStyles from "@mui/styles/makeStyles";
import { CircularProgress, MenuItem, Typography } from "@mui/material";

import OutlinedInput from "atlas/components/FormControls/OutlinedInput";
import Icon from "atlas/components/Icon/Icon";
import SelectInput from "atlas/components/FormControls/SelectInput";
import GenericDialog from "atlas/components/Dialogs/GenericDialog";
import NoticeCard, { ICON_POSITION_MIDDLE } from "atlas/components/Cards/NoticeCard";
import { STATUS_ERROR } from "atlas/assets/jss/utils/statusIndicators";
import { errorColor } from "atlas/assets/jss/shared";

import inputStyle from "atlas/assets/jss/components/inputStyle";

import { updatePageConfigs, updateNotice } from "../../redux/app/actions";

const useInputStyles = makeStyles(inputStyle);

const useStyles = makeStyles(() => ({
	dialog: {
		"& .MuiDialog-paper": {
			width: "480px",
			maxWidth: "100%",
		},
	},
	menuItem: {
		maxWidth: (props) => (props.selectWidth > 0 ? `${props.selectWidth}px` : undefined),
	},
	menuItemText: {
		overflow: "hidden",
		textOverflow: "ellipsis",
	},
	progressBar: {
		width: "240px",
		margin: "0 auto",
		marginTop: "24px",
	},
	hidden: {
		display: "none",
	},
	errorCard: {
		marginBottom: "16px",
	},
}));

const RequestToSpeakAddToAgendaDialog = (props) => {
	const { item, show, handleAddToAgenda, handleCancel, canAdd } = props;

	const [publicSections, setPublicSections] = useState(null);
	const [selectedSection, setSelectedSection] = useState(null);
	const [comment, setComment] = useState(null);
	const [adding, setAdding] = useState(false);

	const { t } = useTranslation("requestToSpeak");
	const navigate = useNavigate();
	const location = useLocation();
	const classes = useStyles();
	const inputClasses = useInputStyles({ fullWidth: true });
	const dispatch = useDispatch();

	const handleChange = (e, field) => {
		const {
			target: { value },
		} = e;
		if (field === "comment") {
			setComment(value);
		} else if (field === "section") {
			setSelectedSection(value);
		}
	};

	const loadPublicSections = () => {
		request.get(`${API_HOST}/api/requesttospeak/${item.id}/publiccommentsections`).then((res) => {
			if (res.status === 200) {
				setPublicSections(res.body);
				if (res.body && res.body.length > 0) {
					setSelectedSection(res.body[0].id);
				}
			}
		});
	};

	const getSections = () => {
		const sectionsItems = [];
		if (publicSections && publicSections.length) {
			publicSections.forEach((section) => {
				sectionsItems.push(
					<MenuItem key={`section-${section.id}`} className={classes.menuItem} value={section.id} data-cy={`section-${section.id}`}>
						<div className={classes.menuItemText}>{processHtml(stripHtml(section.name))}</div>
					</MenuItem>,
				);
			});
		}

		return sectionsItems;
	};

	useEffect(() => {
		if (item) {
			loadPublicSections();
		}
	}, [item]);

	return (
		<GenericDialog
			className={classes.dialog}
			show={show}
			title={t("addToAgendaDialog.title")}
			primaryAction={() => {
				setAdding(true);
				handleAddToAgenda(item.id, { approve: true, section: selectedSection, comment });
			}}
			primaryTitle={t("meetings:buttons.addToAgenda")}
			primaryDisabled={!canAdd || adding || publicSections == null || publicSections.length === 0}
			secondaryAction={handleCancel}
			secondaryTitle={t("app:buttons.cancel")}
			clickAwayDisabled={adding}
			closeIcon={<Icon name="close" />}
			data-cy="add-to-agenda-dialog"
			disableAutoFocus
			disableEnforceFocus
		>
			{publicSections == null && <CircularProgress />}
			{publicSections != null && publicSections.length > 1 && (
				<div>
					<SelectInput
						id="section"
						className={inputClasses.smallInput}
						noDefaultClassName
						fullWidth
						label={t("agendaMenu:section")}
						value={selectedSection > 0 ? selectedSection : publicSections[0].id}
						data-cy="section"
						onChange={(e) => handleChange(e, "section")}
					>
						{getSections()}
					</SelectInput>
				</div>
			)}
			{publicSections != null && publicSections.length === 0 && (
				<NoticeCard
					className={classes.errorCard}
					updateNotice={updateNotice}
					icon="status-error"
					iconPosition={ICON_POSITION_MIDDLE}
					label={
						<Typography type="body1" data-cy="noPublicSectionsWarning">
							{t("addToAgendaDialog.noSectionsWarning")}&nbsp;
							{item.meetingId > 0 && (
								<a
									href={`/home/meeting/detailsV2/${item.meetingId}/`}
									target="_current"
									onClick={(e) => {
										e.preventDefault();
										e.stopPropagation();

										dispatch(
											updatePageConfigs({
												preferedBack: { url: `${location.pathname}?approveRtsId=${item.id}` },
											}),
										);
										navigate(`/meeting/detailsV2/${item.meetingId}`);
									}}
								>
									{t("addToAgendaDialog.noSectionsWarningLink")}
								</a>
							)}
						</Typography>
					}
					status={STATUS_ERROR}
					processHtml={processHtml}
					thickStatus
					iconColor={errorColor}
					limitSizeToText
				/>
			)}

			<Typography type="h1">{t("addToAgendaDialog.commentsToSubmitterOptional")}</Typography>
			<OutlinedInput
				autoFocus
				noDefaultClassName
				id="comment"
				label={t("commentsToSubmitter")}
				value={comment || ""}
				onChange={(e) => handleChange(e, "comment")}
				fullWidth
				size="small"
				data-cy="requestToSpeakAddComment"
				multiline
				disabled={publicSections != null && publicSections.length === 0}
			/>
		</GenericDialog>
	);
};

export default RequestToSpeakAddToAgendaDialog;
