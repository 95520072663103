import React, { useEffect, useState, useRef, useCallback, useMemo, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { DndContext, DragOverlay, KeyboardSensor, PointerSensor, useSensor, useSensors, rectIntersection } from "@dnd-kit/core";
import { createPortal } from "react-dom";
import { debounce, cloneDeep } from "lodash";
import clsx from "clsx";
import { v4 as uuid } from "uuid";

import makeStyles from "@mui/styles/makeStyles";
import { Container, List } from "@mui/material";
import { useNavigate } from "react-router-dom";
import DragPresentation from "atlas/components/DragAndDrop/DragPresentation";
import Droppable from "atlas/components/DragAndDrop/Droppable";
import DropPlaceholder from "atlas/components/DragAndDrop/DropPlaceholder";
import Spinner from "atlas/components/Spinner/Spinner";
import { useWidthUp, useWidthDown } from "atlas/utils/useWidth";
import SingletonEditor from "components/Editor/SingletonEditor";
import HeaderFooter, { ADD_HEADING_BELOW } from "components/Meeting/HeaderFooter";
import MeetingItem from "components/Meeting/MeetingItem";
import { SettingsContext } from "contexts/Settings/SettingsContext";
import {
	persistAgendaBuilderAgendaItems,
	setAgendaBuilderAgendaItems,
	addAgendaItem,
	reorderAgendaBuilderAgendaItems,
	deleteAgendaItem,
	rejectAgendaItem,
	removeAgendaItem,
	restoreAgendaItem,
	setActive,
} from "redux/agendaBuilder/actions";
import { setSnackbarOptions } from "redux/snackBar/actions";
import { ITEM_TYPES, HEADINGS } from "utils/enums/ItemTypes";
import { getCollisionDetection } from "utils/dragAndDrop";
import { useSignInDialog } from "utils/isSignedIn";
import { isHeading, prepareAttachmentDuplication, isItem } from "utils/meetingElement";
import notifierMessage from "utils/notifierMessage";
import { scrollToElement2 as scrollToElement } from "utils/scrollToElement";
import telemetryAddEvent from "utils/telemetryAddEvent";
import { updatePageConfigs } from "redux/app/actions";
import AgendaItemRejectDialog from "views/AgendaItems/components/AgendaItemRejectDialog";
import UnableToDeleteDialog from "./UnableToDeleteDialog";
import { getSidebarWidth, isCurrentTimeInRange } from "../functions/utils";
import { createItemArrayForOverflowMenu } from "../../../components/CopyMove/utils/copyMovefunctions";
import { createItemsForOverflow } from "../../../redux/NewCopyAndMove/actions";
import DragPlaceholder from "components/SharedComponents/DragPlaceholder";

const useEditorStyles = makeStyles(() => ({
	editorFieldHide: {
		display: "none",
	},
	loadingIndicator: {
		height: "49.4px",
		width: "100%",
		textAlign: "center",
		display: "flex",
		alignItems: "center",
		position: "absolute",
		left: "0",
		top: "0",
		boxSizing: "border-box",
	},
	editorContentSmUp: {
		minWidth: "450px",
		boxSizing: "border-box",
		overflow: "hidden",
		"-webkit-transform": "translate3d(0, 0, 0)",
		width: (props) => (props.showOutline ? `calc(100% - ${getSidebarWidth(props.wideSidebar)})` : "100%"),
		marginLeft: (props) => (props.showOutline ? getSidebarWidth(props.wideSidebar) : "0"),
	},
	splitPaneEditorContentSmUp: {
		height: (props) => `calc(100vh - ${props.dynamicHeightOffset}px)`,
		minWidth: "450px",
		boxSizing: "border-box",
		overflowY: "auto",
		"-webkit-transform": "translate3d(0, 0, 0)",
		flexGrow: 1,
	},
	contentContainer: ({ isSplitPane }) => ({
		...(!isSplitPane && { maxWidth: "7.5in !important" }),
		paddingTop: "8px !important",
		paddingBottom: "8px !important",
		marginRight: "48px",
	}),
	agendaItemsContent: {
		padding: 0,
		margin: "0 48px",
	},
	dragging: {
		cursor: " grabbing",
		cursor: "-moz-grabbing",
		cursor: " -webkit-grabbing",
		"&:active": {
			cursor: " grabbing",
			cursor: "-moz-grabbing",
			cursor: " -webkit-grabbing",
		},
	},
}));

const saveDelay = 2000;
const tocFooterDropId = "toc-footer";

const AgendaSingletonEditor = (props) => {
	const {
		editorToolbarRef,
		id,
		showNotes,
		updateMeeting,
		queueFileUploads,
		addPolicy,
		addGoals,
		requestedItemGuid,
		isSplitPane,
		isReorderable,
	} = props;
	const widthUpSm = useWidthUp("sm");
	const widthDownMd = useWidthDown("md");
	const widthUpXl = useWidthUp("xl");
	const { t } = useTranslation("meetings");
	const sensors = useSensors(useSensor(PointerSensor), useSensor(KeyboardSensor));
	const [dialogs, setDialogs] = useState({});
	const [editorFields, _setEditorFields] = useState([]);
	const [editorInitializing, setEditorInitializing] = useState(true);
	const [elementRefsSet, setElementRefsSet] = useState(null);
	const [triggerEditorChange, setTriggerEditorChange] = useState(false);
	const [deletedFieldIdx, setDeletedFieldIdx] = useState(-1);
	const [menuAnchor, setMenuAnchor] = useState({});
	const [draggedId, setDraggedId] = useState(null);
	const [droppedId, setDroppedId] = useState(null);
	const [editorDynamicHeightOffset, setEditorDyamicHeightOffset] = useState(0);
	const editorScrollableContainer = useRef(null);
	const editorFieldsRef = useRef(editorFields);
	const elementsRef = useRef([]);
	const elementsRefStatus = useRef({ rerender: true, status: [] }); // Used to determine if all expected element refs have been set
	const dragRef = useRef(null);
	const dragDimensionsRef = useRef({});
	const { dateFormat } = useContext(SettingsContext);
	const dispatch = useDispatch();
	const showSignIn = useSignInDialog();
	const navigate = useNavigate();
	const {
		agenda: { saving, updated, items, agendaHeader, agendaFooter, customNumbering, startTimeStamp } = {},
		active: { id: active, field: activeField } = {},
	} = useSelector((state) => state.agendaBuilderReducer);
	const meetingActive = startTimeStamp ? startTimeStamp <= Math.floor(Date.now() / 1000) : false;

	useEffect(() => {
		//if outline has been ONLY reordered then just a length check wont help, it wont reinitialize the fields.
		//We need to also check if the order of the items has changed based on a soft check for the item array as a point of data.
		initializeFields(items);
	}, [JSON.stringify(items)]);

	const classes = useEditorStyles({
		showOutline: !widthDownMd,
		wideSidebar: widthUpXl,
		isSplitPane,
		dynamicHeightOffset: editorDynamicHeightOffset,
	});

	const afterElementRefSet = useCallback((index) => {
		if (elementsRefStatus.current.rerender) {
			elementsRefStatus.current.status[index] = true;

			if (!elementsRefStatus.current.status.find((value) => !value)) {
				elementsRefStatus.current.rerender = false;
				setElementRefsSet({}); //Set to a new object reference just to trigger a re-render
			}
		}
	}, []);

	const setEditorFields = (data) => {
		editorFieldsRef.current = data;
		_setEditorFields(data);
	};

	const handleEditorInitialized = (editor) => {
		setEditorInitializing(false);
	};

	const handleFieldChange = (editor) => {
		const fieldData = editor.getFieldData();
		dispatch(setAgendaBuilderAgendaItems(fieldData, editorFieldsRef.current));
		editor.ui.update();
	};

	const focusOnEditor = (fieldName) => {
		dispatch(setActive(fieldName, true));
	};

	const openItem = (item) => {
		const search = new URLSearchParams(window.location.search);
		search.set("itemGuid", item.guid);
		const queryString = search.toString();

		dispatch(
			updatePageConfigs({
				preferedBack: { url: window.location.pathname.replace("/home/", "/") + (queryString ? `?${queryString}` : "") },
			}),
		);

		navigate(`/agendaitems/edit/${item.guid}`);
	};

	// TODO Find the reason why this gets triggered twice when dispatching - Debounce is only there to prevent 2nd call. Temporary band-aid...
	const handleFocusChange = debounce(
		useCallback((fieldName, _fieldElement, editor) => {
			if (fieldName && editor?.ui?.focusTracker?.isFocused) {
				dispatch(setActive(fieldName));
			}
		}, []),
		100,
	);

	const onPendingApprovalClick = useCallback((guid) => {
		dispatch(setActive(guid));
	}, []);

	const updateEditorFields = (itemGuid, isDeleting = false) => {
		const newEditorFields = [...editorFieldsRef.current];
		const editorFieldText = newEditorFields.find((field) => field.name === `${itemGuid}-text`);
		if (editorFieldText) {
			editorFieldText.deleted = isDeleting;
			setEditorFields(newEditorFields);
			setTriggerEditorChange((triggerEditorChange) => !triggerEditorChange);
		}
	};

	const addItemText = useCallback((itemGuid) => {
		updateEditorFields(itemGuid);
		if (itemGuid) {
			dispatch(setActive(itemGuid.indexOf("-text") < 0 ? `${itemGuid}-text` : itemGuid, true));
		}
	}, []);

	const removeItemText = useCallback((itemGuid) => {
		const idx = editorFieldsRef.current.findIndex((f) => f.name === `${itemGuid}-text`);
		setDeletedFieldIdx(idx);
		updateEditorFields(itemGuid, true);
	}, []);

	const handleDeletedFieldIndex = () => {
		setDeletedFieldIdx(-1);
	};

	const handleUndoRedoRoot = (itemGuid, isUndo, isDeletion) => {
		const idx = editorFieldsRef.current.findIndex((f) => f.name === itemGuid);
		if (idx < 0) return;
		const newFields = [...editorFieldsRef.current];
		newFields[idx].deleted = isUndo ? !isDeletion : isDeletion;
		setEditorFields(newFields);
		setTriggerEditorChange((triggerEditorChange) => !triggerEditorChange);
	};

	const setContentAreaOverflow = (value) => {
		let contentArea = document.getElementById("content-area");
		while (contentArea) {
			contentArea.style.overflow = value;
			const element = contentArea;
			setTimeout(() => {
				// Ensure that the scroll position goes back to the top
				element.scrollTop = 0;
			}, 10);
			contentArea = contentArea.parentElement;
		}
	};

	const handleDragStart = (e) => {
		const { active } = e;

		setDraggedId(active.id);

		// Get the dimensions of the active element
		const element = document.getElementById(`meeting-item-${active.id}`);
		if (element) {
			const rect = element.getBoundingClientRect();
			dragDimensionsRef.current = { width: rect.width, height: rect.height };
		}

		// Prevent unwanted text-selection and scrolling
		document.body.style.userSelect = "none";
		document.body.classList.add(classes.dragging);
		setContentAreaOverflow("hidden");
	};

	const handleDragMove = useCallback(
		(e) => {
			const { active, over } = e;

			const activeIndex = items.findIndex((item) => item.guid === active.id);
			const overId = (over?.id || "").replace("-placeholder", "");
			const overIndex = items.findIndex((item) => item.guid === overId);
			if (overId === tocFooterDropId) {
				setDroppedId(overId);
			} else if (activeIndex >= 0 && overIndex >= 0 && overId !== active.id) {
				const moveData = getAvailablePosition(
					activeIndex,
					items[activeIndex].itemType,
					items[activeIndex].fields.Indent.Value > 0,
					overIndex,
					-1,
				);
				setDroppedId(moveData.nextPosition != null ? items[moveData.nextPosition].guid : null);
			} else {
				setDroppedId(null);
			}
		},
		[items],
	);

	const endDrag = () => {
		setDraggedId(null);
		setDroppedId(null);

		document.body.style.userSelect = null;
		document.body.classList.remove(classes.dragging);
		setContentAreaOverflow(null);
	};

	const handleDragEnd = useCallback(
		(e) => {
			const { active, over } = e;

			telemetryAddEvent("Agenda builder reorder - body");

			const activeItem = items.find((item) => item.guid === active.id);
			const activeIndex = items.findIndex((item) => item.guid === active.id);
			const overId = (over?.id || "").replace("-placeholder", "");
			const overIndex = items.findIndex((item) => item.guid === overId);
			moveItem(
				activeItem,
				undefined,
				getAvailablePosition(
					activeIndex,
					items[activeIndex].itemType,
					items[activeIndex].fields.Indent.Value > 0,
					overId === tocFooterDropId ? items.length - 1 : overIndex,
					-1,
				),
			);

			endDrag();
		},
		[items],
	);

	const handleDragCancel = (e) => {
		endDrag();
	};

	const getDragComponent = useCallback(() => {
		let component = null;
		if (draggedId) {
			const item = items.find((item) => item.guid === draggedId);
			if (item) {
				const isHeading = item.itemType === ITEM_TYPES.AGENDA_HEADING;
				const isSubHeading = Boolean(isHeading && item.attributes.relationshipGuid);
				const isItem = item.itemType === ITEM_TYPES.AGENDA_ITEM;
				const isProposedSubmitted = Boolean(item.submission);

				component = (
					<MeetingItem
						readOnly
						item={item}
						isHeading={isHeading}
						isSubHeading={isSubHeading}
						isItem={isItem}
						isRecommendation={item.itemType === ITEM_TYPES.RECOMMENDATION}
						isProposedSubmitted={isProposedSubmitted}
						addBottomBorder
						dragPresentational
						queueFileUploads={queueFileUploads}
						addPolicy={addPolicy}
						addGoals={addGoals}
						isMeetingTemplate={false}
						customNumbering={customNumbering}
						meetingId={id}
					/>
				);
			}
		}

		return component;
	}, [items, draggedId]);

	const handleMenu = useCallback((e, guid, options) => {
		setMenuAnchor(e ? { ...options, anchor: e.currentTarget, guid } : {});
	}, []);

	const initializeFields = (items, itemsToRefresh = []) => {
		const newFields = [];

		// Header
		newFields.push({
			guid: "toc-header",
			name: "toc-header",
			content: agendaHeader,
			toolbar: "header",
			deleted: false,
		});
		items &&
			items.forEach((item) => {
				if (!item.deleted) {
					newFields.push({
						guid: item.guid,
						name: item.guid,
						content: item.fields.Name.Value,
						toolbar: item.fields.Closed.Value ? "simpleMO" : "simple",
						isMemberOnly: item.fields.Closed.Value,
						deleted: false,
						refresh: itemsToRefresh.includes(item.guid),
					});

					newFields.push({
						guid: item.guid,
						name: `${item.guid}-text`,
						content: item.fields.Text.Value || "",
						toolbar: item.fields.Closed.Value ? "agendaItemTextMO" : "agendaItemText",
						isMemberOnly: item.fields.Closed.Value,
						deleted: item.fields.Text.Value === null,
						refresh: itemsToRefresh.includes(item.guid),
					});
				}
			});

		// Footer
		newFields.push({
			guid: "toc-footer",
			name: "toc-footer",
			content: agendaFooter,
			toolbar: "footer",
			deleted: false,
		});
		setEditorFields(newFields);
	};

	const getParentData = useCallback(
		(item, itemType, subHeading, fields) => {
			const itemIndex = items.findIndex((current) => current.guid === item.guid);

			// Get the item that the new one will be inserted after
			let previousItem = null;
			if (itemType === ITEM_TYPES.AGENDA_HEADING && !subHeading) {
				// A new heading goes after the previous heading
				if (item) {
					for (let index = 0; index < items.length; index++) {
						const currentItem = items[index];
						if (
							(!previousItem && currentItem.guid === item.guid) ||
							(previousItem && (currentItem.itemType !== ITEM_TYPES.AGENDA_HEADING || currentItem.fields.Indent.Value > 0))
						) {
							previousItem = currentItem;
						} else if (previousItem) {
							break;
						}
					}
				}
			} else if (itemType === ITEM_TYPES.AGENDA_HEADING && subHeading) {
				// A new sub-heading the previous heading/item and any child items
				for (let index = 0; index < items.length; index++) {
					const currentItem = items[index];
					if (
						(!previousItem && currentItem.guid === item.guid) ||
						(previousItem && (currentItem.itemType === ITEM_TYPES.RECOMMENDATION || currentItem.itemType === ITEM_TYPES.AGENDA_ITEM))
					) {
						previousItem = currentItem;
					} else if (previousItem) {
						break;
					}
				}
			} else {
				// A new sub-heading/item/recommendation goes after the previous heading/item and any recommendations
				for (let index = 0; index < items.length; index++) {
					const currentItem = items[index];
					if ((!previousItem && currentItem.guid === item.guid) || (previousItem && currentItem.itemType === ITEM_TYPES.RECOMMENDATION)) {
						previousItem = currentItem;
					} else if (previousItem) {
						break;
					}
				}
			}

			// Get the fields insertion index
			let fieldsInsertIndex = 1; // The insert location is index + 2, so this inserts after the header
			if (previousItem) {
				fieldsInsertIndex =
					fields.findIndex((field) => {
						return field.name === previousItem.guid;
					}) + 2;
			}

			// Get the items insertion index
			let itemsInsertIndex = 0; // Insert at the start of the list
			if (previousItem) {
				// Insert after the previous item
				itemsInsertIndex =
					items.findIndex((item) => {
						return item.guid === previousItem.guid;
					}) + 1;
			}

			// Get the parent
			let parentItem = null;
			if (itemsInsertIndex > 0 && (itemType !== ITEM_TYPES.AGENDA_HEADING || subHeading)) {
				for (let index = itemsInsertIndex - 1; index >= 0; index--) {
					const currentItem = items[index];
					if (
						(itemType === ITEM_TYPES.AGENDA_HEADING &&
							subHeading &&
							currentItem.itemType === ITEM_TYPES.AGENDA_HEADING &&
							currentItem.fields.Indent.Value === 0) ||
						(itemType === ITEM_TYPES.AGENDA_ITEM && currentItem.itemType === ITEM_TYPES.AGENDA_HEADING) ||
						(itemType === ITEM_TYPES.RECOMMENDATION && currentItem.itemType !== ITEM_TYPES.RECOMMENDATION)
					) {
						// Set parent - If the parent has been found we can stop searching for both
						parentItem = currentItem;
						break;
					}
				}
			}

			return { itemIndex, fieldsInsertIndex, itemsInsertIndex, parentItem };
		},
		[items],
	);

	const createNewItem = useCallback(
		(item, itemType, subHeading = false, duplicate = false, consentAction = false) => {
			const newFields = [...editorFieldsRef.current];
			const { itemIndex, fieldsInsertIndex, itemsInsertIndex, parentItem } = getParentData(item, itemType, subHeading, newFields);
			const numberOfChildren = getNumberOfChildren(itemIndex, itemType, subHeading);
			const newItemGuid = uuid();
			let sourceItem = cloneDeep(item);
			if (duplicate) {
				sourceItem = prepareAttachmentDuplication(sourceItem);
			}
			let consentActionItem = null;
			if (consentAction) {
				consentActionItem = {
					guid: newItemGuid,
					itemType: 7,
					fields: {
						Name: {
							Value: "<p>Consent Action</p>",
						},
					},
					deleted: false,
				};
			}

			const fieldsToAdd = [
				{
					guid: newItemGuid,
					name: newItemGuid,
					content: duplicate ? sourceItem.fields?.Name?.Value || "" : consentAction ? "<p>Consent Action<p>" : "",
					toolbar: parentItem?.fields?.Closed?.Value ? "simpleMO" : "simple",
					isMemberOnly: Boolean(parentItem?.fields?.Closed?.Value),
					deleted: false,
				},
				{
					guid: newItemGuid,
					name: `${newItemGuid}-text`,
					content: duplicate ? sourceItem.fields?.Text?.Value || "" : "",
					toolbar: parentItem?.fields?.Closed?.Value ? "agendaItemTextMO" : "agendaItemText",
					isMemberOnly: Boolean(parentItem?.fields?.Closed?.Value),
					deleted: !duplicate || !sourceItem.fields?.Text?.Value || consentAction,
				},
			];
			const itemsToAdd = [
				{
					sourceItem: duplicate ? sourceItem : consentAction ? consentActionItem : undefined,
					itemType,
					subHeading,
					newItemGuid,
					parentItem,
					parentGuid: parentItem?.guid,
				},
			];

			// If we are duplicating an item, also duplicate it's children
			if (duplicate && numberOfChildren > 0) {
				const guidMap = { [sourceItem.guid]: newItemGuid };
				for (let index = itemIndex + 1; index <= itemIndex + numberOfChildren; index++) {
					const childItem = prepareAttachmentDuplication(cloneDeep(items[index]));
					const newChildItemGuid = uuid();
					guidMap[childItem.guid] = newChildItemGuid;
					fieldsToAdd.push(
						{
							guid: newChildItemGuid,
							name: newChildItemGuid,
							content: childItem.fields?.Name?.Value || "",
							toolbar: childItem?.fields?.Closed?.Value ? "simpleMO" : "simple",
							isMemberOnly: Boolean(childItem?.fields?.Closed?.Value),
							deleted: false,
						},
						{
							guid: newChildItemGuid,
							name: `${newChildItemGuid}-text`,
							content: childItem.fields?.Text?.Value || "",
							toolbar: childItem?.fields?.Closed?.Value ? "agendaItemTextMO" : "agendaItemText",
							isMemberOnly: Boolean(childItem?.fields?.Closed?.Value),
							deleted: !childItem.fields?.Text?.Value,
						},
					);
					itemsToAdd.push({
						sourceItem: childItem,
						itemType: childItem.itemType,
						subHeading: childItem.fields?.Indent?.Value === 1,
						newItemGuid: newChildItemGuid,
						parentItem,
						parentGuid: guidMap[childItem.attributes?.relationshipGuid],
					});
				}
			}

			newFields.splice(fieldsInsertIndex, 0, ...fieldsToAdd);
			elementsRef.current.splice(fieldsInsertIndex, 0, ...fieldsToAdd.map(() => null));
			elementsRefStatus.current.rerender = true;
			setEditorFields(newFields);

			dispatch(addAgendaItem(itemsToAdd, newItemGuid, itemsInsertIndex, parentItem));
			if (!consentAction) {
				focusOnEditor(newItemGuid);
			}
			handleMenu();
		},
		[items],
	);

	const isValidPosition = useCallback(
		(index, itemType, subHeading, currentIndex, numberOfChildren) => {
			if (index < 0 || index >= items.length || (index > currentIndex && index <= currentIndex + numberOfChildren)) {
				return false; // Outside of the list or within it's own children
			}

			let valid = false;
			const item = items[index];
			switch (itemType) {
				case ITEM_TYPES.AGENDA_HEADING:
					if (!subHeading) {
						// Top-level headings
						if (index === items.length - 1 || (item.itemType === ITEM_TYPES.AGENDA_HEADING && item.fields.Indent.Value === 0)) {
							// The bottom or the same position as an existing heading are valid
							valid = true;
						}
					} else {
						// Sub-headings
						if (
							index > 0 &&
							(index === items.length - 1 ||
								item.itemType === ITEM_TYPES.AGENDA_HEADING ||
								(item.itemType === ITEM_TYPES.AGENDA_ITEM && isHeading(items, item.attributes?.relationshipGuid)))
						) {
							// The bottom or the same position as an existing heading/sub-heading/top-level and not the top item are valid
							valid = true;
						}
					}
					break;

				case ITEM_TYPES.AGENDA_ITEM:
					// Items
					if (
						index > 0 &&
						(index === items.length - 1 || item.itemType === ITEM_TYPES.AGENDA_HEADING || item.itemType === ITEM_TYPES.AGENDA_ITEM)
					) {
						// The bottom or the same position as an existing heading/sub-heading/item and not the top are valid
						valid = true;
					}
					break;

				case ITEM_TYPES.RECOMMENDATION:
					// Items
					if (index > 0) {
						// Any non-top position is valid
						valid = true;
					}
					break;
			}

			return valid;
		},
		[items],
	);

	const getNumberOfChildren = useCallback(
		(startIndex, itemType, subHeading, deleteItem = false) => {
			let count = 0;
			for (let index = startIndex + 1; index < items.length; index++) {
				const item = items[index];
				switch (itemType) {
					case ITEM_TYPES.AGENDA_HEADING:
						if (!subHeading) {
							// Top-level headings
							if (item.itemType !== ITEM_TYPES.AGENDA_HEADING || item.fields.Indent.Value > 0) {
								count++;
							} else {
								return count;
							}
						} else {
							// Sub-headings
							if (item.itemType !== ITEM_TYPES.AGENDA_HEADING) {
								if (deleteItem) {
									const childItems = items.filter((item) => {
										return item.attributes.relationshipGuid == items[startIndex].guid;
									});
									count = childItems.length;
								} else if (item.attributes.relationshipGuid !== items[startIndex].attributes.relationshipGuid) {
									// If the item is not a child of the current main heading
									count++;
								}
							} else {
								return count;
							}
						}
						break;

					case ITEM_TYPES.AGENDA_ITEM:
						// Items
						if (item.itemType === ITEM_TYPES.RECOMMENDATION) {
							count++;
						} else {
							return count;
						}
						break;
				}
			}

			return count;
		},
		[items],
	);

	const getAvailablePosition = useCallback(
		(itemIndex, itemType, subHeading, startIndex, step) => {
			const numberOfChildren = getNumberOfChildren(itemIndex, itemType, subHeading);
			let nextPosition = null;
			for (let index = startIndex; index >= 0 && index < items.length; index += step) {
				if (isValidPosition(index, itemType, subHeading, itemIndex, numberOfChildren)) {
					nextPosition = index;
					break;
				}
			}

			return {
				currentIndex: itemIndex,
				nextPosition,
				numberToMove: nextPosition !== null ? numberOfChildren + 1 : 0,
				nextPositionBlockSize:
					nextPosition !== null
						? getNumberOfChildren(nextPosition, items[nextPosition].itemType, items[nextPosition].fields.Indent.Value > 0) + 1
						: 0,
			};
		},
		[items],
	);

	const getNextAvailablePosition = useCallback(
		(itemToMove, moveUp = true) => {
			const currentIndex = items.findIndex((item) => item.guid === itemToMove.guid);
			const step = moveUp ? -1 : 1;

			return getAvailablePosition(currentIndex, itemToMove.itemType, itemToMove.fields.Indent.Value > 0, currentIndex + step, step);
		},
		[items],
	);

	const duplicateItem = (item) => createNewItem(item, item.itemType, item.fields.Indent.Value === 1, true);

	const moveItem = useCallback(
		(item, moveUp = true, data) => {
			const moveData = data || getNextAvailablePosition(item, moveUp);
			// Check if the move is valid
			if (moveData.nextPosition != null) {
				// Move items and children
				let reorderedItems = [...items];
				const movedItems = reorderedItems.splice(moveData.currentIndex, moveData.numberToMove);
				reorderedItems.splice(
					moveData.nextPosition < moveData.currentIndex
						? moveData.nextPosition
						: moveData.nextPosition - moveData.numberToMove + moveData.nextPositionBlockSize,
					0,
					...movedItems,
				);

				initializeFields(reorderedItems);
				dispatch(reorderAgendaBuilderAgendaItems(reorderedItems));
			}

			handleMenu();
		},
		[items],
	);

	const deleteItem = useCallback(
		(item) => {
			const itemIndex = items.findIndex((current) => current.guid === item.guid);
			const numberOfChildren = getNumberOfChildren(
				itemIndex,
				item.itemType,
				HEADINGS.includes(item.itemType) && item.fields.Indent.Value > 0,
			);

			// Check if this item has children that have not been added to the agenda yet
			let hasUnaddedChildren = false;
			if (numberOfChildren > 0) {
				for (let index = itemIndex + 1; index <= itemIndex + numberOfChildren; index++) {
					if (!items[index].added) {
						hasUnaddedChildren = true;
						break;
					}
				}
			}

			if (!hasUnaddedChildren) {
				// Delete the item
				dispatch(deleteAgendaItem(item.guid));

				let itemTypeString;
				switch (item.itemType) {
					case ITEM_TYPES.AGENDA_HEADING:
						itemTypeString = item.fields?.Indent?.Value > 0 ? "deleteSubHeading" : "deleteSection";
						break;

					case ITEM_TYPES.RECOMMENDATION:
						itemTypeString = "deleteRecommendation";
						break;

					default:
						itemTypeString = "deleteItem";
						break;
				}

				let option = notifierMessage(
					t(`snackbar.success.${itemTypeString}`, { count: numberOfChildren }),
					"success",
					() => restoreItem(item),
					10000,
				);
				dispatch(setSnackbarOptions(option));
			} else {
				// Show dialog explaining that this item can't be deleted
				setDialogs({ unableToDelete: true });
			}

			handleMenu();
		},
		[items],
	);

	const rejectItem = (item) => {
		handleMenu();
		setDialogs({ reject: item });
	};

	const handleConfirmReject = useCallback(
		(comment, item) => {
			rejectAgendaItem(comment, item)
				.then((res) => {
					if (res.status === 200) {
						telemetryAddEvent("Agenda item - Reject", { area: "agendaItems" });

						// Remove the item from the agenda build
						dispatch(removeAgendaItem(item.guid));

						const option = notifierMessage(t("rejectAgendaItem.snackbar.success"), "success");
						dispatch(setSnackbarOptions(option));
					}

					closeDialogs();
				})
				.catch((err) => {
					showSignIn(err, () => {
						rejectAgendaItem(comment, item);
					});
				});
		},
		[items],
	);

	const restoreItem = useCallback(
		(deletedItem) => {
			if (deletedItem) {
				const newFields = [...editorFieldsRef.current];

				setEditorFields(newFields);

				dispatch(restoreAgendaItem(deletedItem.guid));
			}
		},
		[items],
	);

	const toggleBooleanField = useCallback(
		(item, field) => {
			dispatch(
				setAgendaBuilderAgendaItems(
					[{ nonEditor: true, fieldName: item.guid, name: field, fieldData: !Boolean(item?.fields?.[field]?.Value) }],
					editorFieldsRef.current,
				),
			);
			// If closed is toggled, we need to re-render the editor to update the attachment icons
			let itemsToRefresh = [];
			if (field === "Closed") {
				//const newFields = [...editorFieldsRef.current];
				const itemIndex = items.findIndex((current) => current.guid === item.guid);
				const numberOfChildren = getNumberOfChildren(
					itemIndex,
					item.itemType,
					HEADINGS.includes(item.itemType) && item.fields.Indent.Value > 0,
					true,
				);
				itemsToRefresh = items.slice(itemIndex, itemIndex + numberOfChildren + 1).map((item) => item.guid);
			}

			if (field === "Consent" && item.fields.Consent.Value) {
				const itemIndex = items.findIndex((current) => current.guid === item.guid);
				let hasRecommendation = false;
				for (let i = itemIndex + 1; i < items.length; i += 1) {
					const currentItem = items[i];
					if (currentItem.itemType === ITEM_TYPES.HEADING) break;
					if (currentItem.itemType === ITEM_TYPES.RECOMMENDATION && currentItem.attributes.relationshipGuid === item.guid)
						hasRecommendation = true;
				}
				if (!hasRecommendation) {
					createNewItem(item, ITEM_TYPES.RECOMMENDATION, false, false, true);
				}
			}

			initializeFields(items, itemsToRefresh);
			handleMenu();
		},
		[items],
	);

	const toggleMembersOnly = (item) => toggleBooleanField(item, "Closed");

	const toggleConsent = (item) => toggleBooleanField(item, "Consent");

	const togglePublicComment = (item) => toggleBooleanField(item, "PublicComment");

	const updateTextField = useCallback((item, field, value) => {
		dispatch(
			setAgendaBuilderAgendaItems([{ nonEditor: true, fieldName: item.guid, name: field, fieldData: value }], editorFieldsRef.current),
		);
	}, []);

	const getItemOptions = useCallback(
		({
			item,
			isProposedSubmitted,
			isHeading,
			isSubHeading,
			isMemberOnlyHeading,
			isConsentHeading,
			isPublicCommentHeading,
			isItem,
			index,
		}) => {
			// Item menu options
			const menuOptions = [];
			if (isProposedSubmitted && (!meetingActive || (meetingActive && isReorderable))) {
				menuOptions.push({
					label: t("agendaMenu:duplicate"),
					actionFunction: () => duplicateItem(item),
					"data-cy": "duplicate",
				});
			}
			if (!isProposedSubmitted) {
				menuOptions.push({
					label: t("agendaMenu:openItem"),
					actionFunction: () => openItem(item),
					"data-cy": "open-item",
				});
			}
			if (getNextAvailablePosition(item, true).nextPosition !== null && (!meetingActive || (meetingActive && isReorderable))) {
				menuOptions.push({
					label: t("agendaMenu:moveUp"),
					actionFunction: () => moveItem(item, true),
					"data-cy": "move-up",
				});
			}
			if (getNextAvailablePosition(item, false).nextPosition !== null && (!meetingActive || (meetingActive && isReorderable))) {
				menuOptions.push({
					label: t("agendaMenu:moveDown"),
					actionFunction: () => moveItem(item, false),
					"data-cy": "move-down",
				});
			}

			if (isProposedSubmitted) {
				if (!item.submission) {
					if (!meetingActive || (meetingActive && isReorderable)) {
						menuOptions.push({
							label: t("agendaMenu:delete"),
							actionFunction: () => deleteItem(item),
							"data-cy": "delete",
						});
					}
				} else {
					menuOptions.push({
						label: t("agendaMenu:reject"),
						actionFunction: () => rejectItem(item),
						"data-cy": "reject",
					});
				}
			}
			if (isHeading || isSubHeading || isItem) {
				menuOptions.push({
					label: t("meetingMenu:copyToAgenda"),
					actionFunction: () => {
						telemetryAddEvent("Agenda builder - Copy overflow");
						const agendaItems = createItemArrayForOverflowMenu(items, item, index);
						dispatch(createItemsForOverflow(agendaItems));
						navigate(`/meeting/copy/${id}?isOverflow=${true}`);
					},
					separator: true,
					"data-cy": "copy-to-agenda",
				});
				if (!meetingActive) {
					menuOptions.push({
						label: t("meetingMenu:moveToAgenda"),
						actionFunction: () => {
							telemetryAddEvent("Agenda builder - Move overflow");
							const agendaItems = createItemArrayForOverflowMenu(items, item, index);
							dispatch(createItemsForOverflow(agendaItems));
							navigate(`/meeting/move/${id}?isOverflow=${true}`);
						},
						separator: false,
						"data-cy": "move-to-agenda",
					});
				}
			}
			if (isHeading && !isSubHeading) {
				menuOptions.push({
					label: isMemberOnlyHeading ? t("agendaMenu:setPublicSection") : t("agendaMenu:setMemberOnlySection"),
					actionFunction: () => toggleMembersOnly(item),
					separator: true,
					"data-cy": "members-only",
				});
				menuOptions.push({
					label: isConsentHeading ? t("agendaMenu:transformSection") : t("agendaMenu:transformSectionConsent"),
					actionFunction: () => toggleConsent(item),
					"data-cy": "consent",
				});
				menuOptions.push({
					label: isPublicCommentHeading ? t("agendaMenu:transformSection") : t("agendaMenu:transformSectionPublicComment"),
					actionFunction: () => togglePublicComment(item),
					"data-cy": "public-comment",
				});
			}

			return menuOptions.length === 0 ? undefined : menuOptions;
		},
		[items, isReorderable],
	);

	const addItem = (item) => createNewItem(item, ITEM_TYPES.AGENDA_ITEM);

	const addHeading = (item) => createNewItem(item, ITEM_TYPES.AGENDA_HEADING);

	const addSubHeading = (item) => createNewItem(item, ITEM_TYPES.AGENDA_HEADING, true);

	const addRecommendation = (item) => createNewItem(item, ITEM_TYPES.RECOMMENDATION);

	const addItemMenuOptions = useMemo(
		() => [
			{
				button: t("agendaMenu:addItem"),
				label: t("agendaMenu:addItemMenu"),
				actionFunction: addItem,
				"data-cy": "add-item",
			},
			{
				button: t("agendaMenu:addHeader"),
				label: t("agendaMenu:addHeaderMenu"),
				actionFunction: addHeading,
				"data-cy": "add-heading",
			},
			{
				button: t("agendaMenu:addSubHeading"),
				label: t("agendaMenu:addSubHeadingMenu"),
				actionFunction: addSubHeading,
				"data-cy": "add-sub-heading",
			},
			{
				button: t("agendaMenu:addRecommendedAction"),
				label: t("agendaMenu:addRecommendedActionMenu"),
				actionFunction: addRecommendation,
				"data-cy": "add-recommendation",
			},
		],
		[items],
	);

	const getItems = (items, droppedId) => {
		let isConsentHeading = false;
		let isPublicCommentHeading = false;
		let isMemberOnlyHeading = false;

		const filteredItems = [];
		const previous = {
			heading: {
				item: null,
				numberOfChildren: 0,
			},
		};
		items.forEach((item) => {
			filteredItems.push(item);
			if (!item.deleted) {
				if (item.itemType === ITEM_TYPES.AGENDA_HEADING) {
					// Top level heading
					previous.heading = {
						item,
						numberOfChildren: !item.attributes.relationshipGuid ? 0 : previous.heading.numberOfChildren,
					};
				} else if (previous.heading.item && item.itemType === ITEM_TYPES.AGENDA_ITEM) {
					// Count the children of the heading
					previous.heading.numberOfChildren++;
				}
			}
		});

		const lastIndex = filteredItems.length - 1;
		let elementsIndex = 0;
		let domElements = [];

		// Header
		const headerElement = (
			<HeaderFooter
				key="header"
				isHeader
				elementsRef={elementsRef}
				elementsIndex={elementsIndex}
				afterElementRefSet={afterElementRefSet}
				addHeadingPosition={ADD_HEADING_BELOW}
				handleAddHeading={addHeading}
				showHeader={!meetingActive || (meetingActive && isReorderable)}
			/>
		);
		domElements.push(headerElement);
		elementsIndex++;

		filteredItems.map((item) => {
			if (!item.deleted) {
				const originalUnfilteredIndex = items.findIndex((i) => i.guid === item.guid);
				if (droppedId === item.guid) {
					domElements.push(
						<Droppable
							dropComponent={DropPlaceholder}
							key={`placeholder-before-${item.guid}`}
							dropId={`${item.guid}-placeholder`}
							component="li"
						></Droppable>,
					);
				}

				const isHeading = item.itemType === ITEM_TYPES.AGENDA_HEADING;
				const isSubHeading = Boolean(isHeading && item.attributes.relationshipGuid);
				const isItem = item.itemType === ITEM_TYPES.AGENDA_ITEM;
				const isProposedSubmitted = Boolean(item.added);

				isMemberOnlyHeading = Boolean(item?.fields?.Closed?.Value);
				isConsentHeading = Boolean(item?.fields?.Consent?.Value);
				isPublicCommentHeading = Boolean(item?.fields?.PublicComment?.Value);
				let element;

				let editorFieldText = {};

				let neweditorFieldText =
					editorFieldsRef &&
					editorFieldsRef.current &&
					editorFieldsRef.current.find((field) => {
						return field.name === `${item && item.guid}-text`;
					});

				if (neweditorFieldText) {
					editorFieldText = neweditorFieldText;
				}
				element = (
					<MeetingItem
						key={item.guid}
						item={item}
						active={active?.indexOf(item.guid) >= 0 ? active : undefined}
						setActive={setActive}
						isMemberOnlyHeading={isMemberOnlyHeading}
						isConsentHeading={isConsentHeading}
						isPublicCommentHeading={isPublicCommentHeading}
						isHeading={isHeading}
						isSubHeading={isSubHeading}
						isProposedSubmitted={isProposedSubmitted}
						isItem={isItem}
						isRecommendation={item.itemType === ITEM_TYPES.RECOMMENDATION}
						showNotes={showNotes}
						handleUpdateNotes={updateTextField}
						showMenu
						menuOptions={getItemOptions}
						index={originalUnfilteredIndex}
						addItemMenuOptions={addItemMenuOptions}
						menuAnchor={menuAnchor.guid === item.guid ? menuAnchor : undefined}
						handleMenu={handleMenu}
						shouldLockAgenda={!isReorderable}
						canDrag
						canDrop
						addBottomBorder={originalUnfilteredIndex === lastIndex}
						elementsRef={elementsRef}
						elementsIndex={elementsIndex}
						afterElementRefSet={afterElementRefSet}
						removeText={removeItemText}
						addText={addItemText}
						editorFieldTextDeleted={editorFieldText.deleted}
						isMeetingTemplate={false}
						queueFileUploads={queueFileUploads}
						addPolicy={addPolicy}
						addGoals={addGoals}
						customNumbering={customNumbering}
						meetingId={id}
						onPendingApprovalClick={onPendingApprovalClick}
					/>
				);
				elementsIndex += 2;

				// We now always create the text element, it is just hidden or shown sometimes
				domElements.push(element);
				return element || <div key={`item${item.guid}`}>{item.fields.Name.Value}</div>;
			}
		});

		if (droppedId === tocFooterDropId) {
			domElements.push(
				<Droppable
					dropComponent={DropPlaceholder}
					key={`placeholder-before-${tocFooterDropId}`}
					dropId={tocFooterDropId}
					component="li"
				></Droppable>,
			);
		}

		// Footer
		const footerElement = (
			<HeaderFooter
				key="footer"
				label={t("templateDetail.labels.footer")}
				elementsRef={elementsRef}
				elementsIndex={elementsIndex}
				afterElementRefSet={afterElementRefSet}
				canDrop
			/>
		);
		domElements.push(<DragPlaceholder height={50} canDrop />);
		domElements.push(footerElement);
		elementsIndex++;

		// Initialize the elements ref status
		if (elementsRefStatus.current.rerender) {
			elementsRefStatus.current.status = [];
			for (let index = 0; index <= elementsIndex; index++) {
				elementsRefStatus.current[index] = false;
			}
		}
		return domElements;
	};

	const scrollToActive = (fieldName, field) => {
		scrollToElement(fieldName);
		const elem = document.getElementById(field ? `${field}-${fieldName}` : `agenda-${fieldName}`);
		if (elem) {
			scrollToElement(elem, document.getElementById("content-scroll"), -48);
		}
		const tocElement = document.getElementById(`outline-minute-${fieldName}`);
		if (tocElement) {
			scrollToElement(tocElement, document.getElementById("minute-outline"));
		}
	};

	const focusEditor = (fieldName, attempts = 0) => {
		const root = window.editor?.model?.document?.getRoot(fieldName);
		let element = null;
		if (root) {
			element = document.querySelectorAll(`[data-fieldname="${fieldName}"][contenteditable="true"]:not([class*="editorFieldHide"])`);
		}
		if (element && element.length > 0 && window.getComputedStyle(element[0], null).display !== "none") {
			// setTimeout ensures that the focus does not revert to the previously focus editor
			setTimeout(() => {
				element[0].focus();
				scrollToActive(fieldName);
			}, 0);
		} else if (attempts < 10) {
			// Try again
			setTimeout(() => {
				focusEditor(fieldName, attempts + 1);
			}, 100);
		} else {
			// Possibly an item pending approval
			element = document.querySelectorAll(`#meeting-item-${fieldName}`);
			if (element && element.length > 0) {
				scrollToElement(element[0], document.getElementById("content-scroll"), -48);
			}
		}
	};

	const saveUpdates = debounce(async () => {
		dispatch(persistAgendaBuilderAgendaItems(t, dateFormat, id))
			.then((res) => {
				// Update the meeting in the parent component
				if (res.meeting && typeof updateMeeting === "function") {
					updateMeeting(res.meeting);
				}
			})
			.catch((err) => {
				showSignIn(err, () => {
					dispatch(persistAgendaBuilderAgendaItems());
				});
			});
	}, saveDelay);

	const closeDialogs = () => {
		setDialogs({});
	};

	useEffect(() => {
		if (window.editor) {
			handleFieldChange(window.editor);
		}
	}, [triggerEditorChange]);

	useEffect(() => {
		if (requestedItemGuid && !editorInitializing) {
			dispatch(setActive(requestedItemGuid, true));
		}
	}, [requestedItemGuid, editorInitializing]);

	useEffect(() => {
		if (active) {
			focusEditor(active);
		}
	}, [active]);

	useEffect(() => {
		initializeFields(items);
	}, []);

	useEffect(() => {
		if (!saving && updated) {
			saveUpdates();
		}
	}, [saving, updated]);

	useEffect(() => {
		if (editorScrollableContainer.current) {
			// Calculate the offset from the top of the page
			let offsetTop = 0;
			let offsetParent = editorScrollableContainer.current;
			while (offsetParent) {
				offsetTop += offsetParent.offsetTop;
				offsetParent = offsetParent.offsetParent;
			}

			setEditorDyamicHeightOffset(offsetTop);
		}
	});

	return (
		<>
			{dialogs.unableToDelete ? <UnableToDeleteDialog onClose={closeDialogs} /> : null}
			{dialogs.reject ? (
				<AgendaItemRejectDialog
					show
					item={dialogs.reject}
					title={t("rejectAgendaItem.title")}
					onApprove={handleConfirmReject}
					onClose={closeDialogs}
				/>
			) : null}
			{editorInitializing && <Spinner />}
			<div
				className={clsx(
					"flex",
					"direction-column",
					"notes-open",
					"agenda-editor-content",
					...(!isSplitPane ? ["notes-open", "agenda-editor-content"] : []),
					{
						["agenda-editor-content-xs"]: !widthUpSm,
						[classes.editorContentSmUp]: !isSplitPane && widthUpSm,
						[classes.splitPaneEditorContentSmUp]: isSplitPane && widthUpSm,
					},
				)}
				ref={editorScrollableContainer}
			>
				<Container
					id={"new-editor-toc-header"}
					maxWidth="lg"
					className={`${classes.contentContainer} ${draggedId ? classes.dragging : ""}`}
				>
					<DndContext
						sensors={sensors}
						collisionDetection={getCollisionDetection(dragRef, rectIntersection)}
						onDragStart={handleDragStart}
						onDragMove={handleDragMove}
						onDragEnd={handleDragEnd}
						onDragCancel={handleDragCancel}
					>
						<List
							component="ul"
							disablePadding
							classes={{ root: isSplitPane ? classes.agendaItemsContent : "agenda-items-container" }}
							id="item-list"
							data-cy="item-list"
						>
							{editorFields.length > 0 && getItems(items, droppedId)}
						</List>
						{createPortal(
							<DragOverlay
								style={{ height: `${dragDimensionsRef.current?.height || 50}px`, width: `${dragDimensionsRef.current?.width || 500}px` }}
							>
								<DragPresentation ref={dragRef}>{getDragComponent()}</DragPresentation>
							</DragOverlay>,
							document.body,
						)}
					</DndContext>
				</Container>
				{editorFields.length > 0 && elementsRef.current.length > 0 && (
					<SingletonEditor
						fields={editorFields}
						fieldsRefs={elementsRef}
						fieldRefsSet={elementRefsSet}
						editorToolbarRef={editorToolbarRef}
						meetingId={id}
						queueFileUploads={queueFileUploads}
						addPolicy={addPolicy}
						addGoals={addGoals}
						onFieldChange={handleFieldChange}
						onEditorInitialized={handleEditorInitialized}
						onFocusChange={handleFocusChange}
						onUndoRedoRoot={handleUndoRedoRoot}
						onDeletedFieldIndex={handleDeletedFieldIndex}
						deletedFieldIdx={deletedFieldIdx}
						features={{
							id: "MOA",
							label: t("meetings:inlineFile.features.MOA.featureLabel"),
							className: "closed",
							anchorTitle: t("meetings:inlineFile.features.MOA.anchorTitleMember"),
							tooltipDisabledOn: t("meetings:inlineFile.features.MOA.tooltipDisabledOn"),
						}}
						tableInlineFileUpload={true}
					/>
				)}
			</div>
		</>
	);
};

export default AgendaSingletonEditor;
