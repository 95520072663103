import React from "react";
import { useTranslation } from "react-i18next";

import SearchResult from "./SearchResult";

const AgendaItemResult = (props) => {
	const { result } = props;
	const { t } = useTranslation();

	const getIconName = () => {
		return "document";
	};

	const link =
		result.submission || !result.meetingId || !result.canUpdateMeeting
			? `/agendaitems/edit/${result.guid}`
			: `/meeting/detailsV2/${result.meetingId}?itemGuid=${result.guid}`;

	return (
		<SearchResult
			result={result}
			icon={{
				name: getIconName(result),
				tooltip: t("search.tooltips.agendaItem"),
			}}
			link={link}
		/>
	);
};

export default React.memo(AgendaItemResult);
