import ObservableMixin from "@ckeditor/ckeditor5-utils/src/observablemixin";
import mix from "@ckeditor/ckeditor5-utils/src/mix";
//
// Feature switches for inlineFile attachments
//
export default class InlineFileFeature {
	constructor({ id, label, className, defaultValue, disabledValue, isEnabled, anchorTitle, tooltipDisabledOn }) {
		this.id = id;
		if (isEnabled) {
			this.set({ value: defaultValue });
		} else {
			this.set({ value: disabledValue });
		}
		this.label = label;
		this.className = className;
		this.defaultValue = defaultValue;
		this.disabledValue = disabledValue;
		this.anchorTitle = anchorTitle;
		this.set({ tooltipDisabledOn: tooltipDisabledOn ? tooltipDisabledOn : "" });
		this.set({ isEnabled: isEnabled });
	}
}

mix(InlineFileFeature, ObservableMixin);
