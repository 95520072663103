import React, { useEffect, useState, useCallback, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import request from "superagent";
import clsx from "clsx";

import makeStyles from "@mui/styles/makeStyles";

import searchAndTreeDialogStyle from "assets/jss/components/searchAndTreeDialogStyle";
import GenericDialog from "atlas/components/Dialogs/GenericDialog";
import Icon from "atlas/components/Icon/Icon";
import CircularProgressIndicator from "atlas/components/Progress/CircularProgressIndicator";
import PolicyTree from "components/Dialogs/PolicyTree";
import SearchInput from "components/Search/SearchInput";
import { API_HOST } from "config/env";
import { FOLDER_TYPE_DRAFT_POLICY } from "utils/enums/DocumentFolderTypes";
import { displayErrorNotification } from "views/Policy/utils/getNotification";
import { getPolicies, copyForPolicy, moveForPolicy } from "redux/policyGovernance/actions";
import { sortTitle } from "views/Policy/PolicySettingsModule";
import SearchResults from "views/Search/components/SearchResults";

const useStyles = makeStyles(searchAndTreeDialogStyle);

const PolicyMoveCopyDialog = (props) => {
	const { show = true, document, onClose, afterCopyMove, move, afterMove } = props;
	const { t } = useTranslation("documents");
	const [searchKeywords, setSearchKeywords] = useState("");
	const [policies, setPolicies] = useState(null);
	const [searching, setSearching] = useState(false);
	const [searchResults, setSearchResults] = useState(null);
	const [total, setTotal] = useState(0);
	const [page, setPage] = useState(0);
	const [selection, setSelection] = useState(null);
	const [moving, setMoving] = useState(false);
	const lastSearch = useRef("");
	const classes = useStyles();
	const dispatch = useDispatch();

	const setSelectedData = (policy) => {
		setSelection(policy.selected ? policy.guid : null);
	};

	const setTreeSelectedData = useCallback(
		(policy) => {
			setSelectedData(policy);

			// Only one policy can be selected at a time
			clearPolicySelection(policies, policy.guid);
		},
		[policies],
	);

	const clearPolicySelection = (policies, excludeGuid) => {
		policies
			.filter((policy) => policy.guid !== excludeGuid)
			.forEach((policy) => {
				policy.selected = false;
				if (policy.children) {
					clearPolicySelection(policy.children, excludeGuid);
				}
			});
	};

	const setSearchResultsSelectedData = useCallback(
		(policy) => {
			setSelectedData(policy);

			// Only one policy can be selected at a time
			searchResults
				.filter((otherResult) => otherResult.selected && otherResult !== policy)
				.forEach((otherResult) => {
					otherResult.selected = false;
				});
		},
		[searchResults],
	);

	const canSelect = (policy) => policy?.isSection;

	const loadPolicies = (parent) => {
		return new Promise((resolve, reject) => {
			dispatch(getPolicies({ parentId: parent ? parent.guid : undefined, showPolicies: false }))
				.then((res) => {
					let policies = res || [];
					policies = policies.filter((policy) => policy.canUpdate).sort(sortTitle);

					if (parent) {
						parent.children = policies;

						// Sections should not have child folders - This prevents the "expand" icon from showing due to child policies
						policies.forEach((policy) => {
							policy.isSection = policy.folder;
							policy.hasChildren = false;
						});
					} else {
						setPolicies(policies);
					}

					resolve(policies);
				})
				.catch((err) => {
					showSignIn(
						err,
						() => {
							loadPolicies(parent);
						},
						reject,
					);
				});
		});
	};

	const handleSearchChange = (e) => {
		const { value } = e.target;
		setSearchKeywords(value);
		if (!value) {
			setSearching(false);
		}
	};

	const handleSearchKeyDown = (e) => {
		if (e.key === "Enter") {
			const { value } = e.target;
			handleSearch(value);
		}
	};

	const onClearSearchClick = () => {
		setSearchKeywords("");
		setSearching(false);
	};

	const searchResultIsSection = (result) => result && result.folder && result.path.length === 1;

	const handleSearch = (value, pageLoadResolver) => {
		setSearching(true);
		lastSearch.current = value;

		if (value) {
			if (!pageLoadResolver) {
				setSearchResults(null); // New search/filter, so show the loading indicator
			}

			request
				.get(`${API_HOST}/api/items/search`)
				.withCredentials()
				.query({
					keyWords: value,
					includeTotals: true,
					searchTypes: 10,
					folderTypes: FOLDER_TYPE_DRAFT_POLICY,
					page: pageLoadResolver ? page + 1 : undefined,
				})
				.then((res) => {
					const { results = [], policies } = res.body;

					if (!pageLoadResolver) {
						setSearchResults(results.filter(searchResultIsSection));
						setTotal(policies);
						setPage(1);
					} else {
						setSearchResults((prev) => (prev || []).concat(results.filter(searchResultIsSection)));
						pageLoadResolver();
						setPage((prev) => prev + 1);
					}
				})
				.catch((err) => {
					showSignIn(
						err,
						() => {
							handleSearch(value);
						},
						() => {
							setSearchResults([]);
							setTotal(0);
							setPage(0);
						},
					);
				});
		} else {
			setSearchResults([]);
		}
	};

	const loadMore = () =>
		new Promise((resolve) => {
			handleSearch(lastSearch.current, resolve);
		});

	const handleCopy = () => {
		if (selection) {
			setMoving(true);
			dispatch(copyForPolicy(document.guid, selection))
				.then((res) => {
					afterCopyMove(res.body, false, res.body.path.map((pathPart) => `${pathPart.code} ${pathPart.title}`.trim()).join(" > "));
					onClose();
				})
				.catch((err) => {
					if (err.status === 500) {
						displayErrorNotification(t("policy.errorMsg.duplicatePolicy"), dispatch);
					} else {
						displayErrorNotification(t("policy.errorMsg.commonMsg"), dispatch);
					}
					onClose();
				});
		}
	};

	const handleMove = () => {
		if (selection) {
			setMoving(true);
			dispatch(moveForPolicy(document.guid, selection))
				.then((res) => {
					afterMove(res.body, true, res.body.path.map((pathPart) => `${pathPart.code} ${pathPart.title}`.trim()).join(" > "));
					onClose();
				})
				.catch((err) => {
					if (err.status === 500) {
						displayErrorNotification(t("policy.errorMsg.movePolicy"), dispatch);
					} else {
						displayErrorNotification(t("policy.errorMsg.commonMsg"), dispatch);
					}
					onClose();
				});
		}
	};

	useEffect(() => {
		loadPolicies();
	}, []);

	const i18n = t("copyMoveDocumentDialog", { title: document.title + document.extension });

	const dialog = move
		? {
				title: i18n.title["move"],
				line1: i18n.line1["move"],
				primaryTitle: t(`app:buttons.move`),
				primaryAction: handleMove,
				secondaryTitle: t("app:buttons.cancel"),
				secondaryAction: onClose,
			}
		: {
				title: i18n.title["duplicate"],
				line1: i18n.line1["copy"],
				primaryTitle: i18n.title["duplicate"],
				primaryAction: handleCopy,
				secondaryTitle: t("app:buttons.cancel"),
				secondaryAction: onClose,
			};

	return (
		<GenericDialog
			className={classes.dialog}
			show={show}
			title={dialog.title}
			primaryAction={dialog.primaryAction}
			primaryTitle={dialog.primaryTitle}
			primaryDisabled={moving || !selection}
			secondaryAction={dialog.secondaryAction}
			secondaryTitle={dialog.secondaryTitle}
			secondaryDisabled={moving}
			clickAwayDisabled={moving}
			closeIcon={<Icon name="close" />}
			data-cy="delete-dialog"
		>
			<div className={classes.content}>
				<div
					className={clsx(classes.searchContainerNoTabs, {
						[classes.searchContainerLoading]: !policies,
					})}
				>
					<SearchInput
						size="extra-small"
						fullWidth
						value={searchKeywords || ""}
						showClearIcon={searchKeywords && searchKeywords.length > 0}
						onKeyDown={handleSearchKeyDown}
						onChange={handleSearchChange}
						onIconClick={() => {
							handleSearch(searchKeywords);
						}}
						onClearClick={onClearSearchClick}
						data-cy="policy-search"
					/>
				</div>
				{!searching ? (
					policies ? (
						<div className={classes.dataContainer} data-cy="policy-tree">
							<PolicyTree
								policies={policies}
								booksAndSectionsOnly
								canSelect={canSelect}
								selection={selection}
								handleLoadChildren={loadPolicies}
								setSelectedData={setTreeSelectedData}
							/>
						</div>
					) : (
						<CircularProgressIndicator />
					)
				) : null}
				{searching ? (
					searchResults ? (
						<div className={classes.dataContainer} data-search data-cy="policy-search-results">
							<SearchResults
								results={searchResults}
								total={total}
								loadMore={loadMore}
								isPolicy
								compact={{ height: "250px" }}
								enableSelect={{
									allow: searchResultIsSection,
									handle: (result, selected) => {
										result.selected = selected;
									},
								}}
								selection={selection}
								setSelectedData={setSearchResultsSelectedData}
								policyAddToAgendaSearch
							/>
						</div>
					) : (
						<CircularProgressIndicator />
					)
				) : null}
			</div>
		</GenericDialog>
	);
};

export default PolicyMoveCopyDialog;
