export default {
	publicRequestToSpeak: "Public request to speak",
	commentsToSubmitter: "Comments to submitter",
	awaitingRequestedChanges: "Awaiting requested changes",

	buttons: {
		addRequestToSpeak: "+ Request",
		approve: "Approve",
		remove: "Remove",
		reject: "Reject",
		addToAgenda: "Add to agenda",
		requestChanges: "Request changes",
		removeToSubmitted: "Remove to submitted",
		addToSubmitted: "Add to submitted",
		moveBackToSubmitted: "Move back to submitted",
		loadMore: "Load more",
		move: "Move",
	},
	menu: {
		returnToSubmitted: "Remove to submitted",
		moveBackToSubmitted: "Move back to submitted",
	},
	noItems: {
		submitted: {
			title: "No submitted request to speak items",
			description: "We couldn't find any submitted request to speak items to display",
		},
		added: {
			title: "No added request to speak items",
			description: "We couldn't find any added request to speak items to display",
		},
		removed: {
			title: "No removed request to speak items",
			description: "We couldn't find any removed request to speak items to display",
		},
	},

	publicSubmission: {
		buttons: {
			openCodeOfConduct: "For further information on how to approach meetings please read our complete code of conduct.",
			submitRequest: "Submit request",
			submitRequestToSpeak: "Submit request to speak",
			updateRequest: "Update request",
		},
		cancel: {
			success: "Your request to speak at {{meeting}} has been cancelled.",
			error: "We were unable to cancel your request to speak.",
		},
		codeOfConduct: "Code of conduct",
		contactInformation: "Contact information",
		emailAddress: "Email",
		errors: {
			firstName: "Please enter a first name.",
			lastName: "Please enter a last name.",
			emailAddress: "Please enter a valid email address (email@email.com)",
			topic: "Please enter a topic",
		},
		firstName: "First name",
		iWillAbideByTheCodeOfConduct: "I will abide by the code of conduct",
		lastName: "Last name",
		meeting: "Meeting",
		meetingInformation: "Meeting you'd like to speak at",
		phoneNumber: "Phone (optional)",
		requestContent: "Request content",
		requestDetails: "Request details",
		requestMeeting: "Meeting: {{name}}",
		requestStatus: "Request status: {{status}}",
		requestSubmitted: "Request submitted: {{date}}",
		requestToSpeak: "Request to speak",
		status: {
			approved: "Approved",
			removed: "Removed",
			submitted: "Submitted",
		},
		title: "Please submit your request to speak below",
		titleEdit: "Public request to speak",
		tooltips: {
			submitRequestToSpeak: "Submit a request to speak at the selected meeting",
			updateRequest: "Update this request to speak",
		},
		topic: "Topic *",
		wellAutomaticallyNotifyTheSubmitter: "We'll automatically notify the submitter of any edits you make",
	},
	snackbar: {
		addToAgenda: {
			success: "Item added to agenda successfully",
		},
		approve: {
			success: "Request to speak item approved successfully",
		},
		create: {
			success: "Request created successfully",
		},
		remove: {
			success: "Request to speak item removed successfully",
		},
		requestChanges: {
			success: "Request sent successfully",
		},
		submit: {
			success: "Request set to submitted",
		},
		submitted: {
			success: "Request successfully submitted",
		},
		update: {
			success: "Request updated successfully",
		},
	},
	tabs: {
		submitted: "Submitted",
		added: "Added",
		removed: "Removed",
	},
	tooltips: {
		addRequestToSpeak: "Add a new request to speak",
		approve: "Approve this item and add it to the meeting",
		remove: "Remove this item from this item list",
		addToAgenda: "Add this item to the agenda",
		requestChanges: "Request changes form the submitter",
		removeToSubmitted: "Remove this item from this list and add it to submitted",
		addToSubmitted: "Add this item to submitted",
		moveBackToSubmitted: "Moves this item back to submitted items",
		loadMore: "Load more items",
	},

	addToAgendaDialog: {
		title: "Approve with comment",
		noSectionsWarning: "No public speaking sections.",
		noSectionsWarningLink: "Add section to agenda",
		commentsToSubmitterOptional: "Comments to submitter (Optional)",
	},

	requestChangesDialog: {
		title: "Request changes",
	},

	rejectDialog: {
		title: "Reject and remove item",
		commentsToSubmitterRequired: "Comments to submitter (Required)",
		rejectCommentToSubmitterPlaceholder: "Add text on why this request to speak was rejected",
	},

	moveItemToSubmittedDialog: {
		title: "Move back to submitted",
		confirmMove: "Are you sure you want to set this item to submitted?",
	},
};
