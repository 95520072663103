import React, { useState, useEffect, useCallback, useRef } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import request from "superagent";
import { closeSnackbar } from "notistack";
import queryString from "query-string";
import { format } from "date-fns";
import isEqual from "lodash/fp/isEqual";

import ComponentContainer from "atlas/components/ComponentContainer/ComponentContainer";
import withErrorHandling from "components/ErrorHOC";
import RightPanelContainer, { MD_DOWN_WIDTH } from "components/Panels/RightPanelContainer";
import FilterPanel, { DEFAULT_SEARCH_FIELD } from "components/Panels/FilterPanel";
import { API_HOST } from "config/env";
import { resetPageConfigs, updatePageConfigs, updateToolbar } from "redux/app/actions";
import { updatePageHeader } from "redux/pageHeader/actions";
import telemetryAddEvent from "utils/telemetryAddEvent";
import getDateRange, {
	ISO_DATE_FORMAT,
	DATE_RANGE_NONE,
	DATE_RANGE_TODAY,
	DATE_RANGE_THIS_WEEK,
	DATE_RANGE_THIS_MONTH,
	DATE_RANGE_LAST_MONTH,
} from "utils/customDateRange";
import { useUpdateObject } from "utils/updateObject";
import AgendaItemList, { BATCH_SIZE } from "./components/AgendaItemList";
import AgendaItemFilter from "./components/AgendaItemFilter";
import { restoreItem } from "./functions/agendaItems";
import notifierMessage from "utils/notifierMessage";
import { setSnackbarOptions } from "redux/snackBar/actions";

const defaultFilter = {
	[DEFAULT_SEARCH_FIELD]: "",
	keyWords: "",
	from: "",
	to: "",
	meetingTypes: [],
};

const AgendaItemsModule = (props) => {
	const { showSignIn } = props;
	const navigate = useNavigate();
	const location = useLocation();
	const { tab: defaultTab = 0 } = queryString.parse(location.search) || {};
	const { t } = useTranslation("agendaItems");
	const [tab, setTab] = useState(parseInt(defaultTab, 10) || 0);
	const [panels, setPanels] = useState({});
	const [filter, setFilter] = useState({ ...defaultFilter });
	const [items, setItems] = useState(null);
	const [meetingTypes, setMeetingTypes] = useState([]);
	const lastFilter = useRef({ ...defaultFilter });
	const dispatch = useDispatch();

	const sanitizeFilter = (prev) => {
		if (prev.customDateRange === DATE_RANGE_NONE) {
			delete prev.from;
			delete prev.to;
		} else {
			let dateRange = null;
			if (prev.customDateRange === DATE_RANGE_TODAY) {
				// Today
				dateRange = getDateRange("today");
			} else if (prev.customDateRange === DATE_RANGE_THIS_WEEK) {
				// This week
				dateRange = getDateRange("thisWeek");
			} else if (prev.customDateRange === DATE_RANGE_THIS_MONTH) {
				// This month
				dateRange = getDateRange("thisMonth");
			} else if (prev.customDateRange === DATE_RANGE_LAST_MONTH) {
				// This month
				dateRange = getDateRange("lastMonth");
			}
			if (dateRange) {
				prev.from = format(dateRange.from, ISO_DATE_FORMAT);
				prev.to = format(dateRange.to, ISO_DATE_FORMAT);
			}
		}
	};

	const updateFilter = useUpdateObject(setFilter, sanitizeFilter);

	const clearItems = () => {
		setItems(null);
	};

	const loadItems = useCallback(
		(page, resolve) => {
			const queryFilters = {};
			const pageSize = BATCH_SIZE;

			if (tab === 1) {
				queryFilters.submitted = true;
			} else if (tab === 2) {
				queryFilters.pending = true;
			} else if (tab === 3) {
				queryFilters.submitted = false;
				queryFilters.rejected = true;
			} else if (tab === 4) {
				queryFilters.added = true;
			} else {
				queryFilters.submitted = false;
			}
			queryFilters.rowFrom = (page - 1) * pageSize + 1;
			queryFilters.rowTo = page * pageSize;
			queryFilters.keyWords = filter.keyWords ? filter.keyWords : undefined;
			queryFilters.from = filter.from ? filter.from : undefined;
			queryFilters.to = filter.to ? filter.to : undefined;
			queryFilters.meetingTypes = filter.meetingTypes ? filter.meetingTypes.join(",") : undefined;

			request
				.get(`${API_HOST}/api/agendaitems`)
				.query(queryFilters)
				.then((res) => {
					const {
						body: { items, total },
					} = res;
					setItems((prev) => ({
						items: page > 1 ? (prev.items || []).concat(items) : items,
						total,
					}));

					resolve();
				})
				.catch((err) => {
					showSignIn(err, () => {
						loadItems(page, resolve);
					});
				});
		},
		[filter, tab],
	);

	const loadMore = ({ startIndex }) =>
		new Promise((resolve) => {
			loadItems(Math.floor(startIndex / BATCH_SIZE) + 1, resolve);
		});

	const removeItemFromList = (id) => {
		setItems((prev) => {
			const newItems = (prev.items || []).filter((item) => item.id !== id && item.guid !== id);

			return {
				items: newItems,
				total: prev.total - (prev.items.length - newItems.length),
			};
		});
	};

	const reloadItems = () => {
		clearItems();
		let option = notifierMessage(t("deleteItemDialog.undo.successful"), "success");
		option.autoHideDuration = 3000;
		dispatch(setSnackbarOptions(option));
	};

	const handleRestoreError = () => {
		let option = notifierMessage(t("deleteItemDialog.undo.error"), "success");
		dispatch(setSnackbarOptions(option));
	};

	const restoreItemToList = (id) => {
		restoreItem(id, reloadItems, handleRestoreError);
	};

	const loadMeetingTypes = () => {
		request
			.get(`${API_HOST}/api/meetingtypes`)
			.query({ adminOnly: true })
			.withCredentials()
			.then((res) => {
				setMeetingTypes(res.body || []);
			})
			.catch((err) => {
				showSignIn(err, () => {
					loadMeetingTypes();
				});
			});
	};

	const tabChange = (_e, tab) => {
		setTab(tab);

		let tabType = "Not submitted";
		switch (tab) {
			case 1:
				tabType = "Submitted";
				break;

			case 2:
				tabType = "Pending";
				break;

			case 3:
				tabType = "Rejected";
				break;

			case 4:
				tabType = "Added";
				break;
		}
		telemetryAddEvent(`Agenda item - Tab - ${tabType}`, { area: "agendaItems" });
	};

	const filterClick = () => {
		setPanels((prev) => ({
			filter: !prev.filter,
		}));
	};

	const closeFilter = () => setPanels({});

	const isFiltered = (filter) => filter.keyWords || filter.from || filter.to || (filter.meetingTypes && filter.meetingTypes.length > 0);

	const filterPanelChange = (newFilter, finished) => {
		if (newFilter) {
			if (!finished) {
				updateFilter({ target: { value: newFilter[DEFAULT_SEARCH_FIELD] } }, DEFAULT_SEARCH_FIELD);
			} else {
				updateFilter({ target: { value: newFilter[DEFAULT_SEARCH_FIELD] } }, "keyWords");
			}
		} else {
			setFilter({ ...defaultFilter });
		}
	};

	const hasFilterChanged = (prev, current) =>
		current &&
		(!prev ||
			current.keyWords !== prev.keyWords ||
			current.from !== prev.from ||
			current.to !== prev.to ||
			!isEqual(current.meetingTypes, prev.meetingTypes));

	useEffect(() => {
		if (hasFilterChanged(lastFilter.current, filter)) {
			lastFilter.current = { ...filter };

			clearItems();
		} else {
		}
	}, [filter]);

	useEffect(() => {
		clearItems();
	}, [tab]);

	useEffect(() => {
		dispatch(resetPageConfigs({ resetBack: true }));
		dispatch(updatePageConfigs({ title: t("agendaItems"), telemetryPage: "Agenda items", contentPaper: { fullHeight: true } }));
		dispatch(
			updatePageHeader({
				primaryAction: () => {
					navigate("/agendaitems/create");

					telemetryAddEvent("Agenda item - Create", { area: "agendaItems" });
				},
				primaryActionText: t("buttons.addItem"),
				primaryActionTooltip: t("tooltips.addItem"),
			}),
		);

		loadMeetingTypes();
	}, []);

	useEffect(() => {
		dispatch(
			updateToolbar({
				display: true,
				right: {
					tools: [
						{
							id: "open-filter",
							icon: "filter",
							tooltipText: t("tooltips.filter"),
							ariaLabel: t("tooltips.filter"),
							onClick: filterClick,
							dataCy: "toggle-filter",
							badgeProps: {
								display: isFiltered(filter),
								dataCy: "filtered",
							},
						},
					],
				},
			}),
		);
	}, [isFiltered(filter)]);

	return (
		<ComponentContainer fullHeight padding="0">
			<AgendaItemList
				items={items}
				loadMore={loadMore}
				removeItemFromList={removeItemFromList}
				restoreItemToList={restoreItemToList}
				tab={tab}
				tabChange={tabChange}
				showSignIn={showSignIn}
				closeSnackbar={closeSnackbar}
			/>
			<RightPanelContainer id="right-panel-container" open={panels.filter} float drawerWidth={MD_DOWN_WIDTH} fullHeight>
				<FilterPanel
					filters={filter}
					onFilterChange={filterPanelChange}
					isFiltered={isFiltered(filter)}
					closeFilter={closeFilter}
					useSearch
				>
					<AgendaItemFilter filter={filter} updateFilter={updateFilter} meetingTypes={meetingTypes}></AgendaItemFilter>
				</FilterPanel>
			</RightPanelContainer>
		</ComponentContainer>
	);
};

export default withErrorHandling(AgendaItemsModule);
