import React from "react";

import ErrorBoundary from "components/ErrorBoundary";
import authentication from "./components/Authentication";

const EmptyLayout = (props) => {
	const { children } = props;

	return <ErrorBoundary>{children}</ErrorBoundary>;
};

export default EmptyLayout;

export const AuthenticatedEmptyLayout = authentication(EmptyLayout);
