import React, { useState, useEffect, useRef, useContext } from "react";
import { useTranslation } from "react-i18next";

import { ListItem, Grid, Typography, Box, Checkbox } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import StyledPhoneInput from "../../../components/PhoneInput/StyledPhoneInput";
import typographyStyles from "atlas/assets/jss/components/typographyStyle";
import clsx from "clsx";
import { Check } from "components/Icons";

const useTypographyStyles = makeStyles(typographyStyles);
const useStyles = makeStyles({
	grow: {
		flexGrow: "1",
	},
	nowrap: {
		flexWrap: "nowrap",
	},
	hidden: {
		visibility: "hidden",
	},
	visible: {
		visibility: "visible",
	},
	listItem: {
		padding: "4px 0",
		borderLeft: "0",
	},
	name: {
		height: "100%",
		display: "flex",
		alignItems: "center",
	},
	checkItem: {
		flexGrow: "1",
		height: "80px",
		display: "flex",
		flexFlow: "column",
		justifyContent: "center",
	},
	nameItem: {
		flexGrow: "1",
		height: "80px",
		display: "flex",
		flexFlow: "column",
	},
	textContainer: {
		height: "84px",
		display: "flex",
		flexFlow: "column",
		flexGrow: "1",
		justifyContent: "center",
	},
	textArea: {
		height: "40px",
		display: "flex",
		flexDirection: "column",
		padding: "0 4px",
		borderRadius: "4px",
	},
	textAreaSuccess: {
		color: "white",
		backgroundColor: "green",
	},
	textAreaWarn: {
		color: "black",
		backgroundColor: "yellow",
	},
	textAreaFail: {
		color: "white",
		backgroundColor: "red",
	},
	textItem: {
		fontSize: "14px",
		display: "flex",
		alignItems: "center",
	},
	textSuccess: {
		color: "white",
	},
	textWarn: {
		color: "black",
	},
	textFail: {
		color: "white",
	},
});

const SmsListCard = (props) => {
	const { user, updateUser, updateSendMessage } = props;
	const { t } = useTranslation("agendaItems");
	const classes = useStyles();
	const typographyClasses = useTypographyStyles();
	const phoneRef = useRef(null);
	const [phoneCountry, setPhoneCountry] = useState(null);
	const [sendMessage, setSendMessage] = useState(false);

	const getTextAreaColor = (user) => {
		if (!user.smsResult || !user.smsResult.validPhoneNumber) {
			return classes.textAreaWarn;
		}
		if (!user.smsResult.smsSuccess) {
			return classes.textAreaFail;
		}
		return classes.textAreaSuccess;
	};

	const getTextColor = (user) => {
		if (!user.smsResult || !user.smsResult.validPhoneNumber) {
			return classes.textWarn;
		}
		if (!user.smsResult.smsSuccess) {
			return classes.textFail;
		}
		return classes.textSuccess;
	};

	const getTextMessage = (user) => {
		if (!user.smsResult || !user.smsResult.validPhoneNumber) {
			return t("sendReminderDialog.results.invalid");
		}
		if (!user.smsResult.smsSuccess) {
			return t("sendReminderDialog.results.failed");
		}
		return t("sendReminderDialog.results.success");
	};

	const getVisibility = (user) => {
		if (user.smsResult && user.smsResult.validPhoneNumber) {
			return classes.hidden;
		} else {
			return classes.visible;
		}
	};

	const handleCheckUpdate = () => {
		const newValue = user.smsResult && user.smsResult.validPhoneNumber ? false : !sendMessage;
		setSendMessage(newValue);
		updateSendMessage(user.id, newValue);
	};

	const handleUpdate = (id, phone) => {
		updateUser(id, phone.target.value, phone.target.formattedValue);
	};

	const checkDisabled = (user) => {
		if (!sendMessage) {
			return true;
		}

		if (user.smsResult && user.smsResult.validPhoneNumber) {
			return true;
		}

		if (user.readonly && !user.smsResult) {
			return true;
		}
		return false;
	};

	useEffect(() => {
		setSendMessage(user.smsResult && user.smsResult.validPhoneNumber ? false : user.sendMessage);
	}, [user]);

	useEffect(() => {
		if (phoneRef !== null && phoneRef.current !== null) {
			setPhoneCountry(phoneRef.current.state.selectedCountry);
		}
	}, [phoneRef, user.phoneNumber]);

	return (
		<ListItem className={classes.listItem} data-cy={`sms-user${user.id}`}>
			<Grid className={classes.nowrap} container spacing={1} direction="row" justifyContent="flex-start" alignItems="flex-start">
				<Grid item className={clsx(classes.checkItem, getVisibility(user))} xs={1}>
					<Checkbox
						checkedIcon={<Check fontSize="small" color="primary" />}
						checked={user.smsResult && user.smsResult.validPhoneNumber ? false : sendMessage}
						onChange={(e) => handleCheckUpdate(e, "active", true)}
						disabled={user.smsResult && user.smsResult.validPhoneNumber ? true : false}
						data-cy={`phone-checkbox-${user.id}`}
					/>
				</Grid>
				<Grid item className={classes.nameItem} xs={5}>
					<Typography variant="h5" color="textSecondary" className={classes.name}>
						{`${user.firstName} ${user.lastName}`}
					</Typography>
				</Grid>
				{(!user.smsResult || (user.smsResult && !user.smsResult.validPhoneNumber)) && (
					<Grid item xs={6}>
						<StyledPhoneInput
							id="phoneNumber"
							ref={phoneRef}
							label=""
							phoneValue={user.phoneNumber}
							onChange={(phone) => handleUpdate(user.id, phone)}
							phoneCountry={phoneCountry}
							dataCy={`phone-number-${user.id}`}
							disabled={checkDisabled(user)}
							validateCountry
							errorMsg={t("userProfile:errors.invalidNumber")}
							invalidNumbers={user.smsResult && !user.smsResult.validPhoneNumber ? [user.smsResult.phoneNumber] : []}
						/>
					</Grid>
				)}
				{user.smsResult && user.smsResult.validPhoneNumber && (
					<Grid item xs={6} className={classes.textContainer}>
						<Box className={clsx(classes.textArea, getTextAreaColor(user))}>
							<Typography
								variant="h5"
								color="textSecondary"
								className={clsx(typographyClasses.fieldLabelLarge, classes.textItem, getTextColor(user), classes.grow)}
								data-cy={`sms-status-${user.id}`}
							>
								{getTextMessage(user)}
							</Typography>
							{user.smsResult.profileUpdated ? (
								<Typography
									variant="h5"
									color="textSecondary"
									className={clsx(typographyClasses.fieldLabelLarge, classes.textItem, getTextColor(user))}
									data-cy={`updated-status-${user.id}`}
								>
									{t("sendReminderDialog.results.profileUpdated")}
								</Typography>
							) : null}
						</Box>
					</Grid>
				)}
			</Grid>
		</ListItem>
	);
};

export default SmsListCard;
