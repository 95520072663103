import React, { Component } from "react";
import { withTranslation } from "react-i18next";

import Typography from "@mui/material/Typography";

import ComponentContainer from "atlas/components/ComponentContainer/ComponentContainer";
import OutlinedInput from "atlas/components/FormControls/OutlinedInput";
import { useWidthUp } from "atlas/utils/useWidth";
import SaveCancelButtons from "./SaveCancelButtons";

const withWidth = () => (WrappedComponent) => (props) => {
	const widthUpSm = useWidthUp("sm");

	return <WrappedComponent {...props} widthUpSm={widthUpSm} />;
};

class NameUpdate extends Component {
	handleKeyPress = (event) => {
		if (event.key === "Enter") {
			const { handleSave, handleBlur } = this.props;
			handleBlur();
			handleSave();
		}
	};

	render() {
		const { t, data, widthUpSm, handleCancel, handleSave, handleChange, handleBlur } = this.props;
		const { editing = {}, errors = {} } = data;

		const content = (
			<>
				<Typography variant="h5" color="textSecondary">
					{t("updateName")}
				</Typography>

				<OutlinedInput
					autoFocus
					className="card-textfield"
					id="firstname"
					label={t("firstName")}
					onChange={handleChange}
					onBlur={() => handleBlur(false, "firstname")}
					onKeyPress={this.handleKeyPress}
					helperText={errors.firstname}
					fullWidth
					value={editing.firstname || ""}
					error={!!errors.firstname}
					data-cy="firstName"
				/>
				<OutlinedInput
					className="card-textfield"
					id="lastname"
					label={t("lastName")}
					onChange={handleChange}
					onBlur={() => handleBlur(false, "lastname")}
					onKeyPress={this.handleKeyPress}
					helperText={errors.lastname}
					fullWidth
					value={editing.lastname || ""}
					error={!!errors.lastname}
					data-cy="lastName"
				/>

				<SaveCancelButtons
					save={handleSave}
					saveEnabled={!errors.first && !errors.lastname}
					disabledTooltip={errors.firstname || errors.lastname ? t("errors.disabledTooltip") : ""}
					cancel={handleCancel}
					saveLabel={t("app:buttons.save")}
					cancelLabel={t("app:buttons.cancel")}
				/>
			</>
		);

		return <ComponentContainer maxWidth={widthUpSm ? "288px" : null}>{content}</ComponentContainer>;
	}
}

export default withWidth()(withTranslation("userProfile")(NameUpdate));
