import React, { Component } from "react";
import { withTranslation } from "react-i18next";

import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Typography from "@mui/material/Typography";

import processHtml from "utils/processHtml";
import ComponentContainer from "atlas/components/ComponentContainer/ComponentContainer";
import { Visibility, VisibilityOff } from "components/Icons";
import OutlinedInput from "atlas/components/FormControls/OutlinedInput";
import { useWidthUp } from "atlas/utils/useWidth";
import SaveCancelButtons from "./SaveCancelButtons";

const withWidth = () => (WrappedComponent) => (props) => {
	const widthUpSm = useWidthUp("sm");

	return <WrappedComponent {...props} widthUpSm={widthUpSm} />;
};

class PasswordUpdate extends Component {
	constructor(props) {
		super(props);
		this.state = { showPassword: false };
	}

	handleClickShowPassword = () => {
		this.setState((state) => ({ showPassword: !state.showPassword }));
	};

	handleKeyPress = (event) => {
		if (event.key === "Enter") {
			const { handleSave, handleBlur } = this.props;
			handleBlur();
			handleSave();
		}
	};

	render() {
		const { t, data, widthUpSm, handleCancel, handleSave, handleBlur, handleChange } = this.props;
		const { editing = {}, errors = {} } = data;

		const content = (
			<form action="/" method="POST">
				<Typography variant="h5" color="textSecondary">
					{t("chooseNewPassword")}
				</Typography>
				<Box mt={2}>
					<Typography variant="body2">{processHtml(t("updatePasswordInstructions"))}</Typography>
				</Box>
				<OutlinedInput
					autoFocus
					id="password"
					label={t("newPassword")}
					value={editing.password || ""}
					type={this.state.showPassword ? "text" : "password"}
					onChange={handleChange}
					onBlur={() => handleBlur(false, "password")}
					onKeyPress={this.handleKeyPress}
					helperText={errors.password}
					fullWidth
					InputProps={{
						endAdornment: (
							<InputAdornment tabIndex="-1" position="end">
								<IconButton tabIndex="-1" onClick={this.handleClickShowPassword} size="large">
									{this.state.showPassword ? <VisibilityOff /> : <Visibility />}
								</IconButton>
							</InputAdornment>
						),
					}}
					error={!!errors.password}
					data-cy="password"
				/>
				<OutlinedInput
					id="confirmPassword"
					onChange={handleChange}
					onBlur={() => handleBlur(false, "confirmPassword")}
					onKeyPress={this.handleKeyPress}
					value={editing.confirmPassword || ""}
					type={this.state.showPassword ? "text" : "password"}
					label={t("newPassword")}
					helperText={errors.confirmPassword}
					fullWidth
					InputProps={{
						endAdornment: (
							<InputAdornment tabIndex="-1" position="end">
								<IconButton tabIndex="-1" onClick={this.handleClickShowPassword} size="large">
									{this.state.showPassword ? <VisibilityOff /> : <Visibility />}
								</IconButton>
							</InputAdornment>
						),
					}}
					error={!!errors.confirmPassword}
					data-cy="confirmPassword"
				/>

				<SaveCancelButtons
					save={handleSave}
					saveEnabled={!errors.password && !errors.confirmPassword}
					disabledTooltip={errors.password || errors.confirmPassword ? t("errors.disabledTooltip") : ""}
					cancel={handleCancel}
					saveLabel={t("app:buttons.save")}
					cancelLabel={t("app:buttons.cancel")}
				/>
			</form>
		);

		return <ComponentContainer maxWidth={widthUpSm ? "288px" : null}>{content}</ComponentContainer>;
	}
}

export default withWidth()(withTranslation("userProfile")(PasswordUpdate));
