/* eslint-disable react/no-array-index-key */
/* eslint-disable no-nested-ternary */
import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation, Trans } from "react-i18next";

import { Box, Link, List, ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material";

import { Close, Error } from "components/Icons";
import { isSharedInSync } from "utils/agendaStatuses";
import { getMeetingOutputText, getMeetingOutputI18nLabel } from "utils/meetingOutputText";
import GenericDialog from "atlas/components/Dialogs/GenericDialog";

const ErrorDialogMeetingPublish = (props) => {
	const {
		show,
		agenda = true,
		meeting: { name, boardAgendaStatus },
		handleClose,
		errors,
		triggeredBy,
		meeting,
		openSupportRequestDialog,
		editorFunctions,
	} = props;
	const { t } = useTranslation("meetings");
	const navigate = useNavigate();
	const location = useLocation();

	const getErrorType = (errors) => {
		if (errors) {
			if (errors.html) return "item";
			if (errors.attachment) return "attachment";
			if (errors.length > 0 || errors.attachments) return "badAttachment";
			if (errors.timeout) return "timeout";
		}

		return "generic";
	};

	const getItemsWithIssues = (errors) => {
		const errorType = getErrorType(errors);
		if (errorType === "item") return errors.html.items;
		if (errorType === "attachment") return [errors.attachment]; // return array, similar to items
		if (errorType === "badAttachment" && errors.attachments) return errors.attachments;
		if (errorType === "badAttachment") return errors;
		return [];
	};

	const handleContactSupportClick = () => {
		openSupportRequestDialog();
	};

	const handleGoToItemClick = (props) => {
		const { meetingId, itemId } = props;

		let inAgendaBuilder = false;
		if (meetingId && itemId) {
			if (location.pathname.includes(`/meeting/detailsV2/${meetingId}`)) {
				inAgendaBuilder = true;
			}

			navigate(`/meeting/detailsV2/${meetingId}?itemGuid=${itemId}`);
			if (inAgendaBuilder) {
				editorFunctions.setActiveItem(itemId, true);
			}
			handleClose();
			return;
		}

		if (meetingId) {
			navigate(`/meeting/detailsV2/${meetingId}`);
			handleClose();
		}
	};

	const errorType = getErrorType(errors);
	const itemsWithIssues = getItemsWithIssues(errors);
	const publishType =
		triggeredBy === "preview"
			? getMeetingOutputI18nLabel("preview", agenda)
			: triggeredBy === "share"
			? getMeetingOutputI18nLabel("shared", agenda)
			: triggeredBy === "badAttachment"
			? "badAttachment"
			: errorType === "timeout"
			? `timeout.${agenda ? "agenda" : "mintues"}`
			: !isSharedInSync(boardAgendaStatus)
			? getMeetingOutputI18nLabel("sharedOrPublished", agenda)
			: getMeetingOutputI18nLabel("published", agenda);
	const i18n = t("publishErrorDialog", { name });
	const dialog = i18n.errorType[errorType];

	return (
		<GenericDialog
			show={show}
			title={t(`publishErrorDialog.title.${publishType}`)}
			primaryTitle={dialog.button}
			primaryAction={handleClose}
			secondaryAction={handleClose}
			closeIcon={<Close />}
		>
			<Typography variant="h4">
				<Box mb={3} fontWeight="fontWeightBold">
					{getMeetingOutputText(dialog.description, agenda)}
				</Box>
			</Typography>
			{["generic", "timeout"].indexOf(errorType) < 0 && (
				<Box mb={2} border={1} borderColor="#e6e6e6">
					<List className="publishing-error">
						{itemsWithIssues &&
							itemsWithIssues.length > 0 &&
							itemsWithIssues.map((item, index) => (
								<ListItem key={index} className="error-list-item">
									<ListItemIcon className="error-icon">
										<Error lcolor="#d22c2c" />
									</ListItemIcon>
									{/* Name (includes bullet and short description for item) attribute for item errors, description for attachment errors */}
									<ListItemText
										className="error-list-item-text"
										primary={errorType === "item" ? item.name || item.html.substr(0, 500) : item.description}
										secondary={
											(errorType === "item" || errorType === "attachment" || errorType === "badAttachment") && (
												<Link
													onClick={() => handleGoToItemClick({ meetingId: meeting.id, itemId: item.itemGuid })}
													className="go-to-item cursor-pointer"
													underline="always"
												>
													Go to item
												</Link>
											)
										}
									/>
								</ListItem>
							))}
					</List>
				</Box>
			)}
			<Typography variant="body1">{dialog.resolution.title}</Typography>
			<ul className="publishing-resolutions">
				{dialog.resolution.suggestions && dialog.resolution.suggestions.map((item, index) => <li key={`suggestion${index}`}>{item}</li>)}
				<li key="contactSupport">
					<Trans i18nKey="publishErrorDialog.issuePersists">
						If the issue persists, please{" "}
						<Link className="cursor-pointer" underline="always" onClick={handleContactSupportClick}>
							contact support
						</Link>
					</Trans>
				</li>
			</ul>
		</GenericDialog>
	);
};

export default ErrorDialogMeetingPublish;
