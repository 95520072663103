import request from "superagent";
import { API_HOST } from "config/env";

export const loadAnnotations = (documentId, originalName, showSignIn) =>
	new Promise((resolve) => {
		request
			.get(`${API_HOST}/api/document/${documentId}/annotations${originalName ? `?originalName=${originalName}` : ""}`)
			.then((res) => {
				if (res && res.status === 200) {
					resolve(JSON.parse(res.text));
				}
			})
			.catch((err) => {
				showSignIn(err, () => {
					resolve(loadAnnotations(documentId, originalName));
				});
			});
	});

export const deleteAnnotation = (documentId, annotationId, userId, showSignIn) =>
	new Promise((resolve) => {
		request
			.del(`${API_HOST}/api/document/${documentId}/annotation`)
			.set("Content-Type", "application/json")
			.send({ annotationId, userId })
			.then((res) => {
				if (res && res.status === 200) {
					resolve();
				}
			})
			.catch((err) => {
				showSignIn(err, () => {
					resolve(deleteAnnotation(documentId, annotationId));
				});
			});
	});

export const saveAnnotation = (paramObject) => {
	const { documentId, documentOriginalName, annotationId, exportedAnnotation, userId, showSignIn } = paramObject;
	return new Promise((resolve) => {
		request
			.post(`${API_HOST}/api/document/${documentId}/annotation`)
			.set("Content-Type", "application/json")
			.send({ documentOriginalName, annotationId, annotation: exportedAnnotation, userId })
			.then((res) => {
				if (res && res.status === 200) {
					resolve();
				}
			})
			.catch((err) => {
				showSignIn(err, () => {
					resolve(saveAnnotation(documentId, documentOriginalName, annotationId, exportedAnnotation));
				});
			});
	});
};

export const deleteAllAnnotations = (documentId, meetingId, showSignIn) =>
	new Promise((resolve) => {
		request
			.del(`${API_HOST}/api/document/${documentId}/allannotations`)
			.set("Content-Type", "application/json")
			.send({ meetingId })
			.then((res) => {
				if (res && res.status === 200) {
					resolve();
				}
			})
			.catch((err) => {
				showSignIn(err, () => {
					resolve(deleteAllAnnotations(documentId));
				});
			});
	});

export const moveAllAnnotations = (documentId, meetingId, showSignIn) =>
	new Promise((resolve) => {
		request
			.post(`${API_HOST}/api/document/${documentId}/moveallannotations`)
			.set("Content-Type", "application/json")
			.send({ meetingId })
			.then((res) => {
				if (res && res.status === 200) {
					resolve();
				}
			})
			.catch((err) => {
				showSignIn(err, () => {
					resolve(moveAllAnnotations(documentId));
				});
			});
	});

export const loadAllAnnotations = (meetingId, showSignIn) =>
	new Promise((resolve) => {
		request
			.get(`${API_HOST}/api/meeting/${meetingId}/annotations`)
			.then((res) => {
				if (res && res.status === 200) {
					resolve(res.body);
				}
			})
			.catch((err) => {
				showSignIn(err, () => {
					throw err;
				});
			});
	});

export const loadSignature = (userId) =>
	new Promise((resolve) => {
		request
			.get(`${API_HOST}/api/user/${userId}/signatures`)
			.withCredentials()
			.then((res) => {
				if (res.status === 200 && res.body) {
					resolve(res.body);
				}
			})
			.catch((err) => {
				console.log(err);
			});
	});

export const saveSignature = (userId, dataURL, annotation) =>
	new Promise((resolve) => {
		request
			.post(`${API_HOST}/api/user/${userId}/signatures`)
			.send({ id: annotation.Id, signature: dataURL })
			.withCredentials()
			.then((res) => {
				if (res.status === 200) {
					resolve(res);
				}
			})
			.catch((err) => {
				console.log(err);
			});
	});

export const deleteSignature = (userId, dataURL) =>
	new Promise((resolve) => {
		request
			.del(`${API_HOST}/api/user/${userId}/signatures`)
			.send({ signature: dataURL })
			.withCredentials()
			.then((res) => {
				if (res.status === 200) {
					resolve(res);
				}
			})
			.catch((err) => {
				console.log(err);
			});
	});

export const uploadMinutes = (settingsMettingId, fileData) =>
	new Promise((resolve, reject) => {
		request
			.post(`${API_HOST}/api/meeting/${settingsMettingId}/uploadminutes`)
			.withCredentials()
			.send(fileData)
			.then((res) => {
				resolve(res);
			})
			.catch((err) => {
				console.log(err);
				reject(err);
			});
	});

export const adoptMinutes = (settingsMettingId, fileData) =>
	new Promise((resolve, reject) => {
		request
			.post(`${API_HOST}/api/meeting/${settingsMettingId}/signdocument`)
			.withCredentials()
			.send(fileData)
			.then((res) => {
				resolve(res);
			})
			.catch((err) => {
				reject(err);
			});
	});

export const loadSignatureAnnotations = (documentId, originalName) =>
	new Promise((resolve) => {
		request
			.get(`${API_HOST}/api/document/${documentId}/allannotations${originalName ? `?originalName=${originalName}` : ""}`)
			.then((res) => {
				if (res && res.status === 200) {
					resolve(JSON.parse(res.text));
				}
			})
			.catch((err) => {
				showSignIn(err, () => {
					resolve(loadAnnotations(documentId, originalName));
				});
			});
	});

export const getAgendaItemsForOutline = (id) =>
	new Promise((resolve, reject) => {
		request
			.get(`${API_HOST}/api/meeting/${id}/getagendaitemsforoutline`)
			.withCredentials()
			.then((response) => response.body)
			.then((json) => {
				resolve(json);
			})
			.catch((exception) => {
				reject("Error getting agenda items", exception);
			});
	});

export const getPolicyCompareList = (data) =>
	new Promise((resolve, reject) => {
		request
			.post(`${API_HOST}/api/policies/PolicyCompare`)
			.withCredentials()
			.send(data)
			.then((response) => response.body)
			.then((json) => {
				resolve(json);
			})
			.catch((exception) => {
				reject(exception);
			});
	});

export const getUrlForPolicyCompare = (selectedPolicy) => {
	const { policyId, policyName, policyGuid } = selectedPolicy;
	let url = `${API_HOST}/filepro/document/${policyId}/${policyName}.docx?handle=${policyGuid}&printPdf=true`;
	return url;
};
