import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import Icon from "atlas/components/Icon/Icon";
import { useTranslation } from "react-i18next";
import processHtml, { stripHtml } from "utils/processHtml";
import { useNavigate } from "react-router-dom";
import { API_HOST } from "config/env";
import Link from "@mui/material/Link";

const useEditorStyles = makeStyles(() => ({
	meetingSectionHeading: {
		marginBottom: "10px",
		fontWeight: "400",
		fontSize: "18px",
		marginTop: "25px",
	},
	meetingIconAndName: {
		display: "flex",
		alignItems: "center",
		gap: "4px",
	},
	meetingIcon: {
		paddingTop: "5px",
	},
	meetingName: {
		fontWeight: "700",
		fontSize: "14px",
		color: " #385F99",
		cursor: "pointer",
	},
	agendaItemTitle: {
		paddingLeft: "24px",
		fontWeight: "400",
		fontSize: "14px",
	},
}));

const GoalMeetingData = (props) => {
	const { goal } = props;
	const editorClasses = useEditorStyles();
	const { t } = useTranslation("goals");
	const navigate = useNavigate();
	const redirectOnMeetingClick = (isAgendaItem, membersViewPermission, id) => {
		if (isAgendaItem) {
			if (membersViewPermission) {
				window.location.href = `${API_HOST}/home/meeting/document/${id}`;
			} else {
				window.open(`${API_HOST}/Portal/MeetingInformation.aspx?Org=Cal&Id=${id}`, "_blank");
			}
		} else {
			if (membersViewPermission) {
				window.location.href = `${API_HOST}/home/meeting/final/${id}/minutes/members`;
			} else {
				window.open(`${API_HOST}/Portal/MeetingInformation.aspx?Org=Cal&Id=${id}&minutes=true`, "_blank");
			}
		}
	};

	return (
		<>
			<div>
				<div className={editorClasses.meetingSectionHeading}>{t("meetingsLinkedToThisGoal")}</div>
				<div>
					{goal.linkedMeetingList.map((meeting) => {
						return (
							<div>
								<div className={editorClasses.meetingIconAndName}>
									<div className={editorClasses.meetingIcon}>
										<Icon name={"goal-attached-to-meeting"} />
									</div>
									<Link
										className={editorClasses.meetingName}
										variant="body2"
										underline="hover"
										onClick={() => {
											redirectOnMeetingClick(meeting.isAgendaItem, meeting.membersViewPermission, meeting.id);
										}}
									>
										{meeting.name}
									</Link>
								</div>
								<div className={editorClasses.agendaItemTitle}>
									{meeting.itemBulletNumber + " " + processHtml(stripHtml(meeting.itemTitle))}
								</div>
							</div>
						);
					})}
				</div>
			</div>
		</>
	);
};

export default GoalMeetingData;
