import React from "react";

import makeStyles from "@mui/styles/makeStyles";

import DocumentTreeNode from "./DocumentTreeNode";

const useStyles = makeStyles((theme) => ({
	tree: {
		margin: "0",
		padding: "0",
		listStyleType: "none",
		"& > li": {
			width: "440px",
			maxWidth: "100%",
		},
	},
}));

const DocumentTree = (props) => {
	const { documents, foldersOnly, canSelect, selection, handleLoadChildren, setSelectedData } = props;
	const classes = useStyles();

	return documents ? (
		<ol className={classes.tree}>
			{documents.map((document) => (
				<DocumentTreeNode
					key={document.guid}
					document={document}
					foldersOnly={foldersOnly}
					canSelect={canSelect}
					selection={selection}
					handleLoadChildren={handleLoadChildren}
					setSelectedData={setSelectedData}
				/>
			))}
		</ol>
	) : null;
};

export default DocumentTree;
