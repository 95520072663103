import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import request from "superagent";
import clsx from "clsx";

import { Box, Typography, Grid, TableCell } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { API_HOST } from "config/env";
import CircularProgressIndicator from "atlas/components/Progress/CircularProgressIndicator";
import TableData from "atlas/components/TableData/TableData";
import { blackColor } from "atlas/assets/jss/shared";
import entityListStyle from "assets/jss/components/entityListStyle";
import WorkflowDeleteDialog from "./WorkflowDeleteDialog";
import notifierMessage from "utils/notifierMessage";
import { setSnackbarOptions } from "redux/snackBar/actions";

const useStyles = makeStyles(entityListStyle);

const WorkflowList = (props) => {
	const { workflows, loadWorkflows, showSignIn } = props;
	const { t } = useTranslation("workflows");
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [dialogs, setDialogs] = useState({});
	const [workflowToDelete, setWorkflowToDelete] = useState(null);
	const classes = useStyles();

	const closeDeleteDialog = () => {
		setDialogs({});
		setWorkflowToDelete(null);
	};

	const afterDelete = () => {
		loadWorkflows();
	};

	const undoDelete = (deletedWorkflow) => {
		request
			.post(`${API_HOST}/api/workflow/${deletedWorkflow.id}/restore`)
			.withCredentials()
			.send({})
			.then(() => {
				let option = notifierMessage(t("deleteUserDialog.snackbar.success", "success"));
				dispatch(setSnackbarOptions(option));

				loadWorkflows();
			})
			.catch((err) => {
				showSignIn(err, () => {
					undoDelete(deletedWorkflow);
				});
			});
	};

	const workflowTableHeadings = [
		{
			id: "name",
			disablePadding: false,
			label: t("list.header.name"),
			retainCase: true,
			rowColor: blackColor[1],
			component: (props) => {
				const { row } = props;

				return (
					<TableCell className={classes.cell}>
						<Grid container spacing={2}>
							<Grid container item xs={12}>
								<div className={classes.detail}>
									<Typography variant="body1">
										<a
											className={clsx("noStyle", classes.link)}
											href={`/home/workflows/edit/${row.id}`}
											onClick={(e) => {
												navigate(`/workflows/edit/${row.id}`);

												e.preventDefault();
											}}
										>
											{row.name}
										</a>
									</Typography>
								</div>
							</Grid>
						</Grid>
					</TableCell>
				);
			},
		},
	];

	return workflows ? (
		workflows.length > 0 ? (
			<>
				<TableData
					showToolbar={false}
					headCells={workflowTableHeadings}
					rows={workflows}
					selectProperty="id"
					defaultOrderBy="name"
					rowClickAction={() => {}}
					withoutMultiSelect={true}
					overrideClasses={classes}
					iconName="remove"
					data-cy="workflow-list"
				/>
				{dialogs.delete && workflowToDelete && (
					<WorkflowDeleteDialog
						workflow={workflowToDelete}
						show={dialogs.delete}
						afterDelete={afterDelete}
						onClose={closeDeleteDialog}
						undoDelete={undoDelete}
					/>
				)}
			</>
		) : (
			<div className={classes.noResults}>
				<Box>
					<Typography variant="h3">{t("list.noResults.title")}</Typography>
				</Box>
				<Box mt={3}>
					<Typography variant="body2">{t("list.noResults.description")}</Typography>
				</Box>
			</div>
		)
	) : (
		<CircularProgressIndicator />
	);
};

export default WorkflowList;
