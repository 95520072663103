/* eslint-disable camelcase */
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

// en-us
import app_en_us from "./en-us/app-en";
import account_en_us from "./en-us/account";
import boards_en_us from "./en-us/boards";
import documents_en_us from "./en-us/documents";
import editor_en_us from "./en-us/editor";
import profile_en_us from "./en-us/userProfile";
import meeting_en_us from "./en-us/meetings";
import agendaMenu_en_us from "./en-us/agendaMenu";
import goals_en_us from "./en-us/goals";
import agendaItems_en_us from "./en-us/agendaItems";
import requestToSpeak_en_us from "./en-us/requestToSpeak";
import settings_en_us from "./en-us/settings";
import generalSettings_en_us from "./en-us/generalSettings";
import users_en_us from "./en-us/users";
import workflows_en_us from "./en-us/workflows";
import subscribers_en_us from "./en-us/subscribers";
import snackbarButton_en_us from "./en-us/snackbarButton";
import meeting_menu_en_us from "./en-us/meetingMenu";

// en-ca
import app_en_ca from "./en-ca/app-en";
import account_en_ca from "./en-ca/account";
import boards_en_ca from "./en-ca/boards";
import documents_en_ca from "./en-ca/documents";
import editor_en_ca from "./en-ca/editor";
import profile_en_ca from "./en-ca/userProfile";
import meeting_en_ca from "./en-ca/meetings";
import agendaMenu_en_ca from "./en-ca/agendaMenu";
import goals_en_ca from "./en-ca/goals";
import agendaItems_en_ca from "./en-ca/agendaItems";
import requestToSpeak_en_ca from "./en-ca/requestToSpeak";
import settings_en_ca from "./en-ca/settings";
import generalSettings_en_ca from "./en-ca/generalSettings";
import users_en_ca from "./en-ca/users";
import workflows_en_ca from "./en-ca/workflows";
import subscribers_en_ca from "./en-ca/subscribers";
import snackbarButton_en_ca from "./en-ca/snackbarButton";
import meeting_menu_en_ca from "./en-ca/meetingMenu";

// fr-ca
import app_fr from "./fr-ca/app-fr";
import account_fr from "./fr-ca/account";
import boards_fr from "./fr-ca/boards";
import documents_fr from "./fr-ca/documents";
import editor_fr from "./fr-ca/editor";
import profile_fr from "./fr-ca/userProfile";
import meeting_fr from "./fr-ca/meetings";
import agendaMenu_fr from "./fr-ca/agendaMenu";
import requestToSpeak_fr from "./fr-ca/requestToSpeak";
import settings_fr from "./fr-ca/settings";
import generalSettings_fr from "./fr-ca/generalSettings";
import users_fr from "./fr-ca/users";
import workflows_fr from "./fr-ca/workflows";
import subscribers_fr from "./fr-ca/subscribers";
import snackbarButton_fr from "./fr-ca/snackbarButton";
import meeting_menu_fr from "./fr-ca/meetingMenu";

i18n
	.use(initReactI18next)
	.use(LanguageDetector)
	.init({
		fallbackLng: {
			en: ["en-US"],
			"en-US": ["en-CA"],
			"en-CA": ["en-US"],
			"fr-CA": ["en-CA"],
			default: ["en-US"],
		},
		resources: {
			"en-US": {
				app: app_en_us,
				account: account_en_us,
				boards: boards_en_us,
				documents: documents_en_us,
				userProfile: profile_en_us,
				meetings: meeting_en_us,
				editor: editor_en_us,
				agendaMenu: agendaMenu_en_us,
				goals: goals_en_us,
				agendaItems: agendaItems_en_us,
				requestToSpeak: requestToSpeak_en_us,
				settings: settings_en_us,
				generalSettings: generalSettings_en_us,
				users: users_en_us,
				workflows: workflows_en_us,
				subscribers: subscribers_en_us,
				snackbarButton: snackbarButton_en_us,
				meetingMenu: meeting_menu_en_us,
			},
			"en-CA": {
				app: app_en_ca,
				account: account_en_ca,
				boards: boards_en_ca,
				documents: documents_en_ca,
				userProfile: profile_en_ca,
				meetings: meeting_en_ca,
				editor: editor_en_ca,
				agendaMenu: agendaMenu_en_ca,
				goals: goals_en_ca,
				agendaItems: agendaItems_en_ca,
				requestToSpeak: requestToSpeak_en_ca,
				settings: settings_en_ca,
				generalSettings: generalSettings_en_ca,
				users: users_en_ca,
				workflows: workflows_en_ca,
				subscribers: subscribers_en_ca,
				snackbarButton: snackbarButton_en_ca,
				meetingMenu: meeting_menu_en_ca,
			},
			// we do not support FR in release 1 so this removes the possibility to see french if your browser detects it
			// "fr-CA": {
			// 	app: app_fr,
			// 	account: account_fr,
			//  boards: boards_fr,
			//  documents: documents_fr,
			// 	userProfile: profile_fr,
			// 	meetings: meeting_fr,
			// 	editor: editor_fr,
			// 	agendaMenu: agendaMenu_fr,
			//	settings: settings_fr,
			//	generalSettings: generalSettings_fr,
			//	users: users_fr,
			//	workflows: workflows_fr,
			// subscribers: subscribers_fr,
			// snackbarButton: snackbarButton_fr,
			// },
		},

		debug: true,
		ns: ["app"],
		defaultNS: "app",
		interpolation: {
			escapeValue: false,
		},
		react: {
			useSuspense: true,
		},
		returnObjects: true,
	});

export default i18n;
