import { warningColor, successColor, primaryColor, errorColor, grayColor, whiteColor, blackColor } from '../shared';

const statusIndicatorStyle = () => ({
	label: {
		whiteSpace: 'nowrap',
		fontSize: '14px !important',
		fontWeight: 'bold !important',
		textTransform: 'uppercase',
		borderRadius: '4px',
		padding: '4px 8px 4px 8px',
		letterSpacing: '0.7px !important',
		verticalAlign: 'middle',
		display: 'inline-block',
		color: whiteColor,
	},
	strikeText: {
		textDecoration: 'line-through',
		textDecorationColor: blackColor[0],
	},
	warning: {
		backgroundColor: warningColor,
	},
	success: {
		backgroundColor: successColor,
	},
	notification: {
		backgrouncColor: primaryColor[1],
	},
	error: {
		backgroundColor: errorColor,
	},
	disabled: {
		backgroundColor: grayColor[0],
	},
	draft: {
		backgroundColor: warningColor,
		color: blackColor[0],
	},
});

export default statusIndicatorStyle;
