import React, { useState, useEffect, useRef, useContext } from "react";

import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import clsx from "clsx";

import { Box, IconButton, MenuItem, Tooltip, Typography } from "@mui/material";
import InputLabel from "atlas/components/FormControls/InputLabel";
import OutlinedInput from "atlas/components/FormControls/OutlinedInput";
import Spinner from "atlas/components/Spinner/Spinner";
import SingletonEditor from "components/Editor/SingletonEditor";

import { updatePageHeader } from "redux/pageHeader/actions";

import makeStyles from "@mui/styles/makeStyles";

import SelectInput from "atlas/components/FormControls/SelectInput";
import inputStyle from "atlas/assets/jss/components/inputStyle";

import AddPolicyToAgendaDialog from "components/Dialogs/AddPolicyToAgendaDialog";
import ErrorGoalDialog from "components/Dialogs/ErrorGoalDialog";
import { SettingsContext } from "contexts/Settings/SettingsContext";

import StyledSwitch from "atlas/components/FormControls/StyledSwitch";

import GoalMeetingData from "./GoalMeetingData";
import Icon from "atlas/components/Icon/Icon";
import telemetryAddEvent from "utils/telemetryAddEvent";

const useEditorStyles = makeStyles(() => ({
	container: {
		maxWidth: "",
	},
	editorContainer: {
		border: "1px solid #c4c4c4",
		padding: "8px",
	},
	editorField: {
		marginTop: "16px",
	},
	editorFieldLabel: {},
	editorFieldContainer: {
		display: "flex",
		flexDirection: "row",
		flexWrap: "nowrap",
		alignItems: "center",
		margin: "8px 0",
		padding: "8px",
	},
	editorFieldContent: {
		position: "relative",
		border: "solid 1px #c4c4c4 !important",
		flexGrow: "1",
		"& td": {
			wordBreak: "break-word",
		},
	},
	editorFieldHide: {
		display: "none",
	},
	stickyToolbar: {
		position: "-webkit-sticky",
		position: "sticky",
		top: 0,
		zIndex: 150,
	},
	progress: {
		width: "125px !important",
	},
	labelWithOtherContent: {
		display: "flex",
		alignItems: "center",
	},
	internalOnly: {
		display: "flex",
		alignItems: "center",
	},
	onlyInternal: {
		color: "#1E1E1E",
		fontWeight: "600",
		fontSize: "16px",
		lineHeight: "20px",
	},
	onlyInternalDisabled: {
		color: "#B3B3B3",
		fontWeight: "600",
		fontSize: "16px",
		lineHeight: "20px",
	},
	icon: {
		position: "absolute",
		left: "0",
		right: "0",
		top: "0",
		bottom: "0",
	},
	autolayout: {
		flex: "none",
		order: "2",
		flexGrow: "0",
	},
}));
const useInputStyles = makeStyles(inputStyle);

const Goal = (props) => {
	const {
		goal,
		updateGoal,
		queueFileUploads,
		invalidFileExtension,
		showErrorGoalDialog,
		handleCloseErrorGoalDialog,
		isForPublish,
		failedUploads,
		preload: { staticToolbar = false } = {},
		preload,
		labelSize,
		labelEllipsis,
		labelLines,
		showSignIn,
		updatePageHeaderButton,
	} = props;
	const { t } = useTranslation("goals");
	const editorToolbar = useRef(null);
	const editorClasses = useEditorStyles({ preload });
	const [errors, setErrors] = useState({ name: null, completionPercentage: null });
	const [editorFields, _setEditorFields] = useState([]);
	const [editorInitializing, setEditorInitializing] = useState(true);
	const [dialogs, setDialogs] = useState({});
	const { policyEnabled } = useContext(SettingsContext);
	const dispatch = useDispatch();

	const editorFieldsRef = useRef(editorFields);
	const setEditorFields = (data) => {
		editorFieldsRef.current = data;
		_setEditorFields(data);
	};

	const elementsRef = useRef([]);
	const inputClasses = useInputStyles({ fullWidth: true });

	let changeField = null;
	let changeFieldValue = null;
	const progressPercentage = [-1, 0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100];

	const validateForm = () => {
		let valid = true;
		if (!goal.name) {
			errors.name = t("errors.requiredName");
			valid = false;
		}

		setErrors(errors);

		return valid;
	};

	const handleChange = (e, field) => {
		const { target } = e;
		changeField = field || target.id || target.name;
		changeFieldValue = target.value;

		goal[changeField] = changeFieldValue;
		errors[changeField] = null;

		setErrors(errors);
		dispatch(
			updatePageHeader({
				primaryActionDisabled: true,
			}),
		);
		validateForm();
		updateGoal(changeFieldValue, changeField);
	};

	const handleNameBlur = (e) => {
		const { target } = e;
		const field = target.id || target.name;
		validateForm(field);
	};

	const handleFieldChange = (editor) => {
		const fieldData = editor.getFieldData();
		fieldData.forEach((field) => {
			if (goal[field.fieldName] === field.fieldData) return;
			dispatch(
				updatePageHeader({
					primaryActionDisabled: true,
				}),
			);
			updateGoal(field.fieldData, field.fieldName);
		});
		editor.ui.update();
	};

	const handleEditorInitialized = (editor) => {
		setEditorInitializing(false);
	};

	const handleFocusChange = (fieldName, fieldElement, editor) => {};

	const openAddPolicy = (options) => {
		setDialogs({
			addPolicy: options,
		});
	};

	const closeDialogs = () => {
		setDialogs({});
	};

	const handleToggleChange = (checked) => {
		let newGoal = { ...goal };
		newGoal.closed = checked;
		updateGoal(checked, "closed");
		if (checked) {
			telemetryAddEvent("Internal Only set");
		}
	};

	useEffect(() => {
		setEditorFields([
			{
				guid: goal.guid,
				name: "description",
				content: goal.description,
				label: "Description",
				toolbar: "goals",
				placeholder: "Enter description",
				deleted: false,
			},
			{
				guid: goal.guid,
				name: "outcomes",
				content: goal.outcomes,
				label: "Outcomes",
				toolbar: "goals",
				placeholder: "Enter outcomes",
				deleted: false,
			},
			{
				guid: goal.guid,
				name: "actions",
				content: goal.actions,
				label: "Actions",
				toolbar: "goals",
				placeholder: "Enter actions",
				deleted: false,
			},
		]);
	}, [goal]);

	return (
		<>
			{showErrorGoalDialog && (
				<ErrorGoalDialog
					show={showErrorGoalDialog}
					handleClose={handleCloseErrorGoalDialog}
					isForPublish={isForPublish}
					failedUploads={failedUploads}
				/>
			)}
			{dialogs.addPolicy && <AddPolicyToAgendaDialog options={dialogs.addPolicy} onClose={closeDialogs} showSignIn={showSignIn} />}
			<div className={editorClasses.container}>
				<div>
					<OutlinedInput
						autoFocus
						noDefaultClassName
						id="name"
						label={t("goalName")}
						value={goal.name || ""}
						helperText={errors.name}
						onChange={handleChange}
						onBlur={handleNameBlur}
						fullWidth
						size="small"
						error={!!errors.name}
						data-cy="goalName"
					/>
				</div>

				<div className={editorClasses.internalOnly}>
					<Typography className={goal.enableInternalOnly ? editorClasses.onlyInternal : editorClasses.onlyInternalDisabled}>
						{t("InternalOnly")}
					</Typography>
					<div className={editorClasses.labelWithOtherContent}>
						<StyledSwitch
							id="goal-switch"
							inputClasses={{
								label: inputClasses.switchLabel,
								stateLabel: inputClasses.switchInstructions,
							}}
							disabled={!goal.enableInternalOnly}
							showLabel={false}
							inline
							title={t("tooltips.internalOrPublicToggle")}
							size="small"
							objectToUpdate={goal}
							fieldToUpdate="closed"
							onChange={(checked) => {
								handleToggleChange(checked);
								updatePageHeaderButton(true, goal.closed);
							}}
							data-cy="internal-goal-enabled"
						/>
					</div>
					<div>
						<Tooltip
							arrow
							title={<Typography className={editorClasses.autolayout}>{t("tooltips.goalAgendaDescription")}</Typography>}
							placement="top"
						>
							<IconButton>
								<Icon className={editorClasses.icon} name={"info-icon"} />
							</IconButton>
						</Tooltip>
					</div>
				</div>
				<div>
					<SelectInput
						id="completionPercentage"
						className={clsx(inputClasses.smallInput, editorClasses.progress)}
						noDefaultClassName
						label={t("progress")}
						size="small"
						value={goal.completionPercentage}
						onChange={(e) => handleChange(e, "completionPercentage")}
						helperText={errors.completionPercentage ? errors.completionPercentage : ""}
						error={!!errors.completionPercentage}
						postText="/ 100"
						data-cy="goalCompletionPercentage"
					>
						{progressPercentage.map((progress) => (
							<MenuItem key={`progress-${progress}`} value={progress} data-cy={`progress${progress}`}>
								{progress >= 0 ? progress : t("options.empty")}
							</MenuItem>
						))}
					</SelectInput>
				</div>
				<Box className={clsx(editorClasses.editorContainer, "genericEditor")}>
					{editorInitializing && <Spinner />}
					<Box className={editorInitializing ? editorClasses.editorFieldHide : null}>
						<Box className={editorClasses.stickyToolbar} id="toolbar" ref={editorToolbar}></Box>
						{editorFields.map((field, idx) => (
							<Box
								className={field.deleted ? clsx(editorClasses.editorField, editorClasses.editorFieldHide) : editorClasses.editorField}
								key={field.name}
							>
								<Box className={editorClasses.editorFieldLabel}>
									<InputLabel
										htmlFor={field.name}
										label={field.label}
										size={labelSize}
										useEllipsis={labelEllipsis}
										lines={labelLines}
										bottomSpacing
									/>
								</Box>
								<Box className={editorClasses.editorFieldContainer}>
									<Box
										className={editorClasses.editorFieldContent}
										data-fieldname={field.name}
										ref={(el) => (elementsRef.current[idx] = el)}
									></Box>
								</Box>
							</Box>
						))}
					</Box>
				</Box>
				<Box>
					{editorFields.length > 0 && (
						<SingletonEditor
							itemGuid={goal.guid}
							fields={editorFields}
							fieldsRefs={elementsRef}
							editorToolbarRef={editorToolbar}
							queueFileUploads={queueFileUploads}
							addPolicy={policyEnabled ? openAddPolicy : undefined}
							invalidFileExtension={invalidFileExtension}
							onFieldChange={handleFieldChange}
							onEditorInitialized={handleEditorInitialized}
							onFocusChange={handleFocusChange}
						/>
					)}
				</Box>

				{goal && goal.linkedMeetingList && goal.linkedMeetingList.length > 0 && <GoalMeetingData goal={goal} />}
			</div>
		</>
	);
};

export default Goal;
