import React from "react";

import makeStyles from "@mui/styles/makeStyles";
import { MenuItem } from "@mui/material";

import selectInputOptionStyle from "atlas/assets/jss/components/selectInputOptionStyle";
import CircularProgressIndicator from "atlas/components/Progress/CircularProgressIndicator";

const useStyles = makeStyles(selectInputOptionStyle);

export const useSelectInputOptionStyles = (props) => useStyles(props);

export const getNoOptionsMenuItem = (t) => (
	<MenuItem key="no-item-option" value={0}>
		{t("app:options.none")}
	</MenuItem>
);

export const getDropdownProgressIndicator = (name) => (
	<MenuItem key={name || "loading"} value={0} data-cy={name}>
		<CircularProgressIndicator minHeight="0px" size={24} padding="8px" />
	</MenuItem>
);

export const getMenuItem = (value, children, dataCy, classes) => (
	<MenuItem key={value} className={classes.menuItem} value={value} data-cy={dataCy}>
		<div className={classes.menuItemText}>{children}</div>
	</MenuItem>
);
