import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import request from "superagent";

import makeStyles from "@mui/styles/makeStyles";
import { Box } from "@mui/material";

import Icon from "atlas/components/Icon/Icon";
import GenericDialog from "atlas/components/Dialogs/GenericDialog";
import { setSnackbarOptions } from "redux/snackBar/actions";
import { publishSectionName } from "../../../redux/policyGovernance/actions";
import { displaySuccessNotification } from "../utils/getNotification";

const useStyles = makeStyles(() => ({
	dialog: {
		"& .MuiDialog-paper": {
			width: "400px",
			maxWidth: "100%",
		},
	},
}));

const PublishSectionNameDialog = (props) => {
	const { show = true, section, onClose } = props;
	const { t } = useTranslation("documents");
	const dispatch = useDispatch();
	const [publishing, setPublishing] = useState(false);
	const classes = useStyles();

	const handlePublish = () => {
		setPublishing(true);
		dispatch(publishSectionName(section.guid))
			.then((res) => {
				setPublishing(false);
				onClose();
				let appendedTitle = (res.body.code !== null ? res.body.code + " " : "") + res.body.title;
				displaySuccessNotification(t("policy.successMsg.publishSection", { title: appendedTitle }), dispatch);
			})
			.catch((err) => handleError(err));
		onClose();
	};

	const handleCancel = () => {
		onClose();
	};

	const handleError = (err) => {
		console.log("Error", err);
		onClose();
	};

	const i18n = t("publishSectionNameDialog");

	const dialog = {
		title: i18n.title,
		line1: i18n.line1,
		primaryTitle: i18n.buttons.publish,
		primaryAction: handlePublish,
		secondaryTitle: t("app:buttons.cancel"),
		secondaryAction: handleCancel,
	};

	return (
		<GenericDialog
			className={classes.dialog}
			show={show}
			title={dialog.title}
			primaryAction={dialog.primaryAction}
			primaryTitle={dialog.primaryTitle}
			primaryDisabled={publishing}
			secondaryAction={dialog.secondaryAction}
			secondaryTitle={dialog.secondaryTitle}
			secondaryDisabled={publishing}
			clickAwayDisabled={publishing}
			closeIcon={<Icon name="close" />}
			data-cy="publish-section-name-dialog"
		>
			<Box fontWeight="fontWeightBold">{dialog.line1}</Box>
		</GenericDialog>
	);
};

export default PublishSectionNameDialog;
