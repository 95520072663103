import React, { Component } from "react";
import { withTranslation } from "react-i18next";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

class MeetingListEmpty extends Component {
	constructor(props) {
		super(props);

		this.editFilterClick = this.editFilterClick.bind(this);
		this.removeFilterClick = this.removeFilterClick.bind(this);
	}

	editFilterClick(e) {
		const { openFilterClick } = this.props;

		openFilterClick();

		e.preventDefault();
	}

	removeFilterClick() {
		const { clearFilterClick } = this.props;

		clearFilterClick();
	}

	render() {
		const { t } = this.props;

		return (
			<Box id="noMatchesFound" data-cy="noMeetingsFound">
				<Box mt={4}>
					<Typography variant="h3">{t("notifications.noMatchesFound")}</Typography>
				</Box>
				<Box mt={3}>
					<Typography variant="body2">{t("notifications.noMeetingsMatchFilters")}</Typography>
				</Box>
			</Box>
		);
	}
}

export default withTranslation("meetings")(MeetingListEmpty);
