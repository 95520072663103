import React, { Component } from "react";
import { connect } from "react-redux";
import request from "superagent";
import { withTranslation } from "react-i18next";
import queryString from "query-string";
import { withRouter } from "utils/router";

import throttle from "lodash/fp/throttle";
import { format } from "date-fns";
import isEqual from "lodash/fp/isEqual";

import ComponentContainer from "atlas/components/ComponentContainer/ComponentContainer";
import { useWidthDown } from "atlas/utils/useWidth";
import withErrorHandling from "components/ErrorHOC";
import RightPanelContainer, { MD_DOWN_WIDTH } from "components/Panels/RightPanelContainer";
import FilterPanel from "components/Panels/FilterPanel";
import loadSettings from "utils/loadSettings";
import getDateRange from "utils/customDateRange";
import numberCompare from "utils/numberCompare";
import { API_HOST } from "config/env";
import telemetryAddEvent from "utils/telemetryAddEvent";
import { SettingsContext } from "contexts/Settings/SettingsContext";
import MeetingList from "./components/MeetingList";
import MeetingFilter from "./components/MeetingFilter";
import notifierMessage from "utils/notifierMessage";
import { mapStateToProps } from "../../redux/app/mapStateToProps";
import { resetPageConfigs, updatePageConfigs, updateToolbar } from "redux/app/actions";
import { updatePageHeader } from "redux/pageHeader/actions";
import { setSnackbarOptions } from "redux/snackBar/actions";

const withWidth = () => (WrappedComponent) => (props) => {
	const widthDownMd = useWidthDown("md");

	return <WrappedComponent {...props} widthDownMd={widthDownMd} />;
};

const defaultFilter = {
	meetingName: "",
	boardAgendaStatuses: [],
	publicAgendaStatuses: [],
	boardMinutesStatuses: [],
	publicMinutesStatuses: [],
	customDateRange: 0,
	from: "",
	to: "",
	meetingTypes: [],
	order: "",
};
const initializeFilter = (filter, meetingTypeId) => {
	const active = filter || { ...defaultFilter };

	if (meetingTypeId > 0) {
		active.meetingTypes.push(parseInt(meetingTypeId, 10));
	}

	return {
		active,
		stack: [active],
		index: 0,
	};
};

class MeetingsModule extends Component {
	constructor(props) {
		super(props);

		const { location } = props;
		const { tab = 0, meetingType } = queryString.parse(location.search) || {};

		this.state = {
			filter: initializeFilter(),
			tab: parseInt(tab, 10),
			meetingTypeId: parseInt(meetingType, 10),
			showFilter: false,
			loading: false,
			meetingFetchHappened: false,
		};

		this.loadMeetings = this.loadMeetings.bind(this);
		this.onFilterChange = this.onFilterChange.bind(this);
		this.tabChange = this.tabChange.bind(this);
		this.filterClick = this.filterClick.bind(this);
		this.openFilterClick = this.openFilterClick.bind(this);
		this.closeFilterClick = this.closeFilterClick.bind(this);
		this.clearFilterClick = this.clearFilterClick.bind(this);
		this.filterClickAway = this.filterClickAway.bind(this);
		this.moreClick = this.moreClick.bind(this);
		this.isFiltered = this.isFiltered.bind(this);
		this.filterNeedsReloadFromApi = this.filterNeedsReloadFromApi.bind(this);
		this.getMeetings = this.getMeetings.bind(this);
		this.undoClick = this.undoClick.bind(this);
		this.removeMeetingFromList = this.removeMeetingFromList.bind(this);
		this.loadMeetingsThrottled = throttle(500, this.loadMeetings, { leading: false });
	}

	componentDidMount() {
		const { t, location, dispatch, showSignIn, boardAdmin } = this.props;
		const { meetingTypeId } = this.state;

		dispatch(resetPageConfigs({ resetBack: true }));
		dispatch(
			updatePageConfigs({
				title: t(boardAdmin ? "allMeetings" : "meetings"),
				telemetryPage: "Meetings",
				preferedBack: { url: boardAdmin ? "/meetings" : "/" },
			}),
		);

		loadSettings(
			"meetings",
			location,
			(res) => {
				const { filter } = res.body;

				this.setState(
					{
						settings: res.body,
						filter: initializeFilter(filter, meetingTypeId),
					},
					() => {
						this.loadMeetings(filter);
						dispatch(updateToolbar({ badgeUpdates: [{ id: "openFilter", display: this.isFiltered() }] }));
					},
				);
			},
			showSignIn,
		);
	}

	componentDidUpdate() {
		const { t, dispatch, boardAdmin, appReducer } = this.props;
		if (appReducer.title.length === 0) {
			dispatch(
				updatePageConfigs({
					title: t(boardAdmin ? "allMeetings" : "meetings"),
					telemetryPage: "Meetings",
					preferedBack: { url: boardAdmin ? "/meetings" : "/" },
				}),
			);
		}

		dispatch(
			updatePageHeader({
				primaryAction: boardAdmin
					? () => {
							telemetryAddEvent(`All meetings - Add meeting`);

							this.props.navigate("/meeting/create");
						}
					: undefined,
				primaryActionText: boardAdmin ? t("buttons.addMeeting") : undefined,
				primaryActionTooltip: boardAdmin ? t("tooltips.createMeeting") : undefined,
			}),
		);
	}

	onFilterChange(newActive, push, pop, clear) {
		const { t, dispatch, widthDownMd } = this.props;
		const { filter: prev } = this.state;

		let newFilter;
		if (clear || (pop && prev.index <= 1) || (!pop && !this.isFiltered(newActive))) {
			// Clear the filter and undo history
			newFilter = initializeFilter();
		} else if (pop) {
			// Undo the last filter change
			newFilter = {
				active: prev.stack[prev.index - 1],
				stack: prev.stack,
				index: prev.index - 1,
			};
		} else {
			// Update the filter and optionally add a new undo point
			const newStack = prev.stack.slice(0, prev.index + 1); // Clear forward undo points
			if (push) {
				newStack.push(newActive);
			}
			newFilter = {
				active: newActive,
				stack: newStack,
				index: prev.index + (push ? 1 : 0),
			};
		}

		telemetryAddEvent("All meetings - Change filter");

		if (this.filterNeedsReloadFromApi(newFilter.active, prev.active)) {
			this.loadMeetingsThrottled(newFilter.active);
		}

		if (this.isFiltered(newFilter.active) && newFilter.index > prev.index && !widthDownMd) {
			let option = notifierMessage(t("notifications.meetingFilterApplied"), "success", () => this.undoClick());
			option.autoHideDuration = 5000;
			dispatch(setSnackbarOptions(option));
		}

		this.setState(
			{
				filter: newFilter,
			},
			() => {
				dispatch(updateToolbar({ badgeUpdates: [{ id: "openFilter", display: this.isFiltered() }] }));
			},
		);
	}

	getMeetings() {
		const {
			meetings = [],
			filter: { active },
		} = this.state;
		const meetingName = (active.meetingName || "").toLowerCase();

		return meetingName.length > 0 ? meetings.filter((meeting) => meeting.cleanName.toLowerCase().indexOf(meetingName) >= 0) : meetings;
	}

	removeMeetingFromList(id) {
		const { meetings } = this.state;
		this.setState({
			meetings: meetings.filter((meeting) => meeting.id !== id),
		});
	}

	tabChange(_e, tab) {
		const { dispatch, boardAdmin, t } = this.props;
		const {
			filter: { active },
		} = this.state;

		telemetryAddEvent(`All meetings - View ${tab}`);

		this.setState(
			{
				tab,
			},
			() => {
				telemetryAddEvent("meetingListTabChange", {
					tab,
				});
				this.loadMeetings(active);
			},
		);

		dispatch(
			updatePageConfigs({
				title: t(boardAdmin ? "allMeetings" : "meetings"),
				preferedBack: { url: `${boardAdmin ? "/meetings" : "/"}?tab=${tab}` },
				telemetryPage: "Meetings",
			}),
		);
	}

	filterClickAway(e) {
		const { widthDownMd } = this.props;
		const isMobile = widthDownMd;

		if (
			isMobile &&
			!e.target.className.includes("MuiListItem-root") &&
			!e.target.className.includes("custom-date-menu-item") &&
			!e.target.parentElement.className.includes("MuiPickersDay-day") &&
			e.target.closest(".date-popover") == null
		) {
			this.setState({
				showFilter: false,
			});
		}
	}

	filterClick() {
		const { showFilter } = this.state;

		this.setState(
			{
				showFilter: !showFilter,
			},
			() => {
				if (!showFilter) {
					// focus first field when activating filters, to allow easier keyboard navigation
					const element = document.getElementById("meeting-name");
					// adding safe guard just in case the drawer is not rendered (happened on smaller breakpoint)
					if (element) {
						element.focus();
					}
				}
			},
		);
	}

	openFilterClick() {
		telemetryAddEvent("All meetings - Open filter");

		this.setState({
			showFilter: true,
		});
	}

	closeFilterClick(e) {
		e.preventDefault();

		this.setState({
			showFilter: false,
		});
	}

	clearFilterClick() {
		telemetryAddEvent("All meetings - Clear filter");

		this.onFilterChange(null, false, false, true);
	}

	moreClick() {
		const {
			filter: { active },
		} = this.state;
		this.loadMeetings(active, true);
	}

	isFiltered(filter) {
		const { filter: { active } = {} } = this.state;

		// eslint-disable-next-line no-param-reassign
		const testfilter = filter || active;

		return (
			(testfilter.meetingName || "").length > 0 ||
			(testfilter.boardAgendaStatuses || []).length > 0 ||
			(testfilter.publicAgendaStatuses || []).length > 0 ||
			(testfilter.boardMinutesStatuses || []).length > 0 ||
			(testfilter.publicMinutesStatuses || []).length > 0 ||
			testfilter.customDateRange ||
			(testfilter.meetingTypes || []).length > 0
		);
	}

	filterNeedsReloadFromApi(filter, previous) {
		// eslint-disable-next-line no-param-reassign
		filter = filter || {};
		// eslint-disable-next-line no-param-reassign
		previous = previous || {};
		const customDateRange = filter.customDateRange || 0;
		const previousCustomDateRange = previous.customDateRange || 0;

		return (
			(filter.meetingName || "") !== (previous.meetingName || "") ||
			!isEqual((filter.boardAgendaStatuses || []).sort(numberCompare), (previous.boardAgendaStatuses || []).sort(numberCompare)) ||
			!isEqual((filter.publicAgendaStatuses || []).sort(numberCompare), (previous.publicAgendaStatuses || []).sort(numberCompare)) ||
			!isEqual((filter.boardMinutesStatuses || []).sort(numberCompare), (previous.boardMinutesStatuses || []).sort(numberCompare)) ||
			!isEqual((filter.publicMinutesStatuses || []).sort(numberCompare), (previous.publicMinutesStatuses || []).sort(numberCompare)) ||
			(customDateRange !== previousCustomDateRange && (![0, 1].includes(customDateRange) || ![0, 1].includes(previousCustomDateRange))) ||
			((filter.from || "") !== (previous.from || "") && (filter.fromValid || previous.fromValid)) ||
			((filter.to || "") !== (previous.to || "") && (filter.toValid || previous.toValid)) ||
			!isEqual((filter.meetingTypes || []).sort(numberCompare), (previous.meetingTypes || []).sort(numberCompare))
		);
	}

	loadMeetings(filters, more = false) {
		const { showSignIn } = this.props;
		const { tab, meetings = [], page } = this.state;
		const url = `${API_HOST}/api/meetings/filter`;
		const queryFilters = {};
		const pageSize = 50;
		const currentDateTime = new Date();
		const date = new Date(
			currentDateTime.getFullYear(),
			currentDateTime.getMonth(),
			currentDateTime.getDate(),
			currentDateTime.getHours(),
			currentDateTime.getMinutes(),
		); // makes sure seconds and milliseconds don't mess up comparisons
		const textDateTime = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()} ${date.getHours()}:${date.getMinutes()}`;
		const textDate = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
		queryFilters.meetingName = filters.meetingName || "";
		queryFilters.boardAgendaStatuses = (filters.boardAgendaStatuses || []).join(","); // Convert to CSV so it plays nice with the query string
		queryFilters.publicAgendaStatuses = (filters.publicAgendaStatuses || []).join(",");
		queryFilters.boardMinutesStatuses = (filters.boardMinutesStatuses || []).join(","); // Convert to CSV so it plays nice with the query string
		queryFilters.publicMinutesStatuses = (filters.publicMinutesStatuses || []).join(",");
		queryFilters.customDateRange = filters.customDateRange;
		queryFilters.from = filters.from;
		queryFilters.to = filters.to;
		queryFilters.rowFrom = (more ? page * pageSize : 0) + 1; // These are inclusive and 1-indexed
		queryFilters.rowTo = queryFilters.rowFrom + pageSize - 1; // These are inclusive and 1-indexed
		queryFilters.meetingTypes = (filters.meetingTypes || []).join(",");
		queryFilters.ascending = tab === 0;

		if (!queryFilters.customDateRange) {
			queryFilters.from = undefined;
			queryFilters.to = undefined;
			if (tab === 0 && (!queryFilters.from || queryFilters.from.length === 0)) {
				queryFilters.from = textDateTime;
			}
			if (tab === 1 && (!queryFilters.to || queryFilters.to.length === 0)) {
				queryFilters.to = textDateTime;
			}
		} else {
			const dateFormat = "yyyy-MM-dd";
			let dateRange;

			if (queryFilters.customDateRange === 2) {
				// Today
				queryFilters.from = tab === 0 ? textDateTime : textDate;
				queryFilters.to = tab === 1 ? textDateTime : textDate;
			} else if (queryFilters.customDateRange === 3) {
				// This week
				dateRange = getDateRange("thisWeek");
				queryFilters.from = tab === 0 ? textDateTime : format(dateRange.from, dateFormat);
				queryFilters.to = tab === 1 ? textDateTime : format(dateRange.to, dateFormat);
			} else if (queryFilters.customDateRange === 4) {
				// This month
				dateRange = getDateRange("thisMonth");
				queryFilters.from = tab === 0 ? textDateTime : format(dateRange.from, dateFormat);
				queryFilters.to = tab === 1 ? textDateTime : format(dateRange.to, dateFormat);
			} else if (queryFilters.customDateRange === 5) {
				// This month
				dateRange = getDateRange("lastMonth");
				queryFilters.from = format(dateRange.from, dateFormat);
				queryFilters.to = format(dateRange.to, dateFormat);
			} else if (queryFilters.customDateRange === 6) {
				// This month
				dateRange = getDateRange("nextMonth");
				queryFilters.from = format(dateRange.from, dateFormat);
				queryFilters.to = format(dateRange.to, dateFormat);
			}

			const fromDate = new Date(queryFilters.from);
			const toDate = new Date(queryFilters.to);
			if (tab === 0 && fromDate < date) {
				queryFilters.from = textDateTime;
			}
			if (tab === 1 && toDate > date) {
				queryFilters.to = textDateTime;
			}
		}
		this.setState({ loading: true, moreLoading: more });

		request
			.get(url)
			.query(queryFilters)
			.then((res) => {
				let newState = {};

				if (more) {
					newState.meetings = meetings.concat(res.body.meetings.filter((meeting) => !meetings.some((m) => m.id === meeting.id))); // Filter out duplicates
					newState.more = res.body.more;
					newState.page = page + 1;
				} else {
					// Get a fresh list of meetings
					newState = {
						...res.body,
						page: 1,
					};
				}
				newState.loading = false;
				newState.moreLoading = false;
				newState.meetingFetchHappened = true; // keeps 'no meeting loaded' message from showing on initial load
				this.setState(newState);
			})
			.catch((err) => {
				showSignIn(err, () => {
					this.loadMeetings(filters, more);
				});
			});
	}

	undoClick() {
		this.onFilterChange(null, false, true);
	}

	render() {
		const { t, boardAdmin, boardMember } = this.props;
		const { settings, tab, showFilter, filter, more, loading, moreLoading, meetingFetchHappened } = this.state;

		return (
			<ComponentContainer padding="0">
				<MeetingList
					boardAdmin={boardAdmin}
					boardMember={boardMember}
					settings={settings}
					meetings={this.getMeetings()}
					more={more}
					tab={tab}
					filterVisible={showFilter}
					filtered={this.isFiltered()}
					tabChange={this.tabChange}
					filterClick={this.filterClick}
					openFilterClick={this.openFilterClick}
					clearFilterClick={this.clearFilterClick}
					filterClearClick
					moreClick={this.moreClick}
					loading={loading}
					moreLoading={moreLoading}
					meetingFetchHappened={meetingFetchHappened}
					removeMeetingFromList={this.removeMeetingFromList}
				/>
				<RightPanelContainer id="right-panel-container" open={showFilter} float drawerWidth={MD_DOWN_WIDTH} fullHeight>
					<FilterPanel
						filters={filter.active}
						onFilterChange={this.onFilterChange}
						isFiltered={this.isFiltered()}
						closeFilter={this.closeFilterClick}
						useSearch
						searchLabel={t("keyword")}
						searchTooltip={t("tooltips.filterByTextInMeetingName")}
						searchPlaceholder={t("tooltips.addKeywordsHere")}
						searchField="meetingName"
					>
						<MeetingFilter
							filters={filter.active}
							onFilterChange={this.onFilterChange}
							boardAdmin={boardAdmin}
							settings={settings}
						></MeetingFilter>
					</FilterPanel>
				</RightPanelContainer>
			</ComponentContainer>
		);
	}
}
MeetingsModule.contextType = SettingsContext;

export default withRouter(withTranslation("meetings")(withWidth()(withErrorHandling(connect(mapStateToProps)(MeetingsModule)))));
