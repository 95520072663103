import React, { Component } from "react";
import { withTranslation } from "react-i18next";

import ComponentContainer from "atlas/components/ComponentContainer/ComponentContainer";
import Typography from "@mui/material/Typography";
import OutlinedInput from "atlas/components/FormControls/OutlinedInput";

import { useWidthUp } from "atlas/utils/useWidth";
import SaveCancelButtons from "./SaveCancelButtons";

const withWidth = () => (WrappedComponent) => (props) => {
	const widthUpSm = useWidthUp("sm");

	return <WrappedComponent {...props} widthUpSm={widthUpSm} />;
};

class UsernameUpdate extends Component {
	handleKeyPress = (event) => {
		if (event.key === "Enter") {
			const { handleSave, handleBlur } = this.props;
			handleBlur();
			handleSave();
		}
	};

	render() {
		const { t, data, widthUpSm, handleCancel, handleSave, handleBlur, handleChange } = this.props;

		const { editing = {}, errors = {} } = data;

		const content = (
			<>
				<Typography variant="h5" color="textSecondary">
					{t("updateUserName")}
				</Typography>

				<OutlinedInput
					autoFocus
					id="username"
					label={t("userName")}
					value={editing.username || ""}
					helperText={errors.username}
					fullWidth
					variant="outlined"
					onChange={handleChange}
					onBlur={handleBlur}
					onKeyPress={this.handleKeyPress}
					error={!!errors.username}
					data-cy="username"
				/>

				<SaveCancelButtons
					save={handleSave}
					saveEnabled={!errors.username}
					disabledTooltip={errors.username ? t("errors.disabledTooltip") : ""}
					cancel={handleCancel}
					saveLabel={t("app:buttons.save")}
					cancelLabel={t("app:buttons.cancel")}
				/>
			</>
		);

		return <ComponentContainer maxWidth={widthUpSm ? "288px" : null}>{content}</ComponentContainer>;
	}
}

export default withWidth()(withTranslation("userProfile")(UsernameUpdate));
