import { applyPatches, produce } from "immer";
import telemetryAddEvent from "utils/telemetryAddEvent";
import { reOrderItemArray, checkAttachmentsAll } from "./utils";

export function undo() {
	if (this.undoStack.length < 1) return;
	if (this.undoPointer < 0) {
		// move to most recent change - end of stack
		this.undoPointer = this.undoStack.length - 1;
	} else if (this.undoPointer > 0) {
		// it walks down the stack, until we return to -1 because we undid all we have.
		this.undoPointer -= 1;
	}

	if (this.undoPointer > -1) {
		const currentState = this.state;
		this.previousItems = this.state.items;
		const agenda = this;
		currentState.active.selected = "";
		const undoUpdate = this.undoStack[this.undoPointer];
		this.setState({ ...applyPatches(currentState, this.undoStack[this.undoPointer]) }, () => {
			undoUpdate.forEach((updateData) => {
				switch (updateData.path[0]) {
					case "items":
						if (updateData.op === "remove") {
							// Find the item that is in previous items but not in state.items and add it in so that it can be set to deleted
							const modifiedItems = this.previousItems.filter((o1) => !this.state.items.some((o2) => o1.guid === o2.guid));
							if (modifiedItems.length > 0) {
								modifiedItems.forEach((item) => {
									agenda.itemIdsToDelete.push(item.guid);
								});
								reOrderItemArray(this.state.items, agenda, currentState.customNumbering);
							}
						} else {
							agenda.itemIdsToUpdate.push(this.state.items[updateData.path[1]].guid);
						}
						break;
					case "agendaHeader":
						agenda.agendaHeaderNeedsUpdate = true;
						break;
					case "agendaFooter":
						agenda.agendaFooterNeedsUpdate = true;
						break;
					case "agendaScratchpad":
						agenda.agendaScratchpadNeedsUpdate = true;
						break;
					default:
						break;
				}
			});
			let changed = false;
			const nextState = produce(this.state, (draft) => {
				changed = checkAttachmentsAll(draft.items);
			});
			if (changed) {
				this.setState({ items: nextState.items });
			}
		});

		// Forces dirty state to trigger a save.
		this.isDirty = true;

		this.props.updateTopBar({
			active: this.state.active,
			canUndo: this.undoStack.length > 0 && this.undoPointer > 0,
			canRedo: this.redoStack.length && this.undoPointer > -1,
		});
	}
}

export function redo() {
	if (this.redoStack.length < 1) return;
	if (this.undoPointer < 0) {
		this.undoPointer = 0;
	}

	if (this.undoPointer < this.redoStack.length) {
		const currentState = this.state;
		this.previousItems = this.state.items;
		currentState.active.selected = "";
		const agenda = this;
		const redoUpdate = this.redoStack[this.undoPointer];
		this.setState({ ...applyPatches(currentState, this.redoStack[this.undoPointer]) }, () => {
			redoUpdate.forEach((updateData) => {
				switch (updateData.path[0]) {
					case "items":
						if (updateData.op === "add") {
							reOrderItemArray(this.state.items, agenda, currentState.customNumbering);
						}
						const item = this.state.items[updateData.path[1]]
							? this.state.items[updateData.path[1]]
							: this.previousItems[updateData.path[1]];

						if (item) {
							agenda.itemIdsToUpdate.push(item.guid);
						}
						break;
					case "agendaHeader":
						agenda.agendaHeaderNeedsUpdate = true;
						break;
					case "agendaFooter":
						agenda.agendaFooterNeedsUpdate = true;
						break;
					case "agendaScratchpad":
						agenda.agendaScratchpadNeedsUpdate = true;
						break;
					default:
						break;
				}
			});
			const nextState = produce(this.state, (draft) => {
				checkAttachmentsAll(draft.items);
			});
			this.setState({ items: nextState.items });
		});
		this.undoPointer += 1;

		// Forces dirty state to trigger a save.
		this.isDirty = true;

		this.props.updateTopBar({
			active: this.state.active,
			canUndo: this.undoStack.length > 0 && this.undoPointer > 0,
			canRedo: this.redoStack.length && this.undoPointer > -1 && this.undoPointer < this.redoStack.length,
		});
	}
}
