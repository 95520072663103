import React from 'react';
import PropTypes from 'prop-types';

import CircularProgress from '@mui/material/CircularProgress';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
	container: {
		width: '100%',
		height: '100%',
		minHeight: (props) => (props.useMinHeight ? props.minHeight : '200px'),
		position: 'relative',
		boxSizing: 'border-box',
		padding: (props) => props.padding || '24px',
		'& > div': {
			position: 'absolute',
			top: '50%',
			left: '50%',
			transform: 'translate(-50%, -50%)',
		},
	},
});

const CircularProgressIndicator = (props) => {
	const { minHeight, padding, ...otherProps } = props;
	const { container } = useStyles({
		useMinHeight: typeof minHeight !== 'undefined',
		minHeight: minHeight && minHeight.length ? minHeight : undefined,
		padding,
	});

	return (
		<div className={container}>
			<div>
				<CircularProgress {...otherProps} />
			</div>
		</div>
	);
};

CircularProgressIndicator.propTypes = {
	minHeight: PropTypes.string,
};

CircularProgressIndicator.defaultProps = {
	minHeight: undefined,
};

export default CircularProgressIndicator;
