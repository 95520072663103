import React from "react";

import makeStyles from "@mui/styles/makeStyles";
import { TableCell } from "@mui/material";

import tableDataStyle from "assets/jss/components/tableDataStyle";
import { formatDate } from "views/Policy/utils/getFormattedDate";

const useStyles = makeStyles(tableDataStyle);

const DraftDate = (props) => {
	const { value } = props;
	const classes = useStyles();

	return <TableCell className={classes.cell}>{formatDate(value)}</TableCell>;
};

export default DraftDate;
