import React from "react";
import SvgIcon from "@mui/material/SvgIcon";

import themeConfig from "../../atlas/assets/jss/theme";
import getOr from "lodash/fp/getOr";

const icon = (props) => {
	const { color } = props;
	const fillColor = color ? getOr(color, ["palette", color, "main"], themeConfig) : "currentColor";

	return (
		<SvgIcon {...props}>
			<defs>
				<path
					d="M22.1538462,8.25523077 L0.923076923,8.25523077 C0.413275616,8.25523077 0,8.66850638 0,9.17830769 C0,9.688109 0.413275616,10.1013846 0.923076923,10.1013846 L22.1538462,10.1013846 C22.6636475,10.1013846 23.0769231,9.688109 23.0769231,9.17830769 C23.0769231,8.66850638 22.6636475,8.25523077 22.1538462,8.25523077 Z M14.1677989,16.7831835 C13.8073149,17.1436674 13.8073149,17.7281274 14.1677989,18.0886114 C14.5282828,18.4490953 15.1127428,18.4490953 15.4732268,18.0886114 L23.729637,9.83220113 C24.090121,9.47171717 24.090121,8.88725719 23.729637,8.52677323 L15.4732268,0.270362971 C15.1127428,-0.0901209904 14.5282828,-0.0901209904 14.1677989,0.270362971 C13.8073149,0.630846933 13.8073149,1.21530691 14.1677989,1.57579087 L21.7714952,9.17948718 L14.1677989,16.7831835 Z"
					id="arrow-right-path-1"
				/>
			</defs>
			<g id="arrow-right" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g id="Icons/24px/Unselected/Arrow">
					<g id="Color/Universal-Greys/00-Black" transform="translate(0.000000, 3.000000)">
						<mask id="arrow-right-mask-2" fill="white">
							<use xlinkHref="#arrow-right-path-1" />
						</mask>
						<use fill="#202020" fillRule="nonzero" xlinkHref="#arrow-right-path-1" />
						<g mask="url(#arrow-right-mask-2)" fill={fillColor}>
							<g transform="translate(0.000000, -3.000000)">
								<rect x="0" y="0" width="24" height="24" />
							</g>
						</g>
					</g>
				</g>
			</g>
		</SvgIcon>
	);
};

export default icon;
