import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import { Close } from "components/Icons";

import GenericDialog from "atlas/components/Dialogs/GenericDialog";

class ErrorAgendaItemDialog extends Component {
	constructor(props) {
		super(props);

		this.state = {
			showErrorDialog: this.props.show,
		};
	}

	render() {
		const { t, handleClose, failedUploads, isFromList, handleGoToItem } = this.props;

		const { showErrorDialog } = this.state;
		const dialog = t("errorDialog");

		return (
			<GenericDialog
				show={showErrorDialog}
				title={failedUploads && failedUploads.length > 0 ? dialog.titleFailedUploads : dialog.title}
				primaryTitle={isFromList ? dialog.buttonFromList : dialog.button}
				primaryAction={isFromList ? handleGoToItem : handleClose}
				secondaryAction={handleClose}
				closeIcon={<Close />}
			>
				<Typography variant="h4">
					<Box mb={3} fontWeight="fontWeightBold">
						{failedUploads && failedUploads.length > 0 ? dialog.descriptionFailedFiles : dialog.description}
					</Box>
					{failedUploads && failedUploads.length > 0 && (
						<ul>{failedUploads && failedUploads.map((file, index) => <li key={`${index}-${file}`}>{file}</li>)}</ul>
					)}
				</Typography>
			</GenericDialog>
		);
	}
}

export default withTranslation("agendaItems")(ErrorAgendaItemDialog);
