import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import request from "superagent";
import clsx from "clsx";
import union from "lodash/fp/union";

import {
	Typography,
	Box,
	Accordion,
	AccordionSummary,
	AccordionDetails,
	List,
	ListItem,
	ListItemAvatar,
	ListItemText,
	Checkbox,
	FormControlLabel,
	ListItemIcon,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { grayColor } from "atlas/assets/jss/shared";
import GenericDialog from "atlas/components/Dialogs/GenericDialog";
import Icon from "atlas/components/Icon/Icon";
import CircularProgressIndicator from "atlas/components/Progress/CircularProgressIndicator";
import GenericAvatar, { GENERIC_AVATAR_BACKGROUNDS } from "components/Avatar/GenericAvatar";
import { Check } from "components/Icons";
import { API_HOST } from "config/env";
import { setSnackbarOptions } from "redux/snackBar/actions";
import notifierMessage from "utils/notifierMessage";
import telemetryAddEvent from "utils/telemetryAddEvent";

const useStyles = makeStyles({
	summary: {
		padding: "0",
	},
	tableRow: {
		display: "table-row",
	},
	tableCell: {
		display: "table-cell",
	},
	avatar: {
		textAlign: "center",
		verticalAlign: "middle",
	},
	sentItem: {
		verticalAlign: "middle",
		"& svg": {
			marginLeft: "20px",
		},
	},
	sendText: {
		marginTop: "1px",
		"& p": {
			position: "relative",
			left: "-30px",
		},
	},
	accordionTitle: {
		display: "flex",
	},
	labelTitle: {
		minWidth: "110px",
		maxWidth: "350px",
	},
	memberStaffSeparator: {
		borderTop: `solid 1px ${grayColor[2]}`,
	},
});

const SendDraftForReviewDialog = (props) => {
	const { show, agenda, members, onClose, meeting } = props;
	const [selectedUsers, setSelectedUsers] = useState([]);
	const [sendingReview, setSendingReview] = useState(false);
	const [showQuorumWarning, setShowQuorumWarning] = useState(false);
	const [boardMembers, setBoardMembers] = useState(null);
	const [gettingMembers, setGettingMembers] = useState(true);
	const [usersAlreadySelected, setUsersAlreadySelected] = useState([]);
	const classes = useStyles();
	const dispatch = useDispatch();

	const { t } = useTranslation("meetings");

	const getMembers = async () => {
		try {
			const res = await request.get(`${API_HOST}/api/meeting/${meeting.id}/draftreviewmembers?agenda=${agenda}&members=${members}`);
			setGettingMembers(false);

			var boardMembers = res.body.sort((a, b) => {
				var memberSort = a.member < b.member ? 1 : a.member > b.member ? -1 : 0;
				return memberSort !== 0 ? memberSort : a.name.localeCompare(b.name);
			});
			setBoardMembers(boardMembers);
			const usersWithAccess = boardMembers.filter(
				(user) => user.draftReview && ((user.draftReview.public && !members) || (user.draftReview.members && members)),
			);
			const usersWithAccessIds = usersWithAccess
				? usersWithAccess.map((user) => {
						return user.id;
					})
				: [];
			setUsersAlreadySelected([...usersWithAccessIds]);
			setSelectedUsers([...usersWithAccessIds]);
		} catch (err) {
			console.log(err);
		}
	};

	const handleCheckboxChange = (id) => {
		const selectedUserIndex = selectedUsers.indexOf(id);
		if (selectedUserIndex >= 0) {
			setSelectedUsers((prev) => {
				prev.splice(selectedUserIndex, 1);
				return [...prev];
			});
		} else {
			setSelectedUsers((prev) => {
				prev.push(id);
				return [...prev];
			});
		}
	};

	const handleSendForReviewDialog = () => {
		setSendingReview(true);
		request
			.post(`${API_HOST}/api/meeting/${meeting.id}/senddraftreview`)
			.send({ selectedUsers, agenda, members })
			.then((response) => {
				if (response && response.status === 200) {
					let option = notifierMessage(t("sendDraftForReviewDialog.draftSentForReview"), "success");
					dispatch(setSnackbarOptions(option));
				}

				telemetryAddEvent(`${agenda ? "Agenda" : "Minutes"} Preview - Send for Review - Send Draft`);
				setSendingReview(false);
				onClose();
			})
			.catch((err) => {
				setSendingReview(false);
				console.log(err);
			});
	};

	const checkQuorumWarning = () => {
		if (boardMembers && boardMembers.length > 0) {
			const totalUserCountSelected = union(selectedUsers, usersAlreadySelected).length;
			setShowQuorumWarning(totalUserCountSelected / boardMembers.length >= 0.5);
		}
	};

	useEffect(() => {
		checkQuorumWarning();
	}, [selectedUsers]);

	useEffect(() => {
		getMembers();
	}, []);

	useEffect(() => {
		const listItemCollection = document.getElementsByClassName("tableList");
		const titleItemCollection = document.getElementsByClassName("titleAccordian");
		const listItemWidth = listItemCollection.length > 0 && listItemCollection[0].offsetWidth;
		const accordianTitleWidth = titleItemCollection.length > 0 && titleItemCollection[0].offsetWidth;
		let sendTextCollection = document.querySelector(".textSent");
		if (sendTextCollection) {
			sendTextCollection.style.marginLeft = listItemWidth - accordianTitleWidth + "px";
		}
	});
	return (
		<>
			<GenericDialog
				show={show}
				title={<span style={{ fontWeight: 600 }}>{t(`sendDraftForReviewDialog.${agenda ? "titleAgenda" : "titleMinutes"}`)}</span>}
				primaryAction={handleSendForReviewDialog}
				primaryTitle={sendingReview ? <CircularProgressIndicator color="secondary" size={20} minHeight="20px" /> : t("buttons.sendDraft")}
				primaryDisabled={sendingReview || selectedUsers.length === 0}
				secondaryAction={onClose}
				secondaryTitle={t("buttons.cancel")}
				secondaryDisabled={sendingReview}
				clickAwayDisabled={sendingReview}
				leftActionChildren={<Typography color="error">{showQuorumWarning ? t("sendDraftForReviewDialog.quorumWarning") : ""}</Typography>}
				closeIcon={<Icon name="close" />}
				data-cy="send-draft-review-dialog"
			>
				<>
					<Typography variant="h5">
						<Box mb={1}>{t("sendDraftForReviewDialog.line1")}</Box>
					</Typography>

					<Typography variant="h5">
						<Box mt={1} mb={1}>
							{t(`sendDraftForReviewDialog.line2${agenda ? "Agenda" : "Minutes"}`, { name: `${meeting.cleanName}` })}
						</Box>
					</Typography>

					<Box mt={1} mb={1}>
						<Accordion elevation={0} classes={{ root: "expansion-panel-root" }} expanded square data-cy="member-list">
							<AccordionSummary
								className={classes.summary}
								classes={{ root: "expansion-summary-root", content: "expansion-summary-content" }}
								data-cy="member-list-summary"
								onClick={() => {}}
							>
								<div className={clsx(classes.accordionTitle, "titleAccordian")}>
									{members && (
										<Box mt="auto" mb="auto" mr={2}>
											<Icon name="locked" />
										</Box>
									)}
									<Box mt="auto" mb="auto" mr={2}>
										<Typography variant="body1">{t("sendDraftForReviewDialog.accordionTitle")}</Typography>
									</Box>
								</div>
								{usersAlreadySelected && usersAlreadySelected.length > 0 ? (
									<div className={clsx(classes.sendText, "textSent")}>
										<Typography variant="body1">{t("sendDraftForReviewDialog.sent")}</Typography>
									</div>
								) : null}
							</AccordionSummary>
							<AccordionDetails>
								{boardMembers !== null && boardMembers.length === 0 ? (
									<Typography variant="h4">{t("sendDraftForReviewDialog.noMembers")}</Typography>
								) : (
									<List>
										{gettingMembers ? (
											<CircularProgressIndicator size={20} />
										) : (
											boardMembers &&
											boardMembers.map((member, index) => {
												const { id, name, email, avatar, firstName, lastName } = member;

												const pic = avatar ? (
													<GenericAvatar imageUrl={avatar} name={name} backgroundNumber={((index || 0) % GENERIC_AVATAR_BACKGROUNDS) + 1} />
												) : (
													<GenericAvatar
														name={name}
														initials={`${(firstName || "").substr(0, 1)}${(lastName || "").substr(0, 1)}`}
														backgroundNumber={((index || 0) % GENERIC_AVATAR_BACKGROUNDS) + 1}
													/>
												);

												const showMemberStaffSeparator = index > 0 && boardMembers[index - 1].member !== member.member;

												return (
													<ListItem
														key={`${name}-${email}`}
														alignItems="flex-start"
														classes={{ root: "list-item-root" }}
														className={clsx(classes.tableRow, "tableList")}
														data-cy="member-list-member"
													>
														<ListItemAvatar
															className={clsx(classes.tableCell, classes.avatar, {
																[classes.memberStaffSeparator]: showMemberStaffSeparator,
															})}
															width={32}
															height={32}
														>
															{pic}
														</ListItemAvatar>
														<label
															className={clsx(classes.tableCell, classes.labelTitle, {
																[classes.memberStaffSeparator]: showMemberStaffSeparator,
															})}
															htmlFor={`group-member-${id}`}
														>
															<ListItemText primary={name} secondary={email} />
														</label>
														<FormControlLabel
															className={clsx(classes.tableCell, {
																[classes.memberStaffSeparator]: showMemberStaffSeparator,
															})}
															control={
																<Checkbox
																	key={`select-member-${id}`}
																	checkedIcon={<Check fontSize="small" color="primary" />}
																	inputProps={{
																		id: `group-member-${id}`,
																		"aria-label": `Select ${email}`,
																	}}
																	checked={selectedUsers.indexOf(id) >= 0}
																	onChange={() => {
																		handleCheckboxChange(id);
																	}}
																	data-cy={`select-user-${id}`}
																/>
															}
														/>
														{usersAlreadySelected && usersAlreadySelected.indexOf(id) >= 0 ? (
															<ListItemIcon
																className={clsx(classes.tableCell, classes.sentItem, {
																	[classes.memberStaffSeparator]: showMemberStaffSeparator,
																})}
															>
																<Icon name="check-mark" />
															</ListItemIcon>
														) : (
															<div
																className={clsx(classes.tableCell, classes.sentItem, {
																	[classes.memberStaffSeparator]: showMemberStaffSeparator,
																})}
															></div>
														)}
													</ListItem>
												);
											})
										)}
									</List>
								)}
							</AccordionDetails>
						</Accordion>
					</Box>
				</>
			</GenericDialog>
		</>
	);
};

export default SendDraftForReviewDialog;
