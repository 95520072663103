import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';

const Dropzone = (props) => {
	const onDrop = useCallback((acceptedFiles) => {
		const reader = new FileReader();

		reader.onabort = () => console.log('file reading was aborted');
		reader.onerror = () => console.log('file reading has failed');
		reader.onload = () => {
			props.onFileLoad(reader.result);
		};

		acceptedFiles.forEach((file) => reader.readAsDataURL(file));
	}, []);

	const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
	const { className } = props;

	return (
		<div {...getRootProps()}>
			<input {...getInputProps()} />
			{isDragActive ? (
				<div className={className}>Drop file here...</div>
			) : (
				<div className={className}>Drag image file, or click to select.</div>
			)}
		</div>
	);
};

Dropzone.propTypes = {
	className: PropTypes.string,
	onFileLoad: PropTypes.func.isRequired,
};

Dropzone.defaultProps = {
	className: undefined,
};

export default Dropzone;
