import React from 'react';
import { useTranslation } from 'react-i18next';
import { Toolbar, Box, Button, Badge } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import LargeLabel from '../Typography/LargeLabel';
import SmallLabel from '../Typography/SmallLabel';
import Icon from '../Icon/Icon';
import OutlinedSelect from '../FormControls/OutlinedSelect';
import { useWidthUp } from '../../utils/useWidth';

const useBadgeStyle = makeStyles(() => ({
	badge: {
		fontSize: '8px',
		padding: '3px',
		height: '16px',
		minWidth: '16px',
		color: '#ffffff',
		backgroundColor: '#af292e',
	},
	anchorOriginTopRightRectangle: {
		transform: 'scale(1) translate(100%,75%)',
	},
}));

const useToolbarStyles = makeStyles((theme, props) => ({
	root: {
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(1),
		position: (props) => (props.numSelected > 0 || !props.showTitle ? 'fixed' : 'inherit'),
		zIndex: 100,
	},
	expandRightIcon: {
		position: 'relative',
		top: '2px',
	},
}));

const EnhancedTableToolbar = (props) => {
	const { numSelected, title, toolbarOptions, exportAction, filterAction, numFilters = 0, showTitle = false } = props;
	const widthUpXs = useWidthUp('xs');
	const { t } = useTranslation();
	const badgeClasses = useBadgeStyle();

	const classes = useToolbarStyles({ numSelected, showTitle });

	return (
		<>
			<Toolbar className={classes.root}>
				{title}

				{numSelected > 0 && widthUpXs && (
					<>
						<Box data-cy="tableData-numSelected" style={{ whiteSpace: 'nowrap' }}>
							<LargeLabel color="black" semiBold>
								{t('tableData.selected', { number: numSelected })}
							</LargeLabel>
						</Box>
						{toolbarOptions && (
							<Box ml={2}>
								<OutlinedSelect id="ToolbarActions" options={toolbarOptions} placeholder={t('tableData.actions')} value="" compactSpacing />
							</Box>
						)}
					</>
				)}
				{exportAction && (
					<Box ml="auto">
						<Badge data-cy="export-badge">
							<Button onClick={exportAction} data-cy="export-button">
								<Icon name="export" size="16px" />
								<Box ml={1}>
									<SmallLabel color="black" bold>
										{t('tableData.export')}
									</SmallLabel>
								</Box>
							</Button>
						</Badge>
					</Box>
				)}
				{filterAction && (
					<Box ml={exportAction ? '' : 'auto'} mr={3}>
						<Badge badgeContent={numFilters} data-cy="filter-badge" classes={badgeClasses}>
							<Button onClick={filterAction} data-cy="filter-button">
								<Icon name="filter" size="16px" />
								<Box ml={1}>
									<SmallLabel color="black" bold>
										{t('tableData.filter')}
									</SmallLabel>
								</Box>
							</Button>
						</Badge>
					</Box>
				)}
			</Toolbar>
			{numSelected > 0 && widthUpXs && (
				<Toolbar className={classes.root}>
					<Box data-cy="tableData-numSelected" style={{ whiteSpace: 'nowrap' }}>
						<LargeLabel color="black" semiBold>
							{t('tableData.selected', { number: numSelected })}
						</LargeLabel>
					</Box>
					{toolbarOptions && (
						<Box ml={2}>
							<OutlinedSelect id="ToolbarActions" options={toolbarOptions} placeholder={t('tableData.actions')} value="" compactSpacing />
						</Box>
					)}
				</Toolbar>
			)}
		</>
	);
};

export default EnhancedTableToolbar;
