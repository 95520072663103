import { STATUS_DEFAULT, STATUS_GOOD, STATUS_WARNING } from "../../atlas/assets/jss/utils/statusIndicators";

const PublicAgendaStatusesEnum = () => ({
	NOTPUBLISHED: {
		value: 2,
		label: "enums.statuses.notPublished",
		color: STATUS_DEFAULT,
	},
	PUBLISHED: {
		value: 6,
		label: "enums.statuses.published",
		color: STATUS_GOOD,
	},
	OUTOFSYNC: {
		value: 7,
		label: "enums.statuses.outOfSync",
		color: STATUS_WARNING,
	},
	FINALPUBLISHED: {
		value: 9,
		label: "enums.statuses.published",
		color: STATUS_GOOD,
	},
});
export default PublicAgendaStatusesEnum;
