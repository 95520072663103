import React from "react";
import SvgIcon from "@mui/material/SvgIcon";

import themeConfig from "../../atlas/assets/jss/theme";
import getOr from "lodash/fp/getOr";

const icon = (props) => {
	const { color } = props;
	const fillColor = color ? getOr(color, ["palette", color, "main"], themeConfig) : "currentColor";

	return (
		<SvgIcon {...props}>
			<g id="send-feedback" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinejoin="round">
				<g id="Group" transform="translate(1.000000, 4.000000)" stroke={fillColor} strokeWidth="1.5">
					<path
						d="M4,3.73529412 L18,3.73529412 M4,7.73529412 L18,7.73529412 M4,11.7352941 L13.1271973,11.7352941"
						id="Shape"
						strokeLinecap="round"
					/>
					<polygon
						id="Rectangle"
						points="0 7.10542736e-15 22 7.10542736e-15 22 15 11.0722656 15 6.29931641 19.0200195 6.29931641 15 0 15"
					/>
				</g>
			</g>
		</SvgIcon>
	);
};

export default icon;
