import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import makeStyles from '@mui/styles/makeStyles';

import inputStyle from '../../assets/jss/components/inputStyle';

const useInputStyles = makeStyles(inputStyle);

export const STYLE_UPPERCASE = 'uppercase';
export const STYLE_SENTENCE_CASE = 'sentence';
export const SIZE_SMALL = 'small';
export const SIZE_LARGE = 'large';
export const ROLE_HEADING = 'heading';

const InputLabel = (props) => {
	const {
		id,
		htmlFor,
		label,
		style,
		size,
		useEllipsis,
		lines,
		bottomSpacing,
		boldLabel = false,
		role,
		ariaLevel: ariaLevelString,
		tabIndex,
	} = props;
	const ariaLevel = ariaLevelString ? (typeof ariaLevelString === 'string' ? parseInt(ariaLevelString, 10) : ariaLevelString) : undefined;
	const classes = useInputStyles({ bottomSpacing, lines });

	const sentenceCase = style === STYLE_SENTENCE_CASE;
	const large = size === SIZE_LARGE;

	const getLabel = () => (
		<label id={`${htmlFor}-label`} htmlFor={htmlFor} tabIndex={tabIndex}>
			{label}
		</label>
	);

	const getHeading = () => {
		let Heading = 'h1';
		if (ariaLevel > 1 && ariaLevel <= 6) {
			Heading = `h${ariaLevel}`;
		}

		return (
			<Heading id={id} tabIndex={tabIndex}>
				{htmlFor ? getLabel() : label}
			</Heading>
		);
	};

	return (
		<div
			className={clsx({
				[classes.externalLabelUppercase]: !large && !sentenceCase && !boldLabel,
				[classes.externalLabelLargeUppercase]: large && !sentenceCase && !boldLabel,
				[classes.externalLabelSentence]: !large && sentenceCase && !boldLabel,
				[classes.externalLabelLargeSentence]: large && sentenceCase && !boldLabel,
				[classes.externalLabelEllipsis]: useEllipsis && !boldLabel,
				[classes.boldLabel]: boldLabel,
			})}
		>
			{ariaLevel > 0 && role === ROLE_HEADING ? getHeading() : getLabel()}
		</div>
	);
};

InputLabel.propTypes = {
	id: PropTypes.string,
	htmlFor: PropTypes.string,
	label: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
	style: PropTypes.oneOf([STYLE_UPPERCASE, STYLE_SENTENCE_CASE]),
	size: PropTypes.oneOf([SIZE_LARGE, SIZE_SMALL]),
	useEllipsis: PropTypes.bool,
	lines: PropTypes.number,
	bottomSpacing: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

InputLabel.defaultProps = {
	style: STYLE_UPPERCASE,
	size: SIZE_SMALL,
	useEllipsis: false,
	lines: 1,
	bottomSpacing: false,
};

export default InputLabel;
