import React from "react";
import { withTranslation } from "react-i18next";
import SvgIcon from "@mui/material/SvgIcon";
import themeConfig from "../../atlas/assets/jss/theme";
import Tooltip from "@mui/material/Tooltip";
import getOr from "lodash/fp/getOr";
import omit from "lodash/fp/omit";

const icon = (props) => {
	const { color, t } = props;
	const fillColor = color ? getOr(color, ["palette", color, "main"], themeConfig) : "currentColor";

	return (
		<SvgIcon {...omit(props, t)}>
			<defs>
				<path
					d="M12.1666665,18.6666663 C12.8109986,18.6666663 13.333333,18.1443319 13.333333,17.4999998 C13.333333,16.8556677 12.8109986,16.3333333 12.1666665,16.3333333 C11.5223344,16.3333333 11,16.8556677 11,17.4999998 C11,18.1443319 11.5223344,18.6666663 12.1666665,18.6666663 Z M10.0833333,9.50933333 C10.0833333,8.58768305 10.8178799,7.75 12.1566667,7.75 C13.3356257,7.75 13.9166667,8.61905123 13.9166667,9.50933333 C13.9166667,10.0871198 13.6627738,10.6198194 12.7840797,11.4982601 C11.6469141,12.6354257 11.25,13.418029 11.25,14.3333333 L11.25,15 C11.25,15.4142136 11.5857864,15.75 12,15.75 C12.4142136,15.75 12.75,15.4142136 12.75,15 L12.75,14.3333333 C12.75,13.8638077 12.9662636,13.4373966 13.8446634,12.5589968 C14.9876867,11.4163032 15.4166667,10.5162484 15.4166667,9.50933333 C15.4166667,7.88812567 14.3214285,6.25 12.1566667,6.25 C9.94240545,6.25 8.58333333,7.79989725 8.58333333,9.50933333 C8.58333333,9.9235469 8.91911977,10.2593333 9.33333333,10.2593333 C9.7475469,10.2593333 10.0833333,9.9235469 10.0833333,9.50933333 Z M12,22.75 C17.9370611,22.75 22.75,17.9370611 22.75,12 C22.75,6.06293894 17.9370611,1.25 12,1.25 C6.06293894,1.25 1.25,6.06293894 1.25,12 C1.25,17.9370611 6.06293894,22.75 12,22.75 Z M12,21.25 C6.89136606,21.25 2.75,17.1086339 2.75,12 C2.75,6.89136606 6.89136606,2.75 12,2.75 C17.1086339,2.75 21.25,6.89136606 21.25,12 C21.25,17.1086339 17.1086339,21.25 12,21.25 Z"
					id="help-path-1"
				/>
			</defs>
			<g id="help" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g id="Icons/24px/Unselected/Help">
					<mask id="help-mask-2" fill="white">
						<use xlinkHref="#help-path-1" />
					</mask>
					<use fill="#FFFFFF" fillRule="nonzero" xlinkHref="#help-path-1" />
					<g mask="url(#help-mask-2)" fill={fillColor} fillRule="evenodd">
						<rect x="0" y="0" width="24" height="24" />
					</g>
				</g>
			</g>
		</SvgIcon>
	);
};
export default withTranslation()(icon);
