import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import clsx from "clsx";

import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import Badge from "@mui/material/Badge";
import makeStyles from "@mui/styles/makeStyles";

import { useWidthUp } from "atlas/utils/useWidth";
import { STATUS_PRESENT, STATUS_LATE, STATUS_ABSENT } from "../../LiveMeeting/utils/rollCallUserStatus";
import typographyStyle from "atlas/assets/jss/components/typographyStyle";
import userListStyle from "assets/jss/components/userListStyle";
import GenericAvatar, { GENERIC_AVATAR_BACKGROUNDS } from "components/Avatar/GenericAvatar";
import { Box } from "@mui/material";
import { useHotkeys } from "react-hotkeys-hook";

const useUserListStyles = makeStyles(userListStyle);
const useStyles = makeStyles({
	rollCall: {
		padding: "16px",
	},
	header: {
		display: "flex",
		alignItems: "center",
		justifyContent: "flex-end",
		height: "48px",
	},
	headerText: {
		...typographyStyle.columnHeaderDiagonal,
		width: "56px",
	},
	radioButtons: {
		display: "flex",
		alignItems: "center",
	},
	radioButton: {
		width: "56px",
	},
	userDisconnected: {
		"& .MuiBadge-badge": {
			borderRadius: "5px",
			top: "auto",
			color: "#444444",
			backgroundColor: "#fff",
			border: "3px solid",
		},
	},
	userConnected: {
		"& .MuiBadge-badge": {
			borderRadius: "5px",
			top: "auto",
			color: "#047b55",
			backgroundColor: "#047b55",
		},
	},
});

const RollCall = (props) => {
	const { users, handleUpdate, dataCy, onlineVoters, digitalVoting } = props;
	const widthUpLg = useWidthUp("lg");
	const { t } = useTranslation("meetings");
	const largeWidth = widthUpLg;
	const classes = useStyles();
	const userListClasses = useUserListStyles();

	useHotkeys(
		"tab",
		() => {
			if (document.getElementById("radio-btn")) {
				document.getElementById("radio-btn").parentElement.style.backgroundColor = "";
			}
		},
		{ enableOnContentEditable: true, enableOnFormTags: ["input", "select", "textarea"] },
	);

	useEffect(() => {
		if (document.getElementById("radio-btn")) {
			setTimeout(() => {
				document.getElementById("radio-btn").focus();
				document.getElementById("radio-btn").parentElement.style.backgroundColor = "#d7dde7";
			}, 500);
		}
	}, []);

	const getRadioButton = (user, type, status) => (
		<Radio
			id="radio-btn"
			checked={user.status === status}
			value={status}
			name={`roll-call-user-options-${user.userId}`}
			onChange={(e) => handleUpdate(e, user.userId)}
			inputProps={{
				"aria-label": t(`rollCall.${type}`),
				"data-cy": `roll-call-user-options-${type}-${user.userId}`,
			}}
		/>
	);

	const getRadioControl = (user, type, status) => (
		<div
			className={clsx({
				[classes.radioButton]: largeWidth,
			})}
		>
			{!largeWidth ? (
				<FormControlLabel label={t(`rollCall.${type}`)} control={getRadioButton(user, type, status)} />
			) : (
				getRadioButton(user, type, status)
			)}
		</div>
	);

	return (
		<div className={classes.rollCall} data-cy={dataCy}>
			{largeWidth && (
				<div className={classes.header}>
					<div className={classes.headerText}>{t("rollCall.present")}</div>
					<div className={classes.headerText}>{t("rollCall.late")}</div>
					<div className={classes.headerText}>{t("rollCall.absent")}</div>
				</div>
			)}
			<ol className={userListClasses.list}>
				{users.map((user) => (
					<li key={`roll-call-user-${user.userId}`} className={userListClasses.user} data-cy={`roll-call-user-${user.userId}`}>
						<div className={userListClasses.avatar}>
							<Badge
								className={onlineVoters && onlineVoters.indexOf(user.userId) >= 0 ? classes.userConnected : classes.userDisconnected}
								overlap="circular"
								anchorOrigin={{
									vertical: "bottom",
									horizontal: "right",
								}}
								variant="dot"
								invisible={!digitalVoting}
								title={
									onlineVoters && onlineVoters.indexOf(user.userId) >= 0
										? t("voting.tooltips.userConnected")
										: t("voting.tooltips.userDisconnected")
								}
							>
								<GenericAvatar
									imageUrl={user.profileImageUrl}
									name={user.name}
									initials={`${(user.firstName || "").substr(0, 1)}${(user.lastName || "").substr(0, 1)}`}
									backgroundNumber={((user.number || 0) % GENERIC_AVATAR_BACKGROUNDS) + 1}
								/>
							</Badge>
						</div>
						<Box display="flex" className={userListClasses.identifier}>
							<div className={userListClasses.name}>{user.name}</div>
							<div className={userListClasses.title}></div>
						</Box>
						<div
							className={clsx({
								[classes.radioButtons]: largeWidth,
							})}
						>
							{getRadioControl(user, "present", STATUS_PRESENT)}
							{getRadioControl(user, "late", STATUS_LATE)}
							{getRadioControl(user, "absent", STATUS_ABSENT)}
						</div>
					</li>
				))}
			</ol>
		</div>
	);
};

export default RollCall;
