import React from "react";
import { useSelector } from "react-redux";
import map from "lodash/fp/map";

import Grid from "@mui/material/Grid";
import MeetingCard from "components/MeetingCard/MeetingCard";

const MeetingCards = (props) => {
	const {
		showArrow,
		showItems,
		showAgendaPublishStatus,
		showMinutesPublishStatus,
		showLinks,
		showWarning,
		showVideo,
		meetings,
		...otherProps
	} = props;
	const appReducer = useSelector((state) => state.appReducer);
	const { openLeftNav } = appReducer;

	return (
		<div className="flex grow" {...otherProps}>
			<Grid container spacing={3}>
				{map(
					(meeting) => (
						<Grid item key={meeting.id} xs={12} sm={12} md={openLeftNav ? 12 : 6} lg={6} xl={4}>
							<MeetingCard
								meeting={meeting}
								showArrow={showArrow}
								showItems={showItems}
								showAgendaPublishStatus={showAgendaPublishStatus}
								showMinutesPublishStatus={showMinutesPublishStatus}
								showLinks={showLinks}
								showWarning={showWarning}
								showVideo={showVideo}
							/>
						</Grid>
					),
					meetings,
				)}
			</Grid>
		</div>
	);
};

export default MeetingCards;
