import React from "react";
import { useTranslation } from "react-i18next";
import clsx from "clsx";

import makeStyles from "@mui/styles/makeStyles";

import typographyStyle from "atlas/assets/jss/components/typographyStyle";
import { whiteColor, errorColor } from "atlas/assets/jss/shared";
import { STATUS_ERROR } from "atlas/assets/jss/utils/statusIndicators";
import Tooltip from "atlas/components/Tooltip/Tooltip";

const useStyles = makeStyles((theme) => ({
	rejected: {
		flexGrow: "1",
		textAlign: "right",
		alignSelf: "center",
	},
	status: {
		...typographyStyle.status,
		whiteSpace: "nowrap",
		borderRadius: "4px",
		padding: "4px 8px 4px 8px",
		height: "24px",
		verticalAlign: "middle",
		textAlign: "center",
		display: "inline-block",
		boxSizing: "border-box",
		[`&.${STATUS_ERROR}`]: {
			backgroundColor: errorColor,
			color: whiteColor,
		},
	},
}));

const RejectedFromMeetingStatus = (props) => {
	const { item } = props;
	const { t } = useTranslation("agendaItems");
	const classes = useStyles();

	return item.rejectedBy ? (
		<div className={classes.rejected}>
			<Tooltip title={t("tooltips.rejectedFromAgenda", { name: item.rejectedBy, meeting: item.rejectedMeeting })}>
				<span className={clsx(classes.status, STATUS_ERROR)} data-cy="rejected-from-meeting">
					{t("rejectedFromAgenda")}
				</span>
			</Tooltip>
		</div>
	) : null;
};

export default RejectedFromMeetingStatus;
