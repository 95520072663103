/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { Button } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import {
	TOP_START,
	TOP,
	TOP_END,
	RIGHT_START,
	RIGHT,
	RIGHT_END,
	BOTTOM_END,
	BOTTOM,
	BOTTOM_START,
	LEFT_END,
	LEFT,
	LEFT_START,
} from '../../utils/tooltipPlacement';
import { LARGE, MEDIUM, SMALL, SMALL_SQUARE, VERY_SMALL } from '../../utils/buttonSize';
import Tooltip from '../Tooltip/Tooltip';
import buttonStyle from '../../assets/jss/components/buttonStyle';
import buttonRippleStyle, { primaryButtonRippleStyle } from '../../assets/jss/components/buttonRippleStyle';
import pageHeaderButtonStyle from '../../assets/jss/components/buttonButtonStyle';

const useButtonStyle = makeStyles(buttonStyle);
const useRippleStyle = makeStyles(buttonRippleStyle);
const usePrimaryButtonRippleStyle = makeStyles(primaryButtonRippleStyle);
const usePageHeaderButtonStyle = makeStyles(pageHeaderButtonStyle);

export const GROUP_POSITION_FIRST = 1;
export const GROUP_POSITION_MIDDLE = 2;
export const GROUP_POSITION_LAST = 3;

const ButtonWithTooltip = (props) => {
	const {
		className: additionalClassName,
		title,
		tooltipPlacement,
		primary,
		secondary,
		variant,
		color,
		size,
		groupPosition,
		onClick,
		fullWidth,
		hasRightMargin,
		hasTopMargin,
		disabled,
		dataCy,
		children,
		pageHeader,
		disableHoverListener = false,
		...otherProps
	} = props;
	const classes = useButtonStyle({ hasRightMargin, hasTopMargin });
	const rippleClasses = primary ? usePrimaryButtonRippleStyle() : useRippleStyle();

	let useSize = true;
	let className;
	if (!primary && !secondary) {
		if (size === LARGE) {
			className = classes.largeDefault;
		} else if (size === MEDIUM) {
			className = classes.mediumDefault;
		} else if (size === SMALL) {
			className = classes.smallDefault;
		} else if (size === SMALL_SQUARE) {
			useSize = false;
			className = classes.smallSquareDefault;
		} else if (size === VERY_SMALL) {
			useSize = false;
			className = classes.verySmallDefault;
		}
	} else {
		className = classes.default;
	}

	const buttonClasses = secondary && pageHeader ? usePageHeaderButtonStyle() : {};

	return (
		<Tooltip placement={tooltipPlacement} disableHoverListener={disableHoverListener} title={title != children ? title : ''} className={fullWidth ? classes.fullWidth : ''}>
			<span className={classes.tooltip}>
				<Button
					className={clsx(
						className,
						{
							[classes.groupFirst]: groupPosition === GROUP_POSITION_FIRST,
							[classes.groupMiddle]: groupPosition === GROUP_POSITION_MIDDLE,
							[classes.groupLast]: groupPosition === GROUP_POSITION_LAST,
						},
						additionalClassName,
					)}
					classes={buttonClasses}
					TouchRippleProps={{ classes: rippleClasses }}
					size={useSize ? size : undefined}
					disabled={disabled}
					variant={variant || (primary ? 'contained' : pageHeader ? 'text' : secondary ? 'outlined' : undefined)}
					color={color || (primary ? 'primary' : secondary ? 'secondary' : undefined)}
					onClick={onClick}
					aria-label={title}
					fullWidth={fullWidth}
					data-cy={dataCy}
					{...otherProps}
				>
					{children}
				</Button>
			</span>
		</Tooltip>
	);
};

ButtonWithTooltip.propTypes = {
	className: PropTypes.string,
	title: PropTypes.string,
	tooltipPlacement: PropTypes.oneOf([
		TOP_START,
		TOP,
		TOP_END,
		RIGHT_START,
		RIGHT,
		RIGHT_END,
		BOTTOM_END,
		BOTTOM,
		BOTTOM_START,
		LEFT_END,
		LEFT,
		LEFT_START,
	]),
	primary: PropTypes.bool,
	secondary: PropTypes.bool,
	variant: PropTypes.string,
	color: PropTypes.string,
	size: PropTypes.oneOf([LARGE, MEDIUM, SMALL, SMALL_SQUARE, VERY_SMALL]),
	groupPosition: PropTypes.oneOf([GROUP_POSITION_FIRST, GROUP_POSITION_MIDDLE, GROUP_POSITION_LAST]),
	onClick: PropTypes.func,
	fullWidth: PropTypes.bool,
	hasRightMargin: PropTypes.bool,
	hasTopMargin: PropTypes.bool,
	disabled: PropTypes.bool,
	pageHeader: PropTypes.bool,
	dataCy: PropTypes.string,
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

ButtonWithTooltip.defaultProps = {
	className: undefined,
	title: '',
	tooltipPlacement: BOTTOM,
	primary: false,
	secondary: false,
	variant: undefined,
	color: undefined,
	size: MEDIUM,
	groupPosition: undefined,
	fullWidth: false,
	hasRightMargin: false,
	hasTopMargin: false,
	disabled: false,
	dataCy: undefined,
	pageHeader: false,
	onClick: undefined,
};

export default ButtonWithTooltip;
