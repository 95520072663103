import { useState, useEffect } from "react";

function useToolbarWidth(viewer) {
	const [widthWebviewer, setWidthWebviewer] = useState(0);

	useEffect(() => {
		const observer = new ResizeObserver((entries) => {
			setWidthWebviewer(entries[0].contentRect.width);
		});
		observer.observe(viewer.current);
		return () => viewer.current && observer.unobserve(viewer.current);
	}, [viewer.current]);

	useEffect(() => {
		if (viewer?.current?.clientWidth) {
			setWidthWebviewer(viewer.current.clientWidth);
		}
	}, [viewer?.current?.clientWidth]);

	return [widthWebviewer];
}

export default useToolbarWidth;
