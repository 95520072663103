import isEqual from "lodash/fp/isEqual";
import {
	GET_TIMERS_FULFILLED,
	GET_TIMERS_PENDING,
	GET_VOTING_DATA_FULFILLED,
	GET_VOTING_DATA_PENDING,
	GET_VOTING_SETTINGS_FULFILLED,
	GET_VOTING_SETTINGS_PENDING,
	PERSIST_ROLLCALL_FULFILLED,
	PERSIST_ROLLCALL_PENDING,
	POST_BOXCAST_INTERMISSION_FULFILLED,
	POST_BOXCAST_INTERMISSION_PENDING,
	UPDATE_ONLINE_VOTERS,
	UPDATE_TIMERS,
	ADOPT_PUBLISH_PREVIOUS_MINUTES_PENDING,
	ADOPT_PUBLISH_PREVIOUS_MINUTES_FULFILLED,
	OPEN_DRAFT_ADOPT_MINUTES_PENDING,
	OPEN_DRAFT_ADOPT_MINUTES_FULFILLED,
} from "./types";

export const liveMeetingReducer = (
	state = {
		persisted: false,
		persisting: false,
		timers: { presets: [], custom: [] },
		updated: false,
		updating: false,
		adoptUpdating: false,
	},
	action,
) => {
	switch (action.type) {
		case GET_TIMERS_PENDING: {
			state = {
				...state,
				updating: true,
				updated: false,
			};
			break;
		}
		case GET_TIMERS_FULFILLED: {
			state = {
				...state,
				timers: action.payload,
				updating: false,
				updated: true,
			};
			break;
		}

		case GET_VOTING_DATA_PENDING: {
			// No state change
			break;
		}

		case GET_VOTING_DATA_FULFILLED: {
			const { votingData } = state;
			const { updateData, payload: newVotingData } = action;

			if (updateData && !isEqual(votingData, newVotingData)) {
				// Only update state if the voting data has changed
				state = {
					...state,
					votingData: newVotingData,
				};
			}
			break;
		}

		case GET_VOTING_SETTINGS_PENDING: {
			state = {
				...state,
				updating: true,
				updated: false,
			};
			break;
		}
		case GET_VOTING_SETTINGS_FULFILLED: {
			state = {
				...state,
				votingSettings: action.payload,
				updating: false,
				updated: true,
			};
			break;
		}
		case PERSIST_ROLLCALL_PENDING: {
			state = {
				...state,
				persisting: true,
				persited: false,
			};
			break;
		}

		case PERSIST_ROLLCALL_FULFILLED: {
			state = {
				...state,
				persisting: false,
				persited: true,
			};
			break;
		}

		case POST_BOXCAST_INTERMISSION_PENDING: {
			state = {
				...state,
				updated: false,
				updating: true,
			};
			break;
		}
		case POST_BOXCAST_INTERMISSION_FULFILLED: {
			state = {
				...state,
				updated: true,
				updating: false,
			};
			break;
		}

		case UPDATE_ONLINE_VOTERS: {
			state = {
				...state,
				onlineVoters: action.onlineVoters,
			};
			break;
		}
		case UPDATE_TIMERS: {
			state = {
				...state,
				timers: action.timers,
			};
			break;
		}

		case ADOPT_PUBLISH_PREVIOUS_MINUTES_PENDING: {
			state = {
				...state,
				adoptUpdating: true,
			};
			break;
		}
		case ADOPT_PUBLISH_PREVIOUS_MINUTES_FULFILLED: {
			state = {
				...state,
				adoptUpdating: false,
			};
			break;
		}
		case OPEN_DRAFT_ADOPT_MINUTES_PENDING: {
			state = {
				...state,
				adoptUpdating: true,
			};
			break;
		}
		case OPEN_DRAFT_ADOPT_MINUTES_FULFILLED: {
			state = {
				...state,
				adoptUpdating: false,
			};
			break;
		}

		default: {
			break;
		}
	}

	return state;
};

export default liveMeetingReducer;
