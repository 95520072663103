import React from "react";
import { Routes, Route } from "react-router-dom";

import PublicDocumentsModule from "views/Documents/PublicDocumentsModule";
import DocumentContainer from "views/Documents/DocumentContainer";

const PublicDocumentRoutes = (props) => {
	const { list = false } = props;

	// Base path = /public/documents/ (list = true) or /public/document/ (list = false)
	return (
		<Routes>
			{list && <Route path="/" element={<PublicDocumentsModule />} />}
			<Route path=":id" element={list ? <PublicDocumentsModule /> : <DocumentContainer />} />
		</Routes>
	);
};

export default PublicDocumentRoutes;
