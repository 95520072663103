import React, { useState, useContext, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import makeStyles from "@mui/styles/makeStyles";

import tableDataStyle from "assets/jss/components/tableDataStyle";
import { focusColor } from "atlas/assets/jss/shared";
import Icon from "atlas/components/Icon/Icon";
import Tooltip from "atlas/components/Tooltip/Tooltip";
import { UserContext } from "contexts/User/UserContext";
import telemetryAddEvent from "utils/telemetryAddEvent";
import { getPathForEditPage } from "views/Policy/utils/getPathForEditPage";
import { DOCUMENT_TREE_INLINE_PADDING } from "./DocumentTreeItem";
import getIconName from "../utils/documentIcon";
import getPathType from "../utils/pathType";
import { useTranslation } from "react-i18next";

const expandIconSize = 12;
const useStyles = makeStyles((theme) => ({
	expandIcon: {
		width: `${expandIconSize}px`,
		flexShrink: "0",
		paddingLeft: ({ depth }) => `${DOCUMENT_TREE_INLINE_PADDING * 2 * depth}px`,
		"&[data-cy='expand']": {
			cursor: "pointer",
		},
		"&:focus-visible": {
			outline: `solid 2px ${focusColor}`,
			outlineOffset: "-2px",
			borderRadius: "4px",
		},
	},
	icon: {
		width: ({ iconSize }) => `${iconSize}px`,
		flexShrink: "0",
	},
	title: {
		flexGrow: "1",
		flexShrink: "1",
		overflow: "hidden",
		textOverflow: "ellipsis",
		display: "-webkit-box",
		WebkitBoxOrient: "vertical",
		WebkitLineClamp: 1,
		flexShrink: "1",
		[theme.breakpoints.down("md")]: {
			overflow: "visible",
			textOverflow: "initial",
			display: "block",
		},
	},
	link: {
		...tableDataStyle.link,
		wordBreak: "break-word",
		"&:focus-visible": {
			outline: `solid 2px ${focusColor}`,
			outlineOffset: "-2px",
			borderRadius: "4px",
		},
	},
	isPolicyRescinded: {
		...tableDataStyle.link,
		fontWeight: "bold",
		textWrap: "nowrap",
	},
}));

const DocumentTreeTitleItemColumn = (props) => {
	const { document, type, inPortal, depth, handleEvent, telemetryPage } = props;
	const navigate = useNavigate();
	const [truncated, setTruncated] = useState(false);
	const { publicUser } = useContext(UserContext).user;
	const iconSize = inPortal ? 20 : 24;
	const classes = useStyles({ iconSize, depth });
	const linkRef = useRef(null);
	const { t } = useTranslation("documents");

	const isPolicy = Object.hasOwn(document, "code");
	const typePath = getPathType(type);
	let link = "";
	if (isPolicy) {
		if (inPortal) {
			link = document.folder ? `/policies/policybook/${document.guid}` : `/home/policies/policydoc/${document.guid}`;
		} else {
			link = document.folder ? `/policy/policybook/${document.guid}` : `/home${getPathForEditPage(document.guid, document.publishedGuid)}`;
		}
	} else {
		link = document.folder
			? publicUser
				? `/public/documents/${document.guid}`
				: `/documents/${typePath}/${document.guid}`
			: publicUser
				? `/home/public/document/${document.guid}`
				: `/home/document/${document.guid}`;
	}

	const handleExpand = () => {
		handleEvent({ eventName: "expand", document });
	};

	const handleKeyDown = (e) => {
		if (e.key === "Enter" || e.keyCode === 32) {
			e.preventDefault();
			handleExpand();
		}
	};

	const linkClick = (e) => {
		e.preventDefault();

		if (document.folder) {
			if (inPortal && window.top && window.top !== window) {
				window.top.location.href = isPolicy
					? `/Portal/Policy.aspx?id=${document.guid}`
					: `/Portal/DocumentLibrary.aspx?id=${document.guid}`;
			} else {
				navigate(link);
			}
		} else {
			// Avoid the react-router-dom basename
			if (inPortal && window.top && window.top !== window) {
				telemetryAddEvent(`${telemetryPage} - Library - View Document in Portal`, { area: "documents" });

				setTimeout(() => {
					window.top.location.href = link;
				}, 50);
			} else {
				telemetryAddEvent(`${telemetryPage} - Library - View Document by Authenticated User`, { area: "documents" });

				setTimeout(() => {
					window.location.href = link;
				}, 50);
			}
		}
	};

	const getTitle = () => (isPolicy ? (document.code ? document.code + " " : "") + document.title : document.title);

	const getPolicyRescindedStatus = () =>
		document.isPolicyRescinded ? <span className={classes.isPolicyRescinded}>{t("policyRescinded.label")}</span> : null;

	useEffect(() => {
		if (linkRef.current) {
			setTruncated(
				linkRef.current.scrollWidth > linkRef.current.offsetWidth || linkRef.current.offsetHeight > linkRef.current.scrollHeight,
			);
		}
	}, [linkRef.current]);

	const extension = !isPolicy && !document.folder ? document.extension : "";
	const fullTitle = getTitle() + extension;
	const canExpand = document && document.folder && document.hasChildren;
	const expandProps = canExpand
		? {
				["aria-hidden"]: "true",
				onKeyDown: handleKeyDown,
				onClick: handleExpand,
				["data-cy"]: "expand",
			}
		: {};

	return (
		<>
			<div className={classes.expandIcon} {...expandProps}>
				{canExpand && <Icon name={document.expanded ? "expand-down" : "expand-right"} size="12" />}
			</div>
			<div className={classes.icon} title={!document.folder ? document.extension : undefined}>
				<Icon name={getIconName(document, isPolicy)} size={inPortal ? "20" : "24"} />
			</div>
			<div className={classes.title}>
				<Tooltip
					PopperProps={{ disablePortal: true }}
					placement={isPolicy ? "top" : "right-start"}
					title={truncated ? fullTitle : undefined}
				>
					<a
						id={`link-${document.guid}`}
						className={classes.link}
						href={`/home${link}`.replace("home/home", "home")}
						onClick={linkClick}
						ref={linkRef}
					>
						{isPolicy && getPolicyRescindedStatus()} {fullTitle}
					</a>
				</Tooltip>
			</div>
		</>
	);
};

export default DocumentTreeTitleItemColumn;
