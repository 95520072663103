import typographyStyle from './typographyStyle';
import { STATUS_DEFAULT, STATUS_GOOD, STATUS_WARNING } from '../utils/statusIndicators';
import { grayColor, primaryColor, successColor, warningColor } from '../shared';

const cardStyle = () => ({
	card: {
		margin: '0 !important',
		padding: '8px 16px 16px 16px',
		borderRadius: '0 3px 3px 0',
		boxShadow: '0 2px 10px 0 rgba(0, 0, 0, 0.1) !important',
		minWidth: '288px',
		maxWidth: '456px',
		boxSizing: 'border-box',
		[`&.${STATUS_DEFAULT}`]: {
			borderLeft: `8px solid ${grayColor[0]}`,
		},
		[`&.${STATUS_GOOD}`]: {
			borderLeft: `8px solid ${successColor}`,
		},
		[`&.${STATUS_WARNING}`]: {
			borderLeft: `8px solid ${warningColor}`,
		},
	},
	title: {
		...typographyStyle.text,
		...typographyStyle.semiBold,
		cursor: 'pointer',
		color: primaryColor[1],
	},
	titleEllipsis: {
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
		'& > span': {
			whiteSpace: 'nowrap',
		},
	},
	subTitle: {
		...typographyStyle.smallText,
		...typographyStyle.gray,
		marginTop: '6px',
		'&:first-letter': {
			textTransform: 'uppercase',
		},
	},
	buttons: {
		margin: '0 -16px',
		width: 'calc(100% + 32px)',
	},
	agendaItemTitle: {
		display: 'flex',
	},
});

export default cardStyle;
