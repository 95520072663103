import React, { Component } from "react";
import { withTranslation } from "react-i18next";

import Typography from "@mui/material/Typography";

import ComponentContainer from "atlas/components/ComponentContainer/ComponentContainer";
import OutlinedInput from "atlas/components/FormControls/OutlinedInput";
import { useWidthUp } from "atlas/utils/useWidth";
import SaveCancelButtons from "./SaveCancelButtons";

const withWidth = () => (WrappedComponent) => (props) => {
	const widthUpSm = useWidthUp("sm");

	return <WrappedComponent {...props} widthUpSm={widthUpSm} />;
};

class EmailUpdate extends Component {
	handleKeyPress = (event) => {
		if (event.key === "Enter") {
			const { handleSave, handleBlur } = this.props;
			handleBlur();
			handleSave();
		}
	};

	render() {
		const { t, data, widthUpSm, handleCancel, handleSave, handleChange, handleBlur } = this.props;
		const { editing = {}, errors = {} } = data;

		const content = (
			<>
				<Typography variant="h5" color="textSecondary">
					{t("updateEmail")}
				</Typography>

				<OutlinedInput
					autoFocus
					className="card-textfield"
					id="email"
					label={t("newEmail")}
					value={editing.email || ""}
					helperText={errors.email}
					onChange={handleChange}
					onBlur={handleBlur}
					onKeyPress={this.handleKeyPress}
					fullWidth
					error={!!errors.email}
					data-cy="emailAddress"
				/>

				<SaveCancelButtons
					save={handleSave}
					saveEnabled={!errors.email}
					disabledTooltip={errors.email ? t("errors.validEmail") : ""}
					cancel={handleCancel}
					saveLabel={t("app:buttons.save")}
					cancelLabel={t("app:buttons.cancel")}
				/>
			</>
		);

		return <ComponentContainer maxWidth={widthUpSm ? "288px" : null}>{content}</ComponentContainer>;
	}
}

export default withWidth()(withTranslation("userProfile")(EmailUpdate));
