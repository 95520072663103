import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import TextField from '@mui/material/TextField';
import makeStyles from '@mui/styles/makeStyles';

import InputLabel from './InputLabel';
import Icon from '../Icon/Icon';

import inputStyle from '../../assets/jss/components/inputStyle';
import { focusColor } from '../../assets/jss/shared';

const useInputStyles = makeStyles(inputStyle);

const SIZE_SMALL = 'small';
const SIZE_EXTRA_SMALL = 'extra-small';

const OutlinedInput = (props) => {
	const {
		noDefaultClassName,
		className,
		id,
		label,
		size,
		multiline,
		highlightRequired,
		externalLabel,
		labelSize,
		preText,
		postText,
		marginTop,
		containerFullWidth,
		autoComplete,
		role,
		ariaLevel,
		ariaRequired,
		required,
		type,
		isKeyboardFocusRequired = false, //The :focus-visible pseudo-class can behave inconsistently across different browsers, particularly in scenarios involving mouse and keyboard inputs.
		onFocus,
		onBlur,
		...newProps
	} = props;
	const classes = useInputStyles({ marginTop, fullWidth: containerFullWidth });

	const [isKeyboardFocused, setIsKeyboardFocused] = useState(false);
	const [isKeyboard, setIsKeyboard] = useState(false);

	useEffect(() => {
		if (isKeyboardFocusRequired) {
			const handleKeyDown = (event) => {
				if (event.key === "Tab") {
					setIsKeyboard(true); // Set to true when Tab key is pressed
				}
			};

			const handleMouseDown = () => {
				setIsKeyboard(false); // Reset when mouse is used
			};

			window.addEventListener("keydown", handleKeyDown);
			window.addEventListener("mousedown", handleMouseDown);

			return () => {
				window.removeEventListener("keydown", handleKeyDown);
				window.removeEventListener("mousedown", handleMouseDown);
			};
		}
	}, [isKeyboardFocusRequired]);

	const handleFocus = () => {
		if (isKeyboardFocusRequired && isKeyboard) {
			setIsKeyboardFocused(true); // Only set true if the focus came via keyboard
		}
	};

	const handleBlur = () => {
		if (isKeyboardFocusRequired) {
			setIsKeyboardFocused(false); // Reset when the input is blurred
		}
	};

	const useExternalLabel = label && externalLabel;

	if (newProps.select) {
		newProps.SelectProps = {
			IconComponent: (iconProps) => <Icon name="expand-down" {...iconProps} />,
		};
	}

	const getField = () => (
		<TextField
			{...newProps}
			onFocus={isKeyboardFocusRequired ? handleFocus : onFocus}  // Corrected conditional event handler
			onBlur={isKeyboardFocusRequired ? handleBlur : onBlur}
			className={clsx(
				{
					[classes.defaultInput]: !noDefaultClassName,
				},
				className,
				{
					[classes.smallInput]: size === SIZE_SMALL,
					[classes.smallInputExternalLabel]: externalLabel && size === SIZE_SMALL,
					[classes.extraSmallInput]: size === SIZE_EXTRA_SMALL,
					[classes.extraSmallInputExternalLabel]: externalLabel && size === SIZE_EXTRA_SMALL,
					[classes.required]: highlightRequired,
				},
			)}
			id={id}
			label={!externalLabel ? label : undefined}
			size={size === SIZE_EXTRA_SMALL ? SIZE_SMALL : size}
			variant="outlined"
			multiline={multiline}
			required={required}
			type={type}
			sx={{
				...(isKeyboardFocused && {
				  outline: `2px solid ${focusColor}`, // Apply when keyboard-focused
				  outlineOffset: "3px",
				  borderRadius: "4px",
				}),
				// Existing default styles remain unchanged when not focused
			  }}
			inputProps={{
				autoComplete: autoComplete,
				'aria-required': ariaRequired,
			}}
		/>
	);

	const getFieldContainer = () =>
		preText || postText ? (
			<div className={classes.inputRowContainer}>
				{preText && <span className={classes.preText}>{preText}</span>}
				{getField()}
				{postText && <span className={classes.postText}>{postText}</span>}
			</div>
		) : (
			getField()
		);

	const getExternalLabel = () =>
		useExternalLabel ? <InputLabel htmlFor={id} label={label} size={labelSize} role={role} ariaLevel={ariaLevel} bottomSpacing /> : null;
	return (
		<div className={classes.container}>
			{useExternalLabel && getExternalLabel()}
			{getFieldContainer()}
		</div>
	);
};

OutlinedInput.propTypes = {
	noDefaultClassName: PropTypes.bool,
	className: PropTypes.string,
	id: PropTypes.string,
	label: PropTypes.string,
	size: PropTypes.string,
	multiline: PropTypes.bool,
	externalLabel: PropTypes.bool,
	labelSize: PropTypes.string,
	preText: PropTypes.string,
	postText: PropTypes.string,
	marginTop: PropTypes.string,
};

OutlinedInput.defaultProps = {
	noDefaultClassName: false,
	className: undefined,
	id: undefined,
	label: undefined,
	size: undefined,
	multiline: false,
	externalLabel: false,
	labelSize: undefined,
	preText: undefined,
	postText: undefined,
	marginTop: undefined,
};

export default OutlinedInput;
