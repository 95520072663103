import { Plugin } from "@ckeditor/ckeditor5-core";
import ButtonView from "@ckeditor/ckeditor5-ui/src/button/buttonview";
import ClickObserver from "@ckeditor/ckeditor5-engine/src/view/observer/clickobserver";
import ContextualBalloon from "@ckeditor/ckeditor5-ui/src/panel/balloon/contextualballoon";
import ClickOutsideHandler from "@ckeditor/ckeditor5-ui/src/bindings/clickoutsidehandler";

import InlineFileViewActions from "../inlineFileViewActions";
import InlineFileViewFormMO from "./inlineFileViewFormMO";

import { isLinkElement, getFileHrefInfo, getRootNameFromSelection, isAttachmentValue } from "../inlineFileUtils";
import clickOutsideHandler from "@ckeditor/ckeditor5-ui/src/bindings/clickoutsidehandler";

import forEach from "lodash/fp/forEach";
import { v4 as uuid } from "uuid";
import InlineFileFeatureMO from "./inlineFileFeatureMO";

const linkKeystroke = "Ctrl+M";
const linkIcon = `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="21px" height="24px" viewBox="0 0 21 24" version="1.1"><path d="M15,15.2 L15,8.8 C15,6.92223185 13.4777681,5.4 11.6,5.4 C9.72223185,5.4 8.2,6.92223185 8.2,8.8 L8.2,20 C8.2,22.7614237 10.4385763,25 13.2,25 C14.5260824,25 15.797852,24.4732158 16.7355339,23.5355339 C17.6732158,22.597852 18.2,21.3260824 18.2,20 L18.2,5.6 C18.2,1.95492065 15.2450793,-1 11.6,-1 C7.95492065,-1 5,1.95492065 5,5.6 L5,15.2 C5,15.7522847 5.44771525,16.2 6,16.2 C6.55228475,16.2 7,15.7522847 7,15.2 L7,5.6 C7,3.05949015 9.05949015,1 11.6,1 C14.1405098,1 16.2,3.05949015 16.2,5.6 L16.2,20 C16.2,20.7956495 15.8839295,21.5587112 15.3213203,22.1213203 C14.7587112,22.6839295 13.9956495,23 13.2,23 C11.5431458,23 10.2,21.6568542 10.2,20 L10.2,8.8 C10.2,8.02680135 10.8268014,7.4 11.6,7.4 C12.3731986,7.4 13,8.02680135 13,8.8 L13,15.2 C13,15.7522847 13.4477153,16.2 14,16.2 C14.5522847,16.2 15,15.7522847 15,15.2 Z" transform="translate(11.600000, 12.000000) rotate(-315.000000) translate(-11.600000, -12.000000) "/></svg>`;

export default class InlineFileUIMO extends Plugin {
	static get requires() {
		[ContextualBalloon];
	}

	init() {
		const editor = this.editor;
		this._executeInit(editor);
	}

	_executeInit(editor) {
		const config = editor.config.get("inlineFile");
		this.editingLink = false;
		this.parentId = config.parentId;
		this.urlBase = config.urlBase;
		this.anchorTitle = config.anchorTitle ? config.anchorTitle : "";
		if (config.translations) {
			this.translations = config.translations;
		} else {
			this.translations = {};
		}
		this.linkCommand = editor.commands.get("inlineFileLinkMO");
		this.unlinkCommand = editor.commands.get("inlineFileUnlinkMO");
		//
		// check to see if the features was passed in, and it is an array
		// if not, then create an empty array
		//
		if (!Array.isArray(config.features)) {
			config.features = [];
		}

		this._createFeatures(config.features, this.linkCommand.featureConfigs);
		if (this.anchorTitle) {
			this.linkCommand.anchorTitle = this.anchorTitle;
		}

		this.functions = config.functions;
		this.allowedFileExtensions = config.allowedFileExtensions;

		editor.editing.view.addObserver(ClickObserver);

		this._balloon = editor.plugins.get(ContextualBalloon);
		//
		// positionLimiter is an HTML element, that will restrict the balloon position so it falls within the element provided
		// if positionLimiter is not provided, it will default to ck editor position function
		//
		this.positionLimiter = config.positionLimiter;
		if (this.positionLimiter) {
			this._balloon.positionLimiter = () => {
				return this.positionLimiter;
			};
		}

		this.actionsView = this._createActionsView();
		this.formView = this._createFormView();
		this._createToolbarLinkButton();
		this._enableUserBalloonInteractions();
	}

	destroy() {
		super.destroy();
		this.formView.destroy();
	}

	_createFeatures(config, features) {
		config.forEach((feature) => {
			features.add(
				new InlineFileFeatureMO({
					id: feature.id,
					label: feature.label,
					className: feature.className,
					defaultValue: true,
					disabledValue: true,
					isEnabled: false,
					anchorTitle: feature.anchorTitle,
					tooltipDisabledOn: feature.tooltipDisabledOn,
				}),
			);
		});
	}

	_createToolbarLinkButton() {
		const editor = this.editor;
		const linkCommand = editor.commands.get("inlineFileLinkMO");
		const t = editor.t;

		editor.keystrokes.set(linkKeystroke, (keyEvtData, cancel) => {
			cancel();
			if (editor.commands.get("inlineFileLinkMO").isEnabled) {
				this._showUI(true);
			}
		});

		editor.ui.componentFactory.add("inlineFileMO", (locale) => {
			const button = new ButtonView(locale);

			button.isEnabled = true;
			button.label = t("Attach File");
			button.icon = linkIcon;
			button.keystroke = linkKeystroke;
			button.tooltip = true;
			button.isToggleable = true;

			button.bind("isEnabled").to(linkCommand, "isEnabled");
			button.bind("isOn").to(linkCommand, "value", isAttachmentValue);

			this.listenTo(button, "execute", () => this._showUI(true));
			return button;
		});
	}
	//
	// this is used for existing links to either update or unlink
	//
	_createActionsView() {
		const editor = this.editor;
		const actionsView = new InlineFileViewActions(editor, this.translations);
		const linkCommand = editor.commands.get("inlineFileLinkMO");
		const unlinkCommand = editor.commands.get("inlineFileUnlinkMO");

		actionsView.bind("href").to(linkCommand, "value");
		actionsView.editButtonView.bind("isEnabled").to(linkCommand);
		actionsView.unlinkButtonView.bind("isEnabled").to(unlinkCommand);

		this.listenTo(actionsView, "edit", () => {
			this.editingLink = true;
			this._addFormView();
		});

		this.listenTo(actionsView, "unlink", () => {
			const href = actionsView.href;
			const hrefInfo = getFileHrefInfo(href);

			const info = {
				parentId: this.parentId,
				hrefGuid: hrefInfo.guid,
				editor: this.editor,
				functions: this.functions,
			};
			this._hideUI();
			editor.execute("inlineFileUnlinkMO", { info: info }, { cb: unlinkAttachment });
		});

		actionsView.keystrokes.set("Esc", (data, cancel) => {
			this._hideUI();
			cancel();
		});

		actionsView.keystrokes.set(linkKeystroke, (data, cancel) => {
			this._addFormView();
			cancel();
		});

		return actionsView;
	}
	//
	// this is used for new links
	//
	_createFormView() {
		const editor = this.editor;
		const linkCommand = editor.commands.get("inlineFileLinkMO");

		const formView = new InlineFileViewFormMO(editor.locale, this.allowedFileExtensions, this.translations, linkCommand.featureConfigs);
		formView.bind("href").to(linkCommand, "value");
		formView.urlInputView.bind("isReadOnly").to(linkCommand, "isEnabled", (value) => !value);
		formView.saveButtonView
			.bind("isEnabled")
			.to(linkCommand, "isEnabled", linkCommand, "value", formView, "files", (isEnabled, value, files) => {
				if (!isEnabled) {
					return false;
				}
				if (value == null && (files == null || files.length === 0)) {
					return false;
				}
				return true;
			});
		formView.saveButtonView.bind("label").to(linkCommand, "value", formView, "files", (value, files) => {
			if (value == null && (files == null || files.length === 0)) {
				return this.translations ? this.translations.tooltips.editConfirmNoFiles : "Select an attachment to proceed";
			}
			return this.translations ? this.translations.tooltips.editConfirm : "Save";
		});

		this.listenTo(formView, "submit", () => {
			const fileHref = this.formView.href;
			const files = formView.getFilesToUpload();
			const featureStatuses = formView.getFeatureStatuses();
			if (files.length > 0) {
				const fileUploads = [];
				const failedUploads = [];
				for (let i = 0; i < files.length; i++) {
					const fileExtension = files[i].name.split(".").pop();
					if (this.allowedFileExtensions.includes(fileExtension.toLowerCase())) {
						const fileGuid = uuid();
						fileUploads.push({
							name: files[i].name,
							file: files[i],
							guid: fileGuid,
							url: `${this.urlBase}/${fileGuid}`,
						});
					} else {
						failedUploads.push(files[i].name);
					}
				}

				if (failedUploads.length > 0) {
					this.functions.onInvalidExtension(this.editor, this.parentId, failedUploads, this.linkCommand.rootName);
				}

				if (fileUploads.length > 0) {
					this._hideUI();

					const info = {
						parentId: this.parentId,
						files: fileUploads,
						fileHref: fileHref,
						oldHref: null,
						urlBase: this.urlBase,
						editor: this.editor,
						functions: this.functions,
						featureConfigs: linkCommand.featureConfigs,
						featureStatuses: featureStatuses,
					};
					editor.execute("inlineFileLinkMO", { info: info }, { cb: linkAttachment });
				}
			} else {
				this._hideUI();

				const info = {
					parentId: this.parentId,
					files: files,
					fileHref: fileHref,
					oldHref: null,
					urlBase: this.urlBase,
					editor: this.editor,
					functions: this.functions,
					featureConfigs: linkCommand.featureConfigs,
					featureStatuses: featureStatuses,
				};
				editor.execute("inlineFileLinkMO", { info: info }, { cb: linkAttachment });
			}
		});

		this.listenTo(formView, "cancel", () => {
			this._closeFormView();
		});

		formView.keystrokes.set("Esc", (data, cancel) => {
			this._closeFormView();
			cancel();
		});

		return formView;
	}

	_addActionsView(isButtonView = true) {
		if (this._areActionsInPanel) {
			return;
		}
		if (isButtonView) {
			this.actionsView.editButtonView.isVisible = true;
		} else {
			this.actionsView.editButtonView.isVisible = false;
		}

		this._balloon.add({
			view: this.actionsView,
			position: this._getBalloonPositionData(),
		});
	}

	_addFormView() {
		if (this._isFormInPanel) {
			this.formView.resetForm();
			return;
		}

		this.formView.resetForm();
		this._balloon.add({
			view: this.formView,
			position: this._getBalloonPositionData(),
		});
	}

	_closeFormView() {
		const linkCommand = this.editor.commands.get("inlineFileLinkMO");

		if (linkCommand.value !== undefined) {
			this._removeFormView();
		} else {
			this._hideUI();
		}
	}

	_removeFormView() {
		if (this._isFormInPanel) {
			this.formView.saveButtonView.focus();
			this.formView.resetFiles();
			this._balloon.remove(this.formView);
			this.editor.editing.view.focus();
		}
	}

	_showUI(forceVisible = false) {
		const editor = this.editor;
		this._balloon.view.element.parentElement.classList.remove("ck-reset_all");
		const linkCommand = editor.commands.get("inlineFileLinkMO");

		if (!linkCommand.isEnabled) {
			return;
		}

		const selectedLinkElement = this._getSelectedLinkElement();
		if (!selectedLinkElement) {
			this._addActionsView();
			if (forceVisible) {
				this._balloon.showStack("main");
			}
			this._addFormView();
		} else {
			if (this._areActionsVisible) {
				this._addFormView();
			} else {
				if (selectedLinkElement._classes.has("inlineLink")) {
					this._addActionsView(false);
				} else {
					this._addActionsView();
				}
			}
			if (forceVisible) {
				this._balloon.showStack("main");
			}
		}
		this._startUpdatingUI();
	}

	_hideUI() {
		this.editingLink = false;
		if (!this._isUIInPanel) {
			return;
		}

		const editor = this.editor;
		this.stopListening(editor.ui, "update");
		this.stopListening(this._balloon, "change:visibleView");
		editor.editing.view.focus();
		this._removeFormView();
		this._balloon.remove(this.actionsView);
	}

	_startUpdatingUI() {
		const editor = this.editor;
		const viewDocument = editor.editing.view.document;

		let prevSelectedLink = this._getSelectedLinkElement();
		let prevSelectionParent = getSelectionParent();

		const update = () => {
			const selectedLink = this._getSelectedLinkElement();
			const selectionParent = getSelectionParent();

			if ((prevSelectedLink && !selectedLink) || (!prevSelectedLink && selectionParent !== prevSelectionParent)) {
				this._hideUI();
			} else if (this._isUIVisible) {
				this._balloon.updatePosition(this._getBalloonPositionData());
			}
			prevSelectedLink = selectedLink;
			prevSelectionParent = selectionParent;
		};

		function getSelectionParent() {
			const ancestors = viewDocument.selection.focus.getAncestors();
			return ancestors.reverse().find((node) => node.is("element"));
		}

		this.listenTo(editor.ui, "update", update);
		this.listenTo(this._balloon, "change:visibleView", update);
	}

	_enableUserBalloonInteractions() {
		const viewDocument = this.editor.editing.view.document;

		this.listenTo(viewDocument, "click", () => {
			const parentLink = this._getSelectedLinkElement();

			if (parentLink) {
				this._showUI();
			}
		});

		this.editor.keystrokes.set(
			"Tab",
			(data, cancel) => {
				if (this._areActionsVisible && !this.actionsView.focusTracker.isFocused) {
					this.actionsView.focus();
					cancel();
				}
			},
			{
				priority: "high",
			},
		);

		this.editor.keystrokes.set("Esc", (data, cancel) => {
			if (this._isUIVisible) {
				this._hideUI();
				cancel();
			}
		});

		clickOutsideHandler({
			emitter: this.formView,
			activator: () => this._isUIInPanel,
			contextElements: [this._balloon.view.element],
			callback: () => this._hideUI(),
		});
	}

	_getSelectedLinkElement() {
		const view = this.editor.editing.view;
		const selection = view.document.selection;
		this.linkCommand.rootName = getRootNameFromSelection(selection);
		this.unlinkCommand.rootName = this.linkCommand.rootName;

		if (selection.isCollapsed) {
			return findLinkElementAncestor(selection.getFirstPosition());
		} else {
			const range = selection.getFirstRange().getTrimmed();
			const startLink = findLinkElementAncestor(range.start);
			const endLink = findLinkElementAncestor(range.end);

			if (!startLink || startLink != endLink) {
				return null;
			}

			if (view.createRangeIn(startLink).getTrimmed().isEqual(range)) {
				return startLink;
			} else {
				return null;
			}
		}
	}

	_getBalloonPositionData() {
		const view = this.editor.editing.view;
		const viewDocument = view.document;
		const targetLink = this._getSelectedLinkElement();

		const target = targetLink
			? view.domConverter.mapViewToDom(targetLink)
			: view.domConverter.viewRangeToDom(viewDocument.selection.getFirstRange());

		return { target };
	}

	get _isFormInPanel() {
		return this._balloon.hasView(this.formView);
	}
	get _areActionsInPanel() {
		return this._balloon.hasView(this.actionsView);
	}
	get _areActionsVisible() {
		return this._balloon.visibleView === this.actionsView;
	}
	get _isUIInPanel() {
		return this._isFormInPanel || this._areActionsInPanel;
	}
	get _isUIVisible() {
		const visibleView = this._balloon.visibleView;
		return visibleView == this.formView || this._areActionsVisible;
	}
}

function findLinkElementAncestor(position) {
	const ancestors = position.getAncestors();
	return ancestors.find((ancestor) => isLinkElement(ancestor));
}

function linkAttachment(info) {
	const { editor, functions, files, parentId, featureConfigs, featureStatuses, rootName } = info;
	if (files.length === 0) {
		return;
	}
	//
	// Build array of active features and add to each file to be uploaded
	//
	const features = [];
	if (featureStatuses && featureConfigs) {
		forEach((featureStatus) => {
			if (featureStatus.value === true) {
				const featureConfig = featureConfigs.get(featureStatus.id);
				if (featureConfig) {
					features.push({ id: featureConfig.id, className: featureConfig.className, anchorTitle: featureConfig.anchorTitle });
				}
			}
		}, featureStatuses);
	}
	forEach((file) => {
		file.features = features;
	}, files);

	functions.onAddFiles(editor, parentId, files, rootName);
}

function unlinkAttachment(info) {
	const { editor, functions, parentId, hrefGuid, rootName } = info;
	functions.onDeleteFile(editor, parentId, hrefGuid, rootName);
}
