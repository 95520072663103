import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import request from "superagent";
import clsx from "clsx";

import { Box, Typography, Grid, TableCell } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { API_HOST } from "config/env";
import CircularProgressIndicator from "atlas/components/Progress/CircularProgressIndicator";
import TableData from "atlas/components/TableData/TableData";
import { blackColor } from "atlas/assets/jss/shared";
import entityListStyle from "assets/jss/components/entityListStyle";
import BoardDeleteDialog from "./BoardDeleteDialog";
import notifierMessage from "utils/notifierMessage";
import { setSnackbarOptions } from "redux/snackBar/actions";

const useStyles = makeStyles(entityListStyle);

const BoardList = (props) => {
	const { boards, loadBoards, showSignIn } = props;
	const dispatch = useDispatch();
	const { t } = useTranslation("boards");
	const navigate = useNavigate();
	const [dialogs, setDialogs] = useState({});
	const [boardToDelete, setBoardToDelete] = useState(null);
	const classes = useStyles();

	const closeDeleteDialog = () => {
		setDialogs({});
		setBoardToDelete(null);
	};

	const afterDelete = () => {
		loadBoards();
	};

	const undoDelete = (deletedBoard) => {
		request
			.post(`${API_HOST}/api/board/${deletedBoard.id}/restore`)
			.withCredentials()
			.send({ meetingTypes: deletedBoard.meetingTypes })
			.then(() => {
				let option = notifierMessage(t("deleteBoardDialog.undo.successful"), "success");
				dispatch(setSnackbarOptions(option));

				loadBoards();
			})
			.catch((err) => {
				showSignIn(err, () => {
					undoDelete(deletedBoard);
				});
			});
	};

	const boardTableHeadings = [
		{
			id: "name",
			disablePadding: false,
			label: t("list.header.name"),
			retainCase: true,
			rowColor: blackColor[1],
			component: (props) => {
				const { row } = props;

				return (
					<TableCell className={classes.cell}>
						<Grid container spacing={2}>
							<Grid container item xs={12}>
								<div className={classes.detail}>
									<a
										className={clsx("noStyle", classes.link)}
										href={`/home/boards/edit/${row.id}`}
										onClick={(e) => {
											navigate(`/boards/edit/${row.id}`);

											e.preventDefault();
										}}
									>
										<Typography variant="body1">{row.name}</Typography>
									</a>
								</div>
							</Grid>
						</Grid>
					</TableCell>
				);
			},
		},
	];

	return boards ? (
		boards.length > 0 ? (
			<>
				<TableData
					showToolbar={false}
					headCells={boardTableHeadings}
					rows={boards}
					selectProperty="id"
					defaultOrderBy="name"
					rowClickAction={() => {}}
					withoutMultiSelect={true}
					overrideClasses={classes}
					iconName="remove"
					data-cy="board-list"
				/>
				{dialogs.delete && boardToDelete && (
					<BoardDeleteDialog
						board={boardToDelete}
						hasMeetingTypes={boardToDelete.meetingTypes.length > 0}
						show={dialogs.delete}
						afterDelete={afterDelete}
						onClose={closeDeleteDialog}
						undoDelete={undoDelete}
					/>
				)}
			</>
		) : (
			<div className={classes.noResults}>
				<Box>
					<Typography variant="h3">{t("list.noResults.title")}</Typography>
				</Box>
				<Box mt={3}>
					<Typography variant="body2">{t("list.noResults.description")}</Typography>
				</Box>
			</div>
		)
	) : (
		<CircularProgressIndicator />
	);
};

export default BoardList;
