import {
	STATUS_DEFAULT,
	STATUS_GOOD,
	STATUS_WARNING,
	STATUS_INTERNAL,
	STATUS_ARCHIVED,
	STATUS_NOTSHARED,
} from "../../atlas/assets/jss/utils/statusIndicators";

const GoalStatusesEnum = () => ({
	NOTPUBLISHED: {
		value: 1,
		label: "enums.statuses.notPublished",
		color: STATUS_DEFAULT,
	},
	PUBLISHED: {
		value: 2,
		label: "enums.statuses.published",
		color: STATUS_GOOD,
	},
	OUTOFSYNC: {
		value: 3,
		label: "enums.statuses.outOfSync",
		color: STATUS_WARNING,
	},
	INTERNAL: {
		value: 4,
		label: "enums.statuses.internal",
		color: STATUS_INTERNAL,
	},
	NOT_SHARED: {
		value: 1,
		label: "enums.statuses.notShared",
		color: STATUS_NOTSHARED,
	},
	ARCHIVED: {
		value: 5,
		label: "enums.statuses.archived",
		color: STATUS_ARCHIVED,
	},
});
export default GoalStatusesEnum;
