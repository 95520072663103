import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import request from "superagent";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import clsx from "clsx";

import makeStyles from "@mui/styles/makeStyles";

import ListCard from "atlas/components/Cards/ListCard";
import ErrorGoalDialog from "components/Dialogs/ErrorGoalDialog";
import { API_HOST } from "config/env";
import GoalStatusesEnum from "utils/enums/GoalStatuses";
import telemetryAddEvent from "utils/telemetryAddEvent";
import { isPublished, isPublishedInSync, isSharedInSync, isShared } from "utils/goalStatuses";
import notifierMessage from "utils/notifierMessage";
import { setSnackbarOptions } from "redux/snackBar/actions";
import ellipsedTruncateText from "utils/ellipsedTruncateText";

const useStyles = makeStyles((theme) => ({
	mainContent: {
		[theme.breakpoints.up("md")]: {
			maxWidth: "30%",
			minWidth: "30%",
		},
		[theme.breakpoints.up("lg")]: {
			maxWidth: "50%",
			minWidth: "50%",
		},
	},
	secondaryContent: {
		[theme.breakpoints.up("md")]: {
			display: "flex",
			alignItems: "center",
		},
	},
}));

const GoalListCard = (props) => {
	const { goal, goalAdmin, telemetryPage, openGoalDeleteDialog, loadGoals, tab } = props;
	const navigate = useNavigate();
	const { t } = useTranslation("goals");
	const dispatch = useDispatch();
	const [menuOptions, setMenuOptions] = useState([]);
	const [status, setStatus] = useState(goal.status);
	const [isArchived, setIsArchived] = useState(goal.archived);
	const [showErrorGoalDialog, setShowErrorGoalDialog] = useState(false);
	const [anchor, setAnchor] = useState(null);
	const [failedUploads, setFailedUploads] = useState(null);
	const [isForPublish, setIsForPublish] = useState(false);
	const classes = useStyles();

	const handleToggleMenu = (menuAnchor) => setAnchor(menuAnchor);

	const handleMenuClose = () => handleToggleMenu(null);

	const handlePublish = (goalDialog) => {
		if (!goal.name) {
			handleShowErrorGoalDialog();
		} else {
			telemetryAddEvent(`${telemetryPage} - ${goal.closed ? "Share" : "Publish"} goal`);

			request
				.post(`${API_HOST}/api/goal/${goal.id}/publish`)
				.send({})
				.then((res) => {
					if (res.body.failedUploads) {
						setFailedUploads(res.body.failedUploads);
						setShowErrorGoalDialog(true);
						setIsForPublish(true);
					} else {
						let option = notifierMessage(t(`${goalDialog}.snackbar.success`), "success");
						dispatch(setSnackbarOptions(option));
						loadGoals(false, tab);
					}
				})
				.catch((err) => {
					console.log("Error", err);
				});
		}
	};
	const handleArchived = (type) => {
		if (!goal.name) {
			handleShowErrorGoalDialog();
		} else {
			telemetryAddEvent(`${telemetryPage} - ${type === "archive" ? "Archived" : "Unarchived"}`);
			request
				.post(`${API_HOST}/api/goal/${goal.id}/${!type ? "archive " : "unarchive"}`)
				.send({})
				.then((res) => {
					let option = notifierMessage(t(`${type ? "unarchiveGoalDialog" : "archivedGoalDialog"}.snackbar.success`), "success");
					dispatch(setSnackbarOptions(option));
					loadGoals(false, tab);
				})
				.catch((err) => {
					console.log("Error", err);
				});
		}
	};

	const handleShowErrorGoalDialog = () => {
		setShowErrorGoalDialog(true);
		handleMenuClose();
	};

	const handleCloseErrorGoalDialog = () => {
		setShowErrorGoalDialog(false);
		setIsForPublish(false);
		setFailedUploads(null);
	};

	const handleUnpublish = (goalDialog) => {
		telemetryAddEvent(`${telemetryPage} - ${goal.closed ? "Unshared" : "Unpublish"} goal`);
		request
			.post(`${API_HOST}/api/goal/${goal.id}/unpublish`)
			.send({})
			.then(() => {
				let option = notifierMessage(t(`${goalDialog}.snackbar.success`), "success");
				dispatch(setSnackbarOptions(option));
				loadGoals(false, tab);
			})
			.catch((err) => {
				console.log("Error", err);
			});
	};

	const handleDelete = () => {
		telemetryAddEvent(`${telemetryPage} - Delete goal`);

		openGoalDeleteDialog({
			id: goal.id,
			name: goal.name,
		});
	};

	const openGoalDetails = (id, published, archived, withoutErrorMsg = false) => {
		if (goalAdmin) {
			if (published || archived) navigate(`/goals/view/${id}`);
			else navigate(goalAdmin && `/goals/edit/${id}${withoutErrorMsg ? `?withoutErrorMsg=${withoutErrorMsg}` : ""}`);
		} else navigate(`/goals/view/${id}`);
	};

	const getGoalLinkClickHandler = (id, published, archived) => (e) => {
		e.preventDefault();

		telemetryAddEvent(`${telemetryPage} - Edit goal`);

		openGoalDetails(id, published, archived);
	};

	const getGoalStatusText = (status) => {
		switch (status) {
			case GoalStatusesEnum().PUBLISHED.value:
				return t(`app:${GoalStatusesEnum().PUBLISHED.label}`);
			case GoalStatusesEnum().OUTOFSYNC.value:
				return t(`app:${GoalStatusesEnum().OUTOFSYNC.label}`);
			case GoalStatusesEnum().INTERNAL.value:
				return t(`app:${GoalStatusesEnum().INTERNAL.label}`);
			case GoalStatusesEnum().ARCHIVED.value:
				return t(`app:${GoalStatusesEnum().ARCHIVED.label}`);
			case GoalStatusesEnum().NOTPUBLISHED.value:
				if (goal.closed) {
					return t(`app:${GoalStatusesEnum().NOT_SHARED.label}`);
				} else {
					return t(`app:${GoalStatusesEnum().NOTPUBLISHED.label}`);
				}
			default:
				return t(`app:${GoalStatusesEnum().NOTPUBLISHED.label}`);
		}
	};

	const getGoalPublishStatusColor = (status) => {
		switch (status) {
			case GoalStatusesEnum().PUBLISHED.value:
				return GoalStatusesEnum().PUBLISHED.color;
			case GoalStatusesEnum().OUTOFSYNC.value:
				return GoalStatusesEnum().OUTOFSYNC.color;
			case GoalStatusesEnum().INTERNAL.value:
				return GoalStatusesEnum().INTERNAL.color;
			case GoalStatusesEnum().NOTPUBLISHED.value:
				if (goal.closed) {
					return GoalStatusesEnum().NOT_SHARED.color;
				} else {
					return GoalStatusesEnum().NOTPUBLISHED.color;
				}
			default:
				return GoalStatusesEnum().NOTPUBLISHED.color;
		}
	};

	useEffect(() => {
		const published = isPublished(status);
		const shared = isShared(status);
		const options = [];
		if (tab !== 4) {
			options.push({
				label: t("buttons.editGoal"),
				actionFunction: () => {
					navigate(`/goals/edit/${goal.id}`);
				},
				"data-cy": "edit-goal",
			});
		}

		if (goal.closed) {
			if (!isSharedInSync(status) && tab !== 4) {
				options.push({
					label: t("buttons.share"),
					actionFunction: () => {
						handlePublish("shareGoalDialog");
					},
					"data-cy": "share",
				});
			}
			if (isShared(status)) {
				options.push({
					label: t("buttons.unshare"),
					actionFunction: () => handleUnpublish("unshareGoalDialog"),
					"data-cy": "unshare",
					hidden: !shared,
				});
			} else {
				options.push({
					label: t("buttons.delete"),
					publishedTooltip: t("tooltips.publishedCantBeDeleted"),
					unpublishedTooltip: t("tooltips.delete"),
					actionFunction: handleDelete,
					"data-cy": "delete",
					hidden: shared,
				});
			}
		} else {
			if (!isPublishedInSync(status) && tab !== 4) {
				options.push({
					label: t("buttons.publish"),
					publishedTooltip: t("tooltips.publish"),
					unpublishedTooltip: t("tooltips.publish"),
					actionFunction: () => handlePublish("publishGoalDialog"),
					"data-cy": "publish",
				});
			}
			if (isPublished(status)) {
				options.push({
					label: t("buttons.unpublish"),
					publishedTooltip: t("tooltips.unpublish"),
					unpublishedTooltip: t("tooltips.draftCantBePublished"),
					actionFunction: () => handleUnpublish("unpublishGoalDialog"),
					"data-cy": "unpublish",
					hidden: !published,
				});
			} else {
				options.push({
					label: t("buttons.delete"),
					publishedTooltip: t("tooltips.publishedCantBeDeleted"),
					unpublishedTooltip: t("tooltips.delete"),
					actionFunction: handleDelete,
					"data-cy": "delete",
					hidden: published,
				});
			}
		}
		options.push({
			label: isArchived ? t("buttons.unArchive") : t("buttons.archive"),
			actionFunction: () => handleArchived(isArchived),
			"data-cy": "archive",
		});
		if (goal.canUpdate) {
			setMenuOptions(options);
		} else {
			setMenuOptions(null);
		}
	}, [goal, status]);

	return (
		<>
			{showErrorGoalDialog && (
				<ErrorGoalDialog
					isFromList
					show={showErrorGoalDialog}
					handleClose={handleCloseErrorGoalDialog}
					handleGoToGoal={() => openGoalDetails(goal.id, goal.published, goal.archived, true)}
					isForPublish={isForPublish}
					failedUploads={failedUploads}
				/>
			)}

			<ListCard
				item={goal}
				urlField={(goal) => `/home/goal/${goal.id}`}
				itemClick={getGoalLinkClickHandler(goal.id, goal.published, goal.archived)}
				secondaryContent={
					<div>
						<span className={clsx("goal-status", getGoalPublishStatusColor(status))} data-cy="status">
							{getGoalStatusText(status)}
						</span>
					</div>
				}
				menuOptions={menuOptions}
				overflowTooltip={t("app:menu.options")}
				menuAnchor={anchor}
				handleToggleMenu={handleToggleMenu}
				classes={{ mainContent: classes.mainContent }}
				ellipsedTruncatedText={ellipsedTruncateText(goal.name, 70, 55)}
				isEllipsed
			></ListCard>
		</>
	);
};

export default GoalListCard;
