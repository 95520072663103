import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import makeStyles from "@mui/styles/makeStyles";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";

import CircularProgressIndicator from "atlas/components/Progress/CircularProgressIndicator";
import RequestToSpeakItemListCard from "./RequestToSpeakItemListCard";

const useStyles = makeStyles(() => ({
	flex: {
		display: "flex",
	},
	fullWidth: {
		width: "100%",
	},
}));

const RequestToSpeakList = (props) => {
	const {
		requestToSpeakItems,
		more,
		moreClick,
		tab,
		loading,
		moreLoading,
		requestToSpeakFetchHappened,
		getMenuOptions,
		handleApproveClick,
		handleRemoveClick,
		handleCancel,
	} = props;

	let noItemTab = "noItems.submitted";
	if (tab === 1) {
		noItemTab = "noItems.added";
	} else if (tab === 2) {
		noItemTab = "noItems.removed";
	}

	const { t } = useTranslation("requestToSpeak");
	const classes = useStyles();

	return (
		<>
			<div className={classes.flex}>
				<div className="flex grow">
					<Container maxWidth="xl" className={classes.fullWidth}>
						{requestToSpeakItems && requestToSpeakItems.length > 0 && (!loading || moreLoading) ? (
							<List>
								{requestToSpeakItems
									.map((item) => (
										<RequestToSpeakItemListCard
											item={item}
											tab={tab}
											getMenuOptions={getMenuOptions}
											data-cy={`requestToSpeakItemCard${item.id}`}
											handleApproveClick={handleApproveClick}
											handleRemoveClick={handleRemoveClick}
											handleCancel={handleCancel}
										/>
									))
									.concat(
										more && !moreLoading ? (
											<li key="load-more">
												<Grid container justifyContent="center">
													<Button variant="contained" color="primary" onClick={() => moreClick()} data-cy="loadMore">
														{t("buttons.loadMore")}
													</Button>
												</Grid>
											</li>
										) : null,
									)
									.concat(moreLoading && <CircularProgressIndicator key="loading-more" data-cy="loadingMore" />)}
							</List>
						) : (
							<div style={{ margin: "24px" }}>
								{loading ? (
									<Box mt={4}>
										<CircularProgressIndicator data-cy="loadingAgendaItems" />
									</Box>
								) : (
									<>
										{requestToSpeakFetchHappened && (
											<>
												<Box mt={4}>
													<Typography id="no-item-title" variant="h3">
														{t(`${noItemTab}.title`)}
													</Typography>
												</Box>
												<Box mt={3}>
													<Typography id="no-item-description" variant="body2">
														{t(`${noItemTab}.description`)}
													</Typography>
												</Box>
											</>
										)}
									</>
								)}
							</div>
						)}
					</Container>
				</div>
			</div>
		</>
	);
};

export default RequestToSpeakList;
