import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { useWidthUp } from "atlas/utils/useWidth";
import formatTimer from "../utils/formatTimer";

import { updateToolbar } from "redux/app/actions";

const AdminLiveMeetingTopBar = (props) => {
	const {
		presenting,
		setPresenting,
		timers = {},
		panels = {},
		broadcastIntermissionData = {},
		handleTimer,
		rollCallClick,
		motionClick,
		viewAllNotesClick,
		intermissionClick,
	} = props;
	const widthUpSm = useWidthUp("sm");
	const widthUpMd = useWidthUp("md");
	const { t } = useTranslation("meetings");
	const { presets = [], custom = [] } = timers;
	const dispatch = useDispatch();

	const setupVideoClick = () => {};

	const togglePresentingClick = () => {
		setPresenting(!presenting);
	};

	const openTimerClick = () => {};

	// Display tools
	useEffect(() => {
		dispatch(
			updateToolbar({
				display: true,
				left: {
					tools: [
						{
							id: "setup-video",
							label: t("buttons.setupVideo"),
							tooltipText: t("tooltips.setupVideo"),
							onClick: setupVideoClick,
							dataCy: "setup-video",
							overflow: !widthUpSm,
							hidden: true,
						},
						{
							id: "toggle-presenting",
							icon: "bullhorn",
							label: presenting ? t("buttons.stopPresenting") : t("buttons.startPresenting"),
							tooltipText: presenting ? t("tooltips.stopPresenting") : t("tooltips.startPresenting"),
							onClick: togglePresentingClick,
							dataCy: "toggle-presenting",
						},
						{
							id: "timers",
							icon: "timer",
							label: t("buttons.timer"),
							tooltipText: t("tooltips.timer"),
							onClick: openTimerClick,
							dataCy: "timers",
							menu: {
								position: "bottom-start",
								options: presets
									.concat(custom)
									.map((timer, index) => ({
										key: `timer-${index + 1}`,
										label: formatTimer(timer),
										actionFunction: () => {
											handleTimer({ timer });
										},
										dataCy: `menu-timer-preset-${index + 1}`,
									}))
									.concat([
										{
											label: t("menu.customTimer"),
											actionFunction: () => {
												handleTimer({ custom: true });
											},
											dataCy: `menu-timer-custom`,
										},
										{
											label: t("menu.editPresetTimers"),
											actionFunction: () => {
												handleTimer({ edit: true });
											},
											dataCy: `menu-timer-preset-edit`,
										},
									]),
							},
						},
						{
							id: "broadcast-intermission",
							icon: broadcastIntermissionData.intermission ? "play-button" : "pause-button",
							label: broadcastIntermissionData.intermission
								? t("buttons.broadcastReenablePublicBroadcast")
								: t("buttons.broadcastGoToIntermission"),
							tooltipText: broadcastIntermissionData.intermission
								? t("tooltips.broadcastReenablePublicBroadcast")
								: t("tooltips.broadcastGoToIntermission"),
							onClick: intermissionClick,
							disabled: broadcastIntermissionData.disabled,
							hidden: broadcastIntermissionData.hidden,
							dataCy: "broadcast-intermission",
						},
					],
				},
				right: {
					tools: [
						{
							id: "roll-call",
							icon: "group",
							label: t("buttons.rollCall"),
							tooltipText: t("tooltips.rollCall"),
							onClick: rollCallClick,
							dataCy: "roll-call",
							overflow: !widthUpMd,
							highlight: panels.rollCall,
						},
						{
							id: "motions",
							icon: "right-panel",
							label: t("buttons.notesMotions"),
							tooltipText: t("tooltips.notesMotions"),
							onClick: motionClick,
							dataCy: "motion",
							overflow: !widthUpMd,
							highlight: panels.motion && panels.motion.show,
						},
						{
							id: "view-all-notes",
							icon: "manage-minutes",
							label: t("buttons.viewAllNotes"),
							tooltipText: t("tooltips.viewAllNotes"),
							onClick: viewAllNotesClick,
							dataCy: "view-all-notes",
							overflow: !widthUpMd,
							highlight: panels.viewAllNotes,
						},
						{
							overflowMenu: true,
						},
					],
				},
			}),
		);
	}, [presenting, timers, panels, widthUpSm, widthUpMd, broadcastIntermissionData]);

	return null;
};

export default AdminLiveMeetingTopBar;
