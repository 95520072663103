import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation, useMatch } from "react-router-dom";
import { resetPageConfigs, updatePageConfigs } from "redux/app/actions";
import { updatePageHeader } from "redux/pageHeader/actions";
import { useTranslation } from "react-i18next";
import request from "superagent";
import clsx from "clsx";
import queryString from "query-string";

import makeStyles from "@mui/styles/makeStyles";

import ComponentContainer from "atlas/components/ComponentContainer/ComponentContainer";
import telemetryAddEvent from "utils/telemetryAddEvent";
import { formatDate } from "utils/date";
import isEmail from "atlas/utils/isEmail";
import { useWidthDown } from "atlas/utils/useWidth";
import { API_HOST } from "config/env";
import { MEDIUM } from "atlas/utils/buttonSize";
import ButtonWithTooltip from "atlas/components/Buttons/ButtonWithTooltip";
import CircularProgressIndicator from "atlas/components/Progress/CircularProgressIndicator";
import RequestToSpeakRequestChangesDialog from "components/Dialogs/RequestToSpeakRequestChangesDialog";
import RequestToSpeakAddToAgendaDialog from "components/Dialogs/RequestToSpeakAddToAgendaDialog";
import RequestToSpeakRejectDialog from "components/Dialogs/RequestToSpeakRejectDialog";
import requestToSpeakStyle from "./jss/requestToSpeakStyle";
import RequestToSpeakForm from "./components/RequestToSpeakForm";
import notifierMessage from "utils/notifierMessage";
import { setSnackbarOptions } from "redux/snackBar/actions";

const useStyles = makeStyles(requestToSpeakStyle);

const RequestToSpeak = (props) => {
	const { showSignIn } = props;
	const { params: { id } = {} } = useMatch({ path: "/requesttospeak/:id", end: true }) || {};
	const widthDownSm = useWidthDown("sm");
	const { t } = useTranslation("requestToSpeak");
	const navigate = useNavigate();
	const location = useLocation();
	const { tab = 0 } = queryString.parse(location.search) || {};
	const [requestToSpeak, setRequestToSpeak] = useState(null);
	const [saving, setSaving] = useState(false);
	const [showRequestChangesDialog, setShowRequestChangesDialog] = useState(false);
	const [showAddToAgendaDialog, setShowAddToAgendaDialog] = useState(false);
	const [showRejectDialog, setShowRejectDialog] = useState(false);
	const dispatch = useDispatch();
	const classes = useStyles();
	const recaptcha = useRef(null);
	const saveTriggered = useRef(false);
	const creating = id === "create";
	const listUrl = `/requesttospeak${tab > 0 ? `?tab=${tab}` : ""}`;

	const loadRequestToSpeak = () => {
		request
			.get(`${API_HOST}/api/requesttospeak/${id}`)
			.withCredentials()
			.then((res) => {
				const { body: { requestToSpeak: data } = {} } = res;

				if (data) {
					setRequestToSpeak(data);
					const isSubmitted = data.status === 1;
					const menuOptions = [];
					if (isSubmitted && !data.changesRequested) {
						menuOptions.push({
							label: t("buttons.requestChanges"),
							tooltip: t("tooltips.requestChanges"),
							ariaLabel: t("tooltips.requestChanges"),
							// eslint-disable-next-line no-use-before-define
							actionFunction: handleRequestChanges,
							"data-cy": "requestChanges",
						});
						// eslint-disable-next-line no-use-before-define
						menuOptions.push({ label: t("buttons.remove"), actionFunction: handleRemove, cypressId: "remove" });
					}
					if (!isSubmitted && !data.changesRequested) {
						menuOptions.push({
							label: t("menu.moveBackToSubmitted"),
							// eslint-disable-next-line no-use-before-define
							actionFunction: handleReturnToSubmitted,
							cypressId: "return-to-submitted",
						});
					}

					dispatch(
						updatePageHeader({
							// eslint-disable-next-line no-use-before-define
							primaryAction: isSubmitted ? handleApprove : undefined,
							primaryActionText: isSubmitted ? t("buttons.approve") : undefined,
							primaryActionTooltip: isSubmitted ? t("tooltips.approve") : undefined,
							menuOptions,
						}),
					);

					const { approveRtsId } = queryString.parse(location.search) || {};
					if (approveRtsId && approveRtsId > 0) {
						navigate(location.pathname);
						// eslint-disable-next-line no-use-before-define
						handleApprove();
					}
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const saveRequest = (data, onSuccess) => {
		setSaving(true);
		saveTriggered.current = false;

		const editing = data.guid && data.guid.length > 0;
		const url = `${API_HOST}/api/requesttospeak${editing ? `/${data.guid}` : ""}`;
		const requestObject = editing ? request.put(url) : request.post(url);

		if (!editing) {
			telemetryAddEvent(`Request to speak - admin item submitted`);
		}

		return requestObject
			.withCredentials()
			.send(data)
			.then((res) => {
				setSaving(false);

				const { status, body: { id: guid } = {} } = res;
				if (status === 200) {
					if (creating) {
						let option = notifierMessage(t("snackbar.create.success"), "success");
						dispatch(setSnackbarOptions(option));
						navigate(`/requesttospeak/${guid}`);
					} else if (typeof onSuccess === "function") {
						onSuccess();
					} else {
						let option = notifierMessage(t("snackbar.update.success"), "success");
						dispatch(setSnackbarOptions(option));
						loadRequestToSpeak();
					}
				}
			})
			.catch(() => {
				setSaving(false);
			});
	};

	const handleChange = (e, field, checkbox, numeric) => {
		const {
			target: { value, checked },
		} = e;

		setRequestToSpeak({
			...requestToSpeak,
			// eslint-disable-next-line no-nested-ternary
			[field]: !checkbox ? (numeric ? parseInt(value, 10) : value) : checked,
		});
	};

	const handleCancel = () => {
		setShowRequestChangesDialog(false);
		setShowAddToAgendaDialog(false);
		setShowRejectDialog(false);
	};

	const handleRequestChanges = () => {
		setShowRequestChangesDialog(true);
	};

	const requestChanges = (_id, itemData) => {
		setShowRequestChangesDialog(false);

		request
			.post(`${API_HOST}/api/requesttospeak/${id}/requestchanges`)
			.withCredentials()
			.send(itemData)
			.then((res) => {
				const { status } = res;
				if (status === 200) {
					let option = notifierMessage(t("snackbar.requestChanges.success"), "success");
					dispatch(setSnackbarOptions(option));
					loadRequestToSpeak();
				}
			})
			.catch((err) => {
				showSignIn(err, () => {
					requestChanges(id, itemData);
				});
			});
	};

	const handleApprove = () => {
		setShowAddToAgendaDialog(true);
	};

	const approveItem = (_id, itemData) => {
		setShowAddToAgendaDialog(false);
		saveRequest(
			{
				...itemData,
				guid: id,
			},
			() => {
				let option = notifierMessage(t("snackbar.approve.success"), "success");
				dispatch(setSnackbarOptions(option));

				navigate(listUrl);
			},
		);
	};

	const handleRemove = () => {
		setShowRejectDialog(true);
	};

	const removeItem = (_id, comment) => {
		setShowRejectDialog(false);
		saveRequest(
			{
				guid: id,
				remove: true,
				comment,
			},
			() => {
				let option = notifierMessage(t("snackbar.remove.success"), "success");
				dispatch(setSnackbarOptions(option));

				navigate(listUrl);
			},
		);
	};

	const handleReturnToSubmitted = () => {
		saveRequest({ guid: id, submit: true });
	};

	const handleSubmit = () => {
		saveTriggered.current = true;
		if (recaptcha.current) {
			recaptcha.current.execute();
		} else {
			// Fallback for localhost
			saveRequest(requestToSpeak);
		}
	};

	const getStatusLabel = (status) => {
		switch (status) {
			case 1:
				return t("publicSubmission.status.submitted");

			case 2:
				return t("publicSubmission.status.approved");

			case 3:
				return t("publicSubmission.status.removed");

			default:
				return "";
		}
	};

	const isDisabled = () => {
		return (
			requestToSpeak.firstName.length === 0 ||
			requestToSpeak.lastName.length === 0 ||
			!isEmail(requestToSpeak.emailAddress) ||
			requestToSpeak.topic.length === 0 ||
			requestToSpeak.meetingId === 0 ||
			saving
		);
	};

	useEffect(() => {
		dispatch(resetPageConfigs({}));
		dispatch(
			updatePageConfigs({
				title: t("publicSubmission.titleEdit"),
				back: { url: listUrl },
				telemetryPage: "Request To Speak Submission",
			}),
		);
	}, []);

	useEffect(() => {
		if (!creating) {
			loadRequestToSpeak();
		} else {
			setRequestToSpeak({
				guid: "",
				firstName: "",
				lastName: "",
				emailAddress: "",
				phoneNumber: "",
				topic: "",
				meetingId: 0,
			});
		}
	}, [id]);

	const saveButtonText = {
		title: creating ? t("publicSubmission.tooltips.submitRequestToSpeak") : t("publicSubmission.tooltips.updateRequest"),
		text: creating ? t("publicSubmission.buttons.submitRequest") : t(`publicSubmission.buttons.updateRequest`),
	};

	return (
		<ComponentContainer onlyScrollbar>
			{showRequestChangesDialog && requestToSpeak && (
				<RequestToSpeakRequestChangesDialog
					show={showRequestChangesDialog}
					item={requestToSpeak}
					handleCancel={handleCancel}
					handleRequestChanges={requestChanges}
				/>
			)}
			{showAddToAgendaDialog && requestToSpeak && (
				<RequestToSpeakAddToAgendaDialog
					show={showAddToAgendaDialog}
					item={requestToSpeak}
					handleCancel={handleCancel}
					handleAddToAgenda={approveItem}
					canAdd
				/>
			)}
			{showRejectDialog && requestToSpeak && (
				<RequestToSpeakRejectDialog show={showRejectDialog} item={requestToSpeak} handleCancel={handleCancel} handleReject={removeItem} />
			)}

			{requestToSpeak && (
				<div className={classes.content}>
					{!creating && (
						<>
							<h1 className={classes.contentLabel}>
								{t("publicSubmission.requestDetails")}
								{requestToSpeak.changesRequested && <span style={{ float: "right" }}> {t("awaitingRequestedChanges")}</span>}
							</h1>
							<div className={classes.details}>
								<div>
									{requestToSpeak.dateCreated &&
										t("publicSubmission.requestSubmitted", {
											date: formatDate(requestToSpeak.dateCreated, null, null, t("app:at"), "", "", false),
										})}
								</div>
								<div>{t("publicSubmission.requestStatus", { status: getStatusLabel(requestToSpeak.status) })}</div>
								<div>{t("publicSubmission.requestMeeting", { name: requestToSpeak.meetingName })}</div>
							</div>
							<div className={classes.separator} />
						</>
					)}
					<h1 className={classes.contentLabel}>{t("publicSubmission.requestContent")}</h1>
					<RequestToSpeakForm requestToSpeak={requestToSpeak} showMeetingAndSections handleChange={handleChange} />
					{requestToSpeak.status !== 2 && (
						<div className={classes.buttons}>
							<ButtonWithTooltip
								className={clsx({ [classes.button]: !widthDownSm })}
								title={saveButtonText.title}
								size={MEDIUM}
								primary
								disabled={isDisabled()}
								onClick={handleSubmit}
								data-cy="submit"
							>
								{saving ? <CircularProgressIndicator minHeight="0px" size={24} padding="8px" /> : saveButtonText.text}
							</ButtonWithTooltip>
						</div>
					)}
				</div>
			)}
		</ComponentContainer>
	);
};

export default RequestToSpeak;
