import React from "react";

export const AnnotationEdit = () => (
	<svg width="24" height="24" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M10.4031 9.26854L19.0197 0.68495C19.2676 0.438015 19.6686 0.438399 19.916 0.68581L21.7648 2.53458C22.0126 2.78233 22.0126 3.18401 21.7648 3.43175L13.1812 12.0153C13.1234 12.0732 13.0549 12.1193 12.9796 12.1511L9.77729 13.5047C9.25511 13.7254 8.72769 13.2026 8.94381 12.6785L10.2644 9.47613C10.2964 9.39842 10.3436 9.32786 10.4031 9.26854ZM20.4191 2.98317L19.4666 2.03071L19.0236 2.47202C19.1628 2.48681 19.298 2.54756 19.4048 2.65426L19.7964 3.04587C19.9023 3.15178 19.9629 3.28583 19.9783 3.42396L20.4191 2.98317ZM19.2773 4.12494C19.1391 4.10959 19.0051 4.04895 18.8992 3.94304L18.5076 3.55144C18.4003 3.44411 18.3394 3.3079 18.3251 3.16784L11.3888 10.0776L10.7053 11.7349L12.3716 11.0306L19.2773 4.12494ZM1.26879 21.1818V5.25144H11.6419C11.9922 5.25144 12.2763 4.96741 12.2763 4.61705C12.2763 4.26668 11.9922 3.98265 11.6419 3.98265H0.634396C0.284029 3.98265 0 4.26668 0 4.61705V21.8162C0 22.1666 0.284029 22.4506 0.634396 22.4506H17.8336C18.1839 22.4506 18.468 22.1666 18.468 21.8162V10.8087C18.468 10.4584 18.1839 10.1744 17.8336 10.1744C17.4832 10.1744 17.1992 10.4584 17.1992 10.8087V21.1818H1.26879Z"
			fill="white"
		/>
	</svg>
);
