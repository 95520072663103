import React from "react";

import makeStyles from '@mui/styles/makeStyles';
import { TableCell } from "@mui/material";

import tableDataStyle from "assets/jss/components/tableDataStyle";

const useStyles = makeStyles(tableDataStyle);

const TextCell = (props) => {
	const { value } = props;
	const classes = useStyles();

	return <TableCell className={classes.cell}>{value}</TableCell>;
};

export default TextCell;
