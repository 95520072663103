/* eslint-disable no-param-reassign */
import React, { useState, useEffect, useRef, useCallback } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useMatch } from "react-router-dom";
import { useTranslation } from "react-i18next";
import request from "superagent";

import CircularProgressIndicator from "atlas/components/Progress/CircularProgressIndicator";
import ComponentContainer from "atlas/components/ComponentContainer/ComponentContainer";
import withErrorHandling from "components/ErrorHOC";
import telemetryAddEvent from "utils/telemetryAddEvent";
import MakePublicDialog from "./components/MakePublicDialog";
import PolicySettings, { PERMISSION_PUBLIC, PERMISSION_INTERNAL, PERMISSION_PRIVATE } from "./PolicySettings";
import { useUpdateObject, initializeValidate, setValidate } from "utils/updateObject";
import { resetPageConfigs, updatePageConfigs, updateLeftNav } from "redux/app/actions";
import { updatePageHeader } from "redux/pageHeader/actions";
import { loadPolicyBook, loadUsersPolicy, savePolicyBook } from "../../redux/policyGovernance/actions";
import { displaySuccessNotification } from "../Policy/utils/getNotification";
import useBackButtonHandler from "utils/hooks/useBackButtonHandler.js";

const telemetryPage = "Policy Settings Detail";
const clearErrors = {
	title: "",
};
const clearSavingState = {
	isSaving: false,
	updated: false,
};

const PolicySettingsContainer = (props) => {
	const { showSignIn } = props;
	const { params: { id } = {} } = useMatch({ path: "/policy/settings/book/:id", end: true }) || {};
	const { t } = useTranslation("documents");
	const navigate = useNavigate();
	const [book, setBook] = useState(null);
	const [originalBook, setOriginalBook] = useState(null);
	const [users, setUsers] = useState(null);
	const [dialogs, setDialogs] = useState({});
	const [errors, setErrors] = useState({
		...clearErrors,
	});
	const [savingState, setSavingState] = useState({
		...clearSavingState,
	});
	const bookRef = useRef(book); // For use in functions that have stale references
	const errorsRef = useRef(errors); // For use in functions that have stale references
	const savingStateRef = useRef(savingState); // For use in functions that have stale references
	const dispatch = useDispatch();
	const onlySpacesRegex = /^\s*$/;
	const [disableSave, setDisableSave] = useState(false);

	const setPermissionField = (book) => {
		if (book) {
			if (book.isPublic) {
				book.permissionType = PERMISSION_PUBLIC;
			} else if (book.isInternal) {
				book.permissionType = PERMISSION_INTERNAL;
			} else {
				book.permissionType = PERMISSION_PRIVATE;
			}
		}
	};

	const loadBook = (id = 0) => {
		dispatch(loadPolicyBook(id, false))
			.then((res) => {
				const { body: book } = res || {};

				// Check if this is a new book
				if (book.id === 0) {
					book.folder = true;
				}

				setPermissionField(book);
				setBook(book);
				setOriginalBook({ ...book });
				initializeValidate(setErrors, book.guid, clearErrors);
				setSavingState({
					...clearSavingState,
				});
			})
			.catch((err) => {
				showSignIn(err, () => {
					loadBook(id);
				});
			});
	};

	const loadUsers = () => {
		dispatch(loadUsersPolicy())
			.then((res) => {
				const { body: users } = res || {};
				if (users) {
					// Give each user a number to use for the avatar background
					let number = 0;
					const numberCache = {};
					users.forEach((user) => {
						if (typeof numberCache[user.id] === "undefined") {
							numberCache[user.id] = number;
							number++;
						}
						user.number = numberCache[user.id];
					});

					setUsers(users);
				}
			})
			.catch((err) => {
				showSignIn(err, () => {
					loadUsers();
				});
			});
	};

	const backToPolicySettings = () => {
		const { isSaving, updated } = savingStateRef.current;
		const backPath = "/policy/settings";

		if (isSaving || updated) {
			if (confirm(t("policySettingsDetail.unsavedChanges"))) {
				navigate(backPath);
			} else {
				return;
			}
		} else {
			navigate(backPath);
		}

		return true;
	};

	const unsavedChangesText = t("policySettingsDetail.unsavedChanges");
	useBackButtonHandler(savingStateRef.current, "/policy/settings", unsavedChangesText);

	const checkUnsavedChangesBeforeClosing = (event) => {
		const { isSaving, updated } = savingStateRef.current;

		if (isSaving || updated) {
			event.preventDefault();
			event.returnValue = "";
		}
	};

	const showMakePublicDialog = (book) => {
		setDialogs({
			makePublic: book,
		});
	};

	const closeDialogs = () => {
		setDialogs({});
	};

	const handleSave = () => {
		// Enable full validation
		initializeValidate(setErrors, true);

		if (!hasValidationErrors(errorsRef.current)) {
			if (originalBook.isPublic !== bookRef.current.isPublic) {
				showMakePublicDialog(bookRef.current);
			} else {
				saveBook(bookRef.current);
			}
		}
	};

	const saveBook = (updatedBook) => {
		telemetryAddEvent(`Policy - Add Policy Book`);
		setSavingState((prev) => ({
			...prev,
			isSaving: true,
			updated: false,
		}));
		updatedBook["isPolicySetting"] = true;
		dispatch(savePolicyBook(updatedBook.guid, updatedBook))
			.then((res) => {
				if (res.status === 200) {
					const { body: book } = res || {};

					setPermissionField(book);
					setBook(book);
					setOriginalBook({ ...book });
					setSavingState((prev) => ({
						...prev,
						isSaving: false,
					}));

					displaySuccessNotification(t("policySettingsDetail.snackbar.saved"), dispatch);

					if (!id) {
						telemetryAddEvent(`${telemetryPage} - Policy - Book added`, { area: "policy" });
						navigate(`/policy/settings/book/${book.guid}`);
					}

					dispatch(
						updateLeftNav({
							reloadLeftNav: { policies: Date.now() },
						}),
					);
				}
			})
			.catch((err) => {
				showSignIn(err, () => {
					saveBook(updatedBook);
				});
			});
	};
	const validateTitle = (title) => {
		if (title.length < 1 || onlySpacesRegex.test(title)) {
			setDisableSave(true);
		} else {
			setDisableSave(false);
		}
	};

	const validateBook = (updatedBook, updatedField) => {
		validateTitle(updatedBook.title);

		setErrors((prev) => {
			if (!updatedBook.title) {
				prev.title = t("policySettingsDetail.validation.title");
			} else {
				prev.title = "";
			}

			setValidate(prev, updatedField);

			return { ...prev };
		});
	};

	const hasValidationErrors = (errorsObject) => errorsObject.title;

	const updateBook = useCallback(
		useUpdateObject(setBook, undefined, validateBook, () => {
			setSavingState((prev) => ({
				...prev,
				updated: true,
			}));
		}),
		[],
	);

	useEffect(() => {
		bookRef.current = book;
		errorsRef.current = errors;
		savingStateRef.current = savingState;
	}, [book, errors, savingState]);

	useEffect(() => {
		dispatch(resetPageConfigs({}));

		loadBook(id);
		loadUsers();

		if (id) {
			telemetryAddEvent(`${telemetryPage} - Open`, { area: "policy" });
		}

		window.addEventListener("beforeunload", checkUnsavedChangesBeforeClosing);

		return () => {
			window.removeEventListener("beforeunload", checkUnsavedChangesBeforeClosing);

			document.body.style.userSelect = null;
		};
	}, [id]);

	useEffect(() => {
		dispatch(
			updatePageHeader({
				primaryAction: handleSave,
				primaryActionText: !savingState.isSaving ? t("app:buttons.save") : t("app:buttons.saving"),
				primaryActionTooltip: !savingState.isSaving ? t("tooltips.saveBook") : undefined,
				primaryActionDisabled: savingState.isSaving || !savingState.updated || disableSave,
			}),
		);
	}, [id, !!book, savingState.isSaving, savingState.updated, savingState.canDelete, disableSave]);

	useEffect(() => {
		dispatch(
			updatePageConfigs({
				title: (book || {}).title || t("policySettingsDetail.title"),
				back: { action: backToPolicySettings },
				telemetryPage,
			}),
		);
	}, [(book || {}).title]);

	return (
		<ComponentContainer padding="16px">
			{book ? (
				<>
					<PolicySettings book={book} users={users} errors={errors} handleUpdate={updateBook} telemetryPage={telemetryPage} />
					{dialogs.makePublic && (
						<MakePublicDialog
							book={dialogs.makePublic}
							continueSave={() => {
								closeDialogs();
								saveBook(bookRef.current);
							}}
							onClose={closeDialogs}
						/>
					)}
				</>
			) : (
				<CircularProgressIndicator />
			)}
		</ComponentContainer>
	);
};

export default withErrorHandling(PolicySettingsContainer);
