import React from "react";
import { useTranslation } from "react-i18next";

import makeStyles from "@mui/styles/makeStyles";
import { Box } from "@mui/material";

import Icon from "atlas/components/Icon/Icon";
import GenericDialog from "atlas/components/Dialogs/GenericDialog";

const useStyles = makeStyles(() => ({
	dialog: {
		"& .MuiDialog-paper": {
			width: "400px",
			maxWidth: "100%",
		},
	},
}));

const RollCallDeleteDialog = (props) => {
	const { show = true, rollCall, onClose, handleDelete } = props;
	const { t } = useTranslation("meetings");
	const classes = useStyles();

	const handleCancel = () => {
		onClose();
	};

	const i18n = t("deleteRollCallDialog", { name: rollCall.name });

	const dialog = {
		title: i18n.title,
		line1: i18n.line1,
		primaryTitle: t("app:buttons.delete"),
		primaryAction: handleDelete,
		secondaryTitle: t("app:buttons.cancel"),
		secondaryAction: handleCancel,
	};

	return (
		<GenericDialog
			className={classes.dialog}
			show={show}
			title={dialog.title}
			primaryAction={dialog.primaryAction}
			primaryTitle={dialog.primaryTitle}
			secondaryAction={dialog.secondaryAction}
			secondaryTitle={dialog.secondaryTitle}
			closeIcon={<Icon name="close" />}
			data-cy="roll-call-delete-dialog"
		>
			<Box fontWeight="fontWeightBold">{dialog.line1}</Box>
		</GenericDialog>
	);
};

export default RollCallDeleteDialog;
