export default {
	addItem: "+ Item",
	addItemMenu: "Add minutes item",
	addHeader: "+ Section",
	addHeaderMenu: "Add section",
	addSubHeading: "+ Subheading",
	addSubHeadingMenu: "Add subheading",
	duplicate: "Duplicate",
	moveUp: "Move up",
	moveDown: "Move down",
	delete: "Delete",
	copyToAgenda: "Copy to agenda",
	moveToAgenda: "Move to agenda",
	setMemberOnlySection: "Set section as members only",
	setPublicSection: "Set as public section",
	transformSectionConsent: "Transform to section (consent)",
	transformSection: "Transform to section",
	transformSectionPublicComment: "Turn section into public comment",
};
