import typographyStyle from './typographyStyle';
import { primaryColor, whiteColor } from '../shared';

const topNavBarStyle = () => ({
	headerToolbar: {
		height: '72px',
		maxHeight: '72px',
		minHeight: '72px',
		backgroundColor: primaryColor[0],
		padding: '0 12px',
		display: 'flex',
		alignItems: 'center',
		overflow: 'hidden',
		flexGrow: 1,
		position: 'initial',
	},
	organizationContainer: {
		overflow: 'hidden',
		color: whiteColor,
		alignItems: 'center',
		display: 'flex',
		paddingBottom: '3px',
	},
	organization: {
		marginLeft: '12px',
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
		overflow: 'hidden',
		...typographyStyle.appBar,
	},
	organizationClosed: {
		marginLeft: '8px',
	},
	diligentLogo: {
		margin: 'auto',
		alignItems: 'center',
		display: 'flex',
		flexDirection: 'column',
	},
	leftSide: {
		margin: 'auto',
		ustifyContent: 'flex-start',
		display: 'flex',
	},
	rightSide: {
		margin: 'auto',
		justifyContent: 'flex-end',
		display: 'flex',
		alignItems: 'center',
	},
	spacer: {
		flexGrow: '1',
	},
	searchInput: {
		width: '525px',
		maxWidth: 'calc(100vw - 120px)', // 48px icons x2 + 12px l/r padding
	},
	menu: {
		minWidth: '160px',
		...typographyStyle.menu,
		'& ul': {
			padding: '0',
		},
		'& li': {
			height: '40px',
			padding: '10px 12px',
		},
	},
});

export default topNavBarStyle;
