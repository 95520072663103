import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "@mui/material";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";

import AccessibleIconButton from "atlas/components/Buttons/AccessibleIconButton";
import CircularProgressIndicator from "atlas/components/Progress/CircularProgressIndicator";
import ListItemText from "@mui/material/ListItemText";

import OutlinedInput from "atlas/components/FormControls/OutlinedInput";
import inputStyle from "atlas/assets/jss/components/inputStyle";
import Tooltip from "atlas/components/Tooltip/Tooltip";

import notifierMessage from "utils/notifierMessage";

import ellipsedTruncateText from "utils/ellipsedTruncateText";

import { setSnackbarOptions } from "redux/snackBar/actions";

import { setMinutesSignature } from "redux/meetingTemplate/actions";

import makeStyles from "@mui/styles/makeStyles";

import telemetryAddEvent from "utils/telemetryAddEvent";

const useInputStyles = makeStyles(inputStyle);
const useStyles = makeStyles(() => ({
	SignaturesInputBlock: {
		display: "flex",
		alignItems: "center",
	},
	signatreRoleInput: {
		flexGrow: 1,
	},
	addButton: {
		margin: "0px 1px 0px 40px",
	},
	noSignatureAdded: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	},
	listItem: {
		margin: "5px 0",
		paddingTop: "4px",
		paddingBottom: "4px",
	},
	signText: {
		marginLeft: "20px",
	},
	list: {
		maxHeight: "140px",
		overflowY: "auto",
	},
}));

function Signatures({ signatures, dispatch }) {
	const classes = useStyles();
	const [signature, setSignature] = useState("");
	const inputClasses = useInputStyles({ fullWidth: true });
	const [loading, setLoading] = useState(false);
	const { t } = useTranslation("meetings");

	const handleDelete = (index) => {
		setLoading(true);
		let signaturesList = [...signatures];
		signaturesList.splice(index, 1);
		dispatch(setMinutesSignature(signaturesList))
			.then((res) => {
				setLoading(false);
				if (res) {
					let option = notifierMessage(t("Signature Removed Successfully"), "success");
					telemetryAddEvent(`Minutes Settings -Singature Removed`);
					dispatch(setSnackbarOptions(option));
				}
			})
			.catch((err) => {
				setLoading(false);
				let option = notifierMessage(t("Signature Removed Failed"), "error");
				dispatch(setSnackbarOptions(option));
			});
	};

	const onChangeSignature = (e) => {
		setSignature(e.target.value);
	};

	const onAddSignature = () => {
		setLoading(true);
		let signaturesList = [...signatures];
		signaturesList.push(signature);

		dispatch(setMinutesSignature(signaturesList))
			.then((res) => {
				setLoading(false);
				if (res) {
					let option = notifierMessage(t("signatures.signatureSucess"), "success");
					telemetryAddEvent(`Minutes Settings -Singature Added`);
					dispatch(setSnackbarOptions(option));
					setSignature("");
				}
			})
			.catch((err) => {
				setLoading(false);
				let option = notifierMessage(err?.response?.body?.Message, "error");
				dispatch(setSnackbarOptions(option));
			});
	};

	const signatureList = () => {
		return !loading ? (
			<>
				{signatures.length > 0 ? (
					<>
						<Typography variant="h5" className={classes.signText}>
							{t("signatures.signAddedFor")}
						</Typography>
						<List className={classes.list}>
							{signatures.length &&
								signatures.map((item, index) => (
									<ListItem
										className={classes.listItem}
										secondaryAction={
											<AccessibleIconButton
												id={item}
												iconName="remove"
												tooltipText={t("signatures.remove")}
												aria-label={t("signatures.remove")}
												onClick={() => handleDelete(index)}
												dataCy="delete-signature"
											/>
										}
									>
										<Tooltip
											disableHoverListener={!ellipsedTruncateText(item).isTruncated}
											PopperProps={{ disablePortal: true }}
											placement={"top"}
											title={`${item}`}
										>
											<ListItemText primary={ellipsedTruncateText(item).text} />
										</Tooltip>
									</ListItem>
								))}
						</List>
					</>
				) : (
					<Typography variant="h5" className={classes.noSignatureAdded}>
						{t("signatures.noSignatureAdded")}
					</Typography>
				)}
			</>
		) : (
			<CircularProgressIndicator />
		);
	};

	return (
		<>
			<div>
				<label>{t("templateDetail.labels.signatures")}</label>
				<div className={classes.SignaturesInputBlock}>
					<div className={classes.signatreRoleInput}>
						<OutlinedInput
							id={"signature-input"}
							className={inputClasses.signatureInput}
							noDefaultClassName
							fullWidth
							size="small"
							label={t("signatures.input")}
							value={signature}
							onChange={(e) => onChangeSignature(e)}
							data-cy={t("signatures.input")}
						/>
					</div>
					<div>
						<Button
							className={classes.addButton}
							disabled={!signature.length > 0}
							primary
							variant="outlined"
							onClick={() => onAddSignature()}
							data-cy="add-signature"
						>
							{t("signatures.addButton")}
						</Button>
					</div>
				</div>
				{signatureList()}
			</div>
		</>
	);
}

export default Signatures;
