import React, { useContext } from "react";

import { useTranslation } from "react-i18next";
import clsx from "clsx";

import { addYoutubeCredentials, validateYoutubeCredentials, getGeneralSettings, updateSettings } from "redux/generalSettings/actions";
import { useSelector, useDispatch } from "react-redux";
import Accordion from "atlas/components/Accordion/Accordion";
import ButtonWithTooltip from "atlas/components/Buttons/ButtonWithTooltip";
import { UserContext } from "contexts/User/UserContext";

import accordionStyle from "assets/jss/components/accordionStyle";
import generalSettingsStyle from "../jss/generalSettingsStyle";
import makeStyles from "@mui/styles/makeStyles";
import notifierMessage from "utils/notifierMessage";
import { setSnackbarOptions } from "redux/snackBar/actions";

import { Checkbox } from "@mui/material";
import { Typography } from "@mui/material";
import Icon from "atlas/components/Icon/Icon";
import { grayColor } from "atlas/assets/jss/shared";

const useStyles = makeStyles(generalSettingsStyle);

const YoutubeSettings = () => {
	const dispatch = useDispatch();
	const useAccordionStyles = makeStyles(accordionStyle);
	const { diligentAdmin, isYouTubeEnabled, validYouTubeCredentials } = useSelector((state) => state.generalSettingsReducer);
	const accordionClasses = useAccordionStyles();
	const { t } = useTranslation("generalSettings");
	const classes = useStyles();
	const { userAdmin } = useContext(UserContext).user;

	const validateTest = async () => {
		const data = await dispatch(validateYoutubeCredentials());
		if (data.Valid) {
			let option = notifierMessage(t("snackbar.success.youtube"), "success");
			dispatch(setSnackbarOptions(option));
		} else {
			let option = notifierMessage(t("snackbar.error.youtube"), "error");
			dispatch(setSnackbarOptions(option));
		}
	};
	const handleToggle = (e, name) => {
		const {
			target: { checked },
		} = e;
		dispatch(updateSettings({ [name]: checked }));
	};

	const getJsonUpload = () => {
		document.getElementById("uploadfile").click();
	};

	const loadFilesToApi = async () => {
		const fileInput = document.getElementById("uploadfile");
		const fileData = new FormData();
		const selectedFile = fileInput.files[0];
		fileData.append("file", selectedFile);
		const data = await dispatch(addYoutubeCredentials(fileData));
		if (data.AuthCodeUrl) {
			let new_window = window.open(data.AuthCodeUrl, "_blank", "toolbar=no,scrollbars=no,resizable=no,top=0,left=0,width=600,height=400");
			let timer = setInterval(function () {
				if (new_window.closed) {
					clearInterval(timer);
					dispatch(getGeneralSettings());
				}
			}, 1000);
		} else {
			let option = notifierMessage(t("snackbar.error.youtube"), "error");
			dispatch(setSnackbarOptions(option));
			dispatch(getGeneralSettings());
		}
	};

	return diligentAdmin || (userAdmin && isYouTubeEnabled) ? (
		<>
			{isYouTubeEnabled ? (
				<div className={clsx(classes.section, classes.sectionAccordion)}>
					<Accordion
						id="youtube-settings"
						classes={accordionClasses}
						className={classes.bold}
						label={t("subtitle.youTubeSettings")}
						scrollContentIntoView
						overflow={false}
						dataCy="youtube-settings"
						handleToggle={handleToggle}
						enableCheckbox={isYouTubeEnabled}
						showCheckbox={diligentAdmin}
						checkboxName="isYouTubeEnabled"
					>
						<div className={classes.youTubeText}>
							<Typography>
								{t("youtube.step1")}
								<a href="https://console.developers.google.com/apis/credentials" target="_blank" className={classes.youtubeLink}>
									{t("youtube.googleConsole")}
									<span className={classes.youTubeLinkIcon}>
										<Icon name="external-link" size="16px" color={grayColor[0]} />
									</span>
								</a>
							</Typography>
							<Typography>{t("youtube.step2")}</Typography>
							<Typography>{t("youtube.step3")}</Typography>
						</div>
						<div className={clsx(classes.subAccordians, classes.youTubeUploadButton)}>
							<div className={classes.buttons}>
								<ButtonWithTooltip
									id="upload-button"
									className={classes.addCredentialButton}
									onClick={getJsonUpload}
									variant="outlined"
									color="primary"
									title={t(`tooltips.${validYouTubeCredentials ? "update" : "add"}`)}
									disableHoverListener={true}
								>
									<input
										id="uploadfile"
										type="file"
										name="file"
										style={{ display: "none" }}
										accept=".json"
										onChange={() => loadFilesToApi()}
										aria-hidden="true"
									/>
									{t(`buttons.${validYouTubeCredentials ? "updateCredentials" : "addCredentials"}`)}
								</ButtonWithTooltip>
								{validYouTubeCredentials && (
									<ButtonWithTooltip
										id="test-btn"
										className={classes.addButton}
										onClick={validateTest}
										variant="outlined"
										color="primary"
										title={t("tooltips.test")}
									>
										{t("buttons.testCredentials")}
									</ButtonWithTooltip>
								)}
							</div>
						</div>
					</Accordion>
				</div>
			) : (
				<div>
					{diligentAdmin ? (
						<div className={classes.youTubeCheckbox}>
							<div className={classes.youTubeTitle}>{t("subtitle.youTubeSettings")}</div>
							<Checkbox
								checked={isYouTubeEnabled}
								onChange={(e) => handleToggle(e, "isYouTubeEnabled")}
								color="primary"
								data-cy="youtube-settings"
								inputProps={{
									"aria-label": t("subtitle.youTubeSettings"),
									"aria-labelledby": "youtubeSettings",
								}}
							/>
						</div>
					) : null}
				</div>
			)}
		</>
	) : null;
};
export default YoutubeSettings;
