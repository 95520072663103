import { blackColor, whiteColor } from "atlas/assets/jss/shared";
import typographyStyles from "atlas/assets/jss/components/typographyStyle";

const settingsStyle = () => ({
	bold: {
		...typographyStyles.bold,
	},
	labelWithOtherContent: {
		display: "flex",
		alignItems: "center",
	},
	displayOptionIndent: {
		display: "flex",
		["@media (max-width:690px)"]: {
			flexDirection: "column",
		},
		alignItems: "center",
	},
	labelWithOtherContentMobile: {
		display: "flex",
		alignItems: "flex-start",
		flexDirection: "column-reverse",
	},
	labelSwitch: {
		display: "flex",
		alignItems: "center",
	},
	labelSwitchMobile: {
		display: "flex",
		alignItems: "center",
		paddingBottom: "16px",
	},
	leftLabel: {
		flexGrow: "1",
	},
	switchLabel: {
		...typographyStyles.fieldLabel,
		marginRight: "16px",
	},
	switchInstructions: {
		...typographyStyles.fieldLabel,
	},
	sectionLabel: {
		marginTop: "8px",
	},
	logoSection: {
		display: "flex",
	},
	sectionLabelLargeSpacing: {
		marginTop: "20px",
	},
	subSection: {
		marginTop: "8px",
	},
	twoColumns: {
		display: "flex",
		flexWrap: "wrap",
		"& > div": {
			minWidth: "285px",
		},
	},
	colorPicker: {
		flexShrink: "0",
		marginBottom: "50px",
		["@media (max-width:690px)"]: {
			marginBottom: "20px",
		},
	},
	additionalContent: {
		marginLeft: "16px",
		alignSelf: "flex-start",
	},
	verticalButtonSpacing: {
		"& > div:not(:first-child)": {
			marginTop: "16px",
		},
	},
	colorIndicator: {
		display: "inline-flex",
		alignItems: "center",
		boxSizing: "border-box",
		height: "40px",
		padding: "8px",
		borderRadius: "4px",
		color: whiteColor,
		marginBottom: "-12px",
	},
	colorIndicatorInvalid: {
		color: blackColor[0],
	},
	colorContrast: {
		display: "flex",
		alignItems: "center",
		marginTop: "8px",
	},
	contrastRatio: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		flexShrink: "0",
		boxSizing: "border-box",
		height: "40px",
		width: "40px",
		borderRadius: "20px",
		fontWeight: "600",
		color: "#ffffff",
	},
	contrastMessage: {
		marginLeft: "8px",
		overflowWrap: "break-word",
	},
	image: {
		cursor: "pointer",
		maxWidth: "400px",
		maxHeight: "150px",
		["@media (max-width:660px)"]: {
			maxWidth: "180px",
		},
	},
	imageDropZone: {
		cursor: "pointer",
		display: "flex",
		alignItems: "center",
		maxWidth: "200px",
		height: "150px",
		border: `solid 1px ${blackColor[1]}`,
		borderRadius: "4px",
		padding: "8px",
		boxSizing: "border-box",
	},
	checkbox: {
		marginLeft: "47px",
		["@media (max-width:690px)"]: {
			marginLeft: "15px",
		},
	},
	subCheckbox: {
		marginLeft: "20px",
	},
	mainCard: {
		marginLeft: "66px",
		["@media (max-width:690px)"]: {
			width: "100%",
			marginLeft: "35px",
		},
	},
	icsFeed: {
		width: "100%",
		["@media (max-width:991px)"]: {
			width: "calc(100% - 60px)",
		},
	},
	icsCalander: {
		display: "flex",
		["@media (max-width:690px)"]: {
			width: "calc(100% - 60px)",
		},
	},
	subCard: {
		display: "flex",
		["@media (max-width:690px)"]: {
			width: "calc(100% - 60px)",
		},
	},
	helpButton: {
		marginTop: "10px",
	},
	inputfield: {
		width: "416px",
		["@media (max-width:690px)"]: {
			width: "100%",
		},
	},
	subTitle: {
		["@media (max-width:690px)"]: {
			width: "222px",
		},
		["@media (max-width:280px)"]: {
			marginLeft: "-15px",
		},
		fontSize: "16px",
		lineHeight: "1.5",
	},
	redirectLink: {
		marginLeft: "15px",
		marginTop: "22px",
		display: "flex",
		alignItems: "center",
		height: "100%",
	},
	ICSInput: {
		display: "flex",
		gap: "10px",
	},
	ICSField: {
		width: "100%",
		["@media (max-width:991px)"]: {
			width: "calc(100% - 60px)",
		},
	},
	addButton: {
		marginTop: "15px",
	},
	addImage: {
		bottom: "45px",
		position: "relative",
		["@media (max-width:690px)"]: {
			right: "90px",
		},
	},
	addLogo: {
		marginTop: "5px",
	},
	removeImage: {
		left: "50px",
		bottom: "86px",
		position: "relative",
		["@media (max-width:690px)"]: {
			left: "-40px",
		},
	},
	insideCheckbox: {
		display: "flex",

		"& p": {
			marginTop: "7px",
		},
	},
	indentedList: {
		padding: "0",
		margin: "0",
		marginLeft: "20px",
		listStyleType: "none",
	},
	labelAndSwitch: {
		display: "flex",
		alignItems: "center",
		gap: "100px",
		margin: "20px 0px 30px 0px",
	},
	labelAndSwitchForPolicy: {
		display: "flex",
		alignItems: "center",
		gap: "124px",
		margin: "35px 0px 30px 0px",
	},
	communityButtons: {
		display: "flex",
		gap: "8px",
	},
	communityButton: {
		width: "228px",
	},
	activeButton: {
		display: "flex",
		alignItems: "center",
		gap: "15px",
	},
	fileInput: {
		display: "none",
	},
});

export default settingsStyle;
