import React from "react";
import { useDroppable } from "@dnd-kit/core";

const TocDummyItem = (props) => {
	const { isHeader = false, height = 20, canDrop } = props;
	const { setNodeRef } = canDrop
		? useDroppable({
				id: isHeader ? "toc-header" : "toc-footer",
			})
		: {};

	return (
		<li
			id={isHeader ? "header" : "footer"}
			style={{
				height,
				position: "absolute",
				bottom: 0,
				width: "100%",
			}}
			ref={setNodeRef}
			{...props}
		/>
	);
};

export default React.memo(TocDummyItem);
