import React from "react";
import { withTranslation } from "react-i18next";
import getOr from "lodash/fp/getOr";

import SvgIcon from "@mui/material/SvgIcon";

import themeConfig from "../../atlas/assets/jss/theme";
import Tooltip from "atlas/components/Tooltip/Tooltip";

const icon = (props) => {
	const { color, t, keyPrefix, tReady, ...otherProps } = props;
	const fillColor = color ? getOr(color, ["palette", color, "main"], themeConfig) : "currentColor";
	const unique = Math.random().toString(36).substr(2, 9);

	return (
		<Tooltip title={t("buttons.moreActions")} aria-label={t("buttons.moreActions")}>
			<SvgIcon {...otherProps}>
				<defs>
					<path
						d="M12,21 C10.8954305,21 10,20.1045695 10,19 C10,17.8954305 10.8954305,17 12,17 C13.1045695,17 14,17.8954305 14,19 C14,20.1045695 13.1045695,21 12,21 Z M12,14 C10.8954305,14 10,13.1045695 10,12 C10,10.8954305 10.8954305,10 12,10 C13.1045695,10 14,10.8954305 14,12 C14,13.1045695 13.1045695,14 12,14 Z M12,7 C13.1045695,7 14,6.1045695 14,5 C14,3.8954305 13.1045695,3 12,3 C10.8954305,3 10,3.8954305 10,5 C10,6.1045695 10.8954305,7 12,7 Z"
						id={`more-vert-path-1-${unique}`}
					/>
				</defs>
				<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
					<g>
						<mask id={`more-vert-mask-2-${unique}`} fill="white">
							<use xlinkHref={`#more-vert-path-1-${unique}`} />
						</mask>
						<use fill="currentColor" fillRule="evenodd" xlinkHref={`#more-vert-path-1-${unique}`} />
						<rect fill={fillColor} fillRule="evenodd" mask={`url(#more-vert-mask-2-${unique}`} x="0" y="0" width="24" height="24" />
					</g>
				</g>
			</SvgIcon>
		</Tooltip>
	);
};
export default withTranslation("meetings")(icon);
