import React, { forwardRef } from "react";
import { useTranslation } from "react-i18next";
import clsx from "clsx";

import makeStyles from "@mui/styles/makeStyles";
import { Checkbox, FormControlLabel } from "@mui/material";

import dragAndDropStyle from "atlas/assets/jss/components/dragAndDropStyle";
import AccessibleIconButton from "atlas/components/Buttons/AccessibleIconButton";
import { grayColor, primaryColor } from "atlas/assets/jss/shared";
import DragHandle from "atlas/components/DragAndDrop/DragHandle";
import Tooltip from "atlas/components/Tooltip/Tooltip";
import telemetryAddEvent from "utils/telemetryAddEvent";
import { Check } from "components/Icons";
import GenericAvatar, { GENERIC_AVATAR_BACKGROUNDS } from "components/Avatar/GenericAvatar";

const focusableElement = {
	display: "flex",
	alignItems: "center",
	height: "48px",
	"&:focus": {
		backgroundColor: "rgba(0, 0, 0, 0.08)",
	},
};

const useDragAndDropStyles = makeStyles(dragAndDropStyle);
const useStyles = makeStyles(() => ({
	userListItem: {
		margin: "0",
		position: "relative",
		display: "flex",
		alignItems: "center",
		height: "48px",
		"&:hover": {
			backgroundColor: grayColor[4],
		},
	},
	userListDrag: {
		paddingLeft: "12px",
	},
	userListItemAvatar: {
		padding: "0 4px 0 8px",
		...focusableElement,
	},
	avatar: {
		width: "24px",
		height: "24px",
		fontSize: "12px",
		lineHeight: "14px",
		fontWeight: "600",
	},
	userListItemName: {
		flexShrink: "1",
		flexGrow: "1",
		padding: "0 8px 0 4px",
		wordBreak: "break-word",
		...focusableElement,
	},
	userListMenu: {
		cursor: "pointer",
	},
	canEdit: {
		whiteSpace: "nowrap",
		"& .MuiFormControlLabel-label": {
			marginLeft: 0,
		},
	},
	disabledCheckBox: {
		"&.MuiButtonBase-root.MuiCheckbox-root.Mui-disabled": {
			opacity: 0.38,
		},
	},
}));

const FolderPermissionUser = forwardRef((props, ref) => {
	const {
		className,
		permissionUser,
		user,
		showEdit,
		editEnabled = true,
		handleUpdate,
		actionIcon,
		actionTooltip,
		handleAction,
		dragAttributes = {},
		dragListeners = {},
		canDrag,
		dragPlaceholder,
		dragPresentational,
		telemetryPage,
		...otherProps
	} = props;
	const { t } = useTranslation("documents");
	const dragAndDropClasses = useDragAndDropStyles();
	const classes = useStyles({ dragPresentational: dragPresentational });

	const getUserName = (user) => (
		<span>
			{user.userDeleted || user.userInactive
				? t(`app:entities.${user.userDeleted ? "deleted" : "inactive"}`, { entity: user.name })
				: user.name}
		</span>
	);

	return user ? (
		<li
			className={clsx(className, classes.userListItem, {
				[dragAndDropClasses.dragPlaceholder]: dragPlaceholder,
				[classes.userListDrag]: canDrag || dragPresentational,
			})}
			ref={ref}
			{...otherProps}
		>
			<div className={classes.userListItemAvatar} {...dragAttributes} {...dragListeners}>
				{(canDrag || dragPresentational) && <DragHandle />}

				<GenericAvatar
					className={classes.avatar}
					imageUrl={user.profileImageUrl}
					name={user.name}
					initials={`${(user.firstName || "").substr(0, 1)}${(user.lastName || "").substr(0, 1)}`}
					backgroundNumber={((user.number || 0) % GENERIC_AVATAR_BACKGROUNDS) + 1}
				/>
			</div>
			<div className={classes.userListItemName} {...dragAttributes} {...dragListeners}>
				{user.emailAddress ? (
					<Tooltip
						PopperProps={{
							disablePortal: true,
						}}
						title={user.emailAddress}
					>
						{getUserName(user)}
					</Tooltip>
				) : (
					getUserName(user)
				)}
			</div>
			{showEdit && (
				<div className={classes.canEdit}>
					<FormControlLabel
						control={
							<Checkbox
								className={clsx({ [classes.disabledCheckBox]: !editEnabled })}
								checkedIcon={<Check fontSize="small" color="primary" />}
								checked={permissionUser.canUpdate}
								disabled={!editEnabled}
								onChange={(e) => {
									telemetryAddEvent(`Policy - ${e.target.checked ? "Add" : "Remove"} Policy User permissions`);
									handleUpdate(e, "canUpdate", true, false, permissionUser, undefined, (updatedFolderPermission, folder) => {
										// Create a new array and new object in order trigger a re-render
										folder.folderPermissions = folder.folderPermissions.map((folderPermission) =>
											folderPermission.userId !== updatedFolderPermission.userId ? folderPermission : { ...updatedFolderPermission },
										);
									});
								}}
							/>
						}
						label={t("customFolderDetail.canEdit")}
						data-cy={`user-can-edit-${user.id}`}
					/>
				</div>
			)}
			{handleAction && (
				<div className={classes.userListMenu}>
					<AccessibleIconButton
						iconName={actionIcon}
						iconColor={primaryColor[1]}
						tooltipText={actionTooltip}
						onClick={(e) => {
							e.stopPropagation();

							handleAction(e, user.id);
						}}
						dataCy={`action-${user.id}`}
					/>
				</div>
			)}
		</li>
	) : null;
});

export default React.memo(FolderPermissionUser);
