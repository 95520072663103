export const GET_MEETING_TEMPLATES_PENDING = "GET_MEETING_TEMPLATES_PENDING";
export const GET_MEETING_TEMPLATES_FULFILLED = "GET_MEETING_TEMPLATES_FULFILLED";
export const GET_MEETING_TEMPLATE_PENDING = "GET_MEETING_TEMPLATE_PENDING";
export const GET_MEETING_TEMPLATE_FULFILLED = "GET_MEETING_TEMPLATE_FULFILLED";
export const GET_MEETING_TEMPLATE_AGENDA_ITEMS_PENDING = "GET_MEETING_TEMPLATE_AGENDA_ITEMS_PENDING";
export const GET_MEETING_TEMPLATE_AGENDA_ITEMS_FULFILLED = "GET_MEETING_TEMPLATE_AGENDA_ITEMS_FULFILLED";
export const GET_MEETING_TEMPLATE_MINUTES_ITEMS_PENDING = "GET_MEETING_TEMPLATE_MINUTES_ITEMS_PENDING";
export const GET_MEETING_TEMPLATE_MINUTES_ITEMS_FULFILLED = "GET_MEETING_TEMPLATE_MINUTES_ITEMS_FULFILLED";
export const UPDATE_MEETING_TEMPLATE = "UPDATE_MEETING_TEMPLATE";
export const UPDATE_MEETING_TEMPLATE_AGENDA_ITEMS = "UPDATE_MEETING_TEMPLATE_AGENDA_ITEMS";
export const UPDATE_MEETING_TEMPLATE_AGENDA_PUBLIC_RELEASE_DELAY = "UPDATE_MEETING_TEMPLATE_AGENDA_PUBLIC_RELEASE_DELAY";
export const UPDATE_MEETING_TEMPLATE_AGENDA_SHOW_CONFIDENTIAL_ITEM_LABEL = "UPDATE_MEETING_TEMPLATE_AGENDA_SHOW_CONFIDENTIAL_ITEM_LABEL";
export const UPDATE_MEETING_TEMPLATE_AGENDA_HIDE_RECOMMENDATIONS_ON_PUBLIC =
	"UPDATE_MEETING_TEMPLATE_AGENDA_HIDE_RECOMMENDATIONS_ON_PUBLIC";
export const UPDATE_MEETING_TEMPLATE_MINUTES_ITEMS = "UPDATE_MEETING_TEMPLATE_MINUTES_ITEMS";
export const ADD_MEETING_TEMPLATE_AGENDA_MEETING_ITEM = "ADD_MEETING_TEMPLATE_AGENDA_MEETING_ITEM";
export const DELETE_MEETING_TEMPLATE_AGENDA_MEETING_ITEM = "DELETE_MEETING_TEMPLATE_AGENDA_MEETING_ITEM";
export const RESTORE_MEETING_TEMPLATE_AGENDA_MEETING_ITEM = "RESTORE_MEETING_TEMPLATE_AGENDA_MEETING_ITEM";
export const REORDER_MEETING_TEMPLATE_AGENDA_ITEMS = "REORDER_MEETING_TEMPLATE_AGENDA_ITEMS";
export const UPDATE_MEETING_TEMPLATE_VOTING_ROLL_CALL = "UPDATE_MEETING_TEMPLATE_VOTING_ROLL_CALL";
export const PERSIST_MEETING_TEMPLATE_PENDING = "PERSIST_MEETING_TEMPLATE_PENDING";
export const PERSIST_MEETING_TEMPLATE_FULFILLED = "PERSIST_MEETING_TEMPLATE_FULFILLED";
export const PERSIST_MEETING_TEMPLATE_AGENDA_ITEMS_PENDING = "PERSIST_MEETING_TEMPLATE_AGENDA_ITEMS_PENDING";
export const PERSIST_MEETING_TEMPLATE_AGENDA_ITEMS_FULFILLED = "PERSIST_MEETING_TEMPLATE_AGENDA_ITEMS_FULFILLED";
export const PERSIST_MEETING_TEMPLATE_AGENDA_ITEMS_FAILED = "PERSIST_MEETING_TEMPLATE_AGENDA_ITEMS_FAILED";
export const PERSIST_MEETING_TEMPLATE_MINUTES_ITEMS_PENDING = "PERSIST_MEETING_TEMPLATE_MINUTES_ITEMS_PENDING";
export const PERSIST_MEETING_TEMPLATE_MINUTES_ITEMS_FULFILLED = "PERSIST_MEETING_TEMPLATE_MINUTES_ITEMS_FULFILLED";
export const PERSIST_MEETING_TEMPLATE_MINUTES_ITEMS_FAILED = "PERSIST_MEETING_TEMPLATE_MINUTES_ITEMS_FAILED";
export const CLEAR_MEETING_TEMPLATE_STATUS = "CLEAR_MEETING_TEMPLATE_STATUS";
export const SET_ACTIVE = "SET_MEETING_TEMPLATE_ACTIVE";
export const SET_CUSTOM_NUMBERING_OPTION = "SET_CUSTOM_NUMBERING_OPTION";
export const UPDATE_MINUTES_INCLUDES_MINUTES_ATTACHMENT = "UPDATE_MINUTES_INCLUDES_MINUTES_ATTACHMENT";
export const UPDATE_SHOW_CLOSED_ITEM_TITLE = "UPDATE_SHOW_CLOSED_ITEM_TITLE";
export const UPDATE_ALWAYS_USE_RECORDED_VOTE = "UPDATE_ALWAYS_USE_RECORDED_VOTE";
export const UPDATE_PUBLISHED_DRAFT_MINUTES = "UPDATE_PUBLISHED_DRAFT_MINUTES";
export const UPDATE_USE_DIGITAL_VOTING = "UPDATE_USE_DIGITAL_VOTING";

export const UPDATE_SIGNATURE_FAILED = "UPDATE_SIGNATURE_FAILED";
export const UPDATE_SIGNATURE_FULFILLED = "UPDATE_SIGNATURE_FULFILLED";
export const UPDATE_SIGNATURE_PENDING = "UPDATE_SIGNATURE_PENDING";
