import React from "react";
import { useTranslation } from "react-i18next";
import clsx from "clsx";

import makeStyles from "@mui/styles/makeStyles";
import { Button, Checkbox, FormControlLabel } from "@mui/material";

import { Check } from "components/Icons";

import clonedeep from "lodash/cloneDeep";

import inputStyle from "atlas/assets/jss/components/inputStyle";
import OutlinedInput from "atlas/components/FormControls/OutlinedInput";
import StackedCheckbox from "atlas/components/FormControls/StackedCheckbox";
import filterStyle from "assets/jss/components/filterStyle";

const useInputStyles = makeStyles(inputStyle);
const useFilterStyles = makeStyles({
	...filterStyle,
	meetingTypes: {
		"& label > span": {
			whiteSpace: "normal",
			height: "auto",
		},
	},
});

const SubscribersFilter = (props) => {
	const { filters, meetingTypes, subscribersCount, onFilterChange } = props;

	const { t } = useTranslation("subscribers");
	const classes = useFilterStyles();
	const inputClasses = useInputStyles({ fullWidth: true });

	const fieldChange = (e, field) => {
		if (filters) {
			const newFilters = clonedeep(filters);

			const value = field === "emailAddress" ? e.target.value : field === "meetingType" ? e.id : e.target.checked;

			switch (field) {
				case "emailAddress":
					newFilters.emailAddress = value;
					break;
				case "meetingType":
					const intValue = parseInt(value, 10);
					const selectedMeetingTypeIndex = newFilters.meetingTypes.indexOf(intValue);
					if (selectedMeetingTypeIndex >= 0) {
						newFilters.meetingTypes = newFilters.meetingTypes.splice(intValue, 1);
					} else {
						newFilters.meetingTypes.push(intValue);
					}
					break;
				case "publicYes":
					newFilters.publicYes = value;
					break;
				case "publicNo":
					newFilters.publicNo = value;
					break;
				case "activeYes":
					newFilters.activeYes = value;
					break;
				case "activeNo":
					newFilters.activeNo = value;
					break;
			}

			onFilterChange(newFilters);
		}
	};

	return (
		<>
			<div className={classes.section}>
				<div className={classes.fieldHeading}>
					<label id="user-types-label" htmlFor="email-address">
						{t("filter.emailAddress")}
					</label>
				</div>
				<OutlinedInput
					id="email-address"
					className={inputClasses.smallInput}
					noDefaultClassName
					fullWidth
					value={filters != null ? filters.emailAddress : ""}
					onChange={(e) => fieldChange(e, "emailAddress")}
					data-cy="email-address"
				/>
			</div>
			<div className={classes.section}>
				<div className={clsx(classes.fieldHeading, classes.fieldHeadingStackedCheckbox)}>
					<label id="meeting-type-label" htmlFor="meeting-type">
						{t("filter.meetingType")}
					</label>
				</div>
				<div id="meeting-type" className={classes.meetingTypes}>
					<StackedCheckbox
						options={meetingTypes}
						getChecked={(meetingType) => filters && filters.meetingTypes && filters.meetingTypes.includes(meetingType.id)}
						getId={(meetingType) => `filter-meeting-type-${meetingType.id}`}
						getKey={(meetingType) => `filter-meeting-type-${meetingType.id}`}
						getValue={(meetingType) => meetingType.id}
						getDataCy={(meetingType) => `filter-meeting-type-${meetingType.id}`}
						getLabel={(meetingType) =>
							meetingType.name +
							(subscribersCount
								? ` (${subscribersCount.find((subscriberCount) => subscriberCount.meetingTypeId === meetingType.id)?.count || 0})`
								: "")
						}
						handleChange={(e) => fieldChange(e, "meetingType")}
					/>
				</div>
			</div>
			<div className={classes.section}>
				<div className={clsx(classes.fieldHeading, classes.fieldHeadingStackedCheckbox)}>
					<label id="public-label" htmlFor="public">
						{t("filter.public")}
					</label>
				</div>
				<div id="public">
					<FormControlLabel
						id="public-yes-checkbox"
						control={
							<Checkbox
								checkedIcon={<Check fontSize="small" color="primary" />}
								checked={filters && filters.publicYes}
								onChange={(e) => fieldChange(e, "publicYes")}
							/>
						}
						label={t("filter.yes")}
						data-cy="public-yes-checkbox"
					/>
					<FormControlLabel
						id="public-no-checkbox"
						control={
							<Checkbox
								checkedIcon={<Check fontSize="small" color="primary" />}
								checked={filters && filters.publicNo}
								onChange={(e) => fieldChange(e, "publicNo")}
							/>
						}
						label={t("filter.no")}
						data-cy="public-no-checkbox"
					/>
				</div>
			</div>
			<div className={classes.section}>
				<div className={clsx(classes.fieldHeading, classes.fieldHeadingStackedCheckbox)}>
					<label id="active-label" htmlFor="active">
						{t("filter.active")}
					</label>
				</div>
				<div id="active">
					<FormControlLabel
						id="active-yes-checkbox"
						control={
							<Checkbox
								checkedIcon={<Check fontSize="small" color="primary" />}
								checked={filters && filters.activeYes}
								onChange={(e) => fieldChange(e, "activeYes")}
							/>
						}
						label={t("filter.yes")}
						data-cy="active-yes-checkbox"
					/>
					<FormControlLabel
						id="active-no-checkbox"
						control={
							<Checkbox
								checkedIcon={<Check fontSize="small" color="primary" />}
								checked={filters && filters.activeNo}
								onChange={(e) => fieldChange(e, "activeNo")}
							/>
						}
						label={t("filter.no")}
						data-cy="active-no-checkbox"
					/>
				</div>
			</div>
		</>
	);
};

export default SubscribersFilter;
