import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import clsx from 'clsx';

import makeStyles from '@mui/styles/makeStyles';

import dragAndDropStyle from '../../assets/jss/components/dragAndDropStyle';

const useDragAndDropStyles = makeStyles(dragAndDropStyle);

const Sortable = (props) => {
	const { dragId: id, dragData: data, dragComponent: Component, dragAttributePassthrough, children, className, ...otherProps } = props;
	const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
		id,
		data,
	});
	const dragAndDropClasses = useDragAndDropStyles();
	const style = {
		transform: CSS.Transform.toString(transform),
		transition,
	};

	return (
		<Component
			ref={setNodeRef}
			style={style}
			{...(!dragAttributePassthrough ? attributes : {})}
			{...(!dragAttributePassthrough ? listeners : {})}
			dragAttributes={dragAttributePassthrough ? attributes : undefined}
			dragListeners={dragAttributePassthrough ? listeners : undefined}
			dragPlaceholder={isDragging}
			{...otherProps}
			className={clsx(className, dragAndDropClasses.draggable)}
		>
			{children}
		</Component>
	);
};

export default Sortable;
