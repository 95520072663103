import React from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import clsx from "clsx";

import makeStyles from "@mui/styles/makeStyles";

import processHtml from "utils/processHtml";
import Icon from "atlas/components/Icon/Icon";
import { blackColor } from "atlas/assets/jss/shared";
import LiveMeetingRadioButton from "./LiveMeetingRadioButton";
import LiveMeetingAttachments from "./LiveMeetingAttachments";

import agendaStyle from "../../../assets/jss/components/agendaStyle";

const useStyle = makeStyles(agendaStyle);

const LiveMeetingRecommendation = (props) => {
	const {
		recommendation,
		isClosedMeeting,
		isMemberOnlyHeading,
		isConsentHeading,
		isPublicCommentHeading,
		isHeadingAction,
		addBottomBorder,
		selected,
		hasNotes,
		handleSelect,
		presentation,
		videoControlsDisabled,
	} = props;
	const { fields, attachments } = recommendation;
	const classes = useStyle({ isHeadingAction });

	const isSelected = selected === recommendation.guid;

	return (
		<>
			<li
				id={`agenda-${recommendation.guid}`}
				className={clsx(classes.agendaListItem, {
					[classes.radioListItem]: !presentation,
					[classes.borderDefault]: !isMemberOnlyHeading && !isConsentHeading && !isPublicCommentHeading && !addBottomBorder,
					[classes.borderDefaultBottomBorder]: !isMemberOnlyHeading && !isConsentHeading && !isPublicCommentHeading && addBottomBorder,
					[classes.borderMemberOnly]: isMemberOnlyHeading,
					[classes.borderConsent]: isConsentHeading,
					[classes.borderPublicComment]: isPublicCommentHeading,
					[classes.selected]: isSelected && !presentation,
					[classes.presentationSelected]: isSelected && presentation,
				})}
				data-cy={`agenda-${recommendation.guid}`}
			>
				{!presentation && (
					<LiveMeetingRadioButton
						value={recommendation.guid}
						label={fields.Name.Value}
						selected={isSelected}
						hasNotes={hasNotes}
						handleSelect={handleSelect}
						disabled={videoControlsDisabled}
					/>
				)}
				<div
					className={clsx(classes.item, classes.itemRecommendation, classes.liveMeetingItem, classes.rightBorder, {
						[classes.bottomBorder]: addBottomBorder,
					})}
				>
					{!presentation && (
						<div data-icon="recommendation">
							<Icon name="recommendation" color={blackColor[1]} />
						</div>
					)}
					<div>
						{processHtml(fields.Name.Value, {
							disableLinks: !presentation,
							classes: { link: classes.disabledLink },
							excludeMemberOnlyAttachments: presentation,
						})}
						{presentation && processHtml(fields.Text.Value, { excludeMemberOnlyAttachments: presentation })}
					</div>
				</div>
			</li>
			{!presentation && (
				<LiveMeetingAttachments
					attachments={attachments}
					item={recommendation}
					isClosedMeeting={isClosedMeeting}
					isMemberOnlyHeading={isMemberOnlyHeading}
					isConsentHeading={isConsentHeading}
					isPublicCommentHeading={isPublicCommentHeading}
					addBottomBorder={addBottomBorder}
					selected={selected}
					handleSelect={handleSelect}
					presentation={presentation}
				/>
			)}
		</>
	);
};

LiveMeetingRecommendation.propTypes = {
	recommendation: PropTypes.object.isRequired,
	isClosedMeeting: PropTypes.bool,
	isMemberOnlyHeading: PropTypes.bool,
	isConsentHeading: PropTypes.bool,
	isPublicCommentHeading: PropTypes.bool,
	addBottomBorder: PropTypes.bool,
	selected: PropTypes.string.isRequired,
	hasNotes: PropTypes.bool,
	handleSelect: PropTypes.func,
};

LiveMeetingRecommendation.defaultProps = {
	isClosedMeeting: false,
	isMemberOnlyHeading: false,
	isConsentHeading: false,
	isPublicCommentHeading: false,
	addBottomBorder: false,
	hasNotes: false,
	handleSelect: undefined,
};

export default withTranslation()(LiveMeetingRecommendation);
