import React, { Component } from "react";
import { connect } from "react-redux";
import request from "superagent";
import { withTranslation } from "react-i18next";
import { withRouter } from "utils/router";

import ComponentContainer from "atlas/components/ComponentContainer/ComponentContainer";
import withErrorHandling from "components/ErrorHOC";
import { API_HOST } from "config/env";
import { SettingsContext } from "contexts/Settings/SettingsContext";
import telemetryAddEvent from "utils/telemetryAddEvent";
import GoalList from "./components/GoalList";

import { mapStateToProps } from "../../redux/app/mapStateToProps";
import { resetPageConfigs, updatePageConfigs } from "redux/app/actions";
import { updatePageHeader } from "redux/pageHeader/actions";

class GoalsModule extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loading: false,
			goalFetchHappened: false,
			tab: 0,
		};
	}

	componentDidMount() {
		const { t, dispatch } = this.props;

		dispatch(resetPageConfigs({ resetBack: true }));
		dispatch(updatePageConfigs({ title: t("goals"), telemetryPage: "Goals" }));
		this.loadGoals();
	}

	componentDidUpdate() {
		const { t, dispatch, goalAdmin } = this.props;

		dispatch(
			updatePageHeader({
				primaryAction: goalAdmin
					? () => {
							telemetryAddEvent("Goal - Add goal");

							this.props.navigate("/goals/create");
						}
					: undefined,
				primaryActionText: goalAdmin ? t("buttons.addGoal") : undefined,
				primaryActionTooltip: goalAdmin ? t("tooltips.addGoal") : undefined,
			}),
		);
	}

	getGoals = () => {
		const { goals } = this.state;
		return goals;
	};

	loadGoals = (more = false, tab = 0) => {
		const { showSignIn, goalAdmin } = this.props;
		const { goals = [] } = this.state;
		let tabsNumber = tab;
		if (tab === 3 && !goalAdmin) {
			tabsNumber = 4;
		}

		this.setState({ loading: true, moreLoading: more });

		request
			.get(`${API_HOST}/api/goals?type=${tabsNumber + 1}`)
			.then((res) => {
				let newState = {};

				if (more) {
					newState.goals = goals.concat(res.body.goals);
					newState.more = res.body.more;
				} else {
					// Get a fresh list of goals
					newState = {
						goals: res.body,
					};
				}
				newState.loading = false;
				newState.moreLoading = false;
				newState.goalFetchHappened = true; // keeps 'no goal loaded' message from showing on initial load
				this.setState(newState);
			})
			.catch((err) => {
				showSignIn(err, () => {
					this.loadGoals(more);
				});
			});
	};

	removeGoalFromList = (id) => {
		const { goals } = this.state;
		this.setState({
			goals: goals.filter((goal) => goal.id !== id),
		});
	};

	tabChange = (_e, tab) => {
		this.setState({ tab });

		this.loadGoals(false, tab);
	};

	render() {
		const { goalAdmin, dispatch } = this.props;
		const { settings, more, loading, moreLoading, goalFetchHappened, tab } = this.state;

		return (
			<ComponentContainer padding="0">
				<GoalList
					goalAdmin={goalAdmin}
					settings={settings}
					goals={this.getGoals()}
					more={more}
					moreClick={this.moreClick}
					loading={loading}
					moreLoading={moreLoading}
					goalFetchHappened={goalFetchHappened}
					removeGoalFromList={this.removeGoalFromList}
					tabChange={this.tabChange}
					telemetryPage="Goal"
					dispatch={dispatch}
					tab={tab}
					loadGoals={this.loadGoals}
				/>
			</ComponentContainer>
		);
	}
}
GoalsModule.contextType = SettingsContext;

export default withRouter(withTranslation("goals")(withErrorHandling(connect(mapStateToProps)(GoalsModule))));
