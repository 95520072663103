import React from "react";
import { Routes, Route } from "react-router-dom";

import DocumentContainer from "views/Documents/DocumentContainer";
import DocumentEditContainer from "views/Documents/DocumentEditContainer";

const DocumentDetailRoutes = () => {
	// Base path = /document/
	return (
		<Routes>
			<Route path=":id" element={<DocumentContainer />} />
			<Route path="/edit/:id" element={<DocumentEditContainer />} />
		</Routes>
	);
};

export default DocumentDetailRoutes;
