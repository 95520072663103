import React from "react";
import { useTranslation } from "react-i18next";

import { Checkbox } from "@mui/material";

const DocumentTreeSelectItemColumn = (props) => {
	const { document, width, action } = props;
	const { t } = useTranslation("documents");

	return (
		<div style={{ width: width }}>
			<Checkbox
				color="primary"
				indeterminate={!document.selected && document.childrenSelected}
				checked={document.selected || false}
				inputProps={{ "aria-label": t("list.tooltips.select") }}
				onChange={(e) => action([document.guid], e.target.checked)}
				data-cy={`${document.guid}-checkbox`}
			/>
		</div>
	);
};

export default DocumentTreeSelectItemColumn;
