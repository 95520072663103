import React, { useEffect, useState, useRef } from "react";
import TableOfContents from "components/Meeting/TableOfContents";
import makeStyles from "@mui/styles/makeStyles";
import AccessibleIconButton from "atlas/components/Buttons/AccessibleIconButton";
import { reOrderItemArray } from "views/MeetingEditor/functions/utils";
import { setFinalRightMeetingAgenda, setOverallSelectedMeetingItems, createItemsForOverflow } from "../../redux/NewCopyAndMove/actions";
import Container from "@mui/material/Container";
import { Box } from "@mui/material";
import ListCard from "atlas/components/Cards/ListCard";
import MeetingPublishStatus from "../MeetingCard/MeetingPublishStatus";
import { formatDate } from "utils/date";
import { getSharedOrPublished, handleKeyDownForToAgenda } from "../CopyMove/utils/functions";
import { useSelector, useDispatch } from "react-redux";
import { useWidthUp } from "atlas/utils/useWidth";
import { useTranslation } from "react-i18next";
import ellipsedTruncateText from "utils/ellipsedTruncateText";
import { getFieldValue } from "atlas/utils/itemData";
import { stripHtml } from "utils/processHtml";
import { childModifier, createItemArrayForOverflowMenu, parentModifier, subheadingModifier } from "./utils/copyMovefunctions";
import Warning from "./utilsComponent/Warning";
import Tooltip from "atlas/components/Tooltip/Tooltip";
import Icon from "atlas/components/Icon/Icon";
import telemetryAddEvent from "utils/telemetryAddEvent";
import { v4 as uuid } from "uuid";

const useStyles = makeStyles(() => ({
	removeButton: {
		position: "absolute",
		right: "10px",
		bottom: "12px",
	},
	mainContent: {
		maxWidth: (props) => (props.widthUpLg ? "70%" : props.widthUpMd ? "70%" : undefined),
		minWidth: (props) => (props.widthUpLg ? "70%" : props.widthUpMd ? "70%" : undefined),
		display: "flex",
		flexGrow: "0",
		flexShrink: "0",
		flexWrap: "no-wrap",
		padding: "0px 8px",
		boxSizing: "border-box",
		whiteSpace: "nowrap",
	},
	calendarIcon: {
		display: "inline-flex",
		alignItems: "center",
		flexWrap: "wrap",
		width: "48px",
		height: "50px",
		marginRight: "10px",
		backgroundColor: "#E6E6E6",
		borderRadius: "10px",
		overflow: "hidden",
		flexGrow: "0",
		flexShrink: "0",
		whiteSpace: "nowrap",
		"& *": {
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
			width: "100%",
			fontStyle: "normal",
		},
		"& h5": {
			height: "26px",
			fontSize: "12px",
			color: "#FFFFFF",
			textTransform: "uppercase",
			backgroundColor: "#455D82",
		},
		"& h4": {
			letterSpacing: "-0.05em",
			fontSize: "16px",
			height: "24px",
			fontWeight: "600",
			color: "#2e2e2e",
		},
	},
	textDetails: {
		marginBottom: (props) => (!props.widthUpMd ? "16px" : undefined),
	},
	statusTag: {
		display: "flex",
		justifyContent: "flex-end",
	},
	newTag: {
		whiteSpace: "nowrap",
		fontFamily: "Source Sans Pro",
		fontSize: "12px !important",
		fontWeight: "600 !important",
		fontStyle: " normal",
		textTransform: "uppercase",
		borderRadius: " 4px",
		padding: "4px 8px 4px 8px",
		letterSpacing: " 0.7px !important",
		verticalAlign: " middle",
		display: "inlineblock",
		flexShrink: " 0",
		backgroundColor: " #385f99",
		color: " #fff !important",
	},
	newTagContainer: {
		flexGrow: "1",
		textAlign: " end",
	},
	briefingNotesSpan: {
		position: "relative",
		top: "8px",
		right: "10px",
	},
}));
const ToAgendaListPage = (props) => {
	const { callBackforToAgendaListPage, copyOrMove, isOverflow } = props;
	const widthUpMd = useWidthUp("md");
	const widthUpLg = useWidthUp("lg");
	const classes = useStyles({ widthUpMd, widthUpLg });
	const { t } = useTranslation("meetings");
	const [warningHeight, setWarningHeight] = useState(0);
	const warningRef = useRef(null);
	const dispatch = useDispatch();
	const {
		finalRightMeetingAgendaItems,
		leftAgendaObject: objectForAgendaItems,
		originalRightMeetingAgendaItems,
		overallSelectedMeetingItems,
		selectedMeeting,
		isNewMeeting,
		toAgendaWarning,
		agendaItemsForOverflow,
	} = useSelector((state) => state.newCopyAndMoveReducer);
	const isMove = copyOrMove === "move";

	useEffect(() => {
		const listItems = document.querySelectorAll(".transition-animation");
		const memberOnlyListItems = document.querySelectorAll(".transition-animation-memberOnly");
		setTimeout(() => {
			listItems.forEach((element) => {
				element.classList.add("transition-animation-added");
				element.classList.remove("transition-animation");
			});
			memberOnlyListItems.forEach((element) => {
				element.classList.add("transition-animation-added-memberOnly");
				element.classList.remove("transition-animation-memberOnly");
			});
		}, 1000);
	}, []);

	useEffect(() => {
		callBackforToAgendaListPage();
		const result = finalRightMeetingAgendaItems.find((element) => element.copied && (element.deleteEnabled || !element.itemDeleteDisable));
		if (result) {
			result.isFirstElementForSkipContent = true;
		}
	}, []);

	useEffect(() => {
		const resizeObserver = new ResizeObserver((entries) => {
			for (let entry of entries) {
				if (entry.target === warningRef.current) {
					setWarningHeight(entry.contentRect.height);
				}
			}
		});
		if (warningRef.current) {
			resizeObserver.observe(warningRef.current);
		}
		return () => {
			if (warningRef.current) {
				resizeObserver.unobserve(warningRef.current);
			}
		};
	}, []);

	const transformData = (arr) => {
		return arr.map((item) => {
			const { copied, attachments, ...rest } = item;
			const newAttachment = attachments.length > 0 ? attachments.map((att) => ({ ...att, copied })) : [];
			return { copied, ...rest, attachments: newAttachment };
		});
	};

	const getEndIndexOnCondition = (arr, startIndex, conditionCallback) => {
		let endIndex = -1;
		for (let i = startIndex + 1; i < arr.length; i++) {
			if (conditionCallback(arr[i]) === true) {
				endIndex = i;
				break;
			}
		}
		// If no condition is satisfied, return the last index
		return endIndex !== -1 ? endIndex : arr.length === startIndex ? arr.length + 1 : arr.length;
	};

	const handleDeleteForItemNotPresent = (item, index) => {
		const {
			guid,
			attributes: { relationshipGuid },
			itemType,
		} = item;
		let finalArray = [];
		const [subArray, startIndex, endIndex] = createItemArrayForOverflowMenu(finalRightMeetingAgendaItems, item, index, false);
		if (itemType === 3 && Array.isArray(overallSelectedMeetingItems[guid])) {
			const mainParenGuid = overallSelectedMeetingItems[guid][0];
			const mainSubheadingGuid = overallSelectedMeetingItems[guid][1];
			if (overallSelectedMeetingItems[mainParenGuid][mainSubheadingGuid].enabled) {
				const subheadingItem = subArray.find((item) => item.guid === mainSubheadingGuid);
				if (!subheadingItem.newguid) {
					const subheadingGuidGenerated = uuid();
					subheadingItem["newguid"] = subheadingGuidGenerated;
					finalArray = updateItemsBasedOnGuid(objectForAgendaItems[mainSubheadingGuid].children, subArray, subheadingGuidGenerated);
				}
			}
		}
		finalArray = finalArray.length === 0 ? subArray : finalArray;
		if (!finalArray[0].newguid && finalArray[0].itemType === 10 && !finalArray[0].attributes.relationshipGuid) {
			const mainGuidGenerated = uuid();
			finalArray[0]["newguid"] = mainGuidGenerated;
			finalArray = updateItemsBasedOnGuid(objectForAgendaItems[relationshipGuid].children, finalArray, mainGuidGenerated);
		}
		replaceArraySection(finalRightMeetingAgendaItems, finalArray, startIndex, endIndex);
	};

	const handleDelete = (index, item) => {
		telemetryAddEvent(`Copy/Move - ${copyOrMove} - Remove item`);
		const { guid, itemType, attributes: { relationshipGuid = null } = {}, checkForSibling = false } = item;
		//There is a possibility that you have already deleted it's all children or maybe some children then you can't go blindly by deleting the children else it is going to delete things at other index
		if (itemType === 10 && !relationshipGuid) {
			let nextItem10Index = getEndIndexOnCondition(
				finalRightMeetingAgendaItems,
				index,
				(item) => item.itemType === 10 && !item.attributes.relationshipGuid,
			);
			finalRightMeetingAgendaItems.splice(index, nextItem10Index - index);
			parentModifier(guid, false, overallSelectedMeetingItems, dispatch, setOverallSelectedMeetingItems);
		} else if (itemType === 10 && relationshipGuid) {
			let nextItem10Index = getEndIndexOnCondition(finalRightMeetingAgendaItems, index, (item) => item.itemType === 10);
			if (isMove && !checkForSibling && objectForAgendaItems[relationshipGuid] && !objectForAgendaItems[relationshipGuid].isItemPresent) {
				handleDeleteForItemNotPresent(item, index);
			}
			finalRightMeetingAgendaItems.splice(index, nextItem10Index - index);
			if (checkForSibling) {
				deleteParentIfNoSiblingForType(relationshipGuid);
			}
			subheadingModifier(guid, false, itemType, overallSelectedMeetingItems, dispatch, setOverallSelectedMeetingItems);
		} else if (itemType === 3) {
			let childrenLength = objectForAgendaItems[guid].children?.length;
			if (isMove && !checkForSibling && objectForAgendaItems[relationshipGuid] && !objectForAgendaItems[relationshipGuid].isItemPresent) {
				handleDeleteForItemNotPresent(item, index);
			}
			finalRightMeetingAgendaItems.splice(index, childrenLength + 1);
			if (checkForSibling) {
				deleteParentIfNoSiblingForType(relationshipGuid);
			}
			if (typeof overallSelectedMeetingItems[guid] === "string") {
				childModifier(guid, false, overallSelectedMeetingItems, dispatch, setOverallSelectedMeetingItems);
			} else if (Array.isArray(overallSelectedMeetingItems[guid])) {
				subheadingModifier(guid, false, itemType, overallSelectedMeetingItems, dispatch, setOverallSelectedMeetingItems);
			}
		} else if (itemType === 7) {
			finalRightMeetingAgendaItems.splice(index, 1);
		}
		reOrderArrayAfterDeletion(finalRightMeetingAgendaItems);
		callBackforToAgendaListPage();
		if (isOverflow) {
			agendaItemsForOverflow.forEach((item) => {
				if (item.guid === guid && item.isItemSelectedFromOverflow) {
					item.isItemSelectedFromOverflow = false;
				}
			});
			dispatch(createItemsForOverflow(agendaItemsForOverflow));
		}
	};

	const updateItemsBasedOnGuid = (firstArray, secondArray, mainGuidGenerated) => {
		return secondArray.map((item) => {
			const matchingItem = firstArray.find((firstItem) => firstItem.guid === item.guid);
			return matchingItem ? { ...item, attributes: { ...item.attributes, newrelationshipGuid: mainGuidGenerated } } : item;
		});
	};

	const replaceArraySection = (finalArray, updatedArray, startIndex, endIndex) => {
		// Use splice to replace the portion of finalArray with updatedArray
		finalArray.splice(startIndex, endIndex - startIndex, ...updatedArray);
	};

	const deleteParentIfNoSiblingForType = (relationshipGuid) => {
		const parentIndex = finalRightMeetingAgendaItems.findIndex((item) => item.guid === relationshipGuid);
		const parentItem = finalRightMeetingAgendaItems[parentIndex];
		if (parentItem.itemDeleteDisable) {
			if (parentItem.guid) {
				// Check if the parent has any other children
				const hasOtherChildren = finalRightMeetingAgendaItems.some((item) => item.attributes?.relationshipGuid === parentItem.guid);
				if (!hasOtherChildren) {
					// If no other children, remove the parent
					finalRightMeetingAgendaItems.splice(parentIndex, 1);
					if (parentItem.itemType === 10 && parentItem.attributes.relationshipGuid) {
						deleteParentIfNoSiblingForType(parentItem.attributes.relationshipGuid);
					}
				}
			}
		}
	};

	const reOrderArrayAfterDeletion = () => {
		reOrderItemArray(
			finalRightMeetingAgendaItems,
			{ props: { agendaNumbering: originalRightMeetingAgendaItems.agendaNumbering }, itemIdsToUpdate: [] },
			originalRightMeetingAgendaItems.customNumbering,
		);

		dispatch(setFinalRightMeetingAgenda(finalRightMeetingAgendaItems));
	};

	const customPopperProps = {
		modifiers: [
			{
				name: "offset",
				options: {
					offset: [10, 15],
				},
			},
		],
	};

	const renderControl = {
		rendercomponent: (item, index) => (
			<AccessibleIconButton
				className={classes.removeButton}
				style={{
					padding: "0px",
				}}
				id={`Remove-button-${item.guid}`}
				iconName="remove"
				iconColor="#455D82"
				tooltipText={"Remove item"}
				aria-label={"Remove item"}
				onClick={() => handleDelete(index, item)}
				dataCy="delete-item"
				placement="top"
				tabIndex={-1}
				popperProps={customPopperProps}
			/>
		),
		isCheckBoxVisible: (item) => (item.itemType === 7 ? item.deleteEnabled && item.copied : item.copied && !item.itemDeleteDisable),
		keyDownAction: (e) => handleKeyDownForToAgenda(e),
		isAnimationAdded: (element) => !element?.classList.contains("transition-animation-added"),
		isBoardNotesPresent: (item) =>
			item.containsBriefingNotes && (
				<Tooltip title={"includes-briefing-notes"}>
					<span aria-label="includes-briefing-notes" className={classes.briefingNotesSpan}>
						<Icon name="notes" />
					</span>
				</Tooltip>
			),
		lineLimit: 1,
		isToAgendaRecommendation: true,
		reviewItems: true,
	};
	return finalRightMeetingAgendaItems?.length > 0 ? (
		<Container>
			<Box ref={warningRef}>{Object.keys(toAgendaWarning).length > 0 && <Warning warnings={toAgendaWarning} />}</Box>
			<Box sx={{ paddingTop: "20px" }}>
				<ListCard
					isCopyMove={true}
					item={selectedMeeting}
					disableLink={true}
					dateField="date"
					nameField="cleanName"
					subTitleField={(selectedMeeting) =>
						formatDate(null, selectedMeeting.startTime, selectedMeeting.endTime, t("app:at"), t("from"), t("to"), false)
					}
					reserveSubTitleSpace={widthUpMd}
					secondaryContent={
						<div className={classes.secondaryContent}>
							{isNewMeeting && (
								<Box className={classes.newTagContainer}>
									<span className={classes.newTag}>{t("app:commonCopyMove.newTag")}</span>
								</Box>
							)}
							{getSharedOrPublished(selectedMeeting?.boardAgendaStatus, selectedMeeting?.publicAgendaStatus) !== null && (
								<div>
									<MeetingPublishStatus
										meetingId={selectedMeeting.id}
										boardStatus={selectedMeeting.administrator || selectedMeeting.member ? selectedMeeting.boardAgendaStatus : undefined}
										publicStatus={selectedMeeting.publicAgendaStatus}
										publicReleaseDay={selectedMeeting.publicAgendaReleaseDay}
										boardHtmlId={selectedMeeting.boardAgendaHtmlId}
										previousUrl="/meetings"
										telemetryPage="All meetings"
										width="auto"
										isCopyMove={true}
									/>
								</div>
							)}
						</div>
					}
					classes={{
						mainContent: classes.mainContent,
						textDetails: classes.textDetails,
						calendarIconStyle: classes.calendarIcon,
						secondaryContent: classes.statusTag,
					}}
					showRadioButton={false}
				></ListCard>
			</Box>
			<Box sx={{ overflowY: "auto", height: `calc(100vh - ${warningHeight + 268}px )` }}>
				<TableOfContents
					className={"copy-move-list"}
					items={transformData(finalRightMeetingAgendaItems)}
					isPdfSidebar
					renderControl={renderControl}
				/>
			</Box>
		</Container>
	) : (
		<Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", height: "50vh" }}>
			<Box sx={{ fontSize: "24px", fontWeight: "600" }}>{`No items to ${copyOrMove} please select some items`}</Box>
		</Box>
	);
};

export default ToAgendaListPage;
