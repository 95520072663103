import { Plugin } from "@ckeditor/ckeditor5-core";
// eslint-disable-next-line import/no-extraneous-dependencies
import Collection from "@ckeditor/ckeditor5-utils/src/collection";

import ViewModel from "@ckeditor/ckeditor5-ui/src/model";
import { addListToDropdown, createDropdown } from "@ckeditor/ckeditor5-ui/src/dropdown/utils";
import { format } from "date-fns";

const rtsPlaceHolders = [
	{
		label: "Meeting Name",
		value: "{MeetingName}",
	},
	{
		label: "Meeting Date",
		value: "{MeetingDate}",
	},
	{
		label: "Meeting Start Time",
		value: "{MeetingStartTime}",
	},
	{
		label: "First Name",
		value: "{FirstName}",
	},
	{
		label: "Last Name",
		value: "{LastName}",
	},
	{
		label: "Topic",
		value: "{Topic}",
	},
	{
		label: "Comment",
		value: "{Comment}",
	},
	{
		label: "Site Url",
		value: "{SiteUrl}",
	},
	{
		label: "Organization Name",
		value: "{OrganizationName}",
	},
	{
		label: "Cancel Link",
		value: "{CancelLink}",
	},
];

const today = new Date();
const meetingPlaceHolders = [
	{
		label: `Date published (${format(today, "iiii, MMMM dd, yyyy hh:mm:ss a")})`,
		value: "[DatePublished]",
	},
	{
		label: `Date revised (${format(today, "iiii, MMMM dd, yyyy hh:mm:ss a")})`,
		value: "[DateRevised]",
	},
	{
		label: "Location (Council Chambers)",
		value: "[Location]",
	},
	{
		label: `Meeting date (${format(today, "iiii, MMMM dd, yyyy")})`,
		value: "[Date]",
	},
	{
		label: `Meeting day of week (${format(today, "iiii")})`,
		value: "[DayOfWeek]",
	},
	{
		label: `Meeting name (Regular Council - ${format(today, "MMM dd yyyy")})`,
		value: "[MeetingName]",
	},
	{
		label: `Meeting start time (${format(today, "hh:00 a")})`,
		value: "[Time]",
	},
	{
		label: "Meeting template name (Regular Council)",
		value: "[MeetingTypeName]",
	},
	{
		label: "Meeting group name (Board)",
		value: "[MeetingGroupName]",
	},
	{
		label: "Organization Name (City of Riverside)",
		value: "[Organization]",
	},
];

export default class InsertPlaceholder extends Plugin {
	init() {
		const { editor } = this;

		editor.ui.componentFactory.add("insertRtsPlaceHolder", (locale) => {
			const dropdownView = createDropdown(locale);
			dropdownView.buttonView.set({
				icon: '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><g fill="none" fillRule="evenodd"><g fill="black"><g><path d="M12.215 1C18.409 1 23.43 6.021 23.43 12.215S18.409 23.43 12.215 23.43 1 18.409 1 12.215 6.021 1 12.215 1zm0 1.402c-5.42 0-9.813 4.393-9.813 9.813 0 5.42 4.393 9.813 9.813 9.813 5.42 0 9.813-4.393 9.813-9.813 0-5.42-4.393-9.813-9.813-9.813zm0 3.505c.387 0 .7.313.7.7v4.907h4.908c.387 0 .7.314.7.701 0 .387-.313.7-.7.7h-4.907v4.908c0 .387-.314.7-.701.7-.387 0-.7-.313-.7-.7v-4.907H6.606c-.387 0-.7-.314-.7-.701 0-.387.313-.7.7-.7h4.907V6.606c0-.387.314-.7.701-.7z" transform="translate(-615 -221) translate(615 221)"/></g></g></g></svg>',
				tooltip: true,
				label: "Add Placeholder",
			});

			dropdownView.set({
				panelPosition: "se",
			});

			dropdownView.extendTemplate({
				attributes: {
					class: ["ck-placeholders-dropdown"],
				},
			});

			const items = new Collection();
			rtsPlaceHolders.forEach((placeholder) => {
				items.add({
					type: "button",
					model: new ViewModel({
						withText: true,
						label: placeholder.label,
						commandParam: placeholder.value,
					}),
				});
			});

			addListToDropdown(dropdownView, items);

			dropdownView.on("execute", (evt) => {
				console.log("execute");
				editor.model.change((writer) => {
					const textElement = writer.createText(evt.source.commandParam);
					editor.model.insertContent(textElement, editor.model.document.selection);
				});
			});

			return dropdownView;
		});

		editor.ui.componentFactory.add("insertMeetingPlaceHolder", (locale) => {
			const dropdownView = createDropdown(locale);
			dropdownView.buttonView.set({
				icon: '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><g fill="none" fillRule="evenodd"><g fill="black"><g><path d="M12.215 1C18.409 1 23.43 6.021 23.43 12.215S18.409 23.43 12.215 23.43 1 18.409 1 12.215 6.021 1 12.215 1zm0 1.402c-5.42 0-9.813 4.393-9.813 9.813 0 5.42 4.393 9.813 9.813 9.813 5.42 0 9.813-4.393 9.813-9.813 0-5.42-4.393-9.813-9.813-9.813zm0 3.505c.387 0 .7.313.7.7v4.907h4.908c.387 0 .7.314.7.701 0 .387-.313.7-.7.7h-4.907v4.908c0 .387-.314.7-.701.7-.387 0-.7-.313-.7-.7v-4.907H6.606c-.387 0-.7-.314-.7-.701 0-.387.313-.7.7-.7h4.907V6.606c0-.387.314-.7.701-.7z" transform="translate(-615 -221) translate(615 221)"/></g></g></g></svg>',
				tooltip: true,
				label: "Add Placeholder",
			});

			dropdownView.set({
				panelPosition: "auto",
			});

			dropdownView.extendTemplate({
				attributes: {
					class: ["ck-placeholders-dropdown"],
				},
			});

			const items = new Collection();
			meetingPlaceHolders.forEach((placeholder) => {
				items.add({
					type: "button",
					model: new ViewModel({
						withText: true,
						label: placeholder.label,
						commandParam: placeholder.value,
					}),
				});
			});

			addListToDropdown(dropdownView, items);

			dropdownView.on("execute", (evt) => {
				console.log("execute");
				editor.model.change((writer) => {
					const textElement = writer.createText(evt.source.commandParam);
					editor.model.insertContent(textElement, editor.model.document.selection);
				});
			});

			return dropdownView;
		});
	}
}
