import React from "react";
import { useTranslation } from "react-i18next";

import getIconName from "views/Documents/utils/documentIcon";
import getPathType from "views/Documents/utils/pathType";
import SearchResult from "./SearchResult";
import getDocumentTooltip from "../utils/documentTooltip";
import { getPathForEditPage } from "views/Policy/utils/getPathForEditPage";

const DocumentResult = (props) => {
	const { result, child, handleExpand, isPolicy, enableSelect, selection, setSelectedData = () => {}, policyAddToAgendaSearch } = props;
	const { t } = useTranslation();
	const { policy, folder, guid, publishedGuid } = result;

	const rootFolder = getPathType(result.folderType);

	const generatePathLink = (pathItem, index) => {
		return isPolicy || policy
			? `/policy/${rootFolder}/${pathItem.guid}`
			: `/documents/${rootFolder}${index > 0 ? `/${pathItem.guid}` : ""}`;
	};

	let link = "";
	if (isPolicy || policy) {
		if (folder) {
			link = `/policy/${rootFolder}/${guid}`;
		} else {
			link = getPathForEditPage(guid, publishedGuid);
		}
	} else {
		if (result.folder) {
			link = `/documents/${rootFolder}/${result.guid}`;
		} else if (result.preview && result.meetingId) {
			link = `/meeting/preview/${result.meetingId}/${result.agenda ? "agenda" : "minutes"}/${result.isPublic ? "public" : "members"}`;
		} else if (result.agenda && result.meetingId && !result.isPublic) {
			link = `/meeting/document/${result.meetingId}`;
		} else if (result.minutes && result.meetingId && !result.isPublic) {
			link = `meeting/final/${result.meetingId}/minutes/members`;
		} else {
			link = `/document/${result.guid}`;
		}
	}

	return (
		<SearchResult
			result={result}
			icon={{
				name: getIconName(result, isPolicy),
				tooltip: t(getDocumentTooltip(result)),
			}}
			handleExpand={handleExpand}
			link={link}
			generatePathLink={generatePathLink}
			child={child}
			isPolicy={isPolicy}
			enableSelect={enableSelect}
			selection={selection}
			setSelectedData={setSelectedData}
			policyAddToAgendaSearch={policyAddToAgendaSearch}
		/>
	);
};

export default React.memo(DocumentResult);
