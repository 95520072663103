import React from "react";
import { useTranslation } from "react-i18next";

import makeStyles from "@mui/styles/makeStyles";

import { blackColor } from "atlas/assets/jss/shared";
import Tooltip from "atlas/components/Tooltip/Tooltip";
import { WORKFLOW_STATUS_PENDING, WORKFLOW_STATUS_APPROVED, WORKFLOW_STATUS_DENIED } from "utils/workflowStatuses";
import ApprovalProgressApproverAvatar from "./ApprovalProgressApproverAvatar";

const useStyles = makeStyles(() => ({
	user: {
		position: "relative",
		zIndex: (props) => (props.group && props.inactive ? `${props.zIndex}` : undefined),
		marginLeft: (props) => (props.group && props.previousInactive && props.inactive ? "-20px" : undefined),
		width: (props) => (!props.group && props.expanded ? "32px" : undefined),
		textAlign: (props) => (!props.group && props.expanded ? "center" : undefined),
		height: "32px",
	},
	avatarContainer: {
		display: "inline-block",
	},
	label: {
		position: "absolute",
		top: "calc(100% + 4px)",
		left: "0",
		maxWidth: "44px",
		fontSize: "11px",
		lineHeight: "14px",
		color: blackColor[6],
		textAlign: "left",
		overflow: "hidden",
		whiteSpace: "nowrap",
		textOverflow: "ellipsis",
		"& > span": {
			whiteSpace: "nowrap",
		},
	},
}));

const ApprovalProgressApprover = (props) => {
	const { block, user, tooltip, highlightPending, inactiveData = {}, expanded } = props;
	const { t } = useTranslation("app");
	const classes = useStyles({ highlightPending, ...inactiveData, expanded, pending: user.status === WORKFLOW_STATUS_PENDING });

	const getTooltip = (user) =>
		t(
			`workflows.toolTips.approver${
				user.status === WORKFLOW_STATUS_APPROVED ? "Approved" : user.status === WORKFLOW_STATUS_DENIED ? "Denied" : ""
			}`,
			{
				name: user.name,
			},
		);

	return (
		user && (
			<li className={classes.user} data-cy={`workflow-block-${block.id}-user-${user.id}`}>
				<div className={classes.avatarContainer}>
					{tooltip ? (
						<Tooltip title={getTooltip(user)}>
							<span>
								<ApprovalProgressApproverAvatar user={user} highlightPending={highlightPending} />
							</span>
						</Tooltip>
					) : (
						<ApprovalProgressApproverAvatar user={user} highlightPending={highlightPending} />
					)}
				</div>
				{expanded && (
					<div className={classes.label}>
						<span>{user.name}</span>
					</div>
				)}
			</li>
		)
	);
};

export default ApprovalProgressApprover;
