import React, { useState, useEffect, useContext } from "react";
import clsx from "clsx";
import { useDispatch } from "react-redux";
import { useLocation, useMatch } from "react-router-dom";
import { resetPageConfigs, updatePageConfigs, updateBottomNotice } from "redux/app/actions";
import { updatePageHeader } from "redux/pageHeader/actions";
import { withTranslation } from "react-i18next";
import forEach from "lodash/fp/forEach";
import request from "superagent";

import withErrorHandling from "components/ErrorHOC";
import loadSettings from "utils/loadSettings";
import { API_HOST } from "config/env";

import { Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import CircularProgressIndicator from "atlas/components/Progress/CircularProgressIndicator";
import ComponentContainer from "atlas/components/ComponentContainer/ComponentContainer";
import { UserContext } from "contexts/User/UserContext";
import isSignedIn from "utils/isSignedIn";
import { setupPublicSignalR, LIVE_MEETING_HUB, VOTING_HUB } from "utils/communication/SignalrClient";
import typographyStyle from "atlas/assets/jss/components/typographyStyle";
import ViewerTimer from "../LiveMeeting/components/ViewerTimer";
import VotingResultsPublicDialog from "components/Dialogs/VotingResultsPublicDialog";
import { updateLeftNav } from "../../redux/app/actions";

const useStyles = makeStyles({
	...typographyStyle,
	documentFrameContainer: {
		textAlign: "center",
	},
	documentFrame: {
		width: "100%",
		maxWidth: "8.5in",
		height: "calc(100vh - 76px)",
		border: "none",
		backgroundColor: "#ffffff",
	},
	liveItem: {
		height: "calc(100vh - 76px)",
	},
	hidden: {
		display: "none",
	},
});

const PublicDocument = (props) => {
	const { setUser } = useContext(UserContext);
	let { user } = useContext(UserContext);
	const { t } = props;
	const { params: { id: meetingId } = {} } = useMatch({ path: "/public/meeting/document/:id", end: true }) || {};
	const [meetingName, setMeetingName] = useState("");
	const [id, setId] = useState(0);
	const [attachments, setAttachments] = useState([]);
	const [documentSrc, setDocumentSrc] = useState("");
	const [attachmentSrc, setAttachmentSrc] = useState("");
	const [noDocument, setNoDocument] = useState(false);
	const [attachmentSelected, setAttachmentSelected] = useState(false);
	const [liveItem, setLiveItem] = useState({
		id: 0,
		text: "",
	});
	const [votingData, setVotingData] = useState(null);
	const [showVotingResultsDialog, setShowVotingResultsDialog] = useState(false);
	const [votingSettings, setVotingSettings] = useState({});
	const [timer, setTimer] = useState(null);
	const dispatch = useDispatch();
	const classes = useStyles();

	const closeAttachment = () => {
		setAttachmentSelected(false);
	};

	const openAttachment = (url) => {
		let extension = "";
		attachments.forEach((attachment) => {
			if (url === attachment.url) {
				extension = attachment.fileExtension;
			}
		});
		setAttachmentSrc(
			`${url}?printPdf=${extension !== "pdf"}&page=${liveItem.page > 0 ? liveItem.page : "1"}#page=${
				liveItem.page > 0 ? liveItem.page : "1"
			}&view=fit&zoom=page-fit`,
		);
		setAttachmentSelected(true);
		document.getElementById("content-scroll").scrollTop = 0;
		dispatch(
			updatePageConfigs({
				contentMaxWidth: "md",
				back: {
					action: () => {
						if (document.getElementById("document-frame") && document.getElementById("document-frame").getClientRects().length === 0) {
							closeAttachment();
						} else {
							window.location.href = `${API_HOST}/Portal/MeetingInformation.aspx?Org=Cal&Id=${meetingId}`;
						}
					},
				},
				contentPaper: { transparent: true },
			}),
		);
		dispatch(updatePageHeader({ displayPageHeader: false }));
	};

	const documentFrameLoad = (e) => {
		dispatch(
			updatePageConfigs({
				title: meetingName,
				telemetryPage: "Public Meeting document",
				contentMaxWidth: "md",
				back: {
					action: () => {
						if (document.getElementById("document-frame") && document.getElementById("document-frame").getClientRects().length === 0) {
							closeAttachment();
						} else {
							window.location.href = `${API_HOST}/Portal/MeetingInformation.aspx?Org=Cal&Id=${meetingId}`;
						}
					},
				},
			}),
		);

		forEach((elem) => {
			elem.onclick = (e) => {
				openAttachment(elem.href);
				e.preventDefault();
			};
		}, e.target.contentDocument.querySelectorAll("a[target='_blank'][href]"));

		if (!attachmentSelected) {
			const frameContentHeight = document.getElementById("document-frame").contentWindow.document.body.scrollHeight;
			document.getElementById("document-frame").style.height = frameContentHeight > 0 ? `${frameContentHeight + 30}px` : "";
		}
	};

	const selectItem = (item) => {
		const iframe = document.getElementById("document-frame");
		if (attachmentSelected) {
			closeAttachment();
		}
		setTimeout(() => {
			const itemElem = iframe && iframe.contentDocument && iframe.contentDocument.querySelector(`a[name$="${item.id}"]`);
			const itemElemRect = itemElem && itemElem.getBoundingClientRect();
			if (itemElemRect && Math.abs(itemElemRect.top) > 10) {
				document.getElementById("content-scroll").scrollTop = iframe.contentWindow.scrollY + itemElemRect.top;
			}
		}, 200);
	};

	const findItem = (item) => {
		if (item != null && item.id > 0) {
			let found = false;
			attachments.forEach((attachment) => {
				if (attachment.id === item.id) {
					found = true;
					selectItem(item);
				} else if (attachment.id !== item.id) {
					if (item.id === attachment.documentId) {
						found = true;
						openAttachment(attachment.url);
					}
				}
			});
			if (!found) {
				attachments.forEach((attachment) => {
					if (attachment.guid === item.headingGuid) {
						selectItem(attachment);
					}
				});
			}
		}
	};

	const updateTimer = (timerMeetingId, timerTotalSeconds, timerRemainingSeconds, timerActive, timerCanceled) => {
		if (timerCanceled || parseInt(meetingId, 10) !== timerMeetingId) {
			setTimer(null);

			return;
		}

		setTimer({
			totalSeconds: timerTotalSeconds,
			remainingSeconds: timerRemainingSeconds,
			active: timerActive,
		});
	};

	const showResults = (hubVotingData) => {
		hubVotingData.showResults = true;
		setVotingData(hubVotingData);
		setShowVotingResultsDialog(true);
	};

	const hideResults = () => {
		setShowVotingResultsDialog(false);
	};

	const votingDialogClose = () => {
		setShowVotingResultsDialog(false);
	};

	const loadVotingSettings = () => {
		request
			.get(`${API_HOST}/api/settings`)
			.query({ type: "voting" })
			.then((res) => {
				if (res.body) {
					setVotingSettings(res.body);
				}
			})
			.catch((err) => {
				if (typeof showSignIn === "function") {
					showSignIn(err, () => {
						loadVotingSettings();
					});
				}
			});
	};

	const getMeetingDocument = () => {
		request
			.get(`${API_HOST}/api/meeting/${meetingId}/meetingdocument`)
			.withCredentials()
			.then((res) => {
				const {
					id: newId,
					meetingName: newMeetingName,
					isMeetingInRange: newIsMeetingInRange,
					attachments: newAttachments,
					activeItem: newActiveItem,
				} = res.body;
				setId(newId);
				setDocumentSrc(`/document/${newId}?preview=true`);
				setMeetingName(newMeetingName);
				setAttachments(newAttachments);
				setLiveItem({
					id: newActiveItem.itemId,
					guid: newActiveItem.guid,
					page: newActiveItem.page,
					text: newActiveItem.itemName,
					headingGuid: newActiveItem.headingGuid,
				});

				if (id === 0 || !newIsMeetingInRange) {
					setNoDocument(true);
				}

				if (newIsMeetingInRange && newId > 0) {
					setupPublicSignalR(
						{ dispatch, t },
						{
							[LIVE_MEETING_HUB]: {
								updateActiveItem: (activeItem) => {
									setLiveItem({
										id: activeItem.itemId,
										guid: activeItem.guid,
										page: activeItem.page,
										text: activeItem.itemName,
										headingGuid: activeItem.headingGuid,
									});
								},
								clearActiveItem: () => {
									setLiveItem({ id: 0, guid: "", page: 0, text: "", headingGuid: "" });
								},
								updateTimer,
							},
							[VOTING_HUB]: {
								showResults,
								hideResults,
							},
						},
						(signalR) => {
							signalR.liveMeetingHub.addUserToMcpViewers(meetingId, true);
							signalR.votingHub.addUserToPublicViewers(meetingId);
							signalR.updateStatusIcon();
						},
					);
				}
			})
			.catch((err) => {
				if (typeof showSignIn === "function") {
					showSignIn(err, () => {
						getMeetingDocument();
					});
				}
			});
	};

	useEffect(() => {
		isSignedIn((res) => {
			if (!user || user.username !== res.body.username) {
				user = setUser({
					username: res.body.username,
					systemAdministrator: res.body.systemAdministrator,
					boardAdmin: res.body.boardAdmin,
					boardMember: res.body.boardMember,
					boardStaff: res.body.boardStaff,
					goalAdmin: res.body.goalAdmin,
					documentLibrary: res.body.documentLibrary,
					publicUser: false,
				});
			}
		});

		dispatch(resetPageConfigs({}));
		dispatch(
			updatePageConfigs({
				title: "",
				telemetryPage: "Public Meeting document",
				contentMaxWidth: "md",
				back: {
					action: () => {
						if (document.getElementById("document-frame") && document.getElementById("document-frame").getClientRects().length === 0) {
							closeAttachment();
						} else {
							window.location.href = `${API_HOST}/Portal/MeetingInformation.aspx?Org=Cal&Id=${meetingId}`;
						}
					},
				},
				contentPaper: { transparent: true },
			}),
		);
		dispatch(updatePageHeader({ displayPageHeader: false }));

		getMeetingDocument();

		loadVotingSettings();
	}, [meetingId]);

	useEffect(() => {
		dispatch(
			updateLeftNav({
				showLeftNav: false,
				meetingName: meetingName,
			}),
		);
	}, [meetingName]);

	useEffect(() => {
		if (liveItem && liveItem.id > 0) {
			dispatch(
				updateBottomNotice({
					icon: "arrow-right",
					label: liveItem.text,
					preText: t("liveMeeting.currentTopic"),
					action: () => {
						findItem(liveItem);
					},
				}),
			);
		} else {
			dispatch(updateBottomNotice({}));
		}

		dispatch(
			updatePageConfigs({
				contentMaxWidth: "md",
				back: {
					action: () => {
						if (document.getElementById("document-frame") && document.getElementById("document-frame").getClientRects().length === 0) {
							closeAttachment();
						} else {
							window.location.href = `${API_HOST}/Portal/MeetingInformation.aspx?Org=Cal&Id=${meetingId}`;
						}
					},
				},
				contentPaper: { transparent: true },
			}),
		);
		dispatch(updatePageHeader({ displayPageHeader: false }));

		return () => dispatch(updateBottomNotice({}));
	}, [liveItem, attachmentSelected]);

	let displayDocument;
	if (noDocument) {
		displayDocument = <Typography variant="h2">{t("noDocument")}</Typography>;
	} else {
		displayDocument = <CircularProgressIndicator size={20} />;
	}

	return (
		<ComponentContainer fullHeight onlyScrollbar>
			<div className={classes.documentFrameContainer}>
				{id > 0 ? (
					<>
						<iframe
							id="document-frame"
							title={meetingName}
							src={documentSrc}
							onLoad={documentFrameLoad}
							className={clsx(classes.documentFrame, {
								[classes.liveItem]: liveItem != null && liveItem.id > 0,
								[classes.hidden]: attachmentSelected,
							})}
						/>
						<iframe
							id="attachment-frame"
							title={meetingName}
							src={attachmentSrc}
							onLoad={documentFrameLoad}
							className={clsx(classes.documentFrame, {
								[classes.liveItem]: liveItem != null && liveItem.id > 0,
								[classes.hidden]: !attachmentSelected,
							})}
						/>
					</>
				) : (
					displayDocument
				)}
			</div>
			{timer && <ViewerTimer timer={timer} />}
			{votingData && showVotingResultsDialog && (
				<VotingResultsPublicDialog
					votingData={votingData}
					votingSettings={votingSettings}
					votingDialogClose={votingDialogClose}
					show={showVotingResultsDialog}
				/>
			)}
		</ComponentContainer>
	);
};

export default withErrorHandling(withTranslation("meetings")(PublicDocument));
