import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import makeStyles from "@mui/styles/makeStyles";
import { Box } from "@mui/material";

import Icon from "atlas/components/Icon/Icon";
import GenericDialog from "atlas/components/Dialogs/GenericDialog";
import { publishPolicyAttachments, unpublishPolicy } from "redux/policyGovernance/actions";
import telemetryAddEvent from "utils/telemetryAddEvent";
import { displayErrorNotification, displaySuccessNotification } from "views/Policy/utils/getNotification";

const useStyles = makeStyles(() => ({
	dialog: {
		"& .MuiDialog-paper": {
			width: "500px",
			maxWidth: "100%",
		},
	},
	list: {
		listStyleType: "none",
		padding: "0",
		margin: "0",
		marginTop: "16px",
	},
}));

const LiveMeetingPolicyPublishDialog = (props) => {
	const { show = true, policyData = {}, onClose } = props;
	const { t } = useTranslation("documents");
	const dispatch = useDispatch();
	const [publishing, setPublishing] = useState(false);
	const classes = useStyles();

	const handlePublish = () => {
		setPublishing(true);

		telemetryAddEvent(`Policy - Minutes Builder Adopt and Publish`);
		dispatch(publishPolicyAttachments({ ...policyData.data, isPublishAndRescind: false }))
			.then(() => {
				displaySuccessNotification(t("agendaMenu:successMsg.publish"), dispatch, () => handleUnpublish());
				onClose();
			})
			.catch((err) => {
				if (err.status === 500) {
					displayErrorNotification(t("agendaMenu:errorMsg.publish"), dispatch);
				} else {
					displayErrorNotification(t("agendaMenu:errorMsg.commonMsg"), dispatch);
				}

				handleError(err);
			});
	};

	const handleUnpublish = (index = 0) => {
		if (index >= 0 && index < policyData.policies.length) {
			dispatch(unpublishPolicy(policyData.policies[index].sourcePolicyGuid))
				.then(() => {
					handleUnpublish(index + 1);
				})
				.catch((err) => {
					if (err.status === 500) {
						displayErrorNotification(t("policy.errorMsg.unpublishPolicy"), dispatch);
					} else {
						displayErrorNotification(t("policy.errorMsg.commonMsg"), dispatch);
					}
				});
		} else {
			displaySuccessNotification(t("policy.successMsg.unpublishPolicy"), dispatch);
		}
	};

	const handleCancel = () => {
		onClose();
	};

	const handleError = (err) => {
		console.log("Error", err);
		onClose();
	};

	const dialog = {
		title: t("liveMeetingPublishPolicyDialog.title"),
		line1: t("liveMeetingPublishPolicyDialog.line1", { count: policyData.policies.length }),
		primaryTitle: t("liveMeetingPublishPolicyDialog.buttons.publish"),
		primaryAction: handlePublish,
		secondaryTitle: t("app:buttons.cancel"),
		secondaryAction: handleCancel,
	};

	return (
		<GenericDialog
			className={classes.dialog}
			show={show}
			title={dialog.title}
			primaryAction={dialog.primaryAction}
			primaryTitle={dialog.primaryTitle}
			primaryDisabled={publishing}
			secondaryAction={dialog.secondaryAction}
			secondaryTitle={dialog.secondaryTitle}
			secondaryDisabled={publishing}
			clickAwayDisabled={publishing}
			closeIcon={<Icon name="close" />}
			data-cy="policy-publish-dialog"
		>
			<Box fontWeight="fontWeightBold">{dialog.line1}</Box>
			<ul className={classes.list}>
				{policyData.policies.map((policy) => (
					<li key={policy.guid}>{policy.title}</li>
				))}
			</ul>
		</GenericDialog>
	);
};

export default LiveMeetingPolicyPublishDialog;
