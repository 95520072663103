const AgendaItemTypesEnum = () => ({
	ITEM: {
		value: 3,
	},
	RECOMMENDATION: {
		value: 7,
	},
	HEADING: {
		value: 10,
	},
});
export default AgendaItemTypesEnum;
