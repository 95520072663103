import React, { useState, useEffect, useRef, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useMatch, useNavigate } from "react-router-dom";
import clsx from "clsx";
import makeStyles from "@mui/styles/makeStyles";
import inputStyle from "atlas/assets/jss/components/inputStyle";
import request from "superagent";
import { API_HOST } from "config/env";
import { useDispatch, useSelector } from "react-redux";

import AddPolicyToAgendaDialog from "components/Dialogs/AddPolicyToAgendaDialog";
import ErrorGoalDialog from "components/Dialogs/ErrorGoalDialog";
import { SettingsContext } from "contexts/Settings/SettingsContext";
import GoalHeadingData from "./GoalHeadingData";
import GoalContentData from "./GoalContentData";
import GoalMeetingData from "./GoalMeetingData";
import { resetPageConfigs, updatePageConfigs } from "redux/app/actions";
import { updatePageHeader } from "redux/pageHeader/actions";
import CircularProgressIndicator from "atlas/components/Progress/CircularProgressIndicator";
import ComponentContainer from "atlas/components/ComponentContainer/ComponentContainer";
import notifierMessage from "utils/notifierMessage";
import { setSnackbarOptions } from "redux/snackBar/actions";
import { getGoal, unarchiveGoal } from "redux/goals/actions";
import Typography from "@mui/material/Typography";

const useEditorStyles = makeStyles(() => ({
	container: {
		maxWidth: "",
		margin: "0",
		padding: "0",
	},
	editorContainer: {
		border: "1px solid #c4c4c4",
		padding: "8px",
	},
	editorField: {
		marginTop: "16px",
	},
	editorFieldLabel: {},
	editorFieldContainer: {
		display: "flex",
		flexDirection: "row",
		flexWrap: "nowrap",
		alignItems: "center",
		margin: "8px 0",
		padding: "8px",
	},
	editorFieldContent: {
		position: "relative",
		border: "solid 1px #c4c4c4 !important",
		flexGrow: "1",
		"& td": {
			wordBreak: "break-word",
		},
	},
	editorFieldHide: {
		display: "none",
	},
	stickyToolbar: {
		position: "-webkit-sticky",
		position: "sticky",
		top: 0,
		zIndex: 150,
	},
	progress: {
		width: "125px !important",
	},
	outOfSyncTag: {
		display: "flex",
		justifyContent: "flex-end",
		backgroundColor: "#2f3b4d",
	},
}));

const GoalViewContainer = (props) => {
	const { showErrorGoalDialog, handleCloseErrorGoalDialog, isForPublish, failedUploads, preload, showSignIn, goalAdmin } = props;
	const { params: { id } = {} } = useMatch({ path: "/goals/view/:id", end: true }) || {};
	const { t } = useTranslation("goals");
	const navigate = useNavigate();
	const editorClasses = useEditorStyles({ preload });
	const [goal, setGoal] = useState(null);
	const [loading, seLoading] = useState(true);
	const dispatch = useDispatch();
	const goalReducer = useSelector((state) => state.goalReducer);
	const { isSaving } = goalReducer;

	useEffect(() => {
		if (id) {
			loadGoal(id);
		}
		dispatch(resetPageConfigs({}));
	}, [id, goal?.archived, isSaving]);

	useEffect(() => {
		if (goal) {
			dispatch(
				updatePageConfigs({
					title: getGoalStatusText(goal),
					back: { url: goalAdmin ? "/goals" : "/", action: backToGoals },
					telemetryPage: "Edit goal",
				}),
			);
		}
		if (goal && goal.canUpdate) {
			if (goal.archived) {
				dispatch(
					updatePageHeader({
						primaryAction: handleArchived,
						primaryActionText: t("unArchiveGoal"),
						primaryActionTooltip: t("unArchiveGoal"),
					}),
				);
			} else {
				dispatch(
					updatePageHeader({
						primaryAction: handleClick,
						primaryActionText: t("editGoal"),
						primaryActionTooltip: t("editGoal"),
					}),
				);
			}
		}
	}, [goal]);

	const getGoalStatusText = (goal) => {
		if (goal && goal.archived) return t("archivedGoal");
		else if (!goal.archived && goal.closed) return t("internalGoal");
		else if (!goal.archived && !goal.closed) return t("publishedGoal");
		else return t("draftGoal");
	};

	const loadGoal = (id) => {
		dispatch(getGoal(id))
			.then((res) => {
				if (res) {
					setGoal(res);
					seLoading(false);
				}
			})
			.catch((err) => {
				showSignIn(err);
				seLoading(false);
			});
	};
	const handleClick = () => {
		let goalId = id;
		if (goal.draftGoalId) {
			goalId = goal.draftGoalId;
		}
		navigate(`/goals/edit/${goalId}`);
	};
	const backToGoals = () => {
		navigate(`/goals`);
	};
	const handleArchived = () => {
		dispatch(unarchiveGoal(id))
			.then((res) => {
				loadGoal(id);
				let option = notifierMessage("Unarchive successfull", "success");
				dispatch(setSnackbarOptions(option));
			})
			.catch((err) => {
				console.log("Error", err);
			});
	};

	return (
		<>
			{showErrorGoalDialog && (
				<ErrorGoalDialog
					show={showErrorGoalDialog}
					handleClose={handleCloseErrorGoalDialog}
					isForPublish={isForPublish}
					failedUploads={failedUploads}
				/>
			)}
			{goal && goal.status === 3 && (
				<div className={editorClasses.outOfSyncTag}>
					<span className={clsx("goal-status", "warning")}>{t("outOfSync")}</span>
				</div>
			)}

			<div>
				{!loading ? (
					<ComponentContainer padding="24px 8px" maxWidth="974px">
						<div className={editorClasses.container}>
							{goal ? (
								<>
									<GoalHeadingData goal={goal} />
									<GoalContentData goal={goal} />
									{goal.linkedMeetingList.length > 0 && <GoalMeetingData goal={goal} />}
								</>
							) : (
								<Typography variant="h4">{t("goalViewDialog.removedGoal")}</Typography>
							)}
						</div>
					</ComponentContainer>
				) : (
					<CircularProgressIndicator />
				)}
			</div>
		</>
	);
};

export default GoalViewContainer;
