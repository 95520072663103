import React from "react";
import SvgIcon from "@mui/material/SvgIcon";

import themeConfig from "../../atlas/assets/jss/theme";
import getOr from "lodash/fp/getOr";

const icon = (props) => {
	const { color } = props;
	const fillColor = color ? getOr(color, ["palette", color, "main"], themeConfig) : "currentColor";

	return (
		<SvgIcon width="18" height="24" viewBox="0 0 18 24" {...props} data-cy="paperclip">
			<path
				fill-rule="evenodd"
				d="M0.375 0.75V23.25C0.375 23.6642 0.710786 24 1.125 24H16.875C17.2892 24 17.625 23.6642 17.625 23.25V6.75C17.625 6.55109 17.546 6.36032 17.4053 6.21967L11.4053 0.21967C11.2647 0.0790176 11.0739 0 10.875 0H10.825H1.125C0.710786 0 0.375 0.335786 0.375 0.75ZM1.875 1.5H10.125V6.3C10.125 6.6866 10.4384 7 10.825 7H16.0643L16.125 7.06066V22.5H1.875V1.5ZM11.525 5.6H14.6643L11.525 2.46066V5.6ZM4.91699 9.37504V10.5417H5.24733L4.0641 13.2743C3.86612 13.5578 3.75 13.9028 3.75 14.2749C3.75 15.2414 4.5335 16.0249 5.5 16.0249C6.4665 16.0249 7.25 15.2414 7.25 14.2749C7.25 13.9028 7.13388 13.5578 6.9359 13.2743L5.75267 10.5417H8.41663V18.7083H4.91699V19.875H13.0837V18.7083H9.58329V10.5417H12.2475L11.064 13.2747C10.8662 13.5582 10.7502 13.903 10.7502 14.2749C10.7502 15.2414 11.5337 16.0249 12.5002 16.0249C13.4667 16.0249 14.2502 15.2414 14.2502 14.2749C14.2502 13.9026 14.134 13.5575 13.9358 13.2738L12.7528 10.5417H13.0837V9.37504H4.91699Z"
			/>
		</SvgIcon>
	);
};

export default icon;
