import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { focusColor } from "atlas/assets/jss/shared";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(() => ({
	noResultContainer: {
		display: "flex",
		flexDirection: "column",
		gap: "15px",
		margin: "auto",
		alignItems: "center",
		paddingTop: "50px",
	},
	noResultHeading: {
		fontWeight: "600",
		fontSize: "28px",
	},
	tabFocusStyling: {
		"&:focus-visible": {
			outline: `solid 2px ${focusColor}`,
			outlineOffset: "2px",
			borderRadius: "4px",
			padding: "0px 5px 0px 5px",
		},
	},
}));

const NoMeetingsPage = (props) => {
	const { isSearchedByUser, onClearSearchClick, createNewMeetingClick } = props;
	const classes = useStyles();
	const { t } = useTranslation("app");

	return (
		<Box className={classes.noResultContainer}>
			<Box className={classes.noResultHeading}>
				{isSearchedByUser ? t("commonCopyMove.nothingFound") : t("commonCopyMove.noUpcomingMeetings")}
			</Box>
			<Box>{isSearchedByUser ? t("commonCopyMove.noMeetingTitles") : t("commonCopyMove.noMeetingsToSelect")}</Box>
			<Box sx={{ display: "flex", flexDirection: "row", gap: "15px" }}>
				{isSearchedByUser && (
					<Button variant="outlined" color="primary" onClick={() => onClearSearchClick()} className={classes.tabFocusStyling}>
						{t("commonCopyMove.clearSearch")}
					</Button>
				)}
				<Button variant="contained" color="primary" onClick={() => createNewMeetingClick()} className={classes.tabFocusStyling}>
					{t("commonCopyMove.createNewMeeting")}
				</Button>
			</Box>
		</Box>
	);
};

export default NoMeetingsPage;
