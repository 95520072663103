import { useState } from "react";

function useAttachmentsHighlight(initialValue) {
	const [clickedOnAttachment, setClickedOnAttachment] = useState(initialValue);

	const clickedOnAttachmentInSidePanel = () => {
		setClickedOnAttachment(true);
	};

	return [clickedOnAttachment, setClickedOnAttachment, clickedOnAttachmentInSidePanel];
}

export default useAttachmentsHighlight;
