/* eslint-disable no-param-reassign */
import { SAVE_IN_PROGRESS } from "./types";

export const goalReducer = (
	state = {
		isSaving: false,
	},
	action,
) => {
	switch (action.type) {
		case SAVE_IN_PROGRESS: {
			state = {
				...state,
				isSaving: action.payload,
			};
			break;
		}

		default:
			break;
	}

	return state;
};

export default goalReducer;
