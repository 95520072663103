import React, { useState, useContext, forwardRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import makeStyles from "@mui/styles/makeStyles";
import { useTranslation } from "react-i18next";

import { whiteColor } from "atlas/assets/jss/shared";
import RightPanelContainer from "components/Panels/RightPanelContainer";
import { SettingsContext } from "contexts/Settings/SettingsContext";
import WopiFrame from "../Wopi/WopiFrame";
import GenericEditor from "components/Editor/GenericEditor";
import { FOLDER_TYPE_AGENDA_ITEM_COVER_TEMPLATE } from "utils/enums/DocumentFolderTypes";
import telemetryAddEvent from "utils/telemetryAddEvent";
import PdfViewerNew from "../../components/PdfViewerNew/PdfViewerNew";
import PolicyDetails from "./components/PolicyDetails";
import getPolicyType from "views/Policy/utils/getPolicyType";
import { DRAFT } from "utils/enums/PolicyTypes";
import { useWidthDown } from "atlas/utils/useWidth";
import { reloadViewPageOfPolicyDocument } from "redux/policyGovernance/actions";
import CircularProgressIndicator from "atlas/components/Progress/CircularProgressIndicator";

const DETAILS_COLLAPSED_WIDTH = 48;
const DETAILS_EXPANDED_WIDTH = 290;

const editors = [];

const useStyles = makeStyles(() => ({
	document: {
		height: "calc(100vh - 72px)",
	},
	documentDetailsCollapsed: {
		width: `calc(100% - ${DETAILS_COLLAPSED_WIDTH}px) !important`,
	},
	documentDetailsExpanded: {
		width: `calc(100% - ${DETAILS_EXPANDED_WIDTH}px) !important`,
	},
	unavailable: {
		padding: "24px",
		textAlign: "center",
	},
	frame: {
		display: "block",
		border: "none",
		height: "100%",
		width: "100%",
		backgroundColor: whiteColor,
	},
	pdfFrame: {
		height: "100%",
		width: "100%",
		"& iframe": {
			border: "none",
		},
	},
	editorFrame: {
		"& .ck-content": {
			height: "calc(100vh - 150px)",
			overflow: "auto",
		},
	},
	hidden: {
		visibility: "hidden", // Workaround for PDFTron in firefox
		height: "0 !important",
		width: "0 !important",
	},
	dialog: {
		"& .MuiDialog-paper": {
			width: "440px",
		},
	},
	progressBar: {
		width: "240px",
		margin: "0 auto",
		marginTop: "24px",
	},
	agendaItemDocumentFrame: {
		height: "calc(100vh - 214px)",
	},
}));

const Policy = forwardRef((props, ref) => {
	const {
		id,
		policy,
		timeline,
		history,
		accessLogs,
		handleRename,
		handleHtmlEdit,
		handleDownload,
		handleReplace,
		handleCopyMove,
		handleLoadTimeline,
		loadMoreHistory,
		toggleFieldChanges,
		handleView,
		handleRestore,
		loadMoreAccessLogs,
		handleExport,
		editing,
		agendaItem,
		telemetryPage,
		handlePublishPolicy,
		handleunpublishPolicy,
		detailsLoading,
		openPanel,
		handleSelectedPolicies,
		selectedPolicies,
		backButtonClicked,
	} = props;
	const [detailsExpanded, setDetailsExpanded] = useState(false);
	const { lite, wopiSettings, testSite } = useContext(SettingsContext);
	const [loading, setLoading] = useState(false);
	const policyGovernanceReducer = useSelector((state) => state.policyGovernanceReducer);
	const { reloadPageAfterWOPIEdit } = policyGovernanceReducer;
	const classes = useStyles();
	const dispatch = useDispatch();
	const { t } = useTranslation("documents");
	const {
		dateDeleted,
		dateModified,
		canUpdate,
		guid,
		title,
		extension,
		parentFolderType,
		wopiDocumentId,
		id: policyGuid,
		html,
		policyStatus,
	} = policy;
	const [openMenu, setOpenMenu] = useState(false);
	const [openPolicyInfo, setOpenPolicyInfo] = useState(false);
	const widthDownMd = useWidthDown("md");

	const openSidePanel = () => {
		if (canUpdate && (getPolicyType(policyStatus) === DRAFT || openPanel)) {
			setDetailsExpanded(true);
			setOpenPolicyInfo(true);
		}
	};
	useEffect(() => {
		if (reloadPageAfterWOPIEdit) {
			dispatch(reloadViewPageOfPolicyDocument(false));
			window.location.reload();
		}
	}, []);
	useEffect(() => {
		if (!widthDownMd) {
			openSidePanel();
		}
	}, [openPanel, widthDownMd]);

	const closePolicyInfo = () => {
		setOpenPolicyInfo(false);
	};
	const setEditorsData = (name, value) => {
		editors[name] = value;
	};

	const handleExpandCollapse = () => {
		setDetailsExpanded((prev) => !prev);
		setOpenPolicyInfo(false);
	};

	const handleMenuClose = (status) => {
		setOpenMenu(!status);
	};

	const getFileContainer = () => {
		if (dateDeleted) {
			// This document has been deleted
			return <div className={classes.unavailable}>{t("detail.labels.documentDeleted")}</div>;
		}

		let fileContainer = null;

		if (!detailsLoading) {
			fileContainer = (
				<iframe
					key={dateModified}
					className={clsx(classes.frame, {
						[classes.documentDetailsCollapsed]: !detailsExpanded && canUpdate,
						[classes.documentDetailsExpanded]: detailsExpanded && canUpdate,
					})}
					src={`/document/${guid}?modified=${dateModified}`}
					title={title}
				/>
			);
		} else {
			return <CircularProgressIndicator data-cy="loading" />;
		}

		//Wopitest and Wopitestx are used for testing purposes only and will be removed after testing
		switch (extension) {
			case ".doc":
			case ".wopitest":
			case ".wopitestx":
			case ".docx":
			case ".ppt":
			case ".pptx":
				if (canUpdate && editing && !lite.enabled) {
					telemetryAddEvent(
						parentFolderType === FOLDER_TYPE_AGENDA_ITEM_COVER_TEMPLATE
							? `${telemetryPage} - Agenda Item Cover Template Edit`
							: `${telemetryPage} - Document Library Edit Office`,
					);

					fileContainer = (
						<div
							className={clsx(classes.frame, {
								[classes.documentDetailsCollapsed]: !detailsExpanded && canUpdate,
								[classes.documentDetailsExpanded]: detailsExpanded && canUpdate,
							})}
						>
							<WopiFrame
								id={wopiDocumentId}
								wopiSettings={wopiSettings}
								extension={extension}
								testSite={testSite}
								isPolicyDate={true}
								handleClose={handleMenuClose}
							/>
						</div>
					);
				}
				break;

			case ".pdf":
				fileContainer = (
					<div
						className={clsx(classes.pdfFrame, {
							[classes.documentDetailsCollapsed]: !detailsExpanded && canUpdate,
							[classes.documentDetailsExpanded]: detailsExpanded && canUpdate,
						})}
					>
						<PdfViewerNew
							id={policyGuid}
							settings={{
								lite,
								url: `/document/${guid}?modified=${dateModified}`,
								downloadOpensNewTab: true,
								showPrintButton: false,
								closeLeftPanel: true,
								disableAnnotations: true,
							}}
							selectedDocumentId={policyGuid}
						/>
					</div>
				);
				break;

			case ".htm":
			case ".html":
				if (canUpdate && editing && testSite) {
					fileContainer = (
						<div
							className={clsx(classes.frame, classes.editorFrame, {
								[classes.documentDetailsCollapsed]: !detailsExpanded && canUpdate,
								[classes.documentDetailsExpanded]: detailsExpanded && canUpdate,
							})}
						>
							<GenericEditor
								guid={guid}
								toolbar="scratchpad"
								name="html"
								content={html || ""}
								title={title}
								setEditorsData={setEditorsData}
								onChange={handleHtmlEdit}
								mt={0}
								loadAsync
								preload={{ staticToolbar: true }}
							/>
						</div>
					);
				}
				break;
		}

		return fileContainer;
	};

	return (
		policy && (
			<>
				<div id={id} className={clsx(classes.document, { [classes.agendaItemDocumentFrame]: agendaItem })}>
					{getFileContainer()}
				</div>
				<RightPanelContainer
					id="right-panel-container"
					open
					drawerWidth={detailsExpanded ? DETAILS_EXPANDED_WIDTH : DETAILS_COLLAPSED_WIDTH}
					drawerHeight={(contentSizes) => (contentSizes ? `calc(100% - ${contentSizes.top}px)` : undefined)}
					fullHeight
				>
					<PolicyDetails
						openMenu={openMenu}
						handleMenuClose={handleMenuClose}
						policyDetail={policy}
						timeline={timeline}
						history={history}
						accessLogs={accessLogs}
						expanded={detailsExpanded}
						handleExpandCollapse={handleExpandCollapse}
						handleRename={handleRename}
						handleDownload={handleDownload}
						handleReplace={handleReplace}
						handleCopyMove={handleCopyMove}
						loadMoreHistory={loadMoreHistory}
						toggleFieldChanges={toggleFieldChanges}
						handleView={handleView}
						handleRestore={handleRestore}
						handleLoadTimeline={handleLoadTimeline}
						loadMoreAccessLogs={loadMoreAccessLogs}
						handleExport={handleExport}
						handlePublishPolicy={handlePublishPolicy}
						handleunpublishPolicy={handleunpublishPolicy}
						openPolicyInfo={openPolicyInfo}
						closePolicyInfo={closePolicyInfo}
						handleSelectedPolicies={handleSelectedPolicies}
						selectedPolicies={selectedPolicies}
						backButtonClicked={backButtonClicked}
					></PolicyDetails>
				</RightPanelContainer>
			</>
		)
	);
});

export default Policy;
