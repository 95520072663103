import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import clsx from "clsx";

import makeStyles from "@mui/styles/makeStyles";

import processHtml from "utils/processHtml";
import Icon from "atlas/components/Icon/Icon";

import { blackColor } from "atlas/assets/jss/shared";
import ButtonWithTooltip from "atlas/components/Buttons/ButtonWithTooltip";
import LiveMeetingRadioButton from "./LiveMeetingRadioButton";
import LiveMeetingAttachments from "./LiveMeetingAttachments";
import TimeStampItem from "./TimeStampItem";

import agendaStyle from "../../../assets/jss/components/agendaStyle";

const useStyle = makeStyles(agendaStyle);

const LiveMeetingMinutesItem = (props) => {
	const {
		agendaItem,
		minutesItem,
		isClosedMeeting,
		isMemberOnlyHeading,
		isConsentHeading,
		isPublicCommentHeading,
		hasMotions,
		addBottomBorder,
		selected,
		hasNotes,
		handleSelect,
		handleClick,
		presentation,
		presenting,
		syncEnabled,
		videoExists,
		youtubePlayerRef,
		updateSync,
		saveSync,
		saveSyncEnabled,
		broadcasts,
		moveConsentItemClick,
		testSite,
		handleTimeStampBlur,
		clearTimestamp,
		setTimestamp,
		videoControlsDisabled,
	} = props;
	const { t } = useTranslation();
	const { fields } = minutesItem;
	const classes = useStyle({ addTopBorder: true });

	const [showMoveButton, setShowMoveButton] = useState(false);

	const isSelected = selected === minutesItem.guid;
	const addBottomBorderToThis = addBottomBorder && agendaItem.attachments.length === 0;

	return (
		!minutesItem.deleted && (
			<>
				<li
					id={`minutes-${minutesItem.guid}`}
					className={clsx(classes.agendaListItem, {
						[classes.radioListItem]: !presentation,
						[classes.borderDefault]: !isMemberOnlyHeading && !isConsentHeading && !isPublicCommentHeading && !addBottomBorderToThis,
						[classes.borderDefaultBottomBorder]:
							!isMemberOnlyHeading && !isConsentHeading && !isPublicCommentHeading && addBottomBorderToThis,
						[classes.borderMemberOnly]: isMemberOnlyHeading,
						[classes.borderConsent]: isConsentHeading,
						[classes.borderPublicComment]: isPublicCommentHeading,
						[classes.selected]: isSelected && !presentation,
						[classes.presentationSelected]: isSelected && presentation,
					})}
					data-cy={`minutes-${minutesItem.guid}`}
					onMouseEnter={() => {
						setShowMoveButton(true);
					}}
					onMouseLeave={() => {
						setShowMoveButton(false);
					}}
				>
					{!presentation && (
						<LiveMeetingRadioButton
							value={minutesItem.guid}
							label={fields.Name.Value}
							selected={isSelected}
							hasNotes={hasNotes}
							handleSelect={handleSelect}
							disabled={syncEnabled || videoControlsDisabled}
						/>
					)}
					<div
						className={clsx(classes.item, classes.itemItem, classes.liveMeetingItem, classes.rightBorder, {
							[classes.bottomBorder]: addBottomBorderToThis,
							[classes.moveButtonMargin]: testSite && isConsentHeading && !presentation && !isSelected && !showMoveButton,
						})}
					>
						<div
							onClick={
								(!syncEnabled || (syncEnabled && saveSyncEnabled)) && handleClick ? (event) => handleClick(event, minutesItem) : null
							}
							className={clsx(classes.textContainer, {
								[classes.listItemClickable]: (!syncEnabled || (syncEnabled && saveSyncEnabled)) && handleClick,
							})}
						>
							{fields.Number.Value.length > 0 && <div className={classes.bullet}>{fields.Number.Value}</div>}
							<div className={clsx(classes.itemText, { [classes.textTruncate]: !presentation && videoExists })}>
								{processHtml(fields.Name.Value, {
									disableLinks: !presentation,
									classes: { link: classes.disabledLink },
									excludeMemberOnlyAttachments: presentation,
								})}
								{presentation && processHtml(fields.Text.Value, { excludeMemberOnlyAttachments: presentation })}
							</div>
						</div>
						{hasMotions && (
							<div
								className={clsx(classes.itemMotionIcon, classes.itemIcon)}
								title={t("meetings:tooltips.itemHasMotions")}
								data-icon="vote"
								data-cy="has-motions"
							>
								<Icon name={hasMotions.approved ? "vote" : "motion"} color={blackColor[1]} />
							</div>
						)}
						{!presentation && videoExists && (
							<TimeStampItem
								item={minutesItem}
								broadcasts={broadcasts}
								isSelected={isSelected}
								syncEnabled={syncEnabled}
								saveSyncEnabled={saveSyncEnabled}
								youtubePlayerRef={youtubePlayerRef}
								updateSync={updateSync}
								saveSync={saveSync}
								handleTimeStampBlur={handleTimeStampBlur}
								clearTimestamp={clearTimestamp}
								setTimestamp={setTimestamp}
								disabled={videoControlsDisabled}
								presenting={presenting}
							/>
						)}

						{testSite && isConsentHeading && !presentation && (isSelected || showMoveButton) && (
							<ButtonWithTooltip
								className={classes.button}
								style={{ alignSelf: "center", margin: "0 8px" }}
								variant="outlined"
								color="primary"
								size="small"
								onClick={() => moveConsentItemClick(minutesItem)}
								dataCy="moveConsentItem"
								title={t("meetings:tooltips.moveConsentItem")}
								disabled={!moveConsentItemClick}
							>
								{t("meetings:buttons.move")}
							</ButtonWithTooltip>
						)}
					</div>
				</li>
				{!presentation && (
					<LiveMeetingAttachments
						attachments={agendaItem.attachments}
						item={agendaItem}
						isClosedMeeting={isClosedMeeting}
						isMemberOnlyHeading={isMemberOnlyHeading}
						isConsentHeading={isConsentHeading}
						isPublicCommentHeading={isPublicCommentHeading}
						addBottomBorder={addBottomBorder}
						selected={selected}
						handleSelect={handleSelect}
						presentation={presentation}
					/>
				)}
			</>
		)
	);
};

LiveMeetingMinutesItem.propTypes = {
	agendaItem: PropTypes.object.isRequired,
	minutesItem: PropTypes.object.isRequired,
	isClosedMeeting: PropTypes.bool,
	isMemberOnlyHeading: PropTypes.bool,
	isConsentHeading: PropTypes.bool,
	isPublicCommentHeading: PropTypes.bool,
	addBottomBorder: PropTypes.bool,
	selected: PropTypes.string.isRequired,
	hasNotes: PropTypes.bool,
	handleSelect: PropTypes.func,
};

LiveMeetingMinutesItem.defaultProps = {
	isClosedMeeting: false,
	isMemberOnlyHeading: false,
	isConsentHeading: false,
	isPublicCommentHeading: false,
	addBottomBorder: false,
	hasNotes: false,
	handleSelect: undefined,
};

export default LiveMeetingMinutesItem;
