import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import request from "superagent";

import ComponentContainer from "atlas/components/ComponentContainer/ComponentContainer";
import withErrorHandling from "components/ErrorHOC";
import RightPanelContainer, { MD_DOWN_WIDTH } from "components/Panels/RightPanelContainer";
import FilterPanel, { DEFAULT_SEARCH_FIELD } from "components/Panels/FilterPanel";
import { API_HOST } from "config/env";
import { resetPageConfigs, updatePageConfigs, updateToolbar } from "redux/app/actions";
import { getMeetingTemplates, clearMeetingTemplates } from "redux/meetingTemplate/actions";
import { setSnackbarOptions } from "redux/snackBar/actions";
import { useSignInDialog } from "utils/isSignedIn";
import notifierMessage from "utils/notifierMessage";
import { useUpdateObject } from "utils/updateObject";
import MeetingTemplateDeleteDialog from "./components/MeetingTemplateDeleteDialog";
import MeetingTemplateDuplicateDialog from "./components/MeetingTemplateDuplicateDialog";
import MeetingTemplateFilter from "./components/MeetingTemplateFilter";
import MeetingTemplateList from "./components/MeetingTemplateList";

const telemetryPage = "Meeting Template List";

const defaultFilter = {
	[DEFAULT_SEARCH_FIELD]: "",
	keyWords: "",
	boards: [],
	includeArchived: false,
};

const MeetingTemplateModule = () => {
	const { t } = useTranslation("meetings");
	const navigate = useNavigate();
	const [boards, setBoards] = useState(null);
	const [filter, setFilter] = useState({ ...defaultFilter });
	const [panels, setPanels] = useState({});
	const [dialogs, setDialogs] = useState({});
	const dispatch = useDispatch();
	const showSignIn = useSignInDialog();
	const meetingTemplatesReducer = useSelector((state) => state.meetingTemplatesReducer);
	const { meetingTemplates } = meetingTemplatesReducer;

	const loadBoards = () => {
		request
			.get(`${API_HOST}/api/boards`)
			.then((res) => {
				setBoards(res.body.boards);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const updateFilter = useUpdateObject(setFilter);

	const loadMeetingTemplates = () => dispatch(getMeetingTemplates(false));

	const handleCloseDialog = () => setDialogs({});

	const handleDuplicate = (meetingTemplate) => {
		setDialogs({ duplicate: meetingTemplate });

		request
			.post(`${API_HOST}/api/meetingtype/${meetingTemplate.id}/copy`)
			.withCredentials()
			.send({})
			.then((res) => {
				const { id: copyId } = res.body;

				handleCloseDialog();

				let option = notifierMessage(t("templateList.snackbar.copy.success", { name: meetingTemplate.name }), "success");
				dispatch(setSnackbarOptions(option));

				navigate(`/meetingtemplate/${copyId}`);
			})
			.catch((err) => {
				showSignIn(err, () => {
					handleUndoDelete(meetingTemplate);
				});
			});
	};

	const handleDelete = (meetingTemplate) => {
		setDialogs({ delete: meetingTemplate });
	};

	const handleAfterDelete = () => loadMeetingTemplates();

	const handleUndoDelete = (meetingTemplate) => {
		request
			.post(`${API_HOST}/api/meetingtype/${meetingTemplate.id}/restore`)
			.withCredentials()
			.send({})
			.then(() => {
				let option = notifierMessage(t("templateList.snackbar.restore.success", { name: meetingTemplate.name }), "success");
				dispatch(setSnackbarOptions(option));
				loadMeetingTemplates();
			})
			.catch((err) => {
				showSignIn(err, () => {
					handleUndoDelete(meetingTemplate);
				});
			});
	};

	const handleMeetingTemplateEvent = ({ eventName, meetingTemplate }) => {
		switch (eventName) {
			case "duplicate":
				handleDuplicate(meetingTemplate);
				break;

			case "delete":
				handleDelete(meetingTemplate);
				break;
		}
	};

	const filterClick = () => {
		setPanels((prev) => ({
			filter: !prev.filter,
		}));
	};

	const closeFilter = () => setPanels({});

	const isFiltered = (filter) => filter.keyWords || (filter.boards && filter.boards.length > 0) || filter.includeArchived;

	const filterPanelChange = (newFilter, finished) => {
		if (newFilter) {
			if (!finished) {
				updateFilter({ target: { value: newFilter[DEFAULT_SEARCH_FIELD] } }, DEFAULT_SEARCH_FIELD);
			} else {
				updateFilter({ target: { value: newFilter[DEFAULT_SEARCH_FIELD] } }, "keyWords");
			}
		} else {
			setFilter({ ...defaultFilter });
		}
	};

	useEffect(() => {
		dispatch(resetPageConfigs({ resetBack: true }));
		dispatch(
			updatePageConfigs({
				title: t("templateList.title"),
				telemetryPage,
				preferedBack: { url: "/meetingtemplates" },
			}),
		);

		loadBoards();

		return () => {
			dispatch(clearMeetingTemplates());
		};
	}, []);

	useEffect(() => {
		if (!meetingTemplates) {
			loadMeetingTemplates();
		}
	}, [meetingTemplates]);

	useEffect(() => {
		dispatch(
			updateToolbar({
				display: true,
				right: {
					tools: [
						{
							id: "open-filter",
							icon: "filter",
							tooltipText: t("templateList.tooltips.filter"),
							ariaLabel: t("templateList.tooltips.filter"),
							onClick: filterClick,
							dataCy: "toggle-filter",
							badgeProps: {
								display: isFiltered(filter),
								dataCy: "filtered",
							},
						},
					],
				},
			}),
		);
	}, [isFiltered(filter)]);

	return (
		<ComponentContainer padding="0">
			<MeetingTemplateList
				meetingTemplates={
					meetingTemplates
						? meetingTemplates.filter(
								(meetingTemplate) =>
									(!filter[DEFAULT_SEARCH_FIELD] ||
										meetingTemplate.name.toLowerCase().includes(filter[DEFAULT_SEARCH_FIELD].toLowerCase())) &&
									(!filter.boards || filter.boards.length === 0 || filter.boards.includes(meetingTemplate.boardId)) &&
									(filter.includeArchived || !meetingTemplate.archived),
						  )
						: meetingTemplates
				}
				handleMeetingTemplateEvent={handleMeetingTemplateEvent}
			></MeetingTemplateList>
			<RightPanelContainer id="right-panel-container" open={panels.filter} float drawerWidth={MD_DOWN_WIDTH} fullHeight>
				<FilterPanel
					filters={filter}
					onFilterChange={filterPanelChange}
					isFiltered={isFiltered(filter)}
					closeFilter={closeFilter}
					useSearch
				>
					<MeetingTemplateFilter filter={filter} updateFilter={updateFilter} boards={boards}></MeetingTemplateFilter>
				</FilterPanel>
			</RightPanelContainer>
			{dialogs.duplicate && <MeetingTemplateDuplicateDialog meetingTemplate={dialogs.duplicate} onClose={handleCloseDialog} />}
			{dialogs.delete && (
				<MeetingTemplateDeleteDialog
					meetingTemplate={dialogs.delete}
					onClose={handleCloseDialog}
					afterDelete={handleAfterDelete}
					undoDelete={handleUndoDelete}
				/>
			)}
		</ComponentContainer>
	);
};

export default withErrorHandling(MeetingTemplateModule);
