import { RESET_PAGE_CONFIG, UPDATE_PAGE_HEADER } from "./types";

export const resetPageConfigs = (config) => (dispatch) => {
	dispatch({
		type: RESET_PAGE_CONFIG,
		payload: config || {},
	});
};

export const updatePageHeader = (config) => (dispatch) => {
	dispatch({
		type: UPDATE_PAGE_HEADER,
		payload: config,
	});
};

export const refreshCurrentMeetings = (config) => (dispatch) => {
	// Used for Atlas compatibility with aws-community changes
};
