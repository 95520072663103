import React, { useState, useEffect, useContext } from "react";
import WopiFrame from "./WopiFrame";
import { useDispatch } from "react-redux";
import { SettingsContext } from "contexts/Settings/SettingsContext";

import withErrorHandling from "components/ErrorHOC";
import { resetPageConfigs, updatePageConfigs } from "redux/app/actions";

const WopiContainer = (props) => {
	const dispatch = useDispatch();

	const {
		wopiSettings: { wopiAccessToken, wopiAccessTokenTTL },
	} = useContext(SettingsContext);

	useEffect(() => {
		dispatch(resetPageConfigs({}));
		dispatch(
			updatePageConfigs({
				back: {
					url: `/home`,
				},
				title: "Wopi",
				countlyPage: "Wopi Integration",
				contentMaxWidth: "xl",
				contentPaper: { transparent: true, fullHeight: true },
			}),
		);
	}, []);

	return (
		<>
			<WopiFrame id="1" accessToken={wopiAccessToken} accessTokenTtl={wopiAccessTokenTTL} />
			<WopiFrame id="7701" accessToken={wopiAccessToken} accessTokenTtl={wopiAccessTokenTTL} />
		</>
	);
};

export default withErrorHandling(WopiContainer);
