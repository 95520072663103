/* eslint-disable no-unused-vars */
/* eslint-disable prefer-rest-params */
/* eslint-disable prefer-spread */
import { extend } from "jquery-deferred";
import { signalR, hubConnection } from "utils/communication/SignalR";
import jQueryShim from "./jQueryShim";

if (typeof signalR !== "function") {
	throw new Error("SignalR: SignalR is not loaded. Please ensure jquery.signalR-x.js is referenced before ~/signalr/js.");
}

function makeProxyCallback(hub, callback) {
	return () => {
		// Call the client hub method
		callback.apply(hub, jQueryShim.makeArray(arguments));
	};
}

function registerHubProxies(instance, shouldSubscribe) {
	let hub;
	let memberKey;
	let memberValue;
	let subscriptionMethod;

	const keys = Object.keys(instance);
	for (let index = 0; index < keys.length; index += 1) {
		hub = instance[keys[index]];

		if (hub.hubName) {
			if (shouldSubscribe) {
				// We want to subscribe to the hub events
				subscriptionMethod = hub.on;
			} else {
				// We want to unsubscribe from the hub events
				subscriptionMethod = hub.off;
			}

			// Loop through all members on the hub and find client hub functions to subscribe/unsubscribe
			const memberKeys = Object.keys(instance);
			for (let index2 = 0; index2 < memberKeys.length; index2 += 1) {
				memberValue = hub.client[memberKeys[index2]];

				if (typeof memberValue === "function") {
					// Use the actual user-provided callback as the "identity" value for the registration.
					subscriptionMethod.call(hub, memberKey, makeProxyCallback(hub, memberValue), memberValue);
				}
			}
		}
	}
}

// eslint-disable-next-line func-names
hubConnection.prototype.createHubProxies = function () {
	const proxies = {};
	this.starting(() => {
		// Register the hub proxies as subscribed
		// (instance, shouldSubscribe)
		registerHubProxies(proxies, true);

		// eslint-disable-next-line no-underscore-dangle
		this._registerSubscribedHubs();
	}).disconnected(() => {
		// Unsubscribe all hub proxies when we "disconnect".  This is to ensure that we do not re-add functional call backs.
		// (instance, shouldSubscribe)
		registerHubProxies(proxies, false);
	});

	proxies.concurrentEditHub = this.createHubProxy("concurrentEditHub");
	proxies.concurrentEditHub.client = {};
	proxies.concurrentEditHub.server = {
		announceMeetingEditor(meetingId, joined) {
			return proxies.concurrentEditHub.invoke.apply(
				proxies.concurrentEditHub,
				jQueryShim.merge(["AnnounceMeetingEditor"], jQueryShim.makeArray(arguments)),
			);
		},

		finishVoting(meetingId, resolutionId) {
			return proxies.concurrentEditHub.invoke.apply(
				proxies.concurrentEditHub,
				jQueryShim.merge(["FinishVoting"], jQueryShim.makeArray(arguments)),
			);
		},

		sendMessage(meetingId, message) {
			return proxies.concurrentEditHub.invoke.apply(
				proxies.concurrentEditHub,
				jQueryShim.merge(["SendMessage"], jQueryShim.makeArray(arguments)),
			);
		},

		setVoterConnectionStatus(meetingId) {
			return proxies.concurrentEditHub.invoke.apply(
				proxies.concurrentEditHub,
				jQueryShim.merge(["SetVoterConnectionStatus"], jQueryShim.makeArray(arguments)),
			);
		},

		updateEditorVoting(meetingId, userId, resolutionId, vote) {
			return proxies.concurrentEditHub.invoke.apply(
				proxies.concurrentEditHub,
				jQueryShim.merge(["UpdateEditorVoting"], jQueryShim.makeArray(arguments)),
			);
		},
	};

	proxies.concurrentEditorsHub = this.createHubProxy("concurrentEditorsHub");
	proxies.concurrentEditorsHub.client = {};
	proxies.concurrentEditorsHub.server = {
		announceEditor(editGroupId, joined) {
			return proxies.concurrentEditorsHub.invoke.apply(
				proxies.concurrentEditorsHub,
				jQueryShim.merge(["AnnounceEditor"], jQueryShim.makeArray(arguments)),
			);
		},

		sendMessage(editGroupId, message) {
			return proxies.concurrentEditorsHub.invoke.apply(
				proxies.concurrentEditorsHub,
				jQueryShim.merge(["SendMessage"], jQueryShim.makeArray(arguments)),
			);
		},
	};

	proxies.concurrentItemEditHub = this.createHubProxy("concurrentItemEditHub");
	proxies.concurrentItemEditHub.client = {};
	proxies.concurrentItemEditHub.server = {
		announceItemEditor(itemId, joined) {
			return proxies.concurrentItemEditHub.invoke.apply(
				proxies.concurrentItemEditHub,
				jQueryShim.merge(["AnnounceItemEditor"], jQueryShim.makeArray(arguments)),
			);
		},

		sendMessage(itemId, message) {
			return proxies.concurrentItemEditHub.invoke.apply(
				proxies.concurrentItemEditHub,
				jQueryShim.merge(["SendMessage"], jQueryShim.makeArray(arguments)),
			);
		},

		shareEdits(itemId, edits) {
			return proxies.concurrentItemEditHub.invoke.apply(
				proxies.concurrentItemEditHub,
				jQueryShim.merge(["ShareEdits"], jQueryShim.makeArray(arguments)),
			);
		},

		shareFocus(itemId, focus) {
			return proxies.concurrentItemEditHub.invoke.apply(
				proxies.concurrentItemEditHub,
				jQueryShim.merge(["ShareFocus"], jQueryShim.makeArray(arguments)),
			);
		},
	};

	proxies.meetingControlPanelHub = this.createHubProxy("meetingControlPanelHub");
	proxies.meetingControlPanelHub.client = {};
	proxies.meetingControlPanelHub.server = {
		checkLiveMeetings() {
			return proxies.meetingControlPanelHub.invoke.apply(
				proxies.meetingControlPanelHub,
				jQueryShim.merge(["CheckLiveMeetings"], jQueryShim.makeArray(arguments)),
			);
		},

		updateLiveMeeting(meetingId, live, activeGuid) {
			return proxies.meetingControlPanelHub.invoke.apply(
				proxies.meetingControlPanelHub,
				jQueryShim.merge(["UpdateLiveMeeting"], jQueryShim.makeArray(arguments)),
			);
		},

		addUserToMcpViewers(meetingId, joined) {
			return proxies.meetingControlPanelHub.invoke.apply(
				proxies.meetingControlPanelHub,
				jQueryShim.merge(["AddUserToMcpViewers"], jQueryShim.makeArray(arguments)),
			);
		},

		clearPosition(meetingId) {
			return proxies.meetingControlPanelHub.invoke.apply(
				proxies.meetingControlPanelHub,
				jQueryShim.merge(["ClearPosition"], jQueryShim.makeArray(arguments)),
			);
		},

		getMeetingControlPanelViewGroupName(meetingId) {
			return proxies.meetingControlPanelHub.invoke.apply(
				proxies.meetingControlPanelHub,
				jQueryShim.merge(["GetMeetingControlPanelViewGroupName"], jQueryShim.makeArray(arguments)),
			);
		},

		getMeetingEditGroupName(meetingId) {
			return proxies.meetingControlPanelHub.invoke.apply(
				proxies.meetingControlPanelHub,
				jQueryShim.merge(["GetMeetingEditGroupName"], jQueryShim.makeArray(arguments)),
			);
		},

		updatePosition(meetingId, itemId, itemType) {
			return proxies.meetingControlPanelHub.invoke.apply(
				proxies.meetingControlPanelHub,
				jQueryShim.merge(["UpdatePosition"], jQueryShim.makeArray(arguments)),
			);
		},

		updatePositionFromAgenda(meetingId, itemId) {
			return proxies.meetingControlPanelHub.invoke.apply(
				proxies.meetingControlPanelHub,
				jQueryShim.merge(["UpdatePositionFromAgenda"], jQueryShim.makeArray(arguments)),
			);
		},

		updateTimer(meetingId, totalSeconds, remainingSeconds, active, canceled) {
			return proxies.meetingControlPanelHub.invoke.apply(
				proxies.meetingControlPanelHub,
				jQueryShim.merge(["UpdateTimer"], jQueryShim.makeArray(arguments)),
			);
		},
	};

	proxies.progressHub = this.createHubProxy("progressHub");
	proxies.progressHub.client = {};
	proxies.progressHub.server = {
		announceProgress(guid, percentage, message) {
			return proxies.progressHub.invoke.apply(proxies.progressHub, jQueryShim.merge(["AnnounceProgress"], jQueryShim.makeArray(arguments)));
		},

		registerProgressGuid(guid) {
			return proxies.progressHub.invoke.apply(
				proxies.progressHub,
				jQueryShim.merge(["RegisterProgressGuid"], jQueryShim.makeArray(arguments)),
			);
		},

		clearProgressGuid(guid) {
			return proxies.progressHub.invoke.apply(
				proxies.progressHub,
				jQueryShim.merge(["ClearProgressGuid"], jQueryShim.makeArray(arguments)),
			);
		},
	};

	proxies.requestToSpeakHub = this.createHubProxy("requestToSpeakHub");
	proxies.requestToSpeakHub.client = {};
	proxies.requestToSpeakHub.server = {
		addUserToRtSViewers(meetingId, joined) {
			return proxies.requestToSpeakHub.invoke.apply(
				proxies.requestToSpeakHub,
				jQueryShim.merge(["AddUserToRtSViewers"], jQueryShim.makeArray(arguments)),
			);
		},

		cancelRequest(meetingId, userId) {
			return proxies.requestToSpeakHub.invoke.apply(
				proxies.requestToSpeakHub,
				jQueryShim.merge(["CancelRequest"], jQueryShim.makeArray(arguments)),
			);
		},

		getRequestToSpeakViewGroupName(meetingId) {
			return proxies.requestToSpeakHub.invoke.apply(
				proxies.requestToSpeakHub,
				jQueryShim.merge(["GetRequestToSpeakViewGroupName"], jQueryShim.makeArray(arguments)),
			);
		},

		newRequest(meetingId, userId) {
			return proxies.requestToSpeakHub.invoke.apply(
				proxies.requestToSpeakHub,
				jQueryShim.merge(["NewRequest"], jQueryShim.makeArray(arguments)),
			);
		},

		startTimer(meetingId, time) {
			return proxies.requestToSpeakHub.invoke.apply(
				proxies.requestToSpeakHub,
				jQueryShim.merge(["StartTimer"], jQueryShim.makeArray(arguments)),
			);
		},

		updateRequest(meetingId, userId, status) {
			return proxies.requestToSpeakHub.invoke.apply(
				proxies.requestToSpeakHub,
				jQueryShim.merge(["UpdateRequest"], jQueryShim.makeArray(arguments)),
			);
		},

		updateTimer(meetingId, action) {
			return proxies.requestToSpeakHub.invoke.apply(
				proxies.requestToSpeakHub,
				jQueryShim.merge(["UpdateTimer"], jQueryShim.makeArray(arguments)),
			);
		},
	};

	proxies.votingHub = this.createHubProxy("votingHub");
	proxies.votingHub.client = {};
	proxies.votingHub.server = {
		addUserToPresentationViewers(meetingId) {
			return proxies.votingHub.invoke.apply(
				proxies.votingHub,
				jQueryShim.merge(["AddUserToPresentationViewers"], jQueryShim.makeArray(arguments)),
			);
		},

		addUserToMembersViewers(meetingId) {
			return proxies.votingHub.invoke.apply(
				proxies.votingHub,
				jQueryShim.merge(["AddUserToMembersViewers"], jQueryShim.makeArray(arguments)),
			);
		},

		addUserToPublicViewers(meetingId) {
			return proxies.votingHub.invoke.apply(
				proxies.votingHub,
				jQueryShim.merge(["AddUserToPublicViewers"], jQueryShim.makeArray(arguments)),
			);
		},

		showResults(meetingId, itemGuid, itemDisposition) {
			return proxies.votingHub.invoke.apply(proxies.votingHub, jQueryShim.merge(["ShowResults"], jQueryShim.makeArray(arguments)));
		},

		hideResults() {
			return proxies.votingHub.invoke.apply(proxies.votingHub, jQueryShim.merge(["HideResults"], jQueryShim.makeArray(arguments)));
		},

		reSendForVote(meetingId, itemGuid) {
			return proxies.votingHub.invoke.apply(proxies.votingHub, jQueryShim.merge(["ReSendForVote"], jQueryShim.makeArray(arguments)));
		},
	};

	proxies.generalHub = this.createHubProxy("generalHub");
	proxies.generalHub.client = {};
	proxies.generalHub.server = {
		addUserToGeneralViewers() {
			return proxies.generalHub.invoke.apply(
				proxies.generalHub,
				jQueryShim.merge(["AddUserToGeneralViewers"], jQueryShim.makeArray(arguments)),
			);
		},
		updateOutstandingAgendaItemApprovalsCount() {
			return proxies.generalHub.invoke.apply(
				proxies.generalHub,
				jQueryShim.merge(["UpdateOutstandingAgendaItemApprovalsCount"], jQueryShim.makeArray(arguments)),
			);
		},
		updateSubmittedRequestToSpeakItemsCount() {
			return proxies.generalHub.invoke.apply(
				proxies.generalHub,
				jQueryShim.merge(["UpdateSubmittedRequestToSpeakItemsCount"], jQueryShim.makeArray(arguments)),
			);
		},
	};

	return proxies;
};

signalR.hub = hubConnection("/signalr", { useDefaultPath: false });
extend(signalR, signalR.hub.createHubProxies());

export { hubConnection, signalR };
